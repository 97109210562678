#gopulsa {
	.gradient {
		background: $gopulsa;
	}
	header {
		.inline {
			font-weight: 700;
		}
		button {
			background: $gopulsa;
		}
	}
	.about {
		h4,
		a,
		a::after {
			color: $gopulsa;
		}
	}
	.section-how-to-use {
		h5 {
			color: $gopulsa;
		}
		.line.gradient {
			background: $gopulsa;
		}
		.arrow-up {
			stroke: $gopulsa;
		}
		.arrow-up-circle {
			stroke-width: 3;
			stroke: $gopulsa;
		}
		.arrow-down {
			stroke: $gopulsa;
		}
		.arrow-down-circle {
			stroke: $gopulsa;
			stroke-width: 3;
		}
		.slider .slick-dots li.slick-active button:before {
			color: $gopulsa;
		}
		.slider-image .slick-dots li.slick-active button:before {
			color: $gopulsa;
		}
	}
	.new-section-how-to-use
	{
			margin-top: 180px;
			margin-bottom:100px;
			h3.title
			{
				color: $gopulsa;
				&:after
				{
					background:$gopulsa;
				}
			}
			.content
			{
				background-color:$gopulsa;
			}
			@media screen and (max-width: 767px) {
				margin-top:50px !important;
			}
	}
	.as-seen-on {
		#content {
			img {
				max-height: 150px;
			}
		}
		#left {
			background: $gopulsa;
		}
		#right {
			background: #343b41;
		}
	}
	.section-how-to-use {
		button:before {
			color: $gopulsa;
		}
	}
}

@media screen and (max-width: 35.5em) {
	#gopulsa {
		header#main--hero {
			// background-image: url(https://d3naj63yelf4gk.cloudfront.net/dist/img/header/hero/go-pulsa--mobile.jpg);
		}
	}
}
