// File Name: _gocar.scss
// Description: Sample style for GO-JEK 2.0
// Used by: Main.scss
// Dependencies:
// ------------------------------------------------------------
#gocar {
	.gofleet {
		header {
			.desc--row {
				position: relative;
				padding-right: unset !important;

				h1.hero--title {
					margin-top: 10%;
				}

				p.hero--desc {
					font-size: 22px;
				}
			}
		}

		.brushing {
	    background-image: url(../../dist/img/brush/GO-FLEET.png);
		}

		section.services {
			padding: 50px 0 !important;
			.container {
				&:before {
					padding-right: 45px;
					background-color: white;
					left: -35px;
					top: 84px;
				}

				.wrapper {
					padding: 0;
					background: transparent;
					position: relative;
    			z-index: 1;

					.dotted {
						background-repeat: no-repeat;
						height: 405px;
						position: absolute;
						width: 500px;
						top: 25px;
						left: -300px;
						z-index: -1;
					}

					h4 {
						padding-left: 25px;
						position: relative;
						top: 48px;
						left: 50px;
						color: white;
						margin-bottom: 70px;
					}

					.service-container-wrapper {
						position: relative;

						.list-wrapper {
							position: relative;
							left: 75px;
							margin-top: -16.5%;


							.list {
						    margin-right: 50px;
								margin-bottom: 50px;

								.img {
									img {
										width: 100%;
							    	transform: scale(1.075);
									}
								}

								.text,
								.text.times {
									background: #fff;
							    padding: 30px;
									position: relative;
									top: -60px;
									margin-bottom: -60px;
									text-align: center;
								}

								.text.times {
									background: #f2f2f2;
								}

								h5 {
									font-weight: bold;
									line-height: 1.5;
									letter-spacing: 0.8px;
									text-align: center;
									color: #4a4a4a;
									font-size: 18px;
									margin: 0;
								}

								i.fa-check {
									font-size: 34px;
							    color: #00c79b;
							    margin: 20px 0;
								}
								i.fa-times {
									font-size: 34px;
							    color: #d0021b;
							    margin: 20px 0;
								}

								p {
									line-height: 1.43;
									letter-spacing: 0.9px;
									text-align: center;
									color: #4a4a4a;
									margin: 0;
								}

							}
						}
					}

					.service-container {
						background-image: none;
						background-color: $gofleet;
						height: 20.5em;
					}
				}
			}
		}

		#join-now {
			.bg-layer {
				background-color: rgba(0, 194, 151, 0.8);
			}

			.right {
				.join--btn {
					  background-color: #00c297;
					  text-transform: uppercase;
					  font-weight: bold;
					  right: 2em;
					  bottom: -2em;
				}
			}
		}

		.section-our-values {
			padding: 30px;

			.title {
				color: $gofleet;
			}

			.line.gradient {
				background: $gofleet;
			}
		}

		#content-only {
			.info {
				h4 {
					color: $gofleet !important;
				}

				.line {
					background: $gofleet !important;
				}

				.link {
					a {
						background: $gofleet !important;
					}
				}
			}
		}
	}

	#talent-story {
		h4:before {
			background-color: #17619d;
		}
		h4 {
			color: #343b41;
		}
	}
	@-webkit-keyframes uil-ring-anim {
		0% {
			-webkit-transform: rotate(0deg);
			transform: rotate(0deg);
		}
		100% {
			-webkit-transform: rotate(360deg);
			transform: rotate(360deg);
		}
	}
	@-moz-keyframes uil-ring-anim {
		0% {
			-webkit-transform: rotate(0deg);
			transform: rotate(0deg);
		}
		100% {
			-webkit-transform: rotate(360deg);
			transform: rotate(360deg);
		}
	}
	@-webkit-keyframes uil-ring-anim {
		0% {
			-webkit-transform: rotate(0deg);
			transform: rotate(0deg);
		}
		100% {
			-webkit-transform: rotate(360deg);
			transform: rotate(360deg);
		}
	}
	@-o-keyframes uil-ring-anim {
		0% {
			-webkit-transform: rotate(0deg);
			transform: rotate(0deg);
		}
		100% {
			-webkit-transform: rotate(360deg);
			transform: rotate(360deg);
		}
	}
	@keyframes uil-ring-anim {
		0% {
			-webkit-transform: rotate(0deg);
			transform: rotate(0deg);
		}
		100% {
			-webkit-transform: rotate(360deg);
			transform: rotate(360deg);
		}
	}
	.uil-ring-css {
		background: none;
		position: relative;
		width: 200px;
		height: 200px;
		position: relative;
		margin: 0 auto;
		margin-top: 80px;

		div {
			position: absolute;
			display: block;
			width: 160px;
			height: 160px;
			top: 20px;
			left: 20px;
			border-radius: 80px;
			box-shadow: 0 3px 0 0 $gocar;
			-webkit-transform-origin: 80px 81.5px;
			transform-origin: 80px 81.5px;
			-webkit-animation: uil-ring-anim 1s linear infinite;
			animation: uil-ring-anim 1s linear infinite;
		}
	}

	.messages-alert {
		display: none;
		background-color: $gocar;
		width: 100%;
		padding: 20px;
		text-transform: capitalize;
		margin-top: 80px;
		margin-bottom: -80px;
		position: fixed;
    z-index: 999;

		h5 {
			color: white;
			font-size: 15px;
			margin: 0;
			padding: 0;
		}
	}

	header {
		h1 {
			font-weight: bold;
		}
		button {
			background: $gocar;
		}
	}
	.new-section-how-to-use
	{
			margin-top: 180px;
			margin-bottom:100px;
			h3.title
			{
				color: #17619d;
				&:after
				{
					background:#17619d;
				}
			}
			.content
			{
				background-color:#17619d;
			}
	}
	.articles--feed {
		background: #1f4864;
		.bg {
			background: #1f4864;
		}
		.line--title {
			background: #ffcd2e;
		}
	}
	#content-only.gocar {
		transform: scale(0.960);
		margin-bottom: -30px;

		.container {
			box-shadow: 0 -3em 0 1.5em #343b41;
			.info {
				h4 {
					color: #1f4864;
				}
				.line {
					background: $gocar;
				}
				.link {
					.contact {
						background: $gocar;
					}
				}
			}
			@media screen and (max-width: 768px) {
				width: 100%;
			}
		}
	}
	#join-now {
		margin: 0 0 30px 0;
		.right {
			a {
				background: $gocar;
				bottom: initial;
				margin-top: 20px;
				right: 0;
			}
			.title {
				font-size: 18px;
			}
			h2 {
				margin-top: -5px;
			}
		}
	}
	.section-how-to-use {
		h5 {
			color: #343b41 !important;
			font-size: 18px;
			margin-top: 200px;
		}
		.line.gradient {
			background: $gocar;
		}
		.arrow-up {
			stroke: $gocar;
		}
		.arrow-up-circle {
			stroke-width: 3;
			stroke: $gocar;
		}
		.arrow-down {
			stroke: $gocar;
		}
		.arrow-down-circle {
			stroke: $gocar;
			stroke-width: 3;
		}
		.slider .slick-dots li.slick-active button:before {
			color: $gocar;
		}
		.slider-image .slick-dots li.slick-active button:before {
			color: $gocar;
		}
		.text-mobile .line.gradient {
			background: $gocar;
		}
		.text h5 {
			color: $gocar;
		}
		.text .line.gradient {
			background: $gocar;
		}
		.slider-image .slick-dots li.slick-active button:before {
			color: $gocar;
		}
		.slider-image .slick-dots li button:before {
			font-size: 13px;
		}
	}
	.slick-arrow {
		cursor: pointer;
		ellipse {
			stroke: $gocar !important;
		}
		polygon {
			fill: $gocar;
		}
	}
	.how-it-works--wrapper {
		margin: 0;
		padding-top: 60px;
    padding-bottom: 60px;

		.container {
			padding: 0;
			h4 {
				color: $gocar;
				position: relative;
				margin-left: 3em;
			}
			.section--title {
				color: #343b41;
			}
			.section--title:before {
				content: "";
				position: absolute;
				left: -5em;
				top: 0.6em;
				padding-right: 45px;
				height: 3px;
				margin: 0;
				background-color: $gocar;
				z-index: 10;
			}
			figure.hand-ss {
				background: #fff;
				img {
					max-height: 27em;
					width: auto;
					right: 0;
					margin: 0 auto;
					position: absolute;
					left: 0;
				}
			}
			.layer .rect--primary {
				background: $gocar;
			}
			.layer .rect--primary.custom-color {
				background: #dbdbdb;
			}
			.layer .rect--secondary.custom-color {
				background: #ffce00;
			}
		}
	}
	.new-section-how-to-use {
		margin-top: 60px;
    margin-bottom: 60px;

		h3.title {
			color: #17619d;
			&:after {
				background: #17619d;
			}
		}
		.content {
			background-color: #17619d;
		}
	}
	.income-calculator {
		background: #f8f8f8;
		padding: 60px 0;

		.point {
			width: 100%;
			height: 470px;
			display: flex;

			.side {
				padding: 10px;
			}

			.form {
				background-color: #fff;
				box-shadow: 0 2px 8px 0 #dbdbdb;
				padding: 30px;
				height: 100%;

				.wrapper {
					position: relative;
					left: 15px;
				}

				.section--title {
			  	font-size: 17.5px;
			  	font-weight: bold;
			  	text-transform: uppercase;
			  	color: $gocar;
			    left: -75px;
			    line-height: .78;
					letter-spacing: 1.9px;
			    position: relative;
			    margin-left: 5em;

					span {
						position: relative;
						top: 5px;
						left: 22px;
					}
			  }
			  .section--title:before {
			    content: "";
			    position: absolute;
			    left: -5em;
			    top: 0.6em;
			    padding-right: 55px;
			    height: 3px;
			    margin: 0;
			    background-color: $gocar;
			    z-index: 10;
			  }
				p {
					position: relative;
					left: -50px;
					margin-left: 6.225em;
					font-size: 14px;
					font-weight: 300;
					line-height: 1.43;
					letter-spacing: 1.5px;
					color: #697b8c;
				}

				.group {
					position: relative;
			    left: -50px;
					margin-top: 30px;
					margin-bottom: 30px;
			    margin-left: 5.525em;

					.selectbox {
						position: relative;
					}

					.selectbox:after {
						content: '\f0d7';
						font-family: 'FontAwesome';
						flex-direction: column;
						justify-content: center;
						right: 15px;
						top: 41.25px;
						font-size: 20px;
						text-align: right;
						position: absolute;
					}

					label {
						font-size: 14px;
						font-weight: bold;
						font-family: $opensans;
						letter-spacing: 1.5px;
						color: #697b8c;
					}

					input {
						margin-top: 7.5px;
						height: 50px;
						object-fit: contain;
						border: solid 1px #c3c9d7;
						width: 100%;
						padding: 15px;
					}

					select {
						background-color: white;
						margin-top: 7.5px;
						height: 50px;
						object-fit: contain;
						border: solid 1px #c3c9d7;
						width: 100%;
						-webkit-appearance: none;
						border-radius: 0;
						padding: 15px;
					}

					input::-webkit-outer-spin-button,
					input::-webkit-inner-spin-button {
				    -webkit-appearance: none;
				    margin: 0;
					}
				}

				.button {
					width: 100%;
					text-align: right;
					margin-top: 50px;
					margin-bottom: -65px;
					margin-left: -35px;

					button {
						border-radius: 5px;
						background-color: $gocar;
						padding: 25px 60px;
						color: white;
					}
				}
			}

			.output {
				height: 100%;

				.image {
					background-color: $gocar;
					display: inline-block;
					height: 100%;
			    align-items: center;

					img {
						margin-top: 55px;
					}
				}

				.loader {
					display: none;
					background-color: #fff;
					box-shadow: 0 2px 8px 0 #dbdbdb;
					padding: 30px;
					height: 100%;
					width: 100%;
					text-align: center;
				}

				.result {
					display: none;
					background-color: #fff;
					box-shadow: 0 2px 8px 0 #dbdbdb;
					padding: 30px;
					height: 100%;
					width: 100%;
					text-align: center;

					.wrapper {
						padding: 65px 0;

						img {
							max-width: 160px;
							margin: 0 auto;
							display: block;
						}

						p {
							font-family: $opensans;
							font-size: 14px;
							font-weight: 300;
							line-height: 1.43;
							letter-spacing: 1.5px;
							text-align: center;
							color: #697b8c;
						}

						h4 {
							object-fit: contain;
							font-family: $lato;
							font-size: 20px;
							font-weight: bold;
							letter-spacing: 1.1px;
							color: #221f20;
							margin: 0;
							margin-top: -7.5px;
						}

						.button {
							margin-top: 37.5px;

							a {
								border-radius: 5px;
								background-color: $gocar;
								padding: 25px 15px;
								color: white;
								font-weight: bold;
							  line-height: 0.8;
							  letter-spacing: 3.3px;
							}
						}
					}
				}
			}
		}
	}
}

@media screen and (max-width: 1024px) {
	#gocar {
		.income-calculator {
			.point {
				height: 510px;

				.form {
					.section--title {
						line-height: 23px;
					}

					.button {
						margin-top: 0;
					}
				}
			}
		}
	}
}
@media screen and (max-width: 768px) {
	#gocar {
		.income-calculator {
			.point {
				height: 560px;

				.form {
					.section--title {
						span {
							top: 0;
						}
					}
				}

				.output {
					.image {
						img {
							margin-top: 150px !important;
						}
					}
					.result {
						padding: 105px 0;
					}
				}
			}
		}
		#content-only {
			transform: unset !important;
		}
		header#main--hero {
			// background-image: url(https://d3naj63yelf4gk.cloudfront.net/dist/img/header/hero/go-ride--mobile.jpg);
		}
		.how-it-works--wrapper {
			padding-top: 0;
			margin-top: -5px;

			.container {
				.section--title {
					margin-left: 4.2em;
					position: relative;
			    top: 10px;

					&:before {
						top: .565em !important;
					}
				}
			}
		}
	}
}

@media screen and (max-width: 35.5em) {
	#gocar {
		.gofleet {
			#join-now {
		    padding-bottom: 120px;
			}

			#content-only {
				padding: 0px 10px 130px !important;

				.container {
			    box-shadow: 0 -3em 0 2.2em #343b41;

					.info {
						.link {
							a {
						    line-height: 15px;
							}
						}
					}
				}
			}

			header {
				.desc--row {
					p.hero--desc {
						font-size: 17px;
				    margin-top: 20px;
					}
				}
			}

			section.services {
				.container {
						&:before {
							padding-right: 35px;
							background-color: $gofleet;
							/* left: -35px; */
							top: 42.5px;
							left: 50px;
						}

					.wrapper {
						.service-container {
							height: 60px !important;
							background: white;

							h4 {
								color: #343b41;
								top: 8.5px;
								left: 80px;
							}
						}

						.service-container-wrapper {
							.list-wrapper {
								margin-top: 7.5%;
								left: 0;

								.list {
									margin: unset;
									height: 235px;
							    transform: scale(1.085);
									margin-bottom: 20px;

									.img {
										width: 50%;
										float: left;

										img {
											transform: scale(1.0);
										}
									}

									.text {
										width: 50%;
										float: right;
										top: 0;
										margin-bottom: 0;
										padding: 10px;

										i {
											display: inline;
											background: $gofleet;
											color: white;
											padding: 7.5px 12.5px 7.5px 7.5px;
											font-size: 25px;
											position: absolute;
											bottom: -20px;
											left: -40px;
										}
									}

									.text.checklist {
										background: $gofleet;
										margin-bottom: 60px;
										height: 100%;

										h5 {
											color: white;
											text-align: left;
										}

										p {
											color: white;
											padding-bottom: 0;
											text-align: left;
											margin-top: 10px;
										}
									}

									.text.times {
										height: 100%;
										padding: 10px;
										top: 0;
										margin-bottom: 60px;

										h5,
										p {
											text-align: left;
										}

										p {
											margin-top: 10px;
										}
									}
								}

								.list.second-side {
									.img {
										float: right !important;
									}

									.text {
										i {
											display: inline;
											background: #f2f2f2;
											color: #d0021b;
											padding: 7.5px 15px 7.5px 10px;
											font-size: 25px;
											position: absolute;
											bottom: -20px;
											right: -40px;
											left: unset;
										}
									}
								}
							}
						}

						.dotted {
							height: 60px;
							position: absolute;
							width: 75px;
							top: 15px;
							left: -20px;
							z-index: 1;
						}
					}
				}
			}
		}
		.messages-alert {
			margin-top: 60px;
		}
		.income-calculator {
			.container {
				padding-left: 0;
				padding-right: 0;
			}

			.point {
				display: block !important;
				height: 560px;

				.side {
					padding: 0;
				}

				.side-mobile {
					width: 100%;
				}

				.form {
					display: block;
					padding: 30px 0;

					.wrapper {
						left: 7.5px;

						.section--title {
							left: -52.5px;

							span {
								left: 2.5px;
								top: 0;
							}
						}
						.section--title:before {
							left: -4.2em;
						}
					}

					.button {
						text-align: center;
						margin-left: 0;
					}
				}

				.output {
					.image {
						display: none;
					}
					.loader {
						height: 500px;
					}
					.result {
						display: none;

						a.close {
							color: $gocar;
							position: relative;
							top: -155px;
							font-size: 34px;
							right: 15px;
							float: right;
						}
					}
				}
			}
		}
		#join-now {
			.right {
				.button {
					text-align: center;

					a {
						right: unset !important;
				    position: relative;
				    top: 55px;
					}
				}
			}
		}
		#content-only {
	    padding: 30px 10px;

		  .container {
				box-shadow: 0 -110px 0 40px #343b41;

				h4 {
		    	margin-bottom: -15px;
				}

				.info {
					p {
						margin: 0;
				    margin-bottom: -30px;
						padding: 2.5px;
					}
				}

		    .link {
		      position: relative;
		      top: 40px;

					a {
						margin: 0 auto;
				    display: block;
						width: 200px;
						padding: 30px;
					}

					.faq {
						position: relative;
				    top: -10px;
					}
		    }
		  }
		}
		.new-section-how-to-use {
			margin-top: -60px;
	    margin-bottom: -60px;
		}
		.section-how-to-use {
			.arrow--wrapper {
				top: 25%;
			}
			ul.slick-dots {
				margin-top: -3.5em;
			}
			.phone-wrapper {
				top: 31%;
			}
		}
	}
}
