/**
 * Author: JR
 * Julian Rulliansyah
 * @JRulliansyah
 * */

.office {
	background: #f8f8f8;
	margin: 2em auto;
	.list {
		margin-right: 2em;
		margin-bottom: 2em;
	}
	header {
		margin-top: 90px;
	}
	header .text-wrapper {
		margin-top: 160px;
	}
	header .text-wrapper h4 {
		font-weight: 300;
		margin-top: -15px;
	}
	section.office {
		margin-top: -5px;
		padding: 40px;
		background: #f8f8f8;
	}
	section.office .wrapper {
		background: white;
		margin-top: 70px;
		padding: 50px;
	}
	section.office .wrapper .headquarters h2.title {
		font-weight: 300;
	}
	section.office .wrapper .branch .line {
		height: 4px;
		width: 45px;
		background: $gojek;
		position: relative;
		top: 33px;
		left: -70px;
	}
	section.office .wrapper .branch .list {
		position: relative;
		margin-bottom: 40px;
	}
	section.office .wrapper .branch .list h4.region {
		font-weight: 700;
		font-size: 17px;
	}
	section.office .wrapper .branch .list p.address {
		font-weight: 300;
		font-size: 14px;
	}
	section.office .wrapper .branch .list a.view-on-map {
		font-size: 12px;
		color: $gojek;
		font-weight: 700;
	}
	section.office .wrapper .branch .list a.view-on-map>img {
		max-width: 15px;
		position: relative;
		top: 1.5px;
		left: 5px;
	}
	.map-overlay {
		background: $gojek;
		height: 400px;
		width: 107%;
		margin-top: -495px;
		position: relative;
		left: 120px;
	}
	#googleMap {
		width: 110%;
		height: 400px;
		z-index: 99 !important;
		position: relative;
		top: -80px;
		left: 90px;
		overflow: hidden;
	}
}

@media only screen and (max-width: 480px) {
	.contact {
		background: white;
		header {
			margin-top: -50px;
		}
		header .text-wrapper {
			margin-top: 160px;
		}
		header .text-wrapper h4 {
			font-weight: 300;
			margin-top: -15px;
		}
		section.office {
			margin-top: -5px;
			padding: 0px;
			background: #f8f8f8;
		}
		section.office .wrapper {
			background: white;
			margin-top: 70px;
			padding: 20px;
		}
		section.office .wrapper .headquarters h2.title {
			font-weight: 300;
		}
		section.office .wrapper .branch .line {
			height: 4px;
			width: 45px;
			background: $gojek;
			position: relative;
			top: 33px;
			left: -70px;
		}
		section.office .wrapper .branch .list {
			position: relative;
			margin-bottom: 40px;
		}
		section.office .wrapper .branch .list h4.region {
			font-weight: 700;
			font-size: 17px;
		}
		section.office .wrapper .branch .list p.address {
			font-weight: 300;
			font-size: 14px;
		}
		section.office .wrapper .branch .list a.view-on-map {
			font-size: 12px;
			color: $gojek;
			font-weight: 700;
		}
		section.office .wrapper .branch .list a.view-on-map>img {
			max-width: 15px;
			position: relative;
			top: 1.5px;
			left: 5px;
		}
		.map-overlay {
			display: none;
		}
		#googleMap {
			width: 100%;
			height: 220px;
			z-index: 99 !important;
			position: relative;
			top: 0px;
			left: 0px;
			overflow: hidden;
		}
		.header-img {
			width: 100%;
			margin-top: 20px;
			margin-bottom: 40px;
		}
	}
}
