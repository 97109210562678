#goauto {
	#join-now {
		margin-bottom: 30px;
		.right {
			padding: 2em;
			a {
				background-color: #1f4864;
				bottom: auto;
				right: 0;
			}
		}
	}
	.new-section-how-to-use {
		margin-top: 180px;
		margin-bottom: 100px;
		h3.title {
			color: #1f4864;
			&:after {
				background: #343b41;
			}
		}
		.content {
			background-color: #1f4864;
		}
		@media screen and (max-width: 35.5em) {
			margin-bottom:20px;
		}
	}
	.messages-alert {
		position: fixed;
		display: none;
		background-color: #1f4864;
		width: 100%;
		padding: 20px;
		margin-top: 0px;
		z-index: 99;
		top: 86px;
		margin-bottom: -80px;
		box-shadow: 0px -5px 25px rgba(10, 10, 10, .85);
		h5 {
			color: white;
			font-size: 15px;
			margin: 0;
			padding: 0;
		}
	}
	@-webkit-keyframes uil-ring-anim {
		0% {
			-webkit-transform: rotate(0deg);
			transform: rotate(0deg);
		}
		100% {
			-webkit-transform: rotate(360deg);
			transform: rotate(360deg);
		}
	}
	@-moz-keyframes uil-ring-anim {
		0% {
			-webkit-transform: rotate(0deg);
			transform: rotate(0deg);
		}
		100% {
			-webkit-transform: rotate(360deg);
			transform: rotate(360deg);
		}
	}
	@-webkit-keyframes uil-ring-anim {
		0% {
			-webkit-transform: rotate(0deg);
			transform: rotate(0deg);
		}
		100% {
			-webkit-transform: rotate(360deg);
			transform: rotate(360deg);
		}
	}
	@-o-keyframes uil-ring-anim {
		0% {
			-webkit-transform: rotate(0deg);
			transform: rotate(0deg);
		}
		100% {
			-webkit-transform: rotate(360deg);
			transform: rotate(360deg);
		}
	}
	@keyframes uil-ring-anim {
		0% {
			-webkit-transform: rotate(0deg);
			transform: rotate(0deg);
		}
		100% {
			-webkit-transform: rotate(360deg);
			transform: rotate(360deg);
		}
	}
	.uil-ring-css {
		background: none;
		position: relative;
		width: 200px;
		height: 200px;
		position: relative;
		margin: 0 auto;
		display: none;
		margin-bottom: -500px;
		/* display: none; */
		top: 370px;
		div {
			position: absolute;
			display: block;
			width: 160px;
			height: 160px;
			top: 20px;
			left: 20px;
			border-radius: 80px;
			box-shadow: 0 3px 0 0 #98ce00;
			-webkit-transform-origin: 80px 81.5px;
			transform-origin: 80px 81.5px;
			-webkit-animation: uil-ring-anim 1s linear infinite;
			animation: uil-ring-anim 1s linear infinite;
		}
	}
	#talent-story-golife {
		h4 {
			color: #4a4a4a !important;
		}
		h4:before,
		a {
			background-color: #4a4a4a !important;
		}
		.content {
			img {
				border-radius: 0;
				width: initial;
				height: initial;
			}
		}
		.detail {
			.info {
				h4 {
					margin-bottom: 1.5em;
				}
			}
		}
	}
	.section-our-values {
		padding: 3em;
		background: #f8f8f8;
		h4.title {
			color: #343b41;
		}
		.line.gradient {
			background: #343b41;
		}
		.list {
			h4 {
				line-height: 25px;
				font-size: 15px;
			}
			img {
				height: 125px;
			}
		}
	}
	.how-it-works--wrapper {
		.section--title {
			color: #343b41;
		}
		a {
			text-decoration: none;
			color: #485460;
			cursor: pointer;
		}
		.section--title:before {
			background-color: #343b41;
		}
		.layer {
			.rect--secondary {
				background: #ffce00;
			}
			.rect--primary {
				background: #343b41;
			}
		}
	}
	#content-only.thank-you {
		margin-top: 100px;
		.container {
			background: white;
			box-shadow: 0 -3em 0 1.5em $goauto;
			.info {
				h4 {
					color: $goauto;
				}
				.line {
					background: $goauto;
				}
				.link {
					a {
						width: 280px;
					}
					.contact {
						background: #1f4864;
						span {
							margin: 0 15px;
						}
					}
				}
			}
		}
	}
	#talent-story {
		h4:before {
			background-color: #343b41;
			top: 6%;
		}
		h4 {
			color: #343b41;
		}
		hr {
			background: #343b41;
			top: 3.75%;
		}
		.detail {
			h2 {
				color: #343b41;
			}
		}
		.slick-slide {
			outline: none;
		}
		.info h5 {
			color: #343b41;
		}
	}
	.articles--feed {
		.bg {
			background: #1f4864;
		}
		.section--title {
			color: white;
		}
		svg {
			ellipse {
				stroke: #ffffff !important;
			}
			polygon {
				fill: #ffffff !important;
			}
		}
		.line--title {
			background: white;
		}
		.read--more>a {
			background: $goauto;
		}
		.content {
			.one {
				border-bottom: 6px solid $goauto;
			}
			.two {
				border-bottom: 6px solid $goauto;
			}
			.three {
				border-bottom: 6px solid $goauto;
			}
			.four {
				border-bottom: 6px solid $goauto;
			}
		}
		h4>a:hover {
			color: $goauto;
		}
	}
	#bg-as-seen-on {
		hr {
			background: $goauto;
		}
		.title {
			color: $goauto;
		}
		#right {
			background: $goauto;
		}
	}
	.section-how-to-use {
		.text {
			.line.gradient {
				background: #343b41;
			}
			h5 {
				color: #343b41;
			}
		}
		.slider-image .slick-dots li.slick-active button:before {
			color: #343b41;
		}
	}
	section.services {
		h4 {
			color: #343b41;
			line-height: 1.2;
		}
		.container:before {
			background: #343b41;
		}
		.wrapper .service-container {
			background: #babcbf;
		}
	} // Join Page
	section.join {
		margin: 120px 0;
		.hidden {
			display: none;
		}
		.banner {
			img {
				height: 100%;
				width: 100%;
			}
		}
		.form-1,
		.form-2 {
			padding: 50px;
			background: white;
			position: relative;
			.error-input {
				border: 1px solid #E0164A !important
			}
			.error-messages {
				color: red;
				font-style: italic;
			}
			.button-disabled {
				cursor: not-allowed;
				box-shadow: none !important;
				background: rgb(200, 200, 200) !important;
			}
			.combo-box {
				position: relative;
			}
			.combo-box:after {
				content: '\f0d7';
				font-family: 'FontAwesome';
				flex-direction: column;
				justify-content: center;
				right: 15px;
				top: 30px;
				text-align: right;
				position: absolute;
			}
			.combo-box-long:after {
				top: 50px;
			}
			.title {
				position: relative;
				font-size: 20px;
				padding-left: 30px;
				padding: 0;
				p {
					font-weight: bold;
					letter-spacing: 1.5px;
					color: #bcbdc0;
					span::before {
						font-weight: bold;
					}
				}
			}
			.text-info {
				margin-top: 30px;
				position: relative;
				padding-left: 25px;
				line-height: 1.46;
				letter-spacing: 1px;
				color: #bcbdc0;
				p:first-child {
					text-transform: uppercase;
				}
				p {
					margin: 0;
				}
			}
			.text-info::before {
				content: "";
				height: 3px;
				width: 15px;
				background: #093c5b;
				position: absolute;
				left: 0;
				top: 20%;
			}
			label {
				font-size: 14px;
				font-family: $opensans;
				margin-bottom: 1em;
				letter-spacing: 1.5px;
				color: #697b8c;
				font-weight: 700;
				margin: 0;
				text-transform: uppercase;
			}
			textarea {
				resize: none;
				width: 100%;
				height: 234px;
			}
			input[type=text],
			select,
			textarea {
				border: 1px solid #c3c9d7;
				width: 100%;
				border-radius: 0;
				box-shadow: none;
				-webkit-appearance: none;
			}
			::-webkit-input-placeholder {
				/* WebKit, Blink, Edge */
				color: rgb(200, 200, 200);
			}
			:-moz-placeholder {
				/* Mozilla Firefox 4 to 18 */
				color: rgb(200, 200, 200);
				opacity: 1;
			}
			::-moz-placeholder {
				/* Mozilla Firefox 19+ */
				color: rgb(200, 200, 200);
				opacity: 1;
			}
			:-ms-input-placeholder {
				/* Internet Explorer 10-11 */
				color: rgb(200, 200, 200);
			}
			::-ms-input-placeholder {
				/* Microsoft Edge */
				color: rgb(200, 200, 200);
			}
			span.normal {
				text-transform: initial;
			}
			span.required {
				color: #b51410;
			}
			.divident {
				margin-top: 25px;
			}
			.submit {
				button {
					border-radius: 5px;
					background-color: #1f4864;
					box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.19);
					color: #fff;
					float: right;
					position: absolute;
					bottom: -30px;
					right: 50px;
					padding: 20px 50px;
					width: 300px;
				}
			}
		}
		.form-2 {
			.text-info:before {
				top: 40%;
			}
			.rating {
				.start,
				.finish {
					font-size: 14px;
				}
				p {
					display: block;
				}
				.start {}
				.finish {}
				ul {
					padding: 0;
					li {
						float: left;
						margin: 0 6%;
						list-style: none;
						width: 20px;
						height: 20px;
						border: 1px solid #485460;
						border-radius: 50%;
						position: relative;
						input[type="radio"] {
							box-shadow: 0px 0px 5px 0 rgba(0, 0, 0, .09);
							-webkit-appearance: none;
							height: 20px;
							width: 20px;
							border-radius: 50%;
						}
						input[type="radio"]:hover {
							cursor: pointer;
						}
						input[type="radio"]:checked {
							background: #98ce00;
							height: 16px;
							width: 16px;
							top: 1px;
							left: 1px;
							border-radius: 50%;
							position: absolute;
						}
					}
				}
			}
			.combo-box:after {
				top: 50px;
			}
			.submit {
				.back {
					right: 40%;
					background: #343b41;
				}
			}
		}
	}
}

@media screen and (max-width: 768px) {
	#goauto {
		header#main--hero {
			// background-image: url(https://d3naj63yelf4gk.cloudfront.net/dist/img/header/hero/go-auto--mobile.jpg);
		}
		#talent-story {
			margin-top: 2em;
		}
		.section-our-values {
			padding: 0;
			margin-top: 8em;
		}
		.how-it-works--wrapper {
			margin-top: 3em;
			.section--title {
				line-height: 1;
			}
		}
	}
}

@media screen and (min-width: 64em) {
	#goauto {
		section.services {
			.wrapper {
				.service-container {
					.service-list {
						width: 19em;
						height: 350px;
					}
				} // .navigation-arrow {
				// 	display: none;
				// }
			}
		}
	}
}

@media screen and (max-width: 35.5em) {
	#goauto {
		#join-now {
			.right {
				.button {
					text-align: center;
					a {
						right: unset!important;
						position: relative;
						top: 55px;
					}
				}
			}
		}
		#talent-story-golife {
			margin: 0;
			h4:before {
				top: 3.5% !important;
			}
		}
		.services {
			margin-top: 2em;
			padding-top: 0;
			.wrapper .service-container .service-list {
				height: 300px;
				.img-container img {
					margin-top: 0;
				}
			}
		}
		section.join {
			// margin: 10px 0;
			.banner {
				display: none;
			}
			.form-1 {
				padding: 10px;
				label {
					font-size: 12px;
				}
				.text-info:before {
					top: 7px;
				}
				.submit {
					button {
						position: initial;
						padding: 25px;
						display: block;
						margin: auto;
						float: none;
						margin-top: 30px;
						font-size: 14px;
						width: 100%;
					}
				}
			}
			.form-2 {
				padding: 10px;
				label {
					font-size: 12px;
				}
				.text-info:before {
					top: 7px;
				}
				.submit {
					button {
						position: initial;
						padding: 25px;
						display: block;
						margin: auto;
						float: none;
						margin-top: 30px;
						font-size: 14px;
						width: 100%;
					}
				}
			}
		}
	}
}
