/**
 * Author: JR
 * Julian Rulliansyah
 * @JRulliansyah
 * */
.about-page {
    width: 100% !important;
    background: rgba(250,250,250,1.0);
    margin-bottom: 3.5em;

    /* -- Header Gradient Styling - START -- */
    .header-gradient {
      background: $gojek;
      width: 100%;
      margin-top: 80px;
      height: 65px;
    }
    /* -- Header Gradient Styling - END -- */

    .video-content {
      display: none;
      position: relative;
      font-family: $opensans;
      width: 100%;
      height: 0;
      padding-bottom: 70%;

      iframe {
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        margin-top: 0 !important;
      }

      .video-close {
        position: absolute;
        color: #fff;
        font-size: 200%;
        cursor: pointer;
        background: $gojek url('../img/card/about/close-btn.png') no-repeat center center;
        background-size: 29px 29px;
        border-radius: 50%;
        width: 60px;
        height: 60px;
        top: -1em;
        right: -1em;
      }
    }

    .img-container {
        position: relative;
        background: #fff url() center center/cover;
        background-size: 100%;
        padding-bottom: 2em;

        .img-container-2 {
          position: relative;
        }

        .play {
          position: absolute;
          margin: auto;
          top: 0;
          left: 0;
          cursor: pointer;
          right: 0;
          bottom: 0;
          width: 50px;
          height: 50px;
          border-radius: 50%;
          object-fit: contain;
          background-color: #ffcd2e;
          box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.24);

          img {
            margin: auto;
            position: absolute;
            top: 0; left: 0; bottom: 0; right: 0;
          }
        }
    }

    /* -- Section Content - START -- */
    .section-content {
      margin-top: 30px;
    }

    .section-content .content {
      background: white;
      padding: 40px;
      border-top: 3.5px solid $gojek;
    }

    .section-content .content .line {
      width: 50px;
      position: relative;
      left: -70px;
      height: 3px;
      background: black;
    }

    .section-content .content .line-2 {
        width: 50px;
        position: relative;
        left: -70px;
        height: 3px;
        background: #3ca33a;
    }

    .section-content .content .subnav-wrapper {
        margin-top: 50px;
    }

    .section-content .content .subnav-wrapper ul {
        list-style-type: none;
        font-family: "Open Sans", sans-serif;
        position: relative;
        top: -28px;
        left: -25px;
    }
    .section-content .content .subnav-wrapper ul li {
        margin-bottom: 10px;
    }
    .section-content .content .subnav-wrapper ul li span {
        text-decoration: none;
        color: inherit;
        font-size: 16px;
        letter-spacing: 1px;
    }
    .section-content ul li span#about-us {
        color: $gojek;
    }
    .section-content ul li span {
        &.active--sidebar {
            font-weight: bold;
            color: #fff;
        }
    }

    .section-content .content .breadcrumb {
        color: #b3b9c7;
        font-family: 'Open Sans', sans-serif;
        line-height: 1.17;
        letter-spacing: 1.3px;
        margin-top: -6.5px;
    }

    .section-content .content .breadcrumb a {
        color: #b3b9c7;
        font-family: 'Open Sans', sans-serif;
        line-height: 1.17;
        letter-spacing: 1.3px;
        margin-top: -6.5px;
    }

    .section-content .content .time {
        color: #b3b9c7;
        font-family: 'Open Sans', sans-serif;
        font-weight: 300;
        font-size: 16px;
        margin-top: -6.5px;
    }

    .section-content .content .title {
        font-family: $lato;
        font-weight: 300;
        font-size: 35px;
        letter-spacing: 1px;
        color: #343b41;
    }

    .section-content .mobile-title {
        font-family: $lato;
        font-weight: 300;
        font-size: 35px;
        letter-spacing: 1px;
        color: #343b41;
    }
    .section-content .content .page-title {
        font-size: 32px;
        font-weight: 300;
        font-family: $opensans;
        letter-spacing: 1.2px;
    }
    .section-content .content .page-title > span {
        color: #3ca33a;
    }
    .section-content .content .page-title-mobile {
      font-size: 32px;
      font-weight: 300;
      display: none;
      font-family: $opensans;
      letter-spacing: 1.5px;
    }
    .section-content .content .page-title-mobile > span {
      color: #3ca33a;
    }
    .section-content .content .text-content {
      font-size: 14px;
      font-weight: 300;
      line-height: 1.56;
      letter-spacing: 1px;
      padding: 15px;
      color: #485460;
    }
    .section-content .content .text-content h4 {
      line-height: 28px;
      letter-spacing: 1.3px;
      color: #485460;
    }

    .section-content .content .text-content p {
      letter-spacing: 1.25px;
      color: #485460;
      font-size: 14px;
    }

    .section-content .sidebar {
      padding: 0 10px;

      .sidebar--link {
        margin-bottom: 20px;
        display: block;
      }
    }
    .section-content .sidebar .wrapper {
      margin-top: -10px;
    }
    .section-content .sidebar .wrapper h3 {
      color: #48af4a;
      font-family: "Open Sans", sans-serif;
      line-height: 0.78;
      font-size: 15px;
      letter-spacing: 1.9px;
    }
    .section-content .sidebar .line {
      width: 50px;
      height: 3px;
      margin-top: 10px;
      background: $gojek;
    }

    .section-content .sidebar .list {
      margin-top: 20px;
    }

    .section-content .sidebar .list img {
      width: 100%;
    }

    .mobile-title-page {
      display: none;
      object-fit: contain;
      font-family: $lato;
      text-align: center;
      font-weight: 300;
      line-height: 1.16;
      letter-spacing: 5.5px;
      margin-top: 0px;
      color: #343b41;
    }
    .mobile-title-page span {
      color: $gojek;
    }

    .mobile-who-we-are {
      margin-top: 20px;
      margin-bottom: -10px;
      margin-left: 10px;
      display: none;
    }
    .mobile-who-we-are .line {
      background: $gojek !important;
    }
    .mobile-who-we-are .breadcrumb {
      color: $gojek !important;
      position: relative !important;
      top: -.5px !important;
    }

    .mobile-mission {
      position: relative;
      top: -52.5px;
      margin-bottom: -50px;
      margin-left: 45px;
      display: none;
    }
    .mobile-mission .line {
      background: white !important;
    }
    .mobile-mission .breadcrumb {
      color: white !important;
      position: relative !important;
      top: -.5px !important;
    }
  /* -- Section Content - END -- */

  /* -- Section Values - START -- */
  .section-values {
      margin: 30px 0 -40px -40px;
  }

  .section-values .content {
      padding: 20px;
      min-height: 400px;
      border: 0;
      background: $gojek !important;
  }

  .section-values .content .navigation .line {
      width: 50px;
      position: relative;
      left: -60px;
      top: 130px;
      height: 3px;
      background: #3ca33a;
  }

  .section-values .content .navigation .line-4 {
      width: 50px;
      position: relative;
      left: -50px;
      top: 130px;
      height: 3px;
      background: #3ca33a;
  }

  .section-values .content .navigation ul {
      list-style-type: none;
      color: white;
      font-family: "Open Sans", sans-serif;
      position: relative;
      top: 70px;
      left: -20px;
  }
  .section-values .content .navigation ul li {
      margin-bottom: 10px;
  }
  .section-values .content .navigation ul li a {
      text-decoration: none;
      color: inherit;
      font-size: 16px;
      letter-spacing: 1px;
  }

  .section-values-box {
      z-index: 10;
      position: relative;
  }
  .section-values-box .text {
      background: #fcfcfe;
      padding: 40px;
      min-height: 320px;
      z-index: -999;
      width: 430px;
      margin-left: 270px;
      margin-bottom: -60px;
      margin-top: -310px;
  }
  .section-values-box .text .wrapper {
      margin-bottom: -35px;
  }
  .section-values-box .text .list {
      margin-bottom: 15px;
  }
  .section-values-box .text .list img {
    max-width: 90px;
    margin-right: 15px;
  }

  .about-img-1 {
    position: relative;
    top: 5px;
  }
  .about-img-2 {
    position: relative;
  }
  .about-img-3 {
    position: relative;
    top: 10px;
  }

  .section-values-box .text .list h4 {
      font-family: $opensans;
      font-size: 15px;
      color: #485460;
      margin-left: 10px;
      margin-top: 10px;
  }
  .section-values-box .text .list p {
      margin-top: -12.5px;
      margin-left: 10px;
      font-size: 12.5px;
      font-family: $opensans;
  }
  @media only screen and (max-width: 480px) {
      .section-values-box .text {
          display: none;
      }
  }

  /* Section Vision Mission - START */
  .section-vision-mission {
      margin: -20px 0 -40px -40px;
      z-index: -10;
      background-repeat: no-repeat !important;
      background-size: cover !important;
  }
  .section-vision-mission .content-outline {

  }
  .section-vision-mission .content-outline .background {
      background-size: cover !important;
      background-position: center center;
      height: 450px;
  }
  .section-vision-mission .content-outline .background .overlay {
      background: rgba(0, 0, 0, .0);
      height: 450px !important;
  }
  .section-vision-mission .content-outline .text {
    padding: 40px 5px 40px 40px;
    background: #fff;
    position: relative;
    left: 30px;
    top: 5px;
  }
  .section-vision-mission .content-outline .text h4 {
    letter-spacing: 1px;
    margin-top: 50px;
    line-height: 28px;
    font-family: $opensans;
  }
  .section-vision-mission .content-outline .text p {
    font-family: $opensans;
    font-size: 14px;
    margin-top: 10px;
    letter-spacing: 1px;
  }

  .section-vision-mission .navigations .line {
      width: 50px;
      position: relative;
      left: -30px;
      top: 183px;
      height: 3px;
      background: #3ca33a;
  }

  .section-vision-mission .navigations ul {
      list-style-type: none;
      color: white;
      font-family: "Open Sans", sans-serif;
      position: relative;
      top: 90px;
      left: 15px;
  }
  .section-vision-mission .navigations ul li {
      margin-bottom: 10px;
  }
  .section-vision-mission .navigations ul li a {
      text-decoration: none;
      color: inherit;
      font-size: 16px;
      letter-spacing: 1px;
  }
  /* Section Vision Mission - END */


  /* Section Our Values Mobile - START */
  .section-our-values-mobile {
      margin: -20px 0 -40px -40px;
      z-index: -10;
      display: none !important;
  }
  .section-our-values-mobile .content-outline .background {
      background-size: cover !important;
      background-position: center center;
      height: 450px;
  }
  .section-our-values-mobile .content-outline .background .overlay {
      background: rgba(0, 0, 0, .0);
      height: 450px !important;
  }
  .section-our-values-mobile .content-outline .text {
    padding: 20px;
    background: white;
    position: relative;
    left: 30px;
  }
  .section-our-values-mobile .content-outline .text h4 {
    letter-spacing: 1px;
    margin-top: 50px;
    line-height: 34px;
    font-family: $opensans;
  }
  .section-our-values-mobile .content-outline .text p {
    font-family: $opensans;
    font-size: 14px;
    margin-top: 10px;
    letter-spacing: 1px;
  }
  .section-our-values-mobile .navigations .line {
      width: 50px;
      position: relative;
      left: -30px;
      top: 183px;
      height: 3px;
      background: #3ca33a;
  }
  .section-our-values-mobile .navigations ul {
      list-style-type: none;
      color: white;
      font-family: "Open Sans", sans-serif;
      position: relative;
      top: 90px;
      left: 15px;
  }
  .section-our-values-mobile .navigations ul li {
    margin-bottom: 10px;
  }
  .section-our-values-mobile .navigations ul li a {
    text-decoration: none;
    color: inherit;
    font-size: 16px;
    letter-spacing: 1px;
  }
  #OurValuesSlider {
    display: none;
    margin-top: 80px;
    margin-bottom: 80px;
    margin-left: -17.5px;
  }
  #OurValuesSlider .slick-dots {
    position: relative;
    display: none;
  }
  #OurValuesSlider .item .wrapper img {
    max-width: 230px;
    border-radius: 0;
    background-color: transparent;
    display: block;
    margin-right: auto;
    margin-left: auto;
  }
  #OurValuesSlider .item .wrapper h4 {
    line-height: 33px;
    letter-spacing: 1.5px;
    color: #485460;
    font-size: 23px;
    text-align: center;
  }
  #OurValuesSlider .item .wrapper p {
    object-fit: contain;
    font-family: $opensans;
    padding: 30px;
    font-size: 16px;
    font-weight: 300;
    line-height: 1.43;
    letter-spacing: 1.5px;
    text-align: center;
    margin-top: -20px;
    color: #343b41;
  }
  .arrow {
    position: relative;
    top: -90px;
    display: none;
    margin-bottom: -80px;
  }
  /* Section Our Values Mobile - END */

  @media only screen and (max-width: 480px) {
    .article-content {
      position: relative;
      left: -20px;
    }

    .mobile-title-page {
      display: block !important;
      font-size: 38px;
    }

    .mobile-who-we-are {
      display: block !important;
    }
    .mobile-mission {
      display: block !important;
    }

    .header-gradient {
      background: $gojek;
      width: 100%;
      margin-top: 80px;
      height: 25px !important;
    }

    /* Section Content */
    .section-content .sidebar .wrapper {
        text-align: center;
    }
    .section-content .content .title {
        display: none;
        letter-spacing: 1px;
    }
    .sub-navigation {
        display: none;
    }
    .section-content .container {
        padding: 0;
        margin: 0;
    }
    .section-content .content .page-title-wrapper {
        display: none;
    }
    .section-content .content .page-title-mobile-wrapper {
        display: block;
    }
    .section-content .content .text-content {
        letter-spacing: 1px;
    }
    .section-content .sidebar {
        margin-top: 30px;
        width: 100%;
        padding: 0;
    }
    .section-content .sidebar .list img {
        max-width: 295px;
    }
    .section-content .sidebar .list {
      text-align: center;
      position: relative;
      top: 10px;
      left: -20px;
    }

    .section-content .content .line {
      width: 50px;
      position: relative;
      top: 2px;
      left: -65px !important;
      height: 3px;
      background: #000;
    }

    .section-content .content .breadcrumb {
      object-fit: contain;
      font-family: $opensans;
      font-size: 15px;
      font-weight: bold;
      letter-spacing: 2.5px !important;
      position: relative;
      top: -2px;
      left: 5px;
      color: #c3c9d7;
    }

    .section-content .content .text-content h4 {
      object-fit: contain;
      font-size: 20px;
      font-weight: bold;
      line-height: 1.43;
      letter-spacing: 1.5px;
      color: #343b41;
    }

    .section-content .content .text-content p {
      font-family: $opensans;
      font-size: 17px !important;
      font-weight: 300;
      line-height: 1.43;
      letter-spacing: 1.5px;
      color: #343b41;
    }
    /* Section Content */


    /* Section Values */
    .section-values {
      display: none;
    }
    /* Section Values */


    /* Section Our Values Mobile */
    #OurValuesSlider {
      display: table !important;
    }
    .arrow {
      display: block !important;
    }

    .section-our-values-mobile {
        z-index: -10;
        margin-top: 30px;
        padding: 0;
        display: block !important;
    }

    .section-our-values-mobile .content-outline {
        width: 100%;
    }

    .section-our-values-mobile .content-outline .background {
      background: $gojek;
      background-size: cover !important;
      background-position: center center;
      height: 100px;
      width: 100%;
    }
    .section-our-values-mobile .content-outline .background .overlay {
      background: rgba(0, 0, 0, .0);
      height: 450px !important;
      width: 100%;
    }

    .section-our-values-mobile .content-outline .text {
      padding: 40px;
      background: white;
      position: relative;
      left: 0px;
    }

    .section-our-values-mobile .content-outline .text h4 {
      object-fit: contain;
      font-family: $opensans;
      font-size: 23px;
      font-weight: bold;
      line-height: 1.43;
      letter-spacing: 1.5px;
      color: #343b41;
      margin-top: 20px;
    }

    .section-our-values-mobile .content-outline .text p {
      letter-spacing: 1.25px;
      color: #485460;
      font-size: 17px;
    }

    .section-our-values-mobile .navigations .line {
        width: 50px;
        position: relative;
        left: -30px;
        top: 407px;
        height: 3px;
        background: #3ca33a;
    }

    .section-our-values-mobile .navigations ul {
        list-style-type: none;
        color: white;
        position: relative;
        bottom: 0;
        top: 380px;
        left: 0px;
        font-weight: 300;
        display: none;
    }

     .section-our-values-mobile .content-outline .navigations ul li.who-we-are,
     .section-our-values-mobile .content-outline .navigations ul li.our-values {
        display: none;
     }
     /* Section Our Values Mobile */

    /* Section Vision Mission */
    .section-vision-mission {
        z-index: -10;
        margin-top: 30px;
        padding: 0;
    }

    .section-vision-mission .content-outline {
        width: 100%;
    }

    .section-vision-mission .content-outline .background {
        background: url("https://d3naj63yelf4gk.cloudfront.net/dist/img/about/mission.png");
        background-size: cover !important;
        background-position: center center;
        height: 450px;
        width: 375px;
    }
    .section-vision-mission .content-outline .background .overlay {
        background: rgba(0, 0, 0, .0);
        height: 450px !important;
        width: 100%;
    }

    .section-vision-mission .content-outline .text {
        padding: 40px;
        background: white;
        position: relative;
        left: 0px;
    }

    .section-vision-mission .content-outline .text h4 {
      object-fit: contain;
      font-family: $opensans;
      font-size: 23px;
      font-weight: bold;
      line-height: 1.43;
      letter-spacing: 1.5px;
      color: #343b41;
      margin-top: 20px;
    }

    .section-vision-mission .content-outline .text p {
      letter-spacing: 1.25px;
      color: #485460;
      font-size: 17px;
    }

    .section-vision-mission .navigations .line {
        width: 50px;
        position: relative;
        left: -30px;
        top: 407px;
        height: 3px;
        background: #3ca33a;
    }

    .section-vision-mission .navigations ul {
        list-style-type: none;
        color: white;
        position: relative;
        bottom: 0;
        top: 380px;
        left: 0px;
        font-weight: 300;
        display: none;
    }

     .section-vision-mission .content-outline .navigations ul li.who-we-are,
     .section-vision-mission .content-outline .navigations ul li.our-values {
        display: none;
     }
     /* Section Vission Mission */
  }
  @media only screen and (max-width: 440px) {
      .article-content {
        position: relative;
        left: -30px;
      }
      /* Section Vission Mission */
      .section-vision-mission {
          z-index: -10;
          padding: 0;
      }

      .section-vision-mission .content-outline {
          width: 100%;
      }

      .section-vision-mission .content-outline .background {
          background: url("https://d3naj63yelf4gk.cloudfront.net/dist/img/about/mission.png");
          background-size: cover !important;
          background-position: center center;
          height: 450px;
          width: 415px;
      }
      .section-vision-mission .content-outline .background .overlay {
          background: rgba(0, 0, 0, .0);
          height: 450px !important;
          width: 100%;
      }

      .section-vision-mission .content-outline .text {
          padding: 40px;
          background: white;
          position: relative;
          left: 0px;
      }

      .section-vision-mission .navigations .line {
          width: 50px;
          position: relative;
          left: -30px;
          top: 407px;
          height: 3px;
          background: #3ca33a;
      }

      .section-vision-mission .navigations ul {
          list-style-type: none;
          color: white;
          font-family: "Open Sans", sans-serif;
          position: relative;
          bottom: 0;
          top: 380px;
          left: 0px;
          font-weight: 300;
          display: none;
      }

       .section-vision-mission .content-outline .navigations ul li.who-we-are,
       .section-vision-mission .content-outline .navigations ul li.our-values {
          display: none;
       }
       /* Section Vission Mission */

       #OurValuesSlider {
         display: none;
         margin-top: 80px;
         margin-bottom: 80px;
       }

       .arrow {
         left: -17.5px;
       }
  }

  @media only screen and (max-width: 320px) {
      /* Section Content */
      .section-content .content .title {
          letter-spacing: 1px;
      }
      .sub-navigation {
          display: none;
      }
      .section-content .container {
          padding: 0;
          margin: 0;
      }
      .section-content .content .page-title-wrapper {
          display: none;
      }
      .section-content .content .page-title-mobile-wrapper {
          display: block;
      }
      .section-content .content .text-content {
          letter-spacing: 1px;
      }
      .section-content .sidebar {
          margin-top: 30px;
          width: 100%;
          padding: 0;
      }
      .section-content .sidebar .list img {
          max-width: 275px;
      }
      /* Section Content */


      /* Section Vision Mission */
      .section-vision-mission {
          z-index: -10;
          padding: 0;
      }

      .section-vision-mission .content-outline {
          width: 100%;
      }

      .section-vision-mission .content-outline .background {
          background: url("https://d3naj63yelf4gk.cloudfront.net/dist/img/about/mission.png");
          background-size: cover !important;
          background-position: center center;
          height: 450px;
          width: 320px;
      }
      .section-vision-mission .content-outline .background .overlay {
          background: rgba(0, 0, 0, .0);
          height: 450px !important;
          width: 100%;
      }

      .section-vision-mission .content-outline .text {
          padding: 20px;
          background: white;
          position: relative;
          left: 0px;
      }

      .section-vision-mission .navigations .line {
          width: 50px;
          position: relative;
          left: -30px;
          top: 407px;
          height: 3px;
          background: #3ca33a;
      }

      .section-vision-mission .navigations ul {
        list-style-type: none;
        color: white;
        font-family: "Open Sans", sans-serif;
        position: relative;
        bottom: 0;
        top: 380px;
        left: 0px;
        font-weight: 300;
      }

      .section-vision-mission .content-outline .navigations ul li.who-we-are,
      .section-vision-mission .content-outline .navigations ul li.our-values {
        display: none;
      }
       /* Section Vission Mission */
  }

  /* Large Screen */
  @media screen and (min-width: 64em) {
    .img-container .play {
      margin-left: -2.5em;
      padding: 40px;
    }
  }


  .slick-slider {
   display: table;
   table-layout: fixed;
   width: 100%;
  }
  .disabled {
    filter: grayscale(100%);
  }

  .slick-arrow ellipse {
    stroke: $gojek !important;
  }

  .slick-arrow polygon {
    fill: $gojek !important;
  }

}
