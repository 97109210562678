// ----------------------------------------------------------------------
  // Base
// ----------------------------------------------------------------------
@import "base/fonts";
@import "base/mixins";
@import "base/variables";
@import "base/pure-extension";
@import "base/extends";
@import "base/reset";

// ----------------------------------------------------------------------
  // Layout
// ----------------------------------------------------------------------

// ----------------------------------------------------------------------
  // V3 styling 
// ----------------------------------------------------------------------
@import "new/default.scss";
@import "new/animations.scss";
@import "new/cards/cards.scss";


// ----------------------------------------------------------------------
  // Modules
// ----------------------------------------------------------------------
@import "modules/preload";
@import "modules/header";
@import "modules/navigation";
@import "modules/footer";
@import "modules/as-seen-on";
@import "modules/blog/highlight-article";
@import "modules/blog/top-picks";
@import "modules/blog/article-feed";
@import "modules/blog/blog-list";
@import "modules/download";
@import "modules/faq/head";
@import "modules/faq/landing";
@import "modules/faq/list-menu";
@import "modules/faq/detail";
@import "modules/faq/social";
@import "modules/talent-story";
@import "modules/about";
@import "modules/privacy";
@import "modules/term";
@import "modules/about-pages";
@import "modules/services";
@import "modules/join-now";
@import "modules/merchant-list";
@import "modules/user-review";
@import "modules/our-values";
@import "modules/how-to-use";
@import "modules/contact";
@import "modules/how-it-works";
@import "modules/gallery";
@import "modules/promo-banner";
@import "modules/how-it-works";
@import "modules/gallery";
@import "modules/content-only";
@import "modules/join-frame";
// ----------------------------------------------------------------------
  // Views
// ----------------------------------------------------------------------
@import "views/blue";
@import "views/gojek";
@import "views/goride";
@import "views/gotix";
@import "views/gomart";
@import "views/gopay";
@import "views/gopulsa";
@import "views/gopoint";
@import "views/goglam";
@import "views/gomassage";
@import "views/gomed";
@import "views/gomed_join";
@import "views/goauto";
@import "views/gobox";
@import "views/goclean";
@import "views/gofood";
@import "views/gocar";
@import "views/gobluebird";
@import "views/gosend";
@import "views/goresto";
@import "views/govideo";
@import "views/gosend_join";
@import "views/gosend_larismanis";
@import "views/career";
@import "views/academy";
@import "views/golife";
@import "views/gobills";
@import "views/topup";

/* Set Style pure row */
.pure-g-r {
  letter-spacing: -.31em;
}

/* Loading */
.loader--wrapper {
  width: 100%;
  height: 100%;
  position: fixed;
  background: #fff;
  z-index: 2000;

  .uil-ring-css {
    width: 100% !important;
    height: 100% !important;
  }
  .uil-ring-css > div#loading-img {
    -webkit-transform-origin: center !important;
    transform-origin: center !important;
  }
}
.mask-form {
  background: rgba(255, 255, 255, .7);
  width: 100%;
  height: 100%;
  min-height: 360px;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 12;
  display: none;
}
@-webkit-keyframes uil-ring-anim {
  0% {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-webkit-keyframes uil-ring-anim {
  0% {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-moz-keyframes uil-ring-anim {
  0% {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-ms-keyframes uil-ring-anim {
  0% {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-moz-keyframes uil-ring-anim {
  0% {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-webkit-keyframes uil-ring-anim {
  0% {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-o-keyframes uil-ring-anim {
  0% {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes uil-ring-anim {
  0% {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.uil-ring-css {
  background: none;
  position: relative;
  width: 100%;
  height: 100%;
}
.uil-ring-css > div#loading-img {
  position: absolute;
  display: block;
  width: 100px;
  height: 100px;
  top: 50%;
  left: 50%;
  margin: -50px;
  border-radius: 80px;
  box-shadow: 0 2px 1px 0 $gojek;
  -ms-animation: uil-ring-anim 1s linear infinite;
  -moz-animation: uil-ring-anim 1s linear infinite;
  -webkit-animation: uil-ring-anim 1s linear infinite;
  -o-animation: uil-ring-anim 1s linear infinite;
  animation: uil-ring-anim 1s linear infinite;
}
.fixed--body {
  position: fixed;
}
/* Message */
section.message {
  width: 100%;
  position: fixed;
  color: #fff;
  top: -100%;
  background: rgba(255, 0, 0, .7);
  z-index: 1000;
  -webkit-transition: top .5s ease-in-out;
  -moz-transition: top .5s ease-in-out;
  -o-transition: top .5s ease-in-out;
  transition: top .5s ease-in-out;
}
section.message.active {
  top: 40px;
}
section.message > p {
  text-align: center;
}

/* Init laod animation body */
@-webkit-keyframes fade {
  from {opacity: 0;}
  to {opacity: 1;}
}
@-moz-keyframes fade {
  from {opacity: 0;}
  to {opacity: 1;}
}
@-o-keyframes fade {
  from {opacity: 0;}
  to {opacity: 1;}
}
@keyframes fade {
  from {opacity: 0;}
  to {opacity: 1;}
}

body {
  animation-name: fade;
  animation-duration: 2s;
}
figure {
  margin: 0;
}

.text-center {
  text-align: center;
}

.row {
  margin: 0 -15px;
}
.row:before, .row:after {
  display: table;
  content: " ";
  clear: both;
}

.modal-overlay {
  position: fixed;
  _position: absolute;
  width: 100%;
  background: rgba(40, 40, 40, .65);
  z-index: 100;
  top: 0;
  width: 100%;
  height: 100%;
  margin-left: 0;
  margin-top: 0;
}
.modal.tnc {
  display: none;
  position: fixed;
  _position: absolute;
  width: 965px;
  background: rgb(242, 242, 242);
  z-index: 100;
  margin-left: 15px;
  margin: 0 auto;
  top: 50%;
  left: 50%;
  margin-top: -200px; /* Negative half of height. */
  margin-left: -482.5px; /* Negative half of width. */
  font-size: 15px;
  height: 490px;
  -moz-box-shadow: 0 0 5px #ff0000;
  -webkit-box-shadow: 0 0 5px #ff0000;
  box-shadow: 0 0px 30px 0px rgba(140, 140, 140, .85);

  .main--title {
    text-transform: uppercase;
  }
  .modal--title {
    margin: 0;
  }

  .checkmark__circle {
    stroke: #f5a523;
    fill: #f5a523;
    stroke-dasharray: 166;
    stroke-dashoffset: 166;
    stroke-width: 2;
    stroke-miterlimit: 10;
    animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
  }
  .checkmark {
    top: -65px;
    left: 30px;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    display: block;
    stroke-width: 2;
    stroke: #fff;
    stroke-miterlimit: 10;
    margin: 10% auto;
    box-shadow: inset 0px 0px 0px $gofood;
    animation: fill .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both;
  }
  .checkmark__check {
    transform-origin: 50% 50%;
    stroke-dasharray: 29;
    stroke-dashoffset: 29;
    animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
  }

  h3.title {
    font-family: 'Open Sans', sans-serif;
    background: $gofood;
    width: 380px;
    font-size: 14px;
    padding: 15px;
    color: white;
    text-transform: uppercase;
    letter-spacing: 4.5px;
    margin-top: -46px;
  }

  .modal--content {
    padding: 15px 0;
  }

  li {
    font-weight: 300;
    line-height: 1.62em;
    letter-spacing: 1.5px;
    font-family: "Open Sans", Helvetica, sans-serif;
    font-size: 0.875em;
  }

  .column-block {
    padding: 3.5px 50px;

    h4 {
      font-size: 14px;
      font-weight: bold;
      letter-spacing: 1.5px;
      color: #697a8b;
    }

    .tnc-wrapper {
      height: 320px;
      overflow-y: scroll;
      background: #fafafa;
      padding: 2.5px 22px;
      width: 100%;
      position: relative;
      top: -60px;
      margin-bottom: -50px;
    }

    a {
      background: $gofood;
      border-radius: 5px;
      color: #fff;
      padding: 22.5px;
      display: block;
      text-transform: uppercase;
      font-weight: 700;
      margin-top: 15px;
      text-align: center;
      width: 100%;
    }
  }
}

@media only screen and (max-width: 768px) {
  .modal.tnc {
    position: fixed;
    _position: absolute;
    width: 100%;
    background: #f2f2f2;
    z-index: 100;
    margin-left: 15px;
    margin: 0 auto;
    top: 140px;
    left: 0;
    font-size: 15px;
    height: 490px;
    box-shadow: 0 0 30px 0 hsla(0,0%,55%,.85);

    a.removebutton {
      opacity: 0;
    }

    h3.title {
      position: relative;
      top: 1.25px;
      width: 100%;
    }

    .column-block {
      padding: 10px;

      h4 {
        float: none;
        position: relative;
        left: 55px;
        text-align: center;
        top: -30.5px;
        padding: 0;
        line-height: 17px;
      }
    }
  }

  section.message.active {
    top: 60px;
  }
}

@media only screen and (max-width: 320px) {
  .modal.tnc {
    transform: scale(.9);
    top: 100px;

    h4 {
      float: none;
      font-size: 10px;
      position: relative;
      left: 55px;
      text-align: center;
      top: -23.5px;
      padding: 0;
      line-height: 17px;
    }
  }
}
