.blog-list {
	padding-top: 82px;
	background: #f8f8f8;
	margin-bottom: 80px;

	@media only screen and (max-width: 768px) {
		padding-top: 60px;
	}

	.section-content .content {
		border-color: $gojek;
	}
	/*[class*='pure-u'] {
		padding: 0 10px;
	}*/
	.video-container {
		iframe {
			width: 70%;
			height: 375px;
			left: 50%;
	    position: relative;
	    transform: translateX(-50%);
		}
	}
	@media only screen and (max-width: 414px) {
		.video-container {
			iframe {
				width: 100%;
				height: 250px;
			}
		}
	}
	.word-break {
		word-break: break-all;
	}
	.load--more {
		position: relative;
		margin: 30px auto 0;
		padding: 12px 20px;
		color: #ffffff;
		font-weight: 300;
		font-size: 14px;
		font-family: $opensans;
		background-color: #48af4a;
		cursor: pointer;
		-webkit-transition: all 0.5s ease-in-out;
		-moz-transition: all 0.5s ease-in-out;
		-o-transition: all 0.5s ease-in-out;
		transition: all 0.5s ease-in-out;
		border-radius: 5px;
		width: 30%;

		p {
			text-transform: uppercase;
			text-align: center;
			font-weight: 300;
			margin: 0px;
		}
	}
	.load--more:hover {
		background: $gojek;
		color: #fff;
	}
	.sidebar--link {
		display: block;
		margin-bottom: 20px;
	}
	.active--fixed {
		position: fixed;
		width: 20%;
	}
	#menu--list {
		padding: 0;
		margin: 0 auto;
		border: 0;
		width: 300px;
		border-radius: 0;
		overflow: hidden;
		background: #fff;
		position: relative;
		top: 25px;
		display: none;
		box-shadow: 0 3px 6px 0 rgba(0, 0, 0, .13);
		.bg--select {
			width: 50px;
			height: 100%;
			background: #48af4a url('https://d3naj63yelf4gk.cloudfront.net/dist/img/select-more.svg') no-repeat 55% 50%;
			position: absolute;
			right: 0;
			z-index: 1;
			background-size: 13px;
		}
		select {
			padding: 15px;
			width: 105%;
			border: none;
			box-shadow: none;
			background-color: transparent;
			background-image: none;
			-webkit-appearance: none;
			-moz-appearance: none;
			appearance: none;
			position: relative;
			z-index: 5;
		}
		select:focus {
			outline: none;
		}
	}
	.media-center {
    width: 80%;
  	margin: 0 auto;

  	@media only screen and (max-width: 768px) {
  		width: 90%;
  	}
	}

	#blog--listing {
		padding:0 30px;
		position: relative;

		&.active--filter {
			.post-item {
				-webkit-filter: blur(3px);
				filter: blur(3px);
			}
		}
		&.active--filter:before {
			// content: 'Loading Data ...';
			position: absolute;
			padding-top: 35px;
			width: 97%;
			height: 100%; // background: rgba(0, 0, 0, 0.3);
			color: #fff;
			z-index: 5;
			text-align: center;
		}
		&>p.no--results {
			text-align: center;
		}
		@media only screen and (max-width: 767px) {
			padding:0px;
		}
	}
	.post-item {
		background: #fff;
		padding: 30px;
		border-top: 6px solid;
		margin-bottom: 30px;
		border-radius: 0px 0px 20px 20px;
		.service--logo {
			width: 45px;
			height: 45px;
			display: inline-block;
			background-size: 25px !important;
		}
		.pre--title {
			font-family: $opensans;
			display: inline-block;
			padding-left: 15px;
			.post--type {
				font-size: 9px;
				font-weight: bold;
				letter-spacing: 1px;
				color: #c3c9d7;
				margin: 0;
				text-transform: uppercase;
			}
			.service {
				font-size: 18px;
				font-weight: bold;
				line-height: 1.0;
				color: #343b41;
				margin: 0px;
				text-transform: uppercase;
			}
			.time {
				font-size: 8px;
				letter-spacing: 0.5px;
				color: #485460;
				margin: 0;
			}
		}
		.post--title {
			margin: 23px 0 25px;
			a {
				color: #343b41;
				font-family: $opensans;
				font-size: 24px;
				font-weight: 500;
				line-height: 1.42;
				letter-spacing: 1.3px;
			}
		}
		.post--image {
			width: 100%;
			height: 100%;
		}
		.post--abstract {
			font-family: 'Open Sans', sans-serif;
			font-size: 18px;
			font-weight: 300;
			line-height: 1.56;
			letter-spacing: 0.8px;
			color: #485460;
			margin: 20px 0;
		}
		.read--more {
			font-family: $opensans;
			display: block;
			width: 100%;
			text-transform: uppercase;
			font-weight: 500;
			color: #91c64f;
			font-size: 14px;
			letter-spacing: 1px;
			&:after {
				content: "";
				position: relative;
				left: 10px;
				top: 8px;
				background: url(https://d3naj63yelf4gk.cloudfront.net/dist/img/about/shape-arrow.svg) no-repeat;
				background-size: contain;
				width: 20px;
				height: 20px;
				display: inline-block;
			}
		}
		.post--share {
			margin: auto;
			height: 45px;
			.share--on {
				display: inline-block;
				background: #e8ebf2;
				color: #b3b9c7;
				padding: 10px 20px;
				font-family: 'Open Sans', sans-serif;
				font-weight: 700;
				height: 100%;
				float: left;
				span {
					line-height: 1.56;
					letter-spacing: .8px;
				}
				a
				{
					display: block !important;
				}
			}
			.socmed {
				display: block !important;
				padding: 14.5px 20px;
				color: #fff;
				font-weight: 700;
				text-align: center;
				position: relative;
				width: 50px;
				float: left;
			}
			.fa-facebook {
				background: #3b5998;
			}
			.fa-twitter {
				background: #55acee;
			}
			.fa-instagram {
				background: #cd486b;
			}
		}
		ul,ol
		{
			font-size:18px;
			line-height: 30px;
		}
	}
	.post-item[data-service*="go-ride"] {
		border-color: $goride;
		.service--logo {
			@include icon-goride;
		}
	}
	.post-item[data-service*="go-food"] {
		border-color: $gofood;
		.service--logo {
			@include icon-gofood;
		}
	}
	.post-item[data-service*="go-pay"] {
		border-color: $gopay;
		.service--logo {
			@include icon-gopay;
		}
	}
	.post-item[data-service*="go-tix"] {
		border-color: $gotix;
		.service--logo {
			@include icon-gotix;
		}
	}
	.post-item[data-service*="go-jek"] {
		border-color: $gojek;
		.service--logo {
			@include icon-gojek;
		}
	}
	.post-item[data-service*="corporate"] {
		border-color: $gojek;
		.service--logo {
			@include icon-gojek;
		}
	}
	.post-item[data-service*="go-send"] {
		border-color: $gosend;
		.service--logo {
			@include icon-gosend;
		}
	}
	.post-item[data-service*="go-mart"] {
		border-color: $gomart;
		.service--logo {
			@include icon-gomart;
		}
	}
	.post-item[data-service*="go-car"] {
		border-color: $gocar;
		.service--logo {
			@include icon-gocar;
		}
	}
	.post-item[data-service*="go-box"] {
		border-color: $gobox;
		.service--logo {
			@include icon-gobox;
		}
	}
	.post-item[data-service*="go-point"] {
		border-color: $gopoint;
		.service--logo {
			@include icon-gopoint;
		}
	}
	.post-item[data-service*="go-pulsa"] {
		border-color: $gopulsa;
		.service--logo {
			@include icon-gopulsa;
		}
	}
	.post-item[data-service*="go-busway"] {
		border-color: $gobusway;
		.service--logo {
			@include icon-gobusway;
		}
	}
	.post-item[data-service*="go-med"] {
		border-color: $gomed;
		.service--logo {
			@include icon-gomed;
		}
	}
	.post-item[data-service*="go-massage"] {
		border-color: $gomassage;
		.service--logo {
			@include icon-gomassage;
		}
	}
	.post-item[data-service*="go-clean"] {
		border-color: $goclean;
		.service--logo {
			@include icon-goclean;
		}
	}
	.post-item[data-service*="go-auto"] {
		border-color: $goauto;
		.service--logo {
			@include icon-goauto;
		}
	}
	.post-item[data-service*="go-glam"] {
		border-color: $goglam;
		.service--logo {
			@include icon-goglam;
		}
	}
	.post-item[data-service*="go-life"] {
		border-color: $golife;
		.service--logo {
			@include icon-golife;
		}
	}
	.post-item[data-service*="go-bills"] {
		border-color: $gobills;
		.service--logo {
			@include icon-gobills;
		}
	}
	.post-item[data-service*="go-pertamina"] {
		border-color: $gopertamina;
		.service--logo {
			@include icon-gopertamina;
		}
	}
	.post-item[data-service*="go-fix"] {
		border-color: $gofix;
		.service--logo {
			@include icon-gofix;
		}
	}
	.post-item[data-service*="go-daily"] {
		border-color: $godaily;
		.service--logo {
			@include icon-godaily;
		}
	}
	.post-item[data-service*="go-laundry"] {
		border-color: $golaundry;
		.service--logo {
			@include icon-golaundry;
		}
	}
	/* header color */
	&[data-service*="go-ride"] {
		.header-line {
			background: $goride;
		}
		.bg--select {
			background-color: $goride !important;
		}
	}
	&[data-service*="go-food"] {
		.header-line {
			background: $gofood;
		}
		.bg--select {
			background-color: $gofood !important;
		}
	}
	&[data-service*="go-pay"] {
		.header-line {
			background: $gopay;
		}
		.bg--select {
			background-color: $gopay !important;
		}
	}
	&[data-service*="go-tix"] {
		.header-line {
			background: $gotix;
		}
		.bg--select {
			background-color: $gotix !important;
		}
	}
	&[data-service*="go-jek"] {
		.header-line {
			background: $gojek;
		}
		.bg--select {
			background-color: $gojek !important;
		}
	}
	&[data-service*="go-send"] {
		.header-line {
			background: $gosend;
		}
		.bg--select {
			background-color: $gosend !important;
		}
	}
	&[data-service*="go-mart"] {
		.header-line {
			background: $gomart;
		}
		.bg--select {
			background-color: $gomart !important;
		}
	}
	&[data-service*="go-car"] {
		.header-line {
			background: $gocar;
		}
		.bg--select {
			background-color: $gocar !important;
		}
	}
	&[data-service*="go-box"] {
		.header-line {
			background: $gobox;
		}
		.bg--select {
			background-color: $gobox !important;
		}
	}
	&[data-service*="go-point"] {
		.header-line {
			background: $gopoint;
		}
		.bg--select {
			background-color: $gopoint !important;
		}
	}
	&[data-service*="go-pulsa"] {
		.header-line {
			background: $gopulsa;
		}
		.bg--select {
			background-color: $gopulsa !important;
		}
	}
	&[data-service*="go-busway"] {
		.header-line {
			background: $gobusway;
		}
		.bg--select {
			background-color: $gobusway !important;
		}
	}
	&[data-service*="go-med"] {
		.header-line {
			background: $gomed;
		}
		.bg--select {
			background-color: $gomed !important;
		}
	}
	&[data-service*="go-massage"] {
		.header-line {
			background: $gomassage;
		}
		.bg--select {
			background-color: $gomassage !important;
		}
	}
	&[data-service*="go-clean"] {
		.header-line {
			background: $goclean;
		}
		.bg--select {
			background-color: $goclean !important;
		}
	}
	&[data-service*="go-auto"] {
		.header-line {
			background: $goauto;
		}
		.bg--select {
			background-color: $goauto !important;
		}
	}
	&[data-service*="go-glam"] {
		.header-line {
			background: $goglam;
		}
		.bg--select {
			background-color: $goglam !important;
		}
	}
	&[data-service*="go-pertamina"] {
		.header-line {
			background: $gopertamina;
		}
		.bg--select {
			background-color: $gopertamina !important;
		}
	}
	&[data-service*="go-fix"] {
		.header-line {
			background: $gofix;
		}
		.bg--select {
			background-color: $gofix !important;
		}
	}
	&[data-service*="go-daily"] {
		.header-line {
			background: $godaily;
		}
		.bg--select {
			background-color: $godaily !important;
		}
	}
	&[data-service*="go-laundry"] {
		.header-line {
			background: $golaundry;
		}
		.bg--select {
			background-color: $golaundry !important;
		}
	}
	.top--line {
		background: $gojek;
		height: 6px;
		width: 100%;
		position: sticky;
    top: 100px;
	}
	.filter--blog {
		background: #fff;
		padding: 30px;
		font-family: 'Open Sans', sans-serif;
		margin-bottom: 30px;
		position: sticky;
		top: 105px;

		.filter--title {
			text-transform: uppercase;
			font-size: 18px;
			color: #48af4a;
			position: relative;
			margin:0px;
			line-height: normal;
		}

		.filter--label {
			font-size: 14px;
			line-height: 1.71;
			letter-spacing: 1.2px;
			color: #343b41;
			text-transform: uppercase;
			display: block;
			padding: 20px 0;
		}
		[data-all*="services"]:before {
			content: url(c);
			position: absolute;
			margin-top: 17px;
			margin-left: 12px;
		}
		.select--wrapper {
			padding: 0;
			margin: 0;
			border: 1px solid #ccc;
			width: 100%;
			border-radius: 3px;
			overflow: hidden;
			background-color: #fff;
			background: #fff url('https://d3naj63yelf4gk.cloudfront.net/dist/img/arrow-select.svg') no-repeat 90% 50%;
			#services {
				padding-left: 45px;
			}
			select {
				padding: 15px;
				width: 110%;
				border: none;
				box-shadow: none;
				background-color: transparent;
				background-image: none;
				-webkit-appearance: none;
				-moz-appearance: none;
				appearance: none;
			}
			select:focus {
				outline: none;
			}
		}
		@media only screen and (max-width: 768px) {
			display: none;
		}
	}
	.header-line {
		background: $gojek;
		height: 50px;
		margin-bottom: 70px;
		.media-nav {
			width: 30% !important;
			display: block !important;

			@media only screen and (max-width: 768px) {
				width: 80% !important;
			}
		}
		.media-nav ul li {
			width: 100%;
			text-align: center;

			& > a {
				pointer-events: none;
			}
		}
		.blog-nav {
			background-color: #fcfcfe;
			box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.13);
			width: 981px;
			margin: 0 auto;
			position: relative;
			width: 980px;
			top: 15px;
		}
		.cat-list {
			list-style: none;
			padding: 0;
			margin: 0;
			li::first-child {
				border-left: 0 !important;
			}
			li {
				display: inline-block;
				padding: 10px 50px;
				margin: 20px 0;
				border-left: 1px solid #e8ebf2;
				width: 240px;
				a {
					color: #000;
					display: block;
					text-align: center;
				}
				a:hover {
					color: #48af4a;
				}
			}
		}
	}
	.special--banner {
		figure {
			margin: 0;
			position: relative;
			img {
				width: 100%;
			}
			.overlay {
				position: absolute;
				width: 100%;
				height: 100%;
				background: #000;
				opacity: 0.6;
				top: 0;
				padding: 25px;
			}
			.text--wrapper {
				position: absolute;
				bottom: 0;
				padding: 30px;
				z-index: 5;
				h4 {
					font-size: 24px;
					font-weight: 900;
					line-height: 1.42;
					letter-spacing: 2.2px;
					color: #ffffff;
				}
				.read--more a {
					font-family: 'Open Sans', sans-serif;
					font-size: 18px;
					font-weight: bold;
					line-height: 0.8;
					letter-spacing: 3.3px;
					color: #ffffff;
					text-transform: uppercase;
				}
				.read--more a:after {
					content: url(https://d3naj63yelf4gk.cloudfront.net/dist/img/header/arrow.png);
					position: relative;
					left: 10px;
				}
			}
			img.brush {
				position: absolute;
				bottom: 0;
			}
		}
		@media only screen and (max-width: 767px) {
			display:none;
		}
	}
}

@media (max-width: 768px) {
	.blog-list {
		.post-item {
			padding: 30px 15px;
		}
		.header-line {
			.blog-nav {
				display: none;
			}
		}
		#menu--list {
			display: block;
		}
	}
}
