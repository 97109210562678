/* rules
1. One card one section tag
2. Media query must nesting in class [no media query on bottom -> css style]
3. No duplicate this class on any other scss file
*/
@import "../../base/variables";
@import "../animations";

/* ---- setion (parent cards) ----- */
section.new-about
{
	font-family: $opensans;
	.container-vertical
	{
		> div
		{
			text-align: left;
			&:first-child
			{
				padding-right:80px;
				@media only screen and (max-width: 767px) {
					padding-right: 0px;
				}
			}
			&.top
			{
				position: relative;
				.container-image
				{
					position: relative;
					.container-video
					{
						position: absolute;
						height: 100%;
						width: 100%;
						background: #000;
						opacity: 0;
						pointer-events: none;
						left: 0;
						transition:all 0.5s;
						bottom: 0;
						&.active
						{
							transition: all 0.5s;
							opacity: 1;
							pointer-events: visible;
						}
						iframe
						{
							width: 100%;
							height: 100%;
						}
						@media only screen and (max-width: 767px) {
							height:calc(100% - 30px);
						}
					}
					.play
					{
						cursor: pointer;
						width:60px;
						height: 60px;
						background: #ffcd2e;
						position: absolute;
						top:0;
						left: -30px;
						border-radius: 50%;
						bottom: 0;
						margin: auto;
						border-radius: 50%;
						transition: all 0.5s;
						&.disactive
						{
							pointer-events: none;
							transition: all 0.5s;
							transform: scale(0) !important;
							+ .close
							{
								transform: scale(1);
								transition:all 0.5s;
							}
						}
						&:hover
						{
							transition:all 0.5s;
							transform: scale(1.2);
						}
						&:after
						{
							transition: all 0.5s;
							position:absolute;
							top: 0;
							bottom: 0;
							right: 0;
							left: 0;
							margin: auto;
							width: 0;
							height: 0;
							border-top: 10px solid transparent;
							border-left: 20px solid #fff;
							border-bottom: 10px solid transparent;
							content: "";
						}
						@media only screen and (max-width: 767px) {
							left:0;
							right:0;
							top:20px;
							width:50px;
							height:50px;
						}
					}
					.close
					{
						cursor: pointer;
						width:60px;
						height: 60px;
						background: red;
						position: absolute;
						top:0;
						right: 0;
						margin: -30px;
						border-radius: 50%;
						bottom: 0;
						border-radius: 50%;
						transition: all 0.5s;
						transform: scale(0);
						z-index: 2;
						line-height: 60px;
					    text-align: center;
					    font-size: 30px;
					    color: #fff;
						@media only screen and (max-width: 767px) {
							width: 40px;
							height: 40px;
							margin: 0;
							right: -20px;
							top: 10px;
							font-size: 20px;
							line-height: 40px;
						}
					}
				}
			}
			.sub-title
			{
				margin:0px;
				color: $gojek;
				letter-spacing: 1px;
				font-size: 18px;
				font-weight: 700;
				@media only screen and (max-width: 767px) {
					font-size: 12px;
					position: absolute;
					top: 0;
					padding-left: 10px;
					&:after
					{
						content:"";
						position: absolute;
						left: -30px;
						margin: auto;
						top: 0;
						bottom: 0;
						height: 3px;
						width: 30px;
						background: $gojek;
					}
				}
			}
			h3.title
			{
				text-transform: uppercase;
				color: $charcoal;
				font-weight: 300;
				margin: 20px 0;
				@media only screen and (max-width: 767px) {
					margin-top: 15px;
					margin-bottom: 10px;
				}
			}
			p
			{
				color: $gunmetal;
				margin:0px;
				font-size: 14px;
				@media only screen and (max-width: 767px) {
					font-size: 12px;
				}
			}
		}
		img.about
		{
			width:100%;
			@media only screen and (max-width: 767px) {
				padding-top: 30px;
			}
		}
	}
}
section.new-our-values
{
	font-family: $opensans;
	background-color: #fff;
	.container-slide-item
	{
		font-size: 0px;
		position: relative;
		@media only screen and (max-width: 767px) {
			padding-bottom: 10px;
		}
		> div
		{
			outline: none;
			padding:0 30px;
			font-size: 14px;
			@media only screen and (max-width: 767px) {
				padding: 0px;
				margin-bottom: 60px;
			}
			/* on desktop if more than 3 items */
			@media only screen and (min-width: 768px) {
				&:nth-child(3) ~ div
				{
					margin-top: 60px;
				}
			}
			img
			{
				max-height: 148px;
				margin-bottom: 30px;
				@media only screen and (max-width: 768px) {
					max-height: 120px;
					display: inline-block;
				}
				@media only screen and (max-width: 767px) {
					margin-bottom:20px;
				}
			}
			h5
			{
				margin: 0px;
				color: $gojek;
				&:after
				{
					display: block;
				    content: "";
				    position: relative;
				    height: 2px;
				    width: 25px;
				    background-color: $gojek;
				    margin: 15px auto;
				}
			}
			a
			{
				color: $charcoal;
				font-size: 14px;
				letter-spacing: 1.6px;
				display: inline-block;
				position: relative;
				font-weight: 700;
				&:after
				{
					top: 1px;
				    position: relative;
				    margin-left: 5px;
				    content: "→";
				    font-size: 20px;
				    transition: all 0.5s;
				}
				@media only screen and (min-width: 769px) {
					font-size:16px;
					font-weight:300;
					&:hover
					{
						&:after
						{
							transition:all 0.5s;
							transform: translateX(100px);
						}
					}
				}
			}
			p
			{
				color: $warmgrey;
				font-size: 14px;
				margin: 0px;
				@media only screen and (max-width: 767px) {
						background-color: transparent;
						margin: 0 auto;
						max-width: 250px;
				}
			}
		}
		.new-float-arrow
		{
			@media only screen and (max-width: 767px) {
				svg
				{
					transition: all 0.5s;
					ellipse
					{
						stroke:$gojek !important;
					}
					polygon
					{
						fill: $gojek !important;
					}
				}
				&.slick-disabled
				{
					svg
					{
						
						ellipse
						{
							stroke:#ececec !important;
						}
						polygon
						{
							fill:#ececec !important;
						}
					}
				}
			}
		}
		.normal-dots
		{
			@media only screen and (max-width: 767px) {
				bottom: 15px;
				> li
				{
					border:1px solid $gojek;
					&.slick-active
					{
						background-color: $gojek;
					}
				}
			}
		}
		
	}
}
section.new-option
{
	font-family: $opensans;
	.info
	{
		color: $gunmetal;
		display: inline-block;
		max-width: 520px;
		margin: 0px;
		@media only screen and (max-width: 767px) {
			padding: 0 20px;
			max-width: 300px;
			margin: 0px auto;
		}
	}
	.content
	{
		margin-top: 30px;
		font-size: 0px;
		> div
		{
			padding: 0 20px;
			font-size: 14px;
			@media only screen and (max-width: 767px) {
				margin-bottom: 30px;
				padding: 0px;
				&:last-child
				{
					margin-bottom:0px;
				}
			}
			h4
			{
				color: $charcoal;
				font-weight: 100;
				margin:30px 0px 10px 0px;
			}
			p
			{
				color: $gunmetal;
				padding: 0 20px;
				max-width: 300px;
				margin: 0px auto;
			}
			img
			{
				width:100%;
			}
		}
	}
	button
	{
		font-size: 14px;
		padding: 30px 30px;
		margin-top: 30px;
		background-color: $gojek;
		border-radius: 5px;
		color: #fff;
		&:after
		{
			top: 1px;
		    position: relative;
		    margin-left: 5px;
		    content: "→";
		    font-size: 20px;
		    transition: all 0.5s;
		}
	}
}
section.new-more-features
{
	.container-all
	{
		max-width: 960px !important;
		padding:30px 0px 60px 0px !important;
		position: relative;
		@media only screen and (max-width: 767px) {
			padding: 60px 0 !important;
			top: auto;
		}
		&:after
		{
			content:"";
			position:absolute;
			height: 100%;
			width: 80%;
			background-color: #fff;
			top:0;
			left: 0;
			right: 0;
			margin: auto;
			@media only screen and (max-width: 767px) {
				width: 100%;
				height: calc(100% - 60px);
				bottom: 0;
			}
		}
		&:before
		{
			background-size: 650px;
			background-repeat: repeat;
			content:"";
			position:absolute;
			height: 100%;
			width: 100%;
			background-image: url(https://d3naj63yelf4gk.cloudfront.net/dist/img/faq/geometry.svg);
			top:0;
			left: 0;
			right: 0;
			margin: auto;
		}
		.container-content
		{
			position: relative;
			z-index: 1;
			.title-underscore
			{
				@media only screen and (max-width: 767px) {
					max-width: 200px;
					margin: auto !important;
				}
			}
			.container-image-slide
			{
				min-height: 200px;
				background-color: $gojek;
				position: relative;
				img
				{
					width: 100%;
				}
			}
			.container-text-slide
			{
				top: -20px;
				position: relative;
				color: #fff;
				width: 85%;
				max-width: 500px;
				margin: auto;
				@media only screen and (max-width: 767px) {
					padding: 20px 0;
					top: auto;
				}
				h3
				{
					margin: 0px;
					margin-bottom: 20px;
				}
				p
				{
					margin: 0px;
					max-width: 400px;
					margin: auto;
					@media only screen and (max-width: 767px) {
						max-width: 300px;
					}
				}
				a
				{
					font-size: 14px;
					display: inline-block;
					font-weight: 700;
					letter-spacing: 1.5px;
					position: relative;
					color: #fff;
					margin-top: 20px;
					&:after
					{
						font-size: 18px;
					    position: relative;
					    content: "→";
					    top: 1px;
					    margin-left: 5px;
					}
				}
			}
			.container-vertical
			{
				background-color: $gojek;
				@media only screen and (max-width: 767px) {
					padding-bottom: 100px;
				}
			
			}
		}
	}
}
section.new-slider-services
{
	.container-all
	{
		@media only screen and (max-width: 767px) {
			background-color: #fff;
			padding-bottom: 100px !important;
		}
	}
	.title
	{
		text-align: left;
		padding: 26px 0;
		padding-left: 50px;
		position: relative;
		font-weight: 700;
		color: $gojek;
		background-color: #fff;
		h5
		{
			margin: 0px;
		}
		.arrow-slider
		{
			position: absolute;
			height: 40px;
			width: 100px;
			right: 20px;
			top: 0;
			bottom: 0;
			margin:auto;
			> div
			{
				cursor: pointer;
				width: 50px;
				&:not(.slick-arrow)
				{
					display: none;
				}
				&.slick-disabled
				{
					filter: grayscale(1) brightness(1.4);
				}
				&.left
				{
					float: left;
				}
				&.right
				{
					float: right;
				}
				svg
				{
					height: 40px;
					ellipse
					{
						stroke:$gojek;
					}
					polygon
					{
						fill: $gojek;
					}
				}
			}
		}
		&:before
		{
			background-color: $gojek;
			position:absolute;
			height: 4px;
			width: 30px;
			top: 0;
			bottom: 0;
			left: -15px;
			margin: auto;
			content: "";
		}
		@media only screen and (max-width: 767px) {
			padding-left:30px;
		}
	}
	.container-slider-services
	{
		position: relative;
		width: 100%;
		&.more
		{
			width: 130%;
			@media only screen and (max-width: 767px) {
				width: 100%;
			}
		}
		&:before
		{
			content:"";
			position: absolute;
			height: 60%;
			top: 0;
			left: 0;
			background-color: $gojek;
			width: 100%;
		}
		.slider-services
		{
			margin-left: 40px;
			margin-top: 60px;
			display: inline-block;
			width: calc(100% - 60px);
			@media only screen and (max-width: 768px) {
				width: 100%;
			}
			@media only screen and (max-width: 767px) {
				margin-left:0px;
			}
			div.slick-slide
			{
				outline: none;
				min-height: 420px;
				padding: 50px 0;
				background: #fff;
				box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.11);
				width: 270px;
				margin-right: 25px;
				margin-bottom: 10px;
				@media only screen and (max-width: 767px) {
					margin-right: auto;
					width: auto;
					margin: 0 10px;
					min-height: 370px;
					margin-bottom: 10px !important;
				}
				@media only screen and (max-width: 321px) {
					margin:0 5px;
				}
			}
			.content-slider
			{
				position: relative;
				img
				{
					max-height: 65px;
					margin: auto;
				}
				h5
				{
					color: $gojek;
					margin: 30px 0px 15px 0px;
					padding: 0 30px;
				}
				p
				{
					color: #4a4a4a;
					max-width: 240px;
					margin: auto;
					padding: 0 20px;
				}
				&.full-image
				{
					padding-top: 0px;
					img
					{
						max-height: none;
						width: 100%;
					}
				}
			}
			.link
			{
				position: absolute;
				bottom: 30px;
				left: 0;
				right: 0;
				margin: auto;
				margin-top: 15px;
				a.arrow
				{
					font-size: 14px;
					font-weight: 700;
					letter-spacing: 1.5px;
					position: relative;
					color: #093c5b;
					&:after
					{
						font-size: 18px;
					    position: relative;
					    content: "→";
					    top: 1px;
					    margin-left: 5px;
					}
				}
			}
			.new-float-arrow
			{
				@media only screen and (max-width: 767px) {
					&.arrow-right
					{
						right: 30px !important;
					}
					&.arrow-left
					{
						left: 30px;
					}
					svg
					{
						transition: all 0.5s;
						ellipse
						{
							stroke:$gojek !important;
						}
						polygon
						{
							fill: $gojek !important;
						}
					}
					&.slick-disabled
					{
						svg
						{
							
							ellipse
							{
								stroke:#ececec !important;
							}
							polygon
							{
								fill:#ececec !important;
							}
						}
					}
				}
			}
		}
	}
}
section.new-join-us
{
	.container-all
	{
		margin-bottom: 30px;
	}
	.container-vertical
	{
		position: relative;
		background-color: $charcoal;
	}
	img
	{
		float: left;
		width: 100%;
	}
	.content-join-us
	{
		text-align: left;
		color: #fff;
		padding: 50px 30px;
		@media only screen and (max-width: 768px) {
			padding:30px 30px;
			padding-bottom:50px;
		}
		h6
		{
			font-size: 12px;
			color: #b3b9c7;	
			margin:0px;
		}
		h3
		{
			margin: 10px 0;
		}
		p
		{
			margin: 0px;
			@media only screen and (max-width: 768px) {
				margin-bottom: 15px;
			}
		}
	}
	.float-button
	{
		position: absolute;
		right: 30px;
		bottom: -30px;
		font-size: 14px;
		padding: 30px 30px;
		color: #fff;
		background-color: $gojek;
		border-radius: 5px;
		@media only screen and (max-width: 768px) {
			right: 0;
			left: 0;
			margin: auto;
			width: 260px;
		}
	}
}
section.new-more-information
{
	ol.list
	{
		font-family: $opensans;
		font-size: 14px;
		line-height: 24px;
		font-weight: 100;
		text-align: left;
		margin: 0px;
		letter-spacing: 1.5px;
		@media only screen and (max-width: 767px) {
			padding-right: 20px;
		}
	}
	.sub-container
	{
		display: inline-block;
		max-width: 700px;
	}
	.container-all
	{
		padding-bottom: 30px !important;
	}
	.content
	{
		position: relative;
		margin: 40px;
		margin-bottom: 0px;
		background-color: #fff;
		padding: 50px 0;
		@media only screen and (max-width: 768px) {
			margin: 30px;
		}
		&:before
		{
			z-index: -1;
		    position: absolute;
		    width: calc(100% + 80px);
		    height: 100%;
		    background: $charcoal;
		    left: -40px;
		    top: -40px;
		    content: "";
		    @media only screen and (max-width: 768px) {
		    	width: calc(100% + 60px);
		    	left: -30px;
		    	top: -30px;
		    }
		}
		p
		{
			margin: auto;
			margin-bottom: 30px;
		}
		.container-button
		{
			margin-bottom: -80px;
			width: 100%;
			text-align: center;
			button
			{
				border-radius: 5px;
				color: #fff;
				width: 210px;
				padding: 30px 0;
				&.left
				{
					background: $gojek;
					margin-right: 10px;
					@media only screen and (max-width: 767px) {
						margin-right: 0px;
						margin-bottom: 10px;
					}
				}
				&.right
				{
					background-color: $charcoal;
				}
			}
		}
	}
}
section.new-how-to-use-promo
{
	.content
	{
		padding: 40px 0;
		background-color: #fff;
		position: relative;
		&:after
		{
			position:absolute;
			height: 100%;
			width: 70px;
			background-image: url(https://d3naj63yelf4gk.cloudfront.net/dist/img/faq/geometry.svg);
			background-size: 650px;
    		background-repeat: repeat;
			left: 0;
			top: 0;
			content: "";
			@media only screen and (max-width: 767px) {
				width: 100%;
				height: 30px;
			}
		}
		&:before
		{
			position:absolute;
			height: 100%;
			width: 70px;
			background-image: url(https://d3naj63yelf4gk.cloudfront.net/dist/img/faq/geometry.svg);
			background-size: 650px;
    		background-repeat: repeat;
			right: 0;
			bottom: 0;
			content: "";
			@media only screen and (max-width: 767px) {
				width: 100%;
				height: 30px;
			}
		}
		p
		{
			font-family: $opensans;
			width: 75%;
			max-width: 700px;
			display: inline-block;
			margin: 0px;
			font-size: 14px;
			@media only screen and (max-width: 767px) {
				width: 100%;
				padding: 0 30px;
			}
		}
		.container-list
		{
			text-align: center;
			margin: 30px 0;
			padding: 30px 0;
			background-color: $gojek;
			ol
			{
				letter-spacing: 1.5px;
				width: 75%;
				display: inline-block;
				max-width: 700px;
				font-family: $opensans;
				font-size: 14px;
				line-height: 24px;
				font-weight: 100;
				text-align: left;
				padding-left: 20px;
				margin: 0px;
				color: #fff;
				@media only screen and (max-width: 767px) {
					width: 100%;
					padding: 0 30px;
					padding-left: 50px;
				}
			}
		}
		@media only screen and (max-width: 767px) {
			padding:60px 0;
		}
	}
}


/* --- exception ------ */
section.gopay-services
{
	.container-service
	{
		font-size: 0px;
		> div
		{
			display: inline-block;
			font-size: 14px;
			&.extra
			{
				@media only screen and (min-width: 769px) {
				&:after
					{
						z-index: -1;
					    position: absolute;
					    height: 100%;
					    width: calc(50% - 50px);
					    background-color: white;
					    left: 30px;
					    top: 0;
					    content: "";
					}
				}
			}
			&:first-child
			{
				padding-right:20px;
				@media only screen and (max-width: 768px) {
					padding-right: 0px;
				}
			}
			&:last-child
			{
				padding-left: 20px;
				@media only screen and (max-width: 768px) {
					padding-left: 0px;
					margin-top: 60px;
				}
				@media only screen and (max-width: 767px) {
					margin-top:0px;
				}
			}
		}
	}
}

/* ----- div (child cards) ------ */
div.new-services
{
	background: #fff;
	padding-bottom: 100px;
	position: relative;
	padding-top: 30px;
	@media only screen and (max-width: 767px) {
		background: transparent;
		padding-top: 0px;
		padding-bottom: 30px;
	}
	&:after
	{
		position:absolute;
		height: 60%;
		width: 100%;
		bottom: 50px;
		content: "";
		left: 0;
		background-image: url(https://d3naj63yelf4gk.cloudfront.net/dist/img/faq/geometry.svg);
		background-size: 650px;
		@media only screen and (max-width: 767px) {
			display: none;
		}
	}
	.container-content
	{
		position: relative;
		z-index: 1;
		font-size: 0px;
		width:100%;
		> div
		{
			position: relative;
			font-size: 14px;
			background: #fff;
			padding: 40px 25px;
			border:1px solid #ececec;
			box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.15);

			&.center
			{
				border: none;
    			box-shadow: none;
    			background: transparent;
				position: absolute;
				height: 100%;
				width: 45px;
				left: 0;
				right: 0;
				top: 0;
				margin: auto;
				.container-content
				{
					position: absolute;
					left: 0;
					top: 82px;
					text-align: center;
					> div
					{
						margin-bottom: 10px;
						display: inline-block;
						padding:0px;
						height: 40px;
						width: 40px;
						background: #fff;
						box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.15);
						border-radius: 50%;
						&:after
						{
							display:inline-block;
							height: 100%;
							vertical-align: middle;
							content: "";
						}
						img
						{
							display: inline-block;
							vertical-align: middle;
							max-width: 20px;
						}
					}
				}
				@media only screen and (max-width: 767px) {
					display:none;
				}
			}
			&.left
			{
				left: -10px;
				@media only screen and (max-width: 767px) {
					left: auto;
					margin-bottom: 10px;
					border-radius: 5px;
					box-shadow: none;
				}
			}
			&.right
			{
				right: -10px;
				@media only screen and (max-width: 767px) {
					right: auto;
					box-shadow: none;
					border-radius: 5px;
				}
			}
			h6
			{
				text-transform: uppercase;
				margin: 0px;
				margin-bottom: 30px;
			}
			> div
			{
				margin-bottom: 20px;
				color: $gojek;
				&.disabled
				{
					color: #dbdbdb;
					img
					{
						filter:grayscale(1) brightness(1.4);
					}
				}
				&:last-child
				{
					margin-bottom:0px;
				}
			}
		}
		.container-vertical
		{
			.icon
			{
				img
				{
					display: none;
					max-width: 30px;
					@media only screen and (max-width: 767px) {
						display: block;
					}
				}
				@media only screen and (max-width: 767px) {
					width:40px;
				}
			}
			.content
			{
				@media only screen and (max-width: 767px) {
					width: calc(100% - 40px);
					text-align: left;
				}
			}
		}
	}
}

div.new-phone-slide
{
	display: inline-block;
	width: 100%;
	padding-top: 30px;
	position: relative;
	&:after
	{
		content:"";
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		background: #fff;
		z-index: -1;
	}
	@media only screen and (min-width: 768px) {
		margin-bottom: 65px;
		&:before
		{
			content: "";
			position: absolute;
			height: 50%;
			bottom: 0;
			width: 100%;
			left: 0;
			background-image: url(https://d3naj63yelf4gk.cloudfront.net/dist/img/faq/geometry.svg);
	    	background-size: 650px;
		}
	}
	.info
	{
		color: $warmgrey;
		max-width: 400px;
		padding:0 20px;
		margin:auto;
	}
	.container-slide-phone
	{
		position: relative;
		width: 85%;
		margin: auto;
		.container-phone
		{
			margin-top: 30px;
    		padding-top: 30px;
    		@media only screen and (max-width: 767px) {
    			margin-top: 15px;
    		}
    		&.stick-top
    		{
    			padding-top: 0px;
    			margin-top: 0px;
    		}
			.background-phone
			{
				position: absolute;
				width: 100%;
				top: 0;
				height: 100%;
				img
				{
					width: 220px;
					position: relative;
					top: -6px;
					@media only screen and (max-width: 767px) {
						top: 25px;
					}
				}
			}
			/* for phone slider */
			.phone-slider
			{
				max-width: 190px;
				position: relative;
				height: 216px;
				background-color: #dbdbdb;
				width: 100%;
				margin: auto;
				margin-top: 20px;
				&:not(.slick-initialized)
				{
					height: 200px !important;
				}
			}

			/* for image gopay */
			.image-slider
			{
				max-width: 300px;
				position: relative;
				min-height: 200px;
				background-color: #dbdbdb;
				width: 100%;
				margin: auto;
				margin-top: 20px;
				&:not(.slick-initialized)
				{
					height: 200px !important;
				}
				.image
				{
					img
					{
						width: 100%;
					}
				}
			}
		}
		.container-slide-text
		{
			position: relative;
			background-color: $gojek;
			padding:20px 0px;
			margin-bottom: -65px;
			em
			{
				font-weight: 100;
			    letter-spacing: 1.6px;
			    color: #fff;
			    max-width: 250px;
			    display: inline-block;
			    font-size: 12px;
			    line-height: 15px;
			}
			.text-slider
			{
				width: calc(100% - 60px);
				margin: auto;
				color: #fff;
				&:not(.slick-initialized)
				{
					height: 100px !important;
				}
				.number
				{
					font-size: 24px;
				}
				p
				{
					font-size: 12px;
					padding: 0 50px;
					@media only screen and (max-width: 767px) {
						padding: 0px;
					}
				}
				.normal-dots
				{
					@media only screen and (max-width: 767px) {
						bottom: -75px !important;
					}
				}
				.new-float-arrow
				{
					bottom: 0;
					@media only screen and (max-width: 767px) {
						bottom: -85px !important;
					}
				}
			}
			@media only screen and (max-width: 767px) {
				padding-bottom:70px;
				margin-bottom:0px;
			}

		}
		@media only screen and (max-width: 767px) {
			width:100%;
			padding-top:15px;
			margin-top:15px;
			&:before
			{
				content: "";
				position: absolute;
				height: 100%;
				bottom: 0;
				width: 100%;
				left: 0;
				background-image: url(https://d3naj63yelf4gk.cloudfront.net/dist/img/faq/geometry.svg);
		    	background-size: 650px;
			}
		}
	}
}

/* ---- other ---- */
div.new-floating-help
{
	width: 90%;
	max-width: 400px;
	z-index: 10;
	position: fixed;
	bottom: 0;
	right: 0;
	margin:25px;
	font-family: $opensans;
	transition:all 0.5s;
	transform: translateY(calc(100% - 30px));
	@media only screen and (max-width: 767px) {
		margin: 25px auto;
		left: 0;
	}
	.view-desktop
	{
		@media only screen and (max-width: 767px) {
			display: none;
		}
	}
	button.trigger
	{
		box-shadow: 0 0 4px 2px rgba(0, 0, 0, 0.3);
		z-index: 10;
		outline: none;
		transition:all 0.5s;
		position: relative;
		overflow: hidden;
		height: 70px;
		position: absolute;
		right: 0;
		top:-35px;
		cursor: pointer;
		border-radius: 5px;
		font-size: 16px;
		letter-spacing: 1.5px;
		padding: 0px;
		width: 220px;
		background-color: $sunyellow;
		color: $gojek;
		.open
		{
			transition:all 0.3s;
		}
		.close
		{
			transition:all 0.3s;
			position: absolute;
			height: 22px;
			bottom:0;
			left:0;
			text-align: center;
			right: 0;
			left: 0;
			top: -10px;
			font-size: 25px;
			margin: auto;
			transform:translateY(100px);
		}
	}
	.container-content
	{
		box-shadow: 0 0 4px 2px rgba(0, 0, 0, 0.3);
		transition:all 0.5s;
		opacity: 0;
		pointer-events:none;
		border:2px solid $gojek;
		border-radius: 5px;
		background-color: #ececec;
		.header
		{
			font-size: 16px;
			text-align: center;
			background-color: $gojek;
			width:100%;
			padding: 10px 0;
			font-weight: 500;
			color: #fff;
			letter-spacing: 1.5px;
		}
		a
		{
			font-size: inherit;
			color: inherit;
		}
		button.link
		{
			border-radius: 5px;
			width: 100%;
			padding: 15px 0;
			letter-spacing: 1.5px;
			color: #fff;
			font-weight: 500;
		}
		.content
		{
			padding: 10px;
			.item
			{
				padding: 10px;
				background: #fafafa;
				display: table;
				font-weight: 100;
				letter-spacing: normal;
				width: 100%;
				font-size: 0px;
				margin-bottom: 10px;
				border-radius: 5px;
				&.prominent
				{
					background-color: $gopay;
					color: #fff;
					transition:all 0.5s;
					&:hover
					{
						background-color: #4490c7;
						transition:all 0.5s;
					}
				}
				> div
				{
					&:first-child
					{
						width: 30px;
					}
					img
					{
						margin-right: 10px;
					}
					display: table-cell;
					vertical-align: middle;
					font-size: 14px;
					&:after
					{
						display:inline-block;
						vertical-align: middle;
						content: "";
						position: absolute;
						top: 0;
					}
				}
			}
		}
	}
	&.active
	{
		transition:all 0.5s;
		transform:translateY(0px);
		.container-content
		{
			transition:all 0.5s;
			opacity: 1;
			pointer-events:visible;
		}
		button.trigger
		{
			box-shadow: 0 0 4px 2px rgba(0, 0, 0, 0.0);
			border-radius: 50%;
			transition:width 0.3s 0s, border-radius 0.3s 0.2s, transform 0.3s 0.2s;
			width:70px;
			transform:scale(0.7);
			.open
			{
				transform:translateX(-100px);
				transition:all 0.3s;
			}
			.close
			{
				transform:translateY(0px);
				transition:all 0.3s;
			}
		}
	}
}