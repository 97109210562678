body {
	background: #f8f8f8;
}

#onboarding-head .title {
	background-color: #fcfcfe !important;
}

#onboarding-head .bg-img {
	background: -webkit-linear-gradient(rgba(88, 199, 91, .6), rgba(88, 199, 91, .8)), url(https://d3naj63yelf4gk.cloudfront.net/dist/img/blog/head.jpg) no-repeat !important;
	background: -moz-linear-gradient(rgba(88, 199, 91, .6), rgba(88, 199, 91, .8)), url(https://d3naj63yelf4gk.cloudfront.net/dist/img/blog/head.jpg) no-repeat !important;
	background: -o-linear-gradient(rgba(88, 199, 91, .6), rgba(88, 199, 91, .8)), url(https://d3naj63yelf4gk.cloudfront.net/dist/img/blog/head.jpg) no-repeat !important;
	background: linear-gradient(rgba(88, 199, 91, .6), rgba(88, 199, 91, .8)), url(https://d3naj63yelf4gk.cloudfront.net/dist/img/blog/head.jpg) no-repeat !important;
	background-size: cover !important;
}

#content-nav,
#faq-head,
#onboarding-head {
	margin-top: 5.4em;
	.title {
		background: url('https://d3naj63yelf4gk.cloudfront.net/dist/img/faq/geometry.svg') repeat;
		background-size: cover;
		.content {
			margin: 5em 5em 8em 5em;
			box-shadow: -1em 1em #48af4a;
			background: white;
			.text {
				padding: 1.8em;
				h2 {
					font-family: $lato;
					font-weight: 700;
					letter-spacing: 1.5px;
					line-height: 1.54;
				}
				p {
					font-family: $opensans;
					letter-spacing: 1px;
				}
			}
		}
	}
	.bg-img {
		background: -webkit-linear-gradient(rgba(88, 199, 91, .6), rgba(88, 199, 91, .8)), url(https://d3naj63yelf4gk.cloudfront.net/dist/img/faq/head.jpg) no-repeat;
		background: -moz-linear-gradient(rgba(88, 199, 91, .6), rgba(88, 199, 91, .8)), url(https://d3naj63yelf4gk.cloudfront.net/dist/img/faq/head.jpg) no-repeat;
		background: -o-linear-gradient(rgba(88, 199, 91, .6), rgba(88, 199, 91, .8)), url(https://d3naj63yelf4gk.cloudfront.net/dist/img/faq/head.jpg) no-repeat;
		background: linear-gradient(rgba(88, 199, 91, .6), rgba(88, 199, 91, .8)), url(https://d3naj63yelf4gk.cloudfront.net/dist/img/faq/head.jpg) no-repeat;
		background-size: cover;
	}
	.faq-nav,
	.onboarding-nav {
		background-color: #fcfcfe;
		box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.13);
		margin: -50px auto 0;
		position: relative;
		width: 980px;
		top: 15px;
	}
	.cat-list {
		list-style: none;
		padding: 0;
		margin: 0;
		li:first-child {
			border-left-width: 0px;
		}
		li {
			display: inline-block;
			padding: 10px 0;
			margin: 20px 0;
			border-left: 1px solid #e8ebf2;
			width: 240px;
			a {
				color: #000;
				display: block;
				text-align: center;
			}
			a:hover {
				color: #48af4a;
			}
			.active {
				font-weight: bold;
				color: #48af4a !important;
			}
		}
	}
	#faq-menu--list,
	.select--wrapper {
		padding: 0;
		margin: 0 auto;
		border: 0;
		width: 300px;
		border-radius: 0;
		overflow: hidden;
		background: #fff;
		position: relative;
		top: 25px;
		display: none;
		box-shadow: 0 3px 6px 0 rgba(0, 0, 0, .13);
		.bg--select {
			width: 50px;
			height: 100%;
			background: #48af4a url('https://d3naj63yelf4gk.cloudfront.net/dist/img/select-more.svg') no-repeat 55% 50%;
			position: absolute;
			right: 0;
			z-index: 1;
			background-size: 13px;
		}
		select {
			padding: 15px;
			width: 105%;
			border: none;
			box-shadow: none;
			background-color: transparent;
			background-image: none;
			-webkit-appearance: none;
			-moz-appearance: none;
			appearance: none;
			position: relative;
			z-index: 5;
		}
		select:focus {
			outline: none;
		}
	}
}

@media screen and (max-width:35.5em) {
	#onboarding-head {
		background: -webkit-linear-gradient(rgba(88, 199, 91, .6), rgba(88, 199, 91, .8)), url(https://d3naj63yelf4gk.cloudfront.net/dist/img/blog/head.jpg) no-repeat !important;
		background: -moz-linear-gradient(rgba(88, 199, 91, .6), rgba(88, 199, 91, .8)), url(https://d3naj63yelf4gk.cloudfront.net/dist/img/blog/head.jpg) no-repeat !important;
		background: -o-linear-gradient(rgba(88, 199, 91, .6), rgba(88, 199, 91, .8)), url(https://d3naj63yelf4gk.cloudfront.net/dist/img/blog/head.jpg) no-repeat !important;
		background: linear-gradient(rgba(88, 199, 91, .6), rgba(88, 199, 91, .8)), url(https://d3naj63yelf4gk.cloudfront.net/dist/img/blog/head.jpg) no-repeat !important;
		background-size: cover !important;
	}
	#faq-head,
	#onboarding-head {
		background: linear-gradient(rgba(88, 199, 91, .6), rgba(88, 199, 91, .8)), url(https://d3naj63yelf4gk.cloudfront.net/dist/img/faq/head.jpg) no-repeat;
		background-size: cover;
		margin-bottom: 2em;
		#faq-menu--list,
		#menu--list {
			display: block;
		}
		.faq-nav,
		.onboarding-nav {
			display: none;
		}
		.title {
			background: none !important;
			.content {
				box-shadow: none;
				background: none;
				color: white;
				margin: 5em 0;
			}
		}
	}
}

@media screen and (max-width:48em) {
	#faq-head,
	#onboarding-head {
		#faq-menu--list,
		#menu--list {
			display: block;
			margin-top: -3em;
		}
		.faq-nav,
		.onboarding-nav {
			display: none;
		}
	}
}
