#gosend-larismanis  {
	.navbar {
		position: fixed !important;
    min-height: 30px !important;
    margin-bottom: 0px !important;
    border: 0px solid transparent !important;
	}
	header#main--hero.larismanis {
		background-image: url('https://d3naj63yelf4gk.cloudfront.net/dist/img/page/gosend/larismanis/header-new.jpg?ver=12');
	}
	.navbar .btn.btn-style-1 {
		height: 34.25px !important;
	}
	.link {
		color: $gojek !important;
    text-decoration: none!important;
    font-weight: 600;
    letter-spacing: 1px;
		cursor: pointer;
    font-size: 12.75px;
	}

	#gojek-brand-desktop {
		display: inline-block !important;
	}

	#gojek-brand-mobile {
		display: none !important;
	}

	.s-and-k-section-mobile {
		display: none;
	}

	.s-dan-k {
		position: relative;
		left: 80px;
		font-weight: 300;
		margin-top: -16px;
	}

	.s-dan-k.small {
		display: none;
	}

	.s-dan-k-checkbox {
		position: relative;
		left: 50px;
	}

	ul.icon-wrapper {
		list-style-type: none;
		margin: 0;
		position: relative;
		top: 320px;
		left: -15px;

		li {
			margin-bottom: 25px;

			img.gosend-icon {
				max-width: 50px;
			}
			img.go-icon {
				min-width: 45px !important;
			}
		}
	}

	#join-now {
		a {
			background: $gojek;
		}
		.bg-layer {
			position: relative;
			background-color: $gojek;
		}
	}

	#content-only.thank-you {
		margin-top: 100px;

		.container {
	    background: white;
	    box-shadow: 0 -3em 0 1.5em #343b41;
		}
	}

	#promo-banner .detail {
		top: 160px;
	}

	#promo-banner .detail .custom-nav .slick-disabled {
		ellipse {
			stroke: $gojek !important;
		}
		polygon {
			fill: $gojek !important;
		}

		.img-slide {
			img {
				margin-top: 5px;
			}
		}

	}

	.as-seen-on  {
		#content img {
	    width: 100%;
			max-height: 100%;
	    width: auto;
	    padding: 2em;
			filter: grayscale(0%) !important;
			opacity: 1.0;
		}
	}

	.about {
		.weekly-promo-title,
		.description-title {
			object-fit: contain;
		  font-family: $lato;
		  font-size: 24px;
		  font-weight: 300;
		  line-height: 1.42;
		  letter-spacing: 1.3px;
		  color: #343b41;
		}
	}

	.how-it-works--wrapper {
		.section--title {
		    color: $gojek;

		    &:before {
	    	    background-color: $gojek;
		    }
		}
		.layer {
			padding: 30px 60px 10px;

			.rect--primary {
				background: $gojek;
			}
			.rect--secondary {
				background: $gojek !important;
			}
		}
		.btn {
			background-color: #252525 !important;
		    color: white !important;
		    font-size: 12px;
	      	padding: 20px 50px;
	    	margin: 20px 0;
		}
		.hor--desc {
			object-fit: contain;
			font-family: $opensans;
			font-size: 14px;
			font-weight: 300;
			line-height: 1.86;
			letter-spacing: 1.3px;
			color: #485460;
		}
	}

	.section-join-form.larismanis {
		margin-top: 50px;
		padding: 80px;

		.main-wrapper {
			height: 940px;
			width: 100%;
			box-shadow: 0px 0px 50px rgba(90, 90, 90, .25);
		}
		.main-wrapper .background-wrapper {
			background: url('https://d3naj63yelf4gk.cloudfront.net/dist/img/page/gosend/texture-img-gojek.jpg?ver=1');
			min-height: 940px !important;
			width: 100%;
			padding: 0;
			text-align: center;
		}
		.main-wrapper .background-wrapper > img {
			max-width: 60px;
			margin-top: 280px;
		}
		.main-wrapper .form-wrapper {
			padding: 50px;
		}
		.main-wrapper .form-wrapper .title {
			margin-top: -15px;
			font-size: 12px;
			position: relative;
			top: 10px;
			color: rgba(200, 200, 200, 1.0);
		}
		.main-wrapper .form-wrapper .group {
			width: 100%;
			margin-top: 30px;
			margin-bottom: 30px;
		}
		.main-wrapper .form-wrapper .group label {
			font-family: "Open Sans", sans-serif;
			font-weight: 700;
			font-size: 12.5px;
			color: #697b8c;
		}
		.main-wrapper .form-wrapper .group input,
		.main-wrapper .form-wrapper .group select {
			width: 100%;
			height: 45px;
			padding: 15px;
			margin-top: 5px;
			letter-spacing: 1.2px;
			font-size: 13.4px;
			background: white;
			border-radius: 0 !important;
			border: 1px solid rgb(210, 210, 210);
			color: #485460;
		}
		.error_messages {
			color: red;
			margin-top: 10px;
		}
		.main-wrapper .form-wrapper .group input[type="checkbox"] {
			width:  10%;
			position: relative;
			margin-bottom: -20px;
			margin-top: -10px;
			display: inline-block;
		}
		.main-wrapper .form-wrapper .group .checkbox-label {
			font-family: "Open Sans", sans-serif;
			font-size: 12.5px;
			margin-top: -10px !important;
			position: relative;
			top: -16px;
			margin-bottom: 20px;
		}
		.main-wrapper .button-submit {
			background: #48af4a;
	    z-index: 100;
	    box-shadow: 0 2px 10px 0 rgba(0,0,0,.45);
	    font-family: Open Sans,sans-serif;
	    font-weight: 400;
	    right: 170px;
	    padding: 24px;
	    transition: all .5s ease 0s;
	    color: #fff;
	    float: right;
	    position: absolute;
	    right: 0;
	    margin-top: 340px;
	    border-radius: 8px;
		}
		.main-wrapper .button-submit:hover {
			box-shadow: 0 2px 10px 0 rgba(0, 0, 0, .65);
		}
		.main-wrapper .button-submit > span {
			margin-left: 50px;
			margin-right: 50px;
		}
	}

	ol.list--item {
		font-family: "Open Sans", Helvetica, sans-serif;
    color: rgb(72, 84, 96);
    padding: 0 20px;
    font-weight: 300;
    line-height: 1.62;
    letter-spacing: 1.5px;
	}

	.section-our-values {
		background: white;

		.additional-text {
			color: #9b9b9b;
			font-weight: 300;
			font-size: 14px;
			line-height: 1.71;
		}

		.list h4 {
			text-transform: none;
		}

		.list p {
			font-size: 14px;
			font-weight: 300;
			color: #9b9b9b;
			line-height: 1.71;
			letter-spacing: 1px;
		}
	}

	@media (min-width: 1024px) and (max-width: 1280px) {
		header#main--hero.larismanis {
			background-image: url('https://d3naj63yelf4gk.cloudfront.net/dist/img/page/gosend/larismanis/header-new.jpg?ver=12');
			background-position-x: 0;
			background-size: contain;
			margin-top: 0;
		}

		.how-it-works--wrapper {
			.container {
				.how--title {
					margin-left: 70px;
				}

				ol.list--item {
					position: relative;
					left: 50px;
				}
			}
		}
	}

	@media (min-width: 769px) and (max-width: 1024px) {
		.navbar {
			.menu {
				display: none;
			}

			.button-wrapper {
				display: none;
			}
			#gojek-brand-desktop {
				display: none !important;
			}

			#gojek-brand-mobile {
				display: block !important;
			}
			.hamburger {
				display: none;
			}
		}


		header#main--hero.larismanis {
			background-size: contain !important;
			margin-top: 0;
		}

		.s-and-k-section-mobile {
			display: block;
			background: white;
			padding: 10px;

			.wrapper {
				margin-top: 45px;
				margin-bottom: 45px;

				.syarat-ketentuan-img {
					margin-top: 40px;
				}
			}
		}

		.how-it-works--wrapper {
			display: none;
		}

		.section-join-form.larismanis {
        padding: 0;
        margin: 0;
        margin-top: 50px;

				.icon-wrapper {
					display: inline;
					position: relative;
					top: 5px !important;

					li {
						display: inline;
						margin: 0 20px;

						.gosend-icon {
							max-width: 50px !important;
							position: relative;
							top: 5px;
						}
					}
				}

				.s-dan-k-checkbox {
					position: relative;
					top: -20px;
					left: 30px;
				}
				.s-dan-k {
					position: relative;
					left: 30px;
					font-weight: 300;
					margin-top: -16px;
				}

				.error_messages {
         color: red;
         margin-top: 10px;
         letter-spacing: 1px
	      }

				.main-wrapper {
		        margin-top: 50px;
		        height: auto;
		        width: 100%;
		        box-shadow: 0 0 50px rgba(90, 90, 90, .25);

						.form-wrapper .title {
				        margin-top: -15px;
				        font-size: 12px;
				        position: relative;
				        top: 10px;
				        color: #c8c8c8;
				        text-align: center;
				    }

						.background-wrapper {
				        background: url('https://d3naj63yelf4gk.cloudfront.net/dist/img/page/gosend/texture-img-gojek.jpg?ver=1');
				        min-height: 130px!important;
				        width: 100%;
				        padding: 0;
				        text-align: center;

								img {
					         max-width: 60px;
					         margin-top: 30px;
					     }
				    }

						.form-wrapper {
			         padding: 25px!important;

							 .group label {
					        font-family: Open Sans, sans-serif;
					        font-weight: 700;
					        font-size: 12.5px;
					        color: #697b8c;
					        letter-spacing: .9px
					    }

							.group .checkbox-label {
				         letter-spacing: 1px;
				         margin-left: 10px
				     }
			     }

					 .button-submit {
			        background: $gojek;
			        z-index: 100;
			        padding: 25px;
			        top: 25px;
			        font-size: 14px;
			        left: -15px;
			        box-shadow: 0 2px 10px 0 rgba(0, 0, 0, .45);
			        position: relative;
			        transition: all .5s ease 0s;
			        color: #fff;
			        border-radius: 8px
			    }
		    }
    }
	}

	@media only screen and (max-width: 768px) {
		header#main--hero.larismanis {
			background-size: cover !important;
		}
	}

	@media only screen and (max-width: 480px) {
		header#main--hero.larismanis {
			background-size: contain !important;
			margin-top: 25px;
			margin-bottom: -100px;
		}

		#promo-banner {
			@media only screen and (max-width: 480px) {
				h4 {
					line-height: 18px;
				}
			}
		}

		.as-seen-on {
			 #content {
					padding: 0;
			 }

			 p {
				 font-size: 1.125em;
				 position: relative;
				 top: -7.5px;
			 }

			 @media only screen and (max-width: 768px) {
				 p {
					 position: relative;
					 top: 15px;
				 }
			 }
		}

		.s-and-k-section-mobile {
			display: block;
			background: white;
			padding: 10px;

			.wrapper {
				margin-top: 45px;
				margin-bottom: 45px;

				.syarat-ketentuan-img {
					margin-top: 40px;
				}
			}
		}

		.how-it-works--wrapper {
			display: none;
		}

		.section-join-form.larismanis {
        padding: 0;
        margin: 0;
        margin-top: 50px;

				.error_messages {
         color: red;
         margin-top: 10px;
         letter-spacing: 1px
	      }

				.main-wrapper {
		        margin-top: 50px;
		        height: auto;
		        width: 100%;
		        box-shadow: 0 0 50px rgba(90, 90, 90, .25);

						.form-wrapper .title {
			        margin-top: -15px;
			        font-size: 12px;
			        position: relative;
			        top: 10px;
			        color: #c8c8c8;
			        text-align: center;
				    }

						.background-wrapper {
				        background: url('https://d3naj63yelf4gk.cloudfront.net/dist/img/page/gosend/texture-img-gojek.jpg?ver=1');
				        min-height: 130px!important;
				        width: 100%;
				        padding: 0;
				        text-align: center;

								img {
					         max-width: 60px;
					         margin-top: 30px;
					     }
				    }

						.form-wrapper {
			         padding: 25px!important;

							 .group label {
					        font-family: Open Sans, sans-serif;
					        font-weight: 700;
					        font-size: 12.5px;
					        color: #697b8c;
					        letter-spacing: .9px
					    }

							.group .checkbox-label {
				         letter-spacing: 1px;
				         margin-left: 10px
				     }
			     }

					 .button-submit {
			        background: $gojek;
			        z-index: 100;
			        padding: 25px;
			        top: 5px;
			        font-size: 14px;
			        left: -15px;
			        box-shadow: 0 2px 10px 0 rgba(0, 0, 0, .45);
			        position: relative;
			        transition: all .5s ease 0s;
			        color: #fff;
			        border-radius: 8px
			    }
		    }
    }
	}

	@media only screen and (max-width: 320px) {
		.s-dan-k.small {
			display: inline-block;
			position: relative;
			left: 20px !important;
		}

		.s-dan-k {
			position: relative;
			font-size: 11px;
			left: 80px;
			display: none;
			font-weight: 300;
			margin-top: -16px;

			.link {
				font-size: 11px;
			}
		}
	}
}

#popup_box {
  display: none;
  position: fixed;
  _position: absolute;
  width: 600px;
  background: #FFFFFF;
  left: 400px;
  top: 150px;
  z-index: 100;
  margin-left: 15px;
  padding: 35px;
  font-size: 15px;
  -moz-box-shadow: 0 0 5px #ff0000;
  -webkit-box-shadow: 0 0 5px #ff0000;
  box-shadow: 0 0px 30px 0px rgba(140, 140, 140, .85);

	h3 {
		margin: 0;
		padding: 0;
	}

	h5 {
		font-weight: 300;
		font-size: 14px;
    margin-top: 15px;
		line-height: 20px;
		margin-bottom: 35px;
	}
}
#popupBoxClose {
  font-size: 15px;
  padding: 10px;
	cursor: pointer;
	background: $gojek;
	letter-spacing: 2px;
  position: relative;
  color: white;
  top: -5px;
  font-weight: 500;
}

@media only screen and (max-width: 480px) {
	#popup_box {
		display: none;
		position: fixed;
		_position: absolute;
		width: 100%;
		background: #FFFFFF;
		left: -15px;
		top: 150px;
		z-index: 100;
		margin-left: 15px;
		padding: 35px;
		font-size: 15px;
		-moz-box-shadow: 0 0 5px #ff0000;
		-webkit-box-shadow: 0 0 5px #ff0000;
		box-shadow: 0 0px 30px 0px rgba(140, 140, 140, .85);
	}
}
