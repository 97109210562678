#faq-list-menu {
	margin: 5em 0;
	.box {
		background-image: url('https://d3naj63yelf4gk.cloudfront.net/dist/img/faq/list-geo.png'), linear-gradient(rgba(255, 255, 255, 1), rgba(255, 255, 255, 1));
		background-position: bottom, top;
		background-repeat: no-repeat, no-repeat;
		background-size: 100%, cover;
		color: #343b41;
		padding: 2.5em 0;
		margin: 0 1.5em 2em;
		box-shadow: 0 3px 15px 0 rgba(0, 0, 0, 0.13), 0px 25px 0px 15px rgba(72, 175, 74, 1);
		text-align: center;
		cursor: pointer;
		h4 {
			margin-top: 0;
			color: #343b41;
			font-weight: 500;
		}
		hr {
			background: #98ce00;
			border: none;
			width: 50px;
			height: 4px;
		}
	}
	.active {
		background-image: url('https://d3naj63yelf4gk.cloudfront.net/dist/img/faq/list-geo.png'), linear-gradient(rgba(44, 130, 46, 1), rgba(44, 130, 46, 1));
		background-position: bottom, top;
		background-repeat: no-repeat, no-repeat;
		background-size: 100%, cover;
		box-shadow: 0px 25px 0px 15px rgba(72, 175, 74, 1);
		cursor: default;
		pointer-events: none;
		h4 {
			color: white;
		}
	}
	.mobile {
		display: none;
	}
	.desktop {
		.content {
			width: 100%;
			padding: 3em 2em;
			text-align: left; // div:first-child {
			// 	margin-bottom: 2em;
			// }
			div {
				text-align: center;
				margin-bottom: 4.5em;
			}
			h4 {
				font-weight: bold;
				letter-spacing: 2.1px;
				margin-bottom: 2em;
			}
			ul {
				list-style: none;
				padding: 0;
				margin-bottom: 2.5em;
				li {
					text-overflow: ellipsis;
					overflow: hidden;
					padding: 0;
					margin: 1.5em 0.5em;
					height: 60px;
					line-height: 1.75;
					border-bottom: 1px solid #dce0e9;
					text-align: left;
					a {
						font-family: $opensans;
						font-weight: 300;
						letter-spacing: 1.7px;
						color: #272d32;
					}
					a:hover {
						color: #48af4a;
					}
				}
			}
			.see-all {
				font-weight: bold;
				line-height: 0.8;
				letter-spacing: 3.3px;
				font-size: 0.7em;
				color: #48af4a;
			}
		}
	}
}

@media screen and (max-width:48em) {
	#faq-list-menu {
		padding: 1em 0;
		margin: 0;
		background: #f8f8f8;
		.desktop {
			display: none;
		}
		.mobile {
			display: inherit;
			.slick-track {
				width: 500px;
				margin: 0 auto;
				margin-top: 2em;
			}
			#faq-list-content {
				.title-content {
					outline: none;
					padding: 1.5em;
					color: #343b41;
					background: white;
					font-family: $opensans;
					line-height: 1.33;
					font-weight: 300;
					letter-spacing: 2.5px;
					box-shadow: 0 3px 15px 0 rgba(0, 0, 0, 0.13), 0 25px 0 15px #48af4a;
					margin-bottom: 4.5em;
					span {
						padding: 0.8em 0px;
						color: #98ce00;
					}
				}
				.ui-state-active {
					background: #2c822e;
					color: white;
				}
				outline: none;
				.title-item {
					background: white;
				}
				.detail-item {
					outline: none;
					ul {
						background: white;
						outline: none;
						padding: 1em;
						padding-top: 0px;
						li {
							padding: 1em 0;
							outline: none;
							border-bottom: solid 1px #e8ebf2;
						}
					}
					.title-item {
						outline: none;
						border-left: 5px solid #98ce00;
						margin: 1em 0;
						padding: 1.5em 1em;
						font-family: $opensans;
						font-weight: bold;
						letter-spacing: 2.5px;
						color: #343b41;
						span {
							color: #98ce00;
							margin-top: 0.30em;
						}
					}
					li {
						.all {
							font-family: $opensans;
							font-weight: bold;
							letter-spacing: 4px;
							color: #48af4a;
						}
						a {
							color: #343b41;
							letter-spacing: 1.5px;
						}
					}
					li:last-child {
						color: green;
					}
					.ui-state-active {
						border-color: #48af4a;
						margin: 0;
						span {
							color: #48af4a;
						}
					}
				}
			}
			.ui-accordion-header-icon {
				float: right;
			}
			ul {
				list-style: none;
				padding: 0;
				margin: 0;
				font-family: $lato;
				font-weight: 300;
				.title-content {
					font-family: $lato;
					font-weight: 700;
					margin-bottom: 1em;
				}
				#accordion ul {
					display: inherit;
				}
			}
		}
	}
}
