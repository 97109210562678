// File Name: _about.scss
// Description: global about styles
// Used by: .scss
// Dependencies:
// ------------------------------------------------------------
section.about {
	background: #FAFAFA;
  padding: 3em 0;
	.container {
		position: relative;
		&:before {
			content: "";
			position: absolute;
			left: -1.2em;
			top: 1.050em;
			padding-right: 45px;
			height: 3px;
			margin: 0;
			background-color: $gojek;
		}
	}
	.video-content {
		display: none;
		position: relative;
		font-family: $opensans;
		width: 100%;
		height: 0;
		padding-bottom: 70%;
		background: #000000;
		iframe {
			width: 100%;
			position: absolute;
			top: 0;
			left: 0;
			height: 100%;
			margin-top: 0 !important;
		}
		.video-close {
			position: absolute;
			color: #fff;
			font-size: 200%;
			cursor: pointer;
			background: $gojek url('../img/card/about/close-btn.png') no-repeat center center;
			background-size: 29px 29px;
			border-radius: 50%;
			width: 60px;
			height: 60px;
			top: -1em;
			right: -1em;
		}
	}
	h2 {
		font-family: $lato;
		font-weight: 300;
	}
	h4 {
		color: #48af4a;
		text-transform: uppercase;
		padding-left: 15px;
	}
	p {
		color: #485460;
		padding-bottom: 20px;
		font-family: Open Sans,Helvetica,sans-serif !important;
	}
	a {
		color: $gojek;
		text-transform: uppercase;
		position: relative;
		svg
		{
			transition: all 0.5s;
		}
		@media screen and (min-width: 769px) {
			&:hover
			{
				svg
				{
					transform:translateX(10px);
					transition: all 0.5s;
				}
			}
		}
	}
	.description {
		// padding-bottom: 4em;
		svg {
			width: 1.5em;
			height: auto;
			position: absolute;
			top: 0.2em;
			margin-left: 1em;
		}
	}
	.img-wrapper {
		position: relative;
		.img-container {
			position: relative;
			background: #fff url() center center/cover;
			background-size: 100%;
			padding: 2em 0;
			margin-right: -3em;
			margin-left: 1em;
			.img-container-2 {
				margin-left: -2em;
				margin-right: 2em;
				position: relative;
			}
			.play {
				overflow: hidden;
				transition: all 0.5s;
				position: absolute;
				margin: auto;
				top: 0;
				left: 0;
				cursor: pointer;
				right: 0;
				bottom: 0;
				width: 50px;
				height: 50px;
				border-radius: 50%;
				object-fit: contain;
				background-color: #ffcd2e;
				box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.24);
				@media screen and (min-width: 769px) {
					&::after
					{
						position: absolute;
						width: 100%;
						text-align: center;
						content: 'Play Video';
						font-weight: 700;
						color: #ffcd2e;
						height: 36px;
						left: 0;
						top: 0;
						bottom: 0;
						height: 34px;
						margin: auto;
						transition: all 1s;
						transform: translateX(-100px);
					}
					&:hover
					{
						background:#ffffff;
						img
						{
							transform: scale(9);
						}
						&::after
						{
							transform: translateX(0px);
						}	
					}
				}
				img {
					transition: all 0.5s;
					margin: auto;
					position: absolute;
					top: 0;
					left: 0;
					bottom: 0;
					right: 0;
					@media screen and (max-width: 768px) {
						max-width: 20px;
					}
				}
			}
		}
	}
}


/* Small Screen */

@media screen and (max-width: 35.5em) {
	section.about .video-content .video-close {
		height: 50px;
		width: 50px;
		margin: -5px 35px;
	}
}

@media screen and (min-width: 35.5em) {
	section.about .img-wrapper {
		margin-left: 5em;
	}
	section.about .img-wrapper .img-container {
		margin-left: -4.188em;
	}
}


/* Medium Screen */

@media screen and (min-width: 48em) {
	section.about .img-wrapper .img-container {
		margin-top: 40%;
		padding: 4em 0;
	}
	section.about .img-wrapper .img-container .img-container-2 {
		margin-right: 4em;
	}
	section.about .img-wrapper {
		display: block;
	}
	section.about a:after {
		top: 6px;
		width: 30px;
		height: 30px;
		background-size: 20px;
	}
	section.about h4 {
		padding: 0;
	}
	section.about .container:before {
		padding: 0;
	}
}


/* Large Screen */

@media screen and (min-width: 64em) {
	section.about .img-wrapper {
		margin-left: 0;
	}
	section.about .img-wrapper .img-container {
		margin-left: 0;
		margin-right: 0;
		margin-top: 0;
	}
	section.about .img-wrapper .img-container .play {
		padding: 40px;
		margin-left: -2.5em;
	}
}


/* Extra Large Screen */

@media screen and (min-width: 80em) {}
