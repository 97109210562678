#content-only {
	background: #f8f8f8;
  	padding: 7em 0;
  	margin-top: 60px;
	.container {
		background: white;
		box-shadow: 0 -3em 0 1.5em #48af4a;
		.info {
			padding: 5em;
			font-family: $opensans;
			text-align: center;
			position: relative;
			.line {
				width: 50px;
				height: 4px;
				background: #48af4a;
				margin: 2em auto;
			}
			h4 {
				color: #48af4a;
				font-weight: 700;
				line-height: 0.78;
				letter-spacing: 1.9px;
			}
			p {
				font-weight: 300;
				line-height: 1.56;
				letter-spacing: 1.7px;
				margin: 0em 10em;
				color: #485460;
			}
			.link {
				margin-bottom: -11.5%;
				margin-top: 2.5em;
				a {
					padding: 0;
					font-weight: bold;
					border-radius: 5px;
					text-decoration: none;
					color: #fff;
					width: 230px;
					display: inline-block;
					height: 80px;
					padding-top: 35px;
					@media screen and (max-width: 767px) {
						margin-bottom: 10px;
					}
				}
				.faq {
					background: #485460;
					margin-right: 10px;
					@media screen and (max-width: 767px) {
						margin-right: 5px;
						margin-bottom: 10px;
					}
				}
				.contact {
					background: #48af4a;
				}
			}
		}
		@media screen and (max-width: 768px) {
			width:100%;
		}
	}
	@media screen and (max-width: 768px) {
			padding:20px;
			width:90%;
			margin-left: auto;
			margin-right: auto;
			margin-top:100px;
	}
}

@media screen and (max-width: 35.5em) {
	#content-only {
		.container {
			box-shadow: none;
			.info {
				padding: 1em 0;
				p {
					margin: 0;
				}
			}
		}
	}
}

@media screen and (max-width: 48em) {
	#content-only {
		.container {
			box-shadow: none;
			.info {
				p {
					margin: 0;
				}
				.link {
					margin-bottom: 0;
					a {
						padding: 2em;
						height: auto;
						width: auto;
					}
				}
			}
		}
	}
}
