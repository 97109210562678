#gotix {
	/* new-cards */
	section[new-version]
	{
		&.new-how-to-use-promo
		{
			h5.title-underscore
			{
				color: $gotix-secondary;
				&:after
				{
					background-color: $gotix-secondary;
				}
			}
			.container-list
			{
				background-color: $gotix;
			}
			p
			{
				color: #4a4a4a;
			}
		}
		&.new-more-information
		{
			h5.title-underscore
			{
				color: $gotix-secondary;
				&:after
				{
					background-color: $gotix-secondary;
				}
			}
		}
	}


	header#main--hero.donation {
		&.ystc {
			background-image: url(https://d3naj63yelf4gk.cloudfront.net/dist/img/page/gotix/ramadhan/header/donation-ystc.jpg);
		}
		&.rumahzakat {
			background-image: url(https://d3naj63yelf4gk.cloudfront.net/dist/img/page/gotix/ramadhan/header/rumahzakat.jpg);
		}
		&.sos {
			background-image: url(https://d3naj63yelf4gk.cloudfront.net/dist/img/page/gotix/ramadhan/header/sos.jpg);
		}
		&.wecare {
			background-image: url(https://d3naj63yelf4gk.cloudfront.net/dist/img/page/gotix/ramadhan/header/wecare.jpg);
		}

		.hero--desc {
	    font-size: 20px;
	    display: block !important;
		}
	}
	p {
		color: #9b9b9b;
	}

	.thank-you
	{
		margin-top: 150px;
		h4
		{
			color: $gotix !important;
		}
		.line
		{
			background: $gotix !important;
		}
		.contact
		{
			width: 100% !important;
			max-width: 300px;
			line-height: normal;
			background: $gotix !important;
		}
		.container
		{
			box-shadow: 0 -3em 0 1.5em $gotix;
		}
	}

	.how-it-works--wrapper {
		.container:before {
			background-color: transparent;
		}
		.section--title {
			color: #343b41;
	    padding: 0;
		}
		.section--title:before {
			background-color: $gotix;
		}
		.layer {
			.rect--primary {
				background: $gotix;
			}
		}
	}

	#join-now {
		.right {
			a {
				background: $gotix;
			}
		}
		.left
		{
			-webkit-box-shadow: inset 0px 0px 300px 200px rgba(234,107,37,1);
		    -moz-box-shadow: inset 0px 0px 300px 200px rgba(234,107,37,1);
		    box-shadow: inset 0px 0px 300px 200px rgba(234,107,37,0.5);
			background-size:cover;
			background-position: center;
		}
	}
	#join-now.donation {
		a {
			display: none;
		}
	}

	#talent-story {
		h4 {
			color: #093c5b;
			margin: 10px 0 0 3em;
		}
		h4:before {
			top: 20px;
		}
	}

	.buy-ticket {
		.img-slides {
			background: url(https://d3naj63yelf4gk.cloudfront.net/dist/img/page/gotix/tickets/bg-cgv-phone.svg);

			.img-item {
				height: 550px;

				img {
			    top: 50%;
			    position: relative;
			    -webkit-transform: translateY(-50%);
			    -moz-transform: translateY(-50%);
			    -o-transform: translateY(-50%);
			    transform: translateY(-50%);
				}
			}
		}
		.detail {
			ul {
		    padding: 0;
		    margin: 0;
		    width: 80%;

		    li.detail--text {
		    	font-family: Open Sans,Helvetica,sans-serif;
			    font-size: 15px;
			    line-height: 1.75;
			    letter-spacing: 1.5px;
			    color: #485460;
	        text-align: left;
			    cursor: pointer;
		    }
		    li.active-detail,
		    li.detail--text:hover, {
		    	color: $gotix;
		    }
			}
		}

		.custom-nav {
			.slick-arrow {
				stroke: #ea6b25;
				cursor: pointer;
			}
		}
	}

	.mv-evt--container {
    margin: 48px 0;

    .button-group {
	    width: 350px;
	    background: $gotix;
	    border-radius: 5px;
	    margin: 0 auto;
	    position: relative;

			ul {
		    list-style: none;
		    padding: 20px;
		    margin: 0;

		    li:first-child {
		    	border-left: 0;
		    }

		    li {
	  	    	display: inline-block;
			    border-left: 1px solid #353738;
			    width: 153px;

					a {
					  display: block;
					  color: #fff;
					  text-align: center;
					  font-family: Lato,Helvetica,sans-serif;
					  font-size: 14px;
					  line-height: 1.33;
					  letter-spacing: 1.5px;
					  font-weight: 300;
					  padding: 10px;
					}
				}
			}
		}
	}

	#what-new.section-our-values {
		.img--wrapper {
	    height: 500px;

	    img {
	    	padding: 0 20px;
	    }
		}
	}

	#register-event.section-our-values {
		.list {
			p {
			padding: 20px;
		    font-family: 'Open Sans', sans-serif;
		    font-size: 16px;
		    font-weight: bold;
		    line-height: 1.56;
		    letter-spacing: 2.8px;
		    text-align: center;
		    color: #4a4a4a;
		    text-transform: uppercase;
			}
			.img--wrapper {
				img {
					padding: 30px;
				}
			}
		}
		.list-container {
	    margin-top: 50px;
		}
		.img--wrapper {
			height: 300px;
		}
		.line.gradient {
	    margin: 0;
		}
	}

	.section-our-values {
		padding: 30px 0;

		.img--wrapper {
			height: 300px;
		}
		img {
			max-width: 250px;
	    padding: 20px;
			display: block;
			top: 50%;
	    position: relative;
	    -webkit-transform: translateY(-50%);
	    -moz-transform: translateY(-50%);
	    transform: translateY(-50%);
		}

		.title {
	    line-height: 0.78;
	    letter-spacing: 1.9px;
	    text-align: center;
	    color: #093c5b;

	    & + p {
	    	margin: 30px 0;
	    }
		}
		.line.gradient {
			background: $gotix;
		}
	}

	.section-how-to-use.how_donation .slider .item-text {
    padding-top: 30px;
	}

	.section-how-to-use {
		padding: 50px;
		height: 620px;
		background-size: cover !important;

		.slider {
			.item-text[data-slide="1"],
			.item-text[data-slide="2"],
			.item-text[data-slide="3"] {
				position: relative;
				top: -400px;
			}

			.item-text[data-slide="4"],
			.item-text[data-slide="5"] {
				position: relative;
				top: -50px;
			}
		}

		.slider-image {
			.item[data-slide="3"] img {
				position: relative;
				top: -30px;
			}
			.item[data-slide="4"] img {
				position: relative;
				top: -45px;
			}
			.item[data-slide="5"] img {
				position: relative;
				top: -55px;
			}
		}

		h5 {
			color: $gotix;
		}
		.line.gradient {
			background: $gotix;
		}
		.title {
			position: relative;
			 left: -410px;
		}
		.slick-arrow {
			polygon {
				fill: $gotix;
			}
		}
		.arrow-up {
			stroke: $gotix;
		}
		.arrow-up-circle {
			stroke-width: 3;
			stroke: $gotix;
		}
		.arrow-down {
			stroke: $gotix;
		}
		.arrow-down-circle {
			stroke: $gotix;
			stroke-width: 3;
		}
		.slider .slick-dots li.slick-active button:before {
			color: $gotix;
		}

		.slider-image .slick-dots li.slick-active button:before {
			color: $gotix;
		}

		.text-mobile {
			display: none;
			text-align: center;
		}

		.text-mobile .line.gradient {
			width: 50px;
			height: 3px;
			margin-top: 0px;
			background: $gotix;
			margin-left: auto;
			margin-right: auto;
		}

		.text h5 {
			margin-top: 100px;
			color: $gotix;
			font-weight: 900;
		}

		.text .line.gradient {
			width: 50px;
			height: 3px;
			margin-top: 0px;
			background: $gotix;
		}

		// .phone-base {
		// 		display: block;
		// 		margin-right: auto;
		// 		margin-left: auto;
		// 		top: 50px;
		// 		position: relative;
		// }


		.slider .item-text {
			// margin-top: 60px;
		}
		.slider .item-text h2 {
			// margin-top: 20px;
			// margin-left: 0px;
			// font-weight: 300;
		}

		.slider .item .wrapper {
				width: 100%;
		}
		.slider-image .slick-list {
				// margin: 40px 0 -112px -20px;
				// position: relative;
				// left: -155px;
				// top: -6px;
		}
		.slider-image .slick-dots {
				// position: relative;
				// top: -350px;
				// left: 30px;
		}
		.slider-image .slick-dots li {
				display: block;
		}
		.slider-image .slick-dots li.slick-active button:before {
				color: $gotix;
		}
		.slider-image .slick-dots li button:before {
				font-size: 13px;
		}
		.slider .slider-text.title {
				position: relative;
				float: right;
				left: -265px;
				top: -20px;
				font-size: 36px;
				margin-top: -320px;
				font-weight: 300;
		}
		.slider .slider-text.desc {
				margin-left: 380px;
				position: relative;
				top: -280px;
				left: 45px;
				margin-bottom: -33px;
				line-height: 1.56;
				font-weight: 300;
				letter-spacing: 1.7px;
		}
		.slider-image {
			// position: relative;
			// z-index: 99 !important;
			// margin-top: -425px;
			// margin-bottom: -98px;
			// text-align: center;
			// margin-right: auto;
			// margin-left: auto;
			// display: block;

			// .phone-slider {
			// 	display: block;
			// 	max-width: 260px;
			// 	margin-right: auto;
			// 	margin-left: auto;
			// 	margin-bottom: 10px;
			// 	position: relative;
			// 	left: 155px;
			// 	top: -17px;
			// }
		}

		ul li {
				margin-top: 10px;
		}
		ul li img {
				cursor: pointer;
		}
	}

	// Ramadhan Pages Homepages
	.homepage {
		.images-only {
			.img-wrapper {
				padding: 1.5px;

				.overlay {
					z-index: 99;
					background: #1e5799; /* Old browsers */
					background: -moz-linear-gradient(top, rgba(255, 255, 255, .2) 0%, rgba(255, 255, 255, .4) 100%); /* FF3.6-15 */
					background: -webkit-linear-gradient(top, rgba(255, 255, 255, .2) 0%, rgba(255, 255, 255, .45) 100%); /* Chrome10-25,Safari5.1-6 */
					background: linear-gradient(to bottom, rgba(255, 255, 255, .2) 0%, rgba(255, 255, 255, .45) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
		  	}

		  	a {
		  		display: block;
		  	}
		  	a:hover {
		  		opacity: 0.6;
		  	}

				img.featured {
					z-index: -1;
					margin-bottom: -50px;
					position: relative;
				}

				img.logo-patch {
					display: inline;
					position: relative;
					top: -20px;
					left: 30px;
				}

				img.logo-patch.logo-sos {
					max-width: 150px;
				}

				img.logo-patch.logo-yayasan-tunas {
					max-width: 300px;
				}
			}
		}

		.berbagi-tanpa-batas {
			background: #f8f8f8;
			width: 100%;
			padding: 25px;
			text-align: center;

			img {
				display: inline-block;
				margin-right: auto;
				margin-left: auto;
			}

			img.motto {
				max-height: 144px;
				position: relative;
				right: 20px;
			}

			img.brand {
				max-height: 144px;
				position: relative;
				top: -15px;
				left: 130px;
			}
		}
	}
}
@media screen and (min-width: 768px) {
	#gotix {
		#join-now
		{
			.right
			{
				padding:4.5em 3em;
			}
		}
	}
}
@media screen and (max-width: 768px) {
	#gotix {
		header#main--hero.donation {
			.hero--desc {
				font-size: 12px;
			}
		}
		#register-event .list .img--wrapper{
			height:auto;
			img
			{
				transform: none;
				top:auto;
			}
		}
		.list-container
		{
			margin-top: 0px !important;
		}
		// Ramadhan Pages Homepages
		.homepage {
			.images-only {
				.img-wrapper {
					padding: 1.5px;
				}
			}

			.berbagi-tanpa-batas {
				background: #f8f8f8;
				width: 100%;
				padding: 25px;
				text-align: center;

				img {
					display: block;
					margin-right: auto;
					margin-left: auto;
				}

				img.motto {
					max-height: 60px;
					right: 0px;
				}

				img.brand {
					max-height: 144px;
					position: relative;
					top: -15px;
					left: 0px;
				}
			}
		}
	}
}
@media screen and (max-width: 480px) {
	#gotix {
		#join-now .left
		{
			height: 300px;
		}
		.section-how-to-use {
			min-height: 815px;
			// padding: 50px;
			background-size: cover !important;

			.overlay {
		    position: relative;
		    width: 100%;
		    height: 100%;
			}

			// .slider {
			// 	.item-text[data-slide="1"],
			// 	.item-text[data-slide="2"],
			// 	.item-text[data-slide="3"] {
			// 		position: relative;
			// 		top: 0px;
			// 	}

			// 	.item-text[data-slide="4"],
			// 	.item-text[data-slide="5"] {
			// 		position: relative;
			// 		top: 0px;
			// 	}
			// }

			// .slick-slider {
			// 	display: table;
			// 	table-layout: fixed;
			// 	width: 100%;
			// }

			.text-mobile {
				text-align: center;
				display: block;
				margin-right: auto;
				margin-left: auto;
			}

			// .text {
			// 	display: none;
			// }

			// .text h5 {
			// 	margin-top: 100px;
			// 	display: none;
			// 	color: $gotix;
			// 	font-weight: 900;
			// }

			// .text .line.gradient {
			// 	width: 50px;
			// 	height: 3px;
			// 	margin-top: 0px;
			// 	display: none;
			// 	background: $gotix;
			// }

			// .phone-base {
			// 	display: block;
			// 	margin-right: auto;
			// 	margin-left: auto;
			// 	top: 50px;
			// 	margin-top: 40px;
			// 	position: relative;
			// }

			.slider .item-text {
				margin-top: 0px;
			}
			.slider .item-text h2 {
				// margin-top: 20px;
				// margin-left: 0px;
				// font-weight: 300;
			}

			.slider .item .wrapper {
					width: 100%;
			}
			.slider-image .slick-list {
					// margin: 40px 0 -112px -20px;
					// position: relative;
					// left: -155px;
					// top: -6px;
			}
			.slider-image ul {
				display: none !important;
			}
			.slider-image .slick-dots {
					position: relative;
			}
			.slider-image .slick-dots li {
					display: block;
			}
			.slider-image .slick-dots li.slick-active button:before {
					color: $gotix;
			}
			.slider-image .slick-dots li button:before {
					font-size: 13px;
			}
			.slider .slider-text.title {
					position: relative;
					float: right;
					left: -265px;
					top: -20px;
					font-size: 36px;
					margin-top: -320px;
					font-weight: 300;
			}
			.slider .slider-text.desc {
					margin-left: 380px;
					position: relative;
					top: -280px;
					left: 45px;
					margin-bottom: -33px;
					line-height: 1.56;
					font-weight: 300;
					letter-spacing: 1.7px;
			}
			.slider-image {
				// position: relative;
				// z-index: 99 !important;
				// margin-top: -425px;
				// margin-bottom: -128px;
				// text-align: center;
				// margin-right: auto;
				// margin-left: auto;
				// display: block;
				// position: relative;
				// left: 155px;

				// .phone-slider {
				// 	display: block;
				// 	max-width: 260px;
				// 	margin-right: auto;
				// 	margin-left: auto;
				// 	margin-bottom: 10px;
				// 	position: relative;
				// 	left: 0;
				// 	top: -17px;
				// }
			}

			// ul {
			// 	position: relative;
			// 	top: 0px;
			// 	display: none;
			// 	margin-bottom: -100px;
			// 	left: 0px;
			// 	list-style-type: none;
			// }
			ul li {
					// margin-top: 10px;
			}
			ul li img {
					cursor: pointer;
			}
		}
		// Ramadhan Pages Homepages
		.homepage {
			.images-only {
				.img-wrapper {
					padding: 1.5px;
				}
			}

			.berbagi-tanpa-batas {
				background: #f8f8f8;
				width: 100%;
				padding: 25px;
				text-align: center;

				img {
					display: block;
					margin-right: auto;
					margin-left: auto;
				}

				img.motto {
					max-height: 60px;
					right: 0px;
				}

				img.brand {
					max-height: 144px;
					position: relative;
					top: -15px;
					left: 0px;
				}
			}
		}
	}
}

@media screen and (max-width: 440px) {
	.section-how-to-use {
		// .phone-base {
		// 	display: block;
		// 	margin-right: auto;
		// 	margin-left: auto;
		// 	top: 50px;
		// 	left: 0px;
		// 	margin-top: 40px;
		// 	position: relative;
		// }
		// .slider-image {
		// 	.phone-slider {
		// 		display: block;
		// 		max-width: 260px;
		// 		margin-right: auto;
		// 		margin-left: auto;
		// 		margin-bottom: 10px;
		// 		position: relative;
		// 		left: 0px;
		// 		top: -17px;
		// 	}
		// }
	}
}

@media screen and (max-width: 400px) {
	.section-how-to-use {
		min-height: 835px;

		// .phone-base {
		// 	display: block;
		// 	margin-right: auto;
		// 	margin-left: auto;
		// 	top: 50px;
		// 	left: -25px;
		// 	margin-top: 40px;
		// 	position: relative;
		// }
		// .slider-image {
		// 	.phone-slider {
		// 		display: block;
		// 		max-width: 260px;
		// 		margin-right: auto;
		// 		margin-left: auto;
		// 		margin-bottom: 10px;
		// 		position: relative;
		// 		left: -25px;
		// 		top: -17px;
		// 	}
		// }
	}
}

@media screen and (max-width: 320px) {
	.section-how-to-use {
		min-height: 835px;

		// .phone-base {
		// 	display: block;
		// 	margin-right: auto;
		// 	margin-left: auto;
		// 	top: 50px;
		// 	left: -55px;
		// 	margin-top: 40px;
		// 	position: relative;
		// }
		// .slider-image {
		// 	.phone-slider {
		// 		display: block;
		// 		max-width: 260px;
		// 		margin-right: auto;
		// 		margin-left: auto;
		// 		margin-bottom: 10px;
		// 		position: relative;
		// 		left: 0;
		// 		top: -17px;
		// 	}
		// 	.slick-list {
		// 			position: relative;
		// 			left: -210px;
		// 			top: -6px;
		// 	}
		// }
	}
}

@media screen and (max-width: 768px) {
	#gotix {
		#join-now.donation {
			a {
				display: block;
			}
		}
	}
}

@media screen and (max-width: 35.5em) {
	#gotix {
		header#main--hero {
			// background-image: url(https://d3naj63yelf4gk.cloudfront.net/dist/img/header/hero/go-tix--mobile.jpg);
		}
		.buy-ticket {
			.img-item {
				height: 350px !important;
			}
			.detail {
				ul {
			    width: 100%;
    			padding: 30px 30px 75px;

    			li.detail--text {
    				font-size: 13px;
    			}
				}
			}
		}
		#talent-story h4 {
			margin: 0 !important;
		}

		// Ramadhan Pages Homepages
		.homepage {
			.images-only {
				.img-wrapper {
					padding: 1.5px;

					img.featured {
						margin-bottom: -30px;
						position: relative;
					}

					img.logo-patch {
						display: inline;
						position: relative;
						top: -5px;
						left: 15px;
					}

					img.logo-patch.logo-rumah-zakat {
						max-width: 60px;
					}

					img.logo-patch.logo-sos {
						max-width: 80px;
					}

					img.logo-patch.logo-yayasan-tunas {
						max-width: 100px;
					}

					img.logo-patch.logo-wecare-id {
						max-width: 60px;
					}
				}
			}

			.berbagi-tanpa-batas {
				background: #f8f8f8;
				width: 100%;
				margin-top: 30px;
				padding: 25px;
				text-align: center;

				img {
					display: block;
					margin-right: auto;
					margin-left: auto;
				}

				img.motto {
					max-height: 60px;
					right: 0px;
				}

				img.brand {
					max-height: 144px;
					position: relative;
					top: -15px;
					left: 0px;
				}
			}
		}
	}
}
