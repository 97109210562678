.section-user-review {
    padding: 80px;
    background: #f8f8f8;
    text-align: center;
    .body
    {
        .slider
        {
            .slick-track > .item:nth-child(3n) > .wrapper
            {
                background: #2c822e;
            }
            .slick-track > .item:nth-child(3n+2) > .wrapper
            {
                background: #58c75b;
            }
            .slick-track > .item:nth-child(3n+1) > .wrapper
            {
                background: #343b41;
            }
        }
    }
    .panel {
        margin: 40px 0px 40px 0px;
    }

    .head, .body {
        background: #ffffff;
        padding: 15px 0;
    }

    .slider 

    .foot {
        background-color: #fbfbfb;
        padding: 15px 0;
    }

    .title {
        font-family: 'Open Sans', sans-serif;
        color: #48af4a;
        text-transform: uppercase;
        font-size: 18px;
        margin: 30px 0 20px 0;
        letter-spacing: 1.9px;
    }

    #up {
        -ms-transform: rotate(-90deg);
        -webkit-transform: rotate(-90deg);
        transform: rotate(-90deg);
        margin-right: 1em;
    }
    #down {
        -ms-transform: rotate(270deg);
        -webkit-transform: rotate(270deg);
        transform: rotate(270deg);
        margin-left: 1em;
    }

    svg.slick-disabled {
        ellipse {
            stroke: #d2d2d2 !important;
        }
        polygon {
            fill: #d2d2d2 !important;
        }
    }

    .line {
        width: 40px;
        margin: 0 auto 0px auto;
        height: 4px;
    }

    .line.light {
        height: 2px;
    }

    .line.gradient {
        background: $gojek;
    }

    .line.yellow {
        background: $goride;
    }

    .slider .slick-slide {
        height: auto !important;
    }

    .slider .item {
        padding: 10px;
    }

    .slider .item .wrapper {
        padding: 40px;
        position: relative;
        top: 20px;
    }

    .slider .item .wrapper.style-1 {
        background: #343b41;
    }

    .slider .item .wrapper.style-2 {
        background: #58c75b;
    }

    .slider .item .wrapper.style-3 {
        background: #2c822e;
    }

    .slider .item .wrapper h3.user-testimonial {
        object-fit: contain;
        font-family: 'Open Sans', sans-serif;
        font-size: 0.6em;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.56;
        letter-spacing: 0.8px;
        text-align: center;
        color: #ffffff;
        height: 70px;
        @media screen and (max-width: 768px) {
            height: 120px;
        }
        @media screen and (max-width: 480px) {
            height:70px;
        }
    }

    .slick-slider .slick-list, .slick-slider .slick-track {
        margin: 0 -20px;
        position: relative;
        left: 10px;
    }

    .slider .item .wrapper h3.user-name {
        object-fit: contain;
        font-family: 'Open Sans', sans-serif;
        font-size: 12.5px;
        font-style: normal;
        font-stretch: normal;
        line-height: 1;
        letter-spacing: 0.8px;
        text-align: center;
        font-weight: bold;
        color: #ffffff;
    }

    .slider .item .wrapper h3.user-name > span {
        object-fit: contain;
        font-family: 'Open Sans', sans-serif;
        font-size: 12.8px;
        font-style: normal;
        font-stretch: normal;
        line-height: 1;
        letter-spacing: 0.8px;
        text-align: center;
        font-weight: 300;
        color: #ffffff;
    }

    .slider .item .wrapper img {
        max-width: 80px;
        margin: -60px auto 25px auto;
        z-index: 999;
        border-radius: 50%;
    }

    .slider .item .wrapper .line {
        margin-top: 50px;
        margin-bottom: 30px;
    }

    .slick-dotted.slick-slider {
        margin-bottom: 50px;
    }

    .slick-dots li button:before {
        font-size: 10px;
        margin-top: 20px;
        line-height: 20px;
    }

    .slick-dots li.slick-active button:before {
        opacity: .75;
        color: #99CC28;
    }

    .foot .wrapper .arrow-prev,
    .foot .wrapper .arrow-next {
        max-width: 35px;
        cursor: pointer;
        margin: 10px 5px 5px 5px;
    }

    .slick-slide {
        outline: none
    }
}

@media screen and (max-width: 480px) {
    .section-user-review {
        padding: 0;

        .slick-slider .slick-list, .slick-slider .slick-track {
            margin: 0;
            position: relative;
            left: 0px;
        }
    }
}
