#gopoint {
	@keyframes showWinner
	{
		from
		{
			opacity: 0;
		}
		to
		{
			opacity: 1;
		}
	}
	button.download
	{
		background-color:$gopoint;
		bottom:-25px !important;
		font-size: 12px !important;
	}
	/* RANDOMIZER */
	@keyframes odometer
	{
		from
		{
			transform:translateY(0%)
		}
		to
		{
			transform:translateY(calc(-100% + 100px));
		}
	}
	@for $i from 0 through 36
	{
		$step: -100 * $i ;
		@keyframes odometerstop-#{$i}
		{
			100%
			{
				transform:translateY($step + 0px);
			}
		}
	}
	.header-random-golucky
	{
		position:fixed;
		height: 60px;
		width:100%;
		background-color: #fff;
		border-bottom:1px solid #ececec;
		text-align: center;
		display: inline-block;
		vertical-align: middle;
		padding: 0 20px;
		z-index: 12;
		.logo
		{
			position: absolute;
			height: 50px;
			width: 155px;
			top:0;
			left: 0;
			right: 0;
			bottom: 0;
			margin: auto;
			background-size: 100%;
			background-repeat: no-repeat;
			background-position: center;
		}
		a.logout
		{
			color: #2b2b2b;
			font-size:16px;
			font-family: $opensans;
			letter-spacing: normal;
			float: right;
			top:20px;
			position:relative;
		}
	}
	.container-random-choose
	{
		.container-select
		{
			&.goride {
				border: 1px solid #eaeaea;
			}
			overflow: hidden;
			position: relative;
			border-radius: 5px;
			padding: 0 20px;
			padding-right: 0px;
			border: 1px solid #a4dbe4;
			margin-bottom: 20px;
			height: 50px;
			select
			{
				font-weight: 700;
				outline: none;
				border: none;
				background: transparent;
				width: 100%;
				height: 100%;
				-webkit-appearance:none;
			}
			.arrow-choose
			{
				&.goride {
					background-color: #eaeaea;
				}
				position: absolute;
				height: 100%;
				width: 50px;
				background-color: #a4dbe4;
				top: 0;
				right: 0;
				text-align: center;
				line-height: 50px;
			}
		}
		.container-button-choose
		{
			> div
			{
				padding: 0 10px;
			}
			button
			{
				&.goride {
					background-color: $gojek;
				}
				&.danger {
					background-color: #ea4249;
				}
				background-color: $gopoint;
				padding: 20px 0;
				width: 100%;
				border-radius: 5px;
				color:#fff;
				&.disactive
				{
					pointer-events:none;
					background-color: #ececec;
				}

			}
		}
	}

	.container-random
	{
		width: 100%;
		position: relative;
		text-align: center;
		.word-random
		{
			padding: 20px 0;
			&.goride {
				> .digit
				{
					height: 100px;
					width: 90px;
					border-radius: 0px;
					display: inline-block;
					background-color: $gojek;
					color: #fff;
					overflow: hidden;
					margin: 0px;
					.container-odo-digit
					{
						span
						{
							display: inline-block;
							height: 100px;
							width: 90px;
							text-align: center;
							font-size: 80px;
						}
					}
				}
			}
		}
		.info
		{
			&.goride {
				color: $gojek;
			}
			font-weight: 700;
			color:$gopoint;
			font-weight: 18px;
			letter-spacing: 1px;
		}
		.winner-id
		{
			font-size: 100px;
			font-weight: 700;
			margin: 20px 0;
			letter-spacing: 20px;
		}
		.winner-name
		{
			transition:all 0.5s;
			width: 90%;
			display: inline-block;
			font-weight: 700;
			font-size: 50px;
			border: 1px solid #a4dbe4;
			border-radius: 5px;
			padding:5px 0;
			text-align: center;
			opacity: 0;
			.winner-name-person
			{
				opacity: 0;
			}
			&.active
			{
				transition:all 0.5s;
				opacity: 1;
				.winner-name-person
				{
					animation:1s showWinner forwards;
					animation-delay:4s;
				}
			}
		}

		@for $i from 1 through 15
		{
			.container-odo-digit
			{
				$time: 0.5 + $i * 0.25;
				$outtime:0.5 + $i * 0.25;
				&[data-container='#{$i}']
				{
					// defaultnya golucky
					// animation: $time + 0s odometer infinite linear; 
					animation: 0.55s odometer infinite linear;
					&.stop
					{
						@for $o from 0 through 36
						{
							&[data-stop='#{$o}']
							{
								animation:
								$time + 0s odometer infinite linear,
								$outtime + 0s odometerstop-#{$o} forwards linear $time - 1 + 0s;
							}
						}
					}
				}
			}
		}



		.digit
		{
			height: 100px;
			width: 100px;
			border-radius: 5px;
			display: inline-block;
			background-color: #a4dbe4;
			overflow: hidden;
			margin: 0 10px;
			.container-odo-digit
			{
				span
				{
					display: inline-block;
					height: 100px;
					width: 100px;
					text-align: center;
					font-size: 80px;
				}
			}
		}
	}
	.drawed-winner
	{
		.append-winner
		{
			opacity: 0;
			&.active
			{
				animation: 0.5s showWinner forwards 0s;
			}
		}
	}
	/* ENDING RANDOMIZER */


	/* go-lucky */
	.loader--wrapper {
		position: absolute;
	}
	section.about
	{
		@media screen and (max-width: 768px) {
			padding-top: 0px;
		}
	}
	.layer.edisiliburan
	{
		.odd--row, .even--row
		{
			margin-bottom: 50px;
		}
		@media screen and (max-width: 767px) {
			figure
			{
				position: relative;
				height: auto;
			}
			.desc--pad
			{
				margin-top: 0px;
			}
			.odd--row, .even--row
			{
				margin-bottom: 0px;
			}
		}
	}
	.container-center
	{
		display: inline-block;
		width: 100%;
		text-align: center;
		margin-top: 100px;
		margin-bottom: 200px;
		@media screen and (max-width: 767px) {
			margin-bottom: 70px;
		}
		.center
		{
			text-align: center;
			display: inline-block;
			width: 100%;
			max-width: 1170px;
		}

		#content-only.golucky-content
		{
			// width:calc(50% - 60px);
			width: 100%;
			float: left;
			margin-top: 0px;
			padding: 0px;
			margin-top: 125px;
			&.edisiliburan
			{
				margin-top: 50px;
				div.container.golucky
				{
					&:after
					{
						background-color: #a4dbe4;
					}
				}
			}
			&.info
			{
				padding-bottom: 0px;
				p
				{
					font-size: 14px;
					margin:0px;
					@media screen and (max-width: 768px) {
						padding-bottom: 30px;
					}
				}
				.link
				{
					margin-top: 0px;
					margin-bottom: 0px;
					top: 35px;
					position: relative;
				}
			}
			> div.container
			{
				&.golucky
				{
					position: relative;
					width: 100%;
					box-shadow: none;
					padding-top: 0px;
					> .info
					{
						padding-top: 30px;
					}
					&:after
					{
						z-index: -1;
						top: -30px;
						position: absolute;
						height: 100%;
						width: calc(100% + 60px);
						background: #343b41;
						left: -30px;
						content: "";
					}
				}
			}
			.contact
			{
				width: 300px;
			}
			@media screen and (max-width: 768px) {
				margin-top:100px;
				margin-bottom:100px;
				width:90%;
				display:inline-block;
				float:none;
				max-width:500px;
				a.contact
				{
					letter-spacing: 1px;
					width: 90%;
					bottom: -10px;
					position: absolute;
					left: 0;
					right: 0;
					margin: auto;
					line-height: 15px;
					padding: 20px;
				}
			}
		}

		section.blog-golucky
		{
			width: 50%;
			height: 440px;
			float: right;
			text-align: center;
			position: relative;

			.blog-container
			{
				display: inline-block;
				width: 100%;
				text-align: center;
				> .title
				{
					text-align: center;
					color: #fff;
					font-size: 16px;
					letter-spacing: 2px;
					&:after
					{
						margin:30px auto;
						display:block;
						width: 30px;
						height: 2px;
						background-color: #fff;
						content: "";
					}
					@media screen and (min-width: 768px) {
						display:none;
					}
				}
				a
				{
					color: inherit;
					font-size: inherit;
				}
				.blog
				{
					position: relative;
					height: 440px;
					width: 50%;
					background: #fff;
					display: inline-block;
					box-shadow: 0 2px 29px 0 rgba(0, 0, 0, 0.19);
					.title
					{
						height: 220px;
						width: 100%;
						display: table;
						p
						{
							display: table-cell;
							vertical-align: middle;
							margin: 0px;
							padding: 0 31px;
							font-size: 18px;
							letter-spacing: 1px;
							text-align: left;
						}
					}
					.image
					{
						box-shadow: 0px -6px 0px 0px #343b41;
						height: 220px;
						width: 100%;
						background-color: #2b2b2b;
						background-size: cover;
						background-position: center;
					}
					button.read-more
					{
						opacity: 0;
						transition: all 0.5s;
						width: 210px;
						right: 0;
						bottom: 0;
						padding: 0px 30px;
						top: 0;
						position: absolute;
						left: 0;
						margin: auto;
						height: 70px;
						text-align: left;
						background-color: $gopoint;
						color: #ffffff;
						border-radius: 5px;
						&:after
						{
							content: url(https://d3naj63yelf4gk.cloudfront.net/dist/img/header/arrow.png);
							position: relative;
							left: 10px;
							top: 2px;
							transition: all 0.5s;
						}
						@media screen and (max-width: 768px) {
							opacity:1;
							font-size:14px;
						}
					}
					&:hover
					{
						button.read-more
						{
							opacity: 1;
							transition: all 0.5s;
						}
					}
					@media screen and (max-width: 767px) {
						width:80%;
						margin-bottom:100px;
					}
				}
				&:after
				{
					position: absolute;
					height: 80%;
					width: 100%;
					background: $gopoint;
					top: 0;
					bottom: 0;
					left:0;
					margin: auto;
					content: "";
					z-index: -1;
					@media screen and (max-width: 767px) {
						display: none;
					}
				}

				svg
				{
					opacity: 0.3;
					ellipse
					{
						stroke:#fff;
					}
					polygon
					{
						fill: #fff;
					}

					&.left
					{
						position: absolute;
						left: 30px;
						bottom: 0;
						top: 0;
						margin: auto;
					}
					&.right
					{
						position: absolute;
						right: 30px;
						bottom: 0;
						top: 0;
						margin: auto;
					}
					@media screen and (max-width: 767px) {
						top:auto !important;
						bottom:30px !important;
					}
				}
				@media screen and (max-width: 768px) {
					margin-top:0px;
				}
				@media screen and (max-width: 767px) {
					padding:50px 0;
				}
			}

			@media screen and (max-width: 768px) {
				width:100%;
			}
			@media screen and (max-width: 767px) {
				background-color:$gopoint;
				height:auto;
			}
		}
		@media screen and (max-width: 768px) {
			margin-top:0px;
		}
	}
	.how-it-works--wrapper
	{
		margin: 30px;
		.desc--pad
		{
			a
			{
				font-size: inherit;
				letter-spacing: normal;
			}
		}
		@media screen and (max-width: 767px) {
			margin: 0px;
			.desc--pad
			{
				margin-top: 52%;
			}
		}
	}
	.link.golucky
	{
		font-size: 18px;
		letter-spacing: 2px;
		a
		{
			width: 300px;
		}
	}
	a.regular {
		text-transform: none !important;
		color: #48af4a;
		font-size: inherit;
		letter-spacing: 1px;

		&:after {
			content: none;
		}
	}
	p
	{
		letter-spacing: normal;
	}
	h4.head-table
	{
		margin-bottom: 0px;
		&.search-title
		{
			margin-bottom: 30px;
		}
		&:after
		{
			display: block;
			position: relative;
			margin: auto;
			height: 5px;
			margin: 10px auto;
			width: 60px;
			margin-top: 20px;
			background: #485460;
			content: "";
		}
	}
	.bg-services
	{
		opacity: 1;
	}
	section.snk
	{
		font-family: Open Sans,Helvetica,sans-serif;
		background-color: #f8f8f8;
		img {
			width: 100%;
		}
		ol
		{
			li
			{
				margin-bottom: 10px;
			}
		}
		.container
		{
			width:90%;
			max-width: 1170px;
			padding: 100px 0;
			padding-top: 10px;
			.option
			{
				border-top: 5px solid $gopoint;
				h2.title
				{
					margin: 25px 0;
					font-weight:300;
				}
				ul
				{
					padding-left: 0px;
					margin: 0px;
					list-style-type: none;
					li
					{
						font-family: Open Sans,Helvetica,sans-serif;
						position: relative;
						cursor: pointer;
						padding-left: 70px;
						margin-bottom: 30px;
					}
					li.active::before
					{
						content: "";
						background: $gopoint;
						position: absolute;
						width: 50px;
						top: 45%;
						left: 0px;
						height: 2px;
					}
					li.active
					{
						color: $gopoint;
					}

				}
			}
			.content
			{
				font-family: Open Sans,Helvetica,sans-serif;
				color: #8c8c8c;
				line-height: 25px;
				display: none;
				ol
				{
					padding-left: 20px;
					margin: 0px;
					line-height: 25px;
				}
				a
				{
					letter-spacing: normal;
					color: #58c75b;
					font-size: inherit;
				}
				b
				{
					color: #2b2b2b;
				}
				.penghargaan
				{
					margin-bottom: 50px;
					h4,p
					{
						margin:0px;
					}
				}
			}
			.content.active
			{
				display: block;
			}
			.back
			{
				display: none;
				h3
				{
					text-align: center;
					margin-top:50px;
					color: #48af4a;
				}
				a
				{
					font-size: inherit;
				}
			}
		}
		@media screen and (max-width: 768px) {
			margin: 0px;
			padding:0px !important;
			.container
			{
				padding:50px 0;
				.option
				{
					width:75%;
				}
				h2.title
				{
					margin: 30px 0 !important;
					font-size: 30px;
				}
			}
			.content
			{
				h4
				{
					font-size: 16px;
					line-height: normal;
					margin-bottom: 5px !important;
				}
			}
		}
		section.timeline {
			ul
			{
				li
				{
					.wrapper
					{
						div
						{
							min-height: inherit;
						}
					}
				}
			}
		}
		section.section-our-values
		{
			width:100%;
			padding: 30px;
			h4
			{
				font-size: 14px;
			}
			h5.more
			{
				display: inline-block !important;
			}
			.categories
			{
				margin-bottom: 30px;
			}
		}
		.judges .title, .categories .title
		{
			position: relative;
			padding-left: 30px;
			text-align: left;
		}
		.judges-desc
		{
			position: relative;
			height: 150px;
			h4
			{
				font-size: 16px !important;
			}
			h5
			{
				font-size: 14px !important;
			}
			button.float
			{
				border-radius: 4px 0px 0 4px;
				right: 0;
				padding: 15px 40px;
				top: -20px;
				position: absolute;
				font-size: 12px;
				font-weight: 300;
				color: white;
				background-color: rgb(0, 148, 68);
			}
			button.float::after
			{
				content: url(https://d3naj63yelf4gk.cloudfront.net/dist/img/header/arrow.png);
				position: absolute;
				padding-left: 10px;
				top: 12px;
				right: 10px;
				transform: scale(0.7);
				width: 20px;
			}
		}
	}
	.search-golucky
	{
		text-align: center;
		font-size: 14px;
		color: #485460;
		width: 100%;
		display: inline-block;
		.limit
		{
			display: inline-block;
			float: left;
			.option
			{
				display: inline-block;
				position: relative;
				select
				{
					font-weight: 700;
					display: inline-block;
					padding: 5px;
					-webkit-appearance:none;
					border:none;
					margin:0 5px;
					padding-right: 20px;
					background: #ffffff;
				}
				i
				{
					position: absolute;
					top: 8px;
					right: 9px;
					font-size: 12px;
				}
			}
			@media screen and (max-width: 767px) {
				display:none;
			}
		}
		.box
		{
			float: right;
			display: inline-block;
			position: relative;
			input
			{
				border:none;
				padding: 5px;
				border-radius: 5px;
				margin-left: 5px;
				width: 300px;
				padding-left: 10px;
				@media screen and (max-width: 767px) {
					width: 215px;
				}
			}
			i
			{
				cursor: pointer;
				position: absolute;
				right: 5px;
				height: 18px;
				top: 0;
				bottom: 0;
				margin: auto;
			}
			@media screen and (max-width: 767px) {
				float:none;
			}
		}
	}
	.pagination-golucky
	{
		text-align: center;
		display: inline-block;
		width: 100%;
		margin-bottom: 50px;
		> div
		{
			font-weight: 700;
			border-radius: 5px;
			cursor: pointer;
			display: inline-block;
			padding: 5px 8px;
			background: #ffffff;
			color: #485460;
			margin-right: 10px;
			margin-top: 10px;

			&:hover {
				background: #f79520;
				color: #fff;
			}
			&:last-child
			{
				margin-right:0px;
			}
			&.active
			{
				background: $gopoint;
				color: #ffffff;
			}
		}
	}
	.rect--secondary
	{
		background: #e8ebf2 !important;
	}
	.rect--primary
	{
		background: $gopoint !important;
	}

	h4.section--title.mekanisme
	{
		&::before
		{
			background: $gopoint;
		}
		@media screen and (min-width: 769px) {
			color: #4a4a4a;
			text-transform: uppercase;
		}
		@media screen and (max-width: 768px) {
			left:-35px;
			text-transform:none;
			&::before
			{
				background: $gopoint;
				left: -4em !important;
				top:11px !important;
			}
		}
		&.edisiliburan
		{
			&::before
			{
				background: #a4dbe4;
			}
			@media screen and (min-width: 769px) {
				color: #4a4a4a;
				text-transform: uppercase;
			}
			@media screen and (max-width: 768px) {
				margin-bottom: 30px;
				&::before
				{
					background: #a4dbe4;
				}
			}
		}
		&.edisigoride
		{
			&::before
			{
				background: $gojek;
			}
			@media screen and (min-width: 769px) {
				color: #4a4a4a;
				text-transform: uppercase;
			}
			@media screen and (max-width: 768px) {
				margin-bottom: 30px;
				&::before
				{
					background: $gojek;
				}
			}
		}
	}

	.golucky.spacing
	{
		height: 60px;
		background: #8dc63f;
		&.edisiliburan
		{
			background-color: #a4dbe4;
		}
	}

	.hero.golucky
	{
		.pure-g
		{
			box-shadow: none;
		}
		&:after
		{
			display: none;
		}
	}

	.select-top
	{
		display: none;
		margin-top: -3em;
		padding: 0;
		margin: 0 auto;
		border: 0;
		width: 300px;
		border-radius: 0;
		overflow: hidden;
		background: #fff;
		position: absolute;
		top: auto;
		bottom: -20px;
		left: 0;
		right: 0;
		box-shadow: 0 3px 6px 0 rgba(0,0,0,.13);
		&.hero
		{
			position: relative;
			margin: 50px auto !important;
			bottom: 0;
		}
		.bg--select
		{
			width: 50px;
			height: 100%;
			background: $gopoint url(https://d3naj63yelf4gk.cloudfront.net/dist/img/select-more.svg) no-repeat 55% 50%;
			position: absolute;
			right: 0;
			z-index: 1;
			background-size: 13px;
		}
		select
		{
			padding: 15px;
			width: 105%;
			border: none;
			box-shadow: none;
			background-color: transparent;
			background-image: none;
			-webkit-appearance: none;
			-moz-appearance: none;
			appearance: none;
			position: relative;
			z-index: 5;
		}
		@media screen and (max-width: 768px) {
			display:block;
		}
	}

	.services
	{
		.container
		{
			p
			{
				margin-top: 0px;
				text-align: center;
				font-size: 14px;
			}
			.step
			{
				font-size: 30px;
				margin-top: 20px;
				font-weight: 700;
				color: $gopoint;
				text-align: center;
				font-family: Neo;

			}
			@media screen and (max-width: 768px) {
				&:before
				{
					display:none;
				}
				h4
				{
					text-align: center;
					padding-left: 0px;
					line-height: 20px;
				}
			}
		}
		&.how-to-token
		{
			padding-top: 0px !important;
			padding-bottom: 250px !important;
		}
		@media screen and (max-width: 767px) {
			margin-top:20px;
			padding-top:0px !important;
			padding-bottom:0px !important;
			.navigation-arrow
			{
				padding-top: 15.5em;
				z-index: 10;
				position: relative;
				background-color: transparent;
			}
		}
	}
	section.about
	{
		.description
		{
			p
			{
				font-size: 14px;
			}
			position: relative;
			@media screen and (max-width: 35.5em) {
				position: static;
			}
		}
		h4.title--content
		{
			@media screen and (max-width: 35.5em) {
				margin: 0px;
				margin-top: 15px;
				top: 0;
				margin-left: 5px;
				position: absolute;
				font-size: 14px;
				padding-left: 0px;
			}
		}
		.container-desc-home-career {
			@media screen and (max-width: 35.5em) {
				margin-top: 50px;
				margin-left: 0px;
			}
		}
		.container
		{
			@media screen and (max-width: 768px) {
				&:before
				{
					display:none;
				}
			}
		}
	}

	#winner-btn {
		padding: 0;
		margin-bottom: 30px;

		button.cta-golucky {
			margin: 0;
		}
	}

	.container-leaderboard
	{
		margin: auto;
		text-align: center;
		width: 90%;
		padding: 20px 0;
		max-width: 960px;

		.title-prize {
			font-family: 'Open Sans';
			font-size: 18px;
			font-weight: bold;
			letter-spacing: 1px;
			text-align: center;
			text-transform: uppercase;
			color: #f7951d;
		}

		&.home
		{
			max-width: 1170px;
			@media screen and (max-width: 767px) {
				margin-top: 40px;
			}
		}
		@media screen and (max-width: 767px) {
			width: 100%;
			padding: 10px;
			text-align: left;
		}
	}
	button.cta-golucky
	{
		background: $gopoint;
		border-radius: 5px;
		color: #ffffff;
		font-size: 18px;
		padding: 20px;
		letter-spacing: 2px;
		padding: 30px;
		width: 300px;
		margin: 0 auto !important;
		display: block;
		@media screen and (max-width: 768px) {
			margin: auto;
			margin-top: 30px;
			padding: 24px;
			font-size: 12px;
		}
	}
	.container-table-golucky
	{
		width: 100%;
		text-align: center;
		overflow: auto;
		position: relative;
		@media screen and (max-width: 767px) {
			box-shadow: inset -42px 0px 100px 7px rgb(248, 248, 248);
		}
	}
	.table-golucky
	{
		font-size: 14px;
		position: relative;
		z-index: -1;
		margin: 20px 0;
		.header
		{
			>.pure-g
			{
				&.goride {
					background: $gojek;
				}
				background: $gopoint;
				color: #ffffff;
				font-weight: 700;

				> div.number
				{
					text-align:right;
				}
			}
		}
		.pure-g > div
		{
			padding: 15px;
			text-align: left;
			&.number
			{
				text-align:right;
			}
		}
		.content
		{
			color: #485460;

			> div:nth-child(odd)
			{
				background: #ffffff;
			}
		}
		@media screen and (max-width: 767px) {
			margin:30px 0;
			width:900px;
			padding-right:100px;
		}
	}

	.about-golucky
	{
		text-align: center;
		display: inline-block;
		width: 100%;
		background: #ffffff;
		padding: 60px;
		margin-bottom: 30px;
		&.edisiliburan
		{
			.container-image
			{
				@media screen and (max-width: 768px) {
					display: none;
				}
			}
			.image-mobile-edisiliburan
			{
				display: none;
				@media screen and (max-width: 768px) {
					display: block;
					margin:auto;
					margin-top: 20px;
					width: 90%;
					img
					{
						width: 100%;
					}
				}
			}
		}
		@media screen and (max-width: 768px) {
			background-size: 500px;
			background-position: 100% 135%;
			background-image: url(https://d3naj63yelf4gk.cloudfront.net/dist/img/faq/geometry.svg);
			background-repeat: no-repeat;
			padding: 30px 10px;
			.switch
			{
				display: table;
				width: 100%;
			}
		}
		h4.title--content
		{
			margin-top: 0px;
			@media screen and (max-width: 768px) {
				text-align: left;
			}
		}
		p
		{
			max-width: 700px;
			font-size: 14px;
			margin: auto;
			text-align: center;
		}
		.container-switch
		{
			margin: auto;
			position: relative;
			width: 100%;
			max-width: 1170px;
			.center-image
			{
				background-size: cover;
				background-position: center;
				position: absolute;
				height: 100%;
				width: 50%;
				left: 0;
				right: 0;
				margin: auto;
				top: 30px;
				height: 400px;
				z-index: 2;
				@media screen and (max-width: 768px) {
					position: relative;
					width: calc(100% + 20px);
					left:-10px;
					top: 0;
				}
				@media screen and (max-width: 500px) {
					height:250px;
				}
			}
			@media screen and (max-width: 768px) {
				display:table-caption;
				margin:20px 0;
			}
		}
		.container-image
		{
			width: 100%;
			max-width: 1170px;
			height: 400px;
			background: #ececec;
			margin: auto;
			margin-top: 30px;
			display: inline-block;
			position: relative;
			.left-image
			{
				position: absolute;
				left: 0;
				top: 0;
				width: 25%;
				height: 100%;
				> div
				{
					background-size: cover !important;
					background-position: center;
					position: relative;
					float: left;
					width: 100%;
					height: 200px;
					@media screen and (max-width: 768px) {
						width:50%;
						height:100%;
					}
				}
				@media screen and (max-width: 768px) {
					position:relative;
					height:150px;
					top:0;
					width:100%;
				}
				@media screen and (max-width: 500px) {
					height:100px;
				}
			}
			.right-image
			{
				position: absolute;
				right: 0;
				top: 0;
				height: 100%;
				width: 25%;
				margin: auto;
				> div
				{
					position: relative;
					float: left;
					width: 100%;
					height: 133.2px;
					background-size: cover;
					background-position: center;
					@media screen and (max-width: 768px) {
						height: 100%;
						width: 33.3%;
					}
				}
				@media screen and (max-width: 768px) {
					height:75px;
					bottom:0;
					top:auto;
					width:100%;
					position:relative;
				}
				@media screen and (max-width: 500px) {
					height:50px;
				}
			}
			@media screen and (max-width: 768px) {
				height:auto;
				max-width:500px;
			}
		}
	}
	.container-note-golucky
	{
		width: calc(100% + 120px);
		left: -60px;
		position: relative;
		display: inline-block;
		text-align: center;
		margin-bottom: 50px;
		p.note-golucky
		{
			color: #fff;
			display: inline-block;
			margin: 0px;
			padding: 40px 80px;
			background-color: $gopoint;
		}
	}
	p.note-golucky
	{
		font-size: 14px;
		padding: 10px;
		max-width: 370px;
		text-align: left;
		margin: 30px auto;
	}
	section.timeline {
		position: relative;
		padding: 30px 0;
		padding-bottom: 130px;
		margin-top: 60px;
		&.edisiliburan
		{
			padding-bottom: 0px;
			.title
			{
				h4::before
				{
					background-color: #a4dbe4;
				}
			}
		}
		.title {
			h4 {
				font-weight: 700;
				margin-bottom: 0px;
				font-family: $opensans;
				background: $gopoint;
				padding: 30px;
				color: #ffffff;
				line-height: 0.78;
				letter-spacing: 1.9px;
				position: relative;
				padding-left: 50px;
				text-transform: uppercase;
			}
			h4::before {
				content: "";
				background-color: #353c41;
				position: absolute;
				width: 50px;
				height: 4px;
				top: 50%;
				left: -25px;
			}
		}
		ul {
			list-style: none;
			margin: 0 auto;
			padding: 0;
			margin-top: 100px;
			li {
				width: 25%;
				float: left;
				position: relative;
				.wrapper {
					height: 180px;
					width: 300px;
					margin: 0 5px;
					padding: 15px 15px;
					div {
						min-height: 250px;
						background: #ffffff;
						padding: 15px;
						border-bottom: 4px solid #dbdbdb;
						h4 {
							line-height: 1.42;
							letter-spacing: 2.2px;
							text-align: center;
							color: #000000;
							font-weight: 500;
							height: 40px;
						}
						h4:first-child {
							font-weight: bold;
						}
					}
				}
			}
			li.done {
				.wrapper {
					div {
						border-bottom: 4px solid $gopoint;
					}
				}
			}
			li.done:before {
				content: "";
				position: absolute;
				font-size: 13px;
				font-weight: 400;
				margin-left: -47px;
				height: 10px;
				width: 10px;
				border-radius: 50%;
				text-align: center;
				line-height: 27px;
				z-index: 1;
				background: #ffffff;
				top: -50px;
				left: 65%;
				border: 5px solid $gopoint;
			}
			li.done:after {
				content: "";
				position: absolute;
				z-index: 0;
				background: $gopoint;
				width: 100%;
				height: 3px;
				bottom: 0;
				top: -43.5px;
				left: -50%;
			}
			li:before {
				content: "";
				position: absolute;
				font-size: 13px;
				font-weight: 400;
				margin-left: -47px;
				height: 10px;
				width: 10px;
				border-radius: 50%;
				text-align: center;
				line-height: 27px;
				z-index: 1;
				background: #ffffff;
				top: -50px;
				left: 65%;
				border: 5px solid #dbdbdb;
			}
			li:after {
				content: "";
				position: absolute;
				z-index: 0;
				background: #dbdbdb;
				width: 100%;
				height: 3px;
				bottom: 0;
				top: -43.5px;
				left: -50%;
			}
			li:first-child:after {
				content: none;
			}
		}
		.extra-notes
		{
			font-weight:700;
			position: absolute;
			right: 0;
			bottom: 50px;
			text-align: right;
			width: 995px;
			left: 0;
			right: 0;
			margin: auto;
			@media screen and (max-width: 768px) {
				font-size: 12px;
				width: 100%;
				box-sizing: border-box;
				padding-right: 40px;
			}
		}
		.edisiliburan-time
		{
			ul
			{
				li
				{
					@media screen and (min-width: 769px) {
						width: auto;
						height: auto;
					}
					.wrapper
					{
						height: auto;
					}
					&.done
					{
						&:before
						{
							background-color: #a4dbe4;
						}
						.wrapper > div
						{
							border-color:#a4dbe4;
						}
					}
				}
			}
		}
		@media screen and (max-width: 768px) {
			padding-bottom: 100px;
		}
	}
	.service-container
	{
		background: $gopoint;
		visibility: hidden;
		&.slick-initialized
		{
			visibility: visible;
		}
		&.edisiliburan
		{
			p
			{
				a:after
				{
					font-size: inherit;
					letter-spacing: normal;
					display: none;
				}
			}
		}
	}
	h4
	{
		&.goride {
			color: $gojek;
		}
		color: $gopoint;
		@media screen and (max-width: 768px) {
			font-size: 14px;
		}
	}
	.table-display
	{
		display: table;
		height: 100%;
		.middle-table
		{
			vertical-align: middle;
			display: table-cell;
		}
	}
	.container-inline
	{
		display: inline-block;
		width: 100%;
		text-align: center;
		@media screen and (max-width: 767px) {
			text-align: left;
			padding: 10px;
			h4
			{
				padding: 10px;
			}
		}

	}
	.option-bar
	{
		overflow: hidden;
		position: relative;
		z-index: 10;
		display: inline-block;
		width: 100%;
		max-width: 960px;
		background: #ffffff;
		font-size: 14px;
		letter-spacing: 1px;
		font-family: $opensans;
		color: #485460;
		border-radius: 4px;
		transform: translateY(-50%);
		box-shadow: 0 3px 6px 0 rgba(0,0,0,.13);
		a
		{
			display: inline-block;
			width: 100%;
			padding: 25px 0;
			color: inherit;
			font-size: inherit;
			letter-spacing: 1.5px;
		}
		.pure-g > .child-option
		{
			position: relative;
			border-right: 1px solid #ececec;
			&:last-child
			{
				border-right:none;
			}

			& > a.active
			{
				&:after
				{
					position: absolute;
					width: 100%;
					height: 4px;
					left: 0;
					background: $gopoint;
					bottom: 0px;
					content: "";
				}
			}
		}
		@media screen and (max-width: 768px) {
			display:none;
		}
	}



	.join {
		.messages-alert {
			position: fixed;
			display: none;
			background-color: rgba(250, 50, 50, .9);
			width: 100%;
			padding: 20px;
			text-transform: capitalize;
			margin-top: -45px;
			z-index: 99;
			top: 86px;
			margin-bottom: -80px;
			box-shadow: 0px -5px 25px rgba(10, 10, 10, .85);

			h5 {
				color: white;
				font-size: 15px;
				margin: 0;
				padding: 0;
			}
		}

		@-webkit-keyframes uil-ring-anim {
			0% {
				-webkit-transform: rotate(0deg);
				transform: rotate(0deg);
			}
			100% {
				-webkit-transform: rotate(360deg);
				transform: rotate(360deg);
			}
		}
		@-moz-keyframes uil-ring-anim {
			0% {
				-webkit-transform: rotate(0deg);
				transform: rotate(0deg);
			}
			100% {
				-webkit-transform: rotate(360deg);
				transform: rotate(360deg);
			}
		}
		@-webkit-keyframes uil-ring-anim {
			0% {
				-webkit-transform: rotate(0deg);
				transform: rotate(0deg);
			}
			100% {
				-webkit-transform: rotate(360deg);
				transform: rotate(360deg);
			}
		}
		@-o-keyframes uil-ring-anim {
			0% {
				-webkit-transform: rotate(0deg);
				transform: rotate(0deg);
			}
			100% {
				-webkit-transform: rotate(360deg);
				transform: rotate(360deg);
			}
		}
		@keyframes uil-ring-anim {
			0% {
				-webkit-transform: rotate(0deg);
				transform: rotate(0deg);
			}
			100% {
				-webkit-transform: rotate(360deg);
				transform: rotate(360deg);
			}
		}
		.uil-ring-css {
			background: none;
			position: relative;
			width: 200px;
			position: relative;
			margin: 0 auto;
			display: none;
			/* display: none; */
			top: 370px;
			div {
				position: absolute;
				display: block;
				width: 160px;
				height: 160px;
				top: 20px;
				left: 20px;
				border-radius: 80px;
				box-shadow: 0 3px 0 0 #98ce00;
				-webkit-transform-origin: 80px 81.5px;
				transform-origin: 80px 81.5px;
				-webkit-animation: uil-ring-anim 1s linear infinite;
				animation: uil-ring-anim 1s linear infinite;
			}
		}

		section.join {
			margin: 120px 0;

			.hidden {
				display: none;
			}
			.banner {
				img {
					height: 100%;
					width: 100%;
				}
			}

			.label,
			label {
				text-transform: none !important;
			}

			.title {
				font-weight: 300;
				text-transform: uppercase;
			}

			.group-title {
				font-weight: 300;
				text-transform: uppercase;
			}

			.form-1,
			.form-2 {
				padding: 50px;
				background: white;
				position: relative;
				.error-input {
					border: 1px solid #E0164A !important
				}
				.error-messages {
					color: red;
					font-style: italic;
				}
				.button-disabled {
					cursor: not-allowed;
					box-shadow: none !important;
					background: rgb(200, 200, 200) !important;
				}

				.combo-box {
					position: relative;
				}

				.combo-box:after {
					content: '\f0d7';
					font-family: 'FontAwesome';
					flex-direction: column;
					justify-content: center;
					right: 15px;
					top: 30px;
					text-align: right;
					position: absolute;
				}

				.combo-box-long:after {
					top: 50px;
				}

				.title {
					position: relative;
					font-size: 20px;
					padding-left: 30px;
					padding: 0;

					p {
						font-size: 13px;
						font-weight: 400;
						letter-spacing: 1.5px;
						color: #bcbdc0;

						span {
							margin-right: 5px !important;

							&:before {
								font-weight: 400;
							}
						}
					}
				}

				.text-info {
					margin-top: 30px;
					position: relative;
					padding-left: 25px;
					line-height: 1.46;
					letter-spacing: 1px;
					color: #bcbdc0;
					p:first-child {
						text-transform: uppercase;
					}
					p {
						margin: 0;
					}
				}
				.text-info::before {
					content: "";
					height: 3px;
					width: 15px;
					background: #093c5b;
					position: absolute;
					left: 0;
					top: 20%;
				}
				.label,
				label {
					color: #2DA144;
					font-size: 14px;
					font-family: $opensans;
					margin-bottom: 1em;
					letter-spacing: 1.5px;
					font-weight: 400;
					margin: 0;
					text-transform: uppercase;
				}
				textarea {
					resize: none;
					width: 100%;
					height: 234px;
				}
				input[type=text],
				input[type=number],
				input[type=email],
				select,
				textarea {
					border: 1px solid #c3c9d7;
					width: 100%;
					border-radius: 0;
					box-shadow: none;
					-webkit-appearance: none;
				}
				input[type=text],
				input[type=number],
				input[type=email],
				textarea {
					padding: 15px;
				}
				select {
					height: 45px;
					text-transform: capitalize;
					padding: 0 15px;
				}

				::-webkit-input-placeholder {
					color: rgb(220, 220, 220);
				}
				:-moz-placeholder {
					color: rgb(220, 220, 220);
					opacity: 1;
				}
				::-moz-placeholder {
					color: rgb(220, 220, 220);
					opacity: 1;
				}
				:-ms-input-placeholder {
					color: rgb(220, 220, 220);
				}
				::-ms-input-placeholder {
					color: rgb(220, 220, 220);
				}
				span.normal {
					text-transform: initial;
				}
				span.required {
					color: #b51410;
				}
				.divident {
					margin-top: 5px;
					margin-bottom: 15px;
				}
				.submit {
					button {
						border-radius: 5px;
						background-color: #1f4864;
						box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.19);
						color: #fff;
						float: right;
						position: absolute;
						bottom: -30px;
						right: 50px;
						padding: 20px 50px;
						width: 300px;
					}
				}
			}
			.form-2 {
				.text-info:before {
					top: 40%;
				}
				.rating {
					.start,
					.finish {
						font-size: 14px;
					}
					p {
						display: block;
					}
					.start {}
					.finish {}
					ul {
						padding: 0;
						li {
							float: left;
							margin: 0 6%;
							list-style: none;
							width: 20px;
							height: 20px;
							border: 1px solid #485460;
							border-radius: 50%;
							position: relative;
							input[type="radio"] {
								box-shadow: 0px 0px 5px 0 rgba(0, 0, 0, .09);
								-webkit-appearance: none;
								height: 20px;
								width: 20px;
								border-radius: 50%;
							}
							input[type="radio"]:hover {
								cursor: pointer;
							}
							input[type="radio"]:checked {
								background: #98ce00;
								height: 16px;
								width: 16px;
								top: 1px;
								left: 1px;
								border-radius: 50%;
								position: absolute;
							}
						}
					}
				}
				.combo-box:after {
					top: 50px;
				}
				.submit {
					.back {
						right: 40%;
						background: #343b41;
					}
				}
			}
		}
	}
	header.ramadhan {
		margin-top: 68px;
		p {
			font-family: $lato !important;
			font-size: 18px;
		}
		.desc--wrapper {
			height: 100%;
		}
	}
	.navbar.ramadhan {
		.btn-style-1-icon {
			background-color: #3c95d1;
		}
		.brand img {
			top: 8.5px;
		}
	}
	#promo-banner {
		.detail {
			bottom: 0;
		}
		.img-item {
			border: none;
		}
	}
	.how-it-works--wrapper.ramadhan {
		.desc--pad {
			ol {
				li {
					font-size: 16px;
					padding: 0 0.5em;
					color: #485460;
					font-family: $opensans !important;
					font-weight: 300;
					line-height: 1.62;
					letter-spacing: 0.8px;
				}
			}
		}
	}
	#join-now.ramadhan {
		.right {
			p {
				font-size: 1em;
				color: #ffffff;
			}
		}
	}
	#bg-as-seen-on.ramadhan .as-seen-on img {
		max-width: 250px;
		min-height: 85px;
	}
	.section-user-review.ramadhan {
		padding-top: 0;
		padding-bottom: 0;
		.head {
			h3 {
				color: #343b41;
			}
		}
		.line.gradient {
			background: $gopoint;
		}
		.body {
			.wrapper {
				height: 225px;
			}
			.style-1,
			.style-3 {
				background: #343b41 !important;
			}
			.style-2 {
				background: $gopoint !important;
			}
			.slick-dots li.slick-active button::before {
				color: $gopoint;
			}
		}
		h3.user-name {
			font-weight: 500 !important;
		}
	}
	section.ramadhan {
		.container:before {
			background-color: $gopoint;
		}
		.description {
			p {
				font-size: 16px;
			}
		}
		h4 {
			color: #343b41;
		}
		.section--title:before {
			background-color: $gopoint !important;
		}
	}
	#bg-as-seen-on.ramadhan .as-seen-on p {
		color: #343b41;
	}
	.how-it-works--wrapper {
		.container
		{
			padding-top: 0px;
		}
		.layer {
			padding-bottom: 10px;
			.rect--secondary {
				background: $gopoint;
			}
			@media screen and (max-width: 35.5em) {
				margin-bottom:100px;
			}
		}
	}
	.how-it-works--wrapper-mobile {
		display: none;
	}
	.articles--feed {
		.bg {
			background: #343b41;
		}
		.line--title {
			background: $gopoint;
		}
		ellipse {
			stroke: $gopoint !important;
		}
		polygon {
			fill: $gopoint !important;
		}
	} // End Ramadhan
	.section-our-values {
		color: $gopoint;
		padding: 3em;
		background: #f8f8f8;
		.title,
		img,
		p {
			margin-bottom: 3em;
		}
		.line.gradient {
			background: $gopoint;
		}
		img {
			max-width: 250px;
		}
		p {
			font-size: 14px;
		}
		h3:first-child {
			font-weight: 700;
		}
		h3 {
			font-weight: 500;
			color: $gopoint;
		}
	}
	#bg-as-seen-on {
		padding-top: 0;
		.as-seen-on {
			p {
				color: $gopoint;
			}
			hr {
				background: $gopoint;
			}
			#content {
				img {
					padding: 1.5em;
				}
			}
			#right {
				background: $gopoint;
			}
			img {
				max-height: 120px;
			}
		}
	}
	#join-now {
		a {
			background: $gopoint;
		}
	}
	.merchant-list {
		padding-bottom: 0;
		h5 {
			color: $gopoint;
		}
		.content {
			.blog-card-img {
				position: relative;
				overflow: hidden;
				width: 100%;
				height: 80px;
				border: 1px solid #cecece;

				img {
					position: relative;
					top: 50%;
					left: 50%;
					-webkit-transform: translate(-50%, -50%);
					-ms-transform: translate(-50%, -50%);
					transform: translate(-50%, -50%);
				}
			}

			.blog-card-title {
				min-height: 250px;
			}
		}
	}
	#content-only {
		.container {
			box-shadow: 0 -3em 0 1.5em $gopoint;
			h4 {
				color: $gopoint;
				line-height: 1.5;
			}
			.line {
				background: $gopoint;
			}
			.contact {
				background: $gopoint;
			}
		}
	}
}

@media screen and (max-width: 35.5em) {
	#gopoint {
		header.ramadhan {
			// background-image: url(https://d3naj63yelf4gk.cloudfront.net/dist/img/header/hero/ramadhan/gopoint/mobile.jpg) !important;
			.desc--row {
				bottom: 25%;
			}
			.desc--wrapper .hero--desc {
				display: inherit;
			}
		}

		section.join {
			.banner {
				img {
					height: 120px !important;
				}
			}
		}
		section.ramadhan {
			padding-bottom: 0;
			.description {
				p {
					font-size: 12px;
				}
			}
		}

		.how-it-works--wrapper.desktop {
			display: none;
		}
		.how-it-works--wrapper-mobile {
			display: block;
			li {
				font-family: $opensans !important;
				font-size: 12px;
				font-weight: 300;
				line-height: 1.62;
				letter-spacing: 0.8px;
			}
		}
		.section-our-values {
			padding: 0;
			margin-top: 50px;
		}
		#bg-as-seen-on {
			img {
				padding: 0;
			}
		}
		.merchant-list {
			h5 {
				color: $gopoint;
			}
			.content {
				.blog-card-title {
					min-height: 140px;
				}
			}
		}
	}
}

@media screen and (min-width: 35.6em) {
	#gopoint {
		#join-now {
			max-width: 150%;
		}
	}
}

@media screen and (max-width: 320px) {
	#gopoint {
		header.ramadhan {
			background-size: contain;
		}

		section.join {
			.form-1 {
				padding: 15px !important;
			}
		}
	}
}

@media screen and (min-width: 48em) {
	#gopoint {
		#bg-as-seen-on.ramadhan {
			.title {
				hr {
					top: 3.3em;
				}
				p {
					font-size: 18px;
				}
			}
		}
	}
}

@media screen and (max-width: 768px) {
	#gopoint {
		section.timeline {
			ul {
				margin: 20px auto 0 auto;
				li {
					margin-left: 5%;
					margin-bottom: 0px;
					float: none;
					.wrapper {
						height: 100%;
						padding:10px 0px;
						width: 450px;
						div
						{
							min-height: inherit;
						}
						h4 {
							margin: 0;
							height: auto !important;
						}
					}
				}
				li.done::before {
					left: 0%;
					top: 18%;
				}
				li.done:after {
					height: 100%;
					width: 3px;
					left: -49%;
					top: -65%;
				}
				li:before {
					left: 0%;
					top: 18%;
				}
				li:after {
					height: 100%;
					width: 3px;
					left: -35%;
					top: -50% !important;
				}
			}
		}
	}
}

@media screen and (max-width: 767px) {
	#gopoint {
		section.timeline {
			padding-bottom: 0px;
			padding-top: 0px;
			ul {
				margin: 20px auto 0 auto;
				li {
					margin-left: 5%;
					margin-bottom: 0px;
					float: none;
					.wrapper {
						height: 100%;
						padding:10px 0px;
						width: 250px;
						div
						{
							min-height: inherit;
						}
						h4 {
							margin: 0;
							height: auto !important;
						}
					}
				}
				li.done::before {
					left: 25%;
					top: 30%;
				}
				li.done:after {
					height: 100%;
					width: 3px;
					left: -35%;
					top: -65%;
				}
				li:before {
					left: 25%;
					top: 35%;
				}
				li:after {
					height: 100%;
					width: 3px;
					left: -35%;
					top: -65%;
				}
			}
		}
	}
}
