#golife {
	section.section-our-values
	{
		&.new
		{
			padding-top: 0px;
			img {
				max-height: 150px;
				width: auto;
			}
			h4.title
			{
				color: $golife;
				@media screen and (max-width: 767px)
				{
						&:after
						{
							top: 30px;
						    position: absolute;
						    width: 15px;
						    left: -20px;
						    background-color: #4285a7;
						    height: 2px;
						    content: "";
						}
				}
			}
			.list
			{
				svg
				{
					height: 140px;
					@media screen and (max-width: 767px)
					{
						height: 130px;
					}
					&.adjust
					{
						position: relative;
						left: -20px;
					}
				}
				h4
				{
					@media screen and (min-width: 768px)
					{
						margin-top: 50px;
						margin-bottom: 40px;
					}
					color: #4a4a4a;
					@media screen and (max-width: 767px)
					{
						padding: 0px;
					    margin-bottom: 0px;
					    margin-top: 20px;
					}
				}
				p
				{
					font-family: $opensans;
					@media screen and (min-width: 768px)
					{
						margin-top:10px;
					}
					@media screen and (max-width: 767px)
					{
						max-width: 300px;
					}
				}
			}
			.line
			{
				background: $golife;
				@media screen and (max-width: 767px)
				{
					display: none;
				}
			}
			h4.title
			{
				@media screen and (max-width: 767px)
				{
					font-size: 14px;
					text-align: left;
				}
			}
			#type-gobills
			{
				width: 100%;
				.arrow-left
				{
					position: absolute;
					left: 0;
					bottom: -40px;
					width:50px;
				}
				.arrow-right
				{
					position: absolute;
					right: 0;
					bottom: -40px;
					width:50px;
				}
				.slick-arrow.slick-disabled
				{
					filter: grayscale(100) brightness(1.5);
				}
				@media screen and (min-width: 768px)
				{
					margin-top:40px;
				}
			}
			@media screen and (max-width: 767px)
			{
				padding:20px;
				padding-bottom:100px;
				margin-top:40px;
				.container-slider
				{
					width:100%;
					padding:0px;
					position:relative;
					display:inline-block;
				}
			}
		}
	}

	section.new-our-partner
	{
		background-color: #fafafa;
		text-align: center;
		margin-bottom: 100px;
		.container-slide
		{
			display: inline-block;
			max-width: 1170px;
			position: relative;
			height: 460px;
			width:90%;
			.title
			{

				z-index: 1;
				position: absolute;
				width: 50%;
				padding-left: 20px;
				font-size: 18px;
				color:$golife;
				right:0;
				text-align: left;
				font-weight: 700;
				height:100px;
				letter-spacing: 1px;
				&:before
				{
					@media screen and (min-width: 769px) {
						position: absolute;
					    width: 35px;
					    left: -25px;
					    height: 3px;
					    background: #49af4b;
					    top: -5px;
					    bottom: 0;
					    content: "";
					    margin: auto;
					}
				}
				&:after
				{
					content: "";
				    display: inline-block;
				    vertical-align: middle;
				    height: 100%;
				    @media screen and (max-width: 768px) {
						height:3px;
						width:50px;
						margin:20px auto;
						background: #49af4b;
						display:block;
						content:"";
					}
				}
				@media screen and (max-width: 768px) {
					margin-top:40px;
					position:relative;
					height:auto;
					padding-left:0px;
					width:100%;
					text-align:center;
					font-size:14px;
				}
			}
			.hero
			{
				float: left;
				height: 460px;
				width:50%;
				background: #ebebeb;
				.image-hero
				{
					height: 460px;
					width: 100%;
					background-size: cover;
					background-position: center;
				}
				@media screen and (max-width: 768px) {
					width:100%;
				}
			}
			.comment
			{
				position: absolute;
				height: calc(100% - 100px);
				background: #fff;
				right: 0;
				bottom:0;
				width: 50%;
				.content
				{
					text-align: left;
					position: absolute;
				    height: 80%;
				    width: 70%;
				    top: 0;
				    bottom: 0;
				    left: 7%;
				    font-size: 14px;
				    margin: auto;
				    @media screen and (max-width: 768px) {
				    	width: 100%;
				    	position: relative;
				    	left: auto;
				    	padding: 30px;
				    }
				}
				.container-comment
				{
					display: table;
					width: 100%;
					height: 300px;
					left: 0;
					font-size: 16px;
					line-height: 28px;
					color: #9b9b9b;
					font-family: $opensans;
					font-weight: 100;
					.user
					{
						color: #2b2b2b;
						margin-top: 10px;
						span
						{
							font-weight: 700;
						}
					}
					> div
					{
						display: table-cell;
						vertical-align: middle;
					}
					@media screen and (max-width: 768px) {
						text-align:center;
					}
				}
				@media screen and (max-width: 768px) {
					position:relative;
					width:100%;
					float:left;
					height:500px;
				}
			}
			.float-arrow
			{
				height: 45px;
			    width: 45px;
			    position: absolute;
			    right: -90px;
			    bottom: 0;
			    top: 0;
			    margin: auto;
			    &.arrow-left
			    {
			    	transform: rotate(90deg);
			    	top: -55px;
			    	&.slick-disabled
			    	{
			    		filter: grayscale(100) brightness(1.4);
			    	}
			    	@media screen and (max-width: 768px)
					{
						top:auto;
						bottom:-50px;
						left:20px;
						right:auto;
						transform:none;
					}
			    }
			    &.arrow-right
			    {
			    	transform: rotate(90deg);
			    	top:55px;
			    	&.slick-disabled
			    	{
			    		filter: grayscale(100) brightness(1.4);
			    	}
			    	@media screen and (max-width: 768px)
					{
						right:20px;
						top:auto;
						bottom:-50px;
						left:auto;
						transform:none;
					}
			    }
			    @media screen and (max-width: 768px)
				{
					right:0;
					left:0;
				}
			}
			@media screen and (max-width: 768px) {
				height:auto;
				width:100%;
			}
		}
		@media screen and (max-width: 768px) {
			background-color:#fff;
			margin-bottom:30px;
		}

		.slick-dots
		{
			left:0;
			> li
			{
				margin:0px;
				margin-right: 5px;
				filter: grayscale(100) brightness(1.4);
				&.slick-active
				{
					filter: grayscale(0);
				}
				button
				{
					width:10px;
					height: 10px;
					background: $golife;
					border-radius: 50%;
					&:before
					{
						display:none;
					}
				}
				&:last-child
				{
					margin-right: 0px;
				}
			}
		}
	}

	section#new-join
	{
		margin-bottom: 100px;
	}
	#content-only.thank-you
	{
		margin-top: 130px;
		a.contact
		{
			width:300px;
		}
		@media screen and (max-width: 768px) {
			margin-bottom: 130px;
			a.contact
			{
				width:100%;
				line-height: normal;
			}
		}
	}


	section .slider--hide {
		visibility: hidden;
	}
	section .slick-slider.slick-initialized {
		visibility: visible;
	}
	header {
		overflow: visible;
		.desc--wrapper {
			.desc--row {
				.hero--desc {
					margin-bottom: 30px;
				}
				a.hero--button {
					display: inline-block;
					bottom: 0px;
					position: relative;
					margin-top: 10px;
					color: white;
					background-color: $golife;
					text-transform: uppercase;
					border-radius: 5px;
					padding:30px 40px;
					-webkit-transition: all .3s ease-in-out;
					-moz-transition: all .3s ease-in-out;
					-o-transition: all .3s ease-in-out;
					transition: all .3s ease-in-out;
					svg {
						width: 12px;
						position: absolute;
						right: 20px;
						top: 50%;
						-webkit-transition: all .3s ease-in-out;
						-moz-transition: all .3s ease-in-out;
						-o-transition: all .3s ease-in-out;
						transition: all .3s ease-in-out;
						-webkit-transform: translateY(-50%);
						-moz-transform: translateY(-50%);
						-o-transform: translateY(-50%);
						transform: translateY(-50%);
					}
				}
				a.hero--button:hover {
					box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.19);
					color: $golife;
					background: #fff;
					svg {
						g {
							fill: $golife;
						}
					}
				}
			}
		}
	}
	section.articles--feed {
		.content .blog-card-title {
			border-top-left-radius: 8px;
			border-top-right-radius: 8px;
		}
	}
	section.services {
		padding: 50px 0 12em;
		.container:before {
			left: -2em;
			top: 52px;
			padding-right: 45px;
		}
		h4 {
			padding-bottom: 0;
			font-size: 1em;
			line-height: 4em;
			position: relative;
		}
		.wrapper {
			padding-top: 0;
			.service-container {
				.slick-track {
					// margin-left: -35% !important;
				}
				.service-list {
					width: 20em;
					.bg-services {
						height: 12em;
					}
					p {
						min-height: 125px;
					}
				}
			}
			img.service-logo {
				top: 50%;
				width: 155px;
				position: absolute;
				-webkit-transform: translateY(-50%);
				-moz-transform: translateY(-50%);
				-o-transform: translateY(-50%);
				transform: translateY(-50%);
			}
		}
	}
	section.about {
		.img-wrapper .img-container {
			margin-top: -85px;
		}
		.slick-dotted {
			margin-bottom: 0;
		}
		.slick-dots {
			bottom: -40px;
			li.slick-active button:before {
				color: $gojek;
			}
		}
	}
	section#download-cards {
		display: none;
	}
	section#bg-as-seen-on .as-seen-on {
		.title p {
			font-size: 1em;
			line-height: 0.8em;
		}
		#right {
			background: #24a749;
		}
		#left {
			background: #ffcd2e;
		}
	} // Tabet View
	@media screen and (max-width: 768px) {
		section.about {
			h4 {
				padding-bottom: 75px;
			}
		}
	}
	/* Mobile View */
	@media screen and (max-width: 35.5em) {
		a.hero--button
		{
			bottom: -15px !important;
			position: absolute !important;
			right: 0;
			text-align: center;
		    left: 0;
		    width: 190px;
		    margin: auto;
		    padding: 20px 0 !important;
		}
		.hero--desc {
			margin-bottom: 0px !important;
		}
		section.about {
			padding-bottom: 0;
			h4 {
				padding-bottom: 0;
			}
			.img-wrapper {
				.img-container {
					margin-top: 0;
				}
			}
			.slick-dots {
				bottom: -25px;
			}
		}
		section.services {
			padding-bottom: 4em;
			h4 {
				font-size: .688em;
			}
			.container:before {
				left: -1em;
				top: 35px;
			}
			.wrapper {
				.service-container {
					.slick-track {
						margin-left: 5% !important;
					}
					.service-list {
						width: 15em;
						.bg-services {
							height: 10em;
						}
					}
				}
				img.service-logo {
					width: 100px;
					right: 0;
				}
			}
		}
		section#download-cards {
			display: block;
			padding: 30px 0;
			.bg-right {
				padding: 0;
			}
			.download-list {
				&>a {
					display: inline-block;
					float: left;
					padding: 12px 0;
					&>p {
						font-size: 1.1em;
						margin: 0;
						color: #24a749;
					}
					&>svg {
						margin: 6px 10px;
						g {
							fill: #24a749;
						}
					}
				}
				.download-btn.ios {
					background-color: #656565;
				}
				.download-btn {
					width: 50px;
					height: 50px;
					background-size: 35%;
				}
			}
		}
		section#bg-as-seen-on {
			padding: 30px 0;
		}
	}
}
