#faq-social {
	margin-bottom: 3em;
	text-align: center;
	font-family: Lato;
	h3 {
		// font-size: 36px;
		line-height: 1.28;
		letter-spacing: 1.1px;
	}
	hr {
		height: 4px;
		width: 50px;
		background-color: #ffcd2e;
		border: none;
	}
	p {
		// font-size: 18px;
		font-weight: 300;
		font-style: normal;
		font-stretch: normal;
		line-height: 1.56;
		letter-spacing: 1px;
		text-align: center;
		a {
			font-weight: normal;
			color: #4bbf49;
			text-decoration: none;
		}
	}
	.link {
		margin-top: 3em;
		padding: 1em;
		background: #0bb2ee;
		color: white;
	}
}

@media screen and (min-width: 48em) {
	#faq-social {
		p {
			margin: 3em auto;
		}
	}
}

@media screen and (min-width: 64em) {
	#faq-social {
		p {
			margin: 2em 15em;
		}
	}
}
