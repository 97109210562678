// File Name: _gojek.scss
// Description: Sample style for GO-JEK 2.0
// Used by: Main.scss
// Dependencies:
// ------------------------------------------------------------

#gojek {
	/*starwars header */
	@-webkit-keyframes uil-ring-anim {
		0% {
			-webkit-transform: rotate(0deg);
			transform: rotate(0deg);
		}
		100% {
			-webkit-transform: rotate(360deg);
			transform: rotate(360deg);
		}
	}
	@-moz-keyframes uil-ring-anim {
		0% {
			-webkit-transform: rotate(0deg);
			transform: rotate(0deg);
		}
		100% {
			-webkit-transform: rotate(360deg);
			transform: rotate(360deg);
		}
	}
	@-webkit-keyframes uil-ring-anim {
		0% {
			-webkit-transform: rotate(0deg);
			transform: rotate(0deg);
		}

		100% {
			-webkit-transform: rotate(360deg);
			transform: rotate(360deg);
		}
	}
	@-o-keyframes uil-ring-anim {
		0% {
			-webkit-transform: rotate(0deg);
			transform: rotate(0deg);
		}
		100% {
			-webkit-transform: rotate(360deg);
			transform: rotate(360deg);
		}
	}
	@keyframes uil-ring-anim {
		0% {
			-webkit-transform: rotate(0deg);
			transform: rotate(0deg);
		}
		100% {
			-webkit-transform: rotate(360deg);
			transform: rotate(360deg);
		}
	}
	.uil-ring-css {
		background: none;
		position: relative;
		width: 200px;
		height: 200px;
		margin: 0 auto;
		display: block;

		div {
			position: absolute;
			display: block;
			width: 160px;
			height: 160px;
			top: 20px;
			left: 20px;
			border-radius: 80px;
			box-shadow: 0 3px 0 0 $gojek;
			-webkit-transform-origin: 80px 81.5px;
			transform-origin: 80px 81.5px;
			-webkit-animation: uil-ring-anim 1s linear infinite;
			animation: uil-ring-anim 1s linear infinite;
		}
	}

	header.starwars-hero
	{
		background-color: #000;
		h1.hero--title.starwars-hero
		{
			max-width: 700px !important;
			.glow
			{
				text-shadow: 0 0 50px rgba(255, 0, 3, 0.8), 0 0 3px rgba(255, 0, 0, 0.5);
			}
			@media screen and (max-width: 768px) {
				font-size:35px;
				max-width:none !important;
			}
			@media screen and (max-width: 767px) {
				font-size:18px;
			}
			@media screen and (min-width: 2000px)
			{
				font-size:54px;
			}
		}
		.desc--wrapper
		{
			@media screen and (max-width: 768px) {
				left: auto;
			}
		}
		.sub-desc--row
		{
			@media screen and (max-width: 768px) {
				text-align: center;
				padding-bottom: 17%;
				vertical-align: bottom;
			}
		}
		img.sw-gojek
		{
			margin-bottom: 30px;
			@media screen and (max-width: 768px) {
				display: inline-block;
				max-width: 200px;
				margin-bottom: 30px;
			}
			@media screen and (max-width: 767px) {
				display: inline-block;
				max-width: 100px;
				margin-bottom: 10px;
			}
		}
		button
		{
			background-color: $starwars;
			box-shadow: 0 0 4px 2px rgba(255, 0, 3, 0.8);
			border: solid 1px rgba(255, 0, 0, 0.9);
			&:hover
			{
				color:#fff;
			}
			&.download
			{
				@media screen and (max-width: 768px) {
					left: 0;
				}
			}
		}
		button.go
		{
			font-size: 25px;
			background-color: $starwars;
		}
		> .pure-g
		{
			box-shadow: none;
		}
		&:after
		{
			box-shadow: none;
		}
	}

	.post-item--new {
		background-color: #ffffff;
		border-radius: 5px;
		margin-bottom: 30px;

		.thumbnail--img {
			height: 280px;
			background-size: cover !important;
			background-position: center !important;
			border-top-left-radius: 5px;
			border-bottom-left-radius: 5px;
			cursor: pointer;
		}

		.post--detail {
			padding: 0px 25px;
			position: relative;

			h3 {
				letter-spacing: unset !important;
				font-weight: unset !important;

				a {
					font-family: $opensans;
					font-size: 18px;
					font-weight: 600;
					letter-spacing: .75px !important;
					text-align: left;
					color: #4a4a4a;
				}
			}

			p {
				color: #4a4a4a;
				font-weight: 400;
				margin: 0;
				padding: 0;
				margin-top: -18.25px;
			}

			.service--info {
				width: 100%;
				padding: 5px 20px;
				position: absolute;
				bottom: 0px;
				left: 0px;
				border-bottom-right-radius: 5px;

				.wrapper {
					margin-bottom: -15px;
				}

				.service--logo {
					width: 45px;
					height: 45px;
					display: inline-block;
					background-size: 25px !important;
				}

				.service--text {
					margin-top: -40.5px;
					margin-left: 50px;
					color: #fff;
					font-weight: 700;
					font-family: $opensans;

					span {
						position: relative;
						top: 0px;
						font-weight: 300;
						font-size: 11px;
					}
				}
			}

			.service--info[data-service*="go-ride"] {
				background-color: $goride;

				.service--logo {
					@include icon-goride;
				}
			}
			.service--info[data-service*="go-food"] {
				background-color: $gofood;
				.service--logo {
					@include icon-gofood;
				}
			}
			.service--info[data-service*="go-pay"] {
				background-color: $gopay;
				.service--logo {
					@include icon-gopay;
				}
			}
			.service--info[data-service*="go-tix"] {
				background-color: $gotix;
				.service--logo {
					@include icon-gotix;
				}
			}
			.service--info[data-service*="go-jek"] {
				background-color: $gojek;
				.service--logo {
					@include icon-gojek;
				}
			}
			.service--info[data-service*="go-send"] {
				background-color: $gosend;
				.service--logo {
					@include icon-gosend;
				}
			}
			.service--info[data-service*="go-mart"] {
				background-color: $gomart;
				.service--logo {
					@include icon-gomart;
				}
			}
			.service--info[data-service*="go-car"] {
				background-color: $gocar;
				.service--logo {
					@include icon-gocar;
				}
			}
			.service--info[data-service*="go-box"] {
				background-color: $gobox;
				.service--logo {
					@include icon-gobox;
				}
			}
			.service--info[data-service*="go-point"] {
				background-color: $gopoint;
				.service--logo {
					@include icon-gopoint;
				}
			}
			.service--info[data-service*="go-pulsa"] {
				background-color: $gopulsa;
				.service--logo {
					@include icon-gopulsa;
				}
			}
			.service--info[data-service*="go-busway"] {
				background-color: $gobusway;
				.service--logo {
					@include icon-gobusway;
				}
			}
			.service--info[data-service*="go-med"] {
				background-color: $gomed;
				.service--logo {
					@include icon-gomed;
				}
			}
			.service--info[data-service*="go-massage"] {
				background-color: $gomassage;
				.service--logo {
					@include icon-gomassage;
				}
			}
			.service--info[data-service*="go-clean"] {
				background-color: $goclean;
				.service--logo {
					@include icon-goclean;
				}
			}
			.service--info[data-service*="go-auto"] {
				background-color: $goauto;
				.service--logo {
					@include icon-goauto;
				}
			}
			.service--info[data-service*="go-glam"] {
				background-color: $goglam;
				.service--logo {
					@include icon-goglam;
				}
			}
			.service--info[data-service*="go-life"] {
				background-color: $golife;
				.service--logo {
					@include icon-golife;
				}
			}
			.service--info[data-service*="go-bills"] {
				background-color: $gobills;
				.service--logo {
					@include icon-gobills;
				}
			}



		}
	}

	@media only screen and (max-width: 35.5em) {
		.post-item--new {
			border-radius: 0 !important;
			transform: scale(1.085);
			margin-bottom: 14px;

			.thumbnail--img {
				height: 170px;
				border-radius: 0 !important;
			}

			a {
				line-height: 18px;
				font-size: 14px;
			}

			p {
				display: none;
			}
		}
	}

	@media only screen and (max-width: 320px) {
		.post-item--new {
			a {
				line-height: 5px;
				font-size: 12px !important;
			}
		}
	}

	.blogNav {
		background: #485460;
		width: 100%;
		padding: 0;
		margin-top: 85px;
		position: fixed;
		z-index: 10;
		transition: all .5s ease 0s;
		ul {
			margin: 0px;
			padding: 0px;
			&.first
			{
				a
				{
					padding: 20px 30px !important;
					background-color: $gojek;
				}
			}
			&.middle
			{
				float: left;
				padding-left: 50px;
				> li
				{
					display: inline-block;
					&:hover
					{
						transition:all 0.5s;
						background-color: #2a3d4e;
						.blogMegamenu
						{
							opacity: 1;
							pointer-events:visible;
							transition:all 0.3s;
						}
					}
				}
			}
			&.last {
				float: right;
				position: relative;
				right: -12.5px;
				i
				{
					line-height: 0px;
				}
			}

			text-align: center;
			list-style-type: none;

			li {
				a.trigger {
					display: inline-block;
					padding: 20px 10px;
					font-family: $opensans;
					color: #fff;
					font-size: 12.5px;
					text-align: center;
					text-decoration: none;
					letter-spacing: 1px;
					top: 0;
				}
			}
		}
		.mobileBlogNavbar
		{
			bottom: 0;
			letter-spacing:2.1px;
			font-weight: 300;
			top: 0;
			margin:auto;
			color: #fff;
			.content
			{
				position: relative;
				padding: 17px 15px;
				display: inline-block;
				left: -15px;
				&.active
				{
					background-color: #2a3d4e;
				}
			}
			@media only screen and (min-width: 769px) {
				display: none;
			}
		}
		.desktopBlogNavbar
		{
			@media only screen and (max-width: 768px) {
				display: none;
			}
		}
		&.active
		{
			@media only screen and (min-width: 769px) {
				transform:translateY(-85px);
			}

			+ .blogNavSearch
			{
				@media only screen and (min-width: 769px) {
					transform:translateY(-85px);
				}
			}
			+ .blogNavSearchResult
			{
				@media only screen and (min-width: 769px) {
					transform:translateY(-85px);
				}
			}
		}
	}
	.blogNavSearchMobile
	{
		position: fixed;
		width: 100%;
		background-color: #2a3d4e;
		padding: 20px 0;
		left: 0;
		top: 112px;
		z-index: 10;
		color: #fff;
		pointer-events:none;
		opacity: 0;
		transition:all 0.3s;
		&.active
		{
			pointer-events:visible;
			opacity: 1;
			transition:all 0.3s;
		}
		.kategori
		{
			margin-bottom: 20px;
		}
		.container-select
		{
			position: relative;
			margin-top: 10px;
			select
			{
				background-color: #fff;
				padding: 10px;
				width: 100%;
				color: #4a4a4a;
			}
			i
			{
				position: absolute;
				color: #4a4a4a;
				right: 15px;
				font-size: 25px;
				height: 25px;
				top:0;
				bottom: 0;
				margin: auto;
			}
		}
	}
	

	.blogMegamenu {
		opacity: 0;
		pointer-events:none;
		transition:all 0.5s;
		position: fixed;
		background-color: #2a3d4e;
		width: 100%;
		left: 0;
		background-color: #2a3d4e;
		box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.09);
		width: 100%;
		padding: 25px 0;
		text-align: left;
		p {
			color: #FFF;
			font-size: 12px;
			position: relative;
			margin: 0px;
		}

		ul {
			text-align: left;
			list-style-type: none;
			padding-left: 0px;
			li {
				list-style-type: none;
				margin-bottom: 10px;

				a {
					font-family: $opensans;
					font-size: 12.5px;
					color: #FFF;
					letter-spacing: 1.3px;
				}
			}
		}

		.first-wrapper.last {
			ul {
				position: relative;
				left: 35px;
			}
		}

		&.active {
			margin-top: 50px;
		}

		&.slide {
			display: block;
		}
	}
	.blogNavSearch
	{
		transition:all 0.5s ease 0s;
		opacity: 0;
		pointer-events:none;
		&.active
		{
			transition:all 0.5s ease 0s;
			opacity: 1;
			pointer-events:visible;
		}
	}

	.blogNavSearch,
	.blogNavSearchResult {
		background: #2a3d4e;
		width: 100%;
		padding: 20px 0;
		margin-top: 135px;
		margin-bottom: -85px;
		position: fixed;
		z-index: 1999;

		.wrapper {
			position: relative;
			left: 10px;
		}

		#searchSubmit {
			background: unset;
			padding: 0;
			position: relative;
			top: 14.25px;
			left: 15px;
			color: #fff;
			font-family: $opensans;
			font-weight: 400;
			font-size: 18px;
			line-height: 0.8;
			letter-spacing: 3.3px;
			text-align: left;
			color: #ffffff;

			i {
				position: relative;
				left: 10px;
				top: -1px;
			}
		}

		form {
			input {
				width: 100%;
				height: 43.5px;
				font-family: $opensans;
				border-radius: 5px;
				border: 0;
				padding: 10px 20px;
				position: relative;
				top: 2.5px;
			}

			::-webkit-input-placeholder {
				color: #babcbf;
				font-style: italic;
			}
			::-moz-placeholder {
				color: #babcbf;
				font-style: italic;
			}
			:-ms-input-placeholder {
				color: #babcbf;
				font-style: italic;
			}
			:-moz-placeholder {
				color: #babcbf;
				font-style: italic;
			}
		}

		ul {
			&.first {
				position: relative;
				left: -85px;
				text-align: left;

				li {
					a {
						font-size: 18px;
						position: relative;
						top: -3.5px;
					}
				}
			}

			&.middle {
				position: relative;
				left: -195px;

				li {
					a {
						background: unset;
						padding: 18px;
					}
				}
			}

			&.last {
				float: right;
				position: relative;
				right: -12.5px;
			}

			text-align: center;
			list-style-type: none;

			li {
				display: inline-block;

				a {
					font-family: $opensans;
					color: #fff;
					text-align: center;
					padding: 16px 40px;
					text-decoration: none;
					letter-spacing: 1px;
					top: 0;
				}
			}
		}
	}

	.blogNavSearchResult {
		display: none;
		padding: 0;
		z-index: 9 !important;
		transition: all 0.3s ease 0s;

		h4.title {
			content: "";
			font-family: $opensans;
			font-size: 22px;
			font-weight: 400;
			text-align: center;
			color: #ffffff;
			line-height: 25px;
			@media only screen and (max-width: 768px) {
				color: #4a4a4a;
				font-size: 14px;
				letter-spacing: normal;
			}
		}
		@media only screen and (max-width: 768px) {
			position: relative;
			background-color: #ececec;
		}
	}

	.blog-loader {
		margin-top: 180px;
	}

	.blog-found {
		margin-top: 200px;
		display: none;
		@media only screen and (max-width: 768px) {
			margin-top: 65px;
		}
	}

	.blog-not-found {
		margin-top: 180px;
		display: none;
		@media only screen and (max-width: 768px) {
			margin-top: 65px;
		}

		.post {
			.articles--feed {
				padding: 0 !important;
			}
		}

		.text {
			h5 {
				font-family: $opensans;
				font-size: 18px;
				font-weight: 400;
				text-align: center;
				color: #4a4a4a;
			}
		}
	}

	@media only screen and (max-width: 768px) {
		.blogNav {
			margin-top: 60px;
			margin-bottom: 0;

			&.active {
				margin-top: 60px;
			}

			ul {
				&.first,
				&.middle {
					display: none;
				}

				&.last {
					right: unset;

					li {
						a {
							font-size: 15px;
							left: -5px;
						}
					}
				}
			}
		}

		.blogNavSearch,
		.blogNavSearchResult {
			margin-top: 110px;

			&.active {
				margin-top: 110px;
			}

			.wrapper {
				left: 0;
			}

			.first-wrapper {
				display: none;
			}

			#searchSubmit {
				text-align: center;
				width: 100%;
				background: $gojek;
				padding: 17px 0px;
				top: 2px;
				font-size: 14px;
				left: 0;
				border-top-right-radius: 5px;
				border-bottom-right-radius: 5px;

				i {
					display: none;
				}
			}

			form {
				input {
					border-top-right-radius: 0px;
					border-bottom-right-radius: 0px;
				}

				::-webkit-input-placeholder {
					color: #fff;
					font-style: italic;
				}
				::-moz-placeholder {
					color: #fff;
					font-style: italic;
				}
				:-ms-input-placeholder {
					color: #fff;
					font-style: italic;
				}
				:-moz-placeholder {
					color: #fff;
					font-style: italic;
				}
			}

			ul {
				&.first {
					display: none;
				}
			}
		}

		.blogNavSearchResult {
			.first-wrapper {
				display: block !important;
			}
		}
	}

	.header {
		padding: 5.375em 0;
		color: $black;

		.left {
			font-family: $opensans;
			text-align: left;
		}
		.right {
			$font-family: $opensans;
			text-align: right;
			line-height: $line-height;
			font-size: 1.2em;
		}
		.content {
			margin-bottom: 0;

			span {
				color: $gojek;
			}
		}
	}
	.services {
		.slick-arrow {
			cursor: pointer;

			ellipse {
				stroke: $gojek;
			}
			polygon {
				fill: $gojek;
			}
		}
	}

	section {
		.content-subhead {
			color: #4a4a4a;
			font-family: $opensans;
			font-size: 1.6em;
			font-weight: bold;
			font-style: normal;
			font-stretch: normal;
			line-height: $line-height;
			letter-spacing: 4px;
		}

		button.normal {
			color: #fff;
			background-color: #48af4a;
		}
		button.hover {
			@include button-hover(#48af4a);
		}
		button.active {
			@include button-active(#48af4a);
		}

		.anchor {
			padding: 3em 0;

			a {
				@include anchor-mobile(#98ce00);
			}

			.hover {
				@include anchor-hover(#48af4a, true);
			}

			.active {
				@include anchor-active(#98ce00);
			}

		}
	}

	.articles--feed {
		svg {
			ellipse {
				stroke: #2c822e !important;
			}
			polygon {
				fill: #2c822e;
			}
		}
	}

	.gojekin-hero
	{
		z-index: 2;
		margin-bottom: -100vh;
		position: relative;
		height: 200vh;
		width:100%;
		background-color: #48af4a;
		.arrow-down
		{
			cursor: pointer;
			text-align: center;
			padding: 30px 0;
			color: #ffffff;
			position: fixed;
			width:100%;
			bottom: 0;
			z-index: 10;
			left: 0;
			font-family: $opensans;
			font-size: 12px;
			letter-spacing: 1px;
			i
			{
				font-size: 20px;
				margin-top: 10px;
			}
			&.green-arrow
			{
				color:#48af4a;
				transition: all 0.5s;
			}
			&.disactive
			{
				transition: all 1s;
				opacity: 0;
			}
			@media screen and (max-width: 768px) {
				position:absolute;
			}
		}
		.container-logo
		{
			position: sticky;
			width: 100%;
			height: calc(50% - 84px);
			top: 84px;
			@media screen and (max-width: 768px) {
				position: relative;
				height: 100%;
				top: 0;
				#driver
				{
					animation: hideDriver 0.5s forwards 1s;
				}
				#jek
				{
					animation: geserkiri 0.5s forwards 1.25s;
				}
				#ct-gojek
				{
					animation: scaling 0.5s forwards 1.7s;
				}
				#hashtag svg
				{
					animation: upAnimationTwo 0.5s forwards 2s;
				}
			}
			.sub-logo
			{
				position: absolute;
				width: 100%;
				height: 100%;
				display: table;
				top: 0;
				right: 0;
				left:0;
				bottom: 0;
				margin: auto;
				> div
				{
					overflow: hidden;
					display: table-cell;
					vertical-align: middle;
					text-align: center;
				}
			}
			.logo
			{
				position: relative;
				@media screen and (min-width: 769px) {
					svg
					{
						width: auto !important;
					}
				}
				@media screen and (max-width: 768px) {
					svg
					{
						width: auto;
						height: 10vw;
						max-height: 55px;
					}
					.small svg
					{
						height: 8vw;
						max-height:45px;
					}
					.j-animation svg
					{
						height: 13vw;
						max-height: 65px;
					}
				}
				> div:first-child
				{
					margin-left:0px;
				}
				> div
				{
					position: relative;
					display: inline-block;
				}
				> div > div
				{
					position: relative;
					display: inline-block;
				}
				> div > div > div
				{
					display: inline-block;
					overflow: hidden;
					display: inline-block;
					padding-left: 7px;
					@media screen and (max-width: 768px) {
						padding-left: 3px;
					}
				}
				.j-animation
				{
					position: relative;
					bottom: -22px;
					padding-left: 2px !important;
					@media screen and (max-width: 768px) {
						transform: translateY(0px);
						bottom: -10px;
						left: -1px;
					}
				}
				#inaja
				{
					bottom: 0;
					position: absolute;
					margin-left: -51px;
					> div
					{
						overflow: hidden;
						padding-left: 7px;
						@media screen and (max-width: 768px) {
							padding-left: 2px;
						}
					}
					svg
					{
						transform: translateY(110px);
					}
					@media screen and (max-width: 768px) {
						transform: scale(0.8);
						bottom: -1px;
						right: 25.5%;
						position: absolute;
						margin-left: auto;

						svg
						{
							transform: translateY(60px) !important;
						}

						$num: 1;
						@while $num < 6 {
							> .sub:nth-child(#{$num}) svg {
								opacity:calc(0 + #{$num});
								animation: upAnimation 0.5s forwards calc(1.9 + (#{$num * 0.1})s);
							}
							$num: $num + 1;
						}
					}
					@media screen and (max-width: 600px) {
						right: 15%;
						svg
						{
							transform: translateY(55px) !important;
						}
					}
					@media screen and (max-width: 320px) {
						right:14.7%;
					}
				}
				#hashtag
				{
					position: absolute;
					bottom: 5px;
					margin-left: -10px;
					overflow: hidden;
					svg
					{
						transform: translateY(-110px);
						@media screen and (max-width: 768px) {
							transform: translateY(-55px);
						}
					}
					@media screen and (max-width: 768px) {
						transform:scale(0.8);
						bottom: 3px;
						margin-left: -38px;
					}
					@media screen and (max-width: 600px) {
						margin-left: -30px;
					}
				}
			}
		}
		.trigger-logo
		{
			position: absolute;
			height: 100%;
			top:25%;
			@media screen and (max-width: 768px) {
				display: none;
			}
		}
		@media screen and (max-width: 768px) {
			margin-top: 60px;
			height: calc(100vh - 60px);
			margin-bottom:0px;
		}
	}
	.content-map
	{
		margin-bottom: -100vh;
		position: relative;
		height: 530vh;
		width:100%;
		background: #fafafa;
		.container-logo
		{
			position: sticky;
			width: 100%;
			height: 100vh;
			top: 0;
			@media screen and (max-width: 768px) {
				position: relative;
				height: auto;
				display: inline-block;
				position: relative;
				padding: 200px 0;
			}
			.sub-logo
			{
				position: absolute;
				width: 100%;
				height: 100%;
				display: table;
				top: 0;
				right: 0;
				left:0;
				bottom: 0;
				margin: auto;
				> div
				{
					display: table-cell;
					vertical-align: middle;
					text-align: center;
					overflow: hidden;
				}
				.center-word
				{
					font-weight: 700;
					font-family: neo;
					transition: all 0.5s;
					font-size:48px;
					color: #2b2b2b;
					font-weight:700;
					width: 100%;
					position: relative;
					overflow: hidden;
					&.active
					{
						transition: all 0.5s;
						transform: scale(1.4);
					}
					> div:first-child
					{
						display:inline-block;
						@media screen and (max-width: 768px) {
							width: 100%;
						}
						> div
						{
							float: left;
							@media screen and (max-width: 768px) {
								width:100%;
							}
						}
						> div.left-word
						{
							left: 0;
							width: calc(50% - 75px);
							text-align: right;
							position: absolute;
							@media screen and (max-width: 768px) {
								width:100%;
								position: relative;
								height: 50px;
								padding-top: 10px;
								text-align: center;
								overflow: hidden;
								margin-bottom: 5px;
							}
							> div
							{
								width: 100%;
								background: #fafafa;
								position: absolute;
								right:0;
								transform: translateY(100px);
								@media screen and (max-width: 768px) {
									transform: translateY(-100px);
								}
							}
							> div:first-child
							{
								transform: translateY(0px);
							}
						}
						> div.right-word
						{
							right: 0;
							width: calc(50% - 75px);
							text-align: left;
							position: absolute;
							@media screen and (max-width: 768px) {
								width:100%;
								position: relative;
								height: 50px;
								text-align: center;
								overflow: hidden;
								margin-top: 5px;
							}
							> div
							{
								width: 100%;
								background: #fafafa;
								position: absolute;
								left: 0;
								transform: translateY(-100px);
							}
							> div:first-child
							{
								transform: translateY(0px);
							}
						}
						.goride
						{
							color: $goride;
						}
						.gocar
						{
							color: $gocar;
						}
						.gosend
						{
							color: $gosend;
						}
						.gomart
						{
							color:$gomart;
						}
						.gotix
						{
							color:$gotix;
						}
						.gofood
						{
							color: $gofood;
						}
						.gopay
						{
							color:$gopay;
						}
						.gobox
						{
							color: $gobox;
						}
						.green
						{
							&.active
							{
								transition: all 0.5s;
								color: $gojek;
							}
						}
					}
					@media screen and (max-width: 768px) {
						font-size:32px;
					}
				}
			}
		}
		.trigger-logo
		{
			position: absolute;
			height: 100%;
			top:auto;
			margin-top: 15%;
			@media screen and (max-width: 768px) {
				display: none;
			}
		}
		@media screen and (max-width: 768px) {
			height:auto;
			margin-bottom:0px;
		}
	}
	.video-gojekin
	{
		position: relative;
		padding: 144px 0;
		height: auto;
		width: 100%;
		text-align: center;
		background: $gojek;
		@media screen and (max-width: 767px) {
			padding: 0px;
			padding-bottom: 100px;
		}
		.container-video
		{
			display: inline-block;
			width:80%;
			max-width: 1100px;
			text-align: center;
			position: relative;
			@media screen and (max-width: 767px) {
				width: 100%;
			}
			.video
			{
				position: relative;
				z-index: 1;
				display: inline-block;
				width:100%;
				float: left;
				height: 500px;
				@media screen and (max-width: 767px) {
					height: 350px;
					display: flex;
					align-items: center;
				}
				iframe
				{
					width: 80%;
					@media screen and (max-width: 767px) {
						width: 100%;
						height: 70%;
					}
				}
			}
			.left
			{
				position: absolute;
				height: 80%;
				left:0;
				bottom: 0;
				width: 50%;
				&:after
				{
					border-radius: 12px 0px 0px 0px;
					position:absolute;
					height: 75%;
					width: 100%;
					background: #8cc63f;
					top:0;
					left: 0;
					content: ""; 
				}
				&:before
				{
					position: absolute;
					height: 25%;
					bottom: 0;
					width: 100%;
					background-image: url('https://d3naj63yelf4gk.cloudfront.net/dist/img/faq/geometry.svg');
					left: 0;
					content: "";
				}
				@media screen and (max-width: 767px) {
					width:10%;
				}
			}
			.right
			{
				position: absolute;
				height: 80%;
				right:0;
				top: 0;
				width: 50%;
				&:after
				{
					border-radius: 0px 0px 12px 0px;
					position:absolute;
					height: 75%;
					width: 100%;
					background: #8cc63f;
					bottom:0;
					left: 0;
					content: "";
				}
				&:before
				{
					position: absolute;
					height: 25%;
					top: 0;
					width: 100%;
					background-image: url('https://d3naj63yelf4gk.cloudfront.net/dist/img/faq/geometry.svg');
					left: 0;
					content: "";
				}
				@media screen and (max-width: 767px) {
					right:auto;
					left:0;
					width:10%;
				}
			}
		}
		.container-about
		{
			margin-top: 35px;
			display: inline-block;
			width: 80%;
			max-width: 1100px;
			text-align: center;
			position: relative;
			@media screen and (max-width: 767px) {
				width: 90%;
				margin-top: 0px;
			}
			.sub-container
			{
				width: 80%;
				display: inline-block;
				position: relative;
				@media screen and (max-width: 767px) {
					width: 90%;
				}
				&:after
				{
					@media screen and (min-width: 768px) {
						position:absolute;
						height: 100%;
						width: 30%;
						left: 10%;
						content: "";
						top: 0;
						background-image: url('https://d3naj63yelf4gk.cloudfront.net/dist/img/faq/geometry.svg');
					}
				}
				.content
				{
					width: 60%;
					background: #fafafa;
					padding: 30px 0;
					float: right;
					border-radius: 0px 12px 12px 0px;
					@media screen and (max-width: 767px) {
						width: 100%;
						border-radius: 0px;
						&:after
						{
							position:absolute;
							width: 100%;
							height: 100px;
							background-color: #fafafa;
							bottom: 0;
							left: 0;
							content: "";
							background-image: url('https://d3naj63yelf4gk.cloudfront.net/dist/img/faq/geometry.svg');

						}
					}

					p
					{
						text-align: left;
						padding-right: 30px;
						padding-left: calc(30px + 10%);
						font-family: $opensans;
						font-size: 14px;
						@media screen and (max-width: 767px) {
							padding-left: 30px;
						}
					}
					img
					{
						width: 46%;
						bottom: 0;
						position: absolute;
						max-width: 100%;
						left: 0;
						top: 0;
						margin: auto;
						z-index: 1;
						@media screen and (max-width: 767px) {
							width: 110%;
							max-width: none;
							left: -5%;
							position: relative;
						}
					}
					.download
					{
						line-height: 30px;
						font-weight: 100;
						font-size: 24px;
						width: 110%;
						max-width: none;
						left: -5%;
						position: relative;
						padding:30px;
						color:#ffffff;
						background-size: cover;
						background-position: center;
						z-index: 1;
					}
					.container-download
					{
						@media screen and (min-width: 768px) {
							display: none;
						}
						button
						{
							top: -20px;
							position: relative;
							color: #ffffff;
							background: #ffcd2e;
							padding: 10px 20px;
							font-size: 12px;
							z-index: 1;
						}
					}
				}
			}
		}
	}
}

/* animations */
@keyframes word
{
	from
	{
		z-index: 0;
		transform: translateY(50px);
	}
	to
	{
		z-index: 1;
		transform: translateY(0px);
	}
}
@keyframes hideDriver
{
	from
	{
		transform: translateX(0px);
	}
	to
	{
		transform: translateX(50px);
		@media screen and (min-width: 768px) {
			transform: translateX(70px);
		}
	}
}
@keyframes geserkiri
{
	from
	{
		transform: translateX(0px);
	}
	to
	{
		transform: translateX(-50%);
	}
}
@keyframes scaling
{
	from
	{
		transform: scale(1);
	}
	to
	{
		transform: scale(0.8) translateX(-25px);
	}
}
@keyframes upAnimation
{
	from
	{
		transform: translateY(50px);
	}
	to
	{
		transform: translateY(0px);
	}
}
@keyframes upAnimationTwo
{
	from
	{
		transform: translateY(-55px);
	}
	to
	{
		transform: translateY(0px);
	}
}


/* Small Screen */
@media screen and (max-width: 768px) {
	#gojek {
		header#main--hero {
			// background-image: url(https://d3naj63yelf4gk.cloudfront.net/dist/img/header/hero/go-jek--mobile.jpg);
		}
	}
}

/* Medium Screen */
@media screen and (min-width: 48em) {
	section {
		.anchor {
			a {
				@include anchor-desktop(#98ce00);
			}
		}
	}
}

/* Large Screen */
@media screen and (min-width: 64em) {

}

/* Extra Large Screen */
@media screen and (min-width: 80em) {

}


/* star wars */
#starwars
{
	.container-gotix-info
	{
		margin-bottom: 60px;
		button
		{
			line-height: normal;
		}
	}
	.gallery--wrapper
	{
		@media screen and (min-width: 769px) {
			margin-top: 100px;
		}
		@media screen and (max-width: 767px) {
			padding-top: 0px;
		}
	}
	section.video-starwars
	{
		display: inline-block;
		max-width: 960px;
		width: 100%;
		text-align: center;
		.info
		{
			font-weight: 100;
			.title
			{
				max-width: 700px;
				margin: 15px auto;
				font-size: 27px;
				font-weight: 700;
				color: $starwars;
				@media screen and (max-width: 767px) {
					font-size: 18px;
				}
			}
			.content
			{
				color: #485460;
				font-size: 21px;
				@media screen and (max-width: 767px) {
					font-size: 14px;
				}
			}
		}
		.ct-video-sw
		{
			height: 400px;
			width: 100%;
			position: relative;
			margin-top: 30px;
			background-size: cover;
			background-position: center;
			background-color: #000;
			@media screen and (max-width: 767px) {
				width: calc(100% + 20px);
				left: -10px;
				margin: 50px 0px;
				background-position: left;
				height: 350px;
			}
			.show-video
			{
				iframe
				{
					height: 400px;
					width: 100%;
				}
			}
			.stop-video
			{
				transition: all 0.5s;
				transform: scale(0);
				position: absolute;
				height: 60px;
				width: 60px;
				right: 0;
				top: 0;
				margin: -30px;
				background: url(../img/card/about/close-btn.png) 50%/29px 29px no-repeat #cd0000;
				border-radius: 50%;
				z-index: 1;
				cursor: pointer;
				@media screen and (max-width: 767px) {
					margin: -30px 0px;
				}
			}
			.container-thumb-video
			{
				position: absolute;
				height: 100%;
				width: 100%;
				left: 0;
				bottom: 0;
				background: #000;
				background-size: cover;
				background-position: center;
				cursor: pointer;
				transition: all 0.5s;
				&.disactive
				{
					opacity: 0;
					pointer-events: none;
					transition:all 0.5s;
					+ .play-button
					{
						opacity: 0;
						transition: all 0.5s;
					}
					+ .play-button + .stop-video
					{
						transform: scale(1);
						transition: all 0.5s;
					}
				}
				@media screen and (min-width: 769px) {
					&:hover
					{
						opacity:0.7;
						transition: all 0.5s;
						+ .play-button img
						{
							transform: translateX(100px);
							transition: all 0.5s;
						}
						+ .play-button:after
						{
							transform: translateX(0px);
							transition: all 0.5s;
						}
					}
				}
				@media screen and (max-width: 767px) {
					background-position:-160px center;
				}
			}
			.play-button
			{
				overflow: hidden;
				pointer-events: none;
				cursor: pointer;
				position: absolute;
				height: 80px;
				width: 80px;
				background: rgba(0,0,0,0.7);
				left: 0;
				right: 0;
				top: 0;
				bottom: 0;
				margin: auto;
				border-radius: 50%;
				line-height: 90px;
				img
				{
					transition: all 0.5s;
				}
				&:after
				{
					font-weight: 100;
					content: "Play Video";
					position: absolute;
					height: 50%;
					width: 80%;
					left: 0;
					top: 0;
					right: 0;
					bottom: 0;
					margin: auto;
					color: #fff;
					line-height: normal;
					transition: all 0.5s;
					transform: translateX(-100px);
				}
			}
			.block-right
			{

				position: absolute;
				right: -20px;
				height: 100%;
				width: 30px;
				top: 0;
				background-repeat: no-repeat;
				background-size: 100% 100%;
				background-position: center;
				@media screen and (max-width: 767px) {
					display: none;
				}
			}
			.block-left
			{

				position: absolute;
				left: -20px;
				height: 100%;
				width: 30px;
				top: 0;
				background-repeat: no-repeat;
				background-size: 100% 100%;
				background-position: center;
				transform: scaleX(-1);
				@media screen and (max-width: 767px) {
					display: none;
				}
			}
		}
	}
	.new-slide-phone
	{
		display: none;
		&.slick-initialized
		{
			display: block;
		}
	}
	.content-sub-menu, ul.list-content
	{
		background: $starwars;
	}
	.ct-sw-merch
	{
		.top-info
		{
			display: inline-block;
			max-width: 860px;
			color: #485460;
			font-weight: 300;
			font-size: 16px;
			text-align: center;
			@media screen and (max-width: 767px) {
				display: block;
				width: 90%;
				text-align: center;
				margin: auto;
				margin-top: 50px;
			}
		}
		@media screen and (max-width: 767px) {
			padding: 0px !important;
		}
	}
	.merchandise-starwars
	{
		width: 90%;
		display: inline-block;
		max-width: 960px;
		position: relative;
		height: 534px;
		margin: 50px 0;
		&.reg-ticket
		{
			.slick-dots
			{
				@media screen and (min-width: 768px) {
					bottom: -80px !important;
				}
			}
			.merch
			{
				.content
				{
					font-size: 18px !important;
				}
			}
		}
		@media screen and (max-width: 767px) {
			width: 100%;
			margin-top: 0px;
		}
		.table-display
		{
			text-align: center;
		}
		.title
		{
			color: $starwars;
			font-size: 18px;
			text-align: center;
			font-weight: 700;
			margin-bottom: 20px;
		}

		.container-slider
		{
			position: relative;
			display: inline-block;
			width: 90%;
			max-width: 500px;
			text-align: center;
			@media screen and (max-width: 767px) {
				width: 100%;
				max-width: 350px;
			}
			@media screen and (max-width: 320px) {
				max-width: 320px;
			}
			.frame-inside-left
			{
				background-size: 100% 100%;
				position: absolute;
				left: -15px;
				transform: scaleX(-1);
				height: 100%;
				width: 15px;
				top: 0;
				bottom: 0;
				margin: auto;
				@media screen and (max-width: 767px) {
					display: none;
				}
			}
			.frame-inside-right
			{
				background-size: 100% 100%;
				position: absolute;
				right: -15px;
				height: 100%;
				width: 15px;
				top: 0;
				bottom: 0;
				margin: auto;
				@media screen and (max-width: 767px) {
					display: none;
				}
			}
			.points-merchandise
			{
				position: absolute;
				left: 0;
				right: 0;
				bottom: -30px;
				color: $starwars;
				@media screen and (max-width: 767px) {
					font-size: 14px;
					bottom: -25px;
				}
			}
		}
		.slider-merch
		{
			position: relative;
			text-align: center;
			width: 100%;
			display: inline-block;
			.merch
			{
				opacity: 0.8;
				transform: scale(0.8);
				transition: all 0.5s;
				&.slick-active
				{
					opacity: 1;
					transform: scale(1);
					transition: all 0.5s;
				}
				text-align: center;
				img
				{
					max-width: 225px;
					margin: auto;
					box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
					@media screen and (max-width: 767px) {
						max-width: 200px;
					}
				}
				.content
				{
					margin-top: 10px;
					font-size: 21px;
					font-weight: 100;
					color: #485460;
					text-align: center;
				}
			}
			.prev-merch
			{
				height:30px;
				cursor: pointer;
				width: 30px;
				top: -35px;
				display: block;
				position: absolute;
				bottom: 0;
				margin: auto;
				left: -100px;
				background-size: auto 100%;
				background-repeat: no-repeat;
				transform: scaleX(-1);
			}
			.next-merch
			{
				cursor: pointer;
				height: 30px;
				width: 30px;
				top: -35px;
				display: block;
				position: absolute;
				bottom: 0;
				margin: auto;
				right: -100px;
				background-repeat: no-repeat;
				background-size: auto 100%;
			}
			ul.slick-dots
			{
				padding-left: 0px;
				list-style-type: none;
				position: absolute;
				display: block;
				right: 0;
				left: 0;
				bottom: -93px;
				@media screen and (max-width: 767px) {
					bottom: -135px;

				}
				> li
				{
					cursor: pointer;
					display: inline-block;
					height: 10px;
					width: 10px;
					border-radius: 50%;
					border:1px solid #fff;
					margin-right: 5px;
					transition: all 0.5s;
					@media screen and (max-width: 767px) {
						background: #dce0e9;
						border: none;
					}
					&:last-child
					{
						margin-right:0px
					}
					&.slick-active
					{
						@media screen and (max-width: 767px) {
							background: $starwars;
							border: none;
						}
						transition: all 0.5s;
						box-shadow: 0 0 4px 2px rgba(255,0,3,.8);
						border: 1px solid $starwars;
					}
					button
					{
						display: none;
					}
				}
			}
		}
		.frame-down
		{
			background-repeat: no-repeat;
			top: 0;
			height: 75px;
			position: absolute;
			width: 100%;
			background-size: 100%;
			transform: scaleY(-1);
			@media screen and (max-width: 767px) {
				width: 140%;
				left: -20%;
			}
		}
		.frame-up
		{
			background-repeat: no-repeat;
			bottom: 0;
			height: 75px;
			position: absolute;
			width: 100%;
			background-size: 100%;
			@media screen and (max-width: 767px) {
				width: 140%;
				left: -20%;
			}
		}
		.frame-left
		{
			bottom: 0;
			top: 0;
			margin: auto;
			height: 100%;
			position: absolute;
			width: 50px;
			background-size: 100% 100%;
			left: 0;
			@media screen and (max-width: 767px) {
				display: none;
			}
		}
		.frame-right
		{
			bottom: 0;
			top: 0;
			right: 0;
			margin: auto;
			height: 100%;
			position: absolute;
			width: 50px;
			background-size: 100% 100%;
			transform: scaleX(-1);
			@media screen and (max-width: 767px) {
				display: none;
			}
		}
		.frame-bottom-left
		{
			position: absolute;
			width: 35%;
			height: 60px;
			background-size: 100% 100%;
			left: 0;
			bottom: 50px;
		}
		.frame-bottom-right
		{
			transform: scaleX(-1);
			position: absolute;
			width: 35%;
			height: 60px;
			background-size: 100% 100%;
			right: 0;
			bottom: 50px;
		}
		.frame-top-right
		{
			position: absolute;
			width: 35%;
			height: 60px;
			background-size: 100% 100%;
			right: 0;
			top: 50px;
			transform: scaleX(-1) scaleY(-1);
		}
		.frame-top-left
		{
			position: absolute;
			width: 35%;
			height: 60px;
			background-size: 100% 100%;
			left: 0;
			top: 50px;
			transform: scaleY(-1);
		}
		.table-display
		{
			display: table;
			height: 100%;
			width: 100%;
			.mid-table
			{
				display: table-cell;
				vertical-align: middle;
			}
		}
	}
	.gopoints-info
	{
		font-weight: 300;
		color: #485460;
		padding: 45px 90px;
		border:3px solid $starwars;
		padding-top: 20px;
		display: inline-block;
		max-width: 960px;
		width: 90%;
		margin-top: 50px;
		box-shadow: 0 0 2px 1px rgba(255, 0, 2, 0.45);
		.title
		{
			position: relative;
			color: $starwars;
			font-size: 18px;
			background-color: #f8f8f8;
			top: -34px;
			display: inline-block;
			padding: 0 30px;
			font-weight: 700;
			@media screen and (max-width: 767px) {
				text-align: center;
				width: 100%;
			}
		}
		@media screen and (max-width: 767px) {
			padding:20px;
			width:100%;
			border:2px solid $starwars;
			margin-bottom:50px;
		}
	}
	section.snk-starwars
	{
		position: relative;
		font-family: $opensans;
		padding: 18px;
		width: 100%;
		max-width: 960px;
		display: inline-block;
		margin-bottom: 50px;
		.frame-up
		{
			position: absolute;
			background-image: url(/static/dist/img/page/starwars/framesnk.svg);
			height: 100px;
			width: 130px;
			margin: -15px;
		}
		.frame-down
		{
			position: absolute;
			background-image: url(/static/dist/img/page/starwars/framesnk.svg);
			height: 100px;
			width: 130px;
			margin: 3px;
			background-position: right bottom;
			right: 0;
			bottom: 0;
		}
		.content
		{
			border: 2px solid #d1011c;
			padding: 30px;
			text-align: left;
			.title
			{
				text-transform: uppercase;
				color: $starwars;
				font-weight: 700;
				font-size: 18px;
				&:before
				{
					position:relative;
					background-color: rgb(205, 0, 0);
					box-shadow: rgba(255, 0, 3, 0.8) 0px 0px 4px 2px;
					border-width: 1px;
					border-style: solid;
					border-color: rgba(255, 0, 0, 0.9);
					border-image: initial;
					content: "";
					height: 2px;
					width: 30px;
					display: inline-block;
					margin-right: 25px;
					bottom: 2px;
					@media screen and (max-width: 767px) {
						width: 10px;
						margin-right: 10px;
					}
				}
				@media screen and (max-width: 767px) {
					position: relative;
					left: -30px;
					font-size: 16px;
				}
			}
			ol
			{
				padding: 0 75px;
				color: #dedede;
				font-size: 14px;
				> li
				{
					line-height: 30px;
					margin-bottom: 10px;
				}
				@media screen and (max-width: 768px) {
					padding:7px;
					font-size:14px;
				}
			}
			ul.extra, ul.sub-extra
			{
				color: #dedede;
				font-size: 14px;
				> li
				{
					line-height: 30px;
					margin-bottom: 10px;
				}
				@media screen and (max-width: 768px) {
					padding:20px;
					font-size:14px;
				}
			}
		}
	}
	.starwars-use
	{
		max-width: 960px;
		.frame-left
		{
			position: absolute;
			height: 80%;
			width: 30px;
			left:-30px;
			top: 0;
			bottom: 0;
			margin: auto;
			background-size: 100% 100%;
			background-repeat: no-repeat;
			transform: scaleX(-1);
		}
		.frame-right
		{
			position: absolute;
			height: 80%;
			width: 30px;
			right:-30px;
			top: 0;
			bottom: 0;
			margin: auto;
			background-size: 100% 100%;
			background-repeat: no-repeat;
		}
		.title
		{
			color: $starwars;
			&:after
			{
				background:$starwars;
			}
			@media screen and (min-width: 769px) {
				width:84%;
				margin-top:80px;
			}

		}
		.content
		{
			color: #485460;
			.bottom-frame
			{
				background-size: 100% 100%;
				background-repeat: no-repeat;
				width: 80%;
				height: 30px;
				position: absolute;
				margin: auto;
				left: 0;
				right: 0;
				bottom: -58px;
				@media screen and (max-width: 767px) {
					bottom: -15px;
				}
				@media screen and (min-width: 768px) {
					left: -20px;
				}
			}
			.up-frame
			{
				bottom: auto;
				top: -150px;
				transform: scaleY(-1);
				background-size: 100% 100%;
				background-repeat: no-repeat;
				width: 80%;
				height: 30px;
				position: absolute;
				margin: auto;
				left: 0;
				right: 0;
				@media screen and (max-width: 767px) {
					top: -15px;
				}
				@media screen and (min-width: 768px) {
					left: -20px;
				}
			}
			.number
			{
				color: $starwars;
			}
			background: #fff;
			@media screen and (min-width: 769px) {
				width: 44%;
				right: 18.5%;
				background: transparent;
			}
			.phoneSlide-dots li
			{
				background-color: #ececec;
				border: none;
				transform: scale(0.9);
			}
			.phoneSlide-dots li.slick-active
			{
				transform: scale(1);
				background-color: transparent;
				border: 1px solid $starwars;
				background-color: rgb(205, 0, 0);
				box-shadow: rgba(255, 0, 3, 0.8) 0px 0px 4px 2px;
			}
			@media screen and (min-width: 769px) {
				margin-top:150px;
			}
		}
		.right-phone
		{
			@media screen and (min-width: 769px) {
				width:59%;
			}
		}
		.slide-content
		{
			max-width: 320px;
			display: inline-block;
		}
		.slide-prevArrow
		{
			border-radius: 0px;
			background-size: 100% 100%;
			background-repeat: no-repeat;
			border: none;
			transform: rotate(180deg);
			@media screen and (min-width: 769px) {
				left: 0;
				top: -65px;
				padding: 15px;

			}
			img
			{
				display: none;
			}
		}
		.slide-nextArrow
		{
			background-size: 100% 100%;
			background-repeat: no-repeat;
			border: none;
			@media screen and (min-width: 769px) {
				right: 0;
				top: -65px;
				height: 30px;
				width: 30px;
				border-radius: 0px;
			}
			img
			{
				display: none;
			}
		}
		@media screen and (max-width: 768px) {
			margin-top: 25px !important;
			margin-bottom:30px;
		}
	}
	.about-first-day
	{
		text-align: center;
		display: inline-block;
		width: 100%;
		padding: 60px;
		margin-bottom: 30px;
		@media screen and (max-width: 768px) {
			background-size: 500px;
			background-position: 100% 135%;
			background-image: url(https://d3naj63yelf4gk.cloudfront.net/dist/img/faq/geometry.svg);
			background-repeat: no-repeat;
			padding: 30px 10px;
			.switch
			{
				display: table;
				width: 100%;
			}
		}
		h4.title--content
		{
			color: $starwars;
			margin-top: 0px;
			@media screen and (max-width: 768px) {
				text-align: left;
				font-size: 16px;
			}
		}
		p
		{
			max-width: 700px;
			font-size: 14px;
			margin: auto;
			text-align: center;
		}
		.container-switch
		{
			margin: auto;
			position: relative;
			width: 100%;
			max-width: 960px;
			.center-image
			{
				background-size: cover;
				background-position: center;
				position: absolute;
				height: 100%;
				width: 50%;
				left: 0;
				right: 0;
				margin: auto;
				top: 60px;
				height: 300px;
				z-index: 2;
				@media screen and (max-width: 768px) {
					position: relative;
					width: calc(100% + 20px);
					left:-10px;
					top: 0;
				}
				@media screen and (max-width: 500px) {
					height:250px;
				}
			}
			@media screen and (max-width: 768px) {
				display:table-caption;
				margin:20px 0;
			}
		}
		.container-image
		{
			width: 100%;
			max-width: 960px;
			height: 300px;
			background: #ececec;
			margin: auto;
			margin-top: 60px;
			display: inline-block;
			position: relative;
			.left-image
			{
				position: absolute;
				left: 0;
				top: 0;
				width: 25%;
				height: 100%;
				> div
				{
					background-size: cover !important;
					background-position: center;
					position: relative;
					float: left;
					width: 100%;
					height: 150px;
					@media screen and (max-width: 768px) {
						width:50%;
						height:100%;
					}
				}
				@media screen and (max-width: 768px) {
					position:relative;
					height:150px;
					top:0;
					width:100%;
				}
				@media screen and (max-width: 500px) {
					height:100px;
				}
			}
			.right-image
			{
				position: absolute;
				right: 0;
				top: 0;
				height: 100%;
				width: 25%;
				margin: auto;
				> div
				{
					position: relative;
					float: left;
					width: 100%;
					height: 100.2px;
					background-size: cover;
					background-position: center;
					@media screen and (max-width: 768px) {
						height: 100%;
						width: 33.3%;
					}
				}
				@media screen and (max-width: 768px) {
					height:75px;
					bottom:0;
					top:auto;
					width:100%;
					position:relative;
				}
				@media screen and (max-width: 500px) {
					height:50px;
				}
			}
			@media screen and (max-width: 768px) {
				height:auto;
				max-width:500px;
				margin-top:20px;
			}
		}
		.frame
		{
			height: 116%;
			position: absolute;
			width: 105%;
			left: -2.5%;
			background-size: 100%;
			background-position: center;
			background-repeat: no-repeat;
			top: -8%;
			@media screen and (max-width: 768px) {
				display: none;
			}
		}
	}
	.select-top
	{
		display: none;
		padding: 0;
		margin: 0 auto;
		border: 0;
		width: 300px;
		border-radius: 0;
		overflow: hidden;
		background: #fff;
		position: absolute;
		top: auto;
		bottom: -20px;
		left: 0;
		right: 0;
		box-shadow: 0 3px 6px 0 rgba(0,0,0,.13);
		&.hero
		{
			position: relative;
			margin: 0 auto ;
			bottom: 0;
		}
		.bg--select
		{
			width: 50px;
			height: 100%;
			background: $starwars url(https://d3naj63yelf4gk.cloudfront.net/dist/img/select-more.svg) no-repeat 55% 50%;
			position: absolute;
			right: 0;
			z-index: 1;
			background-size: 13px;
		}
		select
		{
			padding: 15px;
			width: 105%;
			border: none;
			box-shadow: none;
			background-color: transparent;
			background-image: none;
			-webkit-appearance: none;
			-moz-appearance: none;
			appearance: none;
			position: relative;
			z-index: 5;
		}
		@media screen and (max-width: 768px) {
			display:block;
			margin-top:50px !important;
		}
	}
	button.starwars
	{
		padding: 10px 20px;
		width: 300px;
		background-color: $starwars;
		font-weight: 100;
		color: #fff;
		border-radius: 5px;
		box-shadow: 0 0 4px 2px rgba(255, 0, 3, 0.8);
		border: solid 1px rgba(255, 0, 0, 0.9);
		transition: all 0.5s;
		@media screen and (max-width: 767px) {
			width: 100%;
		}
		@media screen and (min-width: 769px) {
			&:hover
			{
				transform: scale(1.05);
				transition: all 0.5s;
			}
		}
	}
	section.starwars-gotix-info
	{
		width: 90%;
		max-width: 960px;
		background-repeat: round;
		background-size: 900px;
		background-position: center;
		display: block;
		padding: 30px;
		color: #fff;
		margin: auto;
		&.blue
		{
			.title
			{
				color: #40bfff !important;
			}
			button.starwars
			{
				background-color: #40bfff;
				box-shadow: 0 0 10px 1px rgba(187, 229, 250, 0.8);
				border: solid 1px rgba(0, 103, 155, 0.9);
			}
			@media screen and (min-width: 769px) {
				padding:57px;
			}
		}
		.content-container
		{
			padding: 30px;
			.content
			{
				padding: 75px;
				background-size: 100% 100%;
				background-position: center;
				background-repeat: no-repeat;
				@media screen and (max-width: 768px) {
					padding: 0px;
				}
			}
			@media screen and (max-width: 768px) {
				display:inline-block;
				width:100%;
			}
		}
		.table-display
		{
			display: table;
			height: 100%;
			width: 100%;
			.middle-table
			{
				width: 50%;
				padding: 20px;
				display: table-cell;
				vertical-align: middle;
				text-align: left;
				padding: 0 30px;
				.title
				{
					font-size: 14px;
					font-weight: 700;
					color: $starwars;
				}
				.big-title
				{
					font-size: 28px;
					font-weight: 100;
					margin: 20px 0;
					span
					{
						font-size: 18px;
						font-weight: 100;
					}
				}
				@media screen and (max-width: 768px) {
					display:inline-block;
					width:100%;
					padding:0px;
				}
			}
		}
		@media screen and (max-width: 768px) {
			width:100%;
			padding:10px;
			.content
			{
				background-image: none !important;
			}

		}
		button
		{
			text-align: left;
		}
	}
	#about-starwars
	{
		background-color: #f8f8f8;
		.container:before
		{
			display:none;
		}
		.table-display
		{
			height: 100%;
			display: table;
			.middle-table
			{
				display: table-cell;
				vertical-align: middle;
			}
			.title-top
			{
				font-size: 22px;
				@media screen and (max-width: 768px) {
					font-size: 14px;
				}
			}
		}
		.float-robot
		{
			position: absolute;
			right: -30px;
			bottom: -10px;
			z-index: 1;
			@media screen and (max-width: 768px) {
				width: 100px;
				right: 0;
			}
		}
		.block-right
		{
			background-color: #fafafa;
			position: absolute;
			right: 0;
			height: 100%;
			width: 30px;
			top: 0;
			background-repeat: no-repeat;
			background-size: 100% 100%;
			background-position: center;
		}
		&.gotix-sw-about
		{
			padding-bottom: 0px;
			.description
			{
				@media screen and (max-width: 767px) {
					position: static;
				}
			}
			.play
			{
				background: rgba(0,0,0,0.5);
				transition: all 0.5s;
				&:hover
				{
					transition: all 0.5s;
					background: rgb(255, 255, 255);
					img
					{
						transform:translateX(100px);
					}
				}
				&:after
				{
					color: $starwars;
				}
			}
			.title
			{
				color: $starwars;
				font-weight: 700;
				font-size: 18px;
				&:before
				{
					position:relative;
					background-color: rgb(205, 0, 0);
					box-shadow: rgba(255, 0, 3, 0.8) 0px 0px 4px 2px;
					border-width: 1px;
					border-style: solid;
					border-color: rgba(255, 0, 0, 0.9);
					border-image: initial;
					content: "";
					height: 3px;
					width: 50px;
					display: inline-block;
					margin-right: 25px;
					margin-left: -25px;
					bottom: 2px;
					@media screen and (max-width: 767px) {
						width: 25px;
						margin-right: 10px;
					}
				}
				@media screen and (max-width: 767px) {
					position:absolute;
					top:-40px;
				}
			}
			.title-top
			{
				font-size: 18px;
			}
			@media screen and (max-width: 767px) {
				margin-top:50px;
			}
		}
	}
	.vertical-slider
	{
		background-size: 100% 100%;
		background-position: center;
		padding: 100px;
		width:90%;
		margin: 50px auto;
		.detail
		{
			height: 180px;
			border-radius: 0px 20px 20px 0px;
		}
		.detail .custom-nav .slick-disabled
		{
			filter: grayscale(1) brightness(2);
		}
		.container
		{
			width:90%;
			max-width: 960px;
			@media screen and (max-width: 768px) {
				width:100%;
			}
		}
		@media screen and (max-width: 768px) {
			width:100%;
			padding:0px;
			background-image:none !important;
		}
		.custom-nav.slide-mobile
		{
			@media screen and (max-width: 767px) {
				display: block;
				margin: 0 auto;
				margin-top: 20px;
			}
			svg.slick-arrow.slick-disabled
			{
				filter: grayscale(1) brightness(2);
			}
		}
	}
	#how-starwars
	{
		margin-top: 0px;
		margin-bottom: 0px;
		@media screen and (min-width: 768px) {
			.container
			{
				padding-bottom: 50px;
			}
		}
		h4.section--title
		{
			color: $starwars;
			&:before
			{
				background-color:$starwars;
				box-shadow: 0 0 4px 2px rgba(255, 0, 3, 0.8);
				border: solid 1px rgba(255, 0, 0, 0.9);
				@media screen and (max-width: 767px) {
					top: 10px;
				}
			}
			@media screen and (max-width: 767px) {
				padding-left:10px;
				margin-bottom:20px;
			}
		}
		.layer
		{
			padding-bottom: 20px
		}
		.rect
		{
			background: transparent;
			img
			{
				background-image: none;
				@media screen and (max-width: 768px) {
					top: auto;
				}
			}
			@media screen and (max-width: 768px) {
				figure
				{
					height: auto;
					width: 90%;
					position: relative;
					margin: auto;
				}
			}
		}
		.img--wrapper
		{
			overflow: visible;
		}
		p.how--desc
		{
			margin:20px 0;
			@media screen and (max-width: 768px) {
				font-size: 14px;
			}
		}
		a
		{
			line-height: inherit;
			font-size: inherit;
		}
		.desc--pad
		{
			font-family: $opensans;
			.title
			{
				font-weight: 100;
				font-size: 28px;
			}
			@media screen and (max-width: 768px) {
				position:relative;
				margin:auto;
			}
		}
	}
	#promo-starwars
	{
		.container:before
		{
			display:none;
		}
		background-color: #fafafa;
		.bg-services
		{
			opacity: 1;
			background-color: #fafafa;
			border: 1px solid $starwars;
			height: 150px;
			@media screen and (max-width: 767px) {
				height: 105px;
			}
		}
		.service-container
		{
			background-color: #000;
			background-size: cover;
		}
		.service-list
		{
			border:2px solid $starwars;
			border-radius:5px;
			padding-bottom: 0px;
			box-shadow: rgba(255, 0, 3, 0.8) 0px 0px 4px 2px;
			&:after
			{
				background-position: top center;
				margin-top:30px;
				position:relative;
				display: inline-block;
				width: 100%;
				height: 30px;
				content: "";
				float: left;
				background-size: cover;
			}
			p
			{
				min-height: 200px !important;
			}
		}
		.navigation-arrow
		{
			@media screen and (max-width: 767px) {
				padding-top:220px;
			}
		}
		.wrapper
		{
			h4
			{
				color: $starwars;
				&:before
				{
					background-color: rgb(205, 0, 0);
					box-shadow: rgba(255, 0, 3, 0.8) 0px 0px 4px 2px;
					border-width: 1px;
					border-style: solid;
					border-color: rgba(255, 0, 0, 0.9);
					border-image: initial;
					content: "";
					position: absolute;
					left: -50px;
					top: 0.6em;
					padding-right: 45px;
					height: 3px;
					z-index: 10;
					margin: 0px;
					@media screen and (max-width: 767px) {
						top: 57px;
						left:-35px;
					}
				}
				@media screen and (max-width: 767px) {
					font-size:18px;
				}

			}
		}
		a.bottom
		{
			display: inline-block;
			width: 100%;
			text-align: center;
			color: $starwars;
			&:after
			{
				background-image:none;
				content: "→";
				position: relative;
				top: 1px !important;
			}
		}
	}
	footer
	{
		background-color: #fafafa;
		background-size: cover;
		background-position: center;
		@media screen and (max-width: 767px) {
			background-repeat: no-repeat;
			background-position: bottom;
			background-size: 300%;
		}
	}
	.child-navbar
	{
		ul
		{
			li a
			{
				position: relative;
				overflow: hidden;
			}
			li a.active
			{
				color: #fff !important;
			}
			li a.career-tab:after
			{
				display: none;
			}
			li a.career-tab:hover
			{
				background-color: #fff !important;
				color: $starwars;
			}
			li a:not(.career-tab):after
			{
				content: "";
				width: 100%;
				position: absolute;
				bottom: 0;
				height: 4px;
				border-radius: 20px;
				left: 0;
				background: red;
				transition: transform 0.5s, box-shadow 0.1s;
				box-shadow: none;
				transform: translateX(-100%);
			}
			li a:not(.career-tab):hover
			{
				color: $starwars !important;
			}
			li a.active:after {
				transition: all 0.5s;
				transform: translateX(0%);
				top:auto;
				transition: transform 0.5s, box-shadow 0.5s 0.15s;
				box-shadow: 0px 0px 15px 1px rgba(255,20,20,1);
				color: #fff;
			}
		}
	}
	#main--hero
	{
		.pure-g
		{
			box-shadow: none !important;
		}
		&:after
		{
			box-shadow: none !important;
		}
	}
	.gallery--wrapper
	{
		h2.section-title
		{
			padding-left: 65px;
			position: relative;
			color:$starwars;
			&:before
			{
				background-color: #cd0000;
				box-shadow: 0 0 4px 2px rgba(255,0,3,.8);
				border: 1px solid rgba(255,0,0,.9);
				border-image: initial;
				content: "";
				position: absolute;
				left: 0;
				top: 45px;
				padding-right: 45px;
				height: 3px;
				z-index: 10;
				margin: 0;
			}
		}
		.discover--title
		{
			background-color: $starwars;
		}
	}
	.container-inline
	{
		display: inline-block;
		width: 100%;
		text-align: center;
		@media screen and (max-width: 767px) {
			text-align: left;
			padding: 10px;
			h4
			{
				padding: 10px;
			}
		}
		.option-bar
		{
			overflow: hidden;
			position: relative;
			z-index: 10;
			display: inline-block;
			padding: 20px;
			background-repeat: no-repeat;
			background-position: center;
			background-size: 100%;
			width: 100%;
			max-width: 960px;
			font-size: 14px;
			letter-spacing: 1px;
			font-family: $opensans;
			color: #485460;
			transform: translateY(-50%);
			a
			{
				display: inline-block;
				width: 100%;
				color: inherit;
				font-size: inherit;
				letter-spacing: 1.5px;
				padding: 15px 0;
				transition: all 0.5s;
				&:hover:not(.active)
				{
					color:$starwars;
					transition: all 0.5s;
					transform: scale(1.1);
				}
				&.active
				{
					background-color: $starwars;
					color: #fff;
				}
			}
			.pure-g > .child-option
			{
				position: relative;
				border-right: 1px solid $starwars;
				transition: all 0.5s;

				&:last-child
				{
					border-right:none;
				}
			}
			@media screen and (max-width: 768px) {
				display:none;
			}
		}
	}
}


/* DOWNLOAD FLYER BANNER */
#download-banner
{
	.appsflyer-banner-container
	{
		position: fixed;
		height: 75px;
		bottom: 0;
		left:0;
		padding: 0 15px;
		border-radius: 15px 15px 0px 0px;
		width: 100%;
		z-index: 20;
		background-color: #ffffff;
		box-shadow: 0 -2px 4px 0 rgba(0, 0, 0, 0.2);
		display:table;
		margin: auto;
		> div
		{
			display: table-cell;
			vertical-align: middle;
		}
		.appsflyer-banner-icon-img
		{
			width: 35px;
			padding: 5px;
			background-color: #000;
		}
		.appsflyer-banner-title
		{
			font-weight: 700;
			font-size: 12px;
			font-family: neo;
		}
		.appsflyer-banner-subtitle
		{
			font-size: 10px;
		}
		img.appsflyer-banner-close-img
		{
			border-radius: 5px;
			width: 13px;
			margin-right: 5px;
		}
		button
		{
			width: 100%;
			position: relative;
			top:20px;
			letter-spacing: normal;
			color:#fff;
			border-radius: 5px;
			font-family: neo;
			padding: 12px 10px 10px 10px;
			font-size: 12px;
		}
	}
	@media screen and (min-width: 769px) {
		display:none;
	}
}
