#term {
	margin: 5em 0;
	.title {
		border-top: 5px solid #48af4a;
		font-family: $opensans;
		color: #272d32;
		margin-right: 4em;
		h2 {
			font-weight: 300;
			line-height: 1.28;
			letter-spacing: 2px;
		}
		span {
			color: #3ca33a;
			display: block;
		}
	}
	ul {
		list-style: none;
		position: relative;
		margin-top: 8em;
		padding-left: 4em;
		hr {
			position: absolute;
			width: 50px;
			height: 4px;
			border: none;
			background-color: #58c75b;
			left: 0;
			top: 0.5em;
		}
		li {
			margin: 1em 0em;
			a {
				font-family: $opensans;
				line-height: 2.5;
				letter-spacing: 0.6px;
				color: #343b41;
			}
			.active,
			a:hover {
				color: #3ca33a;
			}
		}
	}
	p {
		font-family: $opensans;
		font-weight: 300;
		line-height: 1.56;
		letter-spacing: 1px;
		color: #485460;
	}
	.content {
		.strong {
			font-weight: bold;
		}
		h4 {
			font-size: inherit;
			line-height: 24px;
		}
		p {
			font-size: 16px;
		}
		ol {
			padding-left: 20px;
			margin-top: 2em;
			li {
				font-family: $opensans;
				font-weight: 300;
				line-height: 1.56;
				letter-spacing: 1px;
				color: #485460;
				margin-bottom: 0.7em;
			}
		}
		ul {
			margin-top: 0;
			padding-left: 0;
			li {
				font-family: $opensans;
				font-weight: 300;
				line-height: 1.56;
				letter-spacing: 1px;
				color: #485460;
				margin-bottom: 0.7em;
				margin-left: 1em;
			}
		}
	}
}

@media screen and (max-width: 35.5em) {
	#term {
		ul {
			margin: 0;
			hr {
				top: 0.5em;
			}
			li {
				margin: 1em 0;
			}
		}
	}
}
