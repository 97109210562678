*, html, body {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}

html, body {
	overflow-x: hidden;
}

body {
	font-family: $lato;
	color: $black;
  overflow: hidden;
}

p {
  font-size: 0.750em;
  font-weight: 300;
  line-height: 1.62em;
  letter-spacing: 1.5px;
  font-family: $opensans;
}

h1, h2, h3, h4, h5, h6 {
	@include heading();
}

h1 {
	@include heading($h1Mob, 5.3px);
}

h2 {
	@include heading($h2Mob, 2px);
}

h3 {
	@include heading($h3Mob, 1.7px);
  font-family: $opensans;
}

h4 {
  font-size: 0.688em;
  line-height: 0.78;
  letter-spacing: 1.9px;
}

a {
  font-family: $opensans;
  letter-spacing: 3.3px;
  line-height: 0.8;
  text-decoration: none;
  font-size: 0.688em;
}

fieldset {
}

button {
	@include button-mobile();
}

button:hover {
	@include button-hover()
}

button:active {
	@include button-active()
}
.text-inline {
  display: inline-block;
}

/* Container */

.container {
  margin-right: auto;
  margin-left: auto;
}

@media (max-width: 768px) {
  .container {
    width: 100%;
	  padding-right: 15px;
	  padding-left: 15px;
  }
}
@media (min-width: 768px) {
  .container {
    width: 750px;
  }
}
@media (min-width: 992px) {
  .container {
    width: 970px;
  }
}
@media (min-width: 1200px) {
  .container {
    width: 1170px;
  }
}
@media (min-width: 2000px) {
  .container {
    width: 1700px;
  }
}

@media (min-width: 980px) {
  .pure-visible-phone {
    display: none;
  }
  .pure-visible-tablet {
    display: none;
  }
  .pure-hidden-desktop {
    display: none;
  }
}

@media (max-width: 767px) {
  .pure-hidden-phone {
    display: none;
  }
  .pure-visible-desktop {
    display: none;
  }
}

@media (min-width: 768px) and (max-width: 979px) {
  .pure-hidden-tablet {
    display: none;
  }
  .pure-visible-desktop {
    display: none;
  }
}

/* End of Container */

%clearfix {
  @include clearfix();
}


/* Small Screen */
@media screen and (min-width: 35.5em) {

}

/* Medium Screen */
@media screen and (min-width: 48em) {
	h1 {
    @include heading($h1Desk, 5.3px);
  }

  h2 {
    @include heading($h2Desk, 2px);
  }

  h3 {
    @include heading($h3Desk, 1.7px);
  }
  h4 {
    font-size: 1.125em;
    line-height: 1.42em;
  }
  p {
    font-size: 0.875em;
  }
  a {
    font-size: 0.875em;
  }
}

/* Large Screen */
@media screen and (min-width: 64em) {

}

/* Extra Large Screen */
@media screen and (min-width: 80em) {

}