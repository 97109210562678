#gobills {
	/* gobills howto */
		header .title .content .text h2 {
			background: white !important;
			@media only screen and (max-width: 767px) {
				background: transparent !important;
			}
		}

		.mobile-how-to {
			padding: 30px 0;
			margin-bottom: -80px;
			.pure-u-1 > .list-wrapper:first-child
			{
				margin-top:0px;
			}
		}

		.mobile-how-to .list-wrapper {
			background: #4285a7;
			width: 100%;
			padding: 10px;
			height: 100px;
			margin-top: 50px;
			&.active
			{
				margin-bottom: 20px;
				.list-text
				{
					background: #13587b;
					span
					{
						color: #fff;
					}
					i
					{
						color: #fff;
					}
				}
			}
		}

		.mobile-how-to .list-wrapper .list-text {
			background: #fff;
			color: $gobills;
			padding: 10px 35px;
			width: 100%;
			position: relative;
			top: -30px;
			&.googlelist
			{
				span
				{
					margin-top: -40px;
				    position: relative;
				    top: -6px;
				    line-height: normal;
				}
				i
				{
					position: relative;
					top: -10px;
				}
			}
		}

		.mobile-how-to .list-wrapper .list-text .img-icon-wrapper {
			border-radius: 50%;
			height: 40px;
			width: 40px;
			background: white;
		}

		.mobile-how-to .list-wrapper .list-text .img-icon-wrapper .img-pln,
		.mobile-how-to .list-wrapper .list-text .img-icon-wrapper .img-bpjs {
			max-width: 20px;
			display: inline-block;
			position: relative;
	    left: 10px;
	    top: 7.5px;
		}

		.mobile-how-to .list-wrapper .list-text .img-icon-wrapper .img-bpjs {
			top: 10.5px;
		}

		.mobile-how-to .list-wrapper .list-text span {
			color: $gobills;
	    font-size: 20px;
	    font-weight: 800;
	    letter-spacing: 1.2px;
	    display: inline-block;
	    margin-top: -10px;
	    position: relative;
	    margin-left: 60px;
	    top: -20px;
		}

		.mobile-how-to .list-wrapper .list-text i {
			float: right;
			color: $gobills;
			font-size: 20px;
			margin-top: -25px;
		}

		.mobile-how-to .sub-wrapper {
			position: relative;
			padding: 10px 20px;
		}

		.mobile-how-to .sub-wrapper .content {
			background: white;
			width: 100%;
			position: relative;
			margin-top: 0px;
			border-left: 5px solid #13587b;
			font-weight: 800;
			letter-spacing: 1.2px;
			margin-bottom: 0px;
		}
		.mobile-how-to .sub-wrapper > .content
		{
			padding: 20px;
			padding-right: 40px;
			text-transform: uppercase;
			font-size: 14px;
		}
		.mobile-how-to .sub-wrapper .content i {
			float: right;
			color: black;
			font-size: 20px;
			position: absolute;
			height: 20px;
			right: 15px;
			top: 0;
			bottom: 0;
			margin: auto;
		}
		.mobile-how-to .sub-wrapper .slider ol
		{	
			background: #fff;
			font-size: 14px;
		    line-height: 20px;
		    padding: 35px;
		    margin: 0px;
		    > li
		    {
		    	margin-bottom: 10px;
		    	a
		    	{
		    		letter-spacing: normal;
		    		color: $gojek;
		    		font-size: 14px;
		    	}
		    	&:last-child
		    	{
		    		margin-bottom: 0px;
		    	}
		    }
		}
		#content-nav ul.cat-list li a
		{
			letter-spacing: 1px;
		}
		#content-nav ul.cat-list li .active {
			color: #4285a7 !important;
			position: relative;
			letter-spacing: 1px;
		}

		#content-nav ul.cat-list li .active:after {
			position: absolute;
			content: '';
			border-bottom: 2px solid #4285a7;
			width: 100%;
			transform: translateX(-50%);
			bottom: -15px;
			top: 40px;
			left: 50%;
		}
	.container-image-load
	{
		position: relative;
		min-height: 600px;
		.image-wait-load
		{
			position: absolute;
			height: 100%;
			width: 100%;
			top: 0;
			left: 0;
			img
			{
				filter: blur(10px);
			}
		}
	}
	.google-redeem
	{
		.new-section-how-to-use
		{
			margin-top: 60px;
			margin-bottom: 60px;
			.title
			{
				width: 100%;
				text-transform: uppercase;
			}
			.content
			{
				width: 100%;
				.table-display
				{
					left: auto;
					margin: auto;
				}
			}
		}
	}
	.section-our-values
	{
		&.go-bills
		{
			&.voucher
			{
				.arrow-left, .arrow-right
				{
					bottom: 0px !important;
					z-index: 2;
				}
			}
		}
	}
	.container-google-snk
	{
		margin-top: 100px !important;
		ol.list-googleplay
		{
			font-family: $opensans;
			padding-left: 20px;
			text-align: left;
			font-size: 14px;
			color: #4a4a4a;
			line-height: 24px;
			font-weight: 100;
			max-width: 800px;
			margin: 0 auto;
			> li
			{
				letter-spacing: 1px;
				margin-bottom: 5px;
				&:last-child
				{
					margin-bottom:0px;
				}
			}
		}
		.credits
		{
			width: 100%;
		    max-width: 800px;
		    text-align: left;
		    margin-top: 20px;
		    font-size: 14px;
		    margin: 30px auto;
		    margin-bottom: 0px;
		}
	}
	.container-option-desktop
	{
		display: block;
		width: 100%;
		max-width: 1000px;
		margin: auto;
		> div
		{
			margin-bottom: 50px;
		}
		@media screen and (max-width: 767px)
		{
			display: none;
		}
	}
	#bg-as-seen-on
	{
		@media screen and (max-width: 767px)
		{
			padding-top: 0px;
		}
	}
	.brushing
	{
		@include brush-gobills;
	}
	.about
	{
		.table-display
		{
			height: 100%;
			display: table;
			.middle-table
			{
				display: table-cell;
				vertical-align: middle;
			}
		}
		.description
		{
			@media screen and (max-width: 767px)
			{
				position: static;
			}
		}
		.container
		{
			h4.title--content
			{
				color: $gobills;
				@media screen and (max-width: 767px)
				{
					position: absolute;
					top: -20px;
					left: 0;
					font-size: 14px;
					&:after
					{
						top: 3px;
					    position: absolute;
					    width: 10px;
					    left: 0;
					    background-color: #4285a7;
					    height: 2px;
					    content: "";
					}
				}
			}
			h2
			{
				@media screen and (min-width: 769px)
				{
					font-size: 28px;
				}
			}
			.img-container
			{
				@media screen and (max-width: 767px)
				{
					background: transparent;
				}
			}
			.img-container-2
			{
				img
				{
					width:100%;
					@media screen and (max-width: 767px)
					{
						width: 90%;
						margin: auto;
						display: block;
					}
				}
				@media screen and (max-width: 767px)
				{
					margin-top:30px;
				}
			}
		}
		@media screen and (min-width: 768px)
		{
			padding-top:0px;
		}
	}
	#type-gobills
	{
		width: 100%;
		.arrow-left
		{
			position: absolute;
			left: 0;
			bottom: -40px;
			width:50px;
		}
		.arrow-right
		{
			position: absolute;
			right: 0;
			bottom: -40px;
			width:50px;
		}
		.slick-arrow.slick-disabled
		{
			filter: grayscale(100) brightness(1.5);
		}
		@media screen and (min-width: 768px)
		{
			margin-top:40px;
		}
	}
	.as-seen-on
	{
		.title
		{
			p
			{
				color: $gobills;
				font-size: 17px;
				@media screen and (max-width: 767px)
				{
					margin-left: 10px;
					font-weight: 700;
					font-size: 14px;
				}
				@media screen and (min-width: 768px)
				{
					position:relative;
					bottom:6px;
				}
			}
			hr
			{
				background: $gobills;
			}
		}
		#left
		{

			right: 0;
			left: auto;
			top: 0;
			bottom: auto;
			@media screen and (max-width: 767px)
			{
				height: 100px;
			}
		}
		#right
		{
			top:auto;
			bottom: 0;
			right: auto;
			left: 0;
			background: $gobills;
			@media screen and (max-width: 767px)
			{
				height: 100px;
			}
		}
		#content
		{
			img
			{
				max-height: 200px;
				&.bpjs
				{
					@media screen and (min-width: 768px)
					{
						max-width: 470px;
					    position: relative;
					    top: 10px;
					}
					@media screen and (max-width: 767px)
					{
						max-width:none;
						width:100%;
					}
				}
			}
		}
	}
	#content-only
	{
		margin-top: 0px;
		padding-top: 60px;
		.container
		{
			box-shadow: #4a4a4a 0px -3em 0px 1.5em;
			.info
			{
				padding-top: 50px;
				p
				{
					@media screen and (min-width: 768px)
					{
						margin:auto;
						max-width: 580px;
					}
				}
				h4
				{
					color: $gobills;
					@media screen and (max-width: 767px)
					{
						font-size: 14px;
					}
				}
				.line
				{
					background: $gobills;
				}
				a.contact
				{
					background: $gobills;
					@media screen and (max-width: 767px)
					{
						width: 80%;
						max-width: 300px;
					}
				}
				a.faq
				{
					background: #4a4a4a;
					@media screen and (max-width: 767px)
					{
						width: 80%;
						max-width: 300px;
					}
				}
			}
			.link
			{
				@media screen and (max-width: 767px)
				{
					margin-bottom: -50px;
				}
			}
		}
		@media screen and (max-width: 767px)
		{
			margin-bottom:100px;
		}
	}

	.how-to {
		[data-display=desktop],
		.desktop-only {
			display: block;
		}

		.mobile-how-to {
			display: none;
		}

		.visibility {
			position: absolute;
			opacity: 0.0;
			height: auto;
			bottom: 0;
			margin: auto;
			visibility: hidden !important;
		}

		header {
			.title {
				.content {
					box-shadow: -1em 1em $gobills;

					.text {
				    padding: .2em 1.8em;

						h2 {
							font-family: 'Neo', 'Open Sans', Helvetica, sans-serif !important;
							font-size: 50px !important;
							font-weight: bold;
							font-style: normal;
							font-stretch: normal;
							line-height: 1.12;
							letter-spacing: 0.5px;
						}
					}
				}
			}

			.bg-img {
		    background-position-x: 100% !important;
			}
		}

		.new-section-how-to-use {
			h3.title {
				color: $gobills;

				&:after {
					background-color: $gobills;
				}
			}
			.content {
				background: $gobills;
			}
		}

		#content-nav {
			.text {
				h4 {
					font-family: $opensans;
					font-size: 18px;
					font-weight: bold;
					letter-spacing: 1px;
					text-align: center;
					text-transform: uppercase;
					color: $gobills;
				}

				.line.gradient {
					background: $gobills;
					width: 50px;
					height: 3px;
					margin: 0 auto;
					margin-bottom: 20px;
				}
			}

			.faq-nav {
				width: unset !important;
				margin: 0px auto;
				.cat-list {
					li {
						a {
							&:hover {
								color: $gobills !important;
							}
						}
					}
				}
			}
		}

		.navigation-wrapper {
			margin-bottom: 80px;
			.new-section-how-to-use
			{
				.number > div
				{
					transition: all 0s 0.51s;
					&.active
					{
						transition: all 0s 0s;
					}
				}
			}
		}

		.how-to-slider.pln {
			.new-section-how-to-use {
				margin-top: 200px;
			}
		}

		#faq-list-menu {
	    margin: 2.5em 0;

			.desktop {
				margin-bottom: 80px;

				h4 {
					text-transform: uppercase;
					font-family: $opensans;
					font-size: 18px;
					font-weight: bold;
					letter-spacing: 1px;
					text-align: center;
					color: $gobills;
				}

				.line.gradient {
					background: $gobills;
					width: 50px;
					height: 3px;
					margin: 0 auto;
					margin-bottom: 50px;
				}
			}

			.box {
				box-shadow: 0 3px 15px 0 rgba(0,0,0,.13), 0 25px 0 15px $gobills !important;
				transition:all 0.5s;
				padding: 0px;
				height: 200px;
				padding-top: 40px;
				.img-icon-wrapper {
					width: 40px;
				    height: 40px;
				    position: relative;
				    background: #fff;
					line-height: 50px;
				    margin: 0 auto;
				    border-radius: 50%;
				    margin-bottom: 20px;
					.img-pln {
						max-width: 16px;
					}

					.img-bpjs {
						max-width: 21px;
					}
				}
				h4
				{
					font-weight: 100;
					color: #343b41;
				}
				&:not(.active)
				{
					&:hover
					{
						opacity: 0.8;
						transition:all 0.5s;
					}
				}
			}

			.active {
			    background-image: url(https://d3naj63yelf4gk.cloudfront.net/dist/img/faq/list-geo.png),linear-gradient(#13587b,#13587b);
			    background-position: bottom,top;
			    background-repeat: no-repeat,no-repeat;
			    background-size: 100%,cover;
			    box-shadow: 0 25px 0 15px $gobills;
			    cursor: default;
			    pointer-events: none;
			    transition: all 0.5s;
				color: white;
				h4 {
					color: white !important;
					font-weight: 700;
				}
			}
		}
	}

	.gradient {
		background: $gopay
	}
	.hero {
		button {
			background: $gopay;
		}
	}
	.merchant-list {
		h5 {
			color: $gopay;
		}
		hr {
			background: $gopay;
		}
	}
	section.verification {
		margin-top: 30px;
		margin-bottom: 30px;
		#sub-desc--container {
			background: #f8f8f8;
			z-index: 5;
			position: relative;
			#line--desc {
				height: 4px;
				width: calc(100% - 55px);
				position: relative;
				top: 50%;
				left: 55px;
				-webkit-transform: translateY(-50%);
				-moz-transform: translateY(-50%);
				-o-transform: translateY(-50%);
				transform: translateY(-50%);
				background-color: #333;
			}
			.sub--desc {
				text-align: left;
				margin-left: 50px;
			}
		}
		.container {
			margin: 0;
			padding: 0;
			width: auto !important;
			overflow: hidden;
			table {
				border-collapse: collapse;
				width: 100%;
			}
			td,
			th {
				border: 0px;
				text-align: left;
				font-size: 14px;
				padding: 20px;
			}
			th {
				font-family: Open Sans, sans-serif;
				font-weight: 700;
				text-transform: uppercase;
				line-height: 1.17;
				letter-spacing: 1.3px;
				color: #5ca5da;
				background-color: #fff;
				border-left: 1px solid #d7d7d7;
				padding: 10px 5px;
			}
			th:nth-child(1) {
				border-left: 0px;
			}
			tr:nth-child(even) {
				background-color: rgba(175, 220, 255, 1);
			}
			tr:nth-child(odd) {
				background-color: rgba(250, 250, 250, 1);
			}
			td {
				color: #485460;
			}
			td:nth-child(odd) {
				background-color: rgba(250, 250, 250, 0.5);
			}
			th:nth-child(2),
			th:nth-child(3),
			td:nth-child(2),
			td:nth-child(3) {
				text-align: center;
			}
			.first-block {
				background: white;
				.text-wrapper {
					padding: 45px 35px 0px 55px;
					h1 {
						font-family: $lato;
						font-size: 18px;
						font-weight: bold;
						letter-spacing: 1.5px;
						text-transform: uppercase;
						color: #00b1f1;
					}
					p {
						font-family: $opensans;
						font-size: 14px;
						font-weight: 300;
						font-style: normal;
						font-stretch: normal;
						line-height: 1.64;
						letter-spacing: 1px;
						color: #485460;
						margin: 40px 70px 17px 0px;
					}
				}
			}
			.second-block {
				width: calc(50% + 35px);
				height: 390px;
				background: $gopay;
				margin-left: -35px;
				position: relative;
				right: 0;
				top: 70px;
				.slick-track {
					margin-left: -165px;
					margin-top: 15px;
				}
				.verified-prev {
					display: none !important;
				}
				.verified-next {
					display: none !important;
				}
				.verified-container {
					height: 100%;
					.slick-list {
						overflow: visible;
					}
					.verified-list {
						padding: 0 30px;
						text-align: center;
						outline: none;
						h1 {
							font-family: $opensans;
							font-size: 24px;
							font-weight: 300;
							letter-spacing: 3px;
							color: #ffffff;
						}
						h5 {
							font-family: $opensans;
							font-size: 12px;
							font-weight: 300;
							letter-spacing: 1.5px;
							text-align: center;
							color: #ffffff;
						}
						img {
							width: 250px;
						}
					}
				}
			}
		}
	}
	.mv-evt--container {
		margin: 48px 0;
		text-align: center;
		.button-group {
			display: inline-block;
			background: $gopay;
			border-radius: 5px;
			margin: 0 auto;
			position: relative;
			ul {
				list-style: none;
				padding: 20px 0;
				margin: 0;
				li:first-child {
					border-right: 1px solid #353738;
				}
				li {
					display: inline-block;
					width: 180px;
					text-align: center;
					a {
						display: block;
						color: #fff;
						text-align: center;
						font-family: Lato, Helvetica, sans-serif;
						font-size: 14px;
						line-height: 1.33;
						letter-spacing: 1.5px;
						font-weight: 300;
						padding: 10px;
					}
				}
			}
		}
	}
	.services {
		.png-file {
			background-size: 20em !important;
		}
		.service-list {
			.bg-services {
				background-size: 6em;
				background-repeat: no-repeat;
				opacity: 1;
			}
		}
		.navigation-arrow {
			display: block;
			@media screen and (max-width: 767px)
			{
				margin-top: 50px;
			}
		}
		.container::before {
			background-color: $gopay;
		}
		h4 {
			color: $gopay;
			padding: 0;
			@media screen and (min-width: 769px)
			{
				margin-bottom: 50px;
			}
		}
	}
	.how-it-works--wrapper {
		@media screen and (min-width: 768px)
		{
			margin-top: 160px;
		}
		.container {
			padding: 0;
		}
		h2 {
			color: #093c5b;
		}
		h4 {
			color: $gopay;
		}
		.top {
			img {
				// max-width: 150px;
			}
		}
		.img--wrapper {}
		figure.hand-ss {
			background: #fff;
			img {
				// max-height: 27em;
				width: auto;
				right: 0;
				margin: 0 auto;
				position: absolute;
				left: 0;
			}
		}
		.section--title:before {
			// content: url(https://d3naj63yelf4gk.cloudfront.net/dist/img/page/gopay/hr.svg);
			background-color: #093c5b;
		}
		.rect--primary {
			background: #093c5b;
			img {
				// padding-left: 1em;
			}
		}
		img {
			// max-height: 250px;
			// width: auto;
		}
		.rect--secondary {
			img {
				// padding-right: 1em;
			}
		}
	}
	.section-our-values {
		padding-bottom: 0;
		h4 {
			color: $gopay;
		}
		img {
			max-height: 150px;
			width: auto;
		}

		&.go-bills
		{
			> .pure-g
			{
				display: block;
				max-width: 960px;
				margin: auto;
			}
			h4.title
			{
				color: $gobills;
				@media screen and (max-width: 767px)
				{
						&:after
						{
							top: 30px;
						    position: absolute;
						    width: 15px;
						    left: -20px;
						    background-color: #4285a7;
						    height: 2px;
						    content: "";
						}
				}
			}
			.list
			{
				h4
				{
					@media screen and (min-width: 768px)
					{
						margin-top: 50px;
						margin-bottom: 40px;
					}
					color: #4a4a4a;
					@media screen and (max-width: 767px)
					{
						padding: 0px;
					    margin-bottom: 0px;
					    margin-top: 50px;
					}
				}
				p
				{
					@media screen and (min-width: 768px)
					{
						margin-top:10px;
					}
					@media screen and (max-width: 767px)
					{
						max-width: 300px;
					}
				}
			}
			.line
			{
				background: $gobills;
				@media screen and (max-width: 767px)
				{
					display: none;
				}
			}
			h4.title
			{
				@media screen and (max-width: 767px)
				{
					font-size: 14px;
					text-align: left;
				}
			}
			@media screen and (max-width: 767px)
			{
				padding:20px;
				padding-bottom:100px;
				.container-slider
				{
					width:100%;
					padding:0px;
					position:relative;
					display:inline-block;
				}
			}
		}
		&.voucher
		{
			.list
			{
				img.pure-img
				{
					max-height: 100px;
				}
			}
		}
	}
	.about {
		.container::before {
			display: none;
			background-color: #093c5b;
		}
		padding-bottom: 0;
		h4,
		a,
		a::after {
			color: $gopay;
		}
	}
	#join-now {
		a {
			background: $gopay;
		}
		h2 {
			font-size: 1.5em;
		}
	}
	.faq {
		#content {
			background: url(https://d3naj63yelf4gk.cloudfront.net/dist/img/page/gopay/rectangle-bg.svg);
			.gopay-faq--title {
				color: #000;
				padding: 30px 0;
				span.light {
					font-weight: 300;
				}
			}
		}
		#right {
			background: $gopay;
		}
		.button--wrapper {
			width: 200px;
			border-radius: 5px;
			background-color: $gopay;
			text-align: center;
			position: relative;
			margin: 0 auto 30px;
			a.gopay-faq--link {
				text-decoration: none;
				color: #fff;
				font-size: 16px;
				text-transform: uppercase;
				padding: 19px 0;
				display: block;
			}
		}
	}
}

@media screen and (max-width: 768px) {
	#gopay {
		header#main--hero {
			// background-image: url(https://d3naj63yelf4gk.cloudfront.net/dist/img/header/hero/go-pay--mobile.jpg);
		}
		section.verification {
			margin-top: 30px;
			margin-bottom: 30px;
			#sub-desc--container {
				.sub--desc {
					margin-left: 0px;
					padding: 0 15px;
					text-align: center;
				}
				#line--desc {
					display: none;
				}
			}
			.container {
				.first-block {
					background: white;
					table {
						letter-spacing: normal;
						td {
							padding: 10px;
						}
					}
					.text-wrapper {
						padding: 15px;
						h1 {
							font-family: $lato;
							font-size: 18px;
							font-weight: bold;
							letter-spacing: 1.5px;
							text-transform: uppercase;
							color: #00b1f1;
							margin-top: 20px;
						}
						p {
							margin-top: 40px;
							margin-right: 0;
							margin-bottom: 0;
						}
					}
				}
				.second-block {
					width: 100%;
					height: 380px;
					top: 0;
					margin-left: 0;
					position: relative;
					.verified-prev {
						display: block !important;
						z-index: 999;
						float: left;
						position: relative;
						right: 20px;
						left: 0;
						margin: 22px;
					}
					.verified-next {
						display: block !important;
						z-index: 999;
						float: right;
						position: relative;
						right: 0;
						margin: 22px;
					}
					.slick-disabled ellipse {
						stroke: rgb(210, 210, 210) !important;
					}
					.slick-disabled polygon {
						fill: rgb(210, 210, 210);
					}
					.verified-container {
						margin-left: 0;
						bottom: 0;
						position: relative;
						.slick-list {
							padding: 0 30px !important;
						}
						.slick-track {
							margin-left: 0;
							margin-top: 0;
						}
						.verified-list {
							padding: 0 30px;
							margin-top: -80px;
							text-align: center;
							outline: none;
						}
					}
				}
			}
		}
		#join-now {
			img {
				width: 100%;
			}
			.right {
				a {
					padding: 1.3em;
					bottom: -1.5em;
				}
			}
		}
		.services {
			padding-top: 0;
			padding-bottom: 6em;
		}
		.how-it-works--wrapper {
			.container {
				padding-top: 0;
				padding-bottom: 0;
				.how--title {
					// margin-top: 6em;
				}
			}
			h4 {
				line-height: 1.3em;
			}
			.layer {
				padding-bottom: 0;
				padding-top: 0;
				.odd--row .desc--pad {
					padding-right: 0;
				}
				.even--row .desc--pad {
					padding-right: 0;
					padding-left: 0;
				}
			}
		}
	}
}

@media screen and (max-width: 500px) {
	#gopay {
		.button-group {
			ul {
				padding: 10px 0 !important;
				li {
					width: 150px !important;
					a {
						font-size: 12px !important;
					}
				}
			}
		}
	}
}

@media only screen and (max-width: 35.5em) {
	#gobills {
		[data-display=desktop],
		.desktop-only {
			display: none !important;
		}

		.mobile-how-to {
			display: block !important;
			margin-bottom: 50px;
		}

		.new-section-how-to-use {
			margin-top: 0 !important;
			margin-bottom: 0 !important;

			.title {
				display: none;
			}

			.phoneSlide-dots {
				display: none !important;
			}
		}

		#content-nav {
			display: none;
		}

		#faq-list-menu {
			margin: 0 !important;
			padding: 0;
			margin-bottom: 50px !important;

			.mobile {
				.warper {
					h3 {
				    margin-top: -50px;
				    margin-left: 60px;
					}

					.img-icon-wrapper {
						width: 40px;
				    height: 40px;
				    position: relative;
				    background: #fff;
						line-height: 50px;
				    border-radius: 50%;
				    margin-bottom: 20px;

						.img-pln {
							max-width: 16px;
							position: relative;
							left: 12px;
							top: 7.5px;
						}

						.img-bpjs {
							max-width: 22px;
					    position: relative;
					    top: 8.5px;
					    left: 9px;
						}
					}
				}

				.ui-accordion-content-active {
					margin-bottom: 100px !important;
				}

				#faq-list-content {
					.title-content {
						margin-bottom: 100px;
						transform: scale(1.285);
				    box-shadow: 0 3px 15px 0 rgba(0,0,0,.13), 0 25px 0 15px $gobills;

						span {
							color: unset;
						}
					}

					.detail-item {
						transform: scale(1.25);
						margin-bottom: 35px;

						.how-to-mobile-li {

						}

						ul {
							li {
								padding: 0;
								border-bottom: none;
							}
						}

						.title-item {
							border-left: 5px solid $gobills;

							&.sub-item {
								background: white !important;
							}

							&:last-child {
								margin-bottom: 250px;
							}

							span {
						    color: unset;
							}
						}
					}

					.ui-state-active {
						background: #13587b !important;

						ul {
							margin-bottom: 50px !important;
						}
					}
				}
			}
		}

		.how-to {
			header {
				background-size: cover;
				background-position-x: 100% !important;
				margin-top: 60px;

				.title {
					.content {
						box-shadow: none;

						.text {
							h2 {
								text-align: center;
								font-size: 36px !important;
								line-height: 40px;

								span {
									position: relative;
									top: 7px;
								}
							}
						}
					}
				}
			}
		}
	}
}
