.section-our-values {
	background: #fafafa;
	padding: 80px;
	.title {
		color: $gojek;
		text-align: center;
		text-transform: uppercase;
		font-size: 17px;
		line-height: 30px;
	}
	.line.gradient {
		background: $gojek;
		width: 50px;
		height: 3px;
	}
	.list {
		margin-top: 30px;
		img {
			max-width: 250px;
			display: block;
		}
		h4 {
			text-transform: uppercase;
			font-weight: 700;
	    line-height: 20px;
		}
		p {
			padding: 20px;
			font-size: 14px;
			color: #9b9b9b;
			font-weight: 300;
			margin-top: -5px;
		}
		.line.gradient {
			background: $gojek;
			width: 30px;
			height: 3px;
			margin-top: -10px;
		}
	}
}
@media screen and (max-width: 768px) {
	.section-our-values {
		.list
		{
			display: inline-block;
		    padding: 0px;
		    width: 100%;
			h4
			{
				padding: 0 20px;
				padding-bottom: 10px;
				height: auto;
				font-size: 15.5px;
			}
		}
	}
}

@media screen and (max-width: 400px) {
	.section-our-values {
    padding: 80px 15px;
		.list {
			p {
				padding: 20px;
				max-width: 350px;
			}
		}
	}
}

@media screen and (max-width: 320px) {
	.section-our-values {
		padding: 0;
	}
}
