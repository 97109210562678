#gofood {
	/* SOP */
	.sop-head
	{
		.content
		{
			box-shadow: -1em 1em #e42b2d !important;
			@media only screen and (max-width: 767px) {
				box-shadow: none !important;
			}
		}
		@media only screen and (max-width: 768px) {
			h2
			{
				font-size: 21px;
			}
		}
		@media only screen and (max-width: 767px) {
			background: #fff !important;
			margin-top: 70px;
			margin-bottom:0px;
			.content
			{
				margin: 0px;
				padding: 15PX;
			}
			.text
			{
				padding: 15px !important;
			}
			.title
			{
				background: transparent !important;
				h2
				{
					font-size: 28px;
					margin: 0px;
					color: #343b41;
				}
			}
			.background
			{
				height: 300px;
				margin-bottom: 20px;
			}
		}
	}
	.container-content-sop
	{
		counter-reset: gopay-section;
		display: inline-block;
		width: 100%;
		text-align: center;
		.content
		{
			display: inline-block;
			width: 100%;
			max-width: 960px;
			border-top: 3px solid #e42b2d;
			background-color: #fff;
			padding: 40px;
			text-align: left;
			margin: 60px auto;
			font-size: 18px;
			font-family: $opensans;
			line-height: 28px;
			.intro
			{
				font-weight: 100;
				margin-bottom: 20px;
			}
			.title
			{
				float: left;
				width: 100%;
				display: inline-block;
				
				font-weight: 700;
				&:before
				{
					counter-increment: gopay-section;
  					content: counter(gopay-section)".";
  					margin-right: 10px;
				}
			}
			ul
			{
				> li
				{
					margin-bottom: 20px;
				}
			}
			ol.list-sop
			{
				float: left;
				width: 100%;
				font-weight: 100;
				margin:20px 0;
				
				margin-bottom: 50px;
				padding-left: 20px;
				> li
				{
					margin-bottom: 20px;
					padding-left: 9px;
					&:last-child
					{
						margin-bottom:0px;
					}
				}
				@media only screen and (max-width: 767px) {
					padding-left: 25px;
					margin-bottom:20px;

					counter-reset: item;
					> li
					{
						margin: 0;
						padding: 0 0 0 2em;
						margin-bottom: 10px;
						text-indent: -2em;
						list-style-type: none;
						counter-increment: item;
  						&:before
  						{
  							display: inline-block;
							  width: 1em;
							  padding-right: 13px;
							  font-weight: bold;
							  text-align: right;
							  content: counter(item, lower-alpha) ".";
  						}
					}
				}
			}
			.content-table
			{
				float: left;
				width: 100%;
				position: relative;
				.desktop
				{
					float: left;
					position: relative;
					width: 100%;
					.extra
					{
						font-weight: 100;
						left: 27px;
					    position: relative;
					    top: -30px;
					    color: #4a4a4a;
					}
					.container-table
					{
						position: relative;
					    width: 100%;
					    float: left;
					    margin-top: 30px;
					}
					.info
					{
						top: 78px;
					    position: absolute;
					    height: calc(100% - 179px);
					    width: calc(100% + 60px);
					    left: -20px;
					    background-color: #d0021b;
					    span
					    {
					    	display: inline-block;
					    	color: #fff;
					    	margin-top: 100px;
					    	font-size: 14px;
					    	letter-spacing: 1px;
					    	&:before
					    	{
					    		display: inline-block;
							    position: relative;
							    height: 4px;
							    width: 40px;
							    margin-right: -5px;
							    background-color: #343b41;
							    content: "";
							    left: -20px;
							    bottom: 3px;
					    	}
					    }
					}
					table
					{
						box-shadow: -2px 2px 5px 0px rgba(0,0,0,0.22);
						position: relative;
						margin: auto;
						margin-bottom: 50px;
						font-size: 14px;
						width: 85%;
						float: right;
						text-align: center;
						border-bottom: 2px solid #343b41;
						th
						{
							letter-spacing: 1px;
							padding: 30px 0;
							color: #fff;
							background-color: #343b41;
							border-bottom: 2px solid #d0021b;
						}
						td
						{
							padding: 15px 0;
						}
						tbody
						{
							> tr
							{
								> th, > td
								{
									&:first-child, &:nth-child(2)
									{
										border-right: 1px solid #979797;
									}
								}
								background-color: #fff;
								&:nth-child(even)
								{
									background-color: #ffcaca;
								}
							}
						}
					}
					@media only screen and (max-width: 767px) {
						display:none;
					}
				}
				.mobile-sop
				{
					display: none;
					width: calc(100% + 40px);
					position:relative;
					left: -20px;
					padding: 30px 0;
					background-color: #ececec;
					margin-bottom: 20px;
					.info
					{
						font-weight: 100;
						position: relative;
						padding-left: 50px;

					}
					.table
					{
						text-align: center;
						.title-sop
						{
							text-align: left;
							font-weight: 700;
							margin: 20px 0;
							color: #000;
							&:before
							{
								display:inline-block;
								content:"";
								bottom: 4px;
								position: relative;
								width: 20px;
								margin-right: 10px;
								height: 3px;
								background-color: #d0021b;
							}
						}
						.head-title
						{
							letter-spacing: 1px;
							padding: 15px 0;
							font-weight: 100;
							background-color: #343b41;
							color: #fff;
						}
						.sub-title
						{
							letter-spacing: 1px;
							padding: 15px 0;
							font-weight: 700;
							background-color: #d0021b;
							color: #fff;
						}
						.table-content
						{
							padding: 10px 0;
							width: 100%;
							background-color: #fff;
							table
							{
								background-color: #fff;
								width: 90%;
								margin: auto;
								tr
								{
									&:first-child
									{
										border-bottom: 1px solid #ffcaca;
									}
									> td
									{
										font-weight: 100;
										height: 80px;
										width: 50%;
										&:first-child
										{
											text-align:left;
											border-right:1px solid #e2e2e2;
											font-weight:700;
											padding: 15px;
										}

									}
								}
							}
						}
					}
					@media only screen and (max-width: 767px) {
						display:block;
					}
				}
			}
			@media only screen and (max-width: 767px) {
				margin-top:0px;
				padding: 20px;
				font-size:14px;
				border:none;
			}
		}
	}
	/* ENDING SOP */
	.container-sub-menu
	{
		z-index: 1990;
	}
	.gm-style-mtc {
		letter-spacing: 1px;
	}
	@-webkit-keyframes uil-ring-anim {
		0% {
			-webkit-transform: rotate(0deg);
			transform: rotate(0deg);
		}
		100% {
			-webkit-transform: rotate(360deg);
			transform: rotate(360deg);
		}
	}
	@-moz-keyframes uil-ring-anim {
		0% {
			-webkit-transform: rotate(0deg);
			transform: rotate(0deg);
		}
		100% {
			-webkit-transform: rotate(360deg);
			transform: rotate(360deg);
		}
	}
	@-webkit-keyframes uil-ring-anim {
		0% {
			-webkit-transform: rotate(0deg);
			transform: rotate(0deg);
		}
		100% {
			-webkit-transform: rotate(360deg);
			transform: rotate(360deg);
		}
	}
	@-o-keyframes uil-ring-anim {
		0% {
			-webkit-transform: rotate(0deg);
			transform: rotate(0deg);
		}
		100% {
			-webkit-transform: rotate(360deg);
			transform: rotate(360deg);
		}
	}
	@keyframes uil-ring-anim {
		0% {
			-webkit-transform: rotate(0deg);
			transform: rotate(0deg);
		}
		100% {
			-webkit-transform: rotate(360deg);
			transform: rotate(360deg);
		}
	}
	.uil-ring-css {
		background: none;
		position: relative;
		width: 200px;
		height: 200px;
		margin: 0 auto;
		display: block;
	}
	.uil-ring-css>div {
		position: absolute;
		display: block;
		width: 160px;
		height: 160px;
		top: 20px;
		left: 20px;
		border-radius: 80px;
		box-shadow: 0 3px 0 0 $gofood;
		-webkit-transform-origin: 80px 81.5px;
		transform-origin: 80px 81.5px;
		-webkit-animation: uil-ring-anim 1s linear infinite;
		animation: uil-ring-anim 1s linear infinite;
	}

	@keyframes stroke {
		100% {
			stroke-dashoffset: 0;
		}
	}
	@keyframes scale {
		0%,
		100% {
			transform: none;
		}
		50% {
			transform: scale3d(1.1, 1.1, 1);
		}
	}
	@keyframes fill {
		100% {
			box-shadow: inset 0px 0px 0px 30px #7ac142;
		}
	}
	#promo-banner .detail .custom-nav .slick-disabled {
		ellipse {
			stroke: #9b9b9b !important;
		}
		polygon {
			fill: #9b9b9b;
		}
	}
	#download-cards {
		display: none;
	}
	.articles--feed {
		.bg {
			background: $gofood;
		}
		.section--title {
			color: #ffffff;
		}
		svg {
			ellipse {
				stroke: #ffffff !important;
			}
			polygon {
				fill: #ffffff !important;
			}
		}
		.line--title {
			background: #ffffff;
		}
		.read--more>a {
			background: $gofood;
		}
		.content {
			.one {
				border-bottom: 6px solid $gofood;
			}
			.two {
				border-bottom: 6px solid $gofood;
			}
			.three {
				border-bottom: 6px solid $gofood;
			}
			.four {
				border-bottom: 6px solid $gofood;
			}
		}
		h4>a:hover {
			color: $gofood;
		}
	}
	#join-now {
		.bg-layer {
			position: relative;
			background-color: #e4292c;
			img {
				width: 100%;
				opacity: 0.9;
				filter: contrast(0.9);
				mix-blend-mode: multiply;
			}
		}
		.right {
			a {
				background-color: #b62025;
			}
		}
	}
	.how-it-works--wrapper {
		.section--title {
			color: #b62025;
			&:before {
				background-color: #b62025;
			}
		}
		.rect--primary {
			background: #b62025;
		}
	}
	.section-how-to-use {
		h5 {
			color: $gofood;
		}
		.line.gradient {
			background: $gofood;
		}
		.arrow-up {
			stroke: $gofood;
		}
		.arrow-up-circle {
			stroke-width: 3;
			stroke: $gofood;
		}
		.arrow-down {
			stroke: $gofood;
		}
		.arrow-down-circle {
			stroke: $gofood;
			stroke-width: 3;
		}
		.slider .slick-dots li.slick-active button:before {
			color: $gofood;
		}
		.slider-image .slick-dots li.slick-active button:before {
			color: $gofood;
		}
		.text-mobile .line.gradient {
			background: $gofood;
		}
		.text h5 {
			color: $gofood;
		}
		.text .line.gradient {
			background: $gofood;
		}
		.slider-image .slick-dots li.slick-active button:before {
			color: $gofood;
		}
	}

	.updatemenu {
		::-webkit-input-placeholder {
	    color: rgb(195, 195, 195);
		}
		:-moz-placeholder {
	  	color: rgb(195, 195, 195);
	  	opacity:  1;
		}
		::-moz-placeholder {
	  	color: rgb(195, 195, 195);
	  	opacity:  1;
		}
		:-ms-input-placeholder {
			color: rgb(195, 195, 195);
		}
		::-ms-input-placeholder {
	  	color: rgb(195, 195, 195);
		}

		.messages-alert {
			display: none;
			background-color: $gofood;
			width: 100%;
			padding: 20px;
			text-transform: capitalize;
			margin-top: 80px;
			margin-bottom: -80px;

			h5 {
				color: white;
				font-size: 15px;
				margin: 0;
				padding: 0;
			}
		}

		.checkmark__circle {
	    stroke: #f5a523;
	    fill: #f5a523;
	    stroke-dasharray: 166;
	    stroke-dashoffset: 166;
	    stroke-width: 2;
	    stroke-miterlimit: 10;
	    animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
	  }
	  .checkmark {
			top: -15px;
			left: 20px;
			width: 40px;
			height: 40px;
			border-radius: 50%;
			display: block;
			stroke-width: 2;
			stroke: #fff;
			stroke-miterlimit: 10;
			margin: 10% auto;
			box-shadow: inset 0 0 0 #b62025;
			-webkit-animation: c .4s ease-in-out .4s forwards,b .3s ease-in-out .9s both;
			animation: c .4s ease-in-out .4s forwards,b .3s ease-in-out .9s both;
	  }
	  .checkmark__check {
	    transform-origin: 50% 50%;
	    stroke-dasharray: 29;
	    stroke-dashoffset: 29;
	    animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
	  }

		#content-only.thank-you {
			margin-top: 0px;
			.container {
				background: white;
				box-shadow: 0 -3em 0 1.5em $gofood;
				.info {
					h4 {
						color: $gofood;
					}
					.line {
						background: $gofood;
					}
					.link {
						a {
							width: 280px;
						}
						.contact {
							margin-bottom: 15px;
							background: $gofood;
							span {
								margin: 0 15px;
							}
						}
						.contact.homepage {
							background: rgb(200, 200, 200);
						}
					}
				}
			}
		}
		.section-join-form.updatemenu {
			margin-top: 50px;
			padding: 80px;

			form#input-form-gofood-updatemenu {
			    position: relative;
			    height: 100%;
					background: white;

			    #button--wrapper {
		    	    position: absolute;
				    bottom: -30px;
				    right: 0;
			    }
			}
			.main-wrapper {
				height: 890px;
				width: 100%;
				box-shadow: 0px 0px 50px rgba(90, 90, 90, .25);
				#map {
					height: 300px;
					width: 100%;
					margin-top: 5px;
				}
				#maps-display {
					display: block;
					height: 300px;
					width: 100%;
					margin-top: 5px;
				}
				.maps-show {
					display: block !important;
					-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)" !important;
					filter: alpha(opacity=100) !important;
					-moz-opacity: 1.0 !important;
					-khtml-opacity: 1.0 !important;
					//opacity: 1.0 !important;
				}
				.maps-hide {
					margin-bottom: -300px;
					-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=00)" !important;
					filter: alpha(opacity=00) !important;
					-moz-opacity: 0.0 !important;
					-khtml-opacity: 0.0 !important;
					opacity: 0.0 !important;
				}
				.background-wrapper {
					background: url('https://d3naj63yelf4gk.cloudfront.net/dist/img/card/join-form/gofood-texture.jpg?ver=1');
					min-height: 890px;
					height: 100% !important;
					width: 100%;
					padding: 0;
					text-align: center;
					img {
						max-width: 85px;
						height: 60px;
						margin-top: 280px;
					}
				}
				.text-wrapper {
					.title {
						margin-top: -15px;
						font-size: 12px;
						position: relative;
						top: 10px;
						color: rgba(200, 200, 200, 1.0);
					}
				}
				.form-container {
					padding: 50px;
				}
				.form-container[data-step="2"],
				.form-container[data-step="3"] {
					display: none;
				}
				.form-wrapper {
					.group {
						width: 100%;
						margin: 20px 0;
						label {
							font-family: $opensans;
							font-weight: 700;
							text-transform: uppercase;
							letter-spacing: 1.25px;
							font-size: 10.5px;
							color: #697b8c;
						}
						input[type=text],
						input[type=password],
						input[type=number],
						select {
							width: 100%;
							height: 45px;
							padding: 15px;
							margin-top: 5px;
							letter-spacing: 1.2px;
							font-size: 13.4px;
							background: white;
							border-radius: 0 !important;
							-webkit-appearance: none;
							border: 1px solid rgb(210, 210, 210);
							color: #485460;
						}

						input[type=radio] {
							margin-top: 10px;
						}

						.checkbox-style {
							width: 25px;
							margin: 10px 0px;
							position: relative;
						}

						.checkbox-style label {
							cursor: pointer;
							position: absolute;
							width: 25px;
							height: 25px;
							top: 0;
							left: 0;
							background: #eee;
							border:1px solid #ddd;
						}

						.checkbox-style label:after {
							opacity: 0.0;
							content: '';
							position: absolute;
							width: 9px;
							height: 5px;
							background: transparent;
							top: 6px;
							left: 7px;
							border: 3px solid #333;
							border-top: none;
							border-right: none;

							transform: rotate(-45deg);
						}

						.checkbox-style label:hover::after {
							opacity: 0.0;
						}

						.checkbox-label {
							position: relative;
					    top: -30px;
					    left: 40px;
						}

						/**
						 * Create the checkbox state for the tick
						 */
						.checkbox-style input[type=checkbox]:checked + label:after {
							opacity: 1;
						}

						textarea {
							resize: vertical;
							position: relative;
					    top: 2px;
							padding: 15px;
							margin-top: 5px;
							letter-spacing: 1.2px;
							width: 100%;
							font-size: 13.4px;
							background: white;
							border-radius: 0 !important;
							border: 1px solid rgb(210, 210, 210);
							color: #485460;
						}

						.combo-box {
							position: relative;
						}
						.combo-box:after {
							content: '\f0d7';
							font-family: 'FontAwesome';
							flex-direction: column;
							justify-content: center;
							right: 15px;
							top: 35.75px;
							text-align: right;
							position: absolute;
						}
						.combo-box-long:after {
							top: 50px;
						}
					}
					.error_messages {
						color: red;
						margin-top: 10px;
					}
				}
				.upload-type-file {
					opacity: .0;
				}
				.upload-wrapper {
					margin-top: 15px;
					.title {
						color: #697b8c;
						font-family: $opensans;
						font-weight: 700;
						text-transform: uppercase;
						letter-spacing: 1.25px;
						font-size: 12.5px;
						margin-bottom: 10px;
					}
					.subtitle {
						color: #697b8c;
						font-family: $opensans;
						font-weight: 700;
						text-transform: none;
						letter-spacing: 1.25px;
						font-size: 12.5px;
						margin-bottom: 10px;
					}
					.upload-loader {
						display: none;
						margin-top: -90px;
						color: rgb(195, 195, 195);
						font-size: 12px;
						text-align: center;
					}
					.upload-box {
						margin-top: 0px;
						height: 400px;
						overflow-y: scroll;
						padding: 20px;
						border: 1px solid rgb(220, 220, 220);
						.list {
							cursor: pointer;
							.list.plus {
								background: $gofood;
								padding: 20px;
								font-size: 40px;
								color: white;
								font-weight: 700;
								span {
									margin: 0 20px;
								}
							}
						}
						.upload-first {
							margin-top: 100px;
							color: rgb(195, 195, 195);
							font-size: 12px;
							text-align: center;
							.icon-preview {
								display: none;
							}
							.button-upload-first {
								width: 130px;
								background: $gofood;
								cursor: pointer;
								margin: 0 auto !important;
								display: block;
								padding: 15px;
								color: white;
								span {
									margin: 0 20px;
								}
							}
						}
						.upload-list {
							display: none;
							.list.plus {
								background: $gofood;
								padding: 20px;
								font-size: 40px;
								color: white;
								font-weight: 700;
								position: relative;
								span {
									margin: 0 20px;
								}
							}
							.box {
								border: 1px solid rgb(200, 200, 200);
								height: 150px;
								position: relative;
								margin: 0 5px;
								margin-bottom: 10px;
								img {
									position: absolute;
									top: 50%;
									left: 50%;
									max-height: 100%;
									transform: translate(-50%, -50%);
								}
							}
							.box.addmore {
								padding: 50px;
								background: $gofood;
								height: 150px;
								position: relative;
								margin: 0 5px;
								margin-bottom: 10px;
								label {
									font-size: 70px;
									position: absolute;
									top: 45%;
									left: 50%;
									max-height: 100%;
									transform: translate(-50%, -50%);
								}
							}
						}
					}
				}
				.form-button {
					/*position: relative;
					top: -10px;
					left: -40px;*/
					.button {
						font-size: 14px;
						font-family: $opensans;
						font-weight: 400;
						padding: 35px;
						transition: all .5s ease 0s;
						/*margin: 0 5px;*/
						span {
							margin: 0 50px;
						}
					}
					.button:hover,
					.button:focus {
						box-shadow: 0 2px 10px 0 rgba(0, 0, 0, .65);
					}
					.button-navigation {
						background: #353b44;
						color: #fff;
						padding: 27.75px;
						/*float: right;*/
					    position: absolute;
					    right: 250px;
					    bottom: 0;
					}
					.button-navigation.next {
						background: $gofood;
					}
					.button-navigation.next.button-disabled {
						background: rgb(210, 210, 210);
						cursor: not-allowed;
						box-shadow: none;
					}
					.button-submit-go {
						display: none;
						text-transform: none;
						background: $gofood;
						color: #fff;
						/*float: right;*/
						padding: 24px;
					    right: 30px;
					    position: absolute;
					    bottom: 0;
					}
					.button-navigation.next {
						display: inline;
						right: 30px;
					}
					.button-navigation.back {
						display: none;
					}
				}
				/*
			 .button-submit {
					background: #48af4a;
					z-index: 100;
					box-shadow: 0 2px 10px 0 rgba(0,0,0,.45);
					font-size: 14px;
					font-family: $opensans;
					font-weight: 400;
					padding: 25px;
					transition: all .5s ease 0s;
					color: #fff;
					float: right;
					text-transform: none;
					position: absolute;
					top: 30px;
					left: -50px;
					border-radius: 8px !important;

					span {
						margin-right: 50px;
						margin-left: 50px;
					}
			 }

			.button-submi:hover {
				box-shadow: 0 2px 10px 0 rgba(0, 0, 0, .65);
			}
			*/
			}
		}
	}
}

@media screen and (max-width: 768px) {
	#gofood {
		section#download-cards {
			display: block;
			padding: 30px 0;

			.bg-right {
				padding: 0;
			}
			.download-list {
				& > a {
			    display: inline-block;
			    float: left;
			    padding: 12px 0;

			    & > p {
	    	    font-size: 1.1em;
    				margin: 0;
    				color: #24a749;
			    }

			    & > svg {
	    	    margin: 6px 10px;

	    	    g {
	    	    	fill: #24a749;
	    	    }
			    }
				}
				.download-btn.ios {
			    background-color: #656565;
				}
				.download-btn {
		    	width: 50px;
			    height: 50px;
			    background-size: 35%;
				}
			}
		}
		.section-how-to-use {
			.arrow--wrapper {
				top: 22%;
			}
			.phone-wrapper {
				top: 35%;
			}
			ul.slick-dots {
				margin-top: -3.5em;
			}
		}
		.updatemenu {

			.section-join-form.updatemenu {
				margin-top: 50px;
				padding: 0px;

				.main-wrapper {
					margin: 50px 0;
					height: 890px;
					width: 100%;
					box-shadow: 0px 0px 50px rgba(90, 90, 90, .25);

					.background-wrapper {
						background: url('https://d3naj63yelf4gk.cloudfront.net/dist/img/card/join-form/gofood-texture.jpg?ver=1');
						min-height: 100px;
						height: 100% !important;
						width: 100%;
						padding: 0;
						text-align: center;
						img {
							max-width: 25px;
							margin-top: 20px;
						}
					}
					.text-wrapper {
						.title {
							margin-top: -15px;
							font-size: 12px;
							position: relative;
							top: 10px;
							color: rgba(200, 200, 200, 1.0);
						}
					}
					.form-container {
						padding: 20px;
					}
					.form-container[data-step="2"],
					.form-container[data-step="3"] {
						display: none;
					}
					.form-wrapper {
						.group {
							width: 100%;
							margin: 20px 0;
							label {
								font-family: $opensans;
								font-weight: 700;
								text-transform: uppercase;
								letter-spacing: 1.25px;
								font-size: 10.5px;
								color: #697b8c;
							}
							input[type=text],
							input[type=password],
							input[type=number],,
							select {
								width: 100%;
								height: 45px;
								padding: 15px;
								margin-top: 5px;
								letter-spacing: 1.2px;
								font-size: 13.4px;
								background: white;
								border-radius: 0 !important;
								border: 1px solid rgb(210, 210, 210);
								-webkit-appearance: none;
								color: #485460;
							}

							input[type=radio] {
								margin-top: 10px;
							}

						  .checkbox-label {
					   	  top: -66.5px;
						  }


							input[type=checkbox] {
								border: 0 !important;
							}

							textarea {
								position: relative;
								top: 2px;
							}
						}
						.error_messages {
							color: red;
							margin-top: 10px;
						}
					}
					.upload-button-wrapper {
						span {
							letter-spacing: 1px;
						}
					}
					.upload-type-file {
						opacity: .0;
					}
					.upload-wrapper {
						margin-top: 15px;

						.img-list {
							text-align: center;
						}

						.title {
							color: #697b8c;
							font-family: $opensans;
							font-weight: 700;
							text-transform: uppercase;
							letter-spacing: 1.25px;
							font-size: 12.5px;
							margin-bottom: 10px;
						}
						.subtitle {
							color: #697b8c;
							font-family: $opensans;
							font-weight: 700;
							text-transform: none;
							letter-spacing: 1.25px;
							font-size: 12.5px;
							margin-bottom: 10px;
						}
						.upload-box {
							margin-top: 0px;
							height: 400px;
							overflow-y: scroll;
							padding: 20px;
							border: 1px solid rgb(220, 220, 220);
							.list {
								margin-top: 20px;
								cursor: pointer;
								.list.plus {
									background: $gofood;
									padding: 20px;
									font-size: 40px;
									color: white;
									font-weight: 700;
									span {
										position: relative;
    									top: -20px;
										margin: 0 20px;
									}
								}
							}
							.upload-first {
								margin-top: 100px;
								color: rgb(195, 195, 195);
								font-size: 12px;
								text-align: center;
								.icon-preview {
									display: none;
								}
								.button-upload-first {
									width: 130px;
									background: $gofood;
									cursor: pointer;
									margin: 0 auto !important;
									display: block;
									padding: 15px;
									color: white;
									span {
										margin: 0 20px;
									}
								}
							}
							.upload-list {
								display: none;
								.list.plus {
									background: $gofood;
									padding: 20px;
									font-size: 40px;
									color: white;
									font-weight: 700;
									display: block;
									position: relative;
									top: 0px;
									span {
										position: relative;
										top: -20px;
										margin: 0 20px;
									}
								}
								.box {
									border: 1px solid rgb(200, 200, 200);
									height: 220px;
									position: relative;
									margin: 0 5px;
									margin-bottom: 10px;
									img {
										position: absolute;
										top: 50%;
										left: 50%;
										max-height: 100%;
										transform: translate(-50%, -50%);
									}
								}
							}
						}
					}
					.form-button {
						/*position: relative;
						top: -15px;
						left: -40px;*/
						position: relative;
						left: -10px;

						.button {
							font-size: 14px;
							font-family: $opensans;
							font-weight: 400;
							padding: 20px 0;
							transition: all .5s ease 0s;
							/*margin: 0 5px;*/
							span {
								margin: 0 50px;
							}
						}
						.button:hover,
						.button:focus {
							box-shadow: 0 2px 10px 0 rgba(0, 0, 0, .65);
						}
						.button-submit-go {
							right: 0;
						}
						.button-navigation {
							background: $gofood;
							color: #fff;
							padding: 27.75px;
							padding: 24px 0;
						}
						.button-navigation.next {
							background-color: $gofood;
						}
						.button-navigation.back {
							display: none;
							background: #282828;
							border: 0;
							color: white;
							text-transform: none;
							float: none;
							/*position: relative;
							left: 140px;
							width: 100%;
							top: 70px;
							margin-bottom: 10px;
							padding: 24px;
    						right: 175px;
						}
						.button-submit-go {
							display: none;
							background: $gofood;
							border: 0;
							color: white;
							text-transform: none;
							float: none;
							/*position: relative;
							left: 175px;
							width: 220px;
							top: 40px;
							margin-bottom: -100px;
							padding: 24px;*/
							padding: 24px 0;
						right: 170px;
						}
						.button-navigation.next {
							display: inline;
							right: 10px;
						}
						.button-navigation.back {
							display: none;
						}
					}
					/*
				 .button-submit {
						background: #48af4a;
						z-index: 100;
						box-shadow: 0 2px 10px 0 rgba(0,0,0,.45);
						font-size: 14px;
						font-family: $opensans;
						font-weight: 400;
						padding: 25px;
						transition: all .5s ease 0s;
						color: #fff;
						float: right;
						text-transform: none;
						position: absolute;
						top: 30px;
						left: -50px;
						border-radius: 8px !important;

						span {
							margin-right: 50px;
							margin-left: 50px;
						}
				 }

				.button-submi:hover {
					box-shadow: 0 2px 10px 0 rgba(0, 0, 0, .65);
				}
				*/
				}
			}
		}
	}
}

@media only screen and (max-width: 480px) {
	#gofood {
		.updatemenu {
			.section-join-form.updatemenu {
				.main-wrapper {
					 .form-wrapper {
						 .group {
							 .checkbox-label {
						   	 top: -62.5px;
							 }
						 }
					 }

					 .form-button {
						 .button-navigation.back {
						   right: 170px;
						 }

						 .button-submit-go {
 							 right: 6px;

 							 span {
 								margin: 0 40px;
 							 }
 						 }
					 }
			  }
			}
		}
	}
}
@media only screen and (max-width: 320px) {
	#gofood {
		.updatemenu {
			.section-join-form.updatemenu {
				.main-wrapper {
					 .form-button {
						 position: relative;
						 left: 15px;

						 .button-navigation.back {
						   right: 167px;
						 }

						 .button-submit-go {
							 right: 6px;

							 span {
								 margin: 0 40px;
							 }
						 }
					 }
			  }
			}
		}
	}
}
