/*
this is default scss
1. Styling for font-size,padding,margin section.
2. Styling for default tag
3. For grids system use pure grids system
*/
@import "../base/variables";


/* old style */
/* DISABLED 
	header#main--hero
	{
		margin-bottom: 0px;
		@media only screen and (max-width: 767px) {
			margin-bottom: 30px;
		}
	}
	button.download
	{
		color: #fff;
		border-radius: 5px;
		display: block !important;
		@media only screen and (max-width: 768px) {
		 	width:215px !important;
		}
	}
	footer
	{
		margin-top: 100px;
	}
*/
/* ending */


/* consitency beetween section */
section[new-version]
{
	text-align: center;
	padding: 60px 0;
	@media only screen and (max-width: 767px) {
		padding:30px 0;
	}
	&.stick-to-top
	{
		padding-top: 0px;
	}
	&.stick-to-top-mobile
	{
		@media only screen and (max-width: 767px) {
			padding-top: 0px;
		}
	}
	.container-all
	{
		&.center
		{
			position: relative;
			display: inline-block;
			width: 100%;
			max-width: 1020px;
			padding: 0 30px;
			&.has-items
			{
				@media only screen and (min-width: 768px) {
					padding: 0px;
				}
			}
			&.full-mobile
			{
				@media only screen and (max-width: 768px) {
					width: 100%;
					padding: 0px;
				}
			}
			&.increase-width-mobile
			{
				@media only screen and (max-width: 767px) {
					padding: 0 15px;
				}
			}
		}
	}
	/* default tag */
	p
	{
		font-size: 14px;
	}
	h3
	{
		line-height: normal;
		font-size: 28px;
		@media only screen and (max-width: 767px) {
			font-size: 21px;
		}
	}
	h4
	{
		line-height: normal;
		font-size: 25px;
		@media only screen and (max-width: 767px) {
			font-size: 18px;
		}
	}
	h5
	{
		line-height: normal;
		font-size: 18px;
		@media only screen and (max-width: 767px) {
			font-size: 14px;
		}
	}
	h6
	{
		line-height: normal;
		font-size: 14px;
	}

	/* forced to full width in mobile use it when container is not having full-mobile class */
	.full-child-mobile
	{
		@media only screen and (max-width: 767px) {
			position: relative;
			left: -30px;
    		width: calc(100% + 60px) !important;
		}
	}

	.title-underscore
	{
		line-height: 20px;
		padding: 0 30px;
		color: $gojek;
		margin: 0px;
		text-transform: uppercase;
		span
		{
			display: inline-block;
		}
		&:after
		{
			display: block;
		    content: "";
		    position: relative;
		    height: 4px;
		    width: 50px;
		    background-color: $gojek;
		    margin: 30px auto;
		    @media only screen and (max-width: 768px) {
				width:25px;
				height: 2px;
			}
		}
	}

	/* container switch places on mobile */
	.switch-mobile-container
	{
		@media only screen and (max-width: 767px) {
			display: table !important;
			.top
			{
				display: table-header-group;
			}
			.bottom
			{
				display: table-footer-group;
			}
		}
	}

	/* container vertical align for div */
	.container-vertical
	{
		display: inline-block;
		width: 100%;
		font-size: 0px;
		> div
		{
			display: inline-block;
			vertical-align: middle;
			font-size: 14px;
			&:after
			{
				display:inline-block;
				vertical-align: middle;
				content: "";
				position: absolute;
				top: 0;
			}
		}
	}

	/* add loading before slick init */
	.slider-set-mobile
	{
		@media only screen and (max-width: 767px) {
			&:not(.slick-initialized)
			{
				height: 120px;
				> div
				{
					opacity: 0;
					height: 0px;
				}
				&:after
				{
					animation: loadingSlider 0.4s infinite alternate;
					content: "";
					height:25px;
					width: 25px;
					border:2px solid $gojek;
					background: #fff;
					border-radius: 50%;
					position: absolute;
					left: 0;
					right: 0;
					top: 0;
					bottom: 0;
					margin: auto;
				}
			}
		}
	}

	.slider-set-desktop
	{
		@media only screen and (min-width: 769px) {
			&:not(.slick-initialized)
			{
				height: 120px;
				> div
				{
					opacity: 0;
					height: 0px;
				}
				&:after
				{
					animation: loadingSlider 0.4s infinite alternate;
					content: "";
					height:25px;
					width: 25px;
					border:2px solid $gojek;
					background: #fff;
					border-radius: 50%;
					position: absolute;
					left: 0;
					right: 0;
					top: 0;
					bottom: 0;
					margin: auto;
				}
			}
		}
	}
	.slider-set-both
	{
		&:not(.slick-initialized)
		{
			height: 120px;
			@media only screen and (min-width: 768px) {
				height: 400px;
			}
			> div
			{
				opacity: 0;
				height: 0px;
			}
			&:after
			{
				animation: loadingSlider 0.4s infinite alternate;
				content: "";
				height:25px;
				width: 25px;
				border:2px solid $gojek;
				background: #fff;
				border-radius: 50%;
				position: absolute;
				left: 0;
				right: 0;
				top: 0;
				bottom: 0;
				margin: auto;
			}
		}
	}

	/* ----- SLICK JS CUSTOM CSS  ---- */

	/* arrow slide default class */
	.new-float-arrow
	{
		cursor: pointer;
		z-index: 2;
		width: 40px;
		height: 40px;	
		position: absolute;
		bottom: -60px;
		margin-bottom: 0px;
		&.arrow-right
		{
			right: 0;
		}
		svg
		{
			transition: all 0.5s;
			ellipse
			{
				stroke:#fff;
			}
			polygon
			{
				fill: #fff;
			}
		}
		&.slick-disabled
		{
			cursor: disabled;
			svg
			{
				transition: all 0.5s;
				ellipse
				{
					stroke:#ececec;
				}
				polygon
				{
					fill:#ececec;
				}
			}
		}
	}

	/* custom dots slick */
	.normal-dots
	{
		position: absolute;
		bottom: -48px;
		left: 0;
		right: 0;
		margin: auto;
		padding-left: 0px;
		list-style: none;
		> li
		{
			transition: all 0.5s;
			border:1px solid #fff;
			display: inline-block;
			height: 10px;
			margin-right: 5px;
			width: 10px;
			border-radius: 50%;
			background: transparent;
			&.slick-active
			{
				background: #fff;
			}
			button
			{
				display: none;
			}
		}
	}

}
