// File Name: _goride.scss
// Description: Sample style for GO-JEK 2.0
// Used by: Main.scss
// Dependencies:
// ------------------------------------------------------------
#goride {
	header {
		button {
			background: $goride;
		}
	}
	.articles--feed {
		background: #1f4864;

		.bg {
			background: #1f4864;
		}

		.line--title {
			background: #ffcd2e;
		}
	}
	#content-only {
		transform: scale(0.960);
    margin-bottom: -30px;

		.container {
			box-shadow: 0 -3em 0 1.5em $goride;

			.info {
				h4 {
					color: #1f4864;
				}

				.line {
			    background: $goride;
				}

				.link {
					.contact {
						background: $goride;
					}
				}
			}
		}
	}
	#join-now {
    	margin: 0 0 30px 0;

		.right {
			a {
				background: $goride;
				bottom: initial;
				margin-top: 20px;
    		right: 0;
			}

			.title {
				font-size: 18px;
			}

			h2 {
				margin-top: -5px;
			}
		}
	}
	.section-how-to-use {
		h5 {
			color: #1f4864 !important;
			font-size: 18px;
			margin-top: 200px;
		}
		.line.gradient {
			background: $goride;
		}
		.arrow-up {
			stroke: $goride;
		}
		.arrow-up-circle {
			stroke-width: 3;
			stroke: $goride;
		}
		.arrow-down {
			stroke: $goride;
		}
		.arrow-down-circle {
			stroke: $goride;
			stroke-width: 3;
		}
		.slider .slick-dots li.slick-active button:before {
			color: $goride;
		}
		.slider-image .slick-dots li.slick-active button:before {
			color: $goride;
		}
		.text-mobile .line.gradient {
			background: $goride;
		}
		.text h5 {
			color: $goride;
		}
		.text .line.gradient {
			background: $goride;
		}
		.slider-image .slick-dots li.slick-active button:before {
			color: $goride;
		}
		.slider-image .slick-dots li button:before {
			font-size: 13px;
		}
	}
	.slick-arrow {
		cursor: pointer;
		ellipse {
			stroke: $goride !important;
		}
		polygon {
			fill: $goride;
		}
	}
	.how-it-works--wrapper {
		margin: 0;
		padding-top: 60px;
		padding-bottom: 60px;

		.container {
			padding: 0;

			h4 {
				color: $goride;
				position: relative;
				margin-left: 3em;
			}
			.section--title {
				color: #1f4864;
			}
			.section--title:before {
				content: "";
				position: absolute;
				left: -5em;
				top: 0.6em;
				padding-right: 45px;
				height: 3px;
				margin: 0;
				background-color: $goride;
				z-index: 10;
			}
			figure.hand-ss {
				background: #fff;
				img {
					max-height: 27em;
					width: auto;
					right: 0;
					margin: 0 auto;
					position: absolute;
					left: 0;
				}
			}
			.layer .rect--primary {
				background: $goride;
			}
			.layer .rect--primary.custom-color {
				background: #dbdbdb;
			}
			.layer .rect--secondary.custom-color {
				background: #1f4864;
			}
		}

	}

	.new-section-how-to-use
		{
		  margin-top: 60px;
		  margin-bottom: 60px;
			h3.title
			{
				color: #f9ae3c;
				&:after
				{
					background:#f9ae3c;
				}
			}
			.content
			{
				background-color:#f9ae3c;
			}
			@media screen and (max-width: 767px) {
				margin-top:50px !important;
			}
		}
}

@media screen and (max-width: 768px) {
	#goride {
		#content-only {
			transform: unset !important;
		}
		.how-it-works--wrapper {
			padding-top: 0;
			margin-top: -5px;

			.container {
				.section--title {
					margin-left: 4.2em;
					position: relative;
			    top: 10px;

					&:before {
						top: .565em !important;
					}
				}
			}
		}
		header#main--hero {
			// background-image: url(https://d3naj63yelf4gk.cloudfront.net/dist/img/header/hero/go-ride--mobile.jpg);
		}
	}
}

@media screen and (max-width: 35.5em) {
	#goride {
		.how-it-works--wrapper {
			padding-top: 0;
			margin-top: -5px;

			.container {
				.section--title {
					margin-left: 4.2em;
					position: relative;
			    top: 10px;

					&:before {
						top: .565em !important;
					}
				}
			}
		}
		#join-now {
		  .right {
		    .button {
		      text-align: center;

		      a {
		        right: unset !important;
		        position: relative;
		        top: 55px;
		      }
		    }
		  }
		}
		#content-only {
	    padding: 30px 10px;

		  .container {
				box-shadow: 0 -110px 0 40px #f9ae3c;

				.info {
					p {
						margin: 0;
				    margin-bottom: -30px;
						padding: 2.5px;
					}
				}

		    .link {
		      position: relative;
		      top: 40px;

					a {
						margin: 0 auto;
				    display: block;
						width: 200px;
						padding: 30px;
					}

					.faq {
						position: relative;
				    top: -10px;
					}
		    }
		  }
		}
		.section-how-to-use {
			.arrow--wrapper {
				top: 25%;
			}
			ul.slick-dots {
				margin-top: -3.5em;
			}
			.phone-wrapper {
				top: 31%;
			}
		}
	}
}
