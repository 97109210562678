#gomart {
	.new-section-how-to-use
	{

		h3.title
		{
			color: #3c95d1;
			&:after
			{
				background:#3c95d1;
			}
		}
		.content
		{
			background-color:#3c95d1;
		}
		@media screen and (max-width: 767px) {
			margin-top:50px !important;
		}
	}
	.gradient {
		background: $gomart;
	}
	header {
		button {
			background: $gomart;
		}
	}
	.section-our-values {
		h4 {
			color: $gomart;
		}
		img {
			max-height: 125px;
		}
	}
	.why-container {
		margin-top: 4em;
	}
	section.about {
		h4 {
			color: rgb(60, 149, 209);
		}
	}
	.merchant-list {
		h5 {
			color: $gomart;
		}
		hr {
			background: $gomart;
		}
		.blog-card-img {
			background-color: #fff;
			padding: 1em 0;
			img {
				margin: 0 auto;
				max-width: 80%;
				min-height: 3em;
			}
		}
	}
	.container {
		&:before {
			background-color: $gomart;
		}
	}
	.section-how-to-use {
		h5 {
			color: $gomart;
		}
		.line.gradient {
			background: $gomart;
		}
		.arrow-up {
			stroke: $gomart;
		}
		.arrow-up-circle {
			stroke-width: 3;
			stroke: $gomart;
		}
		.arrow-down {
			stroke: $gomart;
		}
		.arrow-down-circle {
			stroke: $gomart;
			stroke-width: 3;
		}
		.slider .slick-dots li.slick-active button:before {
			color: $gomart;
		}
		.slider-image .slick-dots li.slick-active button:before {
			color: $gomart;
		}
		.text-mobile .line.gradient {
			background: $gomart;
		}
		.text h5 {
			color: $gomart;
		}
		.text .line.gradient {
			background: $gomart;
		}
		.slider-image .slick-dots li.slick-active button:before {
			color: $gomart;
		}
	}
	.new-section-how-to-use
		{
			h3.title
			{
				color: #3c95d1;
				&:after
				{
					background:#3c95d1;
				}
			}
			.content
			{
				background-color:#3c95d1;
			}
		}
	@media screen and (max-width: 480px) {
		header#main--hero {
			// background-image: url(https://d3naj63yelf4gk.cloudfront.net/dist/img/header/hero/go-mart--mobile.jpg);
		}
	}
	.articles--feed {
		.bg {
			background: $gomart;
		}
		.read--more {
			background: $gomart;
		}
		.line--title {
			background: #fff;
		}
		.mobile--nav {
			svg {
				ellipse {
					stroke: white !important;
				}
				polygon {
					fill: white !important;
				}
			}
		}
	}
	.gallery--wrapper {
		.discover {
			background: $gomart;
		}
		h2 {
			color: $gomart;
		}
		.section-title:before {
			content: url(https://d3naj63yelf4gk.cloudfront.net/dist/img/page/gomart/hr.svg);
		}
	}
}

@media screen and (max-width: 35.5em) {
	#gomart {
		.section-how-to-use {
			.arrow--wrapper {
				top: 31%;
			}
			.phone-wrapper {
				top: 40%;
			}
		}
	}
}
