// ----------------------------------------------------------------------

  // Anchor Button Style

// ----------------------------------------------------------------------

// example: @include anchor-mobile($bgColor);

@mixin anchor-mobile($bgColor: #48af4a) {
	font-size: 0.625em;
	font-weight: 700;
	color: $bgColor;
	text-decoration: none;
	letter-spacing: 0.206em;
}

@mixin anchor-desktop($bgColor: #48af4a) {
	font-size: 0.875em;
	font-weight: 700;
	color: $bgColor;
	text-decoration: none;
	letter-spacing: 0.206em;
}

@mixin anchor-hover($bgColor: #48af4a, $normal: false) {
	color: darken($bgColor, 22%);

	@if $normal == true {
		color: $bgColor;
	}
}

@mixin anchor-active($bgColor: #48af4a) {
	color: darken($bgColor, 12%);
}
