section#download-cards {
	padding: 5em 0;
	h4 {
		color: #b3b9c7;
		font-family: $opensans;
		text-transform: uppercase;
	}
	h2 {
		font-family: $lato;
		font-weight: 300;

		span {
			font-weight: 400;
		}
	}
	.bg-right {
		background: #fff;
	    padding: 2em;
	    position: relative;

	    img {
	    	display: none;
	    }
	}
	.bg-left {
		display: none
	}
	.download-list {
		position: absolute;
		bottom: 0;
		right: 0;

		.download-btn {
			float: left;
			width: 40px;
			height: 40px;
			cursor: pointer;

			&.ios {
				background: #000 url('../img/card/download/ios.svg') no-repeat center center;
				margin-left: 10px;
			}
			&.google-play {
				background: #7ed321 url('../img/card/download/google-play.svg') no-repeat center center;
			}
		}
		p {
			float: left;
		    margin-top: 1.8em;
		    color: #98ce00;
		    font-size: 8px;
		}
		svg {
			width: 12px;
			float: left;
		    margin-top: 1em;
		    margin-left: 10px;
	        margin-top: 1em;
		}
	}
	.content-wrapper {
		background-color: #343b41;
		color: #fff;
		display: inline-block;
		padding: 2em;
		position: relative;
		padding-bottom: 4em;
	}
}

@media screen and (min-width: 48em) {
	section#download-cards {
		.content-wrapper {
		    margin-left: -6em;
		    padding-bottom: 6em;
		}

		.download-bg {
		    position: absolute;
		    top: 2em;
		    left: -47em;
		    z-index: -1;

		    img {
		    	display: block;
		    	height: 17em;
		    }
		}

		.download-list {
			.download-btn {
				width: 90px;
				height: 90px;
			}

			p {
				font-size: 1em;
				margin-top: 2.2em;
			}
			svg  {
			    width: 1.3em;
			    margin-top: 2.5em;
			}
		}

		.bg-left {
			display: inline-block;
			img {
			    height: 19em;
			}
		}
	}
}
