section.services {
	background: #FAFAFA;
	padding: 50px 0 13em !important;

	.container {
		position: relative;
		&:before {
			content: "";
			position: absolute;
			height: 3px;
			margin: 0;
			background-color: $gojek;
			z-index: 10;
			left: -2em;
	    top: 52px;
      padding-right: 45px;
		}
	}
	h4 {
		color: #48af4a;
		text-transform: uppercase;
		padding-left: 15px;
		padding-bottom: 2em;
		@media screen and (min-width: 769px) {
			position: relative;
			top: 19px;
		}
	}
	p {
		color: #485460;
		padding-bottom: 3em;
	}
	.wrapper {
		width: 100%;
		margin: 0;
		padding: 1.563em 15px;
		padding-bottom: 0;
		padding-top: 0;
		background-color: #fff;
		position: relative;

		img.service-logo {
			position: absolute;
			top: 25px;
			right: 25px;
			width: 100px;
		}
		.service-container {
			height: 15em;
			background-image: linear-gradient(139deg, #9fda36, #25c768);
			margin: 0 -15px;
			margin-right: -20%;
			.service-list {
				background-color: #fff;
				width: 13em;
				margin: 3em 7px 15px;
				padding: 0 25px 25px;
				box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.11);
				&.slick-slide:focus {
					outline: none;
				}
				p {
					padding: 0;
					min-height: 160px;
				}
				.img-container {
					position: relative;
					width: auto;
					right: auto;
					top: auto;
					background-color: #FEFEFE;
					margin-top: -25px;
					img {
						position: absolute;
						top: 0;
						bottom: 0;
						left: 0;
						right: 0;
						margin: auto;
					}
				}
				.bg-services {
					background-position: center center;
					background-size: cover;
					height: 8em;
					opacity: .16;
				}
				a {
					color: $gojek;
					text-transform: uppercase;
					position: relative;
					&:after {
						content: "";
						position: absolute;
						top: 3px;
						width: 20px;
						height: 20px;
						background-size: 15px;
						background-image: url(../img/about/shape-arrow.svg);
						background-repeat: no-repeat;
						margin-left: 12px;
					}
				}
			}
			.slick-list,
			.slick-track {
				// margin-left: -25px !important;
			}

			.slick-track {
		    margin-left: 5% !important;
			}
			.service-list {
				width: 20em !important;

				.bg-services {
			    height: 12em;
				}
		    p {
		    	min-height: 125px;
				}
			}
		}
		.navigation-arrow {
			padding-top: 10em;
			padding-bottom: 2em;
			background: white;
			margin: 0;
			.left,
			.right {
				cursor: pointer;
				display: inline-block;
				width: 42px;
				height: 42px;
				svg {
					width: 100%;
				}
				:focus {
					background: none;
					outline: 0;
				}
			}
			.right {
				float: right;
			}
			.slick-disabled {
				ellipse {
					stroke: #D2D2D2 !important;
				}
				polygon {
					fill: #D2D2D2 !important;
				}
			}
		}
	}
}

section.services#golife {
	padding-bottom: 7em;
	.wrapper .navigation-arrow {
		padding-top: 13em;
	}
	.wrapper .service-container .service-list {
		margin-top: 6em;
		.img-container {
			position: relative;
			width: auto;
			right: auto;
			top: -35px;
			background-color: #FEFEFE;
			margin: 0 auto;
			margin-top: -25px;
			border-radius: 50%;
			overflow: hidden;
			width: 130px;
			height: 130px;
			img {
				position: absolute;
				top: 0;
				bottom: 0;
				left: 0;
				right: 0;
				margin: auto;
				object-fit: cover;
				height: 100%;
			}
		}
	}
}

	
/* Mobile Screen */
@media screen and (max-width: 414px) {
	section.services {
		padding-bottom: 4em !important;

		h4 {
			font-size: .688em;
	    padding-bottom: 0px;
	    line-height: 4em;
		}
		.container:before {
	    left: -1em;
      top: 35px;
		}
		.wrapper {
			.service-container {
				.slick-track {
    			margin-left: 5% !important;
    		}
    		.service-list {
			    width: 15em !important;

			    .bg-services {
	    	    height: 10em;
			    }
    		}
    	}
    	img.service-logo {
    		width: 100px;
  			right: 25px;
    	}
    }
	}
}

/* Medium Screen */

@media screen and (min-width: 48em) {
	section.services {
		padding-bottom: 12em;
	}
	section.services .img-wrapper .img-container {
		margin-top: 40%;
	}
	section.services .img-wrapper {
		display: block;
	}
	section.services a:after {
		top: 6px;
		width: 30px;
		height: 30px;
		background-size: 20px;
	}
	section.services h4 {
		// padding: 0;
	}
	section.services .container:before {
		padding: 0;
	}
	section.services .wrapper {
		width: 100%;
	}
	section.services .wrapper img.service-logo {
		top: 2.5em;
		right: 0;
		left: 0;
		margin-left: auto;
		margin-right: auto;
	}
	section.services#golife .wrapper .navigation-arrow,
	section.services .wrapper .navigation-arrow {
		padding: 0;
		right: 2.5em;
		top: 2.5em;
		position: absolute;
		.left,
		.right {
			float: none
		}
		.left {
			padding-right: 5px;
		}
		.right {
			padding-left: 5px;
		}
	}

	section.services
	{
		.wrapper
		{
			.service-container
			{
				.service-list
				{
					p
					{
						min-height: 200px;
					}
				}
			}
		}
	}
}

section.services.go-jek .wrapper .service-container {
	background: #58c75b;
}

section.services.go-pay .wrapper .service-container {
	background: #0bb2ee;
}

section.services.go-life .wrapper .service-container {
	background: #48af4a;
}


/* Large Screen */

@media screen and (min-width: 64em) {
	section[data-target="go-pay"] .navigation-arrow
	{
		display: inline;
	}
	section.services#golife {
		padding-bottom: 17em;
	}
	section.services .wrapper .service-container .service-list {
		width: 17em;
	}
	section.services
	{
		.wrapper
		{
			.service-container
			{
				.service-list
				{
					p
					{
						min-height: 160px;
					}
				}
			}
		}
	}
}

@media screen and (min-width: 1440px) {
	section.services .wrapper .service-container {
		margin-right: -50%;
	}
}
