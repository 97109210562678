.merchant-list {
	background: #f8f8f8;
	text-align: center;
	font-family: $opensans;
	font-weight: 300;
	line-height: 0.88;
	letter-spacing: 1.7px;
	color: #48af4a;
	padding: 2.5em;
	hr {
		background: $gopoint;
		border: none;
		display: inline-block;
		width: 50px;
		height: 4px;
	}
	.pure-g {
		margin-top: 3em;
	}
	.content {
		margin: 0 1.25em 5em;
		.blog-card-title {
			text-align: left;
			font-family: $opensans;
			padding: 1em 1.5em;
			color: black;
			background: white;
			font-weight: 300;
			line-height: 1.42;
			letter-spacing: 2.2px;
			h4 {
				font-weight: 700;
				line-height: 1.25;
				letter-spacing: 0.8px;
			}
			p {
				font-weight: 300;
				line-height: 1.56;
				letter-spacing: 0.8px;
			}
		}
	}
	.mobile {
		display: none;
	}
	.custom-nav {
		display: none;
	}
}

@media screen and (max-width:35.5em) {
	.merchant-list {
		padding: 2em 0 5em 0;
		.desktop {
			display: none;
		}
		.mobile {
			display: inherit;
		}
		.custom-nav {
			display: block;
			#custom-prev {
				left: 3.5em;
				position: absolute;
			}
			#custom-next {
				right: 3.5em;
				position: absolute;
			}
		}
		.slick-track {
			padding-top: 2em;
		}
		.slick-current {
			box-shadow: 0 2px 29px 0 rgba(0, 0, 0, 0.19);
		}
	}
}

@media screen and (max-width: 48em) {
	.merchant-list {
		.blog-card-title {
			min-height: 300px;
			h3 {
				font-size: 0.7em;
			}
		}
	}
}
