.academy {
	h1.hero--title
	{
		    font-family: Viva,Helvetica,sans-serif;
	}
	section.about
	{
		text-align: center;
		.description
		{
			font-size: 20px;
		}
	}
	section.about h4 {
		position: relative;
	}
	section.about h4:before {
		content: "";
		background: #48af4a;
		position: absolute;
		height: 3px;
		width: 35px;
		left: 0;
		right: 0;
		margin: auto;
		bottom: -30px;
	}
	.how-it-works--wrapper {
		margin-top: 0;
		.container .how--title {
			font-weight: 500;
		}
		.section--title::before {
			top: 10px !important;
		}
		.desc--pad {
			a {
				line-height: 0.8;
				letter-spacing: 3.3px;
				color: #48af4a;
				font-weight: 700;
			}
		}
		.talk {
			.rect--secondary {
				background: #8cc63f;
			}
		}
		.squad {
			.rect--secondary {
				background: #e8ebf2;
			}
		}
		.visit {
			.rect--secondary {
				background: #48af4a;
			}
		}
	}
}

@media screen and (max-width: 35.5em) {
	.academy {
		section.about h4:before {
			display: none;
		}
	}
}
