#goglam {
	h5 {
		color: $gopoint;
	}
	.articles--feed {
		ellipse {
			stroke: #fff !important;
		}
		polygon {
			fill: #fff !important;
		}
	}
	.section-our-values {
		padding: 3em 0;
		background: #f8f8f8;
		h4.title {
			color: $goglam;
		}
		.line.gradient {
			background: $goglam;
		}
		.list {
			h4 {
				line-height: 1.8em;
				font-size: 0.7em;
			}
			img {
				max-height: 100px;
				margin: 3em 0;
			}
			p {
				font-size: 11px;
			}
		}
	}
	.section-user-review {
		.slick-dots li.slick-active button:before {
			color: #aa2d53;
		}
	}
	.merchant-list {
		h5 {
			color: $goglam;
		}
		hr {
			background: $goglam;
		}
		.line.gradient {
			background: $goglam;
		}
		.list h4 {
			line-height: 25px;
			font-size: 15px;
		}
	}
	#talent-story {
		h4 {
			color: $goglam;
			&:before {
				background-color: $goglam;
			}
		}
		hr {
			background: $goglam;
		}
		.detail h2 {
			color: $goglam;
		}
		.info h5 {
			color: $goglam;
		}
	}
	.articles--feed {
		.bg {
			background: $goglam;
		}
		.read--more>a {
			background: $goglam;
		}
		.content .one {
			border-bottom: 6px solid $goglam;
		}
		.line--title {
			background: #fff;
		}
		.content .two {
			border-bottom: 6px solid $goglam;
		}
		.content .three {
			border-bottom: 6px solid $goglam;
		}
		.content .four {
			border-bottom: 6px solid $goglam;
		}
		h4>a:hover {
			color: $goglam;
		}
	}
	.section-user-review {
		.head .title {
			color: $goglam;
		}
		.head .line.gradient {
			background: $goglam;
		}
		.btm.layer {
			position: relative;
			width: 100%;
			left: 0;
		}
		.item {
			.wrapper {
				min-height: 350px;
			}
		}
		.wrapper .line.light.yellow {
			background: white;
		}
		.slider .item .wrapper.style-2 {
			background: $goglam;
		}
		.slider .item .wrapper.style-3 {
			background: #aa2d53;
		}
	}
	.services {
		.container:before {
			background: #aa2d53;
		}
		h4 {
			color: #aa2d53;
		}
		.wrapper {
			.service-container {
				background: #e77499;
			}
		}
		.service-list {
			max-height: 23em;
			h4 {
				padding: 0;
				margin: 0;
				margin-top: 1.2em;
				margin-bottom: 1em;
			}
			p {
				margin-top: 0;
			}
		}
	}
	#talent-story {
		.slider {
			.slick-slide {
				min-height: 350px !important;
			}
		}
	}
	
	section#new-join
	{
		margin: 48px 0px 100px 0px;
		.container-form{
			.content {
				form {
					.separate-input {
						label.checkbox {
							> label[for] {
								text-transform: capitalize;
								width: 49%;
								height: 17px;
								object-fit: contain;
								font-family: $opensans;
								font-size: inherit;
								font-weight: normal;
								font-style: normal;
								font-stretch: normal;
								line-height: normal;
								letter-spacing: 1.3px;
							}
							@media screen and (max-width: 767px) {
								width:100%;
							}
						}
					}
				}
			}
		}
	}

	#content-only.thank-you
	{
		margin-top: 130px;
		a.contact
		{
			width:300px;
		}
		@media screen and (max-width: 768px) {
			margin-bottom: 130px;
			a.contact
			{
				width:100%;
				line-height: normal;
			}
		}
		.container {
			background: white;
			box-shadow: 0 -3em 0 1.5em $goglam;
			.info {
				h4 {
					color: $goglam;
				}
				.line {
					background: $goglam;
				}
				.link {
					a {
						width: 280px;
					}
					.contact {
						margin-bottom: 15px;
						span {
							margin: 0 15px;
						}
					}
					.contact.homepage {
						background: rgb(200, 200, 200);
					}
				}
			}
		}
	}

	#join-now {
		h2 {
			text-transform: capitalize;
			font-size: 2em;
		}
		a {
			background: $goglam;
		}
		.container {
			position: relative;
			.left {
				height: 100%;
				position: absolute;
				// background: url(https://d3naj63yelf4gk.cloudfront.net/dist/img/page/goglam/join.jpg);
				background-size: cover;
				background-position: center center;
			}
			.right {
				left: 58.3333%;
			}
		}
	}
}

@media screen and (max-width: 768px) {
	#goglam {
		section.services#golife {
			padding-bottom: 12em;
		}
		.section-user-review {
			padding: 80px 0;
			.item {
				.wrapper {
					min-height: 300px;
				}
			}
			.slider .item .wrapper h3.user-testimonial {
				height: 125px;
			}
		}
	}
}

@media screen and (max-width: 35.5em) {
	#goglam {
		#talent-story .slick-dots li.slick-active button:before {
			color: #aa2d53;
		}
		header#main--hero {
			// background-image: url(https://d3naj63yelf4gk.cloudfront.net/dist/img/header/hero/go-glam--mobile.jpg);
		}
		.section-our-values {
			padding: 0;
		}
		.section-user-review {
			.item {
				.wrapper {
					min-height: 300px;
				}
			}
		}
		#join-now {
			.container {
				.left {
					position: relative;
					height: 250px;
				}
				.right {
					left: 0;
				}
			}
		}
		section.services#golife {
			padding-bottom: 0px !important;
		}
	}
}

@media screen and (min-width: 48em) {
	#goglam {
		.section-our-values {
			.list {
				img {
					max-height: 100px;
					margin: 5em 0;
				}
			}
		} // .section-user-review {
		// 	.slider .item .wrapper h3.user-testimonial {
		// 		height: 70px;
		// 	}
		// }
	}
}
