#gosend {
	.section-join-form {
		margin-top: 50px;
		padding: 80px;

		.main-wrapper {
			height: 720px;
			width: 100%;
			box-shadow: 0px 0px 50px rgba(90, 90, 90, .25);
		}
		.main-wrapper .background-wrapper {
			background: url('https://d3naj63yelf4gk.cloudfront.net/dist/img/page/gosend/texture-img.png');
			min-height: 720px !important;
			width: 100%;
			padding: 0;
			text-align: center;
		}
		.main-wrapper .background-wrapper > img {
			max-width: 60px;
			margin-top: 280px;
		}
		.main-wrapper .form-wrapper {
			padding: 50px;
		}
		.main-wrapper .form-wrapper .title {
			margin-top: -15px;
			font-size: 12px;
			position: relative;
			top: 10px;
			color: rgba(200, 200, 200, 1.0);
		}
		.main-wrapper .form-wrapper .group {
			width: 100%;
			margin-top: 30px;
			margin-bottom: 30px;
		}
		.main-wrapper .form-wrapper .group label {
			font-family: "Open Sans", sans-serif;
			font-weight: 700;
			font-size: 12.5px;
			color: #697b8c;
		}
		.main-wrapper .form-wrapper .group input,
		.main-wrapper .form-wrapper .group select {
			width: 100%;
			height: 45px;
			padding: 15px;
			margin-top: 5px;
			letter-spacing: 1.2px;
			font-size: 13.4px;
			background: white;
			border-radius: 0 !important;
			border: 1px solid rgb(210, 210, 210);
			color: #485460;
		}
		.error_messages {
			color: red;
			margin-top: 10px;
		}
		.main-wrapper .form-wrapper .group input[type="checkbox"] {
			width:  10%;
			position: relative;
			margin-bottom: -20px;
			margin-top: -10px;
			display: inline-block;
		}
		.main-wrapper .form-wrapper .group .checkbox-label {
			font-family: "Open Sans", sans-serif;
			font-size: 12.5px;
			margin-top: -10px !important;
			position: relative;
			top: -16px;
			margin-bottom: 20px;
		}
		.main-wrapper .button-submit {
			background: $gosend;
			z-index: 100;
			box-shadow: 0 2px 10px 0 rgba(0, 0, 0, .45);
			position: absolute;
			font-family: 'Open Sans', sans-serif;
			font-weight: 400;
	    top: 810px;
	    right: 170px;
			padding: 24px;
			transition: all 0.5s ease 0s;
			color: white;
			border-radius: 8px;
		}
		.main-wrapper .button-submit:hover {
			box-shadow: 0 2px 10px 0 rgba(0, 0, 0, .65);
		}
		.main-wrapper .button-submit > span {
			margin-left: 50px;
			margin-right: 50px;
		}
	}

	.section-our-values {
		background: white;

		.additional-text {
			color: #9b9b9b;
			font-weight: 300;
			font-size: 14px;
			line-height: 1.71;
		}

		.list h4 {
			text-transform: none;
		}

		.list p {
			font-size: 14px;
			font-weight: 300;
			color: #9b9b9b;
			line-height: 1.71;
			letter-spacing: 1px;
		}
	}

	@media only screen and (max-width: 768px) {
		.section-join-form {
			padding: 0;
			margin: 0;
			margin-top: 80px;

			.main-wrapper {
				height: auto;
				width: 100%;
				box-shadow: 0px 0px 50px rgba(90, 90, 90, .25);
			}
			.main-wrapper .form-wrapper .title {
		    margin-top: -15px;
		    font-size: 12px;
		    position: relative;
		    top: 10px;
		    color: #c8c8c8;
				text-align: center;
			}

			.main-wrapper .background-wrapper {
				background: url('https://d3naj63yelf4gk.cloudfront.net/dist/img/page/gosend/texture-img.png');
				min-height: 130px !important;
				width: 100%;
				padding: 0;
				text-align: center;
			}
			.main-wrapper .background-wrapper > img {
				max-width: 60px;
				margin-top: 30px;
			}
			.error_messages {
				color: red;
				margin-top: 10px;
				letter-spacing: 1px;
			}
			.main-wrapper .form-wrapper {
		    background: #fff;
		    padding: 25px !important;
			}
			.main-wrapper .form-wrapper .group label {
			    font-family: Open Sans,sans-serif;
			    font-weight: 700;
			    font-size: 12.5px;
			    color: #697b8c;
					letter-spacing: .9px;
			}
			.main-wrapper .form-wrapper .group .checkbox-label {
				letter-spacing: 1px;
				margin-left: 10px;
			}
			.main-wrapper .button-submit {
				background: $gosend;
				z-index: 100;
				padding: 25px;
				top: 25px;
				font-size: 14px;
				left: -15px;
				box-shadow: 0 2px 10px 0 rgba(0, 0, 0, .45);
				position: relative;
				transition: all 0.5s ease 0s;
				color: white;
				border-radius: 8px;
			}
		}
	}

	@media only screen and (max-width: 480px) {
		.section-join-form {
			padding: 0;
			margin: 0;
			margin-top: 50px;

			.main-wrapper {
				height: auto;
				width: 100%;
				box-shadow: 0px 0px 50px rgba(90, 90, 90, .25);
			}
			.main-wrapper .form-wrapper .title {
		    margin-top: -15px;
		    font-size: 12px;
		    position: relative;
		    top: 10px;
		    color: #c8c8c8;
				text-align: center;
			}

			.main-wrapper .background-wrapper {
				background: url('https://d3naj63yelf4gk.cloudfront.net/dist/img/page/gosend/texture-img.png');
				min-height: 130px !important;
				width: 100%;
				padding: 0;
				text-align: center;
			}
			.main-wrapper .background-wrapper > img {
				max-width: 60px;
				margin-top: 30px;
			}
			.error_messages {
				color: red;
				margin-top: 10px;
				letter-spacing: 1px;
			}
			.main-wrapper .form-wrapper {
		    background: #fff;
		    padding: 25px !important;
			}
			.main-wrapper .form-wrapper .group label {
			    font-family: Open Sans,sans-serif;
			    font-weight: 700;
			    font-size: 12.5px;
			    color: #697b8c;
					letter-spacing: .9px;
			}
			.main-wrapper .form-wrapper .group .checkbox-label {
				letter-spacing: 1px;
				margin-left: 10px;
			}
			.main-wrapper .button-submit {
				background: $gosend;
				z-index: 100;
				padding: 25px;
				top: 25px;
				font-size: 14px;
				left: -15px;
				box-shadow: 0 2px 10px 0 rgba(0, 0, 0, .45);
				position: relative;
				transition: all 0.5s ease 0s;
				color: white;
				border-radius: 8px;
			}
		}

		.section-our-values {
			.title {
		    color: #48af4a;
		    text-align: center;
		    text-transform: uppercase;
		    font-size: 17px;
				line-height: 25px;
				margin-top: 20px;
			}

			.list h4 {
				font-size: 15.5px;
			}

			.additional-text {
		    padding: 15px;
			}
		}
	}

	@media only screen and (max-width: 414px) {
		.section-join-form {
			padding: 0;
			margin: 0;
			margin-top: 100px;
		}
	}

	@media only screen and (max-width: 375px) {
		.section-join-form {
			padding: 0;
			margin: 0;
			margin-top: 140px;
			margin-bottom: 40px;

			.container {
				padding: 10px;
			}
		}


		.section-our-values {

			.title {
		    color: #48af4a;
		    text-align: center;
		    text-transform: uppercase;
		    font-size: 17px;
				line-height: 25px;
				padding: 20px;
			}
		}
	}

	@media only screen and (max-width: 320px) {
		.section-join-form {
			padding: 0;
			margin: 0;
			margin-top: 140px;
			margin-bottom: 40px;

			.container {
				padding: 10px;
			}
		}
	}
}

#popup_box {
  display: none;
  position: fixed;
  _position: absolute;
  width: 600px;
  background: #FFFFFF;
  left: 400px;
  top: 150px;
  z-index: 100;
  margin-left: 15px;
  padding: 35px;
  font-size: 15px;
  -moz-box-shadow: 0 0 5px #ff0000;
  -webkit-box-shadow: 0 0 5px #ff0000;
  box-shadow: 0 0px 30px 0px rgba(140, 140, 140, .85);

	h3 {
		margin: 0;
		padding: 0;
	}

	h5 {
		font-weight: 300;
		font-size: 14px;
    margin-top: 15px;
		line-height: 20px;
		margin-bottom: 35px;
	}
}
#popupBoxClose {
  font-size: 15px;
  padding: 10px;
	cursor: pointer;
	background: $gosend;
	letter-spacing: 2px;
  position: relative;
  color: white;
  top: -5px;
  font-weight: 500;
}

@media only screen and (max-width: 480px) {
	#popup_box {
		display: none;
		position: fixed;
		_position: absolute;
		width: 100%;
		background: #FFFFFF;
		left: -15px;
		top: 150px;
		z-index: 100;
		margin-left: 15px;
		padding: 35px;
		font-size: 15px;
		-moz-box-shadow: 0 0 5px #ff0000;
		-webkit-box-shadow: 0 0 5px #ff0000;
		box-shadow: 0 0px 30px 0px rgba(140, 140, 140, .85);
	}
}
