#bg-as-seen-on {
	background: #f8f8f8;
	padding: 5em 0;
}

.as-seen-on {
	position: relative;
	text-align: center;
	.title {
		text-align: left;
		p {
			display: inline-block;
			margin-left: 3em;
			font-weight: 700;
		}
	}
	hr {
		background: #25c768;
		border: none;
		display: inline-block;
		width: 50px;
		height: 4px;
		left: 1.5em;
		top: 2.8em;
		position: absolute;
	}
	#left,
	#right {
		height: 250px;
	}
	#left {
		background: #343b41;
		position: absolute;
		bottom: 0; // left: -3.02em;
	}
	#content {
		background: white;
		line-height: 14px;
		letter-spacing: 1.9px;
		color: #48af4a;
		font-family: $opensans;
		font-weight: 700;
		padding: 2em 0;
		margin-left: auto;
		margin-right: auto;
		img {
			max-height: 100px;
			width: auto; // display: block;
			padding: 2em;
		}
	}
	#right {
		position: absolute;
		background: #98ce00;
		right: -3.02em;
	}
	img {
		width: 55em;
		margin: 0 auto;
		filter: grayscale(100%);
		padding: 2em 0;
		opacity: 0.5;
	}
	img, a {		
		-webkit-transition: all .3s ease-in;
		-moz-transition: all .3s ease-in;
		-o-transition: all .3s ease-in;
		transition: all .3s ease-in;
	}
	img:hover,
	a:hover {
		filter: none;
		opacity: 1;
	}
}


/* Small Screen */

@media screen and (max-width:35.5em) {
	.as-seen-on {
		padding: 0;
		hr {
			left: -1em;
			top: 2.35em;
		}
		p {
			margin-left: 0;
		}
		#left {
			left: 0;
		}
		#right {
			right: 0;
		}
		#content {
			padding: 1.5em;
		}
		#left,
		#right {
			height: 350px;
		}
	}
}


/* Medium Screen */

@media screen and (min-width: 48em) {
	.as-seen-on {
		img {
			width: 41em;
		}
		#left,
		#right {
			height: 200px;
		}
		#left {
			left: 0;
		}
		#right {
			right: 0;
		}
	}
}

/* ipad =< media */
@media screen and (max-width:768px) {
	.as-seen-on
	{
		#content
		{
			img
			{
				max-height: none !important;
				width:100%;
				max-width:200px;
			}
			img.big-image
			{
				max-width: 120px;
			}
		}
	}
}


/*  992px */
@media screen and (min-width:62em) {
	.as-seen-on {}
}


/* Large Screen */

@media screen and (min-width:64em) {
	.as-seen-on {
		img {
			width: 60em;
		} // hr {
		// 	left: -0.5em;
		// }
		#left {
			// left: -1.02em;
		}
		#right {
			// right: -1.02em;
		}
	}
}


/* Extra Large Screen */

@media screen and (min-width: 80em) {
	.as-seen-on {}
}
