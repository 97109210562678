#gosend {
	[data-pages=join] {
		.messages-alert {
			position: fixed;
			display: none;
			background-color: $gosend;
			width: 100%;
			padding: 20px;
			margin-top: 0px;
			z-index: 99;
			top: 86px;
			margin-bottom: -80px;
			box-shadow: 0px -5px 25px rgba(10, 10, 10, .85);
			h5 {
				color: white;
				font-size: 15px;
				margin: 0;
				padding: 0;
			}
		}

		.section-our-values {
			h4 {
				&.title {
					color: unset;
				}
			}

			.list {
				h4 {
					font-weight: unset;
				}

				.line {
					position: relative;
					top: 35px;
				}
			}

			&.new
			{
				img {
					max-height: 150px;
					width: auto;
				}
				h4.title
				{
					@media screen and (max-width: 767px)
					{
							&:after
							{
								top: 30px;
							    position: absolute;
							    width: 15px;
							    left: -20px;
							    background-color: #4285a7;
							    height: 2px;
							    content: "";
							}
					}
				}
				.list
				{
					svg
					{
						height: 140px;
						@media screen and (max-width: 767px)
						{
							height: 130px;
						}
						&.adjust
						{
							position: relative;
							left: -20px;
						}
					}
					h4
					{
						@media screen and (min-width: 768px)
						{
							margin-top: 50px;
							margin-bottom: 40px;
						}
						color: #4a4a4a;
						@media screen and (max-width: 767px)
						{
							padding: 0px;
						    margin-bottom: 0px;
						    margin-top: 20px;
						}
					}
					p
					{
						font-family: $opensans;
						@media screen and (min-width: 768px)
						{
							margin-top:10px;
						}
						@media screen and (max-width: 767px)
						{
							max-width: 300px;
						}
					}
				}
				.line
				{
					background: $gosend;
					@media screen and (max-width: 767px)
					{
						display: none;
					}
				}
				h4.title
				{
					@media screen and (max-width: 767px)
					{
						font-size: 14px;
						text-align: left;
					}
				}
				#type-gobills
				{
					width: 100%;
					.arrow-left
					{
						position: absolute;
						left: 0;
						bottom: -40px;
						width:50px;
					}
					.arrow-right
					{
						position: absolute;
						right: 0;
						bottom: -40px;
						width:50px;
					}
					.slick-arrow.slick-disabled
					{
						filter: grayscale(100) brightness(1.5);
					}
					@media screen and (min-width: 768px)
					{
						margin-top:40px;
					}
				}
				@media screen and (max-width: 767px)
				{
					padding:20px;
					padding-bottom:100px;
					margin-top:40px;
					.container-slider
					{
						width:100%;
						padding:0px;
						position:relative;
						display:inline-block;
					}
				}
			}
		}

		#new-join {
			margin-bottom: 120px;
		}

		input, select {
			&:valid
			{
				border:1px solid $gojek;
			}
			&:focus
			{
				border:1px solid #3992f0 !important;
				box-shadow: 0px 0px 3px 1px rgba(57,146,240,1);
				transition:all 0.5s;
				background: transparent !important;
				+ ul
				{
					display: none;
				}
			}

			&.error {
				border: 1px solid $gofood !important;
			}
		}

		.error-message {
			letter-spacing: 1px;
			margin-top: 10px;
			color: $gofood;
		}


		section.new-our-partner
		{
			background-color: #fafafa;
			text-align: center;
	    margin: 80px auto;

			ol {
				position: relative;
				top: -10px;
			}

			.container-slide {
				display: inline-block;
				max-width: 1170px;
				position: relative;
				height: 460px;
				width:90%;

				.title {
					z-index: 1;
					position: absolute;
					width: 50%;
					padding-left: 20px;
					font-size: 18px;
					color:unset;
					right:0;
					text-align: left;
					font-weight: 700;
					height:100px;
					letter-spacing: 2.5px;
					&:before
					{
						@media screen and (min-width: 769px) {
							position: absolute;
						    width: 35px;
						    left: -25px;
						    height: 3px;
						    background: #49af4b;
						    top: -5px;
						    bottom: 0;
						    content: "";
						    margin: auto;
						}
					}
					&:after
					{
						content: "";
					    display: inline-block;
					    vertical-align: middle;
					    height: 100%;
					    @media screen and (max-width: 768px) {
							height:3px;
							width:50px;
							margin:20px auto;
							background: #49af4b;
							display:block;
							content:"";
						}
					}
					@media screen and (max-width: 768px) {
						margin-top:40px;
						position:relative;
						height:auto;
						padding-left:0px;
						width:100%;
						text-align:center;
						font-size:14px;
					}
				}
				.hero
				{
					float: left;
					height: 460px;
					width:50%;
					background: #ebebeb;
					.image-hero
					{
						height: 460px;
						width: 100%;
						background-size: cover;
						background-position: center;
					}
					@media screen and (max-width: 768px) {
						width:100%;
					}
				}
				.comment
				{
					position: absolute;
					height: calc(100% - 100px);
					background: #fff;
					right: 0;
					bottom:0;
					width: 50%;
					.content
					{
						text-align: left;
						position: absolute;
					    height: 80%;
					    width: 70%;
					    top: 0;
					    bottom: 0;
					    left: 7%;
					    font-size: 14px;
					    margin: auto;
					    @media screen and (max-width: 768px) {
					    	width: 100%;
					    	position: relative;
					    	left: auto;
					    	padding: 30px;
					    }
					}
					.container-comment
					{
						display: table;
						width: 100%;
						height: 300px;
						left: 0;
						font-size: 16px;
						line-height: 28px;
						color: #9b9b9b;
						font-family: $opensans;
						font-weight: 100;
						.user
						{
							color: #2b2b2b;
							margin-top: 10px;
							span
							{
								font-weight: 700;
							}
						}
						> div
						{
							display: table-cell;
							vertical-align: middle;
						}
						@media screen and (max-width: 768px) {
							text-align:center;
						}
					}
					@media screen and (max-width: 768px) {
						position:relative;
						width:100%;
						float:left;
						height:500px;
					}
				}
				.float-arrow
				{
					height: 45px;
				    width: 45px;
				    position: absolute;
				    right: -90px;
				    bottom: 0;
				    top: 0;
				    margin: auto;
				    &.arrow-left
				    {
				    	transform: rotate(90deg);
				    	top: -55px;
				    	&.slick-disabled
				    	{
				    		filter: grayscale(100) brightness(1.4);
				    	}
				    	@media screen and (max-width: 768px)
						{
							top:auto;
							bottom:-50px;
							left:20px;
							right:auto;
							transform:none;
						}
				    }
				    &.arrow-right
				    {
				    	transform: rotate(90deg);
				    	top:55px;
				    	&.slick-disabled
				    	{
				    		filter: grayscale(100) brightness(1.4);
				    	}
				    	@media screen and (max-width: 768px)
						{
							right:20px;
							top:auto;
							bottom:-50px;
							left:auto;
							transform:none;
						}
				    }
				    @media screen and (max-width: 768px)
					{
						right:0;
						left:0;
					}
				}
				@media screen and (max-width: 768px) {
					height:auto;
					width:100%;
				}
			}
			@media screen and (max-width: 768px) {
				background-color:#fff;
				margin-bottom:30px;
			}

			.slick-dots
			{
				left:0;
				> li
				{
					margin:0px;
					margin-right: 5px;
					filter: grayscale(100) brightness(1.4);
					&.slick-active
					{
						filter: grayscale(0);
					}
					button
					{
						width:10px;
						height: 10px;
						background: $gosend;
						border-radius: 50%;
						&:before
						{
							display:none;
						}
					}
					&:last-child
					{
						margin-right: 0px;
					}
				}
			}
		}
	}
	.articles--feed {
		.bg {
			background: $gosend;
		}
		.read--more>a {
			background: $gosend;
		}
		.content .one {
			border-bottom: 6px solid $gosend;
		}
		.content .two {
			border-bottom: 6px solid $gosend;
		}
		.content .three {
			border-bottom: 6px solid $gosend;
		}
		.content .four {
			border-bottom: 6px solid $gosend;
		}
		h4>a:hover {
			color: $gosend;
		}
		svg {
			ellipse {
				stroke: #fff !important;
			}
			polygon {
				fill: #fff;
			}
		}
	}
	.as-seen-on #content img {
		padding: 1em !important;
		width: auto !important;
		max-height: 130px !important;
		padding: 16px !important;
		max-width: 200px;
		vertical-align: middle;
	}
	.as-seen-on .title p {
		text-transform: uppercase;
	}
	#promo-banner {
		svg {
			ellipse {
				stroke: $gosend !important;
			}
			polygon {
				fill: $gosend !important;
			}
			&.slick-disabled {
				ellipse {
					stroke: #bdbdbd !important;
				}
				polygon {
					fill: #bdbdbd !important;
				}
			}
		}
	}
	.section-how-to-use {
		h5 {
			color: $gosend;
		}
		.line.gradient {
			background: $gosend;
		}
		.arrow-up {
			stroke: $gosend;
		}
		.arrow-up-circle {
			stroke-width: 3;
			stroke: $gosend;
		}
		.arrow-down {
			stroke: $gosend;
		}
		.arrow-down-circle {
			stroke: $gosend;
			stroke-width: 3;
		}
		.slider .slick-dots li.slick-active button:before {
			color: $gosend;
		}
		.slider-image .slick-dots li.slick-active button:before {
			color: $gosend;
		}
		.text-mobile .line.gradient {
			background: $gosend;
		}
		.text h5 {
			color: $gosend;
		}
		.text .line.gradient {
			background: $gosend;
		}
		.slider-image .slick-dots li.slick-active button:before {
			color: $gosend;
		}
	}
	.new-section-how-to-use
	{
			margin-top: 180px;
			margin-bottom:100px;
			h3.title
			{
				color: #8bc440;
				&:after
				{
					background:#8bc440;
				}
			}
			.content
			{
				background-color:#8bc440;
			}
			@media screen and (max-width: 767px) {
				margin-top:50px !important;
			}
	}
	.section-our-values {
		padding: 3em;
		background: #f8f8f8;
		.img--wrapper {
			height: 250px;
			@media screen and (max-width: 768px) {
				height: auto;
			}
		}
		img {
			max-width: 250px;
			padding: 0 40px;
			display: block;
			top: 50%;
			position: relative;
			-webkit-transform: translateY(-50%);
			-moz-transform: translateY(-50%);
			transform: translateY(-50%);
			@media screen and (max-width: 768px) {
				transform: none;
				top: auto;
			}
		}
		h4.title {
			color: $gosend;
		}
		.line.gradient {
			background: $gosend;
		}
		.list h4 {
			line-height: 25px;
			font-size: 15px;
		}
	}
	#content-only {
		.container {
			.info {
				background: url(../img/page/gosend/join-bg.jpg) no-repeat center center/cover;
				p {
					color: #fff;
				}
			}
		}
		@media screen and (max-width: 767px) {
			margin-top:50px;
			margin-bottom:100px;
			.container
			{
				box-shadow: 0 -3em 0 1.5em #48af4a;
				padding: 0px;
			}
		}
	}
}

@media screen and (max-width: 35.5em) {
	#gosend {
		header#main--hero {
			// background-image: url(https://d3naj63yelf4gk.cloudfront.net/dist/img/header/hero/go-send--mobile.jpg);
		}
		.section-how-to-use {
			.arrow--wrapper {
				top: 30%;
			}
			.phone-wrapper {
				top: 40%;
			}
		}
		.section-our-values {
			padding: 0;
		}
	}
}
