@charset "UTF-8";
/*Open Sans & Lato*/
@import url("https://fonts.googleapis.com/css?family=Lato:300,400,700|Open+Sans:300,400,700");
div[class*="pure-pull"],
div[class*="pure-push"] {
  position: relative; }

@media screen and (min-width: 35em) {
  .pure-push-reset-sm {
    left: 0; }
  .pure-pull-reset-sm {
    right: 0; } }

@media screen and (min-width: 48em) {
  .pure-push-reset-md {
    left: 0; }
  .pure-pull-reset-md {
    right: 0; } }

@media screen and (min-width: 64em) {
  .pure-push-reset-lg {
    left: 0; }
  .pure-pull-reset-lg {
    right: 0; } }

@media screen and (min-width: 80em) {
  .pure-push-reset-xl {
    left: 0; }
  .pure-pull-reset-xl {
    right: 0; } }

@media screen and (min-width: 35.5em) {
  .pure-push-sm-1-24 {
    left: 4.1667%;
    *left: 4.1357%; }
  .pure-push-sm-1-12,
  .pure-push-sm-2-24 {
    left: 8.3333%;
    *left: 8.3023%; }
  .pure-push-sm-1-8,
  .pure-push-sm-3-24 {
    left: 12.5%;
    *left: 12.469%; }
  .pure-push-sm-1-6,
  .pure-push-sm-4-24 {
    left: 16.6667%;
    *left: 16.6357%; }
  .pure-push-sm-1-5 {
    left: 20%;
    *left: 19.969%; }
  .pure-push-sm-5-24 {
    left: 20.8333%;
    *left: 20.8023%; }
  .pure-push-sm-1-4,
  .pure-push-sm-6-24 {
    left: 25%;
    *left: 24.969%; }
  .pure-push-sm-7-24 {
    left: 29.1667%;
    *left: 29.1357%; }
  .pure-push-sm-1-3,
  .pure-push-sm-8-24 {
    left: 33.3333%;
    *left: 33.3023%; }
  .pure-push-sm-3-8,
  .pure-push-sm-9-24 {
    left: 37.5%;
    *left: 37.469%; }
  .pure-push-sm-2-5 {
    left: 40%;
    *left: 39.969%; }
  .pure-push-sm-5-12,
  .pure-push-sm-10-24 {
    left: 41.6667%;
    *left: 41.6357%; }
  .pure-push-sm-11-24 {
    left: 45.8333%;
    *left: 45.8023%; }
  .pure-push-sm-1-2,
  .pure-push-sm-12-24 {
    left: 50%;
    *left: 49.969%; }
  .pure-push-sm-13-24 {
    left: 54.1667%;
    *left: 54.1357%; }
  .pure-push-sm-7-12,
  .pure-push-sm-14-24 {
    left: 58.3333%;
    *left: 58.3023%; }
  .pure-push-sm-3-5 {
    left: 60%;
    *left: 59.969%; }
  .pure-push-sm-5-8,
  .pure-push-sm-15-24 {
    left: 62.5%;
    *left: 62.469%; }
  .pure-push-sm-2-3,
  .pure-push-sm-16-24 {
    left: 66.6667%;
    *left: 66.6357%; }
  .pure-push-sm-17-24 {
    left: 70.8333%;
    *left: 70.8023%; }
  .pure-push-sm-3-4,
  .pure-push-sm-18-24 {
    left: 75%;
    *left: 74.969%; }
  .pure-push-sm-19-24 {
    left: 79.1667%;
    *left: 79.1357%; }
  .pure-push-sm-4-5 {
    left: 80%;
    *left: 79.969%; }
  .pure-push-sm-5-6,
  .pure-push-sm-20-24 {
    left: 83.3333%;
    *left: 83.3023%; }
  .pure-push-sm-7-8,
  .pure-push-sm-21-24 {
    left: 87.5%;
    *left: 87.469%; }
  .pure-push-sm-11-12,
  .pure-push-sm-22-24 {
    left: 91.6667%;
    *left: 91.6357%; }
  .pure-push-sm-23-24 {
    left: 95.8333%;
    *left: 95.8023%; }
  .pure-push-sm-1,
  .pure-push-sm-1-1,
  .pure-push-sm-5-5,
  .pure-push-sm-24-24 {
    left: 100%; }
  .pure-pull-sm-1-24 {
    right: 4.1667%;
    *right: 4.1357%; }
  .pure-pull-sm-1-12,
  .pure-pull-sm-2-24 {
    right: 8.3333%;
    *right: 8.3023%; }
  .pure-pull-sm-1-8,
  .pure-pull-sm-3-24 {
    right: 12.5%;
    *right: 12.469%; }
  .pure-pull-sm-1-6,
  .pure-pull-sm-4-24 {
    right: 16.6667%;
    *right: 16.6357%; }
  .pure-pull-sm-1-5 {
    right: 20%;
    *right: 19.969%; }
  .pure-pull-sm-5-24 {
    right: 20.8333%;
    *right: 20.8023%; }
  .pure-pull-sm-1-4,
  .pure-pull-sm-6-24 {
    right: 25%;
    *right: 24.969%; }
  .pure-pull-sm-7-24 {
    right: 29.1667%;
    *right: 29.1357%; }
  .pure-pull-sm-1-3,
  .pure-pull-sm-8-24 {
    right: 33.3333%;
    *right: 33.3023%; }
  .pure-pull-sm-3-8,
  .pure-pull-sm-9-24 {
    right: 37.5%;
    *right: 37.469%; }
  .pure-pull-sm-2-5 {
    right: 40%;
    *right: 39.969%; }
  .pure-pull-sm-5-12,
  .pure-pull-sm-10-24 {
    right: 41.6667%;
    *right: 41.6357%; }
  .pure-pull-sm-11-24 {
    right: 45.8333%;
    *right: 45.8023%; }
  .pure-pull-sm-1-2,
  .pure-pull-sm-12-24 {
    right: 50%;
    *right: 49.969%; }
  .pure-pull-sm-13-24 {
    right: 54.1667%;
    *right: 54.1357%; }
  .pure-pull-sm-7-12,
  .pure-pull-sm-14-24 {
    right: 58.3333%;
    *right: 58.3023%; }
  .pure-pull-sm-3-5 {
    right: 60%;
    *right: 59.969%; }
  .pure-pull-sm-5-8,
  .pure-pull-sm-15-24 {
    right: 62.5%;
    *right: 62.469%; }
  .pure-pull-sm-2-3,
  .pure-pull-sm-16-24 {
    right: 66.6667%;
    *right: 66.6357%; }
  .pure-pull-sm-17-24 {
    right: 70.8333%;
    *right: 70.8023%; }
  .pure-pull-sm-3-4,
  .pure-pull-sm-18-24 {
    right: 75%;
    *right: 74.969%; }
  .pure-pull-sm-19-24 {
    right: 79.1667%;
    *right: 79.1357%; }
  .pure-pull-sm-4-5 {
    right: 80%;
    *right: 79.969%; }
  .pure-pull-sm-5-6,
  .pure-pull-sm-20-24 {
    right: 83.3333%;
    *right: 83.3023%; }
  .pure-pull-sm-7-8,
  .pure-pull-sm-21-24 {
    right: 87.5%;
    *right: 87.469%; }
  .pure-pull-sm-11-12,
  .pure-pull-sm-22-24 {
    right: 91.6667%;
    *right: 91.6357%; }
  .pure-pull-sm-23-24 {
    right: 95.8333%;
    *right: 95.8023%; }
  .pure-pull-sm-1,
  .pure-pull-sm-1-1,
  .pure-pull-sm-5-5,
  .pure-pull-sm-24-24 {
    right: 100%; } }

@media screen and (min-width: 48em) {
  .pure-push-md-1-24 {
    left: 4.1667%;
    *left: 4.1357%; }
  .pure-push-md-1-12,
  .pure-push-md-2-24 {
    left: 8.3333%;
    *left: 8.3023%; }
  .pure-push-md-1-8,
  .pure-push-md-3-24 {
    left: 12.5%;
    *left: 12.469%; }
  .pure-push-md-1-6,
  .pure-push-md-4-24 {
    left: 16.6667%;
    *left: 16.6357%; }
  .pure-push-md-1-5 {
    left: 20%;
    *left: 19.969%; }
  .pure-push-md-5-24 {
    left: 20.8333%;
    *left: 20.8023%; }
  .pure-push-md-1-4,
  .pure-push-md-6-24 {
    left: 25%;
    *left: 24.969%; }
  .pure-push-md-7-24 {
    left: 29.1667%;
    *left: 29.1357%; }
  .pure-push-md-1-3,
  .pure-push-md-2-6,
  .pure-push-md-8-24 {
    left: 33.3333%;
    *left: 33.3023%; }
  .pure-push-md-3-8,
  .pure-push-md-9-24 {
    left: 37.5%;
    *left: 37.469%; }
  .pure-push-md-2-5 {
    left: 40%;
    *left: 39.969%; }
  .pure-push-md-5-12,
  .pure-push-md-10-24 {
    left: 41.6667%;
    *left: 41.6357%; }
  .pure-push-md-11-24 {
    left: 45.8333%;
    *left: 45.8023%; }
  .pure-push-md-1-2,
  .pure-push-md-2-4,
  .pure-push-md-12-24 {
    left: 50%;
    *left: 49.969%; }
  .pure-push-md-13-24 {
    left: 54.1667%;
    *left: 54.1357%; }
  .pure-push-md-7-12,
  .pure-push-md-14-24 {
    left: 58.3333%;
    *left: 58.3023%; }
  .pure-push-md-3-5 {
    left: 60%;
    *left: 59.969%; }
  .pure-push-md-5-8,
  .pure-push-md-15-24 {
    left: 62.5%;
    *left: 62.469%; }
  .pure-push-md-2-3,
  .pure-push-md-16-24 {
    left: 66.6667%;
    *left: 66.6357%; }
  .pure-push-md-17-24 {
    left: 70.8333%;
    *left: 70.8023%; }
  .pure-push-md-3-4,
  .pure-push-md-18-24 {
    left: 75%;
    *left: 74.969%; }
  .pure-push-md-19-24 {
    left: 79.1667%;
    *left: 79.1357%; }
  .pure-push-md-4-5 {
    left: 80%;
    *left: 79.969%; }
  .pure-push-md-5-6,
  .pure-push-md-20-24 {
    left: 83.3333%;
    *left: 83.3023%; }
  .pure-push-md-7-8,
  .pure-push-md-21-24 {
    left: 87.5%;
    *left: 87.469%; }
  .pure-push-md-11-12,
  .pure-push-md-22-24 {
    left: 91.6667%;
    *left: 91.6357%; }
  .pure-push-md-23-24 {
    left: 95.8333%;
    *left: 95.8023%; }
  .pure-push-md-1,
  .pure-push-md-1-1,
  .pure-push-md-5-5,
  .pure-push-md-24-24 {
    left: 100%; }
  .pure-pull-md-1-24 {
    right: 4.1667%;
    *right: 4.1357%; }
  .pure-pull-md-1-12,
  .pure-pull-md-2-24 {
    right: 8.3333%;
    *right: 8.3023%; }
  .pure-pull-md-1-8,
  .pure-pull-md-3-24 {
    right: 12.5%;
    *right: 12.469%; }
  .pure-pull-md-1-6,
  .pure-pull-md-4-24 {
    right: 16.6667%;
    *right: 16.6357%; }
  .pure-pull-md-1-5 {
    right: 20%;
    *right: 19.969%; }
  .pure-pull-md-5-24 {
    right: 20.8333%;
    *right: 20.8023%; }
  .pure-pull-md-1-4,
  .pure-pull-md-6-24 {
    right: 25%;
    *right: 24.969%; }
  .pure-pull-md-7-24 {
    right: 29.1667%;
    *right: 29.1357%; }
  .pure-pull-md-1-3,
  .pure-pull-md-8-24 {
    right: 33.3333%;
    *right: 33.3023%; }
  .pure-pull-md-3-8,
  .pure-pull-md-9-24 {
    right: 37.5%;
    *right: 37.469%; }
  .pure-pull-md-2-5 {
    right: 40%;
    *right: 39.969%; }
  .pure-pull-md-5-12,
  .pure-pull-md-10-24 {
    right: 41.6667%;
    *right: 41.6357%; }
  .pure-pull-md-11-24 {
    right: 45.8333%;
    *right: 45.8023%; }
  .pure-pull-md-1-2,
  .pure-pull-md-2-4,
  .pure-pull-md-12-24 {
    right: 50%;
    *right: 49.969%; }
  .pure-pull-md-13-24 {
    right: 54.1667%;
    *right: 54.1357%; }
  .pure-pull-md-7-12,
  .pure-pull-md-14-24 {
    right: 58.3333%;
    *right: 58.3023%; }
  .pure-pull-md-3-5 {
    right: 60%;
    *right: 59.969%; }
  .pure-pull-md-5-8,
  .pure-pull-md-15-24 {
    right: 62.5%;
    *right: 62.469%; }
  .pure-pull-md-2-3,
  .pure-pull-md-16-24 {
    right: 66.6667%;
    *right: 66.6357%; }
  .pure-pull-md-17-24 {
    right: 70.8333%;
    *right: 70.8023%; }
  .pure-pull-md-3-4,
  .pure-pull-md-18-24 {
    right: 75%;
    *right: 74.969%; }
  .pure-pull-md-19-24 {
    right: 79.1667%;
    *right: 79.1357%; }
  .pure-pull-md-4-5 {
    right: 80%;
    *right: 79.969%; }
  .pure-pull-md-5-6,
  .pure-pull-md-20-24 {
    right: 83.3333%;
    *right: 83.3023%; }
  .pure-pull-md-7-8,
  .pure-pull-md-21-24 {
    right: 87.5%;
    *right: 87.469%; }
  .pure-pull-md-11-12,
  .pure-pull-md-22-24 {
    right: 91.6667%;
    *right: 91.6357%; }
  .pure-pull-md-23-24 {
    right: 95.8333%;
    *right: 95.8023%; }
  .pure-pull-md-1,
  .pure-pull-md-1-1,
  .pure-pull-md-5-5,
  .pure-pull-md-24-24 {
    right: 100%; } }

@media screen and (min-width: 64em) {
  .pure-push-lg-1-24 {
    left: 4.1667%;
    *left: 4.1357%; }
  .pure-push-lg-1-12,
  .pure-push-lg-2-24 {
    left: 8.3333%;
    *left: 8.3023%; }
  .pure-push-lg-1-8,
  .pure-push-lg-3-24 {
    left: 12.5%;
    *left: 12.469%; }
  .pure-push-lg-1-6,
  .pure-push-lg-4-24 {
    left: 16.6667%;
    *left: 16.6357%; }
  .pure-push-lg-1-5 {
    left: 20%;
    *left: 19.969%; }
  .pure-push-lg-5-24 {
    left: 20.8333%;
    *left: 20.8023%; }
  .pure-push-lg-1-4,
  .pure-push-lg-6-24 {
    left: 25%;
    *left: 24.969%; }
  .pure-push-lg-7-24 {
    left: 29.1667%;
    *left: 29.1357%; }
  .pure-push-lg-1-3,
  .pure-push-lg-2-6,
  .pure-push-lg-8-24 {
    left: 33.3333%;
    *left: 33.3023%; }
  .pure-push-lg-3-8,
  .pure-push-lg-9-24 {
    left: 37.5%;
    *left: 37.469%; }
  .pure-push-lg-2-5 {
    left: 40%;
    *left: 39.969%; }
  .pure-push-lg-5-12,
  .pure-push-lg-10-24 {
    left: 41.6667%;
    *left: 41.6357%; }
  .pure-push-lg-11-24 {
    left: 45.8333%;
    *left: 45.8023%; }
  .pure-push-lg-1-2,
  .pure-push-lg-2-4,
  .pure-push-lg-12-24 {
    left: 50%;
    *left: 49.969%; }
  .pure-push-lg-13-24 {
    left: 54.1667%;
    *left: 54.1357%; }
  .pure-push-lg-7-12,
  .pure-push-lg-14-24 {
    left: 58.3333%;
    *left: 58.3023%; }
  .pure-push-lg-3-5 {
    left: 60%;
    *left: 59.969%; }
  .pure-push-lg-5-8,
  .pure-push-lg-15-24 {
    left: 62.5%;
    *left: 62.469%; }
  .pure-push-lg-2-3,
  .pure-push-lg-16-24 {
    left: 66.6667%;
    *left: 66.6357%; }
  .pure-push-lg-17-24 {
    left: 70.8333%;
    *left: 70.8023%; }
  .pure-push-lg-3-4,
  .pure-push-lg-18-24 {
    left: 75%;
    *left: 74.969%; }
  .pure-push-lg-19-24 {
    left: 79.1667%;
    *left: 79.1357%; }
  .pure-push-lg-4-5 {
    left: 80%;
    *left: 79.969%; }
  .pure-push-lg-5-6,
  .pure-push-lg-20-24 {
    left: 83.3333%;
    *left: 83.3023%; }
  .pure-push-lg-7-8,
  .pure-push-lg-21-24 {
    left: 87.5%;
    *left: 87.469%; }
  .pure-push-lg-11-12,
  .pure-push-lg-22-24 {
    left: 91.6667%;
    *left: 91.6357%; }
  .pure-push-lg-23-24 {
    left: 95.8333%;
    *left: 95.8023%; }
  .pure-push-lg-1,
  .pure-push-lg-1-1,
  .pure-push-lg-5-5,
  .pure-push-lg-24-24 {
    left: 100%; }
  .pure-pull-lg-1-24 {
    right: 4.1667%;
    *right: 4.1357%; }
  .pure-pull-lg-1-12,
  .pure-pull-lg-2-24 {
    right: 8.3333%;
    *right: 8.3023%; }
  .pure-pull-lg-1-8,
  .pure-pull-lg-3-24 {
    right: 12.5%;
    *right: 12.469%; }
  .pure-pull-lg-1-6,
  .pure-pull-lg-4-24 {
    right: 16.6667%;
    *right: 16.6357%; }
  .pure-pull-lg-1-5 {
    right: 20%;
    *right: 19.969%; }
  .pure-pull-lg-5-24 {
    right: 20.8333%;
    *right: 20.8023%; }
  .pure-pull-lg-1-4,
  .pure-pull-lg-6-24 {
    right: 25%;
    *right: 24.969%; }
  .pure-pull-lg-7-24 {
    right: 29.1667%;
    *right: 29.1357%; }
  .pure-pull-lg-1-3,
  .pure-pull-lg-8-24 {
    right: 33.3333%;
    *right: 33.3023%; }
  .pure-pull-lg-3-8,
  .pure-pull-lg-9-24 {
    right: 37.5%;
    *right: 37.469%; }
  .pure-pull-lg-2-5 {
    right: 40%;
    *right: 39.969%; }
  .pure-pull-lg-5-12,
  .pure-pull-lg-10-24 {
    right: 41.6667%;
    *right: 41.6357%; }
  .pure-pull-lg-11-24 {
    right: 45.8333%;
    *right: 45.8023%; }
  .pure-pull-lg-1-2,
  .pure-pull-lg-12-24 {
    right: 50%;
    *right: 49.969%; }
  .pure-pull-lg-13-24 {
    right: 54.1667%;
    *right: 54.1357%; }
  .pure-pull-lg-7-12,
  .pure-pull-lg-14-24 {
    right: 58.3333%;
    *right: 58.3023%; }
  .pure-pull-lg-3-5 {
    right: 60%;
    *right: 59.969%; }
  .pure-pull-lg-5-8,
  .pure-pull-lg-15-24 {
    right: 62.5%;
    *right: 62.469%; }
  .pure-pull-lg-2-3,
  .pure-pull-lg-16-24 {
    right: 66.6667%;
    *right: 66.6357%; }
  .pure-pull-lg-17-24 {
    right: 70.8333%;
    *right: 70.8023%; }
  .pure-pull-lg-3-4,
  .pure-pull-lg-18-24 {
    right: 75%;
    *right: 74.969%; }
  .pure-pull-lg-19-24 {
    right: 79.1667%;
    *right: 79.1357%; }
  .pure-pull-lg-4-5 {
    right: 80%;
    *right: 79.969%; }
  .pure-pull-lg-5-6,
  .pure-pull-lg-20-24 {
    right: 83.3333%;
    *right: 83.3023%; }
  .pure-pull-lg-7-8,
  .pure-pull-lg-21-24 {
    right: 87.5%;
    *right: 87.469%; }
  .pure-pull-lg-11-12,
  .pure-pull-lg-22-24 {
    right: 91.6667%;
    *right: 91.6357%; }
  .pure-pull-lg-23-24 {
    right: 95.8333%;
    *right: 95.8023%; }
  .pure-pull-lg-1,
  .pure-pull-lg-1-1,
  .pure-pull-lg-5-5,
  .pure-pull-lg-24-24 {
    right: 100%; } }

@media screen and (min-width: 80em) {
  .pure-push-xl-1-24 {
    left: 4.1667%;
    *left: 4.1357%; }
  .pure-push-xl-1-12,
  .pure-push-xl-2-24 {
    left: 8.3333%;
    *left: 8.3023%; }
  .pure-push-xl-1-8,
  .pure-push-xl-3-24 {
    left: 12.5%;
    *left: 12.469%; }
  .pure-push-xl-1-6,
  .pure-push-xl-4-24 {
    left: 16.6667%;
    *left: 16.6357%; }
  .pure-push-xl-1-5 {
    left: 20%;
    *left: 19.969%; }
  .pure-push-xl-5-24 {
    left: 20.8333%;
    *left: 20.8023%; }
  .pure-push-xl-1-4,
  .pure-push-xl-6-24 {
    left: 25%;
    *left: 24.969%; }
  .pure-push-xl-7-24 {
    left: 29.1667%;
    *left: 29.1357%; }
  .pure-push-xl-1-3,
  .pure-push-xl-8-24 {
    left: 33.3333%;
    *left: 33.3023%; }
  .pure-push-xl-3-8,
  .pure-push-xl-9-24 {
    left: 37.5%;
    *left: 37.469%; }
  .pure-push-xl-2-5 {
    left: 40%;
    *left: 39.969%; }
  .pure-push-xl-5-12,
  .pure-push-xl-10-24 {
    left: 41.6667%;
    *left: 41.6357%; }
  .pure-push-xl-11-24 {
    left: 45.8333%;
    *left: 45.8023%; }
  .pure-push-xl-1-2,
  .pure-push-xl-12-24 {
    left: 50%;
    *left: 49.969%; }
  .pure-push-xl-13-24 {
    left: 54.1667%;
    *left: 54.1357%; }
  .pure-push-xl-7-12,
  .pure-push-xl-14-24 {
    left: 58.3333%;
    *left: 58.3023%; }
  .pure-push-xl-3-5 {
    left: 60%;
    *left: 59.969%; }
  .pure-push-xl-5-8,
  .pure-push-xl-15-24 {
    left: 62.5%;
    *left: 62.469%; }
  .pure-push-xl-2-3,
  .pure-push-xl-16-24 {
    left: 66.6667%;
    *left: 66.6357%; }
  .pure-push-xl-17-24 {
    left: 70.8333%;
    *left: 70.8023%; }
  .pure-push-xl-3-4,
  .pure-push-xl-18-24 {
    left: 75%;
    *left: 74.969%; }
  .pure-push-xl-19-24 {
    left: 79.1667%;
    *left: 79.1357%; }
  .pure-push-xl-4-5 {
    left: 80%;
    *left: 79.969%; }
  .pure-push-xl-5-6,
  .pure-push-xl-20-24 {
    left: 83.3333%;
    *left: 83.3023%; }
  .pure-push-xl-7-8,
  .pure-push-xl-21-24 {
    left: 87.5%;
    *left: 87.469%; }
  .pure-push-xl-11-12,
  .pure-push-xl-22-24 {
    left: 91.6667%;
    *left: 91.6357%; }
  .pure-push-xl-23-24 {
    left: 95.8333%;
    *left: 95.8023%; }
  .pure-push-xl-1,
  .pure-push-xl-1-1,
  .pure-push-xl-5-5,
  .pure-push-xl-24-24 {
    left: 100%; }
  .pure-pull-xl-1-24 {
    right: 4.1667%;
    *right: 4.1357%; }
  .pure-pull-xl-1-12,
  .pure-pull-xl-2-24 {
    right: 8.3333%;
    *right: 8.3023%; }
  .pure-pull-xl-1-8,
  .pure-pull-xl-3-24 {
    right: 12.5%;
    *right: 12.469%; }
  .pure-pull-xl-1-6,
  .pure-pull-xl-4-24 {
    right: 16.6667%;
    *right: 16.6357%; }
  .pure-pull-xl-1-5 {
    right: 20%;
    *right: 19.969%; }
  .pure-pull-xl-5-24 {
    right: 20.8333%;
    *right: 20.8023%; }
  .pure-pull-xl-1-4,
  .pure-pull-xl-6-24 {
    right: 25%;
    *right: 24.969%; }
  .pure-pull-xl-7-24 {
    right: 29.1667%;
    *right: 29.1357%; }
  .pure-pull-xl-1-3,
  .pure-pull-xl-8-24 {
    right: 33.3333%;
    *right: 33.3023%; }
  .pure-pull-xl-3-8,
  .pure-pull-xl-9-24 {
    right: 37.5%;
    *right: 37.469%; }
  .pure-pull-xl-2-5 {
    right: 40%;
    *right: 39.969%; }
  .pure-pull-xl-5-12,
  .pure-pull-xl-10-24 {
    right: 41.6667%;
    *right: 41.6357%; }
  .pure-pull-xl-11-24 {
    right: 45.8333%;
    *right: 45.8023%; }
  .pure-pull-xl-1-2,
  .pure-pull-xl-12-24 {
    right: 50%;
    *right: 49.969%; }
  .pure-pull-xl-13-24 {
    right: 54.1667%;
    *right: 54.1357%; }
  .pure-pull-xl-7-12,
  .pure-pull-xl-14-24 {
    right: 58.3333%;
    *right: 58.3023%; }
  .pure-pull-xl-3-5 {
    right: 60%;
    *right: 59.969%; }
  .pure-pull-xl-5-8,
  .pure-pull-xl-15-24 {
    right: 62.5%;
    *right: 62.469%; }
  .pure-pull-xl-2-3,
  .pure-pull-xl-16-24 {
    right: 66.6667%;
    *right: 66.6357%; }
  .pure-pull-xl-17-24 {
    right: 70.8333%;
    *right: 70.8023%; }
  .pure-pull-xl-3-4,
  .pure-pull-xl-18-24 {
    right: 75%;
    *right: 74.969%; }
  .pure-pull-xl-19-24 {
    right: 79.1667%;
    *right: 79.1357%; }
  .pure-pull-xl-4-5 {
    right: 80%;
    *right: 79.969%; }
  .pure-pull-xl-5-6,
  .pure-pull-xl-20-24 {
    right: 83.3333%;
    *right: 83.3023%; }
  .pure-pull-xl-7-8,
  .pure-pull-xl-21-24 {
    right: 87.5%;
    *right: 87.469%; }
  .pure-pull-xl-11-12,
  .pure-pull-xl-22-24 {
    right: 91.6667%;
    *right: 91.6357%; }
  .pure-pull-xl-23-24 {
    right: 95.8333%;
    *right: 95.8023%; }
  .pure-pull-xl-1,
  .pure-pull-xl-1-1,
  .pure-pull-xl-5-5,
  .pure-pull-xl-24-24 {
    right: 100%; } }

@media screen and (max-width: 35.5em) {
  .pure-hidden-xs {
    display: none !important; } }

@media screen and (min-width: 35.5em) and (max-width: 47.937em) {
  .pure-hidden-sm {
    display: none !important; } }

@media screen and (min-width: 48em) and (max-width: 63.937em) {
  .pure-hidden-md {
    display: none !important; } }

@media screen and (min-width: 64em) and (max-width: 79.937em) {
  .pure-hidden-lg {
    display: none !important; } }

@media screen and (min-width: 80em) {
  .pure-hidden-xl {
    display: none !important; } }

.pure-g-flex {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display: flex;
  -webkit-box-flex: 0;
  -webkit-flex: 0 1 auto;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap; }

.pure-u-flex-1-12,
.pure-u-flex-2-12,
.pure-u-flex-3-12,
.pure-u-flex-1-4,
.pure-u-flex-4-12,
.pure-u-flex-1-3,
.pure-u-flex-5-12,
.pure-u-flex-6-12,
.pure-u-flex-1-2,
.pure-u-flex-2-4,
.pure-u-flex-7-12,
.pure-u-flex-8-12,
.pure-u-flex-2-3,
.pure-u-flex-9-12,
.pure-u-flex-3-4,
.pure-u-flex-10-12,
.pure-u-flex-11-12,
.pure-u-flex-12-12 {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto; }

.pure-u-flex-1 {
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -webkit-flex-basis: 100%;
  -ms-flex-preferred-size: 0;
  flex-basis: 100%;
  max-width: 100%; }

.pure-u-flex-1-12 {
  -webkit-flex-basis: 8.333%;
  -ms-flex-preferred-size: 8.333%;
  flex-basis: 8.333%;
  max-width: 8.333%; }

.pure-u-flex-2-12 {
  -webkit-flex-basis: 16.666%;
  -ms-flex-preferred-size: 16.666%;
  flex-basis: 16.666%;
  max-width: 16.666%; }

.pure-u-flex-3-12,
.pure-u-flex-1-4 {
  -webkit-flex-basis: 25%;
  -ms-flex-preferred-size: 25%;
  flex-basis: 25%;
  max-width: 25%; }

.pure-u-flex-4-12,
.pure-u-flex-1-3 {
  -webkit-flex-basis: 33.333%;
  -ms-flex-preferred-size: 33.333%;
  flex-basis: 33.333%;
  max-width: 33.333%; }

.pure-u-flex-5-12 {
  -webkit-flex-basis: 41.667%;
  -ms-flex-preferred-size: 41.667%;
  flex-basis: 41.667%;
  max-width: 41.667%; }

.pure-u-flex-6-12,
.pure-u-flex-1-2,
.pure-u-flex-2-4 {
  -webkit-flex-basis: 50%;
  -ms-flex-preferred-size: 50%;
  flex-basis: 50%;
  max-width: 50%; }

.pure-u-flex-7-12 {
  -webkit-flex-basis: 58.333%;
  -ms-flex-preferred-size: 58.333%;
  flex-basis: 58.333%;
  max-width: 58.333%; }

.pure-u-flex-8-12,
.pure-u-flex-1-3 {
  -webkit-flex-basis: 66.667%;
  -ms-flex-preferred-size: 66.667%;
  flex-basis: 66.667%;
  max-width: 66.667%; }

.pure-u-flex-9-12,
.pure-u-flex-3-4 {
  -webkit-flex-basis: 75%;
  -ms-flex-preferred-size: 75%;
  flex-basis: 75%;
  max-width: 75%; }

.pure-u-flex-10-12 {
  -webkit-flex-basis: 83.333%;
  -ms-flex-preferred-size: 83.333%;
  flex-basis: 83.333%;
  max-width: 83.333%; }

.pure-u-flex-11-12 {
  -webkit-flex-basis: 91.667%;
  -ms-flex-preferred-size: 91.667%;
  flex-basis: 91.667%;
  max-width: 91.667%; }

.pure-u-flex-12-12 {
  -webkit-flex-basis: 100%;
  -ms-flex-preferred-size: 100%;
  flex-basis: 100%;
  max-width: 100%; }

@media only screen and (min-width: 35.5em) {
  .pure-u-flex-sm-1-12,
  .pure-u-flex-sm-2-12,
  .pure-u-flex-sm-3-12,
  .pure-u-flex-sm-1-4,
  .pure-u-flex-sm-4-12,
  .pure-u-flex-sm-1-3,
  .pure-u-flex-sm-5-12,
  .pure-u-flex-sm-6-12,
  .pure-u-flex-sm-1-2,
  .pure-u-flex-sm-2-4,
  .pure-u-flex-sm-7-12,
  .pure-u-flex-sm-8-12,
  .pure-u-flex-sm-2-3,
  .pure-u-flex-sm-9-12,
  .pure-u-flex-sm-3-4,
  .pure-u-flex-sm-10-12,
  .pure-u-flex-sm-11-12,
  .pure-u-flex-sm-12-12 {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto; }
  .pure-u-flex-sm-1-12 {
    -webkit-flex-basis: 8.333%;
    -ms-flex-preferred-size: 8.333%;
    flex-basis: 8.333%;
    max-width: 8.333%; }
  .pure-u-flex-sm-2-12 {
    -webkit-flex-basis: 16.666%;
    -ms-flex-preferred-size: 16.666%;
    flex-basis: 16.666%;
    max-width: 16.666%; }
  .pure-u-flex-sm-3-12,
  .pure-u-flex-sm-1-4 {
    -webkit-flex-basis: 25%;
    -ms-flex-preferred-size: 25%;
    flex-basis: 25%;
    max-width: 25%; }
  .pure-u-flex-sm-4-12,
  .pure-u-flex-sm-1-3 {
    -webkit-flex-basis: 33.333%;
    -ms-flex-preferred-size: 33.333%;
    flex-basis: 33.333%;
    max-width: 33.333%; }
  .pure-u-flex-sm-5-12 {
    -webkit-flex-basis: 41.667%;
    -ms-flex-preferred-size: 41.667%;
    flex-basis: 41.667%;
    max-width: 41.667%; }
  .pure-u-flex-sm-6-12,
  .pure-u-flex-sm-1-2,
  .pure-u-flex-sm-2-4 {
    -webkit-flex-basis: 50%;
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
    max-width: 50%; }
  .pure-u-flex-sm-7-12 {
    -webkit-flex-basis: 58.333%;
    -ms-flex-preferred-size: 58.333%;
    flex-basis: 58.333%;
    max-width: 58.333%; }
  .pure-u-flex-sm-8-12,
  .pure-u-flex-sm-2-3 {
    -webkit-flex-basis: 66.667%;
    -ms-flex-preferred-size: 66.667%;
    flex-basis: 66.667%;
    max-width: 66.667%; }
  .pure-u-flex-sm-9-12,
  .pure-u-flex-sm-3-4 {
    -webkit-flex-basis: 75%;
    -ms-flex-preferred-size: 75%;
    flex-basis: 75%;
    max-width: 75%; }
  .pure-u-flex-sm-10-12 {
    -webkit-flex-basis: 83.333%;
    -ms-flex-preferred-size: 83.333%;
    flex-basis: 83.333%;
    max-width: 83.333%; }
  .pure-u-flex-sm-11-12 {
    -webkit-flex-basis: 91.667%;
    -ms-flex-preferred-size: 91.667%;
    flex-basis: 91.667%;
    max-width: 91.667%; }
  .pure-u-flex-sm-12-12 {
    -webkit-flex-basis: 100%;
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    max-width: 100%; } }

@media only screen and (min-width: 48em) {
  .pure-u-flex-md-1-12,
  .pure-u-flex-md-2-12,
  .pure-u-flex-md-3-12,
  .pure-u-flex-md-1-4,
  .pure-u-flex-md-4-12,
  .pure-u-flex-md-1-3,
  .pure-u-flex-md-5-12,
  .pure-u-flex-md-6-12,
  .pure-u-flex-md-1-2,
  .pure-u-flex-md-2-4,
  .pure-u-flex-md-7-12,
  .pure-u-flex-md-8-12,
  .pure-u-flex-md-2-3,
  .pure-u-flex-md-9-12,
  .pure-u-flex-md-3-4,
  .pure-u-flex-md-10-12,
  .pure-u-flex-md-11-12,
  .pure-u-flex-md-12-12 {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto; }
  .pure-u-flex-md-1-12 {
    -webkit-flex-basis: 8.333%;
    -ms-flex-preferred-size: 8.333%;
    flex-basis: 8.333%;
    max-width: 8.333%; }
  .pure-u-flex-md-2-12 {
    -webkit-flex-basis: 16.666%;
    -ms-flex-preferred-size: 16.666%;
    flex-basis: 16.666%;
    max-width: 16.666%; }
  .pure-u-flex-md-3-12,
  .pure-u-flex-md-1-4 {
    -webkit-flex-basis: 25%;
    -ms-flex-preferred-size: 25%;
    flex-basis: 25%;
    max-width: 25%; }
  .pure-u-flex-md-4-12,
  .pure-u-flex-md-1-3 {
    -webkit-flex-basis: 33.333%;
    -ms-flex-preferred-size: 33.333%;
    flex-basis: 33.333%;
    max-width: 33.333%; }
  .pure-u-flex-md-5-12 {
    -webkit-flex-basis: 41.667%;
    -ms-flex-preferred-size: 41.667%;
    flex-basis: 41.667%;
    max-width: 41.667%; }
  .pure-u-flex-md-6-12,
  .pure-u-flex-md-1-2,
  .pure-u-flex-md-2-4 {
    -webkit-flex-basis: 50%;
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
    max-width: 50%; }
  .pure-u-flex-md-7-12 {
    -webkit-flex-basis: 58.333%;
    -ms-flex-preferred-size: 58.333%;
    flex-basis: 58.333%;
    max-width: 58.333%; }
  .pure-u-flex-md-8-12,
  .pure-u-flex-md-2-3 {
    -webkit-flex-basis: 66.667%;
    -ms-flex-preferred-size: 66.667%;
    flex-basis: 66.667%;
    max-width: 66.667%; }
  .pure-u-flex-md-9-12,
  .pure-u-flex-md-3-4 {
    -webkit-flex-basis: 75%;
    -ms-flex-preferred-size: 75%;
    flex-basis: 75%;
    max-width: 75%; }
  .pure-u-flex-md-10-12 {
    -webkit-flex-basis: 83.333%;
    -ms-flex-preferred-size: 83.333%;
    flex-basis: 83.333%;
    max-width: 83.333%; }
  .pure-u-flex-md-11-12 {
    -webkit-flex-basis: 91.667%;
    -ms-flex-preferred-size: 91.667%;
    flex-basis: 91.667%;
    max-width: 91.667%; }
  .pure-u-flex-md-12-12 {
    -webkit-flex-basis: 100%;
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    max-width: 100%; } }

@media only screen and (min-width: 64em) {
  .pure-u-flex-lg-1-12,
  .pure-u-flex-lg-2-12,
  .pure-u-flex-lg-3-12,
  .pure-u-flex-lg-1-4,
  .pure-u-flex-lg-4-12,
  .pure-u-flex-lg-1-3,
  .pure-u-flex-lg-5-12,
  .pure-u-flex-lg-6-12,
  .pure-u-flex-lg-1-2,
  .pure-u-flex-lg-2-4,
  .pure-u-flex-lg-7-12,
  .pure-u-flex-lg-8-12,
  .pure-u-flex-lg-2-3,
  .pure-u-flex-lg-9-12,
  .pure-u-flex-lg-3-4,
  .pure-u-flex-lg-10-12,
  .pure-u-flex-lg-11-12,
  .pure-u-flex-lg-12-12 {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto; }
  .pure-u-flex-lg-1-12 {
    -webkit-flex-basis: 8.333%;
    -ms-flex-preferred-size: 8.333%;
    flex-basis: 8.333%;
    max-width: 8.333%; }
  .pure-u-flex-lg-2-12 {
    -webkit-flex-basis: 16.666%;
    -ms-flex-preferred-size: 16.666%;
    flex-basis: 16.666%;
    max-width: 16.666%; }
  .pure-u-flex-lg-3-12,
  .pure-u-flex-lg-1-4 {
    -webkit-flex-basis: 25%;
    -ms-flex-preferred-size: 25%;
    flex-basis: 25%;
    max-width: 25%; }
  .pure-u-flex-lg-4-12,
  .pure-u-flex-lg-1-3 {
    -webkit-flex-basis: 33.333%;
    -ms-flex-preferred-size: 33.333%;
    flex-basis: 33.333%;
    max-width: 33.333%; }
  .pure-u-flex-lg-5-12 {
    -webkit-flex-basis: 41.667%;
    -ms-flex-preferred-size: 41.667%;
    flex-basis: 41.667%;
    max-width: 41.667%; }
  .pure-u-flex-lg-6-12,
  .pure-u-flex-lg-1-2,
  .pure-u-flex-lg-2-4 {
    -webkit-flex-basis: 50%;
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
    max-width: 50%; }
  .pure-u-flex-lg-7-12 {
    -webkit-flex-basis: 58.333%;
    -ms-flex-preferred-size: 58.333%;
    flex-basis: 58.333%;
    max-width: 58.333%; }
  .pure-u-flex-lg-8-12,
  .pure-u-flex-lg-2-3 {
    -webkit-flex-basis: 66.667%;
    -ms-flex-preferred-size: 66.667%;
    flex-basis: 66.667%;
    max-width: 66.667%; }
  .pure-u-flex-lg-9-12,
  .pure-u-flex-lg-3-4 {
    -webkit-flex-basis: 75%;
    -ms-flex-preferred-size: 75%;
    flex-basis: 75%;
    max-width: 75%; }
  .pure-u-flex-lg-10-12 {
    -webkit-flex-basis: 83.333%;
    -ms-flex-preferred-size: 83.333%;
    flex-basis: 83.333%;
    max-width: 83.333%; }
  .pure-u-flex-lg-11-12 {
    -webkit-flex-basis: 91.667%;
    -ms-flex-preferred-size: 91.667%;
    flex-basis: 91.667%;
    max-width: 91.667%; }
  .pure-u-flex-lg-12-12 {
    -webkit-flex-basis: 100%;
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    max-width: 100%; } }

@media only screen and (min-width: 80em) {
  .pure-u-flex-xl-1-12,
  .pure-u-flex-xl-2-12,
  .pure-u-flex-xl-3-12,
  .pure-u-flex-xl-1-4,
  .pure-u-flex-xl-4-12,
  .pure-u-flex-xl-1-3,
  .pure-u-flex-xl-5-12,
  .pure-u-flex-xl-6-12,
  .pure-u-flex-xl-1-2,
  .pure-u-flex-xl-2-4,
  .pure-u-flex-xl-7-12,
  .pure-u-flex-xl-8-12,
  .pure-u-flex-xl-2-3,
  .pure-u-flex-xl-9-12,
  .pure-u-flex-xl-3-4,
  .pure-u-flex-xl-10-12,
  .pure-u-flex-xl-11-12,
  .pure-u-flex-xl-12-12 {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto; }
  .pure-u-flex-xl-1-12 {
    -webkit-flex-basis: 8.333%;
    -ms-flex-preferred-size: 8.333%;
    flex-basis: 8.333%;
    max-width: 8.333%; }
  .pure-u-flex-xl-2-12 {
    -webkit-flex-basis: 16.666%;
    -ms-flex-preferred-size: 16.666%;
    flex-basis: 16.666%;
    max-width: 16.666%; }
  .pure-u-flex-xl-3-12,
  .pure-u-flex-xl-1-4 {
    -webkit-flex-basis: 25%;
    -ms-flex-preferred-size: 25%;
    flex-basis: 25%;
    max-width: 25%; }
  .pure-u-flex-xl-4-12,
  .pure-u-flex-xl-1-3 {
    -webkit-flex-basis: 33.333%;
    -ms-flex-preferred-size: 33.333%;
    flex-basis: 33.333%;
    max-width: 33.333%; }
  .pure-u-flex-xl-5-12 {
    -webkit-flex-basis: 41.667%;
    -ms-flex-preferred-size: 41.667%;
    flex-basis: 41.667%;
    max-width: 41.667%; }
  .pure-u-flex-xl-6-12,
  .pure-u-flex-xl-1-2,
  .pure-u-flex-xl-2-4 {
    -webkit-flex-basis: 50%;
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
    max-width: 50%; }
  .pure-u-flex-xl-7-12 {
    -webkit-flex-basis: 58.333%;
    -ms-flex-preferred-size: 58.333%;
    flex-basis: 58.333%;
    max-width: 58.333%; }
  .pure-u-flex-xl-8-12,
  .pure-u-flex-xl-2-3 {
    -webkit-flex-basis: 66.667%;
    -ms-flex-preferred-size: 66.667%;
    flex-basis: 66.667%;
    max-width: 66.667%; }
  .pure-u-flex-xl-9-12 {
    -webkit-flex-basis: 75%;
    -ms-flex-preferred-size: 75%;
    flex-basis: 75%;
    max-width: 75%; }
  .pure-u-flex-xl-10-12,
  .pure-u-flex-xl-3-4 {
    -webkit-flex-basis: 83.333%;
    -ms-flex-preferred-size: 83.333%;
    flex-basis: 83.333%;
    max-width: 83.333%; }
  .pure-u-flex-xl-11-12 {
    -webkit-flex-basis: 91.667%;
    -ms-flex-preferred-size: 91.667%;
    flex-basis: 91.667%;
    max-width: 91.667%; }
  .pure-u-flex-xl-12-12 {
    -webkit-flex-basis: 100%;
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    max-width: 100%; } }

*, html, body {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

html, body {
  overflow-x: hidden; }

body {
  font-family: "Lato", Helvetica, sans-serif;
  color: #4a4a4a;
  overflow: hidden; }

p {
  font-size: 0.750em;
  font-weight: 300;
  line-height: 1.62em;
  letter-spacing: 1.5px;
  font-family: "Open Sans", Helvetica, sans-serif; }

h1, h2, h3, h4, h5, h6 {
  font-family: "Lato", Helvetica, sans-serif;
  line-height: 1.2em;
  font-size: "inherit";
  letter-spacing: 0.094em; }

h1 {
  font-family: "Lato", Helvetica, sans-serif;
  line-height: 1.2em;
  font-size: 2em;
  letter-spacing: 5.3px; }

h2 {
  font-family: "Lato", Helvetica, sans-serif;
  line-height: 1.2em;
  font-size: 1.313em;
  letter-spacing: 2px; }

h3 {
  font-family: "Lato", Helvetica, sans-serif;
  line-height: 1.2em;
  font-size: 0.938em;
  letter-spacing: 1.7px;
  font-family: "Open Sans", Helvetica, sans-serif; }

h4 {
  font-size: 0.688em;
  line-height: 0.78;
  letter-spacing: 1.9px; }

a {
  font-family: "Open Sans", Helvetica, sans-serif;
  letter-spacing: 3.3px;
  line-height: 0.8;
  text-decoration: none;
  font-size: 0.688em; }

button {
  padding: 2.313em 4.438em;
  border-radius: 0;
  text-transform: uppercase;
  font-family: "Open Sans", Helvetica, sans-serif;
  font-weight: 700;
  letter-spacing: 0.206em;
  border: none;
  background-color: #48af4a; }

button:hover {
  color: #358036;
  background-color: #5abb5c; }

button:active {
  color: #5abb5c;
  background-color: #358036; }

.text-inline {
  display: inline-block; }

/* Container */
.container {
  margin-right: auto;
  margin-left: auto; }

@media (max-width: 768px) {
  .container {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px; } }

@media (min-width: 768px) {
  .container {
    width: 750px; } }

@media (min-width: 992px) {
  .container {
    width: 970px; } }

@media (min-width: 1200px) {
  .container {
    width: 1170px; } }

@media (min-width: 2000px) {
  .container {
    width: 1700px; } }

@media (min-width: 980px) {
  .pure-visible-phone {
    display: none; }
  .pure-visible-tablet {
    display: none; }
  .pure-hidden-desktop {
    display: none; } }

@media (max-width: 767px) {
  .pure-hidden-phone {
    display: none; }
  .pure-visible-desktop {
    display: none; } }

@media (min-width: 768px) and (max-width: 979px) {
  .pure-hidden-tablet {
    display: none; }
  .pure-visible-desktop {
    display: none; } }

/* End of Container */
/* Small Screen */
/* Medium Screen */
@media screen and (min-width: 48em) {
  h1 {
    font-family: "Lato", Helvetica, sans-serif;
    line-height: 1.2em;
    font-size: 4em;
    letter-spacing: 5.3px; }
  h2 {
    font-family: "Lato", Helvetica, sans-serif;
    line-height: 1.2em;
    font-size: 2.25em;
    letter-spacing: 2px; }
  h3 {
    font-family: "Lato", Helvetica, sans-serif;
    line-height: 1.2em;
    font-size: 1.5em;
    letter-spacing: 1.7px; }
  h4 {
    font-size: 1.125em;
    line-height: 1.42em; }
  p {
    font-size: 0.875em; }
  a {
    font-size: 0.875em; } }

/* Large Screen */
/* Extra Large Screen */
/*
this is default scss
1. Styling for font-size,padding,margin section.
2. Styling for default tag
3. For grids system use pure grids system
*/
/* old style */
/* DISABLED 
	header#main--hero
	{
		margin-bottom: 0px;
		@media only screen and (max-width: 767px) {
			margin-bottom: 30px;
		}
	}
	button.download
	{
		color: #fff;
		border-radius: 5px;
		display: block !important;
		@media only screen and (max-width: 768px) {
		 	width:215px !important;
		}
	}
	footer
	{
		margin-top: 100px;
	}
*/
/* ending */
/* consitency beetween section */
section[new-version] {
  text-align: center;
  padding: 60px 0;
  /* default tag */
  /* forced to full width in mobile use it when container is not having full-mobile class */
  /* container switch places on mobile */
  /* container vertical align for div */
  /* add loading before slick init */
  /* ----- SLICK JS CUSTOM CSS  ---- */
  /* arrow slide default class */
  /* custom dots slick */ }
  @media only screen and (max-width: 767px) {
    section[new-version] {
      padding: 30px 0; } }
  section[new-version].stick-to-top {
    padding-top: 0px; }
  @media only screen and (max-width: 767px) {
    section[new-version].stick-to-top-mobile {
      padding-top: 0px; } }
  section[new-version] .container-all.center {
    position: relative;
    display: inline-block;
    width: 100%;
    max-width: 1020px;
    padding: 0 30px; }
    @media only screen and (min-width: 768px) {
      section[new-version] .container-all.center.has-items {
        padding: 0px; } }
    @media only screen and (max-width: 768px) {
      section[new-version] .container-all.center.full-mobile {
        width: 100%;
        padding: 0px; } }
    @media only screen and (max-width: 767px) {
      section[new-version] .container-all.center.increase-width-mobile {
        padding: 0 15px; } }
  section[new-version] p {
    font-size: 14px; }
  section[new-version] h3 {
    line-height: normal;
    font-size: 28px; }
    @media only screen and (max-width: 767px) {
      section[new-version] h3 {
        font-size: 21px; } }
  section[new-version] h4 {
    line-height: normal;
    font-size: 25px; }
    @media only screen and (max-width: 767px) {
      section[new-version] h4 {
        font-size: 18px; } }
  section[new-version] h5 {
    line-height: normal;
    font-size: 18px; }
    @media only screen and (max-width: 767px) {
      section[new-version] h5 {
        font-size: 14px; } }
  section[new-version] h6 {
    line-height: normal;
    font-size: 14px; }
  @media only screen and (max-width: 767px) {
    section[new-version] .full-child-mobile {
      position: relative;
      left: -30px;
      width: calc(100% + 60px) !important; } }
  section[new-version] .title-underscore {
    line-height: 20px;
    padding: 0 30px;
    color: #48af4a;
    margin: 0px;
    text-transform: uppercase; }
    section[new-version] .title-underscore span {
      display: inline-block; }
    section[new-version] .title-underscore:after {
      display: block;
      content: "";
      position: relative;
      height: 4px;
      width: 50px;
      background-color: #48af4a;
      margin: 30px auto; }
      @media only screen and (max-width: 768px) {
        section[new-version] .title-underscore:after {
          width: 25px;
          height: 2px; } }
  @media only screen and (max-width: 767px) {
    section[new-version] .switch-mobile-container {
      display: table !important; }
      section[new-version] .switch-mobile-container .top {
        display: table-header-group; }
      section[new-version] .switch-mobile-container .bottom {
        display: table-footer-group; } }
  section[new-version] .container-vertical {
    display: inline-block;
    width: 100%;
    font-size: 0px; }
    section[new-version] .container-vertical > div {
      display: inline-block;
      vertical-align: middle;
      font-size: 14px; }
      section[new-version] .container-vertical > div:after {
        display: inline-block;
        vertical-align: middle;
        content: "";
        position: absolute;
        top: 0; }
  @media only screen and (max-width: 767px) {
    section[new-version] .slider-set-mobile:not(.slick-initialized) {
      height: 120px; }
      section[new-version] .slider-set-mobile:not(.slick-initialized) > div {
        opacity: 0;
        height: 0px; }
      section[new-version] .slider-set-mobile:not(.slick-initialized):after {
        animation: loadingSlider 0.4s infinite alternate;
        content: "";
        height: 25px;
        width: 25px;
        border: 2px solid #48af4a;
        background: #fff;
        border-radius: 50%;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto; } }
  @media only screen and (min-width: 769px) {
    section[new-version] .slider-set-desktop:not(.slick-initialized) {
      height: 120px; }
      section[new-version] .slider-set-desktop:not(.slick-initialized) > div {
        opacity: 0;
        height: 0px; }
      section[new-version] .slider-set-desktop:not(.slick-initialized):after {
        animation: loadingSlider 0.4s infinite alternate;
        content: "";
        height: 25px;
        width: 25px;
        border: 2px solid #48af4a;
        background: #fff;
        border-radius: 50%;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto; } }
  section[new-version] .slider-set-both:not(.slick-initialized) {
    height: 120px; }
    @media only screen and (min-width: 768px) {
      section[new-version] .slider-set-both:not(.slick-initialized) {
        height: 400px; } }
    section[new-version] .slider-set-both:not(.slick-initialized) > div {
      opacity: 0;
      height: 0px; }
    section[new-version] .slider-set-both:not(.slick-initialized):after {
      animation: loadingSlider 0.4s infinite alternate;
      content: "";
      height: 25px;
      width: 25px;
      border: 2px solid #48af4a;
      background: #fff;
      border-radius: 50%;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto; }
  section[new-version] .new-float-arrow {
    cursor: pointer;
    z-index: 2;
    width: 40px;
    height: 40px;
    position: absolute;
    bottom: -60px;
    margin-bottom: 0px; }
    section[new-version] .new-float-arrow.arrow-right {
      right: 0; }
    section[new-version] .new-float-arrow svg {
      transition: all 0.5s; }
      section[new-version] .new-float-arrow svg ellipse {
        stroke: #fff; }
      section[new-version] .new-float-arrow svg polygon {
        fill: #fff; }
    section[new-version] .new-float-arrow.slick-disabled {
      cursor: disabled; }
      section[new-version] .new-float-arrow.slick-disabled svg {
        transition: all 0.5s; }
        section[new-version] .new-float-arrow.slick-disabled svg ellipse {
          stroke: #ececec; }
        section[new-version] .new-float-arrow.slick-disabled svg polygon {
          fill: #ececec; }
  section[new-version] .normal-dots {
    position: absolute;
    bottom: -48px;
    left: 0;
    right: 0;
    margin: auto;
    padding-left: 0px;
    list-style: none; }
    section[new-version] .normal-dots > li {
      transition: all 0.5s;
      border: 1px solid #fff;
      display: inline-block;
      height: 10px;
      margin-right: 5px;
      width: 10px;
      border-radius: 50%;
      background: transparent; }
      section[new-version] .normal-dots > li.slick-active {
        background: #fff; }
      section[new-version] .normal-dots > li button {
        display: none; }

@keyframes loadingSlider {
  from {
    transform: scale(1); }
  to {
    transform: scale(0.5); } }

/* rules
1. One card one section tag
2. Media query must nesting in class [no media query on bottom -> css style]
3. No duplicate this class on any other scss file
*/
@keyframes loadingSlider {
  from {
    transform: scale(1); }
  to {
    transform: scale(0.5); } }

/* ---- setion (parent cards) ----- */
section.new-about {
  font-family: "Open Sans", Helvetica, sans-serif; }
  section.new-about .container-vertical > div {
    text-align: left; }
    section.new-about .container-vertical > div:first-child {
      padding-right: 80px; }
      @media only screen and (max-width: 767px) {
        section.new-about .container-vertical > div:first-child {
          padding-right: 0px; } }
    section.new-about .container-vertical > div.top {
      position: relative; }
      section.new-about .container-vertical > div.top .container-image {
        position: relative; }
        section.new-about .container-vertical > div.top .container-image .container-video {
          position: absolute;
          height: 100%;
          width: 100%;
          background: #000;
          opacity: 0;
          pointer-events: none;
          left: 0;
          transition: all 0.5s;
          bottom: 0; }
          section.new-about .container-vertical > div.top .container-image .container-video.active {
            transition: all 0.5s;
            opacity: 1;
            pointer-events: visible; }
          section.new-about .container-vertical > div.top .container-image .container-video iframe {
            width: 100%;
            height: 100%; }
          @media only screen and (max-width: 767px) {
            section.new-about .container-vertical > div.top .container-image .container-video {
              height: calc(100% - 30px); } }
        section.new-about .container-vertical > div.top .container-image .play {
          cursor: pointer;
          width: 60px;
          height: 60px;
          background: #ffcd2e;
          position: absolute;
          top: 0;
          left: -30px;
          border-radius: 50%;
          bottom: 0;
          margin: auto;
          border-radius: 50%;
          transition: all 0.5s; }
          section.new-about .container-vertical > div.top .container-image .play.disactive {
            pointer-events: none;
            transition: all 0.5s;
            transform: scale(0) !important; }
            section.new-about .container-vertical > div.top .container-image .play.disactive + .close {
              transform: scale(1);
              transition: all 0.5s; }
          section.new-about .container-vertical > div.top .container-image .play:hover {
            transition: all 0.5s;
            transform: scale(1.2); }
          section.new-about .container-vertical > div.top .container-image .play:after {
            transition: all 0.5s;
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            left: 0;
            margin: auto;
            width: 0;
            height: 0;
            border-top: 10px solid transparent;
            border-left: 20px solid #fff;
            border-bottom: 10px solid transparent;
            content: ""; }
          @media only screen and (max-width: 767px) {
            section.new-about .container-vertical > div.top .container-image .play {
              left: 0;
              right: 0;
              top: 20px;
              width: 50px;
              height: 50px; } }
        section.new-about .container-vertical > div.top .container-image .close {
          cursor: pointer;
          width: 60px;
          height: 60px;
          background: red;
          position: absolute;
          top: 0;
          right: 0;
          margin: -30px;
          border-radius: 50%;
          bottom: 0;
          border-radius: 50%;
          transition: all 0.5s;
          transform: scale(0);
          z-index: 2;
          line-height: 60px;
          text-align: center;
          font-size: 30px;
          color: #fff; }
          @media only screen and (max-width: 767px) {
            section.new-about .container-vertical > div.top .container-image .close {
              width: 40px;
              height: 40px;
              margin: 0;
              right: -20px;
              top: 10px;
              font-size: 20px;
              line-height: 40px; } }
    section.new-about .container-vertical > div .sub-title {
      margin: 0px;
      color: #48af4a;
      letter-spacing: 1px;
      font-size: 18px;
      font-weight: 700; }
      @media only screen and (max-width: 767px) {
        section.new-about .container-vertical > div .sub-title {
          font-size: 12px;
          position: absolute;
          top: 0;
          padding-left: 10px; }
          section.new-about .container-vertical > div .sub-title:after {
            content: "";
            position: absolute;
            left: -30px;
            margin: auto;
            top: 0;
            bottom: 0;
            height: 3px;
            width: 30px;
            background: #48af4a; } }
    section.new-about .container-vertical > div h3.title {
      text-transform: uppercase;
      color: #343b41;
      font-weight: 300;
      margin: 20px 0; }
      @media only screen and (max-width: 767px) {
        section.new-about .container-vertical > div h3.title {
          margin-top: 15px;
          margin-bottom: 10px; } }
    section.new-about .container-vertical > div p {
      color: #485460;
      margin: 0px;
      font-size: 14px; }
      @media only screen and (max-width: 767px) {
        section.new-about .container-vertical > div p {
          font-size: 12px; } }
  section.new-about .container-vertical img.about {
    width: 100%; }
    @media only screen and (max-width: 767px) {
      section.new-about .container-vertical img.about {
        padding-top: 30px; } }

section.new-our-values {
  font-family: "Open Sans", Helvetica, sans-serif;
  background-color: #fff; }
  section.new-our-values .container-slide-item {
    font-size: 0px;
    position: relative; }
    @media only screen and (max-width: 767px) {
      section.new-our-values .container-slide-item {
        padding-bottom: 10px; } }
    section.new-our-values .container-slide-item > div {
      outline: none;
      padding: 0 30px;
      font-size: 14px;
      /* on desktop if more than 3 items */ }
      @media only screen and (max-width: 767px) {
        section.new-our-values .container-slide-item > div {
          padding: 0px;
          margin-bottom: 60px; } }
      @media only screen and (min-width: 768px) {
        section.new-our-values .container-slide-item > div:nth-child(3) ~ div {
          margin-top: 60px; } }
      section.new-our-values .container-slide-item > div img {
        max-height: 148px;
        margin-bottom: 30px; }
        @media only screen and (max-width: 768px) {
          section.new-our-values .container-slide-item > div img {
            max-height: 120px;
            display: inline-block; } }
        @media only screen and (max-width: 767px) {
          section.new-our-values .container-slide-item > div img {
            margin-bottom: 20px; } }
      section.new-our-values .container-slide-item > div h5 {
        margin: 0px;
        color: #48af4a; }
        section.new-our-values .container-slide-item > div h5:after {
          display: block;
          content: "";
          position: relative;
          height: 2px;
          width: 25px;
          background-color: #48af4a;
          margin: 15px auto; }
      section.new-our-values .container-slide-item > div a {
        color: #343b41;
        font-size: 14px;
        letter-spacing: 1.6px;
        display: inline-block;
        position: relative;
        font-weight: 700; }
        section.new-our-values .container-slide-item > div a:after {
          top: 1px;
          position: relative;
          margin-left: 5px;
          content: "→";
          font-size: 20px;
          transition: all 0.5s; }
        @media only screen and (min-width: 769px) {
          section.new-our-values .container-slide-item > div a {
            font-size: 16px;
            font-weight: 300; }
            section.new-our-values .container-slide-item > div a:hover:after {
              transition: all 0.5s;
              transform: translateX(100px); } }
      section.new-our-values .container-slide-item > div p {
        color: #9b9b9b;
        font-size: 14px;
        margin: 0px; }
        @media only screen and (max-width: 767px) {
          section.new-our-values .container-slide-item > div p {
            background-color: transparent;
            margin: 0 auto;
            max-width: 250px; } }
    @media only screen and (max-width: 767px) {
      section.new-our-values .container-slide-item .new-float-arrow svg {
        transition: all 0.5s; }
        section.new-our-values .container-slide-item .new-float-arrow svg ellipse {
          stroke: #48af4a !important; }
        section.new-our-values .container-slide-item .new-float-arrow svg polygon {
          fill: #48af4a !important; }
      section.new-our-values .container-slide-item .new-float-arrow.slick-disabled svg ellipse {
        stroke: #ececec !important; }
      section.new-our-values .container-slide-item .new-float-arrow.slick-disabled svg polygon {
        fill: #ececec !important; } }
    @media only screen and (max-width: 767px) {
      section.new-our-values .container-slide-item .normal-dots {
        bottom: 15px; }
        section.new-our-values .container-slide-item .normal-dots > li {
          border: 1px solid #48af4a; }
          section.new-our-values .container-slide-item .normal-dots > li.slick-active {
            background-color: #48af4a; } }

section.new-option {
  font-family: "Open Sans", Helvetica, sans-serif; }
  section.new-option .info {
    color: #485460;
    display: inline-block;
    max-width: 520px;
    margin: 0px; }
    @media only screen and (max-width: 767px) {
      section.new-option .info {
        padding: 0 20px;
        max-width: 300px;
        margin: 0px auto; } }
  section.new-option .content {
    margin-top: 30px;
    font-size: 0px; }
    section.new-option .content > div {
      padding: 0 20px;
      font-size: 14px; }
      @media only screen and (max-width: 767px) {
        section.new-option .content > div {
          margin-bottom: 30px;
          padding: 0px; }
          section.new-option .content > div:last-child {
            margin-bottom: 0px; } }
      section.new-option .content > div h4 {
        color: #343b41;
        font-weight: 100;
        margin: 30px 0px 10px 0px; }
      section.new-option .content > div p {
        color: #485460;
        padding: 0 20px;
        max-width: 300px;
        margin: 0px auto; }
      section.new-option .content > div img {
        width: 100%; }
  section.new-option button {
    font-size: 14px;
    padding: 30px 30px;
    margin-top: 30px;
    background-color: #48af4a;
    border-radius: 5px;
    color: #fff; }
    section.new-option button:after {
      top: 1px;
      position: relative;
      margin-left: 5px;
      content: "→";
      font-size: 20px;
      transition: all 0.5s; }

section.new-more-features .container-all {
  max-width: 960px !important;
  padding: 30px 0px 60px 0px !important;
  position: relative; }
  @media only screen and (max-width: 767px) {
    section.new-more-features .container-all {
      padding: 60px 0 !important;
      top: auto; } }
  section.new-more-features .container-all:after {
    content: "";
    position: absolute;
    height: 100%;
    width: 80%;
    background-color: #fff;
    top: 0;
    left: 0;
    right: 0;
    margin: auto; }
    @media only screen and (max-width: 767px) {
      section.new-more-features .container-all:after {
        width: 100%;
        height: calc(100% - 60px);
        bottom: 0; } }
  section.new-more-features .container-all:before {
    background-size: 650px;
    background-repeat: repeat;
    content: "";
    position: absolute;
    height: 100%;
    width: 100%;
    background-image: url(https://d3naj63yelf4gk.cloudfront.net/dist/img/faq/geometry.svg);
    top: 0;
    left: 0;
    right: 0;
    margin: auto; }
  section.new-more-features .container-all .container-content {
    position: relative;
    z-index: 1; }
    @media only screen and (max-width: 767px) {
      section.new-more-features .container-all .container-content .title-underscore {
        max-width: 200px;
        margin: auto !important; } }
    section.new-more-features .container-all .container-content .container-image-slide {
      min-height: 200px;
      background-color: #48af4a;
      position: relative; }
      section.new-more-features .container-all .container-content .container-image-slide img {
        width: 100%; }
    section.new-more-features .container-all .container-content .container-text-slide {
      top: -20px;
      position: relative;
      color: #fff;
      width: 85%;
      max-width: 500px;
      margin: auto; }
      @media only screen and (max-width: 767px) {
        section.new-more-features .container-all .container-content .container-text-slide {
          padding: 20px 0;
          top: auto; } }
      section.new-more-features .container-all .container-content .container-text-slide h3 {
        margin: 0px;
        margin-bottom: 20px; }
      section.new-more-features .container-all .container-content .container-text-slide p {
        margin: 0px;
        max-width: 400px;
        margin: auto; }
        @media only screen and (max-width: 767px) {
          section.new-more-features .container-all .container-content .container-text-slide p {
            max-width: 300px; } }
      section.new-more-features .container-all .container-content .container-text-slide a {
        font-size: 14px;
        display: inline-block;
        font-weight: 700;
        letter-spacing: 1.5px;
        position: relative;
        color: #fff;
        margin-top: 20px; }
        section.new-more-features .container-all .container-content .container-text-slide a:after {
          font-size: 18px;
          position: relative;
          content: "→";
          top: 1px;
          margin-left: 5px; }
    section.new-more-features .container-all .container-content .container-vertical {
      background-color: #48af4a; }
      @media only screen and (max-width: 767px) {
        section.new-more-features .container-all .container-content .container-vertical {
          padding-bottom: 100px; } }

@media only screen and (max-width: 767px) {
  section.new-slider-services .container-all {
    background-color: #fff;
    padding-bottom: 100px !important; } }

section.new-slider-services .title {
  text-align: left;
  padding: 26px 0;
  padding-left: 50px;
  position: relative;
  font-weight: 700;
  color: #48af4a;
  background-color: #fff; }
  section.new-slider-services .title h5 {
    margin: 0px; }
  section.new-slider-services .title .arrow-slider {
    position: absolute;
    height: 40px;
    width: 100px;
    right: 20px;
    top: 0;
    bottom: 0;
    margin: auto; }
    section.new-slider-services .title .arrow-slider > div {
      cursor: pointer;
      width: 50px; }
      section.new-slider-services .title .arrow-slider > div:not(.slick-arrow) {
        display: none; }
      section.new-slider-services .title .arrow-slider > div.slick-disabled {
        filter: grayscale(1) brightness(1.4); }
      section.new-slider-services .title .arrow-slider > div.left {
        float: left; }
      section.new-slider-services .title .arrow-slider > div.right {
        float: right; }
      section.new-slider-services .title .arrow-slider > div svg {
        height: 40px; }
        section.new-slider-services .title .arrow-slider > div svg ellipse {
          stroke: #48af4a; }
        section.new-slider-services .title .arrow-slider > div svg polygon {
          fill: #48af4a; }
  section.new-slider-services .title:before {
    background-color: #48af4a;
    position: absolute;
    height: 4px;
    width: 30px;
    top: 0;
    bottom: 0;
    left: -15px;
    margin: auto;
    content: ""; }
  @media only screen and (max-width: 767px) {
    section.new-slider-services .title {
      padding-left: 30px; } }

section.new-slider-services .container-slider-services {
  position: relative;
  width: 100%; }
  section.new-slider-services .container-slider-services.more {
    width: 130%; }
    @media only screen and (max-width: 767px) {
      section.new-slider-services .container-slider-services.more {
        width: 100%; } }
  section.new-slider-services .container-slider-services:before {
    content: "";
    position: absolute;
    height: 60%;
    top: 0;
    left: 0;
    background-color: #48af4a;
    width: 100%; }
  section.new-slider-services .container-slider-services .slider-services {
    margin-left: 40px;
    margin-top: 60px;
    display: inline-block;
    width: calc(100% - 60px); }
    @media only screen and (max-width: 768px) {
      section.new-slider-services .container-slider-services .slider-services {
        width: 100%; } }
    @media only screen and (max-width: 767px) {
      section.new-slider-services .container-slider-services .slider-services {
        margin-left: 0px; } }
    section.new-slider-services .container-slider-services .slider-services div.slick-slide {
      outline: none;
      min-height: 420px;
      padding: 50px 0;
      background: #fff;
      box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.11);
      width: 270px;
      margin-right: 25px;
      margin-bottom: 10px; }
      @media only screen and (max-width: 767px) {
        section.new-slider-services .container-slider-services .slider-services div.slick-slide {
          margin-right: auto;
          width: auto;
          margin: 0 10px;
          min-height: 370px;
          margin-bottom: 10px !important; } }
      @media only screen and (max-width: 321px) {
        section.new-slider-services .container-slider-services .slider-services div.slick-slide {
          margin: 0 5px; } }
    section.new-slider-services .container-slider-services .slider-services .content-slider {
      position: relative; }
      section.new-slider-services .container-slider-services .slider-services .content-slider img {
        max-height: 65px;
        margin: auto; }
      section.new-slider-services .container-slider-services .slider-services .content-slider h5 {
        color: #48af4a;
        margin: 30px 0px 15px 0px;
        padding: 0 30px; }
      section.new-slider-services .container-slider-services .slider-services .content-slider p {
        color: #4a4a4a;
        max-width: 240px;
        margin: auto;
        padding: 0 20px; }
      section.new-slider-services .container-slider-services .slider-services .content-slider.full-image {
        padding-top: 0px; }
        section.new-slider-services .container-slider-services .slider-services .content-slider.full-image img {
          max-height: none;
          width: 100%; }
    section.new-slider-services .container-slider-services .slider-services .link {
      position: absolute;
      bottom: 30px;
      left: 0;
      right: 0;
      margin: auto;
      margin-top: 15px; }
      section.new-slider-services .container-slider-services .slider-services .link a.arrow {
        font-size: 14px;
        font-weight: 700;
        letter-spacing: 1.5px;
        position: relative;
        color: #093c5b; }
        section.new-slider-services .container-slider-services .slider-services .link a.arrow:after {
          font-size: 18px;
          position: relative;
          content: "→";
          top: 1px;
          margin-left: 5px; }
    @media only screen and (max-width: 767px) {
      section.new-slider-services .container-slider-services .slider-services .new-float-arrow.arrow-right {
        right: 30px !important; }
      section.new-slider-services .container-slider-services .slider-services .new-float-arrow.arrow-left {
        left: 30px; }
      section.new-slider-services .container-slider-services .slider-services .new-float-arrow svg {
        transition: all 0.5s; }
        section.new-slider-services .container-slider-services .slider-services .new-float-arrow svg ellipse {
          stroke: #48af4a !important; }
        section.new-slider-services .container-slider-services .slider-services .new-float-arrow svg polygon {
          fill: #48af4a !important; }
      section.new-slider-services .container-slider-services .slider-services .new-float-arrow.slick-disabled svg ellipse {
        stroke: #ececec !important; }
      section.new-slider-services .container-slider-services .slider-services .new-float-arrow.slick-disabled svg polygon {
        fill: #ececec !important; } }

section.new-join-us .container-all {
  margin-bottom: 30px; }

section.new-join-us .container-vertical {
  position: relative;
  background-color: #343b41; }

section.new-join-us img {
  float: left;
  width: 100%; }

section.new-join-us .content-join-us {
  text-align: left;
  color: #fff;
  padding: 50px 30px; }
  @media only screen and (max-width: 768px) {
    section.new-join-us .content-join-us {
      padding: 30px 30px;
      padding-bottom: 50px; } }
  section.new-join-us .content-join-us h6 {
    font-size: 12px;
    color: #b3b9c7;
    margin: 0px; }
  section.new-join-us .content-join-us h3 {
    margin: 10px 0; }
  section.new-join-us .content-join-us p {
    margin: 0px; }
    @media only screen and (max-width: 768px) {
      section.new-join-us .content-join-us p {
        margin-bottom: 15px; } }

section.new-join-us .float-button {
  position: absolute;
  right: 30px;
  bottom: -30px;
  font-size: 14px;
  padding: 30px 30px;
  color: #fff;
  background-color: #48af4a;
  border-radius: 5px; }
  @media only screen and (max-width: 768px) {
    section.new-join-us .float-button {
      right: 0;
      left: 0;
      margin: auto;
      width: 260px; } }

section.new-more-information ol.list {
  font-family: "Open Sans", Helvetica, sans-serif;
  font-size: 14px;
  line-height: 24px;
  font-weight: 100;
  text-align: left;
  margin: 0px;
  letter-spacing: 1.5px; }
  @media only screen and (max-width: 767px) {
    section.new-more-information ol.list {
      padding-right: 20px; } }

section.new-more-information .sub-container {
  display: inline-block;
  max-width: 700px; }

section.new-more-information .container-all {
  padding-bottom: 30px !important; }

section.new-more-information .content {
  position: relative;
  margin: 40px;
  margin-bottom: 0px;
  background-color: #fff;
  padding: 50px 0; }
  @media only screen and (max-width: 768px) {
    section.new-more-information .content {
      margin: 30px; } }
  section.new-more-information .content:before {
    z-index: -1;
    position: absolute;
    width: calc(100% + 80px);
    height: 100%;
    background: #343b41;
    left: -40px;
    top: -40px;
    content: ""; }
    @media only screen and (max-width: 768px) {
      section.new-more-information .content:before {
        width: calc(100% + 60px);
        left: -30px;
        top: -30px; } }
  section.new-more-information .content p {
    margin: auto;
    margin-bottom: 30px; }
  section.new-more-information .content .container-button {
    margin-bottom: -80px;
    width: 100%;
    text-align: center; }
    section.new-more-information .content .container-button button {
      border-radius: 5px;
      color: #fff;
      width: 210px;
      padding: 30px 0; }
      section.new-more-information .content .container-button button.left {
        background: #48af4a;
        margin-right: 10px; }
        @media only screen and (max-width: 767px) {
          section.new-more-information .content .container-button button.left {
            margin-right: 0px;
            margin-bottom: 10px; } }
      section.new-more-information .content .container-button button.right {
        background-color: #343b41; }

section.new-how-to-use-promo .content {
  padding: 40px 0;
  background-color: #fff;
  position: relative; }
  section.new-how-to-use-promo .content:after {
    position: absolute;
    height: 100%;
    width: 70px;
    background-image: url(https://d3naj63yelf4gk.cloudfront.net/dist/img/faq/geometry.svg);
    background-size: 650px;
    background-repeat: repeat;
    left: 0;
    top: 0;
    content: ""; }
    @media only screen and (max-width: 767px) {
      section.new-how-to-use-promo .content:after {
        width: 100%;
        height: 30px; } }
  section.new-how-to-use-promo .content:before {
    position: absolute;
    height: 100%;
    width: 70px;
    background-image: url(https://d3naj63yelf4gk.cloudfront.net/dist/img/faq/geometry.svg);
    background-size: 650px;
    background-repeat: repeat;
    right: 0;
    bottom: 0;
    content: ""; }
    @media only screen and (max-width: 767px) {
      section.new-how-to-use-promo .content:before {
        width: 100%;
        height: 30px; } }
  section.new-how-to-use-promo .content p {
    font-family: "Open Sans", Helvetica, sans-serif;
    width: 75%;
    max-width: 700px;
    display: inline-block;
    margin: 0px;
    font-size: 14px; }
    @media only screen and (max-width: 767px) {
      section.new-how-to-use-promo .content p {
        width: 100%;
        padding: 0 30px; } }
  section.new-how-to-use-promo .content .container-list {
    text-align: center;
    margin: 30px 0;
    padding: 30px 0;
    background-color: #48af4a; }
    section.new-how-to-use-promo .content .container-list ol {
      letter-spacing: 1.5px;
      width: 75%;
      display: inline-block;
      max-width: 700px;
      font-family: "Open Sans", Helvetica, sans-serif;
      font-size: 14px;
      line-height: 24px;
      font-weight: 100;
      text-align: left;
      padding-left: 20px;
      margin: 0px;
      color: #fff; }
      @media only screen and (max-width: 767px) {
        section.new-how-to-use-promo .content .container-list ol {
          width: 100%;
          padding: 0 30px;
          padding-left: 50px; } }
  @media only screen and (max-width: 767px) {
    section.new-how-to-use-promo .content {
      padding: 60px 0; } }

/* --- exception ------ */
section.gopay-services .container-service {
  font-size: 0px; }
  section.gopay-services .container-service > div {
    display: inline-block;
    font-size: 14px; }
    @media only screen and (min-width: 769px) {
      section.gopay-services .container-service > div.extra:after {
        z-index: -1;
        position: absolute;
        height: 100%;
        width: calc(50% - 50px);
        background-color: white;
        left: 30px;
        top: 0;
        content: ""; } }
    section.gopay-services .container-service > div:first-child {
      padding-right: 20px; }
      @media only screen and (max-width: 768px) {
        section.gopay-services .container-service > div:first-child {
          padding-right: 0px; } }
    section.gopay-services .container-service > div:last-child {
      padding-left: 20px; }
      @media only screen and (max-width: 768px) {
        section.gopay-services .container-service > div:last-child {
          padding-left: 0px;
          margin-top: 60px; } }
      @media only screen and (max-width: 767px) {
        section.gopay-services .container-service > div:last-child {
          margin-top: 0px; } }

/* ----- div (child cards) ------ */
div.new-services {
  background: #fff;
  padding-bottom: 100px;
  position: relative;
  padding-top: 30px; }
  @media only screen and (max-width: 767px) {
    div.new-services {
      background: transparent;
      padding-top: 0px;
      padding-bottom: 30px; } }
  div.new-services:after {
    position: absolute;
    height: 60%;
    width: 100%;
    bottom: 50px;
    content: "";
    left: 0;
    background-image: url(https://d3naj63yelf4gk.cloudfront.net/dist/img/faq/geometry.svg);
    background-size: 650px; }
    @media only screen and (max-width: 767px) {
      div.new-services:after {
        display: none; } }
  div.new-services .container-content {
    position: relative;
    z-index: 1;
    font-size: 0px;
    width: 100%; }
    div.new-services .container-content > div {
      position: relative;
      font-size: 14px;
      background: #fff;
      padding: 40px 25px;
      border: 1px solid #ececec;
      box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.15); }
      div.new-services .container-content > div.center {
        border: none;
        box-shadow: none;
        background: transparent;
        position: absolute;
        height: 100%;
        width: 45px;
        left: 0;
        right: 0;
        top: 0;
        margin: auto; }
        div.new-services .container-content > div.center .container-content {
          position: absolute;
          left: 0;
          top: 82px;
          text-align: center; }
          div.new-services .container-content > div.center .container-content > div {
            margin-bottom: 10px;
            display: inline-block;
            padding: 0px;
            height: 40px;
            width: 40px;
            background: #fff;
            box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.15);
            border-radius: 50%; }
            div.new-services .container-content > div.center .container-content > div:after {
              display: inline-block;
              height: 100%;
              vertical-align: middle;
              content: ""; }
            div.new-services .container-content > div.center .container-content > div img {
              display: inline-block;
              vertical-align: middle;
              max-width: 20px; }
        @media only screen and (max-width: 767px) {
          div.new-services .container-content > div.center {
            display: none; } }
      div.new-services .container-content > div.left {
        left: -10px; }
        @media only screen and (max-width: 767px) {
          div.new-services .container-content > div.left {
            left: auto;
            margin-bottom: 10px;
            border-radius: 5px;
            box-shadow: none; } }
      div.new-services .container-content > div.right {
        right: -10px; }
        @media only screen and (max-width: 767px) {
          div.new-services .container-content > div.right {
            right: auto;
            box-shadow: none;
            border-radius: 5px; } }
      div.new-services .container-content > div h6 {
        text-transform: uppercase;
        margin: 0px;
        margin-bottom: 30px; }
      div.new-services .container-content > div > div {
        margin-bottom: 20px;
        color: #48af4a; }
        div.new-services .container-content > div > div.disabled {
          color: #dbdbdb; }
          div.new-services .container-content > div > div.disabled img {
            filter: grayscale(1) brightness(1.4); }
        div.new-services .container-content > div > div:last-child {
          margin-bottom: 0px; }
    div.new-services .container-content .container-vertical .icon img {
      display: none;
      max-width: 30px; }
      @media only screen and (max-width: 767px) {
        div.new-services .container-content .container-vertical .icon img {
          display: block; } }
    @media only screen and (max-width: 767px) {
      div.new-services .container-content .container-vertical .icon {
        width: 40px; } }
    @media only screen and (max-width: 767px) {
      div.new-services .container-content .container-vertical .content {
        width: calc(100% - 40px);
        text-align: left; } }

div.new-phone-slide {
  display: inline-block;
  width: 100%;
  padding-top: 30px;
  position: relative; }
  div.new-phone-slide:after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: #fff;
    z-index: -1; }
  @media only screen and (min-width: 768px) {
    div.new-phone-slide {
      margin-bottom: 65px; }
      div.new-phone-slide:before {
        content: "";
        position: absolute;
        height: 50%;
        bottom: 0;
        width: 100%;
        left: 0;
        background-image: url(https://d3naj63yelf4gk.cloudfront.net/dist/img/faq/geometry.svg);
        background-size: 650px; } }
  div.new-phone-slide .info {
    color: #9b9b9b;
    max-width: 400px;
    padding: 0 20px;
    margin: auto; }
  div.new-phone-slide .container-slide-phone {
    position: relative;
    width: 85%;
    margin: auto; }
    div.new-phone-slide .container-slide-phone .container-phone {
      margin-top: 30px;
      padding-top: 30px;
      /* for phone slider */
      /* for image gopay */ }
      @media only screen and (max-width: 767px) {
        div.new-phone-slide .container-slide-phone .container-phone {
          margin-top: 15px; } }
      div.new-phone-slide .container-slide-phone .container-phone.stick-top {
        padding-top: 0px;
        margin-top: 0px; }
      div.new-phone-slide .container-slide-phone .container-phone .background-phone {
        position: absolute;
        width: 100%;
        top: 0;
        height: 100%; }
        div.new-phone-slide .container-slide-phone .container-phone .background-phone img {
          width: 220px;
          position: relative;
          top: -6px; }
          @media only screen and (max-width: 767px) {
            div.new-phone-slide .container-slide-phone .container-phone .background-phone img {
              top: 25px; } }
      div.new-phone-slide .container-slide-phone .container-phone .phone-slider {
        max-width: 190px;
        position: relative;
        height: 216px;
        background-color: #dbdbdb;
        width: 100%;
        margin: auto;
        margin-top: 20px; }
        div.new-phone-slide .container-slide-phone .container-phone .phone-slider:not(.slick-initialized) {
          height: 200px !important; }
      div.new-phone-slide .container-slide-phone .container-phone .image-slider {
        max-width: 300px;
        position: relative;
        min-height: 200px;
        background-color: #dbdbdb;
        width: 100%;
        margin: auto;
        margin-top: 20px; }
        div.new-phone-slide .container-slide-phone .container-phone .image-slider:not(.slick-initialized) {
          height: 200px !important; }
        div.new-phone-slide .container-slide-phone .container-phone .image-slider .image img {
          width: 100%; }
    div.new-phone-slide .container-slide-phone .container-slide-text {
      position: relative;
      background-color: #48af4a;
      padding: 20px 0px;
      margin-bottom: -65px; }
      div.new-phone-slide .container-slide-phone .container-slide-text em {
        font-weight: 100;
        letter-spacing: 1.6px;
        color: #fff;
        max-width: 250px;
        display: inline-block;
        font-size: 12px;
        line-height: 15px; }
      div.new-phone-slide .container-slide-phone .container-slide-text .text-slider {
        width: calc(100% - 60px);
        margin: auto;
        color: #fff; }
        div.new-phone-slide .container-slide-phone .container-slide-text .text-slider:not(.slick-initialized) {
          height: 100px !important; }
        div.new-phone-slide .container-slide-phone .container-slide-text .text-slider .number {
          font-size: 24px; }
        div.new-phone-slide .container-slide-phone .container-slide-text .text-slider p {
          font-size: 12px;
          padding: 0 50px; }
          @media only screen and (max-width: 767px) {
            div.new-phone-slide .container-slide-phone .container-slide-text .text-slider p {
              padding: 0px; } }
        @media only screen and (max-width: 767px) {
          div.new-phone-slide .container-slide-phone .container-slide-text .text-slider .normal-dots {
            bottom: -75px !important; } }
        div.new-phone-slide .container-slide-phone .container-slide-text .text-slider .new-float-arrow {
          bottom: 0; }
          @media only screen and (max-width: 767px) {
            div.new-phone-slide .container-slide-phone .container-slide-text .text-slider .new-float-arrow {
              bottom: -85px !important; } }
      @media only screen and (max-width: 767px) {
        div.new-phone-slide .container-slide-phone .container-slide-text {
          padding-bottom: 70px;
          margin-bottom: 0px; } }
    @media only screen and (max-width: 767px) {
      div.new-phone-slide .container-slide-phone {
        width: 100%;
        padding-top: 15px;
        margin-top: 15px; }
        div.new-phone-slide .container-slide-phone:before {
          content: "";
          position: absolute;
          height: 100%;
          bottom: 0;
          width: 100%;
          left: 0;
          background-image: url(https://d3naj63yelf4gk.cloudfront.net/dist/img/faq/geometry.svg);
          background-size: 650px; } }

/* ---- other ---- */
div.new-floating-help {
  width: 90%;
  max-width: 400px;
  z-index: 10;
  position: fixed;
  bottom: 0;
  right: 0;
  margin: 25px;
  font-family: "Open Sans", Helvetica, sans-serif;
  transition: all 0.5s;
  transform: translateY(calc(100% - 30px)); }
  @media only screen and (max-width: 767px) {
    div.new-floating-help {
      margin: 25px auto;
      left: 0; } }
  @media only screen and (max-width: 767px) {
    div.new-floating-help .view-desktop {
      display: none; } }
  div.new-floating-help button.trigger {
    box-shadow: 0 0 4px 2px rgba(0, 0, 0, 0.3);
    z-index: 10;
    outline: none;
    transition: all 0.5s;
    position: relative;
    overflow: hidden;
    height: 70px;
    position: absolute;
    right: 0;
    top: -35px;
    cursor: pointer;
    border-radius: 5px;
    font-size: 16px;
    letter-spacing: 1.5px;
    padding: 0px;
    width: 220px;
    background-color: #ffcd2e;
    color: #48af4a; }
    div.new-floating-help button.trigger .open {
      transition: all 0.3s; }
    div.new-floating-help button.trigger .close {
      transition: all 0.3s;
      position: absolute;
      height: 22px;
      bottom: 0;
      left: 0;
      text-align: center;
      right: 0;
      left: 0;
      top: -10px;
      font-size: 25px;
      margin: auto;
      transform: translateY(100px); }
  div.new-floating-help .container-content {
    box-shadow: 0 0 4px 2px rgba(0, 0, 0, 0.3);
    transition: all 0.5s;
    opacity: 0;
    pointer-events: none;
    border: 2px solid #48af4a;
    border-radius: 5px;
    background-color: #ececec; }
    div.new-floating-help .container-content .header {
      font-size: 16px;
      text-align: center;
      background-color: #48af4a;
      width: 100%;
      padding: 10px 0;
      font-weight: 500;
      color: #fff;
      letter-spacing: 1.5px; }
    div.new-floating-help .container-content a {
      font-size: inherit;
      color: inherit; }
    div.new-floating-help .container-content button.link {
      border-radius: 5px;
      width: 100%;
      padding: 15px 0;
      letter-spacing: 1.5px;
      color: #fff;
      font-weight: 500; }
    div.new-floating-help .container-content .content {
      padding: 10px; }
      div.new-floating-help .container-content .content .item {
        padding: 10px;
        background: #fafafa;
        display: table;
        font-weight: 100;
        letter-spacing: normal;
        width: 100%;
        font-size: 0px;
        margin-bottom: 10px;
        border-radius: 5px; }
        div.new-floating-help .container-content .content .item.prominent {
          background-color: #5CA5DA;
          color: #fff;
          transition: all 0.5s; }
          div.new-floating-help .container-content .content .item.prominent:hover {
            background-color: #4490c7;
            transition: all 0.5s; }
        div.new-floating-help .container-content .content .item > div {
          display: table-cell;
          vertical-align: middle;
          font-size: 14px; }
          div.new-floating-help .container-content .content .item > div:first-child {
            width: 30px; }
          div.new-floating-help .container-content .content .item > div img {
            margin-right: 10px; }
          div.new-floating-help .container-content .content .item > div:after {
            display: inline-block;
            vertical-align: middle;
            content: "";
            position: absolute;
            top: 0; }
  div.new-floating-help.active {
    transition: all 0.5s;
    transform: translateY(0px); }
    div.new-floating-help.active .container-content {
      transition: all 0.5s;
      opacity: 1;
      pointer-events: visible; }
    div.new-floating-help.active button.trigger {
      box-shadow: 0 0 4px 2px transparent;
      border-radius: 50%;
      transition: width 0.3s 0s, border-radius 0.3s 0.2s, transform 0.3s 0.2s;
      width: 70px;
      transform: scale(0.7); }
      div.new-floating-help.active button.trigger .open {
        transform: translateX(-100px);
        transition: all 0.3s; }
      div.new-floating-help.active button.trigger .close {
        transform: translateY(0px);
        transition: all 0.3s; }

/* Image Preload */
/* TODO Not Yes done */
/* #promo-banner .img-item img{
  max-height: 300px;
} */
.about .img-container .img-container-2 .pure-img,
.services .img-container .bg-services,
.articles--feed .blog-card-img,
.section-user-review .body img,
.how-it-works--wrapper img,
#talent-story .img-item img,
#join-now img,
.verification .verified-container img,
#talent-story-golife .main-img,
#landing-onboarding .bg--img,
#landing-onboarding .highlight-article .blog-card-img,
.blog-list #blog--listing .post--image,
.blog-list .text-content img {
  display: block;
  width: 100%;
  background-image: url("https://bilba.go-jek.com/dist/img/lazyload.jpg");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover; }

#landing-onboarding .bg--img,
#landing-onboarding .highlight-article .blog-card-img {
  background-size: cover; }

#gotix #talent-story .img-item img {
  background: none; }

@media screen and (min-width: 1024px) {
  .about .img-container .img-container-2 .pure-img {
    height: auto; }
  .services .wrapper .service-container .service-list .bg-services {
    min-height: 100px; }
  .section-user-review .body img {
    transform: scale(1.25); }
  .how-it-works--wrapper img {
    height: 363.766px; }
  #talent-story .img-item img {
    min-height: 500px; }
  #join-now img {
    max-height: 500px; }
  #talent-story-golife .main-img {
    min-height: 500px; } }

#talent-story-golife .main-img {
  min-height: 220px; }

span.inline {
  display: inline-block; }

/* brushing */
body#gojek .brushing {
  background-image: url("../../dist/img/brush/GO-JEK.png"); }
  body#gojek .brushing:after {
    background: #ffffff url(https://bilba.go-jek.com/images/product-brand/gojek.svg) no-repeat center; }

body#karir .brushing {
  background-image: url("../../dist/img/brush/GO-SEND.png"); }
  body#karir .brushing:after {
    background: #ffffff url(https://bilba.go-jek.com/images/product-brand/gojek.svg) no-repeat center; }

body#goride .brushing {
  background-image: url("../../dist/img/brush/GO-RIDE.png"); }
  body#goride .brushing:after {
    background: #ffffff url("https://bilba.go-jek.com/images/product-brand/goride.svg") no-repeat center; }

body#goride .brushing {
  background-image: url("../../dist/img/brush/GO-RIDE.png"); }
  body#goride .brushing:after {
    background: #ffffff url("https://bilba.go-jek.com/images/product-brand/goride.svg") no-repeat center; }

body#goauto .brushing {
  background-image: url("../../dist/img/brush/GO-AUTO.png"); }
  body#goauto .brushing:after {
    background: #ffffff url("https://bilba.go-jek.com/images/product-brand/goauto.svg") no-repeat center; }

body#gocar .brushing {
  background-image: url("../../dist/img/brush/GO-CAR.png"); }
  body#gocar .brushing:after {
    background: #ffffff url("https://bilba.go-jek.com/images/product-brand/gocar.svg") no-repeat center; }

body#goclean .brushing {
  background-image: url("../../dist/img/brush/GO-CLEAN.png"); }
  body#goclean .brushing:after {
    background: #ffffff url("https://bilba.go-jek.com/images/product-brand/goclean.svg") no-repeat center; }

body#gofood .brushing {
  background-image: url("../../dist/img/brush/GO-FOOD.png"); }
  body#gofood .brushing:after {
    background: #ffffff url("https://bilba.go-jek.com/images/product-brand/gofood.svg") no-repeat center; }

body#goglam .brushing {
  background-image: url("../../dist/img/brush/GO-GLAM.png"); }
  body#goglam .brushing:after {
    background: #ffffff url("https://bilba.go-jek.com/images/product-brand/goglam.svg") no-repeat center; }

body#gomart .brushing {
  background-image: url("../../dist/img/brush/GO-MART.png"); }
  body#gomart .brushing:after {
    background: #ffffff url("https://bilba.go-jek.com/images/product-brand/gomart.svg") no-repeat center; }

body#gomassage .brushing {
  background-image: url("../../dist/img/brush/GO-MASSAGE.png"); }
  body#gomassage .brushing:after {
    background: #ffffff url("https://bilba.go-jek.com/images/product-brand/gomassage.svg") no-repeat center; }

body#golife .brushing {
  background-image: url("../../dist/img/brush/GO-LIFE.png"); }
  body#golife .brushing:after {
    background: #ffffff url("https://bilba.go-jek.com/images/product-brand/golife.svg") no-repeat center; }

body#gomed .brushing {
  background-image: url("../../dist/img/brush/GO-JEK.png"); }
  body#gomed .brushing:after {
    background: #ffffff url("https://bilba.go-jek.com/images/product-brand/gomed.svg") no-repeat center; }

body#gopay .brushing {
  background-image: url("../../dist/img/brush/GO-PAY.png"); }
  body#gopay .brushing:after {
    background: #ffffff url("https://bilba.go-jek.com/images/product-brand/gopay.svg") no-repeat center; }

body#gopoint .brushing {
  background-image: url("../../dist/img/brush/GO-POINTS.png"); }
  body#gopoint .brushing:after {
    background: #ffffff url("https://bilba.go-jek.com/images/product-brand/gopoint.svg") no-repeat center; }

body#gopulsa .brushing {
  background-image: url("../../dist/img/brush/GO-PULSA.png"); }
  body#gopulsa .brushing:after {
    background: #ffffff url("https://bilba.go-jek.com/images/product-brand/gopulsa.svg") no-repeat center; }

body#gosend .brushing {
  background-image: url("../../dist/img/brush/GO-SEND.png"); }
  body#gosend .brushing:after {
    background: #ffffff url("https://bilba.go-jek.com/images/product-brand/gosend.svg") no-repeat center; }

body#gotix .brushing {
  background-image: url("../../dist/img/brush/GO-TIX.png"); }
  body#gotix .brushing:after {
    background: #ffffff url(https://bilba.go-jek.com/images/product-brand/gotix.svg) no-repeat center; }

body#gobox .brushing {
  background-image: url("../../dist/img/brush/GO-BOX.png"); }
  body#gobox .brushing:after {
    background: #ffffff url("https://bilba.go-jek.com/images/product-brand/gobox.svg") no-repeat center; }

/* size per service */
#karir header.hero {
  background-color: #48af4a; }

#gojek .hero--title, #gojek .hero--desc, #gobox .hero--title, #gobox .hero--desc {
  max-width: 500px; }

#goauto header.hero {
  background-color: #BABCBE; }

#goauto .hero--title, #goauto .hero--desc {
  max-width: 637px !important; }

#gopoint header.hero {
  background-color: #F79520; }

#gopoint .hero--title, #gopoint .hero--desc {
  max-width: 500px !important; }

#gojek header.hero {
  background-color: #48af4a; }

#gojek .desktop-button {
  max-width: none; }
  #gojek .desktop-button > a:first-child > button {
    margin-right: 20px; }
  #gojek .desktop-button button {
    transition: all 0.5s;
    border-radius: 5px;
    display: inline-block;
    font-size: 16px;
    padding: 25px 0px;
    width: 280px;
    background-color: #009444; }
    #gojek .desktop-button button:hover {
      transform: scale(1.1); }
  @media screen and (max-width: 768px) {
    #gojek .desktop-button {
      display: none; } }

#gofood header.hero {
  background-color: #B62025; }

#gofood .hero--title, #gofood .hero--desc {
  max-width: 615px !important; }

#gofood .hero--desc > button:first-child {
  margin-right: 20px; }

#gofood .hero--desc button {
  transition: all 0.5s;
  border-radius: 5px;
  display: inline-block;
  font-size: 16px;
  padding: 25px 20px;
  background-color: #b62025; }
  #gofood .hero--desc button:hover {
    transition: all 0.5s;
    background: #ffffff;
    color: #B62025; }

@media screen and (max-width: 768px) {
  #gofood .hero--desc {
    display: none; } }

#gofood button.download {
  background: #B62025;
  z-index: 2; }

#gocar .hero--title, #gocar .hero--desc, #gomed .hero--title, #gomed .hero--desc, #gopay .hero--title, #gopay .hero--desc {
  max-width: 572px !important; }

#gocar header.hero {
  background-color: #17619D; }

#gomed header.hero {
  background-color: #006738; }

#gopay header.hero {
  background-color: #5CA5DA; }

#gotix header.hero {
  background-color: #EA6B25; }

#gomassage header.hero {
  background-color: #18AEAB; }

#gomassage .hero--title, #gomassage .hero--desc {
  max-width: 572px !important; }

#golife header.hero {
  background-color: #49af4b; }

#gomart header.hero {
  background-color: #3C95D1; }

#gobox header.hero {
  background-color: #A1652F; }

#gopulsa header.hero {
  background-color: #22AF6E; }

#goclean header.hero {
  background-color: #2197D4; }

#goglam header.hero {
  background-color: #E67498; }

#gosend header.hero {
  background-color: #8BC440; }

#gosend .sub-desc--row em {
  font-size: 18px;
  color: #ffffff;
  letter-spacing: 0.4px; }
  @media screen and (max-width: 768px) {
    #gosend .sub-desc--row em {
      font-size: 12px; } }
  @media screen and (max-width: 767px) {
    #gosend .sub-desc--row em {
      width: 100%;
      text-align: center;
      display: inline-block; } }

/* brushing */
body#gojek .brushing {
  background-image: url("../../dist/img/brush/GO-JEK.png"); }
  body#gojek .brushing:after {
    background: #ffffff url(https://bilba.go-jek.com/images/product-brand/gojek.svg) no-repeat center; }

body#karir .brushing {
  background-image: url("../../dist/img/brush/GO-SEND.png"); }
  body#karir .brushing:after {
    background: #ffffff url(https://bilba.go-jek.com/images/product-brand/gojek.svg) no-repeat center; }

body#goride .brushing {
  background-image: url("../../dist/img/brush/GO-RIDE.png"); }
  body#goride .brushing:after {
    background: #ffffff url("https://bilba.go-jek.com/images/product-brand/goride.svg") no-repeat center; }

body#goride .brushing {
  background-image: url("../../dist/img/brush/GO-RIDE.png"); }
  body#goride .brushing:after {
    background: #ffffff url("https://bilba.go-jek.com/images/product-brand/goride.svg") no-repeat center; }

body#goauto .brushing {
  background-image: url("../../dist/img/brush/GO-AUTO.png"); }
  body#goauto .brushing:after {
    background: #ffffff url("https://bilba.go-jek.com/images/product-brand/goauto.svg") no-repeat center; }

body#gocar .brushing {
  background-image: url("../../dist/img/brush/GO-CAR.png"); }
  body#gocar .brushing:after {
    background: #ffffff url("https://bilba.go-jek.com/images/product-brand/gocar.svg") no-repeat center; }

body#goclean .brushing {
  background-image: url("../../dist/img/brush/GO-CLEAN.png"); }
  body#goclean .brushing:after {
    background: #ffffff url("https://bilba.go-jek.com/images/product-brand/goclean.svg") no-repeat center; }

body#gofood .brushing {
  background-image: url("../../dist/img/brush/GO-FOOD.png"); }
  body#gofood .brushing:after {
    background: #ffffff url("https://bilba.go-jek.com/images/product-brand/gofood.svg") no-repeat center; }

body#goglam .brushing {
  background-image: url("../../dist/img/brush/GO-GLAM.png"); }
  body#goglam .brushing:after {
    background: #ffffff url("https://bilba.go-jek.com/images/product-brand/goglam.svg") no-repeat center; }

body#gomart .brushing {
  background-image: url("../../dist/img/brush/GO-MART.png"); }
  body#gomart .brushing:after {
    background: #ffffff url("https://bilba.go-jek.com/images/product-brand/gomart.svg") no-repeat center; }

body#gomassage .brushing {
  background-image: url("../../dist/img/brush/GO-MASSAGE.png"); }
  body#gomassage .brushing:after {
    background: #ffffff url("https://bilba.go-jek.com/images/product-brand/gomassage.svg") no-repeat center; }

body#golife .brushing {
  background-image: url("../../dist/img/brush/GO-LIFE.png"); }
  body#golife .brushing:after {
    background: #ffffff url("https://bilba.go-jek.com/images/product-brand/golife.svg") no-repeat center; }

body#gomed .brushing {
  background-image: url("../../dist/img/brush/GO-JEK.png"); }
  body#gomed .brushing:after {
    background: #ffffff url("https://bilba.go-jek.com/images/product-brand/gomed.svg") no-repeat center; }

body#gopay .brushing {
  background-image: url("../../dist/img/brush/GO-PAY.png"); }
  body#gopay .brushing:after {
    background: #ffffff url("https://bilba.go-jek.com/images/product-brand/gopay.svg") no-repeat center; }

body#gopoint .brushing {
  background-image: url("../../dist/img/brush/GO-POINTS.png"); }
  body#gopoint .brushing:after {
    background: #ffffff url("https://bilba.go-jek.com/images/product-brand/gopoint.svg") no-repeat center; }

body#gopulsa .brushing {
  background-image: url("../../dist/img/brush/GO-PULSA.png"); }
  body#gopulsa .brushing:after {
    background: #ffffff url("https://bilba.go-jek.com/images/product-brand/gopulsa.svg") no-repeat center; }

body#gosend .brushing {
  background-image: url("../../dist/img/brush/GO-SEND.png"); }
  body#gosend .brushing:after {
    background: #ffffff url("https://bilba.go-jek.com/images/product-brand/gosend.svg") no-repeat center; }

body#gotix .brushing {
  background-image: url("../../dist/img/brush/GO-TIX.png"); }
  body#gotix .brushing:after {
    background: #ffffff url(https://bilba.go-jek.com/images/product-brand/gotix.svg) no-repeat center; }

body#gobox .brushing {
  background-image: url("../../dist/img/brush/GO-BOX.png"); }
  body#gobox .brushing:after {
    background: #ffffff url("https://bilba.go-jek.com/images/product-brand/gobox.svg") no-repeat center; }

/* size per service */
#karir header.hero {
  background-color: #48af4a; }

#gojek hero--title, #gojek .hero--desc, #gobox hero--title, #gobox .hero--desc {
  max-width: 500px; }

#goauto header.hero {
  background-color: #BABCBE; }

#goauto .hero--title, #goauto .hero--desc {
  max-width: 637px !important; }

#gopoint header.hero {
  background-color: #F79520; }

#gopoint .hero--title, #gopoint .hero--desc {
  max-width: 500px !important; }

#gojek header.hero {
  background-color: #48af4a; }

#gojek .desktop-button {
  max-width: none; }
  #gojek .desktop-button > a:first-child > button {
    margin-right: 20px; }
  #gojek .desktop-button button {
    transition: all 0.5s;
    border-radius: 5px;
    display: inline-block;
    font-size: 16px;
    padding: 25px 0px;
    padding: 25px 30px;
    width: auto;
    background-color: #009444; }
    #gojek .desktop-button button:hover {
      color: #fff;
      transition: all 0.5s; }
    #gojek .desktop-button button:after {
      background-image: url(https://d3naj63yelf4gk.cloudfront.net/dist/img/header/arrow.png);
      position: relative;
      left: 10px;
      margin-right: 10px;
      top: 3px;
      width: 22px;
      height: 15px;
      content: "";
      display: inline-block;
      background-size: cover;
      background-position: 50%; }
  @media screen and (max-width: 768px) {
    #gojek .desktop-button {
      display: none; } }

#gofood header.hero {
  background-color: #B62025; }

#gofood .hero--desc > button:first-child {
  margin-right: 20px; }

#gofood .hero--desc button {
  transition: all 0.5s;
  border-radius: 5px;
  display: inline-block;
  font-size: 16px;
  padding: 25px 20px;
  background-color: #b62025; }
  #gofood .hero--desc button:hover {
    transition: all 0.5s;
    background: #ffffff;
    color: #B62025; }

@media screen and (max-width: 768px) {
  #gofood .hero--desc {
    display: none; } }

#gofood button.download {
  background: #B62025;
  z-index: 2; }

#gocar .hero--title, #gocar .hero--desc, #gomed .hero--title, #gomed .hero--desc, #gopay .hero--title, #gopay .hero--desc {
  max-width: 572px !important; }

#gocar header.hero {
  background-color: #17619D; }

#gomed header.hero {
  background-color: #006738; }

#gopay header.hero {
  background-color: #5CA5DA; }

#gotix header.hero {
  background-color: #EA6B25; }

#gomassage header.hero {
  background-color: #18AEAB; }

#gomassage .hero--title, #gomassage .hero--desc {
  max-width: 572px !important; }

#golife header.hero {
  background-color: #49af4b; }

#gomart header.hero {
  background-color: #3C95D1; }

#gobox header.hero {
  background-color: #A1652F; }

#gopulsa header.hero {
  background-color: #22AF6E; }

#goclean header.hero {
  background-color: #2197D4; }

#goglam header.hero {
  background-color: #E67498; }

#gosend header.hero {
  background-color: #8BC440; }

#gosend .hero--desc button {
  margin-top: 30px;
  transition: all 0.5s;
  border-radius: 5px;
  display: inline-block;
  font-size: 16px;
  padding: 20px 45px;
  background-color: #8BC440; }
  #gosend .hero--desc button:hover {
    transition: all 0.5s;
    background: #ffffff;
    color: #8BC440; }

@media screen and (max-width: 768px) {
  #gosend .hero--desc {
    display: none; } }

#gosend .sub-desc--row em {
  font-size: 18px;
  color: #ffffff;
  letter-spacing: 0.4px; }
  @media screen and (max-width: 768px) {
    #gosend .sub-desc--row em {
      font-size: 12px; } }
  @media screen and (max-width: 767px) {
    #gosend .sub-desc--row em {
      width: 100%;
      text-align: center;
      display: inline-block; } }

header#real--hero {
  margin-top: 85px; }
  header#real--hero .hero--wrapper {
    margin: 0; }
    header#real--hero .hero--wrapper img {
      width: 100%; }

#govideo header#main--hero:after {
  box-shadow: none !important; }

#govideo header#main--hero .pure-g {
  box-shadow: none; }

#govideo header#main--hero:after {
  box-shadow: none !important; }

#govideo header#main--hero .pure-g {
  box-shadow: none; }

header#main--hero {
  z-index: 1;
  height: auto;
  margin: 82px 0 0px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center; }
  @media screen and (min-width: 1025px) {
    header#main--hero {
      min-height: 500px !important; } }
  @media screen and (min-width: 769px) {
    header#main--hero {
      min-height: 300px; }
      header#main--hero:after {
        z-index: -1;
        content: "";
        position: absolute;
        height: 100%;
        width: 30%;
        top: 0;
        box-shadow: inset 15vw 2vw 13vw -7vw rgba(0, 0, 0, 0.8); } }
  @media screen and (max-width: 768px) {
    header#main--hero {
      margin: 60px auto 0;
      min-height: 280px; } }
  @media screen and (min-width: 768px) {
    header#main--hero.hero--2019 {
      min-height: 300px !important;
      background-color: transparent; } }
  @media screen and (max-width: 767px) {
    header#main--hero.hero--2019 {
      text-align: center; }
      header#main--hero.hero--2019 .container {
        padding-left: 0 !important;
        padding-right: 0 !important; } }
  header#main--hero.hero--2019:after {
    box-shadow: none; }
  header#main--hero.hero--2019 h1 {
    font-size: 45px; }
    @media screen and (max-width: 767px) {
      header#main--hero.hero--2019 h1 {
        font-size: 22px; } }
  header#main--hero.hero--2019 p.subtitle {
    font-size: 20px;
    margin: 0px 0px 8px 0px; }
  header#main--hero.hero--2019 .desktop-button button {
    margin: 0; }
  header#main--hero.hero--2019 .hero--title {
    max-width: 100%; }
  header#main--hero.hero--2019 .desc--row {
    min-height: 200px; }
    @media screen and (min-width: 769px) {
      header#main--hero.hero--2019 .desc--row > .sub-desc--row {
        text-align: right; } }
    @media screen and (max-width: 767px) {
      header#main--hero.hero--2019 .desc--row > .sub-desc--row {
        padding-bottom: 13%; } }
  header#main--hero.hero--2019 .desc--wrapper {
    padding: 0 106px; }
    @media screen and (max-width: 767px) {
      header#main--hero.hero--2019 .desc--wrapper {
        padding: 0; } }
  @media screen and (min-width: 768px) {
    header#main--hero.gojeksiapnganter--hero {
      min-height: 300px !important;
      background-color: transparent; } }
  @media screen and (max-width: 767px) {
    header#main--hero.gojeksiapnganter--hero .container {
      padding-left: 0 !important;
      padding-right: 0 !important; } }
  header#main--hero.gojeksiapnganter--hero:after {
    box-shadow: none; }
  header#main--hero.gojeksiapnganter--hero h1 {
    font-size: 48px; }
    @media screen and (max-width: 767px) {
      header#main--hero.gojeksiapnganter--hero h1 {
        font-size: 22px; } }
  header#main--hero.gojeksiapnganter--hero p.subtitle {
    font-size: 20px;
    margin: 0px 0px 5px 0px; }
  @media screen and (max-width: 767px) {
    header#main--hero.gojeksiapnganter--hero br {
      display: none; } }
  header#main--hero.gojeksiapnganter--hero .hero--title {
    max-width: 600px; }
  @media screen and (max-width: 767px) {
    header#main--hero.gojeksiapnganter--hero .desc--row > .sub-desc--row {
      padding-bottom: 13%; } }
  @media screen and (max-width: 767px) {
    header#main--hero.gojeksiapnganter--hero {
      text-align: center; } }
  header#main--hero .hero--title,
  header#main--hero .hero--desc {
    color: #fff; }
  header#main--hero button.download {
    display: none; }
    @media screen and (max-width: 768px) {
      header#main--hero button.download {
        left: -20%;
        bottom: -27px;
        display: inline-block;
        letter-spacing: 1.5px;
        color: #ffffff;
        display: inline-block;
        width: 200px;
        margin: auto;
        padding: 21px 12px;
        position: absolute;
        font-size: 10px;
        right: 0;
        display: inline-block;
        padding-right: 20px;
        border-radius: 5px; }
        header#main--hero button.download:after {
          content: "";
          background-image: url(https://d3naj63yelf4gk.cloudfront.net/dist/img/header/arrow.png);
          position: relative;
          left: 10px;
          width: 13px;
          height: 8px;
          display: inline-block;
          top: 0px;
          background-size: cover; } }
    @media screen and (max-width: 767px) {
      header#main--hero button.download {
        left: 0; } }
    @media screen and (max-width: 320px) {
      header#main--hero button.download {
        bottom: -13px; } }
  header#main--hero .brushing {
    background-size: cover;
    background-position: right;
    background-repeat: no-repeat;
    position: absolute;
    width: 120px;
    height: 120px;
    bottom: 0;
    left: 0; }
    @media screen and (max-width: 767px) {
      header#main--hero .brushing {
        width: 20%;
        height: 20%; } }
    header#main--hero .brushing:after {
      content: "";
      position: absolute;
      height: 40%;
      width: 40%;
      top: 0;
      bottom: -15px;
      left: -15px;
      right: 0;
      margin: auto;
      background-size: 100% 100%;
      background-color: transparent; }
      @media screen and (max-width: 767px) {
        header#main--hero .brushing:after {
          bottom: 0;
          left: 0;
          width: 40%;
          height: 40%; } }
  header#main--hero .desc--row {
    position: relative;
    display: table;
    height: 100%;
    width: 100%;
    min-height: 500px; }
    header#main--hero .desc--row > .sub-desc--row {
      display: table-cell;
      vertical-align: middle;
      max-width: 400px; }
      @media screen and (max-width: 767px) {
        header#main--hero .desc--row > .sub-desc--row {
          vertical-align: bottom;
          padding-bottom: 20%; } }
    @media screen and (max-width: 767px) {
      header#main--hero .desc--row {
        width: 92%;
        margin: auto;
        min-height: unset; } }
  header#main--hero .hero--title {
    font-size: 48px;
    max-width: 465px;
    margin: 0px;
    text-transform: uppercase;
    font-family: 'Neo', 'Open Sans', Helvetica, sans-serif !important;
    line-height: 1.16;
    letter-spacing: 0.5px;
    text-shadow: 0 2px 20px rgba(0, 0, 0, 0.7); }
    header#main--hero .hero--title.gojekinaja--hero {
      text-shadow: 0 2px 20px rgba(0, 0, 0, 0.7);
      font-weight: 900; }
      header#main--hero .hero--title.gojekinaja--hero span {
        font-weight: 700; }
    @media screen and (min-width: 2000px) {
      header#main--hero .hero--title {
        font-size: 120px;
        max-width: 1000px; } }
    @media screen and (max-width: 768px) {
      header#main--hero .hero--title {
        font-size: 40px; } }
    @media screen and (max-width: 767px) {
      header#main--hero .hero--title {
        font-size: 22px;
        text-align: center; } }
  header#main--hero .desc--wrapper {
    height: 100%;
    width: 100%; }
    @media screen and (max-width: 767px) {
      header#main--hero .desc--wrapper {
        width: 100%; } }
  header#main--hero .pure-g {
    position: relative;
    height: 100%;
    box-shadow: inset 55vw 5vw 100vw -13vw rgba(0, 0, 0, 0.65); }
    @media screen and (max-width: 767px) {
      header#main--hero .pure-g {
        box-shadow: inset 0px -100px 174px 0px rgba(0, 0, 0, 0.8); } }
  header#main--hero picture {
    position: relative;
    width: 100%;
    height: auto;
    z-index: -1; }
    header#main--hero picture source,
    header#main--hero picture img {
      float: left;
      width: 100%;
      height: auto; }
      @media screen and (max-width: 767px) {
        header#main--hero picture source,
        header#main--hero picture img {
          height: auto;
          width: 100%;
          float: left; } }
  header#main--hero .desc--wrapper {
    position: absolute; }
    @media screen and (max-width: 768px) {
      header#main--hero .desc--wrapper {
        left: 8%; } }
    @media screen and (max-width: 767px) {
      header#main--hero .desc--wrapper {
        left: 0;
        right: 0;
        margin: auto;
        min-height: 300px; } }

header.hero {
  position: relative; }

header.hero .hero--desc {
  max-width: 465px;
  font-size: 22px;
  font-weight: 300;
  letter-spacing: 1px;
  color: #485460;
  display: block; }
  header.hero .hero--desc a {
    color: #ffffff;
    line-height: inherit; }
  @media screen and (min-width: 2000px) {
    header.hero .hero--desc {
      font-size: 40px;
      max-width: 1000px; } }
  @media screen and (max-width: 767px) {
    header.hero .hero--desc {
      font-size: 12px;
      text-align: center;
      margin: 0px; } }

header.hero .read-more--hero {
  margin: 80px 0;
  padding: 2em 3em;
  font-size: 18px;
  font-weight: 500;
  line-height: 0.8;
  letter-spacing: 3.3px;
  color: #ffffff;
  background-color: #48af4a;
  border: 0;
  border-radius: 5px;
  text-transform: uppercase;
  font-family: 'Open Sans', sans-serif; }

header.hero button#gojek-readmore-hero:after {
  content: url(https://d3naj63yelf4gk.cloudfront.net/dist/img/header/arrow.png);
  position: relative;
  left: 10px; }

header.hero .desc--wrapper {
  /*background: url('https://d3naj63yelf4gk.cloudfront.net/dist/img/header/hero/bg-grey.png') no-repeat;*/ }

header.hero .slide--wrapper {
  /*background: url('/img/hero/bg-bu.png') no-repeat;*/
  position: absolute;
  top: 0;
  right: 0; }

header.hero .slide--row {
  top: 0;
  right: 0;
  position: relative;
  height: 100%;
  display: block; }

header.hero .slide--ovrelay {
  margin: 0;
  position: absolute;
  z-index: 2;
  opacity: 0.4;
  width: 100%;
  height: 100%;
  background: #000000; }

header.hero .slide--ovrelay > img {
  width: 100%;
  height: 100%; }

header.hero .slide--row ul {
  position: absolute;
  margin: 0;
  padding: 0;
  list-style: none;
  z-index: 1;
  width: 100%;
  height: 100%;
  overflow: hidden; }

header.hero .slide--row ul > li {
  position: absolute;
  height: 100%;
  opacity: 0;
  -webkit-transition: opacity .3s ease-in;
  -moz-transition: opacity .3s ease-in;
  -o-transition: opacity .3s ease-in;
  transition: opacity .3s ease-in; }

header.hero .slide--row ul > li img {
  width: 110%;
  height: 100%;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  transform: translateX(-50%);
  position: relative; }

header.hero .slide--row ul > li:first-of-type {
  opacity: 1; }

header.hero .bu--grad {
  position: absolute;
  z-index: 3;
  margin: 0;
  bottom: 0;
  overflow: hidden; }

header.hero .bu--grad > img {
  top: 10px;
  left: -110px;
  position: relative;
  width: 120%; }

@media screen and (max-width: 768px) {
  header#main--hero {
    height: auto; }
  header.hero .read-more--hero {
    margin: 20px 0px 50px;
    font-size: 14px;
    position: relative;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    transform: translateX(-50%); } }

#djDebug {
  opacity: 0;
  pointer-events: none; }

[data-background*="starwars"] {
  background-color: #cd0000 !important; }

[data-background*="goride"] {
  background-color: #F9AE3C !important; }

[data-background*="gobills"] {
  background-color: #4285a7 !important; }

[data-background*="gocar"] {
  background-color: #17619D !important; }

[data-background*="gofood"] {
  background-color: #B62025 !important; }

[data-background*="gomart"] {
  background-color: #3C95D1 !important; }

[data-background*="gosend"] {
  background-color: #8BC440 !important; }

[data-background*="gobox"] {
  background-color: #f2db04 !important; }

[data-background*="gotix"] {
  background-color: #EA6B25 !important; }

[data-background*="gopay"] {
  background-color: #5CA5DA !important; }

[data-background*="gopoint"] {
  background-color: #F79520 !important; }

[data-background*="gomassage"] {
  background-color: #18AEAB !important; }

[data-background*="goclean"] {
  background-color: #2197D4 !important; }

[data-background*="goglam"] {
  background-color: #E67498 !important; }

[data-background*="goauto"] {
  background-color: #043659 !important; }

[data-background*="golife"] {
  background-color: #49af4b !important; }

.hamburger-mobile {
  position: fixed;
  top: 23px;
  right: 15px;
  height: 16px;
  width: 22px;
  z-index: 2002; }
  .hamburger-mobile div {
    height: 2px;
    width: 100%;
    position: absolute;
    background-image: linear-gradient(144deg, #9fda36, #25c4c7);
    right: 0;
    transition: all 0.5s; }
  .hamburger-mobile div.active {
    width: 16px !important;
    background-image: linear-gradient(144deg, #8dc63f, #8dc63f); }
  .hamburger-mobile > div:nth-child(1) {
    width: 95%;
    top: 0; }
  .hamburger-mobile > div:nth-child(1).active {
    transform: rotate(135deg) translate3d(4px, -4px, 0px);
    width: 100%; }
  .hamburger-mobile > div:nth-child(2) {
    width: 80%;
    top: 0;
    bottom: 0;
    margin: auto;
    transition: all 0.5s; }
  .hamburger-mobile > div:nth-child(2).active {
    transform: translateX(60px);
    transition: all 0.5s; }
  .hamburger-mobile > div:nth-child(3) {
    bottom: 0; }
  .hamburger-mobile > div:nth-child(3).active {
    transform: rotate(-135deg) translate3d(5.8px, 5.8px, 0px); }
  @media screen and (min-width: 769px) {
    .hamburger-mobile {
      display: none; } }

.overlay-menu-mobile {
  position: fixed;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  top: 0;
  left: 0;
  z-index: 2001;
  opacity: 0;
  pointer-events: none;
  transition: all 0.5s 0.3s; }
  .overlay-menu-mobile.active {
    opacity: 1;
    pointer-events: visible;
    transition: all 0.5s; }
  .overlay-menu-mobile .active-current--lang {
    padding: 10px 0;
    text-align: center;
    text-transform: uppercase; }
  .overlay-menu-mobile .dropdown-wrapper select {
    background: transparent;
    width: 100%;
    height: 40px;
    padding: 0px 10px;
    border-color: #e8ebf2 !important; }
  .overlay-menu-mobile .content-menu {
    position: fixed;
    overflow: hidden;
    width: calc(100% - 45px);
    height: 100%;
    background: #ffffff;
    transform: translateX(-100%);
    transition: all 0.5s; }
    .overlay-menu-mobile .content-menu.active {
      transform: translateX(0%);
      transition: all 0.5s 0.2s; }
    .overlay-menu-mobile .content-menu .header-menu {
      width: 100%;
      height: 60px;
      background-color: #fff; }
      .overlay-menu-mobile .content-menu .header-menu .navbar-brand-menu {
        width: 100%;
        padding: 0 15px 0;
        position: relative;
        top: 50%;
        -webkit-transform: translateY(-50%);
        -moz-transform: translateY(-50%);
        -o-transform: translateY(-50%);
        transform: translateY(-50%); }
        .overlay-menu-mobile .content-menu .header-menu .navbar-brand-menu img {
          width: 39px; }
      .overlay-menu-mobile .content-menu .header-menu .menu-list {
        top: 60px;
        position: absolute;
        height: calc(100% - 45px);
        overflow: auto;
        width: 100%;
        padding: 0 15px;
        transition: all 0.5s; }
        .overlay-menu-mobile .content-menu .header-menu .menu-list ul {
          padding: 0;
          margin: 0;
          list-style-type: none; }
          .overlay-menu-mobile .content-menu .header-menu .menu-list ul li {
            padding: 15px 0;
            text-transform: uppercase;
            font-family: Lato,Helvetica,sans-serif;
            text-transform: uppercase; }
            .overlay-menu-mobile .content-menu .header-menu .menu-list ul li a {
              width: 100%;
              display: inline-block;
              color: #000;
              font-size: 14px;
              letter-spacing: 1px; }
              .overlay-menu-mobile .content-menu .header-menu .menu-list ul li a i {
                float: right; }
            .overlay-menu-mobile .content-menu .header-menu .menu-list ul li.active {
              font-weight: 700; }
        .overlay-menu-mobile .content-menu .header-menu .menu-list.disactive {
          transform: translateX(100%);
          transition: all 0.5s; }
      .overlay-menu-mobile .content-menu .header-menu .accordion {
        width: 100%;
        height: 100%;
        overflow: auto;
        transition: all 0.5s;
        transform: translateX(-100%);
        top: 60px;
        position: absolute;
        padding-bottom: 50px; }
        .overlay-menu-mobile .content-menu .header-menu .accordion .service_name {
          display: none; }
        .overlay-menu-mobile .content-menu .header-menu .accordion .service_group {
          width: 100%;
          display: inline-block;
          font-family: Lato,Helvetica,sans-serif;
          text-transform: uppercase;
          letter-spacing: 1px;
          font-size: 14px;
          color: #000;
          padding: 15px; }
          .overlay-menu-mobile .content-menu .header-menu .accordion .service_group i {
            transition: all 0.5s;
            float: right; }
          .overlay-menu-mobile .content-menu .header-menu .accordion .service_group i.rotate-down {
            transform: rotate(90deg);
            transition: all 0.5s; }
          .overlay-menu-mobile .content-menu .header-menu .accordion .service_group.back-menu {
            background-color: #fafafa; }
            .overlay-menu-mobile .content-menu .header-menu .accordion .service_group.back-menu i {
              float: none;
              margin-right: 15px; }
        .overlay-menu-mobile .content-menu .header-menu .accordion ul.service_name {
          padding: 0;
          margin: 0;
          list-style-type: none;
          padding-left: 30px; }
          .overlay-menu-mobile .content-menu .header-menu .accordion ul.service_name li {
            padding: 15px 0;
            text-transform: uppercase;
            font-family: Lato,Helvetica,sans-serif; }
            .overlay-menu-mobile .content-menu .header-menu .accordion ul.service_name li a {
              width: 100%;
              display: inline-block;
              color: #000;
              font-size: 14px;
              letter-spacing: 1px; }
              .overlay-menu-mobile .content-menu .header-menu .accordion ul.service_name li a i {
                float: right; }
        .overlay-menu-mobile .content-menu .header-menu .accordion.active {
          transform: translateX(0%); }
    .overlay-menu-mobile .content-menu .table-display {
      display: table;
      width: 100%; }
      .overlay-menu-mobile .content-menu .table-display .middle-table {
        display: table-cell;
        vertical-align: middle; }
        .overlay-menu-mobile .content-menu .table-display .middle-table .logo {
          margin: 9px 0;
          margin-right: 5px;
          height: 35px;
          text-align: center;
          width: 35px;
          background-color: #ffffff; }
          .overlay-menu-mobile .content-menu .table-display .middle-table .logo .table-display {
            height: 100%; }
          .overlay-menu-mobile .content-menu .table-display .middle-table .logo img {
            height: 23px;
            margin-top: 7px; }
        .overlay-menu-mobile .content-menu .table-display .middle-table a {
          font-size: 12px;
          letter-spacing: 1px;
          font-weight: 700;
          color: #000; }
    .overlay-menu-mobile .content-menu .social-media {
      margin-top: 25px;
      text-align: center; }
      .overlay-menu-mobile .content-menu .social-media ul li {
        display: inline-block;
        margin-right: 15px;
        padding: 5px 0 !important; }
        .overlay-menu-mobile .content-menu .social-media ul li i {
          font-size: 24px; }
      .overlay-menu-mobile .content-menu .social-media ul li:last-child {
        margin-right: 0px; }

.navbar {
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 2000;
  -webkit-transition: all 0.5s ease 0s;
  -moz-transition: all 0.5s ease 0s;
  -o-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s;
  background: white;
  box-shadow: none; }
  @media screen and (min-width: 769px) {
    .navbar.no--active {
      top: -84px; } }
  @media screen and (max-width: 768px) {
    .navbar {
      height: 60px; }
      .navbar > .container {
        top: 50%;
        position: relative;
        transform: translateY(-50%); }
      .navbar .brand img {
        width: 100% !important;
        max-width: 30px; } }
  .navbar .dropdown-wrapper {
    border: 1px solid #48af4a;
    border-radius: 4px;
    float: right;
    position: relative;
    height: 35px;
    line-height: 35px; }
    @media screen and (max-width: 768px) {
      .navbar .dropdown-wrapper {
        margin-right: 35px; } }
    .navbar .dropdown-wrapper.gabung {
      margin-right: 15px; }
    .navbar .dropdown-wrapper .container-dropdown {
      height: 100%; }
    .navbar .dropdown-wrapper div {
      float: left; }
      .navbar .dropdown-wrapper div.icon {
        cursor: pointer;
        background-repeat: no-repeat;
        background-size: 17px;
        background-position: center;
        height: 100%;
        width: 30px;
        color: #ffffff;
        text-align: center;
        background-color: #48af4a; }
        .navbar .dropdown-wrapper div.icon svg {
          pointer-events: none;
          position: relative;
          top: 2px; }
          @media screen and (max-width: 768px) {
            .navbar .dropdown-wrapper div.icon svg {
              top: 3px; }
              .navbar .dropdown-wrapper div.icon svg path {
                fill: #48af4a; } }
        @media screen and (max-width: 768px) {
          .navbar .dropdown-wrapper div.icon {
            background-color: transparent; } }
      .navbar .dropdown-wrapper div.current {
        cursor: pointer;
        text-transform: uppercase;
        letter-spacing: 1px;
        padding: 0 10px;
        color: #48af4a;
        font-size: 12px; }
        @media screen and (max-width: 768px) {
          .navbar .dropdown-wrapper div.current {
            display: none; } }
    .navbar .dropdown-wrapper select {
      background: transparent;
      height: 35px;
      width: 60px;
      border-color: #e8ebf2 !important; }
    @media screen and (max-width: 768px) {
      .navbar .dropdown-wrapper .container-language {
        transition: all 0.5s 0.1s;
        opacity: 0;
        pointer-events: none;
        position: fixed;
        margin-top: 60px;
        height: calc(100vh - 55px);
        width: 100%;
        left: 0;
        top: 0;
        background-color: rgba(0, 0, 0, 0.8); }
        .navbar .dropdown-wrapper .container-language .select-lang {
          display: table;
          vertical-align: middle;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          margin: auto;
          transform: scale(0.8); } }
    .navbar .dropdown-wrapper .container-language .select-lang {
      transition: all 0.5s;
      opacity: 0;
      pointer-events: none;
      padding: 13px;
      position: absolute;
      background-color: #ffffff;
      right: 0;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
      border-radius: 5px;
      top: -3px;
      width: 200px;
      right: -15px;
      color: #4a4a4a;
      font-family: "Open Sans", Helvetica, sans-serif;
      z-index: 1000; }
      .navbar .dropdown-wrapper .container-language .select-lang form {
        display: none; }
      .navbar .dropdown-wrapper .container-language .select-lang .close {
        cursor: pointer;
        padding: 0px 0px !important;
        position: absolute;
        height: 20px;
        width: 20px !important;
        top: 13px;
        right: 13px;
        border-bottom: none !important; }
        .navbar .dropdown-wrapper .container-language .select-lang .close:after {
          transform: rotate(45deg) translate3d(10px, 10px, 10px);
          height: 2px;
          position: absolute;
          width: 100%;
          background: #48af4a;
          content: ""; }
        .navbar .dropdown-wrapper .container-language .select-lang .close:before {
          content: "";
          position: absolute;
          height: 2px;
          width: 100%;
          background: #48af4a;
          transform: rotate(-45deg) translate3d(-10px, 10px, 10px); }
      .navbar .dropdown-wrapper .container-language .select-lang .head {
        pointer-events: none;
        line-height: normal;
        font-size: 20px;
        font-weight: 300;
        margin-bottom: 10px; }
      .navbar .dropdown-wrapper .container-language .select-lang .choose-lang > div {
        width: 100%;
        cursor: pointer;
        line-height: normal;
        padding: 7px 0;
        font-size: 12px;
        border-bottom: 1px solid #fafafa; }
        @media screen and (min-width: 769px) {
          .navbar .dropdown-wrapper .container-language .select-lang .choose-lang > div {
            transition: all 0.5s; }
            .navbar .dropdown-wrapper .container-language .select-lang .choose-lang > div:hover {
              transform: translateX(10px);
              color: #48af4a;
              transition: all 0.5s; } }
    .navbar .dropdown-wrapper .container-language.active .select-lang {
      opacity: 1;
      pointer-events: visible;
      transition: all 0.5s; }
    @media screen and (max-width: 768px) {
      .navbar .dropdown-wrapper .container-language.active {
        opacity: 1;
        pointer-events: visible;
        transition: all 0.5s; }
        .navbar .dropdown-wrapper .container-language.active .select-lang {
          opacity: 1;
          transform: scale(1);
          pointer-events: visible;
          transition: all 0.5s 0.2s; } }
    @media screen and (max-width: 768px) {
      .navbar .dropdown-wrapper {
        border: none; } }
  .navbar #gojek-brand-mobile {
    display: none; }
  .navbar #gojek-brand-desktop {
    display: block; }
  .navbar .wrapper {
    padding: 25px 0; }
    @media screen and (max-width: 768px) {
      .navbar .wrapper {
        padding: 14px 0; } }
  .navbar .brand img {
    width: 100%;
    position: relative;
    padding: 0;
    height: auto;
    top: 6px; }
  @media screen and (max-width: 35.5em) {
    .navbar .brand img {
      width: 65px; }
    .navbar .dropdown-wrapper[data-entity=hamburger-menu] {
      display: block; } }
  .navbar ul {
    list-style-type: none;
    margin: 0; }
  .navbar ul li {
    float: left; }
  .navbar ul li.active {
    color: #48af4a !important; }
  .navbar ul li a {
    position: relative;
    font-size: 12.5px;
    letter-spacing: 1.1px;
    color: #485460;
    margin: 0 20px 0 20px;
    top: 7px;
    text-decoration: none;
    -webkit-transition: all .3s ease-in-out;
    -moz-transition: all .3s ease-in-out;
    -o-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out; }
  .navbar ul li a:not(.career-tab):hover,
  .navbar ul li a.active {
    color: #48af4a !important; }
  .navbar ul li a.active:after {
    content: "";
    width: 100%;
    position: absolute;
    top: 50px;
    height: 2.5px;
    left: 0px;
    background: #48af4a;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%); }
  .navbar .btn.btn-style-1 {
    letter-spacing: 1px;
    padding: 10.75px;
    float: right;
    text-decoration: none;
    font-size: 12px;
    color: #48af4a;
    margin: 0 10px 0 0px;
    background: white;
    border: solid 1px #48af4a;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    line-height: .8em; }
  .navbar .btn-style-1-icon {
    float: right;
    color: white;
    object-fit: contain;
    background: #48af4a;
    cursor: pointer;
    padding: 8px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px; }
    .navbar .btn-style-1-icon img {
      vertical-align: inherit; }
  .navbar .btn.btn-style-1 > span {
    margin: 0 10px 0 10px; }
  .navbar .btn.btn-style-2 {
    padding: 7.5px;
    float: right;
    text-decoration: none;
    font-size: 12.5px;
    color: white;
    background: #fecb42;
    margin: 0 5px 0 5px;
    border: solid 1px #fecb42; }
  .navbar .btn.btn-style-2 > span {
    margin: 0 5px 0 5px; }
  .navbar .hamburger {
    display: none;
    float: right; }
  .navbar .hamburger > i {
    font-size: 20px;
    color: #9fda36; }

.megamenu.product {
  display: none;
  background: #f0f0f0;
  width: 100%;
  height: 100%;
  padding: 65px;
  position: fixed;
  top: 82px;
  z-index: 2001;
  overflow-y: scroll; }
  .megamenu.product .toggleClose {
    margin-top: -35px;
    margin-bottom: 0px;
    font-size: 44px;
    float: right;
    color: #969696; }
  .megamenu.product .product-wrapper .list {
    position: relative; }
    .megamenu.product .product-wrapper .list .more {
      display: none;
      padding: 0;
      background: #48af4a;
      color: #fff;
      border-radius: 5px;
      font-family: Open Sans,Helvetica,sans-serif;
      letter-spacing: 1.2px;
      font-weight: 600;
      font-size: 12px;
      position: absolute;
      bottom: -25px;
      left: 50%;
      width: 120px;
      -webkit-transform: translateX(-50%);
      -moz-transform: translateX(-50%);
      -o-transform: translateX(-50%);
      transform: translateX(-50%); }
      .megamenu.product .product-wrapper .list .more span {
        padding: 15px 10px;
        display: block; }
    .megamenu.product .product-wrapper .list img {
      width: 100%;
      margin: 0 auto;
      top: 50%;
      -webkit-transform: translateY(-50%);
      -moz-transform: translateY(-50%);
      -o-transform: translateY(-50%);
      transform: translateY(-50%);
      position: relative; }
  .megamenu.product .product-wrapper .list:hover .more {
    display: block; }
  .megamenu.product .gojek-section {
    margin-top: 0px;
    margin-bottom: 40px; }
    .megamenu.product .gojek-section img {
      max-width: 100px; }
    .megamenu.product .gojek-section p {
      margin-top: 20px;
      font-size: 13px;
      line-height: 1.88;
      letter-spacing: 1px; }
    .megamenu.product .gojek-section .product-wrapper {
      position: relative;
      top: 15px; }
      .megamenu.product .gojek-section .product-wrapper .list {
        background: white;
        padding: 20px;
        text-align: center;
        height: 115px;
        margin-right: 15px;
        margin-bottom: 45px; }
        .megamenu.product .gojek-section .product-wrapper .list img {
          max-width: 100px;
          display: block; }
    .megamenu.product .gojek-section hr {
      margin-top: 50px;
      border: 1px solid #e6e6e6; }
  .megamenu.product .gopay-section {
    margin-top: 0px;
    margin-bottom: 70px; }
    .megamenu.product .gopay-section img {
      max-width: 100px; }
    .megamenu.product .gopay-section p {
      margin-top: 20px;
      font-size: 13px;
      line-height: 1.88;
      letter-spacing: 1px; }
    .megamenu.product .gopay-section .product-wrapper {
      position: relative;
      top: 15px; }
      .megamenu.product .gopay-section .product-wrapper .list {
        background: white;
        padding: 20px;
        height: 115px;
        text-align: center;
        margin-right: 15px;
        margin-bottom: 45px; }
        .megamenu.product .gopay-section .product-wrapper .list img {
          max-width: 100px;
          display: block; }
    .megamenu.product .gopay-section hr {
      margin-top: 50px;
      border: 1px solid #e6e6e6; }
  .megamenu.product .golife-section {
    margin-top: 0px;
    margin-bottom: 40px; }
    .megamenu.product .golife-section img {
      max-width: 100px; }
    .megamenu.product .golife-section p {
      margin-top: 20px;
      font-size: 13px;
      line-height: 1.88;
      letter-spacing: 1px; }
    .megamenu.product .golife-section .product-wrapper {
      position: relative;
      top: 15px; }
      .megamenu.product .golife-section .product-wrapper .list {
        background: white;
        padding: 20px;
        height: 115px;
        text-align: center;
        margin-right: 15px;
        margin-bottom: 45px; }
        .megamenu.product .golife-section .product-wrapper .list img {
          max-width: 100px;
          display: block; }
    .megamenu.product .golife-section hr {
      margin-top: 50px;
      border: 1px solid #e6e6e6; }

.megamenu.join {
  display: none;
  background: #f0f0f0;
  width: 100%;
  height: 100%;
  padding: 65px;
  position: fixed;
  top: 84px;
  z-index: 2001;
  overflow-y: scroll; }
  .megamenu.join .toggleClose {
    margin-top: -35px;
    margin-bottom: 50px;
    font-size: 44px;
    float: right;
    color: #969696; }
  .megamenu.join .table-display {
    position: relative;
    z-index: 2;
    display: table;
    width: 100%;
    height: 100%; }
    .megamenu.join .table-display .mid-table {
      display: table-cell;
      vertical-align: top; }
      .megamenu.join .table-display .mid-table .title-mega {
        line-height: 9px;
        font-size: 9px;
        font-weight: 700;
        color: #343b41;
        margin-top: 5px; }
      .megamenu.join .table-display .mid-table .extra {
        position: absolute;
        font-size: 9px;
        color: #343b41;
        margin: auto;
        margin-top: 2px;
        left: 0;
        right: 0; }
  .megamenu.join .gojek-section {
    margin-top: -10px;
    margin-bottom: 40px; }
    .megamenu.join .gojek-section h3.title {
      font-family: "Lato", Helvetica, sans-serif;
      letter-spacing: 0;
      font-size: 36px;
      font-weight: 300;
      color: #343b41; }
    .megamenu.join .gojek-section h4.service-type {
      color: #48af4a;
      font-size: 18px; }
    .megamenu.join .gojek-section p.service-type-desc {
      font-family: "Open Sans", Helvetica, sans-serif;
      font-size: 13px;
      color: #343b41;
      line-height: 1.88;
      height: 110px; }
    .megamenu.join .gojek-section img {
      max-width: 100px; }
    .megamenu.join .gojek-section p {
      margin-top: 20px;
      font-size: 13px;
      line-height: 1.88;
      letter-spacing: 0;
      color: #343b41; }
    .megamenu.join .gojek-section .product-wrapper {
      position: relative;
      top: 0;
      left: 5%; }
      .megamenu.join .gojek-section .product-wrapper .list-services-type {
        padding: 10px;
        width: 32.9% !important; }
      .megamenu.join .gojek-section .product-wrapper .join-list--wrapper {
        margin: 0 -7px; }
        .megamenu.join .gojek-section .product-wrapper .join-list--wrapper .list {
          background: #fff;
          margin: 8px 5px;
          height: 70px;
          overflow: hidden;
          text-align: center;
          width: 28.1667% !important;
          position: relative; }
          .megamenu.join .gojek-section .product-wrapper .join-list--wrapper .list .more {
            transform: translateY(100%);
            background: #48af4a;
            top: 0;
            left: 0;
            position: absolute;
            width: 100%;
            height: 100%;
            transition: transform 0.5s; }
            .megamenu.join .gojek-section .product-wrapper .join-list--wrapper .list .more span {
              padding: 7px 10px;
              display: block; }
          .megamenu.join .gojek-section .product-wrapper .join-list--wrapper .list .more.GO-SEND {
            background-color: #8bc440 !important; }
          .megamenu.join .gojek-section .product-wrapper .join-list--wrapper .list .more.GO-CAR {
            background-color: #17619d; }
          .megamenu.join .gojek-section .product-wrapper .join-list--wrapper .list .more.GO-MART {
            background-color: #4b9cd2; }
          .megamenu.join .gojek-section .product-wrapper .join-list--wrapper .list .more.GO-RIDE {
            background-color: #f9ae3c; }
          .megamenu.join .gojek-section .product-wrapper .join-list--wrapper .list .more.GO-FOOD {
            background-color: #d8232a; }
          .megamenu.join .gojek-section .product-wrapper .join-list--wrapper .list .more.GO-TIX {
            background-color: #eb6b23; }
          .megamenu.join .gojek-section .product-wrapper .join-list--wrapper .list .more.GO-MASSAGE {
            background-color: #17b4ad; }
          .megamenu.join .gojek-section .product-wrapper .join-list--wrapper .list .more.GO-GLAM {
            background-color: #e87699; }
          .megamenu.join .gojek-section .product-wrapper .join-list--wrapper .list .more.GO-CLEAN {
            background-color: #218fc2; }
          .megamenu.join .gojek-section .product-wrapper .join-list--wrapper .list .more.GO-BOX {
            background-color: #9d662e; }
          .megamenu.join .gojek-section .product-wrapper .join-list--wrapper .list .more.GO-MED {
            background-color: #006738; }
          .megamenu.join .gojek-section .product-wrapper .join-list--wrapper .list .more.GO-POINT {
            background-color: #f7951d; }
          .megamenu.join .gojek-section .product-wrapper .join-list--wrapper .list .more.GO-AUTO {
            background-color: #BABCBE; }
          .megamenu.join .gojek-section .product-wrapper .join-list--wrapper .list img {
            position: relative;
            z-index: 1;
            width: 80%;
            margin: auto; }
        .megamenu.join .gojek-section .product-wrapper .join-list--wrapper .list:hover .more {
          transform: translateY(0%); }
        .megamenu.join .gojek-section .product-wrapper .join-list--wrapper .list:hover img {
          filter: grayscale(100%) brightness(100);
          transition: filter 0s 0.05s ease; }
        .megamenu.join .gojek-section .product-wrapper .join-list--wrapper .list:hover .title-mega, .megamenu.join .gojek-section .product-wrapper .join-list--wrapper .list:hover .extra {
          color: #ffffff;
          transition: all 0.5s; }
    .megamenu.join .gojek-section hr {
      margin-top: 50px;
      border: 1px solid #e6e6e6; }

.hamburger-menu {
  top: 0;
  display: none;
  height: 100%;
  width: 100%;
  position: fixed;
  overflow-y: scroll;
  padding: 20px;
  z-index: 9999;
  background: black; }
  .hamburger-menu .gojek-logo {
    max-width: 70px;
    margin-top: 30px; }
  .hamburger-menu .button-download-wrapper {
    margin-top: 30px; }
    .hamburger-menu .button-download-wrapper .btn.btn-style-2 {
      padding: 12px;
      text-decoration: none;
      font-size: 12.5px;
      color: white;
      background: #fecb42;
      margin: 0 5px 0 5px;
      border: solid 1px #fecb42; }
    .hamburger-menu .button-download-wrapper .btn.btn-style-2 > span {
      margin: 10px 2.5px 2.5px 2.5px;
      position: relative;
      left: 5px;
      top: 5px; }
    .hamburger-menu .button-download-wrapper .download-text {
      text-transform: uppercase;
      font-size: 16px;
      line-height: 2.11;
      letter-spacing: 2.6px;
      color: white;
      font-weight: 300;
      margin-left: 20px; }
  .hamburger-menu #hamburger-btn-close {
    float: right;
    font-size: 30px;
    color: #009444;
    font-weight: 600;
    margin-top: 38px;
    font-family: "Open Sans", sans-serif; }
  .hamburger-menu hr {
    border: 1px solid #535353; }
  .hamburger-menu ul {
    list-style-type: none;
    margin-left: -40px;
    margin-top: 60px;
    margin-bottom: 60px; }
  .hamburger-menu ul li {
    margin-bottom: 22.5px;
    font-weight: 300; }
    .hamburger-menu ul li div.accordion {
      display: none;
      font-size: 16px;
      font-family: "Lato", Helvetica, sans-serif;
      text-transform: uppercase;
      letter-spacing: 3px;
      line-height: 10px;
      color: white; }
      .hamburger-menu ul li div.accordion span {
        display: block;
        margin-left: 20px;
        margin-top: 40px;
        margin-bottom: 30px; }
        .hamburger-menu ul li div.accordion span i {
          float: right; }
      .hamburger-menu ul li div.accordion ul {
        display: none;
        margin-top: 30px;
        margin-left: 10px; }
        .hamburger-menu ul li div.accordion ul li {
          margin-bottom: 30px;
          list-style-type: disc; }
  .hamburger-menu ul li.active {
    font-weight: 700; }
  .hamburger-menu ul li a {
    font-size: 16px;
    font-family: "Lato", Helvetica, sans-serif;
    text-transform: uppercase;
    letter-spacing: 3px;
    line-height: 10px;
    color: white; }
    .hamburger-menu ul li a i {
      float: right; }
  .hamburger-menu .social-media {
    text-align: center;
    margin-top: 100px;
    margin-bottom: 55px; }
    .hamburger-menu .social-media ul {
      list-style-type: none;
      display: inline; }
      .hamburger-menu .social-media ul li {
        display: inline;
        margin-left: 18px;
        margin-right: 18px; }
        .hamburger-menu .social-media ul li a i {
          font-size: 34px;
          float: none !important; }

@media screen and (max-width: 768px) {
  .navbar .menu {
    display: none; }
  .navbar #gojek-brand-mobile {
    display: block; }
  .navbar #gojek-brand-desktop {
    display: none; }
  .navbar .button-wrapper {
    display: none; }
  .navbar .hamburger {
    display: inline-block;
    position: relative;
    top: 5px; } }

@media screen and (max-width: 320px) {
  .hamburger-menu .button-download-wrapper .btn.btn-style-2 {
    padding: 8px;
    text-decoration: none;
    font-size: 12.5px;
    color: white;
    background: #fecb42;
    margin: 0 5px 0 5px;
    border: solid 1px #fecb42; }
  .hamburger-menu .button-download-wrapper .download-text {
    text-transform: uppercase;
    letter-spacing: 2px;
    font-size: 12px;
    color: white;
    font-weight: 700;
    margin-left: 20px; }
  .hamburger-menu .social-media ul li a i {
    font-size: 24px;
    float: none !important; } }

.navbar-active {
  background: white;
  box-shadow: 0px 5px 50px rgba(40, 40, 40, 0.15); }

.overflow-y-hidden {
  overflow-y: hidden !important; }

/* parent condition */
.par-child-navbar {
  margin-top: 125px !important; }
  @media screen and (max-width: 768px) {
    .par-child-navbar {
      margin-top: 60px !important; } }

/* child navbar */
.child-navbar {
  background-color: #343b41;
  position: relative;
  top: 1px;
  z-index: -1; }
  @media screen and (max-width: 48em) {
    .child-navbar {
      display: none; } }
  .child-navbar ul {
    background-color: #343b41;
    list-style: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
    display: block !important;
    justify-content: center; }
    .child-navbar ul li a {
      display: block;
      color: white;
      text-align: center;
      padding: 16px;
      text-decoration: none;
      letter-spacing: 1px;
      top: 0;
      margin: 0; }
    .child-navbar ul li a.career-tab {
      background: #8cc63f;
      padding: 16px 26px;
      margin: 0; }
    .child-navbar ul li.active a {
      color: #48af4a; }
    .child-navbar ul li.active a:after,
    .child-navbar ul li a:hover:after {
      content: "";
      width: 100%;
      position: absolute;
      bottom: 0;
      height: 4px;
      left: 0;
      background: #48af4a; }
  .child-navbar .career-tab {
    background-color: #8cc63f;
    display: flex;
    flex-direction: column;
    justify-content: center; }
    .child-navbar .career-tab a {
      text-decoration: none;
      display: block;
      color: #ffffff; }

.sub-menu {
  display: none; }
  @media screen and (max-width: 48em) {
    .sub-menu {
      display: block;
      /* extra sub menu */
      text-transform: uppercase;
      cursor: pointer;
      position: absolute;
      padding: 0 15px;
      height: 63px;
      margin-left: 17px;
      top: 0;
      color: white;
      font-size: 14px;
      background: #84bf41; }
      .sub-menu .table-display {
        display: table;
        width: 100%;
        height: 100%; }
        .sub-menu .table-display .mid-table {
          width: 100%;
          text-align: center;
          display: table-cell;
          vertical-align: middle; }
          .sub-menu .table-display .mid-table i#iconDownSubmenu {
            transition: all 0.5s;
            margin-left: 5px; }
          .sub-menu .table-display .mid-table i#iconDownSubmenu.active {
            transition: all 0.5s;
            transform: rotate(180deg); } }

.container-sub-menu {
  display: block;
  z-index: 11;
  transition: all 0.5s;
  position: fixed;
  height: 100%;
  width: 100%;
  opacity: 0;
  top: 0;
  pointer-events: none;
  background: rgba(0, 0, 0, 0.5); }
  @media screen and (min-width: 769px) {
    .container-sub-menu {
      display: none; } }
  .container-sub-menu .content-sub-menu {
    width: 100%;
    background: #84bf41;
    transform: translateY(-100%);
    transition: all 0.5s; }
  .container-sub-menu .content-sub-menu.active {
    transition: all 0.5s 0.2s;
    transform: translateY(0%); }
  .container-sub-menu ul.list-content {
    margin: 0px;
    background: #84bf41;
    padding-left: 0px;
    padding-top: 60px; }
    .container-sub-menu ul.list-content li {
      list-style-type: none;
      padding: 15px 20px; }
      .container-sub-menu ul.list-content li a {
        line-height: normal;
        font-size: 14px;
        color: white;
        letter-spacing: normal; }
    .container-sub-menu ul.list-content li.active {
      background: #2c822e; }

.container-sub-menu.active {
  opacity: 1;
  transition: all 0.5s;
  pointer-events: visible; }

footer {
  position: relative;
  height: 25em;
  background: white;
  font-family: "Lato", Helvetica, sans-serif; }
  footer .dropdown-wrapper {
    margin-top: 5px; }
    footer .dropdown-wrapper select {
      background: transparent;
      width: 90%;
      border-color: #e8ebf2 !important;
      height: 40px;
      padding: 0px 10px; }
  @media only screen and (max-width: 35.5em) {
    footer .dropdown-wrapper select {
      width: 100%; } }
  footer #header {
    background-size: cover;
    height: 50px;
    width: 100%;
    top: -2.5em;
    left: 0;
    position: absolute; }
  footer .pure-g:first-child {
    padding-top: 5em; }
  footer a,
  footer h2 {
    color: black !important; }
  footer .hide {
    display: none; }
  footer img {
    width: auto; }
  footer hr {
    display: none; }
  footer a {
    text-decoration: none; }
  footer a,
  footer p {
    font-size: 9px;
    font-weight: 500; }
  footer #brush {
    position: absolute;
    right: 0;
    bottom: 0; }
  footer .wrapper {
    right: 0;
    bottom: 0;
    position: absolute; }
  footer h2 {
    font-size: 3em;
    letter-spacing: 5px;
    font-weight: 300;
    color: white !important;
    font-family: 'Viva', Helvetica, sans-serif;
    text-align: right;
    line-height: 1.2em;
    margin: 0 1em; }
    footer h2 span {
      display: block; }
  footer h2:after {
    content: none !important; }
  footer ul {
    list-style: none;
    padding: 0;
    margin: 0;
    font-family: "Lato", Helvetica, sans-serif;
    font-weight: 300;
    line-height: 2.0714285714285716em;
    letter-spacing: 0.14285714285714285em;
    outline: none; }
    footer ul .title-content {
      font-family: "Lato", Helvetica, sans-serif;
      font-weight: 700;
      outline: none;
      color: black !important; }
      footer ul .title-content i {
        float: right;
        display: none; }
      footer ul .title-content .ui-accordion-header-icon {
        float: right; }
    footer ul #accordion ul {
      display: inherit; }
  footer .social-media {
    text-align: center;
    padding-top: 4.7em;
    font-size: 0.875em; }
    footer .social-media a {
      margin-left: 1em;
      margin-right: 1em; }
    footer .social-media p {
      color: #9b9b9b !important;
      letter-spacing: 0.2em;
      font-family: "Lato", Helvetica, sans-serif;
      font-weight: 300; }

@media screen and (max-width: 768px) {
  footer .social-media {
    position: absolute;
    bottom: 0;
    left: 0;
    margin: 8px; } }

@media screen and (max-width: 35.5em) {
  footer {
    height: 1120px;
    background-size: 100%, cover;
    padding-bottom: 10em; }
    footer .pure-g:first-child {
      padding-top: 3em; }
    footer img {
      margin-left: auto;
      margin-right: auto;
      padding-bottom: 2em; }
    footer li {
      margin: 0 0.875em;
      line-height: 2.0625em;
      letter-spacing: 0.1625em; }
    footer .fa {
      margin: 0.5em 0.75em; }
    footer hr {
      background: white;
      border: solid 0.09375em #9b9b9b;
      width: 100%;
      margin: 1.7em 0.5em;
      display: block;
      height: 0;
      width: 100%; }
    footer .social-media {
      position: relative;
      padding: 0;
      margin-bottom: 5em; }
      footer .social-media a {
        margin: 0; }
    footer #brush {
      padding-bottom: 0; }
    footer .wrapper {
      right: 0;
      left: 0;
      bottom: 2em; }
    footer h2 {
      text-align: center;
      font-size: 24px;
      margin: 1em auto; } }

@media screen and (min-width: 48em) {
  footer li {
    padding: 0;
    line-height: 2.0625em;
    letter-spacing: 0.1625em; }
  footer ul {
    font-size: 0.65em; }
    footer ul .title-content {
      font-size: 12px; }
  footer .social-media {
    font-size: 0.5em; } }

@media screen and (min-width: 64em) {
  footer .social-media {
    padding-top: 4.75em; } }

#bg-as-seen-on {
  background: #f8f8f8;
  padding: 5em 0; }

.as-seen-on {
  position: relative;
  text-align: center; }
  .as-seen-on .title {
    text-align: left; }
    .as-seen-on .title p {
      display: inline-block;
      margin-left: 3em;
      font-weight: 700; }
  .as-seen-on hr {
    background: #25c768;
    border: none;
    display: inline-block;
    width: 50px;
    height: 4px;
    left: 1.5em;
    top: 2.8em;
    position: absolute; }
  .as-seen-on #left,
  .as-seen-on #right {
    height: 250px; }
  .as-seen-on #left {
    background: #343b41;
    position: absolute;
    bottom: 0; }
  .as-seen-on #content {
    background: white;
    line-height: 14px;
    letter-spacing: 1.9px;
    color: #48af4a;
    font-family: "Open Sans", Helvetica, sans-serif;
    font-weight: 700;
    padding: 2em 0;
    margin-left: auto;
    margin-right: auto; }
    .as-seen-on #content img {
      max-height: 100px;
      width: auto;
      padding: 2em; }
  .as-seen-on #right {
    position: absolute;
    background: #98ce00;
    right: -3.02em; }
  .as-seen-on img {
    width: 55em;
    margin: 0 auto;
    filter: grayscale(100%);
    padding: 2em 0;
    opacity: 0.5; }
  .as-seen-on img, .as-seen-on a {
    -webkit-transition: all .3s ease-in;
    -moz-transition: all .3s ease-in;
    -o-transition: all .3s ease-in;
    transition: all .3s ease-in; }
  .as-seen-on img:hover,
  .as-seen-on a:hover {
    filter: none;
    opacity: 1; }

/* Small Screen */
@media screen and (max-width: 35.5em) {
  .as-seen-on {
    padding: 0; }
    .as-seen-on hr {
      left: -1em;
      top: 2.35em; }
    .as-seen-on p {
      margin-left: 0; }
    .as-seen-on #left {
      left: 0; }
    .as-seen-on #right {
      right: 0; }
    .as-seen-on #content {
      padding: 1.5em; }
    .as-seen-on #left,
    .as-seen-on #right {
      height: 350px; } }

/* Medium Screen */
@media screen and (min-width: 48em) {
  .as-seen-on img {
    width: 41em; }
  .as-seen-on #left,
  .as-seen-on #right {
    height: 200px; }
  .as-seen-on #left {
    left: 0; }
  .as-seen-on #right {
    right: 0; } }

/* ipad =< media */
@media screen and (max-width: 768px) {
  .as-seen-on #content img {
    max-height: none !important;
    width: 100%;
    max-width: 200px; }
  .as-seen-on #content img.big-image {
    max-width: 120px; } }

/*  992px */
/* Large Screen */
@media screen and (min-width: 64em) {
  .as-seen-on img {
    width: 60em; } }

/* Extra Large Screen */
.highlight-article, .top-picks {
  background: #f8f8f8;
  text-align: center;
  font-family: "Open Sans", Helvetica, sans-serif;
  font-weight: 300;
  line-height: 0.88;
  letter-spacing: 1.7px;
  color: #48af4a;
  padding: 2.5em; }
  @media screen and (min-width: 1200px) {
    .highlight-article .container, .top-picks .container {
      width: 80%; } }
  .highlight-article hr, .top-picks hr {
    background-image: linear-gradient(98deg, #9fda36, #25c768);
    border: none;
    display: inline-block;
    width: 50px;
    height: 4px; }
  .highlight-article .pure-g, .top-picks .pure-g {
    margin-top: 3em; }
  .highlight-article .content, .top-picks .content {
    margin: 0 1.25em 5em;
    transition: all .3s ease-in-out; }
    .highlight-article .content .blog-card-title, .top-picks .content .blog-card-title {
      text-align: left;
      padding: 1em 1.5em;
      color: black;
      background: white;
      font-family: "Open Sans", Helvetica, sans-serif;
      font-weight: 300;
      line-height: 1.42;
      letter-spacing: 2.2px; }
      .highlight-article .content .blog-card-title h4, .top-picks .content .blog-card-title h4 {
        font-family: "Lato", Helvetica, sans-serif;
        font-weight: 300;
        line-height: 1.33;
        letter-spacing: 2.5px; }
        .highlight-article .content .blog-card-title h4 a, .top-picks .content .blog-card-title h4 a {
          color: #000; }
        .highlight-article .content .blog-card-title h4 a:hover, .top-picks .content .blog-card-title h4 a:hover {
          color: #48af4a; }
      .highlight-article .content .blog-card-title p, .top-picks .content .blog-card-title p {
        font-size: 0.65em;
        font-family: "Open Sans", Helvetica, sans-serif;
        font-weight: 700;
        line-height: 1.0;
        letter-spacing: 1.5px;
        color: #c3c9d7;
        text-transform: uppercase; }
  .highlight-article .mobile, .top-picks .mobile {
    display: none; }
  .highlight-article .custom-nav, .top-picks .custom-nav {
    display: none;
    top: 85% !important; }

#karir .desktop {
  display: block !important; }
  @media screen and (max-width: 767px) {
    #karir .desktop > .pure-u-1 {
      margin-bottom: 30px; } }
  @media screen and (max-width: 767px) {
    #karir .desktop {
      width: 90%;
      margin: auto; }
      #karir .desktop .blog-card-title {
        height: auto;
        padding-bottom: 75px; } }

@media screen and (min-width: 769px) {
  #karir .blog-list .container {
    width: 80%; } }

#karir .blog-list .sub-logo .table-display {
  padding: 10px; }

#karir .blog-list .container-sub-title .title-blog-career {
  padding: 0 40px;
  display: table-cell;
  vertical-align: middle; }

#karir .blog-list .container-sub-title .title {
  padding: 0px;
  display: inline-block;
  width: 100%; }

#karir .blog-list .container-sub-title .auth-new {
  display: inline-block;
  width: 100%;
  margin-top: 10px;
  letter-spacing: normal; }

#karir .blog-list .pre--title p.service {
  margin: 5px 0; }

@media screen and (max-width: 35.5em) {
  .highlight-article {
    padding: 2em 0 5em 0; }
    .highlight-article .desktop {
      display: none; }
    .highlight-article .mobile {
      display: block; }
    .highlight-article .custom-nav {
      display: block; }
      .highlight-article .custom-nav #custom-prev {
        left: 3.5em;
        position: absolute; }
      .highlight-article .custom-nav #custom-next {
        right: 3.5em;
        position: absolute; }
    .highlight-article .slick-track {
      padding-top: 3em; }
    .highlight-article .content {
      margin: 0 0.5em 5em; }
    .highlight-article .content.slick-current {
      box-shadow: 0 2px 29px 0 rgba(0, 0, 0, 0.19);
      transform: scaleY(1.15); } }

.top-picks .bg--img {
  background-size: cover;
  background-position: center center;
  height: 300px;
  overflow: hidden;
  position: relative; }

.top-picks .blog--overlay {
  -webkit-background-image: linear-gradient(to bottom, transparent, rgba(0, 0, 0, 0.9));
  -moz-background-image: linear-gradient(to bottom, transparent, rgba(0, 0, 0, 0.9));
  background-image: linear-gradient(to bottom, transparent, rgba(0, 0, 0, 0.9));
  height: 100%;
  position: absolute;
  width: 100%;
  top: 0;
  left: 0; }

.top-picks [class*="pure-u"] {
  padding: 20px;
  position: relative; }
  @media screen and (max-width: 768px) {
    .top-picks [class*="pure-u"] {
      padding: 5px; } }

@media screen and (max-width: 768px) {
  .top-picks .container {
    width: 100%; } }

.top-picks .desc--wrapper {
  position: absolute;
  top: 0;
  text-align: left;
  padding: 100px 50px; }
  .top-picks .desc--wrapper .category {
    font-size: 14px;
    font-weight: 500;
    line-height: 1.0;
    letter-spacing: 1.5px;
    color: #58c75b;
    text-transform: uppercase; }
  .top-picks .desc--wrapper .title a {
    font-size: 22px;
    font-weight: 500;
    line-height: 1.42;
    letter-spacing: 2.2px;
    color: #ffffff; }
  @media screen and (max-width: 768px) {
    .top-picks .desc--wrapper {
      padding: 20px;
      bottom: 0;
      top: auto;
      right: 0; } }

@media screen and (max-width: 768px) {
  .top-picks {
    padding: 0px; } }

@media screen and (max-width: 35.5em) {
  .top-picks {
    padding: 2.5em 0; }
    .top-picks .desc--wrapper .title a {
      font-size: 18px; } }

.highlight-article h5 {
  font-size: 18px; }

.highlight-article .desktop {
  margin-right: -1.25em;
  margin-left: -1.25em; }

.highlight-article .content {
  margin: 0 0 70px !important;
  padding: 0 1.25em; }
  @media screen and (max-width: 767px) {
    .highlight-article .content {
      margin: 0 10px !important;
      padding: 0px; } }

@media screen and (max-width: 767px) {
  .highlight-article .mobile-slide {
    padding-bottom: 100px; } }

@media screen and (max-width: 767px) {
  .highlight-article .hide-mobile {
    display: none; } }

.articles--feed {
  background: #f8f8f8;
  text-align: center;
  font-family: "Open Sans", Helvetica, sans-serif;
  font-weight: 300;
  line-height: 0.88;
  letter-spacing: 1.7px;
  padding: 2.5em;
  position: relative; }
  .articles--feed .index-blog-slide {
    margin-top: 30px;
    padding-bottom: 130px; }
  .articles--feed .table-display {
    background-color: #ffffff;
    padding: 15px 18px;
    display: table;
    width: 100%; }
    .articles--feed .table-display .mid-tabs {
      text-align: left;
      display: table-cell;
      vertical-align: middle; }
      .articles--feed .table-display .mid-tabs .logo-blog {
        background-size: 22px;
        height: 48px;
        width: 48px; }
      .articles--feed .table-display .mid-tabs .category {
        text-transform: uppercase;
        font-size: 9px;
        font-weight: bold;
        letter-spacing: 1px;
        text-align: left;
        color: #b3b9c7; }
      .articles--feed .table-display .mid-tabs .type {
        font-size: 18px;
        font-weight: bold;
        text-align: left;
        color: #4a4a4a;
        letter-spacing: 1px;
        padding: 7px 0; }
      .articles--feed .table-display .mid-tabs .date-blog {
        font-size: 8px;
        letter-spacing: 0.6px;
        text-align: left;
        color: #485460; }
      .articles--feed .table-display .mid-tabs.ct-logo {
        width: 60px; }
  .articles--feed .slick-track {
    padding: 25px 0; }
  .articles--feed .section--title {
    font-family: Open Sans,sans-serif;
    color: #fff;
    text-transform: uppercase;
    font-size: 1em;
    margin: 30px 0 20px;
    letter-spacing: 1.9px;
    width: 100%;
    position: relative;
    z-index: 5; }
  .articles--feed .line--title {
    width: 50px;
    height: 4px;
    background: #2c822e;
    margin: 0 auto;
    margin-bottom: 20px;
    position: relative;
    z-index: 5; }
  .articles--feed .bg {
    width: 100%;
    height: 550px;
    position: absolute;
    background: #48af4a;
    left: 0;
    margin-top: 0px; }
  .articles--feed .slick-slider {
    overflow: hidden;
    padding-bottom: 130px; }
  .articles--feed .slick-list {
    overflow: visible; }
  .articles--feed .slick-current {
    /*.blog-card-img
		{
			transition: all 0.5s;
			transform: scale(1.05);
		}*/ }
    .articles--feed .slick-current .content {
      transform: scaleY(1.1);
      box-shadow: 0 2px 29px 0 rgba(0, 0, 0, 0.19);
      transition: all 0.5s; }
  .articles--feed .slick-slide {
    outline: none; }
  .articles--feed .desktop--nav .slick-arrow {
    border: 3px solid #fff;
    border-radius: 100%; }
  .articles--feed .custom-nav {
    top: 50%;
    left: 0;
    position: absolute;
    width: 100%; }
    .articles--feed .custom-nav .slick-arrow {
      display: inline-block;
      position: absolute;
      cursor: pointer;
      padding: 10px; }
    .articles--feed .custom-nav .slick-arrow.slick-disabled {
      opacity: 0.41;
      cursor: default; }
    .articles--feed .custom-nav #feed-prev--desktop,
    .articles--feed .custom-nav #feed-prev--mobile {
      left: 20px; }
    .articles--feed .custom-nav #feed-prev--desktop {
      transform: rotateY(180deg); }
    .articles--feed .custom-nav #feed-next--desktop,
    .articles--feed .custom-nav #feed-next--mobile {
      right: 20px; }
  .articles--feed .custom-nav.mobile--nav {
    display: none;
    bottom: 30px;
    top: auto;
    position: absolute;
    height: 60px; }
  .articles--feed .content:hover .read--more:not(.blog--more) {
    opacity: 1; }
  .articles--feed .content {
    margin: 0 1.25em 0;
    transition: all .3s ease-in-out;
    overflow: hidden;
    position: relative; }
    .articles--feed .content .blog--more {
      z-index: 4;
      position: absolute;
      left: 0;
      padding: 15px;
      bottom: 0; }
      .articles--feed .content .blog--more a.blog-anchor {
        color: #48af4a;
        text-transform: uppercase;
        font-weight: 500;
        display: block; }
      .articles--feed .content .blog--more a.blog-anchor:after {
        content: "";
        position: absolute;
        width: 20px;
        height: 20px;
        background-size: 15px;
        background-image: url(../img/about/shape-arrow.svg);
        margin-left: 12px;
        background-repeat: no-repeat; }
    .articles--feed .content .read--more:not(.blog--more) {
      background: #48af4a;
      z-index: 4;
      margin-top: -40px;
      width: 85%;
      max-width: 270px;
      opacity: 0;
      -webkit-transition: opacity .3s ease-in-out;
      -moz-transition: opacity .3s ease-in-out;
      transition: opacity .3s ease-in-out;
      border-radius: 5px;
      position: absolute;
      left: 0;
      right: 0;
      margin: -10% auto; }
      .articles--feed .content .read--more:not(.blog--more) a:not(.blog-anchor) {
        color: #fff;
        text-transform: uppercase;
        font-weight: 500;
        display: block;
        padding: 30px 0px; }
      .articles--feed .content .read--more:not(.blog--more) a:not(.blog-anchor):after {
        content: url(https://d3naj63yelf4gk.cloudfront.net/dist/img/header/arrow.png);
        position: relative;
        left: 10px; }
    .articles--feed .content .blog-card-img {
      transition: all 0.5s;
      height: 300px;
      background-size: cover;
      background-position: center center;
      background-color: #333; }
      @media screen and (max-width: 767px) {
        .articles--feed .content .blog-card-img {
          height: 200px; } }
    .articles--feed .content .blog-title-index h4 a {
      letter-spacing: 1px;
      color: #4a4a4a; }
      @media screen and (max-width: 767px) {
        .articles--feed .content .blog-title-index h4 a {
          font-size: 16px !important; } }
      @media screen and (min-width: 768px) {
        .articles--feed .content .blog-title-index h4 a {
          font-size: 22px !important; } }
    .articles--feed .content .blog-title-index h4 a:hover {
      color: #48af4a; }
    .articles--feed .content .blog-card-title {
      position: relative;
      text-align: left;
      padding: 15px;
      color: black;
      background: white;
      font-family: "Open Sans", Helvetica, sans-serif;
      font-weight: 300;
      line-height: 1.42;
      letter-spacing: 2.2px;
      height: 175px; }
      .articles--feed .content .blog-card-title h4 {
        margin: 0px;
        font-family: "Open Sans", Helvetica, sans-serif;
        font-weight: 300;
        letter-spacing: 1px; }
        .articles--feed .content .blog-card-title h4 a {
          color: #000;
          font-size: .875em;
          line-height: normal; }
          @media screen and (max-width: 35.5em) {
            .articles--feed .content .blog-card-title h4 a {
              font-size: 12px; } }
      .articles--feed .content .blog-card-title p {
        font-size: 0.65em;
        font-family: "Open Sans", Helvetica, sans-serif;
        font-weight: 700;
        line-height: 1.0;
        letter-spacing: 1.5px;
        color: #c3c9d7;
        text-transform: uppercase; }
      @media screen and (max-width: 767px) {
        .articles--feed .content .blog-card-title {
          height: 170px; } }
    .articles--feed .content .one {
      border-bottom: 6px solid #d0021b; }
    .articles--feed .content .two {
      border-bottom: 6px solid #98ce00; }
    .articles--feed .content .three {
      border-bottom: 6px solid #ffcd2e; }
    .articles--feed .content .four {
      border-bottom: 6px solid #2c822e; }
    .articles--feed .content .five {
      border-bottom: 6px solid #0bb2ee; }
    .articles--feed .content .six {
      border-bottom: 6px solid #e77bcc; }
    @media screen and (max-width: 767px) {
      .articles--feed .content {
        padding: 0px;
        margin: 0 7px !important; } }

#onboarding-head .title .content .text h2 {
  font-weight: 300 !important; }

#onboarding-head .title .content {
  border-radius: 0px 0px 0px 20px; }

[data-service*="gojek"] .blog-card-title:not(.blog-title-index) {
  border-bottom: 6px solid #48af4a; }

[data-service*="gojek"] .logo-blog {
  background: #ffffff url(https://bilba.go-jek.com/images/product-brand/gojek.svg) no-repeat center; }

[data-service*="gojek"] .table-display {
  border-top: 4px solid #48af4a; }

[data-service*="corporate"] .blog-card-title:not(.blog-title-index) {
  border-bottom: 6px solid #48af4a; }

[data-service*="corporate"] .logo-blog {
  background: #ffffff url(https://bilba.go-jek.com/images/product-brand/gojek.svg) no-repeat center; }

[data-service*="corporate"] .table-display {
  border-top: 4px solid #48af4a; }

[data-service*="corporate"].post-item .sub-logo .table-display {
  border-top: none !important; }

[data-service*="gocar"] .blog-card-title:not(.blog-title-index) {
  border-bottom: 6px solid #17619D; }

[data-service*="gocar"] .logo-blog {
  background: #ffffff url("https://bilba.go-jek.com/images/product-brand/gocar.svg") no-repeat center; }

[data-service*="gocar"] .table-display {
  border-top: 4px solid #17619D; }

[data-service*="gotix"] .blog-card-title:not(.blog-title-index) {
  border-bottom: 6px solid #EA6B25; }

[data-service*="gotix"] .table-display {
  border-top: 4px solid #EA6B25; }

[data-service*="gotix"] .logo-blog {
  background: #ffffff url(https://bilba.go-jek.com/images/product-brand/gotix.svg) no-repeat center; }

[data-service*="gofood"] .blog-card-title:not(.blog-title-index) {
  border-bottom: 6px solid #B62025; }

[data-service*="gofood"] .table-display {
  border-top: 4px solid #B62025; }

[data-service*="gofood"] .logo-blog {
  background: #ffffff url("https://bilba.go-jek.com/images/product-brand/gofood.svg") no-repeat center; }

[data-service*="gopay"] .blog-card-title:not(.blog-title-index) {
  border-bottom: 6px solid #5CA5DA; }

[data-service*="gopay"] .table-display {
  border-top: 4px solid #5CA5DA; }

[data-service*="gopay"] .logo-blog {
  background: #ffffff url("https://bilba.go-jek.com/images/product-brand/gopay.svg") no-repeat center; }

[data-service*="goride"] .blog-card-title:not(.blog-title-index) {
  border-bottom: 6px solid #5CA5DA; }

[data-service*="goride"] .table-display {
  border-top: 4px solid #F9AE3C; }

[data-service*="goride"] .logo-blog {
  background: #ffffff url("https://bilba.go-jek.com/images/product-brand/goride.svg") no-repeat center; }

[data-service*="gosend"] .blog-card-title:not(.blog-title-index) {
  border-bottom: 6px solid #8BC440; }

[data-service*="gosend"] .table-display {
  border-top: 4px solid #8BC440; }

[data-service*="gosend"] .logo-blog {
  background: #ffffff url("https://bilba.go-jek.com/images/product-brand/gosend.svg") no-repeat center; }

[data-service*="gomart"] .blog-card-title:not(.blog-title-index) {
  border-bottom: 6px solid #3C95D1; }

[data-service*="gomart"] .table-display {
  border-top: 4px solid #3C95D1; }

[data-service*="gomart"] .logo-blog {
  background: #ffffff url("https://bilba.go-jek.com/images/product-brand/gomart.svg") no-repeat center; }

[data-service*="gobox"] .blog-card-title:not(.blog-title-index) {
  border-bottom: 6px solid #A1652F; }

[data-service*="gobox"] .table-display {
  border-top: 4px solid #A1652F; }

[data-service*="gobox"] .logo-blog {
  background: #ffffff url("https://bilba.go-jek.com/images/product-brand/gobox.svg") no-repeat center; }

[data-service*="gopoint"] .blog-card-title:not(.blog-title-index) {
  border-bottom: 6px solid #F79520; }

[data-service*="gopoint"] .table-display {
  border-top: 4px solid #F79520; }

[data-service*="gopoint"] .logo-blog {
  background: #ffffff url("https://bilba.go-jek.com/images/product-brand/gopoint.svg") no-repeat center; }

[data-service*="gopulsa"] .blog-card-title:not(.blog-title-index) {
  border-bottom: 6px solid #22AF6E; }

[data-service*="gopulsa"] .table-display {
  border-top: 4px solid #22AF6E; }

[data-service*="gopulsa"] .logo-blog {
  background: #ffffff url("https://bilba.go-jek.com/images/product-brand/gopulsa.svg") no-repeat center; }

[data-service*="gobusway"] .blog-card-title:not(.blog-title-index) {
  border-bottom: 6px solid #EF7921; }

[data-service*="gobusway"] .table-display {
  border-top: 4px solid #EF7921; }

[data-service*="gobusway"] .logo-blog {
  background: #ffffff url("https://bilba.go-jek.com/images/product-brand/gobusway.svg") no-repeat center; }

[data-service*="gomed"] .blog-card-title:not(.blog-title-index) {
  border-bottom: 6px solid #006738; }

[data-service*="gomed"] .table-display {
  border-top: 4px solid #006738; }

[data-service*="gomed"] .logo-blog {
  background: #ffffff url("https://bilba.go-jek.com/images/product-brand/gomed.svg") no-repeat center; }

[data-service*="gomassage"] .blog-card-title:not(.blog-title-index) {
  border-bottom: 6px solid #18AEAB; }

[data-service*="gomassage"] .table-display {
  border-top: 4px solid #18AEAB; }

[data-service*="gomassage"] .logo-blog {
  background: #ffffff url("https://bilba.go-jek.com/images/product-brand/gomassage.svg") no-repeat center; }

[data-service*="goclean"] .blog-card-title:not(.blog-title-index) {
  border-bottom: 6px solid #2197D4; }

[data-service*="goclean"] .table-display {
  border-top: 4px solid #2197D4; }

[data-service*="goclean"] .logo-blog {
  background: #ffffff url("https://bilba.go-jek.com/images/product-brand/goclean.svg") no-repeat center; }

[data-service*="goauto"] .blog-card-title:not(.blog-title-index) {
  border-bottom: 6px solid #BABCBE; }

[data-service*="goauto"] .table-display {
  border-top: 4px solid #2197D4; }

[data-service*="goauto"] .logo-blog {
  background: #ffffff url("https://bilba.go-jek.com/images/product-brand/goauto.svg") no-repeat center; }

[data-service*="goglam"] .blog-card-title:not(.blog-title-index) {
  border-bottom: 6px solid #E67498; }

[data-service*="goglam"] .table-display {
  border-top: 4px solid #E67498; }

[data-service*="goglam"] .logo-blog {
  background: #ffffff url("https://bilba.go-jek.com/images/product-brand/goglam.svg") no-repeat center; }

[data-service*="golife"] .blog-card-title:not(.blog-title-index) {
  border-bottom: 6px solid #49af4b; }

[data-service*="golife"] .table-display {
  border-top: 4px solid #49af4b; }

[data-service*="golife"] .logo-blog {
  background: #ffffff url("https://bilba.go-jek.com/images/product-brand/golife.svg") no-repeat center; }

[data-service*="gobills"] .blog-card-title:not(.blog-title-index) {
  border-bottom: 6px solid #4285a7; }

[data-service*="gobills"] .table-display {
  border-top: 4px solid #4285a7; }

[data-service*="gobills"] .logo-blog {
  background: #ffffff url("https://bilba.go-jek.com/images/product-brand/gobills.svg") no-repeat center; }

[data-service*="gopertamina"] .blog-card-title:not(.blog-title-index) {
  border-bottom: 6px solid #D0112B; }

[data-service*="gopertamina"] .table-display {
  border-top: 4px solid #D0112B; }

[data-service*="gopertamina"] .logo-blog {
  background: #ffffff url("https://bilba.go-jek.com/images/product-brand/gopertamina.svg") no-repeat center; }

[data-service*="godaily"] .blog-card-title:not(.blog-title-index) {
  border-bottom: 6px solid #007CB6; }

[data-service*="godaily"] .table-display {
  border-top: 4px solid #007CB6; }

[data-service*="godaily"] .logo-blog {
  background: #ffffff url("https://bilba.go-jek.com/images/product-brand/godaily.svg") no-repeat center; }

[data-service*="gofix"] .blog-card-title:not(.blog-title-index) {
  border-bottom: 6px solid #FFCE1D; }

[data-service*="gofix"] .table-display {
  border-top: 4px solid #FFCE1D; }

[data-service*="gofix"] .logo-blog {
  background: #ffffff url("https://bilba.go-jek.com/images/product-brand/gofix.svg") no-repeat center; }

[data-service*="golaundry"] .blog-card-title:not(.blog-title-index) {
  border-bottom: 6px solid #75CDDC; }

[data-service*="golaundry"] .table-display {
  border-top: 4px solid #75CDDC; }

[data-service*="golaundry"] .logo-blog {
  background: #ffffff url("https://bilba.go-jek.com/images/product-brand/golaundry.svg") no-repeat center; }

@media screen and (max-width: 768px) {
  .articles--feed .container {
    width: 680px; } }

@media screen and (max-width: 35.5em) {
  .articles--feed {
    padding: 0; }
    .articles--feed .container {
      padding: 0;
      width: 100% !important;
      margin: 0px; }
    .articles--feed .custom-nav.desktop--nav {
      display: none; }
    .articles--feed .custom-nav.mobile--nav {
      display: block; }
    .articles--feed .bg {
      height: 620px; }
    .articles--feed .custom-nav #feed-prev--mobile {
      left: 3.5em; }
    .articles--feed .custom-nav #feed-next--mobile {
      right: 3.5em; }
    .articles--feed .content {
      margin: 0 0.5em 5em; } }

.blog-list {
  padding-top: 82px;
  background: #f8f8f8;
  margin-bottom: 80px;
  /*[class*='pure-u'] {
		padding: 0 10px;
	}*/
  /* header color */ }
  @media only screen and (max-width: 768px) {
    .blog-list {
      padding-top: 60px; } }
  .blog-list .section-content .content {
    border-color: #48af4a; }
  .blog-list .video-container iframe {
    width: 70%;
    height: 375px;
    left: 50%;
    position: relative;
    transform: translateX(-50%); }
  @media only screen and (max-width: 414px) {
    .blog-list .video-container iframe {
      width: 100%;
      height: 250px; } }
  .blog-list .word-break {
    word-break: break-all; }
  .blog-list .load--more {
    position: relative;
    margin: 30px auto 0;
    padding: 12px 20px;
    color: #ffffff;
    font-weight: 300;
    font-size: 14px;
    font-family: "Open Sans", Helvetica, sans-serif;
    background-color: #48af4a;
    cursor: pointer;
    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
    border-radius: 5px;
    width: 30%; }
    .blog-list .load--more p {
      text-transform: uppercase;
      text-align: center;
      font-weight: 300;
      margin: 0px; }
  .blog-list .load--more:hover {
    background: #48af4a;
    color: #fff; }
  .blog-list .sidebar--link {
    display: block;
    margin-bottom: 20px; }
  .blog-list .active--fixed {
    position: fixed;
    width: 20%; }
  .blog-list #menu--list {
    padding: 0;
    margin: 0 auto;
    border: 0;
    width: 300px;
    border-radius: 0;
    overflow: hidden;
    background: #fff;
    position: relative;
    top: 25px;
    display: none;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.13); }
    .blog-list #menu--list .bg--select {
      width: 50px;
      height: 100%;
      background: #48af4a url("https://d3naj63yelf4gk.cloudfront.net/dist/img/select-more.svg") no-repeat 55% 50%;
      position: absolute;
      right: 0;
      z-index: 1;
      background-size: 13px; }
    .blog-list #menu--list select {
      padding: 15px;
      width: 105%;
      border: none;
      box-shadow: none;
      background-color: transparent;
      background-image: none;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      position: relative;
      z-index: 5; }
    .blog-list #menu--list select:focus {
      outline: none; }
  .blog-list .media-center {
    width: 80%;
    margin: 0 auto; }
    @media only screen and (max-width: 768px) {
      .blog-list .media-center {
        width: 90%; } }
  .blog-list #blog--listing {
    padding: 0 30px;
    position: relative; }
    .blog-list #blog--listing.active--filter .post-item {
      -webkit-filter: blur(3px);
      filter: blur(3px); }
    .blog-list #blog--listing.active--filter:before {
      position: absolute;
      padding-top: 35px;
      width: 97%;
      height: 100%;
      color: #fff;
      z-index: 5;
      text-align: center; }
    .blog-list #blog--listing > p.no--results {
      text-align: center; }
    @media only screen and (max-width: 767px) {
      .blog-list #blog--listing {
        padding: 0px; } }
  .blog-list .post-item {
    background: #fff;
    padding: 30px;
    border-top: 6px solid;
    margin-bottom: 30px;
    border-radius: 0px 0px 20px 20px; }
    .blog-list .post-item .service--logo {
      width: 45px;
      height: 45px;
      display: inline-block;
      background-size: 25px !important; }
    .blog-list .post-item .pre--title {
      font-family: "Open Sans", Helvetica, sans-serif;
      display: inline-block;
      padding-left: 15px; }
      .blog-list .post-item .pre--title .post--type {
        font-size: 9px;
        font-weight: bold;
        letter-spacing: 1px;
        color: #c3c9d7;
        margin: 0;
        text-transform: uppercase; }
      .blog-list .post-item .pre--title .service {
        font-size: 18px;
        font-weight: bold;
        line-height: 1.0;
        color: #343b41;
        margin: 0px;
        text-transform: uppercase; }
      .blog-list .post-item .pre--title .time {
        font-size: 8px;
        letter-spacing: 0.5px;
        color: #485460;
        margin: 0; }
    .blog-list .post-item .post--title {
      margin: 23px 0 25px; }
      .blog-list .post-item .post--title a {
        color: #343b41;
        font-family: "Open Sans", Helvetica, sans-serif;
        font-size: 24px;
        font-weight: 500;
        line-height: 1.42;
        letter-spacing: 1.3px; }
    .blog-list .post-item .post--image {
      width: 100%;
      height: 100%; }
    .blog-list .post-item .post--abstract {
      font-family: 'Open Sans', sans-serif;
      font-size: 18px;
      font-weight: 300;
      line-height: 1.56;
      letter-spacing: 0.8px;
      color: #485460;
      margin: 20px 0; }
    .blog-list .post-item .read--more {
      font-family: "Open Sans", Helvetica, sans-serif;
      display: block;
      width: 100%;
      text-transform: uppercase;
      font-weight: 500;
      color: #91c64f;
      font-size: 14px;
      letter-spacing: 1px; }
      .blog-list .post-item .read--more:after {
        content: "";
        position: relative;
        left: 10px;
        top: 8px;
        background: url(https://d3naj63yelf4gk.cloudfront.net/dist/img/about/shape-arrow.svg) no-repeat;
        background-size: contain;
        width: 20px;
        height: 20px;
        display: inline-block; }
    .blog-list .post-item .post--share {
      margin: auto;
      height: 45px; }
      .blog-list .post-item .post--share .share--on {
        display: inline-block;
        background: #e8ebf2;
        color: #b3b9c7;
        padding: 10px 20px;
        font-family: 'Open Sans', sans-serif;
        font-weight: 700;
        height: 100%;
        float: left; }
        .blog-list .post-item .post--share .share--on span {
          line-height: 1.56;
          letter-spacing: .8px; }
        .blog-list .post-item .post--share .share--on a {
          display: block !important; }
      .blog-list .post-item .post--share .socmed {
        display: block !important;
        padding: 14.5px 20px;
        color: #fff;
        font-weight: 700;
        text-align: center;
        position: relative;
        width: 50px;
        float: left; }
      .blog-list .post-item .post--share .fa-facebook {
        background: #3b5998; }
      .blog-list .post-item .post--share .fa-twitter {
        background: #55acee; }
      .blog-list .post-item .post--share .fa-instagram {
        background: #cd486b; }
    .blog-list .post-item ul, .blog-list .post-item ol {
      font-size: 18px;
      line-height: 30px; }
  .blog-list .post-item[data-service*="go-ride"] {
    border-color: #F9AE3C; }
    .blog-list .post-item[data-service*="go-ride"] .service--logo {
      background: #ffffff url("https://bilba.go-jek.com/images/product-brand/goride.svg") no-repeat center; }
  .blog-list .post-item[data-service*="go-food"] {
    border-color: #B62025; }
    .blog-list .post-item[data-service*="go-food"] .service--logo {
      background: #ffffff url("https://bilba.go-jek.com/images/product-brand/gofood.svg") no-repeat center; }
  .blog-list .post-item[data-service*="go-pay"] {
    border-color: #5CA5DA; }
    .blog-list .post-item[data-service*="go-pay"] .service--logo {
      background: #ffffff url("https://bilba.go-jek.com/images/product-brand/gopay.svg") no-repeat center; }
  .blog-list .post-item[data-service*="go-tix"] {
    border-color: #EA6B25; }
    .blog-list .post-item[data-service*="go-tix"] .service--logo {
      background: #ffffff url(https://bilba.go-jek.com/images/product-brand/gotix.svg) no-repeat center; }
  .blog-list .post-item[data-service*="go-jek"] {
    border-color: #48af4a; }
    .blog-list .post-item[data-service*="go-jek"] .service--logo {
      background: #ffffff url(https://bilba.go-jek.com/images/product-brand/gojek.svg) no-repeat center; }
  .blog-list .post-item[data-service*="corporate"] {
    border-color: #48af4a; }
    .blog-list .post-item[data-service*="corporate"] .service--logo {
      background: #ffffff url(https://bilba.go-jek.com/images/product-brand/gojek.svg) no-repeat center; }
  .blog-list .post-item[data-service*="go-send"] {
    border-color: #8BC440; }
    .blog-list .post-item[data-service*="go-send"] .service--logo {
      background: #ffffff url("https://bilba.go-jek.com/images/product-brand/gosend.svg") no-repeat center; }
  .blog-list .post-item[data-service*="go-mart"] {
    border-color: #3C95D1; }
    .blog-list .post-item[data-service*="go-mart"] .service--logo {
      background: #ffffff url("https://bilba.go-jek.com/images/product-brand/gomart.svg") no-repeat center; }
  .blog-list .post-item[data-service*="go-car"] {
    border-color: #17619D; }
    .blog-list .post-item[data-service*="go-car"] .service--logo {
      background: #ffffff url("https://bilba.go-jek.com/images/product-brand/gocar.svg") no-repeat center; }
  .blog-list .post-item[data-service*="go-box"] {
    border-color: #A1652F; }
    .blog-list .post-item[data-service*="go-box"] .service--logo {
      background: #ffffff url("https://bilba.go-jek.com/images/product-brand/gobox.svg") no-repeat center; }
  .blog-list .post-item[data-service*="go-point"] {
    border-color: #F79520; }
    .blog-list .post-item[data-service*="go-point"] .service--logo {
      background: #ffffff url("https://bilba.go-jek.com/images/product-brand/gopoint.svg") no-repeat center; }
  .blog-list .post-item[data-service*="go-pulsa"] {
    border-color: #22AF6E; }
    .blog-list .post-item[data-service*="go-pulsa"] .service--logo {
      background: #ffffff url("https://bilba.go-jek.com/images/product-brand/gopulsa.svg") no-repeat center; }
  .blog-list .post-item[data-service*="go-busway"] {
    border-color: #EF7921; }
    .blog-list .post-item[data-service*="go-busway"] .service--logo {
      background: #ffffff url("https://bilba.go-jek.com/images/product-brand/gobusway.svg") no-repeat center; }
  .blog-list .post-item[data-service*="go-med"] {
    border-color: #006738; }
    .blog-list .post-item[data-service*="go-med"] .service--logo {
      background: #ffffff url("https://bilba.go-jek.com/images/product-brand/gomed.svg") no-repeat center; }
  .blog-list .post-item[data-service*="go-massage"] {
    border-color: #18AEAB; }
    .blog-list .post-item[data-service*="go-massage"] .service--logo {
      background: #ffffff url("https://bilba.go-jek.com/images/product-brand/gomassage.svg") no-repeat center; }
  .blog-list .post-item[data-service*="go-clean"] {
    border-color: #2197D4; }
    .blog-list .post-item[data-service*="go-clean"] .service--logo {
      background: #ffffff url("https://bilba.go-jek.com/images/product-brand/goclean.svg") no-repeat center; }
  .blog-list .post-item[data-service*="go-auto"] {
    border-color: #BABCBE; }
    .blog-list .post-item[data-service*="go-auto"] .service--logo {
      background: #ffffff url("https://bilba.go-jek.com/images/product-brand/goauto.svg") no-repeat center; }
  .blog-list .post-item[data-service*="go-glam"] {
    border-color: #E67498; }
    .blog-list .post-item[data-service*="go-glam"] .service--logo {
      background: #ffffff url("https://bilba.go-jek.com/images/product-brand/goglam.svg") no-repeat center; }
  .blog-list .post-item[data-service*="go-life"] {
    border-color: #49af4b; }
    .blog-list .post-item[data-service*="go-life"] .service--logo {
      background: #ffffff url("https://bilba.go-jek.com/images/product-brand/golife.svg") no-repeat center; }
  .blog-list .post-item[data-service*="go-bills"] {
    border-color: #4285a7; }
    .blog-list .post-item[data-service*="go-bills"] .service--logo {
      background: #ffffff url("https://bilba.go-jek.com/images/product-brand/gobills.svg") no-repeat center; }
  .blog-list .post-item[data-service*="go-pertamina"] {
    border-color: #D0112B; }
    .blog-list .post-item[data-service*="go-pertamina"] .service--logo {
      background: #ffffff url("https://bilba.go-jek.com/images/product-brand/gopertamina.svg") no-repeat center; }
  .blog-list .post-item[data-service*="go-fix"] {
    border-color: #FFCE1D; }
    .blog-list .post-item[data-service*="go-fix"] .service--logo {
      background: #ffffff url("https://bilba.go-jek.com/images/product-brand/gofix.svg") no-repeat center; }
  .blog-list .post-item[data-service*="go-daily"] {
    border-color: #007CB6; }
    .blog-list .post-item[data-service*="go-daily"] .service--logo {
      background: #ffffff url("https://bilba.go-jek.com/images/product-brand/godaily.svg") no-repeat center; }
  .blog-list .post-item[data-service*="go-laundry"] {
    border-color: #75CDDC; }
    .blog-list .post-item[data-service*="go-laundry"] .service--logo {
      background: #ffffff url("https://bilba.go-jek.com/images/product-brand/golaundry.svg") no-repeat center; }
  .blog-list[data-service*="go-ride"] .header-line {
    background: #F9AE3C; }
  .blog-list[data-service*="go-ride"] .bg--select {
    background-color: #F9AE3C !important; }
  .blog-list[data-service*="go-food"] .header-line {
    background: #B62025; }
  .blog-list[data-service*="go-food"] .bg--select {
    background-color: #B62025 !important; }
  .blog-list[data-service*="go-pay"] .header-line {
    background: #5CA5DA; }
  .blog-list[data-service*="go-pay"] .bg--select {
    background-color: #5CA5DA !important; }
  .blog-list[data-service*="go-tix"] .header-line {
    background: #EA6B25; }
  .blog-list[data-service*="go-tix"] .bg--select {
    background-color: #EA6B25 !important; }
  .blog-list[data-service*="go-jek"] .header-line {
    background: #48af4a; }
  .blog-list[data-service*="go-jek"] .bg--select {
    background-color: #48af4a !important; }
  .blog-list[data-service*="go-send"] .header-line {
    background: #8BC440; }
  .blog-list[data-service*="go-send"] .bg--select {
    background-color: #8BC440 !important; }
  .blog-list[data-service*="go-mart"] .header-line {
    background: #3C95D1; }
  .blog-list[data-service*="go-mart"] .bg--select {
    background-color: #3C95D1 !important; }
  .blog-list[data-service*="go-car"] .header-line {
    background: #17619D; }
  .blog-list[data-service*="go-car"] .bg--select {
    background-color: #17619D !important; }
  .blog-list[data-service*="go-box"] .header-line {
    background: #A1652F; }
  .blog-list[data-service*="go-box"] .bg--select {
    background-color: #A1652F !important; }
  .blog-list[data-service*="go-point"] .header-line {
    background: #F79520; }
  .blog-list[data-service*="go-point"] .bg--select {
    background-color: #F79520 !important; }
  .blog-list[data-service*="go-pulsa"] .header-line {
    background: #22AF6E; }
  .blog-list[data-service*="go-pulsa"] .bg--select {
    background-color: #22AF6E !important; }
  .blog-list[data-service*="go-busway"] .header-line {
    background: #EF7921; }
  .blog-list[data-service*="go-busway"] .bg--select {
    background-color: #EF7921 !important; }
  .blog-list[data-service*="go-med"] .header-line {
    background: #006738; }
  .blog-list[data-service*="go-med"] .bg--select {
    background-color: #006738 !important; }
  .blog-list[data-service*="go-massage"] .header-line {
    background: #18AEAB; }
  .blog-list[data-service*="go-massage"] .bg--select {
    background-color: #18AEAB !important; }
  .blog-list[data-service*="go-clean"] .header-line {
    background: #2197D4; }
  .blog-list[data-service*="go-clean"] .bg--select {
    background-color: #2197D4 !important; }
  .blog-list[data-service*="go-auto"] .header-line {
    background: #BABCBE; }
  .blog-list[data-service*="go-auto"] .bg--select {
    background-color: #BABCBE !important; }
  .blog-list[data-service*="go-glam"] .header-line {
    background: #E67498; }
  .blog-list[data-service*="go-glam"] .bg--select {
    background-color: #E67498 !important; }
  .blog-list[data-service*="go-pertamina"] .header-line {
    background: #D0112B; }
  .blog-list[data-service*="go-pertamina"] .bg--select {
    background-color: #D0112B !important; }
  .blog-list[data-service*="go-fix"] .header-line {
    background: #FFCE1D; }
  .blog-list[data-service*="go-fix"] .bg--select {
    background-color: #FFCE1D !important; }
  .blog-list[data-service*="go-daily"] .header-line {
    background: #007CB6; }
  .blog-list[data-service*="go-daily"] .bg--select {
    background-color: #007CB6 !important; }
  .blog-list[data-service*="go-laundry"] .header-line {
    background: #75CDDC; }
  .blog-list[data-service*="go-laundry"] .bg--select {
    background-color: #75CDDC !important; }
  .blog-list .top--line {
    background: #48af4a;
    height: 6px;
    width: 100%;
    position: sticky;
    top: 100px; }
  .blog-list .filter--blog {
    background: #fff;
    padding: 30px;
    font-family: 'Open Sans', sans-serif;
    margin-bottom: 30px;
    position: sticky;
    top: 105px; }
    .blog-list .filter--blog .filter--title {
      text-transform: uppercase;
      font-size: 18px;
      color: #48af4a;
      position: relative;
      margin: 0px;
      line-height: normal; }
    .blog-list .filter--blog .filter--label {
      font-size: 14px;
      line-height: 1.71;
      letter-spacing: 1.2px;
      color: #343b41;
      text-transform: uppercase;
      display: block;
      padding: 20px 0; }
    .blog-list .filter--blog [data-all*="services"]:before {
      content: url(c);
      position: absolute;
      margin-top: 17px;
      margin-left: 12px; }
    .blog-list .filter--blog .select--wrapper {
      padding: 0;
      margin: 0;
      border: 1px solid #ccc;
      width: 100%;
      border-radius: 3px;
      overflow: hidden;
      background-color: #fff;
      background: #fff url("https://d3naj63yelf4gk.cloudfront.net/dist/img/arrow-select.svg") no-repeat 90% 50%; }
      .blog-list .filter--blog .select--wrapper #services {
        padding-left: 45px; }
      .blog-list .filter--blog .select--wrapper select {
        padding: 15px;
        width: 110%;
        border: none;
        box-shadow: none;
        background-color: transparent;
        background-image: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none; }
      .blog-list .filter--blog .select--wrapper select:focus {
        outline: none; }
    @media only screen and (max-width: 768px) {
      .blog-list .filter--blog {
        display: none; } }
  .blog-list .header-line {
    background: #48af4a;
    height: 50px;
    margin-bottom: 70px; }
    .blog-list .header-line .media-nav {
      width: 30% !important;
      display: block !important; }
      @media only screen and (max-width: 768px) {
        .blog-list .header-line .media-nav {
          width: 80% !important; } }
    .blog-list .header-line .media-nav ul li {
      width: 100%;
      text-align: center; }
      .blog-list .header-line .media-nav ul li > a {
        pointer-events: none; }
    .blog-list .header-line .blog-nav {
      background-color: #fcfcfe;
      box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.13);
      width: 981px;
      margin: 0 auto;
      position: relative;
      width: 980px;
      top: 15px; }
    .blog-list .header-line .cat-list {
      list-style: none;
      padding: 0;
      margin: 0; }
      .blog-list .header-line .cat-list li::first-child {
        border-left: 0 !important; }
      .blog-list .header-line .cat-list li {
        display: inline-block;
        padding: 10px 50px;
        margin: 20px 0;
        border-left: 1px solid #e8ebf2;
        width: 240px; }
        .blog-list .header-line .cat-list li a {
          color: #000;
          display: block;
          text-align: center; }
        .blog-list .header-line .cat-list li a:hover {
          color: #48af4a; }
  .blog-list .special--banner figure {
    margin: 0;
    position: relative; }
    .blog-list .special--banner figure img {
      width: 100%; }
    .blog-list .special--banner figure .overlay {
      position: absolute;
      width: 100%;
      height: 100%;
      background: #000;
      opacity: 0.6;
      top: 0;
      padding: 25px; }
    .blog-list .special--banner figure .text--wrapper {
      position: absolute;
      bottom: 0;
      padding: 30px;
      z-index: 5; }
      .blog-list .special--banner figure .text--wrapper h4 {
        font-size: 24px;
        font-weight: 900;
        line-height: 1.42;
        letter-spacing: 2.2px;
        color: #ffffff; }
      .blog-list .special--banner figure .text--wrapper .read--more a {
        font-family: 'Open Sans', sans-serif;
        font-size: 18px;
        font-weight: bold;
        line-height: 0.8;
        letter-spacing: 3.3px;
        color: #ffffff;
        text-transform: uppercase; }
      .blog-list .special--banner figure .text--wrapper .read--more a:after {
        content: url(https://d3naj63yelf4gk.cloudfront.net/dist/img/header/arrow.png);
        position: relative;
        left: 10px; }
    .blog-list .special--banner figure img.brush {
      position: absolute;
      bottom: 0; }
  @media only screen and (max-width: 767px) {
    .blog-list .special--banner {
      display: none; } }

@media (max-width: 768px) {
  .blog-list .post-item {
    padding: 30px 15px; }
  .blog-list .header-line .blog-nav {
    display: none; }
  .blog-list #menu--list {
    display: block; } }

section#download-cards {
  padding: 5em 0; }
  section#download-cards h4 {
    color: #b3b9c7;
    font-family: "Open Sans", Helvetica, sans-serif;
    text-transform: uppercase; }
  section#download-cards h2 {
    font-family: "Lato", Helvetica, sans-serif;
    font-weight: 300; }
    section#download-cards h2 span {
      font-weight: 400; }
  section#download-cards .bg-right {
    background: #fff;
    padding: 2em;
    position: relative; }
    section#download-cards .bg-right img {
      display: none; }
  section#download-cards .bg-left {
    display: none; }
  section#download-cards .download-list {
    position: absolute;
    bottom: 0;
    right: 0; }
    section#download-cards .download-list .download-btn {
      float: left;
      width: 40px;
      height: 40px;
      cursor: pointer; }
      section#download-cards .download-list .download-btn.ios {
        background: #000 url("../img/card/download/ios.svg") no-repeat center center;
        margin-left: 10px; }
      section#download-cards .download-list .download-btn.google-play {
        background: #7ed321 url("../img/card/download/google-play.svg") no-repeat center center; }
    section#download-cards .download-list p {
      float: left;
      margin-top: 1.8em;
      color: #98ce00;
      font-size: 8px; }
    section#download-cards .download-list svg {
      width: 12px;
      float: left;
      margin-top: 1em;
      margin-left: 10px;
      margin-top: 1em; }
  section#download-cards .content-wrapper {
    background-color: #343b41;
    color: #fff;
    display: inline-block;
    padding: 2em;
    position: relative;
    padding-bottom: 4em; }

@media screen and (min-width: 48em) {
  section#download-cards .content-wrapper {
    margin-left: -6em;
    padding-bottom: 6em; }
  section#download-cards .download-bg {
    position: absolute;
    top: 2em;
    left: -47em;
    z-index: -1; }
    section#download-cards .download-bg img {
      display: block;
      height: 17em; }
  section#download-cards .download-list .download-btn {
    width: 90px;
    height: 90px; }
  section#download-cards .download-list p {
    font-size: 1em;
    margin-top: 2.2em; }
  section#download-cards .download-list svg {
    width: 1.3em;
    margin-top: 2.5em; }
  section#download-cards .bg-left {
    display: inline-block; }
    section#download-cards .bg-left img {
      height: 19em; } }

body {
  background: #f8f8f8; }

#onboarding-head .title {
  background-color: #fcfcfe !important; }

#onboarding-head .bg-img {
  background: -webkit-linear-gradient(rgba(88, 199, 91, 0.6), rgba(88, 199, 91, 0.8)), url(https://d3naj63yelf4gk.cloudfront.net/dist/img/blog/head.jpg) no-repeat !important;
  background: -moz-linear-gradient(rgba(88, 199, 91, 0.6), rgba(88, 199, 91, 0.8)), url(https://d3naj63yelf4gk.cloudfront.net/dist/img/blog/head.jpg) no-repeat !important;
  background: -o-linear-gradient(rgba(88, 199, 91, 0.6), rgba(88, 199, 91, 0.8)), url(https://d3naj63yelf4gk.cloudfront.net/dist/img/blog/head.jpg) no-repeat !important;
  background: linear-gradient(rgba(88, 199, 91, 0.6), rgba(88, 199, 91, 0.8)), url(https://d3naj63yelf4gk.cloudfront.net/dist/img/blog/head.jpg) no-repeat !important;
  background-size: cover !important; }

#content-nav,
#faq-head,
#onboarding-head {
  margin-top: 5.4em; }
  #content-nav .title,
  #faq-head .title,
  #onboarding-head .title {
    background: url("https://d3naj63yelf4gk.cloudfront.net/dist/img/faq/geometry.svg") repeat;
    background-size: cover; }
    #content-nav .title .content,
    #faq-head .title .content,
    #onboarding-head .title .content {
      margin: 5em 5em 8em 5em;
      box-shadow: -1em 1em #48af4a;
      background: white; }
      #content-nav .title .content .text,
      #faq-head .title .content .text,
      #onboarding-head .title .content .text {
        padding: 1.8em; }
        #content-nav .title .content .text h2,
        #faq-head .title .content .text h2,
        #onboarding-head .title .content .text h2 {
          font-family: "Lato", Helvetica, sans-serif;
          font-weight: 700;
          letter-spacing: 1.5px;
          line-height: 1.54; }
        #content-nav .title .content .text p,
        #faq-head .title .content .text p,
        #onboarding-head .title .content .text p {
          font-family: "Open Sans", Helvetica, sans-serif;
          letter-spacing: 1px; }
  #content-nav .bg-img,
  #faq-head .bg-img,
  #onboarding-head .bg-img {
    background: -webkit-linear-gradient(rgba(88, 199, 91, 0.6), rgba(88, 199, 91, 0.8)), url(https://d3naj63yelf4gk.cloudfront.net/dist/img/faq/head.jpg) no-repeat;
    background: -moz-linear-gradient(rgba(88, 199, 91, 0.6), rgba(88, 199, 91, 0.8)), url(https://d3naj63yelf4gk.cloudfront.net/dist/img/faq/head.jpg) no-repeat;
    background: -o-linear-gradient(rgba(88, 199, 91, 0.6), rgba(88, 199, 91, 0.8)), url(https://d3naj63yelf4gk.cloudfront.net/dist/img/faq/head.jpg) no-repeat;
    background: linear-gradient(rgba(88, 199, 91, 0.6), rgba(88, 199, 91, 0.8)), url(https://d3naj63yelf4gk.cloudfront.net/dist/img/faq/head.jpg) no-repeat;
    background-size: cover; }
  #content-nav .faq-nav,
  #content-nav .onboarding-nav,
  #faq-head .faq-nav,
  #faq-head .onboarding-nav,
  #onboarding-head .faq-nav,
  #onboarding-head .onboarding-nav {
    background-color: #fcfcfe;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.13);
    margin: -50px auto 0;
    position: relative;
    width: 980px;
    top: 15px; }
  #content-nav .cat-list,
  #faq-head .cat-list,
  #onboarding-head .cat-list {
    list-style: none;
    padding: 0;
    margin: 0; }
    #content-nav .cat-list li:first-child,
    #faq-head .cat-list li:first-child,
    #onboarding-head .cat-list li:first-child {
      border-left-width: 0px; }
    #content-nav .cat-list li,
    #faq-head .cat-list li,
    #onboarding-head .cat-list li {
      display: inline-block;
      padding: 10px 0;
      margin: 20px 0;
      border-left: 1px solid #e8ebf2;
      width: 240px; }
      #content-nav .cat-list li a,
      #faq-head .cat-list li a,
      #onboarding-head .cat-list li a {
        color: #000;
        display: block;
        text-align: center; }
      #content-nav .cat-list li a:hover,
      #faq-head .cat-list li a:hover,
      #onboarding-head .cat-list li a:hover {
        color: #48af4a; }
      #content-nav .cat-list li .active,
      #faq-head .cat-list li .active,
      #onboarding-head .cat-list li .active {
        font-weight: bold;
        color: #48af4a !important; }
  #content-nav #faq-menu--list,
  #content-nav .select--wrapper,
  #faq-head #faq-menu--list,
  #faq-head .select--wrapper,
  #onboarding-head #faq-menu--list,
  #onboarding-head .select--wrapper {
    padding: 0;
    margin: 0 auto;
    border: 0;
    width: 300px;
    border-radius: 0;
    overflow: hidden;
    background: #fff;
    position: relative;
    top: 25px;
    display: none;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.13); }
    #content-nav #faq-menu--list .bg--select,
    #content-nav .select--wrapper .bg--select,
    #faq-head #faq-menu--list .bg--select,
    #faq-head .select--wrapper .bg--select,
    #onboarding-head #faq-menu--list .bg--select,
    #onboarding-head .select--wrapper .bg--select {
      width: 50px;
      height: 100%;
      background: #48af4a url("https://d3naj63yelf4gk.cloudfront.net/dist/img/select-more.svg") no-repeat 55% 50%;
      position: absolute;
      right: 0;
      z-index: 1;
      background-size: 13px; }
    #content-nav #faq-menu--list select,
    #content-nav .select--wrapper select,
    #faq-head #faq-menu--list select,
    #faq-head .select--wrapper select,
    #onboarding-head #faq-menu--list select,
    #onboarding-head .select--wrapper select {
      padding: 15px;
      width: 105%;
      border: none;
      box-shadow: none;
      background-color: transparent;
      background-image: none;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      position: relative;
      z-index: 5; }
    #content-nav #faq-menu--list select:focus,
    #content-nav .select--wrapper select:focus,
    #faq-head #faq-menu--list select:focus,
    #faq-head .select--wrapper select:focus,
    #onboarding-head #faq-menu--list select:focus,
    #onboarding-head .select--wrapper select:focus {
      outline: none; }

@media screen and (max-width: 35.5em) {
  #onboarding-head {
    background: -webkit-linear-gradient(rgba(88, 199, 91, 0.6), rgba(88, 199, 91, 0.8)), url(https://d3naj63yelf4gk.cloudfront.net/dist/img/blog/head.jpg) no-repeat !important;
    background: -moz-linear-gradient(rgba(88, 199, 91, 0.6), rgba(88, 199, 91, 0.8)), url(https://d3naj63yelf4gk.cloudfront.net/dist/img/blog/head.jpg) no-repeat !important;
    background: -o-linear-gradient(rgba(88, 199, 91, 0.6), rgba(88, 199, 91, 0.8)), url(https://d3naj63yelf4gk.cloudfront.net/dist/img/blog/head.jpg) no-repeat !important;
    background: linear-gradient(rgba(88, 199, 91, 0.6), rgba(88, 199, 91, 0.8)), url(https://d3naj63yelf4gk.cloudfront.net/dist/img/blog/head.jpg) no-repeat !important;
    background-size: cover !important; }
  #faq-head,
  #onboarding-head {
    background: linear-gradient(rgba(88, 199, 91, 0.6), rgba(88, 199, 91, 0.8)), url(https://d3naj63yelf4gk.cloudfront.net/dist/img/faq/head.jpg) no-repeat;
    background-size: cover;
    margin-bottom: 2em; }
    #faq-head #faq-menu--list,
    #faq-head #menu--list,
    #onboarding-head #faq-menu--list,
    #onboarding-head #menu--list {
      display: block; }
    #faq-head .faq-nav,
    #faq-head .onboarding-nav,
    #onboarding-head .faq-nav,
    #onboarding-head .onboarding-nav {
      display: none; }
    #faq-head .title,
    #onboarding-head .title {
      background: none !important; }
      #faq-head .title .content,
      #onboarding-head .title .content {
        box-shadow: none;
        background: none;
        color: white;
        margin: 5em 0; } }

@media screen and (max-width: 48em) {
  #faq-head #faq-menu--list,
  #faq-head #menu--list,
  #onboarding-head #faq-menu--list,
  #onboarding-head #menu--list {
    display: block;
    margin-top: -3em; }
  #faq-head .faq-nav,
  #faq-head .onboarding-nav,
  #onboarding-head .faq-nav,
  #onboarding-head .onboarding-nav {
    display: none; } }

#landing-faq {
  background: #f8f8f8; }
  #landing-faq hr {
    height: 4px;
    width: 50px;
    background: #ffcd2e;
    border: none; }
  #landing-faq .box {
    width: 425px;
    font-family: "Lato", Helvetica, sans-serif;
    font-weight: 300;
    background: white;
    padding: 1em 2em;
    margin-right: 3em; }
    #landing-faq .box a {
      color: #48af4a;
      font-weight: bold; }
    #landing-faq .box a:hover {
      cursor: pointer; }
    #landing-faq .box h4 {
      font-size: 1.5em;
      font-weight: 300; }

@media screen and (max-width: 35.5em) {
  #landing-faq {
    margin-top: -2em; }
    #landing-faq .container .left .box,
    #landing-faq .container .right .box {
      width: auto;
      box-shadow: none;
      padding-bottom: 5em; } }

@media screen and (max-width: 48em) {
  #landing-faq .container {
    background: none;
    padding: 0; }
    #landing-faq .container .warper {
      margin-top: 2em;
      padding-top: 3.5em;
      background: #2c822e; }
    #landing-faq .container .left {
      background: none; }
    #landing-faq .container .top,
    #landing-faq .container .bottom {
      margin-bottom: 7em; }
      #landing-faq .container .top .warper,
      #landing-faq .container .bottom .warper {
        position: relative;
        height: 14em;
        margin-right: 6em; }
      #landing-faq .container .top .box,
      #landing-faq .container .bottom .box {
        margin-top: 0;
        position: absolute;
        margin-bottom: -2em;
        margin-right: -5em; }
    #landing-faq .container .top {
      background: #58c75b; }
    #landing-faq .container .bottom {
      background: #2c822e; }
      #landing-faq .container .bottom .warper {
        background: #58c75b; } }

@media screen and (min-width: 64em) {
  #landing-faq {
    padding: 5em 0; }
    #landing-faq .container {
      background: none; }
      #landing-faq .container .out-warp:first-child {
        margin-bottom: 3em; }
      #landing-faq .container .out-warp {
        background-image: url("https://d3naj63yelf4gk.cloudfront.net/dist/img/faq/geometry.png");
        background-position: bottom right;
        height: 18.25em;
        cursor: pointer; }
        #landing-faq .container .out-warp .top,
        #landing-faq .container .out-warp .bottom {
          margin-bottom: 7em; }
          #landing-faq .container .out-warp .top .warper,
          #landing-faq .container .out-warp .bottom .warper {
            position: relative;
            height: 14em;
            margin-right: 6em; }
          #landing-faq .container .out-warp .top .box,
          #landing-faq .container .out-warp .bottom .box {
            position: absolute;
            margin: 4.6em -3em -2em -1px;
            width: 520px;
            height: 225px; }
        #landing-faq .container .out-warp .top {
          background: #58c75b; }
          #landing-faq .container .out-warp .top .warper {
            background: #2c822e; }
        #landing-faq .container .out-warp .bottom {
          background: #2c822e; }
          #landing-faq .container .out-warp .bottom .warper {
            background: #58c75b; } }

#faq-list-menu {
  margin: 5em 0; }
  #faq-list-menu .box {
    background-image: url("https://d3naj63yelf4gk.cloudfront.net/dist/img/faq/list-geo.png"), linear-gradient(white, white);
    background-position: bottom, top;
    background-repeat: no-repeat, no-repeat;
    background-size: 100%, cover;
    color: #343b41;
    padding: 2.5em 0;
    margin: 0 1.5em 2em;
    box-shadow: 0 3px 15px 0 rgba(0, 0, 0, 0.13), 0px 25px 0px 15px #48af4a;
    text-align: center;
    cursor: pointer; }
    #faq-list-menu .box h4 {
      margin-top: 0;
      color: #343b41;
      font-weight: 500; }
    #faq-list-menu .box hr {
      background: #98ce00;
      border: none;
      width: 50px;
      height: 4px; }
  #faq-list-menu .active {
    background-image: url("https://d3naj63yelf4gk.cloudfront.net/dist/img/faq/list-geo.png"), linear-gradient(#2c822e, #2c822e);
    background-position: bottom, top;
    background-repeat: no-repeat, no-repeat;
    background-size: 100%, cover;
    box-shadow: 0px 25px 0px 15px #48af4a;
    cursor: default;
    pointer-events: none; }
    #faq-list-menu .active h4 {
      color: white; }
  #faq-list-menu .mobile {
    display: none; }
  #faq-list-menu .desktop .content {
    width: 100%;
    padding: 3em 2em;
    text-align: left; }
    #faq-list-menu .desktop .content div {
      text-align: center;
      margin-bottom: 4.5em; }
    #faq-list-menu .desktop .content h4 {
      font-weight: bold;
      letter-spacing: 2.1px;
      margin-bottom: 2em; }
    #faq-list-menu .desktop .content ul {
      list-style: none;
      padding: 0;
      margin-bottom: 2.5em; }
      #faq-list-menu .desktop .content ul li {
        text-overflow: ellipsis;
        overflow: hidden;
        padding: 0;
        margin: 1.5em 0.5em;
        height: 60px;
        line-height: 1.75;
        border-bottom: 1px solid #dce0e9;
        text-align: left; }
        #faq-list-menu .desktop .content ul li a {
          font-family: "Open Sans", Helvetica, sans-serif;
          font-weight: 300;
          letter-spacing: 1.7px;
          color: #272d32; }
        #faq-list-menu .desktop .content ul li a:hover {
          color: #48af4a; }
    #faq-list-menu .desktop .content .see-all {
      font-weight: bold;
      line-height: 0.8;
      letter-spacing: 3.3px;
      font-size: 0.7em;
      color: #48af4a; }

@media screen and (max-width: 48em) {
  #faq-list-menu {
    padding: 1em 0;
    margin: 0;
    background: #f8f8f8; }
    #faq-list-menu .desktop {
      display: none; }
    #faq-list-menu .mobile {
      display: inherit; }
      #faq-list-menu .mobile .slick-track {
        width: 500px;
        margin: 0 auto;
        margin-top: 2em; }
      #faq-list-menu .mobile #faq-list-content {
        outline: none; }
        #faq-list-menu .mobile #faq-list-content .title-content {
          outline: none;
          padding: 1.5em;
          color: #343b41;
          background: white;
          font-family: "Open Sans", Helvetica, sans-serif;
          line-height: 1.33;
          font-weight: 300;
          letter-spacing: 2.5px;
          box-shadow: 0 3px 15px 0 rgba(0, 0, 0, 0.13), 0 25px 0 15px #48af4a;
          margin-bottom: 4.5em; }
          #faq-list-menu .mobile #faq-list-content .title-content span {
            padding: 0.8em 0px;
            color: #98ce00; }
        #faq-list-menu .mobile #faq-list-content .ui-state-active {
          background: #2c822e;
          color: white; }
        #faq-list-menu .mobile #faq-list-content .title-item {
          background: white; }
        #faq-list-menu .mobile #faq-list-content .detail-item {
          outline: none; }
          #faq-list-menu .mobile #faq-list-content .detail-item ul {
            background: white;
            outline: none;
            padding: 1em;
            padding-top: 0px; }
            #faq-list-menu .mobile #faq-list-content .detail-item ul li {
              padding: 1em 0;
              outline: none;
              border-bottom: solid 1px #e8ebf2; }
          #faq-list-menu .mobile #faq-list-content .detail-item .title-item {
            outline: none;
            border-left: 5px solid #98ce00;
            margin: 1em 0;
            padding: 1.5em 1em;
            font-family: "Open Sans", Helvetica, sans-serif;
            font-weight: bold;
            letter-spacing: 2.5px;
            color: #343b41; }
            #faq-list-menu .mobile #faq-list-content .detail-item .title-item span {
              color: #98ce00;
              margin-top: 0.30em; }
          #faq-list-menu .mobile #faq-list-content .detail-item li .all {
            font-family: "Open Sans", Helvetica, sans-serif;
            font-weight: bold;
            letter-spacing: 4px;
            color: #48af4a; }
          #faq-list-menu .mobile #faq-list-content .detail-item li a {
            color: #343b41;
            letter-spacing: 1.5px; }
          #faq-list-menu .mobile #faq-list-content .detail-item li:last-child {
            color: green; }
          #faq-list-menu .mobile #faq-list-content .detail-item .ui-state-active {
            border-color: #48af4a;
            margin: 0; }
            #faq-list-menu .mobile #faq-list-content .detail-item .ui-state-active span {
              color: #48af4a; }
      #faq-list-menu .mobile .ui-accordion-header-icon {
        float: right; }
      #faq-list-menu .mobile ul {
        list-style: none;
        padding: 0;
        margin: 0;
        font-family: "Lato", Helvetica, sans-serif;
        font-weight: 300; }
        #faq-list-menu .mobile ul .title-content {
          font-family: "Lato", Helvetica, sans-serif;
          font-weight: 700;
          margin-bottom: 1em; }
        #faq-list-menu .mobile ul #accordion ul {
          display: inherit; } }

#faq-detail {
  margin-top: 3em;
  margin-bottom: 3em; }
  #faq-detail .banner {
    text-align: center; }
    #faq-detail .banner img {
      margin-left: 1em; }
  #faq-detail .menu {
    padding: 3.5em 0 0 4em;
    background: white;
    position: relative; }
    #faq-detail .menu a {
      color: #b3b9c7; }
    #faq-detail .menu a:hover {
      color: #25c768; }
    #faq-detail .menu p:first-child {
      text-transform: uppercase;
      color: #b3b9c7;
      font-family: "Open Sans", Helvetica, sans-serif;
      font-size: 0.7em;
      font-weight: 700;
      line-height: 1.17;
      letter-spacing: 1.3px;
      margin: 0; }
    #faq-detail .menu h3 {
      font-weight: 300;
      line-height: 1.42;
      letter-spacing: 1.2px; }
    #faq-detail .menu .style:first-of-type {
      background: #343b41;
      top: 3.25em; }
    #faq-detail .menu .style {
      background: #25c768;
      border: none;
      display: inline-block;
      width: 50px;
      height: 4px;
      position: absolute;
      left: 0;
      top: 12.25em; }
    #faq-detail .menu ul {
      list-style: none;
      padding: 0;
      margin: 2em 0; }
      #faq-detail .menu ul li {
        margin: 0.7em 0;
        cursor: pointer; }
        #faq-detail .menu ul li a {
          text-decoration: none;
          color: #343b41;
          font-size: 16px;
          font-family: "Open Sans", Helvetica, sans-serif;
          font-weight: 500;
          line-height: 1.62;
          letter-spacing: 0.5px; }
        #faq-detail .menu ul li a:hover {
          color: #3ca33a; }
        #faq-detail .menu ul li .active {
          color: #3ca33a;
          font-weight: bold; }
  #faq-detail .content {
    padding: 2em;
    background: white; }
    #faq-detail .content p, #faq-detail .content ul {
      font-family: "Open Sans", Helvetica, sans-serif;
      font-size: 14px;
      font-weight: normal;
      line-height: 1.56;
      letter-spacing: 1px;
      color: #485460; }
    #faq-detail .content .title {
      margin-bottom: 2.5em; }
      #faq-detail .content .title h2 {
        font-weight: 300;
        line-height: 1.28;
        letter-spacing: 2px;
        margin-bottom: 0;
        margin-top: 2em; }
    #faq-detail .content .item {
      letter-spacing: 1px; }
      #faq-detail .content .item h4 {
        line-height: 1.56; }
      #faq-detail .content .item ol {
        padding: 0 1em;
        color: #485460;
        font-family: "Open Sans", Helvetica, sans-serif;
        font-weight: 300; }
        #faq-detail .content .item ol li {
          margin: 0.25em 0;
          font-family: "Open Sans", Helvetica, sans-serif;
          font-size: 14px;
          font-weight: normal;
          line-height: 1.75;
          letter-spacing: 1px;
          color: #485460; }
          #faq-detail .content .item ol li h3 {
            font-size: inherit;
            font-weight: 300; }

@media screen and (max-width: 35.5em) {
  #faq-detail {
    padding: 0; }
    #faq-detail .menu .style:first-of-type {
      top: 3.25em !important; }
    #faq-detail .menu .style {
      top: 8.65em !important; }
    #faq-detail .menu ul li {
      margin: 2em 0; }
    #faq-detail .content {
      padding: 3.5em 1.5em; }
      #faq-detail .content h2 {
        font-size: 2em; }
    #faq-detail .banner {
      display: none;
      width: 100%; } }

#faq-social {
  margin-bottom: 3em;
  text-align: center;
  font-family: Lato; }
  #faq-social h3 {
    line-height: 1.28;
    letter-spacing: 1.1px; }
  #faq-social hr {
    height: 4px;
    width: 50px;
    background-color: #ffcd2e;
    border: none; }
  #faq-social p {
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.56;
    letter-spacing: 1px;
    text-align: center; }
    #faq-social p a {
      font-weight: normal;
      color: #4bbf49;
      text-decoration: none; }
  #faq-social .link {
    margin-top: 3em;
    padding: 1em;
    background: #0bb2ee;
    color: white; }

@media screen and (min-width: 48em) {
  #faq-social p {
    margin: 3em auto; } }

@media screen and (min-width: 64em) {
  #faq-social p {
    margin: 2em 15em; } }

#talent-story-golife h4:before {
  background-color: #18aeab !important;
  top: 5.5% !important; }

#talent-story-golife h4 {
  color: #18aeab !important; }

#talent-story-golife .detail {
  padding: 3em;
  margin-top: 0 !important; }

#talent-story-golife a {
  padding: 1.5em 3em;
  background-color: #18aeab;
  color: #fff;
  font-family: "Lato", Helvetica, sans-serif;
  border-radius: 5px;
  right: 25px;
  bottom: -5%;
  position: absolute;
  cursor: pointer; }

#talent-story-golife .big-img {
  height: 100%;
  width: 100%; }

#talent-story-golife .content {
  margin-bottom: 3em; }
  #talent-story-golife .content h4 {
    margin: 0;
    display: inherit !important; }
  #talent-story-golife .content h4:before {
    display: none; }
  #talent-story-golife .content .info p {
    padding: 0; }
  #talent-story-golife .content img {
    object-fit: cover;
    border-radius: 50%;
    overflow: hidden;
    width: 100px;
    height: 100px;
    margin: auto; }

#talent-story,
#talent-story-golife {
  margin: 3em 0;
  position: relative; }
  #talent-story .img-slide img,
  #talent-story-golife .img-slide img {
    min-width: 100%; }
  #talent-story .mobile-text,
  #talent-story-golife .mobile-text {
    display: none; }
  #talent-story .slide-mobile,
  #talent-story-golife .slide-mobile {
    display: none; }
  #talent-story h4:before,
  #talent-story-golife h4:before {
    content: "";
    position: absolute;
    right: 48%;
    top: 2.5%;
    padding-right: 50px;
    height: 4px;
    margin: 0;
    background-color: #f9ae3c;
    z-index: 10; }
  #talent-story h4,
  #talent-story-golife h4 {
    margin-left: 4em;
    color: #48af4a;
    font-family: "Open Sans", Helvetica, sans-serif;
    font-weight: 700; }
  #talent-story hr,
  #talent-story-golife hr {
    position: absolute;
    z-index: 50;
    right: 48%;
    top: 5%;
    width: 50px;
    background: #48af4a;
    border: none;
    height: 4px; }
  #talent-story .detail,
  #talent-story-golife .detail {
    background: white;
    padding: 3em;
    padding-bottom: 2.7em;
    margin-top: 2.6em;
    position: relative; }
    #talent-story .detail .slider .item p,
    #talent-story-golife .detail .slider .item p {
      height: 100%;
      color: #9b9b9b;
      font-weight: 300; }
    #talent-story .detail .slider .item .info p,
    #talent-story-golife .detail .slider .item .info p {
      height: 100%;
      color: #9b9b9b;
      font-weight: 500; }
    #talent-story .detail h2,
    #talent-story-golife .detail h2 {
      font-family: "Lato", Helvetica, sans-serif;
      font-weight: 300;
      line-height: 1.22;
      color: #343b41;
      letter-spacing: 2px;
      margin-top: 0; }
    #talent-story .detail h5,
    #talent-story-golife .detail h5 {
      color: #4a4a4a;
      margin: 0;
      font-family: "Open Sans", Helvetica, sans-serif; }
    #talent-story .detail p,
    #talent-story-golife .detail p {
      font-family: "Open Sans", Helvetica, sans-serif;
      color: #485460;
      padding-right: 3em;
      font-weight: 300;
      line-height: 1.62;
      letter-spacing: 1.5px;
      margin: 0; }
    #talent-story .detail .info,
    #talent-story-golife .detail .info {
      margin-top: 2em; }
    #talent-story .detail .custom-nav,
    #talent-story-golife .detail .custom-nav {
      position: absolute;
      right: 5%;
      top: 50%;
      -webkit-transform: translateY(-50%);
      -moz-transform: translateY(-50%);
      -o-transform: translateY(-50%);
      transform: translateY(-50%); }
      #talent-story .detail .custom-nav .slick-disabled,
      #talent-story-golife .detail .custom-nav .slick-disabled {
        -webkit-filter: grayscale(100%);
        filter: grayscale(100%);
        pointer-events: none; }
      #talent-story .detail .custom-nav img,
      #talent-story .detail .custom-nav svg,
      #talent-story-golife .detail .custom-nav img,
      #talent-story-golife .detail .custom-nav svg {
        outline: none;
        display: block;
        margin-bottom: 1.5em; }

@media screen and (max-width: 48em) {
  #talent-story-golife .main-img {
    width: 100%; }
  #talent-story-golife h4:before {
    right: 42.1%;
    top: 4em; }
  #talent-story-golife h4 {
    margin-bottom: 3.5em; }
  #talent-story-golife .pure-u-lg-1-2 h4 {
    display: none; }
  #talent-story-golife .detail {
    padding: 1em !important;
    margin: 0;
    padding-bottom: 3em !important; }
  #talent-story-golife .mobile-text {
    text-align: center;
    display: inline-block;
    margin: auto; } }

@media screen and (max-width: 35.5em) {
  #talent-story h4:before,
  #talent-story-golife h4:before {
    right: 42.1%;
    top: 4em; }
  #talent-story a,
  #talent-story-golife a {
    position: inherit;
    margin-top: 1em;
    right: 0; }
  #talent-story h4,
  #talent-story-golife h4 {
    margin-bottom: 3.5em; }
  #talent-story .slick-dots,
  #talent-story-golife .slick-dots {
    bottom: 0; }
    #talent-story .slick-dots li button:before,
    #talent-story-golife .slick-dots li button:before {
      font-size: 10px;
      margin-top: 20px;
      line-height: 20px; }
    #talent-story .slick-dots li.slick-active button:before,
    #talent-story-golife .slick-dots li.slick-active button:before {
      opacity: .75;
      color: #99CC28; }
  #talent-story .slick-dotted.slick-slider,
  #talent-story-golife .slick-dotted.slick-slider {
    margin-bottom: 50px; }
  #talent-story .mobile-text,
  #talent-story-golife .mobile-text {
    text-align: center;
    display: inline-block;
    margin: auto; }
  #talent-story hr,
  #talent-story-golife hr {
    position: inherit;
    margin-top: -115%; }
  #talent-story h4,
  #talent-story-golife h4 {
    margin-left: 0;
    text-align: center; }
  #talent-story .pure-u-sm-1-2 h4,
  #talent-story-golife .pure-u-sm-1-2 h4 {
    display: none; }
  #talent-story .detail,
  #talent-story-golife .detail {
    text-align: center;
    margin-top: 0;
    padding: 1em;
    padding-bottom: 1em; }
    #talent-story .detail p,
    #talent-story-golife .detail p {
      padding: 0; }
    #talent-story .detail .custom-nav,
    #talent-story-golife .detail .custom-nav {
      right: inherit;
      top: inherit;
      position: relative;
      bottom: 0; }
      #talent-story .detail .custom-nav svg,
      #talent-story-golife .detail .custom-nav svg {
        margin: 0; }
      #talent-story .detail .custom-nav svg#up,
      #talent-story-golife .detail .custom-nav svg#up {
        left: 0; }
      #talent-story .detail .custom-nav svg#down,
      #talent-story-golife .detail .custom-nav svg#down {
        right: 0; }
  #talent-story .slide-mobile,
  #talent-story-golife .slide-mobile {
    display: inherit;
    text-align: center; }
    #talent-story .slide-mobile svg,
    #talent-story-golife .slide-mobile svg {
      z-index: 22;
      position: absolute; }
    #talent-story .slide-mobile svg#up,
    #talent-story-golife .slide-mobile svg#up {
      left: 2em;
      z-index: 22;
      bottom: 2em; }
    #talent-story .slide-mobile svg#down,
    #talent-story-golife .slide-mobile svg#down {
      right: 2em;
      z-index: 22;
      bottom: 2em; }
  #talent-story .desktop,
  #talent-story-golife .desktop {
    display: none; } }

section.about {
  background: #FAFAFA;
  padding: 3em 0; }
  section.about .container {
    position: relative; }
    section.about .container:before {
      content: "";
      position: absolute;
      left: -1.2em;
      top: 1.050em;
      padding-right: 45px;
      height: 3px;
      margin: 0;
      background-color: #48af4a; }
  section.about .video-content {
    display: none;
    position: relative;
    font-family: "Open Sans", Helvetica, sans-serif;
    width: 100%;
    height: 0;
    padding-bottom: 70%;
    background: #000000; }
    section.about .video-content iframe {
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      margin-top: 0 !important; }
    section.about .video-content .video-close {
      position: absolute;
      color: #fff;
      font-size: 200%;
      cursor: pointer;
      background: #48af4a url("../img/card/about/close-btn.png") no-repeat center center;
      background-size: 29px 29px;
      border-radius: 50%;
      width: 60px;
      height: 60px;
      top: -1em;
      right: -1em; }
  section.about h2 {
    font-family: "Lato", Helvetica, sans-serif;
    font-weight: 300; }
  section.about h4 {
    color: #48af4a;
    text-transform: uppercase;
    padding-left: 15px; }
  section.about p {
    color: #485460;
    padding-bottom: 20px;
    font-family: Open Sans,Helvetica,sans-serif !important; }
  section.about a {
    color: #48af4a;
    text-transform: uppercase;
    position: relative; }
    section.about a svg {
      transition: all 0.5s; }
    @media screen and (min-width: 769px) {
      section.about a:hover svg {
        transform: translateX(10px);
        transition: all 0.5s; } }
  section.about .description svg {
    width: 1.5em;
    height: auto;
    position: absolute;
    top: 0.2em;
    margin-left: 1em; }
  section.about .img-wrapper {
    position: relative; }
    section.about .img-wrapper .img-container {
      position: relative;
      background: #fff url() center center/cover;
      background-size: 100%;
      padding: 2em 0;
      margin-right: -3em;
      margin-left: 1em; }
      section.about .img-wrapper .img-container .img-container-2 {
        margin-left: -2em;
        margin-right: 2em;
        position: relative; }
      section.about .img-wrapper .img-container .play {
        overflow: hidden;
        transition: all 0.5s;
        position: absolute;
        margin: auto;
        top: 0;
        left: 0;
        cursor: pointer;
        right: 0;
        bottom: 0;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        object-fit: contain;
        background-color: #ffcd2e;
        box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.24); }
        @media screen and (min-width: 769px) {
          section.about .img-wrapper .img-container .play::after {
            position: absolute;
            width: 100%;
            text-align: center;
            content: 'Play Video';
            font-weight: 700;
            color: #ffcd2e;
            height: 36px;
            left: 0;
            top: 0;
            bottom: 0;
            height: 34px;
            margin: auto;
            transition: all 1s;
            transform: translateX(-100px); }
          section.about .img-wrapper .img-container .play:hover {
            background: #ffffff; }
            section.about .img-wrapper .img-container .play:hover img {
              transform: scale(9); }
            section.about .img-wrapper .img-container .play:hover::after {
              transform: translateX(0px); } }
        section.about .img-wrapper .img-container .play img {
          transition: all 0.5s;
          margin: auto;
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0; }
          @media screen and (max-width: 768px) {
            section.about .img-wrapper .img-container .play img {
              max-width: 20px; } }

/* Small Screen */
@media screen and (max-width: 35.5em) {
  section.about .video-content .video-close {
    height: 50px;
    width: 50px;
    margin: -5px 35px; } }

@media screen and (min-width: 35.5em) {
  section.about .img-wrapper {
    margin-left: 5em; }
  section.about .img-wrapper .img-container {
    margin-left: -4.188em; } }

/* Medium Screen */
@media screen and (min-width: 48em) {
  section.about .img-wrapper .img-container {
    margin-top: 40%;
    padding: 4em 0; }
  section.about .img-wrapper .img-container .img-container-2 {
    margin-right: 4em; }
  section.about .img-wrapper {
    display: block; }
  section.about a:after {
    top: 6px;
    width: 30px;
    height: 30px;
    background-size: 20px; }
  section.about h4 {
    padding: 0; }
  section.about .container:before {
    padding: 0; } }

/* Large Screen */
@media screen and (min-width: 64em) {
  section.about .img-wrapper {
    margin-left: 0; }
  section.about .img-wrapper .img-container {
    margin-left: 0;
    margin-right: 0;
    margin-top: 0; }
  section.about .img-wrapper .img-container .play {
    padding: 40px;
    margin-left: -2.5em; } }

/* Extra Large Screen */
#gopay .gopay-patform-icon {
  max-width: 200px; }
  @media screen and (max-width: 500px) {
    #gopay .gopay-patform-icon {
      max-width: 160px; } }

#gopay #privacy {
  margin-top: 8em; }

#gopay .gopay p {
  font-size: 16px; }

#gopay .gopay ul.normal {
  padding-left: inherit; }

#gopay .ac-label img {
  margin-bottom: 5px; }

#gopay .ac {
  margin: 100px 0; }

#gopay .ac:first-child {
  margin: 0;
  margin-bottom: 100px; }

#gopay label.ac-label {
  margin: 25px 0;
  display: block; }

#gopay .howto {
  background: white;
  padding: 30px; }

#gopay img.bca {
  height: 30px; }

#gopay img.mandiri {
  height: 30px; }

#gopay img.bri {
  height: 30px; }

#gopay img.bni {
  height: 30px; }

#gopay img.permata {
  height: 40px; }

#gopay img.cimb {
  height: 20px;
  margin-top: 2px; }

#gopay img.atmbersama {
  height: 45px; }

#gopay img.prima {
  height: 45px; }

#gopay img.bank-btn {
  height: 28px;
  margin-top: -3px; }

#privacy {
  margin: 5em 0; }
  #privacy .title {
    border-top: 5px solid #48af4a;
    font-family: "Open Sans", Helvetica, sans-serif;
    color: #272d32;
    margin-right: 4em; }
    #privacy .title h2 {
      font-weight: 300;
      line-height: 1.28;
      letter-spacing: 2px; }
    #privacy .title span {
      color: #3ca33a;
      display: block; }
  #privacy .content .strong {
    font-weight: bold; }
  #privacy .content h4 {
    font-size: inherit; }
  #privacy ul {
    list-style: none;
    position: relative;
    padding-left: 4em; }
    #privacy ul hr {
      position: absolute;
      width: 50px;
      height: 4px;
      border: none;
      background-color: #58c75b;
      left: 0;
      top: 0.5em; }
    #privacy ul li {
      margin: 1em 0em; }
      #privacy ul li a {
        font-family: "Open Sans", Helvetica, sans-serif;
        line-height: 2.5;
        letter-spacing: 0.6px;
        color: #343b41; }
      #privacy ul li .active,
      #privacy ul li a:hover {
        color: #3ca33a; }
  #privacy p {
    font-family: "Open Sans", Helvetica, sans-serif;
    font-weight: 300;
    line-height: 1.56;
    letter-spacing: 1px;
    color: #485460; }
  #privacy .perjanjian ol {
    margin-top: 0 !important; }
  #privacy ol {
    padding-left: 20px; }
    #privacy ol li {
      font-family: "Open Sans", Helvetica, sans-serif;
      font-weight: 300;
      line-height: 1.56;
      letter-spacing: 1px;
      color: #485460;
      margin-bottom: 0.7em; }

@media screen and (max-width: 35.5em) {
  #privacy ul p,
  #privacy ol p {
    font-size: 12px; }
  #privacy ul li,
  #privacy ol li {
    font-size: 14px; }
    #privacy ul li p,
    #privacy ol li p {
      font-size: 12px; }
    #privacy ul li ol li,
    #privacy ol li ol li {
      font-size: 12px; }
  #privacy ul {
    margin: 0; }
    #privacy ul hr {
      top: 0.5em; }
    #privacy ul li {
      margin: 1em 0; } }

#term {
  margin: 5em 0; }
  #term .title {
    border-top: 5px solid #48af4a;
    font-family: "Open Sans", Helvetica, sans-serif;
    color: #272d32;
    margin-right: 4em; }
    #term .title h2 {
      font-weight: 300;
      line-height: 1.28;
      letter-spacing: 2px; }
    #term .title span {
      color: #3ca33a;
      display: block; }
  #term ul {
    list-style: none;
    position: relative;
    margin-top: 8em;
    padding-left: 4em; }
    #term ul hr {
      position: absolute;
      width: 50px;
      height: 4px;
      border: none;
      background-color: #58c75b;
      left: 0;
      top: 0.5em; }
    #term ul li {
      margin: 1em 0em; }
      #term ul li a {
        font-family: "Open Sans", Helvetica, sans-serif;
        line-height: 2.5;
        letter-spacing: 0.6px;
        color: #343b41; }
      #term ul li .active,
      #term ul li a:hover {
        color: #3ca33a; }
  #term p {
    font-family: "Open Sans", Helvetica, sans-serif;
    font-weight: 300;
    line-height: 1.56;
    letter-spacing: 1px;
    color: #485460; }
  #term .content .strong {
    font-weight: bold; }
  #term .content h4 {
    font-size: inherit;
    line-height: 24px; }
  #term .content p {
    font-size: 16px; }
  #term .content ol {
    padding-left: 20px;
    margin-top: 2em; }
    #term .content ol li {
      font-family: "Open Sans", Helvetica, sans-serif;
      font-weight: 300;
      line-height: 1.56;
      letter-spacing: 1px;
      color: #485460;
      margin-bottom: 0.7em; }
  #term .content ul {
    margin-top: 0;
    padding-left: 0; }
    #term .content ul li {
      font-family: "Open Sans", Helvetica, sans-serif;
      font-weight: 300;
      line-height: 1.56;
      letter-spacing: 1px;
      color: #485460;
      margin-bottom: 0.7em;
      margin-left: 1em; }

@media screen and (max-width: 35.5em) {
  #term ul {
    margin: 0; }
    #term ul hr {
      top: 0.5em; }
    #term ul li {
      margin: 1em 0; } }

/**
 * Author: JR
 * Julian Rulliansyah
 * @JRulliansyah
 * */
.about-page {
  width: 100% !important;
  background: #fafafa;
  margin-bottom: 3.5em;
  /* -- Header Gradient Styling - START -- */
  /* -- Header Gradient Styling - END -- */
  /* -- Section Content - START -- */
  /* -- Section Content - END -- */
  /* -- Section Values - START -- */
  /* Section Vision Mission - START */
  /* Section Vision Mission - END */
  /* Section Our Values Mobile - START */
  /* Section Our Values Mobile - END */
  /* Large Screen */ }
  .about-page .header-gradient {
    background: #48af4a;
    width: 100%;
    margin-top: 80px;
    height: 65px; }
  .about-page .video-content {
    display: none;
    position: relative;
    font-family: "Open Sans", Helvetica, sans-serif;
    width: 100%;
    height: 0;
    padding-bottom: 70%; }
    .about-page .video-content iframe {
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      margin-top: 0 !important; }
    .about-page .video-content .video-close {
      position: absolute;
      color: #fff;
      font-size: 200%;
      cursor: pointer;
      background: #48af4a url("../img/card/about/close-btn.png") no-repeat center center;
      background-size: 29px 29px;
      border-radius: 50%;
      width: 60px;
      height: 60px;
      top: -1em;
      right: -1em; }
  .about-page .img-container {
    position: relative;
    background: #fff url() center center/cover;
    background-size: 100%;
    padding-bottom: 2em; }
    .about-page .img-container .img-container-2 {
      position: relative; }
    .about-page .img-container .play {
      position: absolute;
      margin: auto;
      top: 0;
      left: 0;
      cursor: pointer;
      right: 0;
      bottom: 0;
      width: 50px;
      height: 50px;
      border-radius: 50%;
      object-fit: contain;
      background-color: #ffcd2e;
      box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.24); }
      .about-page .img-container .play img {
        margin: auto;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0; }
  .about-page .section-content {
    margin-top: 30px; }
  .about-page .section-content .content {
    background: white;
    padding: 40px;
    border-top: 3.5px solid #48af4a; }
  .about-page .section-content .content .line {
    width: 50px;
    position: relative;
    left: -70px;
    height: 3px;
    background: black; }
  .about-page .section-content .content .line-2 {
    width: 50px;
    position: relative;
    left: -70px;
    height: 3px;
    background: #3ca33a; }
  .about-page .section-content .content .subnav-wrapper {
    margin-top: 50px; }
  .about-page .section-content .content .subnav-wrapper ul {
    list-style-type: none;
    font-family: "Open Sans", sans-serif;
    position: relative;
    top: -28px;
    left: -25px; }
  .about-page .section-content .content .subnav-wrapper ul li {
    margin-bottom: 10px; }
  .about-page .section-content .content .subnav-wrapper ul li span {
    text-decoration: none;
    color: inherit;
    font-size: 16px;
    letter-spacing: 1px; }
  .about-page .section-content ul li span#about-us {
    color: #48af4a; }
  .about-page .section-content ul li span.active--sidebar {
    font-weight: bold;
    color: #fff; }
  .about-page .section-content .content .breadcrumb {
    color: #b3b9c7;
    font-family: 'Open Sans', sans-serif;
    line-height: 1.17;
    letter-spacing: 1.3px;
    margin-top: -6.5px; }
  .about-page .section-content .content .breadcrumb a {
    color: #b3b9c7;
    font-family: 'Open Sans', sans-serif;
    line-height: 1.17;
    letter-spacing: 1.3px;
    margin-top: -6.5px; }
  .about-page .section-content .content .time {
    color: #b3b9c7;
    font-family: 'Open Sans', sans-serif;
    font-weight: 300;
    font-size: 16px;
    margin-top: -6.5px; }
  .about-page .section-content .content .title {
    font-family: "Lato", Helvetica, sans-serif;
    font-weight: 300;
    font-size: 35px;
    letter-spacing: 1px;
    color: #343b41; }
  .about-page .section-content .mobile-title {
    font-family: "Lato", Helvetica, sans-serif;
    font-weight: 300;
    font-size: 35px;
    letter-spacing: 1px;
    color: #343b41; }
  .about-page .section-content .content .page-title {
    font-size: 32px;
    font-weight: 300;
    font-family: "Open Sans", Helvetica, sans-serif;
    letter-spacing: 1.2px; }
  .about-page .section-content .content .page-title > span {
    color: #3ca33a; }
  .about-page .section-content .content .page-title-mobile {
    font-size: 32px;
    font-weight: 300;
    display: none;
    font-family: "Open Sans", Helvetica, sans-serif;
    letter-spacing: 1.5px; }
  .about-page .section-content .content .page-title-mobile > span {
    color: #3ca33a; }
  .about-page .section-content .content .text-content {
    font-size: 14px;
    font-weight: 300;
    line-height: 1.56;
    letter-spacing: 1px;
    padding: 15px;
    color: #485460; }
  .about-page .section-content .content .text-content h4 {
    line-height: 28px;
    letter-spacing: 1.3px;
    color: #485460; }
  .about-page .section-content .content .text-content p {
    letter-spacing: 1.25px;
    color: #485460;
    font-size: 14px; }
  .about-page .section-content .sidebar {
    padding: 0 10px; }
    .about-page .section-content .sidebar .sidebar--link {
      margin-bottom: 20px;
      display: block; }
  .about-page .section-content .sidebar .wrapper {
    margin-top: -10px; }
  .about-page .section-content .sidebar .wrapper h3 {
    color: #48af4a;
    font-family: "Open Sans", sans-serif;
    line-height: 0.78;
    font-size: 15px;
    letter-spacing: 1.9px; }
  .about-page .section-content .sidebar .line {
    width: 50px;
    height: 3px;
    margin-top: 10px;
    background: #48af4a; }
  .about-page .section-content .sidebar .list {
    margin-top: 20px; }
  .about-page .section-content .sidebar .list img {
    width: 100%; }
  .about-page .mobile-title-page {
    display: none;
    object-fit: contain;
    font-family: "Lato", Helvetica, sans-serif;
    text-align: center;
    font-weight: 300;
    line-height: 1.16;
    letter-spacing: 5.5px;
    margin-top: 0px;
    color: #343b41; }
  .about-page .mobile-title-page span {
    color: #48af4a; }
  .about-page .mobile-who-we-are {
    margin-top: 20px;
    margin-bottom: -10px;
    margin-left: 10px;
    display: none; }
  .about-page .mobile-who-we-are .line {
    background: #48af4a !important; }
  .about-page .mobile-who-we-are .breadcrumb {
    color: #48af4a !important;
    position: relative !important;
    top: -.5px !important; }
  .about-page .mobile-mission {
    position: relative;
    top: -52.5px;
    margin-bottom: -50px;
    margin-left: 45px;
    display: none; }
  .about-page .mobile-mission .line {
    background: white !important; }
  .about-page .mobile-mission .breadcrumb {
    color: white !important;
    position: relative !important;
    top: -.5px !important; }
  .about-page .section-values {
    margin: 30px 0 -40px -40px; }
  .about-page .section-values .content {
    padding: 20px;
    min-height: 400px;
    border: 0;
    background: #48af4a !important; }
  .about-page .section-values .content .navigation .line {
    width: 50px;
    position: relative;
    left: -60px;
    top: 130px;
    height: 3px;
    background: #3ca33a; }
  .about-page .section-values .content .navigation .line-4 {
    width: 50px;
    position: relative;
    left: -50px;
    top: 130px;
    height: 3px;
    background: #3ca33a; }
  .about-page .section-values .content .navigation ul {
    list-style-type: none;
    color: white;
    font-family: "Open Sans", sans-serif;
    position: relative;
    top: 70px;
    left: -20px; }
  .about-page .section-values .content .navigation ul li {
    margin-bottom: 10px; }
  .about-page .section-values .content .navigation ul li a {
    text-decoration: none;
    color: inherit;
    font-size: 16px;
    letter-spacing: 1px; }
  .about-page .section-values-box {
    z-index: 10;
    position: relative; }
  .about-page .section-values-box .text {
    background: #fcfcfe;
    padding: 40px;
    min-height: 320px;
    z-index: -999;
    width: 430px;
    margin-left: 270px;
    margin-bottom: -60px;
    margin-top: -310px; }
  .about-page .section-values-box .text .wrapper {
    margin-bottom: -35px; }
  .about-page .section-values-box .text .list {
    margin-bottom: 15px; }
  .about-page .section-values-box .text .list img {
    max-width: 90px;
    margin-right: 15px; }
  .about-page .about-img-1 {
    position: relative;
    top: 5px; }
  .about-page .about-img-2 {
    position: relative; }
  .about-page .about-img-3 {
    position: relative;
    top: 10px; }
  .about-page .section-values-box .text .list h4 {
    font-family: "Open Sans", Helvetica, sans-serif;
    font-size: 15px;
    color: #485460;
    margin-left: 10px;
    margin-top: 10px; }
  .about-page .section-values-box .text .list p {
    margin-top: -12.5px;
    margin-left: 10px;
    font-size: 12.5px;
    font-family: "Open Sans", Helvetica, sans-serif; }
  @media only screen and (max-width: 480px) {
    .about-page .section-values-box .text {
      display: none; } }
  .about-page .section-vision-mission {
    margin: -20px 0 -40px -40px;
    z-index: -10;
    background-repeat: no-repeat !important;
    background-size: cover !important; }
  .about-page .section-vision-mission .content-outline .background {
    background-size: cover !important;
    background-position: center center;
    height: 450px; }
  .about-page .section-vision-mission .content-outline .background .overlay {
    background: transparent;
    height: 450px !important; }
  .about-page .section-vision-mission .content-outline .text {
    padding: 40px 5px 40px 40px;
    background: #fff;
    position: relative;
    left: 30px;
    top: 5px; }
  .about-page .section-vision-mission .content-outline .text h4 {
    letter-spacing: 1px;
    margin-top: 50px;
    line-height: 28px;
    font-family: "Open Sans", Helvetica, sans-serif; }
  .about-page .section-vision-mission .content-outline .text p {
    font-family: "Open Sans", Helvetica, sans-serif;
    font-size: 14px;
    margin-top: 10px;
    letter-spacing: 1px; }
  .about-page .section-vision-mission .navigations .line {
    width: 50px;
    position: relative;
    left: -30px;
    top: 183px;
    height: 3px;
    background: #3ca33a; }
  .about-page .section-vision-mission .navigations ul {
    list-style-type: none;
    color: white;
    font-family: "Open Sans", sans-serif;
    position: relative;
    top: 90px;
    left: 15px; }
  .about-page .section-vision-mission .navigations ul li {
    margin-bottom: 10px; }
  .about-page .section-vision-mission .navigations ul li a {
    text-decoration: none;
    color: inherit;
    font-size: 16px;
    letter-spacing: 1px; }
  .about-page .section-our-values-mobile {
    margin: -20px 0 -40px -40px;
    z-index: -10;
    display: none !important; }
  .about-page .section-our-values-mobile .content-outline .background {
    background-size: cover !important;
    background-position: center center;
    height: 450px; }
  .about-page .section-our-values-mobile .content-outline .background .overlay {
    background: transparent;
    height: 450px !important; }
  .about-page .section-our-values-mobile .content-outline .text {
    padding: 20px;
    background: white;
    position: relative;
    left: 30px; }
  .about-page .section-our-values-mobile .content-outline .text h4 {
    letter-spacing: 1px;
    margin-top: 50px;
    line-height: 34px;
    font-family: "Open Sans", Helvetica, sans-serif; }
  .about-page .section-our-values-mobile .content-outline .text p {
    font-family: "Open Sans", Helvetica, sans-serif;
    font-size: 14px;
    margin-top: 10px;
    letter-spacing: 1px; }
  .about-page .section-our-values-mobile .navigations .line {
    width: 50px;
    position: relative;
    left: -30px;
    top: 183px;
    height: 3px;
    background: #3ca33a; }
  .about-page .section-our-values-mobile .navigations ul {
    list-style-type: none;
    color: white;
    font-family: "Open Sans", sans-serif;
    position: relative;
    top: 90px;
    left: 15px; }
  .about-page .section-our-values-mobile .navigations ul li {
    margin-bottom: 10px; }
  .about-page .section-our-values-mobile .navigations ul li a {
    text-decoration: none;
    color: inherit;
    font-size: 16px;
    letter-spacing: 1px; }
  .about-page #OurValuesSlider {
    display: none;
    margin-top: 80px;
    margin-bottom: 80px;
    margin-left: -17.5px; }
  .about-page #OurValuesSlider .slick-dots {
    position: relative;
    display: none; }
  .about-page #OurValuesSlider .item .wrapper img {
    max-width: 230px;
    border-radius: 0;
    background-color: transparent;
    display: block;
    margin-right: auto;
    margin-left: auto; }
  .about-page #OurValuesSlider .item .wrapper h4 {
    line-height: 33px;
    letter-spacing: 1.5px;
    color: #485460;
    font-size: 23px;
    text-align: center; }
  .about-page #OurValuesSlider .item .wrapper p {
    object-fit: contain;
    font-family: "Open Sans", Helvetica, sans-serif;
    padding: 30px;
    font-size: 16px;
    font-weight: 300;
    line-height: 1.43;
    letter-spacing: 1.5px;
    text-align: center;
    margin-top: -20px;
    color: #343b41; }
  .about-page .arrow {
    position: relative;
    top: -90px;
    display: none;
    margin-bottom: -80px; }
  @media only screen and (max-width: 480px) {
    .about-page {
      /* Section Content */
      /* Section Content */
      /* Section Values */
      /* Section Values */
      /* Section Our Values Mobile */
      /* Section Our Values Mobile */
      /* Section Vision Mission */
      /* Section Vission Mission */ }
      .about-page .article-content {
        position: relative;
        left: -20px; }
      .about-page .mobile-title-page {
        display: block !important;
        font-size: 38px; }
      .about-page .mobile-who-we-are {
        display: block !important; }
      .about-page .mobile-mission {
        display: block !important; }
      .about-page .header-gradient {
        background: #48af4a;
        width: 100%;
        margin-top: 80px;
        height: 25px !important; }
      .about-page .section-content .sidebar .wrapper {
        text-align: center; }
      .about-page .section-content .content .title {
        display: none;
        letter-spacing: 1px; }
      .about-page .sub-navigation {
        display: none; }
      .about-page .section-content .container {
        padding: 0;
        margin: 0; }
      .about-page .section-content .content .page-title-wrapper {
        display: none; }
      .about-page .section-content .content .page-title-mobile-wrapper {
        display: block; }
      .about-page .section-content .content .text-content {
        letter-spacing: 1px; }
      .about-page .section-content .sidebar {
        margin-top: 30px;
        width: 100%;
        padding: 0; }
      .about-page .section-content .sidebar .list img {
        max-width: 295px; }
      .about-page .section-content .sidebar .list {
        text-align: center;
        position: relative;
        top: 10px;
        left: -20px; }
      .about-page .section-content .content .line {
        width: 50px;
        position: relative;
        top: 2px;
        left: -65px !important;
        height: 3px;
        background: #000; }
      .about-page .section-content .content .breadcrumb {
        object-fit: contain;
        font-family: "Open Sans", Helvetica, sans-serif;
        font-size: 15px;
        font-weight: bold;
        letter-spacing: 2.5px !important;
        position: relative;
        top: -2px;
        left: 5px;
        color: #c3c9d7; }
      .about-page .section-content .content .text-content h4 {
        object-fit: contain;
        font-size: 20px;
        font-weight: bold;
        line-height: 1.43;
        letter-spacing: 1.5px;
        color: #343b41; }
      .about-page .section-content .content .text-content p {
        font-family: "Open Sans", Helvetica, sans-serif;
        font-size: 17px !important;
        font-weight: 300;
        line-height: 1.43;
        letter-spacing: 1.5px;
        color: #343b41; }
      .about-page .section-values {
        display: none; }
      .about-page #OurValuesSlider {
        display: table !important; }
      .about-page .arrow {
        display: block !important; }
      .about-page .section-our-values-mobile {
        z-index: -10;
        margin-top: 30px;
        padding: 0;
        display: block !important; }
      .about-page .section-our-values-mobile .content-outline {
        width: 100%; }
      .about-page .section-our-values-mobile .content-outline .background {
        background: #48af4a;
        background-size: cover !important;
        background-position: center center;
        height: 100px;
        width: 100%; }
      .about-page .section-our-values-mobile .content-outline .background .overlay {
        background: transparent;
        height: 450px !important;
        width: 100%; }
      .about-page .section-our-values-mobile .content-outline .text {
        padding: 40px;
        background: white;
        position: relative;
        left: 0px; }
      .about-page .section-our-values-mobile .content-outline .text h4 {
        object-fit: contain;
        font-family: "Open Sans", Helvetica, sans-serif;
        font-size: 23px;
        font-weight: bold;
        line-height: 1.43;
        letter-spacing: 1.5px;
        color: #343b41;
        margin-top: 20px; }
      .about-page .section-our-values-mobile .content-outline .text p {
        letter-spacing: 1.25px;
        color: #485460;
        font-size: 17px; }
      .about-page .section-our-values-mobile .navigations .line {
        width: 50px;
        position: relative;
        left: -30px;
        top: 407px;
        height: 3px;
        background: #3ca33a; }
      .about-page .section-our-values-mobile .navigations ul {
        list-style-type: none;
        color: white;
        position: relative;
        bottom: 0;
        top: 380px;
        left: 0px;
        font-weight: 300;
        display: none; }
      .about-page .section-our-values-mobile .content-outline .navigations ul li.who-we-are,
      .about-page .section-our-values-mobile .content-outline .navigations ul li.our-values {
        display: none; }
      .about-page .section-vision-mission {
        z-index: -10;
        margin-top: 30px;
        padding: 0; }
      .about-page .section-vision-mission .content-outline {
        width: 100%; }
      .about-page .section-vision-mission .content-outline .background {
        background: url("https://d3naj63yelf4gk.cloudfront.net/dist/img/about/mission.png");
        background-size: cover !important;
        background-position: center center;
        height: 450px;
        width: 375px; }
      .about-page .section-vision-mission .content-outline .background .overlay {
        background: transparent;
        height: 450px !important;
        width: 100%; }
      .about-page .section-vision-mission .content-outline .text {
        padding: 40px;
        background: white;
        position: relative;
        left: 0px; }
      .about-page .section-vision-mission .content-outline .text h4 {
        object-fit: contain;
        font-family: "Open Sans", Helvetica, sans-serif;
        font-size: 23px;
        font-weight: bold;
        line-height: 1.43;
        letter-spacing: 1.5px;
        color: #343b41;
        margin-top: 20px; }
      .about-page .section-vision-mission .content-outline .text p {
        letter-spacing: 1.25px;
        color: #485460;
        font-size: 17px; }
      .about-page .section-vision-mission .navigations .line {
        width: 50px;
        position: relative;
        left: -30px;
        top: 407px;
        height: 3px;
        background: #3ca33a; }
      .about-page .section-vision-mission .navigations ul {
        list-style-type: none;
        color: white;
        position: relative;
        bottom: 0;
        top: 380px;
        left: 0px;
        font-weight: 300;
        display: none; }
      .about-page .section-vision-mission .content-outline .navigations ul li.who-we-are,
      .about-page .section-vision-mission .content-outline .navigations ul li.our-values {
        display: none; } }
  @media only screen and (max-width: 440px) {
    .about-page {
      /* Section Vission Mission */
      /* Section Vission Mission */ }
      .about-page .article-content {
        position: relative;
        left: -30px; }
      .about-page .section-vision-mission {
        z-index: -10;
        padding: 0; }
      .about-page .section-vision-mission .content-outline {
        width: 100%; }
      .about-page .section-vision-mission .content-outline .background {
        background: url("https://d3naj63yelf4gk.cloudfront.net/dist/img/about/mission.png");
        background-size: cover !important;
        background-position: center center;
        height: 450px;
        width: 415px; }
      .about-page .section-vision-mission .content-outline .background .overlay {
        background: transparent;
        height: 450px !important;
        width: 100%; }
      .about-page .section-vision-mission .content-outline .text {
        padding: 40px;
        background: white;
        position: relative;
        left: 0px; }
      .about-page .section-vision-mission .navigations .line {
        width: 50px;
        position: relative;
        left: -30px;
        top: 407px;
        height: 3px;
        background: #3ca33a; }
      .about-page .section-vision-mission .navigations ul {
        list-style-type: none;
        color: white;
        font-family: "Open Sans", sans-serif;
        position: relative;
        bottom: 0;
        top: 380px;
        left: 0px;
        font-weight: 300;
        display: none; }
      .about-page .section-vision-mission .content-outline .navigations ul li.who-we-are,
      .about-page .section-vision-mission .content-outline .navigations ul li.our-values {
        display: none; }
      .about-page #OurValuesSlider {
        display: none;
        margin-top: 80px;
        margin-bottom: 80px; }
      .about-page .arrow {
        left: -17.5px; } }
  @media only screen and (max-width: 320px) {
    .about-page {
      /* Section Content */
      /* Section Content */
      /* Section Vision Mission */
      /* Section Vission Mission */ }
      .about-page .section-content .content .title {
        letter-spacing: 1px; }
      .about-page .sub-navigation {
        display: none; }
      .about-page .section-content .container {
        padding: 0;
        margin: 0; }
      .about-page .section-content .content .page-title-wrapper {
        display: none; }
      .about-page .section-content .content .page-title-mobile-wrapper {
        display: block; }
      .about-page .section-content .content .text-content {
        letter-spacing: 1px; }
      .about-page .section-content .sidebar {
        margin-top: 30px;
        width: 100%;
        padding: 0; }
      .about-page .section-content .sidebar .list img {
        max-width: 275px; }
      .about-page .section-vision-mission {
        z-index: -10;
        padding: 0; }
      .about-page .section-vision-mission .content-outline {
        width: 100%; }
      .about-page .section-vision-mission .content-outline .background {
        background: url("https://d3naj63yelf4gk.cloudfront.net/dist/img/about/mission.png");
        background-size: cover !important;
        background-position: center center;
        height: 450px;
        width: 320px; }
      .about-page .section-vision-mission .content-outline .background .overlay {
        background: transparent;
        height: 450px !important;
        width: 100%; }
      .about-page .section-vision-mission .content-outline .text {
        padding: 20px;
        background: white;
        position: relative;
        left: 0px; }
      .about-page .section-vision-mission .navigations .line {
        width: 50px;
        position: relative;
        left: -30px;
        top: 407px;
        height: 3px;
        background: #3ca33a; }
      .about-page .section-vision-mission .navigations ul {
        list-style-type: none;
        color: white;
        font-family: "Open Sans", sans-serif;
        position: relative;
        bottom: 0;
        top: 380px;
        left: 0px;
        font-weight: 300; }
      .about-page .section-vision-mission .content-outline .navigations ul li.who-we-are,
      .about-page .section-vision-mission .content-outline .navigations ul li.our-values {
        display: none; } }
  @media screen and (min-width: 64em) {
    .about-page .img-container .play {
      margin-left: -2.5em;
      padding: 40px; } }
  .about-page .slick-slider {
    display: table;
    table-layout: fixed;
    width: 100%; }
  .about-page .disabled {
    filter: grayscale(100%); }
  .about-page .slick-arrow ellipse {
    stroke: #48af4a !important; }
  .about-page .slick-arrow polygon {
    fill: #48af4a !important; }

section.services {
  background: #FAFAFA;
  padding: 50px 0 13em !important; }
  section.services .container {
    position: relative; }
    section.services .container:before {
      content: "";
      position: absolute;
      height: 3px;
      margin: 0;
      background-color: #48af4a;
      z-index: 10;
      left: -2em;
      top: 52px;
      padding-right: 45px; }
  section.services h4 {
    color: #48af4a;
    text-transform: uppercase;
    padding-left: 15px;
    padding-bottom: 2em; }
    @media screen and (min-width: 769px) {
      section.services h4 {
        position: relative;
        top: 19px; } }
  section.services p {
    color: #485460;
    padding-bottom: 3em; }
  section.services .wrapper {
    width: 100%;
    margin: 0;
    padding: 1.563em 15px;
    padding-bottom: 0;
    padding-top: 0;
    background-color: #fff;
    position: relative; }
    section.services .wrapper img.service-logo {
      position: absolute;
      top: 25px;
      right: 25px;
      width: 100px; }
    section.services .wrapper .service-container {
      height: 15em;
      background-image: linear-gradient(139deg, #9fda36, #25c768);
      margin: 0 -15px;
      margin-right: -20%; }
      section.services .wrapper .service-container .service-list {
        background-color: #fff;
        width: 13em;
        margin: 3em 7px 15px;
        padding: 0 25px 25px;
        box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.11); }
        section.services .wrapper .service-container .service-list.slick-slide:focus {
          outline: none; }
        section.services .wrapper .service-container .service-list p {
          padding: 0;
          min-height: 160px; }
        section.services .wrapper .service-container .service-list .img-container {
          position: relative;
          width: auto;
          right: auto;
          top: auto;
          background-color: #FEFEFE;
          margin-top: -25px; }
          section.services .wrapper .service-container .service-list .img-container img {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto; }
        section.services .wrapper .service-container .service-list .bg-services {
          background-position: center center;
          background-size: cover;
          height: 8em;
          opacity: .16; }
        section.services .wrapper .service-container .service-list a {
          color: #48af4a;
          text-transform: uppercase;
          position: relative; }
          section.services .wrapper .service-container .service-list a:after {
            content: "";
            position: absolute;
            top: 3px;
            width: 20px;
            height: 20px;
            background-size: 15px;
            background-image: url(../img/about/shape-arrow.svg);
            background-repeat: no-repeat;
            margin-left: 12px; }
      section.services .wrapper .service-container .slick-track {
        margin-left: 5% !important; }
      section.services .wrapper .service-container .service-list {
        width: 20em !important; }
        section.services .wrapper .service-container .service-list .bg-services {
          height: 12em; }
        section.services .wrapper .service-container .service-list p {
          min-height: 125px; }
    section.services .wrapper .navigation-arrow {
      padding-top: 10em;
      padding-bottom: 2em;
      background: white;
      margin: 0; }
      section.services .wrapper .navigation-arrow .left,
      section.services .wrapper .navigation-arrow .right {
        cursor: pointer;
        display: inline-block;
        width: 42px;
        height: 42px; }
        section.services .wrapper .navigation-arrow .left svg,
        section.services .wrapper .navigation-arrow .right svg {
          width: 100%; }
        section.services .wrapper .navigation-arrow .left :focus,
        section.services .wrapper .navigation-arrow .right :focus {
          background: none;
          outline: 0; }
      section.services .wrapper .navigation-arrow .right {
        float: right; }
      section.services .wrapper .navigation-arrow .slick-disabled ellipse {
        stroke: #D2D2D2 !important; }
      section.services .wrapper .navigation-arrow .slick-disabled polygon {
        fill: #D2D2D2 !important; }

section.services#golife {
  padding-bottom: 7em; }
  section.services#golife .wrapper .navigation-arrow {
    padding-top: 13em; }
  section.services#golife .wrapper .service-container .service-list {
    margin-top: 6em; }
    section.services#golife .wrapper .service-container .service-list .img-container {
      position: relative;
      width: auto;
      right: auto;
      top: -35px;
      background-color: #FEFEFE;
      margin: 0 auto;
      margin-top: -25px;
      border-radius: 50%;
      overflow: hidden;
      width: 130px;
      height: 130px; }
      section.services#golife .wrapper .service-container .service-list .img-container img {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        object-fit: cover;
        height: 100%; }

/* Mobile Screen */
@media screen and (max-width: 414px) {
  section.services {
    padding-bottom: 4em !important; }
    section.services h4 {
      font-size: .688em;
      padding-bottom: 0px;
      line-height: 4em; }
    section.services .container:before {
      left: -1em;
      top: 35px; }
    section.services .wrapper .service-container .slick-track {
      margin-left: 5% !important; }
    section.services .wrapper .service-container .service-list {
      width: 15em !important; }
      section.services .wrapper .service-container .service-list .bg-services {
        height: 10em; }
    section.services .wrapper img.service-logo {
      width: 100px;
      right: 25px; } }

/* Medium Screen */
@media screen and (min-width: 48em) {
  section.services {
    padding-bottom: 12em; }
  section.services .img-wrapper .img-container {
    margin-top: 40%; }
  section.services .img-wrapper {
    display: block; }
  section.services a:after {
    top: 6px;
    width: 30px;
    height: 30px;
    background-size: 20px; }
  section.services .container:before {
    padding: 0; }
  section.services .wrapper {
    width: 100%; }
  section.services .wrapper img.service-logo {
    top: 2.5em;
    right: 0;
    left: 0;
    margin-left: auto;
    margin-right: auto; }
  section.services#golife .wrapper .navigation-arrow,
  section.services .wrapper .navigation-arrow {
    padding: 0;
    right: 2.5em;
    top: 2.5em;
    position: absolute; }
    section.services#golife .wrapper .navigation-arrow .left,
    section.services#golife .wrapper .navigation-arrow .right,
    section.services .wrapper .navigation-arrow .left,
    section.services .wrapper .navigation-arrow .right {
      float: none; }
    section.services#golife .wrapper .navigation-arrow .left,
    section.services .wrapper .navigation-arrow .left {
      padding-right: 5px; }
    section.services#golife .wrapper .navigation-arrow .right,
    section.services .wrapper .navigation-arrow .right {
      padding-left: 5px; }
  section.services .wrapper .service-container .service-list p {
    min-height: 200px; } }

section.services.go-jek .wrapper .service-container {
  background: #58c75b; }

section.services.go-pay .wrapper .service-container {
  background: #0bb2ee; }

section.services.go-life .wrapper .service-container {
  background: #48af4a; }

/* Large Screen */
@media screen and (min-width: 64em) {
  section[data-target="go-pay"] .navigation-arrow {
    display: inline; }
  section.services#golife {
    padding-bottom: 17em; }
  section.services .wrapper .service-container .service-list {
    width: 17em; }
  section.services .wrapper .service-container .service-list p {
    min-height: 160px; } }

@media screen and (min-width: 1440px) {
  section.services .wrapper .service-container {
    margin-right: -50%; } }

#join-now {
  padding: 3em 0;
  background: #f8f8f8; }
  #join-now .bg-layer {
    position: relative;
    height: 100%; }
  #join-now img {
    width: 120%;
    height: 100%;
    opacity: 0.9;
    filter: contrast(0.9);
    mix-blend-mode: multiply; }
  #join-now .right {
    position: relative;
    font-family: "Open Sans", Helvetica, sans-serif;
    padding: 3em;
    background: #343b41;
    color: white; }
    #join-now .right p:first-child {
      font-family: "Open Sans", Helvetica, sans-serif;
      font-weight: 700; }
    #join-now .right h2 {
      line-height: 1.28;
      letter-spacing: 2px; }
    #join-now .right p {
      line-height: 1.5;
      letter-spacing: 1.9px;
      color: #b3b9c7; }
    #join-now .right a {
      background: #48af4a;
      cursor: pointer;
      color: white;
      font-size: 14px;
      padding: 2em;
      position: absolute;
      bottom: -2em;
      right: 2em;
      border-radius: 5px; }

@media screen and (max-width: 35.5em) {
  #join-now img {
    width: 100%; }
  #join-now .right {
    padding: 3em 1em; }
    #join-now .right h2 {
      font-size: 1em; }
    #join-now .right a {
      right: 1.5em; } }

@media screen and (min-width: 48em) {
  #join-now img {
    width: 100%; } }

section#new-join {
  text-align: center; }
  section#new-join .container-form {
    position: relative;
    display: inline-block;
    width: 90%;
    max-width: 1170px; }
    @media screen and (max-width: 768px) {
      section#new-join .container-form {
        width: 100%; } }
    section#new-join .container-form .background {
      position: absolute;
      height: 100%;
      left: 0;
      top: 0;
      background-size: cover;
      background-position: center;
      width: 25%; }
      section#new-join .container-form .background .float-image {
        position: absolute;
        height: 100px;
        width: 100px;
        background-size: 100% 100%;
        background-position: center;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto; }
      @media screen and (max-width: 768px) {
        section#new-join .container-form .background {
          display: none; } }
    section#new-join .container-form .content {
      float: right;
      width: 75%;
      background: #ffffff;
      box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.19);
      padding: 50px;
      text-align: left; }
      @media screen and (max-width: 767px) {
        section#new-join .container-form .content {
          padding: 50px 10px; } }
      section#new-join .container-form .content .title {
        font-size: 14px;
        margin-left: 20px;
        color: #bcbdc0;
        font-weight: 700;
        margin-bottom: 30px;
        letter-spacing: 1px; }
        section#new-join .container-form .content .title:before {
          content: "";
          position: relative;
          width: 15px;
          height: 15px;
          margin-right: 5px;
          display: inline-block;
          background-size: cover;
          background-position: center;
          background-image: url("/static/dist/img/card/join-form/join-form-icon.png"); }
      section#new-join .container-form .content form {
        font-family: "Open Sans", Helvetica, sans-serif; }
        section#new-join .container-form .content form .pure-g [class*=pure-u] {
          font-family: "Open Sans", Helvetica, sans-serif; }
        section#new-join .container-form .content form .separate-input label.checkbox {
          width: 49%; }
          @media screen and (max-width: 767px) {
            section#new-join .container-form .content form .separate-input label.checkbox {
              width: 100%; } }
        section#new-join .container-form .content form .input-container {
          padding: 5px 20px; }
          @media screen and (max-width: 768px) {
            section#new-join .container-form .content form .input-container {
              width: 100%; } }
        section#new-join .container-form .content form .form-content {
          margin-top: 20px; }
          @media screen and (max-width: 768px) {
            section#new-join .container-form .content form .form-content {
              margin-top: 0px; } }
        section#new-join .container-form .content form label {
          text-transform: uppercase;
          font-size: 14px;
          margin-bottom: 10px;
          font-weight: 700;
          display: inline-block;
          letter-spacing: 1.2px;
          color: #697b8c; }
          section#new-join .container-form .content form label.radio {
            font-weight: unset;
            cursor: pointer;
            margin: 0 8px; }
            section#new-join .container-form .content form label.radio span {
              margin-left: 2px; }
          @media screen and (max-width: 768px) {
            section#new-join .container-form .content form label {
              line-height: 24px; } }
        section#new-join .container-form .content form .ct-radio {
          position: relative; }
          section#new-join .container-form .content form .ct-radio > div {
            display: inline-block;
            margin-right: 30px; }
            section#new-join .container-form .content form .ct-radio > div label {
              font-weight: normal; }
            section#new-join .container-form .content form .ct-radio > div .info {
              display: inline-block;
              margin-left: 5px; }
          section#new-join .container-form .content form .ct-radio .parsley-errors-list {
            font-weight: 100;
            list-style-type: none;
            padding-left: 0px;
            margin: 0px;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            color: #ff6060;
            letter-spacing: normal; }
        section#new-join .container-form .content form .ct {
          position: relative; }
          @media screen and (max-width: 768px) {
            section#new-join .container-form .content form .ct {
              margin-bottom: 25px; } }
          section#new-join .container-form .content form .ct.parsley-error {
            border: 1px solid red !important;
            background-color: #ffeeee; }
          section#new-join .container-form .content form .ct .parsley-error {
            border: 1px solid red;
            background-color: #ffeeee; }
          section#new-join .container-form .content form .ct .parsley-errors-list {
            font-weight: 100;
            list-style-type: none;
            padding-left: 0px;
            margin: 0px;
            position: relative;
            bottom: 0;
            margin-top: 10px;
            color: #ff6060;
            letter-spacing: normal; }
          section#new-join .container-form .content form .ct span.file-name {
            position: absolute;
            top: 0;
            bottom: 0;
            margin: auto;
            height: 20px;
            left: 15px; }
          section#new-join .container-form .content form .ct.full-file-container {
            border: 1px solid #c3c9d7;
            padding: 30px 0;
            text-align: center; }
            section#new-join .container-form .content form .ct.full-file-container .info {
              font-weight: 100;
              letter-spacing: 1px;
              margin-bottom: 10px;
              color: #babcbf;
              font-size: 14px;
              padding: 0 20px; }
            section#new-join .container-form .content form .ct.full-file-container input[type="file"] {
              position: absolute;
              height: 100%;
              width: 100%;
              left: 0;
              top: 0; }
            section#new-join .container-form .content form .ct.full-file-container button.submit-image {
              padding: 10px 20px;
              color: #fff;
              font-size: 14px;
              border-radius: 5px; }
          section#new-join .container-form .content form .ct.select:after {
            position: absolute;
            content: "▼";
            right: 10px;
            bottom: 0;
            top: 15px;
            margin: auto; }
            @media screen and (max-width: 767px) {
              section#new-join .container-form .content form .ct.select:after {
                right: 15px; } }
        section#new-join .container-form .content form input, section#new-join .container-form .content form select, section#new-join .container-form .content form textarea {
          transition: all 0.5s;
          outline: none; }
          section#new-join .container-form .content form input:disabled, section#new-join .container-form .content form select:disabled, section#new-join .container-form .content form textarea:disabled {
            background: #ececec;
            border: none; }
          section#new-join .container-form .content form input:valid, section#new-join .container-form .content form select:valid, section#new-join .container-form .content form textarea:valid {
            border: 1px solid #48af4a; }
          section#new-join .container-form .content form input:focus, section#new-join .container-form .content form select:focus, section#new-join .container-form .content form textarea:focus {
            border: 1px solid #3992f0 !important;
            box-shadow: 0px 0px 3px 1px #3992f0;
            transition: all 0.5s;
            background: transparent !important; }
            section#new-join .container-form .content form input:focus + ul, section#new-join .container-form .content form select:focus + ul, section#new-join .container-form .content form textarea:focus + ul {
              display: none; }
          section#new-join .container-form .content form input::-webkit-input-placeholder, section#new-join .container-form .content form select::-webkit-input-placeholder, section#new-join .container-form .content form textarea::-webkit-input-placeholder {
            color: #d4d4d4;
            font-style: oblique; }
        section#new-join .container-form .content form input[type=radio] {
          width: unset; }
        section#new-join .container-form .content form input,
        section#new-join .container-form .content form textarea {
          border: 1px solid #c3c9d7;
          width: 100%;
          border-radius: 0px;
          padding: 15px 10px; }
          section#new-join .container-form .content form input:focus,
          section#new-join .container-form .content form textarea:focus {
            outline: none; }
        section#new-join .container-form .content form input::-webkit-outer-spin-button,
        section#new-join .container-form .content form input::-webkit-inner-spin-button {
          -webkit-appearance: none; }
        section#new-join .container-form .content form input[type='number'] {
          -moz-appearance: textfield; }
        section#new-join .container-form .content form input[type='checkbox'] {
          display: inline-block;
          width: auto; }
        section#new-join .container-form .content form input[type='file'] {
          cursor: pointer;
          -webkit-appearance: none;
          -moz-appearance: none;
          opacity: 0; }
        section#new-join .container-form .content form select {
          cursor: pointer;
          -webkit-appearance: none;
          -moz-appearance: none;
          position: relative;
          width: 100%;
          padding: 15px 10px;
          background-color: #fff;
          border-radius: 0px;
          border: 1px solid #c3c9d7; }
        section#new-join .container-form .content form button.image {
          color: #fff;
          position: absolute;
          padding: 10px 20px;
          right: -5px;
          top: -5%;
          bottom: 0;
          border-radius: 5px;
          height: 110%;
          pointer-events: none; }
        section#new-join .container-form .content form button.submit {
          padding: 30px 0;
          width: 200px;
          margin-top: 50px;
          float: right;
          color: #ffffff;
          border-radius: 5px;
          margin-bottom: -90px; }
          @media screen and (max-width: 767px) {
            section#new-join .container-form .content form button.submit {
              margin-right: auto;
              margin-left: auto;
              display: block;
              float: none; } }
        section#new-join .container-form .content form .rating > span:hover:before,
        section#new-join .container-form .content form .rating > span:hover ~ span:before {
          content: "\2605";
          position: absolute;
          color: #2b2b2b; }
        section#new-join .container-form .content form .star {
          position: relative;
          font-size: 24px;
          top: 10px;
          left: 10px;
          color: #979797;
          unicode-bidi: bidi-override;
          direction: rtl; }
          section#new-join .container-form .content form .star > span:hover:before, section#new-join .container-form .content form .star > span:hover ~ span:before {
            content: "\2605";
            position: absolute;
            color: #2b2b2b; }
          section#new-join .container-form .content form .star span.active:before, section#new-join .container-form .content form .star span.active ~ span:before {
            content: "\2605";
            position: absolute;
            color: #2b2b2b; }
          section#new-join .container-form .content form .star > span {
            cursor: pointer;
            padding-right: 5px; }
            section#new-join .container-form .content form .star > span:last-child {
              padding-right: 0; }
            @media screen and (max-width: 768px) {
              section#new-join .container-form .content form .star > span {
                margin-right: 10px; } }
          section#new-join .container-form .content form .star .parsley-errors-list {
            top: 6px;
            font-size: 16px;
            font-weight: 100;
            list-style-type: none;
            padding-left: 0;
            margin: 0;
            position: absolute;
            color: #ff6060;
            right: 0px;
            margin: auto;
            background-color: #fff;
            letter-spacing: normal; }
            @media screen and (max-width: 768px) {
              section#new-join .container-form .content form .star .parsley-errors-list {
                left: 0;
                padding-right: 0px;
                top: auto;
                position: relative;
                margin: 20px 0; } }
          @media screen and (max-width: 768px) {
            section#new-join .container-form .content form .star {
              left: 0; } }
        section#new-join .container-form .content form .container-captcha {
          width: 100%;
          text-align: center; }
          section#new-join .container-form .content form .container-captcha ul.parsley-errors-list {
            margin: 0px;
            list-style: none;
            color: #ff6060;
            font-weight: 100;
            font-size: 16px;
            padding-left: 0px; }
          section#new-join .container-form .content form .container-captcha .g-recaptcha {
            margin-top: 40px;
            display: inline-block; }
      section#new-join .container-form .content .preview-image {
        display: none;
        width: 90%;
        margin: 0px auto;
        padding: 30px;
        position: relative;
        z-index: 10; }
        section#new-join .container-form .content .preview-image .image {
          position: relative; }
          section#new-join .container-form .content .preview-image .image .close {
            text-align: center;
            cursor: pointer;
            margin: -20px;
            position: absolute;
            height: 50px;
            width: 50px;
            right: 0;
            top: 0;
            border-radius: 50%;
            background-color: #ff6060; }
            section#new-join .container-form .content .preview-image .image .close i {
              font-size: 24px;
              color: #fafafa;
              position: relative;
              top: 10px; }
        section#new-join .container-form .content .preview-image img {
          max-width: 100%; }
      @media screen and (max-width: 768px) {
        section#new-join .container-form .content .star-container {
          text-align: center; } }
      @media screen and (max-width: 768px) {
        section#new-join .container-form .content {
          width: 100%;
          box-shadow: none; } }

.merchant-list {
  background: #f8f8f8;
  text-align: center;
  font-family: "Open Sans", Helvetica, sans-serif;
  font-weight: 300;
  line-height: 0.88;
  letter-spacing: 1.7px;
  color: #48af4a;
  padding: 2.5em; }
  .merchant-list hr {
    background: #F79520;
    border: none;
    display: inline-block;
    width: 50px;
    height: 4px; }
  .merchant-list .pure-g {
    margin-top: 3em; }
  .merchant-list .content {
    margin: 0 1.25em 5em; }
    .merchant-list .content .blog-card-title {
      text-align: left;
      font-family: "Open Sans", Helvetica, sans-serif;
      padding: 1em 1.5em;
      color: black;
      background: white;
      font-weight: 300;
      line-height: 1.42;
      letter-spacing: 2.2px; }
      .merchant-list .content .blog-card-title h4 {
        font-weight: 700;
        line-height: 1.25;
        letter-spacing: 0.8px; }
      .merchant-list .content .blog-card-title p {
        font-weight: 300;
        line-height: 1.56;
        letter-spacing: 0.8px; }
  .merchant-list .mobile {
    display: none; }
  .merchant-list .custom-nav {
    display: none; }

@media screen and (max-width: 35.5em) {
  .merchant-list {
    padding: 2em 0 5em 0; }
    .merchant-list .desktop {
      display: none; }
    .merchant-list .mobile {
      display: inherit; }
    .merchant-list .custom-nav {
      display: block; }
      .merchant-list .custom-nav #custom-prev {
        left: 3.5em;
        position: absolute; }
      .merchant-list .custom-nav #custom-next {
        right: 3.5em;
        position: absolute; }
    .merchant-list .slick-track {
      padding-top: 2em; }
    .merchant-list .slick-current {
      box-shadow: 0 2px 29px 0 rgba(0, 0, 0, 0.19); } }

@media screen and (max-width: 48em) {
  .merchant-list .blog-card-title {
    min-height: 300px; }
    .merchant-list .blog-card-title h3 {
      font-size: 0.7em; } }

.section-user-review {
  padding: 80px;
  background: #f8f8f8;
  text-align: center; }
  .section-user-review .body .slider .slick-track > .item:nth-child(3n) > .wrapper {
    background: #2c822e; }
  .section-user-review .body .slider .slick-track > .item:nth-child(3n+2) > .wrapper {
    background: #58c75b; }
  .section-user-review .body .slider .slick-track > .item:nth-child(3n+1) > .wrapper {
    background: #343b41; }
  .section-user-review .panel {
    margin: 40px 0px 40px 0px; }
  .section-user-review .head, .section-user-review .body {
    background: #ffffff;
    padding: 15px 0; }
  .section-user-review .slider
.foot {
    background-color: #fbfbfb;
    padding: 15px 0; }
  .section-user-review .title {
    font-family: 'Open Sans', sans-serif;
    color: #48af4a;
    text-transform: uppercase;
    font-size: 18px;
    margin: 30px 0 20px 0;
    letter-spacing: 1.9px; }
  .section-user-review #up {
    -ms-transform: rotate(-90deg);
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
    margin-right: 1em; }
  .section-user-review #down {
    -ms-transform: rotate(270deg);
    -webkit-transform: rotate(270deg);
    transform: rotate(270deg);
    margin-left: 1em; }
  .section-user-review svg.slick-disabled ellipse {
    stroke: #d2d2d2 !important; }
  .section-user-review svg.slick-disabled polygon {
    fill: #d2d2d2 !important; }
  .section-user-review .line {
    width: 40px;
    margin: 0 auto 0px auto;
    height: 4px; }
  .section-user-review .line.light {
    height: 2px; }
  .section-user-review .line.gradient {
    background: #48af4a; }
  .section-user-review .line.yellow {
    background: #F9AE3C; }
  .section-user-review .slider .slick-slide {
    height: auto !important; }
  .section-user-review .slider .item {
    padding: 10px; }
  .section-user-review .slider .item .wrapper {
    padding: 40px;
    position: relative;
    top: 20px; }
  .section-user-review .slider .item .wrapper.style-1 {
    background: #343b41; }
  .section-user-review .slider .item .wrapper.style-2 {
    background: #58c75b; }
  .section-user-review .slider .item .wrapper.style-3 {
    background: #2c822e; }
  .section-user-review .slider .item .wrapper h3.user-testimonial {
    object-fit: contain;
    font-family: 'Open Sans', sans-serif;
    font-size: 0.6em;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.56;
    letter-spacing: 0.8px;
    text-align: center;
    color: #ffffff;
    height: 70px; }
    @media screen and (max-width: 768px) {
      .section-user-review .slider .item .wrapper h3.user-testimonial {
        height: 120px; } }
    @media screen and (max-width: 480px) {
      .section-user-review .slider .item .wrapper h3.user-testimonial {
        height: 70px; } }
  .section-user-review .slick-slider .slick-list, .section-user-review .slick-slider .slick-track {
    margin: 0 -20px;
    position: relative;
    left: 10px; }
  .section-user-review .slider .item .wrapper h3.user-name {
    object-fit: contain;
    font-family: 'Open Sans', sans-serif;
    font-size: 12.5px;
    font-style: normal;
    font-stretch: normal;
    line-height: 1;
    letter-spacing: 0.8px;
    text-align: center;
    font-weight: bold;
    color: #ffffff; }
  .section-user-review .slider .item .wrapper h3.user-name > span {
    object-fit: contain;
    font-family: 'Open Sans', sans-serif;
    font-size: 12.8px;
    font-style: normal;
    font-stretch: normal;
    line-height: 1;
    letter-spacing: 0.8px;
    text-align: center;
    font-weight: 300;
    color: #ffffff; }
  .section-user-review .slider .item .wrapper img {
    max-width: 80px;
    margin: -60px auto 25px auto;
    z-index: 999;
    border-radius: 50%; }
  .section-user-review .slider .item .wrapper .line {
    margin-top: 50px;
    margin-bottom: 30px; }
  .section-user-review .slick-dotted.slick-slider {
    margin-bottom: 50px; }
  .section-user-review .slick-dots li button:before {
    font-size: 10px;
    margin-top: 20px;
    line-height: 20px; }
  .section-user-review .slick-dots li.slick-active button:before {
    opacity: .75;
    color: #99CC28; }
  .section-user-review .foot .wrapper .arrow-prev,
  .section-user-review .foot .wrapper .arrow-next {
    max-width: 35px;
    cursor: pointer;
    margin: 10px 5px 5px 5px; }
  .section-user-review .slick-slide {
    outline: none; }

@media screen and (max-width: 480px) {
  .section-user-review {
    padding: 0; }
    .section-user-review .slick-slider .slick-list, .section-user-review .slick-slider .slick-track {
      margin: 0;
      position: relative;
      left: 0px; } }

.section-our-values {
  background: #fafafa;
  padding: 80px; }
  .section-our-values .title {
    color: #48af4a;
    text-align: center;
    text-transform: uppercase;
    font-size: 17px;
    line-height: 30px; }
  .section-our-values .line.gradient {
    background: #48af4a;
    width: 50px;
    height: 3px; }
  .section-our-values .list {
    margin-top: 30px; }
    .section-our-values .list img {
      max-width: 250px;
      display: block; }
    .section-our-values .list h4 {
      text-transform: uppercase;
      font-weight: 700;
      line-height: 20px; }
    .section-our-values .list p {
      padding: 20px;
      font-size: 14px;
      color: #9b9b9b;
      font-weight: 300;
      margin-top: -5px; }
    .section-our-values .list .line.gradient {
      background: #48af4a;
      width: 30px;
      height: 3px;
      margin-top: -10px; }

@media screen and (max-width: 768px) {
  .section-our-values .list {
    display: inline-block;
    padding: 0px;
    width: 100%; }
    .section-our-values .list h4 {
      padding: 0 20px;
      padding-bottom: 10px;
      height: auto;
      font-size: 15.5px; } }

@media screen and (max-width: 400px) {
  .section-our-values {
    padding: 80px 15px; }
    .section-our-values .list p {
      padding: 20px;
      max-width: 350px; } }

@media screen and (max-width: 320px) {
  .section-our-values {
    padding: 0; } }

.section-how-to-use {
  overflow: hidden;
  position: relative;
  padding: 50px;
  height: 620px;
  background-size: cover !important; }
  .section-how-to-use .text-mobile {
    display: none;
    text-align: center; }
  .section-how-to-use .text-mobile .line.gradient {
    width: 50px;
    height: 3px;
    margin-top: 0px;
    background: #48af4a;
    margin-left: auto;
    margin-right: auto; }
  .section-how-to-use .text h5 {
    margin-top: 100px;
    color: #48af4a;
    font-weight: 900; }
  .section-how-to-use .text .line.gradient {
    width: 50px;
    height: 3px;
    margin-top: 0px;
    background: #48af4a; }
  .section-how-to-use .phone-base {
    display: block;
    margin-right: auto;
    margin-left: auto;
    top: 70px;
    position: relative; }
  .section-how-to-use .slider .item-text {
    height: 250px; }
    .section-how-to-use .slider .item-text h2 {
      text-transform: uppercase;
      margin-top: 20px;
      margin-left: 0px;
      font-weight: 300;
      font-size: 2em; }
  .section-how-to-use .slider .item .wrapper {
    width: 100%; }
  .section-how-to-use .slider-image .slick-dots li {
    display: block; }
  .section-how-to-use .slider-image .slick-dots li.slick-active button:before {
    color: #F9AE3C; }
  .section-how-to-use .slider-image .slick-dots li button:before {
    font-size: 13px; }
  .section-how-to-use .slider .slider-text.title {
    position: relative;
    float: right;
    left: -265px;
    top: -20px;
    font-size: 36px;
    margin-top: -320px;
    font-weight: 300; }
  .section-how-to-use .slider .slider-text.desc {
    margin-left: 380px;
    position: relative;
    top: -280px;
    left: 45px;
    margin-bottom: -33px;
    line-height: 1.56;
    font-weight: 300;
    letter-spacing: 1.7px; }
  .section-how-to-use .slider-image {
    position: relative;
    z-index: 99 !important;
    margin: -405px auto -98px;
    text-align: center;
    display: block;
    width: 265px; }
    .section-how-to-use .slider-image .item img {
      width: 100%; }
  .section-how-to-use .slick-arrow {
    cursor: pointer; }
  .section-how-to-use ul.slick-dots {
    position: relative;
    top: -350px;
    left: -90px; }
  .section-how-to-use ul.nextprev {
    position: absolute;
    display: inline-block;
    list-style-type: none;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%); }
  .section-how-to-use ul li {
    margin-top: 10px; }
  .section-how-to-use ul li img {
    cursor: pointer; }

.new-section-how-to-use {
  background-size: 130px;
  background-position: bottom;
  width: 90%;
  max-width: 1170px;
  margin: auto;
  text-align: center;
  position: relative;
  margin-bottom: 150px; }
  .new-section-how-to-use h3.title {
    font-family: Lato,Helvetica,sans-serif;
    z-index: 1;
    color: #b62025;
    font-size: 18px;
    letter-spacing: 1.5px;
    position: absolute;
    width: 76%;
    text-align: center;
    right: 0;
    margin-top: 45px; }
    .new-section-how-to-use h3.title:after {
      content: "";
      position: absolute;
      width: 46px;
      height: 3px;
      background-color: #b62025;
      bottom: -20px;
      left: 0;
      right: 0;
      margin: auto; }
      @media screen and (max-width: 767px) {
        .new-section-how-to-use h3.title:after {
          bottom: auto;
          top: 35px; } }
    @media screen and (max-width: 768px) {
      .new-section-how-to-use h3.title {
        font-size: 14px;
        width: 70%; } }
    @media screen and (max-width: 767px) {
      .new-section-how-to-use h3.title {
        position: relative;
        width: 100%;
        margin: 0;
        background-color: #f8f8f8;
        padding-bottom: 50px; } }
    @media screen and (min-width: 2560px) {
      .new-section-how-to-use h3.title {
        width: 61%; } }
  .new-section-how-to-use .download-section {
    display: table;
    position: absolute;
    width: 76.5%;
    height: 105px;
    text-align: center;
    right: 0;
    bottom: 0; }
    .new-section-how-to-use .download-section .button-download {
      display: table-cell;
      vertical-align: middle; }
      .new-section-how-to-use .download-section .button-download img {
        max-width: 108px; }
      .new-section-how-to-use .download-section .button-download a:first-child img {
        margin-right: 30px; }
    @media screen and (max-width: 768px) {
      .new-section-how-to-use .download-section {
        width: 70.5%; } }
    @media screen and (max-width: 767px) {
      .new-section-how-to-use .download-section {
        display: none; } }
  .new-section-how-to-use .right-phone {
    position: relative;
    width: 73%;
    height: 100%;
    background: #ffffff;
    margin: auto; }
    @media screen and (max-width: 768px) {
      .new-section-how-to-use .right-phone {
        width: 85%; } }
    @media screen and (max-width: 767px) {
      .new-section-how-to-use .right-phone {
        display: none; } }
  .new-section-how-to-use .content {
    position: absolute;
    width: 63.5%;
    right: 0;
    top: 0;
    margin: auto;
    text-align: center;
    color: #ffffff;
    height: 240px;
    background-color: #b62025;
    bottom: 0; }
    .new-section-how-to-use .content .table-display {
      position: relative;
      left: 35px;
      display: table;
      height: 100%;
      width: 70%; }
      .new-section-how-to-use .content .table-display .mid-table {
        display: table-cell;
        vertical-align: middle; }
        .new-section-how-to-use .content .table-display .mid-table .number {
          position: relative;
          margin-bottom: 30px;
          font-size: 36px;
          height: 40px;
          overflow: hidden; }
          .new-section-how-to-use .content .table-display .mid-table .number div {
            position: absolute;
            height: 100%;
            width: 100%;
            transition: all 0.5s 0.2s;
            text-align: center;
            opacity: 0; }
          .new-section-how-to-use .content .table-display .mid-table .number div.active {
            opacity: 1;
            transition: all 0.5s 0s; }
          @media screen and (max-width: 767px) {
            .new-section-how-to-use .content .table-display .mid-table .number {
              margin-bottom: 5px;
              font-size: 28px;
              display: none; } }
        .new-section-how-to-use .content .table-display .mid-table .slide-content {
          font-family: "Open Sans", Helvetica, sans-serif;
          font-size: 16px;
          letter-spacing: 1.8px;
          font-weight: 100;
          margin: auto;
          height: 115px;
          padding-bottom: 30px;
          max-width: 350px; }
          .new-section-how-to-use .content .table-display .mid-table .slide-content .dot-title {
            padding-right: 5px; }
            @media screen and (min-width: 768px) {
              .new-section-how-to-use .content .table-display .mid-table .slide-content .dot-title {
                display: none; } }
          .new-section-how-to-use .content .table-display .mid-table .slide-content .slick-dots {
            bottom: auto;
            position: relative;
            margin-top: 20px; }
          .new-section-how-to-use .content .table-display .mid-table .slide-content .slide-prevArrow {
            z-index: 5;
            cursor: pointer;
            padding: 10px 12px;
            border: 3px solid #ffffff;
            top: 0;
            font-size: 12px;
            border-radius: 50%;
            position: absolute;
            display: block;
            left: -69px;
            background: transparent; }
            @media screen and (max-width: 768px) {
              .new-section-how-to-use .content .table-display .mid-table .slide-content .slide-prevArrow {
                left: -50px; } }
            @media screen and (max-width: 767px) {
              .new-section-how-to-use .content .table-display .mid-table .slide-content .slide-prevArrow {
                left: 0;
                bottom: -13px;
                top: auto; } }
            .new-section-how-to-use .content .table-display .mid-table .slide-content .slide-prevArrow .rotate-arrow {
              transform: rotate(180deg); }
          .new-section-how-to-use .content .table-display .mid-table .slide-content .slide-nextArrow {
            z-index: 5;
            cursor: pointer;
            padding: 10px 12px;
            border: 3px solid #ffffff;
            top: 0;
            font-size: 12px;
            border-radius: 50%;
            position: absolute;
            display: block;
            right: -69px;
            background: transparent; }
            @media screen and (max-width: 768px) {
              .new-section-how-to-use .content .table-display .mid-table .slide-content .slide-nextArrow {
                right: -50px; } }
            @media screen and (max-width: 767px) {
              .new-section-how-to-use .content .table-display .mid-table .slide-content .slide-nextArrow {
                right: 0;
                bottom: -13px;
                top: auto; } }
          @media screen and (max-width: 767px) {
            .new-section-how-to-use .content .table-display .mid-table .slide-content {
              width: 85%;
              font-size: 14px; } }
          @media screen and (max-width: 768px) {
            .new-section-how-to-use .content .table-display .mid-table .slide-content {
              max-width: 300px; } }
        @media screen and (max-width: 767px) {
          .new-section-how-to-use .content .table-display .mid-table {
            display: inline-block;
            width: 100%; } }
      @media screen and (max-width: 768px) {
        .new-section-how-to-use .content .table-display {
          left: 46px; } }
      @media screen and (max-width: 767px) {
        .new-section-how-to-use .content .table-display {
          width: 100%;
          left: 0;
          display: inline-block; } }
    .new-section-how-to-use .content .phoneSlide-dots {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      margin-top: 0px;
      margin-bottom: 0px;
      padding-left: 0px; }
      .new-section-how-to-use .content .phoneSlide-dots li {
        cursor: pointer;
        list-style-type: none;
        height: 10px;
        width: 10px;
        display: inline-block;
        border-radius: 50%;
        margin-right: 10px;
        border: 1px solid #ffffff;
        transition: all 0.5s; }
        .new-section-how-to-use .content .phoneSlide-dots li:last-child {
          margin-right: 0px; }
        .new-section-how-to-use .content .phoneSlide-dots li button {
          display: none; }
      .new-section-how-to-use .content .phoneSlide-dots li.slick-active {
        background-color: #ffffff;
        transition: all 0.5s; }
      @media screen and (max-width: 767px) {
        .new-section-how-to-use .content .phoneSlide-dots {
          margin-top: 25px; } }
    @media screen and (max-width: 767px) {
      .new-section-how-to-use .content {
        position: relative;
        width: 100%;
        display: inline-block;
        height: auto;
        padding: 30px 0;
        background-color: #b62025;
        margin-bottom: -2px; } }
    @media screen and (min-width: 2560px) {
      .new-section-how-to-use .content {
        width: 61%; } }
  .new-section-how-to-use .left-phone {
    float: left;
    position: relative;
    height: 100%;
    width: 50%;
    background-size: cover;
    background-position: center;
    left: 0;
    top: 0; }
    .new-section-how-to-use .left-phone .half-phone {
      max-width: 290px;
      position: absolute;
      width: 70%;
      left: 0;
      right: 0;
      bottom: -50px;
      margin: auto; }
      .new-section-how-to-use .left-phone .half-phone .new-slide-phone {
        margin: auto;
        right: 0;
        position: absolute;
        width: 85%;
        z-index: 2;
        max-width: 429px;
        left: 0;
        bottom: 0;
        top: 60px; }
        .new-section-how-to-use .left-phone .half-phone .new-slide-phone img {
          width: 100%;
          margin: auto; }
        @media screen and (max-width: 767px) {
          .new-section-how-to-use .left-phone .half-phone .new-slide-phone {
            height: 83%;
            bottom: 0px;
            top: 45px; } }
      @media screen and (max-width: 767px) {
        .new-section-how-to-use .left-phone .half-phone.landscape-mb {
          width: 100%; }
          .new-section-how-to-use .left-phone .half-phone.landscape-mb > img {
            visibility: hidden;
            opacity: 0;
            width: 100% !important; } }
      @media screen and (max-width: 767px) {
        .new-section-how-to-use .left-phone .half-phone > img {
          bottom: -15px; } }
      .new-section-how-to-use .left-phone .half-phone img {
        position: relative;
        z-index: 2;
        width: 95%; }
      @media screen and (max-width: 768px) {
        .new-section-how-to-use .left-phone .half-phone {
          width: 85%;
          bottom: -12%;
          right: auto; } }
      @media screen and (max-width: 767px) {
        .new-section-how-to-use .left-phone .half-phone {
          position: relative;
          margin-bottom: -13%;
          z-index: 0;
          right: 0;
          max-width: 300px;
          width: 60%; } }
      @media screen and (max-width: 320px) {
        .new-section-how-to-use .left-phone .half-phone {
          margin-bottom: -12%; } }
    @media screen and (max-width: 767px) {
      .new-section-how-to-use .left-phone.landscape-mb {
        min-height: 200px; } }
    @media screen and (max-width: 767px) {
      .new-section-how-to-use .left-phone {
        position: relative;
        width: 100%;
        background-image: none !important;
        height: auto;
        margin-top: 40px; } }
  @media screen and (max-width: 768px) {
    .new-section-how-to-use {
      margin-top: 150px !important;
      margin-bottom: 120px;
      display: inline-block;
      width: 100%; } }
  @media screen and (min-width: 768px) {
    .new-section-how-to-use {
      margin-top: 100px;
      height: 448px; } }

@media screen and (max-width: 768px) {
  .section-how-to-use {
    height: 850px !important;
    padding: 50px 10px !important;
    background-size: cover !important; }
    .section-how-to-use .phone-wrapper {
      top: auto !important; }
    .section-how-to-use .overlay {
      position: relative;
      width: 100%;
      max-width: 400px;
      height: 100%; }
    .section-how-to-use .slick-slider {
      display: table;
      table-layout: fixed;
      width: 100%; }
    .section-how-to-use .slider-image {
      width: 265px;
      top: -45px;
      margin: -425px auto -98px; }
    .section-how-to-use .text-mobile {
      text-align: center;
      display: block;
      margin-right: auto;
      margin-left: auto; }
    .section-how-to-use .text {
      margin: 0;
      left: 0;
      position: absolute;
      text-align: center; }
    .section-how-to-use .text h5 {
      margin-top: 0 !important;
      display: block;
      color: #48af4a;
      font-weight: 900; }
    .section-how-to-use .text .line.gradient {
      width: 50px;
      height: 3px;
      margin: 0px auto;
      display: block;
      background: #48af4a; }
    .section-how-to-use .phone-wrapper {
      margin: 0 auto;
      left: 0;
      position: absolute;
      bottom: 0;
      width: 100%; }
    .section-how-to-use .phone-base {
      display: block;
      top: 0px;
      margin: 0px auto;
      position: relative; }
    .section-how-to-use .slider .item-text {
      margin-top: 0px;
      height: 200px; }
    .section-how-to-use .slider .item-text h2 {
      margin-top: 20px;
      margin-left: 0px;
      font-weight: 300;
      font-size: 1.2em; }
    .section-how-to-use .slider .item .wrapper {
      width: 100%; }
    .section-how-to-use .slider-image ul {
      display: none !important; }
    .section-how-to-use .slider-image .slick-dots {
      position: relative; }
    .section-how-to-use .slider-image .slick-dots li {
      display: block; }
    .section-how-to-use .slider-image .slick-dots li.slick-active button:before {
      color: #F9AE3C; }
    .section-how-to-use .slider-image .slick-dots li button:before {
      font-size: 13px; }
    .section-how-to-use .slider .slider-text.title {
      position: relative;
      float: right;
      left: -265px;
      top: -20px;
      font-size: 36px;
      margin-top: -320px;
      font-weight: 300; }
    .section-how-to-use .slider .slider-text.desc {
      margin-left: 380px;
      position: relative;
      top: -280px;
      left: 45px;
      margin-bottom: -33px;
      line-height: 1.56;
      font-weight: 300;
      letter-spacing: 1.7px; }
    .section-how-to-use ul.slick-dots {
      bottom: 0;
      top: 0;
      left: 0; }
    .section-how-to-use .arrow--wrapper {
      width: 100%;
      position: absolute;
      left: 0;
      top: 37%; }
    .section-how-to-use ul.nextprev {
      position: relative;
      top: 0;
      left: 0;
      display: block;
      margin: 0;
      padding: 0;
      list-style-type: none; }
    .section-how-to-use ul.nextprev > li:nth-child(1) {
      left: 0; }
    .section-how-to-use ul.nextprev > li:nth-child(2) {
      right: 0; }
    .section-how-to-use ul.nextprev > li {
      margin: 0 !important;
      display: inline-block;
      position: absolute;
      -webkit-transform: rotate(-90deg);
      -moz-transform: rotate(-90deg);
      -o-transform: rotate(-90deg);
      transform: rotate(-90deg); }
    .section-how-to-use ul li img {
      cursor: pointer; } }

@media screen and (max-width: 400px) {
  .section-how-to-use {
    min-height: 835px; } }

@media screen and (max-width: 320px) {
  .section-how-to-use {
    min-height: 835px; } }

/**
 * Author: JR
 * Julian Rulliansyah
 * @JRulliansyah
 * */
.office {
  background: #f8f8f8;
  margin: 2em auto; }
  .office .list {
    margin-right: 2em;
    margin-bottom: 2em; }
  .office header {
    margin-top: 90px; }
  .office header .text-wrapper {
    margin-top: 160px; }
  .office header .text-wrapper h4 {
    font-weight: 300;
    margin-top: -15px; }
  .office section.office {
    margin-top: -5px;
    padding: 40px;
    background: #f8f8f8; }
  .office section.office .wrapper {
    background: white;
    margin-top: 70px;
    padding: 50px; }
  .office section.office .wrapper .headquarters h2.title {
    font-weight: 300; }
  .office section.office .wrapper .branch .line {
    height: 4px;
    width: 45px;
    background: #48af4a;
    position: relative;
    top: 33px;
    left: -70px; }
  .office section.office .wrapper .branch .list {
    position: relative;
    margin-bottom: 40px; }
  .office section.office .wrapper .branch .list h4.region {
    font-weight: 700;
    font-size: 17px; }
  .office section.office .wrapper .branch .list p.address {
    font-weight: 300;
    font-size: 14px; }
  .office section.office .wrapper .branch .list a.view-on-map {
    font-size: 12px;
    color: #48af4a;
    font-weight: 700; }
  .office section.office .wrapper .branch .list a.view-on-map > img {
    max-width: 15px;
    position: relative;
    top: 1.5px;
    left: 5px; }
  .office .map-overlay {
    background: #48af4a;
    height: 400px;
    width: 107%;
    margin-top: -495px;
    position: relative;
    left: 120px; }
  .office #googleMap {
    width: 110%;
    height: 400px;
    z-index: 99 !important;
    position: relative;
    top: -80px;
    left: 90px;
    overflow: hidden; }

@media only screen and (max-width: 480px) {
  .contact {
    background: white; }
    .contact header {
      margin-top: -50px; }
    .contact header .text-wrapper {
      margin-top: 160px; }
    .contact header .text-wrapper h4 {
      font-weight: 300;
      margin-top: -15px; }
    .contact section.office {
      margin-top: -5px;
      padding: 0px;
      background: #f8f8f8; }
    .contact section.office .wrapper {
      background: white;
      margin-top: 70px;
      padding: 20px; }
    .contact section.office .wrapper .headquarters h2.title {
      font-weight: 300; }
    .contact section.office .wrapper .branch .line {
      height: 4px;
      width: 45px;
      background: #48af4a;
      position: relative;
      top: 33px;
      left: -70px; }
    .contact section.office .wrapper .branch .list {
      position: relative;
      margin-bottom: 40px; }
    .contact section.office .wrapper .branch .list h4.region {
      font-weight: 700;
      font-size: 17px; }
    .contact section.office .wrapper .branch .list p.address {
      font-weight: 300;
      font-size: 14px; }
    .contact section.office .wrapper .branch .list a.view-on-map {
      font-size: 12px;
      color: #48af4a;
      font-weight: 700; }
    .contact section.office .wrapper .branch .list a.view-on-map > img {
      max-width: 15px;
      position: relative;
      top: 1.5px;
      left: 5px; }
    .contact .map-overlay {
      display: none; }
    .contact #googleMap {
      width: 100%;
      height: 220px;
      z-index: 99 !important;
      position: relative;
      top: 0px;
      left: 0px;
      overflow: hidden; }
    .contact .header-img {
      width: 100%;
      margin-top: 20px;
      margin-bottom: 40px; } }

.how-it-works--wrapper {
  background: #f8f8f8;
  margin: 80px 0; }
  .how-it-works--wrapper .section--title {
    font-size: 18px;
    font-weight: bold;
    text-transform: uppercase;
    color: #48af4a;
    left: -50px;
    line-height: 25px;
    position: relative;
    margin-left: 3em; }
    @media screen and (max-width: 35.5em) {
      .how-it-works--wrapper .section--title {
        margin-bottom: 0px; } }
  .how-it-works--wrapper .section--title:before {
    content: "";
    position: absolute;
    left: -5em;
    top: 0.6em;
    padding-right: 45px;
    height: 3px;
    margin: 0;
    background-color: #48af4a;
    z-index: 10; }
  .how-it-works--wrapper .container {
    padding: 100px 0; }
    .how-it-works--wrapper .container .how--title {
      font-family: 'Lato', sans-serif;
      font-size: 36px;
      font-weight: 300;
      line-height: 1.22;
      letter-spacing: 2px;
      color: #343b41;
      margin: 0px; }
    .how-it-works--wrapper .container .how--desc {
      font-family: 'Open Sans', sans-serif;
      font-size: 16px;
      font-weight: 300;
      line-height: 1.62;
      letter-spacing: 1.5px;
      color: #485460; }
  .how-it-works--wrapper .layer {
    background: #ffffff;
    padding: 30px 60px 140px; }
    .how-it-works--wrapper .layer .rect--primary {
      background: #48af4a;
      left: -125px; }
      .how-it-works--wrapper .layer .rect--primary .img--wrapper {
        left: 0; }
      .how-it-works--wrapper .layer .rect--primary img {
        float: left; }
        @media screen and (max-width: 768px) {
          .how-it-works--wrapper .layer .rect--primary img {
            width: 100%; } }
    .how-it-works--wrapper .layer .rect--secondary {
      left: 63%;
      background: #e8ebf2; }
      .how-it-works--wrapper .layer .rect--secondary img {
        float: right; }
        @media screen and (max-width: 768px) {
          .how-it-works--wrapper .layer .rect--secondary img {
            width: 100%; } }
    .how-it-works--wrapper .layer .rect {
      padding: 40px 0;
      width: 310px;
      height: 450px;
      position: relative; }
    .how-it-works--wrapper .layer .desc--pad {
      padding: 90px 0 70px; }
    .how-it-works--wrapper .layer .odd--row, .how-it-works--wrapper .layer .even--row {
      margin-bottom: 100px; }
      @media screen and (max-width: 768px) {
        .how-it-works--wrapper .layer .odd--row, .how-it-works--wrapper .layer .even--row {
          margin-bottom: 30px; } }
    .how-it-works--wrapper .layer .odd--row .desc--pad {
      padding: 0px 50px 0px 0px;
      display: flex;
      flex-direction: column;
      align-self: center; }
    .how-it-works--wrapper .layer .even--row .desc--pad {
      padding: 0px 0px 0px 50px;
      display: flex;
      align-self: center;
      flex-direction: column; }
  .how-it-works--wrapper figure.img--wrapper {
    margin: 0;
    width: 200%;
    position: absolute;
    right: 0;
    height: 360px;
    overflow: hidden; }
    .how-it-works--wrapper figure.img--wrapper img {
      width: auto;
      position: relative; }
  .how-it-works--wrapper figure.hand-ss {
    background: #fff; }
    .how-it-works--wrapper figure.hand-ss img {
      max-height: 27em;
      width: auto;
      right: 0;
      margin: 0 auto;
      position: absolute;
      left: 0; }

@media (max-width: 768px) {
  .how-it-works--wrapper {
    margin: 0; }
    .how-it-works--wrapper .container {
      padding: 0; }
    .how-it-works--wrapper .layer {
      padding: 0 15px; }
      .how-it-works--wrapper .layer .desc--pad {
        padding: 30px 30px 40px 30px !important;
        padding-right: 30px !important;
        background: #fff;
        position: relative;
        bottom: 0;
        left: -15px;
        margin-left: 0;
        margin-right: 40px;
        margin-top: 150px;
        width: 85%; }
        .how-it-works--wrapper .layer .desc--pad .how--title {
          font-size: 24px; }
        .how-it-works--wrapper .layer .desc--pad .how--desc {
          font-size: 12px; }
      .how-it-works--wrapper .layer .rect {
        left: 0;
        height: 100%;
        padding: 0;
        width: 100%; }
        .how-it-works--wrapper .layer .rect .img--wrapper {
          width: 100%;
          height: 250px; }
          .how-it-works--wrapper .layer .rect .img--wrapper img {
            margin: 0 !important; }
    .how-it-works--wrapper .mobile--wrapper {
      margin: 0 -25px;
      position: relative; }
      .how-it-works--wrapper .mobile--wrapper [class*='pure-u'] {
        width: 100%;
        left: 0; }
    .how-it-works--wrapper .mobile--wrapper:nth-child(2) .img--wrapper img {
      top: 25px; }
    .how-it-works--wrapper .section--title:before {
      left: -3em !important;
      top: .3em !important; } }

.gallery--wrapper {
  background: #f8f8f8;
  padding: 80px 0; }
  .gallery--wrapper #instafeed {
    background: #2b2b2b; }
    .gallery--wrapper #instafeed .pure-u-1 {
      overflow: hidden; }
  .gallery--wrapper .instafeed-wrapper img {
    width: auto;
    height: 390px; }
    @media screen and (max-width: 768px) {
      .gallery--wrapper .instafeed-wrapper img {
        width: 100%;
        height: auto; } }
  .gallery--wrapper .instafeed-wrapper a {
    display: block; }
  .gallery--wrapper .instafeed-wrapper .ig--picture {
    -webkit-transition: opacity .3s ease-in-out;
    -moz-transition: opacity .3s ease-in-out;
    transition: opacity .3s ease-in-out; }
  .gallery--wrapper .instafeed-wrapper .ig--picture:hover {
    opacity: .7; }
  .gallery--wrapper .instafeed-wrapper .discover {
    background: #48af4a; }
    .gallery--wrapper .instafeed-wrapper .discover .discover--title {
      margin: 0; }
    .gallery--wrapper .instafeed-wrapper .discover .discover--title a {
      padding: 40px;
      color: #fff;
      font-family: 'Open Sans', sans-serif;
      font-size: 18px;
      font-weight: bold;
      line-height: 0.8;
      letter-spacing: 3.3px;
      text-transform: uppercase;
      text-align: right; }
    .gallery--wrapper .instafeed-wrapper .discover .discover--title a:after {
      content: url(https://d3naj63yelf4gk.cloudfront.net/dist/img/header/arrow.png);
      position: relative;
      left: 10px; }
  .gallery--wrapper .instafeed-wrapper .title {
    background: #fff; }
    .gallery--wrapper .instafeed-wrapper .title .section-title {
      padding: 40px;
      font-family: 'Open Sans', sans-serif;
      font-size: 18px;
      font-weight: bold;
      line-height: 0.78;
      letter-spacing: 1.9px;
      color: #48af4a;
      text-transform: uppercase;
      left: -35px;
      position: relative; }
    .gallery--wrapper .instafeed-wrapper .title .section-title:before {
      content: url(https://d3naj63yelf4gk.cloudfront.net/dist/img/line.svg);
      position: relative;
      left: -30px;
      top: -5px; }

#promo-banner {
  padding: 2.5em 0;
  position: relative;
  background: #F8F8F8;
  min-height: 400px; }
  #promo-banner .mobile {
    display: none; }
  #promo-banner .img-slide picture {
    width: 100%; }
  #promo-banner .img-slide img {
    min-width: 100%;
    width: 100%; }
  #promo-banner .slick-vertical .slick-slide {
    border: 0; }
  #promo-banner .slide-mobile {
    position: relative;
    z-index: 10;
    display: none; }
    #promo-banner .slide-mobile svg.slick-disabled ellipse {
      stroke: #d2d2d2 !important; }
    #promo-banner .slide-mobile svg.slick-disabled polygon {
      fill: #d2d2d2 !important; }
  #promo-banner .slick-list {
    height: 400px; }
    #promo-banner .slick-list picture {
      background: #333; }
  #promo-banner [class*="pure-u"] {
    position: relative; }
  #promo-banner .detail {
    background: #343b41;
    padding: 3em;
    width: 100%;
    padding-bottom: 2.7em;
    top: 50%;
    position: absolute;
    height: 250px;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%); }
    #promo-banner .detail .custom-nav {
      position: absolute;
      left: 50%;
      top: 50%;
      -webkit-transform: translate(-50%, -50%);
      -moz-transform: translate(-50%, -50%);
      -o-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%); }
      #promo-banner .detail .custom-nav .slick-disabled {
        -webkit-filter: grayscale(100%);
        filter: grayscale(100%);
        pointer-events: none; }
      #promo-banner .detail .custom-nav img,
      #promo-banner .detail .custom-nav svg {
        display: block;
        margin: 10px 0;
        cursor: pointer; }

@media screen and (max-width: 35.5em) {
  #promo-banner {
    margin: 0;
    padding: 5em 0;
    min-height: 0px; }
    #promo-banner .mobile {
      display: inherit; }
    #promo-banner .desktop {
      display: none; }
    #promo-banner .container {
      padding: 0; }
    #promo-banner .slide-mobile {
      margin-right: auto;
      margin-left: auto;
      display: inherit;
      text-align: center;
      margin-top: 3em; }
      #promo-banner .slide-mobile #up {
        -ms-transform: rotate(-90deg);
        -webkit-transform: rotate(-90deg);
        transform: rotate(-90deg);
        margin-right: 1em; }
      #promo-banner .slide-mobile #down {
        -ms-transform: rotate(270deg);
        -webkit-transform: rotate(270deg);
        transform: rotate(270deg);
        margin-left: 1em; }
    #promo-banner .desktop {
      display: none; }
    #promo-banner .detail {
      background: inherit;
      padding: 0;
      margin: 0;
      height: 0; } }

.how-it-works--wrapper {
  background: #f8f8f8;
  margin: 80px 0; }
  .how-it-works--wrapper .section--title {
    font-size: 18px;
    font-weight: bold;
    text-transform: uppercase;
    color: #48af4a;
    left: -50px;
    line-height: 25px;
    position: relative;
    margin-left: 3em; }
    @media screen and (max-width: 35.5em) {
      .how-it-works--wrapper .section--title {
        margin-bottom: 0px; } }
  .how-it-works--wrapper .section--title:before {
    content: "";
    position: absolute;
    left: -5em;
    top: 0.6em;
    padding-right: 45px;
    height: 3px;
    margin: 0;
    background-color: #48af4a;
    z-index: 10; }
  .how-it-works--wrapper .container {
    padding: 100px 0; }
    .how-it-works--wrapper .container .how--title {
      font-family: 'Lato', sans-serif;
      font-size: 36px;
      font-weight: 300;
      line-height: 1.22;
      letter-spacing: 2px;
      color: #343b41;
      margin: 0px; }
    .how-it-works--wrapper .container .how--desc {
      font-family: 'Open Sans', sans-serif;
      font-size: 16px;
      font-weight: 300;
      line-height: 1.62;
      letter-spacing: 1.5px;
      color: #485460; }
  .how-it-works--wrapper .layer {
    background: #ffffff;
    padding: 30px 60px 140px; }
    .how-it-works--wrapper .layer .rect--primary {
      background: #48af4a;
      left: -125px; }
      .how-it-works--wrapper .layer .rect--primary .img--wrapper {
        left: 0; }
      .how-it-works--wrapper .layer .rect--primary img {
        float: left; }
        @media screen and (max-width: 768px) {
          .how-it-works--wrapper .layer .rect--primary img {
            width: 100%; } }
    .how-it-works--wrapper .layer .rect--secondary {
      left: 63%;
      background: #e8ebf2; }
      .how-it-works--wrapper .layer .rect--secondary img {
        float: right; }
        @media screen and (max-width: 768px) {
          .how-it-works--wrapper .layer .rect--secondary img {
            width: 100%; } }
    .how-it-works--wrapper .layer .rect {
      padding: 40px 0;
      width: 310px;
      height: 450px;
      position: relative; }
    .how-it-works--wrapper .layer .desc--pad {
      padding: 90px 0 70px; }
    .how-it-works--wrapper .layer .odd--row, .how-it-works--wrapper .layer .even--row {
      margin-bottom: 100px; }
      @media screen and (max-width: 768px) {
        .how-it-works--wrapper .layer .odd--row, .how-it-works--wrapper .layer .even--row {
          margin-bottom: 30px; } }
    .how-it-works--wrapper .layer .odd--row .desc--pad {
      padding: 0px 50px 0px 0px;
      display: flex;
      flex-direction: column;
      align-self: center; }
    .how-it-works--wrapper .layer .even--row .desc--pad {
      padding: 0px 0px 0px 50px;
      display: flex;
      align-self: center;
      flex-direction: column; }
  .how-it-works--wrapper figure.img--wrapper {
    margin: 0;
    width: 200%;
    position: absolute;
    right: 0;
    height: 360px;
    overflow: hidden; }
    .how-it-works--wrapper figure.img--wrapper img {
      width: auto;
      position: relative; }
  .how-it-works--wrapper figure.hand-ss {
    background: #fff; }
    .how-it-works--wrapper figure.hand-ss img {
      max-height: 27em;
      width: auto;
      right: 0;
      margin: 0 auto;
      position: absolute;
      left: 0; }

@media (max-width: 768px) {
  .how-it-works--wrapper {
    margin: 0; }
    .how-it-works--wrapper .container {
      padding: 0; }
    .how-it-works--wrapper .layer {
      padding: 0 15px; }
      .how-it-works--wrapper .layer .desc--pad {
        padding: 30px 30px 40px 30px !important;
        padding-right: 30px !important;
        background: #fff;
        position: relative;
        bottom: 0;
        left: -15px;
        margin-left: 0;
        margin-right: 40px;
        margin-top: 150px;
        width: 85%; }
        .how-it-works--wrapper .layer .desc--pad .how--title {
          font-size: 24px; }
        .how-it-works--wrapper .layer .desc--pad .how--desc {
          font-size: 12px; }
      .how-it-works--wrapper .layer .rect {
        left: 0;
        height: 100%;
        padding: 0;
        width: 100%; }
        .how-it-works--wrapper .layer .rect .img--wrapper {
          width: 100%;
          height: 250px; }
          .how-it-works--wrapper .layer .rect .img--wrapper img {
            margin: 0 !important; }
    .how-it-works--wrapper .mobile--wrapper {
      margin: 0 -25px;
      position: relative; }
      .how-it-works--wrapper .mobile--wrapper [class*='pure-u'] {
        width: 100%;
        left: 0; }
    .how-it-works--wrapper .mobile--wrapper:nth-child(2) .img--wrapper img {
      top: 25px; }
    .how-it-works--wrapper .section--title:before {
      left: -3em !important;
      top: .3em !important; } }

.gallery--wrapper {
  background: #f8f8f8;
  padding: 80px 0; }
  .gallery--wrapper #instafeed {
    background: #2b2b2b; }
    .gallery--wrapper #instafeed .pure-u-1 {
      overflow: hidden; }
  .gallery--wrapper .instafeed-wrapper img {
    width: auto;
    height: 390px; }
    @media screen and (max-width: 768px) {
      .gallery--wrapper .instafeed-wrapper img {
        width: 100%;
        height: auto; } }
  .gallery--wrapper .instafeed-wrapper a {
    display: block; }
  .gallery--wrapper .instafeed-wrapper .ig--picture {
    -webkit-transition: opacity .3s ease-in-out;
    -moz-transition: opacity .3s ease-in-out;
    transition: opacity .3s ease-in-out; }
  .gallery--wrapper .instafeed-wrapper .ig--picture:hover {
    opacity: .7; }
  .gallery--wrapper .instafeed-wrapper .discover {
    background: #48af4a; }
    .gallery--wrapper .instafeed-wrapper .discover .discover--title {
      margin: 0; }
    .gallery--wrapper .instafeed-wrapper .discover .discover--title a {
      padding: 40px;
      color: #fff;
      font-family: 'Open Sans', sans-serif;
      font-size: 18px;
      font-weight: bold;
      line-height: 0.8;
      letter-spacing: 3.3px;
      text-transform: uppercase;
      text-align: right; }
    .gallery--wrapper .instafeed-wrapper .discover .discover--title a:after {
      content: url(https://d3naj63yelf4gk.cloudfront.net/dist/img/header/arrow.png);
      position: relative;
      left: 10px; }
  .gallery--wrapper .instafeed-wrapper .title {
    background: #fff; }
    .gallery--wrapper .instafeed-wrapper .title .section-title {
      padding: 40px;
      font-family: 'Open Sans', sans-serif;
      font-size: 18px;
      font-weight: bold;
      line-height: 0.78;
      letter-spacing: 1.9px;
      color: #48af4a;
      text-transform: uppercase;
      left: -35px;
      position: relative; }
    .gallery--wrapper .instafeed-wrapper .title .section-title:before {
      content: url(https://d3naj63yelf4gk.cloudfront.net/dist/img/line.svg);
      position: relative;
      left: -30px;
      top: -5px; }

#content-only {
  background: #f8f8f8;
  padding: 7em 0;
  margin-top: 60px; }
  #content-only .container {
    background: white;
    box-shadow: 0 -3em 0 1.5em #48af4a; }
    #content-only .container .info {
      padding: 5em;
      font-family: "Open Sans", Helvetica, sans-serif;
      text-align: center;
      position: relative; }
      #content-only .container .info .line {
        width: 50px;
        height: 4px;
        background: #48af4a;
        margin: 2em auto; }
      #content-only .container .info h4 {
        color: #48af4a;
        font-weight: 700;
        line-height: 0.78;
        letter-spacing: 1.9px; }
      #content-only .container .info p {
        font-weight: 300;
        line-height: 1.56;
        letter-spacing: 1.7px;
        margin: 0em 10em;
        color: #485460; }
      #content-only .container .info .link {
        margin-bottom: -11.5%;
        margin-top: 2.5em; }
        #content-only .container .info .link a {
          padding: 0;
          font-weight: bold;
          border-radius: 5px;
          text-decoration: none;
          color: #fff;
          width: 230px;
          display: inline-block;
          height: 80px;
          padding-top: 35px; }
          @media screen and (max-width: 767px) {
            #content-only .container .info .link a {
              margin-bottom: 10px; } }
        #content-only .container .info .link .faq {
          background: #485460;
          margin-right: 10px; }
          @media screen and (max-width: 767px) {
            #content-only .container .info .link .faq {
              margin-right: 5px;
              margin-bottom: 10px; } }
        #content-only .container .info .link .contact {
          background: #48af4a; }
    @media screen and (max-width: 768px) {
      #content-only .container {
        width: 100%; } }
  @media screen and (max-width: 768px) {
    #content-only {
      padding: 20px;
      width: 90%;
      margin-left: auto;
      margin-right: auto;
      margin-top: 100px; } }

@media screen and (max-width: 35.5em) {
  #content-only .container {
    box-shadow: none; }
    #content-only .container .info {
      padding: 1em 0; }
      #content-only .container .info p {
        margin: 0; } }

@media screen and (max-width: 48em) {
  #content-only .container {
    box-shadow: none; }
    #content-only .container .info p {
      margin: 0; }
    #content-only .container .info .link {
      margin-bottom: 0; }
      #content-only .container .info .link a {
        padding: 2em;
        height: auto;
        width: auto; } }

iframe {
  width: 100%;
  border: none;
  overflow: hidden; }

#gojek {
  /*starwars header */ }

@-webkit-keyframes uil-ring-anim {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

@-moz-keyframes uil-ring-anim {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

@-webkit-keyframes uil-ring-anim {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

@-o-keyframes uil-ring-anim {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

@keyframes uil-ring-anim {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }
  #gojek .uil-ring-css {
    background: none;
    position: relative;
    width: 200px;
    height: 200px;
    margin: 0 auto;
    display: block; }
    #gojek .uil-ring-css div {
      position: absolute;
      display: block;
      width: 160px;
      height: 160px;
      top: 20px;
      left: 20px;
      border-radius: 80px;
      box-shadow: 0 3px 0 0 #48af4a;
      -webkit-transform-origin: 80px 81.5px;
      transform-origin: 80px 81.5px;
      -webkit-animation: uil-ring-anim 1s linear infinite;
      animation: uil-ring-anim 1s linear infinite; }
  #gojek header.starwars-hero {
    background-color: #000; }
    #gojek header.starwars-hero h1.hero--title.starwars-hero {
      max-width: 700px !important; }
      #gojek header.starwars-hero h1.hero--title.starwars-hero .glow {
        text-shadow: 0 0 50px rgba(255, 0, 3, 0.8), 0 0 3px rgba(255, 0, 0, 0.5); }
      @media screen and (max-width: 768px) {
        #gojek header.starwars-hero h1.hero--title.starwars-hero {
          font-size: 35px;
          max-width: none !important; } }
      @media screen and (max-width: 767px) {
        #gojek header.starwars-hero h1.hero--title.starwars-hero {
          font-size: 18px; } }
      @media screen and (min-width: 2000px) {
        #gojek header.starwars-hero h1.hero--title.starwars-hero {
          font-size: 54px; } }
    @media screen and (max-width: 768px) {
      #gojek header.starwars-hero .desc--wrapper {
        left: auto; } }
    @media screen and (max-width: 768px) {
      #gojek header.starwars-hero .sub-desc--row {
        text-align: center;
        padding-bottom: 17%;
        vertical-align: bottom; } }
    #gojek header.starwars-hero img.sw-gojek {
      margin-bottom: 30px; }
      @media screen and (max-width: 768px) {
        #gojek header.starwars-hero img.sw-gojek {
          display: inline-block;
          max-width: 200px;
          margin-bottom: 30px; } }
      @media screen and (max-width: 767px) {
        #gojek header.starwars-hero img.sw-gojek {
          display: inline-block;
          max-width: 100px;
          margin-bottom: 10px; } }
    #gojek header.starwars-hero button {
      background-color: #cd0000;
      box-shadow: 0 0 4px 2px rgba(255, 0, 3, 0.8);
      border: solid 1px rgba(255, 0, 0, 0.9); }
      #gojek header.starwars-hero button:hover {
        color: #fff; }
      @media screen and (max-width: 768px) {
        #gojek header.starwars-hero button.download {
          left: 0; } }
    #gojek header.starwars-hero button.go {
      font-size: 25px;
      background-color: #cd0000; }
    #gojek header.starwars-hero > .pure-g {
      box-shadow: none; }
    #gojek header.starwars-hero:after {
      box-shadow: none; }
  #gojek .post-item--new {
    background-color: #ffffff;
    border-radius: 5px;
    margin-bottom: 30px; }
    #gojek .post-item--new .thumbnail--img {
      height: 280px;
      background-size: cover !important;
      background-position: center !important;
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
      cursor: pointer; }
    #gojek .post-item--new .post--detail {
      padding: 0px 25px;
      position: relative; }
      #gojek .post-item--new .post--detail h3 {
        letter-spacing: unset !important;
        font-weight: unset !important; }
        #gojek .post-item--new .post--detail h3 a {
          font-family: "Open Sans", Helvetica, sans-serif;
          font-size: 18px;
          font-weight: 600;
          letter-spacing: .75px !important;
          text-align: left;
          color: #4a4a4a; }
      #gojek .post-item--new .post--detail p {
        color: #4a4a4a;
        font-weight: 400;
        margin: 0;
        padding: 0;
        margin-top: -18.25px; }
      #gojek .post-item--new .post--detail .service--info {
        width: 100%;
        padding: 5px 20px;
        position: absolute;
        bottom: 0px;
        left: 0px;
        border-bottom-right-radius: 5px; }
        #gojek .post-item--new .post--detail .service--info .wrapper {
          margin-bottom: -15px; }
        #gojek .post-item--new .post--detail .service--info .service--logo {
          width: 45px;
          height: 45px;
          display: inline-block;
          background-size: 25px !important; }
        #gojek .post-item--new .post--detail .service--info .service--text {
          margin-top: -40.5px;
          margin-left: 50px;
          color: #fff;
          font-weight: 700;
          font-family: "Open Sans", Helvetica, sans-serif; }
          #gojek .post-item--new .post--detail .service--info .service--text span {
            position: relative;
            top: 0px;
            font-weight: 300;
            font-size: 11px; }
      #gojek .post-item--new .post--detail .service--info[data-service*="go-ride"] {
        background-color: #F9AE3C; }
        #gojek .post-item--new .post--detail .service--info[data-service*="go-ride"] .service--logo {
          background: #ffffff url("https://bilba.go-jek.com/images/product-brand/goride.svg") no-repeat center; }
      #gojek .post-item--new .post--detail .service--info[data-service*="go-food"] {
        background-color: #B62025; }
        #gojek .post-item--new .post--detail .service--info[data-service*="go-food"] .service--logo {
          background: #ffffff url("https://bilba.go-jek.com/images/product-brand/gofood.svg") no-repeat center; }
      #gojek .post-item--new .post--detail .service--info[data-service*="go-pay"] {
        background-color: #5CA5DA; }
        #gojek .post-item--new .post--detail .service--info[data-service*="go-pay"] .service--logo {
          background: #ffffff url("https://bilba.go-jek.com/images/product-brand/gopay.svg") no-repeat center; }
      #gojek .post-item--new .post--detail .service--info[data-service*="go-tix"] {
        background-color: #EA6B25; }
        #gojek .post-item--new .post--detail .service--info[data-service*="go-tix"] .service--logo {
          background: #ffffff url(https://bilba.go-jek.com/images/product-brand/gotix.svg) no-repeat center; }
      #gojek .post-item--new .post--detail .service--info[data-service*="go-jek"] {
        background-color: #48af4a; }
        #gojek .post-item--new .post--detail .service--info[data-service*="go-jek"] .service--logo {
          background: #ffffff url(https://bilba.go-jek.com/images/product-brand/gojek.svg) no-repeat center; }
      #gojek .post-item--new .post--detail .service--info[data-service*="go-send"] {
        background-color: #8BC440; }
        #gojek .post-item--new .post--detail .service--info[data-service*="go-send"] .service--logo {
          background: #ffffff url("https://bilba.go-jek.com/images/product-brand/gosend.svg") no-repeat center; }
      #gojek .post-item--new .post--detail .service--info[data-service*="go-mart"] {
        background-color: #3C95D1; }
        #gojek .post-item--new .post--detail .service--info[data-service*="go-mart"] .service--logo {
          background: #ffffff url("https://bilba.go-jek.com/images/product-brand/gomart.svg") no-repeat center; }
      #gojek .post-item--new .post--detail .service--info[data-service*="go-car"] {
        background-color: #17619D; }
        #gojek .post-item--new .post--detail .service--info[data-service*="go-car"] .service--logo {
          background: #ffffff url("https://bilba.go-jek.com/images/product-brand/gocar.svg") no-repeat center; }
      #gojek .post-item--new .post--detail .service--info[data-service*="go-box"] {
        background-color: #A1652F; }
        #gojek .post-item--new .post--detail .service--info[data-service*="go-box"] .service--logo {
          background: #ffffff url("https://bilba.go-jek.com/images/product-brand/gobox.svg") no-repeat center; }
      #gojek .post-item--new .post--detail .service--info[data-service*="go-point"] {
        background-color: #F79520; }
        #gojek .post-item--new .post--detail .service--info[data-service*="go-point"] .service--logo {
          background: #ffffff url("https://bilba.go-jek.com/images/product-brand/gopoint.svg") no-repeat center; }
      #gojek .post-item--new .post--detail .service--info[data-service*="go-pulsa"] {
        background-color: #22AF6E; }
        #gojek .post-item--new .post--detail .service--info[data-service*="go-pulsa"] .service--logo {
          background: #ffffff url("https://bilba.go-jek.com/images/product-brand/gopulsa.svg") no-repeat center; }
      #gojek .post-item--new .post--detail .service--info[data-service*="go-busway"] {
        background-color: #EF7921; }
        #gojek .post-item--new .post--detail .service--info[data-service*="go-busway"] .service--logo {
          background: #ffffff url("https://bilba.go-jek.com/images/product-brand/gobusway.svg") no-repeat center; }
      #gojek .post-item--new .post--detail .service--info[data-service*="go-med"] {
        background-color: #006738; }
        #gojek .post-item--new .post--detail .service--info[data-service*="go-med"] .service--logo {
          background: #ffffff url("https://bilba.go-jek.com/images/product-brand/gomed.svg") no-repeat center; }
      #gojek .post-item--new .post--detail .service--info[data-service*="go-massage"] {
        background-color: #18AEAB; }
        #gojek .post-item--new .post--detail .service--info[data-service*="go-massage"] .service--logo {
          background: #ffffff url("https://bilba.go-jek.com/images/product-brand/gomassage.svg") no-repeat center; }
      #gojek .post-item--new .post--detail .service--info[data-service*="go-clean"] {
        background-color: #2197D4; }
        #gojek .post-item--new .post--detail .service--info[data-service*="go-clean"] .service--logo {
          background: #ffffff url("https://bilba.go-jek.com/images/product-brand/goclean.svg") no-repeat center; }
      #gojek .post-item--new .post--detail .service--info[data-service*="go-auto"] {
        background-color: #BABCBE; }
        #gojek .post-item--new .post--detail .service--info[data-service*="go-auto"] .service--logo {
          background: #ffffff url("https://bilba.go-jek.com/images/product-brand/goauto.svg") no-repeat center; }
      #gojek .post-item--new .post--detail .service--info[data-service*="go-glam"] {
        background-color: #E67498; }
        #gojek .post-item--new .post--detail .service--info[data-service*="go-glam"] .service--logo {
          background: #ffffff url("https://bilba.go-jek.com/images/product-brand/goglam.svg") no-repeat center; }
      #gojek .post-item--new .post--detail .service--info[data-service*="go-life"] {
        background-color: #49af4b; }
        #gojek .post-item--new .post--detail .service--info[data-service*="go-life"] .service--logo {
          background: #ffffff url("https://bilba.go-jek.com/images/product-brand/golife.svg") no-repeat center; }
      #gojek .post-item--new .post--detail .service--info[data-service*="go-bills"] {
        background-color: #4285a7; }
        #gojek .post-item--new .post--detail .service--info[data-service*="go-bills"] .service--logo {
          background: #ffffff url("https://bilba.go-jek.com/images/product-brand/gobills.svg") no-repeat center; }
  @media only screen and (max-width: 35.5em) {
    #gojek .post-item--new {
      border-radius: 0 !important;
      transform: scale(1.085);
      margin-bottom: 14px; }
      #gojek .post-item--new .thumbnail--img {
        height: 170px;
        border-radius: 0 !important; }
      #gojek .post-item--new a {
        line-height: 18px;
        font-size: 14px; }
      #gojek .post-item--new p {
        display: none; } }
  @media only screen and (max-width: 320px) {
    #gojek .post-item--new a {
      line-height: 5px;
      font-size: 12px !important; } }
  #gojek .blogNav {
    background: #485460;
    width: 100%;
    padding: 0;
    margin-top: 85px;
    position: fixed;
    z-index: 10;
    transition: all .5s ease 0s; }
    #gojek .blogNav ul {
      margin: 0px;
      padding: 0px;
      text-align: center;
      list-style-type: none; }
      #gojek .blogNav ul.first a {
        padding: 20px 30px !important;
        background-color: #48af4a; }
      #gojek .blogNav ul.middle {
        float: left;
        padding-left: 50px; }
        #gojek .blogNav ul.middle > li {
          display: inline-block; }
          #gojek .blogNav ul.middle > li:hover {
            transition: all 0.5s;
            background-color: #2a3d4e; }
            #gojek .blogNav ul.middle > li:hover .blogMegamenu {
              opacity: 1;
              pointer-events: visible;
              transition: all 0.3s; }
      #gojek .blogNav ul.last {
        float: right;
        position: relative;
        right: -12.5px; }
        #gojek .blogNav ul.last i {
          line-height: 0px; }
      #gojek .blogNav ul li a.trigger {
        display: inline-block;
        padding: 20px 10px;
        font-family: "Open Sans", Helvetica, sans-serif;
        color: #fff;
        font-size: 12.5px;
        text-align: center;
        text-decoration: none;
        letter-spacing: 1px;
        top: 0; }
    #gojek .blogNav .mobileBlogNavbar {
      bottom: 0;
      letter-spacing: 2.1px;
      font-weight: 300;
      top: 0;
      margin: auto;
      color: #fff; }
      #gojek .blogNav .mobileBlogNavbar .content {
        position: relative;
        padding: 17px 15px;
        display: inline-block;
        left: -15px; }
        #gojek .blogNav .mobileBlogNavbar .content.active {
          background-color: #2a3d4e; }
      @media only screen and (min-width: 769px) {
        #gojek .blogNav .mobileBlogNavbar {
          display: none; } }
    @media only screen and (max-width: 768px) {
      #gojek .blogNav .desktopBlogNavbar {
        display: none; } }
    @media only screen and (min-width: 769px) {
      #gojek .blogNav.active {
        transform: translateY(-85px); } }
    @media only screen and (min-width: 769px) {
      #gojek .blogNav.active + .blogNavSearch {
        transform: translateY(-85px); } }
    @media only screen and (min-width: 769px) {
      #gojek .blogNav.active + .blogNavSearchResult {
        transform: translateY(-85px); } }
  #gojek .blogNavSearchMobile {
    position: fixed;
    width: 100%;
    background-color: #2a3d4e;
    padding: 20px 0;
    left: 0;
    top: 112px;
    z-index: 10;
    color: #fff;
    pointer-events: none;
    opacity: 0;
    transition: all 0.3s; }
    #gojek .blogNavSearchMobile.active {
      pointer-events: visible;
      opacity: 1;
      transition: all 0.3s; }
    #gojek .blogNavSearchMobile .kategori {
      margin-bottom: 20px; }
    #gojek .blogNavSearchMobile .container-select {
      position: relative;
      margin-top: 10px; }
      #gojek .blogNavSearchMobile .container-select select {
        background-color: #fff;
        padding: 10px;
        width: 100%;
        color: #4a4a4a; }
      #gojek .blogNavSearchMobile .container-select i {
        position: absolute;
        color: #4a4a4a;
        right: 15px;
        font-size: 25px;
        height: 25px;
        top: 0;
        bottom: 0;
        margin: auto; }
  #gojek .blogMegamenu {
    opacity: 0;
    pointer-events: none;
    transition: all 0.5s;
    position: fixed;
    background-color: #2a3d4e;
    width: 100%;
    left: 0;
    background-color: #2a3d4e;
    box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.09);
    width: 100%;
    padding: 25px 0;
    text-align: left; }
    #gojek .blogMegamenu p {
      color: #FFF;
      font-size: 12px;
      position: relative;
      margin: 0px; }
    #gojek .blogMegamenu ul {
      text-align: left;
      list-style-type: none;
      padding-left: 0px; }
      #gojek .blogMegamenu ul li {
        list-style-type: none;
        margin-bottom: 10px; }
        #gojek .blogMegamenu ul li a {
          font-family: "Open Sans", Helvetica, sans-serif;
          font-size: 12.5px;
          color: #FFF;
          letter-spacing: 1.3px; }
    #gojek .blogMegamenu .first-wrapper.last ul {
      position: relative;
      left: 35px; }
    #gojek .blogMegamenu.active {
      margin-top: 50px; }
    #gojek .blogMegamenu.slide {
      display: block; }
  #gojek .blogNavSearch {
    transition: all 0.5s ease 0s;
    opacity: 0;
    pointer-events: none; }
    #gojek .blogNavSearch.active {
      transition: all 0.5s ease 0s;
      opacity: 1;
      pointer-events: visible; }
  #gojek .blogNavSearch,
  #gojek .blogNavSearchResult {
    background: #2a3d4e;
    width: 100%;
    padding: 20px 0;
    margin-top: 135px;
    margin-bottom: -85px;
    position: fixed;
    z-index: 1999; }
    #gojek .blogNavSearch .wrapper,
    #gojek .blogNavSearchResult .wrapper {
      position: relative;
      left: 10px; }
    #gojek .blogNavSearch #searchSubmit,
    #gojek .blogNavSearchResult #searchSubmit {
      background: unset;
      padding: 0;
      position: relative;
      top: 14.25px;
      left: 15px;
      color: #fff;
      font-family: "Open Sans", Helvetica, sans-serif;
      font-weight: 400;
      font-size: 18px;
      line-height: 0.8;
      letter-spacing: 3.3px;
      text-align: left;
      color: #ffffff; }
      #gojek .blogNavSearch #searchSubmit i,
      #gojek .blogNavSearchResult #searchSubmit i {
        position: relative;
        left: 10px;
        top: -1px; }
    #gojek .blogNavSearch form input,
    #gojek .blogNavSearchResult form input {
      width: 100%;
      height: 43.5px;
      font-family: "Open Sans", Helvetica, sans-serif;
      border-radius: 5px;
      border: 0;
      padding: 10px 20px;
      position: relative;
      top: 2.5px; }
    #gojek .blogNavSearch form ::-webkit-input-placeholder,
    #gojek .blogNavSearchResult form ::-webkit-input-placeholder {
      color: #babcbf;
      font-style: italic; }
    #gojek .blogNavSearch form ::-moz-placeholder,
    #gojek .blogNavSearchResult form ::-moz-placeholder {
      color: #babcbf;
      font-style: italic; }
    #gojek .blogNavSearch form :-ms-input-placeholder,
    #gojek .blogNavSearchResult form :-ms-input-placeholder {
      color: #babcbf;
      font-style: italic; }
    #gojek .blogNavSearch form :-moz-placeholder,
    #gojek .blogNavSearchResult form :-moz-placeholder {
      color: #babcbf;
      font-style: italic; }
    #gojek .blogNavSearch ul,
    #gojek .blogNavSearchResult ul {
      text-align: center;
      list-style-type: none; }
      #gojek .blogNavSearch ul.first,
      #gojek .blogNavSearchResult ul.first {
        position: relative;
        left: -85px;
        text-align: left; }
        #gojek .blogNavSearch ul.first li a,
        #gojek .blogNavSearchResult ul.first li a {
          font-size: 18px;
          position: relative;
          top: -3.5px; }
      #gojek .blogNavSearch ul.middle,
      #gojek .blogNavSearchResult ul.middle {
        position: relative;
        left: -195px; }
        #gojek .blogNavSearch ul.middle li a,
        #gojek .blogNavSearchResult ul.middle li a {
          background: unset;
          padding: 18px; }
      #gojek .blogNavSearch ul.last,
      #gojek .blogNavSearchResult ul.last {
        float: right;
        position: relative;
        right: -12.5px; }
      #gojek .blogNavSearch ul li,
      #gojek .blogNavSearchResult ul li {
        display: inline-block; }
        #gojek .blogNavSearch ul li a,
        #gojek .blogNavSearchResult ul li a {
          font-family: "Open Sans", Helvetica, sans-serif;
          color: #fff;
          text-align: center;
          padding: 16px 40px;
          text-decoration: none;
          letter-spacing: 1px;
          top: 0; }
  #gojek .blogNavSearchResult {
    display: none;
    padding: 0;
    z-index: 9 !important;
    transition: all 0.3s ease 0s; }
    #gojek .blogNavSearchResult h4.title {
      content: "";
      font-family: "Open Sans", Helvetica, sans-serif;
      font-size: 22px;
      font-weight: 400;
      text-align: center;
      color: #ffffff;
      line-height: 25px; }
      @media only screen and (max-width: 768px) {
        #gojek .blogNavSearchResult h4.title {
          color: #4a4a4a;
          font-size: 14px;
          letter-spacing: normal; } }
    @media only screen and (max-width: 768px) {
      #gojek .blogNavSearchResult {
        position: relative;
        background-color: #ececec; } }
  #gojek .blog-loader {
    margin-top: 180px; }
  #gojek .blog-found {
    margin-top: 200px;
    display: none; }
    @media only screen and (max-width: 768px) {
      #gojek .blog-found {
        margin-top: 65px; } }
  #gojek .blog-not-found {
    margin-top: 180px;
    display: none; }
    @media only screen and (max-width: 768px) {
      #gojek .blog-not-found {
        margin-top: 65px; } }
    #gojek .blog-not-found .post .articles--feed {
      padding: 0 !important; }
    #gojek .blog-not-found .text h5 {
      font-family: "Open Sans", Helvetica, sans-serif;
      font-size: 18px;
      font-weight: 400;
      text-align: center;
      color: #4a4a4a; }
  @media only screen and (max-width: 768px) {
    #gojek .blogNav {
      margin-top: 60px;
      margin-bottom: 0; }
      #gojek .blogNav.active {
        margin-top: 60px; }
      #gojek .blogNav ul.first, #gojek .blogNav ul.middle {
        display: none; }
      #gojek .blogNav ul.last {
        right: unset; }
        #gojek .blogNav ul.last li a {
          font-size: 15px;
          left: -5px; }
    #gojek .blogNavSearch,
    #gojek .blogNavSearchResult {
      margin-top: 110px; }
      #gojek .blogNavSearch.active,
      #gojek .blogNavSearchResult.active {
        margin-top: 110px; }
      #gojek .blogNavSearch .wrapper,
      #gojek .blogNavSearchResult .wrapper {
        left: 0; }
      #gojek .blogNavSearch .first-wrapper,
      #gojek .blogNavSearchResult .first-wrapper {
        display: none; }
      #gojek .blogNavSearch #searchSubmit,
      #gojek .blogNavSearchResult #searchSubmit {
        text-align: center;
        width: 100%;
        background: #48af4a;
        padding: 17px 0px;
        top: 2px;
        font-size: 14px;
        left: 0;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px; }
        #gojek .blogNavSearch #searchSubmit i,
        #gojek .blogNavSearchResult #searchSubmit i {
          display: none; }
      #gojek .blogNavSearch form input,
      #gojek .blogNavSearchResult form input {
        border-top-right-radius: 0px;
        border-bottom-right-radius: 0px; }
      #gojek .blogNavSearch form ::-webkit-input-placeholder,
      #gojek .blogNavSearchResult form ::-webkit-input-placeholder {
        color: #fff;
        font-style: italic; }
      #gojek .blogNavSearch form ::-moz-placeholder,
      #gojek .blogNavSearchResult form ::-moz-placeholder {
        color: #fff;
        font-style: italic; }
      #gojek .blogNavSearch form :-ms-input-placeholder,
      #gojek .blogNavSearchResult form :-ms-input-placeholder {
        color: #fff;
        font-style: italic; }
      #gojek .blogNavSearch form :-moz-placeholder,
      #gojek .blogNavSearchResult form :-moz-placeholder {
        color: #fff;
        font-style: italic; }
      #gojek .blogNavSearch ul.first,
      #gojek .blogNavSearchResult ul.first {
        display: none; }
    #gojek .blogNavSearchResult .first-wrapper {
      display: block !important; } }
  #gojek .header {
    padding: 5.375em 0;
    color: #4a4a4a; }
    #gojek .header .left {
      font-family: "Open Sans", Helvetica, sans-serif;
      text-align: left; }
    #gojek .header .right {
      text-align: right;
      line-height: 1.2em;
      font-size: 1.2em; }
    #gojek .header .content {
      margin-bottom: 0; }
      #gojek .header .content span {
        color: #48af4a; }
  #gojek .services .slick-arrow {
    cursor: pointer; }
    #gojek .services .slick-arrow ellipse {
      stroke: #48af4a; }
    #gojek .services .slick-arrow polygon {
      fill: #48af4a; }
  #gojek section .content-subhead {
    color: #4a4a4a;
    font-family: "Open Sans", Helvetica, sans-serif;
    font-size: 1.6em;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.2em;
    letter-spacing: 4px; }
  #gojek section button.normal {
    color: #fff;
    background-color: #48af4a; }
  #gojek section button.hover {
    color: #358036;
    background-color: #5abb5c; }
  #gojek section button.active {
    color: #5abb5c;
    background-color: #358036; }
  #gojek section .anchor {
    padding: 3em 0; }
    #gojek section .anchor a {
      font-size: 0.625em;
      font-weight: 700;
      color: #98ce00;
      text-decoration: none;
      letter-spacing: 0.206em; }
    #gojek section .anchor .hover {
      color: #276028;
      color: #48af4a; }
    #gojek section .anchor .active {
      color: #6b9100; }
  #gojek .articles--feed svg ellipse {
    stroke: #2c822e !important; }
  #gojek .articles--feed svg polygon {
    fill: #2c822e; }
  #gojek .gojekin-hero {
    z-index: 2;
    margin-bottom: -100vh;
    position: relative;
    height: 200vh;
    width: 100%;
    background-color: #48af4a; }
    #gojek .gojekin-hero .arrow-down {
      cursor: pointer;
      text-align: center;
      padding: 30px 0;
      color: #ffffff;
      position: fixed;
      width: 100%;
      bottom: 0;
      z-index: 10;
      left: 0;
      font-family: "Open Sans", Helvetica, sans-serif;
      font-size: 12px;
      letter-spacing: 1px; }
      #gojek .gojekin-hero .arrow-down i {
        font-size: 20px;
        margin-top: 10px; }
      #gojek .gojekin-hero .arrow-down.green-arrow {
        color: #48af4a;
        transition: all 0.5s; }
      #gojek .gojekin-hero .arrow-down.disactive {
        transition: all 1s;
        opacity: 0; }
      @media screen and (max-width: 768px) {
        #gojek .gojekin-hero .arrow-down {
          position: absolute; } }
    #gojek .gojekin-hero .container-logo {
      position: sticky;
      width: 100%;
      height: calc(50% - 84px);
      top: 84px; }
      @media screen and (max-width: 768px) {
        #gojek .gojekin-hero .container-logo {
          position: relative;
          height: 100%;
          top: 0; }
          #gojek .gojekin-hero .container-logo #driver {
            animation: hideDriver 0.5s forwards 1s; }
          #gojek .gojekin-hero .container-logo #jek {
            animation: geserkiri 0.5s forwards 1.25s; }
          #gojek .gojekin-hero .container-logo #ct-gojek {
            animation: scaling 0.5s forwards 1.7s; }
          #gojek .gojekin-hero .container-logo #hashtag svg {
            animation: upAnimationTwo 0.5s forwards 2s; } }
      #gojek .gojekin-hero .container-logo .sub-logo {
        position: absolute;
        width: 100%;
        height: 100%;
        display: table;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        margin: auto; }
        #gojek .gojekin-hero .container-logo .sub-logo > div {
          overflow: hidden;
          display: table-cell;
          vertical-align: middle;
          text-align: center; }
      #gojek .gojekin-hero .container-logo .logo {
        position: relative; }
        @media screen and (min-width: 769px) {
          #gojek .gojekin-hero .container-logo .logo svg {
            width: auto !important; } }
        @media screen and (max-width: 768px) {
          #gojek .gojekin-hero .container-logo .logo svg {
            width: auto;
            height: 10vw;
            max-height: 55px; }
          #gojek .gojekin-hero .container-logo .logo .small svg {
            height: 8vw;
            max-height: 45px; }
          #gojek .gojekin-hero .container-logo .logo .j-animation svg {
            height: 13vw;
            max-height: 65px; } }
        #gojek .gojekin-hero .container-logo .logo > div:first-child {
          margin-left: 0px; }
        #gojek .gojekin-hero .container-logo .logo > div {
          position: relative;
          display: inline-block; }
        #gojek .gojekin-hero .container-logo .logo > div > div {
          position: relative;
          display: inline-block; }
        #gojek .gojekin-hero .container-logo .logo > div > div > div {
          display: inline-block;
          overflow: hidden;
          display: inline-block;
          padding-left: 7px; }
          @media screen and (max-width: 768px) {
            #gojek .gojekin-hero .container-logo .logo > div > div > div {
              padding-left: 3px; } }
        #gojek .gojekin-hero .container-logo .logo .j-animation {
          position: relative;
          bottom: -22px;
          padding-left: 2px !important; }
          @media screen and (max-width: 768px) {
            #gojek .gojekin-hero .container-logo .logo .j-animation {
              transform: translateY(0px);
              bottom: -10px;
              left: -1px; } }
        #gojek .gojekin-hero .container-logo .logo #inaja {
          bottom: 0;
          position: absolute;
          margin-left: -51px; }
          #gojek .gojekin-hero .container-logo .logo #inaja > div {
            overflow: hidden;
            padding-left: 7px; }
            @media screen and (max-width: 768px) {
              #gojek .gojekin-hero .container-logo .logo #inaja > div {
                padding-left: 2px; } }
          #gojek .gojekin-hero .container-logo .logo #inaja svg {
            transform: translateY(110px); }
          @media screen and (max-width: 768px) {
            #gojek .gojekin-hero .container-logo .logo #inaja {
              transform: scale(0.8);
              bottom: -1px;
              right: 25.5%;
              position: absolute;
              margin-left: auto; }
              #gojek .gojekin-hero .container-logo .logo #inaja svg {
                transform: translateY(60px) !important; }
              #gojek .gojekin-hero .container-logo .logo #inaja > .sub:nth-child(1) svg {
                opacity: calc(0 + 1);
                animation: upAnimation 0.5s forwards calc(1.9 + (0.1)s); }
              #gojek .gojekin-hero .container-logo .logo #inaja > .sub:nth-child(2) svg {
                opacity: calc(0 + 2);
                animation: upAnimation 0.5s forwards calc(1.9 + (0.2)s); }
              #gojek .gojekin-hero .container-logo .logo #inaja > .sub:nth-child(3) svg {
                opacity: calc(0 + 3);
                animation: upAnimation 0.5s forwards calc(1.9 + (0.3)s); }
              #gojek .gojekin-hero .container-logo .logo #inaja > .sub:nth-child(4) svg {
                opacity: calc(0 + 4);
                animation: upAnimation 0.5s forwards calc(1.9 + (0.4)s); }
              #gojek .gojekin-hero .container-logo .logo #inaja > .sub:nth-child(5) svg {
                opacity: calc(0 + 5);
                animation: upAnimation 0.5s forwards calc(1.9 + (0.5)s); } }
          @media screen and (max-width: 600px) {
            #gojek .gojekin-hero .container-logo .logo #inaja {
              right: 15%; }
              #gojek .gojekin-hero .container-logo .logo #inaja svg {
                transform: translateY(55px) !important; } }
          @media screen and (max-width: 320px) {
            #gojek .gojekin-hero .container-logo .logo #inaja {
              right: 14.7%; } }
        #gojek .gojekin-hero .container-logo .logo #hashtag {
          position: absolute;
          bottom: 5px;
          margin-left: -10px;
          overflow: hidden; }
          #gojek .gojekin-hero .container-logo .logo #hashtag svg {
            transform: translateY(-110px); }
            @media screen and (max-width: 768px) {
              #gojek .gojekin-hero .container-logo .logo #hashtag svg {
                transform: translateY(-55px); } }
          @media screen and (max-width: 768px) {
            #gojek .gojekin-hero .container-logo .logo #hashtag {
              transform: scale(0.8);
              bottom: 3px;
              margin-left: -38px; } }
          @media screen and (max-width: 600px) {
            #gojek .gojekin-hero .container-logo .logo #hashtag {
              margin-left: -30px; } }
    #gojek .gojekin-hero .trigger-logo {
      position: absolute;
      height: 100%;
      top: 25%; }
      @media screen and (max-width: 768px) {
        #gojek .gojekin-hero .trigger-logo {
          display: none; } }
    @media screen and (max-width: 768px) {
      #gojek .gojekin-hero {
        margin-top: 60px;
        height: calc(100vh - 60px);
        margin-bottom: 0px; } }
  #gojek .content-map {
    margin-bottom: -100vh;
    position: relative;
    height: 530vh;
    width: 100%;
    background: #fafafa; }
    #gojek .content-map .container-logo {
      position: sticky;
      width: 100%;
      height: 100vh;
      top: 0; }
      @media screen and (max-width: 768px) {
        #gojek .content-map .container-logo {
          position: relative;
          height: auto;
          display: inline-block;
          position: relative;
          padding: 200px 0; } }
      #gojek .content-map .container-logo .sub-logo {
        position: absolute;
        width: 100%;
        height: 100%;
        display: table;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        margin: auto; }
        #gojek .content-map .container-logo .sub-logo > div {
          display: table-cell;
          vertical-align: middle;
          text-align: center;
          overflow: hidden; }
        #gojek .content-map .container-logo .sub-logo .center-word {
          font-weight: 700;
          font-family: neo;
          transition: all 0.5s;
          font-size: 48px;
          color: #2b2b2b;
          font-weight: 700;
          width: 100%;
          position: relative;
          overflow: hidden; }
          #gojek .content-map .container-logo .sub-logo .center-word.active {
            transition: all 0.5s;
            transform: scale(1.4); }
          #gojek .content-map .container-logo .sub-logo .center-word > div:first-child {
            display: inline-block; }
            @media screen and (max-width: 768px) {
              #gojek .content-map .container-logo .sub-logo .center-word > div:first-child {
                width: 100%; } }
            #gojek .content-map .container-logo .sub-logo .center-word > div:first-child > div {
              float: left; }
              @media screen and (max-width: 768px) {
                #gojek .content-map .container-logo .sub-logo .center-word > div:first-child > div {
                  width: 100%; } }
            #gojek .content-map .container-logo .sub-logo .center-word > div:first-child > div.left-word {
              left: 0;
              width: calc(50% - 75px);
              text-align: right;
              position: absolute; }
              @media screen and (max-width: 768px) {
                #gojek .content-map .container-logo .sub-logo .center-word > div:first-child > div.left-word {
                  width: 100%;
                  position: relative;
                  height: 50px;
                  padding-top: 10px;
                  text-align: center;
                  overflow: hidden;
                  margin-bottom: 5px; } }
              #gojek .content-map .container-logo .sub-logo .center-word > div:first-child > div.left-word > div {
                width: 100%;
                background: #fafafa;
                position: absolute;
                right: 0;
                transform: translateY(100px); }
                @media screen and (max-width: 768px) {
                  #gojek .content-map .container-logo .sub-logo .center-word > div:first-child > div.left-word > div {
                    transform: translateY(-100px); } }
              #gojek .content-map .container-logo .sub-logo .center-word > div:first-child > div.left-word > div:first-child {
                transform: translateY(0px); }
            #gojek .content-map .container-logo .sub-logo .center-word > div:first-child > div.right-word {
              right: 0;
              width: calc(50% - 75px);
              text-align: left;
              position: absolute; }
              @media screen and (max-width: 768px) {
                #gojek .content-map .container-logo .sub-logo .center-word > div:first-child > div.right-word {
                  width: 100%;
                  position: relative;
                  height: 50px;
                  text-align: center;
                  overflow: hidden;
                  margin-top: 5px; } }
              #gojek .content-map .container-logo .sub-logo .center-word > div:first-child > div.right-word > div {
                width: 100%;
                background: #fafafa;
                position: absolute;
                left: 0;
                transform: translateY(-100px); }
              #gojek .content-map .container-logo .sub-logo .center-word > div:first-child > div.right-word > div:first-child {
                transform: translateY(0px); }
            #gojek .content-map .container-logo .sub-logo .center-word > div:first-child .goride {
              color: #F9AE3C; }
            #gojek .content-map .container-logo .sub-logo .center-word > div:first-child .gocar {
              color: #17619D; }
            #gojek .content-map .container-logo .sub-logo .center-word > div:first-child .gosend {
              color: #8BC440; }
            #gojek .content-map .container-logo .sub-logo .center-word > div:first-child .gomart {
              color: #3C95D1; }
            #gojek .content-map .container-logo .sub-logo .center-word > div:first-child .gotix {
              color: #EA6B25; }
            #gojek .content-map .container-logo .sub-logo .center-word > div:first-child .gofood {
              color: #B62025; }
            #gojek .content-map .container-logo .sub-logo .center-word > div:first-child .gopay {
              color: #5CA5DA; }
            #gojek .content-map .container-logo .sub-logo .center-word > div:first-child .gobox {
              color: #A1652F; }
            #gojek .content-map .container-logo .sub-logo .center-word > div:first-child .green.active {
              transition: all 0.5s;
              color: #48af4a; }
          @media screen and (max-width: 768px) {
            #gojek .content-map .container-logo .sub-logo .center-word {
              font-size: 32px; } }
    #gojek .content-map .trigger-logo {
      position: absolute;
      height: 100%;
      top: auto;
      margin-top: 15%; }
      @media screen and (max-width: 768px) {
        #gojek .content-map .trigger-logo {
          display: none; } }
    @media screen and (max-width: 768px) {
      #gojek .content-map {
        height: auto;
        margin-bottom: 0px; } }
  #gojek .video-gojekin {
    position: relative;
    padding: 144px 0;
    height: auto;
    width: 100%;
    text-align: center;
    background: #48af4a; }
    @media screen and (max-width: 767px) {
      #gojek .video-gojekin {
        padding: 0px;
        padding-bottom: 100px; } }
    #gojek .video-gojekin .container-video {
      display: inline-block;
      width: 80%;
      max-width: 1100px;
      text-align: center;
      position: relative; }
      @media screen and (max-width: 767px) {
        #gojek .video-gojekin .container-video {
          width: 100%; } }
      #gojek .video-gojekin .container-video .video {
        position: relative;
        z-index: 1;
        display: inline-block;
        width: 100%;
        float: left;
        height: 500px; }
        @media screen and (max-width: 767px) {
          #gojek .video-gojekin .container-video .video {
            height: 350px;
            display: flex;
            align-items: center; } }
        #gojek .video-gojekin .container-video .video iframe {
          width: 80%; }
          @media screen and (max-width: 767px) {
            #gojek .video-gojekin .container-video .video iframe {
              width: 100%;
              height: 70%; } }
      #gojek .video-gojekin .container-video .left {
        position: absolute;
        height: 80%;
        left: 0;
        bottom: 0;
        width: 50%; }
        #gojek .video-gojekin .container-video .left:after {
          border-radius: 12px 0px 0px 0px;
          position: absolute;
          height: 75%;
          width: 100%;
          background: #8cc63f;
          top: 0;
          left: 0;
          content: ""; }
        #gojek .video-gojekin .container-video .left:before {
          position: absolute;
          height: 25%;
          bottom: 0;
          width: 100%;
          background-image: url("https://d3naj63yelf4gk.cloudfront.net/dist/img/faq/geometry.svg");
          left: 0;
          content: ""; }
        @media screen and (max-width: 767px) {
          #gojek .video-gojekin .container-video .left {
            width: 10%; } }
      #gojek .video-gojekin .container-video .right {
        position: absolute;
        height: 80%;
        right: 0;
        top: 0;
        width: 50%; }
        #gojek .video-gojekin .container-video .right:after {
          border-radius: 0px 0px 12px 0px;
          position: absolute;
          height: 75%;
          width: 100%;
          background: #8cc63f;
          bottom: 0;
          left: 0;
          content: ""; }
        #gojek .video-gojekin .container-video .right:before {
          position: absolute;
          height: 25%;
          top: 0;
          width: 100%;
          background-image: url("https://d3naj63yelf4gk.cloudfront.net/dist/img/faq/geometry.svg");
          left: 0;
          content: ""; }
        @media screen and (max-width: 767px) {
          #gojek .video-gojekin .container-video .right {
            right: auto;
            left: 0;
            width: 10%; } }
    #gojek .video-gojekin .container-about {
      margin-top: 35px;
      display: inline-block;
      width: 80%;
      max-width: 1100px;
      text-align: center;
      position: relative; }
      @media screen and (max-width: 767px) {
        #gojek .video-gojekin .container-about {
          width: 90%;
          margin-top: 0px; } }
      #gojek .video-gojekin .container-about .sub-container {
        width: 80%;
        display: inline-block;
        position: relative; }
        @media screen and (max-width: 767px) {
          #gojek .video-gojekin .container-about .sub-container {
            width: 90%; } }
        @media screen and (min-width: 768px) {
          #gojek .video-gojekin .container-about .sub-container:after {
            position: absolute;
            height: 100%;
            width: 30%;
            left: 10%;
            content: "";
            top: 0;
            background-image: url("https://d3naj63yelf4gk.cloudfront.net/dist/img/faq/geometry.svg"); } }
        #gojek .video-gojekin .container-about .sub-container .content {
          width: 60%;
          background: #fafafa;
          padding: 30px 0;
          float: right;
          border-radius: 0px 12px 12px 0px; }
          @media screen and (max-width: 767px) {
            #gojek .video-gojekin .container-about .sub-container .content {
              width: 100%;
              border-radius: 0px; }
              #gojek .video-gojekin .container-about .sub-container .content:after {
                position: absolute;
                width: 100%;
                height: 100px;
                background-color: #fafafa;
                bottom: 0;
                left: 0;
                content: "";
                background-image: url("https://d3naj63yelf4gk.cloudfront.net/dist/img/faq/geometry.svg"); } }
          #gojek .video-gojekin .container-about .sub-container .content p {
            text-align: left;
            padding-right: 30px;
            padding-left: calc(30px + 10%);
            font-family: "Open Sans", Helvetica, sans-serif;
            font-size: 14px; }
            @media screen and (max-width: 767px) {
              #gojek .video-gojekin .container-about .sub-container .content p {
                padding-left: 30px; } }
          #gojek .video-gojekin .container-about .sub-container .content img {
            width: 46%;
            bottom: 0;
            position: absolute;
            max-width: 100%;
            left: 0;
            top: 0;
            margin: auto;
            z-index: 1; }
            @media screen and (max-width: 767px) {
              #gojek .video-gojekin .container-about .sub-container .content img {
                width: 110%;
                max-width: none;
                left: -5%;
                position: relative; } }
          #gojek .video-gojekin .container-about .sub-container .content .download {
            line-height: 30px;
            font-weight: 100;
            font-size: 24px;
            width: 110%;
            max-width: none;
            left: -5%;
            position: relative;
            padding: 30px;
            color: #ffffff;
            background-size: cover;
            background-position: center;
            z-index: 1; }
          @media screen and (min-width: 768px) {
            #gojek .video-gojekin .container-about .sub-container .content .container-download {
              display: none; } }
          #gojek .video-gojekin .container-about .sub-container .content .container-download button {
            top: -20px;
            position: relative;
            color: #ffffff;
            background: #ffcd2e;
            padding: 10px 20px;
            font-size: 12px;
            z-index: 1; }

/* animations */
@keyframes word {
  from {
    z-index: 0;
    transform: translateY(50px); }
  to {
    z-index: 1;
    transform: translateY(0px); } }

@keyframes hideDriver {
  from {
    transform: translateX(0px); }
  to {
    transform: translateX(50px);
    @media screen and (min-width: 768px) {
      transform: translateX(70px); } } }

@keyframes geserkiri {
  from {
    transform: translateX(0px); }
  to {
    transform: translateX(-50%); } }

@keyframes scaling {
  from {
    transform: scale(1); }
  to {
    transform: scale(0.8) translateX(-25px); } }

@keyframes upAnimation {
  from {
    transform: translateY(50px); }
  to {
    transform: translateY(0px); } }

@keyframes upAnimationTwo {
  from {
    transform: translateY(-55px); }
  to {
    transform: translateY(0px); } }

/* Small Screen */
/* Medium Screen */
@media screen and (min-width: 48em) {
  section .anchor a {
    font-size: 0.875em;
    font-weight: 700;
    color: #98ce00;
    text-decoration: none;
    letter-spacing: 0.206em; } }

/* Large Screen */
/* Extra Large Screen */
/* star wars */
#starwars .container-gotix-info {
  margin-bottom: 60px; }
  #starwars .container-gotix-info button {
    line-height: normal; }

@media screen and (min-width: 769px) {
  #starwars .gallery--wrapper {
    margin-top: 100px; } }

@media screen and (max-width: 767px) {
  #starwars .gallery--wrapper {
    padding-top: 0px; } }

#starwars section.video-starwars {
  display: inline-block;
  max-width: 960px;
  width: 100%;
  text-align: center; }
  #starwars section.video-starwars .info {
    font-weight: 100; }
    #starwars section.video-starwars .info .title {
      max-width: 700px;
      margin: 15px auto;
      font-size: 27px;
      font-weight: 700;
      color: #cd0000; }
      @media screen and (max-width: 767px) {
        #starwars section.video-starwars .info .title {
          font-size: 18px; } }
    #starwars section.video-starwars .info .content {
      color: #485460;
      font-size: 21px; }
      @media screen and (max-width: 767px) {
        #starwars section.video-starwars .info .content {
          font-size: 14px; } }
  #starwars section.video-starwars .ct-video-sw {
    height: 400px;
    width: 100%;
    position: relative;
    margin-top: 30px;
    background-size: cover;
    background-position: center;
    background-color: #000; }
    @media screen and (max-width: 767px) {
      #starwars section.video-starwars .ct-video-sw {
        width: calc(100% + 20px);
        left: -10px;
        margin: 50px 0px;
        background-position: left;
        height: 350px; } }
    #starwars section.video-starwars .ct-video-sw .show-video iframe {
      height: 400px;
      width: 100%; }
    #starwars section.video-starwars .ct-video-sw .stop-video {
      transition: all 0.5s;
      transform: scale(0);
      position: absolute;
      height: 60px;
      width: 60px;
      right: 0;
      top: 0;
      margin: -30px;
      background: url(../img/card/about/close-btn.png) 50%/29px 29px no-repeat #cd0000;
      border-radius: 50%;
      z-index: 1;
      cursor: pointer; }
      @media screen and (max-width: 767px) {
        #starwars section.video-starwars .ct-video-sw .stop-video {
          margin: -30px 0px; } }
    #starwars section.video-starwars .ct-video-sw .container-thumb-video {
      position: absolute;
      height: 100%;
      width: 100%;
      left: 0;
      bottom: 0;
      background: #000;
      background-size: cover;
      background-position: center;
      cursor: pointer;
      transition: all 0.5s; }
      #starwars section.video-starwars .ct-video-sw .container-thumb-video.disactive {
        opacity: 0;
        pointer-events: none;
        transition: all 0.5s; }
        #starwars section.video-starwars .ct-video-sw .container-thumb-video.disactive + .play-button {
          opacity: 0;
          transition: all 0.5s; }
        #starwars section.video-starwars .ct-video-sw .container-thumb-video.disactive + .play-button + .stop-video {
          transform: scale(1);
          transition: all 0.5s; }
      @media screen and (min-width: 769px) {
        #starwars section.video-starwars .ct-video-sw .container-thumb-video:hover {
          opacity: 0.7;
          transition: all 0.5s; }
          #starwars section.video-starwars .ct-video-sw .container-thumb-video:hover + .play-button img {
            transform: translateX(100px);
            transition: all 0.5s; }
          #starwars section.video-starwars .ct-video-sw .container-thumb-video:hover + .play-button:after {
            transform: translateX(0px);
            transition: all 0.5s; } }
      @media screen and (max-width: 767px) {
        #starwars section.video-starwars .ct-video-sw .container-thumb-video {
          background-position: -160px center; } }
    #starwars section.video-starwars .ct-video-sw .play-button {
      overflow: hidden;
      pointer-events: none;
      cursor: pointer;
      position: absolute;
      height: 80px;
      width: 80px;
      background: rgba(0, 0, 0, 0.7);
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      border-radius: 50%;
      line-height: 90px; }
      #starwars section.video-starwars .ct-video-sw .play-button img {
        transition: all 0.5s; }
      #starwars section.video-starwars .ct-video-sw .play-button:after {
        font-weight: 100;
        content: "Play Video";
        position: absolute;
        height: 50%;
        width: 80%;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        color: #fff;
        line-height: normal;
        transition: all 0.5s;
        transform: translateX(-100px); }
    #starwars section.video-starwars .ct-video-sw .block-right {
      position: absolute;
      right: -20px;
      height: 100%;
      width: 30px;
      top: 0;
      background-repeat: no-repeat;
      background-size: 100% 100%;
      background-position: center; }
      @media screen and (max-width: 767px) {
        #starwars section.video-starwars .ct-video-sw .block-right {
          display: none; } }
    #starwars section.video-starwars .ct-video-sw .block-left {
      position: absolute;
      left: -20px;
      height: 100%;
      width: 30px;
      top: 0;
      background-repeat: no-repeat;
      background-size: 100% 100%;
      background-position: center;
      transform: scaleX(-1); }
      @media screen and (max-width: 767px) {
        #starwars section.video-starwars .ct-video-sw .block-left {
          display: none; } }

#starwars .new-slide-phone {
  display: none; }
  #starwars .new-slide-phone.slick-initialized {
    display: block; }

#starwars .content-sub-menu, #starwars ul.list-content {
  background: #cd0000; }

#starwars .ct-sw-merch .top-info {
  display: inline-block;
  max-width: 860px;
  color: #485460;
  font-weight: 300;
  font-size: 16px;
  text-align: center; }
  @media screen and (max-width: 767px) {
    #starwars .ct-sw-merch .top-info {
      display: block;
      width: 90%;
      text-align: center;
      margin: auto;
      margin-top: 50px; } }

@media screen and (max-width: 767px) {
  #starwars .ct-sw-merch {
    padding: 0px !important; } }

#starwars .merchandise-starwars {
  width: 90%;
  display: inline-block;
  max-width: 960px;
  position: relative;
  height: 534px;
  margin: 50px 0; }
  @media screen and (min-width: 768px) {
    #starwars .merchandise-starwars.reg-ticket .slick-dots {
      bottom: -80px !important; } }
  #starwars .merchandise-starwars.reg-ticket .merch .content {
    font-size: 18px !important; }
  @media screen and (max-width: 767px) {
    #starwars .merchandise-starwars {
      width: 100%;
      margin-top: 0px; } }
  #starwars .merchandise-starwars .table-display {
    text-align: center; }
  #starwars .merchandise-starwars .title {
    color: #cd0000;
    font-size: 18px;
    text-align: center;
    font-weight: 700;
    margin-bottom: 20px; }
  #starwars .merchandise-starwars .container-slider {
    position: relative;
    display: inline-block;
    width: 90%;
    max-width: 500px;
    text-align: center; }
    @media screen and (max-width: 767px) {
      #starwars .merchandise-starwars .container-slider {
        width: 100%;
        max-width: 350px; } }
    @media screen and (max-width: 320px) {
      #starwars .merchandise-starwars .container-slider {
        max-width: 320px; } }
    #starwars .merchandise-starwars .container-slider .frame-inside-left {
      background-size: 100% 100%;
      position: absolute;
      left: -15px;
      transform: scaleX(-1);
      height: 100%;
      width: 15px;
      top: 0;
      bottom: 0;
      margin: auto; }
      @media screen and (max-width: 767px) {
        #starwars .merchandise-starwars .container-slider .frame-inside-left {
          display: none; } }
    #starwars .merchandise-starwars .container-slider .frame-inside-right {
      background-size: 100% 100%;
      position: absolute;
      right: -15px;
      height: 100%;
      width: 15px;
      top: 0;
      bottom: 0;
      margin: auto; }
      @media screen and (max-width: 767px) {
        #starwars .merchandise-starwars .container-slider .frame-inside-right {
          display: none; } }
    #starwars .merchandise-starwars .container-slider .points-merchandise {
      position: absolute;
      left: 0;
      right: 0;
      bottom: -30px;
      color: #cd0000; }
      @media screen and (max-width: 767px) {
        #starwars .merchandise-starwars .container-slider .points-merchandise {
          font-size: 14px;
          bottom: -25px; } }
  #starwars .merchandise-starwars .slider-merch {
    position: relative;
    text-align: center;
    width: 100%;
    display: inline-block; }
    #starwars .merchandise-starwars .slider-merch .merch {
      opacity: 0.8;
      transform: scale(0.8);
      transition: all 0.5s;
      text-align: center; }
      #starwars .merchandise-starwars .slider-merch .merch.slick-active {
        opacity: 1;
        transform: scale(1);
        transition: all 0.5s; }
      #starwars .merchandise-starwars .slider-merch .merch img {
        max-width: 225px;
        margin: auto;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2); }
        @media screen and (max-width: 767px) {
          #starwars .merchandise-starwars .slider-merch .merch img {
            max-width: 200px; } }
      #starwars .merchandise-starwars .slider-merch .merch .content {
        margin-top: 10px;
        font-size: 21px;
        font-weight: 100;
        color: #485460;
        text-align: center; }
    #starwars .merchandise-starwars .slider-merch .prev-merch {
      height: 30px;
      cursor: pointer;
      width: 30px;
      top: -35px;
      display: block;
      position: absolute;
      bottom: 0;
      margin: auto;
      left: -100px;
      background-size: auto 100%;
      background-repeat: no-repeat;
      transform: scaleX(-1); }
    #starwars .merchandise-starwars .slider-merch .next-merch {
      cursor: pointer;
      height: 30px;
      width: 30px;
      top: -35px;
      display: block;
      position: absolute;
      bottom: 0;
      margin: auto;
      right: -100px;
      background-repeat: no-repeat;
      background-size: auto 100%; }
    #starwars .merchandise-starwars .slider-merch ul.slick-dots {
      padding-left: 0px;
      list-style-type: none;
      position: absolute;
      display: block;
      right: 0;
      left: 0;
      bottom: -93px; }
      @media screen and (max-width: 767px) {
        #starwars .merchandise-starwars .slider-merch ul.slick-dots {
          bottom: -135px; } }
      #starwars .merchandise-starwars .slider-merch ul.slick-dots > li {
        cursor: pointer;
        display: inline-block;
        height: 10px;
        width: 10px;
        border-radius: 50%;
        border: 1px solid #fff;
        margin-right: 5px;
        transition: all 0.5s; }
        @media screen and (max-width: 767px) {
          #starwars .merchandise-starwars .slider-merch ul.slick-dots > li {
            background: #dce0e9;
            border: none; } }
        #starwars .merchandise-starwars .slider-merch ul.slick-dots > li:last-child {
          margin-right: 0px; }
        #starwars .merchandise-starwars .slider-merch ul.slick-dots > li.slick-active {
          transition: all 0.5s;
          box-shadow: 0 0 4px 2px rgba(255, 0, 3, 0.8);
          border: 1px solid #cd0000; }
          @media screen and (max-width: 767px) {
            #starwars .merchandise-starwars .slider-merch ul.slick-dots > li.slick-active {
              background: #cd0000;
              border: none; } }
        #starwars .merchandise-starwars .slider-merch ul.slick-dots > li button {
          display: none; }
  #starwars .merchandise-starwars .frame-down {
    background-repeat: no-repeat;
    top: 0;
    height: 75px;
    position: absolute;
    width: 100%;
    background-size: 100%;
    transform: scaleY(-1); }
    @media screen and (max-width: 767px) {
      #starwars .merchandise-starwars .frame-down {
        width: 140%;
        left: -20%; } }
  #starwars .merchandise-starwars .frame-up {
    background-repeat: no-repeat;
    bottom: 0;
    height: 75px;
    position: absolute;
    width: 100%;
    background-size: 100%; }
    @media screen and (max-width: 767px) {
      #starwars .merchandise-starwars .frame-up {
        width: 140%;
        left: -20%; } }
  #starwars .merchandise-starwars .frame-left {
    bottom: 0;
    top: 0;
    margin: auto;
    height: 100%;
    position: absolute;
    width: 50px;
    background-size: 100% 100%;
    left: 0; }
    @media screen and (max-width: 767px) {
      #starwars .merchandise-starwars .frame-left {
        display: none; } }
  #starwars .merchandise-starwars .frame-right {
    bottom: 0;
    top: 0;
    right: 0;
    margin: auto;
    height: 100%;
    position: absolute;
    width: 50px;
    background-size: 100% 100%;
    transform: scaleX(-1); }
    @media screen and (max-width: 767px) {
      #starwars .merchandise-starwars .frame-right {
        display: none; } }
  #starwars .merchandise-starwars .frame-bottom-left {
    position: absolute;
    width: 35%;
    height: 60px;
    background-size: 100% 100%;
    left: 0;
    bottom: 50px; }
  #starwars .merchandise-starwars .frame-bottom-right {
    transform: scaleX(-1);
    position: absolute;
    width: 35%;
    height: 60px;
    background-size: 100% 100%;
    right: 0;
    bottom: 50px; }
  #starwars .merchandise-starwars .frame-top-right {
    position: absolute;
    width: 35%;
    height: 60px;
    background-size: 100% 100%;
    right: 0;
    top: 50px;
    transform: scaleX(-1) scaleY(-1); }
  #starwars .merchandise-starwars .frame-top-left {
    position: absolute;
    width: 35%;
    height: 60px;
    background-size: 100% 100%;
    left: 0;
    top: 50px;
    transform: scaleY(-1); }
  #starwars .merchandise-starwars .table-display {
    display: table;
    height: 100%;
    width: 100%; }
    #starwars .merchandise-starwars .table-display .mid-table {
      display: table-cell;
      vertical-align: middle; }

#starwars .gopoints-info {
  font-weight: 300;
  color: #485460;
  padding: 45px 90px;
  border: 3px solid #cd0000;
  padding-top: 20px;
  display: inline-block;
  max-width: 960px;
  width: 90%;
  margin-top: 50px;
  box-shadow: 0 0 2px 1px rgba(255, 0, 2, 0.45); }
  #starwars .gopoints-info .title {
    position: relative;
    color: #cd0000;
    font-size: 18px;
    background-color: #f8f8f8;
    top: -34px;
    display: inline-block;
    padding: 0 30px;
    font-weight: 700; }
    @media screen and (max-width: 767px) {
      #starwars .gopoints-info .title {
        text-align: center;
        width: 100%; } }
  @media screen and (max-width: 767px) {
    #starwars .gopoints-info {
      padding: 20px;
      width: 100%;
      border: 2px solid #cd0000;
      margin-bottom: 50px; } }

#starwars section.snk-starwars {
  position: relative;
  font-family: "Open Sans", Helvetica, sans-serif;
  padding: 18px;
  width: 100%;
  max-width: 960px;
  display: inline-block;
  margin-bottom: 50px; }
  #starwars section.snk-starwars .frame-up {
    position: absolute;
    background-image: url(/static/dist/img/page/starwars/framesnk.svg);
    height: 100px;
    width: 130px;
    margin: -15px; }
  #starwars section.snk-starwars .frame-down {
    position: absolute;
    background-image: url(/static/dist/img/page/starwars/framesnk.svg);
    height: 100px;
    width: 130px;
    margin: 3px;
    background-position: right bottom;
    right: 0;
    bottom: 0; }
  #starwars section.snk-starwars .content {
    border: 2px solid #d1011c;
    padding: 30px;
    text-align: left; }
    #starwars section.snk-starwars .content .title {
      text-transform: uppercase;
      color: #cd0000;
      font-weight: 700;
      font-size: 18px; }
      #starwars section.snk-starwars .content .title:before {
        position: relative;
        background-color: #cd0000;
        box-shadow: rgba(255, 0, 3, 0.8) 0px 0px 4px 2px;
        border-width: 1px;
        border-style: solid;
        border-color: rgba(255, 0, 0, 0.9);
        border-image: initial;
        content: "";
        height: 2px;
        width: 30px;
        display: inline-block;
        margin-right: 25px;
        bottom: 2px; }
        @media screen and (max-width: 767px) {
          #starwars section.snk-starwars .content .title:before {
            width: 10px;
            margin-right: 10px; } }
      @media screen and (max-width: 767px) {
        #starwars section.snk-starwars .content .title {
          position: relative;
          left: -30px;
          font-size: 16px; } }
    #starwars section.snk-starwars .content ol {
      padding: 0 75px;
      color: #dedede;
      font-size: 14px; }
      #starwars section.snk-starwars .content ol > li {
        line-height: 30px;
        margin-bottom: 10px; }
      @media screen and (max-width: 768px) {
        #starwars section.snk-starwars .content ol {
          padding: 7px;
          font-size: 14px; } }
    #starwars section.snk-starwars .content ul.extra, #starwars section.snk-starwars .content ul.sub-extra {
      color: #dedede;
      font-size: 14px; }
      #starwars section.snk-starwars .content ul.extra > li, #starwars section.snk-starwars .content ul.sub-extra > li {
        line-height: 30px;
        margin-bottom: 10px; }
      @media screen and (max-width: 768px) {
        #starwars section.snk-starwars .content ul.extra, #starwars section.snk-starwars .content ul.sub-extra {
          padding: 20px;
          font-size: 14px; } }

#starwars .starwars-use {
  max-width: 960px; }
  #starwars .starwars-use .frame-left {
    position: absolute;
    height: 80%;
    width: 30px;
    left: -30px;
    top: 0;
    bottom: 0;
    margin: auto;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    transform: scaleX(-1); }
  #starwars .starwars-use .frame-right {
    position: absolute;
    height: 80%;
    width: 30px;
    right: -30px;
    top: 0;
    bottom: 0;
    margin: auto;
    background-size: 100% 100%;
    background-repeat: no-repeat; }
  #starwars .starwars-use .title {
    color: #cd0000; }
    #starwars .starwars-use .title:after {
      background: #cd0000; }
    @media screen and (min-width: 769px) {
      #starwars .starwars-use .title {
        width: 84%;
        margin-top: 80px; } }
  #starwars .starwars-use .content {
    color: #485460;
    background: #fff; }
    #starwars .starwars-use .content .bottom-frame {
      background-size: 100% 100%;
      background-repeat: no-repeat;
      width: 80%;
      height: 30px;
      position: absolute;
      margin: auto;
      left: 0;
      right: 0;
      bottom: -58px; }
      @media screen and (max-width: 767px) {
        #starwars .starwars-use .content .bottom-frame {
          bottom: -15px; } }
      @media screen and (min-width: 768px) {
        #starwars .starwars-use .content .bottom-frame {
          left: -20px; } }
    #starwars .starwars-use .content .up-frame {
      bottom: auto;
      top: -150px;
      transform: scaleY(-1);
      background-size: 100% 100%;
      background-repeat: no-repeat;
      width: 80%;
      height: 30px;
      position: absolute;
      margin: auto;
      left: 0;
      right: 0; }
      @media screen and (max-width: 767px) {
        #starwars .starwars-use .content .up-frame {
          top: -15px; } }
      @media screen and (min-width: 768px) {
        #starwars .starwars-use .content .up-frame {
          left: -20px; } }
    #starwars .starwars-use .content .number {
      color: #cd0000; }
    @media screen and (min-width: 769px) {
      #starwars .starwars-use .content {
        width: 44%;
        right: 18.5%;
        background: transparent; } }
    #starwars .starwars-use .content .phoneSlide-dots li {
      background-color: #ececec;
      border: none;
      transform: scale(0.9); }
    #starwars .starwars-use .content .phoneSlide-dots li.slick-active {
      transform: scale(1);
      background-color: transparent;
      border: 1px solid #cd0000;
      background-color: #cd0000;
      box-shadow: rgba(255, 0, 3, 0.8) 0px 0px 4px 2px; }
    @media screen and (min-width: 769px) {
      #starwars .starwars-use .content {
        margin-top: 150px; } }
  @media screen and (min-width: 769px) {
    #starwars .starwars-use .right-phone {
      width: 59%; } }
  #starwars .starwars-use .slide-content {
    max-width: 320px;
    display: inline-block; }
  #starwars .starwars-use .slide-prevArrow {
    border-radius: 0px;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    border: none;
    transform: rotate(180deg); }
    @media screen and (min-width: 769px) {
      #starwars .starwars-use .slide-prevArrow {
        left: 0;
        top: -65px;
        padding: 15px; } }
    #starwars .starwars-use .slide-prevArrow img {
      display: none; }
  #starwars .starwars-use .slide-nextArrow {
    background-size: 100% 100%;
    background-repeat: no-repeat;
    border: none; }
    @media screen and (min-width: 769px) {
      #starwars .starwars-use .slide-nextArrow {
        right: 0;
        top: -65px;
        height: 30px;
        width: 30px;
        border-radius: 0px; } }
    #starwars .starwars-use .slide-nextArrow img {
      display: none; }
  @media screen and (max-width: 768px) {
    #starwars .starwars-use {
      margin-top: 25px !important;
      margin-bottom: 30px; } }

#starwars .about-first-day {
  text-align: center;
  display: inline-block;
  width: 100%;
  padding: 60px;
  margin-bottom: 30px; }
  @media screen and (max-width: 768px) {
    #starwars .about-first-day {
      background-size: 500px;
      background-position: 100% 135%;
      background-image: url(https://d3naj63yelf4gk.cloudfront.net/dist/img/faq/geometry.svg);
      background-repeat: no-repeat;
      padding: 30px 10px; }
      #starwars .about-first-day .switch {
        display: table;
        width: 100%; } }
  #starwars .about-first-day h4.title--content {
    color: #cd0000;
    margin-top: 0px; }
    @media screen and (max-width: 768px) {
      #starwars .about-first-day h4.title--content {
        text-align: left;
        font-size: 16px; } }
  #starwars .about-first-day p {
    max-width: 700px;
    font-size: 14px;
    margin: auto;
    text-align: center; }
  #starwars .about-first-day .container-switch {
    margin: auto;
    position: relative;
    width: 100%;
    max-width: 960px; }
    #starwars .about-first-day .container-switch .center-image {
      background-size: cover;
      background-position: center;
      position: absolute;
      height: 100%;
      width: 50%;
      left: 0;
      right: 0;
      margin: auto;
      top: 60px;
      height: 300px;
      z-index: 2; }
      @media screen and (max-width: 768px) {
        #starwars .about-first-day .container-switch .center-image {
          position: relative;
          width: calc(100% + 20px);
          left: -10px;
          top: 0; } }
      @media screen and (max-width: 500px) {
        #starwars .about-first-day .container-switch .center-image {
          height: 250px; } }
    @media screen and (max-width: 768px) {
      #starwars .about-first-day .container-switch {
        display: table-caption;
        margin: 20px 0; } }
  #starwars .about-first-day .container-image {
    width: 100%;
    max-width: 960px;
    height: 300px;
    background: #ececec;
    margin: auto;
    margin-top: 60px;
    display: inline-block;
    position: relative; }
    #starwars .about-first-day .container-image .left-image {
      position: absolute;
      left: 0;
      top: 0;
      width: 25%;
      height: 100%; }
      #starwars .about-first-day .container-image .left-image > div {
        background-size: cover !important;
        background-position: center;
        position: relative;
        float: left;
        width: 100%;
        height: 150px; }
        @media screen and (max-width: 768px) {
          #starwars .about-first-day .container-image .left-image > div {
            width: 50%;
            height: 100%; } }
      @media screen and (max-width: 768px) {
        #starwars .about-first-day .container-image .left-image {
          position: relative;
          height: 150px;
          top: 0;
          width: 100%; } }
      @media screen and (max-width: 500px) {
        #starwars .about-first-day .container-image .left-image {
          height: 100px; } }
    #starwars .about-first-day .container-image .right-image {
      position: absolute;
      right: 0;
      top: 0;
      height: 100%;
      width: 25%;
      margin: auto; }
      #starwars .about-first-day .container-image .right-image > div {
        position: relative;
        float: left;
        width: 100%;
        height: 100.2px;
        background-size: cover;
        background-position: center; }
        @media screen and (max-width: 768px) {
          #starwars .about-first-day .container-image .right-image > div {
            height: 100%;
            width: 33.3%; } }
      @media screen and (max-width: 768px) {
        #starwars .about-first-day .container-image .right-image {
          height: 75px;
          bottom: 0;
          top: auto;
          width: 100%;
          position: relative; } }
      @media screen and (max-width: 500px) {
        #starwars .about-first-day .container-image .right-image {
          height: 50px; } }
    @media screen and (max-width: 768px) {
      #starwars .about-first-day .container-image {
        height: auto;
        max-width: 500px;
        margin-top: 20px; } }
  #starwars .about-first-day .frame {
    height: 116%;
    position: absolute;
    width: 105%;
    left: -2.5%;
    background-size: 100%;
    background-position: center;
    background-repeat: no-repeat;
    top: -8%; }
    @media screen and (max-width: 768px) {
      #starwars .about-first-day .frame {
        display: none; } }

#starwars .select-top {
  display: none;
  padding: 0;
  margin: 0 auto;
  border: 0;
  width: 300px;
  border-radius: 0;
  overflow: hidden;
  background: #fff;
  position: absolute;
  top: auto;
  bottom: -20px;
  left: 0;
  right: 0;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.13); }
  #starwars .select-top.hero {
    position: relative;
    margin: 0 auto;
    bottom: 0; }
  #starwars .select-top .bg--select {
    width: 50px;
    height: 100%;
    background: #cd0000 url(https://d3naj63yelf4gk.cloudfront.net/dist/img/select-more.svg) no-repeat 55% 50%;
    position: absolute;
    right: 0;
    z-index: 1;
    background-size: 13px; }
  #starwars .select-top select {
    padding: 15px;
    width: 105%;
    border: none;
    box-shadow: none;
    background-color: transparent;
    background-image: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    position: relative;
    z-index: 5; }
  @media screen and (max-width: 768px) {
    #starwars .select-top {
      display: block;
      margin-top: 50px !important; } }

#starwars button.starwars {
  padding: 10px 20px;
  width: 300px;
  background-color: #cd0000;
  font-weight: 100;
  color: #fff;
  border-radius: 5px;
  box-shadow: 0 0 4px 2px rgba(255, 0, 3, 0.8);
  border: solid 1px rgba(255, 0, 0, 0.9);
  transition: all 0.5s; }
  @media screen and (max-width: 767px) {
    #starwars button.starwars {
      width: 100%; } }
  @media screen and (min-width: 769px) {
    #starwars button.starwars:hover {
      transform: scale(1.05);
      transition: all 0.5s; } }

#starwars section.starwars-gotix-info {
  width: 90%;
  max-width: 960px;
  background-repeat: round;
  background-size: 900px;
  background-position: center;
  display: block;
  padding: 30px;
  color: #fff;
  margin: auto; }
  #starwars section.starwars-gotix-info.blue .title {
    color: #40bfff !important; }
  #starwars section.starwars-gotix-info.blue button.starwars {
    background-color: #40bfff;
    box-shadow: 0 0 10px 1px rgba(187, 229, 250, 0.8);
    border: solid 1px rgba(0, 103, 155, 0.9); }
  @media screen and (min-width: 769px) {
    #starwars section.starwars-gotix-info.blue {
      padding: 57px; } }
  #starwars section.starwars-gotix-info .content-container {
    padding: 30px; }
    #starwars section.starwars-gotix-info .content-container .content {
      padding: 75px;
      background-size: 100% 100%;
      background-position: center;
      background-repeat: no-repeat; }
      @media screen and (max-width: 768px) {
        #starwars section.starwars-gotix-info .content-container .content {
          padding: 0px; } }
    @media screen and (max-width: 768px) {
      #starwars section.starwars-gotix-info .content-container {
        display: inline-block;
        width: 100%; } }
  #starwars section.starwars-gotix-info .table-display {
    display: table;
    height: 100%;
    width: 100%; }
    #starwars section.starwars-gotix-info .table-display .middle-table {
      width: 50%;
      padding: 20px;
      display: table-cell;
      vertical-align: middle;
      text-align: left;
      padding: 0 30px; }
      #starwars section.starwars-gotix-info .table-display .middle-table .title {
        font-size: 14px;
        font-weight: 700;
        color: #cd0000; }
      #starwars section.starwars-gotix-info .table-display .middle-table .big-title {
        font-size: 28px;
        font-weight: 100;
        margin: 20px 0; }
        #starwars section.starwars-gotix-info .table-display .middle-table .big-title span {
          font-size: 18px;
          font-weight: 100; }
      @media screen and (max-width: 768px) {
        #starwars section.starwars-gotix-info .table-display .middle-table {
          display: inline-block;
          width: 100%;
          padding: 0px; } }
  @media screen and (max-width: 768px) {
    #starwars section.starwars-gotix-info {
      width: 100%;
      padding: 10px; }
      #starwars section.starwars-gotix-info .content {
        background-image: none !important; } }
  #starwars section.starwars-gotix-info button {
    text-align: left; }

#starwars #about-starwars {
  background-color: #f8f8f8; }
  #starwars #about-starwars .container:before {
    display: none; }
  #starwars #about-starwars .table-display {
    height: 100%;
    display: table; }
    #starwars #about-starwars .table-display .middle-table {
      display: table-cell;
      vertical-align: middle; }
    #starwars #about-starwars .table-display .title-top {
      font-size: 22px; }
      @media screen and (max-width: 768px) {
        #starwars #about-starwars .table-display .title-top {
          font-size: 14px; } }
  #starwars #about-starwars .float-robot {
    position: absolute;
    right: -30px;
    bottom: -10px;
    z-index: 1; }
    @media screen and (max-width: 768px) {
      #starwars #about-starwars .float-robot {
        width: 100px;
        right: 0; } }
  #starwars #about-starwars .block-right {
    background-color: #fafafa;
    position: absolute;
    right: 0;
    height: 100%;
    width: 30px;
    top: 0;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center; }
  #starwars #about-starwars.gotix-sw-about {
    padding-bottom: 0px; }
    @media screen and (max-width: 767px) {
      #starwars #about-starwars.gotix-sw-about .description {
        position: static; } }
    #starwars #about-starwars.gotix-sw-about .play {
      background: rgba(0, 0, 0, 0.5);
      transition: all 0.5s; }
      #starwars #about-starwars.gotix-sw-about .play:hover {
        transition: all 0.5s;
        background: white; }
        #starwars #about-starwars.gotix-sw-about .play:hover img {
          transform: translateX(100px); }
      #starwars #about-starwars.gotix-sw-about .play:after {
        color: #cd0000; }
    #starwars #about-starwars.gotix-sw-about .title {
      color: #cd0000;
      font-weight: 700;
      font-size: 18px; }
      #starwars #about-starwars.gotix-sw-about .title:before {
        position: relative;
        background-color: #cd0000;
        box-shadow: rgba(255, 0, 3, 0.8) 0px 0px 4px 2px;
        border-width: 1px;
        border-style: solid;
        border-color: rgba(255, 0, 0, 0.9);
        border-image: initial;
        content: "";
        height: 3px;
        width: 50px;
        display: inline-block;
        margin-right: 25px;
        margin-left: -25px;
        bottom: 2px; }
        @media screen and (max-width: 767px) {
          #starwars #about-starwars.gotix-sw-about .title:before {
            width: 25px;
            margin-right: 10px; } }
      @media screen and (max-width: 767px) {
        #starwars #about-starwars.gotix-sw-about .title {
          position: absolute;
          top: -40px; } }
    #starwars #about-starwars.gotix-sw-about .title-top {
      font-size: 18px; }
    @media screen and (max-width: 767px) {
      #starwars #about-starwars.gotix-sw-about {
        margin-top: 50px; } }

#starwars .vertical-slider {
  background-size: 100% 100%;
  background-position: center;
  padding: 100px;
  width: 90%;
  margin: 50px auto; }
  #starwars .vertical-slider .detail {
    height: 180px;
    border-radius: 0px 20px 20px 0px; }
  #starwars .vertical-slider .detail .custom-nav .slick-disabled {
    filter: grayscale(1) brightness(2); }
  #starwars .vertical-slider .container {
    width: 90%;
    max-width: 960px; }
    @media screen and (max-width: 768px) {
      #starwars .vertical-slider .container {
        width: 100%; } }
  @media screen and (max-width: 768px) {
    #starwars .vertical-slider {
      width: 100%;
      padding: 0px;
      background-image: none !important; } }
  @media screen and (max-width: 767px) {
    #starwars .vertical-slider .custom-nav.slide-mobile {
      display: block;
      margin: 0 auto;
      margin-top: 20px; } }
  #starwars .vertical-slider .custom-nav.slide-mobile svg.slick-arrow.slick-disabled {
    filter: grayscale(1) brightness(2); }

#starwars #how-starwars {
  margin-top: 0px;
  margin-bottom: 0px; }
  @media screen and (min-width: 768px) {
    #starwars #how-starwars .container {
      padding-bottom: 50px; } }
  #starwars #how-starwars h4.section--title {
    color: #cd0000; }
    #starwars #how-starwars h4.section--title:before {
      background-color: #cd0000;
      box-shadow: 0 0 4px 2px rgba(255, 0, 3, 0.8);
      border: solid 1px rgba(255, 0, 0, 0.9); }
      @media screen and (max-width: 767px) {
        #starwars #how-starwars h4.section--title:before {
          top: 10px; } }
    @media screen and (max-width: 767px) {
      #starwars #how-starwars h4.section--title {
        padding-left: 10px;
        margin-bottom: 20px; } }
  #starwars #how-starwars .layer {
    padding-bottom: 20px; }
  #starwars #how-starwars .rect {
    background: transparent; }
    #starwars #how-starwars .rect img {
      background-image: none; }
      @media screen and (max-width: 768px) {
        #starwars #how-starwars .rect img {
          top: auto; } }
    @media screen and (max-width: 768px) {
      #starwars #how-starwars .rect figure {
        height: auto;
        width: 90%;
        position: relative;
        margin: auto; } }
  #starwars #how-starwars .img--wrapper {
    overflow: visible; }
  #starwars #how-starwars p.how--desc {
    margin: 20px 0; }
    @media screen and (max-width: 768px) {
      #starwars #how-starwars p.how--desc {
        font-size: 14px; } }
  #starwars #how-starwars a {
    line-height: inherit;
    font-size: inherit; }
  #starwars #how-starwars .desc--pad {
    font-family: "Open Sans", Helvetica, sans-serif; }
    #starwars #how-starwars .desc--pad .title {
      font-weight: 100;
      font-size: 28px; }
    @media screen and (max-width: 768px) {
      #starwars #how-starwars .desc--pad {
        position: relative;
        margin: auto; } }

#starwars #promo-starwars {
  background-color: #fafafa; }
  #starwars #promo-starwars .container:before {
    display: none; }
  #starwars #promo-starwars .bg-services {
    opacity: 1;
    background-color: #fafafa;
    border: 1px solid #cd0000;
    height: 150px; }
    @media screen and (max-width: 767px) {
      #starwars #promo-starwars .bg-services {
        height: 105px; } }
  #starwars #promo-starwars .service-container {
    background-color: #000;
    background-size: cover; }
  #starwars #promo-starwars .service-list {
    border: 2px solid #cd0000;
    border-radius: 5px;
    padding-bottom: 0px;
    box-shadow: rgba(255, 0, 3, 0.8) 0px 0px 4px 2px; }
    #starwars #promo-starwars .service-list:after {
      background-position: top center;
      margin-top: 30px;
      position: relative;
      display: inline-block;
      width: 100%;
      height: 30px;
      content: "";
      float: left;
      background-size: cover; }
    #starwars #promo-starwars .service-list p {
      min-height: 200px !important; }
  @media screen and (max-width: 767px) {
    #starwars #promo-starwars .navigation-arrow {
      padding-top: 220px; } }
  #starwars #promo-starwars .wrapper h4 {
    color: #cd0000; }
    #starwars #promo-starwars .wrapper h4:before {
      background-color: #cd0000;
      box-shadow: rgba(255, 0, 3, 0.8) 0px 0px 4px 2px;
      border-width: 1px;
      border-style: solid;
      border-color: rgba(255, 0, 0, 0.9);
      border-image: initial;
      content: "";
      position: absolute;
      left: -50px;
      top: 0.6em;
      padding-right: 45px;
      height: 3px;
      z-index: 10;
      margin: 0px; }
      @media screen and (max-width: 767px) {
        #starwars #promo-starwars .wrapper h4:before {
          top: 57px;
          left: -35px; } }
    @media screen and (max-width: 767px) {
      #starwars #promo-starwars .wrapper h4 {
        font-size: 18px; } }
  #starwars #promo-starwars a.bottom {
    display: inline-block;
    width: 100%;
    text-align: center;
    color: #cd0000; }
    #starwars #promo-starwars a.bottom:after {
      background-image: none;
      content: "→";
      position: relative;
      top: 1px !important; }

#starwars footer {
  background-color: #fafafa;
  background-size: cover;
  background-position: center; }
  @media screen and (max-width: 767px) {
    #starwars footer {
      background-repeat: no-repeat;
      background-position: bottom;
      background-size: 300%; } }

#starwars .child-navbar ul li a {
  position: relative;
  overflow: hidden; }

#starwars .child-navbar ul li a.active {
  color: #fff !important; }

#starwars .child-navbar ul li a.career-tab:after {
  display: none; }

#starwars .child-navbar ul li a.career-tab:hover {
  background-color: #fff !important;
  color: #cd0000; }

#starwars .child-navbar ul li a:not(.career-tab):after {
  content: "";
  width: 100%;
  position: absolute;
  bottom: 0;
  height: 4px;
  border-radius: 20px;
  left: 0;
  background: red;
  transition: transform 0.5s, box-shadow 0.1s;
  box-shadow: none;
  transform: translateX(-100%); }

#starwars .child-navbar ul li a:not(.career-tab):hover {
  color: #cd0000 !important; }

#starwars .child-navbar ul li a.active:after {
  transition: all 0.5s;
  transform: translateX(0%);
  top: auto;
  transition: transform 0.5s, box-shadow 0.5s 0.15s;
  box-shadow: 0px 0px 15px 1px #ff1414;
  color: #fff; }

#starwars #main--hero .pure-g {
  box-shadow: none !important; }

#starwars #main--hero:after {
  box-shadow: none !important; }

#starwars .gallery--wrapper h2.section-title {
  padding-left: 65px;
  position: relative;
  color: #cd0000; }
  #starwars .gallery--wrapper h2.section-title:before {
    background-color: #cd0000;
    box-shadow: 0 0 4px 2px rgba(255, 0, 3, 0.8);
    border: 1px solid rgba(255, 0, 0, 0.9);
    border-image: initial;
    content: "";
    position: absolute;
    left: 0;
    top: 45px;
    padding-right: 45px;
    height: 3px;
    z-index: 10;
    margin: 0; }

#starwars .gallery--wrapper .discover--title {
  background-color: #cd0000; }

#starwars .container-inline {
  display: inline-block;
  width: 100%;
  text-align: center; }
  @media screen and (max-width: 767px) {
    #starwars .container-inline {
      text-align: left;
      padding: 10px; }
      #starwars .container-inline h4 {
        padding: 10px; } }
  #starwars .container-inline .option-bar {
    overflow: hidden;
    position: relative;
    z-index: 10;
    display: inline-block;
    padding: 20px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;
    width: 100%;
    max-width: 960px;
    font-size: 14px;
    letter-spacing: 1px;
    font-family: "Open Sans", Helvetica, sans-serif;
    color: #485460;
    transform: translateY(-50%); }
    #starwars .container-inline .option-bar a {
      display: inline-block;
      width: 100%;
      color: inherit;
      font-size: inherit;
      letter-spacing: 1.5px;
      padding: 15px 0;
      transition: all 0.5s; }
      #starwars .container-inline .option-bar a:hover:not(.active) {
        color: #cd0000;
        transition: all 0.5s;
        transform: scale(1.1); }
      #starwars .container-inline .option-bar a.active {
        background-color: #cd0000;
        color: #fff; }
    #starwars .container-inline .option-bar .pure-g > .child-option {
      position: relative;
      border-right: 1px solid #cd0000;
      transition: all 0.5s; }
      #starwars .container-inline .option-bar .pure-g > .child-option:last-child {
        border-right: none; }
    @media screen and (max-width: 768px) {
      #starwars .container-inline .option-bar {
        display: none; } }

/* DOWNLOAD FLYER BANNER */
#download-banner .appsflyer-banner-container {
  position: fixed;
  height: 75px;
  bottom: 0;
  left: 0;
  padding: 0 15px;
  border-radius: 15px 15px 0px 0px;
  width: 100%;
  z-index: 20;
  background-color: #ffffff;
  box-shadow: 0 -2px 4px 0 rgba(0, 0, 0, 0.2);
  display: table;
  margin: auto; }
  #download-banner .appsflyer-banner-container > div {
    display: table-cell;
    vertical-align: middle; }
  #download-banner .appsflyer-banner-container .appsflyer-banner-icon-img {
    width: 35px;
    padding: 5px;
    background-color: #000; }
  #download-banner .appsflyer-banner-container .appsflyer-banner-title {
    font-weight: 700;
    font-size: 12px;
    font-family: neo; }
  #download-banner .appsflyer-banner-container .appsflyer-banner-subtitle {
    font-size: 10px; }
  #download-banner .appsflyer-banner-container img.appsflyer-banner-close-img {
    border-radius: 5px;
    width: 13px;
    margin-right: 5px; }
  #download-banner .appsflyer-banner-container button {
    width: 100%;
    position: relative;
    top: 20px;
    letter-spacing: normal;
    color: #fff;
    border-radius: 5px;
    font-family: neo;
    padding: 12px 10px 10px 10px;
    font-size: 12px; }

@media screen and (min-width: 769px) {
  #download-banner {
    display: none; } }

#goride header button {
  background: #F9AE3C; }

#goride .articles--feed {
  background: #1f4864; }
  #goride .articles--feed .bg {
    background: #1f4864; }
  #goride .articles--feed .line--title {
    background: #ffcd2e; }

#goride #content-only {
  transform: scale(0.96);
  margin-bottom: -30px; }
  #goride #content-only .container {
    box-shadow: 0 -3em 0 1.5em #F9AE3C; }
    #goride #content-only .container .info h4 {
      color: #1f4864; }
    #goride #content-only .container .info .line {
      background: #F9AE3C; }
    #goride #content-only .container .info .link .contact {
      background: #F9AE3C; }

#goride #join-now {
  margin: 0 0 30px 0; }
  #goride #join-now .right a {
    background: #F9AE3C;
    bottom: initial;
    margin-top: 20px;
    right: 0; }
  #goride #join-now .right .title {
    font-size: 18px; }
  #goride #join-now .right h2 {
    margin-top: -5px; }

#goride .section-how-to-use h5 {
  color: #1f4864 !important;
  font-size: 18px;
  margin-top: 200px; }

#goride .section-how-to-use .line.gradient {
  background: #F9AE3C; }

#goride .section-how-to-use .arrow-up {
  stroke: #F9AE3C; }

#goride .section-how-to-use .arrow-up-circle {
  stroke-width: 3;
  stroke: #F9AE3C; }

#goride .section-how-to-use .arrow-down {
  stroke: #F9AE3C; }

#goride .section-how-to-use .arrow-down-circle {
  stroke: #F9AE3C;
  stroke-width: 3; }

#goride .section-how-to-use .slider .slick-dots li.slick-active button:before {
  color: #F9AE3C; }

#goride .section-how-to-use .slider-image .slick-dots li.slick-active button:before {
  color: #F9AE3C; }

#goride .section-how-to-use .text-mobile .line.gradient {
  background: #F9AE3C; }

#goride .section-how-to-use .text h5 {
  color: #F9AE3C; }

#goride .section-how-to-use .text .line.gradient {
  background: #F9AE3C; }

#goride .section-how-to-use .slider-image .slick-dots li.slick-active button:before {
  color: #F9AE3C; }

#goride .section-how-to-use .slider-image .slick-dots li button:before {
  font-size: 13px; }

#goride .slick-arrow {
  cursor: pointer; }
  #goride .slick-arrow ellipse {
    stroke: #F9AE3C !important; }
  #goride .slick-arrow polygon {
    fill: #F9AE3C; }

#goride .how-it-works--wrapper {
  margin: 0;
  padding-top: 60px;
  padding-bottom: 60px; }
  #goride .how-it-works--wrapper .container {
    padding: 0; }
    #goride .how-it-works--wrapper .container h4 {
      color: #F9AE3C;
      position: relative;
      margin-left: 3em; }
    #goride .how-it-works--wrapper .container .section--title {
      color: #1f4864; }
    #goride .how-it-works--wrapper .container .section--title:before {
      content: "";
      position: absolute;
      left: -5em;
      top: 0.6em;
      padding-right: 45px;
      height: 3px;
      margin: 0;
      background-color: #F9AE3C;
      z-index: 10; }
    #goride .how-it-works--wrapper .container figure.hand-ss {
      background: #fff; }
      #goride .how-it-works--wrapper .container figure.hand-ss img {
        max-height: 27em;
        width: auto;
        right: 0;
        margin: 0 auto;
        position: absolute;
        left: 0; }
    #goride .how-it-works--wrapper .container .layer .rect--primary {
      background: #F9AE3C; }
    #goride .how-it-works--wrapper .container .layer .rect--primary.custom-color {
      background: #dbdbdb; }
    #goride .how-it-works--wrapper .container .layer .rect--secondary.custom-color {
      background: #1f4864; }

#goride .new-section-how-to-use {
  margin-top: 60px;
  margin-bottom: 60px; }
  #goride .new-section-how-to-use h3.title {
    color: #f9ae3c; }
    #goride .new-section-how-to-use h3.title:after {
      background: #f9ae3c; }
  #goride .new-section-how-to-use .content {
    background-color: #f9ae3c; }
  @media screen and (max-width: 767px) {
    #goride .new-section-how-to-use {
      margin-top: 50px !important; } }

@media screen and (max-width: 768px) {
  #goride #content-only {
    transform: unset !important; }
  #goride .how-it-works--wrapper {
    padding-top: 0;
    margin-top: -5px; }
    #goride .how-it-works--wrapper .container .section--title {
      margin-left: 4.2em;
      position: relative;
      top: 10px; }
      #goride .how-it-works--wrapper .container .section--title:before {
        top: .565em !important; } }

@media screen and (max-width: 35.5em) {
  #goride .how-it-works--wrapper {
    padding-top: 0;
    margin-top: -5px; }
    #goride .how-it-works--wrapper .container .section--title {
      margin-left: 4.2em;
      position: relative;
      top: 10px; }
      #goride .how-it-works--wrapper .container .section--title:before {
        top: .565em !important; }
  #goride #join-now .right .button {
    text-align: center; }
    #goride #join-now .right .button a {
      right: unset !important;
      position: relative;
      top: 55px; }
  #goride #content-only {
    padding: 30px 10px; }
    #goride #content-only .container {
      box-shadow: 0 -110px 0 40px #f9ae3c; }
      #goride #content-only .container .info p {
        margin: 0;
        margin-bottom: -30px;
        padding: 2.5px; }
      #goride #content-only .container .link {
        position: relative;
        top: 40px; }
        #goride #content-only .container .link a {
          margin: 0 auto;
          display: block;
          width: 200px;
          padding: 30px; }
        #goride #content-only .container .link .faq {
          position: relative;
          top: -10px; }
  #goride .section-how-to-use .arrow--wrapper {
    top: 25%; }
  #goride .section-how-to-use ul.slick-dots {
    margin-top: -3.5em; }
  #goride .section-how-to-use .phone-wrapper {
    top: 31%; } }

#gotix {
  /* new-cards */ }
  #gotix section[new-version].new-how-to-use-promo h5.title-underscore {
    color: #093c5b; }
    #gotix section[new-version].new-how-to-use-promo h5.title-underscore:after {
      background-color: #093c5b; }
  #gotix section[new-version].new-how-to-use-promo .container-list {
    background-color: #EA6B25; }
  #gotix section[new-version].new-how-to-use-promo p {
    color: #4a4a4a; }
  #gotix section[new-version].new-more-information h5.title-underscore {
    color: #093c5b; }
    #gotix section[new-version].new-more-information h5.title-underscore:after {
      background-color: #093c5b; }
  #gotix header#main--hero.donation.ystc {
    background-image: url(https://d3naj63yelf4gk.cloudfront.net/dist/img/page/gotix/ramadhan/header/donation-ystc.jpg); }
  #gotix header#main--hero.donation.rumahzakat {
    background-image: url(https://d3naj63yelf4gk.cloudfront.net/dist/img/page/gotix/ramadhan/header/rumahzakat.jpg); }
  #gotix header#main--hero.donation.sos {
    background-image: url(https://d3naj63yelf4gk.cloudfront.net/dist/img/page/gotix/ramadhan/header/sos.jpg); }
  #gotix header#main--hero.donation.wecare {
    background-image: url(https://d3naj63yelf4gk.cloudfront.net/dist/img/page/gotix/ramadhan/header/wecare.jpg); }
  #gotix header#main--hero.donation .hero--desc {
    font-size: 20px;
    display: block !important; }
  #gotix p {
    color: #9b9b9b; }
  #gotix .thank-you {
    margin-top: 150px; }
    #gotix .thank-you h4 {
      color: #EA6B25 !important; }
    #gotix .thank-you .line {
      background: #EA6B25 !important; }
    #gotix .thank-you .contact {
      width: 100% !important;
      max-width: 300px;
      line-height: normal;
      background: #EA6B25 !important; }
    #gotix .thank-you .container {
      box-shadow: 0 -3em 0 1.5em #EA6B25; }
  #gotix .how-it-works--wrapper .container:before {
    background-color: transparent; }
  #gotix .how-it-works--wrapper .section--title {
    color: #343b41;
    padding: 0; }
  #gotix .how-it-works--wrapper .section--title:before {
    background-color: #EA6B25; }
  #gotix .how-it-works--wrapper .layer .rect--primary {
    background: #EA6B25; }
  #gotix #join-now .right a {
    background: #EA6B25; }
  #gotix #join-now .left {
    -webkit-box-shadow: inset 0px 0px 300px 200px #ea6b25;
    -moz-box-shadow: inset 0px 0px 300px 200px #ea6b25;
    box-shadow: inset 0px 0px 300px 200px rgba(234, 107, 37, 0.5);
    background-size: cover;
    background-position: center; }
  #gotix #join-now.donation a {
    display: none; }
  #gotix #talent-story h4 {
    color: #093c5b;
    margin: 10px 0 0 3em; }
  #gotix #talent-story h4:before {
    top: 20px; }
  #gotix .buy-ticket .img-slides {
    background: url(https://d3naj63yelf4gk.cloudfront.net/dist/img/page/gotix/tickets/bg-cgv-phone.svg); }
    #gotix .buy-ticket .img-slides .img-item {
      height: 550px; }
      #gotix .buy-ticket .img-slides .img-item img {
        top: 50%;
        position: relative;
        -webkit-transform: translateY(-50%);
        -moz-transform: translateY(-50%);
        -o-transform: translateY(-50%);
        transform: translateY(-50%); }
  #gotix .buy-ticket .detail ul {
    padding: 0;
    margin: 0;
    width: 80%; }
    #gotix .buy-ticket .detail ul li.detail--text {
      font-family: Open Sans,Helvetica,sans-serif;
      font-size: 15px;
      line-height: 1.75;
      letter-spacing: 1.5px;
      color: #485460;
      text-align: left;
      cursor: pointer; }
    #gotix .buy-ticket .detail ul li.active-detail,
    #gotix .buy-ticket .detail ul li.detail--text:hover {
      color: #EA6B25; }
  #gotix .buy-ticket .custom-nav .slick-arrow {
    stroke: #ea6b25;
    cursor: pointer; }
  #gotix .mv-evt--container {
    margin: 48px 0; }
    #gotix .mv-evt--container .button-group {
      width: 350px;
      background: #EA6B25;
      border-radius: 5px;
      margin: 0 auto;
      position: relative; }
      #gotix .mv-evt--container .button-group ul {
        list-style: none;
        padding: 20px;
        margin: 0; }
        #gotix .mv-evt--container .button-group ul li:first-child {
          border-left: 0; }
        #gotix .mv-evt--container .button-group ul li {
          display: inline-block;
          border-left: 1px solid #353738;
          width: 153px; }
          #gotix .mv-evt--container .button-group ul li a {
            display: block;
            color: #fff;
            text-align: center;
            font-family: Lato,Helvetica,sans-serif;
            font-size: 14px;
            line-height: 1.33;
            letter-spacing: 1.5px;
            font-weight: 300;
            padding: 10px; }
  #gotix #what-new.section-our-values .img--wrapper {
    height: 500px; }
    #gotix #what-new.section-our-values .img--wrapper img {
      padding: 0 20px; }
  #gotix #register-event.section-our-values .list p {
    padding: 20px;
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
    font-weight: bold;
    line-height: 1.56;
    letter-spacing: 2.8px;
    text-align: center;
    color: #4a4a4a;
    text-transform: uppercase; }
  #gotix #register-event.section-our-values .list .img--wrapper img {
    padding: 30px; }
  #gotix #register-event.section-our-values .list-container {
    margin-top: 50px; }
  #gotix #register-event.section-our-values .img--wrapper {
    height: 300px; }
  #gotix #register-event.section-our-values .line.gradient {
    margin: 0; }
  #gotix .section-our-values {
    padding: 30px 0; }
    #gotix .section-our-values .img--wrapper {
      height: 300px; }
    #gotix .section-our-values img {
      max-width: 250px;
      padding: 20px;
      display: block;
      top: 50%;
      position: relative;
      -webkit-transform: translateY(-50%);
      -moz-transform: translateY(-50%);
      transform: translateY(-50%); }
    #gotix .section-our-values .title {
      line-height: 0.78;
      letter-spacing: 1.9px;
      text-align: center;
      color: #093c5b; }
      #gotix .section-our-values .title + p {
        margin: 30px 0; }
    #gotix .section-our-values .line.gradient {
      background: #EA6B25; }
  #gotix .section-how-to-use.how_donation .slider .item-text {
    padding-top: 30px; }
  #gotix .section-how-to-use {
    padding: 50px;
    height: 620px;
    background-size: cover !important; }
    #gotix .section-how-to-use .slider .item-text[data-slide="1"],
    #gotix .section-how-to-use .slider .item-text[data-slide="2"],
    #gotix .section-how-to-use .slider .item-text[data-slide="3"] {
      position: relative;
      top: -400px; }
    #gotix .section-how-to-use .slider .item-text[data-slide="4"],
    #gotix .section-how-to-use .slider .item-text[data-slide="5"] {
      position: relative;
      top: -50px; }
    #gotix .section-how-to-use .slider-image .item[data-slide="3"] img {
      position: relative;
      top: -30px; }
    #gotix .section-how-to-use .slider-image .item[data-slide="4"] img {
      position: relative;
      top: -45px; }
    #gotix .section-how-to-use .slider-image .item[data-slide="5"] img {
      position: relative;
      top: -55px; }
    #gotix .section-how-to-use h5 {
      color: #EA6B25; }
    #gotix .section-how-to-use .line.gradient {
      background: #EA6B25; }
    #gotix .section-how-to-use .title {
      position: relative;
      left: -410px; }
    #gotix .section-how-to-use .slick-arrow polygon {
      fill: #EA6B25; }
    #gotix .section-how-to-use .arrow-up {
      stroke: #EA6B25; }
    #gotix .section-how-to-use .arrow-up-circle {
      stroke-width: 3;
      stroke: #EA6B25; }
    #gotix .section-how-to-use .arrow-down {
      stroke: #EA6B25; }
    #gotix .section-how-to-use .arrow-down-circle {
      stroke: #EA6B25;
      stroke-width: 3; }
    #gotix .section-how-to-use .slider .slick-dots li.slick-active button:before {
      color: #EA6B25; }
    #gotix .section-how-to-use .slider-image .slick-dots li.slick-active button:before {
      color: #EA6B25; }
    #gotix .section-how-to-use .text-mobile {
      display: none;
      text-align: center; }
    #gotix .section-how-to-use .text-mobile .line.gradient {
      width: 50px;
      height: 3px;
      margin-top: 0px;
      background: #EA6B25;
      margin-left: auto;
      margin-right: auto; }
    #gotix .section-how-to-use .text h5 {
      margin-top: 100px;
      color: #EA6B25;
      font-weight: 900; }
    #gotix .section-how-to-use .text .line.gradient {
      width: 50px;
      height: 3px;
      margin-top: 0px;
      background: #EA6B25; }
    #gotix .section-how-to-use .slider .item .wrapper {
      width: 100%; }
    #gotix .section-how-to-use .slider-image .slick-dots li {
      display: block; }
    #gotix .section-how-to-use .slider-image .slick-dots li.slick-active button:before {
      color: #EA6B25; }
    #gotix .section-how-to-use .slider-image .slick-dots li button:before {
      font-size: 13px; }
    #gotix .section-how-to-use .slider .slider-text.title {
      position: relative;
      float: right;
      left: -265px;
      top: -20px;
      font-size: 36px;
      margin-top: -320px;
      font-weight: 300; }
    #gotix .section-how-to-use .slider .slider-text.desc {
      margin-left: 380px;
      position: relative;
      top: -280px;
      left: 45px;
      margin-bottom: -33px;
      line-height: 1.56;
      font-weight: 300;
      letter-spacing: 1.7px; }
    #gotix .section-how-to-use ul li {
      margin-top: 10px; }
    #gotix .section-how-to-use ul li img {
      cursor: pointer; }
  #gotix .homepage .images-only .img-wrapper {
    padding: 1.5px; }
    #gotix .homepage .images-only .img-wrapper .overlay {
      z-index: 99;
      background: #1e5799;
      /* Old browsers */
      background: -moz-linear-gradient(top, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0.4) 100%);
      /* FF3.6-15 */
      background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0.45) 100%);
      /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(to bottom, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0.45) 100%);
      /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */ }
    #gotix .homepage .images-only .img-wrapper a {
      display: block; }
    #gotix .homepage .images-only .img-wrapper a:hover {
      opacity: 0.6; }
    #gotix .homepage .images-only .img-wrapper img.featured {
      z-index: -1;
      margin-bottom: -50px;
      position: relative; }
    #gotix .homepage .images-only .img-wrapper img.logo-patch {
      display: inline;
      position: relative;
      top: -20px;
      left: 30px; }
    #gotix .homepage .images-only .img-wrapper img.logo-patch.logo-sos {
      max-width: 150px; }
    #gotix .homepage .images-only .img-wrapper img.logo-patch.logo-yayasan-tunas {
      max-width: 300px; }
  #gotix .homepage .berbagi-tanpa-batas {
    background: #f8f8f8;
    width: 100%;
    padding: 25px;
    text-align: center; }
    #gotix .homepage .berbagi-tanpa-batas img {
      display: inline-block;
      margin-right: auto;
      margin-left: auto; }
    #gotix .homepage .berbagi-tanpa-batas img.motto {
      max-height: 144px;
      position: relative;
      right: 20px; }
    #gotix .homepage .berbagi-tanpa-batas img.brand {
      max-height: 144px;
      position: relative;
      top: -15px;
      left: 130px; }

@media screen and (min-width: 768px) {
  #gotix #join-now .right {
    padding: 4.5em 3em; } }

@media screen and (max-width: 768px) {
  #gotix header#main--hero.donation .hero--desc {
    font-size: 12px; }
  #gotix #register-event .list .img--wrapper {
    height: auto; }
    #gotix #register-event .list .img--wrapper img {
      transform: none;
      top: auto; }
  #gotix .list-container {
    margin-top: 0px !important; }
  #gotix .homepage .images-only .img-wrapper {
    padding: 1.5px; }
  #gotix .homepage .berbagi-tanpa-batas {
    background: #f8f8f8;
    width: 100%;
    padding: 25px;
    text-align: center; }
    #gotix .homepage .berbagi-tanpa-batas img {
      display: block;
      margin-right: auto;
      margin-left: auto; }
    #gotix .homepage .berbagi-tanpa-batas img.motto {
      max-height: 60px;
      right: 0px; }
    #gotix .homepage .berbagi-tanpa-batas img.brand {
      max-height: 144px;
      position: relative;
      top: -15px;
      left: 0px; } }

@media screen and (max-width: 480px) {
  #gotix #join-now .left {
    height: 300px; }
  #gotix .section-how-to-use {
    min-height: 815px;
    background-size: cover !important; }
    #gotix .section-how-to-use .overlay {
      position: relative;
      width: 100%;
      height: 100%; }
    #gotix .section-how-to-use .text-mobile {
      text-align: center;
      display: block;
      margin-right: auto;
      margin-left: auto; }
    #gotix .section-how-to-use .slider .item-text {
      margin-top: 0px; }
    #gotix .section-how-to-use .slider .item .wrapper {
      width: 100%; }
    #gotix .section-how-to-use .slider-image ul {
      display: none !important; }
    #gotix .section-how-to-use .slider-image .slick-dots {
      position: relative; }
    #gotix .section-how-to-use .slider-image .slick-dots li {
      display: block; }
    #gotix .section-how-to-use .slider-image .slick-dots li.slick-active button:before {
      color: #EA6B25; }
    #gotix .section-how-to-use .slider-image .slick-dots li button:before {
      font-size: 13px; }
    #gotix .section-how-to-use .slider .slider-text.title {
      position: relative;
      float: right;
      left: -265px;
      top: -20px;
      font-size: 36px;
      margin-top: -320px;
      font-weight: 300; }
    #gotix .section-how-to-use .slider .slider-text.desc {
      margin-left: 380px;
      position: relative;
      top: -280px;
      left: 45px;
      margin-bottom: -33px;
      line-height: 1.56;
      font-weight: 300;
      letter-spacing: 1.7px; }
    #gotix .section-how-to-use ul li img {
      cursor: pointer; }
  #gotix .homepage .images-only .img-wrapper {
    padding: 1.5px; }
  #gotix .homepage .berbagi-tanpa-batas {
    background: #f8f8f8;
    width: 100%;
    padding: 25px;
    text-align: center; }
    #gotix .homepage .berbagi-tanpa-batas img {
      display: block;
      margin-right: auto;
      margin-left: auto; }
    #gotix .homepage .berbagi-tanpa-batas img.motto {
      max-height: 60px;
      right: 0px; }
    #gotix .homepage .berbagi-tanpa-batas img.brand {
      max-height: 144px;
      position: relative;
      top: -15px;
      left: 0px; } }

@media screen and (max-width: 400px) {
  .section-how-to-use {
    min-height: 835px; } }

@media screen and (max-width: 320px) {
  .section-how-to-use {
    min-height: 835px; } }

@media screen and (max-width: 768px) {
  #gotix #join-now.donation a {
    display: block; } }

@media screen and (max-width: 35.5em) {
  #gotix .buy-ticket .img-item {
    height: 350px !important; }
  #gotix .buy-ticket .detail ul {
    width: 100%;
    padding: 30px 30px 75px; }
    #gotix .buy-ticket .detail ul li.detail--text {
      font-size: 13px; }
  #gotix #talent-story h4 {
    margin: 0 !important; }
  #gotix .homepage .images-only .img-wrapper {
    padding: 1.5px; }
    #gotix .homepage .images-only .img-wrapper img.featured {
      margin-bottom: -30px;
      position: relative; }
    #gotix .homepage .images-only .img-wrapper img.logo-patch {
      display: inline;
      position: relative;
      top: -5px;
      left: 15px; }
    #gotix .homepage .images-only .img-wrapper img.logo-patch.logo-rumah-zakat {
      max-width: 60px; }
    #gotix .homepage .images-only .img-wrapper img.logo-patch.logo-sos {
      max-width: 80px; }
    #gotix .homepage .images-only .img-wrapper img.logo-patch.logo-yayasan-tunas {
      max-width: 100px; }
    #gotix .homepage .images-only .img-wrapper img.logo-patch.logo-wecare-id {
      max-width: 60px; }
  #gotix .homepage .berbagi-tanpa-batas {
    background: #f8f8f8;
    width: 100%;
    margin-top: 30px;
    padding: 25px;
    text-align: center; }
    #gotix .homepage .berbagi-tanpa-batas img {
      display: block;
      margin-right: auto;
      margin-left: auto; }
    #gotix .homepage .berbagi-tanpa-batas img.motto {
      max-height: 60px;
      right: 0px; }
    #gotix .homepage .berbagi-tanpa-batas img.brand {
      max-height: 144px;
      position: relative;
      top: -15px;
      left: 0px; } }

#gomart .new-section-how-to-use h3.title {
  color: #3c95d1; }
  #gomart .new-section-how-to-use h3.title:after {
    background: #3c95d1; }

#gomart .new-section-how-to-use .content {
  background-color: #3c95d1; }

@media screen and (max-width: 767px) {
  #gomart .new-section-how-to-use {
    margin-top: 50px !important; } }

#gomart .gradient {
  background: #3C95D1; }

#gomart header button {
  background: #3C95D1; }

#gomart .section-our-values h4 {
  color: #3C95D1; }

#gomart .section-our-values img {
  max-height: 125px; }

#gomart .why-container {
  margin-top: 4em; }

#gomart section.about h4 {
  color: #3c95d1; }

#gomart .merchant-list h5 {
  color: #3C95D1; }

#gomart .merchant-list hr {
  background: #3C95D1; }

#gomart .merchant-list .blog-card-img {
  background-color: #fff;
  padding: 1em 0; }
  #gomart .merchant-list .blog-card-img img {
    margin: 0 auto;
    max-width: 80%;
    min-height: 3em; }

#gomart .container:before {
  background-color: #3C95D1; }

#gomart .section-how-to-use h5 {
  color: #3C95D1; }

#gomart .section-how-to-use .line.gradient {
  background: #3C95D1; }

#gomart .section-how-to-use .arrow-up {
  stroke: #3C95D1; }

#gomart .section-how-to-use .arrow-up-circle {
  stroke-width: 3;
  stroke: #3C95D1; }

#gomart .section-how-to-use .arrow-down {
  stroke: #3C95D1; }

#gomart .section-how-to-use .arrow-down-circle {
  stroke: #3C95D1;
  stroke-width: 3; }

#gomart .section-how-to-use .slider .slick-dots li.slick-active button:before {
  color: #3C95D1; }

#gomart .section-how-to-use .slider-image .slick-dots li.slick-active button:before {
  color: #3C95D1; }

#gomart .section-how-to-use .text-mobile .line.gradient {
  background: #3C95D1; }

#gomart .section-how-to-use .text h5 {
  color: #3C95D1; }

#gomart .section-how-to-use .text .line.gradient {
  background: #3C95D1; }

#gomart .section-how-to-use .slider-image .slick-dots li.slick-active button:before {
  color: #3C95D1; }

#gomart .new-section-how-to-use h3.title {
  color: #3c95d1; }
  #gomart .new-section-how-to-use h3.title:after {
    background: #3c95d1; }

#gomart .new-section-how-to-use .content {
  background-color: #3c95d1; }

#gomart .articles--feed .bg {
  background: #3C95D1; }

#gomart .articles--feed .read--more {
  background: #3C95D1; }

#gomart .articles--feed .line--title {
  background: #fff; }

#gomart .articles--feed .mobile--nav svg ellipse {
  stroke: white !important; }

#gomart .articles--feed .mobile--nav svg polygon {
  fill: white !important; }

#gomart .gallery--wrapper .discover {
  background: #3C95D1; }

#gomart .gallery--wrapper h2 {
  color: #3C95D1; }

#gomart .gallery--wrapper .section-title:before {
  content: url(https://d3naj63yelf4gk.cloudfront.net/dist/img/page/gomart/hr.svg); }

@media screen and (max-width: 35.5em) {
  #gomart .section-how-to-use .arrow--wrapper {
    top: 31%; }
  #gomart .section-how-to-use .phone-wrapper {
    top: 40%; } }

#gopay {
  /* how to use new cards */
  /* define the cards section */
  /* ending */ }
  #gopay .title-underscore {
    color: #5CA5DA; }
    #gopay .title-underscore:after {
      background-color: #5CA5DA; }
  #gopay section.new-about .sub-title {
    color: #5CA5DA; }
    #gopay section.new-about .sub-title:after {
      background-color: #5CA5DA; }
  #gopay section.new-our-values h5 {
    color: #5CA5DA; }
    #gopay section.new-our-values h5:after {
      background-color: #5CA5DA; }
  @media only screen and (max-width: 767px) {
    #gopay section.new-our-values .new-float-arrow svg {
      transition: all 0.5s; }
      #gopay section.new-our-values .new-float-arrow svg ellipse {
        stroke: #5CA5DA !important; }
      #gopay section.new-our-values .new-float-arrow svg polygon {
        fill: #5CA5DA !important; }
    #gopay section.new-our-values .new-float-arrow.slick-disabled svg ellipse {
      stroke: #ececec !important; }
    #gopay section.new-our-values .new-float-arrow.slick-disabled svg polygon {
      fill: #ececec !important; } }
  @media only screen and (max-width: 767px) {
    #gopay section.new-our-values .normal-dots > li {
      border: 1px solid #5CA5DA; }
      #gopay section.new-our-values .normal-dots > li.slick-active {
        background-color: #5CA5DA; } }
  #gopay section.new-option button {
    background-color: #5CA5DA; }
  #gopay section.new-more-features .container-image-slide {
    background-color: #5CA5DA; }
  #gopay section.new-more-features .container-vertical {
    background-color: #5CA5DA; }
  @media only screen and (min-width: 769px) {
    #gopay section.gopay-services div.new-services h5.title-underscore {
      padding: 0px; } }
  #gopay section.gopay-services div.new-services .container-vertical {
    color: #5CA5DA; }
    #gopay section.gopay-services div.new-services .container-vertical.disabled {
      color: #dbdbdb; }
  #gopay section.gopay-services div.container-slide-text {
    background-color: #5CA5DA; }
  #gopay section.new-slider-services .title {
    color: #5CA5DA; }
    #gopay section.new-slider-services .title:before {
      background-color: #5CA5DA; }
  #gopay section.new-slider-services .arrow-slider svg ellipse {
    stroke: #5CA5DA; }
  #gopay section.new-slider-services .arrow-slider svg polygon {
    fill: #5CA5DA; }
  #gopay section.new-slider-services .container-slider-services:before {
    background-color: #5CA5DA; }
  #gopay section.new-slider-services .container-slider-services .content-slider h5 {
    color: #5CA5DA; }
  @media only screen and (max-width: 767px) {
    #gopay section.new-slider-services .new-float-arrow svg {
      transition: all 0.5s; }
      #gopay section.new-slider-services .new-float-arrow svg ellipse {
        stroke: #5CA5DA !important; }
      #gopay section.new-slider-services .new-float-arrow svg polygon {
        fill: #5CA5DA !important; }
    #gopay section.new-slider-services .new-float-arrow.slick-disabled svg ellipse {
      stroke: #ececec !important; }
    #gopay section.new-slider-services .new-float-arrow.slick-disabled svg polygon {
      fill: #ececec !important; } }
  #gopay section.new-join-us button.float-button {
    background-color: #5CA5DA; }
  #gopay section.new-more-information button.left {
    background-color: #5CA5DA; }
  #gopay div.new-floating-help button.trigger {
    background-color: #093c5b;
    color: #ffffff; }
    #gopay div.new-floating-help button.trigger .close {
      color: #fff; }
  #gopay div.new-floating-help .header {
    background-color: #093c5b; }
  #gopay div.new-floating-help .container-content {
    border-color: #093c5b; }
  #gopay div.new-floating-help button.link {
    background-color: #5CA5DA; }
  #gopay div.new-floating-help.active button.trigger {
    transition: all 0.5s;
    background-color: #ffcd2e; }
  #gopay header#main--hero {
    margin-bottom: 0px; }
    @media only screen and (max-width: 767px) {
      #gopay header#main--hero {
        margin-bottom: 30px; } }
  #gopay button.download {
    color: #fff;
    border-radius: 5px;
    display: block !important; }
    @media only screen and (max-width: 768px) {
      #gopay button.download {
        width: 215px !important; } }
  #gopay footer {
    margin-top: 100px; }
  @media screen and (max-width: 767px) {
    #gopay.gobills #bg-as-seen-on {
      padding-top: 0px; } }
  #gopay.gobills .brushing {
    background-image: url("../../dist/img/brush/GO-BILLS.png"); }
    #gopay.gobills .brushing:after {
      background: #ffffff url("https://bilba.go-jek.com/images/product-brand/gobills.svg") no-repeat center; }
  #gopay.gobills .about .table-display {
    height: 100%;
    display: table; }
    #gopay.gobills .about .table-display .middle-table {
      display: table-cell;
      vertical-align: middle; }
  @media screen and (max-width: 767px) {
    #gopay.gobills .about .description {
      position: static; } }
  #gopay.gobills .about .container h4.title--content {
    color: #4285a7; }
    @media screen and (max-width: 767px) {
      #gopay.gobills .about .container h4.title--content {
        position: absolute;
        top: -20px;
        left: 0;
        font-size: 14px; }
        #gopay.gobills .about .container h4.title--content:after {
          top: 3px;
          position: absolute;
          width: 10px;
          left: 0;
          background-color: #4285a7;
          height: 2px;
          content: ""; } }
  @media screen and (min-width: 769px) {
    #gopay.gobills .about .container h2 {
      font-size: 28px; } }
  @media screen and (max-width: 767px) {
    #gopay.gobills .about .container .img-container {
      background: transparent; } }
  #gopay.gobills .about .container .img-container-2 img {
    width: 100%; }
    @media screen and (max-width: 767px) {
      #gopay.gobills .about .container .img-container-2 img {
        width: 90%;
        margin: auto;
        display: block; } }
  @media screen and (max-width: 767px) {
    #gopay.gobills .about .container .img-container-2 {
      margin-top: 30px; } }
  @media screen and (min-width: 768px) {
    #gopay.gobills .about {
      padding-top: 0px; } }
  #gopay.gobills #type-gobills {
    width: 100%; }
    #gopay.gobills #type-gobills .arrow-left {
      position: absolute;
      left: 0;
      bottom: -40px;
      width: 50px; }
    #gopay.gobills #type-gobills .arrow-right {
      position: absolute;
      right: 0;
      bottom: -40px;
      width: 50px; }
    #gopay.gobills #type-gobills .slick-arrow.slick-disabled {
      filter: grayscale(100) brightness(1.5); }
    @media screen and (min-width: 768px) {
      #gopay.gobills #type-gobills {
        margin-top: 40px; } }
  #gopay.gobills .as-seen-on .title p {
    color: #4285a7;
    font-size: 17px; }
    @media screen and (max-width: 767px) {
      #gopay.gobills .as-seen-on .title p {
        margin-left: 10px;
        font-weight: 700;
        font-size: 14px; } }
    @media screen and (min-width: 768px) {
      #gopay.gobills .as-seen-on .title p {
        position: relative;
        bottom: 6px; } }
  #gopay.gobills .as-seen-on .title hr {
    background: #4285a7; }
  #gopay.gobills .as-seen-on #left {
    right: 0;
    left: auto;
    top: 0;
    bottom: auto; }
    @media screen and (max-width: 767px) {
      #gopay.gobills .as-seen-on #left {
        height: 100px; } }
  #gopay.gobills .as-seen-on #right {
    top: auto;
    bottom: 0;
    right: auto;
    left: 0;
    background: #4285a7; }
    @media screen and (max-width: 767px) {
      #gopay.gobills .as-seen-on #right {
        height: 100px; } }
  #gopay.gobills .as-seen-on #content img {
    max-height: 200px; }
    @media screen and (min-width: 768px) {
      #gopay.gobills .as-seen-on #content img.bpjs {
        max-width: 470px;
        position: relative;
        top: 10px; } }
    @media screen and (max-width: 767px) {
      #gopay.gobills .as-seen-on #content img.bpjs {
        max-width: none;
        width: 100%; } }
  #gopay.gobills #content-only {
    margin-top: 0px;
    padding-top: 60px; }
    #gopay.gobills #content-only .container {
      box-shadow: #4a4a4a 0px -3em 0px 1.5em; }
      #gopay.gobills #content-only .container .info {
        padding-top: 50px; }
        @media screen and (min-width: 768px) {
          #gopay.gobills #content-only .container .info p {
            margin: auto;
            max-width: 580px; } }
        #gopay.gobills #content-only .container .info h4 {
          color: #4285a7; }
          @media screen and (max-width: 767px) {
            #gopay.gobills #content-only .container .info h4 {
              font-size: 14px; } }
        #gopay.gobills #content-only .container .info .line {
          background: #4285a7; }
        #gopay.gobills #content-only .container .info a.contact {
          background: #4285a7; }
          @media screen and (max-width: 767px) {
            #gopay.gobills #content-only .container .info a.contact {
              width: 80%;
              max-width: 300px; } }
        #gopay.gobills #content-only .container .info a.faq {
          background: #4a4a4a; }
          @media screen and (max-width: 767px) {
            #gopay.gobills #content-only .container .info a.faq {
              width: 80%;
              max-width: 300px; } }
      @media screen and (max-width: 767px) {
        #gopay.gobills #content-only .container .link {
          margin-bottom: -50px; } }
    @media screen and (max-width: 767px) {
      #gopay.gobills #content-only {
        margin-bottom: 100px; } }
  #gopay.gobills .how-to [data-display=desktop],
  #gopay.gobills .how-to .desktop-only {
    display: block; }
  #gopay.gobills .how-to .mobile-how-to {
    display: none; }
  #gopay.gobills .how-to .visibility {
    position: absolute;
    opacity: 0.0;
    height: 0;
    top: 0;
    visibility: hidden !important; }
  #gopay.gobills .how-to header .title .content {
    box-shadow: -1em 1em #4285a7; }
    #gopay.gobills .how-to header .title .content .text {
      padding: .2em 1.8em; }
      #gopay.gobills .how-to header .title .content .text h2 {
        font-family: 'Neo', 'Open Sans', Helvetica, sans-serif !important;
        font-size: 50px !important;
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.12;
        letter-spacing: 0.5px;
        background: white; }
  #gopay.gobills .how-to header .bg-img {
    background-position-x: 100% !important; }
  #gopay.gobills .how-to .new-section-how-to-use h3.title {
    color: #4285a7; }
    #gopay.gobills .how-to .new-section-how-to-use h3.title:after {
      background-color: #4285a7; }
  #gopay.gobills .how-to .new-section-how-to-use .content {
    background: #4285a7; }
  #gopay.gobills .how-to #content-nav .text h4 {
    font-family: "Open Sans", Helvetica, sans-serif;
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 1px;
    text-align: center;
    text-transform: uppercase;
    color: #4285a7; }
  #gopay.gobills .how-to #content-nav .text .line.gradient {
    background: #4285a7;
    width: 50px;
    height: 3px;
    margin: 0 auto;
    margin-bottom: 50px; }
  #gopay.gobills .how-to #content-nav .faq-nav {
    width: unset !important; }
    #gopay.gobills .how-to #content-nav .faq-nav .cat-list li a:hover {
      color: #4285a7 !important; }
    #gopay.gobills .how-to #content-nav .faq-nav .cat-list li.active {
      color: #4285a7 !important; }
  #gopay.gobills .how-to .navigation-wrapper {
    margin-bottom: 80px; }
  #gopay.gobills .how-to .how-to-slider.pln .new-section-how-to-use {
    margin-top: 200px; }
  #gopay.gobills .how-to #faq-list-menu {
    margin: 2.5em 0 3.75em 0 !important; }
    #gopay.gobills .how-to #faq-list-menu .desktop {
      margin-bottom: 80px; }
      #gopay.gobills .how-to #faq-list-menu .desktop h4 {
        font-family: "Open Sans", Helvetica, sans-serif;
        font-size: 18px;
        font-weight: bold;
        letter-spacing: 1px;
        text-align: center;
        text-transform: uppercase;
        color: #4285a7; }
      #gopay.gobills .how-to #faq-list-menu .desktop .line.gradient {
        background: #4285a7;
        width: 50px;
        height: 3px;
        margin: 0 auto;
        margin-bottom: 50px; }
    #gopay.gobills .how-to #faq-list-menu .box {
      box-shadow: 0 3px 15px 0 rgba(0, 0, 0, 0.13), 0 25px 0 15px #4285a7 !important; }
      #gopay.gobills .how-to #faq-list-menu .box .img-icon-wrapper {
        width: 40px;
        height: 40px;
        position: relative;
        background: #fff;
        line-height: 50px;
        margin: 0 auto;
        border-radius: 50%;
        margin-bottom: 20px; }
        #gopay.gobills .how-to #faq-list-menu .box .img-icon-wrapper .img-pln {
          max-width: 16px; }
        #gopay.gobills .how-to #faq-list-menu .box .img-icon-wrapper .img-bpjs {
          max-width: 21px; }
    #gopay.gobills .how-to #faq-list-menu .active {
      background-image: url(https://d3naj63yelf4gk.cloudfront.net/dist/img/faq/list-geo.png), linear-gradient(#13587b, #13587b);
      background-position: bottom,top;
      background-repeat: no-repeat,no-repeat;
      background-size: 100%,cover;
      box-shadow: 0 25px 0 15px #4285a7;
      cursor: default;
      pointer-events: none;
      color: white; }
      #gopay.gobills .how-to #faq-list-menu .active h4 {
        color: white !important; }
  #gopay .gradient {
    background: #5CA5DA; }
  #gopay .hero button {
    background: #5CA5DA; }
  #gopay .merchant-list h5 {
    color: #5CA5DA; }
  #gopay .merchant-list hr {
    background: #5CA5DA; }
  #gopay section.verification {
    margin-top: 30px;
    margin-bottom: 30px; }
    #gopay section.verification #sub-desc--container {
      background: #f8f8f8;
      z-index: 5;
      position: relative; }
      #gopay section.verification #sub-desc--container #line--desc {
        height: 4px;
        width: calc(100% - 55px);
        position: relative;
        top: 50%;
        left: 55px;
        -webkit-transform: translateY(-50%);
        -moz-transform: translateY(-50%);
        -o-transform: translateY(-50%);
        transform: translateY(-50%);
        background-color: #333; }
      #gopay section.verification #sub-desc--container .sub--desc {
        text-align: left;
        margin-left: 50px; }
    #gopay section.verification .container {
      margin: 0;
      padding: 0;
      width: auto !important;
      overflow: hidden; }
      #gopay section.verification .container table {
        border-collapse: collapse;
        width: 100%; }
      #gopay section.verification .container td,
      #gopay section.verification .container th {
        border: 0px;
        text-align: left;
        font-size: 14px;
        padding: 20px; }
      #gopay section.verification .container th {
        font-family: Open Sans, sans-serif;
        font-weight: 700;
        text-transform: uppercase;
        line-height: 1.17;
        letter-spacing: 1.3px;
        color: #5ca5da;
        background-color: #fff;
        border-left: 1px solid #d7d7d7;
        padding: 10px 5px; }
      #gopay section.verification .container th:nth-child(1) {
        border-left: 0px; }
      #gopay section.verification .container tr:nth-child(even) {
        background-color: #afdcff; }
      #gopay section.verification .container tr:nth-child(odd) {
        background-color: #fafafa; }
      #gopay section.verification .container td {
        color: #485460; }
      #gopay section.verification .container td:nth-child(odd) {
        background-color: rgba(250, 250, 250, 0.5); }
      #gopay section.verification .container th:nth-child(2),
      #gopay section.verification .container th:nth-child(3),
      #gopay section.verification .container td:nth-child(2),
      #gopay section.verification .container td:nth-child(3) {
        text-align: center; }
      #gopay section.verification .container .first-block {
        background: white; }
        #gopay section.verification .container .first-block .text-wrapper {
          padding: 45px 35px 0px 55px; }
          #gopay section.verification .container .first-block .text-wrapper h1 {
            font-family: "Lato", Helvetica, sans-serif;
            font-size: 18px;
            font-weight: bold;
            letter-spacing: 1.5px;
            text-transform: uppercase;
            color: #00b1f1; }
          #gopay section.verification .container .first-block .text-wrapper p {
            font-family: "Open Sans", Helvetica, sans-serif;
            font-size: 14px;
            font-weight: 300;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.64;
            letter-spacing: 1px;
            color: #485460;
            margin: 40px 70px 17px 0px; }
      #gopay section.verification .container .second-block {
        width: calc(50% + 35px);
        height: 390px;
        background: #5CA5DA;
        margin-left: -35px;
        position: relative;
        right: 0;
        top: 70px; }
        #gopay section.verification .container .second-block .slick-track {
          margin-left: -165px;
          margin-top: 15px; }
        #gopay section.verification .container .second-block .verified-prev {
          display: none !important; }
        #gopay section.verification .container .second-block .verified-next {
          display: none !important; }
        #gopay section.verification .container .second-block .verified-container {
          height: 100%; }
          #gopay section.verification .container .second-block .verified-container .slick-list {
            overflow: visible; }
          #gopay section.verification .container .second-block .verified-container .verified-list {
            padding: 0 30px;
            text-align: center;
            outline: none; }
            #gopay section.verification .container .second-block .verified-container .verified-list h1 {
              font-family: "Open Sans", Helvetica, sans-serif;
              font-size: 24px;
              font-weight: 300;
              letter-spacing: 3px;
              color: #ffffff; }
            #gopay section.verification .container .second-block .verified-container .verified-list h5 {
              font-family: "Open Sans", Helvetica, sans-serif;
              font-size: 12px;
              font-weight: 300;
              letter-spacing: 1.5px;
              text-align: center;
              color: #ffffff; }
            #gopay section.verification .container .second-block .verified-container .verified-list img {
              width: 250px; }
  #gopay .mv-evt--container {
    margin: 48px 0;
    text-align: center; }
    #gopay .mv-evt--container .button-group {
      display: inline-block;
      background: #5CA5DA;
      border-radius: 5px;
      margin: 0 auto;
      position: relative; }
      #gopay .mv-evt--container .button-group ul {
        list-style: none;
        padding: 20px 0;
        margin: 0; }
        #gopay .mv-evt--container .button-group ul li:first-child {
          border-right: 1px solid #353738; }
        #gopay .mv-evt--container .button-group ul li {
          display: inline-block;
          width: 180px;
          text-align: center; }
          #gopay .mv-evt--container .button-group ul li a {
            display: block;
            color: #fff;
            text-align: center;
            font-family: Lato, Helvetica, sans-serif;
            font-size: 14px;
            line-height: 1.33;
            letter-spacing: 1.5px;
            font-weight: 300;
            padding: 10px; }
  #gopay .services .png-file {
    background-size: 20em !important; }
  #gopay .services .service-list .bg-services {
    background-size: 6em;
    background-repeat: no-repeat;
    opacity: 1; }
  #gopay .services .navigation-arrow {
    display: block; }
    @media screen and (max-width: 767px) {
      #gopay .services .navigation-arrow {
        margin-top: 50px; } }
  #gopay .services .container::before {
    background-color: #5CA5DA; }
  #gopay .services h4 {
    color: #5CA5DA;
    padding: 0; }
    @media screen and (min-width: 769px) {
      #gopay .services h4 {
        margin-bottom: 50px; } }
  @media screen and (min-width: 768px) {
    #gopay .how-it-works--wrapper {
      margin-top: 160px; } }
  #gopay .how-it-works--wrapper .container {
    padding: 0; }
  #gopay .how-it-works--wrapper h2 {
    color: #093c5b; }
  #gopay .how-it-works--wrapper h4 {
    color: #5CA5DA; }
  #gopay .how-it-works--wrapper figure.hand-ss {
    background: #fff; }
    #gopay .how-it-works--wrapper figure.hand-ss img {
      width: auto;
      right: 0;
      margin: 0 auto;
      position: absolute;
      left: 0; }
  #gopay .how-it-works--wrapper .section--title:before {
    background-color: #093c5b; }
  #gopay .how-it-works--wrapper .rect--primary {
    background: #093c5b; }
  #gopay .section-our-values {
    padding-bottom: 0; }
    #gopay .section-our-values h4 {
      color: #5CA5DA; }
    #gopay .section-our-values img {
      max-height: 150px;
      width: auto; }
    #gopay .section-our-values.go-bills h4.title {
      color: #4285a7; }
      @media screen and (max-width: 767px) {
        #gopay .section-our-values.go-bills h4.title:after {
          top: 30px;
          position: absolute;
          width: 15px;
          left: -20px;
          background-color: #4285a7;
          height: 2px;
          content: ""; } }
    #gopay .section-our-values.go-bills .list h4 {
      color: #4a4a4a; }
      @media screen and (min-width: 768px) {
        #gopay .section-our-values.go-bills .list h4 {
          margin-top: 50px;
          margin-bottom: 40px; } }
      @media screen and (max-width: 767px) {
        #gopay .section-our-values.go-bills .list h4 {
          padding: 0px;
          margin-bottom: 0px;
          margin-top: 50px; } }
    @media screen and (min-width: 768px) {
      #gopay .section-our-values.go-bills .list p {
        margin-top: 10px; } }
    @media screen and (max-width: 767px) {
      #gopay .section-our-values.go-bills .list p {
        max-width: 300px; } }
    #gopay .section-our-values.go-bills .line {
      background: #4285a7; }
      @media screen and (max-width: 767px) {
        #gopay .section-our-values.go-bills .line {
          display: none; } }
    @media screen and (max-width: 767px) {
      #gopay .section-our-values.go-bills h4.title {
        font-size: 14px;
        text-align: left; } }
    @media screen and (max-width: 767px) {
      #gopay .section-our-values.go-bills {
        padding: 20px;
        padding-bottom: 100px; }
        #gopay .section-our-values.go-bills .container-slider {
          width: 100%;
          padding: 0px;
          position: relative;
          display: inline-block; } }
  #gopay .about {
    padding-bottom: 0; }
    #gopay .about .container::before {
      display: none;
      background-color: #093c5b; }
    #gopay .about h4,
    #gopay .about a,
    #gopay .about a::after {
      color: #5CA5DA; }
  #gopay #join-now a {
    background: #5CA5DA; }
  #gopay #join-now h2 {
    font-size: 1.5em; }
  #gopay .faq #content {
    background: url(https://d3naj63yelf4gk.cloudfront.net/dist/img/page/gopay/rectangle-bg.svg); }
    #gopay .faq #content .gopay-faq--title {
      color: #000;
      padding: 30px 0; }
      #gopay .faq #content .gopay-faq--title span.light {
        font-weight: 300; }
  #gopay .faq #right {
    background: #5CA5DA; }
  #gopay .faq .button--wrapper {
    width: 200px;
    border-radius: 5px;
    background-color: #5CA5DA;
    text-align: center;
    position: relative;
    margin: 0 auto 30px; }
    #gopay .faq .button--wrapper a.gopay-faq--link {
      text-decoration: none;
      color: #fff;
      font-size: 16px;
      text-transform: uppercase;
      padding: 19px 0;
      display: block; }

@media screen and (max-width: 768px) {
  #gopay section.verification {
    margin-top: 30px;
    margin-bottom: 30px; }
    #gopay section.verification #sub-desc--container .sub--desc {
      margin-left: 0px;
      padding: 0 15px;
      text-align: center; }
    #gopay section.verification #sub-desc--container #line--desc {
      display: none; }
    #gopay section.verification .container .first-block {
      background: white; }
      #gopay section.verification .container .first-block table {
        letter-spacing: normal; }
        #gopay section.verification .container .first-block table td {
          padding: 10px; }
      #gopay section.verification .container .first-block .text-wrapper {
        padding: 15px; }
        #gopay section.verification .container .first-block .text-wrapper h1 {
          font-family: "Lato", Helvetica, sans-serif;
          font-size: 18px;
          font-weight: bold;
          letter-spacing: 1.5px;
          text-transform: uppercase;
          color: #00b1f1;
          margin-top: 20px; }
        #gopay section.verification .container .first-block .text-wrapper p {
          margin-top: 40px;
          margin-right: 0;
          margin-bottom: 0; }
    #gopay section.verification .container .second-block {
      width: 100%;
      height: 380px;
      top: 0;
      margin-left: 0;
      position: relative; }
      #gopay section.verification .container .second-block .verified-prev {
        display: block !important;
        z-index: 999;
        float: left;
        position: relative;
        right: 20px;
        left: 0;
        margin: 22px; }
      #gopay section.verification .container .second-block .verified-next {
        display: block !important;
        z-index: 999;
        float: right;
        position: relative;
        right: 0;
        margin: 22px; }
      #gopay section.verification .container .second-block .slick-disabled ellipse {
        stroke: #d2d2d2 !important; }
      #gopay section.verification .container .second-block .slick-disabled polygon {
        fill: #d2d2d2; }
      #gopay section.verification .container .second-block .verified-container {
        margin-left: 0;
        bottom: 0;
        position: relative; }
        #gopay section.verification .container .second-block .verified-container .slick-list {
          padding: 0 30px !important; }
        #gopay section.verification .container .second-block .verified-container .slick-track {
          margin-left: 0;
          margin-top: 0; }
        #gopay section.verification .container .second-block .verified-container .verified-list {
          padding: 0 30px;
          margin-top: -80px;
          text-align: center;
          outline: none; }
  #gopay #join-now img {
    width: 100%; }
  #gopay #join-now .right a {
    padding: 1.3em;
    bottom: -1.5em; }
  #gopay .services {
    padding-top: 0;
    padding-bottom: 6em; }
  #gopay .how-it-works--wrapper .container {
    padding-top: 0;
    padding-bottom: 0; }
  #gopay .how-it-works--wrapper h4 {
    line-height: 1.3em; }
  #gopay .how-it-works--wrapper .layer {
    padding-bottom: 0;
    padding-top: 0; }
    #gopay .how-it-works--wrapper .layer .odd--row .desc--pad {
      padding-right: 0; }
    #gopay .how-it-works--wrapper .layer .even--row .desc--pad {
      padding-right: 0;
      padding-left: 0; } }

@media screen and (max-width: 500px) {
  #gopay .button-group ul {
    padding: 10px 0 !important; }
    #gopay .button-group ul li {
      width: 150px !important; }
      #gopay .button-group ul li a {
        font-size: 12px !important; } }

@media only screen and (max-width: 35.5em) {
  #gopay.gobills [data-display=desktop],
  #gopay.gobills .desktop-only {
    display: none !important; }
  #gopay.gobills .mobile-how-to {
    display: block !important; }
  #gopay.gobills .new-section-how-to-use {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
    #gopay.gobills .new-section-how-to-use .title {
      display: none; }
    #gopay.gobills .new-section-how-to-use .phoneSlide-dots {
      display: none !important; }
  #gopay.gobills #content-nav {
    display: none; }
  #gopay.gobills #faq-list-menu {
    margin: 0 !important;
    padding: 0;
    margin-bottom: 50px !important; }
    #gopay.gobills #faq-list-menu .mobile .warper h3 {
      margin-top: -50px;
      margin-left: 60px; }
    #gopay.gobills #faq-list-menu .mobile .warper .img-icon-wrapper {
      width: 40px;
      height: 40px;
      position: relative;
      background: #fff;
      line-height: 50px;
      border-radius: 50%;
      margin-bottom: 20px; }
      #gopay.gobills #faq-list-menu .mobile .warper .img-icon-wrapper .img-pln {
        max-width: 16px;
        position: relative;
        left: 12px;
        top: 7.5px; }
      #gopay.gobills #faq-list-menu .mobile .warper .img-icon-wrapper .img-bpjs {
        max-width: 22px;
        position: relative;
        top: 8.5px;
        left: 9px; }
    #gopay.gobills #faq-list-menu .mobile .ui-accordion-content-active {
      margin-bottom: 100px !important; }
    #gopay.gobills #faq-list-menu .mobile #faq-list-content .title-content {
      margin-bottom: 100px;
      transform: scale(1.285);
      box-shadow: 0 3px 15px 0 rgba(0, 0, 0, 0.13), 0 25px 0 15px #4285a7; }
      #gopay.gobills #faq-list-menu .mobile #faq-list-content .title-content span {
        color: unset; }
    #gopay.gobills #faq-list-menu .mobile #faq-list-content .detail-item {
      transform: scale(1.25);
      margin-bottom: 35px; }
      #gopay.gobills #faq-list-menu .mobile #faq-list-content .detail-item ul li {
        padding: 0;
        border-bottom: none; }
      #gopay.gobills #faq-list-menu .mobile #faq-list-content .detail-item .title-item {
        border-left: 5px solid #4285a7; }
        #gopay.gobills #faq-list-menu .mobile #faq-list-content .detail-item .title-item.sub-item {
          background: white !important; }
        #gopay.gobills #faq-list-menu .mobile #faq-list-content .detail-item .title-item:last-child {
          margin-bottom: 250px; }
        #gopay.gobills #faq-list-menu .mobile #faq-list-content .detail-item .title-item span {
          color: unset; }
    #gopay.gobills #faq-list-menu .mobile #faq-list-content .ui-state-active {
      background: #13587b !important; }
      #gopay.gobills #faq-list-menu .mobile #faq-list-content .ui-state-active ul {
        margin-bottom: 50px !important; }
  #gopay.gobills .how-to header {
    background: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.4)), url("/static/dist/img/page/gopay/gobills/header.jpg") no-repeat !important;
    background-size: cover;
    background-position-x: 100% !important;
    margin-top: 60px;
    height: 320px; }
    #gopay.gobills .how-to header .title .content {
      box-shadow: none; }
      #gopay.gobills .how-to header .title .content .text h2 {
        text-align: center;
        font-size: 36px !important;
        line-height: 40px; }
        #gopay.gobills .how-to header .title .content .text h2 span {
          position: relative;
          top: 7px; } }

#gopulsa .gradient {
  background: #22AF6E; }

#gopulsa header .inline {
  font-weight: 700; }

#gopulsa header button {
  background: #22AF6E; }

#gopulsa .about h4,
#gopulsa .about a,
#gopulsa .about a::after {
  color: #22AF6E; }

#gopulsa .section-how-to-use h5 {
  color: #22AF6E; }

#gopulsa .section-how-to-use .line.gradient {
  background: #22AF6E; }

#gopulsa .section-how-to-use .arrow-up {
  stroke: #22AF6E; }

#gopulsa .section-how-to-use .arrow-up-circle {
  stroke-width: 3;
  stroke: #22AF6E; }

#gopulsa .section-how-to-use .arrow-down {
  stroke: #22AF6E; }

#gopulsa .section-how-to-use .arrow-down-circle {
  stroke: #22AF6E;
  stroke-width: 3; }

#gopulsa .section-how-to-use .slider .slick-dots li.slick-active button:before {
  color: #22AF6E; }

#gopulsa .section-how-to-use .slider-image .slick-dots li.slick-active button:before {
  color: #22AF6E; }

#gopulsa .new-section-how-to-use {
  margin-top: 180px;
  margin-bottom: 100px; }
  #gopulsa .new-section-how-to-use h3.title {
    color: #22AF6E; }
    #gopulsa .new-section-how-to-use h3.title:after {
      background: #22AF6E; }
  #gopulsa .new-section-how-to-use .content {
    background-color: #22AF6E; }
  @media screen and (max-width: 767px) {
    #gopulsa .new-section-how-to-use {
      margin-top: 50px !important; } }

#gopulsa .as-seen-on #content img {
  max-height: 150px; }

#gopulsa .as-seen-on #left {
  background: #22AF6E; }

#gopulsa .as-seen-on #right {
  background: #343b41; }

#gopulsa .section-how-to-use button:before {
  color: #22AF6E; }

#gopoint {
  /* RANDOMIZER */
  /* ENDING RANDOMIZER */
  /* go-lucky */ }

@keyframes showWinner {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }
  #gopoint button.download {
    background-color: #F79520;
    bottom: -25px !important;
    font-size: 12px !important; }

@keyframes odometer {
  from {
    transform: translateY(0%); }
  to {
    transform: translateY(calc(-100% + 100px)); } }

@keyframes odometerstop-0 {
  100% {
    transform: translateY(0px); } }

@keyframes odometerstop-1 {
  100% {
    transform: translateY(-100px); } }

@keyframes odometerstop-2 {
  100% {
    transform: translateY(-200px); } }

@keyframes odometerstop-3 {
  100% {
    transform: translateY(-300px); } }

@keyframes odometerstop-4 {
  100% {
    transform: translateY(-400px); } }

@keyframes odometerstop-5 {
  100% {
    transform: translateY(-500px); } }

@keyframes odometerstop-6 {
  100% {
    transform: translateY(-600px); } }

@keyframes odometerstop-7 {
  100% {
    transform: translateY(-700px); } }

@keyframes odometerstop-8 {
  100% {
    transform: translateY(-800px); } }

@keyframes odometerstop-9 {
  100% {
    transform: translateY(-900px); } }

@keyframes odometerstop-10 {
  100% {
    transform: translateY(-1000px); } }

@keyframes odometerstop-11 {
  100% {
    transform: translateY(-1100px); } }

@keyframes odometerstop-12 {
  100% {
    transform: translateY(-1200px); } }

@keyframes odometerstop-13 {
  100% {
    transform: translateY(-1300px); } }

@keyframes odometerstop-14 {
  100% {
    transform: translateY(-1400px); } }

@keyframes odometerstop-15 {
  100% {
    transform: translateY(-1500px); } }

@keyframes odometerstop-16 {
  100% {
    transform: translateY(-1600px); } }

@keyframes odometerstop-17 {
  100% {
    transform: translateY(-1700px); } }

@keyframes odometerstop-18 {
  100% {
    transform: translateY(-1800px); } }

@keyframes odometerstop-19 {
  100% {
    transform: translateY(-1900px); } }

@keyframes odometerstop-20 {
  100% {
    transform: translateY(-2000px); } }

@keyframes odometerstop-21 {
  100% {
    transform: translateY(-2100px); } }

@keyframes odometerstop-22 {
  100% {
    transform: translateY(-2200px); } }

@keyframes odometerstop-23 {
  100% {
    transform: translateY(-2300px); } }

@keyframes odometerstop-24 {
  100% {
    transform: translateY(-2400px); } }

@keyframes odometerstop-25 {
  100% {
    transform: translateY(-2500px); } }

@keyframes odometerstop-26 {
  100% {
    transform: translateY(-2600px); } }

@keyframes odometerstop-27 {
  100% {
    transform: translateY(-2700px); } }

@keyframes odometerstop-28 {
  100% {
    transform: translateY(-2800px); } }

@keyframes odometerstop-29 {
  100% {
    transform: translateY(-2900px); } }

@keyframes odometerstop-30 {
  100% {
    transform: translateY(-3000px); } }

@keyframes odometerstop-31 {
  100% {
    transform: translateY(-3100px); } }

@keyframes odometerstop-32 {
  100% {
    transform: translateY(-3200px); } }

@keyframes odometerstop-33 {
  100% {
    transform: translateY(-3300px); } }

@keyframes odometerstop-34 {
  100% {
    transform: translateY(-3400px); } }

@keyframes odometerstop-35 {
  100% {
    transform: translateY(-3500px); } }

@keyframes odometerstop-36 {
  100% {
    transform: translateY(-3600px); } }
  #gopoint .header-random-golucky {
    position: fixed;
    height: 60px;
    width: 100%;
    background-color: #fff;
    border-bottom: 1px solid #ececec;
    text-align: center;
    display: inline-block;
    vertical-align: middle;
    padding: 0 20px;
    z-index: 12; }
    #gopoint .header-random-golucky .logo {
      position: absolute;
      height: 50px;
      width: 155px;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      background-size: 100%;
      background-repeat: no-repeat;
      background-position: center; }
    #gopoint .header-random-golucky a.logout {
      color: #2b2b2b;
      font-size: 16px;
      font-family: "Open Sans", Helvetica, sans-serif;
      letter-spacing: normal;
      float: right;
      top: 20px;
      position: relative; }
  #gopoint .container-random-choose .container-select {
    overflow: hidden;
    position: relative;
    border-radius: 5px;
    padding: 0 20px;
    padding-right: 0px;
    border: 1px solid #a4dbe4;
    margin-bottom: 20px;
    height: 50px; }
    #gopoint .container-random-choose .container-select.goride {
      border: 1px solid #eaeaea; }
    #gopoint .container-random-choose .container-select select {
      font-weight: 700;
      outline: none;
      border: none;
      background: transparent;
      width: 100%;
      height: 100%;
      -webkit-appearance: none; }
    #gopoint .container-random-choose .container-select .arrow-choose {
      position: absolute;
      height: 100%;
      width: 50px;
      background-color: #a4dbe4;
      top: 0;
      right: 0;
      text-align: center;
      line-height: 50px; }
      #gopoint .container-random-choose .container-select .arrow-choose.goride {
        background-color: #eaeaea; }
  #gopoint .container-random-choose .container-button-choose > div {
    padding: 0 10px; }
  #gopoint .container-random-choose .container-button-choose button {
    background-color: #F79520;
    padding: 20px 0;
    width: 100%;
    border-radius: 5px;
    color: #fff; }
    #gopoint .container-random-choose .container-button-choose button.goride {
      background-color: #48af4a; }
    #gopoint .container-random-choose .container-button-choose button.danger {
      background-color: #ea4249; }
    #gopoint .container-random-choose .container-button-choose button.disactive {
      pointer-events: none;
      background-color: #ececec; }
  #gopoint .container-random {
    width: 100%;
    position: relative;
    text-align: center; }
    #gopoint .container-random .word-random {
      padding: 20px 0; }
      #gopoint .container-random .word-random.goride > .digit {
        height: 100px;
        width: 90px;
        border-radius: 0px;
        display: inline-block;
        background-color: #48af4a;
        color: #fff;
        overflow: hidden;
        margin: 0px; }
        #gopoint .container-random .word-random.goride > .digit .container-odo-digit span {
          display: inline-block;
          height: 100px;
          width: 90px;
          text-align: center;
          font-size: 80px; }
    #gopoint .container-random .info {
      font-weight: 700;
      color: #F79520;
      font-weight: 18px;
      letter-spacing: 1px; }
      #gopoint .container-random .info.goride {
        color: #48af4a; }
    #gopoint .container-random .winner-id {
      font-size: 100px;
      font-weight: 700;
      margin: 20px 0;
      letter-spacing: 20px; }
    #gopoint .container-random .winner-name {
      transition: all 0.5s;
      width: 90%;
      display: inline-block;
      font-weight: 700;
      font-size: 50px;
      border: 1px solid #a4dbe4;
      border-radius: 5px;
      padding: 5px 0;
      text-align: center;
      opacity: 0; }
      #gopoint .container-random .winner-name .winner-name-person {
        opacity: 0; }
      #gopoint .container-random .winner-name.active {
        transition: all 0.5s;
        opacity: 1; }
        #gopoint .container-random .winner-name.active .winner-name-person {
          animation: 1s showWinner forwards;
          animation-delay: 4s; }
    #gopoint .container-random .container-odo-digit[data-container='1'] {
      animation: 0.55s odometer infinite linear; }
      #gopoint .container-random .container-odo-digit[data-container='1'].stop[data-stop='0'] {
        animation: 0.75s odometer infinite linear, 0.75s odometerstop-0 forwards linear -0.25s; }
      #gopoint .container-random .container-odo-digit[data-container='1'].stop[data-stop='1'] {
        animation: 0.75s odometer infinite linear, 0.75s odometerstop-1 forwards linear -0.25s; }
      #gopoint .container-random .container-odo-digit[data-container='1'].stop[data-stop='2'] {
        animation: 0.75s odometer infinite linear, 0.75s odometerstop-2 forwards linear -0.25s; }
      #gopoint .container-random .container-odo-digit[data-container='1'].stop[data-stop='3'] {
        animation: 0.75s odometer infinite linear, 0.75s odometerstop-3 forwards linear -0.25s; }
      #gopoint .container-random .container-odo-digit[data-container='1'].stop[data-stop='4'] {
        animation: 0.75s odometer infinite linear, 0.75s odometerstop-4 forwards linear -0.25s; }
      #gopoint .container-random .container-odo-digit[data-container='1'].stop[data-stop='5'] {
        animation: 0.75s odometer infinite linear, 0.75s odometerstop-5 forwards linear -0.25s; }
      #gopoint .container-random .container-odo-digit[data-container='1'].stop[data-stop='6'] {
        animation: 0.75s odometer infinite linear, 0.75s odometerstop-6 forwards linear -0.25s; }
      #gopoint .container-random .container-odo-digit[data-container='1'].stop[data-stop='7'] {
        animation: 0.75s odometer infinite linear, 0.75s odometerstop-7 forwards linear -0.25s; }
      #gopoint .container-random .container-odo-digit[data-container='1'].stop[data-stop='8'] {
        animation: 0.75s odometer infinite linear, 0.75s odometerstop-8 forwards linear -0.25s; }
      #gopoint .container-random .container-odo-digit[data-container='1'].stop[data-stop='9'] {
        animation: 0.75s odometer infinite linear, 0.75s odometerstop-9 forwards linear -0.25s; }
      #gopoint .container-random .container-odo-digit[data-container='1'].stop[data-stop='10'] {
        animation: 0.75s odometer infinite linear, 0.75s odometerstop-10 forwards linear -0.25s; }
      #gopoint .container-random .container-odo-digit[data-container='1'].stop[data-stop='11'] {
        animation: 0.75s odometer infinite linear, 0.75s odometerstop-11 forwards linear -0.25s; }
      #gopoint .container-random .container-odo-digit[data-container='1'].stop[data-stop='12'] {
        animation: 0.75s odometer infinite linear, 0.75s odometerstop-12 forwards linear -0.25s; }
      #gopoint .container-random .container-odo-digit[data-container='1'].stop[data-stop='13'] {
        animation: 0.75s odometer infinite linear, 0.75s odometerstop-13 forwards linear -0.25s; }
      #gopoint .container-random .container-odo-digit[data-container='1'].stop[data-stop='14'] {
        animation: 0.75s odometer infinite linear, 0.75s odometerstop-14 forwards linear -0.25s; }
      #gopoint .container-random .container-odo-digit[data-container='1'].stop[data-stop='15'] {
        animation: 0.75s odometer infinite linear, 0.75s odometerstop-15 forwards linear -0.25s; }
      #gopoint .container-random .container-odo-digit[data-container='1'].stop[data-stop='16'] {
        animation: 0.75s odometer infinite linear, 0.75s odometerstop-16 forwards linear -0.25s; }
      #gopoint .container-random .container-odo-digit[data-container='1'].stop[data-stop='17'] {
        animation: 0.75s odometer infinite linear, 0.75s odometerstop-17 forwards linear -0.25s; }
      #gopoint .container-random .container-odo-digit[data-container='1'].stop[data-stop='18'] {
        animation: 0.75s odometer infinite linear, 0.75s odometerstop-18 forwards linear -0.25s; }
      #gopoint .container-random .container-odo-digit[data-container='1'].stop[data-stop='19'] {
        animation: 0.75s odometer infinite linear, 0.75s odometerstop-19 forwards linear -0.25s; }
      #gopoint .container-random .container-odo-digit[data-container='1'].stop[data-stop='20'] {
        animation: 0.75s odometer infinite linear, 0.75s odometerstop-20 forwards linear -0.25s; }
      #gopoint .container-random .container-odo-digit[data-container='1'].stop[data-stop='21'] {
        animation: 0.75s odometer infinite linear, 0.75s odometerstop-21 forwards linear -0.25s; }
      #gopoint .container-random .container-odo-digit[data-container='1'].stop[data-stop='22'] {
        animation: 0.75s odometer infinite linear, 0.75s odometerstop-22 forwards linear -0.25s; }
      #gopoint .container-random .container-odo-digit[data-container='1'].stop[data-stop='23'] {
        animation: 0.75s odometer infinite linear, 0.75s odometerstop-23 forwards linear -0.25s; }
      #gopoint .container-random .container-odo-digit[data-container='1'].stop[data-stop='24'] {
        animation: 0.75s odometer infinite linear, 0.75s odometerstop-24 forwards linear -0.25s; }
      #gopoint .container-random .container-odo-digit[data-container='1'].stop[data-stop='25'] {
        animation: 0.75s odometer infinite linear, 0.75s odometerstop-25 forwards linear -0.25s; }
      #gopoint .container-random .container-odo-digit[data-container='1'].stop[data-stop='26'] {
        animation: 0.75s odometer infinite linear, 0.75s odometerstop-26 forwards linear -0.25s; }
      #gopoint .container-random .container-odo-digit[data-container='1'].stop[data-stop='27'] {
        animation: 0.75s odometer infinite linear, 0.75s odometerstop-27 forwards linear -0.25s; }
      #gopoint .container-random .container-odo-digit[data-container='1'].stop[data-stop='28'] {
        animation: 0.75s odometer infinite linear, 0.75s odometerstop-28 forwards linear -0.25s; }
      #gopoint .container-random .container-odo-digit[data-container='1'].stop[data-stop='29'] {
        animation: 0.75s odometer infinite linear, 0.75s odometerstop-29 forwards linear -0.25s; }
      #gopoint .container-random .container-odo-digit[data-container='1'].stop[data-stop='30'] {
        animation: 0.75s odometer infinite linear, 0.75s odometerstop-30 forwards linear -0.25s; }
      #gopoint .container-random .container-odo-digit[data-container='1'].stop[data-stop='31'] {
        animation: 0.75s odometer infinite linear, 0.75s odometerstop-31 forwards linear -0.25s; }
      #gopoint .container-random .container-odo-digit[data-container='1'].stop[data-stop='32'] {
        animation: 0.75s odometer infinite linear, 0.75s odometerstop-32 forwards linear -0.25s; }
      #gopoint .container-random .container-odo-digit[data-container='1'].stop[data-stop='33'] {
        animation: 0.75s odometer infinite linear, 0.75s odometerstop-33 forwards linear -0.25s; }
      #gopoint .container-random .container-odo-digit[data-container='1'].stop[data-stop='34'] {
        animation: 0.75s odometer infinite linear, 0.75s odometerstop-34 forwards linear -0.25s; }
      #gopoint .container-random .container-odo-digit[data-container='1'].stop[data-stop='35'] {
        animation: 0.75s odometer infinite linear, 0.75s odometerstop-35 forwards linear -0.25s; }
      #gopoint .container-random .container-odo-digit[data-container='1'].stop[data-stop='36'] {
        animation: 0.75s odometer infinite linear, 0.75s odometerstop-36 forwards linear -0.25s; }
    #gopoint .container-random .container-odo-digit[data-container='2'] {
      animation: 0.55s odometer infinite linear; }
      #gopoint .container-random .container-odo-digit[data-container='2'].stop[data-stop='0'] {
        animation: 1s odometer infinite linear, 1s odometerstop-0 forwards linear 0s; }
      #gopoint .container-random .container-odo-digit[data-container='2'].stop[data-stop='1'] {
        animation: 1s odometer infinite linear, 1s odometerstop-1 forwards linear 0s; }
      #gopoint .container-random .container-odo-digit[data-container='2'].stop[data-stop='2'] {
        animation: 1s odometer infinite linear, 1s odometerstop-2 forwards linear 0s; }
      #gopoint .container-random .container-odo-digit[data-container='2'].stop[data-stop='3'] {
        animation: 1s odometer infinite linear, 1s odometerstop-3 forwards linear 0s; }
      #gopoint .container-random .container-odo-digit[data-container='2'].stop[data-stop='4'] {
        animation: 1s odometer infinite linear, 1s odometerstop-4 forwards linear 0s; }
      #gopoint .container-random .container-odo-digit[data-container='2'].stop[data-stop='5'] {
        animation: 1s odometer infinite linear, 1s odometerstop-5 forwards linear 0s; }
      #gopoint .container-random .container-odo-digit[data-container='2'].stop[data-stop='6'] {
        animation: 1s odometer infinite linear, 1s odometerstop-6 forwards linear 0s; }
      #gopoint .container-random .container-odo-digit[data-container='2'].stop[data-stop='7'] {
        animation: 1s odometer infinite linear, 1s odometerstop-7 forwards linear 0s; }
      #gopoint .container-random .container-odo-digit[data-container='2'].stop[data-stop='8'] {
        animation: 1s odometer infinite linear, 1s odometerstop-8 forwards linear 0s; }
      #gopoint .container-random .container-odo-digit[data-container='2'].stop[data-stop='9'] {
        animation: 1s odometer infinite linear, 1s odometerstop-9 forwards linear 0s; }
      #gopoint .container-random .container-odo-digit[data-container='2'].stop[data-stop='10'] {
        animation: 1s odometer infinite linear, 1s odometerstop-10 forwards linear 0s; }
      #gopoint .container-random .container-odo-digit[data-container='2'].stop[data-stop='11'] {
        animation: 1s odometer infinite linear, 1s odometerstop-11 forwards linear 0s; }
      #gopoint .container-random .container-odo-digit[data-container='2'].stop[data-stop='12'] {
        animation: 1s odometer infinite linear, 1s odometerstop-12 forwards linear 0s; }
      #gopoint .container-random .container-odo-digit[data-container='2'].stop[data-stop='13'] {
        animation: 1s odometer infinite linear, 1s odometerstop-13 forwards linear 0s; }
      #gopoint .container-random .container-odo-digit[data-container='2'].stop[data-stop='14'] {
        animation: 1s odometer infinite linear, 1s odometerstop-14 forwards linear 0s; }
      #gopoint .container-random .container-odo-digit[data-container='2'].stop[data-stop='15'] {
        animation: 1s odometer infinite linear, 1s odometerstop-15 forwards linear 0s; }
      #gopoint .container-random .container-odo-digit[data-container='2'].stop[data-stop='16'] {
        animation: 1s odometer infinite linear, 1s odometerstop-16 forwards linear 0s; }
      #gopoint .container-random .container-odo-digit[data-container='2'].stop[data-stop='17'] {
        animation: 1s odometer infinite linear, 1s odometerstop-17 forwards linear 0s; }
      #gopoint .container-random .container-odo-digit[data-container='2'].stop[data-stop='18'] {
        animation: 1s odometer infinite linear, 1s odometerstop-18 forwards linear 0s; }
      #gopoint .container-random .container-odo-digit[data-container='2'].stop[data-stop='19'] {
        animation: 1s odometer infinite linear, 1s odometerstop-19 forwards linear 0s; }
      #gopoint .container-random .container-odo-digit[data-container='2'].stop[data-stop='20'] {
        animation: 1s odometer infinite linear, 1s odometerstop-20 forwards linear 0s; }
      #gopoint .container-random .container-odo-digit[data-container='2'].stop[data-stop='21'] {
        animation: 1s odometer infinite linear, 1s odometerstop-21 forwards linear 0s; }
      #gopoint .container-random .container-odo-digit[data-container='2'].stop[data-stop='22'] {
        animation: 1s odometer infinite linear, 1s odometerstop-22 forwards linear 0s; }
      #gopoint .container-random .container-odo-digit[data-container='2'].stop[data-stop='23'] {
        animation: 1s odometer infinite linear, 1s odometerstop-23 forwards linear 0s; }
      #gopoint .container-random .container-odo-digit[data-container='2'].stop[data-stop='24'] {
        animation: 1s odometer infinite linear, 1s odometerstop-24 forwards linear 0s; }
      #gopoint .container-random .container-odo-digit[data-container='2'].stop[data-stop='25'] {
        animation: 1s odometer infinite linear, 1s odometerstop-25 forwards linear 0s; }
      #gopoint .container-random .container-odo-digit[data-container='2'].stop[data-stop='26'] {
        animation: 1s odometer infinite linear, 1s odometerstop-26 forwards linear 0s; }
      #gopoint .container-random .container-odo-digit[data-container='2'].stop[data-stop='27'] {
        animation: 1s odometer infinite linear, 1s odometerstop-27 forwards linear 0s; }
      #gopoint .container-random .container-odo-digit[data-container='2'].stop[data-stop='28'] {
        animation: 1s odometer infinite linear, 1s odometerstop-28 forwards linear 0s; }
      #gopoint .container-random .container-odo-digit[data-container='2'].stop[data-stop='29'] {
        animation: 1s odometer infinite linear, 1s odometerstop-29 forwards linear 0s; }
      #gopoint .container-random .container-odo-digit[data-container='2'].stop[data-stop='30'] {
        animation: 1s odometer infinite linear, 1s odometerstop-30 forwards linear 0s; }
      #gopoint .container-random .container-odo-digit[data-container='2'].stop[data-stop='31'] {
        animation: 1s odometer infinite linear, 1s odometerstop-31 forwards linear 0s; }
      #gopoint .container-random .container-odo-digit[data-container='2'].stop[data-stop='32'] {
        animation: 1s odometer infinite linear, 1s odometerstop-32 forwards linear 0s; }
      #gopoint .container-random .container-odo-digit[data-container='2'].stop[data-stop='33'] {
        animation: 1s odometer infinite linear, 1s odometerstop-33 forwards linear 0s; }
      #gopoint .container-random .container-odo-digit[data-container='2'].stop[data-stop='34'] {
        animation: 1s odometer infinite linear, 1s odometerstop-34 forwards linear 0s; }
      #gopoint .container-random .container-odo-digit[data-container='2'].stop[data-stop='35'] {
        animation: 1s odometer infinite linear, 1s odometerstop-35 forwards linear 0s; }
      #gopoint .container-random .container-odo-digit[data-container='2'].stop[data-stop='36'] {
        animation: 1s odometer infinite linear, 1s odometerstop-36 forwards linear 0s; }
    #gopoint .container-random .container-odo-digit[data-container='3'] {
      animation: 0.55s odometer infinite linear; }
      #gopoint .container-random .container-odo-digit[data-container='3'].stop[data-stop='0'] {
        animation: 1.25s odometer infinite linear, 1.25s odometerstop-0 forwards linear 0.25s; }
      #gopoint .container-random .container-odo-digit[data-container='3'].stop[data-stop='1'] {
        animation: 1.25s odometer infinite linear, 1.25s odometerstop-1 forwards linear 0.25s; }
      #gopoint .container-random .container-odo-digit[data-container='3'].stop[data-stop='2'] {
        animation: 1.25s odometer infinite linear, 1.25s odometerstop-2 forwards linear 0.25s; }
      #gopoint .container-random .container-odo-digit[data-container='3'].stop[data-stop='3'] {
        animation: 1.25s odometer infinite linear, 1.25s odometerstop-3 forwards linear 0.25s; }
      #gopoint .container-random .container-odo-digit[data-container='3'].stop[data-stop='4'] {
        animation: 1.25s odometer infinite linear, 1.25s odometerstop-4 forwards linear 0.25s; }
      #gopoint .container-random .container-odo-digit[data-container='3'].stop[data-stop='5'] {
        animation: 1.25s odometer infinite linear, 1.25s odometerstop-5 forwards linear 0.25s; }
      #gopoint .container-random .container-odo-digit[data-container='3'].stop[data-stop='6'] {
        animation: 1.25s odometer infinite linear, 1.25s odometerstop-6 forwards linear 0.25s; }
      #gopoint .container-random .container-odo-digit[data-container='3'].stop[data-stop='7'] {
        animation: 1.25s odometer infinite linear, 1.25s odometerstop-7 forwards linear 0.25s; }
      #gopoint .container-random .container-odo-digit[data-container='3'].stop[data-stop='8'] {
        animation: 1.25s odometer infinite linear, 1.25s odometerstop-8 forwards linear 0.25s; }
      #gopoint .container-random .container-odo-digit[data-container='3'].stop[data-stop='9'] {
        animation: 1.25s odometer infinite linear, 1.25s odometerstop-9 forwards linear 0.25s; }
      #gopoint .container-random .container-odo-digit[data-container='3'].stop[data-stop='10'] {
        animation: 1.25s odometer infinite linear, 1.25s odometerstop-10 forwards linear 0.25s; }
      #gopoint .container-random .container-odo-digit[data-container='3'].stop[data-stop='11'] {
        animation: 1.25s odometer infinite linear, 1.25s odometerstop-11 forwards linear 0.25s; }
      #gopoint .container-random .container-odo-digit[data-container='3'].stop[data-stop='12'] {
        animation: 1.25s odometer infinite linear, 1.25s odometerstop-12 forwards linear 0.25s; }
      #gopoint .container-random .container-odo-digit[data-container='3'].stop[data-stop='13'] {
        animation: 1.25s odometer infinite linear, 1.25s odometerstop-13 forwards linear 0.25s; }
      #gopoint .container-random .container-odo-digit[data-container='3'].stop[data-stop='14'] {
        animation: 1.25s odometer infinite linear, 1.25s odometerstop-14 forwards linear 0.25s; }
      #gopoint .container-random .container-odo-digit[data-container='3'].stop[data-stop='15'] {
        animation: 1.25s odometer infinite linear, 1.25s odometerstop-15 forwards linear 0.25s; }
      #gopoint .container-random .container-odo-digit[data-container='3'].stop[data-stop='16'] {
        animation: 1.25s odometer infinite linear, 1.25s odometerstop-16 forwards linear 0.25s; }
      #gopoint .container-random .container-odo-digit[data-container='3'].stop[data-stop='17'] {
        animation: 1.25s odometer infinite linear, 1.25s odometerstop-17 forwards linear 0.25s; }
      #gopoint .container-random .container-odo-digit[data-container='3'].stop[data-stop='18'] {
        animation: 1.25s odometer infinite linear, 1.25s odometerstop-18 forwards linear 0.25s; }
      #gopoint .container-random .container-odo-digit[data-container='3'].stop[data-stop='19'] {
        animation: 1.25s odometer infinite linear, 1.25s odometerstop-19 forwards linear 0.25s; }
      #gopoint .container-random .container-odo-digit[data-container='3'].stop[data-stop='20'] {
        animation: 1.25s odometer infinite linear, 1.25s odometerstop-20 forwards linear 0.25s; }
      #gopoint .container-random .container-odo-digit[data-container='3'].stop[data-stop='21'] {
        animation: 1.25s odometer infinite linear, 1.25s odometerstop-21 forwards linear 0.25s; }
      #gopoint .container-random .container-odo-digit[data-container='3'].stop[data-stop='22'] {
        animation: 1.25s odometer infinite linear, 1.25s odometerstop-22 forwards linear 0.25s; }
      #gopoint .container-random .container-odo-digit[data-container='3'].stop[data-stop='23'] {
        animation: 1.25s odometer infinite linear, 1.25s odometerstop-23 forwards linear 0.25s; }
      #gopoint .container-random .container-odo-digit[data-container='3'].stop[data-stop='24'] {
        animation: 1.25s odometer infinite linear, 1.25s odometerstop-24 forwards linear 0.25s; }
      #gopoint .container-random .container-odo-digit[data-container='3'].stop[data-stop='25'] {
        animation: 1.25s odometer infinite linear, 1.25s odometerstop-25 forwards linear 0.25s; }
      #gopoint .container-random .container-odo-digit[data-container='3'].stop[data-stop='26'] {
        animation: 1.25s odometer infinite linear, 1.25s odometerstop-26 forwards linear 0.25s; }
      #gopoint .container-random .container-odo-digit[data-container='3'].stop[data-stop='27'] {
        animation: 1.25s odometer infinite linear, 1.25s odometerstop-27 forwards linear 0.25s; }
      #gopoint .container-random .container-odo-digit[data-container='3'].stop[data-stop='28'] {
        animation: 1.25s odometer infinite linear, 1.25s odometerstop-28 forwards linear 0.25s; }
      #gopoint .container-random .container-odo-digit[data-container='3'].stop[data-stop='29'] {
        animation: 1.25s odometer infinite linear, 1.25s odometerstop-29 forwards linear 0.25s; }
      #gopoint .container-random .container-odo-digit[data-container='3'].stop[data-stop='30'] {
        animation: 1.25s odometer infinite linear, 1.25s odometerstop-30 forwards linear 0.25s; }
      #gopoint .container-random .container-odo-digit[data-container='3'].stop[data-stop='31'] {
        animation: 1.25s odometer infinite linear, 1.25s odometerstop-31 forwards linear 0.25s; }
      #gopoint .container-random .container-odo-digit[data-container='3'].stop[data-stop='32'] {
        animation: 1.25s odometer infinite linear, 1.25s odometerstop-32 forwards linear 0.25s; }
      #gopoint .container-random .container-odo-digit[data-container='3'].stop[data-stop='33'] {
        animation: 1.25s odometer infinite linear, 1.25s odometerstop-33 forwards linear 0.25s; }
      #gopoint .container-random .container-odo-digit[data-container='3'].stop[data-stop='34'] {
        animation: 1.25s odometer infinite linear, 1.25s odometerstop-34 forwards linear 0.25s; }
      #gopoint .container-random .container-odo-digit[data-container='3'].stop[data-stop='35'] {
        animation: 1.25s odometer infinite linear, 1.25s odometerstop-35 forwards linear 0.25s; }
      #gopoint .container-random .container-odo-digit[data-container='3'].stop[data-stop='36'] {
        animation: 1.25s odometer infinite linear, 1.25s odometerstop-36 forwards linear 0.25s; }
    #gopoint .container-random .container-odo-digit[data-container='4'] {
      animation: 0.55s odometer infinite linear; }
      #gopoint .container-random .container-odo-digit[data-container='4'].stop[data-stop='0'] {
        animation: 1.5s odometer infinite linear, 1.5s odometerstop-0 forwards linear 0.5s; }
      #gopoint .container-random .container-odo-digit[data-container='4'].stop[data-stop='1'] {
        animation: 1.5s odometer infinite linear, 1.5s odometerstop-1 forwards linear 0.5s; }
      #gopoint .container-random .container-odo-digit[data-container='4'].stop[data-stop='2'] {
        animation: 1.5s odometer infinite linear, 1.5s odometerstop-2 forwards linear 0.5s; }
      #gopoint .container-random .container-odo-digit[data-container='4'].stop[data-stop='3'] {
        animation: 1.5s odometer infinite linear, 1.5s odometerstop-3 forwards linear 0.5s; }
      #gopoint .container-random .container-odo-digit[data-container='4'].stop[data-stop='4'] {
        animation: 1.5s odometer infinite linear, 1.5s odometerstop-4 forwards linear 0.5s; }
      #gopoint .container-random .container-odo-digit[data-container='4'].stop[data-stop='5'] {
        animation: 1.5s odometer infinite linear, 1.5s odometerstop-5 forwards linear 0.5s; }
      #gopoint .container-random .container-odo-digit[data-container='4'].stop[data-stop='6'] {
        animation: 1.5s odometer infinite linear, 1.5s odometerstop-6 forwards linear 0.5s; }
      #gopoint .container-random .container-odo-digit[data-container='4'].stop[data-stop='7'] {
        animation: 1.5s odometer infinite linear, 1.5s odometerstop-7 forwards linear 0.5s; }
      #gopoint .container-random .container-odo-digit[data-container='4'].stop[data-stop='8'] {
        animation: 1.5s odometer infinite linear, 1.5s odometerstop-8 forwards linear 0.5s; }
      #gopoint .container-random .container-odo-digit[data-container='4'].stop[data-stop='9'] {
        animation: 1.5s odometer infinite linear, 1.5s odometerstop-9 forwards linear 0.5s; }
      #gopoint .container-random .container-odo-digit[data-container='4'].stop[data-stop='10'] {
        animation: 1.5s odometer infinite linear, 1.5s odometerstop-10 forwards linear 0.5s; }
      #gopoint .container-random .container-odo-digit[data-container='4'].stop[data-stop='11'] {
        animation: 1.5s odometer infinite linear, 1.5s odometerstop-11 forwards linear 0.5s; }
      #gopoint .container-random .container-odo-digit[data-container='4'].stop[data-stop='12'] {
        animation: 1.5s odometer infinite linear, 1.5s odometerstop-12 forwards linear 0.5s; }
      #gopoint .container-random .container-odo-digit[data-container='4'].stop[data-stop='13'] {
        animation: 1.5s odometer infinite linear, 1.5s odometerstop-13 forwards linear 0.5s; }
      #gopoint .container-random .container-odo-digit[data-container='4'].stop[data-stop='14'] {
        animation: 1.5s odometer infinite linear, 1.5s odometerstop-14 forwards linear 0.5s; }
      #gopoint .container-random .container-odo-digit[data-container='4'].stop[data-stop='15'] {
        animation: 1.5s odometer infinite linear, 1.5s odometerstop-15 forwards linear 0.5s; }
      #gopoint .container-random .container-odo-digit[data-container='4'].stop[data-stop='16'] {
        animation: 1.5s odometer infinite linear, 1.5s odometerstop-16 forwards linear 0.5s; }
      #gopoint .container-random .container-odo-digit[data-container='4'].stop[data-stop='17'] {
        animation: 1.5s odometer infinite linear, 1.5s odometerstop-17 forwards linear 0.5s; }
      #gopoint .container-random .container-odo-digit[data-container='4'].stop[data-stop='18'] {
        animation: 1.5s odometer infinite linear, 1.5s odometerstop-18 forwards linear 0.5s; }
      #gopoint .container-random .container-odo-digit[data-container='4'].stop[data-stop='19'] {
        animation: 1.5s odometer infinite linear, 1.5s odometerstop-19 forwards linear 0.5s; }
      #gopoint .container-random .container-odo-digit[data-container='4'].stop[data-stop='20'] {
        animation: 1.5s odometer infinite linear, 1.5s odometerstop-20 forwards linear 0.5s; }
      #gopoint .container-random .container-odo-digit[data-container='4'].stop[data-stop='21'] {
        animation: 1.5s odometer infinite linear, 1.5s odometerstop-21 forwards linear 0.5s; }
      #gopoint .container-random .container-odo-digit[data-container='4'].stop[data-stop='22'] {
        animation: 1.5s odometer infinite linear, 1.5s odometerstop-22 forwards linear 0.5s; }
      #gopoint .container-random .container-odo-digit[data-container='4'].stop[data-stop='23'] {
        animation: 1.5s odometer infinite linear, 1.5s odometerstop-23 forwards linear 0.5s; }
      #gopoint .container-random .container-odo-digit[data-container='4'].stop[data-stop='24'] {
        animation: 1.5s odometer infinite linear, 1.5s odometerstop-24 forwards linear 0.5s; }
      #gopoint .container-random .container-odo-digit[data-container='4'].stop[data-stop='25'] {
        animation: 1.5s odometer infinite linear, 1.5s odometerstop-25 forwards linear 0.5s; }
      #gopoint .container-random .container-odo-digit[data-container='4'].stop[data-stop='26'] {
        animation: 1.5s odometer infinite linear, 1.5s odometerstop-26 forwards linear 0.5s; }
      #gopoint .container-random .container-odo-digit[data-container='4'].stop[data-stop='27'] {
        animation: 1.5s odometer infinite linear, 1.5s odometerstop-27 forwards linear 0.5s; }
      #gopoint .container-random .container-odo-digit[data-container='4'].stop[data-stop='28'] {
        animation: 1.5s odometer infinite linear, 1.5s odometerstop-28 forwards linear 0.5s; }
      #gopoint .container-random .container-odo-digit[data-container='4'].stop[data-stop='29'] {
        animation: 1.5s odometer infinite linear, 1.5s odometerstop-29 forwards linear 0.5s; }
      #gopoint .container-random .container-odo-digit[data-container='4'].stop[data-stop='30'] {
        animation: 1.5s odometer infinite linear, 1.5s odometerstop-30 forwards linear 0.5s; }
      #gopoint .container-random .container-odo-digit[data-container='4'].stop[data-stop='31'] {
        animation: 1.5s odometer infinite linear, 1.5s odometerstop-31 forwards linear 0.5s; }
      #gopoint .container-random .container-odo-digit[data-container='4'].stop[data-stop='32'] {
        animation: 1.5s odometer infinite linear, 1.5s odometerstop-32 forwards linear 0.5s; }
      #gopoint .container-random .container-odo-digit[data-container='4'].stop[data-stop='33'] {
        animation: 1.5s odometer infinite linear, 1.5s odometerstop-33 forwards linear 0.5s; }
      #gopoint .container-random .container-odo-digit[data-container='4'].stop[data-stop='34'] {
        animation: 1.5s odometer infinite linear, 1.5s odometerstop-34 forwards linear 0.5s; }
      #gopoint .container-random .container-odo-digit[data-container='4'].stop[data-stop='35'] {
        animation: 1.5s odometer infinite linear, 1.5s odometerstop-35 forwards linear 0.5s; }
      #gopoint .container-random .container-odo-digit[data-container='4'].stop[data-stop='36'] {
        animation: 1.5s odometer infinite linear, 1.5s odometerstop-36 forwards linear 0.5s; }
    #gopoint .container-random .container-odo-digit[data-container='5'] {
      animation: 0.55s odometer infinite linear; }
      #gopoint .container-random .container-odo-digit[data-container='5'].stop[data-stop='0'] {
        animation: 1.75s odometer infinite linear, 1.75s odometerstop-0 forwards linear 0.75s; }
      #gopoint .container-random .container-odo-digit[data-container='5'].stop[data-stop='1'] {
        animation: 1.75s odometer infinite linear, 1.75s odometerstop-1 forwards linear 0.75s; }
      #gopoint .container-random .container-odo-digit[data-container='5'].stop[data-stop='2'] {
        animation: 1.75s odometer infinite linear, 1.75s odometerstop-2 forwards linear 0.75s; }
      #gopoint .container-random .container-odo-digit[data-container='5'].stop[data-stop='3'] {
        animation: 1.75s odometer infinite linear, 1.75s odometerstop-3 forwards linear 0.75s; }
      #gopoint .container-random .container-odo-digit[data-container='5'].stop[data-stop='4'] {
        animation: 1.75s odometer infinite linear, 1.75s odometerstop-4 forwards linear 0.75s; }
      #gopoint .container-random .container-odo-digit[data-container='5'].stop[data-stop='5'] {
        animation: 1.75s odometer infinite linear, 1.75s odometerstop-5 forwards linear 0.75s; }
      #gopoint .container-random .container-odo-digit[data-container='5'].stop[data-stop='6'] {
        animation: 1.75s odometer infinite linear, 1.75s odometerstop-6 forwards linear 0.75s; }
      #gopoint .container-random .container-odo-digit[data-container='5'].stop[data-stop='7'] {
        animation: 1.75s odometer infinite linear, 1.75s odometerstop-7 forwards linear 0.75s; }
      #gopoint .container-random .container-odo-digit[data-container='5'].stop[data-stop='8'] {
        animation: 1.75s odometer infinite linear, 1.75s odometerstop-8 forwards linear 0.75s; }
      #gopoint .container-random .container-odo-digit[data-container='5'].stop[data-stop='9'] {
        animation: 1.75s odometer infinite linear, 1.75s odometerstop-9 forwards linear 0.75s; }
      #gopoint .container-random .container-odo-digit[data-container='5'].stop[data-stop='10'] {
        animation: 1.75s odometer infinite linear, 1.75s odometerstop-10 forwards linear 0.75s; }
      #gopoint .container-random .container-odo-digit[data-container='5'].stop[data-stop='11'] {
        animation: 1.75s odometer infinite linear, 1.75s odometerstop-11 forwards linear 0.75s; }
      #gopoint .container-random .container-odo-digit[data-container='5'].stop[data-stop='12'] {
        animation: 1.75s odometer infinite linear, 1.75s odometerstop-12 forwards linear 0.75s; }
      #gopoint .container-random .container-odo-digit[data-container='5'].stop[data-stop='13'] {
        animation: 1.75s odometer infinite linear, 1.75s odometerstop-13 forwards linear 0.75s; }
      #gopoint .container-random .container-odo-digit[data-container='5'].stop[data-stop='14'] {
        animation: 1.75s odometer infinite linear, 1.75s odometerstop-14 forwards linear 0.75s; }
      #gopoint .container-random .container-odo-digit[data-container='5'].stop[data-stop='15'] {
        animation: 1.75s odometer infinite linear, 1.75s odometerstop-15 forwards linear 0.75s; }
      #gopoint .container-random .container-odo-digit[data-container='5'].stop[data-stop='16'] {
        animation: 1.75s odometer infinite linear, 1.75s odometerstop-16 forwards linear 0.75s; }
      #gopoint .container-random .container-odo-digit[data-container='5'].stop[data-stop='17'] {
        animation: 1.75s odometer infinite linear, 1.75s odometerstop-17 forwards linear 0.75s; }
      #gopoint .container-random .container-odo-digit[data-container='5'].stop[data-stop='18'] {
        animation: 1.75s odometer infinite linear, 1.75s odometerstop-18 forwards linear 0.75s; }
      #gopoint .container-random .container-odo-digit[data-container='5'].stop[data-stop='19'] {
        animation: 1.75s odometer infinite linear, 1.75s odometerstop-19 forwards linear 0.75s; }
      #gopoint .container-random .container-odo-digit[data-container='5'].stop[data-stop='20'] {
        animation: 1.75s odometer infinite linear, 1.75s odometerstop-20 forwards linear 0.75s; }
      #gopoint .container-random .container-odo-digit[data-container='5'].stop[data-stop='21'] {
        animation: 1.75s odometer infinite linear, 1.75s odometerstop-21 forwards linear 0.75s; }
      #gopoint .container-random .container-odo-digit[data-container='5'].stop[data-stop='22'] {
        animation: 1.75s odometer infinite linear, 1.75s odometerstop-22 forwards linear 0.75s; }
      #gopoint .container-random .container-odo-digit[data-container='5'].stop[data-stop='23'] {
        animation: 1.75s odometer infinite linear, 1.75s odometerstop-23 forwards linear 0.75s; }
      #gopoint .container-random .container-odo-digit[data-container='5'].stop[data-stop='24'] {
        animation: 1.75s odometer infinite linear, 1.75s odometerstop-24 forwards linear 0.75s; }
      #gopoint .container-random .container-odo-digit[data-container='5'].stop[data-stop='25'] {
        animation: 1.75s odometer infinite linear, 1.75s odometerstop-25 forwards linear 0.75s; }
      #gopoint .container-random .container-odo-digit[data-container='5'].stop[data-stop='26'] {
        animation: 1.75s odometer infinite linear, 1.75s odometerstop-26 forwards linear 0.75s; }
      #gopoint .container-random .container-odo-digit[data-container='5'].stop[data-stop='27'] {
        animation: 1.75s odometer infinite linear, 1.75s odometerstop-27 forwards linear 0.75s; }
      #gopoint .container-random .container-odo-digit[data-container='5'].stop[data-stop='28'] {
        animation: 1.75s odometer infinite linear, 1.75s odometerstop-28 forwards linear 0.75s; }
      #gopoint .container-random .container-odo-digit[data-container='5'].stop[data-stop='29'] {
        animation: 1.75s odometer infinite linear, 1.75s odometerstop-29 forwards linear 0.75s; }
      #gopoint .container-random .container-odo-digit[data-container='5'].stop[data-stop='30'] {
        animation: 1.75s odometer infinite linear, 1.75s odometerstop-30 forwards linear 0.75s; }
      #gopoint .container-random .container-odo-digit[data-container='5'].stop[data-stop='31'] {
        animation: 1.75s odometer infinite linear, 1.75s odometerstop-31 forwards linear 0.75s; }
      #gopoint .container-random .container-odo-digit[data-container='5'].stop[data-stop='32'] {
        animation: 1.75s odometer infinite linear, 1.75s odometerstop-32 forwards linear 0.75s; }
      #gopoint .container-random .container-odo-digit[data-container='5'].stop[data-stop='33'] {
        animation: 1.75s odometer infinite linear, 1.75s odometerstop-33 forwards linear 0.75s; }
      #gopoint .container-random .container-odo-digit[data-container='5'].stop[data-stop='34'] {
        animation: 1.75s odometer infinite linear, 1.75s odometerstop-34 forwards linear 0.75s; }
      #gopoint .container-random .container-odo-digit[data-container='5'].stop[data-stop='35'] {
        animation: 1.75s odometer infinite linear, 1.75s odometerstop-35 forwards linear 0.75s; }
      #gopoint .container-random .container-odo-digit[data-container='5'].stop[data-stop='36'] {
        animation: 1.75s odometer infinite linear, 1.75s odometerstop-36 forwards linear 0.75s; }
    #gopoint .container-random .container-odo-digit[data-container='6'] {
      animation: 0.55s odometer infinite linear; }
      #gopoint .container-random .container-odo-digit[data-container='6'].stop[data-stop='0'] {
        animation: 2s odometer infinite linear, 2s odometerstop-0 forwards linear 1s; }
      #gopoint .container-random .container-odo-digit[data-container='6'].stop[data-stop='1'] {
        animation: 2s odometer infinite linear, 2s odometerstop-1 forwards linear 1s; }
      #gopoint .container-random .container-odo-digit[data-container='6'].stop[data-stop='2'] {
        animation: 2s odometer infinite linear, 2s odometerstop-2 forwards linear 1s; }
      #gopoint .container-random .container-odo-digit[data-container='6'].stop[data-stop='3'] {
        animation: 2s odometer infinite linear, 2s odometerstop-3 forwards linear 1s; }
      #gopoint .container-random .container-odo-digit[data-container='6'].stop[data-stop='4'] {
        animation: 2s odometer infinite linear, 2s odometerstop-4 forwards linear 1s; }
      #gopoint .container-random .container-odo-digit[data-container='6'].stop[data-stop='5'] {
        animation: 2s odometer infinite linear, 2s odometerstop-5 forwards linear 1s; }
      #gopoint .container-random .container-odo-digit[data-container='6'].stop[data-stop='6'] {
        animation: 2s odometer infinite linear, 2s odometerstop-6 forwards linear 1s; }
      #gopoint .container-random .container-odo-digit[data-container='6'].stop[data-stop='7'] {
        animation: 2s odometer infinite linear, 2s odometerstop-7 forwards linear 1s; }
      #gopoint .container-random .container-odo-digit[data-container='6'].stop[data-stop='8'] {
        animation: 2s odometer infinite linear, 2s odometerstop-8 forwards linear 1s; }
      #gopoint .container-random .container-odo-digit[data-container='6'].stop[data-stop='9'] {
        animation: 2s odometer infinite linear, 2s odometerstop-9 forwards linear 1s; }
      #gopoint .container-random .container-odo-digit[data-container='6'].stop[data-stop='10'] {
        animation: 2s odometer infinite linear, 2s odometerstop-10 forwards linear 1s; }
      #gopoint .container-random .container-odo-digit[data-container='6'].stop[data-stop='11'] {
        animation: 2s odometer infinite linear, 2s odometerstop-11 forwards linear 1s; }
      #gopoint .container-random .container-odo-digit[data-container='6'].stop[data-stop='12'] {
        animation: 2s odometer infinite linear, 2s odometerstop-12 forwards linear 1s; }
      #gopoint .container-random .container-odo-digit[data-container='6'].stop[data-stop='13'] {
        animation: 2s odometer infinite linear, 2s odometerstop-13 forwards linear 1s; }
      #gopoint .container-random .container-odo-digit[data-container='6'].stop[data-stop='14'] {
        animation: 2s odometer infinite linear, 2s odometerstop-14 forwards linear 1s; }
      #gopoint .container-random .container-odo-digit[data-container='6'].stop[data-stop='15'] {
        animation: 2s odometer infinite linear, 2s odometerstop-15 forwards linear 1s; }
      #gopoint .container-random .container-odo-digit[data-container='6'].stop[data-stop='16'] {
        animation: 2s odometer infinite linear, 2s odometerstop-16 forwards linear 1s; }
      #gopoint .container-random .container-odo-digit[data-container='6'].stop[data-stop='17'] {
        animation: 2s odometer infinite linear, 2s odometerstop-17 forwards linear 1s; }
      #gopoint .container-random .container-odo-digit[data-container='6'].stop[data-stop='18'] {
        animation: 2s odometer infinite linear, 2s odometerstop-18 forwards linear 1s; }
      #gopoint .container-random .container-odo-digit[data-container='6'].stop[data-stop='19'] {
        animation: 2s odometer infinite linear, 2s odometerstop-19 forwards linear 1s; }
      #gopoint .container-random .container-odo-digit[data-container='6'].stop[data-stop='20'] {
        animation: 2s odometer infinite linear, 2s odometerstop-20 forwards linear 1s; }
      #gopoint .container-random .container-odo-digit[data-container='6'].stop[data-stop='21'] {
        animation: 2s odometer infinite linear, 2s odometerstop-21 forwards linear 1s; }
      #gopoint .container-random .container-odo-digit[data-container='6'].stop[data-stop='22'] {
        animation: 2s odometer infinite linear, 2s odometerstop-22 forwards linear 1s; }
      #gopoint .container-random .container-odo-digit[data-container='6'].stop[data-stop='23'] {
        animation: 2s odometer infinite linear, 2s odometerstop-23 forwards linear 1s; }
      #gopoint .container-random .container-odo-digit[data-container='6'].stop[data-stop='24'] {
        animation: 2s odometer infinite linear, 2s odometerstop-24 forwards linear 1s; }
      #gopoint .container-random .container-odo-digit[data-container='6'].stop[data-stop='25'] {
        animation: 2s odometer infinite linear, 2s odometerstop-25 forwards linear 1s; }
      #gopoint .container-random .container-odo-digit[data-container='6'].stop[data-stop='26'] {
        animation: 2s odometer infinite linear, 2s odometerstop-26 forwards linear 1s; }
      #gopoint .container-random .container-odo-digit[data-container='6'].stop[data-stop='27'] {
        animation: 2s odometer infinite linear, 2s odometerstop-27 forwards linear 1s; }
      #gopoint .container-random .container-odo-digit[data-container='6'].stop[data-stop='28'] {
        animation: 2s odometer infinite linear, 2s odometerstop-28 forwards linear 1s; }
      #gopoint .container-random .container-odo-digit[data-container='6'].stop[data-stop='29'] {
        animation: 2s odometer infinite linear, 2s odometerstop-29 forwards linear 1s; }
      #gopoint .container-random .container-odo-digit[data-container='6'].stop[data-stop='30'] {
        animation: 2s odometer infinite linear, 2s odometerstop-30 forwards linear 1s; }
      #gopoint .container-random .container-odo-digit[data-container='6'].stop[data-stop='31'] {
        animation: 2s odometer infinite linear, 2s odometerstop-31 forwards linear 1s; }
      #gopoint .container-random .container-odo-digit[data-container='6'].stop[data-stop='32'] {
        animation: 2s odometer infinite linear, 2s odometerstop-32 forwards linear 1s; }
      #gopoint .container-random .container-odo-digit[data-container='6'].stop[data-stop='33'] {
        animation: 2s odometer infinite linear, 2s odometerstop-33 forwards linear 1s; }
      #gopoint .container-random .container-odo-digit[data-container='6'].stop[data-stop='34'] {
        animation: 2s odometer infinite linear, 2s odometerstop-34 forwards linear 1s; }
      #gopoint .container-random .container-odo-digit[data-container='6'].stop[data-stop='35'] {
        animation: 2s odometer infinite linear, 2s odometerstop-35 forwards linear 1s; }
      #gopoint .container-random .container-odo-digit[data-container='6'].stop[data-stop='36'] {
        animation: 2s odometer infinite linear, 2s odometerstop-36 forwards linear 1s; }
    #gopoint .container-random .container-odo-digit[data-container='7'] {
      animation: 0.55s odometer infinite linear; }
      #gopoint .container-random .container-odo-digit[data-container='7'].stop[data-stop='0'] {
        animation: 2.25s odometer infinite linear, 2.25s odometerstop-0 forwards linear 1.25s; }
      #gopoint .container-random .container-odo-digit[data-container='7'].stop[data-stop='1'] {
        animation: 2.25s odometer infinite linear, 2.25s odometerstop-1 forwards linear 1.25s; }
      #gopoint .container-random .container-odo-digit[data-container='7'].stop[data-stop='2'] {
        animation: 2.25s odometer infinite linear, 2.25s odometerstop-2 forwards linear 1.25s; }
      #gopoint .container-random .container-odo-digit[data-container='7'].stop[data-stop='3'] {
        animation: 2.25s odometer infinite linear, 2.25s odometerstop-3 forwards linear 1.25s; }
      #gopoint .container-random .container-odo-digit[data-container='7'].stop[data-stop='4'] {
        animation: 2.25s odometer infinite linear, 2.25s odometerstop-4 forwards linear 1.25s; }
      #gopoint .container-random .container-odo-digit[data-container='7'].stop[data-stop='5'] {
        animation: 2.25s odometer infinite linear, 2.25s odometerstop-5 forwards linear 1.25s; }
      #gopoint .container-random .container-odo-digit[data-container='7'].stop[data-stop='6'] {
        animation: 2.25s odometer infinite linear, 2.25s odometerstop-6 forwards linear 1.25s; }
      #gopoint .container-random .container-odo-digit[data-container='7'].stop[data-stop='7'] {
        animation: 2.25s odometer infinite linear, 2.25s odometerstop-7 forwards linear 1.25s; }
      #gopoint .container-random .container-odo-digit[data-container='7'].stop[data-stop='8'] {
        animation: 2.25s odometer infinite linear, 2.25s odometerstop-8 forwards linear 1.25s; }
      #gopoint .container-random .container-odo-digit[data-container='7'].stop[data-stop='9'] {
        animation: 2.25s odometer infinite linear, 2.25s odometerstop-9 forwards linear 1.25s; }
      #gopoint .container-random .container-odo-digit[data-container='7'].stop[data-stop='10'] {
        animation: 2.25s odometer infinite linear, 2.25s odometerstop-10 forwards linear 1.25s; }
      #gopoint .container-random .container-odo-digit[data-container='7'].stop[data-stop='11'] {
        animation: 2.25s odometer infinite linear, 2.25s odometerstop-11 forwards linear 1.25s; }
      #gopoint .container-random .container-odo-digit[data-container='7'].stop[data-stop='12'] {
        animation: 2.25s odometer infinite linear, 2.25s odometerstop-12 forwards linear 1.25s; }
      #gopoint .container-random .container-odo-digit[data-container='7'].stop[data-stop='13'] {
        animation: 2.25s odometer infinite linear, 2.25s odometerstop-13 forwards linear 1.25s; }
      #gopoint .container-random .container-odo-digit[data-container='7'].stop[data-stop='14'] {
        animation: 2.25s odometer infinite linear, 2.25s odometerstop-14 forwards linear 1.25s; }
      #gopoint .container-random .container-odo-digit[data-container='7'].stop[data-stop='15'] {
        animation: 2.25s odometer infinite linear, 2.25s odometerstop-15 forwards linear 1.25s; }
      #gopoint .container-random .container-odo-digit[data-container='7'].stop[data-stop='16'] {
        animation: 2.25s odometer infinite linear, 2.25s odometerstop-16 forwards linear 1.25s; }
      #gopoint .container-random .container-odo-digit[data-container='7'].stop[data-stop='17'] {
        animation: 2.25s odometer infinite linear, 2.25s odometerstop-17 forwards linear 1.25s; }
      #gopoint .container-random .container-odo-digit[data-container='7'].stop[data-stop='18'] {
        animation: 2.25s odometer infinite linear, 2.25s odometerstop-18 forwards linear 1.25s; }
      #gopoint .container-random .container-odo-digit[data-container='7'].stop[data-stop='19'] {
        animation: 2.25s odometer infinite linear, 2.25s odometerstop-19 forwards linear 1.25s; }
      #gopoint .container-random .container-odo-digit[data-container='7'].stop[data-stop='20'] {
        animation: 2.25s odometer infinite linear, 2.25s odometerstop-20 forwards linear 1.25s; }
      #gopoint .container-random .container-odo-digit[data-container='7'].stop[data-stop='21'] {
        animation: 2.25s odometer infinite linear, 2.25s odometerstop-21 forwards linear 1.25s; }
      #gopoint .container-random .container-odo-digit[data-container='7'].stop[data-stop='22'] {
        animation: 2.25s odometer infinite linear, 2.25s odometerstop-22 forwards linear 1.25s; }
      #gopoint .container-random .container-odo-digit[data-container='7'].stop[data-stop='23'] {
        animation: 2.25s odometer infinite linear, 2.25s odometerstop-23 forwards linear 1.25s; }
      #gopoint .container-random .container-odo-digit[data-container='7'].stop[data-stop='24'] {
        animation: 2.25s odometer infinite linear, 2.25s odometerstop-24 forwards linear 1.25s; }
      #gopoint .container-random .container-odo-digit[data-container='7'].stop[data-stop='25'] {
        animation: 2.25s odometer infinite linear, 2.25s odometerstop-25 forwards linear 1.25s; }
      #gopoint .container-random .container-odo-digit[data-container='7'].stop[data-stop='26'] {
        animation: 2.25s odometer infinite linear, 2.25s odometerstop-26 forwards linear 1.25s; }
      #gopoint .container-random .container-odo-digit[data-container='7'].stop[data-stop='27'] {
        animation: 2.25s odometer infinite linear, 2.25s odometerstop-27 forwards linear 1.25s; }
      #gopoint .container-random .container-odo-digit[data-container='7'].stop[data-stop='28'] {
        animation: 2.25s odometer infinite linear, 2.25s odometerstop-28 forwards linear 1.25s; }
      #gopoint .container-random .container-odo-digit[data-container='7'].stop[data-stop='29'] {
        animation: 2.25s odometer infinite linear, 2.25s odometerstop-29 forwards linear 1.25s; }
      #gopoint .container-random .container-odo-digit[data-container='7'].stop[data-stop='30'] {
        animation: 2.25s odometer infinite linear, 2.25s odometerstop-30 forwards linear 1.25s; }
      #gopoint .container-random .container-odo-digit[data-container='7'].stop[data-stop='31'] {
        animation: 2.25s odometer infinite linear, 2.25s odometerstop-31 forwards linear 1.25s; }
      #gopoint .container-random .container-odo-digit[data-container='7'].stop[data-stop='32'] {
        animation: 2.25s odometer infinite linear, 2.25s odometerstop-32 forwards linear 1.25s; }
      #gopoint .container-random .container-odo-digit[data-container='7'].stop[data-stop='33'] {
        animation: 2.25s odometer infinite linear, 2.25s odometerstop-33 forwards linear 1.25s; }
      #gopoint .container-random .container-odo-digit[data-container='7'].stop[data-stop='34'] {
        animation: 2.25s odometer infinite linear, 2.25s odometerstop-34 forwards linear 1.25s; }
      #gopoint .container-random .container-odo-digit[data-container='7'].stop[data-stop='35'] {
        animation: 2.25s odometer infinite linear, 2.25s odometerstop-35 forwards linear 1.25s; }
      #gopoint .container-random .container-odo-digit[data-container='7'].stop[data-stop='36'] {
        animation: 2.25s odometer infinite linear, 2.25s odometerstop-36 forwards linear 1.25s; }
    #gopoint .container-random .container-odo-digit[data-container='8'] {
      animation: 0.55s odometer infinite linear; }
      #gopoint .container-random .container-odo-digit[data-container='8'].stop[data-stop='0'] {
        animation: 2.5s odometer infinite linear, 2.5s odometerstop-0 forwards linear 1.5s; }
      #gopoint .container-random .container-odo-digit[data-container='8'].stop[data-stop='1'] {
        animation: 2.5s odometer infinite linear, 2.5s odometerstop-1 forwards linear 1.5s; }
      #gopoint .container-random .container-odo-digit[data-container='8'].stop[data-stop='2'] {
        animation: 2.5s odometer infinite linear, 2.5s odometerstop-2 forwards linear 1.5s; }
      #gopoint .container-random .container-odo-digit[data-container='8'].stop[data-stop='3'] {
        animation: 2.5s odometer infinite linear, 2.5s odometerstop-3 forwards linear 1.5s; }
      #gopoint .container-random .container-odo-digit[data-container='8'].stop[data-stop='4'] {
        animation: 2.5s odometer infinite linear, 2.5s odometerstop-4 forwards linear 1.5s; }
      #gopoint .container-random .container-odo-digit[data-container='8'].stop[data-stop='5'] {
        animation: 2.5s odometer infinite linear, 2.5s odometerstop-5 forwards linear 1.5s; }
      #gopoint .container-random .container-odo-digit[data-container='8'].stop[data-stop='6'] {
        animation: 2.5s odometer infinite linear, 2.5s odometerstop-6 forwards linear 1.5s; }
      #gopoint .container-random .container-odo-digit[data-container='8'].stop[data-stop='7'] {
        animation: 2.5s odometer infinite linear, 2.5s odometerstop-7 forwards linear 1.5s; }
      #gopoint .container-random .container-odo-digit[data-container='8'].stop[data-stop='8'] {
        animation: 2.5s odometer infinite linear, 2.5s odometerstop-8 forwards linear 1.5s; }
      #gopoint .container-random .container-odo-digit[data-container='8'].stop[data-stop='9'] {
        animation: 2.5s odometer infinite linear, 2.5s odometerstop-9 forwards linear 1.5s; }
      #gopoint .container-random .container-odo-digit[data-container='8'].stop[data-stop='10'] {
        animation: 2.5s odometer infinite linear, 2.5s odometerstop-10 forwards linear 1.5s; }
      #gopoint .container-random .container-odo-digit[data-container='8'].stop[data-stop='11'] {
        animation: 2.5s odometer infinite linear, 2.5s odometerstop-11 forwards linear 1.5s; }
      #gopoint .container-random .container-odo-digit[data-container='8'].stop[data-stop='12'] {
        animation: 2.5s odometer infinite linear, 2.5s odometerstop-12 forwards linear 1.5s; }
      #gopoint .container-random .container-odo-digit[data-container='8'].stop[data-stop='13'] {
        animation: 2.5s odometer infinite linear, 2.5s odometerstop-13 forwards linear 1.5s; }
      #gopoint .container-random .container-odo-digit[data-container='8'].stop[data-stop='14'] {
        animation: 2.5s odometer infinite linear, 2.5s odometerstop-14 forwards linear 1.5s; }
      #gopoint .container-random .container-odo-digit[data-container='8'].stop[data-stop='15'] {
        animation: 2.5s odometer infinite linear, 2.5s odometerstop-15 forwards linear 1.5s; }
      #gopoint .container-random .container-odo-digit[data-container='8'].stop[data-stop='16'] {
        animation: 2.5s odometer infinite linear, 2.5s odometerstop-16 forwards linear 1.5s; }
      #gopoint .container-random .container-odo-digit[data-container='8'].stop[data-stop='17'] {
        animation: 2.5s odometer infinite linear, 2.5s odometerstop-17 forwards linear 1.5s; }
      #gopoint .container-random .container-odo-digit[data-container='8'].stop[data-stop='18'] {
        animation: 2.5s odometer infinite linear, 2.5s odometerstop-18 forwards linear 1.5s; }
      #gopoint .container-random .container-odo-digit[data-container='8'].stop[data-stop='19'] {
        animation: 2.5s odometer infinite linear, 2.5s odometerstop-19 forwards linear 1.5s; }
      #gopoint .container-random .container-odo-digit[data-container='8'].stop[data-stop='20'] {
        animation: 2.5s odometer infinite linear, 2.5s odometerstop-20 forwards linear 1.5s; }
      #gopoint .container-random .container-odo-digit[data-container='8'].stop[data-stop='21'] {
        animation: 2.5s odometer infinite linear, 2.5s odometerstop-21 forwards linear 1.5s; }
      #gopoint .container-random .container-odo-digit[data-container='8'].stop[data-stop='22'] {
        animation: 2.5s odometer infinite linear, 2.5s odometerstop-22 forwards linear 1.5s; }
      #gopoint .container-random .container-odo-digit[data-container='8'].stop[data-stop='23'] {
        animation: 2.5s odometer infinite linear, 2.5s odometerstop-23 forwards linear 1.5s; }
      #gopoint .container-random .container-odo-digit[data-container='8'].stop[data-stop='24'] {
        animation: 2.5s odometer infinite linear, 2.5s odometerstop-24 forwards linear 1.5s; }
      #gopoint .container-random .container-odo-digit[data-container='8'].stop[data-stop='25'] {
        animation: 2.5s odometer infinite linear, 2.5s odometerstop-25 forwards linear 1.5s; }
      #gopoint .container-random .container-odo-digit[data-container='8'].stop[data-stop='26'] {
        animation: 2.5s odometer infinite linear, 2.5s odometerstop-26 forwards linear 1.5s; }
      #gopoint .container-random .container-odo-digit[data-container='8'].stop[data-stop='27'] {
        animation: 2.5s odometer infinite linear, 2.5s odometerstop-27 forwards linear 1.5s; }
      #gopoint .container-random .container-odo-digit[data-container='8'].stop[data-stop='28'] {
        animation: 2.5s odometer infinite linear, 2.5s odometerstop-28 forwards linear 1.5s; }
      #gopoint .container-random .container-odo-digit[data-container='8'].stop[data-stop='29'] {
        animation: 2.5s odometer infinite linear, 2.5s odometerstop-29 forwards linear 1.5s; }
      #gopoint .container-random .container-odo-digit[data-container='8'].stop[data-stop='30'] {
        animation: 2.5s odometer infinite linear, 2.5s odometerstop-30 forwards linear 1.5s; }
      #gopoint .container-random .container-odo-digit[data-container='8'].stop[data-stop='31'] {
        animation: 2.5s odometer infinite linear, 2.5s odometerstop-31 forwards linear 1.5s; }
      #gopoint .container-random .container-odo-digit[data-container='8'].stop[data-stop='32'] {
        animation: 2.5s odometer infinite linear, 2.5s odometerstop-32 forwards linear 1.5s; }
      #gopoint .container-random .container-odo-digit[data-container='8'].stop[data-stop='33'] {
        animation: 2.5s odometer infinite linear, 2.5s odometerstop-33 forwards linear 1.5s; }
      #gopoint .container-random .container-odo-digit[data-container='8'].stop[data-stop='34'] {
        animation: 2.5s odometer infinite linear, 2.5s odometerstop-34 forwards linear 1.5s; }
      #gopoint .container-random .container-odo-digit[data-container='8'].stop[data-stop='35'] {
        animation: 2.5s odometer infinite linear, 2.5s odometerstop-35 forwards linear 1.5s; }
      #gopoint .container-random .container-odo-digit[data-container='8'].stop[data-stop='36'] {
        animation: 2.5s odometer infinite linear, 2.5s odometerstop-36 forwards linear 1.5s; }
    #gopoint .container-random .container-odo-digit[data-container='9'] {
      animation: 0.55s odometer infinite linear; }
      #gopoint .container-random .container-odo-digit[data-container='9'].stop[data-stop='0'] {
        animation: 2.75s odometer infinite linear, 2.75s odometerstop-0 forwards linear 1.75s; }
      #gopoint .container-random .container-odo-digit[data-container='9'].stop[data-stop='1'] {
        animation: 2.75s odometer infinite linear, 2.75s odometerstop-1 forwards linear 1.75s; }
      #gopoint .container-random .container-odo-digit[data-container='9'].stop[data-stop='2'] {
        animation: 2.75s odometer infinite linear, 2.75s odometerstop-2 forwards linear 1.75s; }
      #gopoint .container-random .container-odo-digit[data-container='9'].stop[data-stop='3'] {
        animation: 2.75s odometer infinite linear, 2.75s odometerstop-3 forwards linear 1.75s; }
      #gopoint .container-random .container-odo-digit[data-container='9'].stop[data-stop='4'] {
        animation: 2.75s odometer infinite linear, 2.75s odometerstop-4 forwards linear 1.75s; }
      #gopoint .container-random .container-odo-digit[data-container='9'].stop[data-stop='5'] {
        animation: 2.75s odometer infinite linear, 2.75s odometerstop-5 forwards linear 1.75s; }
      #gopoint .container-random .container-odo-digit[data-container='9'].stop[data-stop='6'] {
        animation: 2.75s odometer infinite linear, 2.75s odometerstop-6 forwards linear 1.75s; }
      #gopoint .container-random .container-odo-digit[data-container='9'].stop[data-stop='7'] {
        animation: 2.75s odometer infinite linear, 2.75s odometerstop-7 forwards linear 1.75s; }
      #gopoint .container-random .container-odo-digit[data-container='9'].stop[data-stop='8'] {
        animation: 2.75s odometer infinite linear, 2.75s odometerstop-8 forwards linear 1.75s; }
      #gopoint .container-random .container-odo-digit[data-container='9'].stop[data-stop='9'] {
        animation: 2.75s odometer infinite linear, 2.75s odometerstop-9 forwards linear 1.75s; }
      #gopoint .container-random .container-odo-digit[data-container='9'].stop[data-stop='10'] {
        animation: 2.75s odometer infinite linear, 2.75s odometerstop-10 forwards linear 1.75s; }
      #gopoint .container-random .container-odo-digit[data-container='9'].stop[data-stop='11'] {
        animation: 2.75s odometer infinite linear, 2.75s odometerstop-11 forwards linear 1.75s; }
      #gopoint .container-random .container-odo-digit[data-container='9'].stop[data-stop='12'] {
        animation: 2.75s odometer infinite linear, 2.75s odometerstop-12 forwards linear 1.75s; }
      #gopoint .container-random .container-odo-digit[data-container='9'].stop[data-stop='13'] {
        animation: 2.75s odometer infinite linear, 2.75s odometerstop-13 forwards linear 1.75s; }
      #gopoint .container-random .container-odo-digit[data-container='9'].stop[data-stop='14'] {
        animation: 2.75s odometer infinite linear, 2.75s odometerstop-14 forwards linear 1.75s; }
      #gopoint .container-random .container-odo-digit[data-container='9'].stop[data-stop='15'] {
        animation: 2.75s odometer infinite linear, 2.75s odometerstop-15 forwards linear 1.75s; }
      #gopoint .container-random .container-odo-digit[data-container='9'].stop[data-stop='16'] {
        animation: 2.75s odometer infinite linear, 2.75s odometerstop-16 forwards linear 1.75s; }
      #gopoint .container-random .container-odo-digit[data-container='9'].stop[data-stop='17'] {
        animation: 2.75s odometer infinite linear, 2.75s odometerstop-17 forwards linear 1.75s; }
      #gopoint .container-random .container-odo-digit[data-container='9'].stop[data-stop='18'] {
        animation: 2.75s odometer infinite linear, 2.75s odometerstop-18 forwards linear 1.75s; }
      #gopoint .container-random .container-odo-digit[data-container='9'].stop[data-stop='19'] {
        animation: 2.75s odometer infinite linear, 2.75s odometerstop-19 forwards linear 1.75s; }
      #gopoint .container-random .container-odo-digit[data-container='9'].stop[data-stop='20'] {
        animation: 2.75s odometer infinite linear, 2.75s odometerstop-20 forwards linear 1.75s; }
      #gopoint .container-random .container-odo-digit[data-container='9'].stop[data-stop='21'] {
        animation: 2.75s odometer infinite linear, 2.75s odometerstop-21 forwards linear 1.75s; }
      #gopoint .container-random .container-odo-digit[data-container='9'].stop[data-stop='22'] {
        animation: 2.75s odometer infinite linear, 2.75s odometerstop-22 forwards linear 1.75s; }
      #gopoint .container-random .container-odo-digit[data-container='9'].stop[data-stop='23'] {
        animation: 2.75s odometer infinite linear, 2.75s odometerstop-23 forwards linear 1.75s; }
      #gopoint .container-random .container-odo-digit[data-container='9'].stop[data-stop='24'] {
        animation: 2.75s odometer infinite linear, 2.75s odometerstop-24 forwards linear 1.75s; }
      #gopoint .container-random .container-odo-digit[data-container='9'].stop[data-stop='25'] {
        animation: 2.75s odometer infinite linear, 2.75s odometerstop-25 forwards linear 1.75s; }
      #gopoint .container-random .container-odo-digit[data-container='9'].stop[data-stop='26'] {
        animation: 2.75s odometer infinite linear, 2.75s odometerstop-26 forwards linear 1.75s; }
      #gopoint .container-random .container-odo-digit[data-container='9'].stop[data-stop='27'] {
        animation: 2.75s odometer infinite linear, 2.75s odometerstop-27 forwards linear 1.75s; }
      #gopoint .container-random .container-odo-digit[data-container='9'].stop[data-stop='28'] {
        animation: 2.75s odometer infinite linear, 2.75s odometerstop-28 forwards linear 1.75s; }
      #gopoint .container-random .container-odo-digit[data-container='9'].stop[data-stop='29'] {
        animation: 2.75s odometer infinite linear, 2.75s odometerstop-29 forwards linear 1.75s; }
      #gopoint .container-random .container-odo-digit[data-container='9'].stop[data-stop='30'] {
        animation: 2.75s odometer infinite linear, 2.75s odometerstop-30 forwards linear 1.75s; }
      #gopoint .container-random .container-odo-digit[data-container='9'].stop[data-stop='31'] {
        animation: 2.75s odometer infinite linear, 2.75s odometerstop-31 forwards linear 1.75s; }
      #gopoint .container-random .container-odo-digit[data-container='9'].stop[data-stop='32'] {
        animation: 2.75s odometer infinite linear, 2.75s odometerstop-32 forwards linear 1.75s; }
      #gopoint .container-random .container-odo-digit[data-container='9'].stop[data-stop='33'] {
        animation: 2.75s odometer infinite linear, 2.75s odometerstop-33 forwards linear 1.75s; }
      #gopoint .container-random .container-odo-digit[data-container='9'].stop[data-stop='34'] {
        animation: 2.75s odometer infinite linear, 2.75s odometerstop-34 forwards linear 1.75s; }
      #gopoint .container-random .container-odo-digit[data-container='9'].stop[data-stop='35'] {
        animation: 2.75s odometer infinite linear, 2.75s odometerstop-35 forwards linear 1.75s; }
      #gopoint .container-random .container-odo-digit[data-container='9'].stop[data-stop='36'] {
        animation: 2.75s odometer infinite linear, 2.75s odometerstop-36 forwards linear 1.75s; }
    #gopoint .container-random .container-odo-digit[data-container='10'] {
      animation: 0.55s odometer infinite linear; }
      #gopoint .container-random .container-odo-digit[data-container='10'].stop[data-stop='0'] {
        animation: 3s odometer infinite linear, 3s odometerstop-0 forwards linear 2s; }
      #gopoint .container-random .container-odo-digit[data-container='10'].stop[data-stop='1'] {
        animation: 3s odometer infinite linear, 3s odometerstop-1 forwards linear 2s; }
      #gopoint .container-random .container-odo-digit[data-container='10'].stop[data-stop='2'] {
        animation: 3s odometer infinite linear, 3s odometerstop-2 forwards linear 2s; }
      #gopoint .container-random .container-odo-digit[data-container='10'].stop[data-stop='3'] {
        animation: 3s odometer infinite linear, 3s odometerstop-3 forwards linear 2s; }
      #gopoint .container-random .container-odo-digit[data-container='10'].stop[data-stop='4'] {
        animation: 3s odometer infinite linear, 3s odometerstop-4 forwards linear 2s; }
      #gopoint .container-random .container-odo-digit[data-container='10'].stop[data-stop='5'] {
        animation: 3s odometer infinite linear, 3s odometerstop-5 forwards linear 2s; }
      #gopoint .container-random .container-odo-digit[data-container='10'].stop[data-stop='6'] {
        animation: 3s odometer infinite linear, 3s odometerstop-6 forwards linear 2s; }
      #gopoint .container-random .container-odo-digit[data-container='10'].stop[data-stop='7'] {
        animation: 3s odometer infinite linear, 3s odometerstop-7 forwards linear 2s; }
      #gopoint .container-random .container-odo-digit[data-container='10'].stop[data-stop='8'] {
        animation: 3s odometer infinite linear, 3s odometerstop-8 forwards linear 2s; }
      #gopoint .container-random .container-odo-digit[data-container='10'].stop[data-stop='9'] {
        animation: 3s odometer infinite linear, 3s odometerstop-9 forwards linear 2s; }
      #gopoint .container-random .container-odo-digit[data-container='10'].stop[data-stop='10'] {
        animation: 3s odometer infinite linear, 3s odometerstop-10 forwards linear 2s; }
      #gopoint .container-random .container-odo-digit[data-container='10'].stop[data-stop='11'] {
        animation: 3s odometer infinite linear, 3s odometerstop-11 forwards linear 2s; }
      #gopoint .container-random .container-odo-digit[data-container='10'].stop[data-stop='12'] {
        animation: 3s odometer infinite linear, 3s odometerstop-12 forwards linear 2s; }
      #gopoint .container-random .container-odo-digit[data-container='10'].stop[data-stop='13'] {
        animation: 3s odometer infinite linear, 3s odometerstop-13 forwards linear 2s; }
      #gopoint .container-random .container-odo-digit[data-container='10'].stop[data-stop='14'] {
        animation: 3s odometer infinite linear, 3s odometerstop-14 forwards linear 2s; }
      #gopoint .container-random .container-odo-digit[data-container='10'].stop[data-stop='15'] {
        animation: 3s odometer infinite linear, 3s odometerstop-15 forwards linear 2s; }
      #gopoint .container-random .container-odo-digit[data-container='10'].stop[data-stop='16'] {
        animation: 3s odometer infinite linear, 3s odometerstop-16 forwards linear 2s; }
      #gopoint .container-random .container-odo-digit[data-container='10'].stop[data-stop='17'] {
        animation: 3s odometer infinite linear, 3s odometerstop-17 forwards linear 2s; }
      #gopoint .container-random .container-odo-digit[data-container='10'].stop[data-stop='18'] {
        animation: 3s odometer infinite linear, 3s odometerstop-18 forwards linear 2s; }
      #gopoint .container-random .container-odo-digit[data-container='10'].stop[data-stop='19'] {
        animation: 3s odometer infinite linear, 3s odometerstop-19 forwards linear 2s; }
      #gopoint .container-random .container-odo-digit[data-container='10'].stop[data-stop='20'] {
        animation: 3s odometer infinite linear, 3s odometerstop-20 forwards linear 2s; }
      #gopoint .container-random .container-odo-digit[data-container='10'].stop[data-stop='21'] {
        animation: 3s odometer infinite linear, 3s odometerstop-21 forwards linear 2s; }
      #gopoint .container-random .container-odo-digit[data-container='10'].stop[data-stop='22'] {
        animation: 3s odometer infinite linear, 3s odometerstop-22 forwards linear 2s; }
      #gopoint .container-random .container-odo-digit[data-container='10'].stop[data-stop='23'] {
        animation: 3s odometer infinite linear, 3s odometerstop-23 forwards linear 2s; }
      #gopoint .container-random .container-odo-digit[data-container='10'].stop[data-stop='24'] {
        animation: 3s odometer infinite linear, 3s odometerstop-24 forwards linear 2s; }
      #gopoint .container-random .container-odo-digit[data-container='10'].stop[data-stop='25'] {
        animation: 3s odometer infinite linear, 3s odometerstop-25 forwards linear 2s; }
      #gopoint .container-random .container-odo-digit[data-container='10'].stop[data-stop='26'] {
        animation: 3s odometer infinite linear, 3s odometerstop-26 forwards linear 2s; }
      #gopoint .container-random .container-odo-digit[data-container='10'].stop[data-stop='27'] {
        animation: 3s odometer infinite linear, 3s odometerstop-27 forwards linear 2s; }
      #gopoint .container-random .container-odo-digit[data-container='10'].stop[data-stop='28'] {
        animation: 3s odometer infinite linear, 3s odometerstop-28 forwards linear 2s; }
      #gopoint .container-random .container-odo-digit[data-container='10'].stop[data-stop='29'] {
        animation: 3s odometer infinite linear, 3s odometerstop-29 forwards linear 2s; }
      #gopoint .container-random .container-odo-digit[data-container='10'].stop[data-stop='30'] {
        animation: 3s odometer infinite linear, 3s odometerstop-30 forwards linear 2s; }
      #gopoint .container-random .container-odo-digit[data-container='10'].stop[data-stop='31'] {
        animation: 3s odometer infinite linear, 3s odometerstop-31 forwards linear 2s; }
      #gopoint .container-random .container-odo-digit[data-container='10'].stop[data-stop='32'] {
        animation: 3s odometer infinite linear, 3s odometerstop-32 forwards linear 2s; }
      #gopoint .container-random .container-odo-digit[data-container='10'].stop[data-stop='33'] {
        animation: 3s odometer infinite linear, 3s odometerstop-33 forwards linear 2s; }
      #gopoint .container-random .container-odo-digit[data-container='10'].stop[data-stop='34'] {
        animation: 3s odometer infinite linear, 3s odometerstop-34 forwards linear 2s; }
      #gopoint .container-random .container-odo-digit[data-container='10'].stop[data-stop='35'] {
        animation: 3s odometer infinite linear, 3s odometerstop-35 forwards linear 2s; }
      #gopoint .container-random .container-odo-digit[data-container='10'].stop[data-stop='36'] {
        animation: 3s odometer infinite linear, 3s odometerstop-36 forwards linear 2s; }
    #gopoint .container-random .container-odo-digit[data-container='11'] {
      animation: 0.55s odometer infinite linear; }
      #gopoint .container-random .container-odo-digit[data-container='11'].stop[data-stop='0'] {
        animation: 3.25s odometer infinite linear, 3.25s odometerstop-0 forwards linear 2.25s; }
      #gopoint .container-random .container-odo-digit[data-container='11'].stop[data-stop='1'] {
        animation: 3.25s odometer infinite linear, 3.25s odometerstop-1 forwards linear 2.25s; }
      #gopoint .container-random .container-odo-digit[data-container='11'].stop[data-stop='2'] {
        animation: 3.25s odometer infinite linear, 3.25s odometerstop-2 forwards linear 2.25s; }
      #gopoint .container-random .container-odo-digit[data-container='11'].stop[data-stop='3'] {
        animation: 3.25s odometer infinite linear, 3.25s odometerstop-3 forwards linear 2.25s; }
      #gopoint .container-random .container-odo-digit[data-container='11'].stop[data-stop='4'] {
        animation: 3.25s odometer infinite linear, 3.25s odometerstop-4 forwards linear 2.25s; }
      #gopoint .container-random .container-odo-digit[data-container='11'].stop[data-stop='5'] {
        animation: 3.25s odometer infinite linear, 3.25s odometerstop-5 forwards linear 2.25s; }
      #gopoint .container-random .container-odo-digit[data-container='11'].stop[data-stop='6'] {
        animation: 3.25s odometer infinite linear, 3.25s odometerstop-6 forwards linear 2.25s; }
      #gopoint .container-random .container-odo-digit[data-container='11'].stop[data-stop='7'] {
        animation: 3.25s odometer infinite linear, 3.25s odometerstop-7 forwards linear 2.25s; }
      #gopoint .container-random .container-odo-digit[data-container='11'].stop[data-stop='8'] {
        animation: 3.25s odometer infinite linear, 3.25s odometerstop-8 forwards linear 2.25s; }
      #gopoint .container-random .container-odo-digit[data-container='11'].stop[data-stop='9'] {
        animation: 3.25s odometer infinite linear, 3.25s odometerstop-9 forwards linear 2.25s; }
      #gopoint .container-random .container-odo-digit[data-container='11'].stop[data-stop='10'] {
        animation: 3.25s odometer infinite linear, 3.25s odometerstop-10 forwards linear 2.25s; }
      #gopoint .container-random .container-odo-digit[data-container='11'].stop[data-stop='11'] {
        animation: 3.25s odometer infinite linear, 3.25s odometerstop-11 forwards linear 2.25s; }
      #gopoint .container-random .container-odo-digit[data-container='11'].stop[data-stop='12'] {
        animation: 3.25s odometer infinite linear, 3.25s odometerstop-12 forwards linear 2.25s; }
      #gopoint .container-random .container-odo-digit[data-container='11'].stop[data-stop='13'] {
        animation: 3.25s odometer infinite linear, 3.25s odometerstop-13 forwards linear 2.25s; }
      #gopoint .container-random .container-odo-digit[data-container='11'].stop[data-stop='14'] {
        animation: 3.25s odometer infinite linear, 3.25s odometerstop-14 forwards linear 2.25s; }
      #gopoint .container-random .container-odo-digit[data-container='11'].stop[data-stop='15'] {
        animation: 3.25s odometer infinite linear, 3.25s odometerstop-15 forwards linear 2.25s; }
      #gopoint .container-random .container-odo-digit[data-container='11'].stop[data-stop='16'] {
        animation: 3.25s odometer infinite linear, 3.25s odometerstop-16 forwards linear 2.25s; }
      #gopoint .container-random .container-odo-digit[data-container='11'].stop[data-stop='17'] {
        animation: 3.25s odometer infinite linear, 3.25s odometerstop-17 forwards linear 2.25s; }
      #gopoint .container-random .container-odo-digit[data-container='11'].stop[data-stop='18'] {
        animation: 3.25s odometer infinite linear, 3.25s odometerstop-18 forwards linear 2.25s; }
      #gopoint .container-random .container-odo-digit[data-container='11'].stop[data-stop='19'] {
        animation: 3.25s odometer infinite linear, 3.25s odometerstop-19 forwards linear 2.25s; }
      #gopoint .container-random .container-odo-digit[data-container='11'].stop[data-stop='20'] {
        animation: 3.25s odometer infinite linear, 3.25s odometerstop-20 forwards linear 2.25s; }
      #gopoint .container-random .container-odo-digit[data-container='11'].stop[data-stop='21'] {
        animation: 3.25s odometer infinite linear, 3.25s odometerstop-21 forwards linear 2.25s; }
      #gopoint .container-random .container-odo-digit[data-container='11'].stop[data-stop='22'] {
        animation: 3.25s odometer infinite linear, 3.25s odometerstop-22 forwards linear 2.25s; }
      #gopoint .container-random .container-odo-digit[data-container='11'].stop[data-stop='23'] {
        animation: 3.25s odometer infinite linear, 3.25s odometerstop-23 forwards linear 2.25s; }
      #gopoint .container-random .container-odo-digit[data-container='11'].stop[data-stop='24'] {
        animation: 3.25s odometer infinite linear, 3.25s odometerstop-24 forwards linear 2.25s; }
      #gopoint .container-random .container-odo-digit[data-container='11'].stop[data-stop='25'] {
        animation: 3.25s odometer infinite linear, 3.25s odometerstop-25 forwards linear 2.25s; }
      #gopoint .container-random .container-odo-digit[data-container='11'].stop[data-stop='26'] {
        animation: 3.25s odometer infinite linear, 3.25s odometerstop-26 forwards linear 2.25s; }
      #gopoint .container-random .container-odo-digit[data-container='11'].stop[data-stop='27'] {
        animation: 3.25s odometer infinite linear, 3.25s odometerstop-27 forwards linear 2.25s; }
      #gopoint .container-random .container-odo-digit[data-container='11'].stop[data-stop='28'] {
        animation: 3.25s odometer infinite linear, 3.25s odometerstop-28 forwards linear 2.25s; }
      #gopoint .container-random .container-odo-digit[data-container='11'].stop[data-stop='29'] {
        animation: 3.25s odometer infinite linear, 3.25s odometerstop-29 forwards linear 2.25s; }
      #gopoint .container-random .container-odo-digit[data-container='11'].stop[data-stop='30'] {
        animation: 3.25s odometer infinite linear, 3.25s odometerstop-30 forwards linear 2.25s; }
      #gopoint .container-random .container-odo-digit[data-container='11'].stop[data-stop='31'] {
        animation: 3.25s odometer infinite linear, 3.25s odometerstop-31 forwards linear 2.25s; }
      #gopoint .container-random .container-odo-digit[data-container='11'].stop[data-stop='32'] {
        animation: 3.25s odometer infinite linear, 3.25s odometerstop-32 forwards linear 2.25s; }
      #gopoint .container-random .container-odo-digit[data-container='11'].stop[data-stop='33'] {
        animation: 3.25s odometer infinite linear, 3.25s odometerstop-33 forwards linear 2.25s; }
      #gopoint .container-random .container-odo-digit[data-container='11'].stop[data-stop='34'] {
        animation: 3.25s odometer infinite linear, 3.25s odometerstop-34 forwards linear 2.25s; }
      #gopoint .container-random .container-odo-digit[data-container='11'].stop[data-stop='35'] {
        animation: 3.25s odometer infinite linear, 3.25s odometerstop-35 forwards linear 2.25s; }
      #gopoint .container-random .container-odo-digit[data-container='11'].stop[data-stop='36'] {
        animation: 3.25s odometer infinite linear, 3.25s odometerstop-36 forwards linear 2.25s; }
    #gopoint .container-random .container-odo-digit[data-container='12'] {
      animation: 0.55s odometer infinite linear; }
      #gopoint .container-random .container-odo-digit[data-container='12'].stop[data-stop='0'] {
        animation: 3.5s odometer infinite linear, 3.5s odometerstop-0 forwards linear 2.5s; }
      #gopoint .container-random .container-odo-digit[data-container='12'].stop[data-stop='1'] {
        animation: 3.5s odometer infinite linear, 3.5s odometerstop-1 forwards linear 2.5s; }
      #gopoint .container-random .container-odo-digit[data-container='12'].stop[data-stop='2'] {
        animation: 3.5s odometer infinite linear, 3.5s odometerstop-2 forwards linear 2.5s; }
      #gopoint .container-random .container-odo-digit[data-container='12'].stop[data-stop='3'] {
        animation: 3.5s odometer infinite linear, 3.5s odometerstop-3 forwards linear 2.5s; }
      #gopoint .container-random .container-odo-digit[data-container='12'].stop[data-stop='4'] {
        animation: 3.5s odometer infinite linear, 3.5s odometerstop-4 forwards linear 2.5s; }
      #gopoint .container-random .container-odo-digit[data-container='12'].stop[data-stop='5'] {
        animation: 3.5s odometer infinite linear, 3.5s odometerstop-5 forwards linear 2.5s; }
      #gopoint .container-random .container-odo-digit[data-container='12'].stop[data-stop='6'] {
        animation: 3.5s odometer infinite linear, 3.5s odometerstop-6 forwards linear 2.5s; }
      #gopoint .container-random .container-odo-digit[data-container='12'].stop[data-stop='7'] {
        animation: 3.5s odometer infinite linear, 3.5s odometerstop-7 forwards linear 2.5s; }
      #gopoint .container-random .container-odo-digit[data-container='12'].stop[data-stop='8'] {
        animation: 3.5s odometer infinite linear, 3.5s odometerstop-8 forwards linear 2.5s; }
      #gopoint .container-random .container-odo-digit[data-container='12'].stop[data-stop='9'] {
        animation: 3.5s odometer infinite linear, 3.5s odometerstop-9 forwards linear 2.5s; }
      #gopoint .container-random .container-odo-digit[data-container='12'].stop[data-stop='10'] {
        animation: 3.5s odometer infinite linear, 3.5s odometerstop-10 forwards linear 2.5s; }
      #gopoint .container-random .container-odo-digit[data-container='12'].stop[data-stop='11'] {
        animation: 3.5s odometer infinite linear, 3.5s odometerstop-11 forwards linear 2.5s; }
      #gopoint .container-random .container-odo-digit[data-container='12'].stop[data-stop='12'] {
        animation: 3.5s odometer infinite linear, 3.5s odometerstop-12 forwards linear 2.5s; }
      #gopoint .container-random .container-odo-digit[data-container='12'].stop[data-stop='13'] {
        animation: 3.5s odometer infinite linear, 3.5s odometerstop-13 forwards linear 2.5s; }
      #gopoint .container-random .container-odo-digit[data-container='12'].stop[data-stop='14'] {
        animation: 3.5s odometer infinite linear, 3.5s odometerstop-14 forwards linear 2.5s; }
      #gopoint .container-random .container-odo-digit[data-container='12'].stop[data-stop='15'] {
        animation: 3.5s odometer infinite linear, 3.5s odometerstop-15 forwards linear 2.5s; }
      #gopoint .container-random .container-odo-digit[data-container='12'].stop[data-stop='16'] {
        animation: 3.5s odometer infinite linear, 3.5s odometerstop-16 forwards linear 2.5s; }
      #gopoint .container-random .container-odo-digit[data-container='12'].stop[data-stop='17'] {
        animation: 3.5s odometer infinite linear, 3.5s odometerstop-17 forwards linear 2.5s; }
      #gopoint .container-random .container-odo-digit[data-container='12'].stop[data-stop='18'] {
        animation: 3.5s odometer infinite linear, 3.5s odometerstop-18 forwards linear 2.5s; }
      #gopoint .container-random .container-odo-digit[data-container='12'].stop[data-stop='19'] {
        animation: 3.5s odometer infinite linear, 3.5s odometerstop-19 forwards linear 2.5s; }
      #gopoint .container-random .container-odo-digit[data-container='12'].stop[data-stop='20'] {
        animation: 3.5s odometer infinite linear, 3.5s odometerstop-20 forwards linear 2.5s; }
      #gopoint .container-random .container-odo-digit[data-container='12'].stop[data-stop='21'] {
        animation: 3.5s odometer infinite linear, 3.5s odometerstop-21 forwards linear 2.5s; }
      #gopoint .container-random .container-odo-digit[data-container='12'].stop[data-stop='22'] {
        animation: 3.5s odometer infinite linear, 3.5s odometerstop-22 forwards linear 2.5s; }
      #gopoint .container-random .container-odo-digit[data-container='12'].stop[data-stop='23'] {
        animation: 3.5s odometer infinite linear, 3.5s odometerstop-23 forwards linear 2.5s; }
      #gopoint .container-random .container-odo-digit[data-container='12'].stop[data-stop='24'] {
        animation: 3.5s odometer infinite linear, 3.5s odometerstop-24 forwards linear 2.5s; }
      #gopoint .container-random .container-odo-digit[data-container='12'].stop[data-stop='25'] {
        animation: 3.5s odometer infinite linear, 3.5s odometerstop-25 forwards linear 2.5s; }
      #gopoint .container-random .container-odo-digit[data-container='12'].stop[data-stop='26'] {
        animation: 3.5s odometer infinite linear, 3.5s odometerstop-26 forwards linear 2.5s; }
      #gopoint .container-random .container-odo-digit[data-container='12'].stop[data-stop='27'] {
        animation: 3.5s odometer infinite linear, 3.5s odometerstop-27 forwards linear 2.5s; }
      #gopoint .container-random .container-odo-digit[data-container='12'].stop[data-stop='28'] {
        animation: 3.5s odometer infinite linear, 3.5s odometerstop-28 forwards linear 2.5s; }
      #gopoint .container-random .container-odo-digit[data-container='12'].stop[data-stop='29'] {
        animation: 3.5s odometer infinite linear, 3.5s odometerstop-29 forwards linear 2.5s; }
      #gopoint .container-random .container-odo-digit[data-container='12'].stop[data-stop='30'] {
        animation: 3.5s odometer infinite linear, 3.5s odometerstop-30 forwards linear 2.5s; }
      #gopoint .container-random .container-odo-digit[data-container='12'].stop[data-stop='31'] {
        animation: 3.5s odometer infinite linear, 3.5s odometerstop-31 forwards linear 2.5s; }
      #gopoint .container-random .container-odo-digit[data-container='12'].stop[data-stop='32'] {
        animation: 3.5s odometer infinite linear, 3.5s odometerstop-32 forwards linear 2.5s; }
      #gopoint .container-random .container-odo-digit[data-container='12'].stop[data-stop='33'] {
        animation: 3.5s odometer infinite linear, 3.5s odometerstop-33 forwards linear 2.5s; }
      #gopoint .container-random .container-odo-digit[data-container='12'].stop[data-stop='34'] {
        animation: 3.5s odometer infinite linear, 3.5s odometerstop-34 forwards linear 2.5s; }
      #gopoint .container-random .container-odo-digit[data-container='12'].stop[data-stop='35'] {
        animation: 3.5s odometer infinite linear, 3.5s odometerstop-35 forwards linear 2.5s; }
      #gopoint .container-random .container-odo-digit[data-container='12'].stop[data-stop='36'] {
        animation: 3.5s odometer infinite linear, 3.5s odometerstop-36 forwards linear 2.5s; }
    #gopoint .container-random .container-odo-digit[data-container='13'] {
      animation: 0.55s odometer infinite linear; }
      #gopoint .container-random .container-odo-digit[data-container='13'].stop[data-stop='0'] {
        animation: 3.75s odometer infinite linear, 3.75s odometerstop-0 forwards linear 2.75s; }
      #gopoint .container-random .container-odo-digit[data-container='13'].stop[data-stop='1'] {
        animation: 3.75s odometer infinite linear, 3.75s odometerstop-1 forwards linear 2.75s; }
      #gopoint .container-random .container-odo-digit[data-container='13'].stop[data-stop='2'] {
        animation: 3.75s odometer infinite linear, 3.75s odometerstop-2 forwards linear 2.75s; }
      #gopoint .container-random .container-odo-digit[data-container='13'].stop[data-stop='3'] {
        animation: 3.75s odometer infinite linear, 3.75s odometerstop-3 forwards linear 2.75s; }
      #gopoint .container-random .container-odo-digit[data-container='13'].stop[data-stop='4'] {
        animation: 3.75s odometer infinite linear, 3.75s odometerstop-4 forwards linear 2.75s; }
      #gopoint .container-random .container-odo-digit[data-container='13'].stop[data-stop='5'] {
        animation: 3.75s odometer infinite linear, 3.75s odometerstop-5 forwards linear 2.75s; }
      #gopoint .container-random .container-odo-digit[data-container='13'].stop[data-stop='6'] {
        animation: 3.75s odometer infinite linear, 3.75s odometerstop-6 forwards linear 2.75s; }
      #gopoint .container-random .container-odo-digit[data-container='13'].stop[data-stop='7'] {
        animation: 3.75s odometer infinite linear, 3.75s odometerstop-7 forwards linear 2.75s; }
      #gopoint .container-random .container-odo-digit[data-container='13'].stop[data-stop='8'] {
        animation: 3.75s odometer infinite linear, 3.75s odometerstop-8 forwards linear 2.75s; }
      #gopoint .container-random .container-odo-digit[data-container='13'].stop[data-stop='9'] {
        animation: 3.75s odometer infinite linear, 3.75s odometerstop-9 forwards linear 2.75s; }
      #gopoint .container-random .container-odo-digit[data-container='13'].stop[data-stop='10'] {
        animation: 3.75s odometer infinite linear, 3.75s odometerstop-10 forwards linear 2.75s; }
      #gopoint .container-random .container-odo-digit[data-container='13'].stop[data-stop='11'] {
        animation: 3.75s odometer infinite linear, 3.75s odometerstop-11 forwards linear 2.75s; }
      #gopoint .container-random .container-odo-digit[data-container='13'].stop[data-stop='12'] {
        animation: 3.75s odometer infinite linear, 3.75s odometerstop-12 forwards linear 2.75s; }
      #gopoint .container-random .container-odo-digit[data-container='13'].stop[data-stop='13'] {
        animation: 3.75s odometer infinite linear, 3.75s odometerstop-13 forwards linear 2.75s; }
      #gopoint .container-random .container-odo-digit[data-container='13'].stop[data-stop='14'] {
        animation: 3.75s odometer infinite linear, 3.75s odometerstop-14 forwards linear 2.75s; }
      #gopoint .container-random .container-odo-digit[data-container='13'].stop[data-stop='15'] {
        animation: 3.75s odometer infinite linear, 3.75s odometerstop-15 forwards linear 2.75s; }
      #gopoint .container-random .container-odo-digit[data-container='13'].stop[data-stop='16'] {
        animation: 3.75s odometer infinite linear, 3.75s odometerstop-16 forwards linear 2.75s; }
      #gopoint .container-random .container-odo-digit[data-container='13'].stop[data-stop='17'] {
        animation: 3.75s odometer infinite linear, 3.75s odometerstop-17 forwards linear 2.75s; }
      #gopoint .container-random .container-odo-digit[data-container='13'].stop[data-stop='18'] {
        animation: 3.75s odometer infinite linear, 3.75s odometerstop-18 forwards linear 2.75s; }
      #gopoint .container-random .container-odo-digit[data-container='13'].stop[data-stop='19'] {
        animation: 3.75s odometer infinite linear, 3.75s odometerstop-19 forwards linear 2.75s; }
      #gopoint .container-random .container-odo-digit[data-container='13'].stop[data-stop='20'] {
        animation: 3.75s odometer infinite linear, 3.75s odometerstop-20 forwards linear 2.75s; }
      #gopoint .container-random .container-odo-digit[data-container='13'].stop[data-stop='21'] {
        animation: 3.75s odometer infinite linear, 3.75s odometerstop-21 forwards linear 2.75s; }
      #gopoint .container-random .container-odo-digit[data-container='13'].stop[data-stop='22'] {
        animation: 3.75s odometer infinite linear, 3.75s odometerstop-22 forwards linear 2.75s; }
      #gopoint .container-random .container-odo-digit[data-container='13'].stop[data-stop='23'] {
        animation: 3.75s odometer infinite linear, 3.75s odometerstop-23 forwards linear 2.75s; }
      #gopoint .container-random .container-odo-digit[data-container='13'].stop[data-stop='24'] {
        animation: 3.75s odometer infinite linear, 3.75s odometerstop-24 forwards linear 2.75s; }
      #gopoint .container-random .container-odo-digit[data-container='13'].stop[data-stop='25'] {
        animation: 3.75s odometer infinite linear, 3.75s odometerstop-25 forwards linear 2.75s; }
      #gopoint .container-random .container-odo-digit[data-container='13'].stop[data-stop='26'] {
        animation: 3.75s odometer infinite linear, 3.75s odometerstop-26 forwards linear 2.75s; }
      #gopoint .container-random .container-odo-digit[data-container='13'].stop[data-stop='27'] {
        animation: 3.75s odometer infinite linear, 3.75s odometerstop-27 forwards linear 2.75s; }
      #gopoint .container-random .container-odo-digit[data-container='13'].stop[data-stop='28'] {
        animation: 3.75s odometer infinite linear, 3.75s odometerstop-28 forwards linear 2.75s; }
      #gopoint .container-random .container-odo-digit[data-container='13'].stop[data-stop='29'] {
        animation: 3.75s odometer infinite linear, 3.75s odometerstop-29 forwards linear 2.75s; }
      #gopoint .container-random .container-odo-digit[data-container='13'].stop[data-stop='30'] {
        animation: 3.75s odometer infinite linear, 3.75s odometerstop-30 forwards linear 2.75s; }
      #gopoint .container-random .container-odo-digit[data-container='13'].stop[data-stop='31'] {
        animation: 3.75s odometer infinite linear, 3.75s odometerstop-31 forwards linear 2.75s; }
      #gopoint .container-random .container-odo-digit[data-container='13'].stop[data-stop='32'] {
        animation: 3.75s odometer infinite linear, 3.75s odometerstop-32 forwards linear 2.75s; }
      #gopoint .container-random .container-odo-digit[data-container='13'].stop[data-stop='33'] {
        animation: 3.75s odometer infinite linear, 3.75s odometerstop-33 forwards linear 2.75s; }
      #gopoint .container-random .container-odo-digit[data-container='13'].stop[data-stop='34'] {
        animation: 3.75s odometer infinite linear, 3.75s odometerstop-34 forwards linear 2.75s; }
      #gopoint .container-random .container-odo-digit[data-container='13'].stop[data-stop='35'] {
        animation: 3.75s odometer infinite linear, 3.75s odometerstop-35 forwards linear 2.75s; }
      #gopoint .container-random .container-odo-digit[data-container='13'].stop[data-stop='36'] {
        animation: 3.75s odometer infinite linear, 3.75s odometerstop-36 forwards linear 2.75s; }
    #gopoint .container-random .container-odo-digit[data-container='14'] {
      animation: 0.55s odometer infinite linear; }
      #gopoint .container-random .container-odo-digit[data-container='14'].stop[data-stop='0'] {
        animation: 4s odometer infinite linear, 4s odometerstop-0 forwards linear 3s; }
      #gopoint .container-random .container-odo-digit[data-container='14'].stop[data-stop='1'] {
        animation: 4s odometer infinite linear, 4s odometerstop-1 forwards linear 3s; }
      #gopoint .container-random .container-odo-digit[data-container='14'].stop[data-stop='2'] {
        animation: 4s odometer infinite linear, 4s odometerstop-2 forwards linear 3s; }
      #gopoint .container-random .container-odo-digit[data-container='14'].stop[data-stop='3'] {
        animation: 4s odometer infinite linear, 4s odometerstop-3 forwards linear 3s; }
      #gopoint .container-random .container-odo-digit[data-container='14'].stop[data-stop='4'] {
        animation: 4s odometer infinite linear, 4s odometerstop-4 forwards linear 3s; }
      #gopoint .container-random .container-odo-digit[data-container='14'].stop[data-stop='5'] {
        animation: 4s odometer infinite linear, 4s odometerstop-5 forwards linear 3s; }
      #gopoint .container-random .container-odo-digit[data-container='14'].stop[data-stop='6'] {
        animation: 4s odometer infinite linear, 4s odometerstop-6 forwards linear 3s; }
      #gopoint .container-random .container-odo-digit[data-container='14'].stop[data-stop='7'] {
        animation: 4s odometer infinite linear, 4s odometerstop-7 forwards linear 3s; }
      #gopoint .container-random .container-odo-digit[data-container='14'].stop[data-stop='8'] {
        animation: 4s odometer infinite linear, 4s odometerstop-8 forwards linear 3s; }
      #gopoint .container-random .container-odo-digit[data-container='14'].stop[data-stop='9'] {
        animation: 4s odometer infinite linear, 4s odometerstop-9 forwards linear 3s; }
      #gopoint .container-random .container-odo-digit[data-container='14'].stop[data-stop='10'] {
        animation: 4s odometer infinite linear, 4s odometerstop-10 forwards linear 3s; }
      #gopoint .container-random .container-odo-digit[data-container='14'].stop[data-stop='11'] {
        animation: 4s odometer infinite linear, 4s odometerstop-11 forwards linear 3s; }
      #gopoint .container-random .container-odo-digit[data-container='14'].stop[data-stop='12'] {
        animation: 4s odometer infinite linear, 4s odometerstop-12 forwards linear 3s; }
      #gopoint .container-random .container-odo-digit[data-container='14'].stop[data-stop='13'] {
        animation: 4s odometer infinite linear, 4s odometerstop-13 forwards linear 3s; }
      #gopoint .container-random .container-odo-digit[data-container='14'].stop[data-stop='14'] {
        animation: 4s odometer infinite linear, 4s odometerstop-14 forwards linear 3s; }
      #gopoint .container-random .container-odo-digit[data-container='14'].stop[data-stop='15'] {
        animation: 4s odometer infinite linear, 4s odometerstop-15 forwards linear 3s; }
      #gopoint .container-random .container-odo-digit[data-container='14'].stop[data-stop='16'] {
        animation: 4s odometer infinite linear, 4s odometerstop-16 forwards linear 3s; }
      #gopoint .container-random .container-odo-digit[data-container='14'].stop[data-stop='17'] {
        animation: 4s odometer infinite linear, 4s odometerstop-17 forwards linear 3s; }
      #gopoint .container-random .container-odo-digit[data-container='14'].stop[data-stop='18'] {
        animation: 4s odometer infinite linear, 4s odometerstop-18 forwards linear 3s; }
      #gopoint .container-random .container-odo-digit[data-container='14'].stop[data-stop='19'] {
        animation: 4s odometer infinite linear, 4s odometerstop-19 forwards linear 3s; }
      #gopoint .container-random .container-odo-digit[data-container='14'].stop[data-stop='20'] {
        animation: 4s odometer infinite linear, 4s odometerstop-20 forwards linear 3s; }
      #gopoint .container-random .container-odo-digit[data-container='14'].stop[data-stop='21'] {
        animation: 4s odometer infinite linear, 4s odometerstop-21 forwards linear 3s; }
      #gopoint .container-random .container-odo-digit[data-container='14'].stop[data-stop='22'] {
        animation: 4s odometer infinite linear, 4s odometerstop-22 forwards linear 3s; }
      #gopoint .container-random .container-odo-digit[data-container='14'].stop[data-stop='23'] {
        animation: 4s odometer infinite linear, 4s odometerstop-23 forwards linear 3s; }
      #gopoint .container-random .container-odo-digit[data-container='14'].stop[data-stop='24'] {
        animation: 4s odometer infinite linear, 4s odometerstop-24 forwards linear 3s; }
      #gopoint .container-random .container-odo-digit[data-container='14'].stop[data-stop='25'] {
        animation: 4s odometer infinite linear, 4s odometerstop-25 forwards linear 3s; }
      #gopoint .container-random .container-odo-digit[data-container='14'].stop[data-stop='26'] {
        animation: 4s odometer infinite linear, 4s odometerstop-26 forwards linear 3s; }
      #gopoint .container-random .container-odo-digit[data-container='14'].stop[data-stop='27'] {
        animation: 4s odometer infinite linear, 4s odometerstop-27 forwards linear 3s; }
      #gopoint .container-random .container-odo-digit[data-container='14'].stop[data-stop='28'] {
        animation: 4s odometer infinite linear, 4s odometerstop-28 forwards linear 3s; }
      #gopoint .container-random .container-odo-digit[data-container='14'].stop[data-stop='29'] {
        animation: 4s odometer infinite linear, 4s odometerstop-29 forwards linear 3s; }
      #gopoint .container-random .container-odo-digit[data-container='14'].stop[data-stop='30'] {
        animation: 4s odometer infinite linear, 4s odometerstop-30 forwards linear 3s; }
      #gopoint .container-random .container-odo-digit[data-container='14'].stop[data-stop='31'] {
        animation: 4s odometer infinite linear, 4s odometerstop-31 forwards linear 3s; }
      #gopoint .container-random .container-odo-digit[data-container='14'].stop[data-stop='32'] {
        animation: 4s odometer infinite linear, 4s odometerstop-32 forwards linear 3s; }
      #gopoint .container-random .container-odo-digit[data-container='14'].stop[data-stop='33'] {
        animation: 4s odometer infinite linear, 4s odometerstop-33 forwards linear 3s; }
      #gopoint .container-random .container-odo-digit[data-container='14'].stop[data-stop='34'] {
        animation: 4s odometer infinite linear, 4s odometerstop-34 forwards linear 3s; }
      #gopoint .container-random .container-odo-digit[data-container='14'].stop[data-stop='35'] {
        animation: 4s odometer infinite linear, 4s odometerstop-35 forwards linear 3s; }
      #gopoint .container-random .container-odo-digit[data-container='14'].stop[data-stop='36'] {
        animation: 4s odometer infinite linear, 4s odometerstop-36 forwards linear 3s; }
    #gopoint .container-random .container-odo-digit[data-container='15'] {
      animation: 0.55s odometer infinite linear; }
      #gopoint .container-random .container-odo-digit[data-container='15'].stop[data-stop='0'] {
        animation: 4.25s odometer infinite linear, 4.25s odometerstop-0 forwards linear 3.25s; }
      #gopoint .container-random .container-odo-digit[data-container='15'].stop[data-stop='1'] {
        animation: 4.25s odometer infinite linear, 4.25s odometerstop-1 forwards linear 3.25s; }
      #gopoint .container-random .container-odo-digit[data-container='15'].stop[data-stop='2'] {
        animation: 4.25s odometer infinite linear, 4.25s odometerstop-2 forwards linear 3.25s; }
      #gopoint .container-random .container-odo-digit[data-container='15'].stop[data-stop='3'] {
        animation: 4.25s odometer infinite linear, 4.25s odometerstop-3 forwards linear 3.25s; }
      #gopoint .container-random .container-odo-digit[data-container='15'].stop[data-stop='4'] {
        animation: 4.25s odometer infinite linear, 4.25s odometerstop-4 forwards linear 3.25s; }
      #gopoint .container-random .container-odo-digit[data-container='15'].stop[data-stop='5'] {
        animation: 4.25s odometer infinite linear, 4.25s odometerstop-5 forwards linear 3.25s; }
      #gopoint .container-random .container-odo-digit[data-container='15'].stop[data-stop='6'] {
        animation: 4.25s odometer infinite linear, 4.25s odometerstop-6 forwards linear 3.25s; }
      #gopoint .container-random .container-odo-digit[data-container='15'].stop[data-stop='7'] {
        animation: 4.25s odometer infinite linear, 4.25s odometerstop-7 forwards linear 3.25s; }
      #gopoint .container-random .container-odo-digit[data-container='15'].stop[data-stop='8'] {
        animation: 4.25s odometer infinite linear, 4.25s odometerstop-8 forwards linear 3.25s; }
      #gopoint .container-random .container-odo-digit[data-container='15'].stop[data-stop='9'] {
        animation: 4.25s odometer infinite linear, 4.25s odometerstop-9 forwards linear 3.25s; }
      #gopoint .container-random .container-odo-digit[data-container='15'].stop[data-stop='10'] {
        animation: 4.25s odometer infinite linear, 4.25s odometerstop-10 forwards linear 3.25s; }
      #gopoint .container-random .container-odo-digit[data-container='15'].stop[data-stop='11'] {
        animation: 4.25s odometer infinite linear, 4.25s odometerstop-11 forwards linear 3.25s; }
      #gopoint .container-random .container-odo-digit[data-container='15'].stop[data-stop='12'] {
        animation: 4.25s odometer infinite linear, 4.25s odometerstop-12 forwards linear 3.25s; }
      #gopoint .container-random .container-odo-digit[data-container='15'].stop[data-stop='13'] {
        animation: 4.25s odometer infinite linear, 4.25s odometerstop-13 forwards linear 3.25s; }
      #gopoint .container-random .container-odo-digit[data-container='15'].stop[data-stop='14'] {
        animation: 4.25s odometer infinite linear, 4.25s odometerstop-14 forwards linear 3.25s; }
      #gopoint .container-random .container-odo-digit[data-container='15'].stop[data-stop='15'] {
        animation: 4.25s odometer infinite linear, 4.25s odometerstop-15 forwards linear 3.25s; }
      #gopoint .container-random .container-odo-digit[data-container='15'].stop[data-stop='16'] {
        animation: 4.25s odometer infinite linear, 4.25s odometerstop-16 forwards linear 3.25s; }
      #gopoint .container-random .container-odo-digit[data-container='15'].stop[data-stop='17'] {
        animation: 4.25s odometer infinite linear, 4.25s odometerstop-17 forwards linear 3.25s; }
      #gopoint .container-random .container-odo-digit[data-container='15'].stop[data-stop='18'] {
        animation: 4.25s odometer infinite linear, 4.25s odometerstop-18 forwards linear 3.25s; }
      #gopoint .container-random .container-odo-digit[data-container='15'].stop[data-stop='19'] {
        animation: 4.25s odometer infinite linear, 4.25s odometerstop-19 forwards linear 3.25s; }
      #gopoint .container-random .container-odo-digit[data-container='15'].stop[data-stop='20'] {
        animation: 4.25s odometer infinite linear, 4.25s odometerstop-20 forwards linear 3.25s; }
      #gopoint .container-random .container-odo-digit[data-container='15'].stop[data-stop='21'] {
        animation: 4.25s odometer infinite linear, 4.25s odometerstop-21 forwards linear 3.25s; }
      #gopoint .container-random .container-odo-digit[data-container='15'].stop[data-stop='22'] {
        animation: 4.25s odometer infinite linear, 4.25s odometerstop-22 forwards linear 3.25s; }
      #gopoint .container-random .container-odo-digit[data-container='15'].stop[data-stop='23'] {
        animation: 4.25s odometer infinite linear, 4.25s odometerstop-23 forwards linear 3.25s; }
      #gopoint .container-random .container-odo-digit[data-container='15'].stop[data-stop='24'] {
        animation: 4.25s odometer infinite linear, 4.25s odometerstop-24 forwards linear 3.25s; }
      #gopoint .container-random .container-odo-digit[data-container='15'].stop[data-stop='25'] {
        animation: 4.25s odometer infinite linear, 4.25s odometerstop-25 forwards linear 3.25s; }
      #gopoint .container-random .container-odo-digit[data-container='15'].stop[data-stop='26'] {
        animation: 4.25s odometer infinite linear, 4.25s odometerstop-26 forwards linear 3.25s; }
      #gopoint .container-random .container-odo-digit[data-container='15'].stop[data-stop='27'] {
        animation: 4.25s odometer infinite linear, 4.25s odometerstop-27 forwards linear 3.25s; }
      #gopoint .container-random .container-odo-digit[data-container='15'].stop[data-stop='28'] {
        animation: 4.25s odometer infinite linear, 4.25s odometerstop-28 forwards linear 3.25s; }
      #gopoint .container-random .container-odo-digit[data-container='15'].stop[data-stop='29'] {
        animation: 4.25s odometer infinite linear, 4.25s odometerstop-29 forwards linear 3.25s; }
      #gopoint .container-random .container-odo-digit[data-container='15'].stop[data-stop='30'] {
        animation: 4.25s odometer infinite linear, 4.25s odometerstop-30 forwards linear 3.25s; }
      #gopoint .container-random .container-odo-digit[data-container='15'].stop[data-stop='31'] {
        animation: 4.25s odometer infinite linear, 4.25s odometerstop-31 forwards linear 3.25s; }
      #gopoint .container-random .container-odo-digit[data-container='15'].stop[data-stop='32'] {
        animation: 4.25s odometer infinite linear, 4.25s odometerstop-32 forwards linear 3.25s; }
      #gopoint .container-random .container-odo-digit[data-container='15'].stop[data-stop='33'] {
        animation: 4.25s odometer infinite linear, 4.25s odometerstop-33 forwards linear 3.25s; }
      #gopoint .container-random .container-odo-digit[data-container='15'].stop[data-stop='34'] {
        animation: 4.25s odometer infinite linear, 4.25s odometerstop-34 forwards linear 3.25s; }
      #gopoint .container-random .container-odo-digit[data-container='15'].stop[data-stop='35'] {
        animation: 4.25s odometer infinite linear, 4.25s odometerstop-35 forwards linear 3.25s; }
      #gopoint .container-random .container-odo-digit[data-container='15'].stop[data-stop='36'] {
        animation: 4.25s odometer infinite linear, 4.25s odometerstop-36 forwards linear 3.25s; }
    #gopoint .container-random .digit {
      height: 100px;
      width: 100px;
      border-radius: 5px;
      display: inline-block;
      background-color: #a4dbe4;
      overflow: hidden;
      margin: 0 10px; }
      #gopoint .container-random .digit .container-odo-digit span {
        display: inline-block;
        height: 100px;
        width: 100px;
        text-align: center;
        font-size: 80px; }
  #gopoint .drawed-winner .append-winner {
    opacity: 0; }
    #gopoint .drawed-winner .append-winner.active {
      animation: 0.5s showWinner forwards 0s; }
  #gopoint .loader--wrapper {
    position: absolute; }
  @media screen and (max-width: 768px) {
    #gopoint section.about {
      padding-top: 0px; } }
  #gopoint .layer.edisiliburan .odd--row, #gopoint .layer.edisiliburan .even--row {
    margin-bottom: 50px; }
  @media screen and (max-width: 767px) {
    #gopoint .layer.edisiliburan figure {
      position: relative;
      height: auto; }
    #gopoint .layer.edisiliburan .desc--pad {
      margin-top: 0px; }
    #gopoint .layer.edisiliburan .odd--row, #gopoint .layer.edisiliburan .even--row {
      margin-bottom: 0px; } }
  #gopoint .container-center {
    display: inline-block;
    width: 100%;
    text-align: center;
    margin-top: 100px;
    margin-bottom: 200px; }
    @media screen and (max-width: 767px) {
      #gopoint .container-center {
        margin-bottom: 70px; } }
    #gopoint .container-center .center {
      text-align: center;
      display: inline-block;
      width: 100%;
      max-width: 1170px; }
    #gopoint .container-center #content-only.golucky-content {
      width: 100%;
      float: left;
      margin-top: 0px;
      padding: 0px;
      margin-top: 125px; }
      #gopoint .container-center #content-only.golucky-content.edisiliburan {
        margin-top: 50px; }
        #gopoint .container-center #content-only.golucky-content.edisiliburan div.container.golucky:after {
          background-color: #a4dbe4; }
      #gopoint .container-center #content-only.golucky-content.info {
        padding-bottom: 0px; }
        #gopoint .container-center #content-only.golucky-content.info p {
          font-size: 14px;
          margin: 0px; }
          @media screen and (max-width: 768px) {
            #gopoint .container-center #content-only.golucky-content.info p {
              padding-bottom: 30px; } }
        #gopoint .container-center #content-only.golucky-content.info .link {
          margin-top: 0px;
          margin-bottom: 0px;
          top: 35px;
          position: relative; }
      #gopoint .container-center #content-only.golucky-content > div.container.golucky {
        position: relative;
        width: 100%;
        box-shadow: none;
        padding-top: 0px; }
        #gopoint .container-center #content-only.golucky-content > div.container.golucky > .info {
          padding-top: 30px; }
        #gopoint .container-center #content-only.golucky-content > div.container.golucky:after {
          z-index: -1;
          top: -30px;
          position: absolute;
          height: 100%;
          width: calc(100% + 60px);
          background: #343b41;
          left: -30px;
          content: ""; }
      #gopoint .container-center #content-only.golucky-content .contact {
        width: 300px; }
      @media screen and (max-width: 768px) {
        #gopoint .container-center #content-only.golucky-content {
          margin-top: 100px;
          margin-bottom: 100px;
          width: 90%;
          display: inline-block;
          float: none;
          max-width: 500px; }
          #gopoint .container-center #content-only.golucky-content a.contact {
            letter-spacing: 1px;
            width: 90%;
            bottom: -10px;
            position: absolute;
            left: 0;
            right: 0;
            margin: auto;
            line-height: 15px;
            padding: 20px; } }
    #gopoint .container-center section.blog-golucky {
      width: 50%;
      height: 440px;
      float: right;
      text-align: center;
      position: relative; }
      #gopoint .container-center section.blog-golucky .blog-container {
        display: inline-block;
        width: 100%;
        text-align: center; }
        #gopoint .container-center section.blog-golucky .blog-container > .title {
          text-align: center;
          color: #fff;
          font-size: 16px;
          letter-spacing: 2px; }
          #gopoint .container-center section.blog-golucky .blog-container > .title:after {
            margin: 30px auto;
            display: block;
            width: 30px;
            height: 2px;
            background-color: #fff;
            content: ""; }
          @media screen and (min-width: 768px) {
            #gopoint .container-center section.blog-golucky .blog-container > .title {
              display: none; } }
        #gopoint .container-center section.blog-golucky .blog-container a {
          color: inherit;
          font-size: inherit; }
        #gopoint .container-center section.blog-golucky .blog-container .blog {
          position: relative;
          height: 440px;
          width: 50%;
          background: #fff;
          display: inline-block;
          box-shadow: 0 2px 29px 0 rgba(0, 0, 0, 0.19); }
          #gopoint .container-center section.blog-golucky .blog-container .blog .title {
            height: 220px;
            width: 100%;
            display: table; }
            #gopoint .container-center section.blog-golucky .blog-container .blog .title p {
              display: table-cell;
              vertical-align: middle;
              margin: 0px;
              padding: 0 31px;
              font-size: 18px;
              letter-spacing: 1px;
              text-align: left; }
          #gopoint .container-center section.blog-golucky .blog-container .blog .image {
            box-shadow: 0px -6px 0px 0px #343b41;
            height: 220px;
            width: 100%;
            background-color: #2b2b2b;
            background-size: cover;
            background-position: center; }
          #gopoint .container-center section.blog-golucky .blog-container .blog button.read-more {
            opacity: 0;
            transition: all 0.5s;
            width: 210px;
            right: 0;
            bottom: 0;
            padding: 0px 30px;
            top: 0;
            position: absolute;
            left: 0;
            margin: auto;
            height: 70px;
            text-align: left;
            background-color: #F79520;
            color: #ffffff;
            border-radius: 5px; }
            #gopoint .container-center section.blog-golucky .blog-container .blog button.read-more:after {
              content: url(https://d3naj63yelf4gk.cloudfront.net/dist/img/header/arrow.png);
              position: relative;
              left: 10px;
              top: 2px;
              transition: all 0.5s; }
            @media screen and (max-width: 768px) {
              #gopoint .container-center section.blog-golucky .blog-container .blog button.read-more {
                opacity: 1;
                font-size: 14px; } }
          #gopoint .container-center section.blog-golucky .blog-container .blog:hover button.read-more {
            opacity: 1;
            transition: all 0.5s; }
          @media screen and (max-width: 767px) {
            #gopoint .container-center section.blog-golucky .blog-container .blog {
              width: 80%;
              margin-bottom: 100px; } }
        #gopoint .container-center section.blog-golucky .blog-container:after {
          position: absolute;
          height: 80%;
          width: 100%;
          background: #F79520;
          top: 0;
          bottom: 0;
          left: 0;
          margin: auto;
          content: "";
          z-index: -1; }
          @media screen and (max-width: 767px) {
            #gopoint .container-center section.blog-golucky .blog-container:after {
              display: none; } }
        #gopoint .container-center section.blog-golucky .blog-container svg {
          opacity: 0.3; }
          #gopoint .container-center section.blog-golucky .blog-container svg ellipse {
            stroke: #fff; }
          #gopoint .container-center section.blog-golucky .blog-container svg polygon {
            fill: #fff; }
          #gopoint .container-center section.blog-golucky .blog-container svg.left {
            position: absolute;
            left: 30px;
            bottom: 0;
            top: 0;
            margin: auto; }
          #gopoint .container-center section.blog-golucky .blog-container svg.right {
            position: absolute;
            right: 30px;
            bottom: 0;
            top: 0;
            margin: auto; }
          @media screen and (max-width: 767px) {
            #gopoint .container-center section.blog-golucky .blog-container svg {
              top: auto !important;
              bottom: 30px !important; } }
        @media screen and (max-width: 768px) {
          #gopoint .container-center section.blog-golucky .blog-container {
            margin-top: 0px; } }
        @media screen and (max-width: 767px) {
          #gopoint .container-center section.blog-golucky .blog-container {
            padding: 50px 0; } }
      @media screen and (max-width: 768px) {
        #gopoint .container-center section.blog-golucky {
          width: 100%; } }
      @media screen and (max-width: 767px) {
        #gopoint .container-center section.blog-golucky {
          background-color: #F79520;
          height: auto; } }
    @media screen and (max-width: 768px) {
      #gopoint .container-center {
        margin-top: 0px; } }
  #gopoint .how-it-works--wrapper {
    margin: 30px; }
    #gopoint .how-it-works--wrapper .desc--pad a {
      font-size: inherit;
      letter-spacing: normal; }
    @media screen and (max-width: 767px) {
      #gopoint .how-it-works--wrapper {
        margin: 0px; }
        #gopoint .how-it-works--wrapper .desc--pad {
          margin-top: 52%; } }
  #gopoint .link.golucky {
    font-size: 18px;
    letter-spacing: 2px; }
    #gopoint .link.golucky a {
      width: 300px; }
  #gopoint a.regular {
    text-transform: none !important;
    color: #48af4a;
    font-size: inherit;
    letter-spacing: 1px; }
    #gopoint a.regular:after {
      content: none; }
  #gopoint p {
    letter-spacing: normal; }
  #gopoint h4.head-table {
    margin-bottom: 0px; }
    #gopoint h4.head-table.search-title {
      margin-bottom: 30px; }
    #gopoint h4.head-table:after {
      display: block;
      position: relative;
      margin: auto;
      height: 5px;
      margin: 10px auto;
      width: 60px;
      margin-top: 20px;
      background: #485460;
      content: ""; }
  #gopoint .bg-services {
    opacity: 1; }
  #gopoint section.snk {
    font-family: Open Sans,Helvetica,sans-serif;
    background-color: #f8f8f8; }
    #gopoint section.snk img {
      width: 100%; }
    #gopoint section.snk ol li {
      margin-bottom: 10px; }
    #gopoint section.snk .container {
      width: 90%;
      max-width: 1170px;
      padding: 100px 0;
      padding-top: 10px; }
      #gopoint section.snk .container .option {
        border-top: 5px solid #F79520; }
        #gopoint section.snk .container .option h2.title {
          margin: 25px 0;
          font-weight: 300; }
        #gopoint section.snk .container .option ul {
          padding-left: 0px;
          margin: 0px;
          list-style-type: none; }
          #gopoint section.snk .container .option ul li {
            font-family: Open Sans,Helvetica,sans-serif;
            position: relative;
            cursor: pointer;
            padding-left: 70px;
            margin-bottom: 30px; }
          #gopoint section.snk .container .option ul li.active::before {
            content: "";
            background: #F79520;
            position: absolute;
            width: 50px;
            top: 45%;
            left: 0px;
            height: 2px; }
          #gopoint section.snk .container .option ul li.active {
            color: #F79520; }
      #gopoint section.snk .container .content {
        font-family: Open Sans,Helvetica,sans-serif;
        color: #8c8c8c;
        line-height: 25px;
        display: none; }
        #gopoint section.snk .container .content ol {
          padding-left: 20px;
          margin: 0px;
          line-height: 25px; }
        #gopoint section.snk .container .content a {
          letter-spacing: normal;
          color: #58c75b;
          font-size: inherit; }
        #gopoint section.snk .container .content b {
          color: #2b2b2b; }
        #gopoint section.snk .container .content .penghargaan {
          margin-bottom: 50px; }
          #gopoint section.snk .container .content .penghargaan h4, #gopoint section.snk .container .content .penghargaan p {
            margin: 0px; }
      #gopoint section.snk .container .content.active {
        display: block; }
      #gopoint section.snk .container .back {
        display: none; }
        #gopoint section.snk .container .back h3 {
          text-align: center;
          margin-top: 50px;
          color: #48af4a; }
        #gopoint section.snk .container .back a {
          font-size: inherit; }
    @media screen and (max-width: 768px) {
      #gopoint section.snk {
        margin: 0px;
        padding: 0px !important; }
        #gopoint section.snk .container {
          padding: 50px 0; }
          #gopoint section.snk .container .option {
            width: 75%; }
          #gopoint section.snk .container h2.title {
            margin: 30px 0 !important;
            font-size: 30px; }
        #gopoint section.snk .content h4 {
          font-size: 16px;
          line-height: normal;
          margin-bottom: 5px !important; } }
    #gopoint section.snk section.timeline ul li .wrapper div {
      min-height: inherit; }
    #gopoint section.snk section.section-our-values {
      width: 100%;
      padding: 30px; }
      #gopoint section.snk section.section-our-values h4 {
        font-size: 14px; }
      #gopoint section.snk section.section-our-values h5.more {
        display: inline-block !important; }
      #gopoint section.snk section.section-our-values .categories {
        margin-bottom: 30px; }
    #gopoint section.snk .judges .title, #gopoint section.snk .categories .title {
      position: relative;
      padding-left: 30px;
      text-align: left; }
    #gopoint section.snk .judges-desc {
      position: relative;
      height: 150px; }
      #gopoint section.snk .judges-desc h4 {
        font-size: 16px !important; }
      #gopoint section.snk .judges-desc h5 {
        font-size: 14px !important; }
      #gopoint section.snk .judges-desc button.float {
        border-radius: 4px 0px 0 4px;
        right: 0;
        padding: 15px 40px;
        top: -20px;
        position: absolute;
        font-size: 12px;
        font-weight: 300;
        color: white;
        background-color: #009444; }
      #gopoint section.snk .judges-desc button.float::after {
        content: url(https://d3naj63yelf4gk.cloudfront.net/dist/img/header/arrow.png);
        position: absolute;
        padding-left: 10px;
        top: 12px;
        right: 10px;
        transform: scale(0.7);
        width: 20px; }
  #gopoint .search-golucky {
    text-align: center;
    font-size: 14px;
    color: #485460;
    width: 100%;
    display: inline-block; }
    #gopoint .search-golucky .limit {
      display: inline-block;
      float: left; }
      #gopoint .search-golucky .limit .option {
        display: inline-block;
        position: relative; }
        #gopoint .search-golucky .limit .option select {
          font-weight: 700;
          display: inline-block;
          padding: 5px;
          -webkit-appearance: none;
          border: none;
          margin: 0 5px;
          padding-right: 20px;
          background: #ffffff; }
        #gopoint .search-golucky .limit .option i {
          position: absolute;
          top: 8px;
          right: 9px;
          font-size: 12px; }
      @media screen and (max-width: 767px) {
        #gopoint .search-golucky .limit {
          display: none; } }
    #gopoint .search-golucky .box {
      float: right;
      display: inline-block;
      position: relative; }
      #gopoint .search-golucky .box input {
        border: none;
        padding: 5px;
        border-radius: 5px;
        margin-left: 5px;
        width: 300px;
        padding-left: 10px; }
        @media screen and (max-width: 767px) {
          #gopoint .search-golucky .box input {
            width: 215px; } }
      #gopoint .search-golucky .box i {
        cursor: pointer;
        position: absolute;
        right: 5px;
        height: 18px;
        top: 0;
        bottom: 0;
        margin: auto; }
      @media screen and (max-width: 767px) {
        #gopoint .search-golucky .box {
          float: none; } }
  #gopoint .pagination-golucky {
    text-align: center;
    display: inline-block;
    width: 100%;
    margin-bottom: 50px; }
    #gopoint .pagination-golucky > div {
      font-weight: 700;
      border-radius: 5px;
      cursor: pointer;
      display: inline-block;
      padding: 5px 8px;
      background: #ffffff;
      color: #485460;
      margin-right: 10px;
      margin-top: 10px; }
      #gopoint .pagination-golucky > div:hover {
        background: #f79520;
        color: #fff; }
      #gopoint .pagination-golucky > div:last-child {
        margin-right: 0px; }
      #gopoint .pagination-golucky > div.active {
        background: #F79520;
        color: #ffffff; }
  #gopoint .rect--secondary {
    background: #e8ebf2 !important; }
  #gopoint .rect--primary {
    background: #F79520 !important; }
  #gopoint h4.section--title.mekanisme::before {
    background: #F79520; }
  @media screen and (min-width: 769px) {
    #gopoint h4.section--title.mekanisme {
      color: #4a4a4a;
      text-transform: uppercase; } }
  @media screen and (max-width: 768px) {
    #gopoint h4.section--title.mekanisme {
      left: -35px;
      text-transform: none; }
      #gopoint h4.section--title.mekanisme::before {
        background: #F79520;
        left: -4em !important;
        top: 11px !important; } }
  #gopoint h4.section--title.mekanisme.edisiliburan::before {
    background: #a4dbe4; }
  @media screen and (min-width: 769px) {
    #gopoint h4.section--title.mekanisme.edisiliburan {
      color: #4a4a4a;
      text-transform: uppercase; } }
  @media screen and (max-width: 768px) {
    #gopoint h4.section--title.mekanisme.edisiliburan {
      margin-bottom: 30px; }
      #gopoint h4.section--title.mekanisme.edisiliburan::before {
        background: #a4dbe4; } }
  #gopoint h4.section--title.mekanisme.edisigoride::before {
    background: #48af4a; }
  @media screen and (min-width: 769px) {
    #gopoint h4.section--title.mekanisme.edisigoride {
      color: #4a4a4a;
      text-transform: uppercase; } }
  @media screen and (max-width: 768px) {
    #gopoint h4.section--title.mekanisme.edisigoride {
      margin-bottom: 30px; }
      #gopoint h4.section--title.mekanisme.edisigoride::before {
        background: #48af4a; } }
  #gopoint .golucky.spacing {
    height: 60px;
    background: #8dc63f; }
    #gopoint .golucky.spacing.edisiliburan {
      background-color: #a4dbe4; }
  #gopoint .hero.golucky .pure-g {
    box-shadow: none; }
  #gopoint .hero.golucky:after {
    display: none; }
  #gopoint .select-top {
    display: none;
    margin-top: -3em;
    padding: 0;
    margin: 0 auto;
    border: 0;
    width: 300px;
    border-radius: 0;
    overflow: hidden;
    background: #fff;
    position: absolute;
    top: auto;
    bottom: -20px;
    left: 0;
    right: 0;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.13); }
    #gopoint .select-top.hero {
      position: relative;
      margin: 50px auto !important;
      bottom: 0; }
    #gopoint .select-top .bg--select {
      width: 50px;
      height: 100%;
      background: #F79520 url(https://d3naj63yelf4gk.cloudfront.net/dist/img/select-more.svg) no-repeat 55% 50%;
      position: absolute;
      right: 0;
      z-index: 1;
      background-size: 13px; }
    #gopoint .select-top select {
      padding: 15px;
      width: 105%;
      border: none;
      box-shadow: none;
      background-color: transparent;
      background-image: none;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      position: relative;
      z-index: 5; }
    @media screen and (max-width: 768px) {
      #gopoint .select-top {
        display: block; } }
  #gopoint .services .container p {
    margin-top: 0px;
    text-align: center;
    font-size: 14px; }
  #gopoint .services .container .step {
    font-size: 30px;
    margin-top: 20px;
    font-weight: 700;
    color: #F79520;
    text-align: center;
    font-family: Neo; }
  @media screen and (max-width: 768px) {
    #gopoint .services .container:before {
      display: none; }
    #gopoint .services .container h4 {
      text-align: center;
      padding-left: 0px;
      line-height: 20px; } }
  #gopoint .services.how-to-token {
    padding-top: 0px !important;
    padding-bottom: 250px !important; }
  @media screen and (max-width: 767px) {
    #gopoint .services {
      margin-top: 20px;
      padding-top: 0px !important;
      padding-bottom: 0px !important; }
      #gopoint .services .navigation-arrow {
        padding-top: 15.5em;
        z-index: 10;
        position: relative;
        background-color: transparent; } }
  #gopoint section.about .description {
    position: relative; }
    #gopoint section.about .description p {
      font-size: 14px; }
    @media screen and (max-width: 35.5em) {
      #gopoint section.about .description {
        position: static; } }
  @media screen and (max-width: 35.5em) {
    #gopoint section.about h4.title--content {
      margin: 0px;
      margin-top: 15px;
      top: 0;
      margin-left: 5px;
      position: absolute;
      font-size: 14px;
      padding-left: 0px; } }
  @media screen and (max-width: 35.5em) {
    #gopoint section.about .container-desc-home-career {
      margin-top: 50px;
      margin-left: 0px; } }
  @media screen and (max-width: 768px) {
    #gopoint section.about .container:before {
      display: none; } }
  #gopoint #winner-btn {
    padding: 0;
    margin-bottom: 30px; }
    #gopoint #winner-btn button.cta-golucky {
      margin: 0; }
  #gopoint .container-leaderboard {
    margin: auto;
    text-align: center;
    width: 90%;
    padding: 20px 0;
    max-width: 960px; }
    #gopoint .container-leaderboard .title-prize {
      font-family: 'Open Sans';
      font-size: 18px;
      font-weight: bold;
      letter-spacing: 1px;
      text-align: center;
      text-transform: uppercase;
      color: #f7951d; }
    #gopoint .container-leaderboard.home {
      max-width: 1170px; }
      @media screen and (max-width: 767px) {
        #gopoint .container-leaderboard.home {
          margin-top: 40px; } }
    @media screen and (max-width: 767px) {
      #gopoint .container-leaderboard {
        width: 100%;
        padding: 10px;
        text-align: left; } }
  #gopoint button.cta-golucky {
    background: #F79520;
    border-radius: 5px;
    color: #ffffff;
    font-size: 18px;
    padding: 20px;
    letter-spacing: 2px;
    padding: 30px;
    width: 300px;
    margin: 0 auto !important;
    display: block; }
    @media screen and (max-width: 768px) {
      #gopoint button.cta-golucky {
        margin: auto;
        margin-top: 30px;
        padding: 24px;
        font-size: 12px; } }
  #gopoint .container-table-golucky {
    width: 100%;
    text-align: center;
    overflow: auto;
    position: relative; }
    @media screen and (max-width: 767px) {
      #gopoint .container-table-golucky {
        box-shadow: inset -42px 0px 100px 7px #f8f8f8; } }
  #gopoint .table-golucky {
    font-size: 14px;
    position: relative;
    z-index: -1;
    margin: 20px 0; }
    #gopoint .table-golucky .header > .pure-g {
      background: #F79520;
      color: #ffffff;
      font-weight: 700; }
      #gopoint .table-golucky .header > .pure-g.goride {
        background: #48af4a; }
      #gopoint .table-golucky .header > .pure-g > div.number {
        text-align: right; }
    #gopoint .table-golucky .pure-g > div {
      padding: 15px;
      text-align: left; }
      #gopoint .table-golucky .pure-g > div.number {
        text-align: right; }
    #gopoint .table-golucky .content {
      color: #485460; }
      #gopoint .table-golucky .content > div:nth-child(odd) {
        background: #ffffff; }
    @media screen and (max-width: 767px) {
      #gopoint .table-golucky {
        margin: 30px 0;
        width: 900px;
        padding-right: 100px; } }
  #gopoint .about-golucky {
    text-align: center;
    display: inline-block;
    width: 100%;
    background: #ffffff;
    padding: 60px;
    margin-bottom: 30px; }
    @media screen and (max-width: 768px) {
      #gopoint .about-golucky.edisiliburan .container-image {
        display: none; } }
    #gopoint .about-golucky.edisiliburan .image-mobile-edisiliburan {
      display: none; }
      @media screen and (max-width: 768px) {
        #gopoint .about-golucky.edisiliburan .image-mobile-edisiliburan {
          display: block;
          margin: auto;
          margin-top: 20px;
          width: 90%; }
          #gopoint .about-golucky.edisiliburan .image-mobile-edisiliburan img {
            width: 100%; } }
    @media screen and (max-width: 768px) {
      #gopoint .about-golucky {
        background-size: 500px;
        background-position: 100% 135%;
        background-image: url(https://d3naj63yelf4gk.cloudfront.net/dist/img/faq/geometry.svg);
        background-repeat: no-repeat;
        padding: 30px 10px; }
        #gopoint .about-golucky .switch {
          display: table;
          width: 100%; } }
    #gopoint .about-golucky h4.title--content {
      margin-top: 0px; }
      @media screen and (max-width: 768px) {
        #gopoint .about-golucky h4.title--content {
          text-align: left; } }
    #gopoint .about-golucky p {
      max-width: 700px;
      font-size: 14px;
      margin: auto;
      text-align: center; }
    #gopoint .about-golucky .container-switch {
      margin: auto;
      position: relative;
      width: 100%;
      max-width: 1170px; }
      #gopoint .about-golucky .container-switch .center-image {
        background-size: cover;
        background-position: center;
        position: absolute;
        height: 100%;
        width: 50%;
        left: 0;
        right: 0;
        margin: auto;
        top: 30px;
        height: 400px;
        z-index: 2; }
        @media screen and (max-width: 768px) {
          #gopoint .about-golucky .container-switch .center-image {
            position: relative;
            width: calc(100% + 20px);
            left: -10px;
            top: 0; } }
        @media screen and (max-width: 500px) {
          #gopoint .about-golucky .container-switch .center-image {
            height: 250px; } }
      @media screen and (max-width: 768px) {
        #gopoint .about-golucky .container-switch {
          display: table-caption;
          margin: 20px 0; } }
    #gopoint .about-golucky .container-image {
      width: 100%;
      max-width: 1170px;
      height: 400px;
      background: #ececec;
      margin: auto;
      margin-top: 30px;
      display: inline-block;
      position: relative; }
      #gopoint .about-golucky .container-image .left-image {
        position: absolute;
        left: 0;
        top: 0;
        width: 25%;
        height: 100%; }
        #gopoint .about-golucky .container-image .left-image > div {
          background-size: cover !important;
          background-position: center;
          position: relative;
          float: left;
          width: 100%;
          height: 200px; }
          @media screen and (max-width: 768px) {
            #gopoint .about-golucky .container-image .left-image > div {
              width: 50%;
              height: 100%; } }
        @media screen and (max-width: 768px) {
          #gopoint .about-golucky .container-image .left-image {
            position: relative;
            height: 150px;
            top: 0;
            width: 100%; } }
        @media screen and (max-width: 500px) {
          #gopoint .about-golucky .container-image .left-image {
            height: 100px; } }
      #gopoint .about-golucky .container-image .right-image {
        position: absolute;
        right: 0;
        top: 0;
        height: 100%;
        width: 25%;
        margin: auto; }
        #gopoint .about-golucky .container-image .right-image > div {
          position: relative;
          float: left;
          width: 100%;
          height: 133.2px;
          background-size: cover;
          background-position: center; }
          @media screen and (max-width: 768px) {
            #gopoint .about-golucky .container-image .right-image > div {
              height: 100%;
              width: 33.3%; } }
        @media screen and (max-width: 768px) {
          #gopoint .about-golucky .container-image .right-image {
            height: 75px;
            bottom: 0;
            top: auto;
            width: 100%;
            position: relative; } }
        @media screen and (max-width: 500px) {
          #gopoint .about-golucky .container-image .right-image {
            height: 50px; } }
      @media screen and (max-width: 768px) {
        #gopoint .about-golucky .container-image {
          height: auto;
          max-width: 500px; } }
  #gopoint .container-note-golucky {
    width: calc(100% + 120px);
    left: -60px;
    position: relative;
    display: inline-block;
    text-align: center;
    margin-bottom: 50px; }
    #gopoint .container-note-golucky p.note-golucky {
      color: #fff;
      display: inline-block;
      margin: 0px;
      padding: 40px 80px;
      background-color: #F79520; }
  #gopoint p.note-golucky {
    font-size: 14px;
    padding: 10px;
    max-width: 370px;
    text-align: left;
    margin: 30px auto; }
  #gopoint section.timeline {
    position: relative;
    padding: 30px 0;
    padding-bottom: 130px;
    margin-top: 60px; }
    #gopoint section.timeline.edisiliburan {
      padding-bottom: 0px; }
      #gopoint section.timeline.edisiliburan .title h4::before {
        background-color: #a4dbe4; }
    #gopoint section.timeline .title h4 {
      font-weight: 700;
      margin-bottom: 0px;
      font-family: "Open Sans", Helvetica, sans-serif;
      background: #F79520;
      padding: 30px;
      color: #ffffff;
      line-height: 0.78;
      letter-spacing: 1.9px;
      position: relative;
      padding-left: 50px;
      text-transform: uppercase; }
    #gopoint section.timeline .title h4::before {
      content: "";
      background-color: #353c41;
      position: absolute;
      width: 50px;
      height: 4px;
      top: 50%;
      left: -25px; }
    #gopoint section.timeline ul {
      list-style: none;
      margin: 0 auto;
      padding: 0;
      margin-top: 100px; }
      #gopoint section.timeline ul li {
        width: 25%;
        float: left;
        position: relative; }
        #gopoint section.timeline ul li .wrapper {
          height: 180px;
          width: 300px;
          margin: 0 5px;
          padding: 15px 15px; }
          #gopoint section.timeline ul li .wrapper div {
            min-height: 250px;
            background: #ffffff;
            padding: 15px;
            border-bottom: 4px solid #dbdbdb; }
            #gopoint section.timeline ul li .wrapper div h4 {
              line-height: 1.42;
              letter-spacing: 2.2px;
              text-align: center;
              color: #000000;
              font-weight: 500;
              height: 40px; }
            #gopoint section.timeline ul li .wrapper div h4:first-child {
              font-weight: bold; }
      #gopoint section.timeline ul li.done .wrapper div {
        border-bottom: 4px solid #F79520; }
      #gopoint section.timeline ul li.done:before {
        content: "";
        position: absolute;
        font-size: 13px;
        font-weight: 400;
        margin-left: -47px;
        height: 10px;
        width: 10px;
        border-radius: 50%;
        text-align: center;
        line-height: 27px;
        z-index: 1;
        background: #ffffff;
        top: -50px;
        left: 65%;
        border: 5px solid #F79520; }
      #gopoint section.timeline ul li.done:after {
        content: "";
        position: absolute;
        z-index: 0;
        background: #F79520;
        width: 100%;
        height: 3px;
        bottom: 0;
        top: -43.5px;
        left: -50%; }
      #gopoint section.timeline ul li:before {
        content: "";
        position: absolute;
        font-size: 13px;
        font-weight: 400;
        margin-left: -47px;
        height: 10px;
        width: 10px;
        border-radius: 50%;
        text-align: center;
        line-height: 27px;
        z-index: 1;
        background: #ffffff;
        top: -50px;
        left: 65%;
        border: 5px solid #dbdbdb; }
      #gopoint section.timeline ul li:after {
        content: "";
        position: absolute;
        z-index: 0;
        background: #dbdbdb;
        width: 100%;
        height: 3px;
        bottom: 0;
        top: -43.5px;
        left: -50%; }
      #gopoint section.timeline ul li:first-child:after {
        content: none; }
    #gopoint section.timeline .extra-notes {
      font-weight: 700;
      position: absolute;
      right: 0;
      bottom: 50px;
      text-align: right;
      width: 995px;
      left: 0;
      right: 0;
      margin: auto; }
      @media screen and (max-width: 768px) {
        #gopoint section.timeline .extra-notes {
          font-size: 12px;
          width: 100%;
          box-sizing: border-box;
          padding-right: 40px; } }
    @media screen and (min-width: 769px) {
      #gopoint section.timeline .edisiliburan-time ul li {
        width: auto;
        height: auto; } }
    #gopoint section.timeline .edisiliburan-time ul li .wrapper {
      height: auto; }
    #gopoint section.timeline .edisiliburan-time ul li.done:before {
      background-color: #a4dbe4; }
    #gopoint section.timeline .edisiliburan-time ul li.done .wrapper > div {
      border-color: #a4dbe4; }
    @media screen and (max-width: 768px) {
      #gopoint section.timeline {
        padding-bottom: 100px; } }
  #gopoint .service-container {
    background: #F79520;
    visibility: hidden; }
    #gopoint .service-container.slick-initialized {
      visibility: visible; }
    #gopoint .service-container.edisiliburan p a:after {
      font-size: inherit;
      letter-spacing: normal;
      display: none; }
  #gopoint h4 {
    color: #F79520; }
    #gopoint h4.goride {
      color: #48af4a; }
    @media screen and (max-width: 768px) {
      #gopoint h4 {
        font-size: 14px; } }
  #gopoint .table-display {
    display: table;
    height: 100%; }
    #gopoint .table-display .middle-table {
      vertical-align: middle;
      display: table-cell; }
  #gopoint .container-inline {
    display: inline-block;
    width: 100%;
    text-align: center; }
    @media screen and (max-width: 767px) {
      #gopoint .container-inline {
        text-align: left;
        padding: 10px; }
        #gopoint .container-inline h4 {
          padding: 10px; } }
  #gopoint .option-bar {
    overflow: hidden;
    position: relative;
    z-index: 10;
    display: inline-block;
    width: 100%;
    max-width: 960px;
    background: #ffffff;
    font-size: 14px;
    letter-spacing: 1px;
    font-family: "Open Sans", Helvetica, sans-serif;
    color: #485460;
    border-radius: 4px;
    transform: translateY(-50%);
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.13); }
    #gopoint .option-bar a {
      display: inline-block;
      width: 100%;
      padding: 25px 0;
      color: inherit;
      font-size: inherit;
      letter-spacing: 1.5px; }
    #gopoint .option-bar .pure-g > .child-option {
      position: relative;
      border-right: 1px solid #ececec; }
      #gopoint .option-bar .pure-g > .child-option:last-child {
        border-right: none; }
      #gopoint .option-bar .pure-g > .child-option > a.active:after {
        position: absolute;
        width: 100%;
        height: 4px;
        left: 0;
        background: #F79520;
        bottom: 0px;
        content: ""; }
    @media screen and (max-width: 768px) {
      #gopoint .option-bar {
        display: none; } }
  #gopoint .join .messages-alert {
    position: fixed;
    display: none;
    background-color: rgba(250, 50, 50, 0.9);
    width: 100%;
    padding: 20px;
    text-transform: capitalize;
    margin-top: -45px;
    z-index: 99;
    top: 86px;
    margin-bottom: -80px;
    box-shadow: 0px -5px 25px rgba(10, 10, 10, 0.85); }
    #gopoint .join .messages-alert h5 {
      color: white;
      font-size: 15px;
      margin: 0;
      padding: 0; }

@-webkit-keyframes uil-ring-anim {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

@-moz-keyframes uil-ring-anim {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

@-webkit-keyframes uil-ring-anim {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

@-o-keyframes uil-ring-anim {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

@keyframes uil-ring-anim {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }
  #gopoint .join .uil-ring-css {
    background: none;
    position: relative;
    width: 200px;
    position: relative;
    margin: 0 auto;
    display: none;
    /* display: none; */
    top: 370px; }
    #gopoint .join .uil-ring-css div {
      position: absolute;
      display: block;
      width: 160px;
      height: 160px;
      top: 20px;
      left: 20px;
      border-radius: 80px;
      box-shadow: 0 3px 0 0 #98ce00;
      -webkit-transform-origin: 80px 81.5px;
      transform-origin: 80px 81.5px;
      -webkit-animation: uil-ring-anim 1s linear infinite;
      animation: uil-ring-anim 1s linear infinite; }
  #gopoint .join section.join {
    margin: 120px 0; }
    #gopoint .join section.join .hidden {
      display: none; }
    #gopoint .join section.join .banner img {
      height: 100%;
      width: 100%; }
    #gopoint .join section.join .label,
    #gopoint .join section.join label {
      text-transform: none !important; }
    #gopoint .join section.join .title {
      font-weight: 300;
      text-transform: uppercase; }
    #gopoint .join section.join .group-title {
      font-weight: 300;
      text-transform: uppercase; }
    #gopoint .join section.join .form-1,
    #gopoint .join section.join .form-2 {
      padding: 50px;
      background: white;
      position: relative; }
      #gopoint .join section.join .form-1 .error-input,
      #gopoint .join section.join .form-2 .error-input {
        border: 1px solid #E0164A !important; }
      #gopoint .join section.join .form-1 .error-messages,
      #gopoint .join section.join .form-2 .error-messages {
        color: red;
        font-style: italic; }
      #gopoint .join section.join .form-1 .button-disabled,
      #gopoint .join section.join .form-2 .button-disabled {
        cursor: not-allowed;
        box-shadow: none !important;
        background: #c8c8c8 !important; }
      #gopoint .join section.join .form-1 .combo-box,
      #gopoint .join section.join .form-2 .combo-box {
        position: relative; }
      #gopoint .join section.join .form-1 .combo-box:after,
      #gopoint .join section.join .form-2 .combo-box:after {
        content: '\f0d7';
        font-family: 'FontAwesome';
        flex-direction: column;
        justify-content: center;
        right: 15px;
        top: 30px;
        text-align: right;
        position: absolute; }
      #gopoint .join section.join .form-1 .combo-box-long:after,
      #gopoint .join section.join .form-2 .combo-box-long:after {
        top: 50px; }
      #gopoint .join section.join .form-1 .title,
      #gopoint .join section.join .form-2 .title {
        position: relative;
        font-size: 20px;
        padding-left: 30px;
        padding: 0; }
        #gopoint .join section.join .form-1 .title p,
        #gopoint .join section.join .form-2 .title p {
          font-size: 13px;
          font-weight: 400;
          letter-spacing: 1.5px;
          color: #bcbdc0; }
          #gopoint .join section.join .form-1 .title p span,
          #gopoint .join section.join .form-2 .title p span {
            margin-right: 5px !important; }
            #gopoint .join section.join .form-1 .title p span:before,
            #gopoint .join section.join .form-2 .title p span:before {
              font-weight: 400; }
      #gopoint .join section.join .form-1 .text-info,
      #gopoint .join section.join .form-2 .text-info {
        margin-top: 30px;
        position: relative;
        padding-left: 25px;
        line-height: 1.46;
        letter-spacing: 1px;
        color: #bcbdc0; }
        #gopoint .join section.join .form-1 .text-info p:first-child,
        #gopoint .join section.join .form-2 .text-info p:first-child {
          text-transform: uppercase; }
        #gopoint .join section.join .form-1 .text-info p,
        #gopoint .join section.join .form-2 .text-info p {
          margin: 0; }
      #gopoint .join section.join .form-1 .text-info::before,
      #gopoint .join section.join .form-2 .text-info::before {
        content: "";
        height: 3px;
        width: 15px;
        background: #093c5b;
        position: absolute;
        left: 0;
        top: 20%; }
      #gopoint .join section.join .form-1 .label,
      #gopoint .join section.join .form-1 label,
      #gopoint .join section.join .form-2 .label,
      #gopoint .join section.join .form-2 label {
        color: #2DA144;
        font-size: 14px;
        font-family: "Open Sans", Helvetica, sans-serif;
        margin-bottom: 1em;
        letter-spacing: 1.5px;
        font-weight: 400;
        margin: 0;
        text-transform: uppercase; }
      #gopoint .join section.join .form-1 textarea,
      #gopoint .join section.join .form-2 textarea {
        resize: none;
        width: 100%;
        height: 234px; }
      #gopoint .join section.join .form-1 input[type=text],
      #gopoint .join section.join .form-1 input[type=number],
      #gopoint .join section.join .form-1 input[type=email],
      #gopoint .join section.join .form-1 select,
      #gopoint .join section.join .form-1 textarea,
      #gopoint .join section.join .form-2 input[type=text],
      #gopoint .join section.join .form-2 input[type=number],
      #gopoint .join section.join .form-2 input[type=email],
      #gopoint .join section.join .form-2 select,
      #gopoint .join section.join .form-2 textarea {
        border: 1px solid #c3c9d7;
        width: 100%;
        border-radius: 0;
        box-shadow: none;
        -webkit-appearance: none; }
      #gopoint .join section.join .form-1 input[type=text],
      #gopoint .join section.join .form-1 input[type=number],
      #gopoint .join section.join .form-1 input[type=email],
      #gopoint .join section.join .form-1 textarea,
      #gopoint .join section.join .form-2 input[type=text],
      #gopoint .join section.join .form-2 input[type=number],
      #gopoint .join section.join .form-2 input[type=email],
      #gopoint .join section.join .form-2 textarea {
        padding: 15px; }
      #gopoint .join section.join .form-1 select,
      #gopoint .join section.join .form-2 select {
        height: 45px;
        text-transform: capitalize;
        padding: 0 15px; }
      #gopoint .join section.join .form-1 ::-webkit-input-placeholder,
      #gopoint .join section.join .form-2 ::-webkit-input-placeholder {
        color: gainsboro; }
      #gopoint .join section.join .form-1 :-moz-placeholder,
      #gopoint .join section.join .form-2 :-moz-placeholder {
        color: gainsboro;
        opacity: 1; }
      #gopoint .join section.join .form-1 ::-moz-placeholder,
      #gopoint .join section.join .form-2 ::-moz-placeholder {
        color: gainsboro;
        opacity: 1; }
      #gopoint .join section.join .form-1 :-ms-input-placeholder,
      #gopoint .join section.join .form-2 :-ms-input-placeholder {
        color: gainsboro; }
      #gopoint .join section.join .form-1 ::-ms-input-placeholder,
      #gopoint .join section.join .form-2 ::-ms-input-placeholder {
        color: gainsboro; }
      #gopoint .join section.join .form-1 span.normal,
      #gopoint .join section.join .form-2 span.normal {
        text-transform: initial; }
      #gopoint .join section.join .form-1 span.required,
      #gopoint .join section.join .form-2 span.required {
        color: #b51410; }
      #gopoint .join section.join .form-1 .divident,
      #gopoint .join section.join .form-2 .divident {
        margin-top: 5px;
        margin-bottom: 15px; }
      #gopoint .join section.join .form-1 .submit button,
      #gopoint .join section.join .form-2 .submit button {
        border-radius: 5px;
        background-color: #1f4864;
        box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.19);
        color: #fff;
        float: right;
        position: absolute;
        bottom: -30px;
        right: 50px;
        padding: 20px 50px;
        width: 300px; }
    #gopoint .join section.join .form-2 .text-info:before {
      top: 40%; }
    #gopoint .join section.join .form-2 .rating .start,
    #gopoint .join section.join .form-2 .rating .finish {
      font-size: 14px; }
    #gopoint .join section.join .form-2 .rating p {
      display: block; }
    #gopoint .join section.join .form-2 .rating ul {
      padding: 0; }
      #gopoint .join section.join .form-2 .rating ul li {
        float: left;
        margin: 0 6%;
        list-style: none;
        width: 20px;
        height: 20px;
        border: 1px solid #485460;
        border-radius: 50%;
        position: relative; }
        #gopoint .join section.join .form-2 .rating ul li input[type="radio"] {
          box-shadow: 0px 0px 5px 0 rgba(0, 0, 0, 0.09);
          -webkit-appearance: none;
          height: 20px;
          width: 20px;
          border-radius: 50%; }
        #gopoint .join section.join .form-2 .rating ul li input[type="radio"]:hover {
          cursor: pointer; }
        #gopoint .join section.join .form-2 .rating ul li input[type="radio"]:checked {
          background: #98ce00;
          height: 16px;
          width: 16px;
          top: 1px;
          left: 1px;
          border-radius: 50%;
          position: absolute; }
    #gopoint .join section.join .form-2 .combo-box:after {
      top: 50px; }
    #gopoint .join section.join .form-2 .submit .back {
      right: 40%;
      background: #343b41; }
  #gopoint header.ramadhan {
    margin-top: 68px; }
    #gopoint header.ramadhan p {
      font-family: "Lato", Helvetica, sans-serif !important;
      font-size: 18px; }
    #gopoint header.ramadhan .desc--wrapper {
      height: 100%; }
  #gopoint .navbar.ramadhan .btn-style-1-icon {
    background-color: #3c95d1; }
  #gopoint .navbar.ramadhan .brand img {
    top: 8.5px; }
  #gopoint #promo-banner .detail {
    bottom: 0; }
  #gopoint #promo-banner .img-item {
    border: none; }
  #gopoint .how-it-works--wrapper.ramadhan .desc--pad ol li {
    font-size: 16px;
    padding: 0 0.5em;
    color: #485460;
    font-family: "Open Sans", Helvetica, sans-serif !important;
    font-weight: 300;
    line-height: 1.62;
    letter-spacing: 0.8px; }
  #gopoint #join-now.ramadhan .right p {
    font-size: 1em;
    color: #ffffff; }
  #gopoint #bg-as-seen-on.ramadhan .as-seen-on img {
    max-width: 250px;
    min-height: 85px; }
  #gopoint .section-user-review.ramadhan {
    padding-top: 0;
    padding-bottom: 0; }
    #gopoint .section-user-review.ramadhan .head h3 {
      color: #343b41; }
    #gopoint .section-user-review.ramadhan .line.gradient {
      background: #F79520; }
    #gopoint .section-user-review.ramadhan .body .wrapper {
      height: 225px; }
    #gopoint .section-user-review.ramadhan .body .style-1,
    #gopoint .section-user-review.ramadhan .body .style-3 {
      background: #343b41 !important; }
    #gopoint .section-user-review.ramadhan .body .style-2 {
      background: #F79520 !important; }
    #gopoint .section-user-review.ramadhan .body .slick-dots li.slick-active button::before {
      color: #F79520; }
    #gopoint .section-user-review.ramadhan h3.user-name {
      font-weight: 500 !important; }
  #gopoint section.ramadhan .container:before {
    background-color: #F79520; }
  #gopoint section.ramadhan .description p {
    font-size: 16px; }
  #gopoint section.ramadhan h4 {
    color: #343b41; }
  #gopoint section.ramadhan .section--title:before {
    background-color: #F79520 !important; }
  #gopoint #bg-as-seen-on.ramadhan .as-seen-on p {
    color: #343b41; }
  #gopoint .how-it-works--wrapper .container {
    padding-top: 0px; }
  #gopoint .how-it-works--wrapper .layer {
    padding-bottom: 10px; }
    #gopoint .how-it-works--wrapper .layer .rect--secondary {
      background: #F79520; }
    @media screen and (max-width: 35.5em) {
      #gopoint .how-it-works--wrapper .layer {
        margin-bottom: 100px; } }
  #gopoint .how-it-works--wrapper-mobile {
    display: none; }
  #gopoint .articles--feed .bg {
    background: #343b41; }
  #gopoint .articles--feed .line--title {
    background: #F79520; }
  #gopoint .articles--feed ellipse {
    stroke: #F79520 !important; }
  #gopoint .articles--feed polygon {
    fill: #F79520 !important; }
  #gopoint .section-our-values {
    color: #F79520;
    padding: 3em;
    background: #f8f8f8; }
    #gopoint .section-our-values .title,
    #gopoint .section-our-values img,
    #gopoint .section-our-values p {
      margin-bottom: 3em; }
    #gopoint .section-our-values .line.gradient {
      background: #F79520; }
    #gopoint .section-our-values img {
      max-width: 250px; }
    #gopoint .section-our-values p {
      font-size: 14px; }
    #gopoint .section-our-values h3:first-child {
      font-weight: 700; }
    #gopoint .section-our-values h3 {
      font-weight: 500;
      color: #F79520; }
  #gopoint #bg-as-seen-on {
    padding-top: 0; }
    #gopoint #bg-as-seen-on .as-seen-on p {
      color: #F79520; }
    #gopoint #bg-as-seen-on .as-seen-on hr {
      background: #F79520; }
    #gopoint #bg-as-seen-on .as-seen-on #content img {
      padding: 1.5em; }
    #gopoint #bg-as-seen-on .as-seen-on #right {
      background: #F79520; }
    #gopoint #bg-as-seen-on .as-seen-on img {
      max-height: 120px; }
  #gopoint #join-now a {
    background: #F79520; }
  #gopoint .merchant-list {
    padding-bottom: 0; }
    #gopoint .merchant-list h5 {
      color: #F79520; }
    #gopoint .merchant-list .content .blog-card-img {
      position: relative;
      overflow: hidden;
      width: 100%;
      height: 80px;
      border: 1px solid #cecece; }
      #gopoint .merchant-list .content .blog-card-img img {
        position: relative;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%); }
    #gopoint .merchant-list .content .blog-card-title {
      min-height: 250px; }
  #gopoint #content-only .container {
    box-shadow: 0 -3em 0 1.5em #F79520; }
    #gopoint #content-only .container h4 {
      color: #F79520;
      line-height: 1.5; }
    #gopoint #content-only .container .line {
      background: #F79520; }
    #gopoint #content-only .container .contact {
      background: #F79520; }

@media screen and (max-width: 35.5em) {
  #gopoint header.ramadhan .desc--row {
    bottom: 25%; }
  #gopoint header.ramadhan .desc--wrapper .hero--desc {
    display: inherit; }
  #gopoint section.join .banner img {
    height: 120px !important; }
  #gopoint section.ramadhan {
    padding-bottom: 0; }
    #gopoint section.ramadhan .description p {
      font-size: 12px; }
  #gopoint .how-it-works--wrapper.desktop {
    display: none; }
  #gopoint .how-it-works--wrapper-mobile {
    display: block; }
    #gopoint .how-it-works--wrapper-mobile li {
      font-family: "Open Sans", Helvetica, sans-serif !important;
      font-size: 12px;
      font-weight: 300;
      line-height: 1.62;
      letter-spacing: 0.8px; }
  #gopoint .section-our-values {
    padding: 0;
    margin-top: 50px; }
  #gopoint #bg-as-seen-on img {
    padding: 0; }
  #gopoint .merchant-list h5 {
    color: #F79520; }
  #gopoint .merchant-list .content .blog-card-title {
    min-height: 140px; } }

@media screen and (min-width: 35.6em) {
  #gopoint #join-now {
    max-width: 150%; } }

@media screen and (max-width: 320px) {
  #gopoint header.ramadhan {
    background-size: contain; }
  #gopoint section.join .form-1 {
    padding: 15px !important; } }

@media screen and (min-width: 48em) {
  #gopoint #bg-as-seen-on.ramadhan .title hr {
    top: 3.3em; }
  #gopoint #bg-as-seen-on.ramadhan .title p {
    font-size: 18px; } }

@media screen and (max-width: 768px) {
  #gopoint section.timeline ul {
    margin: 20px auto 0 auto; }
    #gopoint section.timeline ul li {
      margin-left: 5%;
      margin-bottom: 0px;
      float: none; }
      #gopoint section.timeline ul li .wrapper {
        height: 100%;
        padding: 10px 0px;
        width: 450px; }
        #gopoint section.timeline ul li .wrapper div {
          min-height: inherit; }
        #gopoint section.timeline ul li .wrapper h4 {
          margin: 0;
          height: auto !important; }
    #gopoint section.timeline ul li.done::before {
      left: 0%;
      top: 18%; }
    #gopoint section.timeline ul li.done:after {
      height: 100%;
      width: 3px;
      left: -49%;
      top: -65%; }
    #gopoint section.timeline ul li:before {
      left: 0%;
      top: 18%; }
    #gopoint section.timeline ul li:after {
      height: 100%;
      width: 3px;
      left: -35%;
      top: -50% !important; } }

@media screen and (max-width: 767px) {
  #gopoint section.timeline {
    padding-bottom: 0px;
    padding-top: 0px; }
    #gopoint section.timeline ul {
      margin: 20px auto 0 auto; }
      #gopoint section.timeline ul li {
        margin-left: 5%;
        margin-bottom: 0px;
        float: none; }
        #gopoint section.timeline ul li .wrapper {
          height: 100%;
          padding: 10px 0px;
          width: 250px; }
          #gopoint section.timeline ul li .wrapper div {
            min-height: inherit; }
          #gopoint section.timeline ul li .wrapper h4 {
            margin: 0;
            height: auto !important; }
      #gopoint section.timeline ul li.done::before {
        left: 25%;
        top: 30%; }
      #gopoint section.timeline ul li.done:after {
        height: 100%;
        width: 3px;
        left: -35%;
        top: -65%; }
      #gopoint section.timeline ul li:before {
        left: 25%;
        top: 35%; }
      #gopoint section.timeline ul li:after {
        height: 100%;
        width: 3px;
        left: -35%;
        top: -65%; } }

#goglam h5 {
  color: #F79520; }

#goglam .articles--feed ellipse {
  stroke: #fff !important; }

#goglam .articles--feed polygon {
  fill: #fff !important; }

#goglam .section-our-values {
  padding: 3em 0;
  background: #f8f8f8; }
  #goglam .section-our-values h4.title {
    color: #E67498; }
  #goglam .section-our-values .line.gradient {
    background: #E67498; }
  #goglam .section-our-values .list h4 {
    line-height: 1.8em;
    font-size: 0.7em; }
  #goglam .section-our-values .list img {
    max-height: 100px;
    margin: 3em 0; }
  #goglam .section-our-values .list p {
    font-size: 11px; }

#goglam .section-user-review .slick-dots li.slick-active button:before {
  color: #aa2d53; }

#goglam .merchant-list h5 {
  color: #E67498; }

#goglam .merchant-list hr {
  background: #E67498; }

#goglam .merchant-list .line.gradient {
  background: #E67498; }

#goglam .merchant-list .list h4 {
  line-height: 25px;
  font-size: 15px; }

#goglam #talent-story h4 {
  color: #E67498; }
  #goglam #talent-story h4:before {
    background-color: #E67498; }

#goglam #talent-story hr {
  background: #E67498; }

#goglam #talent-story .detail h2 {
  color: #E67498; }

#goglam #talent-story .info h5 {
  color: #E67498; }

#goglam .articles--feed .bg {
  background: #E67498; }

#goglam .articles--feed .read--more > a {
  background: #E67498; }

#goglam .articles--feed .content .one {
  border-bottom: 6px solid #E67498; }

#goglam .articles--feed .line--title {
  background: #fff; }

#goglam .articles--feed .content .two {
  border-bottom: 6px solid #E67498; }

#goglam .articles--feed .content .three {
  border-bottom: 6px solid #E67498; }

#goglam .articles--feed .content .four {
  border-bottom: 6px solid #E67498; }

#goglam .articles--feed h4 > a:hover {
  color: #E67498; }

#goglam .section-user-review .head .title {
  color: #E67498; }

#goglam .section-user-review .head .line.gradient {
  background: #E67498; }

#goglam .section-user-review .btm.layer {
  position: relative;
  width: 100%;
  left: 0; }

#goglam .section-user-review .item .wrapper {
  min-height: 350px; }

#goglam .section-user-review .wrapper .line.light.yellow {
  background: white; }

#goglam .section-user-review .slider .item .wrapper.style-2 {
  background: #E67498; }

#goglam .section-user-review .slider .item .wrapper.style-3 {
  background: #aa2d53; }

#goglam .services .container:before {
  background: #aa2d53; }

#goglam .services h4 {
  color: #aa2d53; }

#goglam .services .wrapper .service-container {
  background: #e77499; }

#goglam .services .service-list {
  max-height: 23em; }
  #goglam .services .service-list h4 {
    padding: 0;
    margin: 0;
    margin-top: 1.2em;
    margin-bottom: 1em; }
  #goglam .services .service-list p {
    margin-top: 0; }

#goglam #talent-story .slider .slick-slide {
  min-height: 350px !important; }

#goglam section#new-join {
  margin: 48px 0px 100px 0px; }
  #goglam section#new-join .container-form .content form .separate-input label.checkbox > label[for] {
    text-transform: capitalize;
    width: 49%;
    height: 17px;
    object-fit: contain;
    font-family: "Open Sans", Helvetica, sans-serif;
    font-size: inherit;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 1.3px; }
  @media screen and (max-width: 767px) {
    #goglam section#new-join .container-form .content form .separate-input label.checkbox {
      width: 100%; } }

#goglam #content-only.thank-you {
  margin-top: 130px; }
  #goglam #content-only.thank-you a.contact {
    width: 300px; }
  @media screen and (max-width: 768px) {
    #goglam #content-only.thank-you {
      margin-bottom: 130px; }
      #goglam #content-only.thank-you a.contact {
        width: 100%;
        line-height: normal; } }
  #goglam #content-only.thank-you .container {
    background: white;
    box-shadow: 0 -3em 0 1.5em #E67498; }
    #goglam #content-only.thank-you .container .info h4 {
      color: #E67498; }
    #goglam #content-only.thank-you .container .info .line {
      background: #E67498; }
    #goglam #content-only.thank-you .container .info .link a {
      width: 280px; }
    #goglam #content-only.thank-you .container .info .link .contact {
      margin-bottom: 15px; }
      #goglam #content-only.thank-you .container .info .link .contact span {
        margin: 0 15px; }
    #goglam #content-only.thank-you .container .info .link .contact.homepage {
      background: #c8c8c8; }

#goglam #join-now h2 {
  text-transform: capitalize;
  font-size: 2em; }

#goglam #join-now a {
  background: #E67498; }

#goglam #join-now .container {
  position: relative; }
  #goglam #join-now .container .left {
    height: 100%;
    position: absolute;
    background-size: cover;
    background-position: center center; }
  #goglam #join-now .container .right {
    left: 58.3333%; }

@media screen and (max-width: 768px) {
  #goglam section.services#golife {
    padding-bottom: 12em; }
  #goglam .section-user-review {
    padding: 80px 0; }
    #goglam .section-user-review .item .wrapper {
      min-height: 300px; }
    #goglam .section-user-review .slider .item .wrapper h3.user-testimonial {
      height: 125px; } }

@media screen and (max-width: 35.5em) {
  #goglam #talent-story .slick-dots li.slick-active button:before {
    color: #aa2d53; }
  #goglam .section-our-values {
    padding: 0; }
  #goglam .section-user-review .item .wrapper {
    min-height: 300px; }
  #goglam #join-now .container .left {
    position: relative;
    height: 250px; }
  #goglam #join-now .container .right {
    left: 0; }
  #goglam section.services#golife {
    padding-bottom: 0px !important; } }

@media screen and (min-width: 48em) {
  #goglam .section-our-values .list img {
    max-height: 100px;
    margin: 5em 0; } }

#gomassage .section-user-review .slider .item .wrapper h3.user-testimonial {
  height: 25px; }

#gomassage .new-section-how-to-use {
  margin-top: 180px;
  margin-bottom: 100px; }
  #gomassage .new-section-how-to-use h3.title {
    color: #18aeab; }
    #gomassage .new-section-how-to-use h3.title:after {
      background: #18aeab; }
  #gomassage .new-section-how-to-use .content {
    background-color: #18aeab; }

#gomassage .articles--feed .line--title {
  background: #485460 !important; }

#gomassage .articles--feed svg ellipse {
  stroke: #485460 !important; }

#gomassage .articles--feed svg polygon {
  fill: #485460 !important; }

#gomassage section#new-join {
  margin: 48px 0px 100px 0px; }
  #gomassage section#new-join .container-form .content form .separate-input label.checkbox > label[for] {
    text-transform: capitalize;
    width: 49%;
    height: 17px;
    object-fit: contain;
    font-family: "Open Sans", Helvetica, sans-serif;
    font-size: inherit;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 1.3px; }
  @media screen and (max-width: 767px) {
    #gomassage section#new-join .container-form .content form .separate-input label.checkbox {
      width: 100%; } }
  #gomassage section#new-join #id_relatives_nb {
    width: 510px;
    height: 60px;
    object-fit: contain;
    font-family: Open Sans,Helvetica,sans-serif;
    font-size: inherit;
    font-weight: 500;
    font-style: italic;
    font-stretch: normal;
    line-height: 1.43;
    letter-spacing: 0.5px;
    color: #99999a; }
  #gomassage section#new-join .hide-input {
    margin-top: 16px; }

#gomassage #content-only.thank-you {
  margin-top: 220px; }
  #gomassage #content-only.thank-you a.contact {
    width: 300px; }
  @media screen and (max-width: 768px) {
    #gomassage #content-only.thank-you {
      margin-bottom: 130px; }
      #gomassage #content-only.thank-you a.contact {
        width: 100%;
        line-height: normal; } }
  #gomassage #content-only.thank-you .container {
    background: white;
    box-shadow: 0 -3em 0 1.5em #18AEAB; }
    #gomassage #content-only.thank-you .container .info h4 {
      color: #18AEAB; }
    #gomassage #content-only.thank-you .container .info .line {
      background: #18AEAB; }
    #gomassage #content-only.thank-you .container .info .link a {
      width: 280px; }
    #gomassage #content-only.thank-you .container .info .link .contact {
      margin-bottom: 15px; }
      #gomassage #content-only.thank-you .container .info .link .contact span {
        margin: 0 15px; }
    #gomassage #content-only.thank-you .container .info .link .contact.homepage {
      background: #c8c8c8; }

#gomassage #content-only {
  padding-top: 2em; }
  #gomassage #content-only .container {
    box-shadow: 0 -3em 0 1.5em #18AEAB; }
    #gomassage #content-only .container .info {
      background: url("../img/page/gomassage/join-bg.jpg") no-repeat center center/cover; }
      #gomassage #content-only .container .info h4 {
        color: #18AEAB; }
      #gomassage #content-only .container .info p {
        color: white; }
      #gomassage #content-only .container .info .line {
        background: #18AEAB; }
      #gomassage #content-only .container .info .link .contact {
        background: #18AEAB; }

#gomassage .section-our-values {
  padding: 3em;
  background: #f8f8f8; }
  #gomassage .section-our-values h4.title {
    color: #18AEAB; }
  #gomassage .section-our-values .line.gradient {
    background: #18AEAB; }
  #gomassage .section-our-values .list h4 {
    line-height: 25px;
    font-size: 15px; }
  #gomassage .section-our-values img {
    margin-top: 40px;
    margin-bottom: 50px;
    max-width: 250px;
    height: 125px; }
  #gomassage .section-our-values img[data-slide="1"] {
    max-width: 127.5px; }

#gomassage .services .service-logo {
  max-width: 320px; }

#gomassage .services .container::before {
  background-color: #18AEAB; }

#gomassage .services h4 {
  color: #18AEAB; }

#gomassage .services h5 {
  font-size: 20px;
  margin-bottom: 0px; }

#gomassage .services .wrapper .service-container {
  height: 15em;
  background: #18AEAB;
  margin: 0 -15px;
  margin-right: -20%; }
  #gomassage .services .wrapper .service-container .service-list {
    min-height: 450px; }

#gomassage .services .wrapper .service-container .service-list .bg-services {
  opacity: 1.0; }

#gomassage .merchant-list h5 {
  color: #18AEAB; }

#gomassage .merchant-list hr {
  background: #18AEAB; }

#gomassage .merchant-list .line.gradient {
  background: #18AEAB; }

#gomassage .merchant-list .list h4 {
  line-height: 25px;
  font-size: 15px; }

#gomassage #talent-story-golife .content img {
  border-radius: 0;
  width: initial;
  height: initial; }

#gomassage #talent-story-golife .detail .info h4 {
  margin-bottom: 1.5em; }

#gomassage .articles--feed .bg {
  background: #18AEAB; }

#gomassage .articles--feed .read--more > a {
  background: #18AEAB; }

#gomassage .articles--feed .content .one {
  border-bottom: 6px solid #18AEAB; }

#gomassage .articles--feed .content .two {
  border-bottom: 6px solid #18AEAB; }

#gomassage .articles--feed .content .three {
  border-bottom: 6px solid #18AEAB; }

#gomassage .articles--feed .content .four {
  border-bottom: 6px solid #18AEAB; }

#gomassage .articles--feed h4 > a:hover {
  color: #18AEAB; }

#gomassage .section-user-review .slick-dots li.slick-active button:before {
  color: #18aeab; }

#gomassage .section-user-review .head .title {
  color: #18AEAB; }

#gomassage .section-user-review .head .line.gradient {
  background: #18AEAB; }

#gomassage .section-user-review .wrapper .line.light.yellow {
  background: white; }

#gomassage .section-user-review .slider .item .wrapper.style-2 {
  background: #18AEAB; }

#gomassage .section-user-review .slider .item .wrapper.style-3 {
  background: #b8e084; }

#gomassage .section-user-review .slider .item .wrapper {
  text-align: center; }
  #gomassage .section-user-review .slider .item .wrapper .img-wrapper {
    margin-right: auto;
    margin-left: auto;
    display: block;
    margin-top: -60px;
    margin-bottom: 25px;
    height: 80px !important;
    width: 80px !important;
    border-radius: 50%;
    background-size: cover !important; }

#gomassage #join-now a {
  background: #18AEAB; }

#gomassage #promo-banner .arrow-up {
  stroke: #18AEAB; }

#gomassage #promo-banner .arrow-up-circle {
  stroke-width: 3;
  stroke: #18AEAB; }

#gomassage #promo-banner .arrow-down {
  stroke: #18AEAB; }

#gomassage #promo-banner .arrow-down-circle {
  stroke: #18AEAB;
  stroke-width: 3; }

#gomassage #promo-banner .img-slide {
  background: url("http://www.go-massage.co.id/assets/gomassage/img/bg-promo.jpg");
  background-size: cover !important; }
  #gomassage #promo-banner .img-slide .img-item div.wrapper {
    padding: 100px; }
  #gomassage #promo-banner .img-slide .img-item h2 {
    font-family: "Lato", Helvetica, sans-serif;
    font-size: 28px;
    text-align: center;
    color: white; }
  #gomassage #promo-banner .img-slide .img-item p {
    color: white;
    text-align: center; }
  #gomassage #promo-banner .img-slide .img-item a {
    transition: all 0.3s ease 0s;
    color: white;
    padding: 15px;
    cursor: pointer;
    color: #fff;
    position: relative;
    top: 20px;
    border: 1px solid #fff; }
  #gomassage #promo-banner .img-slide .img-item a:hover {
    background: #18AEAB;
    border: 1px solid #18AEAB; }

#gomassage #bg-as-seen-on hr {
  background: #18AEAB; }

#gomassage #bg-as-seen-on .title {
  color: #18AEAB; }

#gomassage #bg-as-seen-on #right {
  background: #18AEAB; }

#gomassage .section-how-to-use h5 {
  color: #18AEAB; }

#gomassage .section-how-to-use .line.gradient {
  background: #18AEAB; }

#gomassage .section-how-to-use .arrow-up {
  stroke: #18AEAB; }

#gomassage .section-how-to-use .arrow-up-circle {
  stroke-width: 3;
  stroke: #18AEAB; }

#gomassage .section-how-to-use .arrow-down {
  stroke: #18AEAB; }

#gomassage .section-how-to-use .arrow-down-circle {
  stroke: #18AEAB;
  stroke-width: 3; }

#gomassage .section-how-to-use .slider .slick-dots li.slick-active button:before {
  color: #18AEAB; }

#gomassage .section-how-to-use .slider-image .slick-dots li.slick-active button:before {
  color: #18AEAB; }

@media only screen and (max-width: 480px) {
  #gomassage #promo-banner .img-slide {
    background: url("http://www.go-massage.co.id/assets/gomassage/img/bg-promo.jpg");
    background-size: cover !important; }
    #gomassage #promo-banner .img-slide .img-item div.wrapper {
      padding: 40px;
      margin-top: -40px;
      margin-bottom: 20px; } }

@media screen and (max-width: 48em) {
  #gomassage section.services#golife .wrapper .service-container .service-list {
    min-height: 440px; }
  #gomassage .section-user-review .slider .item {
    height: 300px; }
    #gomassage .section-user-review .slider .item .wrapper h3.user-testimonial {
      height: 40px; }
  #gomassage .services {
    padding-bottom: 20em !important; } }

@media screen and (max-width: 35.5em) {
  #gomassage #talent-story-golife {
    margin: 0; }
    #gomassage #talent-story-golife h4:before {
      top: 3.5% !important; }
  #gomassage .services {
    padding-bottom: 0 !important; }
    #gomassage .services .wrapper .navigation-arrow {
      padding-top: 21em !important; }
  #gomassage .slider .item .wrapper svg {
    max-width: 80px;
    margin: -60px auto 25px auto;
    z-index: 999;
    border-radius: 50%; }
  #gomassage .section-user-review .foot .wrapper .arrow-next,
  #gomassage .section-user-review .foot .wrapper .arrow-prev {
    margin: 10px 50px 5px; } }

#gomed .gradient {
  background: #006738;
  margin-bottom: 0px; }

#gomed .why-container {
  margin-top: 4em; }

#gomed .section-our-values {
  padding-bottom: 0; }
  #gomed .section-our-values img {
    max-height: 125px; }
  #gomed .section-our-values .title {
    color: #006738; }

#gomed #join-now .right {
  padding: 0; }
  #gomed #join-now .right a {
    background: #006738; }
  #gomed #join-now .right .item {
    margin: 2em; }
    #gomed #join-now .right .item h4 {
      margin: 3em 0;
      line-height: 1.28;
      letter-spacing: 2px; }

#gomed .section-how-to-use {
  padding: 50px;
  height: 620px;
  background-size: cover !important; }
  #gomed .section-how-to-use .slider .item-text[data-slide="1"],
  #gomed .section-how-to-use .slider .item-text[data-slide="2"],
  #gomed .section-how-to-use .slider .item-text[data-slide="3"] {
    position: relative;
    top: -30px; }
  #gomed .section-how-to-use .slider .item-text[data-slide="4"],
  #gomed .section-how-to-use .slider .item-text[data-slide="5"] {
    position: relative;
    top: -50px; }
  #gomed .section-how-to-use .slider-image .item[data-slide="3"] img {
    position: relative;
    top: -30px; }
  #gomed .section-how-to-use .slider-image .item[data-slide="4"] img {
    position: relative;
    top: -45px; }
  #gomed .section-how-to-use .slider-image .item[data-slide="5"] img {
    position: relative;
    top: -55px; }
  #gomed .section-how-to-use h5 {
    color: #006738; }
  #gomed .section-how-to-use .line.gradient {
    background: #006738; }
  #gomed .section-how-to-use .title {
    position: relative;
    left: -410px; }
  #gomed .section-how-to-use .arrow-up {
    stroke: #006738; }
  #gomed .section-how-to-use .arrow-up-circle {
    stroke-width: 3;
    stroke: #006738; }
  #gomed .section-how-to-use .arrow-down {
    stroke: #006738; }
  #gomed .section-how-to-use .arrow-down-circle {
    stroke: #006738;
    stroke-width: 3; }
  #gomed .section-how-to-use .slider .slick-dots li.slick-active button:before {
    color: #006738; }
  #gomed .section-how-to-use .slider-image .slick-dots li.slick-active button:before {
    color: #006738; }
  #gomed .section-how-to-use .text-mobile {
    display: none;
    text-align: center; }
  #gomed .section-how-to-use .text-mobile .line.gradient {
    width: 50px;
    height: 3px;
    margin-top: 0px;
    background: #006738;
    margin-left: auto;
    margin-right: auto; }
  #gomed .section-how-to-use .text h5 {
    margin-top: 100px;
    color: #006738;
    font-weight: 900; }
  #gomed .section-how-to-use .text .line.gradient {
    width: 50px;
    height: 3px;
    margin-top: 0px;
    background: #006738; }
  #gomed .section-how-to-use .phone-base {
    display: block;
    margin-right: auto;
    margin-left: auto;
    top: 50px;
    position: relative; }
  #gomed .section-how-to-use .slider .item-text {
    margin-top: 60px; }
  #gomed .section-how-to-use .slider .item-text h2 {
    margin-top: 20px;
    margin-left: 0px;
    font-weight: 300; }
  #gomed .section-how-to-use .slider .item .wrapper {
    width: 100%; }
  #gomed .section-how-to-use .slider-image .slick-list {
    position: relative;
    left: -155px;
    top: -6px; }
  #gomed .section-how-to-use .slider-image .slick-dots {
    position: relative;
    top: -350px;
    left: 30px; }
  #gomed .section-how-to-use .slider-image .slick-dots li {
    display: block; }
  #gomed .section-how-to-use .slider-image .slick-dots li.slick-active button:before {
    color: #006738; }
  #gomed .section-how-to-use .slider-image .slick-dots li button:before {
    font-size: 13px; }
  #gomed .section-how-to-use .slider .slider-text.title {
    position: relative;
    float: right;
    left: -265px;
    top: -20px;
    font-size: 36px;
    margin-top: -320px;
    font-weight: 300; }
  #gomed .section-how-to-use .slider .slider-text.desc {
    margin-left: 380px;
    position: relative;
    top: -280px;
    left: 45px;
    margin-bottom: -33px;
    line-height: 1.56;
    font-weight: 300;
    letter-spacing: 1.7px; }
  #gomed .section-how-to-use .slider-image {
    position: relative;
    z-index: 99 !important;
    margin-top: -425px;
    margin-bottom: -98px;
    text-align: center;
    margin-right: auto;
    margin-left: auto;
    display: block; }
    #gomed .section-how-to-use .slider-image .phone-slider {
      display: block;
      max-width: 260px;
      margin-right: auto;
      margin-left: auto;
      margin-bottom: 10px;
      position: relative;
      left: 155px;
      top: -17px; }
  #gomed .section-how-to-use ul {
    position: relative;
    top: 35%;
    list-style-type: none; }
  #gomed .section-how-to-use ul li {
    margin-top: 10px; }
  #gomed .section-how-to-use ul li img {
    cursor: pointer; }

@media screen and (max-width: 480px) {
  #gomed .section-how-to-use {
    min-height: 815px;
    padding: 50px;
    background-size: cover !important; }
    #gomed .section-how-to-use .slider .item-text[data-slide="1"],
    #gomed .section-how-to-use .slider .item-text[data-slide="2"],
    #gomed .section-how-to-use .slider .item-text[data-slide="3"] {
      position: relative;
      top: 0px; }
    #gomed .section-how-to-use .slider .item-text[data-slide="4"],
    #gomed .section-how-to-use .slider .item-text[data-slide="5"] {
      position: relative;
      top: 0px; }
    #gomed .section-how-to-use .slick-slider {
      display: table;
      table-layout: fixed;
      width: 100%; }
    #gomed .section-how-to-use .text-mobile {
      text-align: center;
      display: block;
      margin-right: auto;
      margin-left: auto; }
    #gomed .section-how-to-use .text {
      display: none; }
    #gomed .section-how-to-use .text h5 {
      margin-top: 100px;
      display: none;
      color: #006738;
      font-weight: 900; }
    #gomed .section-how-to-use .text .line.gradient {
      width: 50px;
      height: 3px;
      margin-top: 0px;
      display: none;
      background: #006738; }
    #gomed .section-how-to-use .phone-base {
      display: block;
      margin-right: auto;
      margin-left: auto;
      top: 50px;
      margin-top: 40px;
      position: relative; }
    #gomed .section-how-to-use .slider .item-text {
      margin-top: 100px; }
    #gomed .section-how-to-use .slider .item-text h2 {
      margin-top: 20px;
      margin-left: 0px;
      font-weight: 300; }
    #gomed .section-how-to-use .slider .item .wrapper {
      width: 100%; }
    #gomed .section-how-to-use .slider-image .slick-list {
      position: relative;
      left: -155px;
      top: -6px; }
    #gomed .section-how-to-use .slider-image ul {
      display: none !important; }
    #gomed .section-how-to-use .slider-image .slick-dots {
      position: relative; }
    #gomed .section-how-to-use .slider-image .slick-dots li {
      display: block; }
    #gomed .section-how-to-use .slider-image .slick-dots li.slick-active button:before {
      color: #006738; }
    #gomed .section-how-to-use .slider-image .slick-dots li button:before {
      font-size: 13px; }
    #gomed .section-how-to-use .slider .slider-text.title {
      position: relative;
      float: right;
      left: -265px;
      top: -20px;
      font-size: 36px;
      margin-top: -320px;
      font-weight: 300; }
    #gomed .section-how-to-use .slider .slider-text.desc {
      margin-left: 380px;
      position: relative;
      top: -280px;
      left: 45px;
      margin-bottom: -33px;
      line-height: 1.56;
      font-weight: 300;
      letter-spacing: 1.7px; }
    #gomed .section-how-to-use .slider-image {
      position: relative;
      z-index: 99 !important;
      margin-top: -425px;
      margin-bottom: -128px;
      text-align: center;
      margin-right: auto;
      margin-left: auto;
      display: block;
      position: relative;
      left: 155px; }
      #gomed .section-how-to-use .slider-image .phone-slider {
        display: block;
        max-width: 260px;
        margin-right: auto;
        margin-left: auto;
        margin-bottom: 10px;
        position: relative;
        left: 0;
        top: -17px; }
    #gomed .section-how-to-use ul {
      position: relative;
      top: 0px;
      display: none;
      margin-bottom: -100px;
      left: 0px;
      list-style-type: none; }
    #gomed .section-how-to-use ul li {
      margin-top: 10px; }
    #gomed .section-how-to-use ul li img {
      cursor: pointer; } }

@media screen and (max-width: 440px) {
  #gomed .section-how-to-use .phone-base {
    display: block;
    margin-right: auto;
    margin-left: auto;
    top: 50px;
    left: 0px;
    margin-top: 40px;
    position: relative; }
  #gomed .section-how-to-use .slider-image .phone-slider {
    display: block;
    max-width: 260px;
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 10px;
    position: relative;
    left: 0px;
    top: -17px; } }

@media screen and (max-width: 400px) {
  #gomed .section-how-to-use {
    min-height: 835px; }
    #gomed .section-how-to-use .phone-base {
      display: block;
      margin-right: auto;
      margin-left: auto;
      top: 50px;
      left: -25px;
      margin-top: 40px;
      position: relative; }
    #gomed .section-how-to-use .slider-image .phone-slider {
      display: block;
      max-width: 260px;
      margin-right: auto;
      margin-left: auto;
      margin-bottom: 10px;
      position: relative;
      left: -25px;
      top: -17px; } }

@media screen and (max-width: 320px) {
  #gomed .section-how-to-use {
    min-height: 835px; }
    #gomed .section-how-to-use .phone-base {
      display: block;
      margin-right: auto;
      margin-left: auto;
      top: 50px;
      left: -55px;
      margin-top: 40px;
      position: relative; }
    #gomed .section-how-to-use .slider-image .phone-slider {
      display: block;
      max-width: 260px;
      margin-right: auto;
      margin-left: auto;
      margin-bottom: 10px;
      position: relative;
      left: 0;
      top: -17px; }
    #gomed .section-how-to-use .slider-image .slick-list {
      position: relative;
      left: -210px;
      top: -6px; } }

#gomed .about .description {
  padding-bottom: 0; }
  #gomed .about .description a {
    font-size: inherit; }

#gomed .about h4,
#gomed .about a,
#gomed .about a::after {
  color: #006738; }

#gomed .about p {
  padding: 0; }

#gomed img .halodoc {
  max-width: 450px; }

@media screen and (max-width: 35.5em) {
  #gomed header#main--hero p {
    display: none; }
  #gomed #join-now .right {
    padding-bottom: 2em; }
  #gomed #join-now img {
    width: 100%; }
  #gomed .section-our-values .title {
    line-height: 1.5em; } }

@media screen and (max-width: 48em) {
  #gomed #join-now .right {
    padding-bottom: 2em; } }

#gomed .join .section-our-values {
  padding: 60px 0 !important; }
  #gomed .join .section-our-values .list h4 {
    text-transform: none; }

#gomed .join .section-join-form {
  padding: 60px 0; }

@-webkit-keyframes uil-ring-anim {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

@-moz-keyframes uil-ring-anim {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

@-webkit-keyframes uil-ring-anim {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

@-o-keyframes uil-ring-anim {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

@keyframes uil-ring-anim {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }
  #gomed .join .section-join-form .uil-ring-css {
    background: none;
    position: relative;
    width: 200px;
    height: 200px;
    margin: 0 auto;
    display: block; }
    #gomed .join .section-join-form .uil-ring-css div {
      position: absolute;
      display: block;
      width: 160px;
      height: 160px;
      top: 20px;
      left: 20px;
      border-radius: 80px;
      box-shadow: 0 3px 0 0 #006738;
      -webkit-transform-origin: 80px 81.5px;
      transform-origin: 80px 81.5px;
      -webkit-animation: uil-ring-anim 1s linear infinite;
      animation: uil-ring-anim 1s linear infinite; }
  #gomed .join .section-join-form .loader-wrapper {
    display: none;
    position: relative;
    top: 140px;
    margin-bottom: -200px; }
  #gomed .join .section-join-form .main-wrapper {
    background: #fff;
    height: 700px;
    width: 100%;
    box-shadow: 0px 0px 50px rgba(90, 90, 90, 0.25); }
    #gomed .join .section-join-form .main-wrapper .text-wrapper .title {
      margin-top: 30px;
      font-size: 12px;
      position: relative;
      left: 50px;
      top: 10px;
      color: #c8c8c8; }
    #gomed .join .section-join-form .main-wrapper .background-wrapper {
      background: url("https://d3naj63yelf4gk.cloudfront.net/dist/img/card/join-form/gomed-texture.jpg");
      min-height: 700px;
      height: 100% !important;
      width: 100%;
      padding: 0;
      text-align: center; }
      #gomed .join .section-join-form .main-wrapper .background-wrapper img {
        max-width: 60px;
        margin-top: 260px; }
    #gomed .join .section-join-form .main-wrapper .form-wrapper {
      padding: 0 50px; }
      #gomed .join .section-join-form .main-wrapper .form-wrapper .title {
        margin-top: -15px;
        font-size: 12px;
        position: relative;
        top: 10px;
        color: #c8c8c8; }
      #gomed .join .section-join-form .main-wrapper .form-wrapper .group {
        width: 100%;
        margin-top: 30px;
        margin-bottom: 30px; }
        #gomed .join .section-join-form .main-wrapper .form-wrapper .group label {
          font-family: "Open Sans", sans-serif;
          font-weight: 700;
          font-size: 12.5px;
          color: #697b8c; }
        #gomed .join .section-join-form .main-wrapper .form-wrapper .group input,
        #gomed .join .section-join-form .main-wrapper .form-wrapper .group select {
          width: 100%;
          height: 45px;
          padding: 15px;
          margin-top: 5px;
          letter-spacing: 1.2px;
          font-size: 13.4px;
          background: white;
          border-radius: 0 !important;
          border: 1px solid #d2d2d2;
          color: #485460; }
        #gomed .join .section-join-form .main-wrapper .form-wrapper .group input[type=number]::-webkit-inner-spin-button,
        #gomed .join .section-join-form .main-wrapper .form-wrapper .group input[type=number]::-webkit-outer-spin-button {
          -webkit-appearance: none;
          -moz-appearance: none;
          appearance: none;
          margin: 0; }
        #gomed .join .section-join-form .main-wrapper .form-wrapper .group select {
          cursor: pointer;
          appearance: none;
          -webkit-appearance: none; }
        #gomed .join .section-join-form .main-wrapper .form-wrapper .group textarea {
          width: 100%;
          height: 145px;
          padding: 15px;
          margin-top: 5px;
          letter-spacing: 1.2px;
          font-size: 13.4px;
          background: white;
          border-radius: 0 !important;
          border: 1px solid #d2d2d2;
          color: #485460; }
        #gomed .join .section-join-form .main-wrapper .form-wrapper .group .icon {
          float: right; }
          #gomed .join .section-join-form .main-wrapper .form-wrapper .group .icon i {
            position: relative;
            left: -15px;
            top: 35px;
            font-size: 12px;
            color: #d2d2d2; }
    #gomed .join .section-join-form .main-wrapper .form-wrapper.second-column {
      position: relative;
      top: 52.5px; }
    #gomed .join .section-join-form .main-wrapper .error_messages {
      color: red;
      letter-spacing: .1px;
      margin-top: 10px; }
    #gomed .join .section-join-form .main-wrapper .form-button {
      position: relative;
      top: 25px;
      left: -40px; }
      #gomed .join .section-join-form .main-wrapper .form-button .button {
        font-size: 14px;
        font-family: "Open Sans", Helvetica, sans-serif;
        font-weight: 400;
        padding: 35px;
        border-radius: 5px;
        transition: all .5s ease 0s;
        margin: 0 5px;
        box-shadow: unset; }
        #gomed .join .section-join-form .main-wrapper .form-button .button span {
          margin: 0 50px; }
      #gomed .join .section-join-form .main-wrapper .form-button .button-submit-go {
        text-transform: none;
        background: #006738;
        color: #fff;
        float: right;
        padding: 24px; }

#gomed .join.thankyou #content-only {
  margin-top: 100px;
  margin-bottom: 50px; }
  #gomed .join.thankyou #content-only .container {
    background: white;
    box-shadow: 0 -3em 0 1.5em #006738; }
    #gomed .join.thankyou #content-only .container .info h4 {
      color: #006738; }
    #gomed .join.thankyou #content-only .container .info .line {
      background: #006738; }
    #gomed .join.thankyou #content-only .container .info .link a {
      width: 280px; }
    #gomed .join.thankyou #content-only .container .info .link .contact {
      background: #006738; }
      #gomed .join.thankyou #content-only .container .info .link .contact span {
        margin: 0 15px; }

@media screen and (max-width: 768px) {
  #gomed .join .section-join-form {
    margin-top: 50px;
    padding: 0px; }
    #gomed .join .section-join-form .main-wrapper {
      background: #fff;
      margin: 50px 0;
      height: auto;
      width: 100%;
      box-shadow: 0px 0px 50px rgba(90, 90, 90, 0.25); }
      #gomed .join .section-join-form .main-wrapper .background-wrapper {
        min-height: 100px;
        height: 100% !important;
        width: 100%;
        padding: 0;
        text-align: center; }
        #gomed .join .section-join-form .main-wrapper .background-wrapper img {
          max-width: 25px;
          margin-top: 40px; }
      #gomed .join .section-join-form .main-wrapper .text-wrapper {
        margin-bottom: 35px;
        margin-top: 10px;
        position: relative;
        left: -10px; }
        #gomed .join .section-join-form .main-wrapper .text-wrapper .title {
          margin-top: -15px;
          font-size: 12px;
          position: relative;
          top: 10px;
          color: #c8c8c8; }
      #gomed .join .section-join-form .main-wrapper .form-container {
        margin-bottom: 50px;
        padding: 20px; }
      #gomed .join .section-join-form .main-wrapper .form-wrapper {
        padding: 0 !important; }
        #gomed .join .section-join-form .main-wrapper .form-wrapper .group {
          width: 100%;
          margin: 20px 0; }
          #gomed .join .section-join-form .main-wrapper .form-wrapper .group label {
            font-family: "Open Sans", Helvetica, sans-serif;
            font-weight: 700;
            text-transform: uppercase;
            letter-spacing: 1.25px;
            font-size: 10.5px;
            color: #697b8c; }
          #gomed .join .section-join-form .main-wrapper .form-wrapper .group input,
          #gomed .join .section-join-form .main-wrapper .form-wrapper .group select {
            width: 100%;
            height: 45px;
            padding: 15px;
            margin-top: 5px;
            letter-spacing: 1.2px;
            font-size: 13.4px;
            background: white;
            border-radius: 0 !important;
            border: 1px solid #d2d2d2;
            color: #485460; }
          #gomed .join .section-join-form .main-wrapper .form-wrapper .group .icon {
            float: right; }
            #gomed .join .section-join-form .main-wrapper .form-wrapper .group .icon i {
              position: relative;
              left: -15px;
              top: 35px;
              font-size: 12px;
              color: #d2d2d2; }
        #gomed .join .section-join-form .main-wrapper .form-wrapper .error_messages {
          color: red;
          margin-top: 10px; }
      #gomed .join .section-join-form .main-wrapper .form-wrapper.second-column {
        position: relative;
        top: 10px; }
      #gomed .join .section-join-form .main-wrapper .form-button {
        position: relative;
        top: -10px;
        left: -40px; }
        #gomed .join .section-join-form .main-wrapper .form-button .button {
          font-size: 14px;
          font-family: "Open Sans", Helvetica, sans-serif;
          font-weight: 400;
          padding: 35px;
          transition: all .5s ease 0s;
          margin: 0 5px; }
          #gomed .join .section-join-form .main-wrapper .form-button .button span {
            margin: 0 50px; }
        #gomed .join .section-join-form .main-wrapper .form-button .button:hover,
        #gomed .join .section-join-form .main-wrapper .form-button .button:focus {
          box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.65); }
        #gomed .join .section-join-form .main-wrapper .form-button .button-submit-go {
          text-transform: none;
          background: #98ce00;
          color: #fff;
          float: none;
          position: relative;
          left: 53px;
          width: 90%;
          margin-bottom: 10px;
          padding: 24px; } }

#goauto #join-now {
  margin-bottom: 30px; }
  #goauto #join-now .right {
    padding: 2em; }
    #goauto #join-now .right a {
      background-color: #1f4864;
      bottom: auto;
      right: 0; }

#goauto .new-section-how-to-use {
  margin-top: 180px;
  margin-bottom: 100px; }
  #goauto .new-section-how-to-use h3.title {
    color: #1f4864; }
    #goauto .new-section-how-to-use h3.title:after {
      background: #343b41; }
  #goauto .new-section-how-to-use .content {
    background-color: #1f4864; }
  @media screen and (max-width: 35.5em) {
    #goauto .new-section-how-to-use {
      margin-bottom: 20px; } }

#goauto .messages-alert {
  position: fixed;
  display: none;
  background-color: #1f4864;
  width: 100%;
  padding: 20px;
  margin-top: 0px;
  z-index: 99;
  top: 86px;
  margin-bottom: -80px;
  box-shadow: 0px -5px 25px rgba(10, 10, 10, 0.85); }
  #goauto .messages-alert h5 {
    color: white;
    font-size: 15px;
    margin: 0;
    padding: 0; }

@-webkit-keyframes uil-ring-anim {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

@-moz-keyframes uil-ring-anim {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

@-webkit-keyframes uil-ring-anim {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

@-o-keyframes uil-ring-anim {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

@keyframes uil-ring-anim {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

#goauto .uil-ring-css {
  background: none;
  position: relative;
  width: 200px;
  height: 200px;
  position: relative;
  margin: 0 auto;
  display: none;
  margin-bottom: -500px;
  /* display: none; */
  top: 370px; }
  #goauto .uil-ring-css div {
    position: absolute;
    display: block;
    width: 160px;
    height: 160px;
    top: 20px;
    left: 20px;
    border-radius: 80px;
    box-shadow: 0 3px 0 0 #98ce00;
    -webkit-transform-origin: 80px 81.5px;
    transform-origin: 80px 81.5px;
    -webkit-animation: uil-ring-anim 1s linear infinite;
    animation: uil-ring-anim 1s linear infinite; }

#goauto #talent-story-golife h4 {
  color: #4a4a4a !important; }

#goauto #talent-story-golife h4:before,
#goauto #talent-story-golife a {
  background-color: #4a4a4a !important; }

#goauto #talent-story-golife .content img {
  border-radius: 0;
  width: initial;
  height: initial; }

#goauto #talent-story-golife .detail .info h4 {
  margin-bottom: 1.5em; }

#goauto .section-our-values {
  padding: 3em;
  background: #f8f8f8; }
  #goauto .section-our-values h4.title {
    color: #343b41; }
  #goauto .section-our-values .line.gradient {
    background: #343b41; }
  #goauto .section-our-values .list h4 {
    line-height: 25px;
    font-size: 15px; }
  #goauto .section-our-values .list img {
    height: 125px; }

#goauto .how-it-works--wrapper .section--title {
  color: #343b41; }

#goauto .how-it-works--wrapper a {
  text-decoration: none;
  color: #485460;
  cursor: pointer; }

#goauto .how-it-works--wrapper .section--title:before {
  background-color: #343b41; }

#goauto .how-it-works--wrapper .layer .rect--secondary {
  background: #ffce00; }

#goauto .how-it-works--wrapper .layer .rect--primary {
  background: #343b41; }

#goauto #content-only.thank-you {
  margin-top: 100px; }
  #goauto #content-only.thank-you .container {
    background: white;
    box-shadow: 0 -3em 0 1.5em #BABCBE; }
    #goauto #content-only.thank-you .container .info h4 {
      color: #BABCBE; }
    #goauto #content-only.thank-you .container .info .line {
      background: #BABCBE; }
    #goauto #content-only.thank-you .container .info .link a {
      width: 280px; }
    #goauto #content-only.thank-you .container .info .link .contact {
      background: #1f4864; }
      #goauto #content-only.thank-you .container .info .link .contact span {
        margin: 0 15px; }

#goauto #talent-story h4:before {
  background-color: #343b41;
  top: 6%; }

#goauto #talent-story h4 {
  color: #343b41; }

#goauto #talent-story hr {
  background: #343b41;
  top: 3.75%; }

#goauto #talent-story .detail h2 {
  color: #343b41; }

#goauto #talent-story .slick-slide {
  outline: none; }

#goauto #talent-story .info h5 {
  color: #343b41; }

#goauto .articles--feed .bg {
  background: #1f4864; }

#goauto .articles--feed .section--title {
  color: white; }

#goauto .articles--feed svg ellipse {
  stroke: #ffffff !important; }

#goauto .articles--feed svg polygon {
  fill: #ffffff !important; }

#goauto .articles--feed .line--title {
  background: white; }

#goauto .articles--feed .read--more > a {
  background: #BABCBE; }

#goauto .articles--feed .content .one {
  border-bottom: 6px solid #BABCBE; }

#goauto .articles--feed .content .two {
  border-bottom: 6px solid #BABCBE; }

#goauto .articles--feed .content .three {
  border-bottom: 6px solid #BABCBE; }

#goauto .articles--feed .content .four {
  border-bottom: 6px solid #BABCBE; }

#goauto .articles--feed h4 > a:hover {
  color: #BABCBE; }

#goauto #bg-as-seen-on hr {
  background: #BABCBE; }

#goauto #bg-as-seen-on .title {
  color: #BABCBE; }

#goauto #bg-as-seen-on #right {
  background: #BABCBE; }

#goauto .section-how-to-use .text .line.gradient {
  background: #343b41; }

#goauto .section-how-to-use .text h5 {
  color: #343b41; }

#goauto .section-how-to-use .slider-image .slick-dots li.slick-active button:before {
  color: #343b41; }

#goauto section.services h4 {
  color: #343b41;
  line-height: 1.2; }

#goauto section.services .container:before {
  background: #343b41; }

#goauto section.services .wrapper .service-container {
  background: #babcbf; }

#goauto section.join {
  margin: 120px 0; }
  #goauto section.join .hidden {
    display: none; }
  #goauto section.join .banner img {
    height: 100%;
    width: 100%; }
  #goauto section.join .form-1,
  #goauto section.join .form-2 {
    padding: 50px;
    background: white;
    position: relative; }
    #goauto section.join .form-1 .error-input,
    #goauto section.join .form-2 .error-input {
      border: 1px solid #E0164A !important; }
    #goauto section.join .form-1 .error-messages,
    #goauto section.join .form-2 .error-messages {
      color: red;
      font-style: italic; }
    #goauto section.join .form-1 .button-disabled,
    #goauto section.join .form-2 .button-disabled {
      cursor: not-allowed;
      box-shadow: none !important;
      background: #c8c8c8 !important; }
    #goauto section.join .form-1 .combo-box,
    #goauto section.join .form-2 .combo-box {
      position: relative; }
    #goauto section.join .form-1 .combo-box:after,
    #goauto section.join .form-2 .combo-box:after {
      content: '\f0d7';
      font-family: 'FontAwesome';
      flex-direction: column;
      justify-content: center;
      right: 15px;
      top: 30px;
      text-align: right;
      position: absolute; }
    #goauto section.join .form-1 .combo-box-long:after,
    #goauto section.join .form-2 .combo-box-long:after {
      top: 50px; }
    #goauto section.join .form-1 .title,
    #goauto section.join .form-2 .title {
      position: relative;
      font-size: 20px;
      padding-left: 30px;
      padding: 0; }
      #goauto section.join .form-1 .title p,
      #goauto section.join .form-2 .title p {
        font-weight: bold;
        letter-spacing: 1.5px;
        color: #bcbdc0; }
        #goauto section.join .form-1 .title p span::before,
        #goauto section.join .form-2 .title p span::before {
          font-weight: bold; }
    #goauto section.join .form-1 .text-info,
    #goauto section.join .form-2 .text-info {
      margin-top: 30px;
      position: relative;
      padding-left: 25px;
      line-height: 1.46;
      letter-spacing: 1px;
      color: #bcbdc0; }
      #goauto section.join .form-1 .text-info p:first-child,
      #goauto section.join .form-2 .text-info p:first-child {
        text-transform: uppercase; }
      #goauto section.join .form-1 .text-info p,
      #goauto section.join .form-2 .text-info p {
        margin: 0; }
    #goauto section.join .form-1 .text-info::before,
    #goauto section.join .form-2 .text-info::before {
      content: "";
      height: 3px;
      width: 15px;
      background: #093c5b;
      position: absolute;
      left: 0;
      top: 20%; }
    #goauto section.join .form-1 label,
    #goauto section.join .form-2 label {
      font-size: 14px;
      font-family: "Open Sans", Helvetica, sans-serif;
      margin-bottom: 1em;
      letter-spacing: 1.5px;
      color: #697b8c;
      font-weight: 700;
      margin: 0;
      text-transform: uppercase; }
    #goauto section.join .form-1 textarea,
    #goauto section.join .form-2 textarea {
      resize: none;
      width: 100%;
      height: 234px; }
    #goauto section.join .form-1 input[type=text],
    #goauto section.join .form-1 select,
    #goauto section.join .form-1 textarea,
    #goauto section.join .form-2 input[type=text],
    #goauto section.join .form-2 select,
    #goauto section.join .form-2 textarea {
      border: 1px solid #c3c9d7;
      width: 100%;
      border-radius: 0;
      box-shadow: none;
      -webkit-appearance: none; }
    #goauto section.join .form-1 ::-webkit-input-placeholder,
    #goauto section.join .form-2 ::-webkit-input-placeholder {
      /* WebKit, Blink, Edge */
      color: #c8c8c8; }
    #goauto section.join .form-1 :-moz-placeholder,
    #goauto section.join .form-2 :-moz-placeholder {
      /* Mozilla Firefox 4 to 18 */
      color: #c8c8c8;
      opacity: 1; }
    #goauto section.join .form-1 ::-moz-placeholder,
    #goauto section.join .form-2 ::-moz-placeholder {
      /* Mozilla Firefox 19+ */
      color: #c8c8c8;
      opacity: 1; }
    #goauto section.join .form-1 :-ms-input-placeholder,
    #goauto section.join .form-2 :-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: #c8c8c8; }
    #goauto section.join .form-1 ::-ms-input-placeholder,
    #goauto section.join .form-2 ::-ms-input-placeholder {
      /* Microsoft Edge */
      color: #c8c8c8; }
    #goauto section.join .form-1 span.normal,
    #goauto section.join .form-2 span.normal {
      text-transform: initial; }
    #goauto section.join .form-1 span.required,
    #goauto section.join .form-2 span.required {
      color: #b51410; }
    #goauto section.join .form-1 .divident,
    #goauto section.join .form-2 .divident {
      margin-top: 25px; }
    #goauto section.join .form-1 .submit button,
    #goauto section.join .form-2 .submit button {
      border-radius: 5px;
      background-color: #1f4864;
      box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.19);
      color: #fff;
      float: right;
      position: absolute;
      bottom: -30px;
      right: 50px;
      padding: 20px 50px;
      width: 300px; }
  #goauto section.join .form-2 .text-info:before {
    top: 40%; }
  #goauto section.join .form-2 .rating .start,
  #goauto section.join .form-2 .rating .finish {
    font-size: 14px; }
  #goauto section.join .form-2 .rating p {
    display: block; }
  #goauto section.join .form-2 .rating ul {
    padding: 0; }
    #goauto section.join .form-2 .rating ul li {
      float: left;
      margin: 0 6%;
      list-style: none;
      width: 20px;
      height: 20px;
      border: 1px solid #485460;
      border-radius: 50%;
      position: relative; }
      #goauto section.join .form-2 .rating ul li input[type="radio"] {
        box-shadow: 0px 0px 5px 0 rgba(0, 0, 0, 0.09);
        -webkit-appearance: none;
        height: 20px;
        width: 20px;
        border-radius: 50%; }
      #goauto section.join .form-2 .rating ul li input[type="radio"]:hover {
        cursor: pointer; }
      #goauto section.join .form-2 .rating ul li input[type="radio"]:checked {
        background: #98ce00;
        height: 16px;
        width: 16px;
        top: 1px;
        left: 1px;
        border-radius: 50%;
        position: absolute; }
  #goauto section.join .form-2 .combo-box:after {
    top: 50px; }
  #goauto section.join .form-2 .submit .back {
    right: 40%;
    background: #343b41; }

@media screen and (max-width: 768px) {
  #goauto #talent-story {
    margin-top: 2em; }
  #goauto .section-our-values {
    padding: 0;
    margin-top: 8em; }
  #goauto .how-it-works--wrapper {
    margin-top: 3em; }
    #goauto .how-it-works--wrapper .section--title {
      line-height: 1; } }

@media screen and (min-width: 64em) {
  #goauto section.services .wrapper .service-container .service-list {
    width: 19em;
    height: 350px; } }

@media screen and (max-width: 35.5em) {
  #goauto #join-now .right .button {
    text-align: center; }
    #goauto #join-now .right .button a {
      right: unset !important;
      position: relative;
      top: 55px; }
  #goauto #talent-story-golife {
    margin: 0; }
    #goauto #talent-story-golife h4:before {
      top: 3.5% !important; }
  #goauto .services {
    margin-top: 2em;
    padding-top: 0; }
    #goauto .services .wrapper .service-container .service-list {
      height: 300px; }
      #goauto .services .wrapper .service-container .service-list .img-container img {
        margin-top: 0; }
  #goauto section.join .banner {
    display: none; }
  #goauto section.join .form-1 {
    padding: 10px; }
    #goauto section.join .form-1 label {
      font-size: 12px; }
    #goauto section.join .form-1 .text-info:before {
      top: 7px; }
    #goauto section.join .form-1 .submit button {
      position: initial;
      padding: 25px;
      display: block;
      margin: auto;
      float: none;
      margin-top: 30px;
      font-size: 14px;
      width: 100%; }
  #goauto section.join .form-2 {
    padding: 10px; }
    #goauto section.join .form-2 label {
      font-size: 12px; }
    #goauto section.join .form-2 .text-info:before {
      top: 7px; }
    #goauto section.join .form-2 .submit button {
      position: initial;
      padding: 25px;
      display: block;
      margin: auto;
      float: none;
      margin-top: 30px;
      font-size: 14px;
      width: 100%; } }

#gobox #content-only.thank-you {
  margin-top: 130px; }
  #gobox #content-only.thank-you .container {
    box-shadow: 0 -3em 0 1.5em #343b41; }
    #gobox #content-only.thank-you .container .info h4 {
      color: #A1652F; }
    #gobox #content-only.thank-you .container .info .line {
      background: #A1652F; }
  #gobox #content-only.thank-you a.contact {
    width: 300px;
    background: #A1652F !important; }
  @media screen and (max-width: 768px) {
    #gobox #content-only.thank-you {
      margin-bottom: 130px; }
      #gobox #content-only.thank-you a.contact {
        width: 100%;
        line-height: normal; } }

#gobox .join[data-join=business] section.full-slider {
  background: #fff100;
  padding: 40px; }
  #gobox .join[data-join=business] section.full-slider .text h4 {
    text-align: center; }
  #gobox .join[data-join=business] section.full-slider .text span {
    width: 50px;
    height: 4px;
    background: #4a4a4a;
    display: block;
    margin: 0 auto; }

#gobox .join[data-join=business] header {
  overflow: visible; }
  #gobox .join[data-join=business] header .desc--wrapper .desc--row .hero--desc {
    margin-bottom: 30px; }
  #gobox .join[data-join=business] header .desc--wrapper .desc--row a.hero--button {
    display: inline-block;
    bottom: 0px;
    position: relative;
    margin-top: 10px;
    color: #343b41;
    background-color: #f2db04 !important;
    text-transform: uppercase;
    border-radius: 5px;
    padding: 30px 40px;
    -webkit-transition: all .3s ease-in-out;
    -moz-transition: all .3s ease-in-out;
    -o-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out; }
    #gobox .join[data-join=business] header .desc--wrapper .desc--row a.hero--button svg {
      width: 12px;
      position: absolute;
      right: 20px;
      top: 50%;
      -webkit-transition: all .3s ease-in-out;
      -moz-transition: all .3s ease-in-out;
      -o-transition: all .3s ease-in-out;
      transition: all .3s ease-in-out;
      -webkit-transform: translateY(-50%);
      -moz-transform: translateY(-50%);
      -o-transform: translateY(-50%);
      transform: translateY(-50%); }
  #gobox .join[data-join=business] header .desc--wrapper .desc--row a.hero--button:hover {
    box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.19);
    color: #343b41 !important;
    background: #fff !important; }
    #gobox .join[data-join=business] header .desc--wrapper .desc--row a.hero--button:hover svg g {
      fill: #f2db04 !important; }

#gobox .join[data-join=business] .new-our-partner .container-slide .title:before {
  background-color: #fff100; }

#gobox .join[data-join=business] section#new-join {
  margin-bottom: 100px; }
  #gobox .join[data-join=business] section#new-join button.submit {
    padding: 30px 0;
    width: 200px;
    margin-top: 50px;
    background: #fff100;
    float: right;
    color: #4a4a4a;
    border-radius: 5px;
    margin-bottom: -90px; }
    @media screen and (max-width: 767px) {
      #gobox .join[data-join=business] section#new-join button.submit {
        margin-right: auto;
        margin-left: auto;
        display: block;
        float: none; } }

#gobox section.section-our-values.new {
  padding-top: 0px; }
  #gobox section.section-our-values.new img {
    max-height: 150px;
    width: auto; }
  #gobox section.section-our-values.new h4.title {
    color: #A1652F; }
    @media screen and (max-width: 767px) {
      #gobox section.section-our-values.new h4.title:after {
        top: 30px;
        position: absolute;
        width: 15px;
        left: -20px;
        background-color: #4285a7;
        height: 2px;
        content: ""; } }
  #gobox section.section-our-values.new .list svg {
    height: 140px; }
    @media screen and (max-width: 767px) {
      #gobox section.section-our-values.new .list svg {
        height: 130px; } }
    #gobox section.section-our-values.new .list svg.adjust {
      position: relative;
      left: -20px; }
  #gobox section.section-our-values.new .list h4 {
    color: #4a4a4a; }
    @media screen and (min-width: 768px) {
      #gobox section.section-our-values.new .list h4 {
        margin-top: 50px;
        margin-bottom: 40px; } }
    @media screen and (max-width: 767px) {
      #gobox section.section-our-values.new .list h4 {
        padding: 0px;
        margin-bottom: 0px;
        margin-top: 20px; } }
  #gobox section.section-our-values.new .list p {
    font-family: "Open Sans", Helvetica, sans-serif; }
    @media screen and (min-width: 768px) {
      #gobox section.section-our-values.new .list p {
        margin-top: 10px; } }
    @media screen and (max-width: 767px) {
      #gobox section.section-our-values.new .list p {
        max-width: 300px; } }
  #gobox section.section-our-values.new .line {
    background: #A1652F; }
    @media screen and (max-width: 767px) {
      #gobox section.section-our-values.new .line {
        display: none; } }
  @media screen and (max-width: 767px) {
    #gobox section.section-our-values.new h4.title {
      font-size: 14px;
      text-align: left; } }
  #gobox section.section-our-values.new #type-gobills {
    width: 100%; }
    #gobox section.section-our-values.new #type-gobills .arrow-left {
      position: absolute;
      left: 0;
      bottom: -40px;
      width: 50px; }
    #gobox section.section-our-values.new #type-gobills .arrow-right {
      position: absolute;
      right: 0;
      bottom: -40px;
      width: 50px; }
    #gobox section.section-our-values.new #type-gobills .slick-arrow.slick-disabled {
      filter: grayscale(100) brightness(1.5); }
    @media screen and (min-width: 768px) {
      #gobox section.section-our-values.new #type-gobills {
        margin-top: 40px; } }
  @media screen and (max-width: 767px) {
    #gobox section.section-our-values.new {
      padding: 20px;
      padding-bottom: 100px;
      margin-top: 40px; }
      #gobox section.section-our-values.new .container-slider {
        width: 100%;
        padding: 0px;
        position: relative;
        display: inline-block; } }

#gobox section.new-our-partner {
  background-color: #fafafa;
  text-align: center;
  margin-bottom: 100px; }
  #gobox section.new-our-partner .container-slide {
    display: inline-block;
    max-width: 1170px;
    position: relative;
    height: 460px;
    width: 90%; }
    #gobox section.new-our-partner .container-slide .title {
      z-index: 1;
      position: absolute;
      width: 50%;
      padding-left: 20px;
      font-size: 18px;
      color: #A1652F;
      right: 0;
      text-align: left;
      font-weight: 700;
      height: 100px;
      letter-spacing: 1px; }
      @media screen and (min-width: 769px) {
        #gobox section.new-our-partner .container-slide .title:before {
          position: absolute;
          width: 35px;
          left: -25px;
          height: 3px;
          background: #49af4b;
          top: -5px;
          bottom: 0;
          content: "";
          margin: auto; } }
      #gobox section.new-our-partner .container-slide .title:after {
        content: "";
        display: inline-block;
        vertical-align: middle;
        height: 100%; }
        @media screen and (max-width: 768px) {
          #gobox section.new-our-partner .container-slide .title:after {
            height: 3px;
            width: 50px;
            margin: 20px auto;
            background: #49af4b;
            display: block;
            content: ""; } }
      @media screen and (max-width: 768px) {
        #gobox section.new-our-partner .container-slide .title {
          margin-top: 40px;
          position: relative;
          height: auto;
          padding-left: 0px;
          width: 100%;
          text-align: center;
          font-size: 14px; } }
    #gobox section.new-our-partner .container-slide .hero {
      float: left;
      height: 460px;
      width: 50%;
      background: #ebebeb; }
      #gobox section.new-our-partner .container-slide .hero .image-hero {
        height: 460px;
        width: 100%;
        background-size: cover;
        background-position: center; }
      @media screen and (max-width: 768px) {
        #gobox section.new-our-partner .container-slide .hero {
          width: 100%; } }
    #gobox section.new-our-partner .container-slide .comment {
      position: absolute;
      height: calc(100% - 100px);
      background: #fff;
      right: 0;
      bottom: 0;
      width: 50%; }
      #gobox section.new-our-partner .container-slide .comment .content {
        text-align: left;
        position: absolute;
        height: 80%;
        width: 70%;
        top: 0;
        bottom: 0;
        left: 7%;
        font-size: 14px;
        margin: auto; }
        @media screen and (max-width: 768px) {
          #gobox section.new-our-partner .container-slide .comment .content {
            width: 100%;
            position: relative;
            left: auto;
            padding: 30px; } }
      #gobox section.new-our-partner .container-slide .comment .container-comment {
        display: table;
        width: 100%;
        height: 300px;
        left: 0;
        font-size: 16px;
        line-height: 28px;
        color: #9b9b9b;
        font-family: "Open Sans", Helvetica, sans-serif;
        font-weight: 100; }
        #gobox section.new-our-partner .container-slide .comment .container-comment .user {
          color: #2b2b2b;
          margin-top: 10px; }
          #gobox section.new-our-partner .container-slide .comment .container-comment .user span {
            font-weight: 700; }
        #gobox section.new-our-partner .container-slide .comment .container-comment > div {
          display: table-cell;
          vertical-align: middle; }
        @media screen and (max-width: 768px) {
          #gobox section.new-our-partner .container-slide .comment .container-comment {
            text-align: center; } }
      @media screen and (max-width: 768px) {
        #gobox section.new-our-partner .container-slide .comment {
          position: relative;
          width: 100%;
          float: left;
          height: 500px; } }
    #gobox section.new-our-partner .container-slide .float-arrow {
      height: 45px;
      width: 45px;
      position: absolute;
      right: -90px;
      bottom: 0;
      top: 0;
      margin: auto; }
      #gobox section.new-our-partner .container-slide .float-arrow.arrow-left {
        transform: rotate(90deg);
        top: -55px; }
        #gobox section.new-our-partner .container-slide .float-arrow.arrow-left.slick-disabled {
          filter: grayscale(100) brightness(1.4); }
        @media screen and (max-width: 768px) {
          #gobox section.new-our-partner .container-slide .float-arrow.arrow-left {
            top: auto;
            bottom: -50px;
            left: 20px;
            right: auto;
            transform: none; } }
      #gobox section.new-our-partner .container-slide .float-arrow.arrow-right {
        transform: rotate(90deg);
        top: 55px; }
        #gobox section.new-our-partner .container-slide .float-arrow.arrow-right.slick-disabled {
          filter: grayscale(100) brightness(1.4); }
        @media screen and (max-width: 768px) {
          #gobox section.new-our-partner .container-slide .float-arrow.arrow-right {
            right: 20px;
            top: auto;
            bottom: -50px;
            left: auto;
            transform: none; } }
      @media screen and (max-width: 768px) {
        #gobox section.new-our-partner .container-slide .float-arrow {
          right: 0;
          left: 0; } }
    @media screen and (max-width: 768px) {
      #gobox section.new-our-partner .container-slide {
        height: auto;
        width: 100%; } }
  @media screen and (max-width: 768px) {
    #gobox section.new-our-partner {
      background-color: #fff;
      margin-bottom: 30px; } }
  #gobox section.new-our-partner .slick-dots {
    left: 0; }
    #gobox section.new-our-partner .slick-dots > li {
      margin: 0px;
      margin-right: 5px;
      filter: grayscale(100) brightness(1.4); }
      #gobox section.new-our-partner .slick-dots > li.slick-active {
        filter: grayscale(0); }
      #gobox section.new-our-partner .slick-dots > li button {
        width: 10px;
        height: 10px;
        background: #A1652F;
        border-radius: 50%; }
        #gobox section.new-our-partner .slick-dots > li button:before {
          display: none; }
      #gobox section.new-our-partner .slick-dots > li:last-child {
        margin-right: 0px; }

#gobox section.fare-estimate .calculator {
  height: 360px;
  background: white;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.11);
  padding: 50px 50px 60px;
  margin: 50px 0;
  position: relative; }
  #gobox section.fare-estimate .calculator .wrapper-form label[for="box"]:before {
    background-image: url("https://bilba.go-jek.com/dist/img/page/gobox/favicon.png");
    background-size: contain;
    background-repeat: no-repeat;
    display: inline-block;
    width: 25px;
    height: 25px;
    content: "";
    position: absolute;
    margin-top: 14px;
    margin-left: 8px; }
  #gobox section.fare-estimate .calculator .wrapper-form .wrapper-pick-up {
    color: #ffcd2e; }
    #gobox section.fare-estimate .calculator .wrapper-form .wrapper-pick-up .pac-controls {
      display: inline-block;
      padding: 5px 11px; }
    #gobox section.fare-estimate .calculator .wrapper-form .wrapper-pick-up #pick-up:focus {
      border-color: #4d90fe; }
    #gobox section.fare-estimate .calculator .wrapper-form .wrapper-pick-up #title {
      color: #fff;
      background-color: #4d90fe;
      font-size: 25px;
      font-weight: 500;
      padding: 6px 12px; }
    #gobox section.fare-estimate .calculator .wrapper-form .wrapper-pick-up #target {
      width: 345px; }
  #gobox section.fare-estimate .calculator .wrapper-form .wrapper-drop-off {
    color: #935e33; }
  #gobox section.fare-estimate .calculator .wrapper-form .wrapper-pick-up,
  #gobox section.fare-estimate .calculator .wrapper-form .wrapper-drop-off {
    position: relative; }
    #gobox section.fare-estimate .calculator .wrapper-form .wrapper-pick-up .fa-circle:before,
    #gobox section.fare-estimate .calculator .wrapper-form .wrapper-drop-off .fa-circle:before {
      font-size: 11px; }
    #gobox section.fare-estimate .calculator .wrapper-form .wrapper-pick-up label,
    #gobox section.fare-estimate .calculator .wrapper-form .wrapper-drop-off label {
      position: absolute;
      top: 17px;
      left: 15px; }
  #gobox section.fare-estimate .calculator .wrapper-form h4 {
    position: relative;
    margin: 0; }
  #gobox section.fare-estimate .calculator .wrapper-form h4:before {
    content: "";
    height: 4px;
    width: 50px;
    position: absolute;
    background: #f2db01;
    left: -15%;
    top: 42%; }
  #gobox section.fare-estimate .calculator .wrapper-form input,
  #gobox section.fare-estimate .calculator .wrapper-form select {
    display: block;
    width: 100%;
    margin: 15px 0;
    border: solid 1px #e8ebf2;
    border-radius: 0 !important;
    line-height: 0.8;
    letter-spacing: 1px;
    color: #485460;
    font-family: "Open Sans", Helvetica, sans-serif; }
  #gobox section.fare-estimate .calculator .wrapper-form select {
    background: white;
    height: 50px;
    padding: 10px 40px 10px 40px;
    -webkit-appearance: none;
    -ms-appearance: none;
    -moz-appearance: none;
    border: 1px solid #e8ebf2;
    background-color: transparent;
    position: relative;
    cursor: pointer;
    z-index: 10; }
  #gobox section.fare-estimate .calculator .wrapper-form input {
    padding: 15px 20px 15px 40px; }
  #gobox section.fare-estimate .calculator .wrapper-form #submit[disabled] {
    background-color: #b7b7b7 !important;
    cursor: no-drop; }
  #gobox section.fare-estimate .calculator .wrapper-form button {
    border-radius: 5px;
    background-color: #f2db01;
    padding: 20px 60px;
    color: white;
    position: absolute;
    right: 50px;
    bottom: -30px; }
  #gobox section.fare-estimate .calculator .wrapper-form button:focus {
    outline: none; }

#gobox section.fare-estimate .wrapper-map {
  margin: 50px 0;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.11);
  position: relative; }
  #gobox section.fare-estimate .wrapper-map #map {
    height: 360px; }
  #gobox section.fare-estimate .wrapper-map .result .title {
    background: #f2db01;
    padding: 30px 45px; }
    #gobox section.fare-estimate .wrapper-map .result .title h4 {
      margin: 0;
      text-transform: uppercase;
      line-height: 0.8;
      letter-spacing: 3.3px;
      color: #485460;
      font-family: "Open Sans", Helvetica, sans-serif; }
  #gobox section.fare-estimate .wrapper-map .result ol {
    list-style: none; }
  #gobox section.fare-estimate .wrapper-map .result .list {
    list-style: none;
    margin: 0;
    padding: 25px 45px 25px; }
    #gobox section.fare-estimate .wrapper-map .result .list li.wrapper:first-child {
      border: none; }
    #gobox section.fare-estimate .wrapper-map .result .list li.wrapper {
      padding-top: 10px;
      border-top: solid 1px #e8ebf2; }
    #gobox section.fare-estimate .wrapper-map .result .list .item {
      position: relative;
      padding: 0;
      margin-bottom: 10px; }
      #gobox section.fare-estimate .wrapper-map .result .list .item li:first-child {
        line-height: 0.8;
        letter-spacing: 1px;
        color: #485460;
        font-weight: bold;
        margin-bottom: 10px; }
      #gobox section.fare-estimate .wrapper-map .result .list .item li {
        margin-bottom: 5px;
        font-family: "Open Sans", Helvetica, sans-serif;
        text-transform: uppercase; }
      #gobox section.fare-estimate .wrapper-map .result .list .item .price {
        position: absolute;
        top: 0;
        right: 0;
        line-height: 0.8;
        letter-spacing: 1px;
        color: #485460;
        font-weight: bold; }

#gobox section.fare-estimate .hidden {
  display: none; }

#gobox #promo-banner .detail .custom-nav .slick-disabled ellipse {
  stroke: #D2D2D2 !important; }

#gobox #promo-banner .detail .custom-nav .slick-disabled polygon {
  fill: #D2D2D2; }

#gobox #join-now {
  padding-top: 0; }
  #gobox #join-now .right p:first-child {
    text-transform: uppercase; }
  #gobox #join-now .right a {
    background: #fce500;
    color: #353332;
    font-weight: bold; }

#gobox .section-our-values .list img {
  width: 250px;
  height: 250px; }

#gobox .section-our-values .title {
  color: #353332; }

#gobox .section-our-values .line.gradient {
  background: #fce500; }

#gobox .section-how-to-use h5 {
  color: #353332; }

#gobox .section-how-to-use .line.gradient {
  background: #FCE500; }

#gobox .section-how-to-use .arrow-up {
  stroke: #A1652F; }

#gobox .section-how-to-use .arrow-up-circle {
  stroke-width: 3;
  stroke: #A1652F; }

#gobox .section-how-to-use .arrow-down {
  stroke: #A1652F; }

#gobox .section-how-to-use .arrow-down-circle {
  stroke: #A1652F;
  stroke-width: 3; }

#gobox .section-how-to-use .slider .slick-dots li.slick-active button:before {
  color: #A1652F; }

#gobox .section-how-to-use .slider-image .slick-dots li.slick-active button:before {
  color: #A1652F; }

#gobox .new-section-how-to-use {
  margin-top: 180px;
  margin-bottom: 100px; }
  #gobox .new-section-how-to-use h3.title {
    color: #000; }
    #gobox .new-section-how-to-use h3.title:after {
      background: #f2db01; }
  #gobox .new-section-how-to-use .content {
    color: #000;
    background-color: #f2db01; }
  @media screen and (max-width: 767px) {
    #gobox .new-section-how-to-use {
      margin-top: 50px !important;
      margin-bottom: 50px; } }

#gobox .as-seen-on .city {
  background: url(https://d3naj63yelf4gk.cloudfront.net/dist/img/page/gobox/kota_gobox.jpg); }
  #gobox .as-seen-on .city .gobox-city--title {
    font-size: 45px;
    color: #FCE500;
    line-height: 50px; }
  #gobox .as-seen-on .city .gobox-city--list {
    text-align: center;
    color: #fff;
    font-size: 18px;
    margin: 0;
    padding: 0 40px 50px;
    font-weight: 300; }

#gobox .as-seen-on p {
  font-weight: 700; }

#gobox .as-seen-on #right {
  background: #A1652F; }

#gobox .as-seen-on #left {
  background-color: #FCE500; }

#gobox .as-seen-on #content {
  color: #353332; }

#gobox .as-seen-on hr {
  background: #FCE500; }

#gobox .how-it-works--wrapper {
  margin: 30px;
  margin-bottom: 80px; }
  #gobox .how-it-works--wrapper .container {
    padding: 0px; }
  #gobox .how-it-works--wrapper .section--title {
    color: #353332; }
    #gobox .how-it-works--wrapper .section--title:before {
      background-color: #FCE500; }
  #gobox .how-it-works--wrapper .layer .rect--primary {
    background: #FCE500; }
  #gobox .how-it-works--wrapper .layer .rect--secondary {
    background: #A1652F; }
  #gobox .how-it-works--wrapper .btn {
    background-color: #252525 !important;
    color: #FCE500 !important;
    font-size: 12px;
    padding: 20px 50px;
    margin: 20px 0; }
  @media screen and (max-width: 768px) {
    #gobox .how-it-works--wrapper {
      margin: 0px; } }

#gobox .articles--feed .bg,
#gobox .articles--feed .read--more {
  background: #A1652F; }

#gobox .articles--feed .blog-card-title a:hover {
  color: #A1652F; }

#gobox .articles--feed .line--title {
  background: #FCE500; }

@media screen and (max-width: 48em) {
  #gobox .section-how-to-use .arrow--wrapper {
    top: 22%; }
  #gobox .section-how-to-use .phone-wrapper {
    top: 32%; }
  #gobox .section-how-to-use ul.slick-dots {
    margin-top: -3.5em; }
  #gobox section.fare-estimate .calculator {
    padding: 15px;
    margin: 30px 0; }
    #gobox section.fare-estimate .calculator .wrapper-form h4:before {
      left: -55px;
      top: 0px; }
    #gobox section.fare-estimate .calculator .wrapper-form input,
    #gobox section.fare-estimate .calculator .wrapper-form button,
    #gobox section.fare-estimate .calculator .wrapper-form select {
      font-size: 12px; }
    #gobox section.fare-estimate .calculator .wrapper-form select {
      padding: 15px 25px; }
    #gobox section.fare-estimate .calculator .wrapper-form .wrapper-pick-up .fa-circle:before,
    #gobox section.fare-estimate .calculator .wrapper-form .wrapper-drop-off .fa-circle:before {
      font-size: 8px; }
    #gobox section.fare-estimate .calculator .wrapper-form .wrapper-pick-up label,
    #gobox section.fare-estimate .calculator .wrapper-form .wrapper-drop-off label {
      top: 13px;
      left: 10px; }
    #gobox section.fare-estimate .calculator .wrapper-form button {
      margin: auto;
      display: block;
      position: relative;
      font-size: 12px;
      padding: 10px 35px;
      left: 0; }
  #gobox section.fare-estimate .wrapper-map {
    margin: 5px 0; }
  #gobox .as-seen-on #left {
    left: 14px; }
  #gobox .as-seen-on #right {
    right: 14px; } }

@media screen and (max-width: 35.5em) {
  #gobox .join[data-join=business] a.hero--button {
    bottom: -15px !important;
    position: absolute !important;
    right: 0;
    text-align: center;
    left: 0;
    width: 190px;
    margin: auto;
    padding: 20px 0 !important; }
  #gobox .join[data-join=business] .hero--desc {
    margin-bottom: 0px !important; }
  #gobox .section-our-values .list img {
    width: 100px;
    height: 100px; }
  #gobox .as-seen-on #left {
    left: 0px; }
  #gobox .as-seen-on #right {
    right: 0px; } }

#goclean .new-section-how-to-use {
  margin-top: 180px;
  margin-bottom: 100px; }
  #goclean .new-section-how-to-use h3.title {
    color: #2197d4; }
    #goclean .new-section-how-to-use h3.title:after {
      background: #2197d4; }
  #goclean .new-section-how-to-use .content {
    background-color: #2197d4; }

#goclean .about {
  padding-bottom: 0; }
  #goclean .about .container::before {
    background-color: #2197d4; }
  #goclean .about .description {
    padding-bottom: 0; }
  #goclean .about h4 {
    color: #045572; }
  #goclean .about h2 {
    text-transform: uppercase; }

#goclean .slick-disabled {
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
  pointer-events: none; }

#goclean section#new-join {
  margin: 48px 0px 100px 0px; }

#goclean #content-only.thank-you {
  margin-top: 130px; }
  #goclean #content-only.thank-you a.contact {
    width: 300px; }
  @media screen and (max-width: 768px) {
    #goclean #content-only.thank-you {
      margin-bottom: 130px; }
      #goclean #content-only.thank-you a.contact {
        width: 100%;
        line-height: normal; } }
  #goclean #content-only.thank-you .container {
    background: white;
    box-shadow: 0 -3em 0 1.5em #2197D4; }
    #goclean #content-only.thank-you .container .info h4 {
      color: #2197D4; }
    #goclean #content-only.thank-you .container .info .line {
      background: #2197D4; }
    #goclean #content-only.thank-you .container .info .link a {
      width: 280px; }
    #goclean #content-only.thank-you .container .info .link .contact {
      margin-bottom: 15px; }
      #goclean #content-only.thank-you .container .info .link .contact span {
        margin: 0 15px; }
    #goclean #content-only.thank-you .container .info .link .contact.homepage {
      background: #c8c8c8; }

#goclean section.service .title-wrapper {
  position: relative;
  background: #095672;
  color: #fff;
  padding: 35px 30px; }
  #goclean section.service .title-wrapper h4 {
    color: #ffffff;
    margin-left: 10px;
    text-transform: uppercase;
    font-family: "Lato", Helvetica, sans-serif;
    margin: 0; }

#goclean section.service .title-wrapper::before {
  content: "";
  width: 50px;
  height: 5px;
  background-color: #1f97d2;
  position: absolute;
  left: -35px;
  top: 50%;
  transform: translateY(-50%); }

#goclean section.service .content .data {
  background: white;
  margin: 15px 0;
  position: relative; }
  #goclean section.service .content .data .title.active {
    border-left: none; }
  #goclean section.service .content .data .title.not-active {
    border-left: 5px solid #1f97d2; }
  #goclean section.service .content .data .title {
    border-left: 5px solid #1f97d2;
    padding: 15px;
    margin-top: 15px; }
    #goclean section.service .content .data .title h4 {
      font-family: "Lato", Helvetica, sans-serif;
      font-weight: bold;
      letter-spacing: 2.5px;
      color: #221f20;
      text-transform: uppercase;
      outline: none;
      position: relative; }
      #goclean section.service .content .data .title h4 .fa {
        position: absolute;
        right: 30px;
        font-size: 20px;
        top: -5px;
        color: #1f97d2; }
  #goclean section.service .content .data ul {
    list-style: none;
    padding: 0 30px; }
    #goclean section.service .content .data ul p.info {
      text-align: center;
      color: #9b9b9b; }
    #goclean section.service .content .data ul li {
      width: 100%;
      padding: 15px 0;
      border-bottom: solid 1px #e8ebf2;
      font-weight: 300;
      line-height: 1.43;
      letter-spacing: 1.5px;
      color: #221f20;
      font-family: "Open Sans", Helvetica, sans-serif;
      display: table;
      border-spacing: 15px 0; }
      #goclean section.service .content .data ul li picture {
        width: 35px;
        position: relative;
        transform: translateX(-15px);
        display: table-cell; }
        #goclean section.service .content .data ul li picture img {
          max-width: none;
          width: 100%;
          margin: 0; }
      #goclean section.service .content .data ul li p {
        font-size: inherit;
        margin-left: 10px;
        display: table-cell;
        vertical-align: middle;
        color: #1a1718; }

@media screen and (min-width: 64em) {
  #goclean section.service {
    margin-top: 100px; }
    #goclean section.service .title-wrapper {
      margin: 60px 0;
      display: flex;
      justify-content: center;
      flex-direction: column; }
      #goclean section.service .title-wrapper h4 {
        margin-left: 21px; }
    #goclean section.service .content {
      background: #ffffff;
      padding-bottom: 25px; }
      #goclean section.service .content div:nth-child(1) {
        border-left: none; }
      #goclean section.service .content .data .title {
        border: none !important; }
        #goclean section.service .content .data .title h4 {
          text-align: center;
          position: relative; }
        #goclean section.service .content .data .title h4::after {
          content: "";
          position: absolute;
          height: 5px;
          width: 50px;
          bottom: -25px;
          left: 40%;
          background-color: #1f97d2; }
      #goclean section.service .content .data ul li {
        border-bottom: none !important; }
      #goclean section.service .content .data ul p.info {
        display: inherit; }
      #goclean section.service .content .data ul p {
        letter-spacing: normal;
        color: #9b9b9b;
        font-size: 0.6em;
        text-align: left; }
      #goclean section.service .content .data:nth-child(1):after {
        content: "";
        height: 115%;
        width: 1px;
        background-color: #979796;
        position: absolute;
        top: 0;
        right: 0;
        transform: translateY(35%); }
      #goclean section.service .content .data:nth-child(2):after {
        content: "";
        height: 80%;
        width: 1px;
        background-color: #979796;
        position: absolute;
        top: 0;
        right: 0;
        transform: translateY(33%); }
    #goclean section.service .banner {
      background: #095672;
      margin: 60px 0; } }

#goclean .section-our-values {
  padding: 5em 0;
  background: #f8f8f8; }
  #goclean .section-our-values h4.title {
    color: #2197D4; }
  #goclean .section-our-values .line.gradient {
    background: #2197D4; }
  #goclean .section-our-values .list {
    padding: 20px; }
    #goclean .section-our-values .list h4 {
      line-height: 25px;
      font-size: 15px;
      height: 3em; }
    #goclean .section-our-values .list img {
      height: 125px; }

#goclean .section-user-review .head .title {
  color: #2197D4; }

#goclean .section-user-review button:before {
  color: #1d98d5; }

#goclean .section-user-review .head .line.gradient {
  background: #2197D4; }

#goclean .section-user-review .wrapper .line.light.yellow {
  background: white; }

#goclean .section-user-review .slider .item .wrapper {
  min-height: 430px; }
  #goclean .section-user-review .slider .item .wrapper h3.user-testimonial {
    height: 140px; }

#goclean .section-user-review .slider .item .wrapper.style-2 {
  background: #2197D4; }

#goclean .section-user-review .slider .item .wrapper.style-3 {
  background: #babcbf; }

#goclean .articles--feed svg ellipse {
  stroke: #343b41 !important; }

#goclean .articles--feed svg polygon {
  fill: #343b41 !important; }

#goclean #talent-story h4:before {
  background-color: #1d98d5;
  top: 6.5%; }

#goclean #talent-story h4:after {
  display: none; }

#goclean #talent-story h4 {
  color: #2197D4; }

#goclean #talent-story .detail h2 {
  color: #2197D4;
  font-size: 25px; }

#goclean #talent-story .info h5 {
  color: #2197D4; }

#goclean .articles--feed .bg {
  background: #2197D4; }

#goclean .articles--feed .line--title {
  background: #045572; }

#goclean .articles--feed .read--more > a {
  background: #2197D4; }

#goclean .articles--feed .content .one {
  border-bottom: 6px solid #2197D4; }

#goclean .articles--feed .content .two {
  border-bottom: 6px solid #2197D4; }

#goclean .articles--feed .content .three {
  border-bottom: 6px solid #2197D4; }

#goclean .articles--feed .content .four {
  border-bottom: 6px solid #2197D4; }

#goclean .articles--feed h4 > a:hover {
  color: #2197D4; }

#goclean #join-now a {
  background: #2197D4; }

#goclean #join-now .bg-layer {
  position: relative; }

#goclean #bg-as-seen-on hr {
  background: #2197D4; }

#goclean #bg-as-seen-on .title {
  color: #2197D4; }

#goclean #bg-as-seen-on #right {
  background: #2197D4; }

#goclean .section-how-to-use {
  padding: 50px;
  height: 620px;
  background-size: cover !important; }
  #goclean .section-how-to-use h5 {
    color: #2197D4; }
  #goclean .section-how-to-use .line.gradient {
    background: #2197D4; }
  #goclean .section-how-to-use .title {
    position: relative;
    left: -410px; }
  #goclean .section-how-to-use .arrow-up {
    stroke: #2197D4; }
  #goclean .section-how-to-use .arrow-up-circle {
    stroke-width: 3;
    stroke: #2197D4; }
  #goclean .section-how-to-use .arrow-down {
    stroke: #2197D4; }
  #goclean .section-how-to-use .arrow-down-circle {
    stroke: #2197D4;
    stroke-width: 3; }
  #goclean .section-how-to-use .slider .slick-dots li.slick-active button:before {
    color: #2197D4; }
  #goclean .section-how-to-use .slider-image .slick-dots li.slick-active button:before {
    color: #2197D4; }
  #goclean .section-how-to-use .text-mobile {
    display: none;
    text-align: center; }
  #goclean .section-how-to-use .text-mobile .line.gradient {
    width: 50px;
    height: 3px;
    margin-top: 0px;
    background: #2197D4;
    margin-left: auto;
    margin-right: auto; }
  #goclean .section-how-to-use .text h5 {
    color: #2197D4; }
  #goclean .section-how-to-use .text .line.gradient {
    width: 50px;
    height: 3px;
    margin-top: 0px;
    background: #2197D4; }
  #goclean .section-how-to-use .phone-base {
    display: block;
    margin-right: auto;
    margin-left: auto;
    position: relative; }
  #goclean .section-how-to-use .slider .item-text h2 {
    margin-top: 20px;
    margin-left: 0px;
    font-weight: 300; }
  #goclean .section-how-to-use .slider .item .wrapper {
    width: 100%; }
  #goclean .section-how-to-use .slider-image .slick-dots li {
    display: block; }
  #goclean .section-how-to-use .slider-image .slick-dots li.slick-active button:before {
    color: #2197D4; }
  #goclean .section-how-to-use .slider-image .slick-dots li button:before {
    font-size: 13px; }
  #goclean .section-how-to-use .slider .slider-text.title {
    position: relative;
    float: right;
    left: -265px;
    top: -20px;
    font-size: 36px;
    margin-top: -320px;
    font-weight: 300; }
  #goclean .section-how-to-use .slider .slider-text.desc {
    margin-left: 380px;
    position: relative;
    top: -280px;
    left: 45px;
    margin-bottom: -33px;
    line-height: 1.56;
    font-weight: 300;
    letter-spacing: 1.7px; }
  #goclean .section-how-to-use ul li {
    margin-top: 10px; }
  #goclean .section-how-to-use ul li img {
    cursor: pointer; }

@media screen and (max-width: 480px) {
  #goclean .section-how-to-use {
    min-height: 815px;
    background-size: cover !important; }
    #goclean .section-how-to-use .text-mobile {
      text-align: center;
      display: block;
      margin-right: auto;
      margin-left: auto; }
    #goclean .section-how-to-use .slider .item-text {
      margin-top: 0px; }
    #goclean .section-how-to-use .slider .item .wrapper {
      width: 100%; }
    #goclean .section-how-to-use .slider-image ul {
      display: none !important; }
    #goclean .section-how-to-use .slider-image .slick-dots {
      position: relative; }
    #goclean .section-how-to-use .slider-image .slick-dots li {
      display: block; }
    #goclean .section-how-to-use .slider-image .slick-dots li.slick-active button:before {
      color: #2197D4; }
    #goclean .section-how-to-use .slider-image .slick-dots li button:before {
      font-size: 13px; }
    #goclean .section-how-to-use .slider .slider-text.title {
      position: relative;
      float: right;
      left: -265px;
      top: -20px;
      font-size: 36px;
      margin-top: -320px;
      font-weight: 300; }
    #goclean .section-how-to-use .slider .slider-text.desc {
      margin-left: 380px;
      position: relative;
      top: -280px;
      left: 45px;
      margin-bottom: -33px;
      line-height: 1.56;
      font-weight: 300;
      letter-spacing: 1.7px; }
    #goclean .section-how-to-use ul li img {
      cursor: pointer; } }

@media screen and (max-width: 400px) {
  #goclean .section-how-to-use {
    min-height: 835px; } }

@media screen and (max-width: 768px) {
  #goclean #goclean section.services .container:before {
    background-color: #2197d4;
    top: 2.85em; }
  #goclean #goclean section.services .wrapper .service-container {
    background: #1d98d5; }
    #goclean #goclean section.services .wrapper .service-container .service-list {
      min-height: 425px;
      outline: none; }
      #goclean #goclean section.services .wrapper .service-container .service-list .img-container img {
        max-width: 100px; }
  #goclean #goclean section.services h4 {
    line-height: 1.5; }
  #goclean #goclean .slick-dots li.slick-active button:before {
    opacity: .75;
    color: #1d98d5; }
  #goclean #goclean #talent-story h4:before {
    display: none; }
  #goclean #goclean #talent-story h4:after {
    display: inherit;
    content: "";
    position: absolute;
    left: 0;
    top: 4.5%;
    right: 0;
    padding-right: 50px;
    height: 4px;
    margin: 0 auto;
    background-color: #1d98d5;
    z-index: 10;
    width: 5px; }
  #goclean #goclean .section-user-review {
    padding-top: 0;
    padding-bottom: 0; } }

@media screen and (max-width: 320px) {
  #goclean #goclean .as-seen-on #content img {
    max-height: 80px; } }

@media screen and (max-width: 35.5em) {
  #goclean #goclean section.services {
    padding-bottom: 0; }
    #goclean #goclean section.services .navigation-arrow {
      padding-top: 19em !important; }
  #goclean #goclean header#main--hero .desc--wrapper {
    bottom: 1em; }
  #goclean #goclean .about {
    padding-top: 0; }
  #goclean #goclean .services {
    padding-top: 0; } }

@media screen and (max-width: 320px) {
  #goclean #goclean header#main--hero {
    background-size: contain; } }

@media screen and (min-width: 64em) {
  #goclean #goclean section.services .wrapper .navigation-arrow {
    display: none; }
  #goclean #goclean section.services .wrapper .service-container {
    background-image: none;
    background: #1d98d5; }
    #goclean #goclean section.services .wrapper .service-container .service-list {
      width: 22em;
      padding-bottom: 30px; }
  #goclean #goclean .section-our-values {
    margin-top: 2em;
    padding-bottom: 0; }
  #goclean #goclean #join-now .right {
    margin-left: -1px; } }

@media screen and (max-width: 48em) {
  #goclean .section-user-review .slider .item .wrapper h3.user-testimonial {
    height: 220px; } }

#gofood {
  /* SOP */
  /* ENDING SOP */ }
  #gofood .sop-head .content {
    box-shadow: -1em 1em #e42b2d !important; }
    @media only screen and (max-width: 767px) {
      #gofood .sop-head .content {
        box-shadow: none !important; } }
  @media only screen and (max-width: 768px) {
    #gofood .sop-head h2 {
      font-size: 21px; } }
  @media only screen and (max-width: 767px) {
    #gofood .sop-head {
      background: #fff !important;
      margin-top: 70px;
      margin-bottom: 0px; }
      #gofood .sop-head .content {
        margin: 0px;
        padding: 15PX; }
      #gofood .sop-head .text {
        padding: 15px !important; }
      #gofood .sop-head .title {
        background: transparent !important; }
        #gofood .sop-head .title h2 {
          font-size: 28px;
          margin: 0px;
          color: #343b41; }
      #gofood .sop-head .background {
        height: 300px;
        margin-bottom: 20px; } }
  #gofood .container-content-sop {
    counter-reset: gopay-section;
    display: inline-block;
    width: 100%;
    text-align: center; }
    #gofood .container-content-sop .content {
      display: inline-block;
      width: 100%;
      max-width: 960px;
      border-top: 3px solid #e42b2d;
      background-color: #fff;
      padding: 40px;
      text-align: left;
      margin: 60px auto;
      font-size: 18px;
      font-family: "Open Sans", Helvetica, sans-serif;
      line-height: 28px; }
      #gofood .container-content-sop .content .intro {
        font-weight: 100;
        margin-bottom: 20px; }
      #gofood .container-content-sop .content .title {
        float: left;
        width: 100%;
        display: inline-block;
        font-weight: 700; }
        #gofood .container-content-sop .content .title:before {
          counter-increment: gopay-section;
          content: counter(gopay-section) ".";
          margin-right: 10px; }
      #gofood .container-content-sop .content ul > li {
        margin-bottom: 20px; }
      #gofood .container-content-sop .content ol.list-sop {
        float: left;
        width: 100%;
        font-weight: 100;
        margin: 20px 0;
        margin-bottom: 50px;
        padding-left: 20px; }
        #gofood .container-content-sop .content ol.list-sop > li {
          margin-bottom: 20px;
          padding-left: 9px; }
          #gofood .container-content-sop .content ol.list-sop > li:last-child {
            margin-bottom: 0px; }
        @media only screen and (max-width: 767px) {
          #gofood .container-content-sop .content ol.list-sop {
            padding-left: 25px;
            margin-bottom: 20px;
            counter-reset: item; }
            #gofood .container-content-sop .content ol.list-sop > li {
              margin: 0;
              padding: 0 0 0 2em;
              margin-bottom: 10px;
              text-indent: -2em;
              list-style-type: none;
              counter-increment: item; }
              #gofood .container-content-sop .content ol.list-sop > li:before {
                display: inline-block;
                width: 1em;
                padding-right: 13px;
                font-weight: bold;
                text-align: right;
                content: counter(item, lower-alpha) "."; } }
      #gofood .container-content-sop .content .content-table {
        float: left;
        width: 100%;
        position: relative; }
        #gofood .container-content-sop .content .content-table .desktop {
          float: left;
          position: relative;
          width: 100%; }
          #gofood .container-content-sop .content .content-table .desktop .extra {
            font-weight: 100;
            left: 27px;
            position: relative;
            top: -30px;
            color: #4a4a4a; }
          #gofood .container-content-sop .content .content-table .desktop .container-table {
            position: relative;
            width: 100%;
            float: left;
            margin-top: 30px; }
          #gofood .container-content-sop .content .content-table .desktop .info {
            top: 78px;
            position: absolute;
            height: calc(100% - 179px);
            width: calc(100% + 60px);
            left: -20px;
            background-color: #d0021b; }
            #gofood .container-content-sop .content .content-table .desktop .info span {
              display: inline-block;
              color: #fff;
              margin-top: 100px;
              font-size: 14px;
              letter-spacing: 1px; }
              #gofood .container-content-sop .content .content-table .desktop .info span:before {
                display: inline-block;
                position: relative;
                height: 4px;
                width: 40px;
                margin-right: -5px;
                background-color: #343b41;
                content: "";
                left: -20px;
                bottom: 3px; }
          #gofood .container-content-sop .content .content-table .desktop table {
            box-shadow: -2px 2px 5px 0px rgba(0, 0, 0, 0.22);
            position: relative;
            margin: auto;
            margin-bottom: 50px;
            font-size: 14px;
            width: 85%;
            float: right;
            text-align: center;
            border-bottom: 2px solid #343b41; }
            #gofood .container-content-sop .content .content-table .desktop table th {
              letter-spacing: 1px;
              padding: 30px 0;
              color: #fff;
              background-color: #343b41;
              border-bottom: 2px solid #d0021b; }
            #gofood .container-content-sop .content .content-table .desktop table td {
              padding: 15px 0; }
            #gofood .container-content-sop .content .content-table .desktop table tbody > tr {
              background-color: #fff; }
              #gofood .container-content-sop .content .content-table .desktop table tbody > tr > th:first-child, #gofood .container-content-sop .content .content-table .desktop table tbody > tr > th:nth-child(2), #gofood .container-content-sop .content .content-table .desktop table tbody > tr > td:first-child, #gofood .container-content-sop .content .content-table .desktop table tbody > tr > td:nth-child(2) {
                border-right: 1px solid #979797; }
              #gofood .container-content-sop .content .content-table .desktop table tbody > tr:nth-child(even) {
                background-color: #ffcaca; }
          @media only screen and (max-width: 767px) {
            #gofood .container-content-sop .content .content-table .desktop {
              display: none; } }
        #gofood .container-content-sop .content .content-table .mobile-sop {
          display: none;
          width: calc(100% + 40px);
          position: relative;
          left: -20px;
          padding: 30px 0;
          background-color: #ececec;
          margin-bottom: 20px; }
          #gofood .container-content-sop .content .content-table .mobile-sop .info {
            font-weight: 100;
            position: relative;
            padding-left: 50px; }
          #gofood .container-content-sop .content .content-table .mobile-sop .table {
            text-align: center; }
            #gofood .container-content-sop .content .content-table .mobile-sop .table .title-sop {
              text-align: left;
              font-weight: 700;
              margin: 20px 0;
              color: #000; }
              #gofood .container-content-sop .content .content-table .mobile-sop .table .title-sop:before {
                display: inline-block;
                content: "";
                bottom: 4px;
                position: relative;
                width: 20px;
                margin-right: 10px;
                height: 3px;
                background-color: #d0021b; }
            #gofood .container-content-sop .content .content-table .mobile-sop .table .head-title {
              letter-spacing: 1px;
              padding: 15px 0;
              font-weight: 100;
              background-color: #343b41;
              color: #fff; }
            #gofood .container-content-sop .content .content-table .mobile-sop .table .sub-title {
              letter-spacing: 1px;
              padding: 15px 0;
              font-weight: 700;
              background-color: #d0021b;
              color: #fff; }
            #gofood .container-content-sop .content .content-table .mobile-sop .table .table-content {
              padding: 10px 0;
              width: 100%;
              background-color: #fff; }
              #gofood .container-content-sop .content .content-table .mobile-sop .table .table-content table {
                background-color: #fff;
                width: 90%;
                margin: auto; }
                #gofood .container-content-sop .content .content-table .mobile-sop .table .table-content table tr:first-child {
                  border-bottom: 1px solid #ffcaca; }
                #gofood .container-content-sop .content .content-table .mobile-sop .table .table-content table tr > td {
                  font-weight: 100;
                  height: 80px;
                  width: 50%; }
                  #gofood .container-content-sop .content .content-table .mobile-sop .table .table-content table tr > td:first-child {
                    text-align: left;
                    border-right: 1px solid #e2e2e2;
                    font-weight: 700;
                    padding: 15px; }
          @media only screen and (max-width: 767px) {
            #gofood .container-content-sop .content .content-table .mobile-sop {
              display: block; } }
      @media only screen and (max-width: 767px) {
        #gofood .container-content-sop .content {
          margin-top: 0px;
          padding: 20px;
          font-size: 14px;
          border: none; } }
  #gofood .container-sub-menu {
    z-index: 1990; }
  #gofood .gm-style-mtc {
    letter-spacing: 1px; }

@-webkit-keyframes uil-ring-anim {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

@-moz-keyframes uil-ring-anim {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

@-webkit-keyframes uil-ring-anim {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

@-o-keyframes uil-ring-anim {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

@keyframes uil-ring-anim {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }
  #gofood .uil-ring-css {
    background: none;
    position: relative;
    width: 200px;
    height: 200px;
    margin: 0 auto;
    display: block; }
  #gofood .uil-ring-css > div {
    position: absolute;
    display: block;
    width: 160px;
    height: 160px;
    top: 20px;
    left: 20px;
    border-radius: 80px;
    box-shadow: 0 3px 0 0 #B62025;
    -webkit-transform-origin: 80px 81.5px;
    transform-origin: 80px 81.5px;
    -webkit-animation: uil-ring-anim 1s linear infinite;
    animation: uil-ring-anim 1s linear infinite; }

@keyframes stroke {
  100% {
    stroke-dashoffset: 0; } }

@keyframes scale {
  0%,
  100% {
    transform: none; }
  50% {
    transform: scale3d(1.1, 1.1, 1); } }

@keyframes fill {
  100% {
    box-shadow: inset 0px 0px 0px 30px #7ac142; } }
  #gofood #promo-banner .detail .custom-nav .slick-disabled ellipse {
    stroke: #9b9b9b !important; }
  #gofood #promo-banner .detail .custom-nav .slick-disabled polygon {
    fill: #9b9b9b; }
  #gofood #download-cards {
    display: none; }
  #gofood .articles--feed .bg {
    background: #B62025; }
  #gofood .articles--feed .section--title {
    color: #ffffff; }
  #gofood .articles--feed svg ellipse {
    stroke: #ffffff !important; }
  #gofood .articles--feed svg polygon {
    fill: #ffffff !important; }
  #gofood .articles--feed .line--title {
    background: #ffffff; }
  #gofood .articles--feed .read--more > a {
    background: #B62025; }
  #gofood .articles--feed .content .one {
    border-bottom: 6px solid #B62025; }
  #gofood .articles--feed .content .two {
    border-bottom: 6px solid #B62025; }
  #gofood .articles--feed .content .three {
    border-bottom: 6px solid #B62025; }
  #gofood .articles--feed .content .four {
    border-bottom: 6px solid #B62025; }
  #gofood .articles--feed h4 > a:hover {
    color: #B62025; }
  #gofood #join-now .bg-layer {
    position: relative;
    background-color: #e4292c; }
    #gofood #join-now .bg-layer img {
      width: 100%;
      opacity: 0.9;
      filter: contrast(0.9);
      mix-blend-mode: multiply; }
  #gofood #join-now .right a {
    background-color: #b62025; }
  #gofood .how-it-works--wrapper .section--title {
    color: #b62025; }
    #gofood .how-it-works--wrapper .section--title:before {
      background-color: #b62025; }
  #gofood .how-it-works--wrapper .rect--primary {
    background: #b62025; }
  #gofood .section-how-to-use h5 {
    color: #B62025; }
  #gofood .section-how-to-use .line.gradient {
    background: #B62025; }
  #gofood .section-how-to-use .arrow-up {
    stroke: #B62025; }
  #gofood .section-how-to-use .arrow-up-circle {
    stroke-width: 3;
    stroke: #B62025; }
  #gofood .section-how-to-use .arrow-down {
    stroke: #B62025; }
  #gofood .section-how-to-use .arrow-down-circle {
    stroke: #B62025;
    stroke-width: 3; }
  #gofood .section-how-to-use .slider .slick-dots li.slick-active button:before {
    color: #B62025; }
  #gofood .section-how-to-use .slider-image .slick-dots li.slick-active button:before {
    color: #B62025; }
  #gofood .section-how-to-use .text-mobile .line.gradient {
    background: #B62025; }
  #gofood .section-how-to-use .text h5 {
    color: #B62025; }
  #gofood .section-how-to-use .text .line.gradient {
    background: #B62025; }
  #gofood .section-how-to-use .slider-image .slick-dots li.slick-active button:before {
    color: #B62025; }
  #gofood .updatemenu ::-webkit-input-placeholder {
    color: #c3c3c3; }
  #gofood .updatemenu :-moz-placeholder {
    color: #c3c3c3;
    opacity: 1; }
  #gofood .updatemenu ::-moz-placeholder {
    color: #c3c3c3;
    opacity: 1; }
  #gofood .updatemenu :-ms-input-placeholder {
    color: #c3c3c3; }
  #gofood .updatemenu ::-ms-input-placeholder {
    color: #c3c3c3; }
  #gofood .updatemenu .messages-alert {
    display: none;
    background-color: #B62025;
    width: 100%;
    padding: 20px;
    text-transform: capitalize;
    margin-top: 80px;
    margin-bottom: -80px; }
    #gofood .updatemenu .messages-alert h5 {
      color: white;
      font-size: 15px;
      margin: 0;
      padding: 0; }
  #gofood .updatemenu .checkmark__circle {
    stroke: #f5a523;
    fill: #f5a523;
    stroke-dasharray: 166;
    stroke-dashoffset: 166;
    stroke-width: 2;
    stroke-miterlimit: 10;
    animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards; }
  #gofood .updatemenu .checkmark {
    top: -15px;
    left: 20px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: block;
    stroke-width: 2;
    stroke: #fff;
    stroke-miterlimit: 10;
    margin: 10% auto;
    box-shadow: inset 0 0 0 #b62025;
    -webkit-animation: c .4s ease-in-out .4s forwards,b .3s ease-in-out .9s both;
    animation: c .4s ease-in-out .4s forwards,b .3s ease-in-out .9s both; }
  #gofood .updatemenu .checkmark__check {
    transform-origin: 50% 50%;
    stroke-dasharray: 29;
    stroke-dashoffset: 29;
    animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards; }
  #gofood .updatemenu #content-only.thank-you {
    margin-top: 0px; }
    #gofood .updatemenu #content-only.thank-you .container {
      background: white;
      box-shadow: 0 -3em 0 1.5em #B62025; }
      #gofood .updatemenu #content-only.thank-you .container .info h4 {
        color: #B62025; }
      #gofood .updatemenu #content-only.thank-you .container .info .line {
        background: #B62025; }
      #gofood .updatemenu #content-only.thank-you .container .info .link a {
        width: 280px; }
      #gofood .updatemenu #content-only.thank-you .container .info .link .contact {
        margin-bottom: 15px;
        background: #B62025; }
        #gofood .updatemenu #content-only.thank-you .container .info .link .contact span {
          margin: 0 15px; }
      #gofood .updatemenu #content-only.thank-you .container .info .link .contact.homepage {
        background: #c8c8c8; }
  #gofood .updatemenu .section-join-form.updatemenu {
    margin-top: 50px;
    padding: 80px; }
    #gofood .updatemenu .section-join-form.updatemenu form#input-form-gofood-updatemenu {
      position: relative;
      height: 100%;
      background: white; }
      #gofood .updatemenu .section-join-form.updatemenu form#input-form-gofood-updatemenu #button--wrapper {
        position: absolute;
        bottom: -30px;
        right: 0; }
    #gofood .updatemenu .section-join-form.updatemenu .main-wrapper {
      height: 890px;
      width: 100%;
      box-shadow: 0px 0px 50px rgba(90, 90, 90, 0.25);
      /*
			 .button-submit {
					background: #48af4a;
					z-index: 100;
					box-shadow: 0 2px 10px 0 rgba(0,0,0,.45);
					font-size: 14px;
					font-family: $opensans;
					font-weight: 400;
					padding: 25px;
					transition: all .5s ease 0s;
					color: #fff;
					float: right;
					text-transform: none;
					position: absolute;
					top: 30px;
					left: -50px;
					border-radius: 8px !important;

					span {
						margin-right: 50px;
						margin-left: 50px;
					}
			 }

			.button-submi:hover {
				box-shadow: 0 2px 10px 0 rgba(0, 0, 0, .65);
			}
			*/ }
      #gofood .updatemenu .section-join-form.updatemenu .main-wrapper #map {
        height: 300px;
        width: 100%;
        margin-top: 5px; }
      #gofood .updatemenu .section-join-form.updatemenu .main-wrapper #maps-display {
        display: block;
        height: 300px;
        width: 100%;
        margin-top: 5px; }
      #gofood .updatemenu .section-join-form.updatemenu .main-wrapper .maps-show {
        display: block !important;
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)" !important;
        filter: alpha(opacity=100) !important;
        -moz-opacity: 1.0 !important;
        -khtml-opacity: 1.0 !important; }
      #gofood .updatemenu .section-join-form.updatemenu .main-wrapper .maps-hide {
        margin-bottom: -300px;
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=00)" !important;
        filter: alpha(opacity=00) !important;
        -moz-opacity: 0.0 !important;
        -khtml-opacity: 0.0 !important;
        opacity: 0.0 !important; }
      #gofood .updatemenu .section-join-form.updatemenu .main-wrapper .background-wrapper {
        background: url("https://d3naj63yelf4gk.cloudfront.net/dist/img/card/join-form/gofood-texture.jpg?ver=1");
        min-height: 890px;
        height: 100% !important;
        width: 100%;
        padding: 0;
        text-align: center; }
        #gofood .updatemenu .section-join-form.updatemenu .main-wrapper .background-wrapper img {
          max-width: 85px;
          height: 60px;
          margin-top: 280px; }
      #gofood .updatemenu .section-join-form.updatemenu .main-wrapper .text-wrapper .title {
        margin-top: -15px;
        font-size: 12px;
        position: relative;
        top: 10px;
        color: #c8c8c8; }
      #gofood .updatemenu .section-join-form.updatemenu .main-wrapper .form-container {
        padding: 50px; }
      #gofood .updatemenu .section-join-form.updatemenu .main-wrapper .form-container[data-step="2"],
      #gofood .updatemenu .section-join-form.updatemenu .main-wrapper .form-container[data-step="3"] {
        display: none; }
      #gofood .updatemenu .section-join-form.updatemenu .main-wrapper .form-wrapper .group {
        width: 100%;
        margin: 20px 0;
        /**
						 * Create the checkbox state for the tick
						 */ }
        #gofood .updatemenu .section-join-form.updatemenu .main-wrapper .form-wrapper .group label {
          font-family: "Open Sans", Helvetica, sans-serif;
          font-weight: 700;
          text-transform: uppercase;
          letter-spacing: 1.25px;
          font-size: 10.5px;
          color: #697b8c; }
        #gofood .updatemenu .section-join-form.updatemenu .main-wrapper .form-wrapper .group input[type=text],
        #gofood .updatemenu .section-join-form.updatemenu .main-wrapper .form-wrapper .group input[type=password],
        #gofood .updatemenu .section-join-form.updatemenu .main-wrapper .form-wrapper .group input[type=number],
        #gofood .updatemenu .section-join-form.updatemenu .main-wrapper .form-wrapper .group select {
          width: 100%;
          height: 45px;
          padding: 15px;
          margin-top: 5px;
          letter-spacing: 1.2px;
          font-size: 13.4px;
          background: white;
          border-radius: 0 !important;
          -webkit-appearance: none;
          border: 1px solid #d2d2d2;
          color: #485460; }
        #gofood .updatemenu .section-join-form.updatemenu .main-wrapper .form-wrapper .group input[type=radio] {
          margin-top: 10px; }
        #gofood .updatemenu .section-join-form.updatemenu .main-wrapper .form-wrapper .group .checkbox-style {
          width: 25px;
          margin: 10px 0px;
          position: relative; }
        #gofood .updatemenu .section-join-form.updatemenu .main-wrapper .form-wrapper .group .checkbox-style label {
          cursor: pointer;
          position: absolute;
          width: 25px;
          height: 25px;
          top: 0;
          left: 0;
          background: #eee;
          border: 1px solid #ddd; }
        #gofood .updatemenu .section-join-form.updatemenu .main-wrapper .form-wrapper .group .checkbox-style label:after {
          opacity: 0.0;
          content: '';
          position: absolute;
          width: 9px;
          height: 5px;
          background: transparent;
          top: 6px;
          left: 7px;
          border: 3px solid #333;
          border-top: none;
          border-right: none;
          transform: rotate(-45deg); }
        #gofood .updatemenu .section-join-form.updatemenu .main-wrapper .form-wrapper .group .checkbox-style label:hover::after {
          opacity: 0.0; }
        #gofood .updatemenu .section-join-form.updatemenu .main-wrapper .form-wrapper .group .checkbox-label {
          position: relative;
          top: -30px;
          left: 40px; }
        #gofood .updatemenu .section-join-form.updatemenu .main-wrapper .form-wrapper .group .checkbox-style input[type=checkbox]:checked + label:after {
          opacity: 1; }
        #gofood .updatemenu .section-join-form.updatemenu .main-wrapper .form-wrapper .group textarea {
          resize: vertical;
          position: relative;
          top: 2px;
          padding: 15px;
          margin-top: 5px;
          letter-spacing: 1.2px;
          width: 100%;
          font-size: 13.4px;
          background: white;
          border-radius: 0 !important;
          border: 1px solid #d2d2d2;
          color: #485460; }
        #gofood .updatemenu .section-join-form.updatemenu .main-wrapper .form-wrapper .group .combo-box {
          position: relative; }
        #gofood .updatemenu .section-join-form.updatemenu .main-wrapper .form-wrapper .group .combo-box:after {
          content: '\f0d7';
          font-family: 'FontAwesome';
          flex-direction: column;
          justify-content: center;
          right: 15px;
          top: 35.75px;
          text-align: right;
          position: absolute; }
        #gofood .updatemenu .section-join-form.updatemenu .main-wrapper .form-wrapper .group .combo-box-long:after {
          top: 50px; }
      #gofood .updatemenu .section-join-form.updatemenu .main-wrapper .form-wrapper .error_messages {
        color: red;
        margin-top: 10px; }
      #gofood .updatemenu .section-join-form.updatemenu .main-wrapper .upload-type-file {
        opacity: .0; }
      #gofood .updatemenu .section-join-form.updatemenu .main-wrapper .upload-wrapper {
        margin-top: 15px; }
        #gofood .updatemenu .section-join-form.updatemenu .main-wrapper .upload-wrapper .title {
          color: #697b8c;
          font-family: "Open Sans", Helvetica, sans-serif;
          font-weight: 700;
          text-transform: uppercase;
          letter-spacing: 1.25px;
          font-size: 12.5px;
          margin-bottom: 10px; }
        #gofood .updatemenu .section-join-form.updatemenu .main-wrapper .upload-wrapper .subtitle {
          color: #697b8c;
          font-family: "Open Sans", Helvetica, sans-serif;
          font-weight: 700;
          text-transform: none;
          letter-spacing: 1.25px;
          font-size: 12.5px;
          margin-bottom: 10px; }
        #gofood .updatemenu .section-join-form.updatemenu .main-wrapper .upload-wrapper .upload-loader {
          display: none;
          margin-top: -90px;
          color: #c3c3c3;
          font-size: 12px;
          text-align: center; }
        #gofood .updatemenu .section-join-form.updatemenu .main-wrapper .upload-wrapper .upload-box {
          margin-top: 0px;
          height: 400px;
          overflow-y: scroll;
          padding: 20px;
          border: 1px solid gainsboro; }
          #gofood .updatemenu .section-join-form.updatemenu .main-wrapper .upload-wrapper .upload-box .list {
            cursor: pointer; }
            #gofood .updatemenu .section-join-form.updatemenu .main-wrapper .upload-wrapper .upload-box .list .list.plus {
              background: #B62025;
              padding: 20px;
              font-size: 40px;
              color: white;
              font-weight: 700; }
              #gofood .updatemenu .section-join-form.updatemenu .main-wrapper .upload-wrapper .upload-box .list .list.plus span {
                margin: 0 20px; }
          #gofood .updatemenu .section-join-form.updatemenu .main-wrapper .upload-wrapper .upload-box .upload-first {
            margin-top: 100px;
            color: #c3c3c3;
            font-size: 12px;
            text-align: center; }
            #gofood .updatemenu .section-join-form.updatemenu .main-wrapper .upload-wrapper .upload-box .upload-first .icon-preview {
              display: none; }
            #gofood .updatemenu .section-join-form.updatemenu .main-wrapper .upload-wrapper .upload-box .upload-first .button-upload-first {
              width: 130px;
              background: #B62025;
              cursor: pointer;
              margin: 0 auto !important;
              display: block;
              padding: 15px;
              color: white; }
              #gofood .updatemenu .section-join-form.updatemenu .main-wrapper .upload-wrapper .upload-box .upload-first .button-upload-first span {
                margin: 0 20px; }
          #gofood .updatemenu .section-join-form.updatemenu .main-wrapper .upload-wrapper .upload-box .upload-list {
            display: none; }
            #gofood .updatemenu .section-join-form.updatemenu .main-wrapper .upload-wrapper .upload-box .upload-list .list.plus {
              background: #B62025;
              padding: 20px;
              font-size: 40px;
              color: white;
              font-weight: 700;
              position: relative; }
              #gofood .updatemenu .section-join-form.updatemenu .main-wrapper .upload-wrapper .upload-box .upload-list .list.plus span {
                margin: 0 20px; }
            #gofood .updatemenu .section-join-form.updatemenu .main-wrapper .upload-wrapper .upload-box .upload-list .box {
              border: 1px solid #c8c8c8;
              height: 150px;
              position: relative;
              margin: 0 5px;
              margin-bottom: 10px; }
              #gofood .updatemenu .section-join-form.updatemenu .main-wrapper .upload-wrapper .upload-box .upload-list .box img {
                position: absolute;
                top: 50%;
                left: 50%;
                max-height: 100%;
                transform: translate(-50%, -50%); }
            #gofood .updatemenu .section-join-form.updatemenu .main-wrapper .upload-wrapper .upload-box .upload-list .box.addmore {
              padding: 50px;
              background: #B62025;
              height: 150px;
              position: relative;
              margin: 0 5px;
              margin-bottom: 10px; }
              #gofood .updatemenu .section-join-form.updatemenu .main-wrapper .upload-wrapper .upload-box .upload-list .box.addmore label {
                font-size: 70px;
                position: absolute;
                top: 45%;
                left: 50%;
                max-height: 100%;
                transform: translate(-50%, -50%); }
      #gofood .updatemenu .section-join-form.updatemenu .main-wrapper .form-button {
        /*position: relative;
					top: -10px;
					left: -40px;*/ }
        #gofood .updatemenu .section-join-form.updatemenu .main-wrapper .form-button .button {
          font-size: 14px;
          font-family: "Open Sans", Helvetica, sans-serif;
          font-weight: 400;
          padding: 35px;
          transition: all .5s ease 0s;
          /*margin: 0 5px;*/ }
          #gofood .updatemenu .section-join-form.updatemenu .main-wrapper .form-button .button span {
            margin: 0 50px; }
        #gofood .updatemenu .section-join-form.updatemenu .main-wrapper .form-button .button:hover,
        #gofood .updatemenu .section-join-form.updatemenu .main-wrapper .form-button .button:focus {
          box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.65); }
        #gofood .updatemenu .section-join-form.updatemenu .main-wrapper .form-button .button-navigation {
          background: #353b44;
          color: #fff;
          padding: 27.75px;
          /*float: right;*/
          position: absolute;
          right: 250px;
          bottom: 0; }
        #gofood .updatemenu .section-join-form.updatemenu .main-wrapper .form-button .button-navigation.next {
          background: #B62025; }
        #gofood .updatemenu .section-join-form.updatemenu .main-wrapper .form-button .button-navigation.next.button-disabled {
          background: #d2d2d2;
          cursor: not-allowed;
          box-shadow: none; }
        #gofood .updatemenu .section-join-form.updatemenu .main-wrapper .form-button .button-submit-go {
          display: none;
          text-transform: none;
          background: #B62025;
          color: #fff;
          /*float: right;*/
          padding: 24px;
          right: 30px;
          position: absolute;
          bottom: 0; }
        #gofood .updatemenu .section-join-form.updatemenu .main-wrapper .form-button .button-navigation.next {
          display: inline;
          right: 30px; }
        #gofood .updatemenu .section-join-form.updatemenu .main-wrapper .form-button .button-navigation.back {
          display: none; }

@media screen and (max-width: 768px) {
  #gofood section#download-cards {
    display: block;
    padding: 30px 0; }
    #gofood section#download-cards .bg-right {
      padding: 0; }
    #gofood section#download-cards .download-list > a {
      display: inline-block;
      float: left;
      padding: 12px 0; }
      #gofood section#download-cards .download-list > a > p {
        font-size: 1.1em;
        margin: 0;
        color: #24a749; }
      #gofood section#download-cards .download-list > a > svg {
        margin: 6px 10px; }
        #gofood section#download-cards .download-list > a > svg g {
          fill: #24a749; }
    #gofood section#download-cards .download-list .download-btn.ios {
      background-color: #656565; }
    #gofood section#download-cards .download-list .download-btn {
      width: 50px;
      height: 50px;
      background-size: 35%; }
  #gofood .section-how-to-use .arrow--wrapper {
    top: 22%; }
  #gofood .section-how-to-use .phone-wrapper {
    top: 35%; }
  #gofood .section-how-to-use ul.slick-dots {
    margin-top: -3.5em; }
  #gofood .updatemenu .section-join-form.updatemenu {
    margin-top: 50px;
    padding: 0px; }
    #gofood .updatemenu .section-join-form.updatemenu .main-wrapper {
      margin: 50px 0;
      height: 890px;
      width: 100%;
      box-shadow: 0px 0px 50px rgba(90, 90, 90, 0.25);
      /*
				 .button-submit {
						background: #48af4a;
						z-index: 100;
						box-shadow: 0 2px 10px 0 rgba(0,0,0,.45);
						font-size: 14px;
						font-family: $opensans;
						font-weight: 400;
						padding: 25px;
						transition: all .5s ease 0s;
						color: #fff;
						float: right;
						text-transform: none;
						position: absolute;
						top: 30px;
						left: -50px;
						border-radius: 8px !important;

						span {
							margin-right: 50px;
							margin-left: 50px;
						}
				 }

				.button-submi:hover {
					box-shadow: 0 2px 10px 0 rgba(0, 0, 0, .65);
				}
				*/ }
      #gofood .updatemenu .section-join-form.updatemenu .main-wrapper .background-wrapper {
        background: url("https://d3naj63yelf4gk.cloudfront.net/dist/img/card/join-form/gofood-texture.jpg?ver=1");
        min-height: 100px;
        height: 100% !important;
        width: 100%;
        padding: 0;
        text-align: center; }
        #gofood .updatemenu .section-join-form.updatemenu .main-wrapper .background-wrapper img {
          max-width: 25px;
          margin-top: 20px; }
      #gofood .updatemenu .section-join-form.updatemenu .main-wrapper .text-wrapper .title {
        margin-top: -15px;
        font-size: 12px;
        position: relative;
        top: 10px;
        color: #c8c8c8; }
      #gofood .updatemenu .section-join-form.updatemenu .main-wrapper .form-container {
        padding: 20px; }
      #gofood .updatemenu .section-join-form.updatemenu .main-wrapper .form-container[data-step="2"],
      #gofood .updatemenu .section-join-form.updatemenu .main-wrapper .form-container[data-step="3"] {
        display: none; }
      #gofood .updatemenu .section-join-form.updatemenu .main-wrapper .form-wrapper .group {
        width: 100%;
        margin: 20px 0; }
        #gofood .updatemenu .section-join-form.updatemenu .main-wrapper .form-wrapper .group label {
          font-family: "Open Sans", Helvetica, sans-serif;
          font-weight: 700;
          text-transform: uppercase;
          letter-spacing: 1.25px;
          font-size: 10.5px;
          color: #697b8c; }
        #gofood .updatemenu .section-join-form.updatemenu .main-wrapper .form-wrapper .group input[type=text],
        #gofood .updatemenu .section-join-form.updatemenu .main-wrapper .form-wrapper .group input[type=password],
        #gofood .updatemenu .section-join-form.updatemenu .main-wrapper .form-wrapper .group input[type=number],
        #gofood .updatemenu .section-join-form.updatemenu .main-wrapper .form-wrapper .group select {
          width: 100%;
          height: 45px;
          padding: 15px;
          margin-top: 5px;
          letter-spacing: 1.2px;
          font-size: 13.4px;
          background: white;
          border-radius: 0 !important;
          border: 1px solid #d2d2d2;
          -webkit-appearance: none;
          color: #485460; }
        #gofood .updatemenu .section-join-form.updatemenu .main-wrapper .form-wrapper .group input[type=radio] {
          margin-top: 10px; }
        #gofood .updatemenu .section-join-form.updatemenu .main-wrapper .form-wrapper .group .checkbox-label {
          top: -66.5px; }
        #gofood .updatemenu .section-join-form.updatemenu .main-wrapper .form-wrapper .group input[type=checkbox] {
          border: 0 !important; }
        #gofood .updatemenu .section-join-form.updatemenu .main-wrapper .form-wrapper .group textarea {
          position: relative;
          top: 2px; }
      #gofood .updatemenu .section-join-form.updatemenu .main-wrapper .form-wrapper .error_messages {
        color: red;
        margin-top: 10px; }
      #gofood .updatemenu .section-join-form.updatemenu .main-wrapper .upload-button-wrapper span {
        letter-spacing: 1px; }
      #gofood .updatemenu .section-join-form.updatemenu .main-wrapper .upload-type-file {
        opacity: .0; }
      #gofood .updatemenu .section-join-form.updatemenu .main-wrapper .upload-wrapper {
        margin-top: 15px; }
        #gofood .updatemenu .section-join-form.updatemenu .main-wrapper .upload-wrapper .img-list {
          text-align: center; }
        #gofood .updatemenu .section-join-form.updatemenu .main-wrapper .upload-wrapper .title {
          color: #697b8c;
          font-family: "Open Sans", Helvetica, sans-serif;
          font-weight: 700;
          text-transform: uppercase;
          letter-spacing: 1.25px;
          font-size: 12.5px;
          margin-bottom: 10px; }
        #gofood .updatemenu .section-join-form.updatemenu .main-wrapper .upload-wrapper .subtitle {
          color: #697b8c;
          font-family: "Open Sans", Helvetica, sans-serif;
          font-weight: 700;
          text-transform: none;
          letter-spacing: 1.25px;
          font-size: 12.5px;
          margin-bottom: 10px; }
        #gofood .updatemenu .section-join-form.updatemenu .main-wrapper .upload-wrapper .upload-box {
          margin-top: 0px;
          height: 400px;
          overflow-y: scroll;
          padding: 20px;
          border: 1px solid gainsboro; }
          #gofood .updatemenu .section-join-form.updatemenu .main-wrapper .upload-wrapper .upload-box .list {
            margin-top: 20px;
            cursor: pointer; }
            #gofood .updatemenu .section-join-form.updatemenu .main-wrapper .upload-wrapper .upload-box .list .list.plus {
              background: #B62025;
              padding: 20px;
              font-size: 40px;
              color: white;
              font-weight: 700; }
              #gofood .updatemenu .section-join-form.updatemenu .main-wrapper .upload-wrapper .upload-box .list .list.plus span {
                position: relative;
                top: -20px;
                margin: 0 20px; }
          #gofood .updatemenu .section-join-form.updatemenu .main-wrapper .upload-wrapper .upload-box .upload-first {
            margin-top: 100px;
            color: #c3c3c3;
            font-size: 12px;
            text-align: center; }
            #gofood .updatemenu .section-join-form.updatemenu .main-wrapper .upload-wrapper .upload-box .upload-first .icon-preview {
              display: none; }
            #gofood .updatemenu .section-join-form.updatemenu .main-wrapper .upload-wrapper .upload-box .upload-first .button-upload-first {
              width: 130px;
              background: #B62025;
              cursor: pointer;
              margin: 0 auto !important;
              display: block;
              padding: 15px;
              color: white; }
              #gofood .updatemenu .section-join-form.updatemenu .main-wrapper .upload-wrapper .upload-box .upload-first .button-upload-first span {
                margin: 0 20px; }
          #gofood .updatemenu .section-join-form.updatemenu .main-wrapper .upload-wrapper .upload-box .upload-list {
            display: none; }
            #gofood .updatemenu .section-join-form.updatemenu .main-wrapper .upload-wrapper .upload-box .upload-list .list.plus {
              background: #B62025;
              padding: 20px;
              font-size: 40px;
              color: white;
              font-weight: 700;
              display: block;
              position: relative;
              top: 0px; }
              #gofood .updatemenu .section-join-form.updatemenu .main-wrapper .upload-wrapper .upload-box .upload-list .list.plus span {
                position: relative;
                top: -20px;
                margin: 0 20px; }
            #gofood .updatemenu .section-join-form.updatemenu .main-wrapper .upload-wrapper .upload-box .upload-list .box {
              border: 1px solid #c8c8c8;
              height: 220px;
              position: relative;
              margin: 0 5px;
              margin-bottom: 10px; }
              #gofood .updatemenu .section-join-form.updatemenu .main-wrapper .upload-wrapper .upload-box .upload-list .box img {
                position: absolute;
                top: 50%;
                left: 50%;
                max-height: 100%;
                transform: translate(-50%, -50%); }
      #gofood .updatemenu .section-join-form.updatemenu .main-wrapper .form-button {
        /*position: relative;
						top: -15px;
						left: -40px;*/
        position: relative;
        left: -10px; }
        #gofood .updatemenu .section-join-form.updatemenu .main-wrapper .form-button .button {
          font-size: 14px;
          font-family: "Open Sans", Helvetica, sans-serif;
          font-weight: 400;
          padding: 20px 0;
          transition: all .5s ease 0s;
          /*margin: 0 5px;*/ }
          #gofood .updatemenu .section-join-form.updatemenu .main-wrapper .form-button .button span {
            margin: 0 50px; }
        #gofood .updatemenu .section-join-form.updatemenu .main-wrapper .form-button .button:hover,
        #gofood .updatemenu .section-join-form.updatemenu .main-wrapper .form-button .button:focus {
          box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.65); }
        #gofood .updatemenu .section-join-form.updatemenu .main-wrapper .form-button .button-submit-go {
          right: 0; }
        #gofood .updatemenu .section-join-form.updatemenu .main-wrapper .form-button .button-navigation {
          background: #B62025;
          color: #fff;
          padding: 27.75px;
          padding: 24px 0; }
        #gofood .updatemenu .section-join-form.updatemenu .main-wrapper .form-button .button-navigation.next {
          background-color: #B62025; }
        #gofood .updatemenu .section-join-form.updatemenu .main-wrapper .form-button .button-navigation.back {
          display: none;
          background: #282828;
          border: 0;
          color: white;
          text-transform: none;
          float: none;
          /*position: relative;
							left: 140px;
							width: 100%;
							top: 70px;
							margin-bottom: 10px;
							padding: 24px;
    						right: 175px;
						}
						.button-submit-go {
							display: none;
							background: $gofood;
							border: 0;
							color: white;
							text-transform: none;
							float: none;
							/*position: relative;
							left: 175px;
							width: 220px;
							top: 40px;
							margin-bottom: -100px;
							padding: 24px;*/
          padding: 24px 0;
          right: 170px; }
        #gofood .updatemenu .section-join-form.updatemenu .main-wrapper .form-button .button-navigation.next {
          display: inline;
          right: 10px; }
        #gofood .updatemenu .section-join-form.updatemenu .main-wrapper .form-button .button-navigation.back {
          display: none; } }

@media only screen and (max-width: 480px) {
  #gofood .updatemenu .section-join-form.updatemenu .main-wrapper .form-wrapper .group .checkbox-label {
    top: -62.5px; }
  #gofood .updatemenu .section-join-form.updatemenu .main-wrapper .form-button .button-navigation.back {
    right: 170px; }
  #gofood .updatemenu .section-join-form.updatemenu .main-wrapper .form-button .button-submit-go {
    right: 6px; }
    #gofood .updatemenu .section-join-form.updatemenu .main-wrapper .form-button .button-submit-go span {
      margin: 0 40px; } }

@media only screen and (max-width: 320px) {
  #gofood .updatemenu .section-join-form.updatemenu .main-wrapper .form-button {
    position: relative;
    left: 15px; }
    #gofood .updatemenu .section-join-form.updatemenu .main-wrapper .form-button .button-navigation.back {
      right: 167px; }
    #gofood .updatemenu .section-join-form.updatemenu .main-wrapper .form-button .button-submit-go {
      right: 6px; }
      #gofood .updatemenu .section-join-form.updatemenu .main-wrapper .form-button .button-submit-go span {
        margin: 0 40px; } }

#gocar .gofleet header .desc--row {
  position: relative;
  padding-right: unset !important; }
  #gocar .gofleet header .desc--row h1.hero--title {
    margin-top: 10%; }
  #gocar .gofleet header .desc--row p.hero--desc {
    font-size: 22px; }

#gocar .gofleet .brushing {
  background-image: url(../../dist/img/brush/GO-FLEET.png); }

#gocar .gofleet section.services {
  padding: 50px 0 !important; }
  #gocar .gofleet section.services .container:before {
    padding-right: 45px;
    background-color: white;
    left: -35px;
    top: 84px; }
  #gocar .gofleet section.services .container .wrapper {
    padding: 0;
    background: transparent;
    position: relative;
    z-index: 1; }
    #gocar .gofleet section.services .container .wrapper .dotted {
      background-repeat: no-repeat;
      height: 405px;
      position: absolute;
      width: 500px;
      top: 25px;
      left: -300px;
      z-index: -1; }
    #gocar .gofleet section.services .container .wrapper h4 {
      padding-left: 25px;
      position: relative;
      top: 48px;
      left: 50px;
      color: white;
      margin-bottom: 70px; }
    #gocar .gofleet section.services .container .wrapper .service-container-wrapper {
      position: relative; }
      #gocar .gofleet section.services .container .wrapper .service-container-wrapper .list-wrapper {
        position: relative;
        left: 75px;
        margin-top: -16.5%; }
        #gocar .gofleet section.services .container .wrapper .service-container-wrapper .list-wrapper .list {
          margin-right: 50px;
          margin-bottom: 50px; }
          #gocar .gofleet section.services .container .wrapper .service-container-wrapper .list-wrapper .list .img img {
            width: 100%;
            transform: scale(1.075); }
          #gocar .gofleet section.services .container .wrapper .service-container-wrapper .list-wrapper .list .text,
          #gocar .gofleet section.services .container .wrapper .service-container-wrapper .list-wrapper .list .text.times {
            background: #fff;
            padding: 30px;
            position: relative;
            top: -60px;
            margin-bottom: -60px;
            text-align: center; }
          #gocar .gofleet section.services .container .wrapper .service-container-wrapper .list-wrapper .list .text.times {
            background: #f2f2f2; }
          #gocar .gofleet section.services .container .wrapper .service-container-wrapper .list-wrapper .list h5 {
            font-weight: bold;
            line-height: 1.5;
            letter-spacing: 0.8px;
            text-align: center;
            color: #4a4a4a;
            font-size: 18px;
            margin: 0; }
          #gocar .gofleet section.services .container .wrapper .service-container-wrapper .list-wrapper .list i.fa-check {
            font-size: 34px;
            color: #00c79b;
            margin: 20px 0; }
          #gocar .gofleet section.services .container .wrapper .service-container-wrapper .list-wrapper .list i.fa-times {
            font-size: 34px;
            color: #d0021b;
            margin: 20px 0; }
          #gocar .gofleet section.services .container .wrapper .service-container-wrapper .list-wrapper .list p {
            line-height: 1.43;
            letter-spacing: 0.9px;
            text-align: center;
            color: #4a4a4a;
            margin: 0; }
    #gocar .gofleet section.services .container .wrapper .service-container {
      background-image: none;
      background-color: #00c79b;
      height: 20.5em; }

#gocar .gofleet #join-now .bg-layer {
  background-color: rgba(0, 194, 151, 0.8); }

#gocar .gofleet #join-now .right .join--btn {
  background-color: #00c297;
  text-transform: uppercase;
  font-weight: bold;
  right: 2em;
  bottom: -2em; }

#gocar .gofleet .section-our-values {
  padding: 30px; }
  #gocar .gofleet .section-our-values .title {
    color: #00c79b; }
  #gocar .gofleet .section-our-values .line.gradient {
    background: #00c79b; }

#gocar .gofleet #content-only .info h4 {
  color: #00c79b !important; }

#gocar .gofleet #content-only .info .line {
  background: #00c79b !important; }

#gocar .gofleet #content-only .info .link a {
  background: #00c79b !important; }

#gocar #talent-story h4:before {
  background-color: #17619d; }

#gocar #talent-story h4 {
  color: #343b41; }

@-webkit-keyframes uil-ring-anim {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

@-moz-keyframes uil-ring-anim {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

@-webkit-keyframes uil-ring-anim {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

@-o-keyframes uil-ring-anim {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

@keyframes uil-ring-anim {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

#gocar .uil-ring-css {
  background: none;
  position: relative;
  width: 200px;
  height: 200px;
  position: relative;
  margin: 0 auto;
  margin-top: 80px; }
  #gocar .uil-ring-css div {
    position: absolute;
    display: block;
    width: 160px;
    height: 160px;
    top: 20px;
    left: 20px;
    border-radius: 80px;
    box-shadow: 0 3px 0 0 #17619D;
    -webkit-transform-origin: 80px 81.5px;
    transform-origin: 80px 81.5px;
    -webkit-animation: uil-ring-anim 1s linear infinite;
    animation: uil-ring-anim 1s linear infinite; }

#gocar .messages-alert {
  display: none;
  background-color: #17619D;
  width: 100%;
  padding: 20px;
  text-transform: capitalize;
  margin-top: 80px;
  margin-bottom: -80px;
  position: fixed;
  z-index: 999; }
  #gocar .messages-alert h5 {
    color: white;
    font-size: 15px;
    margin: 0;
    padding: 0; }

#gocar header h1 {
  font-weight: bold; }

#gocar header button {
  background: #17619D; }

#gocar .new-section-how-to-use {
  margin-top: 180px;
  margin-bottom: 100px; }
  #gocar .new-section-how-to-use h3.title {
    color: #17619d; }
    #gocar .new-section-how-to-use h3.title:after {
      background: #17619d; }
  #gocar .new-section-how-to-use .content {
    background-color: #17619d; }

#gocar .articles--feed {
  background: #1f4864; }
  #gocar .articles--feed .bg {
    background: #1f4864; }
  #gocar .articles--feed .line--title {
    background: #ffcd2e; }

#gocar #content-only.gocar {
  transform: scale(0.96);
  margin-bottom: -30px; }
  #gocar #content-only.gocar .container {
    box-shadow: 0 -3em 0 1.5em #343b41; }
    #gocar #content-only.gocar .container .info h4 {
      color: #1f4864; }
    #gocar #content-only.gocar .container .info .line {
      background: #17619D; }
    #gocar #content-only.gocar .container .info .link .contact {
      background: #17619D; }
    @media screen and (max-width: 768px) {
      #gocar #content-only.gocar .container {
        width: 100%; } }

#gocar #join-now {
  margin: 0 0 30px 0; }
  #gocar #join-now .right a {
    background: #17619D;
    bottom: initial;
    margin-top: 20px;
    right: 0; }
  #gocar #join-now .right .title {
    font-size: 18px; }
  #gocar #join-now .right h2 {
    margin-top: -5px; }

#gocar .section-how-to-use h5 {
  color: #343b41 !important;
  font-size: 18px;
  margin-top: 200px; }

#gocar .section-how-to-use .line.gradient {
  background: #17619D; }

#gocar .section-how-to-use .arrow-up {
  stroke: #17619D; }

#gocar .section-how-to-use .arrow-up-circle {
  stroke-width: 3;
  stroke: #17619D; }

#gocar .section-how-to-use .arrow-down {
  stroke: #17619D; }

#gocar .section-how-to-use .arrow-down-circle {
  stroke: #17619D;
  stroke-width: 3; }

#gocar .section-how-to-use .slider .slick-dots li.slick-active button:before {
  color: #17619D; }

#gocar .section-how-to-use .slider-image .slick-dots li.slick-active button:before {
  color: #17619D; }

#gocar .section-how-to-use .text-mobile .line.gradient {
  background: #17619D; }

#gocar .section-how-to-use .text h5 {
  color: #17619D; }

#gocar .section-how-to-use .text .line.gradient {
  background: #17619D; }

#gocar .section-how-to-use .slider-image .slick-dots li.slick-active button:before {
  color: #17619D; }

#gocar .section-how-to-use .slider-image .slick-dots li button:before {
  font-size: 13px; }

#gocar .slick-arrow {
  cursor: pointer; }
  #gocar .slick-arrow ellipse {
    stroke: #17619D !important; }
  #gocar .slick-arrow polygon {
    fill: #17619D; }

#gocar .how-it-works--wrapper {
  margin: 0;
  padding-top: 60px;
  padding-bottom: 60px; }
  #gocar .how-it-works--wrapper .container {
    padding: 0; }
    #gocar .how-it-works--wrapper .container h4 {
      color: #17619D;
      position: relative;
      margin-left: 3em; }
    #gocar .how-it-works--wrapper .container .section--title {
      color: #343b41; }
    #gocar .how-it-works--wrapper .container .section--title:before {
      content: "";
      position: absolute;
      left: -5em;
      top: 0.6em;
      padding-right: 45px;
      height: 3px;
      margin: 0;
      background-color: #17619D;
      z-index: 10; }
    #gocar .how-it-works--wrapper .container figure.hand-ss {
      background: #fff; }
      #gocar .how-it-works--wrapper .container figure.hand-ss img {
        max-height: 27em;
        width: auto;
        right: 0;
        margin: 0 auto;
        position: absolute;
        left: 0; }
    #gocar .how-it-works--wrapper .container .layer .rect--primary {
      background: #17619D; }
    #gocar .how-it-works--wrapper .container .layer .rect--primary.custom-color {
      background: #dbdbdb; }
    #gocar .how-it-works--wrapper .container .layer .rect--secondary.custom-color {
      background: #ffce00; }

#gocar .new-section-how-to-use {
  margin-top: 60px;
  margin-bottom: 60px; }
  #gocar .new-section-how-to-use h3.title {
    color: #17619d; }
    #gocar .new-section-how-to-use h3.title:after {
      background: #17619d; }
  #gocar .new-section-how-to-use .content {
    background-color: #17619d; }

#gocar .income-calculator {
  background: #f8f8f8;
  padding: 60px 0; }
  #gocar .income-calculator .point {
    width: 100%;
    height: 470px;
    display: flex; }
    #gocar .income-calculator .point .side {
      padding: 10px; }
    #gocar .income-calculator .point .form {
      background-color: #fff;
      box-shadow: 0 2px 8px 0 #dbdbdb;
      padding: 30px;
      height: 100%; }
      #gocar .income-calculator .point .form .wrapper {
        position: relative;
        left: 15px; }
      #gocar .income-calculator .point .form .section--title {
        font-size: 17.5px;
        font-weight: bold;
        text-transform: uppercase;
        color: #17619D;
        left: -75px;
        line-height: .78;
        letter-spacing: 1.9px;
        position: relative;
        margin-left: 5em; }
        #gocar .income-calculator .point .form .section--title span {
          position: relative;
          top: 5px;
          left: 22px; }
      #gocar .income-calculator .point .form .section--title:before {
        content: "";
        position: absolute;
        left: -5em;
        top: 0.6em;
        padding-right: 55px;
        height: 3px;
        margin: 0;
        background-color: #17619D;
        z-index: 10; }
      #gocar .income-calculator .point .form p {
        position: relative;
        left: -50px;
        margin-left: 6.225em;
        font-size: 14px;
        font-weight: 300;
        line-height: 1.43;
        letter-spacing: 1.5px;
        color: #697b8c; }
      #gocar .income-calculator .point .form .group {
        position: relative;
        left: -50px;
        margin-top: 30px;
        margin-bottom: 30px;
        margin-left: 5.525em; }
        #gocar .income-calculator .point .form .group .selectbox {
          position: relative; }
        #gocar .income-calculator .point .form .group .selectbox:after {
          content: '\f0d7';
          font-family: 'FontAwesome';
          flex-direction: column;
          justify-content: center;
          right: 15px;
          top: 41.25px;
          font-size: 20px;
          text-align: right;
          position: absolute; }
        #gocar .income-calculator .point .form .group label {
          font-size: 14px;
          font-weight: bold;
          font-family: "Open Sans", Helvetica, sans-serif;
          letter-spacing: 1.5px;
          color: #697b8c; }
        #gocar .income-calculator .point .form .group input {
          margin-top: 7.5px;
          height: 50px;
          object-fit: contain;
          border: solid 1px #c3c9d7;
          width: 100%;
          padding: 15px; }
        #gocar .income-calculator .point .form .group select {
          background-color: white;
          margin-top: 7.5px;
          height: 50px;
          object-fit: contain;
          border: solid 1px #c3c9d7;
          width: 100%;
          -webkit-appearance: none;
          border-radius: 0;
          padding: 15px; }
        #gocar .income-calculator .point .form .group input::-webkit-outer-spin-button,
        #gocar .income-calculator .point .form .group input::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0; }
      #gocar .income-calculator .point .form .button {
        width: 100%;
        text-align: right;
        margin-top: 50px;
        margin-bottom: -65px;
        margin-left: -35px; }
        #gocar .income-calculator .point .form .button button {
          border-radius: 5px;
          background-color: #17619D;
          padding: 25px 60px;
          color: white; }
    #gocar .income-calculator .point .output {
      height: 100%; }
      #gocar .income-calculator .point .output .image {
        background-color: #17619D;
        display: inline-block;
        height: 100%;
        align-items: center; }
        #gocar .income-calculator .point .output .image img {
          margin-top: 55px; }
      #gocar .income-calculator .point .output .loader {
        display: none;
        background-color: #fff;
        box-shadow: 0 2px 8px 0 #dbdbdb;
        padding: 30px;
        height: 100%;
        width: 100%;
        text-align: center; }
      #gocar .income-calculator .point .output .result {
        display: none;
        background-color: #fff;
        box-shadow: 0 2px 8px 0 #dbdbdb;
        padding: 30px;
        height: 100%;
        width: 100%;
        text-align: center; }
        #gocar .income-calculator .point .output .result .wrapper {
          padding: 65px 0; }
          #gocar .income-calculator .point .output .result .wrapper img {
            max-width: 160px;
            margin: 0 auto;
            display: block; }
          #gocar .income-calculator .point .output .result .wrapper p {
            font-family: "Open Sans", Helvetica, sans-serif;
            font-size: 14px;
            font-weight: 300;
            line-height: 1.43;
            letter-spacing: 1.5px;
            text-align: center;
            color: #697b8c; }
          #gocar .income-calculator .point .output .result .wrapper h4 {
            object-fit: contain;
            font-family: "Lato", Helvetica, sans-serif;
            font-size: 20px;
            font-weight: bold;
            letter-spacing: 1.1px;
            color: #221f20;
            margin: 0;
            margin-top: -7.5px; }
          #gocar .income-calculator .point .output .result .wrapper .button {
            margin-top: 37.5px; }
            #gocar .income-calculator .point .output .result .wrapper .button a {
              border-radius: 5px;
              background-color: #17619D;
              padding: 25px 15px;
              color: white;
              font-weight: bold;
              line-height: 0.8;
              letter-spacing: 3.3px; }

@media screen and (max-width: 1024px) {
  #gocar .income-calculator .point {
    height: 510px; }
    #gocar .income-calculator .point .form .section--title {
      line-height: 23px; }
    #gocar .income-calculator .point .form .button {
      margin-top: 0; } }

@media screen and (max-width: 768px) {
  #gocar .income-calculator .point {
    height: 560px; }
    #gocar .income-calculator .point .form .section--title span {
      top: 0; }
    #gocar .income-calculator .point .output .image img {
      margin-top: 150px !important; }
    #gocar .income-calculator .point .output .result {
      padding: 105px 0; }
  #gocar #content-only {
    transform: unset !important; }
  #gocar .how-it-works--wrapper {
    padding-top: 0;
    margin-top: -5px; }
    #gocar .how-it-works--wrapper .container .section--title {
      margin-left: 4.2em;
      position: relative;
      top: 10px; }
      #gocar .how-it-works--wrapper .container .section--title:before {
        top: .565em !important; } }

@media screen and (max-width: 35.5em) {
  #gocar .gofleet #join-now {
    padding-bottom: 120px; }
  #gocar .gofleet #content-only {
    padding: 0px 10px 130px !important; }
    #gocar .gofleet #content-only .container {
      box-shadow: 0 -3em 0 2.2em #343b41; }
      #gocar .gofleet #content-only .container .info .link a {
        line-height: 15px; }
  #gocar .gofleet header .desc--row p.hero--desc {
    font-size: 17px;
    margin-top: 20px; }
  #gocar .gofleet section.services .container:before {
    padding-right: 35px;
    background-color: #00c79b;
    /* left: -35px; */
    top: 42.5px;
    left: 50px; }
  #gocar .gofleet section.services .container .wrapper .service-container {
    height: 60px !important;
    background: white; }
    #gocar .gofleet section.services .container .wrapper .service-container h4 {
      color: #343b41;
      top: 8.5px;
      left: 80px; }
  #gocar .gofleet section.services .container .wrapper .service-container-wrapper .list-wrapper {
    margin-top: 7.5%;
    left: 0; }
    #gocar .gofleet section.services .container .wrapper .service-container-wrapper .list-wrapper .list {
      margin: unset;
      height: 235px;
      transform: scale(1.085);
      margin-bottom: 20px; }
      #gocar .gofleet section.services .container .wrapper .service-container-wrapper .list-wrapper .list .img {
        width: 50%;
        float: left; }
        #gocar .gofleet section.services .container .wrapper .service-container-wrapper .list-wrapper .list .img img {
          transform: scale(1); }
      #gocar .gofleet section.services .container .wrapper .service-container-wrapper .list-wrapper .list .text {
        width: 50%;
        float: right;
        top: 0;
        margin-bottom: 0;
        padding: 10px; }
        #gocar .gofleet section.services .container .wrapper .service-container-wrapper .list-wrapper .list .text i {
          display: inline;
          background: #00c79b;
          color: white;
          padding: 7.5px 12.5px 7.5px 7.5px;
          font-size: 25px;
          position: absolute;
          bottom: -20px;
          left: -40px; }
      #gocar .gofleet section.services .container .wrapper .service-container-wrapper .list-wrapper .list .text.checklist {
        background: #00c79b;
        margin-bottom: 60px;
        height: 100%; }
        #gocar .gofleet section.services .container .wrapper .service-container-wrapper .list-wrapper .list .text.checklist h5 {
          color: white;
          text-align: left; }
        #gocar .gofleet section.services .container .wrapper .service-container-wrapper .list-wrapper .list .text.checklist p {
          color: white;
          padding-bottom: 0;
          text-align: left;
          margin-top: 10px; }
      #gocar .gofleet section.services .container .wrapper .service-container-wrapper .list-wrapper .list .text.times {
        height: 100%;
        padding: 10px;
        top: 0;
        margin-bottom: 60px; }
        #gocar .gofleet section.services .container .wrapper .service-container-wrapper .list-wrapper .list .text.times h5,
        #gocar .gofleet section.services .container .wrapper .service-container-wrapper .list-wrapper .list .text.times p {
          text-align: left; }
        #gocar .gofleet section.services .container .wrapper .service-container-wrapper .list-wrapper .list .text.times p {
          margin-top: 10px; }
    #gocar .gofleet section.services .container .wrapper .service-container-wrapper .list-wrapper .list.second-side .img {
      float: right !important; }
    #gocar .gofleet section.services .container .wrapper .service-container-wrapper .list-wrapper .list.second-side .text i {
      display: inline;
      background: #f2f2f2;
      color: #d0021b;
      padding: 7.5px 15px 7.5px 10px;
      font-size: 25px;
      position: absolute;
      bottom: -20px;
      right: -40px;
      left: unset; }
  #gocar .gofleet section.services .container .wrapper .dotted {
    height: 60px;
    position: absolute;
    width: 75px;
    top: 15px;
    left: -20px;
    z-index: 1; }
  #gocar .messages-alert {
    margin-top: 60px; }
  #gocar .income-calculator .container {
    padding-left: 0;
    padding-right: 0; }
  #gocar .income-calculator .point {
    display: block !important;
    height: 560px; }
    #gocar .income-calculator .point .side {
      padding: 0; }
    #gocar .income-calculator .point .side-mobile {
      width: 100%; }
    #gocar .income-calculator .point .form {
      display: block;
      padding: 30px 0; }
      #gocar .income-calculator .point .form .wrapper {
        left: 7.5px; }
        #gocar .income-calculator .point .form .wrapper .section--title {
          left: -52.5px; }
          #gocar .income-calculator .point .form .wrapper .section--title span {
            left: 2.5px;
            top: 0; }
        #gocar .income-calculator .point .form .wrapper .section--title:before {
          left: -4.2em; }
      #gocar .income-calculator .point .form .button {
        text-align: center;
        margin-left: 0; }
    #gocar .income-calculator .point .output .image {
      display: none; }
    #gocar .income-calculator .point .output .loader {
      height: 500px; }
    #gocar .income-calculator .point .output .result {
      display: none; }
      #gocar .income-calculator .point .output .result a.close {
        color: #17619D;
        position: relative;
        top: -155px;
        font-size: 34px;
        right: 15px;
        float: right; }
  #gocar #join-now .right .button {
    text-align: center; }
    #gocar #join-now .right .button a {
      right: unset !important;
      position: relative;
      top: 55px; }
  #gocar #content-only {
    padding: 30px 10px; }
    #gocar #content-only .container {
      box-shadow: 0 -110px 0 40px #343b41; }
      #gocar #content-only .container h4 {
        margin-bottom: -15px; }
      #gocar #content-only .container .info p {
        margin: 0;
        margin-bottom: -30px;
        padding: 2.5px; }
      #gocar #content-only .container .link {
        position: relative;
        top: 40px; }
        #gocar #content-only .container .link a {
          margin: 0 auto;
          display: block;
          width: 200px;
          padding: 30px; }
        #gocar #content-only .container .link .faq {
          position: relative;
          top: -10px; }
  #gocar .new-section-how-to-use {
    margin-top: -60px;
    margin-bottom: -60px; }
  #gocar .section-how-to-use .arrow--wrapper {
    top: 25%; }
  #gocar .section-how-to-use ul.slick-dots {
    margin-top: -3.5em; }
  #gocar .section-how-to-use .phone-wrapper {
    top: 31%; } }

#gobluebird header#main--hero {
  background-image: url(https://d3naj63yelf4gk.cloudfront.net/dist/img/header/hero/go-massage.jpg); }
  #gobluebird header#main--hero .hero--title {
    margin-top: -50px;
    font-size: 37.5px; }
  #gobluebird header#main--hero .hero--subtitle {
    color: white;
    font-weight: 300; }
  #gobluebird header#main--hero .hero--description {
    color: white;
    font-weight: 300; }

#gobluebird .how-it-works--wrapper h4 {
  color: #27285F;
  position: relative;
  margin-left: 3em; }

#gobluebird .how-it-works--wrapper .section--title:before {
  content: "";
  position: absolute;
  left: -5em;
  top: 0.6em;
  padding-right: 45px;
  height: 3px;
  margin: 0;
  background-color: #27285F;
  z-index: 10; }

#gobluebird .how-it-works--wrapper figure.hand-ss {
  background: #fff; }
  #gobluebird .how-it-works--wrapper figure.hand-ss img {
    max-height: 27em;
    width: auto;
    right: 0;
    margin: 0 auto;
    position: absolute;
    left: 0; }

#gobluebird .how-it-works--wrapper .layer .rect--primary {
  background: #27285F; }

#gosend [data-pages=join] .messages-alert {
  position: fixed;
  display: none;
  background-color: #8BC440;
  width: 100%;
  padding: 20px;
  margin-top: 0px;
  z-index: 99;
  top: 86px;
  margin-bottom: -80px;
  box-shadow: 0px -5px 25px rgba(10, 10, 10, 0.85); }
  #gosend [data-pages=join] .messages-alert h5 {
    color: white;
    font-size: 15px;
    margin: 0;
    padding: 0; }

#gosend [data-pages=join] .section-our-values h4.title {
  color: unset; }

#gosend [data-pages=join] .section-our-values .list h4 {
  font-weight: unset; }

#gosend [data-pages=join] .section-our-values .list .line {
  position: relative;
  top: 35px; }

#gosend [data-pages=join] .section-our-values.new img {
  max-height: 150px;
  width: auto; }

@media screen and (max-width: 767px) {
  #gosend [data-pages=join] .section-our-values.new h4.title:after {
    top: 30px;
    position: absolute;
    width: 15px;
    left: -20px;
    background-color: #4285a7;
    height: 2px;
    content: ""; } }

#gosend [data-pages=join] .section-our-values.new .list svg {
  height: 140px; }
  @media screen and (max-width: 767px) {
    #gosend [data-pages=join] .section-our-values.new .list svg {
      height: 130px; } }
  #gosend [data-pages=join] .section-our-values.new .list svg.adjust {
    position: relative;
    left: -20px; }

#gosend [data-pages=join] .section-our-values.new .list h4 {
  color: #4a4a4a; }
  @media screen and (min-width: 768px) {
    #gosend [data-pages=join] .section-our-values.new .list h4 {
      margin-top: 50px;
      margin-bottom: 40px; } }
  @media screen and (max-width: 767px) {
    #gosend [data-pages=join] .section-our-values.new .list h4 {
      padding: 0px;
      margin-bottom: 0px;
      margin-top: 20px; } }

#gosend [data-pages=join] .section-our-values.new .list p {
  font-family: "Open Sans", Helvetica, sans-serif; }
  @media screen and (min-width: 768px) {
    #gosend [data-pages=join] .section-our-values.new .list p {
      margin-top: 10px; } }
  @media screen and (max-width: 767px) {
    #gosend [data-pages=join] .section-our-values.new .list p {
      max-width: 300px; } }

#gosend [data-pages=join] .section-our-values.new .line {
  background: #8BC440; }
  @media screen and (max-width: 767px) {
    #gosend [data-pages=join] .section-our-values.new .line {
      display: none; } }

@media screen and (max-width: 767px) {
  #gosend [data-pages=join] .section-our-values.new h4.title {
    font-size: 14px;
    text-align: left; } }

#gosend [data-pages=join] .section-our-values.new #type-gobills {
  width: 100%; }
  #gosend [data-pages=join] .section-our-values.new #type-gobills .arrow-left {
    position: absolute;
    left: 0;
    bottom: -40px;
    width: 50px; }
  #gosend [data-pages=join] .section-our-values.new #type-gobills .arrow-right {
    position: absolute;
    right: 0;
    bottom: -40px;
    width: 50px; }
  #gosend [data-pages=join] .section-our-values.new #type-gobills .slick-arrow.slick-disabled {
    filter: grayscale(100) brightness(1.5); }
  @media screen and (min-width: 768px) {
    #gosend [data-pages=join] .section-our-values.new #type-gobills {
      margin-top: 40px; } }

@media screen and (max-width: 767px) {
  #gosend [data-pages=join] .section-our-values.new {
    padding: 20px;
    padding-bottom: 100px;
    margin-top: 40px; }
    #gosend [data-pages=join] .section-our-values.new .container-slider {
      width: 100%;
      padding: 0px;
      position: relative;
      display: inline-block; } }

#gosend [data-pages=join] #new-join {
  margin-bottom: 120px; }

#gosend [data-pages=join] input:valid, #gosend [data-pages=join] select:valid {
  border: 1px solid #48af4a; }

#gosend [data-pages=join] input:focus, #gosend [data-pages=join] select:focus {
  border: 1px solid #3992f0 !important;
  box-shadow: 0px 0px 3px 1px #3992f0;
  transition: all 0.5s;
  background: transparent !important; }
  #gosend [data-pages=join] input:focus + ul, #gosend [data-pages=join] select:focus + ul {
    display: none; }

#gosend [data-pages=join] input.error, #gosend [data-pages=join] select.error {
  border: 1px solid #B62025 !important; }

#gosend [data-pages=join] .error-message {
  letter-spacing: 1px;
  margin-top: 10px;
  color: #B62025; }

#gosend [data-pages=join] section.new-our-partner {
  background-color: #fafafa;
  text-align: center;
  margin: 80px auto; }
  #gosend [data-pages=join] section.new-our-partner ol {
    position: relative;
    top: -10px; }
  #gosend [data-pages=join] section.new-our-partner .container-slide {
    display: inline-block;
    max-width: 1170px;
    position: relative;
    height: 460px;
    width: 90%; }
    #gosend [data-pages=join] section.new-our-partner .container-slide .title {
      z-index: 1;
      position: absolute;
      width: 50%;
      padding-left: 20px;
      font-size: 18px;
      color: unset;
      right: 0;
      text-align: left;
      font-weight: 700;
      height: 100px;
      letter-spacing: 2.5px; }
      @media screen and (min-width: 769px) {
        #gosend [data-pages=join] section.new-our-partner .container-slide .title:before {
          position: absolute;
          width: 35px;
          left: -25px;
          height: 3px;
          background: #49af4b;
          top: -5px;
          bottom: 0;
          content: "";
          margin: auto; } }
      #gosend [data-pages=join] section.new-our-partner .container-slide .title:after {
        content: "";
        display: inline-block;
        vertical-align: middle;
        height: 100%; }
        @media screen and (max-width: 768px) {
          #gosend [data-pages=join] section.new-our-partner .container-slide .title:after {
            height: 3px;
            width: 50px;
            margin: 20px auto;
            background: #49af4b;
            display: block;
            content: ""; } }
      @media screen and (max-width: 768px) {
        #gosend [data-pages=join] section.new-our-partner .container-slide .title {
          margin-top: 40px;
          position: relative;
          height: auto;
          padding-left: 0px;
          width: 100%;
          text-align: center;
          font-size: 14px; } }
    #gosend [data-pages=join] section.new-our-partner .container-slide .hero {
      float: left;
      height: 460px;
      width: 50%;
      background: #ebebeb; }
      #gosend [data-pages=join] section.new-our-partner .container-slide .hero .image-hero {
        height: 460px;
        width: 100%;
        background-size: cover;
        background-position: center; }
      @media screen and (max-width: 768px) {
        #gosend [data-pages=join] section.new-our-partner .container-slide .hero {
          width: 100%; } }
    #gosend [data-pages=join] section.new-our-partner .container-slide .comment {
      position: absolute;
      height: calc(100% - 100px);
      background: #fff;
      right: 0;
      bottom: 0;
      width: 50%; }
      #gosend [data-pages=join] section.new-our-partner .container-slide .comment .content {
        text-align: left;
        position: absolute;
        height: 80%;
        width: 70%;
        top: 0;
        bottom: 0;
        left: 7%;
        font-size: 14px;
        margin: auto; }
        @media screen and (max-width: 768px) {
          #gosend [data-pages=join] section.new-our-partner .container-slide .comment .content {
            width: 100%;
            position: relative;
            left: auto;
            padding: 30px; } }
      #gosend [data-pages=join] section.new-our-partner .container-slide .comment .container-comment {
        display: table;
        width: 100%;
        height: 300px;
        left: 0;
        font-size: 16px;
        line-height: 28px;
        color: #9b9b9b;
        font-family: "Open Sans", Helvetica, sans-serif;
        font-weight: 100; }
        #gosend [data-pages=join] section.new-our-partner .container-slide .comment .container-comment .user {
          color: #2b2b2b;
          margin-top: 10px; }
          #gosend [data-pages=join] section.new-our-partner .container-slide .comment .container-comment .user span {
            font-weight: 700; }
        #gosend [data-pages=join] section.new-our-partner .container-slide .comment .container-comment > div {
          display: table-cell;
          vertical-align: middle; }
        @media screen and (max-width: 768px) {
          #gosend [data-pages=join] section.new-our-partner .container-slide .comment .container-comment {
            text-align: center; } }
      @media screen and (max-width: 768px) {
        #gosend [data-pages=join] section.new-our-partner .container-slide .comment {
          position: relative;
          width: 100%;
          float: left;
          height: 500px; } }
    #gosend [data-pages=join] section.new-our-partner .container-slide .float-arrow {
      height: 45px;
      width: 45px;
      position: absolute;
      right: -90px;
      bottom: 0;
      top: 0;
      margin: auto; }
      #gosend [data-pages=join] section.new-our-partner .container-slide .float-arrow.arrow-left {
        transform: rotate(90deg);
        top: -55px; }
        #gosend [data-pages=join] section.new-our-partner .container-slide .float-arrow.arrow-left.slick-disabled {
          filter: grayscale(100) brightness(1.4); }
        @media screen and (max-width: 768px) {
          #gosend [data-pages=join] section.new-our-partner .container-slide .float-arrow.arrow-left {
            top: auto;
            bottom: -50px;
            left: 20px;
            right: auto;
            transform: none; } }
      #gosend [data-pages=join] section.new-our-partner .container-slide .float-arrow.arrow-right {
        transform: rotate(90deg);
        top: 55px; }
        #gosend [data-pages=join] section.new-our-partner .container-slide .float-arrow.arrow-right.slick-disabled {
          filter: grayscale(100) brightness(1.4); }
        @media screen and (max-width: 768px) {
          #gosend [data-pages=join] section.new-our-partner .container-slide .float-arrow.arrow-right {
            right: 20px;
            top: auto;
            bottom: -50px;
            left: auto;
            transform: none; } }
      @media screen and (max-width: 768px) {
        #gosend [data-pages=join] section.new-our-partner .container-slide .float-arrow {
          right: 0;
          left: 0; } }
    @media screen and (max-width: 768px) {
      #gosend [data-pages=join] section.new-our-partner .container-slide {
        height: auto;
        width: 100%; } }
  @media screen and (max-width: 768px) {
    #gosend [data-pages=join] section.new-our-partner {
      background-color: #fff;
      margin-bottom: 30px; } }
  #gosend [data-pages=join] section.new-our-partner .slick-dots {
    left: 0; }
    #gosend [data-pages=join] section.new-our-partner .slick-dots > li {
      margin: 0px;
      margin-right: 5px;
      filter: grayscale(100) brightness(1.4); }
      #gosend [data-pages=join] section.new-our-partner .slick-dots > li.slick-active {
        filter: grayscale(0); }
      #gosend [data-pages=join] section.new-our-partner .slick-dots > li button {
        width: 10px;
        height: 10px;
        background: #8BC440;
        border-radius: 50%; }
        #gosend [data-pages=join] section.new-our-partner .slick-dots > li button:before {
          display: none; }
      #gosend [data-pages=join] section.new-our-partner .slick-dots > li:last-child {
        margin-right: 0px; }

#gosend .articles--feed .bg {
  background: #8BC440; }

#gosend .articles--feed .read--more > a {
  background: #8BC440; }

#gosend .articles--feed .content .one {
  border-bottom: 6px solid #8BC440; }

#gosend .articles--feed .content .two {
  border-bottom: 6px solid #8BC440; }

#gosend .articles--feed .content .three {
  border-bottom: 6px solid #8BC440; }

#gosend .articles--feed .content .four {
  border-bottom: 6px solid #8BC440; }

#gosend .articles--feed h4 > a:hover {
  color: #8BC440; }

#gosend .articles--feed svg ellipse {
  stroke: #fff !important; }

#gosend .articles--feed svg polygon {
  fill: #fff; }

#gosend .as-seen-on #content img {
  padding: 1em !important;
  width: auto !important;
  max-height: 130px !important;
  padding: 16px !important;
  max-width: 200px;
  vertical-align: middle; }

#gosend .as-seen-on .title p {
  text-transform: uppercase; }

#gosend #promo-banner svg ellipse {
  stroke: #8BC440 !important; }

#gosend #promo-banner svg polygon {
  fill: #8BC440 !important; }

#gosend #promo-banner svg.slick-disabled ellipse {
  stroke: #bdbdbd !important; }

#gosend #promo-banner svg.slick-disabled polygon {
  fill: #bdbdbd !important; }

#gosend .section-how-to-use h5 {
  color: #8BC440; }

#gosend .section-how-to-use .line.gradient {
  background: #8BC440; }

#gosend .section-how-to-use .arrow-up {
  stroke: #8BC440; }

#gosend .section-how-to-use .arrow-up-circle {
  stroke-width: 3;
  stroke: #8BC440; }

#gosend .section-how-to-use .arrow-down {
  stroke: #8BC440; }

#gosend .section-how-to-use .arrow-down-circle {
  stroke: #8BC440;
  stroke-width: 3; }

#gosend .section-how-to-use .slider .slick-dots li.slick-active button:before {
  color: #8BC440; }

#gosend .section-how-to-use .slider-image .slick-dots li.slick-active button:before {
  color: #8BC440; }

#gosend .section-how-to-use .text-mobile .line.gradient {
  background: #8BC440; }

#gosend .section-how-to-use .text h5 {
  color: #8BC440; }

#gosend .section-how-to-use .text .line.gradient {
  background: #8BC440; }

#gosend .section-how-to-use .slider-image .slick-dots li.slick-active button:before {
  color: #8BC440; }

#gosend .new-section-how-to-use {
  margin-top: 180px;
  margin-bottom: 100px; }
  #gosend .new-section-how-to-use h3.title {
    color: #8bc440; }
    #gosend .new-section-how-to-use h3.title:after {
      background: #8bc440; }
  #gosend .new-section-how-to-use .content {
    background-color: #8bc440; }
  @media screen and (max-width: 767px) {
    #gosend .new-section-how-to-use {
      margin-top: 50px !important; } }

#gosend .section-our-values {
  padding: 3em;
  background: #f8f8f8; }
  #gosend .section-our-values .img--wrapper {
    height: 250px; }
    @media screen and (max-width: 768px) {
      #gosend .section-our-values .img--wrapper {
        height: auto; } }
  #gosend .section-our-values img {
    max-width: 250px;
    padding: 0 40px;
    display: block;
    top: 50%;
    position: relative;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    transform: translateY(-50%); }
    @media screen and (max-width: 768px) {
      #gosend .section-our-values img {
        transform: none;
        top: auto; } }
  #gosend .section-our-values h4.title {
    color: #8BC440; }
  #gosend .section-our-values .line.gradient {
    background: #8BC440; }
  #gosend .section-our-values .list h4 {
    line-height: 25px;
    font-size: 15px; }

#gosend #content-only .container .info {
  background: url(../img/page/gosend/join-bg.jpg) no-repeat center center/cover; }
  #gosend #content-only .container .info p {
    color: #fff; }

@media screen and (max-width: 767px) {
  #gosend #content-only {
    margin-top: 50px;
    margin-bottom: 100px; }
    #gosend #content-only .container {
      box-shadow: 0 -3em 0 1.5em #48af4a;
      padding: 0px; } }

@media screen and (max-width: 35.5em) {
  #gosend .section-how-to-use .arrow--wrapper {
    top: 30%; }
  #gosend .section-how-to-use .phone-wrapper {
    top: 40%; }
  #gosend .section-our-values {
    padding: 0; } }

#gofood .goresto .pure-g [class*=pure-u] {
  padding: 0 5px; }

#gofood .goresto .messages-alert {
  position: fixed;
  display: none;
  background-color: #B62025;
  width: 100%;
  padding: 20px;
  margin-top: 0px;
  z-index: 99;
  margin-bottom: -80px; }
  #gofood .goresto .messages-alert h5 {
    color: white;
    font-size: 15px;
    margin: 0;
    padding: 0; }

#gofood .goresto #content-only .container {
  box-shadow: 0 -3em 0 1.5em #B62025; }
  #gofood .goresto #content-only .container .info h4 {
    color: #B62025; }

#gofood .goresto .upload-list .checkmark__circle {
  stroke: #f5a523;
  fill: #f5a523;
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 2;
  stroke-miterlimit: 10;
  animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards; }

#gofood .goresto .upload-list .checkmark {
  top: -25px;
  left: 30px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: block;
  stroke-width: 2;
  stroke: #fff;
  stroke-miterlimit: 10;
  margin: 10% auto;
  box-shadow: inset 0 0 0 #b62025 !important; }

#gofood .goresto .upload-list .checkmark__check {
  transform-origin: 50% 50%;
  stroke-dasharray: 29;
  stroke-dashoffset: 29;
  animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards; }

@-webkit-keyframes uil-ring-anim {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

@-moz-keyframes uil-ring-anim {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

@-webkit-keyframes uil-ring-anim {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

@-o-keyframes uil-ring-anim {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

@keyframes uil-ring-anim {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

#gofood .goresto .uil-ring-css {
  background: none;
  position: relative;
  width: 200px;
  height: 200px;
  margin: 0 auto;
  display: block; }
  #gofood .goresto .uil-ring-css div {
    position: absolute;
    display: block;
    width: 160px;
    height: 160px;
    top: 20px;
    left: 20px;
    border-radius: 80px;
    box-shadow: 0 3px 0 0 #B62025;
    -webkit-transform-origin: 80px 81.5px;
    transform-origin: 80px 81.5px;
    -webkit-animation: uil-ring-anim 1s linear infinite;
    animation: uil-ring-anim 1s linear infinite; }

#gofood .goresto button, #gofood .goresto .button {
  background: #B62025;
  color: white; }

#gofood .goresto .loader,
#gofood .goresto .loader-addmore {
  display: none;
  background: white; }

#gofood .goresto .loader {
  position: relative;
  z-index: 10;
  width: 100%;
  height: 100%;
  top: 0;
  margin: 0;
  opacity: 0.8; }
  #gofood .goresto .loader .loader-wrapper {
    top: 0;
    position: relative;
    background-color: #fff; }

#gofood .goresto section.call-navigation .container .box {
  color: white;
  display: inline-block !important;
  background-color: transparent;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  top: 30px;
  border-radius: 7.5px;
  padding: 25px; }
  #gofood .goresto section.call-navigation .container .box a {
    text-transform: uppercase;
    letter-spacing: .1;
    color: #fff;
    padding: 22px 30px;
    background: #babcbf;
    text-decoration: none;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    margin: 0 -5px; }
  #gofood .goresto section.call-navigation .container .box a.active {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px !important;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    font-weight: 600;
    background-color: #B62025; }

#gofood .goresto section.search-resto {
  background: #FAFAFA; }
  #gofood .goresto section.search-resto .join--link {
    text-align: center;
    margin-bottom: 30px; }
    #gofood .goresto section.search-resto .join--link p {
      display: inline-block; }
    #gofood .goresto section.search-resto .join--link a {
      color: #b52126;
      font-weight: bold;
      text-indent: 5px;
      display: inline-block; }
    #gofood .goresto section.search-resto .join--link a:hover {
      text-decoration: underline; }
    #gofood .goresto section.search-resto .join--link a.button {
      color: #FFFFFF; }
  #gofood .goresto section.search-resto .container {
    position: relative; }
  #gofood .goresto section.search-resto h4 {
    color: #B62025;
    text-transform: uppercase;
    padding-left: 15px;
    padding-bottom: 2em;
    margin-left: 35px;
    margin-top: 7.5px; }
  #gofood .goresto section.search-resto .wrapper {
    width: 100%;
    margin: 0;
    border-radius: 5px;
    padding-bottom: 0;
    background-color: #B62025;
    position: relative; }
    #gofood .goresto section.search-resto .wrapper .form-wrapper {
      text-align: center; }
      #gofood .goresto section.search-resto .wrapper .form-wrapper fieldset {
        padding: 20px 0; }
        #gofood .goresto section.search-resto .wrapper .form-wrapper fieldset label {
          margin-right: 35px;
          position: relative;
          top: 2.5px; }
          #gofood .goresto section.search-resto .wrapper .form-wrapper fieldset label i {
            font-size: 32px; }
      #gofood .goresto section.search-resto .wrapper .form-wrapper button {
        padding: 0;
        margin: 0 auto;
        margin-bottom: -30px; }
        #gofood .goresto section.search-resto .wrapper .form-wrapper button span {
          margin-left: 15px; }
          #gofood .goresto section.search-resto .wrapper .form-wrapper button span i {
            margin-left: 5px; }
      #gofood .goresto section.search-resto .wrapper .form-wrapper input {
        width: 75%;
        padding: 0 15px;
        height: 45px;
        border: 1px solid #c3c9d7;
        font-family: 'Open Sans';
        font-style: italic;
        letter-spacing: 3px;
        color: #babcbf;
        font-size: 12px; }
      #gofood .goresto section.search-resto .wrapper .form-wrapper input::-webkit-input-placeholder {
        color: #cdcdcd;
        font-style: italic; }
      #gofood .goresto section.search-resto .wrapper .form-wrapper .addmore-outlet {
        color: #B62025; }

#gofood .goresto section.result-resto {
  margin: 0 0 50px; }
  #gofood .goresto section.result-resto [data-collapse="data-outlet"] .merchant {
    box-shadow: 0 2px 8px 0 #dbdbdb;
    height: 450px;
    overflow-y: scroll;
    position: absolute;
    z-index: 20;
    top: 108px;
    width: 100%; }
  #gofood .goresto section.result-resto .join--link {
    text-align: center; }
    #gofood .goresto section.result-resto .join--link p {
      display: inline-block; }
    #gofood .goresto section.result-resto .join--link a {
      color: #b52126;
      font-weight: bold;
      text-indent: 5px;
      display: inline-block; }
    #gofood .goresto section.result-resto .join--link a:hover {
      text-decoration: underline; }
    #gofood .goresto section.result-resto .join--link a.button {
      color: #FFFFFF; }
  #gofood .goresto section.result-resto #goresto-form {
    position: relative;
    margin: 0 -5px; }
    #gofood .goresto section.result-resto #goresto-form > .form-container .loader {
      position: absolute; }
      #gofood .goresto section.result-resto #goresto-form > .form-container .loader > .loader-wrapper {
        top: 50%;
        transform: translateY(-50%); }
    #gofood .goresto section.result-resto #goresto-form .personal-wrapper--fields {
      display: none; }
    #gofood .goresto section.result-resto #goresto-form #procuration-wrapper--file {
      display: none; }
  #gofood .goresto section.result-resto .panel img.resto-claim-header {
    display: none; }
  #gofood .goresto section.result-resto .panel .text {
    display: none;
    margin-top: -5px;
    background: #B62025;
    text-align: center;
    z-index: -1;
    padding: 10px 35px; }
    #gofood .goresto section.result-resto .panel .text h4 {
      color: white; }
    #gofood .goresto section.result-resto .panel .text p, #gofood .goresto section.result-resto .panel .text a {
      font-weight: 300;
      color: white;
      margin-top: -20px;
      margin-bottom: 25px; }
    #gofood .goresto section.result-resto .panel .text .link.notfound {
      display: none; }
  #gofood .goresto section.result-resto .panel .body {
    display: none;
    padding: 0px 0px;
    background: transparent;
    position: relative; }
    #gofood .goresto section.result-resto .panel .body .list-addmore {
      display: none;
      position: relative;
      top: 20px;
      text-align: center;
      z-index: 11;
      position: relative; }
      #gofood .goresto section.result-resto .panel .body .list-addmore a.button {
        margin-top: 0px;
        border-radius: 5px;
        padding: 20px;
        float: none;
        text-align: center;
        display: inline-block;
        text-transform: uppercase; }
        #gofood .goresto section.result-resto .panel .body .list-addmore a.button span {
          margin: 0 65px; }
    #gofood .goresto section.result-resto .panel .body .merchant {
      margin-top: -40px;
      background: white;
      padding: 30px 40px; }
      #gofood .goresto section.result-resto .panel .body .merchant .join--link {
        background: #b52126;
        color: #fff;
        text-align: center;
        padding: 25px 0;
        margin: 0 -40px -30px;
        text-align: center; }
        #gofood .goresto section.result-resto .panel .body .merchant .join--link h3 {
          margin: 0 0 10px; }
        #gofood .goresto section.result-resto .panel .body .merchant .join--link a {
          color: #fff; }
      #gofood .goresto section.result-resto .panel .body .merchant .list h4, #gofood .goresto section.result-resto .panel .body .merchant .list h5, #gofood .goresto section.result-resto .panel .body .merchant .list p {
        margin: 0;
        padding: 0; }
      #gofood .goresto section.result-resto .panel .body .merchant .list h5 {
        font-weight: 300;
        line-height: 1.6;
        letter-spacing: 0.5px;
        color: #9b9b9b; }
      #gofood .goresto section.result-resto .panel .body .merchant .list p {
        font-weight: 300;
        object-fit: contain;
        line-height: 1.6;
        letter-spacing: 0.5px;
        color: #4a4a4a; }
      #gofood .goresto section.result-resto .panel .body .merchant .list hr {
        margin: 15px 0;
        border: 0;
        height: 1px;
        background: rgba(200, 200, 200, 0.25); }
      #gofood .goresto section.result-resto .panel .body .merchant .list .item--wrapper {
        position: relative;
        margin: 35px 0; }
        #gofood .goresto section.result-resto .panel .body .merchant .list .item--wrapper a.button {
          border-radius: 5px;
          display: table;
          height: 55px;
          margin: 0;
          position: absolute;
          right: 0;
          bottom: 0;
          width: 170px;
          text-transform: uppercase;
          text-align: center;
          font-size: 14px;
          font-weight: bold;
          line-height: 1.03;
          letter-spacing: 2px;
          transition: all 0.3s ease-in-out; }
          #gofood .goresto section.result-resto .panel .body .merchant .list .item--wrapper a.button.claimed {
            background: #9b9b9b;
            cursor: no-drop;
            pointer-events: none; }
          #gofood .goresto section.result-resto .panel .body .merchant .list .item--wrapper a.button > span {
            display: table-cell;
            vertical-align: middle; }
        #gofood .goresto section.result-resto .panel .body .merchant .list .item--wrapper a.button:hover {
          background: #fff;
          border: solid 3px #b62025;
          color: #b62025; }
    #gofood .goresto section.result-resto .panel .body .merchant-info {
      background: white;
      padding: 30px 40px; }
      #gofood .goresto section.result-resto .panel .body .merchant-info .found h4,
      #gofood .goresto section.result-resto .panel .body .merchant-info .found p {
        color: #B62025; }
      #gofood .goresto section.result-resto .panel .body .merchant-info .not-found svg {
        margin-top: 50px; }
      #gofood .goresto section.result-resto .panel .body .merchant-info .not-found h4 {
        color: #B62025;
        line-height: 35px; }
      #gofood .goresto section.result-resto .panel .body .merchant-info .not-found p {
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 2.05;
        letter-spacing: 0.7px;
        text-align: center;
        color: #4a4a4a; }
      #gofood .goresto section.result-resto .panel .body .merchant-info .not-found a {
        padding: 20px 40px;
        border-radius: 5px;
        position: relative;
        bottom: -75px; }
      #gofood .goresto section.result-resto .panel .body .merchant-info h4 {
        font-size: 25px;
        font-weight: bold;
        letter-spacing: 0.9px;
        text-align: center; }
      #gofood .goresto section.result-resto .panel .body .merchant-info p {
        margin-top: -20px; }
    #gofood .goresto section.result-resto .panel .body .form {
      display: none; }
      #gofood .goresto section.result-resto .panel .body .form .identity-other {
        display: none; }
      #gofood .goresto section.result-resto .panel .body .form .loader-step--wrapper {
        width: 100%;
        height: 10px;
        background: #c3c1c1;
        position: relative; }
        #gofood .goresto section.result-resto .panel .body .form .loader-step--wrapper .loader-step {
          position: absolute;
          width: 0%;
          height: 100%;
          background: #48af4a;
          transition: width .3s ease-in-out; }
      #gofood .goresto section.result-resto .panel .body .form hr.form-separator {
        position: relative;
        transform: scale(1.025);
        top: 0px;
        border-color: red;
        display: block;
        height: 1px;
        border: 0;
        border-top: 1px solid gainsboro;
        margin: 3em 0 2em;
        padding: 0; }
      #gofood .goresto section.result-resto .panel .body .form .addmore-outlet {
        object-fit: contain;
        border-radius: 5px;
        background-color: #b62025;
        display: block;
        width: 100%;
        padding: 22px 0;
        font-family: "Open Sans", Helvetica, sans-serif;
        font-size: 12px;
        font-weight: bold;
        line-height: 1.2;
        letter-spacing: 2.2px;
        text-align: center; }
      #gofood .goresto section.result-resto .panel .body .form .side-two-column {
        margin-right: 22px; }
      #gofood .goresto section.result-resto .panel .body .form [data-step="2"],
      #gofood .goresto section.result-resto .panel .body .form [data-step="3"] {
        position: relative;
        left: 310px !important;
        display: none; }
      #gofood .goresto section.result-resto .panel .body .form [data-step="2"] .group-dummy {
        opacity: 0; }
      #gofood .goresto section.result-resto .panel .body .form .form-container {
        position: relative;
        left: 60px; }
      #gofood .goresto section.result-resto .panel .body .form .collapse--wrapper .delete-outlet {
        font-size: 12px;
        font-weight: 600;
        letter-spacing: 1.3px;
        float: right;
        color: #a50d1c;
        display: table;
        cursor: pointer; }
        #gofood .goresto section.result-resto .panel .body .form .collapse--wrapper .delete-outlet img {
          display: table-cell;
          vertical-align: middle;
          margin-right: 10px; }
        #gofood .goresto section.result-resto .panel .body .form .collapse--wrapper .delete-outlet span {
          display: table-cell;
          vertical-align: middle; }
      #gofood .goresto section.result-resto .panel .body .form .collapse--wrapper .head {
        transition: all 0.3s ease;
        cursor: pointer;
        opacity: .45; }
        #gofood .goresto section.result-resto .panel .body .form .collapse--wrapper .head .panel h4 {
          object-fit: contain;
          font-family: "Open Sans", Helvetica, sans-serif;
          font-size: 18px;
          font-weight: 300;
          color: #4a4a4a; }
          #gofood .goresto section.result-resto .panel .body .form .collapse--wrapper .head .panel h4.error {
            color: #B62025; }
            #gofood .goresto section.result-resto .panel .body .form .collapse--wrapper .head .panel h4.error span.changetitle {
              color: #B62025; }
          #gofood .goresto section.result-resto .panel .body .form .collapse--wrapper .head .panel h4 span.changetitle {
            font-size: 18px;
            font-weight: 300;
            color: #4a4a4a; }
          #gofood .goresto section.result-resto .panel .body .form .collapse--wrapper .head .panel h4 span.icon {
            float: right; }
            #gofood .goresto section.result-resto .panel .body .form .collapse--wrapper .head .panel h4 span.icon i {
              font-size: 20px;
              position: relative;
              top: 2.5px;
              left: -2.5px; }
        #gofood .goresto section.result-resto .panel .body .form .collapse--wrapper .head .panel hr {
          position: relative;
          transform: scale(1.025);
          top: -15px;
          border-color: red;
          display: block;
          height: 1px;
          border: 0;
          border-top: 1px solid #ccc;
          margin: 1em 0;
          padding: 0; }
        #gofood .goresto section.result-resto .panel .body .form .collapse--wrapper .head:hover {
          opacity: 1.0; }
      #gofood .goresto section.result-resto .panel .body .form .collapse--wrapper .collapse-body {
        margin-top: -25px;
        display: none; }
      #gofood .goresto section.result-resto .panel .body .form .collapse--wrapper.active .head {
        opacity: 1.0; }
        #gofood .goresto section.result-resto .panel .body .form .collapse--wrapper.active .head .panel h4 {
          color: #B62025;
          font-weight: 600;
          line-height: 25px; }
          #gofood .goresto section.result-resto .panel .body .form .collapse--wrapper.active .head .panel h4 span.changetitle {
            color: #B62025;
            font-weight: 600; }
        #gofood .goresto section.result-resto .panel .body .form .collapse--wrapper.active .head .panel hr {
          display: none; }
        #gofood .goresto section.result-resto .panel .body .form .collapse--wrapper.active .head .panel span.separator {
          display: inline-block;
          background: #B62025;
          width: 40px;
          height: 4px;
          position: relative;
          top: -4.25px;
          margin-right: 10px; }
        #gofood .goresto section.result-resto .panel .body .form .collapse--wrapper.active .head .panel span.icon i:before {
          content: "\f068"; }
      #gofood .goresto section.result-resto .panel .body .form .collapse--wrapper.active .collapse-body {
        margin-top: -10px;
        margin-bottom: 40px;
        display: block; }
      #gofood .goresto section.result-resto .panel .body .form .outlet-list hr {
        border: 0;
        height: 0;
        border-top: 1px solid rgba(0, 0, 0, 0.085);
        margin-bottom: 30px;
        border-bottom: 1px solid rgba(255, 255, 255, 0.2); }
      #gofood .goresto section.result-resto .panel .body .form .outlet-list [data-outlet] {
        margin-bottom: 60px; }
        #gofood .goresto section.result-resto .panel .body .form .outlet-list [data-outlet] .remove-outlet {
          color: #B62025;
          float: right;
          position: relative;
          top: -5px; }
        #gofood .goresto section.result-resto .panel .body .form .outlet-list [data-outlet] .form-wrapper .text-wrapper .title {
          top: 40px;
          left: 0;
          margin-bottom: 45px;
          text-transform: uppercase; }
      #gofood .goresto section.result-resto .panel .body .form .outlet-search {
        display: none; }
        #gofood .goresto section.result-resto .panel .body .form .outlet-search .form-wrapper .text-wrapper .title {
          top: 40px;
          left: 0;
          margin-bottom: 45px;
          text-transform: uppercase; }
        #gofood .goresto section.result-resto .panel .body .form .outlet-search .outlet-search-list-wrapper {
          padding: 0px 50px; }
          #gofood .goresto section.result-resto .panel .body .form .outlet-search .outlet-search-list-wrapper .outlet-search-list {
            box-shadow: rgba(20, 20, 20, 0.15) 0px 5px 20px;
            margin-top: -30px;
            margin-bottom: 80px; }
            #gofood .goresto section.result-resto .panel .body .form .outlet-search .outlet-search-list-wrapper .outlet-search-list .outlet-search-list-loader {
              display: none; }
            #gofood .goresto section.result-resto .panel .body .form .outlet-search .outlet-search-list-wrapper .outlet-search-list .outlet-search-not-found {
              display: none;
              text-align: center;
              padding: 50px;
              font-family: "Open Sans", Helvetica, sans-serif;
              font-weight: 300; }
              #gofood .goresto section.result-resto .panel .body .form .outlet-search .outlet-search-list-wrapper .outlet-search-list .outlet-search-not-found span {
                font-weight: 700; }
            #gofood .goresto section.result-resto .panel .body .form .outlet-search .outlet-search-list-wrapper .outlet-search-list .list {
              margin-bottom: 0px;
              padding: 15px 35px; }
              #gofood .goresto section.result-resto .panel .body .form .outlet-search .outlet-search-list-wrapper .outlet-search-list .list h4, #gofood .goresto section.result-resto .panel .body .form .outlet-search .outlet-search-list-wrapper .outlet-search-list .list h5, #gofood .goresto section.result-resto .panel .body .form .outlet-search .outlet-search-list-wrapper .outlet-search-list .list p {
                margin: 0;
                padding: 0; }
              #gofood .goresto section.result-resto .panel .body .form .outlet-search .outlet-search-list-wrapper .outlet-search-list .list h4 {
                line-height: 18px; }
              #gofood .goresto section.result-resto .panel .body .form .outlet-search .outlet-search-list-wrapper .outlet-search-list .list h5 {
                line-height: 1.6;
                letter-spacing: 0.5px;
                color: #9b9b9b;
                font-size: 11px;
                margin-top: 5px; }
              #gofood .goresto section.result-resto .panel .body .form .outlet-search .outlet-search-list-wrapper .outlet-search-list .list p {
                object-fit: contain;
                line-height: 1.6;
                letter-spacing: 0.5px;
                color: #4a4a4a; }
              #gofood .goresto section.result-resto .panel .body .form .outlet-search .outlet-search-list-wrapper .outlet-search-list .list hr {
                position: relative;
                top: 27px;
                margin: 15px 0;
                border: 0;
                height: 1px;
                background: rgba(200, 200, 200, 0.25); }
              #gofood .goresto section.result-resto .panel .body .form .outlet-search .outlet-search-list-wrapper .outlet-search-list .list a.button {
                margin-top: -50px;
                border-radius: 5px;
                padding: 20px;
                float: right; }
                #gofood .goresto section.result-resto .panel .body .form .outlet-search .outlet-search-list-wrapper .outlet-search-list .list a.button span {
                  margin: 0 25px; }
      #gofood .goresto section.result-resto .panel .body .form .outlet-search.active {
        display: block; }
      #gofood .goresto section.result-resto .panel .body .form .addmore-outlet h4 {
        float: right;
        color: #B62025;
        margin-right: 50px;
        margin-top: -10px; }
      #gofood .goresto section.result-resto .panel .body .form .main-wrapper {
        height: auto;
        width: 100%;
        background: white;
        position: relative;
        left: 0px; }
        #gofood .goresto section.result-resto .panel .body .form .main-wrapper .button-disabled {
          cursor: not-allowed;
          box-shadow: none !important;
          background: #c8c8c8 !important; }
        #gofood .goresto section.result-resto .panel .body .form .main-wrapper .separator-title {
          position: relative;
          left: 50px;
          top: 0px;
          color: #c8c8c8;
          margin-top: 55px;
          margin-bottom: -20px; }
        #gofood .goresto section.result-resto .panel .body .form .main-wrapper .upload-type-file {
          opacity: .0; }
        #gofood .goresto section.result-resto .panel .body .form .main-wrapper .upload-wrapper {
          margin-top: 15px; }
          #gofood .goresto section.result-resto .panel .body .form .main-wrapper .upload-wrapper .title {
            color: #697b8c;
            font-family: "Open Sans", Helvetica, sans-serif;
            font-weight: 700;
            text-transform: uppercase;
            letter-spacing: 1.25px;
            font-size: 12.5px;
            margin-bottom: 10px; }
          #gofood .goresto section.result-resto .panel .body .form .main-wrapper .upload-wrapper .subtitle {
            color: #697b8c;
            font-family: "Open Sans", Helvetica, sans-serif;
            font-weight: 700;
            text-transform: none;
            letter-spacing: 1.25px;
            font-size: 12.5px;
            margin-bottom: 10px; }
          #gofood .goresto section.result-resto .panel .body .form .main-wrapper .upload-wrapper .upload-loader {
            display: none;
            margin-top: -90px;
            color: #c8c8c8;
            font-size: 12px;
            text-align: center; }
          #gofood .goresto section.result-resto .panel .body .form .main-wrapper .upload-wrapper .upload-box {
            margin-top: 0px;
            height: auto;
            overflow-y: hidden;
            padding: 30px;
            border: 1px solid gainsboro; }
            #gofood .goresto section.result-resto .panel .body .form .main-wrapper .upload-wrapper .upload-box .list {
              cursor: pointer; }
              #gofood .goresto section.result-resto .panel .body .form .main-wrapper .upload-wrapper .upload-box .list .list.plus {
                background: #B62025;
                padding: 20px;
                font-size: 40px;
                color: white;
                font-weight: 700; }
                #gofood .goresto section.result-resto .panel .body .form .main-wrapper .upload-wrapper .upload-box .list .list.plus span {
                  margin: 0 20px; }
            #gofood .goresto section.result-resto .panel .body .form .main-wrapper .upload-wrapper .upload-box .ilus-first {
              text-align: center;
              width: 50%;
              float: left; }
              #gofood .goresto section.result-resto .panel .body .form .main-wrapper .upload-wrapper .upload-box .ilus-first > div {
                width: 100%;
                margin: auto; }
              #gofood .goresto section.result-resto .panel .body .form .main-wrapper .upload-wrapper .upload-box .ilus-first img {
                max-width: 100%; }
              #gofood .goresto section.result-resto .panel .body .form .main-wrapper .upload-wrapper .upload-box .ilus-first img[data-image] {
                display: none; }
              @media only screen and (max-width: 768px) {
                #gofood .goresto section.result-resto .panel .body .form .main-wrapper .upload-wrapper .upload-box .ilus-first {
                  width: 100%; } }
            #gofood .goresto section.result-resto .panel .body .form .main-wrapper .upload-wrapper .upload-box .upload-first {
              width: 100%;
              float: right;
              color: #c8c8c8;
              font-size: 12px;
              text-align: center; }
              #gofood .goresto section.result-resto .panel .body .form .main-wrapper .upload-wrapper .upload-box .upload-first.identity-select {
                width: 50%; }
              #gofood .goresto section.result-resto .panel .body .form .main-wrapper .upload-wrapper .upload-box .upload-first ul {
                text-align: left;
                font-size: 16px;
                margin: 0px; }
                @media only screen and (max-width: 768px) {
                  #gofood .goresto section.result-resto .panel .body .form .main-wrapper .upload-wrapper .upload-box .upload-first ul {
                    font-size: 14px;
                    padding-left: 15px; }
                    #gofood .goresto section.result-resto .panel .body .form .main-wrapper .upload-wrapper .upload-box .upload-first ul li {
                      margin-bottom: 5px; } }
              #gofood .goresto section.result-resto .panel .body .form .main-wrapper .upload-wrapper .upload-box .upload-first div[data-identity] {
                display: none; }
              #gofood .goresto section.result-resto .panel .body .form .main-wrapper .upload-wrapper .upload-box .upload-first .icon-preview {
                display: none; }
              #gofood .goresto section.result-resto .panel .body .form .main-wrapper .upload-wrapper .upload-box .upload-first .button-upload-first {
                width: 130px;
                background: #B62025;
                cursor: pointer;
                margin: 25px auto 0 !important;
                display: block;
                border-radius: 5px;
                padding: 15px;
                color: white; }
                #gofood .goresto section.result-resto .panel .body .form .main-wrapper .upload-wrapper .upload-box .upload-first .button-upload-first span {
                  margin: 0 20px; }
              @media only screen and (max-width: 768px) {
                #gofood .goresto section.result-resto .panel .body .form .main-wrapper .upload-wrapper .upload-box .upload-first {
                  width: 100% !important;
                  float: none; } }
            #gofood .goresto section.result-resto .panel .body .form .main-wrapper .upload-wrapper .upload-box .upload-list {
              display: none; }
              #gofood .goresto section.result-resto .panel .body .form .main-wrapper .upload-wrapper .upload-box .upload-list .list.plus {
                background: #B62025;
                padding: 20px;
                font-size: 40px;
                color: white;
                font-weight: 700;
                position: relative; }
                #gofood .goresto section.result-resto .panel .body .form .main-wrapper .upload-wrapper .upload-box .upload-list .list.plus span {
                  margin: 0 20px; }
              #gofood .goresto section.result-resto .panel .body .form .main-wrapper .upload-wrapper .upload-box .upload-list .box {
                border: 1px solid #c8c8c8;
                height: 200px;
                position: relative;
                margin: 0 5px;
                margin-bottom: 10px;
                margin-top: 20px; }
                #gofood .goresto section.result-resto .panel .body .form .main-wrapper .upload-wrapper .upload-box .upload-list .box img {
                  position: absolute;
                  top: 50%;
                  left: 50%;
                  max-height: 100%;
                  transform: translate(-50%, -50%); }
              #gofood .goresto section.result-resto .panel .body .form .main-wrapper .upload-wrapper .upload-box .upload-list .box.addmore {
                padding: 50px;
                background: #B62025;
                height: 150px;
                position: relative;
                margin: 0 5px;
                margin-bottom: 10px; }
                #gofood .goresto section.result-resto .panel .body .form .main-wrapper .upload-wrapper .upload-box .upload-list .box.addmore label {
                  font-size: 70px;
                  position: absolute;
                  top: 45%;
                  left: 50%;
                  max-height: 100%;
                  transform: translate(-50%, -50%); }
        #gofood .goresto section.result-resto .panel .body .form .main-wrapper .text-wrapper .title {
          margin-top: 30px;
          font-size: 12px;
          position: relative;
          left: 50px;
          top: 10px;
          color: #c8c8c8; }
        #gofood .goresto section.result-resto .panel .body .form .main-wrapper .background-wrapper {
          background: url("https://bilba.go-jek.com/dist/img/card/join-form/gofood-texture.jpg?ver=1") #b81800;
          min-height: auto;
          height: 100% !important;
          width: 245px;
          padding: 0;
          position: absolute;
          text-align: center;
          left: 0; }
          #gofood .goresto section.result-resto .panel .body .form .main-wrapper .background-wrapper img {
            min-width: 60px;
            position: relative;
            margin-top: 50%;
            top: 35%;
            transform: translateY(-50%); }
        #gofood .goresto section.result-resto .panel .body .form .main-wrapper .form-wrapper {
          padding: 0 40px; }
          #gofood .goresto section.result-resto .panel .body .form .main-wrapper .form-wrapper .upload-button-wrapper p {
            margin: 0; }
          #gofood .goresto section.result-resto .panel .body .form .main-wrapper .form-wrapper .title {
            margin-top: -15px;
            font-size: 12px;
            position: relative;
            top: 10px;
            color: #c8c8c8; }
          #gofood .goresto section.result-resto .panel .body .form .main-wrapper .form-wrapper .group {
            width: 100%;
            margin-top: 15px;
            margin-bottom: 15px;
            position: relative; }
            #gofood .goresto section.result-resto .panel .body .form .main-wrapper .form-wrapper .group .tnc-wrapper {
              width: 100%;
              height: 145px;
              padding: 15px;
              margin-top: 5px;
              letter-spacing: 1.2px;
              font-size: 13.4px;
              background: white;
              border-radius: 0 !important;
              border: 1px solid #d2d2d2;
              color: #485460;
              overflow-y: scroll;
              overflow-x: hidden; }
            #gofood .goresto section.result-resto .panel .body .form .main-wrapper .form-wrapper .group label {
              text-transform: uppercase;
              font-family: "Open Sans", sans-serif;
              font-weight: 700;
              font-size: 11.5px;
              letter-spacing: 1.5px;
              color: #697b8c; }
            #gofood .goresto section.result-resto .panel .body .form .main-wrapper .form-wrapper .group h6 {
              font-size: 14px;
              font-weight: 600;
              font-style: italic;
              line-height: 1.43;
              letter-spacing: 1.5px;
              color: #babcbf;
              margin-top: 7.5px;
              margin-bottom: 0; }
            #gofood .goresto section.result-resto .panel .body .form .main-wrapper .form-wrapper .group i.arrow {
              float: right;
              z-index: 2;
              position: absolute;
              top: 35px;
              color: #b4b4b4;
              right: 15px; }
            #gofood .goresto section.result-resto .panel .body .form .main-wrapper .form-wrapper .group input[type=text],
            #gofood .goresto section.result-resto .panel .body .form .main-wrapper .form-wrapper .group input[type=number],
            #gofood .goresto section.result-resto .panel .body .form .main-wrapper .form-wrapper .group input[type=password],
            #gofood .goresto section.result-resto .panel .body .form .main-wrapper .form-wrapper .group input[type=email],
            #gofood .goresto section.result-resto .panel .body .form .main-wrapper .form-wrapper .group select {
              width: 100%;
              height: 45px;
              padding: 15px;
              margin-top: 5px;
              letter-spacing: 1.2px;
              font-size: 13.4px;
              background: white;
              border-radius: 0 !important;
              border: 1px solid #d2d2d2;
              color: #485460; }
            #gofood .goresto section.result-resto .panel .body .form .main-wrapper .form-wrapper .group input[type=number]::-webkit-inner-spin-button,
            #gofood .goresto section.result-resto .panel .body .form .main-wrapper .form-wrapper .group input[type=number]::-webkit-outer-spin-button {
              -webkit-appearance: none;
              -moz-appearance: none;
              appearance: none;
              margin: 0; }
            #gofood .goresto section.result-resto .panel .body .form .main-wrapper .form-wrapper .group input[type="radio"] {
              display: none; }
            #gofood .goresto section.result-resto .panel .body .form .main-wrapper .form-wrapper .group input[type="radio"] + label {
              background: transparent;
              border: 1px solid #c8c8c8;
              border-radius: 50%;
              font-weight: 500;
              height: 24px;
              width: 24px;
              text-transform: unset;
              display: inline-block;
              padding: 0 0 0 0px;
              cursor: pointer;
              margin: 15px 0;
              position: relative; }
            #gofood .goresto section.result-resto .panel .body .form .main-wrapper .form-wrapper .group input[type="radio"]:checked + label:before {
              content: "";
              background: #B62025;
              padding: 0;
              width: 15px;
              height: 15px;
              top: 50%;
              left: 50%;
              position: absolute;
              border-radius: 100%;
              transform: translate(-50%, -50%); }
            #gofood .goresto section.result-resto .panel .body .form .main-wrapper .form-wrapper .group input[type="checkbox"] {
              display: none; }
            #gofood .goresto section.result-resto .panel .body .form .main-wrapper .form-wrapper .group input[type="checkbox"] + label:before {
              content: "";
              background: #fff;
              border: 2px solid #ddd;
              display: inline-block;
              vertical-align: middle;
              width: 15px;
              height: 15px;
              padding: 2px;
              margin-right: 10px;
              text-align: center; }
            #gofood .goresto section.result-resto .panel .body .form .main-wrapper .form-wrapper .group input[type="checkbox"]:checked + label:before {
              content: "\f00c";
              font-size: 14px;
              font-family: 'FontAwesome';
              background: #fff;
              color: #B62025; }
            #gofood .goresto section.result-resto .panel .body .form .main-wrapper .form-wrapper .group span.radio-label {
              position: relative;
              top: 1.75px;
              left: 40px;
              font-family: "Open Sans", Helvetica, sans-serif;
              font-size: 14px;
              letter-spacing: 1.3px;
              color: #485460; }
            #gofood .goresto section.result-resto .panel .body .form .main-wrapper .form-wrapper .group input[disabled],
            #gofood .goresto section.result-resto .panel .body .form .main-wrapper .form-wrapper .group textarea[disabled],
            #gofood .goresto section.result-resto .panel .body .form .main-wrapper .form-wrapper .group select[disabled] {
              background-color: whitesmoke; }
            #gofood .goresto section.result-resto .panel .body .form .main-wrapper .form-wrapper .group select {
              cursor: pointer;
              appearance: none;
              -webkit-appearance: none; }
            #gofood .goresto section.result-resto .panel .body .form .main-wrapper .form-wrapper .group textarea {
              width: 100%;
              height: 145px;
              padding: 15px;
              margin-top: 5px;
              letter-spacing: 1.2px;
              font-size: 13.4px;
              background: white;
              border-radius: 0 !important;
              border: 1px solid #d2d2d2;
              color: #485460; }
            #gofood .goresto section.result-resto .panel .body .form .main-wrapper .form-wrapper .group .icon {
              float: right; }
              #gofood .goresto section.result-resto .panel .body .form .main-wrapper .form-wrapper .group .icon i {
                position: relative;
                left: -15px;
                top: 35px;
                font-size: 12px;
                color: #d2d2d2; }
        #gofood .goresto section.result-resto .panel .body .form .main-wrapper .form-wrapper.second-column {
          position: relative;
          left: -5px; }
        #gofood .goresto section.result-resto .panel .body .form .main-wrapper .error_messages {
          color: red;
          letter-spacing: .1px;
          margin-top: 10px; }
        #gofood .goresto section.result-resto .panel .body .form .main-wrapper .form-button {
          text-align: right;
          position: relative;
          top: 30px;
          left: -50px; }
          #gofood .goresto section.result-resto .panel .body .form .main-wrapper .form-button .button {
            font-size: 14px;
            font-family: "Open Sans", Helvetica, sans-serif;
            font-weight: 400;
            padding: 24px;
            border-radius: 5px;
            transition: all .5s ease 0s;
            margin: 0 5px;
            color: #fff;
            box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.65);
            margin-top: 60px;
            text-transform: none;
            float: none;
            display: inline-block; }
            #gofood .goresto section.result-resto .panel .body .form .main-wrapper .form-button .button span {
              margin: 0 50px; }
          #gofood .goresto section.result-resto .panel .body .form .main-wrapper .form-button .button:hover,
          #gofood .goresto section.result-resto .panel .body .form .main-wrapper .form-button .button:focus {
            box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.85); }
          #gofood .goresto section.result-resto .panel .body .form .main-wrapper .form-button .button-show {
            display: inline-block !important; }
          #gofood .goresto section.result-resto .panel .body .form .main-wrapper .form-button .button-hide {
            display: none !important; }
          #gofood .goresto section.result-resto .panel .body .form .main-wrapper .form-button .button-submit,
          #gofood .goresto section.result-resto .panel .body .form .main-wrapper .form-button .button-next {
            background: #B62025;
            display: inline-block; }
          #gofood .goresto section.result-resto .panel .body .form .main-wrapper .form-button .button-next {
            padding: 28px; }
          #gofood .goresto section.result-resto .panel .body .form .main-wrapper .form-button .button-back {
            background: #353b44;
            display: none; }
          #gofood .goresto section.result-resto .panel .body .form .main-wrapper .form-button .button-back.disable,
          #gofood .goresto section.result-resto .panel .body .form .main-wrapper .form-button .button-next.disable,
          #gofood .goresto section.result-resto .panel .body .form .main-wrapper .form-button .button-submit.disable {
            background: #c8c8c8; }

#gofood .goresto section.about .container::before {
  background-color: #B62025; }

#gofood .goresto section.about h4 {
  color: #B62025;
  text-transform: uppercase; }

#gofood .goresto section.about a {
  color: #B62025;
  text-transform: uppercase;
  position: relative; }

#gofood .goresto .section-our-values {
  padding: 80px 0px; }
  #gofood .goresto .section-our-values .title {
    color: #B62025; }
  #gofood .goresto .section-our-values .line.gradient {
    background: #B62025; }
  #gofood .goresto .section-our-values .list {
    padding: 0 5px; }
    #gofood .goresto .section-our-values .list img {
      max-width: 90px; }
    #gofood .goresto .section-our-values .list img.small {
      max-width: 120px; }
    #gofood .goresto .section-our-values .list img.seq-1 {
      margin-top: 58px; }
    #gofood .goresto .section-our-values .list img.seq-3 {
      margin-top: 42px; }
    #gofood .goresto .section-our-values .list img.seq-4 {
      margin-top: 64px; }

#gofood .goresto section.services h4 {
  color: #B62025; }

#gofood .goresto section.services .wrapper .service-container {
  background: #B62025;
  background-image: none; }

#gofood .goresto section.services .service-list h5 {
  color: #B62025; }

#gofood .goresto section.services .service-list p {
  margin-bottom: -50px; }

#gofood .goresto section.services .service-list a {
  color: #B62025; }

#gofood .goresto section.services .service-list a:after {
  background-image: none; }

#gofood .goresto .modal.welcome {
  display: none;
  position: fixed;
  _position: absolute;
  width: 965px;
  background: #FFFFFF;
  z-index: 100;
  margin-left: 15px;
  margin: 0 auto;
  top: 50%;
  left: 50%;
  margin-top: -200px;
  /* Negative half of height. */
  margin-left: -482.5px;
  /* Negative half of width. */
  font-size: 15px;
  height: 450px;
  -moz-box-shadow: 0 0 5px #ff0000;
  -webkit-box-shadow: 0 0 5px #ff0000;
  box-shadow: 0 0px 30px 0px rgba(140, 140, 140, 0.85); }
  #gofood .goresto .modal.welcome .column-left {
    text-align: center;
    padding: 15px 0; }
    #gofood .goresto .modal.welcome .column-left h1 {
      font-weight: 300;
      font-size: 28px;
      letter-spacing: 5px; }
    #gofood .goresto .modal.welcome .column-left p {
      font-size: 15.5px;
      font-weight: 400;
      margin-bottom: 20px; }
    #gofood .goresto .modal.welcome .column-left .button-wrapper {
      margin: 40px 0; }
      #gofood .goresto .modal.welcome .column-left .button-wrapper p {
        margin: 30px 0; }
      #gofood .goresto .modal.welcome .column-left .button-wrapper a.button {
        background: #B62025;
        padding: 20px 30px;
        margin-bottom: 20px; }
  #gofood .goresto .modal.welcome .column-right {
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    height: 450px !important;
    position: relative;
    box-shadow: 40px -40px 0 #B62025; }

@media only screen and (max-width: 768px) {
  #gofood .goresto header#main--hero .hero--title {
    position: relative;
    top: -15px; }
  #gofood .goresto header#main--hero button.download {
    bottom: unset; }
  #gofood .goresto .captcha-wrapper {
    margin-top: 80px; }
  #gofood .goresto .checkbox-wrapper {
    padding: 0 !important;
    margin-top: 50px;
    margin-bottom: -40px; }
  #gofood .goresto #content-only {
    padding-top: 70px;
    padding-bottom: 30px; }
  #gofood .goresto section.search-resto {
    padding: 0;
    margin-top: -4px;
    position: relative;
    z-index: 1000; }
    #gofood .goresto section.search-resto .container {
      padding: 0; }
    #gofood .goresto section.search-resto .wrapper {
      border-radius: 0; }
      #gofood .goresto section.search-resto .wrapper .form-wrapper {
        padding: 0; }
        #gofood .goresto section.search-resto .wrapper .form-wrapper fieldset {
          padding: 10px; }
          #gofood .goresto section.search-resto .wrapper .form-wrapper fieldset label {
            display: unset;
            margin-right: 15px;
            position: relative;
            top: 0px; }
            #gofood .goresto section.search-resto .wrapper .form-wrapper fieldset label i {
              display: none; }
          #gofood .goresto section.search-resto .wrapper .form-wrapper fieldset input {
            width: 100%;
            display: unset;
            position: relative;
            top: 17px;
            border-radius: 0; }
          #gofood .goresto section.search-resto .wrapper .form-wrapper fieldset button {
            position: relative;
            top: 18px;
            margin-top: 10px;
            padding: 15px;
            background: #353b44;
            border-radius: 5px; }
  #gofood .goresto section.result-resto {
    margin-top: 50px;
    padding: 0px; }
    #gofood .goresto section.result-resto .container {
      padding: 0; }
      #gofood .goresto section.result-resto .container .panel .text {
        margin-top: -5px;
        background: #B62025;
        text-align: center;
        z-index: -1;
        padding: 32px; }
        #gofood .goresto section.result-resto .container .panel .text h4 {
          line-height: 40px;
          font-size: 24px;
          font-weight: 300;
          font-family: "Open Sans", Helvetica, sans-serif;
          color: white; }
        #gofood .goresto section.result-resto .container .panel .text p, #gofood .goresto section.result-resto .container .panel .text a {
          font-weight: 300;
          color: white;
          margin-top: -25px;
          margin-bottom: 25px; }
        #gofood .goresto section.result-resto .container .panel .text .link.notfound {
          display: none; }
      #gofood .goresto section.result-resto .container .panel .body {
        padding: 0; }
        #gofood .goresto section.result-resto .container .panel .body .loader {
          margin-top: -90px; }
        #gofood .goresto section.result-resto .container .panel .body [data-step="2"],
        #gofood .goresto section.result-resto .container .panel .body [data-step="3"] {
          left: 0 !important;
          padding: 25px !important; }
        #gofood .goresto section.result-resto .container .panel .body .form .addmore-outlet h4 {
          float: right;
          color: #b62025;
          font-size: 15px;
          margin-right: 0px;
          margin-top: -10px; }
        #gofood .goresto section.result-resto .container .panel .body .form .outlet-search .outlet-search-list-wrapper {
          padding: 0; }
          #gofood .goresto section.result-resto .container .panel .body .form .outlet-search .outlet-search-list-wrapper .outlet-search-list .list a.button {
            position: relative;
            top: 65px;
            z-index: 9; }
        #gofood .goresto section.result-resto .container .panel .body .merchant-info .not-found a {
          bottom: -95px; }
        #gofood .goresto section.result-resto .container .panel .body .merchant {
          padding: 40px 0; }
          #gofood .goresto section.result-resto .container .panel .body .merchant .join--link {
            margin: 40px 0 0;
            top: 45px;
            position: relative; }
          #gofood .goresto section.result-resto .container .panel .body .merchant .list {
            padding: 0px 35px; }
            #gofood .goresto section.result-resto .container .panel .body .merchant .list .item--wrapper {
              margin: 0;
              padding: 50px 0; }
            #gofood .goresto section.result-resto .container .panel .body .merchant .list h4, #gofood .goresto section.result-resto .container .panel .body .merchant .list h5, #gofood .goresto section.result-resto .container .panel .body .merchant .list p {
              margin: 0;
              padding: 0; }
            #gofood .goresto section.result-resto .container .panel .body .merchant .list h4 {
              line-height: 18px; }
            #gofood .goresto section.result-resto .container .panel .body .merchant .list h5 {
              line-height: 1.6;
              letter-spacing: 0.5px;
              color: #9b9b9b;
              font-size: 11px;
              margin-top: 5px; }
            #gofood .goresto section.result-resto .container .panel .body .merchant .list p {
              object-fit: contain;
              line-height: 1.6;
              letter-spacing: 0.5px;
              color: #4a4a4a; }
            #gofood .goresto section.result-resto .container .panel .body .merchant .list hr {
              position: relative;
              margin: 0;
              border: 0;
              height: 1px;
              background: rgba(200, 200, 200, 0.25); }
            #gofood .goresto section.result-resto .container .panel .body .merchant .list a.button {
              bottom: -30px;
              z-index: 1000; }
              #gofood .goresto section.result-resto .container .panel .body .merchant .list a.button span {
                margin: 0 25px; }
        #gofood .goresto section.result-resto .container .panel .body .main-wrapper {
          margin: 50px 0;
          height: auto;
          width: 100%; }
          #gofood .goresto section.result-resto .container .panel .body .main-wrapper .separator-title {
            left: 0px;
            top: 0px; }
          #gofood .goresto section.result-resto .container .panel .body .main-wrapper .upload-wrapper {
            margin-top: 40px; }
          #gofood .goresto section.result-resto .container .panel .body .main-wrapper .background-wrapper {
            display: none;
            min-height: 100px;
            height: 100% !important;
            width: 100%;
            padding: 0;
            text-align: center; }
            #gofood .goresto section.result-resto .container .panel .body .main-wrapper .background-wrapper img {
              max-width: 25px;
              margin-top: 40px; }
          #gofood .goresto section.result-resto .container .panel .body .main-wrapper .checkbox-wrapper label {
            width: 100%;
            display: block; }
          #gofood .goresto section.result-resto .container .panel .body .main-wrapper .text-wrapper {
            margin-bottom: 35px;
            margin-top: 10px;
            position: relative;
            left: -10px; }
            #gofood .goresto section.result-resto .container .panel .body .main-wrapper .text-wrapper .title {
              left: 9.5px;
              margin-top: -15px;
              font-size: 12px;
              position: relative;
              top: 10px;
              color: #c8c8c8; }
          #gofood .goresto section.result-resto .container .panel .body .main-wrapper .form-container {
            margin-bottom: 50px;
            padding: 10px;
            margin-left: 0px;
            left: 0;
            position: relative; }
          #gofood .goresto section.result-resto .container .panel .body .main-wrapper .form-wrapper {
            padding: 0 !important; }
            #gofood .goresto section.result-resto .container .panel .body .main-wrapper .form-wrapper .group {
              margin-bottom: 20px;
              width: 100%;
              margin: 20px 0; }
              #gofood .goresto section.result-resto .container .panel .body .main-wrapper .form-wrapper .group label {
                font-family: "Open Sans", Helvetica, sans-serif;
                font-weight: 700;
                text-transform: uppercase;
                letter-spacing: 1.25px;
                font-size: 10.5px;
                color: #697b8c; }
              #gofood .goresto section.result-resto .container .panel .body .main-wrapper .form-wrapper .group h6 {
                margin-bottom: -30px; }
              #gofood .goresto section.result-resto .container .panel .body .main-wrapper .form-wrapper .group input,
              #gofood .goresto section.result-resto .container .panel .body .main-wrapper .form-wrapper .group select {
                width: 100%;
                height: 45px;
                padding: 15px;
                margin-top: 5px;
                letter-spacing: 1.2px;
                font-size: 13.4px;
                background: white;
                border-radius: 0 !important;
                border: 1px solid #d2d2d2;
                color: #485460; }
              #gofood .goresto section.result-resto .container .panel .body .main-wrapper .form-wrapper .group .icon {
                float: right; }
                #gofood .goresto section.result-resto .container .panel .body .main-wrapper .form-wrapper .group .icon i {
                  position: relative;
                  left: -15px;
                  top: 35px;
                  font-size: 12px;
                  color: #d2d2d2; }
            #gofood .goresto section.result-resto .container .panel .body .main-wrapper .form-wrapper .error_messages {
              color: red;
              margin-top: 10px; }
          #gofood .goresto section.result-resto .container .panel .body .main-wrapper .form-wrapper.second-column {
            position: relative;
            top: 10px;
            left: 0; }
          #gofood .goresto section.result-resto .container .panel .body .main-wrapper .form-wrapper.first-column {
            margin-top: 40px;
            left: 0; }
          #gofood .goresto section.result-resto .container .panel .body .main-wrapper .form-button {
            position: relative;
            text-align: center;
            top: 0;
            left: 0px;
            padding: 12px;
            margin-bottom: -110px; }
            #gofood .goresto section.result-resto .container .panel .body .main-wrapper .form-button .button {
              font-size: 14px;
              font-family: "Open Sans", Helvetica, sans-serif;
              font-weight: 400;
              padding: 25px;
              transition: all .5s ease 0s;
              margin: 0 5px; }
              #gofood .goresto section.result-resto .container .panel .body .main-wrapper .form-button .button span {
                margin: 0 50px; }
            #gofood .goresto section.result-resto .container .panel .body .main-wrapper .form-button .button:hover,
            #gofood .goresto section.result-resto .container .panel .body .main-wrapper .form-button .button:focus {
              box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.65); }
            #gofood .goresto section.result-resto .container .panel .body .main-wrapper .form-button .button-back {
              background: #353b44;
              margin-bottom: 20px;
              padding: 20px; }
            #gofood .goresto section.result-resto .container .panel .body .main-wrapper .form-button .button-submit-go {
              text-transform: none;
              background: #B62025;
              color: #fff;
              float: none;
              position: relative;
              left: 30px;
              padding: 24px;
              margin: 0 auto;
              display: block; }
  #gofood .goresto section.about p {
    margin-bottom: -100px; }
  #gofood .goresto section.search-resto h4 {
    position: relative;
    left: -25px;
    font-size: 11px;
    margin-top: 15px; }
  #gofood .goresto section.call-navigation .container .box a {
    margin: 0;
    margin-right: -5px;
    position: relative;
    top: -2.5px;
    padding: 15px 10.5px; }
  #gofood .goresto .modal.welcome {
    background: transparent;
    top: 52%;
    width: 100%; }
    #gofood .goresto .modal.welcome .column-left {
      position: relative;
      left: 240px;
      top: 20px;
      background: white;
      padding: 20px 50px; }
      #gofood .goresto .modal.welcome .column-left .wrapper {
        position: relative;
        left: 12.5px; }
    #gofood .goresto .modal.welcome .column-right {
      position: absolute;
      float: none;
      left: 320px;
      z-index: -9;
      box-shadow: -45px -45px 0 #b62025;
      top: -15px;
      height: 540px !important; }
  #gofood .goresto .modal.tnc {
    position: fixed;
    _position: absolute;
    width: 100%;
    background: #f2f2f2;
    z-index: 100;
    margin-left: 15px;
    margin: 0 auto;
    top: 140px;
    left: 0;
    /* left: 50%; */
    /* margin-top: -200px; */
    /* margin-left: -482.5px; */
    font-size: 15px;
    height: 490px;
    box-shadow: 0 0 30px 0 rgba(140, 140, 140, 0.85); }
    #gofood .goresto .modal.tnc a.removebutton {
      opacity: 0; }
    #gofood .goresto .modal.tnc h3.title {
      position: relative;
      top: 1.25px;
      width: 100%; }
    #gofood .goresto .modal.tnc .column-block {
      padding: 10px; }
      #gofood .goresto .modal.tnc .column-block h4 {
        float: none;
        position: relative;
        left: 55px;
        text-align: center;
        top: -30.5px;
        padding: 0;
        line-height: 17px; } }

@media only screen and (max-width: 480px) {
  #gofood .goresto .captcha-wrapper {
    margin-top: 80px; }
  #gofood .goresto section.search-resto .join--link p {
    position: relative;
    top: 15px; }
  #gofood .goresto section.result-resto .container .panel .body .main-wrapper {
    margin-bottom: 100px; }
    #gofood .goresto section.result-resto .container .panel .body .main-wrapper .side-two-column {
      margin-right: 0px !important; }
    #gofood .goresto section.result-resto .container .panel .body .main-wrapper .upload-button-wrapper.saving-book {
      margin-top: -45px; }
    #gofood .goresto section.result-resto .container .panel .body .main-wrapper .form-button {
      left: 0px;
      top: 40px; }
      #gofood .goresto section.result-resto .container .panel .body .main-wrapper .form-button .button {
        padding: 25px 0px !important; }
      #gofood .goresto section.result-resto .container .panel .body .main-wrapper .form-button .button-submit {
        padding: 20px 0px !important; }
      #gofood .goresto section.result-resto .container .panel .body .main-wrapper .form-button .button-back {
        padding: 20px 5px !important; } }

@media only screen and (max-width: 400px) {
  #gofood .goresto section.result-resto .container .panel .body .main-wrapper .form-button {
    left: 0px; }
    #gofood .goresto section.result-resto .container .panel .body .main-wrapper .form-button .button {
      padding: 25px 0px !important; }
    #gofood .goresto section.result-resto .container .panel .body .main-wrapper .form-button .button-submit {
      padding: 20px 0px !important; }
    #gofood .goresto section.result-resto .container .panel .body .main-wrapper .form-button .button-back {
      padding: 20px 5px !important; }
  #gofood .goresto section.call-navigation .container .box {
    padding: 15px; }
    #gofood .goresto section.call-navigation .container .box a {
      font-size: 9px; }
  #gofood .goresto .modal.welcome {
    transform: scale(0.9);
    left: 250px; } }

@media only screen and (max-width: 320px) {
  #gofood .goresto header#main--hero .desc--row .sub-desc--row {
    position: relative;
    top: -70px; }
  #gofood .goresto section.call-navigation .container .box {
    padding: 10px; }
    #gofood .goresto section.call-navigation .container .box a {
      font-size: 7px; }
  #gofood .goresto section.result-resto .container .panel .body .main-wrapper .form-button {
    left: 0px;
    padding: 0; }
    #gofood .goresto section.result-resto .container .panel .body .main-wrapper .form-button .button {
      padding: 25px 0px !important; }
    #gofood .goresto section.result-resto .container .panel .body .main-wrapper .form-button .button-submit {
      padding: 20px 0px !important; }
    #gofood .goresto section.result-resto .container .panel .body .main-wrapper .form-button .button-back {
      padding: 20px 0px !important; }
  #gofood .goresto .modal.welcome {
    transform: scale(0.795);
    left: 259px; }
    #gofood .goresto .modal.welcome .column-left {
      padding: 20px 20px; }
      #gofood .goresto .modal.welcome .column-left .wrapper {
        left: 0; }
    #gofood .goresto .modal.welcome .column-right {
      box-shadow: -105px -75px 0 #b62025; }
  #gofood .goresto .modal.tnc {
    transform: scale(0.9);
    top: 100px; }
    #gofood .goresto .modal.tnc h4 {
      float: none;
      font-size: 10px;
      position: relative;
      left: 55px;
      text-align: center;
      top: -23.5px;
      padding: 0;
      line-height: 17px; } }

#govideo {
  /*submenu navbar */
  /*ending submenu-navbar */
  /* Global UX */
  /* Form */ }
  #govideo .cat-desktop {
    display: block; }
  #govideo .cat-mobile {
    display: none; }
    #govideo .cat-mobile select {
      text-transform: none;
      width: 100% !important;
      border: 1px solid #c8c8c8;
      border-radius: 0;
      background: white;
      padding: 10px;
      display: block; }
    #govideo .cat-mobile i {
      float: right;
      margin-top: -30px;
      position: relative;
      left: -12px; }

@-webkit-keyframes uil-ring-anim {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

@-moz-keyframes uil-ring-anim {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

@-webkit-keyframes uil-ring-anim {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

@-o-keyframes uil-ring-anim {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

@keyframes uil-ring-anim {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }
  #govideo .loader {
    position: absolute;
    margin-bottom: -200px;
    display: none;
    z-index: 99;
    background: rgba(250, 250, 250, 0.8);
    height: 100%;
    width: 100%;
    margin-top: -15px; }
    #govideo .loader .uil-ring-css {
      background: none;
      position: relative;
      width: 200px;
      height: 200px;
      margin: 0 auto;
      display: block; }
    #govideo .loader .uil-ring-css > div {
      position: absolute;
      display: block;
      width: 160px;
      height: 160px;
      top: 80%;
      transform: translateY(100%);
      left: 20px;
      border-radius: 80px;
      box-shadow: 0 5px 0 0 #ebb329;
      -webkit-transform-origin: 80px 81.5px;
      transform-origin: 80px 81.5px;
      -webkit-animation: uil-ring-anim 1s linear infinite;
      animation: uil-ring-anim 1s linear infinite; }
  #govideo .container-action-govideo {
    left: 0;
    margin: 6% 12%;
    bottom: 0;
    position: absolute; }
    #govideo .container-action-govideo img {
      max-width: 100%;
      max-width: 450px;
      width: 90%; }
      @media screen and (max-width: 768px) {
        #govideo .container-action-govideo img {
          margin-bottom: 20px;
          width: 70%; } }
    #govideo .container-action-govideo button {
      margin-top: 30px;
      padding: 15px 35px;
      background: #ecb42c;
      border-radius: 5px;
      color: #ffffff;
      transition: all 0.5s; }
      @media screen and (max-width: 767px) {
        #govideo .container-action-govideo button {
          position: absolute;
          margin-top: -3px;
          width: 215px;
          margin: auto;
          left: 0;
          right: 0;
          bottom: -32px; } }
      @media screen and (min-width: 769px) {
        #govideo .container-action-govideo button:hover {
          transition: all 0.5s;
          background: #ffffff;
          color: #ecb42c;
          box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.19); } }
    @media screen and (max-width: 768px) {
      #govideo .container-action-govideo {
        margin: 5% 3%;
        bottom: 0;
        position: absolute; } }
  #govideo .hamburger-mobile {
    top: 23px; }
  #govideo .navbar .brand {
    top: 4px;
    position: relative; }
  #govideo .career-menu {
    background-color: #343b41; }
    #govideo .career-menu ul {
      background-color: #343b41;
      list-style: none;
      margin: 0;
      padding: 0;
      overflow: hidden;
      display: block !important;
      justify-content: center; }
      #govideo .career-menu ul li a {
        display: block;
        color: white;
        text-align: center;
        padding: 16px;
        text-decoration: none;
        letter-spacing: 1px;
        top: 0;
        margin: 0; }
      #govideo .career-menu ul li a.career-tab {
        background: #8cc63f;
        padding: 16px 26px;
        margin: 0; }
      #govideo .career-menu ul li a.active:after {
        transform: none;
        bottom: 0;
        top: auto;
        height: 4px; }
      #govideo .career-menu ul li.active a:not(.career-tab):after,
      #govideo .career-menu ul li a:not(.career-tab):hover:after {
        content: "";
        width: 100%;
        position: absolute;
        bottom: 0;
        height: 4px;
        left: 0;
        background: #48af4a; }
    #govideo .career-menu .career-tab {
      background-color: #8cc63f;
      display: flex;
      flex-direction: column;
      justify-content: center; }
      #govideo .career-menu .career-tab a {
        text-decoration: none;
        display: block;
        color: #ffffff; }
  #govideo .sub-menu, #govideo .container-sub-menu {
    display: none; }
  @media screen and (max-width: 48em) {
    #govideo #main--hero {
      margin-top: 60px;
      min-height: unset; }
    #govideo .career-menu {
      display: none; }
    #govideo .sub-menu, #govideo .container-sub-menu {
      display: block; }
    #govideo .navbar {
      min-height: unset;
      height: 60px;
      /* extra sub menu */ }
      #govideo .navbar .brand img {
        width: 100% !important;
        max-width: 30px; }
      #govideo .navbar .wrapper {
        padding: 11px 0;
        height: auto; }
      #govideo .navbar .hamburger {
        top: 13px; }
      #govideo .navbar .sub-menu {
        cursor: pointer;
        position: absolute;
        padding: 0 15px;
        height: 100%;
        margin-left: 15px;
        top: 0;
        color: white;
        font-size: 14px;
        background: #84bf41; }
        #govideo .navbar .sub-menu .table-display {
          display: table;
          width: 100%;
          height: 100%; }
          #govideo .navbar .sub-menu .table-display .mid-table {
            width: 100%;
            text-align: center;
            display: table-cell;
            vertical-align: middle; }
            #govideo .navbar .sub-menu .table-display .mid-table i#iconDownSubmenu {
              transition: all 0.5s;
              margin-left: 5px; }
            #govideo .navbar .sub-menu .table-display .mid-table i#iconDownSubmenu.active {
              transition: all 0.5s;
              transform: rotate(180deg); }
    #govideo .container-sub-menu {
      display: block;
      z-index: 11;
      transition: all 0.5s;
      position: fixed;
      height: 100%;
      width: 100%;
      opacity: 0;
      pointer-events: none;
      background: rgba(0, 0, 0, 0.5); }
      #govideo .container-sub-menu .content-sub-menu {
        width: 100%;
        background: #84bf41;
        transform: translateY(-100%);
        transition: all 0.5s; }
      #govideo .container-sub-menu .content-sub-menu.active {
        transition: all 0.5s 0.2s;
        transform: translateY(0%); }
      #govideo .container-sub-menu ul.list-content {
        margin: 0px;
        background: #84bf41;
        padding-left: 0px;
        padding-top: 60px; }
        #govideo .container-sub-menu ul.list-content li {
          list-style-type: none;
          padding: 15px 20px; }
          #govideo .container-sub-menu ul.list-content li a {
            line-height: normal;
            font-size: 14px;
            color: white;
            letter-spacing: normal; }
        #govideo .container-sub-menu ul.list-content li.active {
          background: #2c822e; }
    #govideo .container-sub-menu.active {
      opacity: 1;
      transition: all 0.5s;
      pointer-events: visible; }
    #govideo .navbar.navbar-active.no--active {
      top: 0px; }
    #govideo .g-recaptcha {
      transform: none;
      left: auto; } }
  @media screen and (min-width: 769px) {
    #govideo .navbar.no--active {
      transform: translateY(-84px); } }
  #govideo .menu ul li {
    position: relative; }
  #govideo .hide {
    display: none !important; }
  #govideo header.participant {
    background-size: cover !important;
    background-repeat: no-repeat !important;
    background-position: center !important;
    height: 600px; }
    #govideo header.participant .video-wrapper {
      display: none;
      width: 100% !important;
      position: absolute;
      margin-top: -100px; }
      #govideo header.participant .video-wrapper iframe.video {
        height: 600px; }
  #govideo .play {
    overflow: hidden;
    transition: all 0.5s;
    position: absolute;
    margin: auto;
    top: 0;
    left: 0;
    cursor: pointer;
    right: 0;
    bottom: 0;
    width: 90px;
    height: 90px;
    border-radius: 50%;
    object-fit: contain;
    background-color: #ffcd2e;
    box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.24); }
    @media screen and (min-width: 769px) {
      #govideo .play::after {
        position: absolute;
        width: 80%;
        text-align: center;
        content: 'Play Video';
        font-weight: 700;
        color: #ffcd2e;
        height: 36px;
        letter-spacing: normal !important;
        right: 0;
        line-height: normal !important;
        left: 0;
        top: 0;
        bottom: 0;
        height: 30px;
        margin: auto;
        transition: all 1s;
        transform: translateX(-100px); }
      #govideo .play:hover {
        background: #ffffff; }
        #govideo .play:hover img {
          transform: scale(9); }
        #govideo .play:hover::after {
          transform: translateX(0px); } }
    #govideo .play img {
      transition: all 0.5s;
      margin: auto;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0; }
  #govideo header {
    overflow: visible; }
    #govideo header .desc--wrapper .desc--row .bottom {
      font-weight: bold; }
    #govideo header .desc--wrapper .desc--row h1.hero--title {
      object-fit: contain;
      font-family: "Lato", Helvetica, sans-serif;
      font-size: 40px;
      line-height: 1.41;
      letter-spacing: 4.5px;
      margin-bottom: 65px !important; }
      #govideo header .desc--wrapper .desc--row h1.hero--title span {
        display: inline-block; }
      #govideo header .desc--wrapper .desc--row h1.hero--title div.bottom {
        margin-top: 20px; }
    #govideo header .desc--wrapper .desc--row h1.hero--title.second {
      margin-top: 200px;
      font-size: 52px; }
    #govideo header .desc--wrapper .desc--row a.hero--button {
      color: white;
      background-color: #ECB42C;
      text-transform: uppercase;
      border-radius: 5px;
      padding: 22.5px 20px;
      -webkit-transition: all .3s ease-in-out;
      -moz-transition: all .3s ease-in-out;
      -o-transition: all .3s ease-in-out;
      transition: all .3s ease-in-out; }
    #govideo header .desc--wrapper .desc--row a.hero--button:hover {
      box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.19);
      color: #ECB42C;
      background: #fff; }
  #govideo .scale--hover {
    transition: all .3s ease-in-out;
    transform: scale(1);
    transform-origin: center; }
  @media screen and (min-width: 769px) {
    #govideo .scale--hover:hover {
      transform: scale(1.1); } }
  #govideo form .err--message {
    color: red;
    margin: 0;
    font-style: italic;
    font-size: 0.875em;
    font-weight: 300;
    line-height: 1.62em;
    letter-spacing: 1.5px;
    font-family: "Open Sans", Helvetica, sans-serif; }
  #govideo form .err--message.poster {
    text-align: center;
    display: block;
    margin-top: 10px; }
  #govideo form .poster.error .item,
  #govideo form .error .form--field {
    border-color: red !important;
    border-width: 2px !important; }
  #govideo .float-arrow {
    position: absolute;
    height: 50px;
    width: 50px;
    top: 18%;
    bottom: 0;
    right: auto;
    left: auto;
    z-index: 10; }
  #govideo .float-arrow.arrow-right {
    right: 0; }
  #govideo .float-arrow.arrow-left {
    left: 0; }
  #govideo .float-arrow.slick-disabled svg ellipse, #govideo .float-arrow.slick-disabled svg polygon {
    stroke: #9d9d9d !important; }
  #govideo .float-arrow.slick-disabled svg polygon {
    fill: #9d9d9d !important; }
  #govideo section.support {
    padding-bottom: 100px;
    text-align: center; }
    #govideo section.support .logo div.container-logo {
      text-align: left;
      display: inline-block;
      margin: 15px; }
      #govideo section.support .logo div.container-logo img {
        max-height: 70px; }
  #govideo section.how-it-works--wrapper {
    margin-bottom: -60px; }
    #govideo section.how-it-works--wrapper img {
      background-image: none !important; }
    #govideo section.how-it-works--wrapper figure.img--wrapper {
      height: 100% !important; }
      #govideo section.how-it-works--wrapper figure.img--wrapper img {
        top: 0 !important; }
    #govideo section.how-it-works--wrapper [data-video=poster] {
      width: 100% !important; }
    #govideo section.how-it-works--wrapper .layer {
      padding: 30px 60px 0px !important; }
      #govideo section.how-it-works--wrapper .layer .play {
        z-index: 9; }
        #govideo section.how-it-works--wrapper .layer .play img {
          top: 0px !important; }
      #govideo section.how-it-works--wrapper .layer .video-wrapper {
        display: none;
        height: 100%; }
      #govideo section.how-it-works--wrapper .layer .video {
        margin: 0;
        height: 100%; }
      #govideo section.how-it-works--wrapper .layer .how--title {
        font-family: "Open Sans", Helvetica, sans-serif;
        font-size: 16px;
        font-weight: bold;
        line-height: 0.88;
        text-transform: uppercase;
        letter-spacing: 1.7px;
        color: #009444; }
        #govideo section.how-it-works--wrapper .layer .how--title span {
          position: relative;
          top: -15px;
          margin-left: 10px; }
      #govideo section.how-it-works--wrapper .layer .how--title[data-category="Music Video"]:before {
        background: url(https://bilba.go-jek.com/dist/img/page/govideo/category/music.svg);
        content: '';
        display: inline-block;
        position: relative;
        height: 50px;
        width: 50px;
        background-size: 50px 50px;
        background-repeat: no-repeat; }
      #govideo section.how-it-works--wrapper .layer .how--title[data-category="Drama"]:before {
        background: url(https://bilba.go-jek.com/dist/img/page/govideo/category/short.svg);
        content: '';
        display: inline-block;
        position: relative;
        height: 50px;
        width: 50px;
        background-size: 50px 50px;
        background-repeat: no-repeat; }
      #govideo section.how-it-works--wrapper .layer .how--title[data-category="Comedy"]:before {
        background: url(https://bilba.go-jek.com/dist/img/page/govideo/category/comedy.svg);
        content: '';
        display: inline-block;
        position: relative;
        height: 50px;
        width: 50px;
        background-size: 50px 50px;
        background-repeat: no-repeat; }
      #govideo section.how-it-works--wrapper .layer .how--title[data-category="Animation"]:before {
        background: url(https://bilba.go-jek.com/dist/img/page/govideo/category/animation.svg);
        content: '';
        display: inline-block;
        position: relative;
        height: 50px;
        width: 50px;
        background-size: 50px 50px;
        background-repeat: no-repeat; }
      #govideo section.how-it-works--wrapper .layer .how--title[data-category="Documentary"]:before {
        background: url(https://bilba.go-jek.com/dist/img/page/govideo/category/doc.svg);
        content: '';
        display: inline-block;
        position: relative;
        height: 50px;
        width: 50px;
        background-size: 50px 50px;
        background-repeat: no-repeat; }
      #govideo section.how-it-works--wrapper .layer [data-video=info] {
        font-family: "Open Sans", Helvetica, sans-serif;
        font-size: 14px;
        font-weight: bold;
        line-height: 1.0;
        text-transform: uppercase;
        letter-spacing: 1.5px; }
      #govideo section.how-it-works--wrapper .layer [data-video=desc] {
        font-family: "Open Sans", Helvetica, sans-serif;
        font-size: 16px;
        font-weight: 300;
        line-height: 1.62;
        letter-spacing: 0.6px;
        margin-top: -20px;
        padding: 0; }
      #govideo section.how-it-works--wrapper .layer [data-video=title] {
        font-family: "Open Sans", Helvetica, sans-serif;
        font-size: 26px;
        font-weight: bold;
        line-height: 1.29;
        letter-spacing: 1.1px;
        color: #000000; }
      #govideo section.how-it-works--wrapper .layer [data-display=desktop] {
        display: block; }
      #govideo section.how-it-works--wrapper .layer [data-display=mobile] {
        display: none; }
      #govideo section.how-it-works--wrapper .layer .rect--secondary,
      #govideo section.how-it-works--wrapper .layer .rect--primary {
        background: transparent !important; }
      #govideo section.how-it-works--wrapper .layer .btn-all {
        object-fit: contain;
        border-radius: 5px;
        background-color: #ebb329;
        text-align: center;
        margin: 0 auto;
        padding: 30px 25px;
        color: #fff;
        position: relative;
        z-index: 9; }
  @media only screen and (max-width: 35.5em) {
    #govideo section.how-it-works--wrapper.home {
      margin-bottom: 0px; }
      #govideo section.how-it-works--wrapper.home figure.img--wrapper {
        height: 82% !important; }
        #govideo section.how-it-works--wrapper.home figure.img--wrapper img {
          transform: scale(1.35); }
      #govideo section.how-it-works--wrapper.home .play {
        transform: scale(0.5); }
        #govideo section.how-it-works--wrapper.home .play img {
          left: 0;
          width: 30px;
          position: absolute;
          right: 0;
          bottom: 0;
          margin: auto !important; }
      #govideo section.how-it-works--wrapper.home .btn-all {
        top: 65px; }
      #govideo section.how-it-works--wrapper.home [data-display=desktop] {
        display: none !important; }
      #govideo section.how-it-works--wrapper.home [data-display=mobile] {
        display: inline-block !important; }
      #govideo section.how-it-works--wrapper.home [data-video=title] {
        margin-bottom: 0;
        margin-top: 0px;
        font-size: 24px;
        margin-bottom: -12.5px; }
      #govideo section.how-it-works--wrapper.home [data-video=title].hardcode {
        margin-top: 12.5px; }
      #govideo section.how-it-works--wrapper.home .featured-img {
        max-width: 30px !important;
        margin-top: -10px; }
      #govideo section.how-it-works--wrapper.home .featured-copy {
        margin-left: 45px !important; }
      #govideo section.how-it-works--wrapper.home .how--title {
        margin-top: -10px !important;
        margin-bottom: -5px; }
      #govideo section.how-it-works--wrapper.home .layer {
        margin-top: 0 !important;
        padding: 30px 25px 60px !important; }
        #govideo section.how-it-works--wrapper.home .layer .desc--pad {
          padding: 0px 25px !important;
          margin-top: -10px;
          margin-bottom: -40px; }
        #govideo section.how-it-works--wrapper.home .layer .video {
          height: 250px !important; } }
  @media only screen and (max-width: 320px) {
    #govideo section.how-it-works--wrapper .layer [data-video=title] {
      margin-top: 0px;
      margin-bottom: -12.5px; }
    #govideo section.how-it-works--wrapper .layer [data-video=title].hardcode {
      margin-top: 12.5px; }
    #govideo section.how-it-works--wrapper .layer .how--title span {
      font-size: 14.5px; } }
  #govideo section.services {
    padding-bottom: 200px !important; }
    #govideo section.services .container:before {
      background-color: transparent; }
    #govideo section.services h4.title {
      position: relative;
      top: 15px;
      left: -10px;
      padding-left: 0 !important;
      font-size: 18px;
      font-weight: bold;
      line-height: 0.78;
      letter-spacing: 1.9px;
      color: #4a4a4a;
      margin-bottom: 20px;
      line-height: 25px; }
      #govideo section.services h4.title:before {
        content: url(https://d3naj63yelf4gk.cloudfront.net/dist/img/line.svg);
        position: relative;
        left: -30px;
        top: -5px;
        margin-right: -10px; }
    #govideo section.services .wrapper .service-container {
      background-image: unset !important;
      background-color: #009444; }
      #govideo section.services .wrapper .service-container .service-list {
        height: 22em !important; }
        #govideo section.services .wrapper .service-container .service-list .img-container {
          background-size: cover !important;
          background-position: center !important;
          background-repeat: no-repeat !important;
          height: 240px; }
        #govideo section.services .wrapper .service-container .service-list a:after {
          background-image: none !important; }
        #govideo section.services .wrapper .service-container .service-list .text {
          padding: 0px 10px;
          text-align: unset; }
          #govideo section.services .wrapper .service-container .service-list .text [data-video=category] {
            object-fit: contain;
            font-family: "Open Sans", Helvetica, sans-serif;
            font-size: 12.5px;
            font-weight: bold;
            line-height: 0.93;
            letter-spacing: 1.6px;
            color: #009444; }
          #govideo section.services .wrapper .service-container .service-list .text [data-video=title] {
            font-family: "Open Sans", Helvetica, sans-serif;
            font-size: 15px;
            font-weight: bold;
            line-height: 1.5;
            text-transform: unset;
            letter-spacing: 0.8px;
            margin-top: -27.5px;
            margin-left: -15px;
            color: #4a4a4a; }
          #govideo section.services .wrapper .service-container .service-list .text [data-video=name] {
            font-family: "Open Sans", Helvetica, sans-serif;
            font-size: 13px;
            font-weight: unset;
            margin-top: 0;
            line-height: 1.87;
            letter-spacing: 0.6px;
            color: #babcbf;
            margin-top: -30px;
            margin-bottom: 0px;
            opacity: .8; }
  #govideo section.video .filter {
    margin-top: -30px;
    position: sticky;
    top: 150px; }
  #govideo section.video .filter,
  #govideo section.video .detail {
    background: white;
    width: 100%;
    padding: 5px 20px;
    border-top: 6px solid #ebb329; }
    #govideo section.video .filter h4,
    #govideo section.video .detail h4 {
      object-fit: contain;
      font-family: "Open Sans", Helvetica, sans-serif;
      font-size: 18px;
      font-weight: bold;
      line-height: 0.78;
      letter-spacing: 1.9px;
      color: #4a4a4a;
      margin-left: -10px; }
      #govideo section.video .filter h4:before,
      #govideo section.video .detail h4:before {
        content: url(https://d3naj63yelf4gk.cloudfront.net/dist/img/line.svg);
        position: relative;
        left: -30px;
        top: -5px; }
    #govideo section.video .filter h4.breadcrumb,
    #govideo section.video .detail h4.breadcrumb {
      font-size: 12px;
      font-weight: bold;
      line-height: 1.5;
      letter-spacing: 1.3px;
      color: #b3b9c7;
      margin-top: 35px;
      text-transform: uppercase;
      position: relative;
      margin-left: 10px; }
      #govideo section.video .filter h4.breadcrumb a,
      #govideo section.video .detail h4.breadcrumb a {
        font-size: 12px;
        font-weight: bold;
        line-height: 1.17;
        letter-spacing: 1.3px;
        color: #b3b9c7;
        margin-top: 35px; }
      #govideo section.video .filter h4.breadcrumb:before,
      #govideo section.video .detail h4.breadcrumb:before {
        content: url(https://d3naj63yelf4gk.cloudfront.net/dist/img/line.svg);
        position: absolute;
        left: -60px;
        top: -2.5px;
        margin-right: 0; }
    #govideo section.video .filter .wrapper,
    #govideo section.video .detail .wrapper {
      padding: 0px 27px;
      margin-bottom: 50px; }
      #govideo section.video .filter .wrapper h1,
      #govideo section.video .detail .wrapper h1 {
        object-fit: contain;
        font-family: "Lato", Helvetica, sans-serif;
        font-size: 36px;
        font-weight: 300;
        line-height: 1.28;
        letter-spacing: 2px;
        color: #343b41; }
      #govideo section.video .filter .wrapper .group-wrapper,
      #govideo section.video .detail .wrapper .group-wrapper {
        margin-top: 40px; }
        #govideo section.video .filter .wrapper .group-wrapper .group,
        #govideo section.video .detail .wrapper .group-wrapper .group {
          margin-bottom: 25px; }
          #govideo section.video .filter .wrapper .group-wrapper .group label,
          #govideo section.video .detail .wrapper .group-wrapper .group label {
            font-family: "Open Sans", Helvetica, sans-serif;
            font-size: 14px;
            font-weight: bold;
            line-height: 1.0;
            letter-spacing: 1.5px;
            text-transform: uppercase; }
          #govideo section.video .filter .wrapper .group-wrapper .group p,
          #govideo section.video .detail .wrapper .group-wrapper .group p {
            margin-top: 5px; }
    #govideo section.video .filter h5,
    #govideo section.video .detail h5 {
      object-fit: contain;
      font-family: "Open Sans", Helvetica, sans-serif;
      font-size: 14px;
      line-height: 1.71;
      letter-spacing: 1.2px;
      color: #343b41;
      text-transform: uppercase;
      margin-top: 30px;
      font-weight: 400; }
    #govideo section.video .filter form,
    #govideo section.video .detail form {
      margin-top: -15px;
      margin-bottom: 20px; }
      #govideo section.video .filter form label,
      #govideo section.video .detail form label {
        cursor: pointer; }
        #govideo section.video .filter form label input[type=radio],
        #govideo section.video .detail form label input[type=radio] {
          margin: 10px 0;
          background: transparent;
          width: 20px;
          height: 20px;
          -webkit-appearance: none;
          border-radius: 50%;
          position: relative;
          top: 17.5px;
          margin-right: 5px;
          border: 1px solid #dbdbdb; }
        #govideo section.video .filter form label input[type=radio]:checked,
        #govideo section.video .detail form label input[type=radio]:checked {
          -webkit-appearance: none;
          border: 1px solid #009444;
          outline: none; }
        #govideo section.video .filter form label input[type="radio"]:checked:after,
        #govideo section.video .detail form label input[type="radio"]:checked:after {
          content: '';
          position: absolute;
          width: 10px;
          height: 10px;
          background: #009444;
          border-radius: 100%;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%); }
        #govideo section.video .filter form label span,
        #govideo section.video .detail form label span {
          margin-left: 5px;
          position: relative;
          top: 1.5px; }
  #govideo section.video .participant {
    margin-top: -15px;
    margin-bottom: 50px;
    margin-left: 20px;
    position: relative; }
  #govideo .list.participant {
    transition: all 0.3s ease 0s;
    text-align: left !important;
    background-color: white;
    margin: 25px 10px; }
    #govideo .list.participant .image {
      min-height: 245px;
      position: relative;
      background-size: cover !important;
      background-position: center !important; }
      #govideo .list.participant .image img {
        position: absolute;
        top: 50%;
        left: 50%;
        max-height: 100%;
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%); }
    #govideo .list.participant .text {
      padding: 0px 25px;
      text-align: unset; }
      #govideo .list.participant .text [data-video=category] {
        object-fit: contain;
        font-family: "Open Sans", Helvetica, sans-serif;
        font-size: 12.5px;
        font-weight: bold;
        line-height: 0.93;
        letter-spacing: 1.6px;
        color: #009444; }
      #govideo .list.participant .text [data-video=title] {
        font-family: "Open Sans", Helvetica, sans-serif;
        font-size: 15px;
        font-weight: bold;
        line-height: 1.5;
        text-transform: unset;
        letter-spacing: 0.8px;
        margin-top: -8.5px;
        color: #4a4a4a; }
      #govideo .list.participant .text [data-video=name] {
        font-family: "Open Sans", Helvetica, sans-serif;
        font-size: 13px;
        font-weight: unset;
        margin-top: 0;
        line-height: 1.87;
        letter-spacing: 0.6px;
        color: #babcbf;
        margin-top: -15px;
        opacity: .8; }
    #govideo .list.participant:hover {
      box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.095); }
  #govideo section.participant {
    padding: 20px; }
    #govideo section.participant h4 {
      color: #48af4a;
      text-transform: uppercase; }
      #govideo section.participant h4 .title {
        float: left; }
      #govideo section.participant h4 .show-more {
        color: inherit;
        float: right; }
        #govideo section.participant h4 .show-more svg {
          position: relative;
          top: 2px;
          margin-left: 5px; }
    #govideo section.participant span.separator {
      display: none !important; }
    #govideo section.participant h4.show-more-mobile {
      display: none !important; }
    #govideo section.participant .slick-disabled {
      filter: grayscale(100%); }
    #govideo section.participant .navigation {
      display: none; }
    #govideo section.participant .video-list {
      text-align: center;
      margin-top: 30px; }
  #govideo #content-only {
    padding: 0; }
    #govideo #content-only .container {
      box-shadow: 0 -3em 0 1.5em #343b41; }
      #govideo #content-only .container .info h4 {
        color: #343b41; }
      #govideo #content-only .container .info .line {
        background: #ecb42c; }
  #govideo section.snk-govideo {
    font-family: Open Sans,Helvetica,sans-serif;
    background-color: #f8f8f8;
    margin-top: 80px; }
    #govideo section.snk-govideo .container {
      width: 90%;
      max-width: 1170px;
      padding: 100px 0; }
      #govideo section.snk-govideo .container .option {
        border-top: 3px solid #009444; }
        #govideo section.snk-govideo .container .option h2.title {
          margin: 50px 0;
          font-weight: 300; }
          #govideo section.snk-govideo .container .option h2.title span {
            color: #58c75b; }
        #govideo section.snk-govideo .container .option ul {
          padding-left: 0px;
          margin: 0px;
          list-style-type: none; }
          #govideo section.snk-govideo .container .option ul li {
            font-family: Open Sans,Helvetica,sans-serif;
            position: relative;
            cursor: pointer;
            padding-left: 70px;
            margin-bottom: 30px; }
          #govideo section.snk-govideo .container .option ul li.active::before {
            content: "";
            background: #58c75b;
            position: absolute;
            width: 50px;
            top: 45%;
            left: 0px;
            height: 2px; }
          #govideo section.snk-govideo .container .option ul li.active {
            color: #58c75b; }
      #govideo section.snk-govideo .container .content {
        font-family: Open Sans,Helvetica,sans-serif;
        color: #8c8c8c;
        line-height: 25px;
        display: none; }
        #govideo section.snk-govideo .container .content ol {
          padding-left: 20px;
          margin: 0px;
          line-height: 25px; }
        #govideo section.snk-govideo .container .content a {
          letter-spacing: normal;
          color: #58c75b;
          font-size: inherit; }
        #govideo section.snk-govideo .container .content b {
          color: #2b2b2b; }
        #govideo section.snk-govideo .container .content .penghargaan {
          margin-bottom: 50px; }
          #govideo section.snk-govideo .container .content .penghargaan h4, #govideo section.snk-govideo .container .content .penghargaan p {
            margin: 0px; }
      #govideo section.snk-govideo .container .content.active {
        display: block; }
      #govideo section.snk-govideo .container .back {
        display: none; }
        #govideo section.snk-govideo .container .back h3 {
          text-align: center;
          margin-top: 50px;
          color: #48af4a; }
        #govideo section.snk-govideo .container .back a {
          font-size: inherit; }
  #govideo section.about {
    background-color: #f8f8f8; }
    #govideo section.about .img-wrapper {
      margin-top: -80px; }
    #govideo section.about .container .pure-g .img-wrapper .img-container {
      background-color: transparent; }
    #govideo section.about .img-wrapper .img-container {
      margin-top: 0px;
      width: 100%;
      margin-left: 0px; }
    #govideo section.about .down-photo {
      width: 50%;
      float: left; }
      #govideo section.about .down-photo img {
        width: 100%; }
    #govideo section.about img.pure-img {
      height: auto; }
  #govideo section.section-our-values {
    text-align: center;
    background-color: #FFF; }
    #govideo section.section-our-values .container-arrow-judges {
      display: none; }
      #govideo section.section-our-values .container-arrow-judges svg:first-child {
        float: left; }
      #govideo section.section-our-values .container-arrow-judges svg:last-child {
        float: right; }
    #govideo section.section-our-values .slide-container a {
      font-size: inherit;
      color: inherit; }
      #govideo section.section-our-values .slide-container a svg {
        display: inline-block;
        width: 16px; }
    #govideo section.section-our-values .title {
      color: #4a494a; }
    #govideo section.section-our-values .container-arrow-judges svg.slick-disabled ellipse, #govideo section.section-our-values .container-arrow-judges svg.slick-disabled polygon {
      stroke: #9d9d9d !important; }
    #govideo section.section-our-values .container-arrow-judges svg.slick-disabled polygon {
      fill: #9d9d9d !important; }
    #govideo section.section-our-values .line.gradient {
      background-color: #ECB42C; }
    #govideo section.section-our-values .categories {
      display: inline-block;
      margin-bottom: 100px;
      width: 100%; }
      #govideo section.section-our-values .categories h5.more {
        color: #019447;
        display: none;
        text-transform: uppercase; }
      #govideo section.section-our-values .categories .description-wrapper img {
        width: 85%;
        max-width: 840px;
        margin: 50px auto;
        display: block; }
      #govideo section.section-our-values .categories .category img {
        margin: auto;
        width: 100px;
        height: 100px; }
      #govideo section.section-our-values .categories .category h4 {
        margin-top: 50px;
        text-align: center;
        font-weight: 600;
        line-height: 1.42;
        letter-spacing: 2.2px;
        text-align: center;
        color: #000000; }
    #govideo section.section-our-values .judges .list {
      padding: 30px; }
      #govideo section.section-our-values .judges .list h4 {
        text-align: left;
        float: none;
        text-transform: none; }
        @media screen and (max-width: 768px) {
          #govideo section.section-our-values .judges .list h4 {
            padding: 0px; } }
      #govideo section.section-our-values .judges .list h5 {
        font-family: "Open Sans", Helvetica, sans-serif;
        font-size: 18px;
        font-weight: 300;
        line-height: 1.56;
        letter-spacing: 1.7px;
        color: #485460;
        text-align: left;
        float: none;
        margin-top: -15px; }
      #govideo section.section-our-values .judges .list img {
        max-width: none; }
      #govideo section.section-our-values .judges .list img.pure-img {
        width: 100%; }
  #govideo section.timeline {
    position: relative;
    padding: 100px 0;
    padding-bottom: 150px; }
    #govideo section.timeline .title h4 {
      font-family: "Open Sans", Helvetica, sans-serif;
      background: #009444;
      padding: 30px;
      color: #ffffff;
      line-height: 0.78;
      letter-spacing: 1.9px;
      position: relative;
      padding-left: 50px;
      font-weight: 500;
      text-transform: uppercase; }
    #govideo section.timeline .title h4::before {
      content: "";
      background-color: #ecb42c;
      position: absolute;
      width: 50px;
      height: 4px;
      top: 50%;
      left: -25px; }
    #govideo section.timeline ul {
      list-style: none;
      margin: 0 auto;
      padding: 0;
      margin-top: 100px; }
      #govideo section.timeline ul li {
        width: 25%;
        float: left;
        position: relative; }
        #govideo section.timeline ul li .wrapper {
          height: 180px;
          width: 250px;
          margin: 0 5px;
          padding: 15px 15px; }
          #govideo section.timeline ul li .wrapper div {
            min-height: 200px;
            background: #ffffff;
            padding: 15px;
            border-bottom: 4px solid #dbdbdb; }
            #govideo section.timeline ul li .wrapper div h4 {
              line-height: 1.42;
              letter-spacing: 2.2px;
              text-align: center;
              color: #000000;
              font-weight: 500;
              height: 40px; }
            #govideo section.timeline ul li .wrapper div h4:first-child {
              font-weight: bold; }
      #govideo section.timeline ul li.done .wrapper div {
        border-bottom: 4px solid #009444; }
      #govideo section.timeline ul li.done:before {
        content: "";
        position: absolute;
        font-size: 13px;
        font-weight: 400;
        margin-left: -47px;
        height: 10px;
        width: 10px;
        border-radius: 50%;
        text-align: center;
        line-height: 27px;
        z-index: 1;
        background: #ffffff;
        border: 5px solid #009444; }
      #govideo section.timeline ul li.done:after {
        content: "";
        position: absolute;
        z-index: 0;
        background: #009444;
        width: 100%;
        height: 3px;
        bottom: 0; }
      #govideo section.timeline ul li:before {
        content: "";
        position: absolute;
        font-size: 13px;
        font-weight: 400;
        margin-left: -47px;
        height: 10px;
        width: 10px;
        border-radius: 50%;
        text-align: center;
        line-height: 27px;
        z-index: 1;
        background: #ffffff;
        top: -50px;
        left: 65%;
        border: 5px solid #dbdbdb; }
      #govideo section.timeline ul li:after {
        content: "";
        position: absolute;
        z-index: 0;
        background: #dbdbdb;
        width: 100%;
        height: 3px;
        bottom: 0;
        top: -43.5px;
        left: -50%; }
      #govideo section.timeline ul li:first-child:after {
        content: none; }
    #govideo section.timeline .extra-notes {
      font-weight: 700;
      position: absolute;
      right: 0;
      bottom: 50px;
      text-align: right;
      width: 995px;
      left: 0;
      right: 0;
      margin: auto; }
      @media screen and (max-width: 768px) {
        #govideo section.timeline .extra-notes {
          font-size: 12px;
          width: 100%;
          box-sizing: border-box;
          padding-right: 40px; } }
    @media screen and (max-width: 768px) {
      #govideo section.timeline {
        padding-bottom: 100px; } }
  #govideo section.how-to {
    background-color: #ecb42c; }
    #govideo section.how-to .nav-down.slick-arrow.slick-disabled ellipse, #govideo section.how-to .nav-down.slick-arrow.slick-disabled polygon, #govideo section.how-to .nav-up.slick-arrow.slick-disabled ellipse, #govideo section.how-to .nav-up.slick-arrow.slick-disabled polygon {
      stroke: #8a8a8a !important; }
    #govideo section.how-to .nav-down.slick-arrow.slick-disabled polygon, #govideo section.how-to .nav-up.slick-arrow.slick-disabled polygon {
      fill: #8a8a8a !important; }
    #govideo section.how-to .slick-arrow:not(.slick-disabled) {
      cursor: pointer; }
    #govideo section.how-to .out-wrapper .wrapper {
      padding: 20px 0;
      color: #ffffff; }
      #govideo section.how-to .out-wrapper .wrapper .title h4 {
        letter-spacing: 1.7px;
        font-family: "Open Sans", Helvetica, sans-serif;
        text-transform: uppercase;
        padding: 0 40px;
        font-size: 14px;
        position: relative; }
      #govideo section.how-to .out-wrapper .wrapper .title h4::before {
        content: "";
        background: #ffffff;
        position: absolute;
        width: 50px;
        top: 45%;
        left: -25px;
        height: 4px; }
      #govideo section.how-to .out-wrapper .wrapper .slide {
        padding: 70px 0; }
        #govideo section.how-to .out-wrapper .wrapper .slide .dots-slide {
          margin: auto;
          display: flex;
          position: relative; }
          #govideo section.how-to .out-wrapper .wrapper .slide .dots-slide ul {
            z-index: 999;
            margin: auto;
            padding: 0; }
            #govideo section.how-to .out-wrapper .wrapper .slide .dots-slide ul li {
              float: none;
              display: block;
              margin: auto; }
              #govideo section.how-to .out-wrapper .wrapper .slide .dots-slide ul li button::before {
                color: #ffffff; }
        #govideo section.how-to .out-wrapper .wrapper .slide .image-slide picture {
          width: 200px;
          height: 200px;
          outline: none;
          padding: 10px; }
          #govideo section.how-to .out-wrapper .wrapper .slide .image-slide picture img {
            width: 100%;
            height: 100%;
            margin: auto; }
        #govideo section.how-to .out-wrapper .wrapper .slide .text-slide h3 {
          padding: 30px 0;
          display: flex;
          flex-direction: column;
          justify-content: center;
          font-weight: 500;
          line-height: 1.28;
          letter-spacing: 2px;
          outline: none; }
        #govideo section.how-to .out-wrapper .wrapper .slide .nav-slide {
          display: flex;
          justify-content: center;
          flex-direction: column;
          margin: auto; }
          #govideo section.how-to .out-wrapper .wrapper .slide .nav-slide svg {
            margin: auto;
            margin-bottom: 15px; }
  #govideo .daftar {
    margin: 100px auto; }
    #govideo .daftar .banner {
      position: relative;
      background-size: cover; }
      #govideo .daftar .banner img.background-banner {
        height: 100%; }
      #govideo .daftar .banner .video-image {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        height: 70px;
        width: 70px; }
        #govideo .daftar .banner .video-image img {
          width: 100%; }
    #govideo .daftar fieldset {
      min-width: 0px; }
    #govideo .daftar .form {
      padding: 50px;
      background: white;
      position: relative;
      box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.19); }
      #govideo .daftar .form .title {
        position: relative;
        letter-spacing: 1.5px;
        color: #babcbf;
        font-size: 20px;
        font-weight: 700; }
        #govideo .daftar .form .title p {
          font-weight: 700; }
      #govideo .daftar .form label {
        font-family: "Open Sans", Helvetica, sans-serif;
        margin-bottom: 1em;
        letter-spacing: 1.5px;
        color: #697b8c;
        font-weight: 700;
        margin: 0;
        text-transform: uppercase;
        cursor: pointer; }
      #govideo .daftar .form textarea {
        resize: none;
        width: 100%;
        height: 234px; }
      #govideo .daftar .form input[type=text], #govideo .daftar .form input[type=email], #govideo .daftar .form input[type=number],
      #govideo .daftar .form select,
      #govideo .daftar .form textarea {
        color: #2b2b2b;
        position: relative;
        border: 1px solid #c3c9d7;
        width: 100%;
        border-radius: 0;
        box-shadow: none;
        -webkit-appearance: none; }
      #govideo .daftar .form input[type=number]::-webkit-inner-spin-button,
      #govideo .daftar .form input[type=number]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        margin: 0; }
      #govideo .daftar .form .arrow-select {
        pointer-events: none;
        position: absolute;
        width: 0;
        height: 0;
        border-left: 7px solid transparent;
        border-right: 7px solid transparent;
        border-top: 10px solid #2b2b2b;
        bottom: 0;
        right: 10px;
        top: 40px; }
      #govideo .daftar .form .divident {
        margin-top: 25px; }
      #govideo .daftar .form .poster p:first-child {
        text-align: center; }
      #govideo .daftar .form .poster p {
        color: #babcbf; }
      #govideo .daftar .form .poster .item {
        position: relative;
        border: 1px solid #c3c9d7;
        padding: 5em;
        margin-top: 4px; }
        #govideo .daftar .form .poster .item button {
          display: block;
          margin: auto;
          border-radius: 5px;
          background-color: #ecb42c;
          padding: 1.5em 3em;
          color: white;
          line-height: 1.03;
          letter-spacing: 2.6px;
          border: 2px solid #ecb42c;
          cursor: pointer;
          -webkit-transition: all .3s ease-in-out;
          -moz-transition: all .3s ease-in-out;
          -o-transition: all .3s ease-in-out;
          transition: all .3s ease-in-out; }
        #govideo .daftar .form .poster .item button:hover {
          background: #fff;
          color: #ecb42c; }
        #govideo .daftar .form .poster .item input {
          position: absolute;
          height: 100%;
          width: 100%;
          opacity: 0;
          left: 0;
          top: 0; }
        #govideo .daftar .form .poster .item #img-preview {
          width: 200px;
          margin: 0 auto 30px;
          display: block;
          border-radius: 5px; }
      #govideo .daftar .form .checkbox {
        text-align: left; }
        #govideo .daftar .form .checkbox .cb {
          opacity: 0;
          position: absolute; }
        #govideo .daftar .form .checkbox .cb,
        #govideo .daftar .form .checkbox .cb-label {
          display: inline-block;
          vertical-align: middle;
          margin: 5px;
          cursor: pointer; }
        #govideo .daftar .form .checkbox .cb-label {
          position: relative; }
        #govideo .daftar .form .checkbox .cb + .cb-label:before {
          content: '';
          background: #fff;
          border: 2px solid #ddd;
          display: inline-block;
          vertical-align: middle;
          width: 15px;
          height: 15px;
          padding: 2px;
          margin-right: 10px;
          text-align: center; }
        #govideo .daftar .form .checkbox .cb:checked + .cb-label:before {
          content: "\f00c";
          font-size: 14px;
          font-family: 'FontAwesome';
          background: white;
          color: #009444; }
        #govideo .daftar .form .checkbox label {
          display: inline-block;
          letter-spacing: 1px;
          color: #343b41;
          font-weight: 500; }
        #govideo .daftar .form .checkbox span {
          letter-spacing: 0.7px;
          color: #118534;
          font-weight: 700; }
        #govideo .daftar .form .checkbox a.link {
          font-size: inherit; }
        #govideo .daftar .form .checkbox a.link:hover span {
          text-decoration: underline; }
      #govideo .daftar .form .submit button {
        border-radius: 5px;
        background: #333333;
        color: #ffffff;
        float: right;
        position: absolute;
        bottom: -50px;
        right: 50px;
        padding: 35px 100px;
        z-index: 99;
        -webkit-transition: all .3s ease-in-out;
        -moz-transition: all .3s ease-in-out;
        -o-transition: all .3s ease-in-out;
        transition: all .3s ease-in-out; }
      #govideo .daftar .form .submit button:focus {
        outline: none; }
      #govideo .daftar .form .submit button:hover {
        box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.19);
        background-color: #009444; }
  #govideo .video {
    margin: 100px auto;
    padding: 0; }
    #govideo .video .link--more {
      -webkit-transition: all .3s ease-in-out;
      -moz-transition: all .3s ease-in-out;
      -o-transition: all .3s ease-in-out;
      transition: all .3s ease-in-out; }
    #govideo .video .link--more:hover {
      background-color: #99ca5d; }
    #govideo .video .title {
      background: none; }
      #govideo .video .title h4 {
        margin: 0;
        padding: 30px;
        background: white;
        position: relative;
        line-height: 0.78;
        letter-spacing: 1.9px;
        color: #4a4a4a; }
      #govideo .video .title h4:before {
        content: "";
        background: #f7dd89;
        position: absolute;
        height: 4px;
        width: 50px;
        left: -30px;
        top: 45%; }
    #govideo .video .title-right {
      background: none; }
      #govideo .video .title-right h4 {
        text-align: right;
        margin: 0;
        padding: 30px;
        background: #ecb42c;
        position: relative;
        line-height: 0.78;
        letter-spacing: 1.9px;
        color: white; }
    #govideo .video .img-wrapper {
      margin: 0 !important;
      background: white; }
      #govideo .video .img-wrapper .img-container {
        padding: 0;
        padding-bottom: 0; }
        #govideo .video .img-wrapper .img-container .img-container-2 {
          overflow: hidden;
          max-height: 718px;
          position: relative;
          margin: 0; }
          #govideo .video .img-wrapper .img-container .img-container-2 .play {
            margin: auto;
            -webkit-transition: all .3s ease-in-out;
            -moz-transition: all .3s ease-in-out;
            -o-transition: all .3s ease-in-out;
            transition: all .3s ease-in-out; }
          #govideo .video .img-wrapper .img-container .img-container-2 .play:hover {
            background-color: #99ca5d; }
          #govideo .video .img-wrapper .img-container .img-container-2 .container-youtube {
            position: absolute;
            height: 100%;
            width: 100%;
            left: 0;
            top: 0;
            background-color: black;
            z-index: 2;
            opacity: 0;
            pointer-events: none;
            transition: all 0.5s; }
            #govideo .video .img-wrapper .img-container .img-container-2 .container-youtube .video-close {
              z-index: 3;
              position: absolute;
              color: white;
              font-size: 200%;
              cursor: pointer;
              width: 60px;
              height: 60px;
              top: 0.5em;
              right: 0.5em;
              background: url(../img/card/about/close-btn.png) 50% center/29px 29px no-repeat #48af4a;
              border-radius: 50%; }
            #govideo .video .img-wrapper .img-container .img-container-2 .container-youtube iframe {
              position: absolute;
              height: 100%;
              width: 100%;
              left: 0;
              top: 0;
              z-index: 2;
              transition: all 0.5s; }
          #govideo .video .img-wrapper .img-container .img-container-2 .container-youtube.active {
            pointer-events: visible;
            opacity: 1;
            transition: all 0.5; }
  #govideo section.how-it-works--wrapper .rect--secondary {
    background: #dbdbdb; }
  #govideo section.how-it-works--wrapper .mobile--wrapper {
    padding-top: 100px;
    margin-top: -100px; }
  #govideo section.how-it-works--wrapper .rect--primary {
    background: #009444; }
  #govideo section.how-it-works--wrapper .section--title {
    padding-left: 10px;
    margin-bottom: 50px;
    font-size: 14px;
    color: #b3b9c7; }
    #govideo section.how-it-works--wrapper .section--title a {
      font-size: inherit;
      color: inherit; }
  #govideo section.how-it-works--wrapper figure.img--wrapper {
    height: 82%;
    overflow: hidden; }
    #govideo section.how-it-works--wrapper figure.img--wrapper img {
      height: auto;
      top: -20%;
      background: none; }
  #govideo section.how-it-works--wrapper .layer .even--row .desc--pad {
    padding: 25px; }
  #govideo section.how-it-works--wrapper .layer .odd--row .desc--pad {
    padding-right: 0px;
    padding: 25px; }
  #govideo section.how-it-works--wrapper .ok-video-down {
    text-align: center;
    border: 1px solid #ececec;
    background: white; }
    #govideo section.how-it-works--wrapper .ok-video-down img {
      left: 0;
      height: 100% !important;
      top: 0% !important;
      right: 0;
      margin: auto;
      width: 100%; }
  #govideo section.how-it-works--wrapper.hadiah-govideo figure.img--wrapper {
    width: 100%;
    height: 100%; }
    #govideo section.how-it-works--wrapper.hadiah-govideo figure.img--wrapper img {
      width: 60%;
      bottom: 0;
      top: 0;
      left: 0;
      right: 0;
      margin: auto;
      height: 100%;
      height: auto; }
  #govideo section.how-it-works--wrapper.hadiah-govideo .even--row, #govideo section.how-it-works--wrapper.hadiah-govideo .odd--row {
    min-height: 330px; }
    #govideo section.how-it-works--wrapper.hadiah-govideo .even--row .pure-u-1, #govideo section.how-it-works--wrapper.hadiah-govideo .odd--row .pure-u-1 {
      position: relative; }

@media screen and (max-width: 768px) {
  #govideo header h1.hero--title.second {
    display: none; }
  #govideo section.video .participant {
    margin-top: 20px;
    margin-bottom: 50px;
    margin-left: 20px;
    position: relative;
    left: -15px; } }

@media screen and (min-width: 768px) {
  #govideo .right-up-photo {
    height: 292px;
    overflow: hidden; }
  #govideo .table-switch {
    display: table; }
  #govideo .table-switch > div:first-child {
    display: table-footer-group; }
  #govideo section.section-our-values .judges-desc button.float {
    display: none; } }

@media screen and (max-width: 768px) {
  #govideo header .desc--wrapper {
    left: 0%;
    min-height: unset;
    padding-left: 0px;
    text-align: center; }
    #govideo header .desc--wrapper .desc--row {
      right: 0;
      left: 0;
      bottom: 0 !important; }
      #govideo header .desc--wrapper .desc--row .sub-desc--row {
        vertical-align: bottom; }
      #govideo header .desc--wrapper .desc--row h1.hero--title {
        line-height: normal;
        max-width: 300px;
        letter-spacing: 2px;
        font-size: 12px;
        display: block;
        margin: auto; }
        #govideo header .desc--wrapper .desc--row h1.hero--title div.bottom {
          margin-top: 0px; }
      #govideo header .desc--wrapper .desc--row .sub-desc--row {
        padding-bottom: 10%; }
  #govideo .container-arrow-judges {
    display: block !important; }
  #govideo .slide-container {
    width: 100%; }
  #govideo section.how-it-works--wrapper .container {
    width: 100%; }
  #govideo section.how-it-works--wrapper .layer {
    padding-top: 30px;
    margin-top: 60px; }
    #govideo section.how-it-works--wrapper .layer .section--title {
      letter-spacing: normal;
      left: -15px;
      line-height: normal; }
    #govideo section.how-it-works--wrapper .layer .desc--pad {
      margin-top: 0px; }
      #govideo section.how-it-works--wrapper .layer .desc--pad h4 {
        line-height: normal; }
    #govideo section.how-it-works--wrapper .layer .rect {
      width: 50%; }
    #govideo section.how-it-works--wrapper .layer .odd--row .rect {
      right: 0;
      float: right; }
      #govideo section.how-it-works--wrapper .layer .odd--row .rect figure {
        left: -100%; }
  #govideo section.how-it-works--wrapper figure {
    width: 200%;
    top: 10%;
    position: relative; }
    #govideo section.how-it-works--wrapper figure img {
      position: relative; }
  #govideo section.how-it-works--wrapper .ok-video-down {
    background: white; }
    #govideo section.how-it-works--wrapper .ok-video-down img {
      top: -15% !important;
      height: auto !important;
      max-width: 100%; }
  #govideo section.how-it-works--wrapper.hadiah-govideo figure {
    top: 0px; }
  #govideo section.how-it-works--wrapper.hadiah-govideo figure.img--wrapper {
    text-align: center;
    position: relative;
    margin: 0;
    width: 100%;
    position: relative;
    right: 0; }
    #govideo section.how-it-works--wrapper.hadiah-govideo figure.img--wrapper img {
      width: 80%;
      max-width: 200px;
      position: relative; }
  #govideo section.how-it-works--wrapper.hadiah-govideo .desc--pad {
    margin-top: 0px; }
  #govideo section.snk-govideo {
    margin: 0px;
    margin-top: 85px;
    padding: 0px !important; }
    #govideo section.snk-govideo .container {
      padding: 50px 0; }
      #govideo section.snk-govideo .container .option {
        width: 75%; }
      #govideo section.snk-govideo .container h2.title {
        margin: 30px 0 !important;
        font-size: 30px; }
    #govideo section.snk-govideo .content h4 {
      font-size: 16px;
      line-height: normal;
      margin-bottom: 5px !important; }
  #govideo section.timeline ul li .wrapper div {
    min-height: inherit; }
  #govideo section.section-our-values {
    width: 100%;
    padding: 30px; }
    #govideo section.section-our-values h4 {
      font-size: 14px; }
    #govideo section.section-our-values h5.more {
      display: inline-block !important; }
    #govideo section.section-our-values .categories {
      margin-bottom: 30px; }
  #govideo .judges .title, #govideo .categories .title {
    position: relative;
    padding-left: 30px;
    text-align: left; }
  #govideo .judges-desc {
    position: relative;
    height: 150px; }
    #govideo .judges-desc h4 {
      font-size: 16px !important; }
    #govideo .judges-desc h5 {
      font-size: 14px !important; }
    #govideo .judges-desc button.float {
      border-radius: 4px 0px 0 4px;
      right: 0;
      padding: 15px 40px;
      top: -20px;
      position: absolute;
      font-size: 12px;
      font-weight: 300;
      color: white;
      background-color: #009444; }
    #govideo .judges-desc button.float::after {
      content: url(https://d3naj63yelf4gk.cloudfront.net/dist/img/header/arrow.png);
      position: absolute;
      padding-left: 10px;
      top: 12px;
      right: 10px;
      transform: scale(0.7);
      width: 20px; }
  #govideo .daftar .form .poster p:first-child {
    display: block;
    text-align: center; }
    #govideo .daftar .form .poster p:first-child span {
      display: none; }
  #govideo .daftar .form .poster .item {
    position: relative;
    padding: 0px; }
    #govideo .daftar .form .poster .item button {
      padding: 15px 20px;
      margin: 10px auto; }
    #govideo .daftar .form .poster .item #img-preview {
      margin: 10px auto; }
  #govideo .daftar .form .checkbox {
    text-align: center; }
  #govideo .daftar .form .submit button {
    padding: 25px 100px; }
  #govideo .judges .title::before, #govideo .categories .title:before {
    content: "";
    position: absolute;
    height: 4px;
    width: 50px;
    left: -30px;
    top: 45%;
    background: #f7dd89; }
  #govideo .line.gradient {
    display: none; } }

@media screen and (max-width: 35.5em) {
  #govideo .cat-desktop {
    display: none !important; }
  #govideo .cat-mobile {
    display: block; }
  #govideo header h1.hero--title {
    position: relative;
    top: 65px; }
  #govideo header h1.hero--title.second {
    font-size: 12px !important;
    margin-top: 150px !important; }
  #govideo header a {
    position: relative;
    top: 40px; }
  #govideo section.video .participant {
    margin-top: 25px;
    position: relative;
    left: -7.5px; }
    #govideo section.video .participant .text [data-video=name] {
      margin-top: 0;
      position: relative;
      top: -18.5px; }
  #govideo section.video.detail .wrapper {
    padding: 0; }
  #govideo section.services [data-video=title] {
    margin-top: -10.5px !important; }
  #govideo section.services [data-video=name] {
    margin-top: -20px !important; }
  #govideo section.services h4.title {
    margin-left: 20px;
    margin-bottom: 35px; }
    #govideo section.services h4.title:before {
      position: absolute;
      left: -65px;
      top: -5px;
      margin-right: -10px; }
  #govideo section.participant {
    padding: 30px 0; }
    #govideo section.participant h4.main-title {
      text-align: center;
      object-fit: contain;
      font-family: "Open Sans", Helvetica, sans-serif;
      font-size: 16px;
      font-weight: bold;
      letter-spacing: 2.5px;
      text-align: center;
      color: #4a4a4a; }
      #govideo section.participant h4.main-title .title {
        float: none !important; }
    #govideo section.participant h4.show-more-mobile {
      display: block !important;
      z-index: 12;
      text-align: center;
      font-size: 21px;
      font-weight: bold;
      letter-spacing: 4px;
      position: relative;
      top: -70px;
      margin-bottom: -85px; }
      #govideo section.participant h4.show-more-mobile a {
        color: #009444; }
    #govideo section.participant span.separator {
      display: block;
      width: 50px;
      height: 4px;
      margin: 0 auto;
      background-color: #ebb329; }
    #govideo section.participant .navigation {
      display: block;
      margin: 20px 0;
      z-index: 1; }
      #govideo section.participant .navigation #prevArrow {
        float: right; }
    #govideo section.participant a.show-more {
      display: none; }
  #govideo .daftar .form .submit {
    text-align: center; }
    #govideo .daftar .form .submit button {
      position: relative;
      bottom: auto;
      left: 0;
      right: 0;
      float: none;
      margin-top: 30px; }
  #govideo .judges .list {
    padding: 0px !important;
    margin: 0px;
    box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.19); } }
  @media screen and (max-width: 35.5em) and (max-width: 768px) {
    #govideo .judges .list {
      box-shadow: none; } }

@media screen and (max-width: 35.5em) {
  #govideo .judges .judges-desc {
    padding: 20px; }
  #govideo .img-wrapper {
    margin-top: 0px !important; }
    #govideo .img-wrapper .img-container .img-container-2 {
      margin: auto; }
  #govideo section.how-to .out-wrapper .wrapper .slide {
    max-width: 300px;
    margin: auto; }
  #govideo section.snk-govideo .back {
    display: block !important; }
  #govideo section.about {
    width: 100% !important; }
    #govideo section.about h4 {
      padding-left: 0px; }
    #govideo section.about .container:before {
      background-color: transparent !important; }
    #govideo section.about a {
      font-size: inherit; }
  #govideo section.about.top {
    margin: auto; }
  #govideo .daftar {
    background-color: white;
    margin: 0;
    padding: 0; }
    #govideo .daftar .banner {
      display: none; }
    #govideo .daftar .form {
      box-shadow: none;
      width: 90%;
      margin: 20px auto;
      padding: 0px; }
      #govideo .daftar .form .title {
        padding-left: 30px;
        color: #4a494a; }
        #govideo .daftar .form .title i {
          display: none; }
      #govideo .daftar .form .title::before {
        content: "";
        position: absolute;
        height: 4px;
        width: 50px;
        left: -30px;
        top: 45%;
        background: #f7dd89; }
  #govideo section.timeline ul li.done:after {
    left: -22px !important; }
  #govideo section.how-to .title {
    text-align: center; }
    #govideo section.how-to .title h4::before {
      top: 30px !important;
      left: 0;
      margin: auto;
      right: 0; }
  #govideo section.how-to .text-slide h3 {
    font-size: 28px;
    font-weight: 300 !important; }
  #govideo section.how-to .slide {
    padding-bottom: 0;
    position: relative; }
    #govideo section.how-to .slide .dots-slide {
      position: absolute !important;
      top: 35%;
      text-align: center; }
      #govideo section.how-to .slide .dots-slide .slick-dots {
        display: inline-block !important;
        margin: auto; }
      #govideo section.how-to .slide .dots-slide ul {
        display: inherit; }
        #govideo section.how-to .slide .dots-slide ul li {
          display: inline-block !important;
          margin: 0px !important;
          /*float: left !important;*/ }
    #govideo section.how-to .slide .image-slide {
      width: 50% !important;
      margin: auto;
      margin-top: 50%; }
      #govideo section.how-to .slide .image-slide img {
        height: 50%; }
    #govideo section.how-to .slide .text-slide {
      position: absolute;
      text-align: center;
      top: 0;
      left: 0; }
    #govideo section.how-to .slide .nav-slide {
      position: absolute;
      top: 60%;
      display: inline-block !important;
      left: 0; }
      #govideo section.how-to .slide .nav-slide svg {
        display: inline-block !important;
        margin: 0 !important;
        transform: rotate(-90deg) !important; }
      #govideo section.how-to .slide .nav-slide .nav-down {
        float: right; } }

@media screen and (max-width: 1024px) {
  #govideo section.timeline ul {
    margin: 20px auto 0 auto; }
    #govideo section.timeline ul li {
      margin-left: 5%;
      margin-bottom: 0px;
      float: none; }
      #govideo section.timeline ul li .wrapper {
        height: 100%;
        padding: 10px 0px; }
        #govideo section.timeline ul li .wrapper h4 {
          margin: 0;
          height: auto !important; }
    #govideo section.timeline ul li.done:after {
      height: 100%;
      width: 3px; }
    #govideo section.timeline ul li:before {
      left: 25%;
      top: 35%; }
    #govideo section.timeline ul li:after {
      height: 100%;
      width: 3px;
      left: -35%;
      top: -65%; } }

@media screen and (max-width: 320px) {
  #govideo header h1.hero--title.second {
    margin-top: 110px !important; } }

#gosend .section-join-form {
  margin-top: 50px;
  padding: 80px; }
  #gosend .section-join-form .main-wrapper {
    height: 720px;
    width: 100%;
    box-shadow: 0px 0px 50px rgba(90, 90, 90, 0.25); }
  #gosend .section-join-form .main-wrapper .background-wrapper {
    background: url("https://d3naj63yelf4gk.cloudfront.net/dist/img/page/gosend/texture-img.png");
    min-height: 720px !important;
    width: 100%;
    padding: 0;
    text-align: center; }
  #gosend .section-join-form .main-wrapper .background-wrapper > img {
    max-width: 60px;
    margin-top: 280px; }
  #gosend .section-join-form .main-wrapper .form-wrapper {
    padding: 50px; }
  #gosend .section-join-form .main-wrapper .form-wrapper .title {
    margin-top: -15px;
    font-size: 12px;
    position: relative;
    top: 10px;
    color: #c8c8c8; }
  #gosend .section-join-form .main-wrapper .form-wrapper .group {
    width: 100%;
    margin-top: 30px;
    margin-bottom: 30px; }
  #gosend .section-join-form .main-wrapper .form-wrapper .group label {
    font-family: "Open Sans", sans-serif;
    font-weight: 700;
    font-size: 12.5px;
    color: #697b8c; }
  #gosend .section-join-form .main-wrapper .form-wrapper .group input,
  #gosend .section-join-form .main-wrapper .form-wrapper .group select {
    width: 100%;
    height: 45px;
    padding: 15px;
    margin-top: 5px;
    letter-spacing: 1.2px;
    font-size: 13.4px;
    background: white;
    border-radius: 0 !important;
    border: 1px solid #d2d2d2;
    color: #485460; }
  #gosend .section-join-form .error_messages {
    color: red;
    margin-top: 10px; }
  #gosend .section-join-form .main-wrapper .form-wrapper .group input[type="checkbox"] {
    width: 10%;
    position: relative;
    margin-bottom: -20px;
    margin-top: -10px;
    display: inline-block; }
  #gosend .section-join-form .main-wrapper .form-wrapper .group .checkbox-label {
    font-family: "Open Sans", sans-serif;
    font-size: 12.5px;
    margin-top: -10px !important;
    position: relative;
    top: -16px;
    margin-bottom: 20px; }
  #gosend .section-join-form .main-wrapper .button-submit {
    background: #8BC440;
    z-index: 100;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.45);
    position: absolute;
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
    top: 810px;
    right: 170px;
    padding: 24px;
    transition: all 0.5s ease 0s;
    color: white;
    border-radius: 8px; }
  #gosend .section-join-form .main-wrapper .button-submit:hover {
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.65); }
  #gosend .section-join-form .main-wrapper .button-submit > span {
    margin-left: 50px;
    margin-right: 50px; }

#gosend .section-our-values {
  background: white; }
  #gosend .section-our-values .additional-text {
    color: #9b9b9b;
    font-weight: 300;
    font-size: 14px;
    line-height: 1.71; }
  #gosend .section-our-values .list h4 {
    text-transform: none; }
  #gosend .section-our-values .list p {
    font-size: 14px;
    font-weight: 300;
    color: #9b9b9b;
    line-height: 1.71;
    letter-spacing: 1px; }

@media only screen and (max-width: 768px) {
  #gosend .section-join-form {
    padding: 0;
    margin: 0;
    margin-top: 80px; }
    #gosend .section-join-form .main-wrapper {
      height: auto;
      width: 100%;
      box-shadow: 0px 0px 50px rgba(90, 90, 90, 0.25); }
    #gosend .section-join-form .main-wrapper .form-wrapper .title {
      margin-top: -15px;
      font-size: 12px;
      position: relative;
      top: 10px;
      color: #c8c8c8;
      text-align: center; }
    #gosend .section-join-form .main-wrapper .background-wrapper {
      background: url("https://d3naj63yelf4gk.cloudfront.net/dist/img/page/gosend/texture-img.png");
      min-height: 130px !important;
      width: 100%;
      padding: 0;
      text-align: center; }
    #gosend .section-join-form .main-wrapper .background-wrapper > img {
      max-width: 60px;
      margin-top: 30px; }
    #gosend .section-join-form .error_messages {
      color: red;
      margin-top: 10px;
      letter-spacing: 1px; }
    #gosend .section-join-form .main-wrapper .form-wrapper {
      background: #fff;
      padding: 25px !important; }
    #gosend .section-join-form .main-wrapper .form-wrapper .group label {
      font-family: Open Sans,sans-serif;
      font-weight: 700;
      font-size: 12.5px;
      color: #697b8c;
      letter-spacing: .9px; }
    #gosend .section-join-form .main-wrapper .form-wrapper .group .checkbox-label {
      letter-spacing: 1px;
      margin-left: 10px; }
    #gosend .section-join-form .main-wrapper .button-submit {
      background: #8BC440;
      z-index: 100;
      padding: 25px;
      top: 25px;
      font-size: 14px;
      left: -15px;
      box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.45);
      position: relative;
      transition: all 0.5s ease 0s;
      color: white;
      border-radius: 8px; } }

@media only screen and (max-width: 480px) {
  #gosend .section-join-form {
    padding: 0;
    margin: 0;
    margin-top: 50px; }
    #gosend .section-join-form .main-wrapper {
      height: auto;
      width: 100%;
      box-shadow: 0px 0px 50px rgba(90, 90, 90, 0.25); }
    #gosend .section-join-form .main-wrapper .form-wrapper .title {
      margin-top: -15px;
      font-size: 12px;
      position: relative;
      top: 10px;
      color: #c8c8c8;
      text-align: center; }
    #gosend .section-join-form .main-wrapper .background-wrapper {
      background: url("https://d3naj63yelf4gk.cloudfront.net/dist/img/page/gosend/texture-img.png");
      min-height: 130px !important;
      width: 100%;
      padding: 0;
      text-align: center; }
    #gosend .section-join-form .main-wrapper .background-wrapper > img {
      max-width: 60px;
      margin-top: 30px; }
    #gosend .section-join-form .error_messages {
      color: red;
      margin-top: 10px;
      letter-spacing: 1px; }
    #gosend .section-join-form .main-wrapper .form-wrapper {
      background: #fff;
      padding: 25px !important; }
    #gosend .section-join-form .main-wrapper .form-wrapper .group label {
      font-family: Open Sans,sans-serif;
      font-weight: 700;
      font-size: 12.5px;
      color: #697b8c;
      letter-spacing: .9px; }
    #gosend .section-join-form .main-wrapper .form-wrapper .group .checkbox-label {
      letter-spacing: 1px;
      margin-left: 10px; }
    #gosend .section-join-form .main-wrapper .button-submit {
      background: #8BC440;
      z-index: 100;
      padding: 25px;
      top: 25px;
      font-size: 14px;
      left: -15px;
      box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.45);
      position: relative;
      transition: all 0.5s ease 0s;
      color: white;
      border-radius: 8px; }
  #gosend .section-our-values .title {
    color: #48af4a;
    text-align: center;
    text-transform: uppercase;
    font-size: 17px;
    line-height: 25px;
    margin-top: 20px; }
  #gosend .section-our-values .list h4 {
    font-size: 15.5px; }
  #gosend .section-our-values .additional-text {
    padding: 15px; } }

@media only screen and (max-width: 414px) {
  #gosend .section-join-form {
    padding: 0;
    margin: 0;
    margin-top: 100px; } }

@media only screen and (max-width: 375px) {
  #gosend .section-join-form {
    padding: 0;
    margin: 0;
    margin-top: 140px;
    margin-bottom: 40px; }
    #gosend .section-join-form .container {
      padding: 10px; }
  #gosend .section-our-values .title {
    color: #48af4a;
    text-align: center;
    text-transform: uppercase;
    font-size: 17px;
    line-height: 25px;
    padding: 20px; } }

@media only screen and (max-width: 320px) {
  #gosend .section-join-form {
    padding: 0;
    margin: 0;
    margin-top: 140px;
    margin-bottom: 40px; }
    #gosend .section-join-form .container {
      padding: 10px; } }

#popup_box {
  display: none;
  position: fixed;
  _position: absolute;
  width: 600px;
  background: #FFFFFF;
  left: 400px;
  top: 150px;
  z-index: 100;
  margin-left: 15px;
  padding: 35px;
  font-size: 15px;
  -moz-box-shadow: 0 0 5px #ff0000;
  -webkit-box-shadow: 0 0 5px #ff0000;
  box-shadow: 0 0px 30px 0px rgba(140, 140, 140, 0.85); }
  #popup_box h3 {
    margin: 0;
    padding: 0; }
  #popup_box h5 {
    font-weight: 300;
    font-size: 14px;
    margin-top: 15px;
    line-height: 20px;
    margin-bottom: 35px; }

#popupBoxClose {
  font-size: 15px;
  padding: 10px;
  cursor: pointer;
  background: #8BC440;
  letter-spacing: 2px;
  position: relative;
  color: white;
  top: -5px;
  font-weight: 500; }

@media only screen and (max-width: 480px) {
  #popup_box {
    display: none;
    position: fixed;
    _position: absolute;
    width: 100%;
    background: #FFFFFF;
    left: -15px;
    top: 150px;
    z-index: 100;
    margin-left: 15px;
    padding: 35px;
    font-size: 15px;
    -moz-box-shadow: 0 0 5px #ff0000;
    -webkit-box-shadow: 0 0 5px #ff0000;
    box-shadow: 0 0px 30px 0px rgba(140, 140, 140, 0.85); } }

#gosend-larismanis .navbar {
  position: fixed !important;
  min-height: 30px !important;
  margin-bottom: 0px !important;
  border: 0px solid transparent !important; }

#gosend-larismanis header#main--hero.larismanis {
  background-image: url("https://d3naj63yelf4gk.cloudfront.net/dist/img/page/gosend/larismanis/header-new.jpg?ver=12"); }

#gosend-larismanis .navbar .btn.btn-style-1 {
  height: 34.25px !important; }

#gosend-larismanis .link {
  color: #48af4a !important;
  text-decoration: none !important;
  font-weight: 600;
  letter-spacing: 1px;
  cursor: pointer;
  font-size: 12.75px; }

#gosend-larismanis #gojek-brand-desktop {
  display: inline-block !important; }

#gosend-larismanis #gojek-brand-mobile {
  display: none !important; }

#gosend-larismanis .s-and-k-section-mobile {
  display: none; }

#gosend-larismanis .s-dan-k {
  position: relative;
  left: 80px;
  font-weight: 300;
  margin-top: -16px; }

#gosend-larismanis .s-dan-k.small {
  display: none; }

#gosend-larismanis .s-dan-k-checkbox {
  position: relative;
  left: 50px; }

#gosend-larismanis ul.icon-wrapper {
  list-style-type: none;
  margin: 0;
  position: relative;
  top: 320px;
  left: -15px; }
  #gosend-larismanis ul.icon-wrapper li {
    margin-bottom: 25px; }
    #gosend-larismanis ul.icon-wrapper li img.gosend-icon {
      max-width: 50px; }
    #gosend-larismanis ul.icon-wrapper li img.go-icon {
      min-width: 45px !important; }

#gosend-larismanis #join-now a {
  background: #48af4a; }

#gosend-larismanis #join-now .bg-layer {
  position: relative;
  background-color: #48af4a; }

#gosend-larismanis #content-only.thank-you {
  margin-top: 100px; }
  #gosend-larismanis #content-only.thank-you .container {
    background: white;
    box-shadow: 0 -3em 0 1.5em #343b41; }

#gosend-larismanis #promo-banner .detail {
  top: 160px; }

#gosend-larismanis #promo-banner .detail .custom-nav .slick-disabled ellipse {
  stroke: #48af4a !important; }

#gosend-larismanis #promo-banner .detail .custom-nav .slick-disabled polygon {
  fill: #48af4a !important; }

#gosend-larismanis #promo-banner .detail .custom-nav .slick-disabled .img-slide img {
  margin-top: 5px; }

#gosend-larismanis .as-seen-on #content img {
  width: 100%;
  max-height: 100%;
  width: auto;
  padding: 2em;
  filter: grayscale(0%) !important;
  opacity: 1.0; }

#gosend-larismanis .about .weekly-promo-title,
#gosend-larismanis .about .description-title {
  object-fit: contain;
  font-family: "Lato", Helvetica, sans-serif;
  font-size: 24px;
  font-weight: 300;
  line-height: 1.42;
  letter-spacing: 1.3px;
  color: #343b41; }

#gosend-larismanis .how-it-works--wrapper .section--title {
  color: #48af4a; }
  #gosend-larismanis .how-it-works--wrapper .section--title:before {
    background-color: #48af4a; }

#gosend-larismanis .how-it-works--wrapper .layer {
  padding: 30px 60px 10px; }
  #gosend-larismanis .how-it-works--wrapper .layer .rect--primary {
    background: #48af4a; }
  #gosend-larismanis .how-it-works--wrapper .layer .rect--secondary {
    background: #48af4a !important; }

#gosend-larismanis .how-it-works--wrapper .btn {
  background-color: #252525 !important;
  color: white !important;
  font-size: 12px;
  padding: 20px 50px;
  margin: 20px 0; }

#gosend-larismanis .how-it-works--wrapper .hor--desc {
  object-fit: contain;
  font-family: "Open Sans", Helvetica, sans-serif;
  font-size: 14px;
  font-weight: 300;
  line-height: 1.86;
  letter-spacing: 1.3px;
  color: #485460; }

#gosend-larismanis .section-join-form.larismanis {
  margin-top: 50px;
  padding: 80px; }
  #gosend-larismanis .section-join-form.larismanis .main-wrapper {
    height: 940px;
    width: 100%;
    box-shadow: 0px 0px 50px rgba(90, 90, 90, 0.25); }
  #gosend-larismanis .section-join-form.larismanis .main-wrapper .background-wrapper {
    background: url("https://d3naj63yelf4gk.cloudfront.net/dist/img/page/gosend/texture-img-gojek.jpg?ver=1");
    min-height: 940px !important;
    width: 100%;
    padding: 0;
    text-align: center; }
  #gosend-larismanis .section-join-form.larismanis .main-wrapper .background-wrapper > img {
    max-width: 60px;
    margin-top: 280px; }
  #gosend-larismanis .section-join-form.larismanis .main-wrapper .form-wrapper {
    padding: 50px; }
  #gosend-larismanis .section-join-form.larismanis .main-wrapper .form-wrapper .title {
    margin-top: -15px;
    font-size: 12px;
    position: relative;
    top: 10px;
    color: #c8c8c8; }
  #gosend-larismanis .section-join-form.larismanis .main-wrapper .form-wrapper .group {
    width: 100%;
    margin-top: 30px;
    margin-bottom: 30px; }
  #gosend-larismanis .section-join-form.larismanis .main-wrapper .form-wrapper .group label {
    font-family: "Open Sans", sans-serif;
    font-weight: 700;
    font-size: 12.5px;
    color: #697b8c; }
  #gosend-larismanis .section-join-form.larismanis .main-wrapper .form-wrapper .group input,
  #gosend-larismanis .section-join-form.larismanis .main-wrapper .form-wrapper .group select {
    width: 100%;
    height: 45px;
    padding: 15px;
    margin-top: 5px;
    letter-spacing: 1.2px;
    font-size: 13.4px;
    background: white;
    border-radius: 0 !important;
    border: 1px solid #d2d2d2;
    color: #485460; }
  #gosend-larismanis .section-join-form.larismanis .error_messages {
    color: red;
    margin-top: 10px; }
  #gosend-larismanis .section-join-form.larismanis .main-wrapper .form-wrapper .group input[type="checkbox"] {
    width: 10%;
    position: relative;
    margin-bottom: -20px;
    margin-top: -10px;
    display: inline-block; }
  #gosend-larismanis .section-join-form.larismanis .main-wrapper .form-wrapper .group .checkbox-label {
    font-family: "Open Sans", sans-serif;
    font-size: 12.5px;
    margin-top: -10px !important;
    position: relative;
    top: -16px;
    margin-bottom: 20px; }
  #gosend-larismanis .section-join-form.larismanis .main-wrapper .button-submit {
    background: #48af4a;
    z-index: 100;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.45);
    font-family: Open Sans,sans-serif;
    font-weight: 400;
    right: 170px;
    padding: 24px;
    transition: all .5s ease 0s;
    color: #fff;
    float: right;
    position: absolute;
    right: 0;
    margin-top: 340px;
    border-radius: 8px; }
  #gosend-larismanis .section-join-form.larismanis .main-wrapper .button-submit:hover {
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.65); }
  #gosend-larismanis .section-join-form.larismanis .main-wrapper .button-submit > span {
    margin-left: 50px;
    margin-right: 50px; }

#gosend-larismanis ol.list--item {
  font-family: "Open Sans", Helvetica, sans-serif;
  color: #485460;
  padding: 0 20px;
  font-weight: 300;
  line-height: 1.62;
  letter-spacing: 1.5px; }

#gosend-larismanis .section-our-values {
  background: white; }
  #gosend-larismanis .section-our-values .additional-text {
    color: #9b9b9b;
    font-weight: 300;
    font-size: 14px;
    line-height: 1.71; }
  #gosend-larismanis .section-our-values .list h4 {
    text-transform: none; }
  #gosend-larismanis .section-our-values .list p {
    font-size: 14px;
    font-weight: 300;
    color: #9b9b9b;
    line-height: 1.71;
    letter-spacing: 1px; }

@media (min-width: 1024px) and (max-width: 1280px) {
  #gosend-larismanis header#main--hero.larismanis {
    background-image: url("https://d3naj63yelf4gk.cloudfront.net/dist/img/page/gosend/larismanis/header-new.jpg?ver=12");
    background-position-x: 0;
    background-size: contain;
    margin-top: 0; }
  #gosend-larismanis .how-it-works--wrapper .container .how--title {
    margin-left: 70px; }
  #gosend-larismanis .how-it-works--wrapper .container ol.list--item {
    position: relative;
    left: 50px; } }

@media (min-width: 769px) and (max-width: 1024px) {
  #gosend-larismanis .navbar .menu {
    display: none; }
  #gosend-larismanis .navbar .button-wrapper {
    display: none; }
  #gosend-larismanis .navbar #gojek-brand-desktop {
    display: none !important; }
  #gosend-larismanis .navbar #gojek-brand-mobile {
    display: block !important; }
  #gosend-larismanis .navbar .hamburger {
    display: none; }
  #gosend-larismanis header#main--hero.larismanis {
    background-size: contain !important;
    margin-top: 0; }
  #gosend-larismanis .s-and-k-section-mobile {
    display: block;
    background: white;
    padding: 10px; }
    #gosend-larismanis .s-and-k-section-mobile .wrapper {
      margin-top: 45px;
      margin-bottom: 45px; }
      #gosend-larismanis .s-and-k-section-mobile .wrapper .syarat-ketentuan-img {
        margin-top: 40px; }
  #gosend-larismanis .how-it-works--wrapper {
    display: none; }
  #gosend-larismanis .section-join-form.larismanis {
    padding: 0;
    margin: 0;
    margin-top: 50px; }
    #gosend-larismanis .section-join-form.larismanis .icon-wrapper {
      display: inline;
      position: relative;
      top: 5px !important; }
      #gosend-larismanis .section-join-form.larismanis .icon-wrapper li {
        display: inline;
        margin: 0 20px; }
        #gosend-larismanis .section-join-form.larismanis .icon-wrapper li .gosend-icon {
          max-width: 50px !important;
          position: relative;
          top: 5px; }
    #gosend-larismanis .section-join-form.larismanis .s-dan-k-checkbox {
      position: relative;
      top: -20px;
      left: 30px; }
    #gosend-larismanis .section-join-form.larismanis .s-dan-k {
      position: relative;
      left: 30px;
      font-weight: 300;
      margin-top: -16px; }
    #gosend-larismanis .section-join-form.larismanis .error_messages {
      color: red;
      margin-top: 10px;
      letter-spacing: 1px; }
    #gosend-larismanis .section-join-form.larismanis .main-wrapper {
      margin-top: 50px;
      height: auto;
      width: 100%;
      box-shadow: 0 0 50px rgba(90, 90, 90, 0.25); }
      #gosend-larismanis .section-join-form.larismanis .main-wrapper .form-wrapper .title {
        margin-top: -15px;
        font-size: 12px;
        position: relative;
        top: 10px;
        color: #c8c8c8;
        text-align: center; }
      #gosend-larismanis .section-join-form.larismanis .main-wrapper .background-wrapper {
        background: url("https://d3naj63yelf4gk.cloudfront.net/dist/img/page/gosend/texture-img-gojek.jpg?ver=1");
        min-height: 130px !important;
        width: 100%;
        padding: 0;
        text-align: center; }
        #gosend-larismanis .section-join-form.larismanis .main-wrapper .background-wrapper img {
          max-width: 60px;
          margin-top: 30px; }
      #gosend-larismanis .section-join-form.larismanis .main-wrapper .form-wrapper {
        padding: 25px !important; }
        #gosend-larismanis .section-join-form.larismanis .main-wrapper .form-wrapper .group label {
          font-family: Open Sans, sans-serif;
          font-weight: 700;
          font-size: 12.5px;
          color: #697b8c;
          letter-spacing: .9px; }
        #gosend-larismanis .section-join-form.larismanis .main-wrapper .form-wrapper .group .checkbox-label {
          letter-spacing: 1px;
          margin-left: 10px; }
      #gosend-larismanis .section-join-form.larismanis .main-wrapper .button-submit {
        background: #48af4a;
        z-index: 100;
        padding: 25px;
        top: 25px;
        font-size: 14px;
        left: -15px;
        box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.45);
        position: relative;
        transition: all .5s ease 0s;
        color: #fff;
        border-radius: 8px; } }

@media only screen and (max-width: 768px) {
  #gosend-larismanis header#main--hero.larismanis {
    background-size: cover !important; } }

@media only screen and (max-width: 480px) {
  #gosend-larismanis header#main--hero.larismanis {
    background-size: contain !important;
    margin-top: 25px;
    margin-bottom: -100px; } }

@media only screen and (max-width: 480px) and (max-width: 480px) {
  #gosend-larismanis #promo-banner h4 {
    line-height: 18px; } }

@media only screen and (max-width: 480px) {
  #gosend-larismanis .as-seen-on #content {
    padding: 0; }
  #gosend-larismanis .as-seen-on p {
    font-size: 1.125em;
    position: relative;
    top: -7.5px; } }

@media only screen and (max-width: 480px) and (max-width: 768px) {
  #gosend-larismanis .as-seen-on p {
    position: relative;
    top: 15px; } }

@media only screen and (max-width: 480px) {
  #gosend-larismanis .s-and-k-section-mobile {
    display: block;
    background: white;
    padding: 10px; }
    #gosend-larismanis .s-and-k-section-mobile .wrapper {
      margin-top: 45px;
      margin-bottom: 45px; }
      #gosend-larismanis .s-and-k-section-mobile .wrapper .syarat-ketentuan-img {
        margin-top: 40px; }
  #gosend-larismanis .how-it-works--wrapper {
    display: none; }
  #gosend-larismanis .section-join-form.larismanis {
    padding: 0;
    margin: 0;
    margin-top: 50px; }
    #gosend-larismanis .section-join-form.larismanis .error_messages {
      color: red;
      margin-top: 10px;
      letter-spacing: 1px; }
    #gosend-larismanis .section-join-form.larismanis .main-wrapper {
      margin-top: 50px;
      height: auto;
      width: 100%;
      box-shadow: 0 0 50px rgba(90, 90, 90, 0.25); }
      #gosend-larismanis .section-join-form.larismanis .main-wrapper .form-wrapper .title {
        margin-top: -15px;
        font-size: 12px;
        position: relative;
        top: 10px;
        color: #c8c8c8;
        text-align: center; }
      #gosend-larismanis .section-join-form.larismanis .main-wrapper .background-wrapper {
        background: url("https://d3naj63yelf4gk.cloudfront.net/dist/img/page/gosend/texture-img-gojek.jpg?ver=1");
        min-height: 130px !important;
        width: 100%;
        padding: 0;
        text-align: center; }
        #gosend-larismanis .section-join-form.larismanis .main-wrapper .background-wrapper img {
          max-width: 60px;
          margin-top: 30px; }
      #gosend-larismanis .section-join-form.larismanis .main-wrapper .form-wrapper {
        padding: 25px !important; }
        #gosend-larismanis .section-join-form.larismanis .main-wrapper .form-wrapper .group label {
          font-family: Open Sans, sans-serif;
          font-weight: 700;
          font-size: 12.5px;
          color: #697b8c;
          letter-spacing: .9px; }
        #gosend-larismanis .section-join-form.larismanis .main-wrapper .form-wrapper .group .checkbox-label {
          letter-spacing: 1px;
          margin-left: 10px; }
      #gosend-larismanis .section-join-form.larismanis .main-wrapper .button-submit {
        background: #48af4a;
        z-index: 100;
        padding: 25px;
        top: 5px;
        font-size: 14px;
        left: -15px;
        box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.45);
        position: relative;
        transition: all .5s ease 0s;
        color: #fff;
        border-radius: 8px; } }

@media only screen and (max-width: 320px) {
  #gosend-larismanis .s-dan-k.small {
    display: inline-block;
    position: relative;
    left: 20px !important; }
  #gosend-larismanis .s-dan-k {
    position: relative;
    font-size: 11px;
    left: 80px;
    display: none;
    font-weight: 300;
    margin-top: -16px; }
    #gosend-larismanis .s-dan-k .link {
      font-size: 11px; } }

#popup_box {
  display: none;
  position: fixed;
  _position: absolute;
  width: 600px;
  background: #FFFFFF;
  left: 400px;
  top: 150px;
  z-index: 100;
  margin-left: 15px;
  padding: 35px;
  font-size: 15px;
  -moz-box-shadow: 0 0 5px #ff0000;
  -webkit-box-shadow: 0 0 5px #ff0000;
  box-shadow: 0 0px 30px 0px rgba(140, 140, 140, 0.85); }
  #popup_box h3 {
    margin: 0;
    padding: 0; }
  #popup_box h5 {
    font-weight: 300;
    font-size: 14px;
    margin-top: 15px;
    line-height: 20px;
    margin-bottom: 35px; }

#popupBoxClose {
  font-size: 15px;
  padding: 10px;
  cursor: pointer;
  background: #48af4a;
  letter-spacing: 2px;
  position: relative;
  color: white;
  top: -5px;
  font-weight: 500; }

@media only screen and (max-width: 480px) {
  #popup_box {
    display: none;
    position: fixed;
    _position: absolute;
    width: 100%;
    background: #FFFFFF;
    left: -15px;
    top: 150px;
    z-index: 100;
    margin-left: 15px;
    padding: 35px;
    font-size: 15px;
    -moz-box-shadow: 0 0 5px #ff0000;
    -webkit-box-shadow: 0 0 5px #ff0000;
    box-shadow: 0 0px 30px 0px rgba(140, 140, 140, 0.85); } }

body#karir {
  /* Loader */
  /* Blog Page */
  /* submenu */
  /* Media Global */
  /* Blog Page */ }
  body#karir .table-display {
    display: table;
    height: 100%; }
    body#karir .table-display .middle-table {
      display: table-cell;
      vertical-align: middle; }
    @media screen and (max-width: 35.5em) {
      body#karir .table-display h4.title--content {
        margin: 0px;
        margin-top: 15px;
        top: 0;
        margin-left: 5px;
        position: absolute;
        font-size: 14px; } }
  @media screen and (max-width: 35.5em) {
    body#karir section.about {
      padding-bottom: 0px; } }
  @media screen and (max-width: 35.5em) {
    body#karir #join-now {
      margin-bottom: 50px; } }
  body#karir .description {
    position: relative; }
    @media screen and (max-width: 35.5em) {
      body#karir .description {
        position: static; } }
  @media screen and (max-width: 35.5em) {
    body#karir .container-desc-home-career {
      margin-top: 50px; } }
  body#karir .hamburger-mobile {
    top: 23px; }

@-webkit-keyframes uil-ring-anim {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

@-moz-keyframes uil-ring-anim {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

@-webkit-keyframes uil-ring-anim {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

@-o-keyframes uil-ring-anim {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

@keyframes uil-ring-anim {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }
  body#karir .section-our-values {
    padding: 80px 0px;
    background: #f8f8f8; }
    body#karir .section-our-values .title {
      color: inherit; }
    body#karir .section-our-values .list h4 {
      height: 25px; }
    body#karir .section-our-values .list img {
      max-height: 100px;
      max-width: 250px;
      display: block; }
    @media screen and (max-width: 35.5em) {
      body#karir .section-our-values {
        padding-bottom: 0px; } }
  body#karir .loader--animate {
    background: none;
    position: relative;
    width: 200px;
    height: 200px;
    margin: 0 auto;
    display: block;
    transform: scale(0.5); }
  body#karir .loader--animate > div {
    position: absolute;
    display: block;
    width: 160px;
    height: 160px;
    top: 20px;
    left: 20px;
    border-radius: 80px;
    box-shadow: 0 3px 0 0 #48af4a;
    -webkit-transform-origin: 80px 81.5px;
    transform-origin: 80px 81.5px;
    -webkit-animation: uil-ring-anim 1s linear infinite;
    animation: uil-ring-anim 1s linear infinite; }
  body#karir .load--more {
    display: none; }
  body#karir #landing-onboarding {
    margin: 20px 0;
    padding: 0; }
    body#karir #landing-onboarding .load--more {
      margin: 0 auto 100px;
      width: 250px; }
  body#karir #onboarding-head {
    margin-top: 125px; }
    body#karir #onboarding-head .bg-img {
      background-image: url("https://bilba.go-jek.com/dist/img/career/blog.jpg") !important;
      background-size: cover;
      box-shadow: inset 0px 0px 300px 200px rgba(72, 175, 74, 0.56); }
    @media screen and (max-width: 767px) {
      body#karir #onboarding-head {
        background-image: url("https://bilba.go-jek.com/dist/img/career/blog.jpg") !important;
        background-size: cover;
        box-shadow: inset 0px 0px 300px 200px rgba(72, 175, 74, 0.56); } }
  body#karir #onboarding-head .onboarding-nav,
  body#karir .blog-list .header-line .blog-nav {
    width: auto; }
  body#karir .blog-list .header-line {
    text-align: center;
    position: relative; }
  body#karir .blog-list .header-line .cat-list li {
    width: 270px; }
  @media screen and (min-width: 769px) {
    body#karir .blog-list .header-line .blog-nav {
      display: inline-block; } }
  body#karir header.career {
    margin-top: 120px; }
  body#karir header.sub {
    background: #2c822e;
    height: 83px;
    margin-top: 125px; }
  body#karir .pure-hidden-sm {
    text-transform: capitalize; }
  body#karir .search--wrapper {
    background: #fff;
    border: 2px solid #48af4a;
    border-radius: 5px;
    z-index: 10;
    position: relative; }
  body#karir .sub .search--wrapper {
    margin-top: -35px; }
  body#karir #submit--button {
    background: #fff;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px; }
  body#karir .search input,
  body#karir .search select,
  body#karir .search .select2-selection,
  body#karir .search .select2-container {
    height: 100%;
    width: 100% !important;
    border-radius: 0;
    outline: none;
    border: 0; }
  body#karir .search .select2-container--default .select2-selection--single .select2-selection__rendered,
  body#karir .search #job-search {
    font-family: 'Open Sans', sans-serif;
    font-size: 20px;
    padding: 0 20px; }
  body#karir .search #city-search,
  body#karir .search #job-search {
    border-left: 2px solid #48af4a;
    height: 100%;
    display: none; }
  body#karir .search .select2-selection {
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px; }
  body#karir .search .select2-selection__arrow {
    display: none; }
  body#karir .search .select2-selection:after {
    content: "\f078";
    top: 0;
    margin-right: 1em;
    justify-content: center;
    display: flex;
    flex-direction: column;
    text-align: center;
    color: #48af4a;
    font-family: FontAwesome;
    font-style: normal;
    font-weight: normal;
    text-decoration: inherit;
    font-size: 24px;
    height: 100%;
    position: absolute;
    right: 0;
    z-index: 1; }
  body#karir .search .pure-u-12-24 {
    border-left: solid 1.5px #48af4a; }
  body#karir .search input::-webkit-input-placeholder {
    color: #000000; }
  body#karir .search select {
    color: #000000;
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 1px;
    text-overflow: ''; }
  body#karir .search .job-select {
    position: relative; }
  body#karir .search button {
    background-color: #48af4a;
    color: #ffffff;
    width: 100%;
    padding: 20px 0;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    margin: 5px; }
  body#karir #department .bg-img {
    background-image: url("https://bilba.go-jek.com/dist/img/career/departement.jpg");
    background-size: cover;
    box-shadow: inset 0px 0px 300px 200px rgba(72, 175, 74, 0.56); }
  @media screen and (max-width: 767px) {
    body#karir #department header#faq-head {
      background-image: url("https://bilba.go-jek.com/dist/img/career/departement.jpg");
      background-size: cover;
      box-shadow: inset 0px 0px 300px 200px rgba(72, 175, 74, 0.56); } }
  body#karir .how-it-works--wrapper .container {
    padding: 60px 0 0; }
  body#karir section.job-list .title {
    text-align: center;
    font-family: "Open Sans", Helvetica, sans-serif;
    font-weight: bold;
    letter-spacing: 2px;
    color: #48af4a; }
    body#karir section.job-list .title h2 {
      margin: 45px 0; }
      @media screen and (max-width: 35.5em) {
        body#karir section.job-list .title h2 {
          margin-top: 0px; } }
  body#karir section.job-list table {
    margin: 4em auto 8em;
    width: 100%;
    border: none; }
    body#karir section.job-list table thead {
      background: none; }
      body#karir section.job-list table thead th {
        border: none;
        color: #485460;
        font-weight: bold;
        letter-spacing: 1px;
        padding-bottom: 1.5em; }
    body#karir section.job-list table tbody tr:nth-child(odd) {
      background: #ffffff; }
    body#karir section.job-list table tbody tr:nth-child(even) {
      background: #f8f8f8; }
    body#karir section.job-list table tbody td {
      border: none;
      padding: 0 1em; }
      body#karir section.job-list table tbody td p {
        letter-spacing: normal;
        font-size: 18px; }
      body#karir section.job-list table tbody td a {
        color: #48af4a;
        letter-spacing: normal;
        font-size: 18px; }
  body#karir .career-home header a {
    padding: 2.5em 3.5em;
    background: #8cc63f;
    letter-spacing: 3px;
    color: #ffffff;
    display: inline-block; }
  body#karir .career-home .how-it-works--wrapper {
    margin: 0; }
    body#karir .career-home .how-it-works--wrapper .container .how--title h2:after {
      display: none; }
  body#karir .career-home #join-now .right p:first-child {
    display: none; }
  body#karir .career-home #join-now .right h2,
  body#karir .career-home #join-now .right p {
    color: #ffffff;
    font-weight: 300; }
  body#karir .career-home #join-now .right a {
    bottom: -3.5em;
    right: 3.5em;
    padding: 2em 5em;
    letter-spacing: 3px;
    font-family: "Open Sans", Helvetica, sans-serif; }
  @media screen and (max-width: 35.5em) {
    body#karir .career-home .pure-g {
      border-width: 1.2px; }
    body#karir .career-home .title {
      font-size: 14px;
      margin: 0 0 20px 0; }
    body#karir .career-home .line {
      margin-bottom: 20px; }
    body#karir .career-home #join-now .right a {
      padding: 25px; } }
  @media screen and (max-width: 35.5em) {
    body#karir #landing-faq .container {
      margin-top: 25px; }
      body#karir #landing-faq .container .box {
        width: 120%; } }
  body#karir #landing-faq .container .super-bottom {
    margin-top: 3em; }
  body#karir #landing-faq .container .out-warp .top {
    background: #8cc63f; }
  body#karir #landing-faq .container .out-warp .bottom {
    background: #8cc63f; }
  body#karir #landing-faq .container .out-warp .warper {
    background: #48af4a; }
    body#karir #landing-faq .container .out-warp .warper .box h3 {
      font-family: "Lato", Helvetica, sans-serif;
      font-weight: 300;
      color: #000000;
      margin-bottom: 15px;
      margin-top: 0px; }
    body#karir #landing-faq .container .out-warp .warper .box p {
      font-family: "Open Sans", Helvetica, sans-serif;
      color: #000000; }
  body#karir #landing-faq .container .out-warp .bottom .box,
  body#karir #landing-faq .container .out-warp .top .box {
    padding-top: 3em; }
  body#karir #detail-list .bg-img {
    background-image: url("https://bilba.go-jek.com/dist/img/career/category.jpg"); }
  body#karir #detail-list header a {
    color: #48af4a;
    font-weight: 500;
    text-decoration: none; }
  @media screen and (max-width: 35.5em) {
    body#karir #detail-list header a {
      color: #ffffff; } }
  @media screen and (max-width: 767px) {
    body#karir #detail-list header {
      background-image: url("https://bilba.go-jek.com/dist/img/career/category.jpg");
      background-size: cover;
      box-shadow: inset 0px 0px 300px 200px rgba(72, 175, 74, 0.56); } }
  body#karir #detail {
    margin-top: 10em; }
    body#karir #detail .job-detail {
      margin: 3em auto; }
      body#karir #detail .job-detail .job-desc {
        margin-right: 32px;
        border-top: 8px solid #48af4a;
        background: #ffffff;
        padding: 50px 30px; }
        body#karir #detail .job-detail .job-desc .breadcrumb,
        body#karir #detail .job-detail .job-desc a {
          font-weight: bold;
          letter-spacing: 1px;
          color: #b3b9c7;
          text-decoration: none;
          text-transform: uppercase;
          font-size: 14px; }
        body#karir #detail .job-detail .job-desc h2 {
          letter-spacing: 2px;
          color: #343b41;
          font-family: "Lato", Helvetica, sans-serif;
          margin-bottom: 2em;
          font-weight: 300;
          letter-spacing: 2px; }
        body#karir #detail .job-detail .job-desc h4 {
          letter-spacing: 1px;
          color: #485460;
          font-weight: bold;
          font-family: "Open Sans", Helvetica, sans-serif; }
        body#karir #detail .job-detail .job-desc p {
          color: #000000;
          font-family: "Open Sans", Helvetica, sans-serif;
          font-size: 1em;
          font-weight: 500; }
        body#karir #detail .job-detail .job-desc ul {
          padding: 0 20px; }
          body#karir #detail .job-detail .job-desc ul li {
            line-height: 1.61;
            font-family: "Open Sans", Helvetica, sans-serif;
            color: #000000; }
      body#karir #detail .job-detail .aside-panel .apply-panel {
        padding: 3em 1.5em;
        margin-bottom: 1.5em;
        background: #ffffff; }
        body#karir #detail .job-detail .aside-panel .apply-panel ul {
          padding: 0;
          margin: 0; }
          body#karir #detail .job-detail .aside-panel .apply-panel ul li:first-child {
            margin-top: 0 !important; }
          body#karir #detail .job-detail .aside-panel .apply-panel ul li {
            list-style: none;
            margin: 2em 0; }
            body#karir #detail .job-detail .aside-panel .apply-panel ul li .fa {
              color: #48af4a;
              width: 25px; }
            body#karir #detail .job-detail .aside-panel .apply-panel ul li span {
              font-size: 14px; }
        body#karir #detail .job-detail .aside-panel .apply-panel a {
          padding: 30px;
          border-radius: 6px;
          background-color: #48af4a;
          display: block;
          text-decoration: none;
          color: #ffffff;
          text-align: center;
          width: 100%; }
        body#karir #detail .job-detail .aside-panel .apply-panel p {
          color: #000000;
          font-family: "Open Sans", Helvetica, sans-serif;
          font-weight: 500; }
          body#karir #detail .job-detail .aside-panel .apply-panel p img {
            height: 1em; }
      body#karir #detail .job-detail .aside-panel .related-job-panel {
        background: #ffffff;
        color: #48af4a;
        padding: 3em 1.5em; }
        body#karir #detail .job-detail .aside-panel .related-job-panel h4 {
          font-weight: bold;
          position: relative; }
        body#karir #detail .job-detail .aside-panel .related-job-panel h4:after {
          content: "";
          height: 4px;
          background-image: radial-gradient(circle at 0 50%, #8cc63f, #48af4a);
          position: absolute;
          width: 50px;
          left: 0;
          margin-top: 2em; }
        body#karir #detail .job-detail .aside-panel .related-job-panel ul {
          padding: 0;
          margin: 40px 0px; }
          body#karir #detail .job-detail .aside-panel .related-job-panel ul li {
            list-style: none;
            margin: 12px 0; }
            body#karir #detail .job-detail .aside-panel .related-job-panel ul li a {
              text-decoration: none;
              color: #48af4a;
              letter-spacing: 2px; }
    @media screen and (max-width: 48em) {
      body#karir #detail {
        margin-top: 5em; }
        body#karir #detail .job-detail .job-desc {
          margin: 0; }
          body#karir #detail .job-detail .job-desc .breadcrumb,
          body#karir #detail .job-detail .job-desc .breadcrumb a {
            font-size: 11px; }
          body#karir #detail .job-detail .job-desc h4 {
            font-size: 1em; }
          body#karir #detail .job-detail .job-desc ul li {
            line-height: 1.38; }
        body#karir #detail .aside-panel {
          margin: 1.5em 0; }
          body#karir #detail .aside-panel a {
            width: inherit !important;
            font-weight: bold;
            letter-spacing: 3px; } }
  body#karir .enginering header .bg-img {
    background-image: url("https://bilba.go-jek.com/dist/img/career/engineering_header.jpg") !important;
    background-size: cover;
    box-shadow: inset 0px 0px 300px 200px rgba(72, 175, 74, 0.56); }
  body#karir .enginering h2.title-eng {
    margin-bottom: 5px; }
    @media screen and (max-width: 35.5em) {
      body#karir .enginering h2.title-eng {
        font-size: 24px; } }
  body#karir .enginering h4.sub-title {
    font-weight: normal;
    margin-top: 0px; }
    @media screen and (max-width: 35.5em) {
      body#karir .enginering h4.sub-title {
        font-size: 16px; } }
  body#karir .enginering .how-it-works--wrapper {
    margin: 0; }
    body#karir .enginering .how-it-works--wrapper .container .how--title h2:after {
      display: none; }
    body#karir .enginering .how-it-works--wrapper .link {
      line-height: .8;
      letter-spacing: 3.3px;
      color: #48af4a;
      font-weight: 700; }
  body#karir .enginering #join-now .right p:first-child {
    display: none; }
  body#karir .enginering #join-now .right h2,
  body#karir .enginering #join-now .right p {
    color: #ffffff;
    font-weight: 300; }
  body#karir .enginering #join-now .right a {
    bottom: -3.5em;
    right: 3.5em;
    padding: 2em 5em;
    letter-spacing: 3px;
    font-family: "Open Sans", Helvetica, sans-serif; }
  body#karir .enginering section.about {
    background: #f8f8f8; }
    @media screen and (max-width: 768px) {
      body#karir .enginering section.about {
        padding-bottom: 0px; }
        body#karir .enginering section.about .container h4 {
          margin-top: 10px;
          margin-bottom: 20px;
          font-size: 14px; }
        body#karir .enginering section.about .img-wrapper .img-container {
          margin-bottom: 30px; } }
  body#karir .enginering section.engineering {
    margin-bottom: 17em;
    margin-top: 1em;
    padding: 0;
    background: #f8f8f8; }
    @media screen and (max-width: 35.5em) {
      body#karir .enginering section.engineering {
        margin-bottom: 14em; } }
    body#karir .enginering section.engineering h2.title {
      text-transform: uppercase;
      margin: 35px 0;
      text-align: center;
      color: #48af4a; }
      @media screen and (max-width: 35.5em) {
        body#karir .enginering section.engineering h2.title {
          margin: 20px 0; } }
    body#karir .enginering section.engineering .container {
      position: relative; }
    body#karir .enginering section.engineering .wrapper {
      width: 100%;
      margin: 0;
      padding: 0px 15px;
      background-color: #fff;
      position: relative; }
      body#karir .enginering section.engineering .wrapper img.service-logo {
        position: absolute;
        top: 25px;
        right: 25px;
        width: 100px; }
      body#karir .enginering section.engineering .wrapper .service-container {
        height: 9em;
        background: #48af4a;
        margin: 0 -15px;
        margin-right: -20%; }
        body#karir .enginering section.engineering .wrapper .service-container .service-list {
          background-color: #fff;
          padding: 0 25px 25px;
          box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.11);
          margin: 50px 15px;
          width: 300px; }
          body#karir .enginering section.engineering .wrapper .service-container .service-list.slick-slide:focus {
            outline: none; }
          body#karir .enginering section.engineering .wrapper .service-container .service-list p {
            padding: 0;
            height: 120px;
            margin-bottom: 25px; }
          body#karir .enginering section.engineering .wrapper .service-container .service-list .img-container {
            position: relative;
            width: auto;
            right: auto;
            top: auto;
            background-color: #FEFEFE;
            margin-top: -25px; }
            body#karir .enginering section.engineering .wrapper .service-container .service-list .img-container img {
              position: absolute;
              top: 0;
              bottom: 0;
              left: 0;
              right: 0;
              margin: auto; }
          body#karir .enginering section.engineering .wrapper .service-container .service-list .bg-services {
            background-position: center center;
            background-size: cover;
            height: 8em;
            opacity: .16; }
          body#karir .enginering section.engineering .wrapper .service-container .service-list a {
            color: #48af4a;
            position: relative;
            text-transform: uppercase; }
        body#karir .enginering section.engineering .wrapper .service-container .slick-list,
        body#karir .enginering section.engineering .wrapper .service-container .slick-track {
          margin-left: -25px !important; }
      body#karir .enginering section.engineering .wrapper .navigation-arrow {
        background: white;
        margin: 0; }
        body#karir .enginering section.engineering .wrapper .navigation-arrow .left,
        body#karir .enginering section.engineering .wrapper .navigation-arrow .right {
          cursor: pointer;
          display: inline-block;
          width: 42px;
          height: 42px; }
          body#karir .enginering section.engineering .wrapper .navigation-arrow .left svg,
          body#karir .enginering section.engineering .wrapper .navigation-arrow .right svg {
            width: 100%; }
          body#karir .enginering section.engineering .wrapper .navigation-arrow .left :focus,
          body#karir .enginering section.engineering .wrapper .navigation-arrow .right :focus {
            background: none;
            outline: 0; }
        body#karir .enginering section.engineering .wrapper .navigation-arrow .right {
          float: right; }
        body#karir .enginering section.engineering .wrapper .navigation-arrow .slick-disabled ellipse {
          stroke: #D2D2D2 !important; }
        body#karir .enginering section.engineering .wrapper .navigation-arrow .slick-disabled polygon {
          fill: #D2D2D2 !important; }
    body#karir .enginering section.engineering h4 {
      font-weight: bold;
      color: #000000;
      padding: 0;
      margin: 30px 0 10px;
      height: 50px; }
    body#karir .enginering section.engineering p {
      font-weight: 500;
      color: #485460;
      padding-bottom: 3em;
      margin-top: 0; }
    body#karir .enginering section.engineering a {
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #48af4a; }
  @media screen and (max-width: 35.5em) {
    body#karir .enginering header {
      background-image: url(https://bilba.go-jek.com/dist/img/career/engineering_header.jpg) !important;
      background-size: cover;
      box-shadow: inset 0px 0px 300px 200px rgba(72, 175, 74, 0.56); }
    body#karir .enginering .pure-g {
      border-width: 1.2px; }
    body#karir .enginering .title {
      font-size: 14px;
      margin-bottom: 0px; }
    body#karir .enginering #join-now .right a {
      padding: 25px; }
    body#karir .enginering .navigation-arrow {
      display: none; }
    body#karir .enginering .wrapper .service-container .service-list {
      width: 200px !important; }
    body#karir .enginering h4 {
      line-height: 1.25; } }
  body#karir .search-page .job--result {
    display: none; }
  body#karir .search-page .loading {
    text-align: center; }
  body#karir .search-page .job-list {
    margin-bottom: 50px; }
    body#karir .search-page .job-list .not-found {
      margin-top: 30px; }
      body#karir .search-page .job-list .not-found img {
        display: block;
        margin: 0 auto; }
      body#karir .search-page .job-list .not-found .text {
        margin-left: 150px;
        margin-right: 150px;
        text-align: center;
        border-radius: 8px;
        background-color: #ffffff;
        padding: 15px;
        margin-bottom: 50px;
        letter-spacing: 2px;
        color: #343b41; }
      body#karir .search-page .job-list .not-found h5 {
        font-weight: 500; }
    body#karir .search-page .job-list h2 {
      line-height: normal;
      letter-spacing: 2px;
      color: #343b41;
      font-family: "Lato", Helvetica, sans-serif; }
      body#karir .search-page .job-list h2 span {
        font-weight: bold;
        color: #2c822e; }
    body#karir .search-page .job-list table {
      width: 100%;
      border: none; }
      body#karir .search-page .job-list table thead {
        background: none; }
        body#karir .search-page .job-list table thead th {
          border: none;
          color: #485460;
          font-weight: bold;
          letter-spacing: 1px;
          padding-bottom: 1.5em; }
      body#karir .search-page .job-list table tbody tr:nth-child(odd) {
        background: #ffffff; }
      body#karir .search-page .job-list table tbody tr:nth-child(even) {
        background: #f8f8f8; }
      body#karir .search-page .job-list table tbody td {
        border: none;
        padding: 0 1em; }
        body#karir .search-page .job-list table tbody td a {
          color: #48af4a; }
    @media screen and (max-width: 35.5em) {
      body#karir .search-page .job-list .not-found .text {
        margin-left: 0px;
        margin-right: 0px;
        margin-bottom: 10px; }
      body#karir .search-page .job-list .not-found img {
        max-width: 200px; } }
  body#karir #main--hero {
    margin-top: 125px; }
  body#karir #faq-head {
    margin-top: 125px; }
  @media screen and (min-width: 48em) {
    body#karir section.engineering {
      padding-bottom: 12em; }
    body#karir section.engineering .img-wrapper .img-container {
      margin-top: 40%; }
    body#karir section.engineering .img-wrapper {
      display: block; }
    body#karir section.engineering a:after {
      top: 6px;
      width: 30px;
      height: 30px;
      background-size: 20px; }
    body#karir section.engineering .container:before {
      padding: 0; }
    body#karir section.engineering .wrapper {
      width: 100%; }
    body#karir section.engineering .wrapper img.service-logo {
      top: 2.5em;
      right: 0;
      left: 0;
      margin-left: auto;
      margin-right: auto; }
    body#karir section.engineering#golife .wrapper .navigation-arrow,
    body#karir section.engineering .wrapper .navigation-arrow {
      padding: 0;
      right: 2.5em;
      top: 2.5em;
      position: absolute; }
      body#karir section.engineering#golife .wrapper .navigation-arrow .left,
      body#karir section.engineering#golife .wrapper .navigation-arrow .right,
      body#karir section.engineering .wrapper .navigation-arrow .left,
      body#karir section.engineering .wrapper .navigation-arrow .right {
        float: none; }
      body#karir section.engineering#golife .wrapper .navigation-arrow .left,
      body#karir section.engineering .wrapper .navigation-arrow .left {
        padding-right: 5px; }
      body#karir section.engineering#golife .wrapper .navigation-arrow .right,
      body#karir section.engineering .wrapper .navigation-arrow .right {
        padding-left: 5px; } }
  @media screen and (min-width: 64em) {
    body#karir section.services#golife {
      padding-bottom: 17em; }
    body#karir section.services .wrapper .service-container .service-list {
      width: 17em; } }
  @media screen and (min-width: 1440px) {
    body#karir section.services .wrapper .service-container {
      margin-right: -50%; } }
  @media screen and (max-width: 35.5em) {
    body#karir header.sub {
      margin-top: 80px; }
    body#karir .pure-hidden-sm {
      display: none; }
    body#karir .search {
      padding: 10px 0; }
      body#karir .search .select2-selection:after {
        font-size: 8px; }
      body#karir .search .select2-selection__rendered {
        padding: 8px;
        font-size: 12px !important; }
      body#karir .search #city-search {
        padding: 0 !important; }
      body#karir .search #job-search,
      body#karir .search #city-search {
        font-size: 12px;
        padding: 0 5px;
        border-width: 1.2px; }
      body#karir .search button {
        padding: 38.5px 0;
        font-size: 8px; }
      body#karir .search .select2-container {
        padding: 0; }
      body#karir .search .select2-selection {
        padding: 0; } }
  @media screen and (max-width: 48em) {
    body#karir #onboarding-head {
      margin-top: 60px; }
    body#karir #faq-head {
      margin-top: 60px; }
    body#karir #main--hero {
      margin-top: 60px; }
    body#karir .sub-menu {
      display: block; }
    body#karir .navbar {
      min-height: unset;
      height: 60px; }
      body#karir .navbar .brand img {
        width: 100% !important;
        max-width: 30px; }
      body#karir .navbar .wrapper {
        padding: 12px 0;
        height: auto; }
      body#karir .navbar .hamburger {
        top: 13px; }
    body#karir .career-home header .hero--title {
      font-size: 35px !important; }
    body#karir .navbar.navbar-active.no--active {
      top: 0px; } }
  @media screen and (max-width: 767px) {
    body#karir #join-now {
      position: relative; }
      body#karir #join-now .float-image {
        overflow: hidden;
        z-index: 1;
        position: absolute;
        height: 78%;
        width: 85%;
        left: 0;
        right: 0;
        margin: auto;
        top: 75px;
        background-color: #9a9a9a; }
        body#karir #join-now .float-image img {
          width: 260%;
          left: -125%;
          position: relative;
          height: auto;
          filter: grayscale(100%); }
      body#karir #join-now .bg-layer {
        z-index: 2;
        padding: 0 20px; }
      body#karir #join-now .right {
        background: #48af4a; }
        body#karir #join-now .right a {
          left: 0;
          right: 0 !important;
          text-align: center;
          padding: 20px 0 !important;
          font-size: 12px;
          width: 150px;
          margin: auto;
          bottom: -75px !important; }
    body#karir #submit--button button.pull-right {
      right: 5px;
      padding: 0px;
      margin: auto;
      top: 0;
      width: 80px;
      position: absolute;
      margin: auto;
      height: 90%;
      bottom: 0; }
    body#karir .sub .search--wrapper {
      width: 95%;
      top: -20px; } }
  @media screen and (min-width: 769px) {
    @keyframes slidedown {
      from {
        opacity: 0; }
      to {
        opacity: 1; } }
    body#karir .select2-dropdown {
      animation: slidedown 0.7s forwards; }
    body#karir .select2-container--open .select2-selection::after {
      transition: all 0.5s;
      transform: rotate(180deg); }
    body#karir #submit--button:hover i.fa-arrow-right {
      transition: all 0.3s;
      transform: translateX(10px); } }
  body#karir .blog-list {
    padding-top: 125px; }
    body#karir .blog-list .cat-list li {
      padding: 10px 15px; }
    body#karir .blog-list .author {
      background: #fff;
      margin-bottom: 15px;
      padding: 16px;
      position: relative;
      padding-top: 40%;
      margin-top: 30%; }
      body#karir .blog-list .author img {
        display: block;
        margin: 0 auto;
        width: 100%;
        position: absolute;
        top: -60%;
        padding: 50px;
        left: 0; }
      body#karir .blog-list .author ul {
        list-style: none;
        padding: 0; }
        body#karir .blog-list .author ul li {
          padding-left: 20px;
          margin: 10px auto; }
          body#karir .blog-list .author ul li i {
            color: #48af4a;
            width: 20px; }
    body#karir .blog-list .pre--title {
      padding-left: 0; }
    body#karir .blog-list .post-item {
      border-color: #8cc63f; }
    @media screen and (max-width: 768px) {
      body#karir .blog-list {
        padding-top: 125px; } }

.academy h1.hero--title {
  font-family: Viva,Helvetica,sans-serif; }

.academy section.about {
  text-align: center; }
  .academy section.about .description {
    font-size: 20px; }

.academy section.about h4 {
  position: relative; }

.academy section.about h4:before {
  content: "";
  background: #48af4a;
  position: absolute;
  height: 3px;
  width: 35px;
  left: 0;
  right: 0;
  margin: auto;
  bottom: -30px; }

.academy .how-it-works--wrapper {
  margin-top: 0; }
  .academy .how-it-works--wrapper .container .how--title {
    font-weight: 500; }
  .academy .how-it-works--wrapper .section--title::before {
    top: 10px !important; }
  .academy .how-it-works--wrapper .desc--pad a {
    line-height: 0.8;
    letter-spacing: 3.3px;
    color: #48af4a;
    font-weight: 700; }
  .academy .how-it-works--wrapper .talk .rect--secondary {
    background: #8cc63f; }
  .academy .how-it-works--wrapper .squad .rect--secondary {
    background: #e8ebf2; }
  .academy .how-it-works--wrapper .visit .rect--secondary {
    background: #48af4a; }

@media screen and (max-width: 35.5em) {
  .academy section.about h4:before {
    display: none; } }

#golife {
  /* Mobile View */ }
  #golife section.section-our-values.new {
    padding-top: 0px; }
    #golife section.section-our-values.new img {
      max-height: 150px;
      width: auto; }
    #golife section.section-our-values.new h4.title {
      color: #49af4b; }
      @media screen and (max-width: 767px) {
        #golife section.section-our-values.new h4.title:after {
          top: 30px;
          position: absolute;
          width: 15px;
          left: -20px;
          background-color: #4285a7;
          height: 2px;
          content: ""; } }
    #golife section.section-our-values.new .list svg {
      height: 140px; }
      @media screen and (max-width: 767px) {
        #golife section.section-our-values.new .list svg {
          height: 130px; } }
      #golife section.section-our-values.new .list svg.adjust {
        position: relative;
        left: -20px; }
    #golife section.section-our-values.new .list h4 {
      color: #4a4a4a; }
      @media screen and (min-width: 768px) {
        #golife section.section-our-values.new .list h4 {
          margin-top: 50px;
          margin-bottom: 40px; } }
      @media screen and (max-width: 767px) {
        #golife section.section-our-values.new .list h4 {
          padding: 0px;
          margin-bottom: 0px;
          margin-top: 20px; } }
    #golife section.section-our-values.new .list p {
      font-family: "Open Sans", Helvetica, sans-serif; }
      @media screen and (min-width: 768px) {
        #golife section.section-our-values.new .list p {
          margin-top: 10px; } }
      @media screen and (max-width: 767px) {
        #golife section.section-our-values.new .list p {
          max-width: 300px; } }
    #golife section.section-our-values.new .line {
      background: #49af4b; }
      @media screen and (max-width: 767px) {
        #golife section.section-our-values.new .line {
          display: none; } }
    @media screen and (max-width: 767px) {
      #golife section.section-our-values.new h4.title {
        font-size: 14px;
        text-align: left; } }
    #golife section.section-our-values.new #type-gobills {
      width: 100%; }
      #golife section.section-our-values.new #type-gobills .arrow-left {
        position: absolute;
        left: 0;
        bottom: -40px;
        width: 50px; }
      #golife section.section-our-values.new #type-gobills .arrow-right {
        position: absolute;
        right: 0;
        bottom: -40px;
        width: 50px; }
      #golife section.section-our-values.new #type-gobills .slick-arrow.slick-disabled {
        filter: grayscale(100) brightness(1.5); }
      @media screen and (min-width: 768px) {
        #golife section.section-our-values.new #type-gobills {
          margin-top: 40px; } }
    @media screen and (max-width: 767px) {
      #golife section.section-our-values.new {
        padding: 20px;
        padding-bottom: 100px;
        margin-top: 40px; }
        #golife section.section-our-values.new .container-slider {
          width: 100%;
          padding: 0px;
          position: relative;
          display: inline-block; } }
  #golife section.new-our-partner {
    background-color: #fafafa;
    text-align: center;
    margin-bottom: 100px; }
    #golife section.new-our-partner .container-slide {
      display: inline-block;
      max-width: 1170px;
      position: relative;
      height: 460px;
      width: 90%; }
      #golife section.new-our-partner .container-slide .title {
        z-index: 1;
        position: absolute;
        width: 50%;
        padding-left: 20px;
        font-size: 18px;
        color: #49af4b;
        right: 0;
        text-align: left;
        font-weight: 700;
        height: 100px;
        letter-spacing: 1px; }
        @media screen and (min-width: 769px) {
          #golife section.new-our-partner .container-slide .title:before {
            position: absolute;
            width: 35px;
            left: -25px;
            height: 3px;
            background: #49af4b;
            top: -5px;
            bottom: 0;
            content: "";
            margin: auto; } }
        #golife section.new-our-partner .container-slide .title:after {
          content: "";
          display: inline-block;
          vertical-align: middle;
          height: 100%; }
          @media screen and (max-width: 768px) {
            #golife section.new-our-partner .container-slide .title:after {
              height: 3px;
              width: 50px;
              margin: 20px auto;
              background: #49af4b;
              display: block;
              content: ""; } }
        @media screen and (max-width: 768px) {
          #golife section.new-our-partner .container-slide .title {
            margin-top: 40px;
            position: relative;
            height: auto;
            padding-left: 0px;
            width: 100%;
            text-align: center;
            font-size: 14px; } }
      #golife section.new-our-partner .container-slide .hero {
        float: left;
        height: 460px;
        width: 50%;
        background: #ebebeb; }
        #golife section.new-our-partner .container-slide .hero .image-hero {
          height: 460px;
          width: 100%;
          background-size: cover;
          background-position: center; }
        @media screen and (max-width: 768px) {
          #golife section.new-our-partner .container-slide .hero {
            width: 100%; } }
      #golife section.new-our-partner .container-slide .comment {
        position: absolute;
        height: calc(100% - 100px);
        background: #fff;
        right: 0;
        bottom: 0;
        width: 50%; }
        #golife section.new-our-partner .container-slide .comment .content {
          text-align: left;
          position: absolute;
          height: 80%;
          width: 70%;
          top: 0;
          bottom: 0;
          left: 7%;
          font-size: 14px;
          margin: auto; }
          @media screen and (max-width: 768px) {
            #golife section.new-our-partner .container-slide .comment .content {
              width: 100%;
              position: relative;
              left: auto;
              padding: 30px; } }
        #golife section.new-our-partner .container-slide .comment .container-comment {
          display: table;
          width: 100%;
          height: 300px;
          left: 0;
          font-size: 16px;
          line-height: 28px;
          color: #9b9b9b;
          font-family: "Open Sans", Helvetica, sans-serif;
          font-weight: 100; }
          #golife section.new-our-partner .container-slide .comment .container-comment .user {
            color: #2b2b2b;
            margin-top: 10px; }
            #golife section.new-our-partner .container-slide .comment .container-comment .user span {
              font-weight: 700; }
          #golife section.new-our-partner .container-slide .comment .container-comment > div {
            display: table-cell;
            vertical-align: middle; }
          @media screen and (max-width: 768px) {
            #golife section.new-our-partner .container-slide .comment .container-comment {
              text-align: center; } }
        @media screen and (max-width: 768px) {
          #golife section.new-our-partner .container-slide .comment {
            position: relative;
            width: 100%;
            float: left;
            height: 500px; } }
      #golife section.new-our-partner .container-slide .float-arrow {
        height: 45px;
        width: 45px;
        position: absolute;
        right: -90px;
        bottom: 0;
        top: 0;
        margin: auto; }
        #golife section.new-our-partner .container-slide .float-arrow.arrow-left {
          transform: rotate(90deg);
          top: -55px; }
          #golife section.new-our-partner .container-slide .float-arrow.arrow-left.slick-disabled {
            filter: grayscale(100) brightness(1.4); }
          @media screen and (max-width: 768px) {
            #golife section.new-our-partner .container-slide .float-arrow.arrow-left {
              top: auto;
              bottom: -50px;
              left: 20px;
              right: auto;
              transform: none; } }
        #golife section.new-our-partner .container-slide .float-arrow.arrow-right {
          transform: rotate(90deg);
          top: 55px; }
          #golife section.new-our-partner .container-slide .float-arrow.arrow-right.slick-disabled {
            filter: grayscale(100) brightness(1.4); }
          @media screen and (max-width: 768px) {
            #golife section.new-our-partner .container-slide .float-arrow.arrow-right {
              right: 20px;
              top: auto;
              bottom: -50px;
              left: auto;
              transform: none; } }
        @media screen and (max-width: 768px) {
          #golife section.new-our-partner .container-slide .float-arrow {
            right: 0;
            left: 0; } }
      @media screen and (max-width: 768px) {
        #golife section.new-our-partner .container-slide {
          height: auto;
          width: 100%; } }
    @media screen and (max-width: 768px) {
      #golife section.new-our-partner {
        background-color: #fff;
        margin-bottom: 30px; } }
    #golife section.new-our-partner .slick-dots {
      left: 0; }
      #golife section.new-our-partner .slick-dots > li {
        margin: 0px;
        margin-right: 5px;
        filter: grayscale(100) brightness(1.4); }
        #golife section.new-our-partner .slick-dots > li.slick-active {
          filter: grayscale(0); }
        #golife section.new-our-partner .slick-dots > li button {
          width: 10px;
          height: 10px;
          background: #49af4b;
          border-radius: 50%; }
          #golife section.new-our-partner .slick-dots > li button:before {
            display: none; }
        #golife section.new-our-partner .slick-dots > li:last-child {
          margin-right: 0px; }
  #golife section#new-join {
    margin-bottom: 100px; }
  #golife #content-only.thank-you {
    margin-top: 130px; }
    #golife #content-only.thank-you a.contact {
      width: 300px; }
    @media screen and (max-width: 768px) {
      #golife #content-only.thank-you {
        margin-bottom: 130px; }
        #golife #content-only.thank-you a.contact {
          width: 100%;
          line-height: normal; } }
  #golife section .slider--hide {
    visibility: hidden; }
  #golife section .slick-slider.slick-initialized {
    visibility: visible; }
  #golife header {
    overflow: visible; }
    #golife header .desc--wrapper .desc--row .hero--desc {
      margin-bottom: 30px; }
    #golife header .desc--wrapper .desc--row a.hero--button {
      display: inline-block;
      bottom: 0px;
      position: relative;
      margin-top: 10px;
      color: white;
      background-color: #49af4b;
      text-transform: uppercase;
      border-radius: 5px;
      padding: 30px 40px;
      -webkit-transition: all .3s ease-in-out;
      -moz-transition: all .3s ease-in-out;
      -o-transition: all .3s ease-in-out;
      transition: all .3s ease-in-out; }
      #golife header .desc--wrapper .desc--row a.hero--button svg {
        width: 12px;
        position: absolute;
        right: 20px;
        top: 50%;
        -webkit-transition: all .3s ease-in-out;
        -moz-transition: all .3s ease-in-out;
        -o-transition: all .3s ease-in-out;
        transition: all .3s ease-in-out;
        -webkit-transform: translateY(-50%);
        -moz-transform: translateY(-50%);
        -o-transform: translateY(-50%);
        transform: translateY(-50%); }
    #golife header .desc--wrapper .desc--row a.hero--button:hover {
      box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.19);
      color: #49af4b;
      background: #fff; }
      #golife header .desc--wrapper .desc--row a.hero--button:hover svg g {
        fill: #49af4b; }
  #golife section.articles--feed .content .blog-card-title {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px; }
  #golife section.services {
    padding: 50px 0 12em; }
    #golife section.services .container:before {
      left: -2em;
      top: 52px;
      padding-right: 45px; }
    #golife section.services h4 {
      padding-bottom: 0;
      font-size: 1em;
      line-height: 4em;
      position: relative; }
    #golife section.services .wrapper {
      padding-top: 0; }
      #golife section.services .wrapper .service-container .service-list {
        width: 20em; }
        #golife section.services .wrapper .service-container .service-list .bg-services {
          height: 12em; }
        #golife section.services .wrapper .service-container .service-list p {
          min-height: 125px; }
      #golife section.services .wrapper img.service-logo {
        top: 50%;
        width: 155px;
        position: absolute;
        -webkit-transform: translateY(-50%);
        -moz-transform: translateY(-50%);
        -o-transform: translateY(-50%);
        transform: translateY(-50%); }
  #golife section.about .img-wrapper .img-container {
    margin-top: -85px; }
  #golife section.about .slick-dotted {
    margin-bottom: 0; }
  #golife section.about .slick-dots {
    bottom: -40px; }
    #golife section.about .slick-dots li.slick-active button:before {
      color: #48af4a; }
  #golife section#download-cards {
    display: none; }
  #golife section#bg-as-seen-on .as-seen-on .title p {
    font-size: 1em;
    line-height: 0.8em; }
  #golife section#bg-as-seen-on .as-seen-on #right {
    background: #24a749; }
  #golife section#bg-as-seen-on .as-seen-on #left {
    background: #ffcd2e; }
  @media screen and (max-width: 768px) {
    #golife section.about h4 {
      padding-bottom: 75px; } }
  @media screen and (max-width: 35.5em) {
    #golife a.hero--button {
      bottom: -15px !important;
      position: absolute !important;
      right: 0;
      text-align: center;
      left: 0;
      width: 190px;
      margin: auto;
      padding: 20px 0 !important; }
    #golife .hero--desc {
      margin-bottom: 0px !important; }
    #golife section.about {
      padding-bottom: 0; }
      #golife section.about h4 {
        padding-bottom: 0; }
      #golife section.about .img-wrapper .img-container {
        margin-top: 0; }
      #golife section.about .slick-dots {
        bottom: -25px; }
    #golife section.services {
      padding-bottom: 4em; }
      #golife section.services h4 {
        font-size: .688em; }
      #golife section.services .container:before {
        left: -1em;
        top: 35px; }
      #golife section.services .wrapper .service-container .slick-track {
        margin-left: 5% !important; }
      #golife section.services .wrapper .service-container .service-list {
        width: 15em; }
        #golife section.services .wrapper .service-container .service-list .bg-services {
          height: 10em; }
      #golife section.services .wrapper img.service-logo {
        width: 100px;
        right: 0; }
    #golife section#download-cards {
      display: block;
      padding: 30px 0; }
      #golife section#download-cards .bg-right {
        padding: 0; }
      #golife section#download-cards .download-list > a {
        display: inline-block;
        float: left;
        padding: 12px 0; }
        #golife section#download-cards .download-list > a > p {
          font-size: 1.1em;
          margin: 0;
          color: #24a749; }
        #golife section#download-cards .download-list > a > svg {
          margin: 6px 10px; }
          #golife section#download-cards .download-list > a > svg g {
            fill: #24a749; }
      #golife section#download-cards .download-list .download-btn.ios {
        background-color: #656565; }
      #golife section#download-cards .download-list .download-btn {
        width: 50px;
        height: 50px;
        background-size: 35%; }
    #golife section#bg-as-seen-on {
      padding: 30px 0; } }

#gobills {
  /* gobills howto */ }
  #gobills header .title .content .text h2 {
    background: white !important; }
    @media only screen and (max-width: 767px) {
      #gobills header .title .content .text h2 {
        background: transparent !important; } }
  #gobills .mobile-how-to {
    padding: 30px 0;
    margin-bottom: -80px; }
    #gobills .mobile-how-to .pure-u-1 > .list-wrapper:first-child {
      margin-top: 0px; }
  #gobills .mobile-how-to .list-wrapper {
    background: #4285a7;
    width: 100%;
    padding: 10px;
    height: 100px;
    margin-top: 50px; }
    #gobills .mobile-how-to .list-wrapper.active {
      margin-bottom: 20px; }
      #gobills .mobile-how-to .list-wrapper.active .list-text {
        background: #13587b; }
        #gobills .mobile-how-to .list-wrapper.active .list-text span {
          color: #fff; }
        #gobills .mobile-how-to .list-wrapper.active .list-text i {
          color: #fff; }
  #gobills .mobile-how-to .list-wrapper .list-text {
    background: #fff;
    color: #4285a7;
    padding: 10px 35px;
    width: 100%;
    position: relative;
    top: -30px; }
    #gobills .mobile-how-to .list-wrapper .list-text.googlelist span {
      margin-top: -40px;
      position: relative;
      top: -6px;
      line-height: normal; }
    #gobills .mobile-how-to .list-wrapper .list-text.googlelist i {
      position: relative;
      top: -10px; }
  #gobills .mobile-how-to .list-wrapper .list-text .img-icon-wrapper {
    border-radius: 50%;
    height: 40px;
    width: 40px;
    background: white; }
  #gobills .mobile-how-to .list-wrapper .list-text .img-icon-wrapper .img-pln,
  #gobills .mobile-how-to .list-wrapper .list-text .img-icon-wrapper .img-bpjs {
    max-width: 20px;
    display: inline-block;
    position: relative;
    left: 10px;
    top: 7.5px; }
  #gobills .mobile-how-to .list-wrapper .list-text .img-icon-wrapper .img-bpjs {
    top: 10.5px; }
  #gobills .mobile-how-to .list-wrapper .list-text span {
    color: #4285a7;
    font-size: 20px;
    font-weight: 800;
    letter-spacing: 1.2px;
    display: inline-block;
    margin-top: -10px;
    position: relative;
    margin-left: 60px;
    top: -20px; }
  #gobills .mobile-how-to .list-wrapper .list-text i {
    float: right;
    color: #4285a7;
    font-size: 20px;
    margin-top: -25px; }
  #gobills .mobile-how-to .sub-wrapper {
    position: relative;
    padding: 10px 20px; }
  #gobills .mobile-how-to .sub-wrapper .content {
    background: white;
    width: 100%;
    position: relative;
    margin-top: 0px;
    border-left: 5px solid #13587b;
    font-weight: 800;
    letter-spacing: 1.2px;
    margin-bottom: 0px; }
  #gobills .mobile-how-to .sub-wrapper > .content {
    padding: 20px;
    padding-right: 40px;
    text-transform: uppercase;
    font-size: 14px; }
  #gobills .mobile-how-to .sub-wrapper .content i {
    float: right;
    color: black;
    font-size: 20px;
    position: absolute;
    height: 20px;
    right: 15px;
    top: 0;
    bottom: 0;
    margin: auto; }
  #gobills .mobile-how-to .sub-wrapper .slider ol {
    background: #fff;
    font-size: 14px;
    line-height: 20px;
    padding: 35px;
    margin: 0px; }
    #gobills .mobile-how-to .sub-wrapper .slider ol > li {
      margin-bottom: 10px; }
      #gobills .mobile-how-to .sub-wrapper .slider ol > li a {
        letter-spacing: normal;
        color: #48af4a;
        font-size: 14px; }
      #gobills .mobile-how-to .sub-wrapper .slider ol > li:last-child {
        margin-bottom: 0px; }
  #gobills #content-nav ul.cat-list li a {
    letter-spacing: 1px; }
  #gobills #content-nav ul.cat-list li .active {
    color: #4285a7 !important;
    position: relative;
    letter-spacing: 1px; }
  #gobills #content-nav ul.cat-list li .active:after {
    position: absolute;
    content: '';
    border-bottom: 2px solid #4285a7;
    width: 100%;
    transform: translateX(-50%);
    bottom: -15px;
    top: 40px;
    left: 50%; }
  #gobills .container-image-load {
    position: relative;
    min-height: 600px; }
    #gobills .container-image-load .image-wait-load {
      position: absolute;
      height: 100%;
      width: 100%;
      top: 0;
      left: 0; }
      #gobills .container-image-load .image-wait-load img {
        filter: blur(10px); }
  #gobills .google-redeem .new-section-how-to-use {
    margin-top: 60px;
    margin-bottom: 60px; }
    #gobills .google-redeem .new-section-how-to-use .title {
      width: 100%;
      text-transform: uppercase; }
    #gobills .google-redeem .new-section-how-to-use .content {
      width: 100%; }
      #gobills .google-redeem .new-section-how-to-use .content .table-display {
        left: auto;
        margin: auto; }
  #gobills .section-our-values.go-bills.voucher .arrow-left, #gobills .section-our-values.go-bills.voucher .arrow-right {
    bottom: 0px !important;
    z-index: 2; }
  #gobills .container-google-snk {
    margin-top: 100px !important; }
    #gobills .container-google-snk ol.list-googleplay {
      font-family: "Open Sans", Helvetica, sans-serif;
      padding-left: 20px;
      text-align: left;
      font-size: 14px;
      color: #4a4a4a;
      line-height: 24px;
      font-weight: 100;
      max-width: 800px;
      margin: 0 auto; }
      #gobills .container-google-snk ol.list-googleplay > li {
        letter-spacing: 1px;
        margin-bottom: 5px; }
        #gobills .container-google-snk ol.list-googleplay > li:last-child {
          margin-bottom: 0px; }
    #gobills .container-google-snk .credits {
      width: 100%;
      max-width: 800px;
      text-align: left;
      margin-top: 20px;
      font-size: 14px;
      margin: 30px auto;
      margin-bottom: 0px; }
  #gobills .container-option-desktop {
    display: block;
    width: 100%;
    max-width: 1000px;
    margin: auto; }
    #gobills .container-option-desktop > div {
      margin-bottom: 50px; }
    @media screen and (max-width: 767px) {
      #gobills .container-option-desktop {
        display: none; } }
  @media screen and (max-width: 767px) {
    #gobills #bg-as-seen-on {
      padding-top: 0px; } }
  #gobills .brushing {
    background-image: url("../../dist/img/brush/GO-BILLS.png"); }
    #gobills .brushing:after {
      background: #ffffff url("https://bilba.go-jek.com/images/product-brand/gobills.svg") no-repeat center; }
  #gobills .about .table-display {
    height: 100%;
    display: table; }
    #gobills .about .table-display .middle-table {
      display: table-cell;
      vertical-align: middle; }
  @media screen and (max-width: 767px) {
    #gobills .about .description {
      position: static; } }
  #gobills .about .container h4.title--content {
    color: #4285a7; }
    @media screen and (max-width: 767px) {
      #gobills .about .container h4.title--content {
        position: absolute;
        top: -20px;
        left: 0;
        font-size: 14px; }
        #gobills .about .container h4.title--content:after {
          top: 3px;
          position: absolute;
          width: 10px;
          left: 0;
          background-color: #4285a7;
          height: 2px;
          content: ""; } }
  @media screen and (min-width: 769px) {
    #gobills .about .container h2 {
      font-size: 28px; } }
  @media screen and (max-width: 767px) {
    #gobills .about .container .img-container {
      background: transparent; } }
  #gobills .about .container .img-container-2 img {
    width: 100%; }
    @media screen and (max-width: 767px) {
      #gobills .about .container .img-container-2 img {
        width: 90%;
        margin: auto;
        display: block; } }
  @media screen and (max-width: 767px) {
    #gobills .about .container .img-container-2 {
      margin-top: 30px; } }
  @media screen and (min-width: 768px) {
    #gobills .about {
      padding-top: 0px; } }
  #gobills #type-gobills {
    width: 100%; }
    #gobills #type-gobills .arrow-left {
      position: absolute;
      left: 0;
      bottom: -40px;
      width: 50px; }
    #gobills #type-gobills .arrow-right {
      position: absolute;
      right: 0;
      bottom: -40px;
      width: 50px; }
    #gobills #type-gobills .slick-arrow.slick-disabled {
      filter: grayscale(100) brightness(1.5); }
    @media screen and (min-width: 768px) {
      #gobills #type-gobills {
        margin-top: 40px; } }
  #gobills .as-seen-on .title p {
    color: #4285a7;
    font-size: 17px; }
    @media screen and (max-width: 767px) {
      #gobills .as-seen-on .title p {
        margin-left: 10px;
        font-weight: 700;
        font-size: 14px; } }
    @media screen and (min-width: 768px) {
      #gobills .as-seen-on .title p {
        position: relative;
        bottom: 6px; } }
  #gobills .as-seen-on .title hr {
    background: #4285a7; }
  #gobills .as-seen-on #left {
    right: 0;
    left: auto;
    top: 0;
    bottom: auto; }
    @media screen and (max-width: 767px) {
      #gobills .as-seen-on #left {
        height: 100px; } }
  #gobills .as-seen-on #right {
    top: auto;
    bottom: 0;
    right: auto;
    left: 0;
    background: #4285a7; }
    @media screen and (max-width: 767px) {
      #gobills .as-seen-on #right {
        height: 100px; } }
  #gobills .as-seen-on #content img {
    max-height: 200px; }
    @media screen and (min-width: 768px) {
      #gobills .as-seen-on #content img.bpjs {
        max-width: 470px;
        position: relative;
        top: 10px; } }
    @media screen and (max-width: 767px) {
      #gobills .as-seen-on #content img.bpjs {
        max-width: none;
        width: 100%; } }
  #gobills #content-only {
    margin-top: 0px;
    padding-top: 60px; }
    #gobills #content-only .container {
      box-shadow: #4a4a4a 0px -3em 0px 1.5em; }
      #gobills #content-only .container .info {
        padding-top: 50px; }
        @media screen and (min-width: 768px) {
          #gobills #content-only .container .info p {
            margin: auto;
            max-width: 580px; } }
        #gobills #content-only .container .info h4 {
          color: #4285a7; }
          @media screen and (max-width: 767px) {
            #gobills #content-only .container .info h4 {
              font-size: 14px; } }
        #gobills #content-only .container .info .line {
          background: #4285a7; }
        #gobills #content-only .container .info a.contact {
          background: #4285a7; }
          @media screen and (max-width: 767px) {
            #gobills #content-only .container .info a.contact {
              width: 80%;
              max-width: 300px; } }
        #gobills #content-only .container .info a.faq {
          background: #4a4a4a; }
          @media screen and (max-width: 767px) {
            #gobills #content-only .container .info a.faq {
              width: 80%;
              max-width: 300px; } }
      @media screen and (max-width: 767px) {
        #gobills #content-only .container .link {
          margin-bottom: -50px; } }
    @media screen and (max-width: 767px) {
      #gobills #content-only {
        margin-bottom: 100px; } }
  #gobills .how-to [data-display=desktop],
  #gobills .how-to .desktop-only {
    display: block; }
  #gobills .how-to .mobile-how-to {
    display: none; }
  #gobills .how-to .visibility {
    position: absolute;
    opacity: 0.0;
    height: auto;
    bottom: 0;
    margin: auto;
    visibility: hidden !important; }
  #gobills .how-to header .title .content {
    box-shadow: -1em 1em #4285a7; }
    #gobills .how-to header .title .content .text {
      padding: .2em 1.8em; }
      #gobills .how-to header .title .content .text h2 {
        font-family: 'Neo', 'Open Sans', Helvetica, sans-serif !important;
        font-size: 50px !important;
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.12;
        letter-spacing: 0.5px; }
  #gobills .how-to header .bg-img {
    background-position-x: 100% !important; }
  #gobills .how-to .new-section-how-to-use h3.title {
    color: #4285a7; }
    #gobills .how-to .new-section-how-to-use h3.title:after {
      background-color: #4285a7; }
  #gobills .how-to .new-section-how-to-use .content {
    background: #4285a7; }
  #gobills .how-to #content-nav .text h4 {
    font-family: "Open Sans", Helvetica, sans-serif;
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 1px;
    text-align: center;
    text-transform: uppercase;
    color: #4285a7; }
  #gobills .how-to #content-nav .text .line.gradient {
    background: #4285a7;
    width: 50px;
    height: 3px;
    margin: 0 auto;
    margin-bottom: 20px; }
  #gobills .how-to #content-nav .faq-nav {
    width: unset !important;
    margin: 0px auto; }
    #gobills .how-to #content-nav .faq-nav .cat-list li a:hover {
      color: #4285a7 !important; }
  #gobills .how-to .navigation-wrapper {
    margin-bottom: 80px; }
    #gobills .how-to .navigation-wrapper .new-section-how-to-use .number > div {
      transition: all 0s 0.51s; }
      #gobills .how-to .navigation-wrapper .new-section-how-to-use .number > div.active {
        transition: all 0s 0s; }
  #gobills .how-to .how-to-slider.pln .new-section-how-to-use {
    margin-top: 200px; }
  #gobills .how-to #faq-list-menu {
    margin: 2.5em 0; }
    #gobills .how-to #faq-list-menu .desktop {
      margin-bottom: 80px; }
      #gobills .how-to #faq-list-menu .desktop h4 {
        text-transform: uppercase;
        font-family: "Open Sans", Helvetica, sans-serif;
        font-size: 18px;
        font-weight: bold;
        letter-spacing: 1px;
        text-align: center;
        color: #4285a7; }
      #gobills .how-to #faq-list-menu .desktop .line.gradient {
        background: #4285a7;
        width: 50px;
        height: 3px;
        margin: 0 auto;
        margin-bottom: 50px; }
    #gobills .how-to #faq-list-menu .box {
      box-shadow: 0 3px 15px 0 rgba(0, 0, 0, 0.13), 0 25px 0 15px #4285a7 !important;
      transition: all 0.5s;
      padding: 0px;
      height: 200px;
      padding-top: 40px; }
      #gobills .how-to #faq-list-menu .box .img-icon-wrapper {
        width: 40px;
        height: 40px;
        position: relative;
        background: #fff;
        line-height: 50px;
        margin: 0 auto;
        border-radius: 50%;
        margin-bottom: 20px; }
        #gobills .how-to #faq-list-menu .box .img-icon-wrapper .img-pln {
          max-width: 16px; }
        #gobills .how-to #faq-list-menu .box .img-icon-wrapper .img-bpjs {
          max-width: 21px; }
      #gobills .how-to #faq-list-menu .box h4 {
        font-weight: 100;
        color: #343b41; }
      #gobills .how-to #faq-list-menu .box:not(.active):hover {
        opacity: 0.8;
        transition: all 0.5s; }
    #gobills .how-to #faq-list-menu .active {
      background-image: url(https://d3naj63yelf4gk.cloudfront.net/dist/img/faq/list-geo.png), linear-gradient(#13587b, #13587b);
      background-position: bottom,top;
      background-repeat: no-repeat,no-repeat;
      background-size: 100%,cover;
      box-shadow: 0 25px 0 15px #4285a7;
      cursor: default;
      pointer-events: none;
      transition: all 0.5s;
      color: white; }
      #gobills .how-to #faq-list-menu .active h4 {
        color: white !important;
        font-weight: 700; }
  #gobills .gradient {
    background: #5CA5DA; }
  #gobills .hero button {
    background: #5CA5DA; }
  #gobills .merchant-list h5 {
    color: #5CA5DA; }
  #gobills .merchant-list hr {
    background: #5CA5DA; }
  #gobills section.verification {
    margin-top: 30px;
    margin-bottom: 30px; }
    #gobills section.verification #sub-desc--container {
      background: #f8f8f8;
      z-index: 5;
      position: relative; }
      #gobills section.verification #sub-desc--container #line--desc {
        height: 4px;
        width: calc(100% - 55px);
        position: relative;
        top: 50%;
        left: 55px;
        -webkit-transform: translateY(-50%);
        -moz-transform: translateY(-50%);
        -o-transform: translateY(-50%);
        transform: translateY(-50%);
        background-color: #333; }
      #gobills section.verification #sub-desc--container .sub--desc {
        text-align: left;
        margin-left: 50px; }
    #gobills section.verification .container {
      margin: 0;
      padding: 0;
      width: auto !important;
      overflow: hidden; }
      #gobills section.verification .container table {
        border-collapse: collapse;
        width: 100%; }
      #gobills section.verification .container td,
      #gobills section.verification .container th {
        border: 0px;
        text-align: left;
        font-size: 14px;
        padding: 20px; }
      #gobills section.verification .container th {
        font-family: Open Sans, sans-serif;
        font-weight: 700;
        text-transform: uppercase;
        line-height: 1.17;
        letter-spacing: 1.3px;
        color: #5ca5da;
        background-color: #fff;
        border-left: 1px solid #d7d7d7;
        padding: 10px 5px; }
      #gobills section.verification .container th:nth-child(1) {
        border-left: 0px; }
      #gobills section.verification .container tr:nth-child(even) {
        background-color: #afdcff; }
      #gobills section.verification .container tr:nth-child(odd) {
        background-color: #fafafa; }
      #gobills section.verification .container td {
        color: #485460; }
      #gobills section.verification .container td:nth-child(odd) {
        background-color: rgba(250, 250, 250, 0.5); }
      #gobills section.verification .container th:nth-child(2),
      #gobills section.verification .container th:nth-child(3),
      #gobills section.verification .container td:nth-child(2),
      #gobills section.verification .container td:nth-child(3) {
        text-align: center; }
      #gobills section.verification .container .first-block {
        background: white; }
        #gobills section.verification .container .first-block .text-wrapper {
          padding: 45px 35px 0px 55px; }
          #gobills section.verification .container .first-block .text-wrapper h1 {
            font-family: "Lato", Helvetica, sans-serif;
            font-size: 18px;
            font-weight: bold;
            letter-spacing: 1.5px;
            text-transform: uppercase;
            color: #00b1f1; }
          #gobills section.verification .container .first-block .text-wrapper p {
            font-family: "Open Sans", Helvetica, sans-serif;
            font-size: 14px;
            font-weight: 300;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.64;
            letter-spacing: 1px;
            color: #485460;
            margin: 40px 70px 17px 0px; }
      #gobills section.verification .container .second-block {
        width: calc(50% + 35px);
        height: 390px;
        background: #5CA5DA;
        margin-left: -35px;
        position: relative;
        right: 0;
        top: 70px; }
        #gobills section.verification .container .second-block .slick-track {
          margin-left: -165px;
          margin-top: 15px; }
        #gobills section.verification .container .second-block .verified-prev {
          display: none !important; }
        #gobills section.verification .container .second-block .verified-next {
          display: none !important; }
        #gobills section.verification .container .second-block .verified-container {
          height: 100%; }
          #gobills section.verification .container .second-block .verified-container .slick-list {
            overflow: visible; }
          #gobills section.verification .container .second-block .verified-container .verified-list {
            padding: 0 30px;
            text-align: center;
            outline: none; }
            #gobills section.verification .container .second-block .verified-container .verified-list h1 {
              font-family: "Open Sans", Helvetica, sans-serif;
              font-size: 24px;
              font-weight: 300;
              letter-spacing: 3px;
              color: #ffffff; }
            #gobills section.verification .container .second-block .verified-container .verified-list h5 {
              font-family: "Open Sans", Helvetica, sans-serif;
              font-size: 12px;
              font-weight: 300;
              letter-spacing: 1.5px;
              text-align: center;
              color: #ffffff; }
            #gobills section.verification .container .second-block .verified-container .verified-list img {
              width: 250px; }
  #gobills .mv-evt--container {
    margin: 48px 0;
    text-align: center; }
    #gobills .mv-evt--container .button-group {
      display: inline-block;
      background: #5CA5DA;
      border-radius: 5px;
      margin: 0 auto;
      position: relative; }
      #gobills .mv-evt--container .button-group ul {
        list-style: none;
        padding: 20px 0;
        margin: 0; }
        #gobills .mv-evt--container .button-group ul li:first-child {
          border-right: 1px solid #353738; }
        #gobills .mv-evt--container .button-group ul li {
          display: inline-block;
          width: 180px;
          text-align: center; }
          #gobills .mv-evt--container .button-group ul li a {
            display: block;
            color: #fff;
            text-align: center;
            font-family: Lato, Helvetica, sans-serif;
            font-size: 14px;
            line-height: 1.33;
            letter-spacing: 1.5px;
            font-weight: 300;
            padding: 10px; }
  #gobills .services .png-file {
    background-size: 20em !important; }
  #gobills .services .service-list .bg-services {
    background-size: 6em;
    background-repeat: no-repeat;
    opacity: 1; }
  #gobills .services .navigation-arrow {
    display: block; }
    @media screen and (max-width: 767px) {
      #gobills .services .navigation-arrow {
        margin-top: 50px; } }
  #gobills .services .container::before {
    background-color: #5CA5DA; }
  #gobills .services h4 {
    color: #5CA5DA;
    padding: 0; }
    @media screen and (min-width: 769px) {
      #gobills .services h4 {
        margin-bottom: 50px; } }
  @media screen and (min-width: 768px) {
    #gobills .how-it-works--wrapper {
      margin-top: 160px; } }
  #gobills .how-it-works--wrapper .container {
    padding: 0; }
  #gobills .how-it-works--wrapper h2 {
    color: #093c5b; }
  #gobills .how-it-works--wrapper h4 {
    color: #5CA5DA; }
  #gobills .how-it-works--wrapper figure.hand-ss {
    background: #fff; }
    #gobills .how-it-works--wrapper figure.hand-ss img {
      width: auto;
      right: 0;
      margin: 0 auto;
      position: absolute;
      left: 0; }
  #gobills .how-it-works--wrapper .section--title:before {
    background-color: #093c5b; }
  #gobills .how-it-works--wrapper .rect--primary {
    background: #093c5b; }
  #gobills .section-our-values {
    padding-bottom: 0; }
    #gobills .section-our-values h4 {
      color: #5CA5DA; }
    #gobills .section-our-values img {
      max-height: 150px;
      width: auto; }
    #gobills .section-our-values.go-bills > .pure-g {
      display: block;
      max-width: 960px;
      margin: auto; }
    #gobills .section-our-values.go-bills h4.title {
      color: #4285a7; }
      @media screen and (max-width: 767px) {
        #gobills .section-our-values.go-bills h4.title:after {
          top: 30px;
          position: absolute;
          width: 15px;
          left: -20px;
          background-color: #4285a7;
          height: 2px;
          content: ""; } }
    #gobills .section-our-values.go-bills .list h4 {
      color: #4a4a4a; }
      @media screen and (min-width: 768px) {
        #gobills .section-our-values.go-bills .list h4 {
          margin-top: 50px;
          margin-bottom: 40px; } }
      @media screen and (max-width: 767px) {
        #gobills .section-our-values.go-bills .list h4 {
          padding: 0px;
          margin-bottom: 0px;
          margin-top: 50px; } }
    @media screen and (min-width: 768px) {
      #gobills .section-our-values.go-bills .list p {
        margin-top: 10px; } }
    @media screen and (max-width: 767px) {
      #gobills .section-our-values.go-bills .list p {
        max-width: 300px; } }
    #gobills .section-our-values.go-bills .line {
      background: #4285a7; }
      @media screen and (max-width: 767px) {
        #gobills .section-our-values.go-bills .line {
          display: none; } }
    @media screen and (max-width: 767px) {
      #gobills .section-our-values.go-bills h4.title {
        font-size: 14px;
        text-align: left; } }
    @media screen and (max-width: 767px) {
      #gobills .section-our-values.go-bills {
        padding: 20px;
        padding-bottom: 100px; }
        #gobills .section-our-values.go-bills .container-slider {
          width: 100%;
          padding: 0px;
          position: relative;
          display: inline-block; } }
    #gobills .section-our-values.voucher .list img.pure-img {
      max-height: 100px; }
  #gobills .about {
    padding-bottom: 0; }
    #gobills .about .container::before {
      display: none;
      background-color: #093c5b; }
    #gobills .about h4,
    #gobills .about a,
    #gobills .about a::after {
      color: #5CA5DA; }
  #gobills #join-now a {
    background: #5CA5DA; }
  #gobills #join-now h2 {
    font-size: 1.5em; }
  #gobills .faq #content {
    background: url(https://d3naj63yelf4gk.cloudfront.net/dist/img/page/gopay/rectangle-bg.svg); }
    #gobills .faq #content .gopay-faq--title {
      color: #000;
      padding: 30px 0; }
      #gobills .faq #content .gopay-faq--title span.light {
        font-weight: 300; }
  #gobills .faq #right {
    background: #5CA5DA; }
  #gobills .faq .button--wrapper {
    width: 200px;
    border-radius: 5px;
    background-color: #5CA5DA;
    text-align: center;
    position: relative;
    margin: 0 auto 30px; }
    #gobills .faq .button--wrapper a.gopay-faq--link {
      text-decoration: none;
      color: #fff;
      font-size: 16px;
      text-transform: uppercase;
      padding: 19px 0;
      display: block; }

@media screen and (max-width: 768px) {
  #gopay section.verification {
    margin-top: 30px;
    margin-bottom: 30px; }
    #gopay section.verification #sub-desc--container .sub--desc {
      margin-left: 0px;
      padding: 0 15px;
      text-align: center; }
    #gopay section.verification #sub-desc--container #line--desc {
      display: none; }
    #gopay section.verification .container .first-block {
      background: white; }
      #gopay section.verification .container .first-block table {
        letter-spacing: normal; }
        #gopay section.verification .container .first-block table td {
          padding: 10px; }
      #gopay section.verification .container .first-block .text-wrapper {
        padding: 15px; }
        #gopay section.verification .container .first-block .text-wrapper h1 {
          font-family: "Lato", Helvetica, sans-serif;
          font-size: 18px;
          font-weight: bold;
          letter-spacing: 1.5px;
          text-transform: uppercase;
          color: #00b1f1;
          margin-top: 20px; }
        #gopay section.verification .container .first-block .text-wrapper p {
          margin-top: 40px;
          margin-right: 0;
          margin-bottom: 0; }
    #gopay section.verification .container .second-block {
      width: 100%;
      height: 380px;
      top: 0;
      margin-left: 0;
      position: relative; }
      #gopay section.verification .container .second-block .verified-prev {
        display: block !important;
        z-index: 999;
        float: left;
        position: relative;
        right: 20px;
        left: 0;
        margin: 22px; }
      #gopay section.verification .container .second-block .verified-next {
        display: block !important;
        z-index: 999;
        float: right;
        position: relative;
        right: 0;
        margin: 22px; }
      #gopay section.verification .container .second-block .slick-disabled ellipse {
        stroke: #d2d2d2 !important; }
      #gopay section.verification .container .second-block .slick-disabled polygon {
        fill: #d2d2d2; }
      #gopay section.verification .container .second-block .verified-container {
        margin-left: 0;
        bottom: 0;
        position: relative; }
        #gopay section.verification .container .second-block .verified-container .slick-list {
          padding: 0 30px !important; }
        #gopay section.verification .container .second-block .verified-container .slick-track {
          margin-left: 0;
          margin-top: 0; }
        #gopay section.verification .container .second-block .verified-container .verified-list {
          padding: 0 30px;
          margin-top: -80px;
          text-align: center;
          outline: none; }
  #gopay #join-now img {
    width: 100%; }
  #gopay #join-now .right a {
    padding: 1.3em;
    bottom: -1.5em; }
  #gopay .services {
    padding-top: 0;
    padding-bottom: 6em; }
  #gopay .how-it-works--wrapper .container {
    padding-top: 0;
    padding-bottom: 0; }
  #gopay .how-it-works--wrapper h4 {
    line-height: 1.3em; }
  #gopay .how-it-works--wrapper .layer {
    padding-bottom: 0;
    padding-top: 0; }
    #gopay .how-it-works--wrapper .layer .odd--row .desc--pad {
      padding-right: 0; }
    #gopay .how-it-works--wrapper .layer .even--row .desc--pad {
      padding-right: 0;
      padding-left: 0; } }

@media screen and (max-width: 500px) {
  #gopay .button-group ul {
    padding: 10px 0 !important; }
    #gopay .button-group ul li {
      width: 150px !important; }
      #gopay .button-group ul li a {
        font-size: 12px !important; } }

@media only screen and (max-width: 35.5em) {
  #gobills [data-display=desktop],
  #gobills .desktop-only {
    display: none !important; }
  #gobills .mobile-how-to {
    display: block !important;
    margin-bottom: 50px; }
  #gobills .new-section-how-to-use {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
    #gobills .new-section-how-to-use .title {
      display: none; }
    #gobills .new-section-how-to-use .phoneSlide-dots {
      display: none !important; }
  #gobills #content-nav {
    display: none; }
  #gobills #faq-list-menu {
    margin: 0 !important;
    padding: 0;
    margin-bottom: 50px !important; }
    #gobills #faq-list-menu .mobile .warper h3 {
      margin-top: -50px;
      margin-left: 60px; }
    #gobills #faq-list-menu .mobile .warper .img-icon-wrapper {
      width: 40px;
      height: 40px;
      position: relative;
      background: #fff;
      line-height: 50px;
      border-radius: 50%;
      margin-bottom: 20px; }
      #gobills #faq-list-menu .mobile .warper .img-icon-wrapper .img-pln {
        max-width: 16px;
        position: relative;
        left: 12px;
        top: 7.5px; }
      #gobills #faq-list-menu .mobile .warper .img-icon-wrapper .img-bpjs {
        max-width: 22px;
        position: relative;
        top: 8.5px;
        left: 9px; }
    #gobills #faq-list-menu .mobile .ui-accordion-content-active {
      margin-bottom: 100px !important; }
    #gobills #faq-list-menu .mobile #faq-list-content .title-content {
      margin-bottom: 100px;
      transform: scale(1.285);
      box-shadow: 0 3px 15px 0 rgba(0, 0, 0, 0.13), 0 25px 0 15px #4285a7; }
      #gobills #faq-list-menu .mobile #faq-list-content .title-content span {
        color: unset; }
    #gobills #faq-list-menu .mobile #faq-list-content .detail-item {
      transform: scale(1.25);
      margin-bottom: 35px; }
      #gobills #faq-list-menu .mobile #faq-list-content .detail-item ul li {
        padding: 0;
        border-bottom: none; }
      #gobills #faq-list-menu .mobile #faq-list-content .detail-item .title-item {
        border-left: 5px solid #4285a7; }
        #gobills #faq-list-menu .mobile #faq-list-content .detail-item .title-item.sub-item {
          background: white !important; }
        #gobills #faq-list-menu .mobile #faq-list-content .detail-item .title-item:last-child {
          margin-bottom: 250px; }
        #gobills #faq-list-menu .mobile #faq-list-content .detail-item .title-item span {
          color: unset; }
    #gobills #faq-list-menu .mobile #faq-list-content .ui-state-active {
      background: #13587b !important; }
      #gobills #faq-list-menu .mobile #faq-list-content .ui-state-active ul {
        margin-bottom: 50px !important; }
  #gobills .how-to header {
    background-size: cover;
    background-position-x: 100% !important;
    margin-top: 60px; }
    #gobills .how-to header .title .content {
      box-shadow: none; }
      #gobills .how-to header .title .content .text h2 {
        text-align: center;
        font-size: 36px !important;
        line-height: 40px; }
        #gobills .how-to header .title .content .text h2 span {
          position: relative;
          top: 7px; } }

#topup {
  margin: 0px;
  padding: 0px;
  font-family: "Lato", Helvetica, sans-serif;
  background-color: #f4f4f4; }
  #topup .lang-option {
    position: relative;
    top: 0;
    width: 100%;
    padding: 10px 0;
    display: inline-block;
    border-bottom: 1px solid #c3c9d7;
    background-color: #fbfbfb; }
    #topup .lang-option a {
      font-size: inherit;
      color: inherit;
      letter-spacing: normal; }
    #topup .lang-option div.choose:first-child {
      border-right: 1px solid #c3c9d7; }
    #topup .lang-option div.choose {
      position: relative;
      float: left;
      width: 50%;
      text-align: center;
      font-size: 17px;
      font-weight: 300;
      color: #697b8c;
      padding: 5px 0;
      transition: all 0.5s; }
      #topup .lang-option div.choose.active {
        font-weight: 700;
        color: #4a90e2;
        transition: all 0.5s; }
        #topup .lang-option div.choose.active:after {
          position: absolute;
          content: "";
          width: 100%;
          height: 3px;
          background: #4a90e2;
          bottom: -10px;
          left: 0; }
  #topup .content {
    display: inline-block;
    width: 100%;
    padding: 50px 20px; }
    #topup .content .title {
      margin-bottom: 30px; }
      #topup .content .title b {
        font-size: 17px; }
      #topup .content .title p {
        color: #828282;
        font-size: 14px;
        letter-spacing: normal;
        margin: 5px 0; }
    #topup .content .choose-way {
      display: inline-block;
      width: 100%;
      margin-bottom: 20px;
      position: relative;
      z-index: 1; }
      #topup .content .choose-way.choose-bank {
        opacity: 0;
        transition: all 0.5s;
        transform: translateY(-90px);
        pointer-events: none;
        z-index: 0; }
        #topup .content .choose-way.choose-bank.active {
          opacity: 1;
          transition: all 0.5s;
          transform: translateY(0px);
          pointer-events: visible; }
      #topup .content .choose-way b {
        letter-spacing: 1.1px;
        text-transform: uppercase;
        font-size: 12px;
        color: #697b8c; }
      #topup .content .choose-way .container-select {
        margin-top: 10px;
        width: calc(100% + 20px);
        background-color: #fbfbfb;
        position: relative;
        left: -10px; }
        #topup .content .choose-way .container-select select {
          border-radius: 0px;
          border: 1px solid #ececec;
          font-size: 14px;
          padding: 10px 20px;
          width: 100%;
          letter-spacing: 1.1px;
          text-transform: uppercase;
          -webkit-appearance: none; }
        #topup .content .choose-way .container-select .arrow {
          color: #7e7e7e;
          top: 10px;
          position: absolute;
          right: 20px;
          font-size: 14px; }
    #topup .content .show-content {
      display: none;
      border-top: 2px solid #4a90e2;
      position: relative;
      width: calc(100% + 20px);
      left: -10px;
      padding: 20px 10px;
      background: #fbfbfb;
      opacity: 0;
      pointer-events: none;
      transform: translateY(-90px); }
      #topup .content .show-content.down {
        transform: translateY(0px); }
      #topup .content .show-content.active {
        display: block;
        opacity: 1;
        pointer-events: visible; }
      #topup .content .show-content ol.step {
        padding-left: 60px;
        counter-reset: section; }
        #topup .content .show-content ol.step li {
          font-size: 14px;
          list-style-type: none;
          position: relative;
          padding-bottom: 30px; }
          #topup .content .show-content ol.step li p {
            letter-spacing: normal;
            font-size: 14px;
            margin-top: 0px;
            line-height: normal; }
          #topup .content .show-content ol.step li:before {
            content: counters(section, ".");
            counter-increment: section;
            text-align: center;
            position: absolute;
            font-family: Neo;
            color: #54a2fe;
            left: -60px;
            height: 37px;
            width: 37px;
            font-size: 18px;
            border: 2px solid #54a2fe;
            border-radius: 50%;
            background: #f8f8f8;
            z-index: 1;
            line-height: 40px; }
          #topup .content .show-content ol.step li:after {
            position: absolute;
            left: -41px;
            width: 2px;
            height: 100%;
            top: 0;
            background: #4a90e2;
            content: ""; }
          #topup .content .show-content ol.step li.last {
            padding-bottom: 10px; }
            #topup .content .show-content ol.step li.last:after {
              display: none; }
          #topup .content .show-content ol.step li:last-child:after {
            display: none; }
          #topup .content .show-content ol.step li img {
            max-width: 100%; }
    @media screen and (min-width: 769px) {
      #topup .content {
        max-width: 500px;
        margin: auto;
        display: block; } }
  #topup .gopay-subservices,
  #topup .gobills-benefits {
    display: inline-block;
    width: 100%;
    margin: 20px 0; }
    #topup .gopay-subservices .container-gobills,
    #topup .gobills-benefits .container-gobills {
      display: inline-block;
      width: 100%;
      margin-bottom: 16px; }
      #topup .gopay-subservices .container-gobills .icon,
      #topup .gobills-benefits .container-gobills .icon {
        float: left;
        text-align: center;
        width: 60px; }
        #topup .gopay-subservices .container-gobills .icon img,
        #topup .gobills-benefits .container-gobills .icon img {
          width: 36px; }
      #topup .gopay-subservices .container-gobills .content-gobills,
      #topup .gobills-benefits .container-gobills .content-gobills {
        float: left;
        width: calc(100% - 60px);
        padding-right: 16px;
        padding-bottom: 16px;
        border-bottom: 1px solid #979797; }
        #topup .gopay-subservices .container-gobills .content-gobills .title,
        #topup .gobills-benefits .container-gobills .content-gobills .title {
          font-weight: 700;
          font-size: 14px;
          line-height: 20px;
          color: #454545;
          margin-bottom: 5px; }
        #topup .gopay-subservices .container-gobills .content-gobills .content-fill,
        #topup .gobills-benefits .container-gobills .content-gobills .content-fill {
          font-size: 12px;
          color: #8d8d8d;
          font-weight: 100;
          line-height: 20px; }
        #topup .gopay-subservices .container-gobills .content-gobills ol,
        #topup .gobills-benefits .container-gobills .content-gobills ol {
          margin: 0px;
          padding-left: 10px; }
          #topup .gopay-subservices .container-gobills .content-gobills ol li,
          #topup .gobills-benefits .container-gobills .content-gobills ol li {
            padding-left: 10px; }
    #topup .gopay-subservices ol.terms,
    #topup .gopay-subservices ul.terms,
    #topup .gobills-benefits ol.terms,
    #topup .gobills-benefits ul.terms {
      margin: 0px;
      font-size: 12px;
      color: #747474;
      font-weight: 100;
      line-height: 20px;
      padding-right: 20px; }
      #topup .gopay-subservices ol.terms li,
      #topup .gopay-subservices ul.terms li,
      #topup .gobills-benefits ol.terms li,
      #topup .gobills-benefits ul.terms li {
        margin-bottom: 10px; }
        #topup .gopay-subservices ol.terms li a,
        #topup .gopay-subservices ul.terms li a,
        #topup .gobills-benefits ol.terms li a,
        #topup .gobills-benefits ul.terms li a {
          letter-spacing: normal;
          font-size: normal;
          font-size: inherit;
          color: #48af4a; }
    #topup .gopay-subservices .text-wrapper,
    #topup .gopay-subservices .copyr,
    #topup .gobills-benefits .text-wrapper,
    #topup .gobills-benefits .copyr {
      padding: 20px;
      font-size: 12px; }
      #topup .gopay-subservices .text-wrapper .text,
      #topup .gopay-subservices .text-wrapper .title,
      #topup .gopay-subservices .copyr .text,
      #topup .gopay-subservices .copyr .title,
      #topup .gobills-benefits .text-wrapper .text,
      #topup .gobills-benefits .text-wrapper .title,
      #topup .gobills-benefits .copyr .text,
      #topup .gobills-benefits .copyr .title {
        margin-bottom: 20px; }
        #topup .gopay-subservices .text-wrapper .text.footer,
        #topup .gopay-subservices .text-wrapper .title.footer,
        #topup .gopay-subservices .copyr .text.footer,
        #topup .gopay-subservices .copyr .title.footer,
        #topup .gobills-benefits .text-wrapper .text.footer,
        #topup .gobills-benefits .text-wrapper .title.footer,
        #topup .gobills-benefits .copyr .text.footer,
        #topup .gobills-benefits .copyr .title.footer {
          margin-top: 30px; }
      #topup .gopay-subservices .text-wrapper .heading,
      #topup .gopay-subservices .copyr .heading,
      #topup .gobills-benefits .text-wrapper .heading,
      #topup .gobills-benefits .copyr .heading {
        margin-top: -20px;
        margin-bottom: 30px;
        text-align: center; }
        #topup .gopay-subservices .text-wrapper .heading h3,
        #topup .gopay-subservices .copyr .heading h3,
        #topup .gobills-benefits .text-wrapper .heading h3,
        #topup .gobills-benefits .copyr .heading h3 {
          font-weight: bold; }
      #topup .gopay-subservices .text-wrapper span.ls,
      #topup .gopay-subservices .copyr span.ls,
      #topup .gobills-benefits .text-wrapper span.ls,
      #topup .gobills-benefits .copyr span.ls {
        margin: 0px;
        font-size: 12px;
        color: #747474;
        font-weight: 100;
        line-height: 20px;
        padding-right: 20px; }

/* Set Style pure row */
.pure-g-r {
  letter-spacing: -.31em; }

/* Loading */
.loader--wrapper {
  width: 100%;
  height: 100%;
  position: fixed;
  background: #fff;
  z-index: 2000; }
  .loader--wrapper .uil-ring-css {
    width: 100% !important;
    height: 100% !important; }
  .loader--wrapper .uil-ring-css > div#loading-img {
    -webkit-transform-origin: center !important;
    transform-origin: center !important; }

.mask-form {
  background: rgba(255, 255, 255, 0.7);
  width: 100%;
  height: 100%;
  min-height: 360px;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 12;
  display: none; }

@-webkit-keyframes uil-ring-anim {
  0% {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg); } }

@-webkit-keyframes uil-ring-anim {
  0% {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg); } }

@-moz-keyframes uil-ring-anim {
  0% {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg); } }

@-ms-keyframes uil-ring-anim {
  0% {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg); } }

@-moz-keyframes uil-ring-anim {
  0% {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg); } }

@-webkit-keyframes uil-ring-anim {
  0% {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg); } }

@-o-keyframes uil-ring-anim {
  0% {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg); } }

@keyframes uil-ring-anim {
  0% {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg); } }

.uil-ring-css {
  background: none;
  position: relative;
  width: 100%;
  height: 100%; }

.uil-ring-css > div#loading-img {
  position: absolute;
  display: block;
  width: 100px;
  height: 100px;
  top: 50%;
  left: 50%;
  margin: -50px;
  border-radius: 80px;
  box-shadow: 0 2px 1px 0 #48af4a;
  -ms-animation: uil-ring-anim 1s linear infinite;
  -moz-animation: uil-ring-anim 1s linear infinite;
  -webkit-animation: uil-ring-anim 1s linear infinite;
  -o-animation: uil-ring-anim 1s linear infinite;
  animation: uil-ring-anim 1s linear infinite; }

.fixed--body {
  position: fixed; }

/* Message */
section.message {
  width: 100%;
  position: fixed;
  color: #fff;
  top: -100%;
  background: rgba(255, 0, 0, 0.7);
  z-index: 1000;
  -webkit-transition: top .5s ease-in-out;
  -moz-transition: top .5s ease-in-out;
  -o-transition: top .5s ease-in-out;
  transition: top .5s ease-in-out; }

section.message.active {
  top: 40px; }

section.message > p {
  text-align: center; }

/* Init laod animation body */
@-webkit-keyframes fade {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@-moz-keyframes fade {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@-o-keyframes fade {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes fade {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

body {
  animation-name: fade;
  animation-duration: 2s; }

figure {
  margin: 0; }

.text-center {
  text-align: center; }

.row {
  margin: 0 -15px; }

.row:before, .row:after {
  display: table;
  content: " ";
  clear: both; }

.modal-overlay {
  position: fixed;
  _position: absolute;
  width: 100%;
  background: rgba(40, 40, 40, 0.65);
  z-index: 100;
  top: 0;
  width: 100%;
  height: 100%;
  margin-left: 0;
  margin-top: 0; }

.modal.tnc {
  display: none;
  position: fixed;
  _position: absolute;
  width: 965px;
  background: #f2f2f2;
  z-index: 100;
  margin-left: 15px;
  margin: 0 auto;
  top: 50%;
  left: 50%;
  margin-top: -200px;
  /* Negative half of height. */
  margin-left: -482.5px;
  /* Negative half of width. */
  font-size: 15px;
  height: 490px;
  -moz-box-shadow: 0 0 5px #ff0000;
  -webkit-box-shadow: 0 0 5px #ff0000;
  box-shadow: 0 0px 30px 0px rgba(140, 140, 140, 0.85); }
  .modal.tnc .main--title {
    text-transform: uppercase; }
  .modal.tnc .modal--title {
    margin: 0; }
  .modal.tnc .checkmark__circle {
    stroke: #f5a523;
    fill: #f5a523;
    stroke-dasharray: 166;
    stroke-dashoffset: 166;
    stroke-width: 2;
    stroke-miterlimit: 10;
    animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards; }
  .modal.tnc .checkmark {
    top: -65px;
    left: 30px;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    display: block;
    stroke-width: 2;
    stroke: #fff;
    stroke-miterlimit: 10;
    margin: 10% auto;
    box-shadow: inset 0px 0px 0px #B62025;
    animation: fill .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both; }
  .modal.tnc .checkmark__check {
    transform-origin: 50% 50%;
    stroke-dasharray: 29;
    stroke-dashoffset: 29;
    animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards; }
  .modal.tnc h3.title {
    font-family: 'Open Sans', sans-serif;
    background: #B62025;
    width: 380px;
    font-size: 14px;
    padding: 15px;
    color: white;
    text-transform: uppercase;
    letter-spacing: 4.5px;
    margin-top: -46px; }
  .modal.tnc .modal--content {
    padding: 15px 0; }
  .modal.tnc li {
    font-weight: 300;
    line-height: 1.62em;
    letter-spacing: 1.5px;
    font-family: "Open Sans", Helvetica, sans-serif;
    font-size: 0.875em; }
  .modal.tnc .column-block {
    padding: 3.5px 50px; }
    .modal.tnc .column-block h4 {
      font-size: 14px;
      font-weight: bold;
      letter-spacing: 1.5px;
      color: #697a8b; }
    .modal.tnc .column-block .tnc-wrapper {
      height: 320px;
      overflow-y: scroll;
      background: #fafafa;
      padding: 2.5px 22px;
      width: 100%;
      position: relative;
      top: -60px;
      margin-bottom: -50px; }
    .modal.tnc .column-block a {
      background: #B62025;
      border-radius: 5px;
      color: #fff;
      padding: 22.5px;
      display: block;
      text-transform: uppercase;
      font-weight: 700;
      margin-top: 15px;
      text-align: center;
      width: 100%; }

@media only screen and (max-width: 768px) {
  .modal.tnc {
    position: fixed;
    _position: absolute;
    width: 100%;
    background: #f2f2f2;
    z-index: 100;
    margin-left: 15px;
    margin: 0 auto;
    top: 140px;
    left: 0;
    font-size: 15px;
    height: 490px;
    box-shadow: 0 0 30px 0 rgba(140, 140, 140, 0.85); }
    .modal.tnc a.removebutton {
      opacity: 0; }
    .modal.tnc h3.title {
      position: relative;
      top: 1.25px;
      width: 100%; }
    .modal.tnc .column-block {
      padding: 10px; }
      .modal.tnc .column-block h4 {
        float: none;
        position: relative;
        left: 55px;
        text-align: center;
        top: -30.5px;
        padding: 0;
        line-height: 17px; }
  section.message.active {
    top: 60px; } }

@media only screen and (max-width: 320px) {
  .modal.tnc {
    transform: scale(0.9);
    top: 100px; }
    .modal.tnc h4 {
      float: none;
      font-size: 10px;
      position: relative;
      left: 55px;
      text-align: center;
      top: -23.5px;
      padding: 0;
      line-height: 17px; } }
