#gofood {
	.goresto {

		.pure-g [class*=pure-u] {
		  padding: 0 5px;
		}

		.messages-alert {
			position: fixed;
			display: none;
			background-color: $gofood;
			width: 100%;
			padding: 20px;
			margin-top: 0px;
			z-index: 99;
			margin-bottom: -80px;

			h5 {
				color: white;
				font-size: 15px;
				margin: 0;
				padding: 0;
			}
		}

		#content-only {
			.container {
		    box-shadow: 0 -3em 0 1.5em $gofood;

				.info {
					h4 {
						color: $gofood;
					}
				}
			}
		}

		.upload-list {
			.checkmark__circle {
		    stroke: #f5a523;
		    fill: #f5a523;
		    stroke-dasharray: 166;
		    stroke-dashoffset: 166;
		    stroke-width: 2;
		    stroke-miterlimit: 10;
		    animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
		  }
		  .checkmark {
				top: -25px;
				left: 30px;
				width: 50px;
				height: 50px;
				border-radius: 50%;
				display: block;
				stroke-width: 2;
				stroke: #fff;
				stroke-miterlimit: 10;
				margin: 10% auto;
				box-shadow: inset 0 0 0 #b62025 !important;
				// -webkit-animation: c .4s ease-in-out .4s forwards,b .3s ease-in-out .9s both;
				// animation: c .4s ease-in-out .4s forwards,b .3s ease-in-out .9s both;
		  }
		  .checkmark__check {
		    transform-origin: 50% 50%;
		    stroke-dasharray: 29;
		    stroke-dashoffset: 29;
		    animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
		  }
		}

		@-webkit-keyframes uil-ring-anim {
			0% {
				-webkit-transform: rotate(0deg);
				transform: rotate(0deg);
			}
			100% {
				-webkit-transform: rotate(360deg);
				transform: rotate(360deg);
			}
		}
		@-moz-keyframes uil-ring-anim {
			0% {
				-webkit-transform: rotate(0deg);
				transform: rotate(0deg);
			}
			100% {
				-webkit-transform: rotate(360deg);
				transform: rotate(360deg);
			}
		}
		@-webkit-keyframes uil-ring-anim {
			0% {
				-webkit-transform: rotate(0deg);
				transform: rotate(0deg);
			}

			100% {
				-webkit-transform: rotate(360deg);
				transform: rotate(360deg);
			}
		}
		@-o-keyframes uil-ring-anim {
			0% {
				-webkit-transform: rotate(0deg);
				transform: rotate(0deg);
			}
			100% {
				-webkit-transform: rotate(360deg);
				transform: rotate(360deg);
			}
		}
		@keyframes uil-ring-anim {
			0% {
				-webkit-transform: rotate(0deg);
				transform: rotate(0deg);
			}
			100% {
				-webkit-transform: rotate(360deg);
				transform: rotate(360deg);
			}
		}
		.uil-ring-css {
			background: none;
			position: relative;
			width: 200px;
			height: 200px;
			margin: 0 auto;
			display: block;

			div {
				position: absolute;
				display: block;
				width: 160px;
				height: 160px;
				top: 20px;
				left: 20px;
				border-radius: 80px;
				box-shadow: 0 3px 0 0 $gofood;
				-webkit-transform-origin: 80px 81.5px;
				transform-origin: 80px 81.5px;
				-webkit-animation: uil-ring-anim 1s linear infinite;
				animation: uil-ring-anim 1s linear infinite;
			}
		}

		button, .button {
			background: $gofood;
			color: white;
		}

		.loader,
		.loader-addmore {
			display: none;
			background: white;
		}

		.loader {
	    position: relative;
	    z-index: 10;
	    width: 100%;
	    height: 100%;
	    top: 0;
	    margin: 0;
	    opacity: 0.8;

	    .loader-wrapper {
	    	top: 0;
		    position: relative;
		    background-color: #fff;
	    }
		}

		section.call-navigation {
			.container {
				.box {
					color: white;
					display: inline-block !important;
					background-color: transparent;
					margin-left: auto;
					margin-right: auto;
					position: relative;
					top: 30px;
					border-radius: 7.5px;
					padding: 25px;

					a {
						text-transform: uppercase;
						letter-spacing: .1;
						color: #fff;
						padding: 22px 30px;
						background: #babcbf;
						text-decoration: none;
						border-top-left-radius: 5px;
				    border-bottom-left-radius: 5px;
						margin: 0 -5px;
					}

					a.active {
						border-top-left-radius: 0px;
				    border-bottom-left-radius: 0px !important;
						border-top-right-radius: 5px;
				    border-bottom-right-radius: 5px;
						font-weight: 600;
						background-color: $gofood;
					}
				}
			}
		}

		section.search-resto {
			background: #FAFAFA;

			.join--link {
				text-align: center;
		    margin-bottom: 30px;
		    p {
		    	display: inline-block;
		    }
		    a {
    	    color: #b52126;
			    font-weight: bold;
			    text-indent: 5px;
			    display: inline-block;
		    }
		    a:hover {
		    	text-decoration: underline;
		    }
		    a.button {
		    	color: #FFFFFF;
		    }
			}

			.container {
				position: relative;

				// &:before {
				// 	content: "";
			  //   position: absolute;
			  //   left: -1em;
			  //   top: 2.55em;
			  //   padding-right: 45px;
	      //   height: 3px;
			  //   margin: 0;
			  //   background-color: $gofood;
	      //   z-index: 10;
				// }
			}

			h4 {
				color: $gofood;
				text-transform: uppercase;
				padding-left: 15px;
				padding-bottom: 2em;
				margin-left: 35px;
    			margin-top: 7.5px;
			}

			.wrapper {
				width: 100%;
		    margin: 0;
				border-radius: 5px;
		    padding-bottom: 0;
		    background-color: $gofood;
		    position: relative;

				.form-wrapper {
					text-align: center;

					fieldset {
				    padding: 20px 0;

						label {
							margin-right: 35px;
							position: relative;
							top: 2.5px;

							i {
								font-size: 32px;
							}
						}
					}

					button {
						padding: 0;
						margin: 0 auto;
						margin-bottom: -30px;

						span {
							margin-left: 15px;

							i {
								margin-left: 5px;
							}
						}
					}

					input {
						width: 75%;
						padding: 0 15px;
						height: 45px;
						border: 1px solid #c3c9d7;
				    font-family: 'Open Sans';
				    font-style: italic;
				    letter-spacing: 3px;
				    color: #babcbf;
				    font-size: 12px;
					}

					input::-webkit-input-placeholder {
						color: rgb(205, 205, 205);
						font-style: italic;
					}

					.addmore-outlet {
						color: $gofood;
					}
				}
			}
		}

		section.result-resto {
			margin: 0 0 50px;

			[data-collapse="data-outlet"] {
				.merchant {
					box-shadow: 0 2px 8px 0 #dbdbdb;
			    height: 450px;
			    overflow-y: scroll;
			    position: absolute;
			    z-index: 20;
			    top: 108px;
			    width: 100%;
				}
			}

			.join--link {
				text-align: center;
		    p {
		    	display: inline-block;
		    }
		    a {
    	    color: #b52126;
			    font-weight: bold;
			    text-indent: 5px;
			    display: inline-block;
		    }
		    a:hover {
		    	text-decoration: underline;
		    }
		    a.button {
		    	color: #FFFFFF;
		    }
			}

			#goresto-form {
		    position: relative;
		    margin: 0 -5px;

    		& > .form-container {
    			.loader {
    				position: absolute;

	    			& > .loader-wrapper {
					    top: 50%;
	    				transform: translateY(-50%);
	    			}
    			}
    		}

				.personal-wrapper--fields {
					display: none;
				}

				#procuration-wrapper--file {
					display: none;
				}
			}

			.panel {
				img.resto-claim-header {
					display: none;
				}
				.text {
					display: none;
					margin-top: -5px;
					background: $gofood;
					text-align: center;
					z-index: -1;
					padding: 10px 35px;

					h4 {
						color: white;
					}

					p, a {
						font-weight: 300;
						color: white;
						margin-top: -20px;
    					margin-bottom: 25px;
					}

					.link.notfound {
						display: none;
					}
				}

				.body {
					display: none;
			    padding: 0px 0px;
					background: transparent;
			    position: relative;

					.list-addmore {
						display: none;
						position: relative;
						top: 20px;
						text-align: center;
						z-index: 11;
    				position: relative;

						a.button {
							margin-top: 0px;
							border-radius: 5px;
							padding: 20px;
							float: none;
							text-align: center;
							display: inline-block;
					    text-transform: uppercase;

							span {
								margin: 0 65px;
							}
						}
					}

					.merchant {
						margin-top: -40px;
						background: white;
						padding: 30px 40px;

						.join--link {
					    background: #b52126;
					    color: #fff;
					    text-align: center;
					    padding: 25px 0;
					    margin: 0 -40px -30px;
					    text-align: center;

					    h3 {
					    	margin: 0 0 10px;
					    }
					    a {
					    	color: #fff;
					    }
						}

						.list {
							h4, h5, p {
								margin: 0;
								padding: 0;
							}

							h5 {
								font-weight: 300;
								line-height: 1.6;
								letter-spacing: 0.5px;
								color: #9b9b9b;
							}

							p {
								font-weight: 300;
								object-fit: contain;
								line-height: 1.6;
								letter-spacing: 0.5px;
								color: #4a4a4a;
							}

							hr {
								margin: 15px 0;
								border: 0;
						    height: 1px;
						    background: rgba(200, 200, 200, .25);
							}

							.item--wrapper {
								position: relative;
								margin: 35px 0;

								a.button {
							    border-radius: 5px;
    							display: table;
    							height: 55px;
							    margin: 0;
							    position: absolute;
							    right: 0;
							    bottom: 0;
							    width: 170px;
							    text-transform: uppercase;
							    text-align: center;
							    font-size: 14px;
							    font-weight: bold;
							    line-height: 1.03;
							    letter-spacing: 2px;
							    transition: all 0.3s ease-in-out;

									&.claimed {
	    							background: #9b9b9b;
	    							cursor: no-drop;
	    							pointer-events: none;
									}

									& > span {
										display: table-cell;
										vertical-align: middle;
									}
								}
								a.button:hover {
							    background: #fff;
							    border: solid 3px #b62025;
							    color: #b62025;
								}
							}
						}
					}

					.merchant-info {
						background: white;
						padding: 30px 40px;

						.found {
							h4,
							p {
								color: $gofood;
							}
						}

						.not-found {
							svg {
								margin-top: 50px;
							}

							h4 {
								color: $gofood;
						    line-height: 35px;
							}

							p {
								font-weight: normal;
								font-style: normal;
								font-stretch: normal;
								line-height: 2.05;
								letter-spacing: 0.7px;
								text-align: center;
								color: #4a4a4a;
							}

							a {
								padding: 20px 40px;
								border-radius: 5px;
								position: relative;
						    bottom: -75px;
							}
						}

						h4 {
							font-size: 25px;
							font-weight: bold;
							letter-spacing: 0.9px;
							text-align: center;
						}

						p {
							margin-top: -20px;
						}
					}

					.form {
						display: none;

						.identity-other {
							display: none;
						}

						.loader-step--wrapper {
					    width: 100%;
					    height: 10px;
					    background: #c3c1c1;
					    position: relative;

					    .loader-step {
			    	    position: absolute;
						    width: 0%;
						    height: 100%;
						    background: #48af4a;
						    transition: width .3s ease-in-out;
					    }
						}

						hr.form-separator {
							position: relative;
							transform: scale(1.025);
							top: 0px;
							border-color: red;
							display: block;
							height: 1px;
							border: 0;
					    border-top: 1px solid rgb(220, 220, 220);
							margin: 3em 0 2em;
							padding: 0;
						}

						.addmore-outlet {
							object-fit: contain;
							border-radius: 5px;
							background-color: #b62025;
							display: block;
							width: 100%;
							padding: 22px 0;
							font-family: $opensans;
							font-size: 12px;
							font-weight: bold;
							line-height: 1.2;
							letter-spacing: 2.2px;
							text-align: center;

						}

						.side-two-column {
							margin-right: 22px;
						}

						[data-step="2"],
						[data-step="3"] {
							position: relative;
							left: 310px !important;
							display: none;
						}

						[data-step="2"] {
							.group-dummy {
								opacity: 0;
							}
						}

						.form-container {
							position: relative;
							left: 60px;
						}

						.collapse--wrapper {
							.delete-outlet {
								font-size: 12px;
						    font-weight: 600;
						    letter-spacing: 1.3px;
						    float: right;
						    color: #a50d1c;
						    display: table;
						    cursor: pointer;

						    img {
						    	display: table-cell;
							    vertical-align: middle;
							    margin-right: 10px;
						    }
						    span {
						    	display: table-cell;
    							vertical-align: middle;
						    }
							}
							.head {
								transition: all 0.3s ease;
								cursor: pointer;
								opacity: .45;

								.panel {
									h4 {
										object-fit: contain;
										font-family: $opensans;
										font-size: 18px;
										font-weight: 300;
										color: #4a4a4a;

										&.error {
											color: $gofood;

											span.changetitle {
												color: $gofood;
											}
										}

										span.changetitle {
											font-size: 18px;
											font-weight: 300;
											color: #4a4a4a;
										}

										span.icon {
											float: right;

											i {
												font-size: 20px;
												position: relative;
												top: 2.5px;
												left: -2.5px;
											}
										}
									}

									hr {
										position: relative;
										transform: scale(1.025);
										top: -15px;
										border-color: red;
										display: block;
										height: 1px;
										border: 0;
										border-top: 1px solid #ccc;
										margin: 1em 0;
										padding: 0;
									}
								}

								&:hover {
									opacity: 1.0;
								}
							}

							.collapse-body {
								margin-top: -25px;
								display: none;
							}
						}

						.collapse--wrapper.active {
							.head {
								opacity: 1.0;

								.panel {
									h4 {
										color: $gofood;
										font-weight: 600;
										line-height: 25px;

										span.changetitle {
											color: $gofood;
											font-weight: 600;
										}
									}

									hr {
										display: none;
									}

									span.separator {
										display: inline-block;
								    background: $gofood;
								    width: 40px;
								    height: 4px;
								    position: relative;
								    top: -4.25px;
								    margin-right: 10px;
									}

									span.icon {
										i:before {
  										content: "\f068";
										}
									}
								}
							}

							.collapse-body {
								margin-top: -10px;
								margin-bottom: 40px;
								display: block;
							}
						}



						.outlet-list {
							hr {
						    border: 0;
						    height: 0;
						    border-top: 1px solid rgba(0,0,0,.085);
						    margin-bottom: 30px;
						    border-bottom: 1px solid hsla(0,0%,100%,.2);
							}

							[data-outlet] {
								margin-bottom: 60px;

								.remove-outlet {
									color: $gofood;
									float: right;
									position: relative;
									top: -5px;
								}

								.form-wrapper {
									.text-wrapper {
										.title {
											top: 40px;
											left: 0;
											margin-bottom: 45px;
											text-transform: uppercase;
										}
									}
								}
							}
						}

						.outlet-search {
							display: none;

							.form-wrapper {
								.text-wrapper {
									.title {
										top: 40px;
										left: 0;
										margin-bottom: 45px;
										text-transform: uppercase;
									}
								}
							}

							.outlet-search-list-wrapper {
						    padding: 0px 50px;

								.outlet-search-list {
									box-shadow: rgba(20, 20, 20, .15) 0px 5px 20px;
									margin-top: -30px;
							    margin-bottom: 80px;

									.outlet-search-list-loader {
										display: none;
									}

									.outlet-search-not-found {
										display: none;
										text-align: center;
										padding: 50px;
										font-family: $opensans;
										font-weight: 300;

										span {
											font-weight: 700;
										}
									}

									.list {
										margin-bottom: 0px;
										padding: 15px 35px;

										h4, h5, p {
											margin: 0;
											padding: 0;
										}

										h4 {
									    line-height: 18px;
										}

										h5 {
											line-height: 1.6;
											letter-spacing: 0.5px;
											color: #9b9b9b;
									    font-size: 11px;
									    margin-top: 5px;
										}

										p {
											object-fit: contain;
											line-height: 1.6;
											letter-spacing: 0.5px;
											color: #4a4a4a;
										}

										hr {
											position: relative;
											top: 27px;
											margin: 15px 0;
											border: 0;
									    height: 1px;
									    background: rgba(200, 200, 200, .25);
										}

										a.button {
											margin-top: -50px;
											border-radius: 5px;
											padding: 20px;
											float: right;

											span {
												margin: 0 25px;
											}
										}
									}
								}
							}
						}

						.outlet-search.active {
							display: block;
						}

						.addmore-outlet {
							h4 {
								float: right;
								color: $gofood;
								margin-right: 50px;
						    margin-top: -10px;
							}
						}
						.main-wrapper {
							height: auto;
							width: 100%;
							background: white;
							position: relative;
							left: 0px;

							.button-disabled {
								cursor: not-allowed;
								box-shadow: none !important;
								background: rgb(200, 200, 200) !important;
							}

							.separator-title {
								position: relative;
								left: 50px;
								top: 0px;
								color: rgba(200, 200, 200, 1.0);
								margin-top: 55px;
								margin-bottom: -20px;
							}

							.upload-type-file {
								opacity: .0;
							}
							.upload-wrapper {
								margin-top: 15px;

								.title {
									color: #697b8c;
									font-family: $opensans;
									font-weight: 700;
									text-transform: uppercase;
									letter-spacing: 1.25px;
									font-size: 12.5px;
									margin-bottom: 10px;
								}
								.subtitle {
									color: #697b8c;
									font-family: $opensans;
									font-weight: 700;
									text-transform: none;
									letter-spacing: 1.25px;
									font-size: 12.5px;
									margin-bottom: 10px;
								}
								.upload-loader {
									display: none;
									margin-top: -90px;
									color: rgb(200, 200, 200);
									font-size: 12px;
									text-align: center;
								}
								.upload-box {
									margin-top: 0px;
									height: auto;
									overflow-y: hidden;
									padding: 30px;
									border: 1px solid rgb(220, 220, 220);

									.list {
										cursor: pointer;
										.list.plus {
											background: $gofood;
											padding: 20px;
											font-size: 40px;
											color: white;
											font-weight: 700;
											span {
												margin: 0 20px;
											}
										}
									}
									.ilus-first
									{
										text-align: center;
										width: 50%;
										float: left;
										> div
										{
											width:100%;
											margin: auto;
										}
										img
										{
											max-width: 100%;
										}
										img[data-image]
										{
											display: none;
										}
										@media only screen and (max-width: 768px) {
											width:100%;
										}
									}
									.upload-first {
										width: 100%;
    									float: right;
										color: rgb(200, 200, 200);
										font-size: 12px;
										text-align: center;
										&.identity-select
										{
											width: 50%;
										}
										ul
										{
											text-align: left;
    										font-size: 16px;
    										margin: 0px;
    										@media only screen and (max-width: 768px) {
												font-size: 14px;
												padding-left: 15px;
												li
												{
													margin-bottom: 5px;
												}
											}
										}
										div[data-identity]
										{
											display: none;
										}
										.icon-preview {
											display: none;
										}

										.button-upload-first {
											width: 130px;
											background: $gofood;
											cursor: pointer;
											margin: 25px auto 0 !important;
											display: block;
											border-radius: 5px;
											padding: 15px;
											color: white;
											span {
												margin: 0 20px;
											}
										}
										@media only screen and (max-width: 768px) {
											width:100% !important;
											float: none;
										}
									}

									.upload-list {
										display: none;
										.list.plus {
											background: $gofood;
											padding: 20px;
											font-size: 40px;
											color: white;
											font-weight: 700;
											position: relative;
											span {
												margin: 0 20px;
											}
										}
										.box {
											border: 1px solid rgb(200, 200, 200);
											height: 200px;
											position: relative;
											margin: 0 5px;
											margin-bottom: 10px;
											margin-top: 20px;
											img {
												position: absolute;
												top: 50%;
												left: 50%;
												max-height: 100%;
												transform: translate(-50%, -50%);
											}
										}
										.box.addmore {
											padding: 50px;
											background: $gofood;
											height: 150px;
											position: relative;
											margin: 0 5px;
											margin-bottom: 10px;
											label {
												font-size: 70px;
												position: absolute;
												top: 45%;
												left: 50%;
												max-height: 100%;
												transform: translate(-50%, -50%);
											}
										}
									}
								}
							}

							.text-wrapper {
								.title {
									margin-top: 30px;
									font-size: 12px;
									position: relative;
									left: 50px;
									top: 10px;
									color: rgba(200, 200, 200, 1.0);
								}
							}

							.background-wrapper {
								background: url('https://bilba.go-jek.com/dist/img/card/join-form/gofood-texture.jpg?ver=1') #b81800;
								min-height: auto;
								height: 100% !important;
								width: 245px;
								padding: 0;
								position: absolute;
								text-align: center;
								left: 0;

								img {
									min-width: 60px;
									position: relative;
									margin-top: 50%;
									top: 35%;
									transform: translateY(-50%);
								}
							}

							.form-wrapper {
								padding: 0 40px;

								.upload-button-wrapper {
									p {
										margin: 0;
									}
								}

								.title {
									margin-top: -15px;
									font-size: 12px;
									position: relative;
									top: 10px;
									color: rgba(200, 200, 200, 1.0);
								}

								.group {
									width: 100%;
									margin-top: 15px;
									margin-bottom: 15px;
							    position: relative;

									.tnc-wrapper {
										width: 100%;
										height: 145px;
										padding: 15px;
										margin-top: 5px;
										letter-spacing: 1.2px;
										font-size: 13.4px;
										background: white;
										border-radius: 0 !important;
										border: 1px solid rgb(210, 210, 210);
										color: #485460;
								    overflow-y: scroll;
    								overflow-x: hidden;
									}

									label {
										text-transform: uppercase;
										font-family: "Open Sans", sans-serif;
										font-weight: 700;
										font-size: 11.5px;
										letter-spacing: 1.5px;
										color: #697b8c;
									}

									h6 {
										font-size: 14px;
										font-weight: 600;
										font-style: italic;
										line-height: 1.43;
										letter-spacing: 1.5px;
										color: #babcbf;
										margin-top: 7.5px;
								    margin-bottom: 0;
									}

									i.arrow {
										float: right;
								    z-index: 2;
								    position: absolute;
								    top: 35px;
								    color: #b4b4b4;
								    right: 15px;
									}

									input[type=text],
									input[type=number],
									input[type=password],
									input[type=email],
									select {
										width: 100%;
										height: 45px;
										padding: 15px;
										margin-top: 5px;
										letter-spacing: 1.2px;
										font-size: 13.4px;
										background: white;
										border-radius: 0 !important;
										border: 1px solid rgb(210, 210, 210);
										color: #485460;
									}

									input[type=number]::-webkit-inner-spin-button,
									input[type=number]::-webkit-outer-spin-button {
								    -webkit-appearance: none;
								    -moz-appearance: none;
								    appearance: none;
								    margin: 0;
									}

									input[type="radio"]{
								    display: none;
									}

									input[type="radio"] + label {
								    background: transparent;
										border: 1px solid rgb(200, 200, 200);
										border-radius: 50%;
										font-weight: 500;
								    height: 24px;
								    width: 24px;
										text-transform: unset;
								    display: inline-block;
								    padding: 0 0 0 0px;
										cursor: pointer;
										margin: 15px 0;
										position: relative;
									}

									input[type="radio"]:checked + label:before {
								    content: "";
								    background: $gofood;
								    padding: 0;
								    width: 15px;
								    height: 15px;
								    top: 50%;
								    left: 50%;
								    position: absolute;
								    border-radius: 100%;
								    transform: translate(-50%, -50%);
									}

									input[type="checkbox"] {
										display: none;
									}

									input[type="checkbox"] + label:before {
										content: "";
								    background: #fff;
								    border: 2px solid #ddd;
								    display: inline-block;
								    vertical-align: middle;
								    width: 15px;
								    height: 15px;
								    padding: 2px;
								    margin-right: 10px;
								    text-align: center;
									}
									input[type="checkbox"]:checked + label:before {
								    content: "\f00c";
								    font-size: 14px;
								    font-family: 'FontAwesome';
								    background: #fff;
								    color: $gofood;
									}

									span.radio-label {
										position: relative;
										top: 1.75px;
								    left: 40px;
										font-family: $opensans;
										font-size: 14px;
										letter-spacing: 1.3px;
										color: #485460;
									}

									input[disabled],
									textarea[disabled],
									select[disabled] {
										background-color: rgb(245, 245, 245);
									}

									select {
										cursor: pointer;
										appearance: none;
										-webkit-appearance: none;
									}

									textarea {
										width: 100%;
										height: 145px;
										padding: 15px;
										margin-top: 5px;
										letter-spacing: 1.2px;
										font-size: 13.4px;
										background: white;
										border-radius: 0 !important;
										border: 1px solid rgb(210, 210, 210);
										color: #485460;
									}


									.icon {
										float: right;

										i {
											position: relative;
											left: -15px;
											top: 35px;
											font-size: 12px;
											color: rgb(210, 210, 210);
										}
									}
								}
							}

							.form-wrapper.second-column {
								position: relative;
								left: -5px;
							}

							.error_messages {
								color: red;
								letter-spacing: .1px;
								margin-top: 10px;
							}

							.form-button {
							  text-align: right;
			 				  position: relative;
			 				  top: 30px;
								left: -50px;

			 				 .button {
			 					 font-size: 14px;
			 					 font-family: $opensans;
			 					 font-weight: 400;
			 					 padding: 24px;
								 border-radius: 5px;
			 					 transition: all .5s ease 0s;
			 					 margin: 0 5px;
			 					 color: #fff;
		 					 	 box-shadow: 0 2px 10px 0 rgba(0, 0, 0, .65);
								 margin-top: 60px;
			 					 text-transform: none;
			 					 float: none;
								 display: inline-block;

			 	 				 span {
			 	 					 margin: 0 50px;
			 	 				 }
			 				 }
			 				 .button:hover,
			 				 .button:focus {
			 					 box-shadow: 0 5px 15px 0 rgba(0, 0, 0, .85);
			 				 }

							 .button-show {
								 display: inline-block !important;
							 }
							 .button-hide {
								 display: none !important;
							 }

			 				 .button-submit,
							 .button-next {
			 					 background: $gofood;
								 display: inline-block;
			 				 }
							 .button-next {
						     padding: 28px;
							 }
							 .button-back {
								 background: #353b44;
								 display: none;
							 }
							 .button-submit {
								//  display: none;
							 }
							 .button-back.disable,
							 .button-next.disable,
							 .button-submit.disable {
								 background: rgb(200, 200, 200);
							 }
			 			 }
						}
					}
				}
			}
		}

		section.about {
			.container::before {
				background-color: $gofood;
			}

			h4 {
		    color: $gofood;
		    text-transform: uppercase;
			}

			a {
				color: $gofood;
				text-transform: uppercase;
				position: relative;
			}
		}

		.section-our-values {
			padding: 80px 0px;

			.title {
				color: $gofood;
			}

			.line.gradient {
				background: $gofood;
			}

			.list {
				padding: 0 5px;

				img {
					max-width: 90px;
				}

				img.small {
					max-width: 120px;
				}

				img.seq-1 {
				  margin-top: 58px;
				}

				img.seq-3 {
					margin-top: 42px;
				}

				img.seq-4 {
					margin-top: 64px;
				}
			}
		}

		section.services {
			h4 {
				color: $gofood;
			}

			.wrapper {
				.service-container {
					background: $gofood;
					background-image: none;
				}
			}

			.service-list {
				h5 {
					color: $gofood;
				}

				p {
					margin-bottom: -50px;
				}

				a {
					color: $gofood;
				}

				a:after {
					background-image: none;
				}
			}
		}

		.modal.welcome {
			display: none;
			position: fixed;
			_position: absolute;
			width: 965px;
			background: #FFFFFF;
			z-index: 100;
			margin-left: 15px;
			margin: 0 auto;
			top: 50%;
			left: 50%;
			margin-top: -200px; /* Negative half of height. */
			margin-left: -482.5px; /* Negative half of width. */
			font-size: 15px;
			height: 450px;
			-moz-box-shadow: 0 0 5px #ff0000;
			-webkit-box-shadow: 0 0 5px #ff0000;
			box-shadow: 0 0px 30px 0px rgba(140, 140, 140, .85);

			.column-left {
				text-align: center;
				padding: 15px 0;

				h1 {
					font-weight: 300;
					font-size: 28px;
					letter-spacing: 5px;
				}

				p {
					font-size: 15.5px;
					font-weight: 400;
					margin-bottom: 20px;
				}

				.button-wrapper {
					margin: 40px 0;

					p {
						margin: 30px 0;
					}

					a.button {
						background: $gofood;
						padding: 20px 30px;
						margin-bottom: 20px;
					}
				}
			}

			.column-right {
				background-size: cover;
				background-repeat: no-repeat;
				width: 100%;
				height: 450px !important;
				position: relative;
		    box-shadow: 40px -40px 0 $gofood;
			}
		}
	}
}

@media only screen and (max-width: 768px) {
	#gofood {
		.goresto {
			header#main--hero {
				.hero--title {
					position: relative;
					top: -15px;
				}

				button.download {
					bottom: unset;
				}
			}

			.captcha-wrapper {
				margin-top: 80px;
			}

			.checkbox-wrapper {
				padding: 0 !important;
				margin-top: 50px;
				margin-bottom: -40px;
			}

			#content-only {
				padding-top: 70px;
				padding-bottom: 30px;
			}

			section.search-resto {
				padding: 0;
				margin-top: -4px;
		    position: relative;
    		z-index: 1000;

    		.container {
    			padding: 0;
    		}

				.wrapper {
					border-radius: 0;

					.form-wrapper {
						padding: 0;

						fieldset {
							padding: 10px;

							label {
								display: unset;
								margin-right: 15px;
								position: relative;
								top: 0px;

								i {
									display: none;
								}
							}

							input {
								width: 100%;
								display: unset;
								position: relative;
								top: 17px;
								border-radius: 0;
							}

							button {
								position: relative;
						    top: 18px;
						    margin-top: 10px;
						    padding: 15px;
						    background: #353b44;
						    border-radius: 5px;
							}
						}
					}
				}
			}

			section.result-resto {
				margin-top: 50px;
				padding: 0px;

				.container {
					padding: 0;

					.panel {
						.text {
							margin-top: -5px;
							background: $gofood;
							text-align: center;
							z-index: -1;
							padding: 32px;

							h4 {
						    line-height: 40px;
								font-size: 24px;
								font-weight: 300;
								font-family: $opensans;
								color: white;
							}

							p, a {
								font-weight: 300;
								color: white;
								margin-top: -25px;
		    					margin-bottom: 25px;
							}

							.link.notfound {
								display: none;
							}
						}

						.body {
					    padding: 0;

							.loader {
								margin-top: -90px;
							}

							[data-step="2"],
							[data-step="3"] {
								left: 0 !important;
								padding: 25px !important;
							}

							.form {
								.addmore-outlet {
									h4 {
										float: right;
										color: #b62025;
										font-size: 15px;
										margin-right: 0px;
										margin-top: -10px;
									}
								}

								.outlet-search  {
									.outlet-search-list-wrapper {
										padding: 0;

										.outlet-search-list {
											.list {
												a.button {
													position: relative;
											    top: 65px;
											    z-index: 9;
												}
											}
										}
									}
								}
							}

							.merchant-info {
								.not-found {
									a {
								    bottom: -95px;
									}
								}
							}

							.merchant {
								padding: 40px 0;

								.join--link {
									margin: 40px 0 0;
								  top: 45px;
								  position: relative;
								}

								.list {
									padding: 0px 35px;

									.item--wrapper {
								    margin: 0;
								    padding: 50px 0;
									}

									h4, h5, p {
										margin: 0;
										padding: 0;
									}

									h4 {
								    line-height: 18px;
									}

									h5 {
										line-height: 1.6;
										letter-spacing: 0.5px;
										color: #9b9b9b;
								    font-size: 11px;
								    margin-top: 5px;
									}

									p {
										object-fit: contain;
										line-height: 1.6;
										letter-spacing: 0.5px;
										color: #4a4a4a;
									}

									hr {
										position: relative;
										margin: 0;
										border: 0;
								    height: 1px;
								    background: rgba(200, 200, 200, .25);
									}

									a.button {
								    bottom: -30px;
    								z-index: 1000;

										span {
											margin: 0 25px;
										}
									}
								}
							}

							.main-wrapper {
								margin: 50px 0;
								height: auto;
								width: 100%;

								.separator-title {
									left: 0px;
									top: 0px;
								}

								.upload-wrapper {
									margin-top: 40px;
								}

								.background-wrapper {
									display: none;
									min-height: 100px;
									height: 100% !important;
									width: 100%;
									padding: 0;
									text-align: center;

									img {
										max-width: 25px;
										margin-top: 40px;
									}
								}

								.checkbox-wrapper {
									label {
										width: 100%;
										display: block;
									}
								}

								.text-wrapper {
									margin-bottom: 35px;
									margin-top: 10px;
									position: relative;
									left: -10px;

									.title {
										left: 9.5px;
										margin-top: -15px;
										font-size: 12px;
										position: relative;
										top: 10px;
										color: rgba(200, 200, 200, 1.0);
									}
								}

								.form-container {
									margin-bottom: 50px;
							    padding: 10px;
							    margin-left: 0px;
									left: 0;
									position: relative;
								}

								.form-wrapper {
									 padding: 0 !important;

									.group {
										margin-bottom: 20px;
										 width: 100%;
										 margin: 20px 0;

										 label {
							 				 font-family: $opensans;
							 				 font-weight: 700;
											 text-transform: uppercase;
											 letter-spacing: 1.25px;
							 				 font-size: 10.5px;
							 				 color: #697b8c;
							 			 }

										 h6 {
											 margin-bottom: -30px;
										 }

										 input,
										 select {
											 width: 100%;
											 height: 45px;
											 padding: 15px;
											 margin-top: 5px;
											 letter-spacing: 1.2px;
											 font-size: 13.4px;
											 background: white;
											 border-radius: 0 !important;
											 border: 1px solid rgb(210, 210, 210);
											 color: #485460;
										 }

										 .icon {
				 							float: right;

				 							i {
				 								position: relative;
				 								left: -15px;
				 								top: 35px;
				 								font-size: 12px;
				 								color: rgb(210, 210, 210);
				 							}
				 						}
								 	 }

									 .error_messages {
					 						color: red;
					 						margin-top: 10px;
									 }
							   }

								 .form-wrapper.second-column {
				 					position: relative;
				 					top: 10px;
									left: 0;
				 				}

								 .form-wrapper.first-column {
				 					margin-top: 40px;
									left: 0;
				 				}

								 .form-button {
									 position: relative;
									 text-align: center;
									 top: 0;
									 left: 0px;
									 padding: 12px;
									 margin-bottom: -110px;

									 .button {
										 font-size: 14px;
										 font-family: $opensans;
										 font-weight: 400;
										 padding: 25px;
										 transition: all .5s ease 0s;
										 margin: 0 5px;

						 				 span {
						 					 margin: 0 50px;
						 				 }
									 }

									 .button:hover,
									 .button:focus {
										 box-shadow: 0 2px 10px 0 rgba(0, 0, 0, .65);
									 }

									 .button-back {
										 background: #353b44;
										 margin-bottom: 20px;
										 padding: 20px;
									 }

									 .button-submit-go {
										 text-transform: none;
										 background: $gofood;
										 color: #fff;
										 float: none;
										 position: relative;
										 left: 30px;
										 padding: 24px;
										 margin: 0 auto;
										 display: block;
									}
							  }
							}
						}
					}
				}
			}

			section.about p {
		    margin-bottom: -100px;
			}

			section.search-resto {
				h4 {
					position: relative;
					left: -25px;
			    font-size: 11px;
					margin-top: 15px;
				}
			}

			section.call-navigation {
				.container {
					.box {
						a {
							margin: 0;
							margin-right: -5px;
							position: relative;
							top: -2.5px;
							padding: 15px 10.5px;
						}
					}
				}
			}

			.modal.welcome {
				background: transparent;
		    top: 52%;
				width: 100%;

				.column-left {
				  position: relative;
			    left: 240px;
			    top: 20px;
			    background: white;
			    padding: 20px 50px;

					.wrapper {
						position: relative;
				    left: 12.5px;
					}
				}

				.column-right {
					position: absolute;
					float: none;
			    left: 320px;
			    z-index: -9;
					box-shadow: -45px -45px 0 #b62025;
			    top: -15px;
			    height: 540px !important;
				}
			}

			.modal.tnc {
				position: fixed;
		    _position: absolute;
		    width: 100%;
		    background: #f2f2f2;
		    z-index: 100;
		    margin-left: 15px;
		    margin: 0 auto;
		    top: 140px;
				left: 0;
		    /* left: 50%; */
		    /* margin-top: -200px; */
		    /* margin-left: -482.5px; */
		    font-size: 15px;
		    height: 490px;
		    box-shadow: 0 0 30px 0 hsla(0,0%,55%,.85);


				a.removebutton {
					opacity: 0;
				}

				h3.title {
					position: relative;
    				top: 1.25px;
					width: 100%;
				}

				.column-block {
					padding: 10px;

					h4 {
						float: none;
						position: relative;
						left: 55px;
						text-align: center;
						top: -30.5px;
						padding: 0;
						line-height: 17px;
					}
				}
			}
		}
	}
}

@media only screen and (max-width: 480px) {
	#gofood {
		.goresto {

			.captcha-wrapper {
				margin-top: 80px;
			}

			section.search-resto {
				.join--link {
					p {
						position: relative;
						top: 15px;
					}
				}
			}

			section.result-resto {
				.container {
					.panel {
						.body {
							.main-wrapper {
								margin-bottom: 100px;

								.side-two-column {
									margin-right: 0px !important;
								}

								.upload-button-wrapper.saving-book {
									margin-top: -45px;
								}

								.form-button {
									left: 0px;
									top: 40px;

									.button {
										padding: 25px 0px !important;
									}

									.button-submit {
										padding: 20px 0px !important;
									}

									.button-back {
										padding: 20px 5px !important;
									}
								}
							}
						}
					}
				}
			}
		}
	}
}

@media only screen and (max-width: 400px) {
	#gofood {
		.goresto {
			section.result-resto {
				.container {
					.panel {
						.body {
							.main-wrapper {
								.form-button {
									left: 0px;

									.button {
										padding: 25px 0px !important;
									}

									.button-submit {
										padding: 20px 0px !important;
									}

									.button-back {
										padding: 20px 5px !important;
									}
								}
							}
						}
					}
				}
			}

			section.call-navigation {
				.container {
					.box {
						padding: 15px;

						a {
							font-size: 9px;
						}
					}
				}
			}

			.modal.welcome {
				transform: scale(.9);
		    left: 250px;
			}
		}
	}
}

@media only screen and (max-width: 320px) {
	#gofood {
		.goresto {
			header#main--hero {
				.desc--row {
					.sub-desc--row {
						position: relative;
						top: -70px;
					}
				}
			}

			section.call-navigation {
				.container {
					.box {
						padding: 10px;

						a {
							font-size: 7px;
						}
					}
				}
			}

			section.result-resto {
				.container {
					.panel {
						.body {
							.main-wrapper {
								.form-button {
									left: 0px;
									padding: 0;

									.button {
										padding: 25px 0px!important;
									}

									.button-submit {
										padding: 20px 0px !important;
									}

									.button-back {
								    padding: 20px 0px!important;
									}
								}
							}
						}
					}
				}
			}

			.modal.welcome {
				transform: scale(.795);
				left: 259px;

				.column-left {
					padding: 20px 20px;

					.wrapper {
						left: 0;
					}
				}

				.column-right {
					box-shadow: -105px -75px 0 #b62025;
				}
			}

			.modal.tnc {
				transform: scale(.9);
				top: 100px;

				h4 {
					float: none;
			    font-size: 10px;
			    position: relative;
			    left: 55px;
			    text-align: center;
			    top: -23.5px;
			    padding: 0;
			    line-height: 17px;
				}
			}
		}
	}
}
