#topup
{
	margin:0px;
	padding: 0px;
	font-family: $lato;
	background-color: #f4f4f4;
	.lang-option
	{
		position: relative;
		top: 0;
		width:100%;
		padding: 10px 0;
		display: inline-block;
		border-bottom: 1px solid #c3c9d7;
		background-color: #fbfbfb;
		a
		{
			font-size: inherit;
			color: inherit;
			letter-spacing: normal;
		}
		div.choose:first-child
		{
			border-right:1px solid #c3c9d7;
		}
		div.choose
		{
			position: relative;
			float: left;
			width:50%;
			text-align: center;
			font-size: 17px;
			font-weight:300;
			color: #697b8c;
			padding: 5px 0;
			transition: all 0.5s;
			&.active
			{
				font-weight: 700;
				color:#4a90e2;
				transition: all 0.5s;
				&:after
				{
					position:absolute;
					content: "";
					width: 100%;
					height: 3px;
					background: #4a90e2;
					bottom: -10px;
					left: 0;
				}
			}
		}
	}

	.content
	{
		display: inline-block;
		width: 100%;
		padding: 50px 20px;
		.title
		{
			margin-bottom: 30px;
			b
			{
				font-size: 17px;
			}
			p
			{
				color: #828282;
				font-size: 14px;
				letter-spacing: normal;
				margin: 5px 0;
			}
		}
		.choose-way
		{
			display: inline-block;
			width: 100%;
			margin-bottom: 20px;
			position: relative;
			z-index: 1;
			&.choose-bank
			{
				opacity: 0;
				transition: all 0.5s;
				transform: translateY(-90px);
				pointer-events: none;
				z-index: 0;
				&.active
				{
					opacity: 1;
					transition: all 0.5s;
					transform: translateY(0px);
					pointer-events: visible;
				}
			}
			b
			{
				letter-spacing: 1.1px;
				text-transform: uppercase;
				font-size: 12px;
				color: #697b8c;
			}
			.container-select
			{
				margin-top: 10px;
			    width: calc(100% + 20px);
			    background-color: #fbfbfb;
			    position: relative;
			    left: -10px;
			    select
			    {
			    	border-radius: 0px;
			    	border:1px solid #ececec;
			    	font-size: 14px;
			    	padding: 10px 20px;
			    	width: 100%;
			    	letter-spacing: 1.1px;
			 		text-transform: uppercase;
			 		-webkit-appearance: none;
			    }
			    .arrow
			    {
			    	color: #7e7e7e;
			    	top: 10px;
				    position: absolute;
				    right: 20px;
				    font-size: 14px;
			    }
			}

		}

		.show-content
		{
			display: none;
			border-top:2px solid #4a90e2;
			position: relative;
			width: calc(100% + 20px);
			left: -10px;
			padding: 20px 10px;
			background: #fbfbfb;
			opacity: 0;
			pointer-events: none;
			transform: translateY(-90px);
			&.down
			{
				transform: translateY(0px);
			}
			&.active
			{
				display: block;
				opacity: 1;
				pointer-events: visible;
			}
			ol.step
			{
				padding-left: 60px;
				counter-reset: section;
				li
				{
					font-size: 14px;
					list-style-type: none;
					position: relative;
					padding-bottom: 30px;
					p
					{
						letter-spacing: normal;
						font-size: 14px;
						margin-top: 0px;
						line-height: normal;
					}
					&:before
					{
						content: counters(section,".");
    					counter-increment: section;
    					text-align: center;
					    position: absolute;
					    font-family: Neo;
					    color: #54a2fe;
					    left: -60px;
					    height: 37px;
					    width: 37px;
					    font-size: 18px;
					    border: 2px solid #54a2fe;
					    border-radius: 50%;
					    background: #f8f8f8;
					    z-index: 1;
					    line-height: 40px;
					}
					&:after
					{
						position: absolute;
					    left: -41px;
					    width: 2px;
					    height: 100%;
					    top: 0;
					    background: #4a90e2;
					    content: "";
					}
					&.last
					{
						padding-bottom: 10px;
						&:after
						{
							display:none;
						}
					}
					&:last-child
					{
						&:after
						{
							display:none;
						}
					}
					img
					{
						max-width: 100%;
					}
				}
			}

		}
		@media screen and (min-width: 769px) {
			max-width:500px;
			margin:auto;
			display:block;
		}
	}

	.gopay-subservices,
	.gobills-benefits
	{
		display: inline-block;
		width: 100%;
		margin: 20px 0;
		.container-gobills
		{
			display: inline-block;
			width: 100%;
			margin-bottom: 16px;
			.icon
			{
				float: left;
				text-align: center;
				width:60px;
				img
				{
					width: 36px;
				}
			}
			.content-gobills
			{
				float: left;
				width: calc(100% - 60px);
				padding-right: 16px;
				padding-bottom: 16px;
				border-bottom: 1px solid #979797;
				.title
				{
					font-weight: 700;
					font-size: 14px;
					line-height: 20px;
					color: #454545;
					margin-bottom: 5px;
				}
				.content-fill
				{
					font-size: 12px;
					color: #8d8d8d;
					font-weight: 100;
					line-height: 20px;
				}
				ol
				{
					margin: 0px;
					padding-left: 10px;
					li
					{
						padding-left: 10px;
					}
				}
			}
		}
		ol.terms,
		ul.terms
		{
			margin: 0px;
			font-size: 12px;
			color: rgb(116,116,116);
			font-weight: 100;
			line-height: 20px;
			padding-right: 20px;
			li
			{
				margin-bottom: 10px;
				a
				{
					letter-spacing: normal;
					font-size: normal;
					font-size: inherit;
					color: $gojek;
				}
			}
		}
		.text-wrapper,
		.copyr
		{
			padding: 20px;
			font-size: 12px;

			.text,
			.title
			{
				margin-bottom: 20px;

				&.footer
				{
					margin-top: 30px;
				}
			}

			.heading
			{
				margin-top: -20px;
				margin-bottom: 30px;
				text-align: center;

				h3
				{
					font-weight: bold;
				}
			}


			span.ls
			{
				margin: 0px;
				font-size: 12px;
				color: rgb(116,116,116);
				font-weight: 100;
				line-height: 20px;
				padding-right: 20px;
			}
		}
	}
}
