#faq-detail {
	.banner {
		text-align: center;
		img {
			margin-left: 1em;
		}
	}
	margin-top: 3em;
	margin-bottom: 3em;
	.menu {
		padding: 3.5em 0 0 4em;
		background: white;
		position: relative;
		a {
			color: #b3b9c7;
		}
		a:hover {
			color: #25c768;
		}
		p:first-child {
			text-transform: uppercase;
			color: #b3b9c7;
			font-family: $opensans;
			font-size: 0.7em;
			font-weight: 700;
			line-height: 1.17;
			letter-spacing: 1.3px;
			margin: 0;
		}
		h3 {
			font-weight: 300;
			line-height: 1.42;
			letter-spacing: 1.2px;
		}
		.style:first-of-type {
			background: #343b41;
			top: 3.25em;
		}
		.style {
			background: #25c768;
			border: none;
			display: inline-block;
			width: 50px;
			height: 4px;
			position: absolute;
			left: 0;
			top: 12.25em;
		}
		ul {
			list-style: none;
			padding: 0;
			margin: 2em 0;
			li {
				margin: 0.7em 0;
				cursor: pointer;
				a {
					text-decoration: none;
					color: #343b41;
					font-size: 16px;
					font-family: $opensans;
					font-weight: 500;
					line-height: 1.62;
					letter-spacing: 0.5px;
				}
				a:hover {
					color: #3ca33a;
				}
				.active {
					color: #3ca33a;
					font-weight: bold;
				}
			}
		}
	}
	.content {
		padding: 2em;
		background: white;
		p, ul {
			font-family: $opensans;
			font-size: 14px;
			font-weight: normal;
			line-height: 1.56;
			letter-spacing: 1px;
			color: #485460;
		}
		.title {
			margin-bottom: 2.5em;
			h2 {
				font-weight: 300;
				line-height: 1.28;
				letter-spacing: 2px;
				margin-bottom: 0;
				margin-top: 2em;
			}
		}
		.item {
			letter-spacing: 1px;
			h4 {
				line-height: 1.56;
			}
			ol {
				padding: 0 1em;
				color: #485460;
				font-family: $opensans;
				font-weight: 300;
				li {
					margin: 0.25em 0;
					font-family: $opensans;
					font-size: 14px;
					font-weight: normal;
					line-height: 1.75;
					letter-spacing: 1px;
					color: #485460;
					h3 {
						font-size: inherit;
						font-weight: 300;
					}
				}
			}
		}
	}
}

@media screen and (max-width: 35.5em) {
	#faq-detail {
		padding: 0;
		.menu {
			// padding: 2.5em 0;
			.style:first-of-type {
				top: 3.25em !important;
			}
			.style {
				top: 8.65em !important;
			}
			ul {
				li {
					margin: 2em 0;
				}
			}
		}
		.content {
			padding: 3.5em 1.5em;
			h2 {
				font-size: 2em;
			}
		}
		.banner {
			display: none;
			width: 100%;
		}
	}
}

// @media screen and (max-width: 48em) {
// 	#faq-detail {
// 		.menu {
// 			padding: 0;
// 			padding-top: 3.5em;
// 			.style {
// 				display: none;
// 			}
// 		}
// 	}
// }
