// ----------------------------------------------------------------------

  // Base Button Style

// ----------------------------------------------------------------------

// example: @include button-desk($bgColor);

@mixin button-mobile($bgColor: #48af4a) {
	padding: 2.313em 4.438em;
	border-radius: 0;
	text-transform: uppercase;
	font-family: $opensans;
	font-weight: 700;
	letter-spacing: 0.206em;
	border: none;
	background-color: $bgColor;
}

@mixin button-desk($bgColor: #48af4a) {
	padding: 2.313em 4.438em;
	border-radius: 0;
	text-transform: uppercase;
	font-family: $opensans;
	font-weight: 700;
	letter-spacing: 0.206em;
	border: none;
	background-color: $bgColor;
}

@mixin button-hover($bgColor: #48af4a) {
	color: darken($bgColor, 13%);
	background-color: lighten($bgColor, 6%);
}

@mixin button-active($bgColor: #48af4a) {
	color: lighten($bgColor, 6%);
	background-color: darken($bgColor, 13%);
}