#talent-story-golife {
	h4:before {
		background-color: #18aeab !important;
		top: 5.5% !important;
	}
	h4 {
		color: #18aeab !important;
	}
	.detail {
		padding: 3em;
		margin-top: 0 !important;
	}
	a {
		padding: 1.5em 3em;
		background-color: #18aeab;
		color: #fff;
		font-family: $lato;
		border-radius: 5px;
		right: 25px;
		bottom: -5%;
		position: absolute;
		cursor: pointer;
	}
	.big-img {
		height: 100%;
		width: 100%;
	}
	.content {
		margin-bottom: 3em;
		h4 {
			margin: 0;
			display: inherit !important;
		}
		h4:before {
			display: none;
		}
		.info {
			// div:first-child {
			// 	margin-top: 15%;
			// }
			p {
				padding: 0;
			}
		}
		img {
			object-fit: cover;
			border-radius: 50%;
			overflow: hidden;
			width: 100px;
			height: 100px;
			margin: auto;
		}
	}
}

#talent-story,
#talent-story-golife {
  margin: 3em 0;
	position: relative;
	.img-slide {
		img {
			min-width: 100%;
		}
	}
	.mobile-text {
		display: none;
	}
	.slide-mobile {
		display: none;
	}
	h4:before {
		content: "";
		position: absolute;
		right: 48%;
		top: 2.5%;
		padding-right: 50px;
		height: 4px;
		margin: 0;
		background-color: #f9ae3c;
		z-index: 10;
	}
	h4 {
		margin-left: 4em;
		color: #48af4a;
		font-family: $opensans;
		font-weight: 700;
	}
	hr {
		position: absolute;
		z-index: 50;
		right: 48%;
		top: 5%;
		width: 50px;
		background: #48af4a;
		border: none;
		height: 4px;
	}
	.detail {
		background: white;
		padding: 3em;
		padding-bottom: 2.7em;
		margin-top: 2.6em;
		position: relative;
		.slider {
			.item {
				p {
					height: 100%;
					color: #9b9b9b;
					font-weight: 300;
				}
				.info {
					p {
						height: 100%;
						color: #9b9b9b;
						font-weight: 500;
					}
				}
			}
		}
		h2 {
			font-family: $lato;
			font-weight: 300;
			line-height: 1.22;
			color: #343b41;
			letter-spacing: 2px;
			margin-top: 0;
		}
		h5 {
			color: #4a4a4a;
			margin: 0;
			font-family: $opensans;
		}
		p {
			font-family: $opensans;
			color: #485460;
			padding-right: 3em;
			font-weight: 300;
			line-height: 1.62;
			letter-spacing: 1.5px;
			margin: 0;
		}
		.info {
			margin-top: 2em;
		}
		.custom-nav {
			position: absolute;
			right: 5%;
			top: 50%;
			-webkit-transform: translateY(-50%);
			-moz-transform: translateY(-50%);
			-o-transform: translateY(-50%);
			transform: translateY(-50%);
			.slick-disabled {
				-webkit-filter: grayscale(100%);
				filter: grayscale(100%);
				pointer-events: none;
			}
			img,
			svg {
				outline: none;
				display: block;
				margin-bottom: 1.5em;
			}
		}
	}
}

@media screen and (max-width: 48em) {
	#talent-story-golife {
		.main-img {
			width: 100%;
		}
		h4:before {
			right: 42.1%;
			top: 4em;
		}
		h4 {
			margin-bottom: 3.5em;
		}
		.pure-u-lg-1-2 {
			h4 {
				display: none;
			}
		}
		.detail {
			padding: 1em !important;
			margin: 0;
			padding-bottom: 3em !important;
		}
		.mobile-text {
			text-align: center;
			display: inline-block;
			margin: auto;
		}
	}
}

@media screen and (max-width: 35.5em) {
	#talent-story,
	#talent-story-golife {
		h4:before {
			right: 42.1%;
			top: 4em;
		}
		a {
			position: inherit;
			margin-top: 1em;
			right: 0;
		}
		h4 {
			margin-bottom: 3.5em;
		}
		.slick-dots {
			bottom: 0;
			li {
				button:before {
					font-size: 10px;
					margin-top: 20px;
					line-height: 20px;
				}
			}
			li.slick-active button:before {
				opacity: .75;
				color: #99CC28;
			}
		}
		.slick-dotted.slick-slider {
			margin-bottom: 50px;
		}
		.mobile-text {
			text-align: center;
			display: inline-block;
			margin: auto;
		}
		hr {
			position: inherit;
			margin-top: -115%;
		}
		h4 {
			margin-left: 0;
			text-align: center;
		}
		.pure-u-sm-1-2 {
			h4 {
				display: none;
			}
		}
		.detail {
			text-align: center;
			margin-top: 0;
			padding: 1em;
			padding-bottom: 1em;
			p {
				padding: 0;
			}
			.custom-nav {
				right: inherit;
				top: inherit;
				position: relative;
				bottom: 0;
				svg {
					margin: 0;
				}
				svg#up {
					left: 0;
				}
				svg#down {
					right: 0;
				}
			}
		}
		.slide-mobile {
			display: inherit;
			text-align: center;
			svg {
				z-index: 22;
				position: absolute;
			}
			svg#up {
				left: 2em;
				z-index: 22;
				bottom: 2em;
			}
			svg#down {
				right: 2em;
				z-index: 22;
				bottom: 2em;
			}
		}
		.desktop {
			display: none;
		}
	}
}
