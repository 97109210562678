#promo-banner {
	padding: 2.5em 0;
	position: relative;
	background: #F8F8F8;
  min-height: 400px;

	.mobile {
		display: none;
	}
	.img-slide {
		picture {
			width: 100%;
		}
		img {
			min-width: 100%;
			width: 100%;
		}
	}
	.slick-vertical .slick-slide {
		border: 0;
	}
	.slide-mobile {
		position: relative;
		z-index: 10;
		display: none;
		svg.slick-disabled {
			ellipse {
				stroke: #d2d2d2 !important;
			}
			polygon {
				fill: #d2d2d2 !important;
			}
		}
	}
	.slick-list {
		height: 400px;

		picture {
			background: #333;
		}
	}

	[class*="pure-u"] {
		position: relative;
	}
	.detail {
		background: #343b41;
    padding: 3em;
    width: 100%;
    padding-bottom: 2.7em;
    top: 50%;
    position: absolute;
    height: 250px;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);

		.custom-nav {
			position: absolute;
	    left: 50%;
	    top: 50%;
	    -webkit-transform: translate(-50%, -50%);
	    -moz-transform: translate(-50%, -50%);
	    -o-transform: translate(-50%, -50%);
	    transform: translate(-50%, -50%);
			.slick-disabled {
				-webkit-filter: grayscale(100%);
				filter: grayscale(100%);
				pointer-events: none;
			}
			img,
			svg {
				display: block;
				margin: 10px 0;
				cursor: pointer;
			}
		}
	}
}

@media screen and (max-width: 35.5em) {
	#promo-banner {
		margin: 0;
		padding: 5em 0;
		min-height: 0px;
		.mobile {
			display: inherit;
		}
		.desktop {
			display: none;
		}
		.container {
			padding: 0;
		}
		.slide-mobile {
			margin-right: auto;
			margin-left: auto;
			display: inherit;
			text-align: center;
			margin-top: 3em;
			#up {
				-ms-transform: rotate(-90deg);
				-webkit-transform: rotate(-90deg);
				transform: rotate(-90deg);
				margin-right: 1em;
			}
			#down {
				-ms-transform: rotate(270deg);
				-webkit-transform: rotate(270deg);
				transform: rotate(270deg);
				margin-left: 1em;
			}
		}
		.desktop {
			display: none;
		}
		.detail {
			background: inherit;
			padding: 0;
			margin: 0;
			height: 0;
		}
	}
}
