#gopay {
	/* how to use new cards */
	.title-underscore
	{
		color: $gopay;
		&:after
		{
			background-color:$gopay;
		}
	}

	/* define the cards section */
	section.new-about
	{
		.sub-title
		{
			color: $gopay;
			&:after
			{
				background-color:$gopay;
			}
		}
	}
	section.new-our-values
	{
		h5
		{
			color: $gopay;
			&:after
			{
				background-color:$gopay;
			}
		}
		.new-float-arrow
		{
			@media only screen and (max-width: 767px) {
				svg
				{
					transition: all 0.5s;
					ellipse
					{
						stroke:$gopay !important;
					}
					polygon
					{
						fill: $gopay !important;
					}
				}
				&.slick-disabled
				{
					svg
					{
						
						ellipse
						{
							stroke:#ececec !important;
						}
						polygon
						{
							fill:#ececec !important;
						}
					}
				}
			}
		}
		.normal-dots
		{
			@media only screen and (max-width: 767px) {
				> li
				{
					border:1px solid $gopay;
					&.slick-active
					{
						background-color: $gopay;
					}
				}
			}
		}
	}
	section.new-option
	{
		button
		{
			background-color: $gopay;
		}
	}
	section.new-more-features
	{
		.container-image-slide
		{
			background-color: $gopay;
		}
		.container-vertical
		{
			background-color: $gopay;
		}
	}
	section.gopay-services
	{
		div.new-services
		{
			h5.title-underscore
			{
				@media only screen and (min-width: 769px) {
					padding: 0px;
				}
			}
			.container-vertical
			{
				color: $gopay;
				&.disabled
				{
					color: #dbdbdb;
				}
			}
		}
		div.container-slide-text
		{
			background-color: $gopay;
		}
	}
	section.new-slider-services
	{
		.title
		{
			color:$gopay;
			&:before
			{
				background-color:$gopay;
			}
		}
		.arrow-slider
		{
			svg
			{
				ellipse
				{
					stroke:$gopay;
				}
				polygon
				{
					fill: $gopay;
				}
			}
		}
		.container-slider-services
		{
			&:before
			{
				background-color:$gopay;
			}
			.content-slider
			{
				h5
				{
					color: $gopay;
				}
			}
		}
		.new-float-arrow
		{
			@media only screen and (max-width: 767px) {
				svg
				{
					transition: all 0.5s;
					ellipse
					{
						stroke:$gopay !important;
					}
					polygon
					{
						fill: $gopay !important;
					}
				}
				&.slick-disabled
				{
					svg
					{
						
						ellipse
						{
							stroke:#ececec !important;
						}
						polygon
						{
							fill:#ececec !important;
						}
					}
				}
			}
		}
	}
	section.new-join-us
	{
		button.float-button
		{
			background-color: $gopay;
		}
	}
	section.new-more-information
	{
		button.left
		{
			background-color: $gopay;
		}
	}
	div.new-floating-help
	{
		button.trigger
		{
			background-color: #093c5b;
			color:#ffffff;
			.close
			{
				color: #fff;
			}
		}
		.header
		{
			background-color: #093c5b;
		}
		.container-content
		{
			border-color: #093c5b;
		}
		button.link
		{
			background-color: $gopay;
		}
		&.active
		{
			button.trigger
			{
				transition:all 0.5s;
				background-color: $sunyellow;
			}
		}
	}
	/* ending */

	header#main--hero
	{
		margin-bottom: 0px;
		@media only screen and (max-width: 767px) {
			margin-bottom: 30px;
		}
	}
	button.download
	{
		color: #fff;
		border-radius: 5px;
		display: block !important;
		@media only screen and (max-width: 768px) {
			width:215px !important;
		}
	}
	footer
	{
		margin-top: 100px;
	}

	&.gobills {
		#bg-as-seen-on
		{
			@media screen and (max-width: 767px)
			{
				padding-top: 0px;
			}
		}
		.brushing
		{
			@include brush-gobills;
		}
		.about
		{
			.table-display
			{
				height: 100%;
				display: table;
				.middle-table
				{
					display: table-cell;
					vertical-align: middle;
				}
			}
			.description
			{
				@media screen and (max-width: 767px)
				{
					position: static;
				}
			}
			.container
			{
				h4.title--content
				{
					color: $gobills;
					@media screen and (max-width: 767px)
					{
						position: absolute;
						top: -20px;
						left: 0;
						font-size: 14px;
						&:after
						{
							top: 3px;
						    position: absolute;
						    width: 10px;
						    left: 0;
						    background-color: #4285a7;
						    height: 2px;
						    content: "";
						}
					}
				}
				h2
				{
					@media screen and (min-width: 769px)
					{
						font-size: 28px;
					}
				}
				.img-container
				{
					@media screen and (max-width: 767px)
					{
						background: transparent;
					}
				}
				.img-container-2
				{
					img
					{
						width:100%;
						@media screen and (max-width: 767px)
						{
							width: 90%;
							margin: auto;
							display: block;
						}
					}
					@media screen and (max-width: 767px)
					{
						margin-top:30px;
					}
				}
			}
			@media screen and (min-width: 768px)
			{
				padding-top:0px;
			}
		}
		#type-gobills
		{
			width: 100%;
			.arrow-left
			{
				position: absolute;
				left: 0;
				bottom: -40px;
				width:50px;
			}
			.arrow-right
			{
				position: absolute;
				right: 0;
				bottom: -40px;
				width:50px;
			}
			.slick-arrow.slick-disabled
			{
				filter: grayscale(100) brightness(1.5);
			}
			@media screen and (min-width: 768px)
			{
				margin-top:40px;
			}
		}
		.as-seen-on
		{
			.title
			{
				p
				{
					color: $gobills;
					font-size: 17px;
					@media screen and (max-width: 767px)
					{
						margin-left: 10px;
						font-weight: 700;
						font-size: 14px;
					}
					@media screen and (min-width: 768px)
					{
						position:relative;
						bottom:6px;
					}
				}
				hr
				{
					background: $gobills;
				}
			}
			#left
			{

				right: 0;
				left: auto;
				top: 0;
				bottom: auto;
				@media screen and (max-width: 767px)
				{
					height: 100px;
				}
			}
			#right
			{
				top:auto;
				bottom: 0;
				right: auto;
				left: 0;
				background: $gobills;
				@media screen and (max-width: 767px)
				{
					height: 100px;
				}
			}
			#content
			{
				img
				{
					max-height: 200px;
					&.bpjs
					{
						@media screen and (min-width: 768px)
						{
							max-width: 470px;
						    position: relative;
						    top: 10px;
						}
						@media screen and (max-width: 767px)
						{
							max-width:none;
							width:100%;
						}
					}
				}
			}
		}
		#content-only
		{
			margin-top: 0px;
			padding-top: 60px;
			.container
			{
				box-shadow: #4a4a4a 0px -3em 0px 1.5em;
				.info
				{
					padding-top: 50px;
					p
					{
						@media screen and (min-width: 768px)
						{
							margin:auto;
							max-width: 580px;
						}
					}
					h4
					{
						color: $gobills;
						@media screen and (max-width: 767px)
						{
							font-size: 14px;
						}
					}
					.line
					{
						background: $gobills;
					}
					a.contact
					{
						background: $gobills;
						@media screen and (max-width: 767px)
						{
							width: 80%;
							max-width: 300px;
						}
					}
					a.faq
					{
						background: #4a4a4a;
						@media screen and (max-width: 767px)
						{
							width: 80%;
							max-width: 300px;
						}
					}
				}
				.link
				{
					@media screen and (max-width: 767px)
					{
						margin-bottom: -50px;
					}
				}
			}
			@media screen and (max-width: 767px)
			{
				margin-bottom:100px;
			}
		}

		.how-to {
			[data-display=desktop],
			.desktop-only {
				display: block;
			}

			.mobile-how-to {
				display: none;
			}

			.visibility {
				position: absolute;
				opacity: 0.0;
				height: 0;
				top: 0;
				visibility: hidden !important;
			}

			header {
				.title {
					.content {
						box-shadow: -1em 1em $gobills;

						.text {
					    padding: .2em 1.8em;

							h2 {
								font-family: 'Neo', 'Open Sans', Helvetica, sans-serif !important;
								font-size: 50px !important;
								font-weight: bold;
								font-style: normal;
								font-stretch: normal;
								line-height: 1.12;
								letter-spacing: 0.5px;
								background: white;
							}
						}
					}
				}

				.bg-img {
			    background-position-x: 100% !important;
				}
			}

			.new-section-how-to-use {
				h3.title {
					color: $gobills;

					&:after {
						background-color: $gobills;
					}
				}
				.content {
					background: $gobills;
				}
			}

			#content-nav {
				.text {
					h4 {
						font-family: $opensans;
						font-size: 18px;
						font-weight: bold;
						letter-spacing: 1px;
						text-align: center;
						text-transform: uppercase;
						color: $gobills;
					}

					.line.gradient {
						background: $gobills;
						width: 50px;
						height: 3px;
						margin: 0 auto;
						margin-bottom: 50px;
					}
				}

				.faq-nav {
					width: unset !important;

					.cat-list {
						li {
							a {
								&:hover {
									color: $gobills !important;
								}
							}
						}

						li.active {
							color: $gobills !important;
						}
					}
				}
			}

			.navigation-wrapper {
				margin-bottom: 80px;
			}

			.how-to-slider.pln {
				.new-section-how-to-use {
					margin-top: 200px;
				}
			}

			#faq-list-menu {
		    margin: 2.5em 0 3.75em 0 !important;

				.desktop {
					margin-bottom: 80px;

					h4 {
						font-family: $opensans;
						font-size: 18px;
						font-weight: bold;
						letter-spacing: 1px;
						text-align: center;
						text-transform: uppercase;
						color: $gobills;
					}

					.line.gradient {
						background: $gobills;
						width: 50px;
						height: 3px;
						margin: 0 auto;
						margin-bottom: 50px;
					}
				}

				.box {
					box-shadow: 0 3px 15px 0 rgba(0,0,0,.13), 0 25px 0 15px $gobills !important;

					.img-icon-wrapper {
						width: 40px;
				    height: 40px;
				    position: relative;
				    background: #fff;
						line-height: 50px;
				    margin: 0 auto;
				    border-radius: 50%;
				    margin-bottom: 20px;

						.img-pln {
							max-width: 16px;
						}

						.img-bpjs {
							max-width: 21px;
						}
					}
				}

				.active {
			    background-image: url(https://d3naj63yelf4gk.cloudfront.net/dist/img/faq/list-geo.png),linear-gradient(#13587b,#13587b);
			    background-position: bottom,top;
			    background-repeat: no-repeat,no-repeat;
			    background-size: 100%,cover;
			    box-shadow: 0 25px 0 15px $gobills;
			    cursor: default;
			    pointer-events: none;
					color: white;

					h4 {
						color: white !important;
					}
				}
			}
		}
	}

	.gradient {
		background: $gopay
	}
	.hero {
		button {
			background: $gopay;
		}
	}
	.merchant-list {
		h5 {
			color: $gopay;
		}
		hr {
			background: $gopay;
		}
	}
	section.verification {
		margin-top: 30px;
		margin-bottom: 30px;
		#sub-desc--container {
			background: #f8f8f8;
			z-index: 5;
			position: relative;
			#line--desc {
				height: 4px;
				width: calc(100% - 55px);
				position: relative;
				top: 50%;
				left: 55px;
				-webkit-transform: translateY(-50%);
				-moz-transform: translateY(-50%);
				-o-transform: translateY(-50%);
				transform: translateY(-50%);
				background-color: #333;
			}
			.sub--desc {
				text-align: left;
				margin-left: 50px;
			}
		}
		.container {
			margin: 0;
			padding: 0;
			width: auto !important;
			overflow: hidden;
			table {
				border-collapse: collapse;
				width: 100%;
			}
			td,
			th {
				border: 0px;
				text-align: left;
				font-size: 14px;
				padding: 20px;
			}
			th {
				font-family: Open Sans, sans-serif;
				font-weight: 700;
				text-transform: uppercase;
				line-height: 1.17;
				letter-spacing: 1.3px;
				color: #5ca5da;
				background-color: #fff;
				border-left: 1px solid #d7d7d7;
				padding: 10px 5px;
			}
			th:nth-child(1) {
				border-left: 0px;
			}
			tr:nth-child(even) {
				background-color: rgba(175, 220, 255, 1);
			}
			tr:nth-child(odd) {
				background-color: rgba(250, 250, 250, 1);
			}
			td {
				color: #485460;
			}
			td:nth-child(odd) {
				background-color: rgba(250, 250, 250, 0.5);
			}
			th:nth-child(2),
			th:nth-child(3),
			td:nth-child(2),
			td:nth-child(3) {
				text-align: center;
			}
			.first-block {
				background: white;
				.text-wrapper {
					padding: 45px 35px 0px 55px;
					h1 {
						font-family: $lato;
						font-size: 18px;
						font-weight: bold;
						letter-spacing: 1.5px;
						text-transform: uppercase;
						color: #00b1f1;
					}
					p {
						font-family: $opensans;
						font-size: 14px;
						font-weight: 300;
						font-style: normal;
						font-stretch: normal;
						line-height: 1.64;
						letter-spacing: 1px;
						color: #485460;
						margin: 40px 70px 17px 0px;
					}
				}
			}
			.second-block {
				width: calc(50% + 35px);
				height: 390px;
				background: $gopay;
				margin-left: -35px;
				position: relative;
				right: 0;
				top: 70px;
				.slick-track {
					margin-left: -165px;
					margin-top: 15px;
				}
				.verified-prev {
					display: none !important;
				}
				.verified-next {
					display: none !important;
				}
				.verified-container {
					height: 100%;
					.slick-list {
						overflow: visible;
					}
					.verified-list {
						padding: 0 30px;
						text-align: center;
						outline: none;
						h1 {
							font-family: $opensans;
							font-size: 24px;
							font-weight: 300;
							letter-spacing: 3px;
							color: #ffffff;
						}
						h5 {
							font-family: $opensans;
							font-size: 12px;
							font-weight: 300;
							letter-spacing: 1.5px;
							text-align: center;
							color: #ffffff;
						}
						img {
							width: 250px;
						}
					}
				}
			}
		}
	}
	.mv-evt--container {
		margin: 48px 0;
		text-align: center;
		.button-group {
			display: inline-block;
			background: $gopay;
			border-radius: 5px;
			margin: 0 auto;
			position: relative;
			ul {
				list-style: none;
				padding: 20px 0;
				margin: 0;
				li:first-child {
					border-right: 1px solid #353738;
				}
				li {
					display: inline-block;
					width: 180px;
					text-align: center;
					a {
						display: block;
						color: #fff;
						text-align: center;
						font-family: Lato, Helvetica, sans-serif;
						font-size: 14px;
						line-height: 1.33;
						letter-spacing: 1.5px;
						font-weight: 300;
						padding: 10px;
					}
				}
			}
		}
	}
	.services {
		.png-file {
			background-size: 20em !important;
		}
		.service-list {
			.bg-services {
				background-size: 6em;
				background-repeat: no-repeat;
				opacity: 1;
			}
		}
		.navigation-arrow {
			display: block;
			@media screen and (max-width: 767px)
			{
				margin-top: 50px;
			}
		}
		.container::before {
			background-color: $gopay;
		}
		h4 {
			color: $gopay;
			padding: 0;
			@media screen and (min-width: 769px)
			{
				margin-bottom: 50px;
			}
		}
	}
	.how-it-works--wrapper {
		@media screen and (min-width: 768px)
		{
			margin-top: 160px;
		}
		.container {
			padding: 0;
		}
		h2 {
			color: #093c5b;
		}
		h4 {
			color: $gopay;
		}
		.top {
			img {
				// max-width: 150px;
			}
		}
		.img--wrapper {}
		figure.hand-ss {
			background: #fff;
			img {
				// max-height: 27em;
				width: auto;
				right: 0;
				margin: 0 auto;
				position: absolute;
				left: 0;
			}
		}
		.section--title:before {
			// content: url(https://d3naj63yelf4gk.cloudfront.net/dist/img/page/gopay/hr.svg);
			background-color: #093c5b;
		}
		.rect--primary {
			background: #093c5b;
			img {
				// padding-left: 1em;
			}
		}
		img {
			// max-height: 250px;
			// width: auto;
		}
		.rect--secondary {
			img {
				// padding-right: 1em;
			}
		}
	}
	.section-our-values {
		padding-bottom: 0;
		h4 {
			color: $gopay;
		}
		img {
			max-height: 150px;
			width: auto;
		}
		&.go-bills
		{
			h4.title
			{
				color: $gobills;
				@media screen and (max-width: 767px)
				{
						&:after
						{
							top: 30px;
						    position: absolute;
						    width: 15px;
						    left: -20px;
						    background-color: #4285a7;
						    height: 2px;
						    content: "";
						}
				}
			}
			.list
			{
				h4
				{
					@media screen and (min-width: 768px)
					{
						margin-top: 50px;
						margin-bottom: 40px;
					}
					color: #4a4a4a;
					@media screen and (max-width: 767px)
					{
						padding: 0px;
					    margin-bottom: 0px;
					    margin-top: 50px;
					}
				}
				p
				{
					@media screen and (min-width: 768px)
					{
						margin-top:10px;
					}
					@media screen and (max-width: 767px)
					{
						max-width: 300px;
					}
				}
			}
			.line
			{
				background: $gobills;
				@media screen and (max-width: 767px)
				{
					display: none;
				}
			}
			h4.title
			{
				@media screen and (max-width: 767px)
				{
					font-size: 14px;
					text-align: left;
				}
			}
			@media screen and (max-width: 767px)
			{
				padding:20px;
				padding-bottom:100px;
				.container-slider
				{
					width:100%;
					padding:0px;
					position:relative;
					display:inline-block;
				}
			}
		}
	}
	.about {
		.container::before {
			display: none;
			background-color: #093c5b;
		}
		padding-bottom: 0;
		h4,
		a,
		a::after {
			color: $gopay;
		}
	}
	#join-now {
		a {
			background: $gopay;
		}
		h2 {
			font-size: 1.5em;
		}
	}
	.faq {
		#content {
			background: url(https://d3naj63yelf4gk.cloudfront.net/dist/img/page/gopay/rectangle-bg.svg);
			.gopay-faq--title {
				color: #000;
				padding: 30px 0;
				span.light {
					font-weight: 300;
				}
			}
		}
		#right {
			background: $gopay;
		}
		.button--wrapper {
			width: 200px;
			border-radius: 5px;
			background-color: $gopay;
			text-align: center;
			position: relative;
			margin: 0 auto 30px;
			a.gopay-faq--link {
				text-decoration: none;
				color: #fff;
				font-size: 16px;
				text-transform: uppercase;
				padding: 19px 0;
				display: block;
			}
		}
	}
}

@media screen and (max-width: 768px) {
	#gopay {
		header#main--hero {
			// background-image: url(https://d3naj63yelf4gk.cloudfront.net/dist/img/header/hero/go-pay--mobile.jpg);
		}
		section.verification {
			margin-top: 30px;
			margin-bottom: 30px;
			#sub-desc--container {
				.sub--desc {
					margin-left: 0px;
					padding: 0 15px;
					text-align: center;
				}
				#line--desc {
					display: none;
				}
			}
			.container {
				.first-block {
					background: white;
					table {
						letter-spacing: normal;
						td {
							padding: 10px;
						}
					}
					.text-wrapper {
						padding: 15px;
						h1 {
							font-family: $lato;
							font-size: 18px;
							font-weight: bold;
							letter-spacing: 1.5px;
							text-transform: uppercase;
							color: #00b1f1;
							margin-top: 20px;
						}
						p {
							margin-top: 40px;
							margin-right: 0;
							margin-bottom: 0;
						}
					}
				}
				.second-block {
					width: 100%;
					height: 380px;
					top: 0;
					margin-left: 0;
					position: relative;
					.verified-prev {
						display: block !important;
						z-index: 999;
						float: left;
						position: relative;
						right: 20px;
						left: 0;
						margin: 22px;
					}
					.verified-next {
						display: block !important;
						z-index: 999;
						float: right;
						position: relative;
						right: 0;
						margin: 22px;
					}
					.slick-disabled ellipse {
						stroke: rgb(210, 210, 210) !important;
					}
					.slick-disabled polygon {
						fill: rgb(210, 210, 210);
					}
					.verified-container {
						margin-left: 0;
						bottom: 0;
						position: relative;
						.slick-list {
							padding: 0 30px !important;
						}
						.slick-track {
							margin-left: 0;
							margin-top: 0;
						}
						.verified-list {
							padding: 0 30px;
							margin-top: -80px;
							text-align: center;
							outline: none;
						}
					}
				}
			}
		}
		#join-now {
			img {
				width: 100%;
			}
			.right {
				a {
					padding: 1.3em;
					bottom: -1.5em;
				}
			}
		}
		.services {
			padding-top: 0;
			padding-bottom: 6em;
		}
		.how-it-works--wrapper {
			.container {
				padding-top: 0;
				padding-bottom: 0;
				.how--title {
					// margin-top: 6em;
				}
			}
			h4 {
				line-height: 1.3em;
			}
			.layer {
				padding-bottom: 0;
				padding-top: 0;
				.odd--row .desc--pad {
					padding-right: 0;
				}
				.even--row .desc--pad {
					padding-right: 0;
					padding-left: 0;
				}
			}
		}
	}
}

@media screen and (max-width: 500px) {
	#gopay {
		.button-group {
			ul {
				padding: 10px 0 !important;
				li {
					width: 150px !important;
					a {
						font-size: 12px !important;
					}
				}
			}
		}
	}
}

@media only screen and (max-width: 35.5em) {
	#gopay {
		&.gobills {
			[data-display=desktop],
			.desktop-only {
				display: none !important;
			}

			.mobile-how-to {
				display: block !important;
			}

		.new-section-how-to-use {
			margin-top: 0 !important;
			margin-bottom: 0 !important;

			.title {
				display: none;
			}

			.phoneSlide-dots {
				display: none !important;
			}
		}

			#content-nav {
				display: none;
			}

			#faq-list-menu {
				margin: 0 !important;
				padding: 0;
				margin-bottom: 50px !important;

				.mobile {
					.warper {
						h3 {
					    margin-top: -50px;
					    margin-left: 60px;
						}

						.img-icon-wrapper {
							width: 40px;
					    height: 40px;
					    position: relative;
					    background: #fff;
							line-height: 50px;
					    border-radius: 50%;
					    margin-bottom: 20px;

							.img-pln {
								max-width: 16px;
								position: relative;
								left: 12px;
								top: 7.5px;
							}

							.img-bpjs {
								max-width: 22px;
						    position: relative;
						    top: 8.5px;
						    left: 9px;
							}
						}
					}

					.ui-accordion-content-active {
						margin-bottom: 100px !important;
					}

					#faq-list-content {
						.title-content {
							margin-bottom: 100px;
							transform: scale(1.285);
					    box-shadow: 0 3px 15px 0 rgba(0,0,0,.13), 0 25px 0 15px $gobills;

							span {
								color: unset;
							}
						}

						.detail-item {
							transform: scale(1.25);
							margin-bottom: 35px;

							.how-to-mobile-li {

							}

							ul {
								li {
									padding: 0;
									border-bottom: none;
								}
							}

							.title-item {
								border-left: 5px solid $gobills;

								&.sub-item {
									background: white !important;
								}

								&:last-child {
									margin-bottom: 250px;
								}

								span {
							    color: unset;
								}
							}
						}

						.ui-state-active {
							background: #13587b !important;

							ul {
								margin-bottom: 50px !important;
							}
						}
					}
				}
			}

			.how-to {
				header {
					background: linear-gradient(
						rgba(0,0,0,.20),
						rgba(0,0,0,.40)
					), url('/static/dist/img/page/gopay/gobills/header.jpg') no-repeat !important;
					background-size: cover;
					background-position-x: 100% !important;
					margin-top: 60px;
			    height: 320px;

					.title {
						.content {
							box-shadow: none;

							.text {
								h2 {
									text-align: center;
									font-size: 36px !important;
									line-height: 40px;

									span {
										position: relative;
										top: 7px;
									}
								}
							}
						}
					}
				}
			}
		}
	}
}
