.gallery--wrapper {
	background: #f8f8f8;
	padding: 80px 0;
	#instafeed
	{
		background: #2b2b2b;
		.pure-u-1
		{
			overflow: hidden;
		}
	}
	.instafeed-wrapper {
		img {
			width: auto;
			height: 390px;
			@media screen and (max-width: 768px) {
				width: 100%;
				height: auto;
			}
		}
		a {
			display: block;
		}

		.ig--picture {
			-webkit-transition: opacity .3s ease-in-out;
			-moz-transition: opacity .3s ease-in-out;
			transition: opacity .3s ease-in-out;
		}
		.ig--picture:hover {
			opacity: .7;
		}

		.discover {
			background: #48af4a;

			.discover--title {
		    margin: 0;
			}
			.discover--title a {
		    padding: 40px;
		    color: #fff;
		    font-family: 'Open Sans', sans-serif;
		    font-size: 18px;
		    font-weight: bold;
		    line-height: 0.8;
		    letter-spacing: 3.3px;
		    text-transform: uppercase;
		    text-align: right;
			}
			.discover--title a:after {
				content: url(https://d3naj63yelf4gk.cloudfront.net/dist/img/header/arrow.png);
		    position: relative;
		    left: 10px;
			}
		}

		.title {
	    background: #fff;

	    .section-title {
		    padding: 40px;
		    font-family: 'Open Sans', sans-serif;
				font-size: 18px;
				font-weight: bold;
				line-height: 0.78;
				letter-spacing: 1.9px;
				color: #48af4a;
		    text-transform: uppercase;
		    left: -35px;
		    position: relative;
	    }
			.section-title:before {
				content: url(https://d3naj63yelf4gk.cloudfront.net/dist/img/line.svg);
				position: relative;
				left: -30px;
    			top: -5px;
			}
		}
	}
}
