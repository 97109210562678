.top-picks {
	.bg--img {
        background-size: cover;
        background-position: center center;
        height: 300px;
        overflow: hidden;
        position: relative;
	}

	.blog--overlay {
        -webkit-background-image: linear-gradient(to bottom, transparent, rgba(0, 0, 0, 0.9));
        -moz-background-image: linear-gradient(to bottom, transparent, rgba(0, 0, 0, 0.9));
        background-image: linear-gradient(to bottom, transparent, rgba(0, 0, 0, 0.9));
        height: 100%;
        position: absolute;
        width: 100%;
        top: 0;
        left: 0;
	}

	[class*="pure-u"] {
        padding: 20px;
        position: relative;
        @media screen and (max-width:768px) {
            padding:5px;
        }
	}
    .container
    {
        @media screen and (max-width:768px) {
            width:100%;
        }
    }

    .desc--wrapper {
        position: absolute;
        top: 0;
        text-align: left;
        padding: 100px 50px;

        .category {
            font-size: 14px;
            font-weight: 500;
            line-height: 1.0;
            letter-spacing: 1.5px;
            color: #58c75b;
            text-transform: uppercase;
        }
        .title {
            a {
                font-size: 22px;
                font-weight: 500;
                line-height: 1.42;
                letter-spacing: 2.2px;
                color: #ffffff;
            }
        }
        @media screen and (max-width:768px) {
            padding:20px;
            bottom:0;
            top:auto;
            right:0;
        }
    }
    @media screen and (max-width:768px) {
        padding:0px;
    }
}

@media screen and (max-width:35.5em) {
    .top-picks {
        padding: 2.5em 0;

        .desc--wrapper {
            .title {
                a {
                    font-size: 18px;
                }
            }
        }
    }
}
