#goclean {
	.new-section-how-to-use
	{
			margin-top: 180px;
			margin-bottom:100px;
			h3.title
			{
				color: #2197d4;
				&:after
				{
					background:#2197d4;
				}
			}
			.content
			{
				background-color:#2197d4;
			}
	}
	.about {
		padding-bottom: 0;
		.container::before {
			background-color: #2197d4;
		}
		.description {
			padding-bottom: 0;
		}
		h4 {
			color: #045572;
		}
		h2 {
			text-transform: uppercase;
		}
	}
	.slick-disabled {
		-webkit-filter: grayscale(100%);
		filter: grayscale(100%);
		pointer-events: none;
	} // Mobile First Styleing

	section#new-join
	{
		margin: 48px 0px 100px 0px;
	}

	#content-only.thank-you
	{
		margin-top: 130px;
		a.contact
		{
			width:300px;
		}
		@media screen and (max-width: 768px) {
			margin-bottom: 130px;
			a.contact
			{
				width:100%;
				line-height: normal;
			}
		}
		.container {
			background: white;
			box-shadow: 0 -3em 0 1.5em $goclean;
			.info {
				h4 {
					color: $goclean;
				}
				.line {
					background: $goclean;
				}
				.link {
					a {
						width: 280px;
					}
					.contact {
						margin-bottom: 15px;
						span {
							margin: 0 15px;
						}
					}
					.contact.homepage {
						background: rgb(200, 200, 200);
					}
				}
			}
		}
	}

	section.service {
		.title-wrapper {
			position: relative;
			background: #095672;
			color: #fff;
			padding: 35px 30px;
			h4 {
				color: #ffffff;
				margin-left: 10px;
				text-transform: uppercase;
				font-family: $lato;
				margin: 0;
			}
		}
		.title-wrapper::before {
			content: "";
			width: 50px;
			height: 5px;
			background-color: #1f97d2;
			position: absolute;
			left: -35px;
			top: 50%;
			transform: translateY(-50%);
		}
		.content {
			.data {
				background: white;
				margin: 15px 0;
				position: relative;
				.title.active {
					border-left: none;
				}
				.title.not-active {
					border-left: 5px solid #1f97d2;
				}
				.title {
					border-left: 5px solid #1f97d2;
					padding: 15px;
					margin-top: 15px;
					h4 {
						font-family: $lato;
						font-weight: bold;
						letter-spacing: 2.5px;
						color: #221f20;
						text-transform: uppercase;
						outline: none;
						position: relative;
						.fa {
							position: absolute;
							right: 30px;
							font-size: 20px;
							top: -5px;
							color: #1f97d2;
						}
					}
				}
				ul {
					list-style: none;
					padding: 0 30px;
					p.info {
						text-align: center;
						color: #9b9b9b;
					}
					li {
						width: 100%;
						padding: 15px 0;
						border-bottom: solid 1px #e8ebf2;
						font-weight: 300;
						line-height: 1.43;
						letter-spacing: 1.5px;
						color: #221f20;
						font-family: $opensans;
						display: table;
						border-spacing: 15px 0;
						picture {
							width: 35px;
							position: relative;
							transform: translateX(-15px);
							display: table-cell;
							img {
								max-width: none;
								width: 100%;
								margin: 0;
							}
						}
						p {
							font-size: inherit;
							margin-left: 10px;
							display: table-cell;
							vertical-align: middle;
							color: #1a1718;
						}
					}
				}
			}
		}
		@media screen and (min-width: 64em) {
			margin-top: 100px;
			.title-wrapper {
				margin: 60px 0;
				display: flex;
				justify-content: center;
				flex-direction: column;
				h4 {
					margin-left: 21px;
				}
			}
			.content {
				background: #ffffff;
				padding-bottom: 25px;
				div:nth-child(1) {
					border-left: none;
				}
				.data {
					.title {
						border: none !important;
						h4 {
							text-align: center;
							position: relative;
						}
						h4::after {
							content: "";
							position: absolute;
							height: 5px;
							width: 50px;
							bottom: -25px;
							left: 40%;
							background-color: #1f97d2;
						}
					}
					ul {
						li {
							border-bottom: none !important;
						}
						p.info {
							display: inherit;
						}
						p {
							letter-spacing: normal;
							color: #9b9b9b;
							font-size: 0.6em;
							text-align: left;
						}
					}
				}
				.data:nth-child(1):after {
					content: "";
					height: 115%;
					width: 1px;
					background-color: #979796;
					position: absolute;
					top: 0;
					right: 0;
					transform: translateY(35%);
				}
				.data:nth-child(2):after {
					content: "";
					height: 80%;
					width: 1px;
					background-color: #979796;
					position: absolute;
					top: 0;
					right: 0;
					transform: translateY(33%);
				}
			}
			.banner {
				background: #095672;
				margin: 60px 0;
			}
		}
	} // End Mobile First Styleing
	.section-our-values {
		padding: 5em 0;
		background: #f8f8f8;
		h4.title {
			color: $goclean;
		}
		.line.gradient {
			background: $goclean;
		}
		.list {
			padding: 20px;
			h4 {
				line-height: 25px;
				font-size: 15px;
				height: 3em;
			}
			img {
				height: 125px;
			}
		}
	}
	.section-user-review {
		.head .title {
			color: $goclean;
		}
		button:before {
			color: #1d98d5;
		}
		.head .line.gradient {
			background: $goclean;
		}
		.wrapper .line.light.yellow {
			background: white;
		}
		.slider .item {
			.wrapper {
				min-height: 430px;
				h3.user-testimonial {
					height: 140px;
				}
			}
			.wrapper.style-2 {
				background: $goclean;
			}
			.wrapper.style-3 {
				background: #babcbf;
			}
		}
	}
	.articles--feed {
		svg {
			ellipse {
				stroke: #343b41 !important;
			}
			polygon {
				fill: #343b41 !important;
			}
		}
	}
	#talent-story {
		h4:before {
			background-color: #1d98d5;
			top: 6.5%;
		}
		h4:after {
			display: none;
		}
		h4 {
			color: $goclean;
		}
		.detail h2 {
			color: $goclean;
			font-size: 25px;
		}
		.info h5 {
			color: $goclean;
		}
	}
	.articles--feed {
		.bg {
			background: $goclean;
		}
		.line--title {
			background: #045572;
		}
		.read--more>a {
			background: $goclean;
		}
		.content .one {
			border-bottom: 6px solid $goclean;
		}
		.content .two {
			border-bottom: 6px solid $goclean;
		}
		.content .three {
			border-bottom: 6px solid $goclean;
		}
		.content .four {
			border-bottom: 6px solid $goclean;
		}
		h4>a:hover {
			color: $goclean;
		}
	}
	#join-now {
		a {
			background: $goclean;
		}
		.bg-layer {
			position: relative;
		}
	}
	#bg-as-seen-on {
		hr {
			background: $goclean;
		}
		.title {
			color: $goclean;
		}
		#right {
			background: $goclean;
		}
	}
	.section-how-to-use {
		padding: 50px;
		height: 620px;
		background-size: cover !important;
		h5 {
			color: $goclean;
		}
		.line.gradient {
			background: $goclean;
		}
		.title {
			position: relative;
			left: -410px;
		}
		.arrow-up {
			stroke: $goclean;
		}
		.arrow-up-circle {
			stroke-width: 3;
			stroke: $goclean;
		}
		.arrow-down {
			stroke: $goclean;
		}
		.arrow-down-circle {
			stroke: $goclean;
			stroke-width: 3;
		}
		.slider .slick-dots li.slick-active button:before {
			color: $goclean;
		}
		.slider-image .slick-dots li.slick-active button:before {
			color: $goclean;
		}
		.text-mobile {
			display: none;
			text-align: center;
		}
		.text-mobile .line.gradient {
			width: 50px;
			height: 3px;
			margin-top: 0px;
			background: $goclean;
			margin-left: auto;
			margin-right: auto;
		}
		.text h5 {
			color: $goclean;
		}
		.text .line.gradient {
			width: 50px;
			height: 3px;
			margin-top: 0px;
			background: $goclean;
		}
		.phone-base {
			display: block;
			margin-right: auto;
			margin-left: auto;
			position: relative;
		}
		.slider .item-text {
			// margin-top: 60px;
		}
		.slider .item-text h2 {
			margin-top: 20px;
			margin-left: 0px;
			font-weight: 300;
		}
		.slider .item .wrapper {
			width: 100%;
		}
		.slider-image .slick-list {
			// margin: 40px 0 -112px -20px;
			// position: relative;
			// left: -155px;
			// top: -6px;
		}
		.slider-image .slick-dots {
			// position: relative;
			// top: -350px;
			// left: 30px;
		}
		.slider-image .slick-dots li {
			display: block;
		}
		.slider-image .slick-dots li.slick-active button:before {
			color: $goclean;
		}
		.slider-image .slick-dots li button:before {
			font-size: 13px;
		}
		.slider .slider-text.title {
			position: relative;
			float: right;
			left: -265px;
			top: -20px;
			font-size: 36px;
			margin-top: -320px;
			font-weight: 300;
		}
		.slider .slider-text.desc {
			margin-left: 380px;
			position: relative;
			top: -280px;
			left: 45px;
			margin-bottom: -33px;
			line-height: 1.56;
			font-weight: 300;
			letter-spacing: 1.7px;
		}
		.slider-image {
			// position: relative;
			// z-index: 99 !important;
			// margin-top: -425px;
			// margin-bottom: -98px;
			// text-align: center;
			// margin-right: auto;
			// margin-left: auto;
			// display: block;
			// .phone-slider {
			// 	display: block;
			// 	max-width: 260px;
			// 	margin-right: auto;
			// 	margin-left: auto;
			// 	margin-bottom: 10px;
			// 	position: relative;
			// 	left: 155px;
			// 	top: -17px;
			// }
		}
		ul {
			// position: relative;
			// top: 35%;
			// list-style-type: none;
		}
		ul li {
			margin-top: 10px;
		}
		ul li img {
			cursor: pointer;
		}
	}
	@media screen and (max-width: 480px) {
		.section-how-to-use {
			min-height: 815px; // padding: 50px;
			background-size: cover !important; // .slider {
			// 	.item-text[data-slide="1"],
			// 	.item-text[data-slide="2"],
			// 	.item-text[data-slide="3"] {
			// 		position: relative;
			// 		top: 0px;
			// 	}
			// 	.item-text[data-slide="4"],
			// 	.item-text[data-slide="5"] {
			// 		position: relative;
			// 		top: 0px;
			// 	}
			// }
			// .slick-slider {
			// 	display: table;
			// 	table-layout: fixed;
			// 	width: 100%;
			// }
			.text-mobile {
				text-align: center;
				display: block;
				margin-right: auto;
				margin-left: auto;
			} // .text {
			// 	display: none;
			// }
			// .text h5 {
			// 	margin-top: 100px;
			// 	display: none;
			// 	color: $goclean;
			// 	font-weight: 900;
			// }
			// .text .line.gradient {
			// 	width: 50px;
			// 	height: 3px;
			// 	margin-top: 0px;
			// 	display: none;
			// 	background: $goclean;
			// }
			// .phone-base {
			// 	display: block;
			// 	margin-right: auto;
			// 	margin-left: auto;
			// 	top: 50px;
			// 	margin-top: 40px;
			// 	position: relative;
			// }
			.slider .item-text {
				margin-top: 0px;
			}
			.slider .item-text h2 {
				// margin-top: 20px;
				// margin-left: 0px;
				// font-weight: 300;
			}
			.slider .item .wrapper {
				width: 100%;
			}
			.slider-image .slick-list {
				// margin: 40px 0 -112px -20px;
				// position: relative;
				// left: -155px;
				// top: -6px;
			}
			.slider-image ul {
				display: none !important;
			}
			.slider-image .slick-dots {
				position: relative;
			}
			.slider-image .slick-dots li {
				display: block;
			}
			.slider-image .slick-dots li.slick-active button:before {
				color: $goclean;
			}
			.slider-image .slick-dots li button:before {
				font-size: 13px;
			}
			.slider .slider-text.title {
				position: relative;
				float: right;
				left: -265px;
				top: -20px;
				font-size: 36px;
				margin-top: -320px;
				font-weight: 300;
			}
			.slider .slider-text.desc {
				margin-left: 380px;
				position: relative;
				top: -280px;
				left: 45px;
				margin-bottom: -33px;
				line-height: 1.56;
				font-weight: 300;
				letter-spacing: 1.7px;
			} // .slider-image {
			// 	position: relative;
			// 	z-index: 99 !important;
			// 	margin-top: -425px;
			// 	margin-bottom: -128px;
			// 	text-align: center;
			// 	margin-right: auto;
			// 	margin-left: auto;
			// 	display: block;
			// 	position: relative;
			// 	left: 155px;
			// 	.phone-slider {
			// 		display: block;
			// 		max-width: 260px;
			// 		margin-right: auto;
			// 		margin-left: auto;
			// 		margin-bottom: 10px;
			// 		position: relative;
			// 		left: 0;
			// 		top: -17px;
			// 	}
			// }
			// ul {
			// 	position: relative;
			// 	top: 0px;
			// 	display: none;
			// 	margin-bottom: -100px;
			// 	left: 0px;
			// 	list-style-type: none;
			// }
			ul li {
				// margin-top: 10px;
			}
			ul li img {
				cursor: pointer;
			}
		}
	}
	@media screen and (max-width: 440px) {
		.section-how-to-use {
			// .phone-base {
			// 	display: block;
			// 	margin-right: auto;
			// 	margin-left: auto;
			// 	top: 50px;
			// 	left: 0px;
			// 	margin-top: 40px;
			// 	position: relative;
			// }
			// .slider-image {
			// 	.phone-slider {
			// 		display: block;
			// 		max-width: 260px;
			// 		margin-right: auto;
			// 		margin-left: auto;
			// 		margin-bottom: 10px;
			// 		position: relative;
			// 		left: 0px;
			// 		top: -17px;
			// 	}
			// }
		}
	}
	@media screen and (max-width: 400px) {
		.section-how-to-use {
			min-height: 835px; // .phone-base {
			// 	display: block;
			// 	margin-right: auto;
			// 	margin-left: auto;
			// 	top: 50px;
			// 	left: -25px;
			// 	margin-top: 40px;
			// 	position: relative;
			// }
			// .slider-image {
			// 	.phone-slider {
			// 		display: block;
			// 		max-width: 260px;
			// 		margin-right: auto;
			// 		margin-left: auto;
			// 		margin-bottom: 10px;
			// 		position: relative;
			// 		left: -25px;
			// 		top: -17px;
			// 	}
			// }
		}
	} // @media screen and (max-width: 320px) {
	// 	.section-how-to-use {
	// 		min-height: 835px;
	// 		.phone-base {
	// 			display: block;
	// 			margin-right: auto;
	// 			margin-left: auto;
	// 			top: 50px;
	// 			left: -55px;
	// 			margin-top: 40px;
	// 			position: relative;
	// 		}
	// 		.slider-image {
	// 			.phone-slider {
	// 				display: block;
	// 				max-width: 260px;
	// 				margin-right: auto;
	// 				margin-left: auto;
	// 				margin-bottom: 10px;
	// 				position: relative;
	// 				left: 0;
	// 				top: -17px;
	// 			}
	// 			.slick-list {
	// 					position: relative;
	// 					left: -210px;
	// 					top: -6px;
	// 			}
	// 		}
	// 	}
	// }
	@media screen and (max-width: 768px) {
		#goclean {
			header#main--hero {
				// background-image: url(https://d3naj63yelf4gk.cloudfront.net/dist/img/header/hero/go-clean.jpg);
			}
			section.services {
				.container:before {
					background-color: #2197d4;
					top: 2.85em;
				}
				.wrapper .service-container {
					background: #1d98d5;
					.service-list {
						min-height: 425px;
						outline: none;
						.img-container img {
							max-width: 100px;
						}
					}
				}
				h4 {
					line-height: 1.5;
				}
			}
			.slick-dots li.slick-active button:before {
				opacity: .75;
				color: #1d98d5;
			}
			#talent-story {
				h4:before {
					display: none;
				}
				h4:after {
					display: inherit;
					content: "";
					position: absolute;
					left: 0;
					top: 4.5%;
					right: 0;
					padding-right: 50px;
					height: 4px;
					margin: 0 auto;
					background-color: #1d98d5;
					z-index: 10;
					width: 5px;
				}
			}
			.section-user-review {
				padding-top: 0;
				padding-bottom: 0; // .slider .item .wrapper {
				// 	min-height: 430px;
				// }
			}
		}
	}
	@media screen and (max-width: 320px) {
		#goclean {
			.as-seen-on #content img {
				max-height: 80px;
			}
		}
	}
	@media screen and (max-width: 35.5em) {
		#goclean {
			section.services {
				padding-bottom: 0;
				.navigation-arrow {
					padding-top: 19em !important;
				}
			}
			header#main--hero {
				// background-image: url(https://d3naj63yelf4gk.cloudfront.net/dist/img/header/hero/go-clean--mobile.jpg);
				.desc--wrapper {
					bottom: 1em;
				}
			}
			.about {
				padding-top: 0;
			}
			.services {
				padding-top: 0;
			}
		}
	}
	@media screen and (max-width: 320px) {
		#goclean {
			header#main--hero {
				background-size: contain;
			}
		}
	}
	@media screen and (min-width: 64em) {
		#goclean {
			section.services {
				.wrapper {
					.navigation-arrow {
						display: none;
					}
					.service-container {
						background-image: none;
						background: #1d98d5;
						.service-list {
							width: 22em;
							padding-bottom: 30px;
						}
					}
				}
			}
			.section-our-values {
				margin-top: 2em;
				padding-bottom: 0;
			}
			#join-now {
				.right {
					margin-left: -1px;
				}
			}
		}
	}
}

@media screen and (max-width: 48em) {
	#goclean .section-user-review .slider .item .wrapper h3.user-testimonial {
		height: 220px;
	}
}
