/* Image Preload */


/* TODO Not Yes done */


/* #promo-banner .img-item img{
  max-height: 300px;
} */

.about .img-container .img-container-2 .pure-img,
.services .img-container .bg-services,
.articles--feed .blog-card-img,
.section-user-review .body img,
.how-it-works--wrapper img,
#talent-story .img-item img,
#join-now img,
.verification .verified-container img,
#talent-story-golife .main-img,
#landing-onboarding .bg--img,
#landing-onboarding .highlight-article .blog-card-img,
.blog-list #blog--listing .post--image,
.blog-list .text-content img{
	display: block;
	width: 100%;
	background-image: url('https://bilba.go-jek.com/dist/img/lazyload.jpg');
	background-repeat: no-repeat;
	background-position: center center;
	background-size: cover;
}

// Custom Style for Blog 
#landing-onboarding .bg--img,
#landing-onboarding .highlight-article .blog-card-img {
	background-size: cover;
}

#gotix {
	#talent-story .img-item img {
		background: none;
	}
}

@media screen and (min-width: 1024px) {
	.about .img-container .img-container-2 .pure-img {
		height: auto;
	}
	.services .wrapper .service-container .service-list .bg-services {
		min-height: 100px;
	}
	.section-user-review .body img {
		transform: scale(1.25);
	}
	.how-it-works--wrapper img {
		height: 363.766px;
	}
	#talent-story .img-item img {
		min-height: 500px;
	}
	#join-now img {
		max-height: 500px;
	}
	#talent-story-golife .main-img {
		min-height: 500px;
	}
	#golife .img-container img {}
}

#talent-story-golife .main-img {
	min-height: 220px;
}
