#gobluebird {
	header#main--hero {
		background-image: url(https://d3naj63yelf4gk.cloudfront.net/dist/img/header/hero/go-massage.jpg);

    .hero--title {
      margin-top: -50px;
      font-size: 37.5px;
    }
    .hero--subtitle {
      color: white;
      font-weight: 300;
    }
    .hero--description {
      color: white;
      font-weight: 300;
    }
	}


  	.how-it-works--wrapper {
  		h4 {
  			color: $gobluebird;
  		    position: relative;
  		    margin-left: 3em;
  		}

  		.section--title:before {
  			content: "";
  		    position: absolute;
  		    left: -5em;
  		    top: 0.6em;
  		    padding-right: 45px;
  		    height: 3px;
  		    margin: 0;
  		    background-color: $gobluebird;
  		    z-index: 10;
  		}

  		figure.hand-ss {
  			background: #fff;

  			img {
  			    max-height: 27em;
  			    width: auto;
  		        right: 0;
  			    margin: 0 auto;
  		        position: absolute;
  			    left: 0;
  			}
  		}

  		.layer .rect--primary {
  			background: $gobluebird;
  		}
  	}
}
