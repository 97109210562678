#landing-faq {
	background: #f8f8f8;
	hr {
		height: 4px;
		width: 50px;
		background: #ffcd2e;
		border: none;
	}
	.box {
		width: 425px;
		font-family: $lato;
		font-weight: 300;
		background: white;
		padding: 1em 2em;
		margin-right: 3em;
		a {
			color: #48af4a;
			font-weight: bold;
		}
		a:hover {
			cursor: pointer;
		}
		h4 {
			font-size: 1.5em;
			font-weight: 300;
		}
	}
}

@media screen and (max-width: 35.5em) {
	#landing-faq {
		margin-top: -2em;
		.container {
			.left,
			.right {
				.box {
					width: auto;
					box-shadow: none;
					padding-bottom: 5em;
				}
			}
		}
	}
}

@media screen and (max-width:48em) {
	#landing-faq {
		.container {
			background: none;
			padding: 0;
			.warper {
				margin-top: 2em;
				padding-top: 3.5em;
				background: #2c822e;
			}
			.left {
				background: none;
			}
			.top,
			.bottom {
				margin-bottom: 7em;
				.warper {
					position: relative;
					height: 14em;
					margin-right: 6em;
				}
				.box {
					margin-top: 0;
					position: absolute;
					margin-bottom: -2em;
					margin-right: -5em;
				}
			}
			.top {
				background: #58c75b;
			}
			.bottom {
				background: #2c822e;
				.warper {
					background: #58c75b;
				}
			}
		}
	}
}

@media screen and (min-width: 64em) {
	#landing-faq {
		padding: 5em 0;
		.container {
			background: none;
			.out-warp:first-child {
				margin-bottom: 3em;
			}
			.out-warp {
				background-image: url('https://d3naj63yelf4gk.cloudfront.net/dist/img/faq/geometry.png');
				background-position: bottom right;
				height: 18.25em;
				cursor: pointer;
				.top,
				.bottom {
					margin-bottom: 7em;
					.warper {
						position: relative;
						height: 14em;
						margin-right: 6em;
					}
					.box {
						position: absolute;
						margin: 4.6em -3em -2em -1px;
						width: 520px;
						height: 225px;
					}
				}
				.top {
					background: #58c75b;
					.warper {
						background: #2c822e;
					}
				}
				.bottom {
					background: #2c822e;
					.warper {
						background: #58c75b;
					}
				}
			}
		}
	}
}
