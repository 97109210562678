#gomed {
	.gradient {
		background: $gomed;
		margin-bottom: 0px;
	}
	.why-container
	{
		margin-top: 4em;
	}
	.section-our-values {
		padding-bottom: 0;
		img {
			max-height: 125px;
		}
		.title {
			color: $gomed;
		}
	}
	#join-now {
		.right {
			padding: 0;
			a {
				background: $gomed;
			}
			.item {
				margin: 2em;
				h4 {
					margin: 3em 0;
					line-height: 1.28;
					letter-spacing: 2px;
				}
			}
		}
	}
	.section-how-to-use {
		padding: 50px;
		height: 620px;
		background-size: cover !important;
		.slider {
			.item-text[data-slide="1"],
			.item-text[data-slide="2"],
			.item-text[data-slide="3"] {
				position: relative;
				top: -30px;
			}
			.item-text[data-slide="4"],
			.item-text[data-slide="5"] {
				position: relative;
				top: -50px;
			}
		}
		.slider-image {
			.item[data-slide="3"] img {
				position: relative;
				top: -30px;
			}
			.item[data-slide="4"] img {
				position: relative;
				top: -45px;
			}
			.item[data-slide="5"] img {
				position: relative;
				top: -55px;
			}
		}
		h5 {
			color: $gomed;
		}
		.line.gradient {
			background: $gomed;
		}
		.title {
			position: relative;
			left: -410px;
		}
		.arrow-up {
			stroke: $gomed;
		}
		.arrow-up-circle {
			stroke-width: 3;
			stroke: $gomed;
		}
		.arrow-down {
			stroke: $gomed;
		}
		.arrow-down-circle {
			stroke: $gomed;
			stroke-width: 3;
		}
		.slider .slick-dots li.slick-active button:before {
			color: $gomed;
		}
		.slider-image .slick-dots li.slick-active button:before {
			color: $gomed;
		}
		.text-mobile {
			display: none;
			text-align: center;
		}
		.text-mobile .line.gradient {
			width: 50px;
			height: 3px;
			margin-top: 0px;
			background: $gomed;
			margin-left: auto;
			margin-right: auto;
		}
		.text h5 {
			margin-top: 100px;
			color: $gomed;
			font-weight: 900;
		}
		.text .line.gradient {
			width: 50px;
			height: 3px;
			margin-top: 0px;
			background: $gomed;
		}
		.phone-base {
			display: block;
			margin-right: auto;
			margin-left: auto;
			top: 50px;
			position: relative;
		}
		.slider .item-text {
			margin-top: 60px;
		}
		.slider .item-text h2 {
			margin-top: 20px;
			margin-left: 0px;
			font-weight: 300;
		}
		.slider .item .wrapper {
			width: 100%;
		}
		.slider-image .slick-list {
			// margin: 40px 0 -112px -20px;
			position: relative;
			left: -155px;
			top: -6px;
		}
		.slider-image .slick-dots {
			position: relative;
			top: -350px;
			left: 30px;
		}
		.slider-image .slick-dots li {
			display: block;
		}
		.slider-image .slick-dots li.slick-active button:before {
			color: $gomed;
		}
		.slider-image .slick-dots li button:before {
			font-size: 13px;
		}
		.slider .slider-text.title {
			position: relative;
			float: right;
			left: -265px;
			top: -20px;
			font-size: 36px;
			margin-top: -320px;
			font-weight: 300;
		}
		.slider .slider-text.desc {
			margin-left: 380px;
			position: relative;
			top: -280px;
			left: 45px;
			margin-bottom: -33px;
			line-height: 1.56;
			font-weight: 300;
			letter-spacing: 1.7px;
		}
		.slider-image {
			position: relative;
			z-index: 99 !important;
			margin-top: -425px;
			margin-bottom: -98px;
			text-align: center;
			margin-right: auto;
			margin-left: auto;
			display: block;
			.phone-slider {
				display: block;
				max-width: 260px;
				margin-right: auto;
				margin-left: auto;
				margin-bottom: 10px;
				position: relative;
				left: 155px;
				top: -17px;
			}
		}
		ul {
			position: relative;
			top: 35%;
			list-style-type: none;
		}
		ul li {
			margin-top: 10px;
		}
		ul li img {
			cursor: pointer;
		}
	}
	@media screen and (max-width: 480px) {
		.section-how-to-use {
			min-height: 815px;
			padding: 50px;
			background-size: cover !important;
			.slider {
				.item-text[data-slide="1"],
				.item-text[data-slide="2"],
				.item-text[data-slide="3"] {
					position: relative;
					top: 0px;
				}
				.item-text[data-slide="4"],
				.item-text[data-slide="5"] {
					position: relative;
					top: 0px;
				}
			}
			.slick-slider {
				display: table;
				table-layout: fixed;
				width: 100%;
			}
			.text-mobile {
				text-align: center;
				display: block;
				margin-right: auto;
				margin-left: auto;
			}
			.text {
				display: none;
			}
			.text h5 {
				margin-top: 100px;
				display: none;
				color: $gomed;
				font-weight: 900;
			}
			.text .line.gradient {
				width: 50px;
				height: 3px;
				margin-top: 0px;
				display: none;
				background: $gomed;
			}
			.phone-base {
				display: block;
				margin-right: auto;
				margin-left: auto;
				top: 50px;
				margin-top: 40px;
				position: relative;
			}
			.slider .item-text {
				margin-top: 100px;
			}
			.slider .item-text h2 {
				margin-top: 20px;
				margin-left: 0px;
				font-weight: 300;
			}
			.slider .item .wrapper {
				width: 100%;
			}
			.slider-image .slick-list {
				// margin: 40px 0 -112px -20px;
				position: relative;
				left: -155px;
				top: -6px;
			}
			.slider-image ul {
				display: none !important;
			}
			.slider-image .slick-dots {
				position: relative;
			}
			.slider-image .slick-dots li {
				display: block;
			}
			.slider-image .slick-dots li.slick-active button:before {
				color: $gomed;
			}
			.slider-image .slick-dots li button:before {
				font-size: 13px;
			}
			.slider .slider-text.title {
				position: relative;
				float: right;
				left: -265px;
				top: -20px;
				font-size: 36px;
				margin-top: -320px;
				font-weight: 300;
			}
			.slider .slider-text.desc {
				margin-left: 380px;
				position: relative;
				top: -280px;
				left: 45px;
				margin-bottom: -33px;
				line-height: 1.56;
				font-weight: 300;
				letter-spacing: 1.7px;
			}
			.slider-image {
				position: relative;
				z-index: 99 !important;
				margin-top: -425px;
				margin-bottom: -128px;
				text-align: center;
				margin-right: auto;
				margin-left: auto;
				display: block;
				position: relative;
				left: 155px;
				.phone-slider {
					display: block;
					max-width: 260px;
					margin-right: auto;
					margin-left: auto;
					margin-bottom: 10px;
					position: relative;
					left: 0;
					top: -17px;
				}
			}
			ul {
				position: relative;
				top: 0px;
				display: none;
				margin-bottom: -100px;
				left: 0px;
				list-style-type: none;
			}
			ul li {
				margin-top: 10px;
			}
			ul li img {
				cursor: pointer;
			}
		}
	}
	@media screen and (max-width: 440px) {
		.section-how-to-use {
			.phone-base {
				display: block;
				margin-right: auto;
				margin-left: auto;
				top: 50px;
				left: 0px;
				margin-top: 40px;
				position: relative;
			}
			.slider-image {
				.phone-slider {
					display: block;
					max-width: 260px;
					margin-right: auto;
					margin-left: auto;
					margin-bottom: 10px;
					position: relative;
					left: 0px;
					top: -17px;
				}
			}
		}
	}
	@media screen and (max-width: 400px) {
		.section-how-to-use {
			min-height: 835px;
			.phone-base {
				display: block;
				margin-right: auto;
				margin-left: auto;
				top: 50px;
				left: -25px;
				margin-top: 40px;
				position: relative;
			}
			.slider-image {
				.phone-slider {
					display: block;
					max-width: 260px;
					margin-right: auto;
					margin-left: auto;
					margin-bottom: 10px;
					position: relative;
					left: -25px;
					top: -17px;
				}
			}
		}
	}
	@media screen and (max-width: 320px) {
		.section-how-to-use {
			min-height: 835px;
			.phone-base {
				display: block;
				margin-right: auto;
				margin-left: auto;
				top: 50px;
				left: -55px;
				margin-top: 40px;
				position: relative;
			}
			.slider-image {
				.phone-slider {
					display: block;
					max-width: 260px;
					margin-right: auto;
					margin-left: auto;
					margin-bottom: 10px;
					position: relative;
					left: 0;
					top: -17px;
				}
				.slick-list {
					position: relative;
					left: -210px;
					top: -6px;
				}
			}
		}
	}
	.about {
		.description {
			padding-bottom: 0;
			a {
				font-size: inherit;
			}
		}
		h4,
		a,
		a::after {
			color: $gomed;
		}
		p {
			padding: 0;
		}
	}
	img .halodoc {
		max-width: 450px;
	}
}

@media screen and (max-width: 35.5em) {
	#gomed {
		header#main--hero {
			p {
				display: none;
			}
		}
		#join-now {
			.right {
				padding-bottom: 2em;
			}
			img {
				width: 100%;
			}
		}
		.section-our-values {
			.title {
				line-height: 1.5em;
			}
		}
	}
}

@media screen and (max-width: 48em) {
	#gomed {
		header#main--hero {
			// background-image: url(https://d3naj63yelf4gk.cloudfront.net/dist/img/header/hero/go-med--mobile.jpg);
		}
		#join-now {
			.right {
				padding-bottom: 2em;
			}
		}
	}
}

