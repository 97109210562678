#join-now {
	padding: 3em 0;
	background: #f8f8f8;
	.bg-layer {
		position: relative; // background-color: #e4292c;
		height: 100%;
	}
	img {
		width: 120%;
		height: 100%;
		opacity: 0.9;
		filter: contrast(0.9);
		mix-blend-mode: multiply;
	}
	.right {
		position: relative;
		font-family: $opensans;
		padding: 3em;
		background: #343b41;
		color: white;
		p:first-child {
			font-family: $opensans;
			font-weight: 700;
		}
		h2 {
			line-height: 1.28;
			letter-spacing: 2px;
		}
		p {
			line-height: 1.5;
			letter-spacing: 1.9px;
			color: #b3b9c7;
		}
		a {
			background: $gojek;
			cursor: pointer;
			color: white;
			font-size: 14px;
			padding: 2em;
			position: absolute;
			bottom: -2em;
			right: 2em;
			border-radius: 5px;
		}
	}
}

@media screen and (max-width: 35.5em) {
	#join-now {
		img {
			width: 100%;
		}
		.right {
			h2 {
				font-size: 1em;
			}
			padding: 3em 1em;
			a {
				right: 1.5em;
			}
		}
	}
}

@media screen and (min-width: 48em) {
	#join-now {
		img {
			width: 100%;
		}
	}
}



section#new-join
{
	text-align: center;
	.container-form
	{
		position: relative;
		display: inline-block;
		width:90%;
		max-width: 1170px;
		@media screen and (max-width: 768px) {
				width:100%;
		}
		.background
		{
			position: absolute;
			height: 100%;
			left: 0;
			top:0;
			background-size: cover;
			background-position: center;
			width: 25%;
			.float-image
			{
				position: absolute;
				height: 100px;
				width: 100px;
				background-size: 100% 100%;
				background-position: center;
				left: 0;
				right: 0;
				top: 0;
				bottom: 0;
				margin: auto;
			}
			@media screen and (max-width: 768px) {
				display:none;
			}
		}
		.content
		{
			float: right;
			width: 75%;
			background: #ffffff;
			box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.19);
			padding: 50px;
			text-align: left;
			@media screen and (max-width: 767px) {
				padding: 50px 10px;
			}
			.title
			{
				font-size: 14px;
			    margin-left: 20px;
			    color: #bcbdc0;
			    font-weight: 700;
			    margin-bottom: 30px;
			    letter-spacing: 1px;
				&:before
				{
					content:"";
					position:relative;
					width:15px;
					height: 15px;
					margin-right: 5px;
					display: inline-block;
					background-size: cover;
					background-position: center;
					background-image: url('/static/dist/img/card/join-form/join-form-icon.png');
				}
			}
			form
			{
				font-family: $opensans;
				.pure-g [class*=pure-u]
				{
					font-family: $opensans;
				}
				.separate-input {
					label.checkbox {
						width: 49%;
						@media screen and (max-width: 767px) {
							width:100%;
						}
					}
				}
				.input-container
				{
					padding: 5px 20px;
					@media screen and (max-width: 768px) {
						width:100%;
					}
				}
				.form-content
				{
					margin-top: 20px;
					@media screen and (max-width: 768px) {
						margin-top: 0px;
					}
				}
				label
				{
					text-transform: uppercase;
					font-size:14px;
					margin-bottom: 10px;
					font-weight: 700;
					display: inline-block;
					letter-spacing: 1.2px;
					color: #697b8c;

					&.radio {
						font-weight: unset;
						cursor: pointer;
				    margin: 0 8px;

						span {
							margin-left: 2px;
						}
					}

					@media screen and (max-width: 768px) {
						line-height: 24px;
					}
				}
				.ct-radio
				{
					position: relative;
					> div
					{
						display: inline-block;
						margin-right: 30px;
						label
						{
							font-weight: normal;
						}
						.info
						{
							display: inline-block;
							margin-left: 5px;
						}
					}
					.parsley-errors-list
					{
						font-weight: 100;
						list-style-type: none;
						padding-left: 0px;
					    margin: 0px;
					    position: absolute;
					    top:0;
					    right: 0;
					    bottom: 0;
					    color: #ff6060;
					    letter-spacing: normal;
					}
				}
				.ct
				{
					position: relative;
					@media screen and (max-width: 768px) {
						margin-bottom: 25px;
					}
					&.parsley-error
					{
						border: 1px solid red !important;
						background-color: #ffeeee;
					}
					.parsley-error
					{
						border: 1px solid red;
						background-color: #ffeeee;
					}
					.parsley-errors-list
					{
						font-weight: 100;
						list-style-type: none;
						padding-left: 0px;
					    margin: 0px;
					    position: relative;
					    bottom: 0;
					    margin-top: 10px;
					    color: #ff6060;
					    letter-spacing: normal;
					}
					span.file-name
					{
						position: absolute;
					    top: 0;
					    bottom: 0;
					    margin: auto;
					    height: 20px;
					    left: 15px;
					}
					&.full-file-container
					{
						border: 1px solid #c3c9d7;
						padding: 30px 0;
						.info
						{
							font-weight: 100;
						    letter-spacing: 1px;
						    margin-bottom: 10px;
						    color: #babcbf;
						    font-size: 14px;
						    padding: 0 20px;
						}
						text-align: center;
						input[type="file"]
						{
							position: absolute;
							height: 100%;
							width: 100%;
							left:0;
							top: 0;
						}
						button.submit-image
						{
							padding: 10px 20px;
							color: #fff;
							font-size: 14px;
							border-radius: 5px;
						}
					}
					&.select
					{
						&:after
						{
							position: absolute;
						    content: "▼";
						    right: 10px;
						    bottom: 0;
						    top: 15px;
						    margin: auto;
						    @media screen and (max-width: 767px) {
						    	right: 15px;
						    }
						}
					}

				}
				input,select,textarea
				{
					transition:all 0.5s;
					outline:none;
					&:disabled
					{
						background:#ececec;
						border:none;
					}
					&:valid
					{
						border:1px solid $gojek;
					}
					&:focus
					{
						border:1px solid #3992f0 !important;
						box-shadow: 0px 0px 3px 1px rgba(57,146,240,1);
						transition:all 0.5s;
						background: transparent !important;
						+ ul
						{
							display: none;
						}
					}
					&::-webkit-input-placeholder
					{
						color: #d4d4d4;
						font-style: oblique;
					}
				}
				input[type=radio] {
					width: unset;
				}
				input,
				textarea
				{
					border: 1px solid #c3c9d7;
					width:100%;
					border-radius:0px;
					padding: 15px 10px;
					&:focus
					{
						outline:none;
					}
				}
				input::-webkit-outer-spin-button,
				input::-webkit-inner-spin-button {
				    -webkit-appearance: none;
				}
				input[type='number']
				{
					-moz-appearance:textfield;
				}
				input[type='checkbox'] {
					display: inline-block;
					width: auto;
				}
				input[type='file']
				{
					cursor: pointer;
					-webkit-appearance:none;
					-moz-appearance:none;
					opacity: 0;
				}
				select
				{
					cursor: pointer;
					-webkit-appearance:none;
					-moz-appearance:none;
					position: relative;
					width: 100%;
					padding: 15px 10px;
					background-color: #fff;
					border-radius:0px;
					border: 1px solid #c3c9d7;
				}
				button.image
				{
					color: #fff;
				    position: absolute;
				    padding: 10px 20px;
				    right: -5px;
				    top: -5%;
				    bottom: 0;
				    border-radius: 5px;
				    height: 110%;
				    pointer-events: none;
				}
				button.submit
				{
					padding: 30px 0;
					width: 200px;
				    margin-top: 50px;
				    float: right;
				    color: #ffffff;
				    border-radius: 5px;
				    margin-bottom: -90px;
				    @media screen and (max-width: 767px) {
						margin-right: auto;
					    margin-left: auto;
					    display: block;
					    float: none;
					}
				}
				.rating > span:hover:before,
				.rating > span:hover ~ span:before {
				   content: "\2605";
				   position: absolute;
				   color: #2b2b2b;
				}
				.star
				{
					position: relative;
				    font-size: 24px;
				    top: 10px;
				    left: 10px;
				    color: #979797;
				    unicode-bidi: bidi-override;
  					direction: rtl;
				    > span:hover:before, > span:hover ~ span:before
				    {
				    	content: "\2605";
				   		position: absolute;
				   		color: #2b2b2b;
				    }
				    span.active:before, span.active ~ span:before
				    {
				    	content: "\2605";
				   		position: absolute;
				   		color: #2b2b2b;
				    }
					> span
					{
						cursor: pointer;
						&:last-child
						{
							padding-right:0;
						}
						padding-right: 5px;
						@media screen and (max-width: 768px) {
							margin-right:10px;
						}
					}
					.parsley-errors-list
					{
						top: 6px;
					    font-size: 16px;
					    font-weight: 100;
					    list-style-type: none;
					    padding-left: 0;
					    margin: 0;
					    position: absolute;
					    color: #ff6060;
					    right: 0px;
					    margin: auto;
					    background-color: #fff;
					    letter-spacing: normal;
					    @media screen and (max-width: 768px) {
							left: 0;
							padding-right: 0px;
							top: auto;
							position: relative;
							margin:20px 0;
						}
					}
					@media screen and (max-width: 768px) {
						left:0;
					}
				}
				.container-captcha
				{
					ul.parsley-errors-list
					{
						margin: 0px;
					    list-style: none;
					    color: #ff6060;
					    font-weight: 100;
					    font-size: 16px;
					    padding-left: 0px;
					}
					width: 100%;
					text-align: center;
					.g-recaptcha
					{
						margin-top: 40px;
						display: inline-block;
					}
				}

			}
			.preview-image
			{
				display: none;
				width: 90%;
				margin: 0px auto;
			    padding: 30px;
			    position: relative;
			    z-index: 10;
			    .image
			    {
			    	position: relative;
			    	.close
				    {
				    	text-align: center;
				    	cursor:pointer;
				    	margin: -20px;
				    	position: absolute;
				    	height: 50px;
				    	width:50px;
				    	right: 0;
				    	top:0;
				    	border-radius:50%;
				    	background-color: #ff6060;
				    	i
				    	{
				    		font-size: 24px;
				    		color: #fafafa;
				    		position: relative;
				    		top: 10px;
				    	}
				    }
			    }
				img
				{
					max-width: 100%;
				}
			}
			.star-container
			{
				@media screen and (max-width: 768px) {
					text-align: center;
				}
			}
			@media screen and (max-width: 768px) {
				width:100%;
				box-shadow:none;
			}
		}
	}
}
