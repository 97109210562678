.highlight-article {
	h5
	{
		font-size: 18px;
	}
	.desktop {
    margin-right: -1.25em;
    margin-left: -1.25em;
	}
	.content {
		margin: 0 0 70px !important;
		padding: 0 1.25em;
		@media screen and (max-width:767px) {
			margin: 0 10px !important;
			padding: 0px;
		}
	}
	.mobile-slide
	{
		@media screen and (max-width:767px) {
			padding-bottom: 100px;
		}
	}
	.hide-mobile
	{
		@media screen and (max-width:767px) {
			display: none;
		}
	}
}
.articles--feed {
	background: #f8f8f8;
	text-align: center;
	font-family: $opensans;
	font-weight: 300;
	line-height: 0.88;
	letter-spacing: 1.7px;
	padding: 2.5em;
	position: relative;
	.index-blog-slide
	{
		margin-top: 30px;
		padding-bottom: 130px;
	}
	.table-display
	{
		background-color: #ffffff;
		padding:15px 18px;
		display: table;
		width: 100%;
		.mid-tabs
		{
			text-align: left;
			display: table-cell;
			vertical-align: middle;
			.logo-blog
			{
				background-size: 22px;
				height: 48px;
				width: 48px;
			}
			.category
			{
				text-transform: uppercase;
				font-size: 9px;
				font-weight: bold;
				letter-spacing: 1px;
				text-align: left;
				color: #b3b9c7;
			}
			.type
			{
				font-size: 18px;
				font-weight: bold;
				text-align: left;
				color: #4a4a4a;
				letter-spacing: 1px;
				padding: 7px 0;
			}
			.date-blog
			{
				font-size: 8px;
				letter-spacing: 0.6px;
				text-align: left;
				color: #485460;
			}
			&.ct-logo
			{
				width:60px;
			}
		}
	}

	.slick-track {
		padding: 25px 0;
	}

	.section--title {
		font-family: Open Sans,sans-serif;
	    color: #fff;
	    text-transform: uppercase;
	    font-size: 1em;
	    margin: 30px 0 20px;
	    letter-spacing: 1.9px;
	    width: 100%;
	    position: relative;
	    z-index: 5;
	}
	.line--title {
		width: 50px;
	    height: 4px;
	    background: #2c822e;
	    margin: 0 auto;
	    margin-bottom: 20px;
	    position: relative;
	    z-index: 5;
	}
	.bg {
    width: 100%;
    height: 550px;
    position: absolute;
    background: #48af4a;
    left: 0;
    margin-top: 0px;
	}
	.slick-slider {
		overflow: hidden;
		padding-bottom: 130px;
	}
	.slick-list {
		overflow: visible;
		// padding-top: 60px !important;
		// padding-bottom: 60px !important;
	}
	.slick-current {
		.content {
		transform: scaleY(1.1);
	    box-shadow: 0 2px 29px 0 rgba(0, 0, 0, 0.19);
	    transition: all 0.5s;
		}
		/*.blog-card-img
		{
			transition: all 0.5s;
			transform: scale(1.05);
		}*/
	}
	.slick-slide {
		outline: none;
	}
	.desktop--nav .slick-arrow {
    border: 3px solid #fff;
    border-radius: 100%;
  }
	.custom-nav {
		top: 50%;
    left: 0;
    position: absolute;
    width: 100%;

    .slick-arrow {
      display: inline-block;
  	  position: absolute;
  	  cursor: pointer;
      padding: 10px;
    }
    .slick-arrow.slick-disabled {
    	opacity: 0.41;
    	cursor: default;
    }
    #feed-prev--desktop,
    #feed-prev--mobile {
    	left: 20px;
    }
    #feed-prev--desktop {
    	transform: rotateY(180deg);
    }
    #feed-next--desktop,
    #feed-next--mobile {
    	right: 20px;
    }
	}
	.custom-nav.mobile--nav {
		display: none;
		bottom: 30px;
    	top: auto;
    	position: absolute;
    	height: 60px;
	}

	.pure-g {
		// margin-top: 3em;
		// margin-bottom: 3em;
	}
	.content:hover .read--more:not(.blog--more) {
		opacity: 1;
	}

	.content {
		margin: 0 1.25em 0;
		transition: all .3s ease-in-out;
	    overflow: hidden;
	    position: relative;
	    .blog--more
		{
			z-index: 4;
		    position: absolute;
		    left: 0;
		    padding: 15px;
		    bottom: 0;
		    a.blog-anchor
			{
				color: #48af4a;
			    text-transform: uppercase;
			    font-weight: 500;
			    display: block;
			}
			a.blog-anchor:after
			{
				content: "";
			    position: absolute;
			    width: 20px;
			    height: 20px;
			    background-size: 15px;
			    background-image: url(../img/about/shape-arrow.svg);
			    margin-left: 12px;
			    background-repeat: no-repeat;
			}
		}
		.read--more:not(.blog--more) {
		    background: #48af4a;
		    z-index: 4;
		    margin-top: -40px;
	      	width: 85%;
	      	max-width: 270px;
		    opacity: 0;
		    -webkit-transition: opacity .3s ease-in-out;
		    -moz-transition: opacity .3s ease-in-out;
		    transition: opacity .3s ease-in-out;
		    border-radius: 5px;
		    position: absolute;
		    left: 0;
		    right: 0;
		    margin: -10% auto;

		    a:not(.blog-anchor){
  	    		color: #fff;
			    text-transform: uppercase;
			    font-weight: 500;
			    display: block;
			    padding: 30px 0px;
		    }
			a:not(.blog-anchor):after {
			    content: url(https://d3naj63yelf4gk.cloudfront.net/dist/img/header/arrow.png);
				position: relative;
				left: 10px;
			}
		}
		.blog-card-img {
			transition: all 0.5s;
			height: 300px;
		    background-size: cover;
		    background-position: center center;
		    background-color: #333;
		    @media screen and (max-width:767px) {
		    	height: 200px;
		    }
		}
		.blog-title-index
		{
			h4
			{
				a {
					letter-spacing: 1px;
					color: #4a4a4a;
					@media screen and (max-width:767px) {
						font-size: 16px !important;
					}
					@media screen and (min-width:768px) {
						font-size: 22px !important;
					}
				}
				a:hover {
					color: #48af4a;
				}
			}
		}
		.blog-card-title {
			position: relative;
			text-align: left;
			padding: 15px;
			color: black;
			background: white;
			font-family: $opensans;
			font-weight: 300;
			line-height: 1.42;
			letter-spacing: 2.2px;
			height: 175px;
			h4 {
				margin: 0px;
				font-family: $opensans;
				font-weight: 300;
				letter-spacing: 1px;
				a
				{
					color: #000;
					font-size: .875em;
					line-height: normal;
					@media screen and (max-width:35.5em) {
						font-size: 12px;
					}
				}
			}
			p {
				font-size: 0.65em;
				font-family: $opensans;
				font-weight: 700;
				line-height: 1.0;
				letter-spacing: 1.5px;
				color: #c3c9d7;
				text-transform: uppercase;
			}
			@media screen and (max-width:767px) {
				height:170px;
			}
		}
		.one {
			border-bottom: 6px solid #d0021b;
		}
		.two {
			border-bottom: 6px solid #98ce00;
		}
		.three {
			border-bottom: 6px solid #ffcd2e;
		}
		.four {
			border-bottom: 6px solid #2c822e;
		}
		.five {
			border-bottom: 6px solid #0bb2ee;
		}
		.six {
			border-bottom: 6px solid #e77bcc;
		}
		@media screen and (max-width:767px) {
			padding:0px;
			margin:0 7px !important;
		}
	}
}
#onboarding-head .title .content .text h2
{
	font-weight: 300 !important;
}
#onboarding-head .title .content
{
	border-radius: 0px 0px 0px 20px;
}
[data-service*="gojek"] {
	.blog-card-title:not(.blog-title-index) {
		border-bottom: 6px solid $gojek;
	}
	.logo-blog
	{
		@include icon-gojek;
	}
	.table-display
	{
		border-top:4px solid $gojek;
	}
}
[data-service*="corporate"] {
	.blog-card-title:not(.blog-title-index) {
		border-bottom: 6px solid $gojek;
	}
	.logo-blog
	{
		@include icon-gojek;
	}
	.table-display
	{
		border-top:4px solid $gojek;
	}
	&.post-item
	{
		.sub-logo
		{
			.table-display
			{
				border-top: none !important;
			}
		}
	}
}
[data-service*="gocar"] {
	.blog-card-title:not(.blog-title-index) {
		border-bottom: 6px solid $gocar;
	}
	.logo-blog
	{
		@include icon-gocar;
	}
	.table-display
	{
		border-top:4px solid $gocar;
	}
}
[data-service*="gotix"] {
	.blog-card-title:not(.blog-title-index) {
		border-bottom: 6px solid $gotix;
	}
	.table-display
	{
		border-top:4px solid $gotix;
	}
	.logo-blog
	{
		@include icon-gotix;
	}
}
[data-service*="gofood"] {
	.blog-card-title:not(.blog-title-index) {
		border-bottom: 6px solid $gofood;
	}
	.table-display
	{
		border-top:4px solid $gofood;
	}
	.logo-blog
	{
		@include icon-gofood;
	}
}
[data-service*="gopay"] {
	.blog-card-title:not(.blog-title-index) {
		border-bottom: 6px solid $gopay;
	}
	.table-display
	{
		border-top:4px solid $gopay;
	}
	.logo-blog
	{
		@include icon-gopay;
	}
}
[data-service*="goride"] {
	.blog-card-title:not(.blog-title-index) {
		border-bottom: 6px solid $gopay;
	}
	.table-display
	{
		border-top:4px solid $goride;
	}
	.logo-blog
	{
		@include icon-goride;
	}
}
[data-service*="gosend"] {
	.blog-card-title:not(.blog-title-index) {
		border-bottom: 6px solid $gosend;
	}
	.table-display
	{
		border-top:4px solid $gosend;
	}
	.logo-blog
	{
		@include icon-gosend;
	}
}
[data-service*="gomart"] {
	.blog-card-title:not(.blog-title-index) {
		border-bottom: 6px solid $gomart;
	}
	.table-display
	{
		border-top:4px solid $gomart;
	}
	.logo-blog
	{
		@include icon-gomart;
	}
}
[data-service*="gobox"] {
	.blog-card-title:not(.blog-title-index) {
		border-bottom: 6px solid $gobox;
	}
	.table-display
	{
		border-top:4px solid $gobox;
	}
	.logo-blog
	{
		@include icon-gobox;
	}
}
[data-service*="gopoint"] {
	.blog-card-title:not(.blog-title-index) {
		border-bottom: 6px solid $gopoint;
	}
	.table-display
	{
		border-top:4px solid $gopoint;
	}
	.logo-blog
	{
		@include icon-gopoint;
	}
}
[data-service*="gopulsa"] {
	.blog-card-title:not(.blog-title-index) {
		border-bottom: 6px solid $gopulsa;
	}
	.table-display
	{
		border-top:4px solid $gopulsa;
	}
	.logo-blog
	{
		@include icon-gopulsa;
	}
}
[data-service*="gobusway"] {
	.blog-card-title:not(.blog-title-index) {
		border-bottom: 6px solid $gobusway;
	}
	.table-display
	{
		border-top:4px solid $gobusway;
	}
	.logo-blog
	{
		@include icon-gobusway;
	}
}
[data-service*="gomed"] {
	.blog-card-title:not(.blog-title-index) {
		border-bottom: 6px solid $gomed;
	}
	.table-display
	{
		border-top:4px solid $gomed;
	}
	.logo-blog
	{
		@include icon-gomed;
	}
}
[data-service*="gomassage"] {
	.blog-card-title:not(.blog-title-index) {
		border-bottom: 6px solid $gomassage;
	}
	.table-display
	{
		border-top:4px solid $gomassage;
	}
	.logo-blog
	{
		@include icon-gomassage;
	}
}
[data-service*="goclean"] {
	.blog-card-title:not(.blog-title-index) {
		border-bottom: 6px solid $goclean;
	}
	.table-display
	{
		border-top:4px solid $goclean;
	}
	.logo-blog
	{
		@include icon-goclean;
	}
}
[data-service*="goauto"] {
	.blog-card-title:not(.blog-title-index) {
		border-bottom: 6px solid $goauto;
	}
	.table-display
	{
		border-top:4px solid $goclean;
	}
	.logo-blog
	{
		@include icon-goauto;
	}
}
[data-service*="goglam"] {
	.blog-card-title:not(.blog-title-index) {
		border-bottom: 6px solid $goglam;
	}
	.table-display
	{
		border-top:4px solid $goglam;
	}
	.logo-blog
	{
		@include icon-goglam;
	}
}
[data-service*="golife"] {
	.blog-card-title:not(.blog-title-index) {
		border-bottom: 6px solid $golife;
	}
	.table-display
	{
		border-top:4px solid $golife;
	}
	.logo-blog
	{
		@include icon-golife;
	}
}
[data-service*="gobills"] {
	.blog-card-title:not(.blog-title-index) {
		border-bottom: 6px solid $gobills;
	}
	.table-display
	{
		border-top:4px solid $gobills;
	}
	.logo-blog
	{
		@include icon-gobills;
	}
}
[data-service*="gopertamina"] {
	.blog-card-title:not(.blog-title-index) {
		border-bottom: 6px solid $gopertamina;
	}
	.table-display
	{
		border-top:4px solid $gopertamina;
	}
	.logo-blog
	{
		@include icon-gopertamina;
	}
}
[data-service*="godaily"] {
	.blog-card-title:not(.blog-title-index) {
		border-bottom: 6px solid $godaily;
	}
	.table-display
	{
		border-top:4px solid $godaily;
	}
	.logo-blog
	{
		@include icon-godaily;
	}
}
[data-service*="gofix"] {
	.blog-card-title:not(.blog-title-index) {
		border-bottom: 6px solid $gofix;
	}
	.table-display
	{
		border-top:4px solid $gofix;
	}
	.logo-blog
	{
		@include icon-gofix;
	}
}
[data-service*="golaundry"] {
	.blog-card-title:not(.blog-title-index) {
		border-bottom: 6px solid $golaundry;
	}
	.table-display
	{
		border-top:4px solid $golaundry;
	}
	.logo-blog
	{
		@include icon-golaundry;
	}
}

@media screen and (max-width:768px) {
	.articles--feed
	{
		.container
		{
			width: 680px;
		}
	}
}

@media screen and (max-width:35.5em) {
	.articles--feed {
		padding: 0;

		.container {
		    padding: 0;
		    width: 100% !important;
		    margin:0px;
		}

		.custom-nav.desktop--nav {
			display: none;
		}
		.custom-nav.mobile--nav {
			display: block;
		}
		.bg
		{
			height: 620px;
		}
		.custom-nav {
			#feed-prev--mobile {
				left: 3.5em;
			}
			#feed-next--mobile {
				right: 3.5em;
			}
		}
		.content {
    		margin: 0 0.5em 5em;
    	}
	}
}
