.highlight-article, .top-picks {
	background: #f8f8f8;
	text-align: center;
	font-family: $opensans;
	font-weight: 300;
	line-height: 0.88;
	letter-spacing: 1.7px;
	color: #48af4a;
	padding: 2.5em;
	.container
	{
		@media screen and (min-width: 1200px) {
			width: 80%;
		}
	}
	hr {
		background-image: linear-gradient(98deg, #9fda36, #25c768);
		border: none;
		display: inline-block;
		width: 50px;
		height: 4px;
	}
	.pure-g {
		margin-top: 3em;
	}
	.content {
		margin: 0 1.25em 5em;
		transition: all .3s ease-in-out;

		.blog-card-title {
			text-align: left;
			padding: 1em 1.5em;
			color: black;
			background: white;
			font-family: $opensans;
			font-weight: 300;
			line-height: 1.42;
			letter-spacing: 2.2px;
			h4 {
				font-family: $lato;
				font-weight: 300;
				line-height: 1.33;
				letter-spacing: 2.5px;

				a {
					color: #000;
				}
				a:hover {
					color: $gojek;
				}
			}
			p {
				font-size: 0.65em;
				font-family: $opensans;
				font-weight: 700;
				line-height: 1.0;
				letter-spacing: 1.5px;
				color: #c3c9d7;
				text-transform: uppercase;
			}
		}
	}
	.mobile {
		display: none;
	}
	.custom-nav {
		display: none;
		top: 85% !important;
	}
}

#karir
{
	.desktop
	{
		display: block !important;
		> .pure-u-1
		{
			@media screen and (max-width:767px) {
				margin-bottom: 30px;
			}
		}
		@media screen and (max-width:767px) {
			width:90%;
			margin:auto;
			.blog-card-title
			{
				height: auto;
				padding-bottom: 75px;
			}
		}
	}
	.blog-list
	{
		.container
		{
			@media screen and (min-width:769px) {
				width: 80%;
			}
		}
		.sub-logo
		{
			.table-display
			{
				padding: 10px;
			}
		}
		.container-sub-title
		{
			.title-blog-career
			{
				padding: 0 40px;
			    display: table-cell;
			    vertical-align: middle;
			}
			.title
			{
				padding: 0px;
				display: inline-block;
				width: 100%;
			}
			.auth-new
			{
				display: inline-block;
				width: 100%;
				margin-top: 10px;
				letter-spacing: normal;
			}
		}
		.pre--title
		{
			p.service
			{
				margin: 5px 0;
			}
		}
	}
}

@media screen and (max-width:35.5em) {
	.highlight-article {
		padding: 2em 0 5em 0;
		.desktop {
			display: none;
		}
		.mobile {
			display: block;
		}
		.custom-nav {
			display: block;
			#custom-prev {
				left: 3.5em;
				position: absolute;
			}
			#custom-next {
				right: 3.5em;
				position: absolute;
			}
		}
		.slick-track {
			padding-top: 3em;
		}
		.content {
    	margin: 0 0.5em 5em;
		}
		.content.slick-current {
	    box-shadow: 0 2px 29px 0 rgba(0, 0, 0, 0.19);
    	transform: scaleY(1.15);
		}
	}
}
