#djDebug
{
	opacity: 0;
	pointer-events: none;
}
[data-background*="starwars"]
{
	background-color: #cd0000 !important;
}
[data-background*="goride"]
{
	background-color: $goride !important;
}
[data-background*="gobills"]
{
	background-color: $gobills !important;
}
[data-background*="gocar"]
{
	background-color: $gocar !important;
}
[data-background*="gofood"]
{
	background-color: $gofood !important;
}
[data-background*="gomart"]
{
	background-color: $gomart !important;
}
[data-background*="gosend"]
{
	background-color: $gosend !important;
}
[data-background*="gobox"]
{
	background-color: #f2db04 !important;
}
[data-background*="gotix"]
{
	background-color: $gotix !important;
}
[data-background*="gopay"]
{
	background-color: $gopay !important;
}
[data-background*="gopoint"]
{
	background-color: $gopoint !important;
}
[data-background*="gomassage"]
{
	background-color: $gomassage !important;
}
[data-background*="goclean"]
{
	background-color: $goclean !important;
}
[data-background*="goglam"]
{
	background-color: $goglam !important;
}
[data-background*="goauto"]
{
	background-color: #043659 !important;
}
[data-background*="golife"]
{
	background-color: $golife !important;
}

.hamburger-mobile
{
	position: fixed;
	top: 23px;
	right: 15px;
	height:16px;
	width: 22px;
	z-index: 2002;
	div
	{
		height: 2px;
		width: 100%;
		position: absolute;
		background-image: linear-gradient(144deg, #9fda36, #25c4c7);
		right: 0;
		transition: all 0.5s;
	}
	div.active
	{
		width: 16px !important;
		background-image: linear-gradient(144deg, #8dc63f, #8dc63f);
	}
	> div:nth-child(1)
	{
		width: 95%;
		top: 0;
	}
	> div:nth-child(1).active
	{
		transform: rotate(135deg) translate3d(4px,-4px,0px);
		width: 100%;
	}
	> div:nth-child(2)
	{
		width: 80%;
		top: 0;
		bottom: 0;
		margin: auto;
		transition: all 0.5s;
	}
	> div:nth-child(2).active
	{
		transform: translateX(60px);
		transition: all 0.5s;
	}
	> div:nth-child(3)
	{
		bottom: 0;
	}
	> div:nth-child(3).active
	{
		transform: rotate(-135deg) translate3d(5.8px,5.8px,0px);
	}
	@media screen and (min-width: 769px) {
		display:none;
	}
}
.overlay-menu-mobile
{
	position: fixed;
	height: 100%;
	width: 100%;
	background-color: rgba(0,0,0,0.8);
	top: 0;
	left: 0;
	z-index: 2001;
	opacity: 0;
	pointer-events: none;
	transition: all 0.5s 0.3s;
	&.active
	{
		opacity: 1;
		pointer-events: visible;
		transition: all 0.5s;
	}

	.active-current--lang {
    padding: 10px 0;
    text-align: center;
    text-transform: uppercase;
	}


	.dropdown-wrapper {
		select {
			background: transparent;
			width: 100%;
			height: 40px;
			padding: 0px 10px;
			border-color: #e8ebf2 !important;
		}

	}

	.content-menu
	{
		position: fixed;
		overflow: hidden;
		width: calc(100% - 45px);
		height: 100%;
		background: #ffffff;
		transform: translateX(-100%);
		transition: all 0.5s;
		&.active
		{
			transform: translateX(0%);
			transition: all 0.5s 0.2s;
		}
		.header-menu
		{
			width: 100%;
			height: 60px;
			background-color: #fff;
			.navbar-brand-menu
			{
				width: 100%;
				padding: 0 15px 0;
		    position: relative;
		    top: 50%;
		    -webkit-transform: translateY(-50%);
		    -moz-transform: translateY(-50%);
		    -o-transform: translateY(-50%);
		    transform: translateY(-50%);
				img
				{
					width: 39px;
				}
			}
			.menu-list
			{
				    top: 60px;
				    position: absolute;
				    height: calc(100% - 45px);
				    overflow: auto;
				    width: 100%;
				    padding:0 15px;
				    transition: all 0.5s;
				    ul
				    {
				    	padding:0;
				    	margin: 0;
				    	list-style-type: none;
				    	li
				    	{
				    		padding: 15px 0;
				    		text-transform: uppercase;
				    		font-family: Lato,Helvetica,sans-serif;
						    text-transform: uppercase;
						    a
						    {
						    	width: 100%;
						    	display: inline-block;
						    	color: #000;
						    	font-size: 14px;
						    	letter-spacing: 1px;
						    	i
						    	{
						    		float: right;
						    	}
						    }
						    &.active
						    {
						    	font-weight: 700;
						    }
				    	}
				    }
				    &.disactive
				    {
				    	transform: translateX(100%);
				    	transition: all 0.5s;
				    }
			}
			.accordion
			{
				width: 100%;
				height: 100%;
				overflow: auto;
				transition: all 0.5s;
				transform: translateX(-100%);
				top: 60px;
				position: absolute;
				padding-bottom: 50px;
				.service_name
				{
					display: none;
				}
				.service_group
				{
					width: 100%;
			    	display: inline-block;
			    	font-family: Lato,Helvetica,sans-serif;
				    text-transform: uppercase;
				    letter-spacing: 1px;
				    font-size: 14px;
				    color: #000;
				    padding: 15px;
				    i
				    {
				    	transition: all 0.5s;
				    	float: right;
				    }
				    i.rotate-down
				    {
				    	transform: rotate(90deg);
				    	transition: all 0.5s;
				    }
				    &.back-menu
				    {
				    	background-color: #fafafa;
				    	i
				    	{
				    		float: none;
				    		margin-right: 15px;
				    	}
				    }
			    }
			    ul.service_name
			    {
			    	padding:0;
			    	margin: 0;
			    	list-style-type: none;
			    	padding-left: 30px;
			    	li
			    	{
			    		padding: 15px 0;
			    		text-transform: uppercase;
			    		font-family: Lato,Helvetica,sans-serif;
					    a
					    {
					    	width: 100%;
					    	display: inline-block;
					    	color: #000;
					    	font-size: 14px;
					    	letter-spacing: 1px;
					    	i
					    	{
					    		float: right;
					    	}
					    }
			    	}
			    }
			    &.active
			    {
			    	transform: translateX(0%);
			    }
			}
		}
		.table-display
		{
			display: table;
			width: 100%;
			.middle-table
			{
				display: table-cell;
				vertical-align: middle;
				.logo
				{
					margin: 9px 0;
					margin-right: 5px;
					height: 35px;
					text-align: center;
					width: 35px;
					background-color: #ffffff;
					.table-display
					{
						height: 100%;
					}
					img
					{
						height: 23px;
                        margin-top: 7px;
					}
				}
				a
				{
					font-size: 12px;
					letter-spacing: 1px;
					font-weight: 700;
					color: #000;
				}
			}
		}
		.social-media
		{
			margin-top: 25px;
			text-align: center;
			ul
			{
				li
				{
					display: inline-block;
					margin-right: 15px;
					padding: 5px 0 !important;
					i
					{
						font-size: 24px;
					}
				}
				li:last-child
				{
					margin-right: 0px;
				}
			}
		}
	}
}
.navbar {
	&.no--active
	{
		@media screen and (min-width: 769px) {
			top: -84px;
		}
	}
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 2000;
    -webkit-transition: all 0.5s ease 0s;
    -moz-transition: all 0.5s ease 0s;
    -o-transition: all 0.5s ease 0s;
    transition: all 0.5s ease 0s;
    background: rgb(255,255,255);
    box-shadow: none;
    @media screen and (max-width: 768px) {
    	height: 60px;

    	& > .container {
		    top: 50%;
			  position: relative;
			  transform: translateY(-50%);
	    }

	    .brand {
				img {
					width: 100% !important;
					max-width: 30px;
				}
			}
    }

		.dropdown-wrapper {
			border:1px solid $gojek;
			border-radius: 4px;
			float: right;
			position: relative;
			height: 35px;
			line-height: 35px;
			@media screen and (max-width: 768px) {
				margin-right: 35px;
			}
			&.gabung
			{
				margin-right: 15px;
			}
			.container-dropdown
			{
				height:100%;
			}
			div
			{
				float: left;
				&.icon
				{
					cursor: pointer;
					background-repeat: no-repeat;
    				background-size: 17px;
    				background-position: center;
					height: 100%;
					width: 30px;
					color: #ffffff;
    				text-align: center;
					background-color: $gojek;
					svg
					{
						pointer-events: none;
						position: relative;
						top:2px;
						@media screen and (max-width: 768px) {
							top:3px;
							path
							{
								fill: $gojek;
							}
						}
					}
					@media screen and (max-width: 768px) {
						background-color:transparent;
					}
				}
				&.current
				{
					cursor: pointer;
					text-transform: uppercase;
					letter-spacing: 1px;
					padding: 0 10px;
					color:$gojek;
					font-size: 12px;
					@media screen and (max-width: 768px) {
						display: none;
					}
				}
			}
			select {
				background: transparent;
				height: 35px;
				width: 60px;
				border-color: #e8ebf2 !important;
			}
			.container-language
			{
				@media screen and (max-width: 768px) {
					transition: all 0.5s 0.1s;
					opacity: 0;
					pointer-events: none;
					position: fixed;
					margin-top: 60px;
					height: calc(100vh - 55px);
					width: 100%;
					left: 0;
					top: 0;
					background-color: rgba(0,0,0,0.8);

					.select-lang
					{
						display: table;
						vertical-align: middle;
						left: 0;
						right: 0;
						top: 0;
						bottom: 0;
						margin: auto;
						transform: scale(0.8);
					}
				}

				.select-lang
				{
					transition: all 0.5s;
					opacity: 0;
					pointer-events: none;
					padding: 13px;
					position: absolute;
					background-color: #ffffff;
					right: 0;
					box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
					border-radius:5px;
					top: -3px;
			    width: 200px;
			    right: -15px;
			    color: #4a4a4a;
			    font-family: $opensans;
	        z-index: 1000;
				    form
				    {
				    	display: none;
				    }
				    .close
				    {
				    	cursor: pointer;
				    	padding: 0px 0px !important;
				    	position: absolute;
				    	height: 20px;
				    	width: 20px !important;
				    	top:13px;
				    	right: 13px;
				    	border-bottom: none !important;
				    	&:after
				    	{
				    		transform: rotate(45deg) translate3d(10px,10px,10px);
						    height: 2px;
						    position: absolute;
						    width: 100%;
						    background: $gojek;
						    content:"";
				    	}
				    	&:before
				    	{
				    		content: "";
				    		position: absolute;
						    height: 2px;
						    width: 100%;
						    background: $gojek;
						    transform: rotate(-45deg) translate3d(-10px,10px,10px);
				    	}
				    }
				    .head
				    {
				    	pointer-events: none;
				    	line-height: normal;
				    	font-size: 20px;
				    	font-weight: 300;
				    	margin-bottom: 10px;
				    }
				    .choose-lang
				    {
					    > div
					    {
					    	width: 100%;
					    	cursor: pointer;
					    	line-height: normal;
					    	padding: 7px 0;
					    	font-size: 12px;
					    	border-bottom:1px solid #fafafa;
					    	@media screen and (min-width: 769px) {
					    		transition: all 0.5s;
					    		&:hover
					    		{
					    			transform:translateX(10px);
					    			color: $gojek;
					    			transition: all 0.5s;
					    		}
					    	}
					    }
					}
				}
				&.active
			    {
			    	.select-lang
			    	{
				    	opacity: 1;
				    	pointer-events: visible;
				    	transition: all 0.5s;
				    }
				    @media screen and (max-width: 768px) {
				    	opacity:1;
				    	pointer-events:visible;
				    	transition: all 0.5s;

				    	.select-lang
				    	{
					    	opacity: 1;
					    	transform: scale(1);
					    	pointer-events: visible;
					    	transition: all 0.5s 0.2s;
					    }
				    }
			    }
			}

			@media screen and (max-width: 768px) {
				border:none;
			}
		}
    #gojek-brand-mobile {
      display: none;
    }
    #gojek-brand-desktop {
      display: block;
    }

    .wrapper {
      padding: 25px 0;
      @media screen and (max-width: 768px) {
      	padding:14px 0;
      }
    }

    .brand img {
	  	width: 100%;
      position: relative;
	    padding: 0;
	    height: auto;
	    top: 6px;
    }

    @media screen and (max-width: 35.5em) {
    	.brand img {
	    	width: 65px;
	    }

			.dropdown-wrapper[data-entity=hamburger-menu] {
				display: block;
			}
    }

    ul {
      list-style-type: none;
      margin: 0;
    }

    ul li {
      float: left;
    }
    ul li.active {
      color: $gojek !important;
    }

    ul li a {
      position: relative;
      font-size: 12.5px;
      letter-spacing: 1.1px;
      color: #485460;
      margin: 0 20px 0 20px;
      top: 7px;
      text-decoration: none;
      -webkit-transition: all .3s ease-in-out;
      -moz-transition: all .3s ease-in-out;
      -o-transition: all .3s ease-in-out;
      transition: all .3s ease-in-out;
    }

    ul li a:not(.career-tab):hover,
    ul li a.active {
      color: $gojek !important;
    }

    ul li a.active:after {
      content: "";
      width: 100%;
      position: absolute;
      top: 50px;
      height: 2.5px;
      left: 0px;
      background: $gojek;
      -webkit-transform: translateY(-50%);
      transform: translateY(-50%);
    }

    .btn.btn-style-1 {
    	letter-spacing: 1px;
      padding: 10.75px;
      float: right;
      text-decoration: none;
      font-size: 12px;
      color: $gojek;
      margin: 0 10px 0 0px;
      background: white;
      border: solid 1px $gojek;
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
      border-bottom-left-radius: 0;
      border-top-left-radius: 0;
      line-height: .8em;
    }

    .btn-style-1-icon {
      float: right;
      color: white;
      object-fit: contain;
      background: $gojek;
      cursor: pointer;
      padding: 8px;
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;

      img {
      	vertical-align: inherit;
      }
    }

    .btn.btn-style-1 > span {
      margin: 0 10px 0 10px;
    }

    .btn.btn-style-2 {
      padding: 7.5px;
      float: right;
      text-decoration: none;
      font-size: 12.5px;
      color: white;
      background: #fecb42;
      margin: 0 5px 0 5px;
      border: solid 1px #fecb42;
    }

    .btn.btn-style-2 > span {
      margin: 0 5px 0 5px;
    }

   .hamburger {
     display: none;
     float: right;
   }

   .hamburger > i {
     font-size: 20px;
     color: #9fda36;
   }
}

.megamenu.product {
	display: none;
	background: rgb(240, 240, 240);
	width: 100%;
	height: 100%;
	padding: 65px;
	position: fixed;
	top: 82px;
	z-index: 2001;
	overflow-y: scroll;
	.toggleClose {
		margin-top: -35px;
		margin-bottom: 0px;
		font-size: 44px;
		float: right;
		color: rgb(150, 150, 150);
	}

  .product-wrapper {
    .list {
      position: relative;

      .more {
        display: none;
        padding: 0;
        background: #48af4a;
        color: #fff;
        border-radius: 5px;
        font-family: Open Sans,Helvetica,sans-serif;
        letter-spacing: 1.2px;
        font-weight: 600;
        font-size: 12px;
        position: absolute;
        bottom: -25px;
        left: 50%;
        width: 120px;
				-webkit-transform: translateX(-50%);
				-moz-transform: translateX(-50%);
				-o-transform: translateX(-50%);
				transform: translateX(-50%);

        span {
          padding: 15px 10px;
          display: block;
        }
      }
      img {
		width: 100%;
        margin: 0 auto;
        top: 50%;
        -webkit-transform: translateY(-50%);
        -moz-transform: translateY(-50%);
        -o-transform: translateY(-50%);
        transform: translateY(-50%);
        position: relative;
      }
    }
    .list:hover .more {
      display: block;
    }
  }

	.gojek-section {
		margin-top: 0px;
		margin-bottom: 40px;
		img {
			max-width: 100px;
		}
		p {
			margin-top: 20px;
			font-size: 13px;
			line-height: 1.88;
			letter-spacing: 1px;
		}
		.product-wrapper {
			position: relative;
			top: 15px;
			.list {
				background: white;
				padding: 20px;
				text-align: center;
				height: 115px;
				margin-right: 15px;
				margin-bottom: 45px;
				img {
					max-width: 100px;
					display: block;
				}
			}
		}
		hr {
			margin-top: 50px;
			border: 1px solid rgb(230, 230, 230);
		}
	}
	.gopay-section {
		margin-top: 0px;
		margin-bottom: 70px;
		img {
			max-width: 100px;
		}
		p {
			margin-top: 20px;
			font-size: 13px;
			line-height: 1.88;
			letter-spacing: 1px;
		}
		.product-wrapper {
			position: relative;
			top: 15px;
			.list {
				background: white;
				padding: 20px;
				height: 115px;
				text-align: center;
				margin-right: 15px;
				margin-bottom: 45px;
				img {
					max-width: 100px;
					display: block;
				}
			}
		}
		hr {
			margin-top: 50px;
			border: 1px solid rgb(230, 230, 230);
		}
	}
	.golife-section {
		margin-top: 0px;
		margin-bottom: 40px;
		img {
			max-width: 100px;
		}
		p {
			margin-top: 20px;
			font-size: 13px;
			line-height: 1.88;
			letter-spacing: 1px;
		}
		.product-wrapper {
			position: relative;
			top: 15px;
			.list {
				background: white;
				padding: 20px;
				height: 115px;
				text-align: center;
				margin-right: 15px;
				margin-bottom: 45px;
				img {
					max-width: 100px;
					display: block;
				}
			}
		}
		hr {
			margin-top: 50px;
			border: 1px solid rgb(230, 230, 230);
		}
	}
}

.megamenu.join {
	display: none;
	background: rgb(240, 240, 240);
	width: 100%;
	height: 100%;
	padding: 65px;
	position: fixed;
	top: 84px;
	z-index: 2001;
	overflow-y: scroll;
	.toggleClose {
		margin-top: -35px;
		margin-bottom: 50px;
		font-size: 44px;
		float: right;
		color: rgb(150, 150, 150);
	}
	.table-display
	{
		position: relative;
		z-index: 2;
		display: table;
		width: 100%;
		height: 100%;
		.mid-table
		{
			display: table-cell;
			vertical-align: top;
			.title-mega
			{
				line-height: 9px;
				font-size: 9px;
				font-weight: 700;
				color: #343b41;
				margin-top: 5px;
			}
			.extra
			{
				position: absolute;
				font-size: 9px;
				color: #343b41;
				margin: auto;
				margin-top: 2px;
				left: 0;
				right:0;
			}
		}
	}
	.gojek-section {
		margin-top: -10px;
		margin-bottom: 40px;

		h3.title {
			font-family: $lato;
			letter-spacing: 0;
			font-size: 36px;
			font-weight: 300;
			color: #343b41;
		}
    h4.service-type {
      color: $gojek;
      font-size: 18px;
    }
    p.service-type-desc {
      font-family: $opensans;
      font-size: 13px;
      color: #343b41;
			line-height: 1.88;
      height: 110px;
    }
		img {
			max-width: 100px;
		}
		p {
			margin-top: 20px;
			font-size: 13px;
			line-height: 1.88;
			letter-spacing: 0;
			color: #343b41;
		}
		.product-wrapper {
			position: relative;
			top: 0;
			left: 5%;

      .list-services-type {
        padding: 10px;
        width: 32.9% !important;
      }

      .join-list--wrapper {
        margin: 0 -7px;

        .list {
          background: #fff;
          margin: 8px 5px;
          height: 70px;
          overflow: hidden;
          text-align: center;
          width: 28.1667% !important;
          position: relative;

          .more {
          	transform: translateY(100%);
          	background: #48af4a;
	        top: 0;
	        left: 0;
	        position: absolute;
	        width:100%;
	        height: 100%;
	        transition: transform 0.5s;

            span {
              padding: 7px 10px;
              display: block;
            }
          }

          .more.GO-SEND
          {
          	background-color: #8bc440 !important;
          }
          .more.GO-CAR
          {
          	background-color: #17619d;
          }
          .more.GO-MART
          {
          	background-color: #4b9cd2;
          }
          .more.GO-RIDE
          {
          	background-color: #f9ae3c;
          }
          .more.GO-FOOD
          {
          	background-color: #d8232a;
          }
          .more.GO-TIX
          {
          	background-color: #eb6b23;
          }
          .more.GO-MASSAGE
          {
          	background-color: #17b4ad;
          }
          .more.GO-GLAM
          {
          	background-color: #e87699;
          }
          .more.GO-CLEAN
          {
          	background-color: #218fc2;
          }
          .more.GO-BOX
          {
          	background-color: #9d662e;
          }
          .more.GO-MED
          {
          	background-color: #006738;
          }
          .more.GO-POINT
          {
          	background-color: #f7951d;
          }
          .more.GO-AUTO
          {
          	background-color: $goauto;
          }

          img {
          	position: relative;
          	z-index: 1;
            width: 80%;
			margin: auto;
          }
        }
        .list:hover
        {
        	.more {
          		transform: translateY(0%);
      		}
      		img
      		{
      			filter: grayscale(100%) brightness(100);
      			transition: filter 0s 0.05s ease;
      		}
      		.title-mega, .extra
      		{
      			color: #ffffff;
      			transition: all 0.5s;
      		}
        }
      }
		}
		hr {
			margin-top: 50px;
			border: 1px solid rgb(230, 230, 230);
		}
	}
}

.hamburger-menu {
	top: 0;
	display: none;
	height: 100%;
	width: 100%;
	position: fixed;
	overflow-y: scroll;
	padding: 20px;
	z-index: 9999;
	background: rgb(0, 0, 0);

	.gojek-logo {
		max-width: 70px;
		margin-top: 30px;
	}
	.button-download-wrapper {
		margin-top: 30px;
		.btn.btn-style-2 {
			padding: 12px;
			text-decoration: none;
			font-size: 12.5px;
			color: white;
			background: #fecb42;
			margin: 0 5px 0 5px;
			border: solid 1px #fecb42;
		}
		.btn.btn-style-2>span {
			margin: 10px 2.5px 2.5px 2.5px;
			position: relative;
			left: 5px;
			top: 5px;
		}
		.download-text {
			text-transform: uppercase;
			font-size: 16px;
			line-height: 2.11;
			letter-spacing: 2.6px;
			color: white;
			font-weight: 300;
			margin-left: 20px;
		}
	}
	#hamburger-btn-close {
		float: right;
		font-size: 30px;
		color: #009444;
		font-weight: 600;
		margin-top: 38px;
		font-family: "Open Sans", sans-serif;
	}
	hr {
		border: 1px solid #535353;
	}
	ul {
		list-style-type: none;
		margin-left: -40px;
		margin-top: 60px;
		margin-bottom: 60px;
	}
	ul li {
		margin-bottom: 22.5px;
		font-weight: 300;
		div.accordion {
			display: none;
			font-size: 16px;
			font-family: $lato;
			text-transform: uppercase;
			letter-spacing: 3px;
			line-height: 10px;
			color: white;
			span {
				display: block;
				margin-left: 20px;
				margin-top: 40px;
				margin-bottom: 30px;
				i {
					float: right;
				}
			}
			ul {
				display: none;
				margin-top: 30px;
				margin-left: 10px;
				li {
					margin-bottom: 30px;
					list-style-type: disc;
				}
			}
		}
	}
	ul li.active {
		font-weight: 700;
	}
	ul li a {
		font-size: 16px;
		font-family: $lato;
		text-transform: uppercase;
		letter-spacing: 3px;
		line-height: 10px;
		color: white;
		i {
			float: right;
		}
	}
	.social-media {
		text-align: center;
		margin-top: 100px;
		margin-bottom: 55px;
		ul {
			list-style-type: none;
			display: inline;
			li {
				display: inline;
				margin-left: 18px;
				margin-right: 18px;
				a {
					i {
						font-size: 34px;
						float: none !important;
					}
				}
			}
		}
	}
}

@media screen and (max-width: 768px) {
  .navbar {
     .menu {
       display: none;
     }

    #gojek-brand-mobile {
      display: block;
    }
    #gojek-brand-desktop {
      display: none;
    }

     .button-wrapper {
       display: none;
     }

     .hamburger {
       display: inline-block;
       position: relative;
       top: 5px;
     }
  }
}

@media screen and (max-width: 320px) {
	.hamburger-menu {
		.button-download-wrapper {
			.btn.btn-style-2 {
				padding: 8px;
				text-decoration: none;
				font-size: 12.5px;
				color: white;
				background: #fecb42;
				margin: 0 5px 0 5px;
				border: solid 1px #fecb42;
			}
			.download-text {
				text-transform: uppercase;
				letter-spacing: 2px;
				font-size: 12px;
				color: white;
				font-weight: 700;
				margin-left: 20px;
			}
		}

    .social-media {
      ul {
        li {
          a {
            i {
              font-size: 24px;
              float: none!important;
            }
          }
        }
      }
    }
	}
}

.navbar-active {
	background: rgba(255, 255, 255, 1.0);
	box-shadow: 0px 5px 50px rgba(40, 40, 40, .15);
}

.overflow-y-hidden {
	overflow-y: hidden !important;
}

/* parent condition */
.par-child-navbar
{
	margin-top: 125px !important;
	@media screen and (max-width: 768px) {
		margin-top: 60px !important;
	}
}


/* child navbar */
.child-navbar {
		background-color: #343b41;
		position: relative;
		top: 1px;
		z-index: -1;

		@media screen and (max-width: 48em) {
			display: none;
		}
		ul {
			background-color: #343b41;
			list-style: none;
			margin: 0;
			padding: 0;
			overflow: hidden;
			display: block !important;
			justify-content: center;
			li {
				a {
					display: block;
					color: white;
					text-align: center;
					padding: 16px;
					text-decoration: none;
					letter-spacing: 1px;
					top: 0;
					margin: 0;
				}
				a.career-tab {
					background: #8cc63f;
					padding: 16px 26px;
					margin: 0;
				}
			}
			li.active a
			{
				color: $gojek;
			}
			li.active a:after,
			li a:hover:after {
				content: "";
				width: 100%;
				position: absolute;
				bottom: 0;
				height: 4px;
				left: 0;
				background: #48af4a;
			}
		}
		.career-tab {
			background-color: #8cc63f;
			display: flex;
			flex-direction: column;
			justify-content: center;
			a {
				text-decoration: none;
				display: block;
				color: #ffffff;
			}
		}
}
.sub-menu {
	display: none;
	@media screen and (max-width: 48em) {
		display: block;
		/* extra sub menu */
		text-transform: uppercase;
		cursor: pointer;
		position: absolute;
		padding: 0 15px;
		height: 63px;
		margin-left: 17px;
		top: 0;
		color: white;
		font-size: 14px;
		background: #84bf41;
		.table-display {
			display: table;
			width: 100%;
			height: 100%;
			.mid-table {
				width: 100%;
				text-align: center;
				display: table-cell;
				vertical-align: middle;
				i#iconDownSubmenu {
					transition: all 0.5s;
					margin-left: 5px;
				}
				i#iconDownSubmenu.active {
					transition: all 0.5s;
					transform: rotate(180deg);
				}
			}
		}

	}
}
.container-sub-menu {
	@media screen and (min-width: 769px) {
		display: none;
	}
	display: block;
	z-index: 11;
	transition: all 0.5s;
	position: fixed;
	height: 100%;
	width: 100%;
	opacity: 0;
	top:0;
	pointer-events: none;
	background: rgba(0, 0, 0, 0.5);
	.content-sub-menu {
		width: 100%;
		background: #84bf41;
		transform: translateY(-100%);
		transition: all 0.5s;
	}
	.content-sub-menu.active {
		transition: all 0.5s 0.2s;
		transform: translateY(0%);
	}
	ul.list-content {
		margin: 0px;
		background: #84bf41;
		padding-left: 0px;
		padding-top: 60px;
		li {
			list-style-type: none;
			padding: 15px 20px;
			a {
				line-height: normal;
				font-size: 14px;
				color: white;
				letter-spacing: normal;
			}
		}
		li.active {
			background: #2c822e;
		}
	}
}
.container-sub-menu.active {
	opacity: 1;
	transition: all 0.5s;
	pointer-events: visible;
}
