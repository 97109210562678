body#karir {
	.table-display
	{
		display: table;
		height: 100%;
		.middle-table
		{
			display: table-cell;
			vertical-align: middle;
		}
		h4.title--content
		{
			@media screen and (max-width: 35.5em) {
				margin: 0px;
			    margin-top: 15px;
			    top: 0;
			    margin-left: 5px;
			    position: absolute;
			    font-size: 14px;
			}
		}
	}
	section.about
	{
		@media screen and (max-width: 35.5em) {
			padding-bottom: 0px;
		}
	}
	#join-now
	{
		@media screen and (max-width: 35.5em) {
			margin-bottom: 50px;
		}
	}
	.description
	{
		position: relative;
		@media screen and (max-width: 35.5em) {
			position: static;
		}
	}
	.container-desc-home-career
	{
		@media screen and (max-width: 35.5em) {
			margin-top: 50px;
		}
	}
	.hamburger-mobile
	{
		top: 23px;
	}
	/* Loader */
	@-webkit-keyframes uil-ring-anim {
		0% {
			-webkit-transform: rotate(0deg);
			transform: rotate(0deg);
		}
		100% {
			-webkit-transform: rotate(360deg);
			transform: rotate(360deg);
		}
	}
	@-moz-keyframes uil-ring-anim {
		0% {
			-webkit-transform: rotate(0deg);
			transform: rotate(0deg);
		}
		100% {
			-webkit-transform: rotate(360deg);
			transform: rotate(360deg);
		}
	}
	@-webkit-keyframes uil-ring-anim {
		0% {
			-webkit-transform: rotate(0deg);
			transform: rotate(0deg);
		}
		100% {
			-webkit-transform: rotate(360deg);
			transform: rotate(360deg);
		}
	}
	@-o-keyframes uil-ring-anim {
		0% {
			-webkit-transform: rotate(0deg);
			transform: rotate(0deg);
		}
		100% {
			-webkit-transform: rotate(360deg);
			transform: rotate(360deg);
		}
	}
	@keyframes uil-ring-anim {
		0% {
			-webkit-transform: rotate(0deg);
			transform: rotate(0deg);
		}
		100% {
			-webkit-transform: rotate(360deg);
			transform: rotate(360deg);
		}
	}
	.section-our-values {
		padding: 80px 0px;
		background: #f8f8f8;
		.title {
			color: inherit;
		}
		.list {
			h4 {
				height: 25px;
			}
			img {
				max-height: 100px;
				max-width: 250px;
				display: block;
			}
		}
		@media screen and (max-width: 35.5em) {
			padding-bottom:0px;
		}
	}
	.loader--animate {
		background: none;
		position: relative;
		width: 200px;
		height: 200px;
		margin: 0 auto;
		display: block;
		transform: scale(0.5);
	}
	.loader--animate>div {
		position: absolute;
		display: block;
		width: 160px;
		height: 160px;
		top: 20px;
		left: 20px;
		border-radius: 80px;
		box-shadow: 0 3px 0 0 rgb(72, 175, 74);
		-webkit-transform-origin: 80px 81.5px;
		transform-origin: 80px 81.5px;
		-webkit-animation: uil-ring-anim 1s linear infinite;
		animation: uil-ring-anim 1s linear infinite;
	}
	/* Blog Page */
	.load--more {
		display: none;
	}
	#landing-onboarding {
		margin: 20px 0;
		padding: 0;
		.load--more {
			margin: 0 auto 100px;
			width: 250px;
		}
	}
	#onboarding-head {
		margin-top: 125px;
		.bg-img {
			background-image: url('https://bilba.go-jek.com/dist/img/career/blog.jpg') !important;
			background-size: cover;
			box-shadow: inset 0px 0px 300px 200px rgba(72, 175, 74, 0.56);
		}
		@media screen and (max-width: 767px) {
			background-image: url('https://bilba.go-jek.com/dist/img/career/blog.jpg') !important;
			background-size: cover;
			box-shadow: inset 0px 0px 300px 200px rgba(72, 175, 74, 0.56);
		}
	}
	#onboarding-head .onboarding-nav,
	.blog-list .header-line .blog-nav {
		width: auto;
	}
	.blog-list .header-line {
		text-align: center;
		position: relative;
	}
	.blog-list .header-line .cat-list li {
		width: 270px;
	}
	.blog-list .header-line .blog-nav {
		@media screen and (min-width: 769px) {
			display: inline-block;
		}
	}
	header.career {
		margin-top: 120px;
	}
	header.sub {
		background: #2c822e;
		height: 83px;
		margin-top: 125px;
	}
	.pure-hidden-sm {
		text-transform: capitalize;
	}
	.search--wrapper {
		background: #fff;
		border: 2px solid rgb(72, 175, 74);
		border-radius: 5px;
		z-index: 10;
		position: relative;
	}
	.sub {
		.search--wrapper {
			margin-top: -35px;
		}
	}
	#submit--button {
		background: #fff;
		border-top-right-radius: 5px;
		border-bottom-right-radius: 5px;
	}
	.search {
		input,
		select,
		.select2-selection,
		.select2-container {
			height: 100%;
			width: 100% !important;
			border-radius: 0;
			outline: none;
			border: 0;
		}
		.select2-container--default .select2-selection--single .select2-selection__rendered,
		#job-search {
			font-family: 'Open Sans', sans-serif;
			font-size: 20px;
			padding: 0 20px;

		}
		#city-search,
		#job-search {
			border-left: 2px solid rgb(72, 175, 74);
			height: 100%;
			display: none;
		}
		.select2-selection {
			display: flex;
			flex-direction: column;
			justify-content: center;
			border-top-left-radius: 5px;
			border-bottom-left-radius: 5px;
		}
		.select2-selection__arrow {
			display: none;
		}
		.select2-selection:after {
			content: "\f078";
			top: 0;
			margin-right: 1em;
			justify-content: center;
			display: flex;
			flex-direction: column;
			text-align: center;
			color: #48af4a;
			font-family: FontAwesome;
			font-style: normal;
			font-weight: normal;
			text-decoration: inherit;
			font-size: 24px;
			height: 100%;
			position: absolute;
			right: 0;
			z-index: 1;
		}
		.pure-u-12-24 {
			border-left: solid 1.5px #48af4a;
		}
		input::-webkit-input-placeholder {
			color: #000000;
		}
		select {
			color: #000000;
			-webkit-appearance: none;
			-moz-appearance: none;
			text-indent: 1px;
			text-overflow: '';
		}
		.job-select {
			position: relative;
		}
		button {
			background-color: #48af4a;
			color: #ffffff;
			width: 100%;
			padding: 20px 0;
			border-top-right-radius: 5px;
			border-bottom-right-radius: 5px;
			margin: 5px;
		}
	}
	#department {
		.bg-img {
			background-image: url('https://bilba.go-jek.com/dist/img/career/departement.jpg');
			background-size: cover;
			box-shadow: inset 0px 0px 300px 200px rgba(72, 175, 74, 0.56);
		}
		header#faq-head {
			@media screen and (max-width: 767px) {
				background-image: url('https://bilba.go-jek.com/dist/img/career/departement.jpg');
				background-size: cover;
				box-shadow: inset 0px 0px 300px 200px rgba(72, 175, 74, 0.56);
			}
		}
	}
	.how-it-works--wrapper .container {
		padding: 60px 0 0;
	}
	section.job-list {
		.title {
			text-align: center;
			font-family: $opensans;
			font-weight: bold;
			letter-spacing: 2px;
			color: #48af4a;
			h2 {
				margin: 45px 0;
				@media screen and (max-width: 35.5em) {
					margin-top: 0px;
				}
			}
		}
		table {
			margin: 4em auto 8em;
			width: 100%;
			border: none;
			thead {
				background: none;
				th {
					border: none;
					color: #485460;
					font-weight: bold;
					letter-spacing: 1px;
					padding-bottom: 1.5em;
				}
			}
			tbody {
				tr:nth-child(odd) {
					background: #ffffff;
				}
				tr:nth-child(even) {
					background: #f8f8f8;
				}
				td {
					border: none;
					padding: 0 1em;
					p {
						letter-spacing: normal;
						font-size: 18px;
					}
					a {
						color: #48af4a;
						letter-spacing: normal;
						font-size: 18px;
					}
				}
			}
		}
	}
	.career-home {
		header {
			a {
				padding: 2.5em 3.5em;
				background: #8cc63f;
				letter-spacing: 3px;
				color: #ffffff;
				display: inline-block;
			}
		}
		.how-it-works--wrapper {
			margin: 0;
			.container .how--title {
				h2:after {
					display: none;
				}
			}
		}
		#join-now .right {
			p:first-child {
				display: none;
			}
			h2,
			p {
				color: #ffffff;
				font-weight: 300;
			}
			a {
				bottom: -3.5em;
				right: 3.5em;
				padding: 2em 5em;
				letter-spacing: 3px;
				font-family: $opensans;
			}
		}
		@media screen and (max-width: 35.5em) {
			.pure-g {
				border-width: 1.2px;
			}
			.title {
				font-size: 14px;
				margin: 0 0 20px 0;
			}
			.line {
				margin-bottom: 20px;
			}
			#join-now .right a {
				padding: 25px;
			}
		}
	}
	#landing-faq .container {
		@media screen and (max-width: 35.5em) {
			margin-top: 25px;
			.box {
				width: 120%;
			}
		}
		.super-bottom {
			margin-top: 3em;
		}
		.out-warp {
			.top {
				background: #8cc63f;
			}
			.bottom {
				background: #8cc63f;
			}
			.warper {
				background: #48af4a;
				.box {
					h3 {
						font-family: $lato;
						font-weight: 300;
						color: #000000;
						margin-bottom: 15px;
						margin-top: 0px;
					}
					p {
						font-family: $opensans;
						color: #000000;
					}
				}
			}
			.bottom .box,
			.top .box {
				padding-top: 3em;
			}
		}
	}
	#detail-list {
		.bg-img {
			background-image: url('https://bilba.go-jek.com/dist/img/career/category.jpg');
		}
		header {
			a {
				color: #48af4a;
				font-weight: 500;
				text-decoration: none;
			}
			@media screen and (max-width: 35.5em) {
				a {
					color: #ffffff;
				}
			}
			@media screen and (max-width: 767px) {
				background-image: url('https://bilba.go-jek.com/dist/img/career/category.jpg');
				background-size: cover;
				box-shadow: inset 0px 0px 300px 200px rgba(72, 175, 74, 0.56);
			}
		}
	}
	#detail {
		margin-top: 10em;
		.job-detail {
			margin: 3em auto;
			.job-desc {
				margin-right: 32px;
				border-top: 8px solid #48af4a;
				background: #ffffff;
				padding: 50px 30px;
				.breadcrumb,
				a {
					font-weight: bold;
					letter-spacing: 1px;
					color: #b3b9c7;
					text-decoration: none;
					text-transform: uppercase;
					font-size: 14px;
				}
				h2 {
					letter-spacing: 2px;
					color: #343b41;
					font-family: $lato;
					margin-bottom: 2em;
					font-weight: 300;
					letter-spacing: 2px;
				}
				h4 {
					letter-spacing: 1px;
					color: #485460;
					font-weight: bold;
					font-family: $opensans;
				}
				p {
					color: #000000;
					font-family: $opensans;
					font-size: 1em;
					font-weight: 500;
				}
				ul {
					padding: 0 20px;
					li {
						line-height: 1.61;
						font-family: $opensans;
						color: #000000;
					}
				}
			}
			.aside-panel {
				.apply-panel {
					padding: 3em 1.5em;
					margin-bottom: 1.5em;
					background: #ffffff;
					ul {
						padding: 0;
						margin: 0;
						li:first-child {
							margin-top: 0 !important;
						}
						li {
							list-style: none;
							margin: 2em 0;
							.fa {
								color: #48af4a;
								width: 25px;
							}
							span {
								font-size: 14px;
							}
						}
					}
					a {
						padding: 30px;
						border-radius: 6px;
						background-color: #48af4a;
						display: block;
						text-decoration: none;
						color: #ffffff;
						text-align: center;
						width: 100%;
					}
					p {
						color: #000000;
						font-family: $opensans;
						font-weight: 500;
						img {
							height: 1em;
						}
					}
				}
				.related-job-panel {
					background: #ffffff;
					color: #48af4a;
					padding: 3em 1.5em;
					h4 {
						font-weight: bold;
						position: relative;
					}
					h4:after {
						content: "";
						height: 4px;
						background-image: radial-gradient(circle at 0 50%, #8cc63f, #48af4a);
						position: absolute;
						width: 50px;
						left: 0;
						margin-top: 2em;
					}
					ul {
						padding: 0;
						margin: 40px 0px;
						li {
							list-style: none;
							margin: 12px 0;
							a {
								text-decoration: none;
								color: #48af4a;
								letter-spacing: 2px;
							}
						}
					}
				}
			}
		}
		@media screen and (max-width: 48em) {
			margin-top: 5em;
			.job-detail .job-desc {
				margin: 0;
				.breadcrumb,
				.breadcrumb a {
					font-size: 11px;
				}
				h4 {
					font-size: 1em;
				}
				ul li {
					line-height: 1.38;
				}
			}
			.aside-panel {
				margin: 1.5em 0;
				a {
					width: inherit !important;
					font-weight: bold;
					letter-spacing: 3px;
				}
			}
		}
	}
	.enginering {
		header {
			.bg-img {
				background-image: url('https://bilba.go-jek.com/dist/img/career/engineering_header.jpg') !important;
				background-size: cover;
				box-shadow: inset 0px 0px 300px 200px rgba(72, 175, 74, 0.56);
			}
		}
		h2.title-eng {
			margin-bottom: 5px;
			@media screen and (max-width: 35.5em) {
				font-size:24px;
			}
		}
		h4.sub-title {
			font-weight: normal;
			margin-top: 0px;
			@media screen and (max-width: 35.5em) {
				font-size: 16px;
			}
		}
		.how-it-works--wrapper {
			margin: 0;
			.container .how--title {
				h2:after {
					display: none;
				}
			}
			.link {
				line-height: .8;
				letter-spacing: 3.3px;
				color: #48af4a;
				font-weight: 700;
			}
		}
		#join-now .right {
			p:first-child {
				display: none;
			}
			h2,
			p {
				color: #ffffff;
				font-weight: 300;
			}
			a {
				bottom: -3.5em;
				right: 3.5em;
				padding: 2em 5em;
				letter-spacing: 3px;
				font-family: $opensans;
			}
		}
		section.about {
			background: #f8f8f8;
			@media screen and (max-width: 768px) {
				padding-bottom: 0px;
				.container
				{
					h4
					{
						margin-top: 10px;
						margin-bottom: 20px;
						font-size: 14px;
					}
				}
				.img-wrapper
				{
					.img-container
					{
						margin-bottom: 30px;
					}
				}
			}
		}
		section.engineering {
			margin-bottom: 17em;
			margin-top: 1em;
			padding: 0;
			background: #f8f8f8;
			@media screen and (max-width: 35.5em) {
				margin-bottom: 14em;
			}
			h2.title {
				text-transform: uppercase;
				margin:35px 0;
				text-align: center;
				color: #48af4a;
				@media screen and (max-width: 35.5em) {
					margin: 20px 0;
				}
			}
			.container {
				position: relative;
			}
			.wrapper {
				width: 100%;
				margin: 0;
				padding: 0px 15px;
				background-color: #fff;
				position: relative;
				img.service-logo {
					position: absolute;
					top: 25px;
					right: 25px;
					width: 100px;
				}
				.service-container {
					height: 9em;
					background: #48af4a;
					margin: 0 -15px;
					margin-right: -20%;
					.service-list {
						background-color: #fff;
						padding: 0 25px 25px;
						box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.11);
						margin: 50px 15px;
						width: 300px;
						&.slick-slide:focus {
							outline: none;
						}
						p {
							padding: 0;
							height: 120px;
							margin-bottom: 25px;
						}
						.img-container {
							position: relative;
							width: auto;
							right: auto;
							top: auto;
							background-color: #FEFEFE;
							margin-top: -25px;
							img {
								position: absolute;
								top: 0;
								bottom: 0;
								left: 0;
								right: 0;
								margin: auto;
							}
						}
						.bg-services {
							background-position: center center;
							background-size: cover;
							height: 8em;
							opacity: .16;
						}
						a {
							color: $gojek;
							position: relative;
							text-transform: uppercase;
						}
					}
					.slick-list,
					.slick-track {
						margin-left: -25px !important;
					}
				}
				.navigation-arrow {
					background: white;
					margin: 0;
					.left,
					.right {
						cursor: pointer;
						display: inline-block;
						width: 42px;
						height: 42px;
						svg {
							width: 100%;
						}
						:focus {
							background: none;
							outline: 0;
						}
					}
					.right {
						float: right;
					}
					.slick-disabled {
						ellipse {
							stroke: #D2D2D2 !important;
						}
						polygon {
							fill: #D2D2D2 !important;
						}
					}
				}
			}
			h4 {
				font-weight: bold;
				color: #000000;
				padding: 0;
				margin: 30px 0 10px;
				height: 50px;
			}
			p {
				font-weight: 500;
				color: #485460;
				padding-bottom: 3em;
				margin-top: 0;
			}
			a {
				font-weight: bold;
				font-style: normal;
				font-stretch: normal;
				line-height: normal;
				letter-spacing: normal;
				color: #48af4a;
			}
		}
		@media screen and (max-width: 35.5em) {
			header {
				background-image: url(https://bilba.go-jek.com/dist/img/career/engineering_header.jpg) !important;
				background-size: cover;
				box-shadow: inset 0px 0px 300px 200px rgba(72, 175, 74, 0.56);
			}
			.pure-g {
				border-width: 1.2px;
			}
			.title {
				font-size: 14px;
				margin-bottom: 0px;
			}
			#join-now .right a {
				padding: 25px;
			}
			.navigation-arrow {
				display: none;
			}
			.wrapper .service-container .service-list {
				width: 200px !important;
			}
			h4 {
				line-height: 1.25;
			}
		}
	}
	.search-page {
		.job--result {
			display: none;
		}
		.loading {
			text-align: center;
		}
		.job-list {
			margin-bottom: 50px;
			.not-found {
				margin-top: 30px;
				img {
					display: block;
					margin: 0 auto;
				}
				.text {
					margin-left: 150px;
					margin-right: 150px;
					text-align: center;
					border-radius: 8px;
					background-color: #ffffff;
					padding: 15px;
					margin-bottom: 50px;
					letter-spacing: 2px;
					color: #343b41;
				}
				h5 {
					font-weight: 500;
				}
			}
			h2 {
				line-height: normal;
				letter-spacing: 2px;
				color: #343b41;
				font-family: $lato;
				span {
					font-weight: bold;
					color: #2c822e;
				}
			}
			table {
				width: 100%;
				border: none;
				thead {
					background: none;
					th {
						border: none;
						color: #485460;
						font-weight: bold;
						letter-spacing: 1px;
						padding-bottom: 1.5em;
					}
				}
				tbody {
					tr:nth-child(odd) {
						background: #ffffff;
					}
					tr:nth-child(even) {
						background: #f8f8f8;
					}
					td {
						border: none;
						padding: 0 1em;
						a {
							color: #48af4a;
						}
					}
				}
			}
			@media screen and (max-width: 35.5em) {
				.not-found {
					.text {
						margin-left: 0px;
						margin-right: 0px;
						margin-bottom: 10px;
					}
					img {
						max-width: 200px;
					}
				}
			}
		}
	}
	/* submenu */
	#main--hero {
		margin-top: 125px;
	}
	#faq-head {
		margin-top: 125px;
	}
	@media screen and (min-width: 48em) {
		section.engineering {
			padding-bottom: 12em;
		}
		section.engineering .img-wrapper .img-container {
			margin-top: 40%;
		}
		section.engineering .img-wrapper {
			display: block;
		}
		section.engineering a:after {
			top: 6px;
			width: 30px;
			height: 30px;
			background-size: 20px;
		}
		section.engineering h4 {
			// padding: 0;
		}
		section.engineering .container:before {
			padding: 0;
		}
		section.engineering .wrapper {
			width: 100%;
		}
		section.engineering .wrapper img.service-logo {
			top: 2.5em;
			right: 0;
			left: 0;
			margin-left: auto;
			margin-right: auto;
		}
		section.engineering#golife .wrapper .navigation-arrow,
		section.engineering .wrapper .navigation-arrow {
			padding: 0;
			right: 2.5em;
			top: 2.5em;
			position: absolute;
			.left,
			.right {
				float: none
			}
			.left {
				padding-right: 5px;
			}
			.right {
				padding-left: 5px;
			}
		}
	}
	@media screen and (min-width: 64em) {
		section.services#golife {
			padding-bottom: 17em;
		}
		section.services .wrapper .service-container .service-list {
			width: 17em;
		}
	}
	@media screen and (min-width: 1440px) {
		section.services .wrapper .service-container {
			margin-right: -50%;
		}
	}
	/* Media Global */
	@media screen and (max-width: 35.5em) {
		header.sub {
			margin-top: 80px;
		}
		.pure-hidden-sm {
			display: none;
		}
		.search {
			padding: 10px 0;
			.select2-selection:after {
				font-size: 8px;
			}
			.select2-selection__rendered {
				padding: 8px;
				font-size: 12px !important;
			}
			#city-search {
				padding: 0 !important;
			}
			#job-search,
			#city-search {
				font-size: 12px;
				padding: 0 5px;
				border-width: 1.2px;
			}
			button {
				padding: 38.5px 0;
				font-size: 8px;
			}
			.select2-container {
				padding: 0;
			}
			.select2-selection {
				padding: 0;
			}
		}
	}
	@media screen and (max-width: 48em) {
		#onboarding-head {
			margin-top: 60px;
		}
		#faq-head {
			margin-top: 60px;
		}
		#main--hero {
			margin-top: 60px;
		}
		.sub-menu {
			display: block;
		}
		.navbar {
			min-height: unset;
			height: 60px;
			.brand {
				img {
					width: 100% !important;
					max-width: 30px;
				}
			}
			.wrapper {
				padding: 12px 0;
				height: auto;
			}
			.hamburger {
				top: 13px;
			}
		}
		.career-home header .hero--title {
			font-size: 35px !important;
		}
		.navbar.navbar-active.no--active {
			top: 0px;
		}
	}
	@media screen and (max-width: 767px) {
		#join-now {
			position: relative;
			.float-image {
				overflow: hidden;
				z-index: 1;
				position: absolute;
				height: 78%;
				width: 85%;
				left: 0;
				right: 0;
				margin: auto;
				top: 75px;
				background-color: #9a9a9a;
				img {
					width: 260%;
					left: -125%;
					position: relative;
					height: auto;
					filter: grayscale(100%);
				}
			}
			.bg-layer {
				z-index: 2;
				padding: 0 20px;
			}
			.right {
				background: rgb(72, 175, 74);
				a {
					left: 0;
					right: 0 !important;
					text-align: center;
					padding: 20px 0 !important;
					font-size: 12px;
					width: 150px;
					margin: auto;
					bottom: -75px !important;
				}
			}
		}
		#submit--button {
			button.pull-right {
				right: 5px;
				padding: 0px;
				margin: auto;
				top: 0;
				width: 80px;
				position: absolute;
				margin: auto;
				height: 90%;
				bottom: 0;
			}
		}
		.sub {
			.search--wrapper {
				width: 95%;
				top: -20px;
			}
		}
	}
	@media screen and (min-width: 769px) {
		@keyframes slidedown {
			from {
				opacity: 0;
			}
			to {
				opacity: 1;
			}
		}
		.select2-dropdown {
			animation: slidedown 0.7s forwards;
		}
		.select2-container--open .select2-selection::after {
			transition: all 0.5s;
			transform: rotate(180deg);
		}
		#submit--button:hover {
			i.fa-arrow-right {
				transition: all 0.3s;
				transform: translateX(10px);
			}
		}
	}
	/* Blog Page */
	.blog-list {
		padding-top: 125px;
		.cat-list li {
			padding: 10px 15px;
		}
		.author {
			background: #fff;
			margin-bottom: 15px;
			padding: 16px;
			position: relative;
			padding-top: 40%;
			margin-top: 30%;
			img {
				display: block;
				margin: 0 auto;
				width: 100%;
				position: absolute;
				top: -60%;
				padding: 50px;
				left: 0;
			}
			ul {
				list-style: none;
				padding: 0;
				li {
					padding-left: 20px;
					margin: 10px auto;
					i {
						color: #48af4a;
						width: 20px;
					}
				}
			}
		}
		.pre--title {
			padding-left: 0;
		}
		.post-item {
			border-color: #8cc63f;
		}
		@media screen and (max-width: 768px) {
			padding-top: 125px;
		}
	}
}
