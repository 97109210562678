#gobox {
	#content-only.thank-you {
		margin-top: 130px;

		.container {
			box-shadow: 0 -3em 0 1.5em #343b41;

			.info {
				h4 {
					color: $gobox;
				}

				.line {
					background: $gobox;
				}
			}
		}

		a.contact
		{
			width:300px;
			background: $gobox !important;
		}
		@media screen and (max-width: 768px) {
			margin-bottom: 130px;
			a.contact
			{
				width:100%;
				line-height: normal;
			}
		}
	}
	.join[data-join=business] {
		section {
			&.full-slider {
				background: #fff100;
				padding: 40px;

				.text {
					h4 {
						text-align: center;
					}

					span {
						width: 50px;
						height: 4px;
						background: #4a4a4a;
						display: block;
						margin: 0 auto;
					}
				}
			}
		}
		header {
				overflow: visible;
				.desc--wrapper {
					.desc--row {
						.hero--desc {
							margin-bottom: 30px;
						}
						a.hero--button {
							display: inline-block;
							bottom: 0px;
							position: relative;
							margin-top: 10px;
							color: #343b41;
							background-color: #f2db04!important;
							text-transform: uppercase;
							border-radius: 5px;
							padding:30px 40px;
							-webkit-transition: all .3s ease-in-out;
							-moz-transition: all .3s ease-in-out;
							-o-transition: all .3s ease-in-out;
							transition: all .3s ease-in-out;
							svg {
								width: 12px;
								position: absolute;
								right: 20px;
								top: 50%;
								-webkit-transition: all .3s ease-in-out;
								-moz-transition: all .3s ease-in-out;
								-o-transition: all .3s ease-in-out;
								transition: all .3s ease-in-out;
								-webkit-transform: translateY(-50%);
								-moz-transform: translateY(-50%);
								-o-transform: translateY(-50%);
								transform: translateY(-50%);
							}
						}
						a.hero--button:hover {
							box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.19);
							color: #343b41!important;
							background: #fff !important;
							svg {
								g {
									fill: #f2db04!important;
								}
							}
						}
					}
				}
			}

		.new-our-partner .container-slide .title:before {
			background-color: #fff100;
		}

		section#new-join {
			margin-bottom: 100px;

			button.submit {
				padding: 30px 0;
				width: 200px;
				margin-top: 50px;
				background: #fff100;
				float: right;
				color: #4a4a4a;
				border-radius: 5px;
				margin-bottom: -90px;

				@media screen and (max-width: 767px) {
					margin-right: auto;
					margin-left: auto;
					display: block;
					float: none;
				}
			}
		}
	}
	section.section-our-values {
		&.new
		{
			padding-top: 0px;
			img {
				max-height: 150px;
				width: auto;
			}
			h4.title
			{
				color: $gobox;
				@media screen and (max-width: 767px)
				{
						&:after
						{
							top: 30px;
						    position: absolute;
						    width: 15px;
						    left: -20px;
						    background-color: #4285a7;
						    height: 2px;
						    content: "";
						}
				}
			}
			.list
			{
				svg
				{
					height: 140px;
					@media screen and (max-width: 767px)
					{
						height: 130px;
					}
					&.adjust
					{
						position: relative;
						left: -20px;
					}
				}
				h4
				{
					@media screen and (min-width: 768px)
					{
						margin-top: 50px;
						margin-bottom: 40px;
					}
					color: #4a4a4a;
					@media screen and (max-width: 767px)
					{
						padding: 0px;
					    margin-bottom: 0px;
					    margin-top: 20px;
					}
				}
				p
				{
					font-family: $opensans;
					@media screen and (min-width: 768px)
					{
						margin-top:10px;
					}
					@media screen and (max-width: 767px)
					{
						max-width: 300px;
					}
				}
			}
			.line
			{
				background: $gobox;
				@media screen and (max-width: 767px)
				{
					display: none;
				}
			}
			h4.title
			{
				@media screen and (max-width: 767px)
				{
					font-size: 14px;
					text-align: left;
				}
			}
			#type-gobills
			{
				width: 100%;
				.arrow-left
				{
					position: absolute;
					left: 0;
					bottom: -40px;
					width:50px;
				}
				.arrow-right
				{
					position: absolute;
					right: 0;
					bottom: -40px;
					width:50px;
				}
				.slick-arrow.slick-disabled
				{
					filter: grayscale(100) brightness(1.5);
				}
				@media screen and (min-width: 768px)
				{
					margin-top:40px;
				}
			}
			@media screen and (max-width: 767px)
			{
				padding:20px;
				padding-bottom:100px;
				margin-top:40px;
				.container-slider
				{
					width:100%;
					padding:0px;
					position:relative;
					display:inline-block;
				}
			}
		}
	}

	section.new-our-partner
	{
		background-color: #fafafa;
		text-align: center;
		margin-bottom: 100px;
		.container-slide
		{
			display: inline-block;
			max-width: 1170px;
			position: relative;
			height: 460px;
			width:90%;
			.title
			{

				z-index: 1;
				position: absolute;
				width: 50%;
				padding-left: 20px;
				font-size: 18px;
				color:$gobox;
				right:0;
				text-align: left;
				font-weight: 700;
				height:100px;
				letter-spacing: 1px;
				&:before
				{
					@media screen and (min-width: 769px) {
						position: absolute;
					    width: 35px;
					    left: -25px;
					    height: 3px;
					    background: #49af4b;
					    top: -5px;
					    bottom: 0;
					    content: "";
					    margin: auto;
					}
				}
				&:after
				{
					content: "";
				    display: inline-block;
				    vertical-align: middle;
				    height: 100%;
				    @media screen and (max-width: 768px) {
						height:3px;
						width:50px;
						margin:20px auto;
						background: #49af4b;
						display:block;
						content:"";
					}
				}
				@media screen and (max-width: 768px) {
					margin-top:40px;
					position:relative;
					height:auto;
					padding-left:0px;
					width:100%;
					text-align:center;
					font-size:14px;
				}
			}
			.hero
			{
				float: left;
				height: 460px;
				width:50%;
				background: #ebebeb;
				.image-hero
				{
					height: 460px;
					width: 100%;
					background-size: cover;
					background-position: center;
				}
				@media screen and (max-width: 768px) {
					width:100%;
				}
			}
			.comment
			{
				position: absolute;
				height: calc(100% - 100px);
				background: #fff;
				right: 0;
				bottom:0;
				width: 50%;
				.content
				{
					text-align: left;
					position: absolute;
				    height: 80%;
				    width: 70%;
				    top: 0;
				    bottom: 0;
				    left: 7%;
				    font-size: 14px;
				    margin: auto;
				    @media screen and (max-width: 768px) {
				    	width: 100%;
				    	position: relative;
				    	left: auto;
				    	padding: 30px;
				    }
				}
				.container-comment
				{
					display: table;
					width: 100%;
					height: 300px;
					left: 0;
					font-size: 16px;
					line-height: 28px;
					color: #9b9b9b;
					font-family: $opensans;
					font-weight: 100;
					.user
					{
						color: #2b2b2b;
						margin-top: 10px;
						span
						{
							font-weight: 700;
						}
					}
					> div
					{
						display: table-cell;
						vertical-align: middle;
					}
					@media screen and (max-width: 768px) {
						text-align:center;
					}
				}
				@media screen and (max-width: 768px) {
					position:relative;
					width:100%;
					float:left;
					height:500px;
				}
			}
			.float-arrow
			{
				height: 45px;
			    width: 45px;
			    position: absolute;
			    right: -90px;
			    bottom: 0;
			    top: 0;
			    margin: auto;
			    &.arrow-left
			    {
			    	transform: rotate(90deg);
			    	top: -55px;
			    	&.slick-disabled
			    	{
			    		filter: grayscale(100) brightness(1.4);
			    	}
			    	@media screen and (max-width: 768px)
					{
						top:auto;
						bottom:-50px;
						left:20px;
						right:auto;
						transform:none;
					}
			    }
			    &.arrow-right
			    {
			    	transform: rotate(90deg);
			    	top:55px;
			    	&.slick-disabled
			    	{
			    		filter: grayscale(100) brightness(1.4);
			    	}
			    	@media screen and (max-width: 768px)
					{
						right:20px;
						top:auto;
						bottom:-50px;
						left:auto;
						transform:none;
					}
			    }
			    @media screen and (max-width: 768px)
				{
					right:0;
					left:0;
				}
			}
			@media screen and (max-width: 768px) {
				height:auto;
				width:100%;
			}
		}
		@media screen and (max-width: 768px) {
			background-color:#fff;
			margin-bottom:30px;
		}

		.slick-dots
		{
			left:0;
			> li
			{
				margin:0px;
				margin-right: 5px;
				filter: grayscale(100) brightness(1.4);
				&.slick-active
				{
					filter: grayscale(0);
				}
				button
				{
					width:10px;
					height: 10px;
					background: $gobox;
					border-radius: 50%;
					&:before
					{
						display:none;
					}
				}
				&:last-child
				{
					margin-right: 0px;
				}
			}
		}
	}

	section.fare-estimate {
		.calculator {
			height: 360px;
			background: white;
			box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.11);
			padding: 50px 50px 60px;
			margin: 50px 0;
			position: relative;
			.wrapper-form {
				label[for="box"]:before {
			    background-image: url('https://bilba.go-jek.com/dist/img/page/gobox/favicon.png');
			    background-size: contain;
			    background-repeat: no-repeat;
			    display: inline-block;
			    width: 25px;
			    height: 25px;
			    content: "";
			    position: absolute;
			    margin-top: 14px;
			    margin-left: 8px;
				}
				.wrapper-pick-up {
					color: #ffcd2e;
					.pac-controls {
						display: inline-block;
						padding: 5px 11px;
					}
					#pick-up:focus {
						border-color: #4d90fe;
					}
					#title {
						color: #fff;
						background-color: #4d90fe;
						font-size: 25px;
						font-weight: 500;
						padding: 6px 12px;
					}
					#target {
						width: 345px;
					}
				}
				.wrapper-drop-off {
					color: #935e33;
				}
				.wrapper-pick-up,
				.wrapper-drop-off {
					position: relative;
					.fa-circle:before {
						font-size: 11px;
					}
					label {
						position: absolute;
						top: 17px;
						left: 15px;
					}
				}
				h4 {
					position: relative;
					margin: 0;
				}
				h4:before {
					content: "";
					height: 4px;
					width: 50px;
					position: absolute;
					background: #f2db01;
					left: -15%;
					top: 42%;
				}
				input,
				select {
					display: block;
					width: 100%;
					margin: 15px 0;
					border: solid 1px #e8ebf2;
					border-radius: 0 !important;
					line-height: 0.8;
					letter-spacing: 1px;
					color: #485460;
					font-family: $opensans;
				}
				select {
					background: white;
					height: 50px;
					padding: 10px 40px 10px 40px;
					-webkit-appearance: none;
					-ms-appearance: none;
					-moz-appearance: none;
					border: 1px solid #e8ebf2;
					background-color: transparent;
					position: relative;
					cursor: pointer;
					z-index: 10;
				}
				input {
					padding: 15px 20px 15px 40px;
				}
				#submit[disabled] {
			    background-color: #b7b7b7 !important;
    			cursor: no-drop;
				}
				button {
					border-radius: 5px;
					background-color: #f2db01;
					padding: 20px 60px;
					color: white;
					position: absolute;
					right: 50px;
					bottom: -30px;
				}
				button:focus {
					outline: none;
				}
			}
		}
		.wrapper-map {
			margin: 50px 0;
			box-shadow: 0 2px 8px 0 rgba(0, 0, 0, .11);
			position: relative;
			#map {
				// height: 100%;
				height: 360px;
			}
			.result {
				.title {
					background: #f2db01;
					padding: 30px 45px;
					h4 {
						margin: 0;
						text-transform: uppercase;
						line-height: 0.8;
						letter-spacing: 3.3px;
						color: #485460;
						font-family: $opensans;
					}
				}
				ol {
					list-style: none;
				}
				.list {
					list-style: none;
					margin: 0;
					padding: 25px 45px 25px;
					li.wrapper:first-child {
						border: none;
					}
					li.wrapper {
						padding-top: 10px;
						border-top: solid 1px #e8ebf2;
					}
					.item {
						position: relative;
						padding: 0;
						margin-bottom: 10px;
						li:first-child {
							line-height: 0.8;
							letter-spacing: 1px;
							color: #485460;
							font-weight: bold;
							margin-bottom: 10px;
						}
						li {
							margin-bottom: 5px;
							font-family: $opensans;
							text-transform: uppercase;
						}
						.price {
							position: absolute;
							top: 0;
							right: 0;
							line-height: 0.8;
							letter-spacing: 1px;
							color: #485460;
							font-weight: bold;
						}
					}
				}
			}
		}
		.hidden {
			display: none;
		}
		.result {}
	}
	#promo-banner .detail .custom-nav .slick-disabled {
		ellipse {
			stroke: #D2D2D2 !important;
		}
		polygon {
			fill: #D2D2D2;
		}
	}
	#join-now {
		padding-top: 0;
		.right {
			p:first-child {
				text-transform: uppercase;
			}
			a {
				background: #fce500;
				color: #353332;
				font-weight: bold;
			}
		}
	}
	.section-our-values {
		.list {
			img {
				width: 250px;
				height: 250px;
			}
		}
		.title {
			color: #353332;
		}
		.line.gradient {
			background: #fce500;
		}
	}
	.section-how-to-use {
		h5 {
			color: #353332;
		}
		.line.gradient {
			background: #FCE500;
		}
		.arrow-up {
			stroke: $gobox;
		}
		.arrow-up-circle {
			stroke-width: 3;
			stroke: $gobox;
		}
		.arrow-down {
			stroke: $gobox;
		}
		.arrow-down-circle {
			stroke: $gobox;
			stroke-width: 3;
		}
		.slider .slick-dots li.slick-active button:before {
			color: $gobox;
		}
		.slider-image .slick-dots li.slick-active button:before {
			color: $gobox;
		}
	}
	.new-section-how-to-use
	{
			margin-top: 180px;
			margin-bottom:100px;
			h3.title
			{
				color: #000;
				&:after
				{
					background: #f2db01;
				}
			}
			.content
			{
				color: #000;
				background-color: #f2db01;
			}
			@media screen and (max-width: 767px) {
				margin-top:50px !important;
				margin-bottom:50px;
			}
	}
	@media screen and (max-width: 480px) {
		header#main--hero {
			// background-image: url(https://d3naj63yelf4gk.cloudfront.net/dist/img/header/hero/go-box--mobile.jpg);
		}
	}
	.as-seen-on {
		.city {
			background: url(https://d3naj63yelf4gk.cloudfront.net/dist/img/page/gobox/kota_gobox.jpg);
			.gobox-city--title {
				font-size: 45px;
				color: $gobox-yellow;
				line-height: 50px;
			}
			.gobox-city--list {
				text-align: center;
				color: #fff;
				font-size: 18px;
				margin: 0;
				padding: 0 40px 50px;
				font-weight: 300;
			}
		}
		p {
			font-weight: 700;
		}
		#right {
			background: $gobox;
		}
		#left {
			background-color: $gobox-yellow;
		}
		#content {
			color: #353332;
		}
		hr {
			background: $gobox-yellow;
		}
	}
	.how-it-works--wrapper {
		margin:30px;
		margin-bottom: 80px;
		.container
		{
			padding: 0px;
		}
		.section--title {
			color: #353332;
			&:before {
				background-color: $gobox-yellow;
			}
		}
		.layer {
			.rect--primary {
				background: $gobox-yellow;
			}
			.rect--secondary {
				background: $gobox;
			}
		}
		.btn {
			background-color: #252525 !important;
			color: $gobox-yellow !important;
			font-size: 12px;
			padding: 20px 50px;
			margin: 20px 0;
		}
		@media screen and (max-width: 768px) {
			margin:0px;
		}
	}
	.articles--feed {
		.bg,
		.read--more {
			background: $gobox;
		}
		.blog-card-title a:hover {
			color: $gobox;
		}
		.line--title {
			background: $gobox-yellow;
		}
	}
}

@media screen and (max-width: 48em) {
	#gobox {
		.section-how-to-use {
			.arrow--wrapper {
				top: 22%;
			}
			.phone-wrapper {
				top: 32%;
			}
			ul.slick-dots {
				margin-top: -3.5em;
			}
		}
		section.fare-estimate {
			.calculator {
				padding: 15px;
		    margin: 30px 0;
				.wrapper-form {
					h4:before {
						left: -55px;
						top: 0px;
					}
					input,
					button,
					select {
						font-size: 12px;
					}
					select {
						padding: 15px 25px;
					}
					.wrapper-pick-up,
					.wrapper-drop-off {
						.fa-circle:before {
							font-size: 8px;
						}
						label {
							top: 13px;
							left: 10px;
						}
					}
					button {
						margin: auto;
						display: block;
						position: relative;
						font-size: 12px;
						padding: 10px 35px;
						left: 0;
					}
				}
			}
			.wrapper-map {
				margin: 5px 0;
			}
		}
		.as-seen-on {
			#left {
				left: 14px;
			}
			#right {
				right: 14px;
			}
		}
	}
}
@media screen and (max-width: 35.5em) {
	#gobox {
		.join[data-join=business] {
			a.hero--button {
				bottom: -15px !important;
				position: absolute !important;
				right: 0;
				text-align: center;
				left: 0;
				width: 190px;
				margin: auto;
				padding: 20px 0 !important;
			}

			.hero--desc {
				margin-bottom: 0px !important;
			}
		}
		.section-our-values {
			.list {
				img {
					width: 100px;
					height: 100px;
				}
			}
		}
		.as-seen-on {
			#left {
				left: 0px;
			}
			#right {
				right: 0px;
			}
		}
	}
}
