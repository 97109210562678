// ----------------------------------------------------------------------
	// Layout Breakpoints
// ----------------------------------------------------------------------
$maxWidth: 1280;

// ----------------------------------------------------------------------
	// Color
// ----------------------------------------------------------------------
$black: #4a4a4a;
$gunmetal:#485460;
$charcoal:#343b41;
$warmgrey:#9b9b9b;
$sunyellow:#ffcd2e;
$icon_bg: #ffffff;

// ----------------------------------------------------------------------
	// Brand Color
// ----------------------------------------------------------------------
$starwars: #cd0000;
$gojek: #48af4a;
$gosend: #8BC440;
$gomart: #3C95D1;
$goride: #F9AE3C;
$gocar: #17619D;
$gofleet: #00c79b;
$gobox: #A1652F;
$gobox-yellow: #FCE500;
$gopay: #5CA5DA;
$gopoint: #F79520;
$gopulsa: #22AF6E;
$gofood: #B62025;
$gobluebird: #27285F;
$gotix: #EA6B25;
$gobusway: #EF7921;
$gomed: #006738;
$gomassage: #18AEAB;
$goclean: #2197D4;
$goauto: #BABCBE;
$goglam: #E67498;
$gobills: #4285a7;
$govideo: #ECB42C;
$golife: #49af4b;
$gotix-secondary: #093c5b;
$gopertamina: #D0112B;
$gofix: #FFCE1D;
$godaily: #007CB6;
$golaundry: #75CDDC;
$godeals: #ff1b00;

// ----------------------------------------------------------------------
	// Font Sizes
// ----------------------------------------------------------------------
$bodyDesk: 1em;
$bodyMob: .75em;

$h1Desk: 4em;
$h1Mob: 2em;

$h2Desk: 2.250em;
$h2Mob: 1.313em;

$h3Desk: 1.500em;
$h3Mob: 0.938em;

// Button, Form
$formDesk: 1.125em;
$formMob: 0.688em;

$titleDesk: 0.875em;
$titleMob: 0.750em;


// ----------------------------------------------------------------------
	// Font Families
// ----------------------------------------------------------------------
$opensans: 'Open Sans', Helvetica, sans-serif;
$lato: 'Lato', Helvetica, sans-serif;
$viva: 'Viva', Helvetica, sans-serif;

// ----------------------------------------------------------------------
  // Grid
// ----------------------------------------------------------------------
$grid-columns: 12;

// ----------------------------------------------------------------------
  // Line Height
// ----------------------------------------------------------------------
$line-height: 1.2em;

// ----------------------------------------------------------------------
  // Icon Services
// ----------------------------------------------------------------------
@mixin icon-gotix {
	background: $icon_bg url(https://bilba.go-jek.com/images/product-brand/gotix.svg) no-repeat center;
}
@mixin icon-gofood {
	background: $icon_bg url('https://bilba.go-jek.com/images/product-brand/gofood.svg') no-repeat center;
}
@mixin icon-gopay {
	background: $icon_bg url('https://bilba.go-jek.com/images/product-brand/gopay.svg') no-repeat center;
}
@mixin icon-goride {
	background: $icon_bg url('https://bilba.go-jek.com/images/product-brand/goride.svg') no-repeat center;
}
@mixin icon-gojek {
	background: $icon_bg url(https://bilba.go-jek.com/images/product-brand/gojek.svg) no-repeat center;
}
@mixin icon-gosend {
	background: $icon_bg url('https://bilba.go-jek.com/images/product-brand/gosend.svg') no-repeat center;
}
@mixin icon-gomart {
	background: $icon_bg url('https://bilba.go-jek.com/images/product-brand/gomart.svg') no-repeat center;
}
@mixin icon-gocar {
	background: $icon_bg url('https://bilba.go-jek.com/images/product-brand/gocar.svg') no-repeat center;
}
@mixin icon-gobox {
	background: $icon_bg url('https://bilba.go-jek.com/images/product-brand/gobox.svg') no-repeat center;
}
@mixin icon-gopoint {
	background: $icon_bg url('https://bilba.go-jek.com/images/product-brand/gopoint.svg') no-repeat center;
}
@mixin icon-gopulsa {
	background: $icon_bg url('https://bilba.go-jek.com/images/product-brand/gopulsa.svg') no-repeat center;
}
@mixin icon-gobusway {
	background: $icon_bg url('https://bilba.go-jek.com/images/product-brand/gobusway.svg') no-repeat center;
}
@mixin icon-gomed {
	background: $icon_bg url('https://bilba.go-jek.com/images/product-brand/gomed.svg') no-repeat center;
}
@mixin icon-gomassage {
	background: $icon_bg url('https://bilba.go-jek.com/images/product-brand/gomassage.svg') no-repeat center;
}
@mixin icon-goclean {
	background: $icon_bg url('https://bilba.go-jek.com/images/product-brand/goclean.svg') no-repeat center;
}
@mixin icon-goauto {
	background: $icon_bg url('https://bilba.go-jek.com/images/product-brand/goauto.svg') no-repeat center;
}
@mixin icon-goglam {
	background: $icon_bg url('https://bilba.go-jek.com/images/product-brand/goglam.svg') no-repeat center;
}
@mixin icon-golife {
	background: $icon_bg url('https://bilba.go-jek.com/images/product-brand/golife.svg') no-repeat center;
}
@mixin icon-gobills {
	background: $icon_bg url('https://bilba.go-jek.com/images/product-brand/gobills.svg') no-repeat center;
}
@mixin icon-gopertamina{
	background: $icon_bg url('https://bilba.go-jek.com/images/product-brand/gopertamina.svg') no-repeat center;
}
@mixin icon-gofix{
	background: $icon_bg url('https://bilba.go-jek.com/images/product-brand/gofix.svg') no-repeat center;
}
@mixin icon-godaily{
	background: $icon_bg url('https://bilba.go-jek.com/images/product-brand/godaily.svg') no-repeat center;
}
@mixin icon-golaundry{
	background: $icon_bg url('https://bilba.go-jek.com/images/product-brand/golaundry.svg') no-repeat center;
}
@mixin icon-godeals{
	background: $icon_bg url('https://bilba.go-jek.com/images/product-brand/godeals.svg') no-repeat center;
}

@mixin brush-gojek {
	background-image:url('../../dist/img/brush/GO-JEK.png');
	&:after
	{
		@include icon-gojek;
	}
}
@mixin brush-gobills {
	background-image:url('../../dist/img/brush/GO-BILLS.png');
	&:after
	{
		@include icon-gobills;
	}
}
@mixin brush-goauto {
	background-image:url('../../dist/img/brush/GO-AUTO.png');
	&:after
	{
		@include icon-goauto;
	}
}
@mixin brush-gobox {
	background-image:url('../../dist/img/brush/GO-BOX.png');
	&:after
	{
		@include icon-gobox;
	}
}
@mixin brush-gocar {
	background-image:url('../../dist/img/brush/GO-CAR.png');
	&:after
	{
		@include icon-gocar;
	}
}
@mixin brush-goclean {
	background-image:url('../../dist/img/brush/GO-CLEAN.png');
	&:after
	{
		@include icon-goclean;
	}
}
@mixin brush-gofood {
	background-image:url('../../dist/img/brush/GO-FOOD.png');
	&:after
	{
		@include icon-gofood;
	}
}
@mixin brush-goglam {
	background-image:url('../../dist/img/brush/GO-GLAM.png');
	&:after
	{
		@include icon-goglam;
	}
}
@mixin brush-gomart {
	background-image:url('../../dist/img/brush/GO-MART.png');
	&:after
	{
		@include icon-gomart;
	}
}
@mixin brush-gomassage {
	background-image:url('../../dist/img/brush/GO-MASSAGE.png');
	&:after
	{
		@include icon-gomassage;
	}
}
@mixin brush-gomed {
	background-image:url('../../dist/img/brush/GO-JEK.png');
	&:after
	{
		@include icon-gomed;
	}
}
@mixin brush-gopay {
	background-image:url('../../dist/img/brush/GO-PAY.png');
	&:after
	{
		@include icon-gopay;
	}
}
@mixin brush-gopoints {
	background-image:url('../../dist/img/brush/GO-POINTS.png');
	&:after
	{
		@include icon-gopoint;
	}
}
@mixin brush-gopulsa {
	background-image:url('../../dist/img/brush/GO-PULSA.png');
	&:after
	{
		@include icon-gopulsa;
	}
}
@mixin brush-goride {
	background-image:url('../../dist/img/brush/GO-RIDE.png');
	&:after
	{
		@include icon-goride;
	}
}
@mixin brush-golife {
	background-image:url('../../dist/img/brush/GO-LIFE.png');
	&:after
	{
		@include icon-golife;
	}
}
@mixin brush-gosend {
	background-image:url('../../dist/img/brush/GO-SEND.png');
	&:after
	{
		@include icon-gosend;
	}
}
@mixin brush-karir {
	background-image:url('../../dist/img/brush/GO-SEND.png');
	&:after
	{
		@include icon-gojek;
	}
}
@mixin brush-gotix {
	background-image:url('../../dist/img/brush/GO-TIX.png');
	&:after
	{
		@include icon-gotix;
	}
}
