#gomed {
	.join {
		.section-our-values {
			padding: 60px 0 !important;

			.list {
				h4 {
					text-transform: none;
				}
			}
		}

		.section-join-form {
			padding: 60px 0;

			@-webkit-keyframes uil-ring-anim {
			  0% {
			    -webkit-transform: rotate(0deg);
			    transform: rotate(0deg);
			  }
			  100% {
			    -webkit-transform: rotate(360deg);
			    transform: rotate(360deg);
			  }
			}
			@-moz-keyframes uil-ring-anim {
			  0% {
			    -webkit-transform: rotate(0deg);
			    transform: rotate(0deg);
			  }
			  100% {
			    -webkit-transform: rotate(360deg);
			    transform: rotate(360deg);
			  }
			}
			@-webkit-keyframes uil-ring-anim {
			  0% {
			    -webkit-transform: rotate(0deg);
			    transform: rotate(0deg);
			  }
			  100% {
			    -webkit-transform: rotate(360deg);
			    transform: rotate(360deg);
			  }
			}
			@-o-keyframes uil-ring-anim {
			  0% {
			    -webkit-transform: rotate(0deg);
			    transform: rotate(0deg);
			  }
			  100% {
			    -webkit-transform: rotate(360deg);
			    transform: rotate(360deg);
			  }
			}
			@keyframes uil-ring-anim {
			  0% {
			    -webkit-transform: rotate(0deg);
			    transform: rotate(0deg);
			  }
			  100% {
			    -webkit-transform: rotate(360deg);
			    transform: rotate(360deg);
			  }
			}
			.uil-ring-css {
			  background: none;
			  position: relative;
			  width: 200px;
			  height: 200px;
				margin: 0 auto;
				display: block;

				div {
				  position: absolute;
				  display: block;
				  width: 160px;
				  height: 160px;
				  top: 20px;
				  left: 20px;
				  border-radius: 80px;
				  box-shadow: 0 3px 0 0 $gomed;
				  -webkit-transform-origin: 80px 81.5px;
				  transform-origin: 80px 81.5px;
				  -webkit-animation: uil-ring-anim 1s linear infinite;
				  animation: uil-ring-anim 1s linear infinite;
				}
			}

			.loader-wrapper {
				display: none;
				position: relative;
				top: 140px;
				margin-bottom: -200px;
			}

			.main-wrapper {
				background: #fff;
				height: 700px;
				width: 100%;
				box-shadow: 0px 0px 50px rgba(90, 90, 90, .25);


				.text-wrapper {
					.title {
						margin-top: 30px;
						font-size: 12px;
						position: relative;
						left: 50px;
						top: 10px;
						color: rgba(200, 200, 200, 1.0);
					}
				}

				.background-wrapper {
					background: url('https://d3naj63yelf4gk.cloudfront.net/dist/img/card/join-form/gomed-texture.jpg');
					min-height: 700px;
					height: 100% !important;
					width: 100%;
					padding: 0;
					text-align: center;

					img {
						max-width: 60px;
						margin-top: 260px;
					}
				}

				.form-wrapper {
					padding: 0 50px;

					.title {
						margin-top: -15px;
						font-size: 12px;
						position: relative;
						top: 10px;
						color: rgba(200, 200, 200, 1.0);
					}

					.group {
						width: 100%;
						margin-top: 30px;
						margin-bottom: 30px;

						label {
							font-family: "Open Sans", sans-serif;
							font-weight: 700;
							font-size: 12.5px;
							color: #697b8c;
						}

						input,
						select {
							width: 100%;
							height: 45px;
							padding: 15px;
							margin-top: 5px;
							letter-spacing: 1.2px;
							font-size: 13.4px;
							background: white;
							border-radius: 0 !important;
							border: 1px solid rgb(210, 210, 210);
							color: #485460;
						}

						input[type=number]::-webkit-inner-spin-button,
						input[type=number]::-webkit-outer-spin-button {
					    -webkit-appearance: none;
					    -moz-appearance: none;
					    appearance: none;
					    margin: 0;
						}

						select {
							cursor: pointer;
							appearance: none;
							-webkit-appearance: none;
						}

						textarea {
							width: 100%;
							height: 145px;
							padding: 15px;
							margin-top: 5px;
							letter-spacing: 1.2px;
							font-size: 13.4px;
							background: white;
							border-radius: 0 !important;
							border: 1px solid rgb(210, 210, 210);
							color: #485460;
						}


						.icon {
							float: right;

							i {
								position: relative;
								left: -15px;
								top: 35px;
								font-size: 12px;
								color: rgb(210, 210, 210);
							}
						}
					}
				}

				.form-wrapper.second-column {
					position: relative;
					top: 52.5px;
				}

				.error_messages {
					color: red;
					letter-spacing: .1px;
					margin-top: 10px;
				}

				.form-button {
 				 position: relative;
 				 top: 25px;
 				 left: -40px;

 				 .button {
 					 font-size: 14px;
 					 font-family: $opensans;
 					 font-weight: 400;
 					 padding: 35px;
					 border-radius: 5px;
 					 transition: all .5s ease 0s;
 					 margin: 0 5px;
					 box-shadow: unset;

 	 				 span {
 	 					 margin: 0 50px;
 	 				 }
 				 }

 				 .button-submit-go {
 					 text-transform: none;
 					 background: $gomed;
 					 color: #fff;
 					 float: right;
 					 padding: 24px;
 				 }
 			 	}
			}
		}
	}

	.join.thankyou {
		#content-only {
			margin-top: 100px;
			margin-bottom: 50px;

			.container {
		    background: white;
		    box-shadow: 0 -3em 0 1.5em $gomed;

				.info {
					h4 {
						color: $gomed;
					}

					.line {
						background: $gomed;
					}

					.link {
						a {
							width: 280px;
						}

						.contact {
							background: $gomed;

							span {
								margin: 0 15px;
							}
						}
					}
				}
			}
		}
	}
}

@media screen and (max-width: 768px) {
	#gomed {
		.join {

			.section-join-form {
				margin-top: 50px;
				padding: 0px;

				.main-wrapper {

					background: #fff;
					margin: 50px 0;
					height: auto;
					width: 100%;
					box-shadow: 0px 0px 50px rgba(90, 90, 90, .25);

					.background-wrapper {
						min-height: 100px;
						height: 100% !important;
						width: 100%;
						padding: 0;
						text-align: center;

						img {
							max-width: 25px;
							margin-top: 40px;
						}
					}

					.text-wrapper {
						margin-bottom: 35px;
						margin-top: 10px;
						position: relative;
						left: -10px;

						.title {
							margin-top: -15px;
							font-size: 12px;
							position: relative;
							top: 10px;
							color: rgba(200, 200, 200, 1.0);
						}
					}

					.form-container {
						margin-bottom: 50px;
						padding: 20px;
					}

					.form-wrapper {
						 padding: 0 !important;

						.group {
							 width: 100%;
							 margin: 20px 0;

							 label {
				 				 font-family: $opensans;
				 				 font-weight: 700;
								 text-transform: uppercase;
								 letter-spacing: 1.25px;
				 				 font-size: 10.5px;
				 				 color: #697b8c;
				 			 }

							 input,
							 select {
								 width: 100%;
								 height: 45px;
								 padding: 15px;
								 margin-top: 5px;
								 letter-spacing: 1.2px;
								 font-size: 13.4px;
								 background: white;
								 border-radius: 0 !important;
								 border: 1px solid rgb(210, 210, 210);
								 color: #485460;
							 }

							 .icon {
	 							float: right;

	 							i {
	 								position: relative;
	 								left: -15px;
	 								top: 35px;
	 								font-size: 12px;
	 								color: rgb(210, 210, 210);
	 							}
	 						}
					 	 }

						 .error_messages {
		 						color: red;
		 						margin-top: 10px;
						 }
				   }

					 .form-wrapper.second-column {
	 					position: relative;
	 					top: 10px;
	 				}

					 .form-button {
						 position: relative;
						 top: -10px;
						 left: -40px;

						 .button {
							 font-size: 14px;
							 font-family: $opensans;
							 font-weight: 400;
							 padding: 35px;
							 transition: all .5s ease 0s;
							 margin: 0 5px;

			 				 span {
			 					 margin: 0 50px;
			 				 }
						 }

						 .button:hover,
						 .button:focus {
							 box-shadow: 0 2px 10px 0 rgba(0, 0, 0, .65);
						 }

						 .button-submit-go {
						   text-transform: none;
						   background: #98ce00;
						   color: #fff;
						   float: none;
						   position: relative;
						   left: 53px;
						   width: 90%;
						   margin-bottom: 10px;
						   padding: 24px;
						}
				  }
				}
			}
		}
	}
}
