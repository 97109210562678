// ----------------------------------------------------------------------

  // Base Heading Style

// ----------------------------------------------------------------------

// example: @include heading($fontSize, $letterSpacing);

@mixin heading($fontSize: 'inherit', $letterSpacing: .094em) {
  font-family: $lato;
  line-height: $line-height;
  font-size: $fontSize;
  letter-spacing: $letterSpacing;
}