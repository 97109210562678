// File Name: _footer.scss
// Description: global footer styles
// Used by: Page.scss
// Dependencies:
// ------------------------------------------------------------
//
footer {
	position: relative;
	height: 25em;
	background: white;
	font-family: $lato;

	.dropdown-wrapper {
		margin-top: 5px;

		select {
			background: transparent;
			width: 90%;
			border-color: #e8ebf2 !important;
			height: 40px;
			padding: 0px 10px;
		}
	}

	@media only screen and (max-width: 35.5em) {
		.dropdown-wrapper {
			select {
				width: 100%;
			}
		}
	}


	#header {
		background-size: cover;
	    height: 50px;
	    width: 100%;
	    top: -2.5em;
	    left: 0;
	    position: absolute;
	}
	.pure-g:first-child {
		padding-top: 5em;
	}
	a,
	h2 {
		color: black !important;
	}
	.hide {
		display: none;
	}
	img {
		width: auto;
	}
	hr {
		display: none;
	}
	a {
		text-decoration: none;
	}
	a,
	p {
		font-size: 9px;
		font-weight: 500;
	}
	#brush {
		position: absolute;
		right: 0;
		bottom: 0;
	}
	.wrapper {
		right: 0;
		bottom: 0;
		position: absolute;
	}
	h2 {
		font-size: 3em;
		letter-spacing: 5px;
		font-weight: 300;
		color: white !important;
		font-family: 'Viva', Helvetica, sans-serif;
		text-align: right;
		line-height: $line-height;
		margin: 0 1em;
		span {
			display: block;
		}
	}
	h2:after {
		content: none !important;
	}
	ul {
		list-style: none;
		padding: 0;
		margin: 0;
		font-family: $lato;
		font-weight: 300;
		line-height: 2.0714285714285716em;
		letter-spacing: 0.14285714285714285em;
		outline: none;
		.title-content {
			font-family: $lato;
			font-weight: 700;
			outline: none;
			color: black !important;
			i {
				float: right;
				display: none;
			}
			.ui-accordion-header-icon {
				float: right;
			}
		}
		#accordion ul {
			display: inherit;
		}
	}
	.social-media {
		text-align: center;
		padding-top: 4.7em;
		font-size: 0.875em;
		a {
			margin-left: 1em;
			margin-right: 1em;
		}
		p {
			color: #9b9b9b !important;
			letter-spacing: 0.2em;
			font-family: $lato;
			font-weight: 300;
		}
	}
}

@media screen and (max-width:768px) {
	footer {
		.social-media {
			position: absolute;
			bottom: 0;
			left: 0;
			margin: 8px;
		}
	}
}

@media screen and (max-width:35.5em) {
	footer {
		height: 1120px;
		background-size: 100%, cover;
		padding-bottom: 10em;
		.pure-g:first-child {
			padding-top: 3em;
		}
		img {
			margin-left: auto;
			margin-right: auto;
			padding-bottom: 2em;
		}
		li {
			margin: 0 0.875em;
			line-height: 2.0625em;
			letter-spacing: 0.1625em;
		}
		.fa {
			margin: 0.5em 0.75em;
		}
		hr {
			background: white;
			border: solid 0.09375em #9b9b9b;
			width: 100%;
			margin: 1.7em 0.5em;
			display: block;
			height: 0;
			width: 100%;
		}
		.social-media {
			position: relative;
			padding: 0;
			margin-bottom: 5em;
			a {
				margin: 0;
			}
		}
		#brush {
			padding-bottom: 0;
		}
		.wrapper {
			right: 0;
			left: 0;
			bottom: 2em;
		}
		h2 {
			text-align: center;
			font-size: 24px;
			margin: 1em auto;
		}
	}
}

@media screen and (min-width: 48em) {
	footer {
		li {
			padding: 0;
			line-height: 2.0625em;
			letter-spacing: 0.1625em;
		}
		ul {
			font-size: 0.65em;
			.title-content {
				font-size: 12px;
			}
		}
		.social-media {
			font-size: 0.5em;
		}
	}
}

@media screen and (min-width: 64em) {
	footer {
		.social-media {
			padding-top: 4.75em;
		}
	}
}
