#gomassage {
	.section-user-review .slider .item .wrapper h3.user-testimonial {
		height: 25px;
	}
	
	.new-section-how-to-use
	{
			margin-top: 180px;
			margin-bottom:100px;
			h3.title
			{
				color: #18aeab;
				&:after
				{
					background:#18aeab;
				}
			}
			.content
			{
				background-color:#18aeab;
			}
	}
	.articles--feed {
		.line--title {
			background: #485460 !important;
		}
		svg {
			ellipse {
				stroke: #485460 !important;
			}
			polygon {
				fill: #485460 !important;
			}
		}
	}

	section#new-join
	{
		margin: 48px 0px 100px 0px;
		.container-form{
			.content {
				form {
					.separate-input {
						label.checkbox {
							> label[for] {
								text-transform: capitalize;
								width: 49%;
								height: 17px;
								object-fit: contain;
								font-family: $opensans;
								font-size: inherit;
								font-weight: normal;
								font-style: normal;
								font-stretch: normal;
								line-height: normal;
								letter-spacing: 1.3px;
							}
							@media screen and (max-width: 767px) {
								width:100%;
							}
						}
					}
				}
			}
		}

		#id_relatives_nb{
			width: 510px;
			height: 60px;
			object-fit: contain;
			font-family: Open Sans,Helvetica,sans-serif;
			font-size: inherit;
			font-weight: 500;
			font-style: italic;
			font-stretch: normal;
			line-height: 1.43;
			letter-spacing: 0.5px;
			color: #99999a;
		}

		.hide-input{
			margin-top: 16px;
		}
	}

	#content-only.thank-you
	{
		margin-top: 220px;
		a.contact
		{
			width:300px;
		}
		@media screen and (max-width: 768px) {
			margin-bottom: 130px;
			a.contact
			{
				width:100%;
				line-height: normal;
			}
		}
		.container {
			background: white;
			box-shadow: 0 -3em 0 1.5em $gomassage;
			.info {
				h4 {
					color: $gomassage;
				}
				.line {
					background: $gomassage;
				}
				.link {
					a {
						width: 280px;
					}
					.contact {
						margin-bottom: 15px;
						span {
							margin: 0 15px;
						}
					}
					.contact.homepage {
						background: rgb(200, 200, 200);
					}
				}
			}
		}
	}

	#content-only {
		padding-top: 2em;
		.container {
			box-shadow: 0 -3em 0 1.5em $gomassage;
			.info {
				background: url('../img/page/gomassage/join-bg.jpg') no-repeat center center/cover;
				h4 {
					color: $gomassage;
				}
				p {
					color: white;
				}
				.line {
					background: $gomassage;
				}
				.link {
					.contact {
						background: $gomassage;
					}
				}
			}
		}
	}
	.section-our-values {
		padding: 3em;
		background: #f8f8f8;
		h4.title {
			color: $gomassage;
		}
		.line.gradient {
			background: $gomassage;
		}
		.list h4 {
			line-height: 25px;
			font-size: 15px;
		}
		img {
			margin-top: 40px;
			margin-bottom: 50px;
			max-width: 250px;
			height: 125px;
		}
		img[data-slide="1"] {
			max-width: 127.5px;
		}
	}
	.services {
		.service-logo {
			max-width: 320px;
		}
		.container::before {
			background-color: $gomassage;
		}
		h4 {
			color: $gomassage;
		}
		h5 {
			font-size: 20px;
			margin-bottom: 0px;
		}
		.wrapper .service-container {
			height: 15em;
			background: $gomassage;
			margin: 0 -15px;
			margin-right: -20%;
			.service-list {
				min-height: 450px;
			}
		}
		.wrapper .service-container .service-list .bg-services {
			opacity: 1.0;
		}
	}
	.merchant-list {
		h5 {
			color: $gomassage;
		}
		hr {
			background: $gomassage;
		}
		.line.gradient {
			background: $gomassage;
		}
		.list h4 {
			line-height: 25px;
			font-size: 15px;
		}
	}
	#talent-story-golife {
		.content {
			img {
				border-radius: 0;
				width: initial;
				height: initial;
			}
		}
		.detail {
			.info {
				h4 {
					margin-bottom: 1.5em;
				}
			}
		}
	}
	.articles--feed {
		.bg {
			background: $gomassage;
		}
		.read--more>a {
			background: $gomassage;
		}
		.content .one {
			border-bottom: 6px solid $gomassage;
		}
		.content .two {
			border-bottom: 6px solid $gomassage;
		}
		.content .three {
			border-bottom: 6px solid $gomassage;
		}
		.content .four {
			border-bottom: 6px solid $gomassage;
		}
		h4>a:hover {
			color: $gomassage;
		}
	}
	.section-user-review .slick-dots li.slick-active button:before {
		color: #18aeab;
	}
	.section-user-review {
		.head .title {
			color: $gomassage;
		}
		.head .line.gradient {
			background: $gomassage;
		}
		.wrapper .line.light.yellow {
			background: white;
		}
		.slider .item .wrapper.style-2 {
			background: $gomassage;
		}
		.slider .item .wrapper.style-3 {
			background: #b8e084;
		}
		.slider {
			.item {
				.wrapper {
					text-align: center;
					.img-wrapper {
						margin-right: auto;
						margin-left: auto;
						display: block;
						margin-top: -60px;
						margin-bottom: 25px;
						height: 80px !important;
						width: 80px !important;
						border-radius: 50%;
						background-size: cover !important;
					}
				}
			}
		}
	}
	#join-now {
		a {
			background: $gomassage;
		}
	}
	#promo-banner {
		.arrow-up {
			stroke: $gomassage;
		}
		.arrow-up-circle {
			stroke-width: 3;
			stroke: $gomassage;
		}
		.arrow-down {
			stroke: $gomassage;
		}
		.arrow-down-circle {
			stroke: $gomassage;
			stroke-width: 3;
		}
		.img-slide {
			background: url('http://www.go-massage.co.id/assets/gomassage/img/bg-promo.jpg');
			background-size: cover !important;
			.img-item {
				div.wrapper {
					padding: 100px;
				}
				h2 {
					font-family: $lato;
					font-size: 28px;
					text-align: center;
					color: white;
				}
				p {
					color: white;
					text-align: center;
				}
				a {
					transition: all 0.3s ease 0s;
					color: white;
					padding: 15px;
					cursor: pointer;
					color: #fff;
					position: relative;
					top: 20px;
					border: 1px solid #fff;
				}
				a:hover {
					background: $gomassage;
					border: 1px solid $gomassage;
				}
			}
		}
	}
	#bg-as-seen-on {
		hr {
			background: $gomassage;
		}
		.title {
			color: $gomassage;
		}
		#right {
			background: $gomassage;
		}
	}
	.section-how-to-use {
		h5 {
			color: $gomassage;
		}
		.line.gradient {
			background: $gomassage;
		}
		.arrow-up {
			stroke: $gomassage;
		}
		.arrow-up-circle {
			stroke-width: 3;
			stroke: $gomassage;
		}
		.arrow-down {
			stroke: $gomassage;
		}
		.arrow-down-circle {
			stroke: $gomassage;
			stroke-width: 3;
		}
		.slider .slick-dots li.slick-active button:before {
			color: $gomassage;
		}
		.slider-image .slick-dots li.slick-active button:before {
			color: $gomassage;
		}
	}
	@media only screen and (max-width: 480px) {
		#promo-banner {
			.img-slide {
				background: url('http://www.go-massage.co.id/assets/gomassage/img/bg-promo.jpg');
				background-size: cover !important;
				.img-item {
					div.wrapper {
						padding: 40px;
						margin-top: -40px;
						margin-bottom: 20px;
					}
				}
			}
		}
	}
}

@media screen and (max-width: 48em) {
	#gomassage {
		section.services#golife .wrapper .service-container .service-list {
			min-height: 440px;
		}
		.section-user-review .slider .item {
			height: 300px;
			.wrapper h3.user-testimonial {
				height: 40px;
			}
		}
		.services
		{
			padding-bottom: 20em !important;
		}
	}
}

@media screen and (max-width: 35.5em) {
	#gomassage {
		#talent-story-golife {
			margin: 0;
			h4:before {
				top: 3.5% !important;
			}
		}
		.services {
			padding-bottom: 0 !important;
			.wrapper .navigation-arrow {
				padding-top: 21em !important;
			}
		}
		.slider .item .wrapper svg {
			max-width: 80px;
			margin: -60px auto 25px auto;
			z-index: 999;
			border-radius: 50%;
		}
		.section-user-review .foot .wrapper .arrow-next,
		.section-user-review .foot .wrapper .arrow-prev {
			margin: 10px 50px 5px;
		}
	}
}
