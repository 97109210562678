#gopay {
	.gopay-patform-icon {
		max-width: 200px;
		@media screen and (max-width: 500px) {
			max-width: 160px;
		}
	}
	#privacy {
		margin-top: 8em;
	}
	.gopay {
		p {
			font-size: 16px;
		}
		ul.normal {
			padding-left: inherit;
		}
	}
	.ac-label {
		img {
			margin-bottom: 5px;
		}
	}
	.ac {
		margin: 100px 0;
	}
	.ac:first-child {
		margin: 0;
		margin-bottom: 100px;
	}
	label.ac-label {
		margin: 25px 0;
		display: block;
	}
	.howto {
		background: white;
		padding: 30px;
	}
	img.bca {
		height: 30px;
	}
	img.mandiri {
		height: 30px;
	}
	img.bri {
		height: 30px;
	}
	img.bni {
		height: 30px;
	}
	img.permata {
		height: 40px;
	}
	img.cimb {
		height: 20px;
		margin-top: 2px;
	}
	img.atmbersama {
		height: 45px;
	}
	img.prima {
		height: 45px;
	}
	img.bank-btn {
		height: 28px;
		margin-top: -3px;
	}
}

#privacy {
	margin: 5em 0;
	.title {
		border-top: 5px solid #48af4a;
		font-family: $opensans;
		color: #272d32;
		margin-right: 4em;
		h2 {
			font-weight: 300;
			line-height: 1.28;
			letter-spacing: 2px;
		}
		span {
			color: #3ca33a;
			display: block;
		}
	}
	.content {
		.strong {
			font-weight: bold;
		}
		h4 {
			font-size: inherit;
		}
	}
	ul {
		list-style: none;
		position: relative; // margin-top: 8em;
		padding-left: 4em;
		hr {
			position: absolute;
			width: 50px;
			height: 4px;
			border: none;
			background-color: #58c75b;
			left: 0;
			top: 0.5em;
		}
		li {
			margin: 1em 0em;
			a {
				font-family: $opensans;
				line-height: 2.5;
				letter-spacing: 0.6px;
				color: #343b41;
			}
			.active,
			a:hover {
				color: #3ca33a;
			}
		}
	}
	p {
		font-family: $opensans;
		font-weight: 300;
		line-height: 1.56;
		letter-spacing: 1px;
		color: #485460;
	}
	.perjanjian {
		ol {
			margin-top: 0 !important;
		}
	}
	ol {
		padding-left: 20px; // margin-top: 4em;
		li {
			font-family: $opensans;
			font-weight: 300;
			line-height: 1.56;
			letter-spacing: 1px;
			color: #485460;
			margin-bottom: 0.7em;
		}
	}
}

@media screen and (max-width: 35.5em) {
	#privacy {
		// Used For perjanjian-kerjasama-kemitraan/
		ul,
		ol {
			p {
				font-size: 12px;
			}
			li {
				font-size: 14px;
				p {
					font-size: 12px;
				}
				ol {
					li {
						font-size: 12px;
					}
				}
			}
		}
		ul {
			margin: 0;
			hr {
				top: 0.5em;
			}
			li {
				margin: 1em 0;
			}
		}
	}
}
