.section-how-to-use {
	overflow: hidden;
	position: relative;
	padding: 50px;
	height: 620px;
	background-size: cover!important;
	.text-mobile {
		display: none;
		text-align: center;
	}
	.text-mobile .line.gradient {
		width: 50px;
		height: 3px;
		margin-top: 0px;
		background: $gojek;
		margin-left: auto;
		margin-right: auto;
	}
	.text h5 {
		margin-top: 100px;
		color: $gojek;
		font-weight: 900;
	}
	.text .line.gradient {
		width: 50px;
		height: 3px;
		margin-top: 0px;
		background: $gojek;
	}
	.phone-base {
		display: block;
		margin-right: auto;
		margin-left: auto;
		top: 70px;
		position: relative;
	}
	.slider .item-text {
		height: 250px;
		h2 {
			text-transform: uppercase;
			margin-top: 20px;
			margin-left: 0px;
			font-weight: 300;
			font-size: 2em;
		}
	}
	.slider .item .wrapper {
		width: 100%;
	}
	.slider-image .slick-list {
		// margin: 40px 0 -112px -20px;
		// position: relative;
		// left: -155px;
		// top: -6px;
	}
	.slider-image .slick-dots {
		// position: relative;
		// top: -350px;
		// left: 30px;
	}
	.slider-image .slick-dots li {
		display: block;
	}
	.slider-image .slick-dots li.slick-active button:before {
		color: $goride;
	}
	.slider-image .slick-dots li button:before {
		font-size: 13px;
	}
	.slider .slider-text.title {
		position: relative;
		float: right;
		left: -265px;
		top: -20px;
		font-size: 36px;
		margin-top: -320px;
		font-weight: 300;
	}
	.slider .slider-text.desc {
		margin-left: 380px;
		position: relative;
		top: -280px;
		left: 45px;
		margin-bottom: -33px;
		line-height: 1.56;
		font-weight: 300;
		letter-spacing: 1.7px;
	}
	.slider-image {
		position: relative;
		z-index: 99 !important;
		margin: -405px auto -98px;
		text-align: center;
		display: block;
		width: 265px;
		.item {
			img {
				width: 100%;
			}
		} // .phone-slider {
		//   display: block;
		//   max-width: 260px;
		//   margin-right: auto;
		//   margin-left: auto;
		//   margin-bottom: 10px;
		//   position: relative;
		//   left: 155px;
		//   top: -17px;
		// }
	}
	.slick-arrow {
		cursor: pointer;
	}
	ul.slick-dots {
		position: relative;
		top: -350px;
		left: -90px;
	}
	ul.nextprev {
		position: absolute;
		display: inline-block;
		list-style-type: none;
		top: 50%;
		-webkit-transform: translateY(-50%);
		-moz-transform: translateY(-50%);
		-o-transform: translateY(-50%);
		transform: translateY(-50%);
	}
	ul li {
		margin-top: 10px;
	}
	ul li img {
		cursor: pointer;
	}
}

.new-section-how-to-use {
		background-size: 130px;
		background-position: bottom;
		width: 90%;
		max-width: 1170px;
		margin: auto;
		text-align: center;
		position: relative;
		margin-bottom: 150px;
		h3.title
		{
			font-family: Lato,Helvetica,sans-serif;
			z-index: 1;
			color:#b62025;
			font-size: 18px;
			letter-spacing: 1.5px;
			position: absolute;
			width: 76%;
			text-align: center;
			right: 0;
			margin-top: 45px;
			&:after
			{
				content:"";
				position: absolute;
				width: 46px;
				height: 3px;
				background-color: #b62025;
				bottom: -20px;
				left: 0;
				right: 0;
				margin: auto;
				@media screen and (max-width: 767px) {
					bottom: auto;
					top:35px;
				}
			}
			@media screen and (max-width: 768px) {
				font-size:14px;
				width:70%;
			}
			@media screen and (max-width: 767px) {
				position:relative;
				width:100%;
				margin:0;
				background-color:#f8f8f8;
				padding-bottom:50px;
			}
			@media screen and (min-width: 2560px) {
				width:61%;
			}
		}
		.download-section
		{
			display: table;
			position: absolute;
    		width: 76.5%;
    		height: 105px;
    		text-align: center;
    		right: 0;
    		bottom: 0;
    		.button-download
    		{
    			display: table-cell;
    			vertical-align: middle;
    			img
    			{
    				max-width: 108px;
    			}
    			a:first-child img
    			{
    				margin-right:30px;
    			}
    		}
    		@media screen and (max-width: 768px) {
    			width:70.5%;
    		}
    		@media screen and (max-width: 767px) {
    			display:none;
    		}
		}
		.right-phone
		{
			position: relative;
			width: 73%;
			height: 100%;
			background:#ffffff;
			margin: auto;
			@media screen and (max-width: 768px) {
				width: 85%;
			}
			@media screen and (max-width: 767px) {
				display: none;
			}
		}
		.content
		{
			position: absolute;
			width: 63.5%;
			right: 0;
			top: 0;
			margin: auto;
			text-align: center;
			color: #ffffff;
			height: 240px;
			background-color:#b62025;
			bottom: 0;
			.table-display
			{
				position: relative;
				left: 35px;
				display: table;
				height: 100%;
				width: 70%;
				.mid-table
				{
					display: table-cell;
					vertical-align: middle;
					.number
					{
						position: relative;
						margin-bottom: 30px;
						font-size: 36px;
						height: 40px;
						overflow: hidden;
						div
						{
							position: absolute;
							height: 100%;
							width: 100%;
							transition: all 0.5s 0.2s;
							text-align: center;
							opacity: 0;
						}
						div.active
						{
							opacity: 1;
							transition: all 0.5s 0s;
						}
						@media screen and (max-width: 767px) {
							margin-bottom:5px;
							font-size:28px;
							display:none;
						}
					}
					.slide-content
					{
						font-family: $opensans;
						font-size: 16px;
						letter-spacing: 1.8px;
						font-weight: 100;
						margin: auto;
						height: 115px;
						padding-bottom: 30px;
						max-width: 350px;
						.dot-title
						{
							padding-right: 5px;
							@media screen and (min-width: 768px) {
								display: none;
							}
						}
						.slick-dots
						{
							bottom: auto;
							position: relative;
							margin-top: 20px;
						}
						.slide-prevArrow
						{
							z-index: 5;
							cursor: pointer;
							padding: 10px 12px;
							border: 3px solid #ffffff;
						    top: 0;
						    font-size: 12px;
						    border-radius: 50%;
						    position: absolute;
						    display: block;
						    left: -69px;
						    background: transparent;
						    @media screen and (max-width: 768px) {
						    	left: -50px;
						    }
						    @media screen and (max-width: 767px) {
						    	left:0;
						    	bottom:-13px;
						    	top:auto;
						    }
						    .rotate-arrow
						    {
						    	transform: rotate(180deg);
						    }
						}
						.slide-nextArrow
						{
							z-index: 5;
							cursor: pointer;
							padding: 10px 12px;
							border: 3px solid #ffffff;
						    top: 0;
						    font-size: 12px;
						    border-radius: 50%;
						    position: absolute;
						    display: block;
						    right: -69px;
						    background: transparent;
						    @media screen and (max-width: 768px) {
						    	right: -50px;
						    }
						    @media screen and (max-width: 767px) {
						    	right:0;
						    	bottom:-13px;
						    	top:auto;
						    }
						}
						@media screen and (max-width: 767px) {
							width:85%;
							font-size:14px;
						}
						@media screen and (max-width: 768px) {
							max-width:300px;
						}
					}
					@media screen and (max-width: 767px) {
						display:inline-block;
						width:100%;
					}
				}
				@media screen and (max-width: 768px) {
					left:46px;
				}
				@media screen and (max-width: 767px) {
					width:100%;
					left:0;
					display:inline-block;
				}
			}
			.phoneSlide-dots
			{
				position: absolute;
				left: 0;
				right: 0;
				bottom: 0;
				margin-top:0px;
				margin-bottom: 0px;
				padding-left: 0px;
				li
				{
					cursor: pointer;
					list-style-type: none;
					height: 10px;
					width: 10px;
					display: inline-block;
					border-radius: 50%;
					margin-right: 10px;
					border:1px solid #ffffff;
					transition: all 0.5s;
					&:last-child
					{
						margin-right:0px;
					}
					button
					{
						display: none;
					}
				}
				li.slick-active
				{
					background-color:#ffffff;
					transition:all 0.5s;
				}
				@media screen and (max-width: 767px) {
					margin-top:25px;
				}
			}
			@media screen and (max-width: 767px) {
				position:relative;
				width:100%;
				display: inline-block;
				height: auto;
				padding:30px 0;
				background-color:#b62025;
				margin-bottom:-2px;
			}
			@media screen and (min-width: 2560px) {
				width:61%;
			}
		}
		.left-phone
		{
			float: left;
			position: relative;
			height: 100%;
			width: 50%;
			background-size: cover;
			background-position: center;
			left: 0;
			top: 0;
			.half-phone
			{
				max-width: 290px;
				position: absolute;
				width: 70%;
				left: 0;
				right: 0;
				bottom: -50px;
				margin: auto;
				.new-slide-phone
				{
					margin: auto;
				    right: 0;
				    position: absolute;
				    width: 85%;
				    z-index: 2;
				    max-width: 429px;
				    left: 0;
				    bottom: 0;
				    top: 60px;
				    img
				    {
				    	width: 100%;
				    	margin: auto;
				    }
				    @media screen and (max-width: 767px) {
				    	height: 83%;
				    	bottom:0px;
				    	top:45px;
				    }
				}
				&.landscape-mb {
					@media screen and (max-width: 767px) {
						width: 100%;
						> img {
							visibility: hidden;
							opacity: 0;
							width: 100% !important;
						}
					}
				}
				& > img
				{
					@media screen and (max-width: 767px) {
						bottom: -15px;
					}
				}
				img
				{
					position: relative;
					z-index: 2;
					width: 95%;
				}
				@media screen and (max-width: 768px) {
					width:85%;
					bottom:-12%;
					right:auto;
				}
				@media screen and (max-width: 767px) {
					position:relative;
					margin-bottom:-13%;
					z-index:0;
					right:0;
					max-width:300px;
					width:60%;
				}
				@media screen and (max-width: 320px) {
					margin-bottom:-12%;
				}
			}
			&.landscape-mb {
				@media screen and (max-width: 767px) {
					min-height: 200px;
				}	
			}
			@media screen and (max-width: 767px) {
				position:relative;
				width:100%;
				background-image:none !important;
				height:auto;
				margin-top:40px;
			}
		}
		@media screen and (max-width: 768px) {
			margin-top:150px !important;
			margin-bottom:120px;
			display:inline-block;
			width:100%;
		}
		@media screen and (min-width: 768px) {
			margin-top:100px;
			height: 448px;
		}
}

@media screen and (max-width: 768px) {
	.section-how-to-use {
		height: 850px !important;
		padding: 50px 10px !important;
		background-size: cover !important;
		.phone-wrapper
		{
			top: auto !important
		}
		.overlay {
			position: relative;
			width: 100%;
			max-width: 400px;
			height: 100%;
		}
		.slick-slider {
			display: table;
			table-layout: fixed;
			width: 100%;
		}
		.slider-image {
			width: 265px;
			top: -45px;
			margin: -425px auto -98px;
		}
		.text-mobile {
			text-align: center;
			display: block;
			margin-right: auto;
			margin-left: auto;
		}
		.text {
			margin: 0;
			left: 0;
			position: absolute;
			text-align: center;
		}
		.text h5 {
			margin-top: 0 !important;
			display: block;
			color: $gojek;
			font-weight: 900;
		}
		.text .line.gradient {
			width: 50px;
			height: 3px;
			margin: 0px auto;
			display: block;
			background: $gojek;
		}
		.phone-wrapper {
			margin: 0 auto;
			left: 0;
			position: absolute;
			bottom: 0;
			width: 100%;
		}
		.phone-base {
			display: block;
			top: 0px;
			margin: 0px auto;
			position: relative;
		}
		.slider .item-text {
			margin-top: 0px;
			height: 200px;
		}
		.slider .item-text h2 {
			margin-top: 20px;
			margin-left: 0px;
			font-weight: 300;
			font-size: 1.2em;
		}
		.slider .item .wrapper {
			width: 100%;
		}
		.slider-image .slick-list {
			// margin: 40px 0 -112px -20px;
			// position: relative;
			// left: -155px;
			// top: -6px;
		}
		.slider-image ul {
			display: none !important;
		}
		.slider-image .slick-dots {
			position: relative;
		}
		.slider-image .slick-dots li {
			display: block;
		}
		.slider-image .slick-dots li.slick-active button:before {
			color: $goride;
		}
		.slider-image .slick-dots li button:before {
			font-size: 13px;
		}
		.slider .slider-text.title {
			position: relative;
			float: right;
			left: -265px;
			top: -20px;
			font-size: 36px;
			margin-top: -320px;
			font-weight: 300;
		}
		.slider .slider-text.desc {
			margin-left: 380px;
			position: relative;
			top: -280px;
			left: 45px;
			margin-bottom: -33px;
			line-height: 1.56;
			font-weight: 300;
			letter-spacing: 1.7px;
		} // .slider-image {
		//   position: relative;
		//   z-index: 99 !important;
		//   margin-top: -425px;
		//   margin-bottom: -128px;
		//   text-align: center;
		//   margin-right: auto;
		//   margin-left: auto;
		//   display: block;
		//   position: relative;
		//   left: 155px;
		//   .phone-slider {
		//     display: block;
		//     max-width: 260px;
		//     margin-right: auto;
		//     margin-left: auto;
		//     margin-bottom: 10px;
		//     position: relative;
		//     left: 0;
		//     top: -17px;
		//   }
		// }
		ul.slick-dots {
			bottom: 0;
			top: 0;
			left: 0;
		}
		.arrow--wrapper {
			width: 100%;
			position: absolute;
			left: 0;
			top: 37%;
		}
		ul.nextprev {
			position: relative;
			top: 0;
			left: 0;
			display: block;
			margin: 0;
			padding: 0;
			list-style-type: none;
		}
		ul.nextprev>li:nth-child(1) {
			left: 0;
		}
		ul.nextprev>li:nth-child(2) {
			right: 0;
		}
		ul.nextprev>li {
			margin: 0 !important;
			display: inline-block;
			position: absolute;
			-webkit-transform: rotate(-90deg);
			-moz-transform: rotate(-90deg);
			-o-transform: rotate(-90deg);
			transform: rotate(-90deg);
		}
		ul li {
			// margin-top: 10px;
		}
		ul li img {
			cursor: pointer;
		}
	}
}

@media screen and (max-width: 440px) {
	.section-how-to-use {
		// .phone-base {
		//   display: block;
		//   margin-right: auto;
		//   margin-left: auto;
		//   top: 50px;
		//   left: 0px;
		//   margin-top: 40px;
		//   position: relative;
		// }
		// .slider-image {
		//   .phone-slider {
		//     display: block;
		//     max-width: 260px;
		//     margin-right: auto;
		//     margin-left: auto;
		//     margin-bottom: 10px;
		//     position: relative;
		//     left: 0px;
		//     top: -17px;
		//   }
		// }
	}
}

@media screen and (max-width: 400px) {
	.section-how-to-use {
		min-height: 835px; // .phone-base {
		//   display: block;
		//   margin-right: auto;
		//   margin-left: auto;
		//   top: 50px;
		//   left: -25px;
		//   margin-top: 40px;
		//   position: relative;
		// }
		// .slider-image {
		//   .phone-slider {
		//     display: block;
		//     max-width: 260px;
		//     margin-right: auto;
		//     margin-left: auto;
		//     margin-bottom: 10px;
		//     position: relative;
		//     left: -25px;
		//     top: -17px;
		//   }
		// }
	}
}

@media screen and (max-width: 320px) {
	.section-how-to-use {
		min-height: 835px; // .phone-base {
		//   display: block;
		//   margin-right: auto;
		//   margin-left: auto;
		//   top: 50px;
		//   left: -55px;
		//   margin-top: 40px;
		//   position: relative;
		// }
		// .slider-image {
		//   .phone-slider {
		//     display: block;
		//     max-width: 260px;
		//     margin-right: auto;
		//     margin-left: auto;
		//     margin-bottom: 10px;
		//     position: relative;
		//     left: 0;
		//     top: -17px;
		//   }
		//   .slick-list {
		//       position: relative;
		//       left: -210px;
		//       top: -6px;
		//   }
		// }
	}
}
