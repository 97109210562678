.how-it-works--wrapper {
  background: #f8f8f8;
  margin: 80px 0;

  .section--title {
  	font-size: 18px;
  	font-weight: bold;
  	text-transform: uppercase;
  	color: #48af4a;
    left: -50px;
    line-height: 25px;
    position: relative;
    margin-left: 3em;
    @media screen and (max-width: 35.5em) {
      margin-bottom: 0px;
    }
  }
  .section--title:before {
    content: "";
    position: absolute;
    left: -5em;
    top: 0.6em;
    padding-right: 45px;
    height: 3px;
    margin: 0;
    background-color: $gojek;
    z-index: 10;
  }

  .container {
  	padding: 100px 0;

  	.how--title {
  		font-family: 'Lato', sans-serif;
			font-size: 36px;
			font-weight: 300;
			line-height: 1.22;
			letter-spacing: 2px;
			color: #343b41;
      margin:0px;
  	}
  	.how--desc {
  		font-family: 'Open Sans', sans-serif;
			font-size: 16px;
			font-weight: 300;
			line-height: 1.62;
			letter-spacing: 1.5px;
			color: #485460;
  	}
  }

  .layer {
  	background: #ffffff;
    padding: 30px 60px 140px;

    .rect--primary {
    	background: #48af4a;
    	left: -125px;

    	.img--wrapper {
    		left: 0;
    	}
      img
      {
        float: left;
        @media screen and (max-width: 768px) {
          width: 100%;
        }
      }
    }
    .rect--secondary {
	    left: 63%;
  		background: #e8ebf2;
      img
      {
        float: right;
        @media screen and (max-width: 768px) {
          width: 100%;
        }
      }
    }
    .rect {
	    padding: 40px 0;
	    width: 310px;
	    height: 450px;
	    position: relative;
    }
    .desc--pad {
    	padding: 90px 0 70px;
    }
    .odd--row, .even--row
    {
      margin-bottom: 100px;
      @media screen and (max-width: 768px) {
        margin-bottom: 30px;
      }
    }
    .odd--row .desc--pad {
      padding: 0px 50px 0px 0px;
      display: flex;
      flex-direction: column;
      align-self: center;
    }
    .even--row .desc--pad {
      padding: 0px 0px 0px 50px;
      display: flex;
      align-self: center;
      flex-direction: column;
    }
  }

	figure.img--wrapper {
    margin: 0;
    width: 200%;
    position: absolute;
    right: 0;
    height: 360px;
    overflow: hidden;

		img {
			width: auto;
      position: relative;
		}
	}
  figure.hand-ss {
    background: #fff;

    img {
        max-height: 27em;
        width: auto;
        right: 0;
        margin: 0 auto;
        position: absolute;
        left: 0;
    }
  }
}

@media (max-width: 768px) {
  .how-it-works--wrapper {
    margin: 0;

    .container {
      padding: 0;
    }

    .layer {
      padding: 0 15px;

      .desc--pad {
        padding: 30px 30px 40px 30px !important;
        padding-right: 30px!important;
        background: #fff;
        position: relative;
        bottom: 0;
        left: -15px;
        margin-left: 0;
        margin-right: 40px;
        margin-top: 150px;
        width: 85%;

        .how--title {
          font-size: 24px;
        }
        .how--desc {
          font-size: 12px;
        }
      }

      .rect {
        left: 0;
        height: 100%;
        padding: 0;
        width: 100%;

        .img--wrapper {
          width: 100%;
          height: 250px;

          img {
            margin: 0 !important;
          }
        }
      }
    }
    .mobile--wrapper {
      // height: 450px;
      margin: 0 -25px;
      position: relative;

      [class*='pure-u'] {
        width: 100%;
        left: 0;
      }
    }
    .mobile--wrapper:nth-child(2) {
      .img--wrapper img {
        top: 25px;
      }
    }

    .section--title:before {
      left: -3em !important;
      top: .3em !important;
    }
  }
}
