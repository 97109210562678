#govideo {
	.cat-desktop {
		display: block;
	}
	.cat-mobile {
		display: none;

		select {
			text-transform: none;
			width: 100% !important;
			border: 1px solid rgb(200, 200, 200);
			border-radius: 0;
			background: white;
			padding: 10px;
			display: block;
		}

		i {
			float: right;
	    margin-top: -30px;
	    position: relative;
	    left: -12px;
		}
	}
	@-webkit-keyframes uil-ring-anim {
		0% {
			-webkit-transform: rotate(0deg);
			transform: rotate(0deg);
		}
		100% {
			-webkit-transform: rotate(360deg);
			transform: rotate(360deg);
		}
	}
	@-moz-keyframes uil-ring-anim {
		0% {
			-webkit-transform: rotate(0deg);
			transform: rotate(0deg);
		}
		100% {
			-webkit-transform: rotate(360deg);
			transform: rotate(360deg);
		}
	}
	@-webkit-keyframes uil-ring-anim {
		0% {
			-webkit-transform: rotate(0deg);
			transform: rotate(0deg);
		}
		100% {
			-webkit-transform: rotate(360deg);
			transform: rotate(360deg);
		}
	}
	@-o-keyframes uil-ring-anim {
		0% {
			-webkit-transform: rotate(0deg);
			transform: rotate(0deg);
		}
		100% {
			-webkit-transform: rotate(360deg);
			transform: rotate(360deg);
		}
	}
	@keyframes uil-ring-anim {
		0% {
			-webkit-transform: rotate(0deg);
			transform: rotate(0deg);
		}
		100% {
			-webkit-transform: rotate(360deg);
			transform: rotate(360deg);
		}
	}
	.loader {
		position: absolute;
    margin-bottom: -200px;
    display: none;
    z-index: 99;
    background: rgba(250, 250, 250, .8);
    height: 100%;
    width: 100%;
    margin-top: -15px;

		.uil-ring-css {
			background: none;
			position: relative;
			width: 200px;
			height: 200px;
			margin: 0 auto;
			display: block;
		}
		.uil-ring-css>div {
			position: absolute;
	    display: block;
	    width: 160px;
	    height: 160px;
	    top: 80%;
	    transform: translateY(100%);
	    left: 20px;
	    border-radius: 80px;
	    box-shadow: 0 5px 0 0 #ebb329;
	    -webkit-transform-origin: 80px 81.5px;
	    transform-origin: 80px 81.5px;
	    -webkit-animation: uil-ring-anim 1s linear infinite;
	    animation: uil-ring-anim 1s linear infinite;
		}
	}
	.container-action-govideo
	{
		left: 0;
		margin: 6% 12%;
	    bottom: 0;
	    position: absolute;
	    img
	    {
	    	max-width: 100%;
	    	max-width: 450px;
	    	width: 90%;
	    	@media screen and (max-width: 768px) {
	    		margin-bottom: 20px;
	    		width: 70%;
	    	}
	    }
	    button
	    {
	    	margin-top: 30px;
    		padding: 15px 35px;
    		background: #ecb42c;
    		border-radius: 5px;
    		color: #ffffff;
    		transition: all 0.5s;
    		@media screen and (max-width: 767px) {
    			position: absolute;
    			margin-top: -3px;
    			    width: 215px;
				    margin: auto;
				    left: 0;
				    right: 0;
				    bottom: -32px;
    		}
    		@media screen and (min-width: 769px) {
    			&:hover
    			{
    				transition: all 0.5s;
    				background: #ffffff;
    				color: #ecb42c;
    				box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.19);
    			}
    		}
	    }
	    @media screen and (max-width: 768px) {
		    margin: 5% 3%;
		    bottom: 0;
		    position: absolute;
		}
	}
	.hamburger-mobile
	{
		top: 23px;
	}
	.navbar {
		.brand {
			top: 4px;
    		position: relative;
		}
	}
	/*submenu navbar */
		.career-menu {
		background-color: #343b41;
		ul {
			background-color: #343b41;
			list-style: none;
			margin: 0;
			padding: 0;
			overflow: hidden;
			display: block !important;
			justify-content: center;
			li {
				a {
					display: block;
					color: white;
					text-align: center;
					padding: 16px;
					text-decoration: none;
					letter-spacing: 1px;
					top: 0;
					margin: 0;
				}
				a.career-tab {
					background: #8cc63f;
					padding: 16px 26px;
					margin: 0;
				}
				a.active:after
				{
					transform: none;
					bottom: 0;
					top: auto;
					height: 4px;
				}
			}
			li.active a:not(.career-tab):after,
			li a:not(.career-tab):hover:after {
		    content: "";
		    width: 100%;
		    position: absolute;
		    bottom: 0;
		    height: 4px;
		    left: 0;
		    background: #48af4a;
		  }
		}
		.career-tab {
			background-color: #8cc63f;
			display: flex;
			flex-direction: column;
			justify-content: center;
			a {
				text-decoration: none;
				display: block;
				color: #ffffff;
			}
		}
	}
	.sub-menu,.container-sub-menu
	{
		display: none;
	}
	@media screen and (max-width: 48em) {
		#main--hero
		{
			margin-top: 60px;
			min-height: unset;
		}
		.career-menu {
			display: none;
		}
		.sub-menu,.container-sub-menu
		{
			display: block;
		}
		.navbar
		{
			min-height: unset;
	    	height: 60px;
			.brand
			{
				img
				{
					width:100% !important;
					max-width: 30px;
				}
			}
			.wrapper
			{
				padding: 11px 0;
				height: auto;
			}
			.hamburger
			{
				top: 13px;
			}
			/* extra sub menu */
			.sub-menu
			{
				cursor: pointer;
				position: absolute;
				padding:0 15px;
				height:100%;
				margin-left: 15px;
				top:0;
				color: white;
				font-size: 14px;
				background: #84bf41;
				.table-display
				{
					display: table;
					width: 100%;
					height: 100%;
					.mid-table
					{
						width: 100%;
						text-align: center;
						display: table-cell;
						vertical-align: middle;
						i#iconDownSubmenu
						{
							transition: all 0.5s;
							margin-left: 5px;
						}
						i#iconDownSubmenu.active
						{
							transition: all 0.5s;
							transform: rotate(180deg);
						}
					}
				}

			}
		}
		.container-sub-menu
		{
			display: block;
			z-index: 11;
			transition: all 0.5s;
			position: fixed;
			height: 100%;
			width: 100%;
			opacity: 0;
			pointer-events: none;
			background: rgba(0,0,0,0.5);
			.content-sub-menu
			{
				width: 100%;
				background: #84bf41;
				transform: translateY(-100%);
				transition: all 0.5s;
			}
			.content-sub-menu.active
			{
				transition: all 0.5s 0.2s;
				transform: translateY(0%);
			}
			ul.list-content
			{
				margin:0px;
				background: #84bf41;
				padding-left: 0px;
				padding-top: 60px;
				li
				{
					list-style-type: none;
					padding: 15px 20px;
					a
					{
						line-height: normal;
						font-size: 14px;
						color: white;
						letter-spacing: normal;
					}
				}
				li.active
				{
					background: #2c822e;
				}
			}
		}
		.container-sub-menu.active
		{
			opacity: 1;
			transition:all 0.5s;
			pointer-events: visible;
		}
		.navbar.navbar-active.no--active
		{
			top: 0px;
		}
		.g-recaptcha
		{
			transform: none;
			left: auto;
		}
	}
	@media screen and (min-width: 769px) {
		.navbar.no--active
		{
			transform: translateY(-84px);
		}
	}
	/*ending submenu-navbar */
	.menu ul li
	{
		position: relative;
	}

	.hide {
		display: none !important;
	}
	header.participant {
		background-size: cover !important;
		background-repeat: no-repeat !important;
		background-position: center !important;
		height: 600px;

		.video-wrapper {
			display: none;
			width: 100% !important;
	    position: absolute;
	    margin-top: -100px;

			iframe.video {
				height: 600px;
			}
		}
	}
	.play {
		overflow: hidden;
		transition: all 0.5s;
		position: absolute;
		margin: auto;
		top: 0;
		left: 0;
		cursor: pointer;
		right: 0;
		bottom: 0;
		width: 90px;
		height: 90px;
		border-radius: 50%;
		object-fit: contain;
		background-color: #ffcd2e;
		box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.24);
		@media screen and (min-width: 769px) {
			&::after
			{
				position: absolute;
				width: 80%;
				text-align: center;
				content: 'Play Video';
				font-weight: 700;
				color: #ffcd2e;
				height: 36px;
				letter-spacing: normal !important;
				right: 0;
				line-height: normal !important;
				left: 0;
				top: 0;
				bottom: 0;
				height: 30px;
				margin: auto;
				transition: all 1s;
				transform: translateX(-100px);
			}
			&:hover
			{
				background:#ffffff;
				img
				{
					transform: scale(9);
				}
				&::after
				{
					transform: translateX(0px);
				}
			}
		}
		img {
			transition: all 0.5s;
			margin: auto;
			position: absolute;
			top: 0;
			left: 0;
			bottom: 0;
			right: 0;
		}
	}
	header {
		overflow: visible;
		.desc--wrapper {
			.desc--row {
				.bottom {
			    font-weight: bold;
				}

				h1.hero--title {
					object-fit: contain;
					font-family: $lato;
					font-size: 40px;
					line-height: 1.41;
					letter-spacing: 4.5px;
					margin-bottom: 65px !important;

					span
					{
						display: inline-block;
					}
					div.bottom
					{
						margin-top: 20px;
					}
				}

				h1.hero--title.second {
			    margin-top: 200px;
					font-size: 52px;
				}
				a.hero--button {
					color: white;
					background-color: $govideo;
					text-transform: uppercase;
					border-radius: 5px;
					padding: 22.5px 20px;
	    			-webkit-transition: all .3s ease-in-out;
	    			-moz-transition: all .3s ease-in-out;
	    			-o-transition: all .3s ease-in-out;
	    			transition: all .3s ease-in-out;
				}
				a.hero--button:hover {
			    box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.19);
			    color: $govideo;
			    background: #fff;
				}
			}
		}
	}

	/* Global UX */
	.scale--hover {
		transition: all .3s ease-in-out;
    	transform: scale(1);
    	transform-origin: center;
	}
	.scale--hover:hover {
		@media screen and (min-width: 769px) {
			transform: scale(1.1);
		}
	}

	/* Form */
	form {
		.err--message {
	    color: red;
	    margin: 0;
	    font-style: italic;
	    font-size: 0.875em;
	    font-weight: 300;
	    line-height: 1.62em;
	    letter-spacing: 1.5px;
	    font-family: "Open Sans", Helvetica, sans-serif;
		}
		.err--message.poster {
	    text-align: center;
	    display: block;
	    margin-top: 10px;
		}
		.poster.error .item,
		.error .form--field {
			border-color: red !important;
	    border-width: 2px !important;
		}
	}

	.float-arrow
	{
		position: absolute;
		height: 50px;
		width: 50px;
		top:18%;
		bottom:0;
		right: auto;
		left: auto;
		z-index: 10;
	}
	.float-arrow.arrow-right{
		right: 0;
	}
	.float-arrow.arrow-left{
		left: 0;
	}
	.float-arrow.slick-disabled svg ellipse, .float-arrow.slick-disabled svg polygon{
		stroke: #9d9d9d !important;
	}
	.float-arrow.slick-disabled svg polygon{
		fill: #9d9d9d !important;
	}
	section.support
	{
		padding-bottom: 100px;
		text-align: center;
		.logo
		{
			div.container-logo
			{
				text-align: left;
				display: inline-block;
				margin: 15px;
				img
					{
						max-height: 70px;
				}
			}
		}
	}
	section.how-it-works--wrapper {
		margin-bottom: -60px;

		img {
			background-image: none !important;
		}

		figure.img--wrapper {
			height: 100% !important;

			img {
				top: 0 !important;
			}
		}

		[data-video=poster] {
			width: 100% !important;
		}

		.layer {
			padding: 30px 60px 0px !important;

			.play {
				z-index: 9;

				img {
			    top: 0px !important;
				}
			}

			.video-wrapper {
				display: none;
				height: 100%;
			}

			.video {
				margin: 0;
				height: 100%;
			}

			.how--title {
				font-family: $opensans;
				font-size: 16px;
				font-weight: bold;
				line-height: 0.88;
				text-transform: uppercase;
				letter-spacing: 1.7px;
				color: #009444;

				span {
					position: relative;
					top: -15px;
					margin-left: 10px;
				}
			}

			.how--title[data-category="Music Video"] {
				&:before {
					background: url(https://bilba.go-jek.com/dist/img/page/govideo/category/music.svg);
					content: '';
					display: inline-block;
					position: relative;
					height: 50px;
					width: 50px;
					background-size: 50px 50px;
					background-repeat: no-repeat;
				}
			}
			.how--title[data-category="Drama"] {
				&:before {
					background: url(https://bilba.go-jek.com/dist/img/page/govideo/category/short.svg);
					content: '';
					display: inline-block;
					position: relative;
					height: 50px;
					width: 50px;
					background-size: 50px 50px;
					background-repeat: no-repeat;
				}
			}
			.how--title[data-category="Comedy"] {
				&:before {
					background: url(https://bilba.go-jek.com/dist/img/page/govideo/category/comedy.svg);
					content: '';
					display: inline-block;
					position: relative;
					height: 50px;
					width: 50px;
					background-size: 50px 50px;
					background-repeat: no-repeat;
				}
			}
			.how--title[data-category="Animation"] {
				&:before {
					background: url(https://bilba.go-jek.com/dist/img/page/govideo/category/animation.svg);
					content: '';
					display: inline-block;
					position: relative;
					height: 50px;
					width: 50px;
					background-size: 50px 50px;
					background-repeat: no-repeat;
				}
			}
			.how--title[data-category="Documentary"] {
				&:before {
					background: url(https://bilba.go-jek.com/dist/img/page/govideo/category/doc.svg);
					content: '';
					display: inline-block;
					position: relative;
					height: 50px;
					width: 50px;
					background-size: 50px 50px;
					background-repeat: no-repeat;
				}
			}

			[data-video=info] {
				font-family: $opensans;
				font-size: 14px;
				font-weight: bold;
				line-height: 1.0;
				text-transform: uppercase;
				letter-spacing: 1.5px;
			}

			[data-video=desc] {
				font-family: $opensans;
				font-size: 16px;
				font-weight: 300;
				line-height: 1.62;
				letter-spacing: 0.6px;
				margin-top: -20px;
				padding: 0;
			}

			[data-video=title] {
				font-family: $opensans;
			  font-size: 26px;
			  font-weight: bold;
			  line-height: 1.29;
			  letter-spacing: 1.1px;
			  color: #000000;
			}

			[data-display=desktop] {
				display: block;
			}
			[data-display=mobile] {
				display: none;
			}

			.rect--secondary,
			.rect--primary {
		    background: transparent !important;
			}

			.btn-all {
				object-fit: contain;
		    border-radius: 5px;
		    background-color: #ebb329;
		    text-align: center;
		    margin: 0 auto;
		    padding: 30px 25px;
		    color: #fff;
				position: relative;
		    z-index: 9;
			}
		}
	}

	@media only screen and (max-width: 35.5em) {
		section.how-it-works--wrapper.home {
			margin-bottom: 0px;

			figure.img--wrapper {
				height: 82% !important;

				img {
					transform: scale(1.35);
				}
			}

			.play {
		    transform: scale(.5);

				img {
					left: 0;
			    width: 30px;
			    position: absolute;
			    right: 0;
			    bottom: 0;
			    margin: auto !important;
				}
			}

			.btn-all {
				top: 65px;
			}

			[data-display=desktop] {
				display: none !important;
			}
			[data-display=mobile] {
				display: inline-block !important;
			}

			[data-video=title] {
				margin-bottom: 0;
				margin-top: 0px;
				font-size: 24px;
				margin-bottom: -12.5px;
			}

			[data-video=title].hardcode {
				margin-top: 12.5px;
			}

			.featured-img {
				max-width: 30px !important;
		    margin-top: -10px;
			}
			.featured-copy {
				margin-left: 45px !important;
			}

			.how--title {
				margin-top: -10px !important;
				margin-bottom: -5px;
			}

			.layer {
				margin-top: 0 !important;
				padding: 30px 25px 60px !important;

				.desc--pad {
					padding: 0px 25px !important;
					margin-top: -10px;
			    margin-bottom: -40px;
				}

				.video {
					height: 250px !important;
				}
			}
		}
	}

	@media only screen and (max-width: 320px) {
		section.how-it-works--wrapper {
			.layer {
				[data-video=title] {
					margin-top: 0px;
					margin-bottom: -12.5px;
				}
				[data-video=title].hardcode {
					margin-top: 12.5px;
				}

				.how--title {
					span {
						font-size: 14.5px;
					}
				}
			}
		}
	}

	section.services {
		padding-bottom: 200px !important;

		.container {
			&:before {
				background-color: transparent;
			}
		}

		h4.title {
			position: relative;
	    top: 15px;
			left: -10px;
			padding-left: 0 !important;
			font-size: 18px;
			font-weight: bold;
			line-height: 0.78;
			letter-spacing: 1.9px;
			color: #4a4a4a;
	    margin-bottom: 20px;
			line-height: 25px;

			&:before {
				content: url(https://d3naj63yelf4gk.cloudfront.net/dist/img/line.svg);
		    position: relative;
		    left: -30px;
		    top: -5px;
		    margin-right: -10px;
			}
		}

		.wrapper {
			.service-container {
				background-image: unset !important;
				background-color: #009444;

				.service-list {
					height: 22em !important;

					.img-container {
						background-size: cover !important;
						background-position: center !important;
						background-repeat: no-repeat !important;
						height: 240px;
					}

					a {
						&:after {
							background-image: none !important;
						}
					}

					.text {
						padding: 0px 10px;
						text-align: unset;

						[data-video=category] {
							object-fit: contain;
							font-family: $opensans;
							font-size: 12.5px;
							font-weight: bold;
							line-height: 0.93;
							letter-spacing: 1.6px;
							color: #009444;
						}

						[data-video=title] {
							font-family: $opensans;
							font-size: 15px;
							font-weight: bold;
							line-height: 1.5;
							text-transform: unset;
							letter-spacing: 0.8px;
							margin-top: -27.5px;
							margin-left: -15px;
							color: $black;
						}

						[data-video=name] {
							font-family: $opensans;
							font-size: 13px;
							font-weight: unset;
							margin-top: 0;
							line-height: 1.87;
							letter-spacing: 0.6px;
							color: #babcbf;
							margin-top: -30px;
							margin-bottom: 0px;
							opacity: .8;
						}
					}
				}
			}
		}
	}
	section.video {
		.filter {
			margin-top: -30px;
			position: sticky;
			top: 150px;
		}
		.filter,
		.detail {
			background: white;
			width: 100%;
			padding: 5px 20px;
			border-top: 6px solid #ebb329;

			h4 {
				object-fit: contain;
				font-family: $opensans;
				font-size: 18px;
				font-weight: bold;
				line-height: 0.78;
				letter-spacing: 1.9px;
				color: #4a4a4a;
				margin-left: -10px;

				&:before {
					content: url(https://d3naj63yelf4gk.cloudfront.net/dist/img/line.svg);
			    position: relative;
			    left: -30px;
			    top: -5px;
				}
			}


			h4.breadcrumb {
				font-size: 12px;
				font-weight: bold;
				line-height: 1.5;
				letter-spacing: 1.3px;
				color: #b3b9c7;
				margin-top: 35px;
				text-transform: uppercase;
				position: relative;
				margin-left: 10px;

				a {
					font-size: 12px;
					font-weight: bold;
					line-height: 1.17;
					letter-spacing: 1.3px;
					color: #b3b9c7;
					margin-top: 35px;
				}

				&:before {
					content: url(https://d3naj63yelf4gk.cloudfront.net/dist/img/line.svg);
					position: absolute;
			    left: -60px;
			    top: -2.5px;
			    margin-right: 0;
				}
			}

			.wrapper {
				padding: 0px 27px;
				margin-bottom: 50px;

				h1 {
					object-fit: contain;
					font-family: $lato;
					font-size: 36px;
					font-weight: 300;
					line-height: 1.28;
					letter-spacing: 2px;
					color: #343b41;
				}

				.group-wrapper {
					margin-top: 40px;

					.group {
						margin-bottom: 25px;

						label {
							font-family: $opensans;
							font-size: 14px;
							font-weight: bold;
							line-height: 1.0;
							letter-spacing: 1.5px;
							text-transform: uppercase;
						}

						p {
							margin-top: 5px;
						}
					}
				}
			}

			h5 {
				object-fit: contain;
				font-family: $opensans;
				font-size: 14px;
				line-height: 1.71;
				letter-spacing: 1.2px;
				color: #343b41;
				text-transform: uppercase;
				margin-top: 30px;
				font-weight: 400;
			}

			form {
				margin-top: -15px;
				margin-bottom: 20px;

				label {
					cursor: pointer;

					input[type=radio] {
						margin: 10px 0;
						background: transparent;
						width: 20px;
						height: 20px;
						-webkit-appearance: none;
						border-radius: 50%;
						position: relative;
						top: 17.5px;
						margin-right: 5px;
						border: 1px solid #dbdbdb;
					}

					input[type=radio]:checked {
						-webkit-appearance: none;
						border: 1px solid #009444;
						outline: none;
					}

					input[type="radio"]:checked:after {
				    content: '';
				    position: absolute;
				    width: 10px;
				    height: 10px;
				    background: #009444;
				    border-radius: 100%;
				    top: 50%;
				    left: 50%;
				    transform: translate(-50%, -50%);
					}

					span {
						margin-left: 5px;
						position: relative;
						top: 1.5px;
					}
				}
			}
		}

		.participant {
			margin-top: -15px;
			margin-bottom: 50px;
			margin-left: 20px;
			position: relative;
		}
	}
	.list.participant {
		transition: all 0.3s ease 0s;
		text-align: left !important;
		background-color: white;
		margin: 25px 10px;

		.image {
			min-height: 245px;
			position: relative;
			background-size: cover !important;
			background-position: center !important;

			img {
				position: absolute;
		    top: 50%;
		    left: 50%;
		    max-height: 100%;
		    -webkit-transform: translate(-50%,-50%);
		    transform: translate(-50%,-50%);
			}
		}

		.text {
			padding: 0px 25px;
			text-align: unset;

			[data-video=category] {
				object-fit: contain;
				font-family: $opensans;
				font-size: 12.5px;
				font-weight: bold;
				line-height: 0.93;
				letter-spacing: 1.6px;
				color: #009444;
			}

			[data-video=title] {
				font-family: $opensans;
				font-size: 15px;
				font-weight: bold;
				line-height: 1.5;
				text-transform: unset;
				letter-spacing: 0.8px;
				margin-top: -8.5px;
				color: $black;
			}

			[data-video=name] {
				font-family: $opensans;
				font-size: 13px;
				font-weight: unset;
				margin-top: 0;
				line-height: 1.87;
				letter-spacing: 0.6px;
				color: #babcbf;
				margin-top: -15px;
				opacity: .8;
			}
		}

		&:hover {
			box-shadow: 5px 5px 5px rgba(0,0,0,.095);
		}
	}
	section.participant {
		padding: 20px;

		h4 {
			color: #48af4a;
			text-transform: uppercase;

			.title {
				float: left;
			}

			.show-more {
				color: inherit;
				float: right;

				svg {
					position: relative;
					top: 2px;
					margin-left: 5px;
				}
			}
		}

		span.separator {
			display: none !important;
		}

		h4.show-more-mobile {
			display: none !important;
		}

		.slick-disabled {
			filter: grayscale(100%);
		}

		.navigation {
			display: none;
		}

		.video-list {
			text-align: center;
			margin-top: 30px;
		}
	}
	#content-only {
		padding: 0;

		.container {
			box-shadow: 0 -3em 0 1.5em #343b41;

			.info {
				h4 {
					color: #343b41;
				}

				.line {
					background: #ecb42c;
				}
			}
		}
	}
	section.snk-govideo
	{
		font-family: Open Sans,Helvetica,sans-serif;
		background-color: #f8f8f8;
		margin-top: 80px;
		.container
		{
			width:90%;
			max-width: 1170px;
			padding: 100px 0;
			.option
			{
				border-top: 3px solid #009444;
				h2.title
				{
					margin: 50px 0;
					font-weight:300;
					span
					{
						color:#58c75b;
					}
				}
				ul
				{
					padding-left: 0px;
					margin: 0px;
					list-style-type: none;
					li
					{
						font-family: Open Sans,Helvetica,sans-serif;
						position: relative;
						cursor: pointer;
						padding-left: 70px;
						margin-bottom: 30px;
					}
					li.active::before
					{
						content: "";
						background: #58c75b;
						position: absolute;
						width: 50px;
						top: 45%;
						left: 0px;
						height: 2px;
					}
					li.active
					{
						color: #58c75b;
					}

				}
			}
			.content
			{
				font-family: Open Sans,Helvetica,sans-serif;
				color: #8c8c8c;
				line-height: 25px;
				display: none;
				ol
				{
					padding-left: 20px;
					margin: 0px;
					line-height: 25px;
				}
				a
				{
					letter-spacing: normal;
					color: #58c75b;
					font-size: inherit;
				}
				b
				{
					color: #2b2b2b;
				}
				.penghargaan
				{
					margin-bottom: 50px;
					h4,p
					{
						margin:0px;
					}
				}
			}
			.content.active
			{
				display: block;
			}
			.back
			{
				display: none;
				h3
				{
					text-align: center;
					margin-top:50px;
					color: #48af4a;
				}
				a
				{
					font-size: inherit;
				}
			}
		}
	}
	section.about {
		background-color: #f8f8f8;
		.img-wrapper {
			margin-top: -80px;
		}
		.container .pure-g .img-wrapper .img-container
		{

			background-color: transparent;
		}
		.img-wrapper .img-container
		{
			margin-top: 0px;
			width:100%;
			margin-left: 0px;
		}
		.down-photo
		{
			width: 50%;
			float:left;
			img
			{
				width: 100%;
			}
		}
		img.pure-img
		{
			height: auto;
		}

	}
	section.section-our-values {
		text-align:center;
		.container-arrow-judges
		{
			display: none;
			svg:first-child
			{
				float:left;
			}
			svg:last-child
			{
				float: right;
			}
		}
		.slide-container
		{
			a
			{
				font-size: inherit;
				color: inherit;
				svg
				{
					display: inline-block;
					width: 16px;
				}
			}
		}
		background-color: #FFF;
		.title {
			color: #4a494a;
		}
		.container-arrow-judges svg.slick-disabled ellipse, .container-arrow-judges svg.slick-disabled polygon
		{
			stroke: #9d9d9d !important;
		}
		.container-arrow-judges svg.slick-disabled polygon
		{
			fill: #9d9d9d !important;
		}
		.line.gradient {
			background-color: $govideo;
		}
		.categories {
			display: inline-block;
			margin-bottom: 100px;
			width:100%;
			h5.more
			{
				color: #019447;
				display: none;
		    text-transform: uppercase;
			}

			.description-wrapper {
				img {
					width:85%;
					max-width: 840px;
					margin: 50px auto;
					display: block;
				}
			}
			.category {
				img {
					margin: auto;
					width: 100px;
					height: 100px;
				}
				h4 {
					margin-top: 50px;
					text-align: center;
					font-weight: 600;
					line-height: 1.42;
					letter-spacing: 2.2px;
					text-align: center;
					color: #000000;
				}
			}
		}
		.judges {
			.list {
				padding: 30px;
				h4 {
					text-align: left;
					float: none;
					text-transform: none;
					@media screen and (max-width: 768px) {
						padding: 0px;
					}
				}
				h5 {
					font-family: $opensans;
					font-size: 18px;
					font-weight: 300;
					line-height: 1.56;
					letter-spacing: 1.7px;
					color: #485460;
					text-align: left;
					float: none;
					margin-top: -15px;
				}
				img {
					max-width: none;
				}
				img.pure-img {
					width: 100%;
				}
			}
		}
	}
	section.timeline {
		position: relative;
		padding: 100px 0;
		padding-bottom: 150px;
		.title {
			h4 {
				font-family: $opensans;
				background: #009444;
				padding: 30px;
				color: #ffffff;
				line-height: 0.78;
				letter-spacing: 1.9px;
				position: relative;
				padding-left: 50px;
				font-weight: 500;
				text-transform: uppercase;
			}
			h4::before {
				content: "";
				background-color: #ecb42c;
				position: absolute;
				width: 50px;
				height: 4px;
				top: 50%;
				left: -25px;
			}
		}
		ul {
			list-style: none;
			margin: 0 auto;
			padding: 0;
			margin-top: 100px;
			li {
				width: 25%;
				float: left;
				position: relative;
				.wrapper {
					height: 180px;
					width: 250px;
					margin: 0 5px;
					padding: 15px 15px;
					div {
						min-height: 200px;
						background: #ffffff;
						padding: 15px;
						border-bottom: 4px solid #dbdbdb;
						h4 {
							line-height: 1.42;
							letter-spacing: 2.2px;
							text-align: center;
							color: #000000;
							font-weight: 500;
							height: 40px;
						}
						h4:first-child {
							font-weight: bold;
						}
					}
				}
			}
			li.done {
				.wrapper {
					div {
						border-bottom: 4px solid #009444;
					}
				}
			}
			li.done:before {
				content: "";
				position: absolute;
				font-size: 13px;
				font-weight: 400;
				margin-left: -47px;
				height: 10px;
				width: 10px;
				border-radius: 50%;
				text-align: center;
				line-height: 27px;
				z-index: 1;
				background: #ffffff;
				border: 5px solid #009444;
			}
			li.done:after {
				content: "";
				position: absolute;
				z-index: 0;
				background: #009444;
				width: 100%;
				height: 3px;
				bottom: 0;
			}
			li:before {
				content: "";
				position: absolute;
				font-size: 13px;
				font-weight: 400;
				margin-left: -47px;
				height: 10px;
				width: 10px;
				border-radius: 50%;
				text-align: center;
				line-height: 27px;
				z-index: 1;
				background: #ffffff;
				top: -50px;
				left: 65%;
				border: 5px solid #dbdbdb;
			}
			li:after {
				content: "";
				position: absolute;
				z-index: 0;
				background: #dbdbdb;
				width: 100%;
				height: 3px;
				bottom: 0;
				top: -43.5px;
				left: -50%;
			}
			li:first-child:after {
				content: none;
			}
		}
		.extra-notes
		{
			font-weight:700;
			position: absolute;
			right: 0;
			bottom: 50px;
		    text-align: right;
		    width: 995px;
		    left: 0;
		    right: 0;
		    margin: auto;
		    @media screen and (max-width: 768px) {
		    	font-size: 12px;
		    	width: 100%;
		    	box-sizing: border-box;
		    	padding-right: 40px;
		    }
		}
		@media screen and (max-width: 768px) {
		    padding-bottom: 100px;
		}
	}
	section.how-to {
		background-color: #ecb42c;
		.nav-down.slick-arrow.slick-disabled ellipse, .nav-down.slick-arrow.slick-disabled polygon, .nav-up.slick-arrow.slick-disabled ellipse, .nav-up.slick-arrow.slick-disabled polygon
		{
			stroke: #8a8a8a !important;
		}
		.nav-down.slick-arrow.slick-disabled polygon, .nav-up.slick-arrow.slick-disabled polygon
		{
			fill: #8a8a8a !important;
		}
		.slick-arrow:not(.slick-disabled) {
			cursor: pointer;
		}
		.out-wrapper {
			.wrapper {
				padding: 20px 0;
				color: #ffffff;
				.title {
					h4 {
						letter-spacing: 1.7px;
						font-family: $opensans;
						text-transform: uppercase;
						padding: 0 40px;
						font-size: 14px;

						position: relative;
					}
					h4::before {
						content: "";
						background: #ffffff;
						position: absolute;
						width: 50px;
						top: 45%;
						left: -25px;
						height: 4px;
					}
				}
				.slide {
					padding: 70px 0;
					.dots-slide {
						margin: auto;
						display: flex;
						position: relative;
						ul {
							z-index: 999;
							margin: auto;
							padding: 0;
							li {
								float: none;
								display: block;
								margin: auto;
								button::before {
									color: #ffffff;
								}
							}
						}
					}
					.image-slide {
						picture {
							width: 200px;
							height: 200px;
							outline: none;
							padding: 10px;
							img {
								width: 100%;
								height: 100%;
								margin: auto;
							}
						}
					}
					.text-slide {

						h3 {
							padding: 30px 0;
							display: flex;
							flex-direction: column;
							justify-content: center;
							font-weight: 500;
							line-height: 1.28;
							letter-spacing: 2px;
							outline: none;
						}
					}
					.nav-slide {
						display: flex;
						justify-content: center;
						flex-direction: column;
						margin: auto;
						svg {
							margin: auto;
							margin-bottom: 15px;
						}
					}
				}
			}
		}
	}
	.daftar {
		margin: 100px auto;
		.banner {
			position:relative;
			background-size: cover;
			img.background-banner {
				height: 100%;
			}
			.video-image
			{
				position: absolute;
				left: 0;
				right:0;
				top:0;
				bottom: 0;
				margin: auto;
				height: 70px;
				width:70px;
				img
				{
					width: 100%;
				}
			}

		}
		fieldset
		{
			min-width: 0px;
		}
		.form {
			padding: 50px;
			background: white;
			position: relative;
			box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.19);
			.title {

				position: relative;
				letter-spacing: 1.5px;
				color : rgb(186, 188, 191);
				font-size: 20px;
				font-weight: 700;
				p
				{
					font-weight: 700;
				}
			}
			label {
				font-family: $opensans;
				margin-bottom: 1em;
				letter-spacing: 1.5px;
				color: #697b8c;
				font-weight: 700;
				margin: 0;
				text-transform: uppercase;
				cursor: pointer;
			}
			textarea {
				resize: none;
				width: 100%;
				height: 234px;
			}
			input[type=text],input[type=email],input[type=number],
			select,
			textarea {
				color: #2b2b2b;
				position: relative;
				border: 1px solid #c3c9d7;
				width: 100%;
				border-radius: 0;
				box-shadow: none;
				-webkit-appearance: none;
			}
			input[type=number]::-webkit-inner-spin-button,
			input[type=number]::-webkit-outer-spin-button {
			    -webkit-appearance: none;
			    -moz-appearance: none;
			    appearance: none;
			    margin: 0;
			}
			.arrow-select
			{
				pointer-events: none;
				position: absolute;
				width: 0;
				height: 0;
				border-left: 7px solid transparent;
				border-right: 7px solid transparent;
				border-top: 10px solid #2b2b2b;
				bottom: 0;
		    right: 10px;
		    top: 40px;
			}
			.divident {
				margin-top: 25px;
			}
			.poster {
				p:first-child {
					text-align: center;
				}
				p {
					color: #babcbf;
				}
				.item {
					position: relative;
					border: 1px solid #c3c9d7;
					padding: 5em;
					margin-top: 4px;
					button {
						display: block;
						margin: auto;
						border-radius: 5px;
						background-color: #ecb42c;
						padding: 1.5em 3em;
						color: white;
						line-height: 1.03;
						letter-spacing: 2.6px;
	    				border: 2px solid rgb(236, 180, 44);
	    				cursor: pointer;
						-webkit-transition: all .3s ease-in-out;
						-moz-transition: all .3s ease-in-out;
						-o-transition: all .3s ease-in-out;
						transition: all .3s ease-in-out;
					}
					button:hover {
						background: #fff;
    					color: rgb(236, 180, 44);
					}
					input
					{
						position: absolute;
						height: 100%;
						width: 100%;
						opacity: 0;
						left: 0;
						top:0;
					}
					#img-preview {
				    width: 200px;
				    margin: 0 auto 30px;
				    display: block;
				    border-radius: 5px;
					}
				}
			}
			.checkbox {
				text-align: left;
				.cb,
				{
					opacity: 0;
					position: absolute;
				}
				.cb,
				.cb-label,
				{
					display: inline-block;
					vertical-align: middle;
					margin: 5px;
					cursor: pointer;
				}
				.cb-label,
				{
					position: relative;
				}
				.cb+.cb-label:before,
				{
					content: '';
					background: #fff;
					border: 2px solid #ddd;
					display: inline-block;
					vertical-align: middle;
					width: 15px;
					height: 15px;
					padding: 2px;
					margin-right: 10px;
					text-align: center;
				}
				.cb:checked+.cb-label:before {
					content: "\f00c";
					font-size: 14px;
					font-family: 'FontAwesome';
					background: white;
					color: #009444;
				}
				label {
					display: inline-block;
					letter-spacing: 1px;
					color: #343b41;
					font-weight: 500;
				}
				span {
						letter-spacing: 0.7px;
						color: #118534;
						font-weight: 700;
				}
				a.link
				{
					font-size: inherit;
				}
				a.link:hover span {
					text-decoration: underline;
				}
			}
			.submit {
				button {
					border-radius: 5px;
			    	background: #333333;
					color: #ffffff;
					float: right;
					position: absolute;
					bottom: -50px;
					right: 50px;
					padding: 35px 100px;
					z-index: 99;
	    			-webkit-transition: all .3s ease-in-out;
	    			-moz-transition: all .3s ease-in-out;
	    			-o-transition: all .3s ease-in-out;
	    			transition: all .3s ease-in-out;
				}
				button:focus {
					outline: none;
				}
				button:hover {
			    box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.19);
					background-color: #009444;
				}
			}
		}
	}
	.video {
		margin: 100px auto;
		padding: 0;

		.link--more {
			-webkit-transition: all .3s ease-in-out;
			-moz-transition: all .3s ease-in-out;
			-o-transition: all .3s ease-in-out;
			transition: all .3s ease-in-out;
		}
		.link--more:hover {
	    background-color: rgb(153, 202, 93);
		}
		.title {
			background: none;
			h4 {
				margin: 0;
				padding: 30px;
				background: white;
				position: relative;
				line-height: 0.78;
				letter-spacing: 1.9px;
				color: #4a4a4a;
			}
			h4:before {
				content: "";
				background: #f7dd89;
				position: absolute;
				height: 4px;
				width: 50px;
				left: -30px;
				top: 45%;
			}
		}

		.title-right
		{
			background: none;
			h4 {
				text-align: right;
				margin: 0;
				padding: 30px;
				background: #ecb42c;
				position: relative;
				line-height: 0.78;
				letter-spacing: 1.9px;
				color: white;
			}

		}

		.img-wrapper {
			margin: 0 !important;
			background: white;
			.img-container {
				padding: 0;
				padding-bottom: 0;
				.img-container-2 {
					overflow: hidden;
					max-height: 718px;
					position: relative;
					margin: 0;
					.play {
						margin: auto;
						-webkit-transition: all .3s ease-in-out;
						-moz-transition: all .3s ease-in-out;
						-o-transition: all .3s ease-in-out;
						transition: all .3s ease-in-out;
					}
					.play:hover {
				    background-color: rgb(153, 202, 93);
					}
					.container-youtube
					{
						position: absolute;
						height: 100%;
						width:100%;
						left: 0;
						top:0;
						background-color: black;
						z-index: 2;
						opacity: 0;
						pointer-events: none;
						transition: all 0.5s;
						.video-close
						{
							z-index: 3;
							position: absolute;
					    color: rgb(255, 255, 255);
					    font-size: 200%;
					    cursor: pointer;
					    width: 60px;
					    height: 60px;
			        	top: 0.5em;
    					right: 0.5em;
					    background: url(../img/card/about/close-btn.png) 50% center / 29px 29px no-repeat rgb(72, 175, 74);
					    border-radius: 50%;
						}
						iframe
						{
							position: absolute;
							height: 100%;
							width:100%;
							left: 0;
							top:0;
							z-index: 2;
							transition: all 0.5s;
						}
					}
					.container-youtube.active
					{
						pointer-events: visible;
						opacity: 1;
						transition: all 0.5;
					}
				}
			}
		}
	}
	section.how-it-works--wrapper
		{
			.rect--secondary
			{
				background: #dbdbdb;
			}
			.mobile--wrapper
			{
				padding-top:100px;
				margin-top: -100px;
			}
			.rect--primary
			{
				background: #009444;
			}
			.section--title
			{
				padding-left: 10px;
				margin-bottom: 50px;
				font-size: 14px;
				color: #b3b9c7;
				a
				{
					font-size:inherit;
					color: inherit;
				}
			}
			figure.img--wrapper
			{
				height: 82%;
				overflow: hidden;
				img
				{
					height:auto;
					top:-20%;
					background: none;
				}
			}
			.layer
			{
				.even--row
				{
					.desc--pad
					{
						padding: 25px;
					}
				}
				.odd--row
				{
					.desc--pad
					{
						padding-right: 0px;
						padding: 25px;
					}
				}
			}
			.ok-video-down
			{
				text-align: center;
				border: 1px solid #ececec;
				background: white;
				img
				{
				    left: 0;
				    height: 100% !important;
				    top: 0% !important;
				    right: 0;
				    margin: auto;
						width: 100%;
				}
			}
		}
		section.how-it-works--wrapper.hadiah-govideo
		{
			figure.img--wrapper
			{
				width:100%;
				height: 100%;
				img
				{
					width: 60%;
				    bottom: 0;
				    top: 0;
				    left: 0;
				    right: 0;
				    margin: auto;
				    height: 100%;
				    height: auto;
				}
			}
			.even--row, .odd--row
			{
				min-height: 330px;
				.pure-u-1
				{
					position: relative;
				}
			}
		}
}

@media screen and(max-width: 768px) {
	#govideo {
		header {
			h1.hero--title.second {
				display: none;
			}
		}

		section.video {
			.participant {
				margin-top: 20px;
	      margin-bottom: 50px;
	      margin-left: 20px;
	      position: relative;
	      left: -15px;
			}
		}
	}
}
@media screen and(min-width: 768px)
{
	#govideo {
		.right-up-photo
		{
			height:292px;
			overflow:hidden;
		}
		.table-switch
		{
			display: table;
		}
		.table-switch > div:first-child
		{
			display: table-footer-group;
		}

		section.section-our-values .judges-desc
		{
			button.float
			{
				display: none;
			}
		}
	}
}

@media screen and (max-width: 768px)
{
	#govideo {
		header {
			.desc--wrapper {
				left: 0%;
				min-height: unset;
				padding-left: 0px;
				text-align: center;
				.desc--row {

					right: 0;
					left: 0;
					bottom:0 !important;
					.sub-desc--row
					{
						vertical-align: bottom;
					}
					h1.hero--title {
						line-height: normal;
						max-width: 300px;
					    letter-spacing: 2px;
					    font-size: 12px;
					    display: block;
					    margin: auto;
					    div.bottom
					    {
					    	margin-top: 0px;
					    }
					}

					.sub-desc--row {
				    padding-bottom: 10%;
					}
				}
			}
		}

		.container-arrow-judges
		{
			display: block !important;
		}
		.slide-container
		{
			width: 100%;
		}

		section.how-it-works--wrapper
		{
			.container
			{
				width: 100%;
			}
			.layer
			{
				padding-top: 30px;
    			margin-top: 60px;
    			.section--title
    			{
    				letter-spacing: normal;
    				left: -15px;
    				line-height: normal;
    			}
    			.desc--pad
    			{
    				margin-top: 0px;
    				h4
    				{
    					line-height: normal;
    				}
    			}
    			.rect
    			{
    				width:50%;
    			}
    			.odd--row
    			{
    				.rect
    				{
    					right:0;
    					float: right;
    					figure
    					{
    						left: -100%;
    					}
    				}
    			}
			}
			figure
			{
				width:200%;
				top:10%;
				position: relative;
				img
				{
					position: relative;
				}
			}
			.ok-video-down
			{
				background: white;
				img
				{
				    top: -15% !important;
				    height: auto !important;
				    max-width: 100%;
				}
			}

		}
		section.how-it-works--wrapper.hadiah-govideo
		{
			figure
			{
				top:0px;
			}
			figure.img--wrapper
			{
				text-align: center;
				position: relative;
				margin: 0;
			    width: 100%;
			    position: relative;
			    right: 0;
			    img
			    {
			    	width:80%;
			    	max-width: 200px;
			    	position: relative;
			    }
			}
			.desc--pad
			{
				margin-top: 0px;
			}
		}
		section.snk-govideo
		{
			margin: 0px;
			margin-top: 85px;
			padding:0px !important;
			.container
			{
				padding:50px 0;
				.option
				{
					width:75%;
				}
				h2.title
				{
					margin: 30px 0 !important;
					font-size: 30px;
				}
			}
			.content
			{
				h4
				{
					font-size: 16px;
					line-height: normal;
					margin-bottom: 5px !important;
				}
			}
		}
		section.timeline {
			ul
			{
				li
				{
					.wrapper
					{
						div
						{
							min-height: inherit;
						}
					}
				}
			}
		}
		section.section-our-values
		{
			width:100%;
			padding: 30px;
			h4
			{
				font-size: 14px;
			}
			h5.more
			{
				display: inline-block !important;
			}
			.categories
			{
				margin-bottom: 30px;
			}
		}
		.judges .title, .categories .title
		{
			position: relative;
			padding-left: 30px;
			text-align: left;
		}
		.judges-desc
		{
			position: relative;
			height: 150px;
			h4
			{
				font-size: 16px !important;
			}
			h5
			{
				font-size: 14px !important;
			}
			button.float
			{
				border-radius: 4px 0px 0 4px;
			    right: 0;
			    padding: 15px 40px;
			    top: -20px;
			    position: absolute;
			    font-size: 12px;
			    font-weight: 300;
			    color: white;
			    background-color: rgb(0, 148, 68);
			}
			button.float::after
			{
				content: url(https://d3naj63yelf4gk.cloudfront.net/dist/img/header/arrow.png);
    			position: absolute;
    			padding-left: 10px;
    			top: 12px;
    			right: 10px;
    			transform: scale(0.7);
    			width: 20px;
			}
		}
		.daftar
		{
			.form
			{
				.poster
				{
					p:first-child
					{
						display: block;
	  					text-align: center;
	  					span
	  					{
	  						display: none;
	  					}
					}
					.item
					{
						position: relative;
						padding: 0px;
						button
						{
							padding:15px 20px;
							margin: 10px auto;
						}
						#img-preview {
							margin: 10px auto;
						}
					}
				}
				.checkbox
				{
					text-align: center;
				}
				.submit
				{
					button
					{
						padding:25px 100px;
					}
				}
			}

		}
		.judges .title::before, .categories .title:before
		{
			content: "";
			position: absolute;
			height: 4px;
			width: 50px;
			left: -30px;
			top: 45%;
			background: rgb(247, 221, 137);
		}
		.line.gradient
		{
			display: none;
		}
	}
}

@media screen and (max-width: 35.5em) {
	#govideo {
		.cat-desktop {
			display: none !important;
		}
		.cat-mobile {
			display: block;
		}
		header {
			h1.hero--title {
				position: relative;
				top: 65px;
			}

			h1.hero--title.second {
				font-size: 12px !important;
		    margin-top: 150px !important;
			}

			a {
				position: relative;
				top: 40px;
			}
		}
		section.video {
			.participant {
				margin-top: 25px;
				position: relative;
				left: -7.5px;

				.text {
					[data-video=name] {
						margin-top: 0;
						position: relative;
						top: -18.5px;
					}
				}
			}
		}
		section.video.detail {
			.wrapper {
				padding: 0;
			}
		}
		section.services {
			[data-video=title] {
				margin-top: -10.5px !important;
			}

			[data-video=name] {
				margin-top: -20px !important;
			}

			h4.title {
		    margin-left: 20px;
				margin-bottom: 35px;

				&:before {
					position: absolute;
					left: -65px;
					top: -5px;
					margin-right: -10px;
				}
			}
		}
		section.participant {
			padding: 30px 0;

			h4.main-title {
				text-align: center;
				object-fit: contain;
				font-family: $opensans;
				font-size: 16px;
				font-weight: bold;
				letter-spacing: 2.5px;
				text-align: center;
				color: #4a4a4a;

				.title {
					float: none !important;
				}
			}

			h4.show-more-mobile {
				display: block !important;
				z-index: 12;
				text-align: center;
				font-size: 21px;
				font-weight: bold;
				letter-spacing: 4px;
				position: relative;
		    top: -70px;
		    margin-bottom: -85px;

				a {
					color: #009444;
				}
			}

			span.separator {
				display: block;
				width: 50px;
				height: 4px;
				margin: 0 auto;
				background-color: #ebb329;
			}

			.navigation {
				display: block;
				margin: 20px 0;
				z-index: 1;

				#prevArrow {
					float: right;
				}
			}

			a.show-more {
				display: none;
			}
		}

		.daftar
		{
			.form {
				.submit {
					text-align: center;
					button {
						position: relative;
						bottom: auto;
						left:0;
						right: 0;
						float: none;
						margin-top: 30px;
					}
				}
			}
		}
		.judges {
			.list {
				padding: 0px !important;
				margin: 0px;
				box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.19);
				@media screen and (max-width: 768px) {
					box-shadow: none;
				}
			}
			.judges-desc
			{
				padding:20px;
			}
		}
		.img-wrapper {
			margin-top: 0px !important;
			.img-container {
				.img-container-2 {
					margin: auto;
				}
			}
		}
		section.how-to
		{
			.out-wrapper {
				.wrapper {
					.slide
					{
						max-width: 300px;
						margin: auto;
					}
				}
			}
		}
		section.snk-govideo
		{
			.back
			{
				display: block !important;
			}
		}
		section.about
		{
			width:100% !important;
			h4
			{
				padding-left: 0px;
			}
			.container:before
			{
				background-color: transparent !important;
			}
			a
			{
				font-size: inherit;
			}
		}

		section.about.top
		{
			margin: auto;
		}
		.daftar {
			background-color: white;
			margin: 0;
			padding: 0;
			.banner {
				display: none;
			}
			.form {
				box-shadow: none;
				width:90%;
				margin:20px auto;
				padding: 0px;

					.title
					{
						padding-left: 30px;
						color: #4a494a;
						i
						{
							display: none;
						}
					}
					.title::before
					{
						content: "";
					    position: absolute;
					    height: 4px;
					    width: 50px;
					    left: -30px;
					    top: 45%;
					    background: rgb(247, 221, 137);
					}

			}
		}

		section.timeline {
			ul {
				li.done {
					&:after {
						left: -22px !important;
					}
				}
			}
		}
		section.how-to {
			.title {
				text-align: center;
				h4::before {
					top: 30px !important;
					left: 0;
					margin: auto;
					right: 0;
				}
			}
			.text-slide {
				h3 {
					font-size: 28px;
					font-weight: 300 !important;
					}
			}
			.slide {
				padding-bottom: 0;
				position: relative;
				.dots-slide {
					position: absolute !important;
					top: 35%;
					text-align: center;
					.slick-dots
					{
						display: inline-block !important;
						margin: auto;
					}
					ul {
						display: inherit;
						li {
							display: inline-block !important;
							margin:0px !important;
							/*float: left !important;*/
						}
					}
				}
				.image-slide {
					width: 50% !important;
					margin: auto;
					margin-top: 50%;
					img {
						height: 50%;
					}
				}
				.text-slide {
					position: absolute;
					text-align: center;
					top: 0;
					left: 0;
				}
				.nav-slide {
					position: absolute;
					top: 60%;
					display: inline-block !important;
					left: 0;

					svg {
						display: inline-block !important;
						margin: 0 !important;
						transform: rotate(-90deg) !important;
					}
					.nav-down {
						float: right;
					}
				}
			}
		}
	}
}

@media screen and (max-width: 1024px) {
	#govideo {
		section.timeline {
			ul {
				margin: 20px auto 0 auto;
				li {
					margin-left: 5%;
					margin-bottom: 0px;
					float: none;
					.wrapper {
						height: 100%;
						padding:10px 0px;
						h4 {
							margin: 0;
							height: auto !important;
						}
					}
				}
				li.done:after {
					height: 100%;
					width: 3px;
				}
				li:before {
					left: 25%;
					top: 35%;
				}
				li:after {
					height: 100%;
					width: 3px;
					left: -35%;
					top: -65%;
				}
			}
		}
	}
}

@media screen and (max-width: 320px) {
	#govideo {
		header {
			h1.hero--title.second {
				margin-top: 110px !important;
			}
		}
	}
}
