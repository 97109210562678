// File Name: _header.scss
// Description: global header styles
// Used by: Page.scss
// Dependencies:
// ------------------------------------------------------------
span.inline {
  display: inline-block;
}

/* brushing */
body
{
  &#gojek .brushing
  {
    @include brush-gojek;
  }
  &#karir .brushing
  {
    @include brush-karir;
  }
  &#goride .brushing
  {
    @include brush-goride;
  }
  &#goride .brushing
  {
    @include brush-goride;
  }
  &#goauto .brushing
  {
    @include brush-goauto;
  }
  &#gocar .brushing
  {
    @include brush-gocar;
  }
  &#goclean .brushing
  {
    @include brush-goclean;
  }
  &#gofood .brushing
  {
    @include brush-gofood;
  }
  &#goglam .brushing
  {
    @include brush-goglam;
  }
  &#gomart .brushing
  {
    @include brush-gomart;
  }
  &#gomassage .brushing
  {
    @include brush-gomassage;
  }
  &#golife .brushing
  {
    @include brush-golife;
  }
  &#gomed .brushing
  {
    @include brush-gomed;
  }
  &#gopay .brushing
  {
    @include brush-gopay;
  }
  &#gopoint .brushing
  {
    @include brush-gopoints;
  }
  &#gopulsa .brushing
  {
    @include brush-gopulsa;
  }
  &#gosend .brushing
  {
    @include brush-gosend;
  }
  &#gotix .brushing
  {
    @include brush-gotix;
  }
  &#gobox .brushing
  {
    @include brush-gobox;
  }
}

/* size per service */
#karir
{
  header.hero
  {
    background-color: $gojek;
  }
}
#gojek,#gobox
{
  .hero--title,.hero--desc
  {
    max-width: 500px;
  }
}
#goauto
{
  header.hero
  {
    background-color: $goauto;
  }
  .hero--title,.hero--desc
  {
    max-width: 637px !important;
  }
}
#gopoint
{
  header.hero
  {
    background-color: $gopoint;
  }
  .hero--title,.hero--desc
  {
    max-width: 500px !important;
  }
}

#gojek
{
  header.hero
  {
    background-color: $gojek;
  }
  .desktop-button
  {
    max-width: none;
    > a:first-child > button
    {
      margin-right:20px;
    }
    button
    {
      &:hover
      {
        transform:scale(1.1);
      }
      transition: all 0.5s;
      border-radius: 5px;
      display: inline-block;
      font-size: 16px;
      padding: 25px 0px;
      width:280px;
      background-color: #009444;
    }
    @media screen and (max-width: 768px) {
      display:none;
    }
  }
}
#gofood
{
  header.hero
  {
    background-color: $gofood;
  }
  .hero--title,.hero--desc
  {
    max-width: 615px !important;
  }
  .hero--desc
  {
    > button:first-child
    {
      margin-right:20px;
    }
    button
    {
      transition: all 0.5s;
      &:hover
      {
        transition:all 0.5s;
        background:#ffffff;
        color:$gofood;
      }
      border-radius: 5px;
      display: inline-block;
      font-size: 16px;
      padding: 25px 20px;
      background-color: #b62025;
    }
    @media screen and (max-width: 768px) {
      display:none;
    }
  }
  button.download
  {
    background: $gofood;
    z-index: 2;
  }
}

#gocar,#gomed,#gopay
{
  .hero--title,.hero--desc
  {
    max-width: 572px !important;
  }
}
#gocar
{
  header.hero
  {
    background-color: $gocar;
  }
}
#gomed
{
  header.hero
  {
    background-color: $gomed;
  }
}
#gopay
{
  header.hero
  {
    background-color: $gopay;
  }
}
#gotix
{
  header.hero
  {
    background-color: $gotix;
  }
}
#gomassage
{
  header.hero
  {
    background-color: $gomassage;
  }
  .hero--title,.hero--desc
  {
    max-width: 572px !important;
  }
}
#golife
{
  header.hero
  {
    background-color: $golife;
  }
}
#gomart
{
  header.hero
  {
    background-color: $gomart;
  }
}
#gobox
{
  header.hero
  {
    background-color: $gobox;
  }
}
#gopulsa
{
  header.hero
  {
    background-color: $gopulsa;
  }
}
#goclean
{
  header.hero
  {
    background-color: $goclean;
  }
}
#goglam
{
  header.hero
  {
    background-color: $goglam;
  }
}
#gosend
{
  header.hero
  {
    background-color: $gosend;
  }
  .sub-desc--row
  {
    em
    {
      font-size: 18px;
      color: #ffffff;
      letter-spacing: 0.4px;
      @media screen and (max-width: 768px) {
        font-size: 12px;
      }
      @media screen and (max-width: 767px) {
        width: 100%;
        text-align: center;
        display: inline-block;
      }
    }
  }
}

/* brushing */
body
{
	&#gojek .brushing
	{
		@include brush-gojek;
	}
	&#karir .brushing
	{
		@include brush-karir;
	}
	&#goride .brushing
	{
		@include brush-goride;
	}
	&#goride .brushing
	{
		@include brush-goride;
	}
	&#goauto .brushing
	{
		@include brush-goauto;
	}
	&#gocar .brushing
	{
		@include brush-gocar;
	}
	&#goclean .brushing
	{
		@include brush-goclean;
	}
	&#gofood .brushing
	{
		@include brush-gofood;
	}
	&#goglam .brushing
	{
		@include brush-goglam;
	}
	&#gomart .brushing
	{
		@include brush-gomart;
	}
	&#gomassage .brushing
	{
		@include brush-gomassage;
	}
	&#golife .brushing
	{
		@include brush-golife;
	}
	&#gomed .brushing
	{
		@include brush-gomed;
	}
	&#gopay .brushing
	{
		@include brush-gopay;
	}
	&#gopoint .brushing
	{
		@include brush-gopoints;
	}
	&#gopulsa .brushing
	{
		@include brush-gopulsa;
	}
	&#gosend .brushing
	{
		@include brush-gosend;
	}
	&#gotix .brushing
	{
		@include brush-gotix;
	}
	&#gobox .brushing
	{
		@include brush-gobox;
	}
}

/* size per service */
#karir
{
	header.hero
	{
		background-color: $gojek;
	}
}
#gojek,#gobox
{
	hero--title,.hero--desc
	{
		max-width: 500px;
	}
}
#goauto
{
	header.hero
	{
		background-color: $goauto;
	}
	.hero--title,.hero--desc
	{
		max-width: 637px !important;
	}
}
#gopoint
{
	header.hero
	{
		background-color: $gopoint;
	}
	.hero--title,.hero--desc
	{
		max-width: 500px !important;
	}
}

#gojek
{
	header.hero
	{
		background-color: $gojek;
	}
	.desktop-button
	{
		max-width: none;
		> a:first-child > button
		{
			margin-right:20px;
		}
		button
		{
      transition: all 0.5s;
      border-radius: 5px;
      display: inline-block;
      font-size: 16px;
      padding: 25px 0px;
      padding: 25px 30px;
      width: auto;
      background-color: #009444;
      &:hover
      {
        color: #fff;
        transition: all 0.5s;
      }
      &:after
      {
        background-image: url(https://d3naj63yelf4gk.cloudfront.net/dist/img/header/arrow.png);
        position: relative;
        left: 10px;
        margin-right: 10px;
        top: 3px;
        width: 22px;
        height: 15px;
        content: "";
        display: inline-block;
        background-size: cover;
        background-position: 50%;
      }

    }
    @media screen and (max-width: 768px) {
     display:none;
   }
 }
}
#gofood
{
	header.hero
	{
		background-color: $gofood;
	}
	.hero--desc
	{
		> button:first-child
		{
			margin-right:20px;
		}
		button
		{
			transition: all 0.5s;
			&:hover
			{
				transition:all 0.5s;
				background:#ffffff;
				color:$gofood;
			}
			border-radius: 5px;
      display: inline-block;
      font-size: 16px;
      padding: 25px 20px;
      background-color: #b62025;
    }
    @media screen and (max-width: 768px) {
     display:none;
   }
 }
 button.download
 {
  background: $gofood;
  z-index: 2;
}
}
#gocar,#gomed,#gopay
{
	.hero--title,.hero--desc
	{
		max-width: 572px !important;
	}
}
#gocar
{
	header.hero
	{
		background-color: $gocar;
	}
}
#gomed
{
	header.hero
	{
		background-color: $gomed;
	}
}
#gopay
{
	header.hero
	{
		background-color: $gopay;
	}
}
#gotix
{
	header.hero
	{
		background-color: $gotix;
	}
}
#gomassage
{
	header.hero
	{
		background-color: $gomassage;
	}
	.hero--title,.hero--desc
	{
		max-width: 572px !important;
	}
}
#golife
{
	header.hero
	{
		background-color: $golife;
	}
}
#gomart
{
	header.hero
	{
		background-color: $gomart;
	}
}
#gobox
{
	header.hero
	{
		background-color: $gobox;
	}
}
#gopulsa
{
	header.hero
	{
		background-color: $gopulsa;
	}
}
#goclean
{
	header.hero
	{
		background-color: $goclean;
	}
}
#goglam
{
	header.hero
	{
		background-color: $goglam;
	}
}
#gosend
{
	header.hero
	{
		background-color: $gosend;
	}
  .hero--desc
  {
    button
    {
      margin-top: 30px;
      transition: all 0.5s;

      &:hover
      {
        transition:all 0.5s;
        background:#ffffff;
        color: $gosend;
      }

      border-radius: 5px;
      display: inline-block;
      font-size: 16px;
      padding: 20px 45px;
      background-color: $gosend;
    }
    @media screen and (max-width: 768px) {
     display:none;
   }
 }
 .sub-desc--row
 {
  em
  {
   font-size: 18px;
   color: #ffffff;
   letter-spacing: 0.4px;
   @media screen and (max-width: 768px) {
    font-size: 12px;
  }
  @media screen and (max-width: 767px) {
    width: 100%;
    text-align: center;
    display: inline-block;
  }
}
}
}

header#real--hero {
  margin-top: 85px;
  .hero--wrapper {
    margin: 0;
    img {
      width: 100%;
    }
  }
}

#govideo header#main--hero
{
  &:after
  {
    box-shadow: none !important;
  }
  .pure-g
  {
    box-shadow: none;
  }
}

#govideo header#main--hero
{
	&:after
	{
		box-shadow: none !important;
	}
	.pure-g
	{
		box-shadow: none;
	}
}

header#main--hero {
	z-index: 1;
	height: auto;
	margin: 82px 0 0px;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center center;
	@media screen and (min-width: 1025px)
	{
		min-height: 500px !important;
	}
	@media screen and (min-width: 769px)
	{
		min-height: 300px;
		&:after
		{
			z-index: -1;
      content: "";
      position: absolute;
      height: 100%;
      width: 30%;
      top: 0;
      box-shadow: inset 15vw 2vw 13vw -7vw rgba(0,0,0,.8);
    }
  }
  @media screen and (max-width: 768px) {
    margin: 60px auto 0;
    min-height:280px;
  }

  &.hero--2019
  {
    @media screen and(min-width: 768px) {
      min-height: 300px !important;
      background-color: transparent;
    }

    @media screen and(max-width: 767px) {
      text-align: center;
      .container {
        padding-left: 0 !important;
        padding-right: 0 !important;
      }
    }

    &:after {
      box-shadow: none;
    }

    h1 {
      font-size: 45px;
      @media screen and(max-width: 767px) {
        font-size: 22px;
      }
    }

    p.subtitle {
      font-size: 20px;
      margin: 0px 0px 8px 0px;
    }

    .desktop-button {
      button {
        margin: 0;
      }
    }

    .hero--title
    {
      max-width: 100%;
    }

    .desc--row
    {
      min-height: 200px;
     > .sub-desc--row
     {
      @media screen and (min-width: 769px) {
        text-align: right;
      }
      @media screen and (max-width: 767px) {
        padding-bottom: 13%;
      }
     }
    }

    .desc--wrapper
    {
      padding: 0 106px;
      @media screen and(max-width: 767px) {
        padding: 0;
      }
    }
  }

  &.gojeksiapnganter--hero
  {
    @media screen and(min-width: 768px) {
      min-height: 300px !important;
      background-color: transparent;
    }
    @media screen and(max-width: 767px) {
      .container {
        padding-left: 0 !important;
        padding-right: 0 !important;
      }
    }
    &:after {
      box-shadow: none;
    }
    h1 {
      font-size: 48px;
      @media screen and(max-width: 767px) {
        font-size: 22px;
      }
    }
    p.subtitle {
      font-size: 20px;
      margin: 0px 0px 5px 0px;
    }
    br {
      @media screen and(max-width: 767px) {
        display: none;
      }
    }
    .hero--title
    {
      max-width: 600px;
    }
    .desc--row
    {
     > .sub-desc--row
     {
      @media screen and (max-width: 767px) {
        padding-bottom: 13%;
      }
     }
    }
    @media screen and (max-width: 767px) {
      text-align:center;
    }
  }
  .hero--title,
  .hero--desc {
    color: #fff;
  }
  button.download
  {
    display: none;
    @media screen and (max-width: 768px) {
      left: -20%;
      bottom: -27px;
      display: inline-block;
      letter-spacing: 1.5px;
      color: #ffffff;
      display: inline-block;
      width: 200px;
      margin: auto;
      padding: 21px 12px;
      position: absolute;
      font-size: 10px;
      right: 0;
      display: inline-block;
      padding-right: 20px;
      border-radius: 5px;
      &:after
      {
        content: "";
        background-image: url(https://d3naj63yelf4gk.cloudfront.net/dist/img/header/arrow.png);
        position: relative;
        left: 10px;
        width: 13px;
        height: 8px;
        display: inline-block;
        top:0px;
        background-size: cover;
      }
    }
    @media screen and (max-width: 767px) {
      left:0;
    }
    @media screen and (max-width: 320px) {
      bottom: -13px;
    }
  }
  .brushing
  {
    background-size: cover;
    background-position: right;
    background-repeat: no-repeat;
    position: absolute;
    width: 120px;
    height: 120px;
    bottom: 0;
    left: 0;
    @media screen and (max-width: 767px) {
      width: 20%;
      height: 20%;
    }
    &:after
    {
      content:"";
      position: absolute;
      height: 40%;
      width: 40%;
      top: 0;
      bottom: -15px;
      left: -15px;
      right: 0;
      margin: auto;
      background-size: 100% 100%;
      background-color: transparent;
      @media screen and (max-width: 767px) {
        bottom: 0;
        left: 0;
        width: 40%;
        height: 40%;
      }
    }
  }
  .desc--row
  {
    position: relative;
    display: table;
    height: 100%;
    width: 100%;
    min-height: 500px;
    > .sub-desc--row
    {
      display: table-cell;
      vertical-align: middle;
      max-width: 400px;
      @media screen and (max-width: 767px) {
        vertical-align: bottom;
        padding-bottom: 20% ;
      }
    }
    @media screen and (max-width: 767px) {
      width: 92%;
      margin:auto;
      min-height: unset;
    }

  }
  .hero--title {
    &.gojekinaja--hero
    {
      text-shadow: 0 2px 20px rgba(0, 0, 0, 0.7);
      font-weight: 900;
      span
      {
        font-weight: 700;
      }
    }
    font-size: 48px;
    max-width: 465px;
    margin: 0px;
    text-transform: uppercase;
    font-family: 'Neo', 'Open Sans', Helvetica, sans-serif !important;
    line-height: 1.16;
    letter-spacing: 0.5px;
    text-shadow: 0 2px 20px rgba(0, 0, 0, 0.7);
    @media screen and (min-width: 2000px) {
      font-size: 120px;
      max-width: 1000px;
    }
    @media screen and (max-width: 768px) {
      font-size:40px;
    }
    @media screen and (max-width: 767px) {
      font-size:22px;
      text-align:center;
    }
  }
  .desc--wrapper {
    height: 100%;
    width: 100%;
    @media screen and (max-width: 767px) {
      width: 100%;
    }
  }
  .pure-g {
    position: relative;
    height: 100%;
    box-shadow: inset 55vw 5vw 100vw -13vw rgba(0,0,0,0.65);
    @media screen and (max-width: 767px) {
      box-shadow:inset 0px -100px 174px 0px rgba(0,0,0,0.80)
    }
  }

  // Backround Ramadhan DO NOT DELETE by Genta
  picture {
    position: relative;
    width: 100%;
    height: auto;
    z-index: -1;

    source,
    img {
      float: left;
      width: 100%;
      height: auto;
      @media screen and (max-width: 767px) {
        height: auto;
        width: 100%;
        float: left;
      }
    }
  }
  .desc--wrapper {
    position: absolute;
    @media screen and (max-width: 768px) {
      left: 8%;
    }
    @media screen and (max-width: 767px) {
      left:0;
      right:0;
      margin:auto;
      min-height:300px;
    }
  }
  // END Backround Ramadhan DO NOT DELETE by Genta
}

header.hero {
  position: relative;
}


header.hero .hero--desc {
  max-width: 465px;
  font-size: 22px;
  font-weight: 300;
  letter-spacing: 1px;
  color: #485460;
  display: block;
  a
  {
    color: #ffffff;
    line-height: inherit;
  }
  @media screen and (min-width: 2000px) {
    font-size: 40px;
    max-width: 1000px;
  }
  @media screen and (max-width: 767px) {
    font-size:12px;
    text-align:center;
    margin:0px;
  }
}

header.hero .read-more--hero {
  margin: 80px 0;
  padding: 2em 3em;
  font-size: 18px;
  font-weight: 500;
  line-height: 0.8;
  letter-spacing: 3.3px;
  color: #ffffff;
  background-color: #48af4a;
  border: 0;
  border-radius: 5px;
  text-transform: uppercase;
  font-family: 'Open Sans', sans-serif;
}

header.hero button#gojek-readmore-hero:after {
  content: url(https://d3naj63yelf4gk.cloudfront.net/dist/img/header/arrow.png);
  position: relative;
  left: 10px;
}

header.hero .desc--wrapper {
  /*background: url('https://d3naj63yelf4gk.cloudfront.net/dist/img/header/hero/bg-grey.png') no-repeat;*/
}

header.hero .slide--wrapper {
  /*background: url('/img/hero/bg-bu.png') no-repeat;*/
  position: absolute;
  top: 0;
  right: 0;
}


header.hero .slide--row {
  top: 0;
  right: 0;
  position: relative;
  height: 100%;
  display: block;
}

header.hero .slide--ovrelay {
  margin: 0;
  position: absolute;
  z-index: 2;
  opacity: 0.4;
  width: 100%;
  height: 100%;
  background: #000000;
}

header.hero .slide--ovrelay>img {
  width: 100%;
  height: 100%;
}

header.hero .slide--row ul {
  position: absolute;
  margin: 0;
  padding: 0;
  list-style: none;
  z-index: 1;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

header.hero .slide--row ul>li {
  position: absolute;
  height: 100%;
  opacity: 0;
  -webkit-transition: opacity .3s ease-in;
  -moz-transition: opacity .3s ease-in;
  -o-transition: opacity .3s ease-in;
  transition: opacity .3s ease-in;
}

header.hero .slide--row ul>li img {
  width: 110%;
  height: 100%;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  transform: translateX(-50%);
  position: relative;
}

header.hero .slide--row ul>li:first-of-type {
  opacity: 1;
}

header.hero .bu--grad {
  position: absolute;
  z-index: 3;
  margin: 0;
  bottom: 0;
  overflow: hidden;
}

header.hero .bu--grad>img {
  top: 10px;
  left: -110px;
  position: relative;
  width: 120%;
}

@media screen and (max-width: 768px) {
  header#main--hero {
    height: auto;
  }

  header.hero .read-more--hero {
    margin: 20px 0px 50px;
    font-size: 14px;
    position: relative;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    transform: translateX(-50%);
  }
  // Backround Ramadhan DO NOT DELETE by Genta
  // END Backround Ramadhan DO NOT DELETE by Genta
}
